






































































import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'

import Modal from '@/components/Modal.vue'

import { ModalMultiSelectValue } from '@/lib/support/components/ModalMultiSelect/ModalMultiSelectValue'

export default defineComponent({
  name: 'ModalMultiSelect',
  components: {
    Modal,
  },
  props: {
    isSmall: { type: Boolean, required: false, default: true },
    title: { required: false, default: '', type: String },
    values: { type: Array as PropType<ModalMultiSelectValue[]>, required: true, default: () => [] },
    disabledIds: { type: Array as PropType<string[]>, required: false, default: () => [] },
    selectedIds: { type: Array as PropType<string[]>, required: true, default: () => [] },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)
    const internalDisabledIds = ref<string[]>([])
    const internalSelectedIds = ref<string[]>([])

    watch(
      () => props.disabledIds,
      () => {
        internalDisabledIds.value = [...props.disabledIds]
      },
      { immediate: true }
    )

    watch(
      () => props.selectedIds,
      () => {
        internalSelectedIds.value = [...props.selectedIds]
      },
      { immediate: true }
    )

    function toggleValue(thing: ModalMultiSelectValue) {
      //can't change something that is disabled.
      if (props.disabledIds.indexOf(thing.id) < 0) {
        // not selected
        if (internalSelectedIds.value.indexOf(thing.id) < 0) {
          internalSelectedIds.value.push(thing.id)
        } else {
          internalSelectedIds.value = [...internalSelectedIds.value.filter((x) => x != thing.id)]
        }
        updateSelected()
      }
    }

    function selectAll() {
      internalSelectedIds.value = [
        ...props.values
          .filter((x) => internalDisabledIds.value.indexOf(x.id) < 0 || props.selectedIds.indexOf(x.id) >= 0)
          .map((x) => x.id),
      ]
      updateSelected()
    }

    function reset() {
      internalSelectedIds.value = [...props.selectedIds]
    }

    function clearAll() {
      internalSelectedIds.value = []
      props.values.forEach((x) => {
        if (internalDisabledIds.value.indexOf(x.id) >= 0 && props.selectedIds.indexOf(x.id) >= 0) {
          //retain initial value.
          internalSelectedIds.value.push(x.id)
        }
      })
    }

    function cancel() {
      closeModal()
    }

    function updateSelected() {
      ctx.emit('update-selected', internalSelectedIds.value)
    }

    function save() {
      closeModal()
      ctx.emit('save', internalSelectedIds.value)
    }

    //private initialState: string[] = []

    return {
      isModalOpen,
      toggleValue,
      internalSelectedIds,
      internalDisabledIds,
      clearAll,
      selectAll,
      reset,
      cancel,
      save,
    }
  },
})
