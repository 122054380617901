








































































import { defineComponent, ref, computed, PropType, onMounted } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import TextInput from '@/elements/TextInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import store from '@/store'
import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
import Loading from '@/elements/Loading.vue'
import communicationsClient from '@/clients/communicationsClient'
export default defineComponent({
  name: 'SendEmailModal',
  props: {
    value: { type: Boolean, default: false, required: true },
    recipients: { type: Array as PropType<Array<string>>, default: [], required: true },
  },
  components: {
    Modal,
    VeeValidateForm,
    TextInput,
    HtmlEditor,
    Loading,
  },
  setup(props, { emit }) {
    const form = ref<any | null>(null)
    const loading = ref(false)
    const isSending = ref(false)
    const email = computed(() => {
      return {
        displayName: from.value,
        replyTo: replyTo.value,
        emailSubject: subject.value,
        messageBody: message.value,
        to: props.recipients,
        accountNumber: accountNumber.value,
        upwardLeagueID: upwardLeagueID.value,
        attachments: null,
        sendCommunicationOn: null,
        minutesToAdd: null,
        recipientCriteria: null,
      }
    })
    const from = ref('')
    const replyTo = ref('')
    const subject = ref('')
    const message = ref('')
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const providerInfo = ref<EmailSMSAccount>({} as any)
    const upwardLeagueID = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID ?? '')
    const hasCredits = computed(() => {
      if (!providerInfo.value) return false
      return (
        providerInfo.value!.emailMaxCredits < 0 ||
        providerInfo.value!.emailCredits < providerInfo.value.emailMaxCredits
      )
    })
    const isAllowed = computed(() => {
      return providerInfo.value?.isEmailAllowed ?? false
    })
    const internalValue = computed({
      get() {
        return props.value
      },
      set(v: Boolean) {
        emit('input', v)
      },
    })
    async function reportValidity() {
      return await form.value.reportValidity()
    }
    async function send() {
      const valid = await reportValidity()
      if (valid) {
        try {
          isSending.value = true
          await communicationsClient.sendMessage(email.value, 'sendemail')
          emit('sendCompleted', {
            success: true,
            error: null,
          })
        } catch (e: any) {
          emit('sendCompleted', {
            success: false,
            error: typeof e === 'string' ? e : e?.message,
          })
        } finally {
          isSending.value = false
        }
      }
    }
    onMounted(async () => {
      try {
        loading.value = true
        const info = await communicationsClient.retrieveProviderInfo(
          upwardLeagueID.value,
          accountNumber.value
        )
        providerInfo.value = info!
      } finally {
        loading.value = false
      }
    })
    return {
      form,
      internalValue,
      from,
      replyTo,
      subject,
      message,
      send,
      loading,
      isAllowed,
      hasCredits,
      isSending,
    }
  },
})
export class SendCompletedResult {
  success = false
  error = ''
}
