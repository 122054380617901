





































































































































































































































































































import { computed, defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import SearchInput from '@/elements/SearchInput.vue'
import communicationsClient from '@/clients/communicationsClient'
import { TwilioApplicationRequest } from '@/GeneratedTypes/Communication/TwilioApplicationRequest'
import Loading from '@/elements/Loading.vue'
import exportFromJSON from 'export-from-json'
import { debounce } from 'lodash'
import { DataTableHeader } from 'vuetify/types'
import DataTable from '@/elements/DataTable/DataTable.vue'
import TextInput from '@/elements/TextInput.vue'
import Modal from '@/components/Modal.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
enum ApplicationStatus {
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETE',
}
interface SearchResults {
  name: string
  status: string
  accountNumber: string
  id: number
}

export default defineComponent({
  components: { SearchInput, Loading, DataTable, TextInput, Modal, VeeValidateForm },
  data() {
    const headers: DataTableHeader[] = [
      { text: '', value: 'actions' },
      { text: 'Number', value: 'accountNumber' },
      { text: 'Name', value: 'name' },
    ]
    return { headers }
  },
  setup() {
    // set to an error if there is one.
    const selectedStatus = ref(ApplicationStatus.Pending)
    const error = ref('')
    const searchTerm = ref('')
    const selectedItem = ref<SearchResults>()
    const selectedRequest = ref<TwilioApplicationRequest>()
    const loading = ref(false)
    const detailLoading = ref(false)
    const editMode = ref(false)
    const results = ref<TwilioApplicationRequest[]>(new Array<TwilioApplicationRequest>())
    const showModal = ref<boolean>(false)
    const editable = computed(() => selectedRequest.value?.status === ApplicationStatus.Pending)
    const canSubmit = computed(() => selectedRequest.value?.status === ApplicationStatus.Pending)
    const canComplete = computed(() => selectedRequest.value?.status === ApplicationStatus.Approved)
    const canRevert = computed(() => selectedRequest.value?.status === ApplicationStatus.Completed)
    const estimatedVolume = ref<string>('')
    const isSelected = (s: string) => {
      return s === selectedStatus.value
    }
    const setStatus = (s: string) => {
      selectedStatus.value = s as ApplicationStatus
    }
    const searchResults = computed(() => {
      return results.value.map((r) => {
        let name = r.partnerName
        let accountNumber = r.accountNumber
        let status = r.status
        let id = r.id
        return { id, name, status, accountNumber } as SearchResults
      })
    })
    watch(
      () => selectedItem.value,
      async (item: SearchResults | undefined) => {
        if (!item) return
        try {
          detailLoading.value = true
          const request = await communicationsClient.getTollFreeApp(item.accountNumber)
          selectedRequest.value = request ?? undefined
          estimatedVolume.value = selectedRequest.value?.estimatedVolume.toString() ?? ''
        } finally {
          detailLoading.value = false
          showModal.value = true
        }
      }
    )
    watch(
      () => showModal.value,
      (v) => {
        if (!v) {
          selectedItem.value = undefined
          selectedRequest.value = undefined
          estimatedVolume.value = ''
          editMode.value = false
        }
      }
    )
    watch(
      () => selectedStatus.value,
      async () => {
        await search()
      }
    )
    watch(
      () => searchTerm.value,
      async () => await search()
    )
    const search = debounce(async () => {
      try {
        loading.value = true
        results.value = await communicationsClient.getTwilioApplicationRequestList(
          selectedStatus.value,
          searchTerm.value
        )
      } finally {
        loading.value = false
      }
    }, 500)
    onMounted(async () => {
      await search()
    })
    async function cancelEdit(num: string) {
      try {
        detailLoading.value = true
        const request = await communicationsClient.getTollFreeApp(num)
        selectedRequest.value = request ?? undefined
        estimatedVolume.value = selectedRequest.value?.estimatedVolume.toString() ?? ''
      } finally {
        editMode.value = false
        detailLoading.value = false
      }
    }
    async function update() {
      try {
        detailLoading.value = true
        const estVol = !estimatedVolume.value ? 0 : parseInt(estimatedVolume.value)
        await communicationsClient.updateTwilioTollFreeApplication({
          ...selectedRequest.value!,
          estimatedVolume: estVol,
        })
        const request = await communicationsClient.getTollFreeApp(selectedRequest.value!.accountNumber!)
        selectedRequest.value = request ?? undefined
        await search()
      } finally {
        editMode.value = false
        detailLoading.value = false
      }
    }
    function approveApplication(accountNumber: string) {
      console.log(accountNumber)

      //THIS IS NOT IMPLEMENTED YET
      //await communicationsClient.approveTollFreeApplication(accountNumber)
    }

    async function generateCSV() {
      error.value = 'Generating CSV...'
      const results = await communicationsClient.getPendingApplicationsCSV()

      const fileName = 'twilioapplicationrequests_' + new Date().toJSON().slice(0, 10)
      const exportType = exportFromJSON.types.csv

      if (results && results.data) {
        var data = results.data
        exportFromJSON({ data, fileName, exportType })
        error.value = 'CSV file generated. Check Downloads folder.'
      }
    }

    async function markAsSubmitted() {
      await communicationsClient.setPendingApplicationsToSubmitted()
      error.value = 'Pending applications marked as submitted.'
    }
    async function submit(accountNumber: string) {
      try {
        detailLoading.value = true
        await communicationsClient.submitTwilioVerificationRequest(accountNumber)
        const request = await communicationsClient.getTollFreeApp(accountNumber)
        selectedRequest.value = request ?? undefined
        await search()
      } finally {
        detailLoading.value = false
      }
    }
    async function complete(accountNumber: string) {
      try {
        detailLoading.value = true
        await communicationsClient.completeTwilioApplicationRequest(accountNumber)
        const request = await communicationsClient.getTollFreeApp(accountNumber)
        selectedRequest.value = request ?? undefined
        await search()
      } finally {
        detailLoading.value = false
      }
    }
    async function revert(accountNumber: string) {
      try {
        detailLoading.value = true
        await communicationsClient.revertTwilioApplicationRequest(accountNumber)
        const request = await communicationsClient.getTollFreeApp(accountNumber)
        selectedRequest.value = request ?? undefined
        await search()
      } finally {
        detailLoading.value = false
      }
    }
    return {
      searchTerm,
      loading,
      searchResults,
      error,
      selectedStatus,
      approveApplication,
      generateCSV,
      markAsSubmitted,
      isSelected,
      setStatus,
      selectedItem,
      showModal,
      selectedRequest,
      editMode,
      editable,
      cancelEdit,
      canSubmit,
      detailLoading,
      update,
      estimatedVolume,
      submit,
      canComplete,
      complete,
      search,
      canRevert,
      revert,
    }
  },
})
