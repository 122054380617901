






















import { defineComponent, ref, watch, onMounted } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'InfoModal',
  components: {
    Modal,
  },
  props: {
    show: { type: Boolean, required: false, default: false },
    title: { type: String, required: true },
    buttonText: { type: String, required: true },
    showClose: { type: Boolean, required: true, default: true },
  },
  setup(props, ctx) {
    const modalIsVisible = ref(false)

    onMounted(() => {
      modalIsVisible.value = props.show
    })

    function close() {
      ctx.emit('close')
    }

    watch(
      () => props.show,
      (newValue: boolean) => {
        modalIsVisible.value = newValue
      }
    )

    return {
      modalIsVisible,
      close,
    }
  },
})
