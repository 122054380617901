


















import { defineComponent } from '@vue/composition-api'
import { ValidationObserver, extend } from 'vee-validate'
import { required, email, between, integer, min, alpha_num } from 'vee-validate/dist/rules'
import { postalCodeLooksValid } from '@/lib/support/utilities/address/postalCodeUtil'
import { isPhoneValid } from '@/lib/support/utilities/phone/phoneUtil.ts'
import dayjs from 'dayjs'

/***
 * Add new validation rules here, or move the library to @/lib/components/VeeValidateForm if it overflows.
 */
extend('namelike', {
  validate: (value) => {
    return value.match(/^([^0-9])+$/)
  },
  message: 'Numbers are unusual in a name.',
})
extend('phone', {
  validate: (value) => value.match(/^(|[0-9]{10})$/),
  message: 'Include 10 digit phone number',
})
extend('fullname', { validate: (value) => value.match(/\S+\s\S+/), message: 'Include a first and last name' })
extend('required', { ...required, message: 'required' })
extend('email', { ...email, message: 'Invalid email format' })
extend('integer', { ...integer, message: 'Only numbers are valid' })
extend('alpha_num', { ...alpha_num, message: 'Only letters and numbers are valid' })
extend('email-list', { ...email, message: 'At least one email is invalid' })
extend('between', { ...between, message: 'Must be between {min} and {max}' })
extend('min', { ...min, message: 'Must be at least {length} characters' })
extend('max_additional_recipient', {
  validate: (value) => {
    let numberValues: string[] = []
    numberValues = value.split(' ').join('').split(',')

    if (numberValues.length >= 21 || value.length >= 300) {
      return 'Maximum number of additional recipient numbers entered'
    }
    return true
  },
})
extend('non_zero_required', (value) => {
  if (value !== 0) {
    return true
  }
  return 'required'
})
extend('exclude-semi-colon', (value) => {
  if (value.indexOf(';') === -1) {
    return true
  }
  return 'semi-colons are not allowed'
})
extend('postal_code', (value) => {
  // passes validation if value is valid US zip code or Canadian postal code
  return postalCodeLooksValid(value) || 'invalid postal code'
})

extend('valid_size', (value) => {
  return value !== 'NONE' || 'select a size'
})

extend('birthdate', {
  validate(value) {
    const dateVal = new Date(value)
    const now = new Date()
    const min = new Date()
    min.setFullYear(min.getFullYear() - 100)

    if (dateVal >= now) {
      return 'Birthdate cannot be in the future'
    }

    if (dateVal <= min) {
      return 'Birthdate must be more recent'
    }

    return true
  },
})

extend('northamerican_phone', {
  // Simple validation for North American phone numbers.
  // Accepts 555-555-5555, (555)555-5555, (555) 555-5555, 555 555 5555, 5555555555, 1 555 555 5555
  // Supports comma delimited string of phone numbers.
  // If you pass 'multiple' as an argument, you get a message suitable for delimited list of phone numbers
  validate: (value: string) => {
    let values: string[] = []
    let valid = true
    values = value.split(' ').join('').split(',')
    values.forEach((val: string) => {
      if (val && !isPhoneValid(val)) {
        valid = false
        return
      }
    })
    return valid
  },
  message: (value, args) => {
    if (args && args[0] === 'multiple') {
      return 'One of the phone numbers is invalid'
    } else {
      return 'Invalid phone number'
    }
  },
})

extend('date_before', {
  params: ['endDate'],
  validate(value, args: Record<string, any>) {
    return dayjs(value).isBefore(args.endDate)
  },
  message: 'Date must be before {endDate}',
})

extend('date_after_target', {
  params: ['target'],
  validate(value, { target }: Record<string, any>) {
    return dayjs(value).isAfter(target)
  },
  message: 'Date must be after {target}',
})

extend('beginDateLessThanEndDate', {
  params: ['target'],
  // @ts-ignore
  validate(value, { target }) {
    /* 
    // usage:
    // on the start date field add the property: validation-provider-name="beginDate"
    // on the end date add the property: vee-validate-rules="beginDateLessThanEndDate@beginDate" 
    */
    const beginDate = new Date(target).setHours(0, 0, 0, 0)
    const endDate = new Date(value).setHours(0, 0, 0, 0)
    if (beginDate > endDate) {
      return false
    } else {
      return true
    }
  },
  message: 'Start date must be earlier than end date.',
})

export default defineComponent({
  name: 'VeeValidateForm',
  components: {
    ValidationObserver,
  },
  setup(props, ctx) {
    function save() {
      ctx.emit('submit')
    }

    /**
     * Works like DOM form (except it is a promise)
     * @return {Promise<boolean>} form status.
     */
    async function checkValidity(): Promise<boolean> {
      const observer = ctx.refs.observer as InstanceType<typeof ValidationObserver>
      return await observer.validate({ silent: true })
    }

    /**
     * Works like form reportValidity (except its a promise)
     * displays validation errors and sets the focus to the first.
     * @return {Promise<boolean>} form status.
     */
    async function reportValidity(): Promise<boolean> {
      const observer = ctx.refs.observer as InstanceType<typeof ValidationObserver>
      return await observer.validate()
    }

    return {
      checkValidity,
      save,
      reportValidity,
    }
  },
})
