export enum InviteStatus {
  WAITING = 'WAITING',
  INVITATIONSENT = 'INVITATIONSENT',
  INVITATIONACCEPTED = 'INVITATIONACCEPTED',
  INVITATIONEXPIRED = 'INVITATIONEXPIRED',
  INVITATIONDECLINED = 'INVITATIONDECLINED',
}

export enum WaitlistActionsEnum {
  SEND = 'SEND',
  CANCEL = 'CANCEL',
  REMOVE = 'REMOVE',
  EMAIL = 'EMAIL',
}
