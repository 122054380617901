


































































































// TODO: Once warnings are included in the API, make sure they show (already in markup)

import { defineComponent, computed, PropType } from '@vue/composition-api'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import ProgramIcon from '@/components/ProgramIcon.vue'
import date from '@/filters/date'
import ProgramOverview from '@/components/ProgramOverview.vue'
import { LeagueTypes } from '@/lib/common/LeagueTypes'

export default defineComponent({
  name: 'LeagueOverview',
  components: {
    ProgramIcon,
    ProgramOverview,
  },
  filters: {
    date,
  },
  props: {
    program: { type: Object as PropType<LeagueInfoCondensed>, required: true },
    showNameOnly: { type: Boolean, required: false, default: false },
    link: { type: String, required: false, default: '' },
  },
  setup(props) {
    const registrationUrl = computed(
      () => `${process.env.VUE_APP_PARTICIPANT_REGISTRATION_DOMAIN}${props.program.upwardLeagueID}`
    )

    const volunteerApplicationUrl = computed(
      () => `${process.env.VUE_APP_PARTICIPANT_REGISTRATION_DOMAIN}${props.program.upwardLeagueID}/volunteer`
    )

    const isResourceOnlyLeague = computed((): boolean => {
      if (props.program && props.program.typeSystemsAccessID) {
        return props.program.typeSystemsAccessID == 'NONE'
      }
      return false
    })

    const isUpwardSelect = computed((): boolean => props.program.typeLeagueID == LeagueTypes.UPWARDSELECT)

    const baseLink = computed(() => {
      const base = `/programs/${isResourceOnlyLeague.value ? 'digitalleague' : 'league'}/${
        props.program.upwardLeagueID
      }`

      if (props.link) {
        return `${base}${props.link}`
      }
      return base
    })

    const defaultLink = computed(() => {
      const base = baseLink.value

      if (!props.showNameOnly) {
        if (isResourceOnlyLeague.value) {
          return `${base}/resources`
        } else {
          return `${base}/setup/information`
        }
      }
      return base
    })

    return {
      defaultLink,
      isResourceOnlyLeague,
      baseLink,
      registrationUrl,
      volunteerApplicationUrl,
      isUpwardSelect,
    }
  },
})
