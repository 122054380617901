




























































import SelectorCard from '@/components/SelectorCard.vue'

import {
  DisplayablePromoCardMedia,
  getEmptyDisplayablePromoCardMedia,
  getEmptyPromoCardMedia,
  PromoCardMedia,
} from '@/models/PromoCards/PromoCardMedia'
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import PromoCardModal from '@/views/Programs/Orders/vues/components/PromoCardModal.vue'
import ordersClient from '@/clients/ordersClient'
import PromoCardDisplay from '@/views/Programs/Orders/vues/components/PromoCardDisplay.vue'
import Loading from '@/elements/Loading.vue'
import { cloneDeep } from 'lodash'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

import store from '@/store'
import { getEmptyOrderProduct } from '@/lib/support/models/GeneratedTypes/xOrders/xOrderProduct'

/**
 * returns if the size variation is "non-empty"
 **/
function emptyProduct(s: string) {
  return s && s != 'N/A'
}

export default defineComponent({
  components: {
    ConfirmationModal,
    Loading,
    PromoCardDisplay,
    PromoCardModal,
    SelectorCard,
  },
  props: {
    leagueID: { type: String, default: '', required: true },
    accountNumber: { type: String, default: 0, required: true },
    //** selected products, we skip promo cards if they aren't selected.
  },
  setup(props, ctx) {
    //establishes type here.

    const cards = ref<DisplayablePromoCardMedia[]>([])
    const productsSelected = computed(() => store.getters.orders.productsSelected)

    //** basically the product order qty is holding our selected cards for a promocard, we refer to it as a number[]
    const cardsSelected = computed(
      () =>
        (
          (
            (store.getters.orders.currentOrderProducts.length
              ? store.getters.orders.currentOrderProducts
              : []
            ).find((x) => x.upwardProductID == 'PROMOCARD') ?? getEmptyOrderProduct()
          ).productColorSizes?.filter((p) => emptyProduct(p.typeColorID)) ?? []
        ).map((y) => y.typeColorID) ?? []
    )

    const hasSelectedACard = computed(() => cardsSelected.value && cardsSelected.value.length)

    /**
     * When a card is selected allow continuing to the next step
     */
    watch(
      () => hasSelectedACard.value,
      (b) => {
        if (b) {
          ctx.emit('on-enable')
        } else {
          ctx.emit('on-disable')
        }
      },
      { immediate: true }
    )
    /**
     * toggles a card, updates the store.
     * @param card
     */
    function toggleCard(card: PromoCardMedia) {
      let cs = (cloneDeep(cardsSelected.value) as unknown) as string[]
      cs = cs.filter((x) => x != card.productUniqueID)
      // if it is not found  in the current selected, then it must be an add.
      if (!cardsSelected.value.find((x) => x == card.productUniqueID)) {
        cs.push(card.productUniqueID)
      }

      function cardfind(id: string) {
        return cards.value.find((x) => x.productUniqueID == id) ?? getEmptyPromoCardMedia()
      }

      store.commit.orders.setCardsSelected({
        cards: cs
          .map((x) => {
            card = cardfind(x)
            return { name: card?.name ?? '', id: card.productUniqueID }
          })
          .filter((x) => emptyProduct(x.id)),
      })
    }

    function isSelected(card: PromoCardMedia) {
      return !!cardsSelected.value.find((x) => x == card.productUniqueID)
    }

    const isPromoCardCreateShowing = ref(false)
    const loadingPromoCard = ref(false)
    function promoCardClosed() {
      isPromoCardCreateShowing.value = false
    }
    async function createNewCardClicked() {
      loadingPromoCard.value = true
      try {
        promocard.value =
          (await ordersClient.getPromoCardTemplate(props.leagueID)) ?? getEmptyDisplayablePromoCardMedia()
        promocard.value.upwardleagueID = props.leagueID
        promocard.value.accountNumber = props.accountNumber
        isPromoCardCreateShowing.value = true
      } finally {
        loadingPromoCard.value = false
      }
    }
    const promocard = ref<DisplayablePromoCardMedia>(getEmptyDisplayablePromoCardMedia())
    const promoPreview = ref<PromoCardMedia | null>(null)
    async function promoCardUpdated(promocard: PromoCardMedia) {
      if (!promocard.upwardleagueID) {
        promocard.upwardleagueID = props.leagueID
      }
      if (!promocard.accountNumber || !parseInt(promocard.accountNumber)) {
        promocard.accountNumber = props.accountNumber
      }
      await saveCard(promocard)
    }

    async function saveCard(promocard: PromoCardMedia) {
      loading.value = true
      isPromoCardCreateShowing.value = false
      const newcard = await ordersClient.savePromoCard(promocard, false)
      loading.value = false
      if (newcard) {
        cards.value = cards.value.filter((x) => x.marketingID != newcard.marketingID)
        cards.value.push(newcard)
        toggleCard(newcard)
      }
    }

    onMounted(async () => {
      cards.value = await ordersClient.getPromoCards(props.leagueID)

      if (!productsSelected.value.find((x) => x.upwardProductID == 'PROMOCARD')) {
        //skip this step if no promo cards are selected.
        ctx.emit('go-next')
      }
    })
    const loading = ref(false)

    async function copyCard(card: PromoCardMedia) {
      loading.value = true
      const newcard = cloneDeep(card)
      newcard.marketingID = 0
      card.name = `Copy of ${card.name}`
      await saveCard(newcard)
    }

    watch(
      () => loading.value,
      (nv: boolean) => {
        // if we are loading we aren't allowing the next button
        if (!nv) {
          ctx.emit('on-enable', nv)
        } else {
          ctx.emit('on-disable', nv)
        }
      }
    )

    function editCard(card: PromoCardMedia) {
      promocard.value = cloneDeep(card)
      isPromoCardCreateShowing.value = true
    }

    const isPromoCardDeleteConfirmShowing = ref(false)
    const promoCardToDelete = ref(getEmptyDisplayablePromoCardMedia())
    function removeCard(card: DisplayablePromoCardMedia) {
      isPromoCardDeleteConfirmShowing.value = true
      promoCardToDelete.value = card
    }

    async function deleteCardResponded(b: boolean) {
      if (b) {
        loading.value = true
        await ordersClient.deletePromoCard(promoCardToDelete.value.marketingID)
        // remove the deleted card from the list.
        cards.value = cards.value.filter((x) => x.marketingID != promoCardToDelete.value.marketingID)
        store.commit.orders.setCardsSelected({
          cards: cards.value
            .map((x) => {
              return { name: x?.name ?? '', id: x.productUniqueID }
            })
            .filter((x) => emptyProduct(x.id)),
        })
        loading.value = false
      }
      isPromoCardDeleteConfirmShowing.value = false
    }

    return {
      deleteCardResponded,
      loading,
      isPromoCardDeleteConfirmShowing,
      promoCardToDelete,
      copyCard,
      editCard,
      promoPreview,
      removeCard,
      isPromoCardCreateShowing,
      promocard,
      promoCardClosed,
      promoCardUpdated,
      createNewCardClicked,
      cards,
      isSelected,
      toggleCard,
      loadingPromoCard,
    }
  },
})
