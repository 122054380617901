

























import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import { loadGradeDataIntoProgram, getGradesList, ProgramWithGrades } from '@/services/gradeService'

import { getDefaultGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import { LeagueAccountProgramGenderGrade } from 'src/GeneratedTypes/LeagueAccountProgramGenderGrade'
import { LeagueProgramGenderGrade } from 'src/GeneratedTypes/LeagueProgramGenderGrade'

import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import GradeRangeSlider from '@/elements/GradeRangeSlider.vue'

export interface SelectedGradeGender {
  accountGrades: LeagueAccountProgramGenderGrade[] | null
  cheerProgramGrades: LeagueProgramGenderGrade[] | null
  sportProgramGrades: LeagueProgramGenderGrade[] | null
}

export function emptySelectedGrades(): SelectedGradeGender {
  return {
    accountGrades: [] as LeagueAccountProgramGenderGrade[],
    cheerProgramGrades: [] as LeagueProgramGenderGrade[],
    sportProgramGrades: [] as LeagueProgramGenderGrade[],
  }
}

export default defineComponent({
  name: 'GradeGenderSelector',
  components: {
    InputLabel,
    CheckboxInput,
    GradeRangeSlider,
  },
  props: {
    program: { type: Object as PropType<ProgramWithGrades>, required: false },
    showAges: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const league = ref<ProgramWithGrades>({ id: 0, accounts: null, programs: null })
    const girlGrades = ref<string[]>([])
    const boyGrades = ref<string[]>([])
    const cheerBoyGrades = ref<string[]>([])
    const cheerGirlGrades = ref<string[]>([])
    const includeGirls = ref(true)
    const includeBoys = ref(true)
    const includeGirlsCheer = ref(true)
    const includeBoysCheer = ref(false)

    onMounted(() => {
      if (props.program) {
        league.value = cloneDeep(props.program)
      }
      initValues()
    })

    function initValues() {
      initSport()
      initCheer()
    }

    function initSport() {
      if (!league.value || !league.value.programs) return
      if (league.value.programs && sportProgramTypeId.value) {
        if (newLeague.value) {
          boyGrades.value = getDefaultGrades(false)
          girlGrades.value = getDefaultGrades(false)
        } else {
          girlGrades.value = cloneDeep(getGradesList(league.value, sportProgramTypeId.value, 'F')).map(
            (x) => x.upwardTypeID!
          )
          boyGrades.value = cloneDeep(getGradesList(league.value, sportProgramTypeId.value, 'M')).map(
            (x) => x.upwardTypeID!
          )
        }
        includeBoys.value = sportProgramTypeId.value === 'SOFTBALL' ? false : boyGrades.value.length > 0
        includeGirls.value = girlGrades.value.length > 0
      }
    }

    function initCheer() {
      if (!league.value || !league.value.programs) return
      if (hasCheer.value && cheerProgramTypeId.value) {
        if (newLeague.value) {
          cheerGirlGrades.value = getDefaultGrades(false)
          cheerBoyGrades.value = getDefaultGrades(false)
        } else {
          cheerGirlGrades.value = cloneDeep(getGradesList(league.value, cheerProgramTypeId.value, 'F')).map(
            (x) => x.upwardTypeID!
          )
          cheerBoyGrades.value = cloneDeep(getGradesList(league.value, cheerProgramTypeId.value, 'M')).map(
            (x) => x.upwardTypeID!
          )
        }

        includeBoysCheer.value = newLeague.value ? false : cheerBoyGrades.value.length > 0
        includeGirlsCheer.value = cheerGirlGrades.value.length > 0
      }
    }

    const newLeague = computed(() => {
      let grades = [] as LeagueAccountProgramGenderGrade[]
      if (league.value.accounts && league.value.accounts[0].grades) {
        grades = league.value.accounts[0].grades
      }
      return league.value.id === 0 && grades?.length === 0
    })

    const sportProgramTypeId = computed((): string | null => {
      if (league.value.programs != null && league.value.programs.length > 0) {
        return league.value.programs[0].typeProgramID
      }
      return null
    })

    const cheerProgramTypeId = computed((): string | null => {
      if (league.value.programs != null && league.value.programs.length > 1) {
        return league.value.programs[1].typeProgramID
      }
      return null
    })

    const hasCheer = computed((): boolean => {
      if (!league.value || !league.value.programs) return false
      return league.value.programs && league.value.programs.length > 1
    })

    watch(
      () => props.program,
      () => {
        if (props.program) {
          league.value = cloneDeep(props.program)
          initValues()
        }
      }
    )

    watch(
      () => girlGrades.value,
      () => {
        if (!sportProgramTypeId.value) return
        loadGradeDataIntoProgram(league.value, girlGrades.value, sportProgramTypeId.value, 'F')
      }
    )

    watch(
      () => cheerGirlGrades.value,
      () => {
        if (!cheerProgramTypeId.value) return
        loadGradeDataIntoProgram(league.value, cheerGirlGrades.value, cheerProgramTypeId.value, 'F')
      }
    )

    watch(
      () => boyGrades.value,
      () => {
        if (!sportProgramTypeId.value) return
        loadGradeDataIntoProgram(league.value, boyGrades.value, sportProgramTypeId.value, 'M')
      }
    )

    watch(
      () => cheerBoyGrades.value,
      () => {
        if (!cheerProgramTypeId.value) return
        loadGradeDataIntoProgram(league.value, cheerBoyGrades.value, cheerProgramTypeId.value, 'M')
      }
    )

    watch(
      () => [
        league.value.accounts,
        league.value.programs,
        includeGirls.value,
        includeBoys.value,
        includeGirlsCheer.value,
        includeBoysCheer.value,
      ],
      () => {
        emitChange()
      },
      { deep: true }
    )

    function emitChange() {
      const data = emptySelectedGrades()
      beforeEmit()
      if (league.value.accounts && league.value.accounts.length > 0) {
        data.accountGrades = cloneDeep(league.value.accounts[0].grades)
      }
      if (league.value.programs) {
        if (league.value.programs.length > 0) {
          data.sportProgramGrades = cloneDeep(league.value.programs[0].gradesByGender)
        }
        if (league.value.programs.length > 1) {
          data.cheerProgramGrades = cloneDeep(league.value.programs[1].gradesByGender)
        }
        ctx.emit('changed', data)
      }
    }

    function beforeEmit() {
      // if a gender is excluded, clear grades
      if (!includeGirls.value && sportProgramTypeId.value) {
        girlGrades.value = []
        loadGradeDataIntoProgram(league.value, girlGrades.value, sportProgramTypeId.value, 'F')
      }
      if (!includeBoys.value && sportProgramTypeId.value) {
        boyGrades.value = []
        loadGradeDataIntoProgram(league.value, boyGrades.value, sportProgramTypeId.value, 'M')
      }
      if (!includeBoysCheer.value) cheerBoyGrades.value = []
      if (!includeGirlsCheer.value) cheerGirlGrades.value = []

      //If a gender was excluded, then added back, it's necessary
      //to add the grades back

      if (includeGirls.value && !girlGrades.value?.length && sportProgramTypeId.value) {
        loadGradeDataIntoProgram(league.value, getDefaultGrades(false), sportProgramTypeId.value, 'F')
      }

      if (includeBoys.value && !boyGrades.value?.length && sportProgramTypeId.value) {
        loadGradeDataIntoProgram(league.value, getDefaultGrades(false), sportProgramTypeId.value, 'M')
      }

      if (includeGirlsCheer.value && !cheerGirlGrades.value?.length && cheerProgramTypeId.value) {
        loadGradeDataIntoProgram(league.value, getDefaultGrades(false), cheerProgramTypeId.value, 'F')
      }

      if (includeBoysCheer.value && !cheerBoyGrades.value?.length && cheerProgramTypeId.value) {
        loadGradeDataIntoProgram(league.value, getDefaultGrades(false), cheerProgramTypeId.value, 'M')
      }
    }

    return {
      includeGirls,
      girlGrades,
      includeBoys,
      boyGrades,
      hasCheer,
      includeGirlsCheer,
      cheerGirlGrades,
      includeBoysCheer,
      cheerBoyGrades,
    }
  },
})
