




























































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import Alert from '@/components/Alert.vue'
import Accordion from '@/components/Accordion.vue'
import AccordionItem from '@/components/AccordionItem.vue'
import store from '@/store'

export default defineComponent({
  name: 'TheErrorRenderer',
  components: {
    Alert,
    Accordion,
    AccordionItem,
  },
  setup(props, ctx) {
    const currentError = computed(() => store.getters.errors.currentError)
    const allErrors = computed(() => store.getters.errors.allErrors)
    const currentValidationError = computed(() => store.getters.errors.currentValidationError)
    const allValidationErrors = computed(() => store.getters.errors.allValidationErrors)

    const alertIsVisible = ref(!!currentError.value)

    const highWaterTimeStamp = ref(new Date())
    const highestTimeStamp = computed(() => {
      if (currentError.value && currentError.value.timestamp > highWaterTimeStamp.value) {
        highWaterTimeStamp.value = currentError.value.timestamp
      }
      if (currentValidationError.value && currentValidationError.value.timestamp > highWaterTimeStamp.value) {
        highWaterTimeStamp.value = currentValidationError.value.timestamp
      }
      return highWaterTimeStamp.value
    })

    /**
     * Grab the error with the newest timestamp as "current"
     */
    const currentItem = computed(() => {
      if (currentError.value && currentValidationError.value) {
        if (currentError.value.timestamp > currentValidationError.value.timestamp) {
          return currentError.value
        }
        return currentValidationError.value
      }
      return currentError.value || currentValidationError.value
    })

    const itemCount = computed(() => allErrors.value.length + allValidationErrors.value.length)

    function errorDetailMessage(err: any) {
      if (err.innerException && err.innerException.errorObject && err.innerException.errorObject.message) {
        return err.innerException.errorObject.message
      }
      return null
    }

    watch(
      () => ctx.root.$route,
      () => {
        store.commit.errors.clearAll(null)
      }
    )

    watch(
      () => currentItem.value,
      () => {
        alertIsVisible.value = !!currentItem.value
      }
    )

    function onAlertIsVisibleChange() {
      if (!alertIsVisible.value && currentItem.value) {
        store.commit.errors.clearCurrentValidationError(0)
        store.commit.errors.clearCurrentError(0)
      }
    }

    return {
      alertIsVisible,
      currentError,
      currentItem,
      highestTimeStamp,
      currentValidationError,
      itemCount,
      onAlertIsVisibleChange,
      errorDetailMessage,
    }
  },
})
