

















































import { defineComponent, computed, PropType } from '@vue/composition-api'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import { getEmptyLeagueVolunteer } from '@/lib/support/models/LeagueVolunteer/data'

export default defineComponent({
  name: 'CoachListElement',
  props: {
    coach: {
      type: Object as PropType<LeagueCoachProgramInfoFLAT>,
      required: true,
      default: () => getEmptyLeagueVolunteer(),
    },
    teamCount: { required: false, type: Number, default: () => 0 },
    selectedCoachID: { required: false, type: Number, default: () => 0 },
  },
  setup(props, ctx) {
    /***
     * Return string of coach nights in english
     */
    const coachNights = computed(() => props.coach.practiceNights)

    const girlString = computed(() => (props.coach.girlGrades ? `Girls: ${props.coach.girlGrades}` : ''))

    const boyString = computed(() => (props.coach.boyGrades ? `Boys: ${props.coach.boyGrades}` : ''))

    const isSelected = computed(() => props.coach.individualID == props.selectedCoachID)

    function selectCoach() {
      ctx.emit('selectCoach', props.coach)
    }

    return {
      isSelected,
      coachNights,
      boyString,
      girlString,
      selectCoach,
    }
  },
})
