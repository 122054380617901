














import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

interface TeamOrderType {
  order: number
  team: DivisionTeamInfo
}

export default defineComponent({
  name: 'TeamToggle',
  props: {
    teams: { required: true, type: Array as PropType<DivisionTeamInfo[]> },
    teamsChecked: { required: true, type: Array as PropType<number[]> },
    loading: Boolean,
  },
  setup(props, ctx) {
    const teamOrder = ref<TeamOrderType[]>([])

    watch(
      () => props.teams,
      () => {
        let order = 0
        const copyList = cloneDeep(props.teams).sort(
          (a, b) => a?.teamName?.localeCompare(b?.teamName ?? '') ?? 0
        )

        teamOrder.value = copyList.map((x) => ({
          order: order++,
          team: x,
        }))
      },
      { immediate: true }
    )

    const isSelected = (teamID: number) => {
      return props.teamsChecked.includes(teamID)
    }

    const toggleTeam = (teamID: number) => ctx.emit('toggle-team', teamID)

    return {
      toggleTeam,
      teamOrder,
      isSelected,
    }
  },
})
