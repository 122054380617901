



















import { defineComponent, computed, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import Step from '@/components/ProductFlow/Step.vue'
import ProductGroupPicker from './ProductPickers/ProductGroupPicker.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'

import store from '@/store'

export default defineComponent({
  name: 'ProductOfferingStep',
  props: {
    step: { type: Number, required: true },
    isSport: { type: Boolean, required: true },
    selectedPackage: { type: String, required: false, default: '' },
  },

  components: { Step, ProductGroupPicker },
  setup(props, { emit }) {
    const logic = productFlowLogic()

    const maxTouchedStep = computed(() =>
      props.isSport ? logic.kit1MaxTouchedStep.value : logic.kit2MaxTouchedStep.value
    )

    /*
     * For saved configs, find the productoffering the user saved
     */

    const savedProductOffering = computed((): number => {
      // If there is a config that is aleady saved, retrieve the
      // product offering id that had been originally selected by finding the
      // first id that is in the productOffering array.
      // This is needed because there can be sports and cheer offering ids present.
      const details = store.getters.productOfferings.productOfferingConfig.details
      if (!details || details.length === 0) return 0
      const uniqueProductOfferingConfigIds = [...new Set(details.map((d) => d.productOfferingID))]
      const firstMatch = uniqueProductOfferingConfigIds.find((id: number) => {
        return productOfferings.value.some((p: ProductOfferingInfo) => p.id === id)
      })

      return firstMatch ?? 0
    })

    /*
     * Calculate the list of valid productOffings for this kit (aka sport or cheer)
     */

    const productOfferings = computed(() => {
      const o = cloneDeep(store.getters.productOfferings.offeringsByProgram)
      const offeringForThisProgram = o.find((p) => p.isCheer == !props.isSport)
      if (offeringForThisProgram) {
        return offeringForThisProgram.offerings
      } else {
        return [] as ProductOfferingInfo[]
      }
    })

    const validProductOfferings = computed(() => {
      return productOfferings.value.filter((p) => p.id == savedProductOffering.value || p.active)
    })

    /*
     * Tracking and committing selected product offering
     */
    const selectedOffering = ref<ProductOfferingInfo | null>(null)

    async function setSelectedProductGroup(result: {
      action: string
      package: string
      offering: ProductOfferingInfo
    }) {
      if (result.action == 'PackageChangeOnly') {
        //remove product details of old offering
        store.commit.productOfferings.removeFromSelectedProductDetailByOfferingId({
          selectedOffering: result.offering,
        })
        emit('change', result)
      } else if (result.action == 'OfferingChanged') {
        //remove product details of old offering
        store.commit.productOfferings.removeFromSelectedProductDetailByOfferingId({
          selectedOffering: result.offering,
        })

        // commit offering
        selectedOffering.value = cloneDeep(result.offering)
        store.commit.productOfferings.setSelectedProductOfferings({
          selectedOffering: cloneDeep(result.offering) as ProductOfferingInfo,
        })

        //fetch child products
        await fetchChildProducts()

        //set isDirty
        logic.markAsDirty()

        //emit
        emit('change', result)
      }
    }

    const stepLabel = computed(() => {
      return selectedOffering.value?.typeProgramID?.includes('CAMP') ? 'Camp Kit' : 'Uniform'
    })

    /*
     *  Get child products of the selected productOffering
     */
    async function fetchChildProducts() {
      if (selectedOffering.value) {
        const selectedId = selectedOffering.value.id
        await fetchKitProducts(selectedId)
      }
    }

    async function fetchKitProducts(productOfferingId: number) {
      await store.dispatch.productOfferings.fetchChildOfferings({
        id: productOfferingId,
      })
    }

    return {
      ...logic,
      validProductOfferings,
      savedProductOffering,
      setSelectedProductGroup,
      stepLabel,
      maxTouchedStep,
    }
  },
})
