










































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import { Resource } from '@/models/Program/Resource'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import ResourceTile from './ResourceTile.vue'

const allCategoriesLabel = 'All'

type Taxonomy = {
  title: string
  resources: Resource[]
}

export default defineComponent({
  name: 'RoleResources',
  components: {
    VerticalTabs,
    Loading,
    TextInput,
    SelectInput,
    ResourceTile,
  },
  props: {
    resources: { type: Array as PropType<Resource[]>, required: true },
    role: { type: Object as PropType<TypeLeagueVolunteerRole>, required: false, default: null },
    leagueListItem: { type: Object as PropType<LeagueListItem>, required: false, default: null },
  },
  setup(props) {
    const loading = ref(false)
    const taxonomyTitleIndex = ref(0)
    const searchKeyword = ref('')
    const committedTaxonomyTitle = ref(allCategoriesLabel)
    const committedSearchKeyword = ref('')

    /** A human friendly sentence about the current committed search filters */
    const searchDesciption = computed(() => {
      const isAllCategories = committedTaxonomyTitle.value === allCategoriesLabel
      const allText = isAllCategories ? 'all' : ''
      const title = isAllCategories ? '' : committedTaxonomyTitle.value

      if (!displayedResources.value.length) {
        return `There are no ${props.role?.description} resources matching those filters.`
      }

      if (title && committedSearchKeyword.value) {
        return `Here are ${allText} ${props.role?.description} resources for "${title}" containing the keyword "${committedSearchKeyword.value}":`
      }

      if (committedSearchKeyword.value) {
        return `Here are ${allText} ${props.role?.description} resources containing the keyword "${committedSearchKeyword.value}":`
      }

      if (title) {
        return `Here are ${allText} ${props.role?.description} resources for "${title}":`
      }

      if (isAllCategories) {
        return `Here are ${allText} ${props.role?.description} resources:`
      }

      return ''
    })

    /** Should the filter controls be shown and used? */
    const showFilter = computed(() => taxonomyTitles.value.length > 1)

    const taxonomies = computed(() => {
      const taxonomies: { [key: string]: Resource[] } = {}

      for (const resource of props.resources) {
        if (resource.leagueDirectoryTaxonomy && Array.isArray(resource.leagueDirectoryTaxonomy)) {
          for (const taxonomyTitle of resource.leagueDirectoryTaxonomy) {
            if (!taxonomies[taxonomyTitle]) {
              taxonomies[taxonomyTitle] = []
            }

            taxonomies[taxonomyTitle].push(resource)
          }
        }
      }

      return taxonomies
    })

    const taxonomyTitles = computed(() => Object.keys(taxonomies.value).sort())

    const taxonomyFilterOptions = computed(() => [allCategoriesLabel, ...taxonomyTitles.value])

    /** Gets the taxonomies to display as an array. They are to be displayed
     * based on the category filter.
     */
    const taxonomiesToDisplay = computed(() => {
      const isAllCategories = committedTaxonomyTitle.value === allCategoriesLabel

      if (!isAllCategories && showFilter.value && committedTaxonomyTitle.value) {
        return [
          {
            title: committedTaxonomyTitle.value,
            resources: taxonomies.value[committedTaxonomyTitle.value],
          } as Taxonomy,
        ]
      }

      let asArray = [] as Taxonomy[]

      for (const title of taxonomyTitles.value) {
        asArray.push({
          title,
          resources: taxonomies.value[title],
        })
      }

      return asArray
    })

    /** This should return the resources that are display on the page.
     * Resources are displayed if the filters are such that the resource matches.
     * If no filter controls are shown, then all resources are shown.
     */
    const displayedResources = computed(() => {
      let resources = taxonomiesToDisplay.value.map((t) => t.resources).flat()

      if (showFilter.value && committedSearchKeyword.value) {
        resources = resources.filter(
          (r) =>
            r.title.toLowerCase().includes(committedSearchKeyword.value) ||
            (r.shortDescriptionContent || '').toLowerCase().includes(committedSearchKeyword.value)
        )
      }

      // Get the unique resource
      return resources.filter((e, i) => resources.findIndex((a) => a.resourceID === e.resourceID) === i)
    })

    /** Event handler for the filter submit button.
     * This should update the displayed resources based on the filter values.
     */
    function doSearch() {
      committedSearchKeyword.value = searchKeyword.value.trim().toLowerCase()
      committedTaxonomyTitle.value = taxonomyFilterOptions.value[taxonomyTitleIndex.value] || ''
    }

    watch(
      () => props.role,
      () => {
        // Reset the filters
        taxonomyTitleIndex.value = 0
        searchKeyword.value = ''
        committedSearchKeyword.value = ''
        committedTaxonomyTitle.value = allCategoriesLabel
      }
    )

    return {
      loading,
      showFilter,
      taxonomyTitleIndex,
      taxonomyFilterOptions,
      searchKeyword,
      searchDesciption,
      displayedResources,
      doSearch,
    }
  },
})
