






























































import dayjs from 'dayjs'

interface EFTListHeader {
  EftAmount: number
  EftDate: Date
  EftNumber: string
  detail: EFTListDetail[]
}

interface EFTListDetail {
  AmountExpectedToPay: null | number
  CashRecAmount: number
  CashRecNbr: string
  ConfirmationNumber: null | string
  lastConfirmationNumber: null | string
  FirstName: null | string
  LastName: null | string
  Regid: null | string
  TransactionCompletedDate: null | Date
  UpwardLeagueID: string
  total: number | null
  bg: number
}

interface EFTListDataType {
  AmountExpectedToPay: null | number
  CashRecAmount: number
  CashRecNbr: string
  ConfirmationNumber: null | string
  EftAmount: number
  EftDate: Date
  EftNumber: string
  FirstName: null | string
  LastName: null | string
  Regid: null | string
  TransactionCompletedDate: null | Date
  UpwardLeagueID: string
}

import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

export default defineComponent({
  props: {
    reportData: {
      type: Array as PropType<EFTListDataType[]>,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const showing = ref<string[]>([])

    function date(d: Date) {
      return dayjs(d).format('MM/DD/YYYY')
    }

    const reportNormalized = computed(() => {
      const rv: EFTListHeader[] = []
      props.reportData.forEach((x) => {
        let d = rv.find((y) => y.EftNumber == x.EftNumber)
        if (!d) {
          d = {
            EftNumber: x.EftNumber,
            EftAmount: x.EftAmount,
            EftDate: x.EftDate,
            detail: [],
          }
          rv.push(d)
        }
        d?.detail.push({
          AmountExpectedToPay: x.AmountExpectedToPay,
          CashRecAmount: x.CashRecAmount,
          CashRecNbr: x.CashRecNbr,
          ConfirmationNumber: x.ConfirmationNumber,
          lastConfirmationNumber: x.ConfirmationNumber,
          FirstName: x.FirstName,
          LastName: x.LastName,
          Regid: x.Regid,
          TransactionCompletedDate: x.TransactionCompletedDate,
          UpwardLeagueID: x.UpwardLeagueID,
          total: 0,
          bg: 0,
        })
      })

      let bg = 0
      rv.forEach((x) => {
        x.EftAmount = x.detail.reduce((a, y) => a + (y.AmountExpectedToPay || 0), 0)
        x.detail = cloneDeep(
          x.detail.sort(
            (a, b) =>
              dayjs(a?.TransactionCompletedDate ?? undefined).valueOf() -
              dayjs(b?.TransactionCompletedDate ?? undefined).valueOf()
          )
        )
        x.detail.forEach((x, i, a) => {
          x.bg = bg

          // clear out some of the columns that are duplicates
          if (a[i - 1] && a[i - 1].lastConfirmationNumber == x.lastConfirmationNumber) {
            x.ConfirmationNumber = '➡'
            x.TransactionCompletedDate = null
            x.UpwardLeagueID = ''
          }

          // change the background color based on alternating confirmation numbers
          if (!a[i + 1] || x.lastConfirmationNumber != a[i + 1].lastConfirmationNumber) {
            bg = (bg + 1) % 2
            x.total = a
              .filter((y) => y.lastConfirmationNumber == x.lastConfirmationNumber)
              .reduce((a, b) => a + (b?.AmountExpectedToPay || 0), 0)
          }
        })
      })

      return rv
    })

    function showAll() {
      if (showing.value.length == reportNormalized.value.length) {
        showing.value = []
      } else {
        showing.value = reportNormalized.value.map((x) => x.EftNumber)
      }
    }

    function showDetails(s: string) {
      const i = showing.value.indexOf(s)
      if (i >= 0) {
        showing.value.splice(i, 1)
      } else {
        showing.value.push(s)
      }
    }

    function cash(n: number) {
      return `$ ${n.toFixed(2)}`
    }

    function formatDate(d: Date | null) {
      if (d) {
        return dayjs(d).format('MMM DD YYYY hh:mm A')
      } else {
        return ''
      }
    }

    function downloadCsv() {
      const headerTitles = [
        'Confirmation Number',
        'Transaction Date/Time',
        'Upward League ID',
        'Description',
        'Subtotal',
      ]

      const csvLines = ['data:text/csv;charset=utf-8,', headerTitles.map((t) => `"${t}"`).join(',')]

      props.reportData.map((r) => {
        const rowValues = [
          r.ConfirmationNumber,
          r.TransactionCompletedDate,
          r.UpwardLeagueID,
          `${r.FirstName} ${r.LastName}`,
          r.AmountExpectedToPay,
        ]

        csvLines.push(rowValues.map((t) => `"${t}"`).join(','))
      })

      const csvData = csvLines.join('\n')
      var encodedUri = encodeURI(csvData)
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'eft_report.csv')
      document.body.appendChild(link) // Required for FF

      link.click()
    }

    return { date, cash, reportNormalized, showDetails, showAll, showing, formatDate, downloadCsv }
  },
})
