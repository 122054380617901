export enum ProductCategoryEnum {
  AUTOINCLUDE = 1,
  OPTIONAL_APPAREL = 2,
  PLAYER_ITEMS = 3,
  PLAYER_RECOGNITION = 4,
  AWARDS = 5,
  PLAYER_BAG = 6,
  COACH_APPAREL = 7,
  PREMIUM_SERVICES = 8,
  UPWARD_FEES = 9,
  UPWARD_DISCOUNT = 10,
}
