import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { sortGrades } from '@/services/gradeService'
import { TeamOrderType } from '@/views/Programs/Divisions/TeamManagement/types/TeamOrderType'

const sortName = (a: TeamPlayerInfo, b: TeamPlayerInfo, isDesc: boolean): number => {
  const r = a?.formattedNameLastFirst?.localeCompare(b?.formattedNameLastFirst ?? '') ?? 0
  return isDesc ? r * -1 : r
}
const sortFirstName = (a: TeamPlayerInfo, b: TeamPlayerInfo, isDesc: boolean): number => {
  const r = a?.formattedNameFirstLast?.localeCompare(b?.formattedNameFirstLast ?? '') ?? 0
  return isDesc ? r * -1 : r
}

const sortScore = (a: TeamPlayerInfo, b: TeamPlayerInfo, isDesc: boolean): number => {
  const r = a.evaluationScore - b.evaluationScore
  return isDesc ? r * -1 : r
}

const sortByGrades = (a: TeamPlayerInfo, b: TeamPlayerInfo, isDesc: boolean): number => {
  if (!a.grade || !b.grade) return 0
  const r = sortGrades(a.grade, b.grade)
  return isDesc ? r * -1 : r
}

const sortTeamName = (a: TeamOrderType, b: TeamOrderType, isDesc: boolean): number => {
  const r = (a.team.teamName ?? '').localeCompare(b.team.teamName ?? '') ?? 0
  return isDesc ? r * -1 : r
}

const sortTeamAvgEval = (a: TeamOrderType, b: TeamOrderType, isDesc: boolean): number => {
  const r = a.teamAvgEvalScore - b.teamAvgEvalScore
  return isDesc ? r * -1 : r
}

const sortExperience = (a: TeamOrderType, b: TeamOrderType, isDesc: boolean): number => {
  const r = a.teamAvgYearsExperience - b.teamAvgYearsExperience
  return isDesc ? r * -1 : r
}

export const sortFunctions: { [key: string]: any } = {
  name_asc: sortName,
  name_desc: sortName,
  firstname_asc: sortFirstName,
  firstname_desc: sortFirstName,
  score_asc: sortScore,
  score_desc: sortScore,
  grade_asc: sortByGrades,
  grade_desc: sortByGrades,
  team_name_asc: sortTeamName,
  team_name_desc: sortTeamName,
  avg_eval_asc: sortTeamAvgEval,
  avg_eval_desc: sortTeamAvgEval,
  avg_years_asc: sortExperience,
  avg_years_desc: sortExperience,
}
