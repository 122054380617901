















































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import { useConnectSetup } from '@/composables/ConnectSetup'

import { ContactToContactInfo, ContactInfoToContact, GetEmptyContactInfo } from '@/services/contactService'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'

import ContactPickerAndEditor from '@/components/ContactPickerAndEditor.vue'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'
import { cloneDeep } from 'lodash'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import SetupCompleteConfirmation from '@/views/Connects/vues/SetupCompleteConfirmation.vue'
import store from '@/store'

export default defineComponent({
  name: 'Leadership',
  components: {
    ContactPickerAndEditor,
    SaveContinueButton,
    SetupCompleteConfirmation,
  },
  setup(props, ctx) {
    const {
      connect,
      storeConnect,
      loading,
      saveAndContinue,
      beforeRouteLeave,
      afterSaveNew,
      afterUpdateExisting,
      initialLoadDone,
      save,
    } = useConnectSetup(ctx)

    const accountNumbers = computed(() => store.getters.authorization.accountNumbers)
    const sportContact = ref<ContactInfo>(GetEmptyContactInfo())
    const newId = ref('')
    const modalIsVisible = ref(false)

    onMounted(() => {
      afterSaveNew.value = afterSaveNewMethod
      afterUpdateExisting.value = afterUpdateExistingMethod
    })

    watch(
      () => initialLoadDone.value,
      () => {
        if (initialLoadDone.value && connect.value) {
          sportContact.value = ContactToContactInfo(
            connect.value.partnerContact!,
            connect.value.leagueDirectorPartnerID
          )
        }
      },
      { immediate: true }
    )

    const canChangeName = computed(() => !(connect.value && connect.value!.leagueDirectorPartnerID))

    async function onSubmit() {
      connect.value.partnerContact = sportContact.value
        ? ContactInfoToContact(sportContact.value)
        : getEmptyContact()
      connect.value.leagueDirectorPartnerID = sportContact.value ? sportContact.value.id : 0

      afterSaveNew.value = null

      await save()
      if (connect.value.partnerContact) {
        sportContact.value = ContactToContactInfo(
          connect.value!.partnerContact!,
          connect.value!.leagueDirectorPartnerID
        )
      }
      afterSaveNew.value = afterSaveNewMethod
    }

    const isClean = computed(
      () => storeConnect.value.leagueDirectorPartnerID === connect.value.leagueDirectorPartnerID
    )

    function afterUpdateExistingMethod() {
      connect.value = { ...cloneDeep(storeConnect.value) }
      ctx.root.$router.push(`/programs/connect/${connect.value.upwardLeagueID}/setup/information`)
    }

    async function onModalStateChange() {
      await ctx.root.$router.push('/')
    }

    async function afterSaveNewMethod() {
      const result = await store.dispatch.connects.create({ item: connect.value })

      if (result) {
        connect.value = { ...result }
        newId.value = connect.value.upwardLeagueID ?? ''
      }
      modalIsVisible.value = true
    }

    return {
      saveAndContinue,
      connect,
      loading,
      initialLoadDone,
      beforeRouteLeave,
      accountNumbers,
      sportContact,
      onSubmit,
      canChangeName,
      isClean,
      modalIsVisible,
      onModalStateChange,
    }
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeave(to, from, next)
  },
})
