

















import { defineComponent } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'FacilityTimeWarningModal',
  components: {
    Modal,
  },
  props: {
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)

    function cancel() {
      closeModal()
    }

    function confirmed() {
      ctx.emit('confirmed')
      closeModal()
    }

    return {
      isModalOpen,
      confirmed,
      cancel,
    }
  },
})
