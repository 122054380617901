







import { defineComponent, ref, watch } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  name: 'ActionsProgress',
  components: { Loading },
  props: {
    message: { type: String, required: true },
    spinner: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const progress = ref('')
    watch(
      () => props.message,
      () => (progress.value = props.message)
    )
    function close() {
      ctx.emit('close')
    }
    return { progress, close }
  },
})
