import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { UpwardTimezoneTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardTimezoneTypeID'
import upwardTypesClient from '@/clients/upwardTypesClient'
import { ActionsImpl } from 'direct-vuex'

interface TimezoneState {
  items: UpwardTimezoneTypeID[]
}

const timezoneState: TimezoneState = {
  items: [],
}

export enum getterNames {
  allItems = 'allItems',
}

const getterTree: GetterTree<TimezoneState, RootState> = {
  [getterNames.allItems]: (state) => state.items,
}

export enum mutationNames {
  setAll = 'setAll',
}

const mutations: MutationTree<TimezoneState> = {
  [mutationNames.setAll](state, { items }) {
    state.items = items
  },
}

export enum actionNames {
  fetchAll = 'fetchAll',
}

const actions: ActionTree<TimezoneState, RootState> & ActionsImpl = {
  async [actionNames.fetchAll]({ commit, getters }, { force = false }): Promise<boolean> {
    if (getters[getterNames.allItems].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrieveTimezoneTypes()

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setAll, { items })
      return true
    }

    return false
  },
}

export const namespace = 'timezoneTypes'

export const timezoneTypes = {
  namespaced: true as true,
  state: timezoneState,
  getters: getterTree,
  actions,
  mutations,
}
