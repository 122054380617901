





































import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import TeamCard from '@/views/Programs/Divisions/TeamManagement/vues/TeamCard.vue'
import { cloneDeep, difference } from 'lodash'

import { Container, ContainerProps, Draggable, DropResult } from 'vue-smooth-dnd'
import { defineComponent, PropType, ref, watch, computed } from '@vue/composition-api'
import { useManagerLogic } from '@/views/Programs/Divisions/TeamManagement/logic/ManagerLogic'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'
import { getEmptyDivisionTeamInfo } from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import { UpwardTapeDownReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardTapeDownReasonTypeID'
import { sortFunctions } from '@/views/Programs/Divisions/TeamManagement/logic/teamSort'
import { TeamOrderType } from '@/views/Programs/Divisions/TeamManagement/types/TeamOrderType'
import {
  getTotalTeamEvalScore,
  getTotalYearsExperience,
} from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import ModalConfirmation from '@/components/ModalConfirmation.vue'

export default defineComponent({
  name: 'TeamCardField',

  components: { Draggable, Container, TeamCard, ModalConfirmation },
  props: {
    teams: { required: true, type: Array as PropType<DivisionTeamInfo[]> },
    vertical: { required: false, default: true, type: Boolean },
    tapeDownReasons: {
      required: true,
      type: Array as PropType<UpwardTapeDownReasonTypeID[]>,
      default: () => [],
    },
    selectedCoach: { required: false, default: null, type: Object as PropType<LeagueCoachProgramInfoFLAT> },
    selectedPlayer: { required: false, default: null, type: Object as PropType<LeaguePlayerInfo> },
    approvedCoaches: {
      required: false,
      type: Array as PropType<LeagueCoachProgramInfoFLAT[]>,
    },
  },
  setup(props, ctx) {
    const userPrefs = userPreferences()
    let teamOrder = ref<TeamOrderType[]>([])
    const manager = useManagerLogic()
    const innerCoaches = ref<LeagueCoachProgramInfoFLAT[]>(
      props.approvedCoaches ?? ([] as LeagueCoachProgramInfoFLAT[])
    )
    /**
     * Pulls the payload out of the container based on the index
     */
    function getPayload(index: number) {
      return teamOrder.value.find((x) => x.order == index)
    }

    const gridClass = computed(() => {
      if (props.vertical) return ''

      let selectedColumns = userPrefs.getTeamColumns.value.filter((c) => c.value)
      if (!userPrefs.usingHeight.value) selectedColumns = selectedColumns.filter((c) => c.label != 'height')
      if (!userPrefs.usingPosition.value)
        selectedColumns = selectedColumns.filter((c) => c.label != 'position')
      if (!userPrefs.usingScore.value) selectedColumns = selectedColumns.filter((c) => c.label != 'score')

      const showPosition = selectedColumns.find((c) => c.label == 'position')?.value ?? false

      return selectedColumns.length > 3 && showPosition
        ? 'grid-wide-position'
        : selectedColumns.length > 3
        ? 'grid-wide'
        : 'grid'
    })

    /**
     * Lets us know if the payload will be accepted
     */
    function accepting(a: ContainerProps, info: Record<string, unknown>) {
      if (info && info.team) {
        return true
      }
    }

    const sorter = computed(() => {
      return (a: TeamOrderType, b: TeamOrderType) => {
        const fnc = sortFunctions[userPrefs.getTeamListSort.value]
        const isDesc = userPrefs.getTeamListSort.value.indexOf('_desc') > 0
        return fnc(a, b, isDesc)
      }
    })

    const toggleTeam = (teamID: number) => ctx.emit('toggle-team', teamID)
    const ordersort = (a: TeamOrderType, b: TeamOrderType) =>
      a.order == b.order ? 0 : a.order > b.order ? 1 : -1
    /**
     * action for when something is dropped on the field, in this case for reorder.
     */
    function onDrop(movedEvent: DropResult) {
      const addedTeam = (movedEvent.payload as unknown) as TeamOrderType

      //sanity check here.
      if (addedTeam.team && movedEvent.addedIndex != null && movedEvent.addedIndex >= 0) {
        // thing moved card drop, or nothing if external drop
        let displacedTeam = getPayload(movedEvent.removedIndex)

        // remove the displaced team if it exists.
        if (displacedTeam) {
          teamOrder.value = teamOrder.value.filter((x) => x.order != displacedTeam!.order)
        }
        //remap order based on index added
        teamOrder.value = teamOrder.value.map((x) => ({
          ...x,
          order: x.order >= movedEvent.addedIndex ? x.order + 1 : x.order,
        }))
        // now have room to add in the new team with index
        teamOrder.value.push({
          order: movedEvent.addedIndex,
          teamAvgEvalScore: addedTeam.teamAvgEvalScore,
          teamAvgYearsExperience: addedTeam.teamAvgYearsExperience,
          team: addedTeam.team,
        })
        //reset order.
        let order = 0
        teamOrder.value = teamOrder.value.sort(ordersort).map((x) => ({
          order: order++,
          team: x.team,
          teamAvgEvalScore: x.teamAvgEvalScore,
          teamAvgYearsExperience: x.teamAvgYearsExperience,
        }))
      }
      //set a list of teams currently on the field, syncs checkboxes on the list.
      manager.setTeamsOnField(teamOrder.value.map((x) => x.team.teamID))
    }
    watch(
      () => props.teams,
      () => {
        //teamOrder is pre-sorted

        const hasTeams = teamOrder.value.map((x) => x.team.teamID) // our current team ids
        const newTeams = props.teams.map((x) => x.teamID)
        const addTeams = difference(newTeams, hasTeams)
        const removeTeams = difference(hasTeams, newTeams)

        let order = 0
        let teamsCopy = cloneDeep(teamOrder.value)
        let propCopy = cloneDeep(props.teams)

        teamOrder.value = [
          ...teamsCopy
            .filter((x) => removeTeams.indexOf(x.team.teamID) < 0)
            .map((x) => ({
              order: x.order + addTeams.length,
              teamAvgEvalScore: 0,
              teamAvgYearsExperience: 0,
              // clone the team passed in teams will change and get passed in updated but order should not change
              team: cloneDeep(propCopy.find((y) => y.teamID == x.team.teamID) ?? getEmptyDivisionTeamInfo()),
            })),
          ...propCopy
            .filter((x) => addTeams.indexOf(x.teamID) >= 0)
            .map((x) => ({
              order: order++,
              team: x,
              teamAvgEvalScore: getTotalTeamEvalScore(x) / (x.players ?? []).length,
              teamAvgYearsExperience: getTotalYearsExperience(x) / (x.players ?? []).length,
            })),
        ].sort(sorter.value)
      },
      { immediate: true }
    )

    watch(
      () => userPrefs.getTeamListSort.value,
      () => {
        teamOrder.value.sort(sorter.value)
      },
      { immediate: true }
    )
    watch(
      () => props.approvedCoaches,
      () => {
        innerCoaches.value = props.approvedCoaches ?? ([] as LeagueCoachProgramInfoFLAT[])
      },
      { immediate: true, deep: true }
    )
    const getApprovedCoach = (coachID: number): LeagueCoachProgramInfoFLAT | null => {
      return innerCoaches.value.find((c) => c.individualID == coachID) ?? null
    }
    const addLinkedCoachPrompt = (
      coach: LeagueCoachProgramInfoFLAT
    ): Promise<LeagueCoachProgramInfoFLAT | null> => {
      const shouldPrompt = coach.coachLinkID > 0
      if (!shouldPrompt) return Promise.resolve(null)
      const approved = getApprovedCoach(coach.coachLinkID)
      const message = approved
        ? `Would you also like to add ${coach.coachLinkFirstName} ${coach.coachLinkLastName} to this team as a linked coach?`
        : `${coach.coachLinkFirstName} ${coach.coachLinkLastName} is a linked coach but is not approved. If you want to add ${coach.coachLinkFirstName} ${coach.coachLinkLastName} as a coach for any team, you must approve them first.`
      const confirm = ctx.refs.addLinkedCoachConfirm as any
      return confirm
        .show(message, {
          title: 'Add Linked Coach',
          okLabel: !approved ? 'Ok' : 'Yes',
          cancelLabel: !approved ? 'Cancel' : 'No',
          showCancel: !!approved,
          isSmall: true,
        })
        .then((yes: boolean) => {
          return yes ? approved : null
        })
    }
    async function addCoach(teamID: number, coach: LeagueCoachProgramInfoFLAT) {
      await manager
        .addCoachToTeam(teamID, coach)
        .then(() => {
          return addLinkedCoachPrompt(coach)
        })
        .then((linked) => {
          return !linked ? Promise.resolve() : manager.addCoachToTeam(teamID, linked)
        })
      ctx.emit('add-coach', teamID, coach)
    }

    function addParticipant(teamID: number, player: LeaguePlayerInfo) {
      manager.addParticipantToTeam(teamID, player)
      ctx.emit('add-player', teamID, player)
    }

    return {
      teamOrder,
      getPayload,
      accepting,
      onDrop,
      gridClass,
      toggleTeam,
      togglePlayerLock: manager.togglePlayerLock,
      togglePlayerPosition: manager.togglePlayerPosition,
      changeHeadCoach: manager.changeHeadCoach,
      addCoach,
      removeCoach: manager.removeCoach,
      addParticipant,
      removeParticipant: manager.removeParticipant,
    }
  },
})
