

























import { defineComponent, ref, watch, onMounted, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { xShipMethodBase } from '@/GeneratedTypes/xOrder/xShipMethodBase.ts'
import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'
import { getEmptyXShipMethodBaseArray } from '@/lib/support/models/GeneratedTypes/xOrders/xShippingMethodBase.ts'
import currency from '@/filters/currency'

export default defineComponent({
  name: 'ShippingMethodSelect',
  components: {
    BaseInputWrapper,
  },
  filters: {
    currency,
  },
  props: {
    value: {
      type: Array as PropType<xShipMethodBase[]>,
      default: getEmptyXShipMethodBaseArray,
      required: false,
    },
  },
  setup(props, ctx) {
    const selected = ref('')

    onMounted(() => {
      selected.value = getSelected()
    })

    function getSelected() {
      const selMethod = props.value.find((method) => {
        return method.selected === true
      })
      if (selMethod) {
        return selMethod.id
      } else {
        return ''
      }
    }

    watch(
      () => props.value,
      () => {
        selected.value = getSelected()
      },
      { deep: true }
    )

    watch(
      () => selected.value,
      () => {
        const methods = cloneDeep(props.value)
        methods.forEach((method) => {
          method.selected = false
          if (method.id === selected.value) method.selected = true
        })
        ctx.emit('input', methods)
      }
    )

    return {
      selected,
    }
  },
})
