










































/***
 * @vue-event {null} onDeleteConfirm - confirm delete
 * @vue-event {null} onDeleteDismissed - delete closed
 */

import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import { DeleteIcon } from '@/elements/Icons'

export default defineComponent({
  name: 'ConfirmDeleteModal',
  components: {
    Modal,
    TextInput,
    DeleteIcon,
  },
  props: {
    value: { type: Boolean, required: false, default: false },
    title: { type: String, default: '' },
    hasConfirmBox: { type: Boolean, default: true },
  },
  setup(props, ctx) {
    const internalModalIsVisible = ref(false)
    const confirmation = ref('')

    /***
     * Do we have everything we need for a user to hit the Delete button?
     */
    const areWeReadyToConfirm = computed(() => !confirmText.value && props.hasConfirmBox)

    const confirmText = computed(() => confirmation.value.toUpperCase() === 'CONFIRM')

    onMounted(() => {
      internalModalIsVisible.value = props.value
    })

    function confirmed() {
      ctx.emit('onDeleteConfirmed')
      toggleModal()
    }

    function cancel() {
      ctx.emit('onDeleteDismissed')
      toggleModal()
    }

    function toggleModal() {
      confirmation.value = ''
      internalModalIsVisible.value = !internalModalIsVisible.value
    }

    function modalClose() {
      confirmation.value = ''
      ctx.emit('onDeleteDismissed')
    }

    watch(
      () => internalModalIsVisible.value,
      () => {
        ctx.emit('input', internalModalIsVisible.value)
        ctx.emit('change', internalModalIsVisible.value)
      }
    )

    watch(
      () => props.value,
      () => {
        if (internalModalIsVisible.value !== props.value) {
          internalModalIsVisible.value = props.value
        }
      }
    )

    return {
      internalModalIsVisible,
      modalClose,
      confirmation,
      cancel,
      areWeReadyToConfirm,
      confirmed,
    }
  },
})
