import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardDivisionGameTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardDivisionGameTypeID'
import { ActionsImpl } from 'direct-vuex'

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardDivisionGameTypeID>(
  upwardTypesClient.retrievePartnerPhoneTypes
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardDivisionGameTypeID> = {
  items: [],
}

const getterTree: GetterTree<genericUpwardTypes.GenericTypeState<UpwardDivisionGameTypeID>, RootState> = {
  ...generic.getterTree,
}

const mutations: MutationTree<genericUpwardTypes.GenericTypeState<UpwardDivisionGameTypeID>> = {
  ...generic.mutations,
}

const actions: ActionTree<genericUpwardTypes.GenericTypeState<UpwardDivisionGameTypeID>, RootState> &
  ActionsImpl = {
  ...generic.actions,
}

export const getterNames = {
  ...genericUpwardTypes.getterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

export const namespace = 'divisionGameTypes'

export const divisionGameTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
