/**
 * Rest Operations primarily for CRUD on LeaguePlayerInfo
 */

import restService, { RestServiceResult } from '@/services/restService'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import { PlayerSearchInfo } from '@/GeneratedTypes/ListInfo/PlayerSearchInfo'
import { PlayerSearchArgs } from '@/GeneratedTypes/PlayerSearchArgs'
import dayjs from 'dayjs'
import { AxiosRequestConfig } from 'axios'
import { IsNewPlayer, PlayerId } from '@/lib/support/models/LeaguePlayer/data'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { CombineDataArray } from '@/lib/support/models/RestServiceResult/CombineDataArray'
import { PlayerConflictInfo } from '@/GeneratedTypes/ListInfo/PlayerConflictInfo'

const baseUrl = 'participants/'
function ourURL(leagueID: string) {
  return `${baseUrl}${leagueID}`
}

interface ParticipantsSearchFnArgs {
  orSearch?: boolean
  leagueID: UpwardLeagueIDType
  first?: string
  last?: string
  email?: string
  phone?: string
  includePastLeagues?: boolean
  historyLimit?: null | Date
  guardianFullName?: string
}

class ParticipantClientException extends GeneralError {
  name = 'Participant API Exception'
}

export default class participantsClient {
  /***
   * Run the validation routine
   * @param leagueID
   * @param participant
   * @param imported if this player was imported
   * @throws Error object -- has validation errors.
   */
  static async validate(leagueID: UpwardLeagueIDType, participant: LeaguePlayer, matched = false) {
    let idpart = '/' // if new this isn't set.
    if (!IsNewPlayer(participant) && !matched) {
      idpart = '/' + PlayerId(participant) + '/'
    }
    const x = await restService.post<LeaguePlayer>(`${ourURL(leagueID)}${idpart}validate`, participant)
    if (x.isSuccess) {
      return true
    }
    throw x.errorObject // this makes the exceptions show on the big red box.
  }
  /***
   * Run the save routine
   * @param leagueID
   * @param participant
   * @param matched - when a player was being added using 'Add New', did the automatic search find a match on lname/fname
   * @throws Error object -- has validation errors.
   */
  static async save(
    leagueID: UpwardLeagueIDType,
    participant: LeaguePlayer,
    matched = false,
    xlsImport = false
  ) {
    const isNewPlayer = IsNewPlayer(participant)
    let x: RestServiceResult<LeaguePlayer> | null = null

    // participant import requires special header
    const header: AxiosRequestConfig = { headers: { AutoMapPendingLinks: true } }
    const config = xlsImport ? header : undefined

    if (isNewPlayer || matched) {
      x = await restService.post<LeaguePlayer>(`${ourURL(leagueID)}`, participant, config)
    } else {
      const idpart = '/' + PlayerId(participant) + '/'
      x = await restService.put<LeaguePlayer>(`${ourURL(leagueID)}${idpart}`, participant, config)
    }

    if (!x.isSuccess) {
      throw new ParticipantClientException('Problem with participant save. ', x.errorObject)
    }

    return x.data
  }

  static async remove(
    leagueID: UpwardLeagueIDType,

    participantId: PlayerInfoIDType
  ) {
    const x = await restService.delete<LeaguePlayerInfo>(`${ourURL(leagueID)}/${participantId}`)
    if (!x.isSuccess) {
      throw new ParticipantClientException('Server could not remove ' + participantId)
    }
    return x.data
  }

  /**
   * Returns participants based on a LeagueID or LeagueID and ProgramType
   * @param leagueID UPW### league
   * @param typeProgramId - basketball etc. comes from leauge programs idtype -- optional
   * @return array of league players from the league id
   */
  static async retrieve(
    leagueID: UpwardLeagueIDType,
    typeProgramId?: string
  ): Promise<LeaguePlayerInfo[] | null> {
    const x = await restService.get<LeaguePlayerInfo[]>(
      `${ourURL(leagueID)}/${typeProgramId ? 'filterByProgram/' + typeProgramId : ''}`
    )
    if (x.isSuccess) {
      return x.data
    }
    throw new ParticipantClientException('Problem with retrieval of the participants.')
  }

  /**
   * Returns participants based on a LeagueID or LeagueID and ProgramType
   * @param leagueID UPW### league
   */
  static async retrieveExt(leagueID: string): Promise<LeaguePlayerInfo[] | null> {
    const x = await restService.get<LeaguePlayerInfo[]>(`${ourURL(leagueID)}/udf`)
    if (x.isSuccess) {
      return x.data
    }
    throw new ParticipantClientException('Problem with retrieval of extended participants.')
  }

  /***
   * Search former participants.
   * @param leagueID {string} - league to search
   * @param first {string} - first or general search (for an or search)
   * @param last {string} - last name or general search
   * @param email {string} - email or general search (for an or search)
   * @param phone {string} - phone number or general search
   * @param orSearch {boolean} - and/or search boolean flag.
   */
  static search({
    leagueID,
    first,
    last,
    email,
    phone,
    orSearch,
    includePastLeagues,
    historyLimit,
    guardianFullName,
  }: ParticipantsSearchFnArgs): Promise<PlayerSearchInfo[]> {
    const params: PlayerSearchArgs = {
      currentLeagueID: 0,
      currentUpwardLeagueID: leagueID,
      firstName: first ?? null,
      lastName: last ?? null,
      email: email ?? null,
      phone: phone ?? null,
      historyLimit: typeof historyLimit === 'undefined' ? dayjs().subtract(3, 'year').toDate() : historyLimit,
      includeCurrentLeague: true,
      includePastLeagues: includePastLeagues ?? true,
      useStartsWith: true,
      accountNumber: null,
      guardianFullName: guardianFullName ?? null,
    }
    const otherParams: PlayerSearchArgs = { ...params }
    const endpointURI = `participants/search`
    let results: Promise<PlayerSearchInfo[]> = new Promise((resolve) => resolve([]))
    // in an or search take the first name and do a last or first search.
    if (orSearch) {
      params.lastName = null
      otherParams.firstName = null
      otherParams.lastName = params.firstName

      results = new Promise((resolve, reject) => {
        Promise.all<RestServiceResult<PlayerSearchInfo[]>, RestServiceResult<PlayerSearchInfo[]>>([
          restService.post<PlayerSearchInfo[]>(endpointURI, params),
          restService.post<PlayerSearchInfo[]>(endpointURI, otherParams),
        ])
          .then((values) => {
            const rv = CombineDataArray<PlayerSearchInfo>(values)

            resolve(rv)
          })
          .catch((res) => reject(res))
      })
    } else {
      results = new Promise((resolve, reject) => {
        restService
          .post<PlayerSearchInfo[]>(endpointURI, params)
          .then((x) => {
            if (!x.isSuccess) {
              throw new ParticipantClientException('Problem with the retrieval of search information')
            }
            if (x.data) {
              resolve(x.data)
            } else {
              reject(x.errorObject)
            }
          })
          .catch((res) => reject(res))
      })
    }

    return results
  }

  /**
   * Returns a template, null or empty participant ID will be a new template, otherwise gets the template
   * corresponding to the participant passed, expect null on error, exception on error.
   * @param leagueID
   * @param participantID
   * @param importLeagueID - if you are loading a template from a player outside the league, use the league selected
   * @return Promise<LeaguePlayer|null>
   */
  static async retrieveTemplate(
    leagueID: UpwardLeagueIDType,
    participantID?: PlayerInfoIDType,
    importLeagueID?: UpwardLeagueIDType
  ): Promise<LeaguePlayer | null> {
    const suffix = participantID ? `/${participantID}` : ''
    const x = await restService.get<LeaguePlayer>(
      `${ourURL(leagueID)}/new${suffix}`,
      {},
      importLeagueID
        ? {
            headers: {
              CopyContactsFromUPW: importLeagueID,
            },
          }
        : undefined
    )
    if (!x.isSuccess) {
      throw new ParticipantClientException('Problem with the server retrieval of template')
    }
    return x.data
  }

  static async retrieveParticipant(
    leagueID: UpwardLeagueIDType,
    participantID: PlayerInfoIDType
  ): Promise<LeaguePlayer | null> {
    const suffix = participantID ? `/${participantID}` : ''
    const x = await restService.get<LeaguePlayer>(`${ourURL(leagueID)}${suffix}`)
    if (!x.isSuccess) {
      throw new ParticipantClientException('Problem with the server retrieval of participant ')
    }
    return x.data
  }

  static async retrieveParticipantInfo(
    leagueID: UpwardLeagueIDType,
    participantID: PlayerInfoIDType
  ): Promise<LeaguePlayerInfo | null> {
    const suffix = participantID ? `/${participantID}/info` : ''
    const x = await restService.get<LeaguePlayerInfo>(`${ourURL(leagueID)}${suffix}`)
    if (!x.isSuccess) {
      throw new ParticipantClientException('Problem with the server retrieval of participant ')
    }
    return x.data
  }

  static async retrieveUnassigned(
    leagueID: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number
  ): Promise<LeaguePlayerInfo[] | null> {
    const x = await restService.get<LeaguePlayerInfo[]>(
      `${ourURL(leagueID)}/unassignedByDivision/${typeProgramId}/${divisionId}`
    )
    if (x.isSuccess) {
      return x.data
    }
    throw new ParticipantClientException('Problem with retrieval of the participants.')
  }

  static async retrieveSiblingLinks(
    leagueID: UpwardLeagueIDType,
    participantID: PlayerInfoIDType
  ): Promise<LeaguePlayerInfo[] | null> {
    const x = await restService.get<LeaguePlayerInfo[]>(`${ourURL(leagueID)}/${participantID}/siblingLinks`)
    if (!x.isSuccess) {
      throw new ParticipantClientException('Problem with the server retrieval of participant sibling links ')
    }
    return x.data
  }

  static async retrievePlayerConflicts(
    leagueID: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number
  ): Promise<PlayerConflictInfo[] | null> {
    if (leagueID) {
      let pcURL = `${ourURL(leagueID)}/playerConflicts`
      if (typeProgramId !== '') {
        pcURL = pcURL.concat(`/${typeProgramId}`)
        if (divisionId > 0) {
          pcURL = pcURL.concat(`/${divisionId}`)
        }
      }

      const x = await restService.get<PlayerConflictInfo[]>(pcURL)
      if (x.isSuccess) {
        return x.data
      }
      throw new ParticipantClientException('Problem with retrieval of the player conflicts.')
    } else {
      return []
    }
  }
}
