














import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { EvaluationFormOptions } from '@/components/EvaluationForm/data'

export enum EFTypes {
  'LEAGUE' = 'league',
  'CAMP' = 'camp',
}

export default defineComponent({
  name: 'Setup',
  components: {
    HtmlEditor,
    InputLabel,
  },
  props: {
    type: { type: String as PropType<EFTypes>, required: false, default: EFTypes.LEAGUE },
    value: { type: String, required: true },
  },
  setup(props, ctx) {
    const evaluationDescription = ref(props.value ?? '')
    const options = ref(EvaluationFormOptions.get(props.type))

    watch(
      () => evaluationDescription.value,
      () => {
        ctx.emit('input', evaluationDescription.value)
        ctx.emit('change', evaluationDescription.value)
      }
    )

    watch(
      () => props.value,
      () => {
        if (props.value) {
          if (evaluationDescription.value !== props.value) {
            evaluationDescription.value = props.value
          }
        } else {
          evaluationDescription.value = ''
        }
      }
    )

    return {
      options,
      evaluationDescription,
    }
  },
})
