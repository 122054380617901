
































































import { defineComponent, ref, computed, PropType } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import { Resource } from '@/models/Program/Resource'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import ResourceTile from './ResourceTile.vue'
import ToggleSwitchInput from '@/elements/ToggleSwitchInput.vue'
import resourceService, { ResourceFileTypeCategory } from '@/services/resourceService'
import DropDownMultiSelect from '@/components/DropDownMultiSelect.vue'
import { MultiSelectValue } from '@/lib/support/components/MultiSelect/MultiSelectValue'

export default defineComponent({
  name: 'ResourceList',
  components: {
    VerticalTabs,
    Loading,
    TextInput,
    SelectInput,
    ResourceTile,
    ToggleSwitchInput,
    DropDownMultiSelect,
  },
  props: {
    resources: { type: Array as PropType<Resource[]>, required: true },
    leagueListItem: { type: Object as PropType<LeagueListItem>, required: false, default: null },
  },
  setup(props) {
    const loading = ref(false)
    const searchKeyword = ref('')

    /** A human friendly sentence about the current search filters */
    const searchDesciption = computed(() => {
      if (!searchKeyword.value) return `Showing ${displayedResources.value.length} resources.`
      return `Showing ${displayedResources.value.length} resources matching the term "${searchKeyword.value}".`
    })

    const taxonomies = computed(() => {
      const retval: { [key: string]: Resource[] } = {}
      for (const resource of props.resources) {
        if (resource.leagueDirectoryTaxonomy && Array.isArray(resource.leagueDirectoryTaxonomy)) {
          for (const taxonomyTitle of resource.leagueDirectoryTaxonomy) {
            if (!retval[taxonomyTitle]) {
              retval[taxonomyTitle] = []
            }

            retval[taxonomyTitle].push(resource)
          }
        }
      }
      return retval
    })

    const taxonomyTitles = computed(() => Object.keys(taxonomies.value).sort())

    const taxonomyFilterOptions = computed(() => [...taxonomyTitles.value])

    const selectedCategories = ref<string[]>([])

    function toggleCategory(category: string) {
      if (!selectedCategories.value.includes(category)) {
        selectedCategories.value = [...selectedCategories.value, category]
      } else {
        selectedCategories.value = selectedCategories.value.filter((c) => c !== category)
      }
    }

    function categorySelected(category: string) {
      return selectedCategories.value.includes(category)
    }

    function categoryCount(category: string) {
      return taxonomies.value[category]?.length ?? 0
    }

    const selectedResourceTypes = ref<MultiSelectValue[]>([])
    /** This should return the resources that are display on the page.
     * Resources are displayed if the filters are such that the resource matches.
     * If no filter controls are shown, then all resources are shown.
     */
    const displayedResources = computed(() => {
      const filter = (r: Resource): boolean => {
        const resourceCategories = Array.isArray(r.leagueDirectoryTaxonomy)
          ? r.leagueDirectoryTaxonomy
          : ([] as string[])
        return (
          selectedCategories.value.length === 0 ||
          selectedCategories.value.some((c) => resourceCategories.includes(c))
        )
      }
      let myResources = props.resources.filter(filter)

      if (searchKeyword.value) {
        myResources = myResources.filter(
          (r) =>
            r.title.toLowerCase().includes(searchKeyword.value.toLowerCase()) ||
            (r.shortDescriptionContent || '').toLowerCase().includes(searchKeyword.value.toLowerCase())
        )
      }

      if (selectedResourceTypes.value.length > 0) {
        myResources = myResources.filter((r) => {
          const resourceType = resourceService.getResourceFileType(r.resourceUrl)
          return (
            selectedResourceTypes.value.filter((t) => {
              return t.id === resourceService.getResourceFileTypeCategory(resourceType)
            }).length > 0
          )
        })
      }
      myResources.sort((a, b) => a.title.localeCompare(b.title))

      // Get the unique resource
      return myResources.filter((e, i) => myResources.findIndex((a) => a.resourceID === e.resourceID) === i)
    })

    const resourceTypes = computed(() =>
      [
        ...new Set(
          props.resources.map((r) => {
            const t = resourceService.getResourceFileType(r.resourceUrl)
            const word = resourceService.getResourceFileTypeCategory(t)
            return word
          })
        ),
      ]
        .sort()
        .map((w: ResourceFileTypeCategory) => {
          const cat = { id: w }
          switch (w) {
            case ResourceFileTypeCategory.archive:
              return { ...cat, description: 'Archives' }
            case ResourceFileTypeCategory.image:
              return { ...cat, description: 'Images' }
            case ResourceFileTypeCategory.pdf:
              return { ...cat, description: 'PDFs' }
            case ResourceFileTypeCategory.slides:
              return { ...cat, description: 'Slides' }
            case ResourceFileTypeCategory.video:
              return { ...cat, description: 'Videos' }
            case ResourceFileTypeCategory.unknown:
            default:
              return null
          }
        })
        .filter((w) => w !== null)
    )

    return {
      loading,
      searchKeyword,
      selectedResourceTypes,
      resourceTypes,
      taxonomyFilterOptions,
      displayedResources,
      searchDesciption,
      categoryCount,
      categorySelected,
      toggleCategory,
    }
  },
})
