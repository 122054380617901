











import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SectionHeader',
  props: {
    title: { type: String, required: true },
  },
})
