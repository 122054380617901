

















import { defineComponent, ref, watch } from '@vue/composition-api'
import Modal from './Modal.vue'
import { snakeCase } from 'lodash'

import { html2pdf } from '@/lib/support/utilities/html2pdf/html2pdf'
export default defineComponent({
  components: {
    Modal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, ctx) {
    const internalValue = ref(false)
    const internalTitle = ref('')
    const slotRef = ref<HTMLElement | null>(null)
    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      },
      { immediate: true }
    )
    watch(
      () => props.title,
      () => {
        internalTitle.value = props.title
      },
      { immediate: true }
    )

    watch(
      () => internalValue.value,
      () => ctx.emit('input', internalValue.value)
    )

    async function downloadPDF() {
      if (slotRef.value) {
        await html2pdf(slotRef.value, { filename: snakeCase(internalTitle.value) + '.pdf' })
      }
    }

    function cancel() {
      internalValue.value = false
    }

    return { cancel, internalValue, internalTitle, slotref: slotRef, downloadPDF }
  },
})
