export function getEmptyXShipMethodBase() {
  return {
    id: '',
    description: '',
    extendedDescription: '',
    selected: false,
    cost: 0.0,
  }
}

export function getEmptyXShipMethodBaseArray() {
  return [
    {
      id: '',
      description: '',
      extendedDescription: '',
      selected: false,
      cost: 0.0,
    },
  ]
}
