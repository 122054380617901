import { RouteConfig } from 'vue-router'
import Setup from '@/views/Clinics/Setup.vue'
import Information from '@/views/Clinics/Information.vue'
import ClinicIndex from '@/views/Clinics/ClinicIndex.vue'
import orders from '@/router/orders'
import resources from './resources'
import { idExpression } from '@/lib/support/router/idHelper'
import Archive from '@/views/Clinics/Archive.vue'
import Leadership from '@/views/Clinics/Leadership.vue'

export default [
  {
    path: `clinic/${idExpression}`,
    component: ClinicIndex,
    children: [
      {
        path: '/',
        redirect: `/programs/clinic/${idExpression}/setup`,
      },
      {
        path: 'setup',
        component: Setup,
        children: [
          {
            path: '/',
            redirect: `/programs/clinic/${idExpression}/setup/information`,
          },
          {
            path: 'information',
            component: Information,
          },
          {
            path: 'leadership',
            component: Leadership,
          },
          {
            path: 'archive',
            component: Archive,
          },
        ],
      },
      ...resources('Clinic'),
      ...orders('clinic'),
    ],
  },
] as RouteConfig[]
