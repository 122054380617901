













import { defineComponent, ref, watch } from '@vue/composition-api'
import CheckboxInput from '@/elements/CheckboxInput.vue'

export default defineComponent({
  name: 'ToggleSlot',
  components: {
    CheckboxInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    value: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const internalValue = ref(props.value)

    watch(
      () => internalValue.value,
      () => {
        ctx.emit('input', internalValue.value)
        ctx.emit('change', internalValue.value)
      }
    )

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    return {
      internalValue,
    }
  },
})
