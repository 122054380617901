











import { defineComponent, computed } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'
import ParticipantKitStepper from '@/components/ProductFlow/ParticipantKitStepper/ParticipantKitStepper.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

import store from '@/store'

export default defineComponent({
  name: 'ParticipantKitStep',
  props: {
    step: { type: Number, required: true },
    stepTitle: { type: String, required: true },
    isCheer: { type: Boolean, required: true },
    nextOfferingIndex: { type: Number, required: false, default: 0 },
  },
  components: { Step, ParticipantKitStepper },
  setup(props) {
    const logic = productFlowLogic()
    const offeringsByProgram = computed(() => store.getters.productOfferings.offeringsByProgram)

    function stepClicked(step: number) {
      updateCurrentProgram()
      logic.productSetupStepClicked(step)
    }

    function updateCurrentProgram() {
      const program = logic.getUpwardProgramTypeByIsCheer(props.isCheer)
      if (program) {
        logic.setCurrentTypeProgram({ program })
      }
    }

    function nextStepClicked() {
      updateNextProgram()
      logic.productSetupNextStep(props.step)
    }

    function updateNextProgram() {
      let nextOffering = offeringsByProgram.value[props.nextOfferingIndex]
      if (!nextOffering) nextOffering = offeringsByProgram.value[0]
      const program = logic.getUpwardProgramTypeByIsCheer(nextOffering.isCheer)
      if (program) {
        logic.setCurrentTypeProgram({ program })
      }
    }

    return { ...logic, offeringsByProgram, stepClicked, nextStepClicked }
  },
})
