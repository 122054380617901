
































































import { defineComponent, ref, watch, onMounted, PropType, computed } from '@vue/composition-api'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import dayjs from 'dayjs'
import Loading from '@/elements/Loading.vue'
import { cloneDeep } from 'lodash'
import { isNullDate, toDate } from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import store from '@/store'

import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import GameModal from '@/views/Programs/Scheduling/Games/ext/GameModal.vue'

import divisionsClient from '@/clients/divisionsClient'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { DivisionGame } from '@/GeneratedTypes/DivisionGame'
import { DivisionGameInfoToDivisionGame } from '@/lib/support/models/DivisionGame/DivisionGameInfoToDivisionGame'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { League } from '@/GeneratedTypes/League'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { LeagueScoringTypes } from '@/lib/common/LeagueScoringTypes'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'

import {
  divisionGames,
  divisionGame,
} from '@/lib/support/models/GeneratedTypes/ListInfo/LeagueDivisionGameInfo'
import {
  decrementingDGStrategy,
  DivisionGameTypes,
  getEmptyDivisionGame,
} from '@/lib/support/models/DivisionGame/data'

import { getterNames as leagueGetters, namespace as leagueStoreName } from '@/store/leagues'
import { getterNames as volunteerGetters, namespace as volunteerStoreName } from '@/store/volunteers'
import { getterNames as facilitiesGetters, namespace as facilitiesStoreName } from '@/store/facilities'
import { actionNames as divisionActions, namespace as divisionsStoreName } from '@/store/divisions'
//import { HeaderStandingScoreValue } from '@/views/Programs/Divisions/ext/headerStandingScoreValue'

export default defineComponent({
  name: 'DivisionGamesList',
  components: {
    SimpleGrid,
    Loading,
    Alert,
    GameModal,
  },
  props: {
    ProgramId: { type: String, required: false, default: null },
    DivisionId: { type: Number as PropType<number>, required: true, default: 1 },
    TeamId: { type: Number as PropType<number>, required: true, default: 1 },
    RoundId: { type: Number as PropType<number>, required: true, default: 1 },
    IncompleteGamesOnly: { type: Boolean as PropType<boolean>, required: true, default: false },
    IncrementId: { type: Number as PropType<number>, required: true, default: 0 },
  },
  setup(props, ctx) {
    const alertType = AlertTypeEnum.NOTICE
    //const division = ref<LeagueDivisionInfo | null>(null)
    const currentDivision = computed(() => store.getters.divisions.currentDivision)
    const currentDivisionRoundMax = currentDivision.value?.maxRoundNumber ?? 16
    const currentGameDivisionGameID = computed(
      () => currentGame.value.typeDivisionGameID ?? DivisionGameTypes.REGULAR_GAME
    )
    const currentDivisionLeagueScoringID = computed(
      () => currentDivision.value?.typeLeagueScoringID ?? LeagueScoringTypes.WIN_LOSS
    )
    const currentGame = ref<DivisionGame>(getEmptyDivisionGame(decrementingDGStrategy(0)))
    const currentLeague = ref<League>()
    const divisionGameList = ref<divisionGame[]>([])
    const divisionList = ref<LeagueDivision[]>([])
    const facilityList = ref<LeagueFacility[]>([])
    const games = computed(() => store.getters.scheduling.games)
    const gameLength = currentDivision.value?.gameLength
    const gameModalShowing = ref<true | false>(false)
    const isLoading = ref(true)
    const incompleteGamesOnly = computed(() => props.IncompleteGamesOnly ?? false)
    //const currentGame: DivisionGame = getEmptyDivisionGame(decrementingDGStrategy(0))
    const internalGames = ref<DivisionGameInfo[]>([])
    const programID = ref<string>('')
    const showDeleteConfirmed = ref<true | false>(false)
    const selectedProgramId = computed(() => props.ProgramId ?? '')
    const selectedDivisionId = computed(() => props.DivisionId ?? 0)
    const selectedTeamId = computed(() => props.TeamId ?? 0)
    const selectedRoundId = computed(() => props.RoundId ?? 0)
    const selectedGame = ref<DivisionGame>({} as DivisionGame)
    const teams = ref<DivisionTeamInfo[]>([])
    const divisionName = ref<string | null>('')
    const divisionNameEx = computed(() => store.getters.divisions.currentDivision?.divisionName ?? '')
    const typeLeagueScoringID = ref<string | null>(null)

    const isWinLossDivision = computed(
      () => currentDivision.value?.typeLeagueScoringID === LeagueScoringTypes.WIN_LOSS
    )
    const leagueID = computed(() => {
      const id = ctx.root.$route.params.id
      return id
    })
    const isWinLossScoringDivision = computed(
      () => currentDivision.value?.typeLeagueScoringID === LeagueScoringTypes.WIN_LOSS_SCORING
    )
    const columnsWinLoss = ref<GridColumn[]>([
      { field: 'roundNumber', headerText: 'Round' } as GridColumn,
      { field: 'gameName', headerText: 'Game Name' } as GridColumn,
      { field: 'winningTeamName', headerText: 'Winning Team' } as GridColumn,
      { field: 'prettyStartDate', headerText: 'Date' } as GridColumn,
      { field: 'prettyTimeRange', headerText: 'Time' } as GridColumn,
    ])

    const columnsWinLossScoring = ref<GridColumn[]>([
      { field: 'roundNumber', headerText: 'Round' } as GridColumn,
      { field: 'gameName', headerText: 'Game Name' } as GridColumn,
      { field: 'gameResult', headerText: 'Game Result' } as GridColumn,
      { field: 'prettyStartDate', headerText: 'Date' } as GridColumn,
      { field: 'prettyTimeRange', headerText: 'Time' } as GridColumn,
    ])

    const confirmGame = async (newelement: DivisionGameInfo) => {
      try {
        await store.dispatch.scheduling.upsertGame({ id: leagueID.value, game: newelement })
      } catch (e) {
        console.error(e) // Log the error or handle it as needed
        throw e
      }
      emitValuesGameScores()
      gameModalShowing.value = false
    }

    function emitValuesGameScores() {
      if (!currentDivision.value) return
      const headerValues = {
        selectedDivision: currentDivision.value,
        selectedTypeProgramID: selectedProgramId.value,
        selectedTeamID: selectedTeamId.value,
        selectedRoundID: selectedRoundId.value,
        incompleteGamesOnly: incompleteGamesOnly.value,
      }
      ctx.emit('divisionScoreGameScoreChanged', headerValues)
    }

    const deleteGame = async (newelement: DivisionGame) => {
      try {
        await store.dispatch.scheduling.removeGame({ id: props.ProgramId, gameID: newelement.gameID })
        showDeleteConfirmed.value = true
        gameModalShowing.value = false
      } catch (e) {
        console.error(e) // Log the error or handle it as needed
        throw e
      }
    }

    const gameDivisionList = ref<LeagueDivisionInfo[]>([])

    const eventClick = async (gameID: number) => {
      const localGame = internalGames.value.find((x) => x.gameID === gameID)
      if (localGame) {
        // game is going to be in league time zone, dialog expects it in local.
        currentGame.value = {
          ...DivisionGameInfoToDivisionGame(localGame),
          gameStart: localGame.gameStart,
          gameEnd: localGame.gameEnd,
          volunteers: localGame.volunteers,
        }

        const localTeams = await store.getters.scheduling.teams
        teams.value = [...localTeams] // Creates a new mutable array

        const localLeague = store.getters.leagues.currentItem
        currentLeague.value = localLeague

        const localDivision = await divisionsClient.retrieveAll(ctx.root.$route.params.id)
        //gameDivisionList.value = localDivision!
        gameDivisionList.value = localDivision!.map((division) => ({
          ...division,
          typeProgramID: division.typeProgramID ?? props.ProgramId,
        }))

        const localFacilities = store.getters.facilities.items
        facilityList.value = localFacilities!

        gameModalShowing.value = true
      }
    }

    const roundList = computed(() => {
      const roundlimit = currentDivision.value?.maxRoundNumber || 16
      const rounds = Array.from(Array(roundlimit), (_, i) => ({
        id: (i + 1).toString(), // Convert id to string
        description: `Round ${i + 1}`,
      }))
      return rounds
    })

    const clearGames = computed(() => store.dispatch.scheduling.clearGames)
    const removeGame = computed(() => store.dispatch.scheduling.removeGame)
    const upsertGame = computed(() => store.dispatch.scheduling.upsertGame)
    const retrieveEvents = store.dispatch.scheduling.loadEvents
    const retrieveTeams = store.dispatch.scheduling.loadTeams
    const retrieveGames = store.dispatch.scheduling.loadGames
    const league = computed(() => store.getters[leagueGetters.currentItem])
    const referees = computed(() => store.getters[volunteerGetters.referees])
    const facilities = computed(() => store.getters[facilitiesGetters.items])

    const fetchAllDivisions = async ({
      upwardLeagueId,
      force,
    }: {
      upwardLeagueId: string
      force: boolean
    }) => {
      await (divisionActions.fetchAll,
      {
        upwardLeagueId,
        force,
      })
    }

    const retrieveFacilities = async (id: { id: string }) => {
      return await store.dispatch.facilities.load(id)
    }

    const refreshLeagues = async () => {
      await fetchAllDivisions({ upwardLeagueId: ctx.root.$route.params.id!, force: true })
    }

    const refreshFacilities = async () => {
      await retrieveFacilities({ id: ctx.root.$route.params.id! })
    }

    const refreshGames = async () => {
      await retrieveGames({ id: ctx.root.$route.params.id! })
    }

    const refreshEvents = async () => {
      await retrieveEvents({ id: ctx.root.$route.params.id! })
    }

    const refreshTeams = async () => {
      await retrieveTeams({
        id: ctx.root.$route.params.id!,
        programID: props.ProgramId,
        divisionID: selectedDivisionId.value, // Ensure you pass all necessary params
      })
    }

    onMounted(async () => {
      await Promise.all([refreshLeagues(), refreshFacilities(), refreshGames(), refreshEvents()])
      if (props.ProgramId) {
        await Promise.all([refreshTeams()])
      }
      loadList()
      divisionName.value = currentDivision.value?.divisionName ?? null
    })

    function editClicked(item: divisionGame) {
      gameModalShowing.value = true
      eventClick(item.gameID)
    }

    // Helper function to format game results
    function formatGameResults(awayTeamScore: number | null, homeTeamScore: number | null): string | null {
      // Check if both scores are null
      if (awayTeamScore === null && homeTeamScore === null) {
        return 'No Results Available'
      }

      // Format the result if at least one score is present
      return `${homeTeamScore !== null ? homeTeamScore : 'N/A'} (H) -  ${
        awayTeamScore !== null ? awayTeamScore : 'N/A'
      } (A)`
    }

    // Define the getPrettyTime function
    function getPrettyTime(evtStart: Date, evtEnd: Date | null): string {
      const startJS = dayjs(evtStart)
      const endJS = dayjs(evtEnd ?? '')

      if (startJS.startOf('day').isSame(startJS) && evtEnd == null) {
        return ''
      } else {
        if (evtEnd != null) {
          return startJS.format('h:mmA') + ' - ' + endJS.format('h:mmA')
        } else {
          return startJS.format('h:mmA')
        }
      }
    }

    function gamesSort(a: divisionGame, b: divisionGame): number {
      // Handle cases where gameStart might be null
      if (a.gameStart === null && b.gameStart === null) {
        // Both are null, treat as equal
        return 0
      }
      if (a.gameStart === null) {
        // a is null and b is not null
        return 1
      }
      if (b.gameStart === null) {
        // b is null and a is not null
        return -1
      }

      // Both are not null, perform sorting based on datetime
      const dateStartA = dayjs(a.gameStart)
      const dateStartB = dayjs(b.gameStart)

      const same = dateStartA.isSame(dateStartB, 'minute')
      if (!same) {
        return dateStartA.isAfter(dateStartB) ? 1 : -1
      } else {
        // Sort by facilityName
        const facilityNameA = a.facilityName?.toLowerCase() || ''
        const facilityNameB = b.facilityName?.toLowerCase() || ''

        if (facilityNameA > facilityNameB) return 1
        if (facilityNameA < facilityNameB) return -1
        return 0
      }
    }

    function loadList() {
      isLoading.value = true
      try {
        if (props.ProgramId) {
          const list = [...games.value]
          if (list) {
            divisionGameList.value = divisionGames(list)
              .filter(
                (x) =>
                  !isNullDate(toDate(x.gameStart)) &&
                  x.divisionID === selectedDivisionId.value &&
                  (x.roundNumber == selectedRoundId.value || selectedRoundId.value == 0) &&
                  (x.awayTeamID == selectedTeamId.value ||
                    selectedTeamId.value == 0 ||
                    x.homeTeamID == selectedTeamId.value ||
                    selectedTeamId.value == 0) &&
                  (incompleteGamesOnly.value === false ||
                    (x.winningTeamID != null &&
                      incompleteGamesOnly.value !== null &&
                      incompleteGamesOnly.value! === true))
              )
              .map((x: DivisionGameInfo) => ({
                ...x,
                prettyStartDate: x.gameStart ? dayjs(x.gameStart ?? '').format('ddd MMM DD') : null,
                prettyStartTime: x.gameEnd !== null ? getPrettyTime(x.gameStart!, null) : '',
                prettyEndDate: x.gameEnd ? dayjs(x.gameEnd ?? '').format('ddd MMM DD') : null,
                prettyEndTime: x.gameEnd !== null ? getPrettyTime(x.gameEnd!, null) : '',
                prettyTimeRange:
                  x.gameStart !== null && x.gameEnd !== null ? getPrettyTime(x.gameStart!, x.gameEnd) : '',
                gameName: `${x.awayTeamName} at ${x.homeTeamName}`,
                winningTeamName:
                  x.winningTeamName === null || x.winningTeamName === undefined || x.winningTeamName === ''
                    ? x.winningTeamID === x.awayTeamID
                      ? x.awayTeamName
                      : x.winningTeamID === x.homeTeamID
                      ? x.homeTeamName
                      : x.winningTeamID === -9999
                      ? 'Tie'
                      : ''
                    : x.winningTeamName,
                gameResult: formatGameResults(x.awayTeamScore, x.homeTeamScore),
              }))
              .sort(gamesSort)
          }
        } else {
          divisionGameList.value = []
        }
      } finally {
        isLoading.value = false
      }
    }

    watch(
      () => props.ProgramId,
      async () => {
        await loadList(),
          await refreshLeagues(),
          await refreshFacilities(),
          await refreshGames(),
          await refreshEvents()
        await refreshTeams()
      }
    )

    watch(
      () => props.DivisionId,
      async () => {
        await loadList()
        await refreshTeams()
      }
    )

    watch(
      () => props.TeamId,
      async () => {
        await loadList()
      }
    )
    watch(
      () => props.RoundId,
      async () => {
        await loadList()
      }
    )
    watch(
      () => props.IncompleteGamesOnly,
      async () => {
        await loadList()
      }
    )

    watch(
      () => props.IncrementId,
      async () => {
        refreshGames()
        store.getters.scheduling.games
        await loadList()
      }
    )

    watch(
      () => currentDivision.value?.typeLeagueScoringID,
      (newVal) => {
        typeLeagueScoringID.value = newVal
      },
      { immediate: true } // Run immediately to initialize the value
    )

    // watch(
    //   () => currentDivision.value,
    //   (newVal) => {
    //     division.value = newVal
    //   },
    //   { immediate: true } // Run immediately to initialize the value
    // )

    // Watch for changes in `games`
    watch(
      games,
      (newGames) => {
        internalGames.value = cloneDeep(
          newGames.map((x) => ({
            ...x,
            gameStart: x.gameStart ? dayjs(x.gameStart).toDate() : null,
            gameEnd: x.gameEnd ? dayjs(x.gameEnd).toDate() : null,
          }))
        )
      },
      { immediate: true }
    )

    return {
      alertType,
      Alert,
      clearGames,
      columnsWinLoss,
      columnsWinLossScoring,
      confirmGame,
      currentDivisionRoundMax,
      currentGame,
      currentLeague,
      deleteGame,
      DivisionGameInfoToDivisionGame,
      divisionGameList,
      divisionList,
      divisionName,
      divisionNameEx,
      editClicked,
      eventClick,
      facilityList,
      gameDivisionList,
      gameLength,
      games,
      gameModalShowing,
      incompleteGamesOnly,
      internalGames,
      isLoading,
      isWinLossDivision,
      isWinLossScoringDivision,
      leagueID,
      programID,
      removeGame,
      roundList,
      selectedDivisionId,
      selectedGame,
      selectedProgramId,
      selectedRoundId,
      selectedTeamId,
      showDeleteConfirmed,
      teams,
      upsertGame,
      typeLeagueScoringID,
      leagueStoreName,
      volunteerStoreName,
      facilitiesStoreName,
      divisionsStoreName,
      retrieveEvents,
      league,
      referees,
      facilities,
      fetchAllDivisions,
      currentGameDivisionGameID,
      currentDivisionLeagueScoringID,
    }
  },
})
