
































import CampSetupMixin from '@/views/Camps/CampSetupMixin'

import { Getter } from 'vuex-class'
import { getterNames, namespace } from '@/store/authorization'

import { ContactToContactInfo, ContactInfoToContact, GetEmptyContactInfo } from '@/services/contactService'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'

import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import ContactPickerAndEditor from '@/components/ContactPickerAndEditor.vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    ContactPickerAndEditor,
    SaveContinueButton,
  },
})
export default class Leadership extends Mixins(CampSetupMixin) {
  private sportContact: ContactInfo = GetEmptyContactInfo()

  @Getter(getterNames.accountNumbers, { namespace })
  private accountNumbers!: string[] | null

  mounted() {
    this.afterUpdateExisting = this.afterUpdateExistingMethod

    if (this.camp.upwardLeagueID) {
      this.nextRoute = `/programs/camp/${this.camp.upwardLeagueID}/setup/partnership`
    } else {
      this.nextRoute = '/programs/camp/setup/partnership'
    }
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    if (this.camp) {
      this.sportContact = ContactToContactInfo(this.camp!.partnerContact!, this.camp!.leagueDirectorPartnerID)
    }
  }

  private get canChangeName() {
    return !(this.camp && this.camp!.leagueDirectorPartnerID)
  }

  private async onSubmit() {
    this.camp.partnerContact = this.sportContact ? ContactInfoToContact(this.sportContact) : getEmptyContact()
    this.camp.leagueDirectorPartnerID = this.sportContact ? this.sportContact.id : 0
    await this.save()
  }

  private get isExisting() {
    return !!this.camp.upwardLeagueID
  }

  private get isClean() {
    return this.storeCamp.leagueDirectorPartnerID === this.camp.leagueDirectorPartnerID
  }

  private afterUpdateExistingMethod() {
    this.camp = { ...cloneDeep(this.storeCamp) }
  }
}
