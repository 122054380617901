













































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import CampLeagueStarterBox from '@/components/CampLeagueStarterBox.vue'
import store from '@/store'

export default defineComponent({
  name: 'SetupCompleteConfirmation',
  components: {
    CampLeagueStarterBox,
    Modal,
  },
  props: {
    value: { type: Boolean, required: true },
    upwardLeagueId: { type: String, required: false, default: '' },
    isCamp: { type: Boolean, required: false, default: false },
    hasOnlineRegistration: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const isUpwardSelect = computed(() => store.getters.leagues.isUpwardSelect)
    const modalIsVisible = ref(props.value)

    function hideModal() {
      modalIsVisible.value = false
      onModalChange()
    }

    watch(
      () => props.value,
      () => {
        modalIsVisible.value = props.value
      }
    )

    function onModalChange() {
      ctx.emit('input', modalIsVisible.value)
      ctx.emit('change', modalIsVisible.value)
    }

    return {
      modalIsVisible,
      onModalChange,
      isUpwardSelect,
      hideModal,
    }
  },
})
