






























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import { LeagueInfoMoreThanOneProgram } from '@/lib/support/models/LeagueListItem/data'

import { League } from '@/GeneratedTypes/League'

import { namespace } from 'vuex-class'
import { getterNames as leagueGetters, namespace as leagueStoreName } from '@/store/leagues'
const league = namespace(leagueStoreName)
import {
  getterNames as facilitiesGetters,
  actionNames as facilitiesActions,
  namespace as facilitiesStoreName,
} from '@/store/facilities'

import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'

const facilities = namespace(facilitiesStoreName)

import {
  getterNames as divisionGetters,
  actionNames as divisionActions,
  namespace as divisionNamespace,
} from '@/store/divisions'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import CheerSchedulingGrid from '@/views/Programs/Scheduling/Squads/ext/CheerSchedulingGrid.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { getEmptyDivisionSquadMap } from '@/models/Scheduling/DivisionSquadMap'
import { CheerSquadSchedulerMapping } from '@/GeneratedTypes/CheerSquadSchedulerMapping'
import appointmentClient from '@/clients/appointmentClient'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

const divisions = namespace(divisionNamespace)
@Component({
  components: {
    ConfirmationModal,
    SelectInput,
    CheerSchedulingGrid,
    TypeProgramTab,
    Alert,
  },
  methods: {
    LeagueInfoMoreThanOneProgram,
  },
})
export default class SquadsAutomaticScheduling extends Vue {
  /** getters */
  @league.Getter(leagueGetters.currentItem) league!: League
  @facilities.Getter(facilitiesGetters.items) facilities!: LeagueFacility[]

  @facilities.Action(facilitiesActions.load)
  private readonly retrieveFacilities!: ({ id }: { id: string }) => LeagueFacility[] | null

  @divisions.Getter(divisionGetters.allItems) divisionList!: (s: string) => LeagueDivisionInfo[]
  @divisions.Action(divisionActions.fetchAll) fetchAllDivisions!: ({
    upwardLeagueId,
    force,
  }: {
    upwardLeagueId: UpwardLeagueIDType
    force: boolean
  }) => Promise<boolean>
  STRATEGY_RANDOM = 'RANDOM'
  STRATEGY_DIVISION = 'DIVISION'

  showScheduleConfirm = false
  clickSchedule() {
    this.showScheduleConfirm = true
  }

  internalSquadMapping: CheerSquadSchedulerMapping[] = []
  get squadMapping(): CheerSquadSchedulerMapping[] {
    return this.mapping.map((x) => ({
      cheerDivisionID: x.cheerDivisionID,
      sportDivisionID: x.sportDivisionID,
      cheerTypeProgramID: this.cheerProgramID ?? '',
      sportTypeProgramID: this.sportProgramID ?? '',
      leagueID: this.league.id,
    }))
  }

  squadMappingUpdated() {
    appointmentClient.savePreferences(this.upwardLeagueID, this.squadMapping)
  }

  /***
   * mapping --
   */
  mapping = getEmptyDivisionSquadMap()
  alertType = AlertTypeEnum.NOTICE
  strategy = this.STRATEGY_RANDOM

  @Watch('strategy')
  strategyUpdated() {
    if (this.strategy === this.STRATEGY_RANDOM) {
      appointmentClient.savePreferences(this.upwardLeagueID, [])
    }
  }

  @Watch('internalSquadMapping', { immediate: true })
  updateInternalSquadMapping() {
    this.mapping = this.internalSquadMapping.map((x) => ({
      cheerDivisionID: x.cheerDivisionID,
      sportDivisionID: x.sportDivisionID,
    }))
  }

  /** other variables**/
  private loading = true
  get sportProgramID() {
    if (this.league && this.league.programs?.length) {
      return this.league.programs[0].typeProgramID || ''
    }
    return undefined
  }
  get cheerProgramID() {
    if (this.league && this.league.programs && this.league.programs?.length > 1) {
      return this.league.programs[1].typeProgramID || ''
    }
    return undefined
  }

  get upwardLeagueID() {
    return this.$route.params.id
  }

  public async mounted() {
    await this.retrieveFacilities({ id: this.upwardLeagueID })
    await this.fetchAllDivisions({ upwardLeagueId: this.upwardLeagueID, force: true })
    this.internalSquadMapping = (await appointmentClient.getPreferences(this.upwardLeagueID)) ?? []
    this.$nextTick(() => this.$watch('squadMapping', this.squadMappingUpdated))
    this.loading = false
  }

  offset = 5
  get offsetList() {
    const rv = []
    for (let i = 0; i < 60; i += 5) {
      rv.push({ id: i.toString(), desc: `${i} Minutes` })
    }
    return rv
  }

  get canScheduleNow() {
    return this.mapping.length > 0 || this.strategy == this.STRATEGY_RANDOM
  }

  confirmCheerScheduledShowing = false
  async scheduleCheerConfirmed(b: boolean) {
    if (b) {
      await appointmentClient.clearSchedule(this.upwardLeagueID)
      await appointmentClient.schedule(this.upwardLeagueID, { offset: this.offset })
      this.confirmCheerScheduledShowing = true
    }
    this.showScheduleConfirm = false
    this.$router.push(`/programs/league/${this.upwardLeagueID}/scheduling/cheer/manual`)
  }

  get divisionsList() {
    return this.divisionList(this.league?.upwardLeagueID ?? '')
  }
}
