




















































import ComboBoxInput from '@/elements/ComboBoxInput.vue'
import GradeRangeSlider from '@/elements/GradeRangeSlider.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import TextInput from '@/elements/TextInput.vue'

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import * as authorizationStore from '@/store/authorization'
import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount.ts'
import { isEqual, cloneDeep } from 'lodash'
import { loadGradeDataIntoAccount, getGradesListFromAccount } from '@/services/gradeService'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'

import * as accountsStore from '@/store/partnerNearByAccounts'

@Component({
  components: {
    TextInput,
    ComboBoxInput,
    GradeRangeSlider,
    CheckboxInput,
  },
})
export default class PartnerForm extends Vue {
  @Prop({ type: String, required: true })
  private readonly label!: string

  @Prop({ type: String, required: true })
  private readonly typeProgramId!: string

  @Prop({ type: String, required: false, default: '' })
  private readonly cheerTypeProgramId!: string

  @Prop({ type: Number, required: false, default: '' })
  private readonly leagueId!: number

  @Prop({ type: Object, required: true })
  private readonly value!: LeagueAccount

  @Prop({ type: Boolean, required: false, default: false })
  private readonly isByAge!: boolean

  @Getter(authorizationStore.getterNames.firstAccountNumber, { namespace: authorizationStore.namespace })
  private masterAccountNumber!: string

  @Getter(accountsStore.getterNames.getAccountsInRadius, {
    namespace: accountsStore.namespace,
  })
  private partnersInRadius!: (accountNumber: string) => AccountInfo[]

  @Action(accountsStore.actionNames.fetchAccountsInRadius, {
    namespace: accountsStore.namespace,
  })
  private fetchAccountsInRadius!: ({}: { accountNumber: string }) => Promise<boolean>

  private internalAccount: LeagueAccount | null = null

  private girlGrades: string[] = []
  private boyGrades: string[] = []
  private cheerGrades: string[] = []

  private isHostChurch = false
  private partner = ''

  private async mounted() {
    this.setInternalAccount(this.value)
    await this.fetchAccountsInRadius({ accountNumber: this.masterAccountNumber })
  }

  @Watch('value')
  private onAccountUpdate() {
    this.setInternalAccount(this.value)
  }

  @Watch('internalAccount', { deep: true })
  private onInternalAccountUpdate() {
    if (!isEqual(this.value, this.internalAccount)) {
      this.$emit('input', this.internalAccount)
    }
  }

  private setInternalAccount(value: LeagueAccount | null) {
    if (value) {
      if (!isEqual(value, this.internalAccount)) {
        this.internalAccount = cloneDeep(value)

        this.setGradesValue()
      }
    }
  }

  private setGradesValue() {
    if (this.typeProgramId && this.internalAccount) {
      this.boyGrades = cloneDeep(getGradesListFromAccount(this.internalAccount, this.typeProgramId, 'M')).map(
        (x) => x.upwardTypeID!
      )
      this.girlGrades = cloneDeep(
        getGradesListFromAccount(this.internalAccount, this.typeProgramId, 'F')
      ).map((x) => x.upwardTypeID!)

      if (this.cheerTypeProgramId) {
        this.cheerGrades = cloneDeep(
          getGradesListFromAccount(this.internalAccount, this.cheerTypeProgramId, 'F').map(
            (x) => x.upwardTypeID!
          )
        )
      }
      this.$emit('input', this.internalAccount)
    }
  }

  @Watch('boyGrades')
  private onBoyGradeChange() {
    if (this.typeProgramId && this.internalAccount) {
      loadGradeDataIntoAccount(this.internalAccount, this.boyGrades, this.typeProgramId, 'M')
    }
  }

  @Watch('girlGrades')
  private onGirlGradeChange() {
    if (this.typeProgramId && this.internalAccount) {
      loadGradeDataIntoAccount(this.internalAccount, this.girlGrades, this.typeProgramId, 'F')
    }
  }

  @Watch('cheerGrades')
  private onCheerGradeChange() {
    if (this.cheerTypeProgramId && this.internalAccount) {
      loadGradeDataIntoAccount(this.internalAccount, this.cheerGrades, this.cheerTypeProgramId, 'F')
    }
  }

  private get accountNumber(): string {
    if (this.internalAccount && this.internalAccount.accountNumber.charAt(0) === '-') {
      return ''
    }

    if (this.internalAccount && this.internalAccount.accountNumber) {
      return this.internalAccount.accountNumber
    }

    return ''
  }

  private set accountNumber(value: string) {
    if (this.internalAccount) {
      this.internalAccount.accountNumber = value
      this.$emit('input', this.internalAccount)
    }
  }
}
