










import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

import Loading from '@/elements/Loading.vue'
import MinistryAgreementModal from '@/components/MinistryAgreementModal.vue'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Modal from '@/components/Modal.vue'
import store from '@/store'

export default defineComponent({
  name: 'Setup',
  components: {
    VerticalTabs,
    Loading,
    Modal,
    MinistryAgreementModal,
  },
  setup(props, ctx) {
    const storeCamp = computed(() => store.getters.camps.currentItem)

    const reloadMinistryAgreement = ref(false)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const baseRoute = computed(() => `/programs/camp/${routeId.value}setup`)

    const tabs = computed(() => {
      if (storeCamp.value && !storeCamp.value.canEdit) {
        return []
      }

      const retval = [
        { text: 'Program Information', to: `${baseRoute.value}/information` },
        { text: 'Key Dates', to: `${baseRoute.value}/dates` },
        { text: 'Director', to: `${baseRoute.value}/leadership` },
        { text: 'Partnership', to: `${baseRoute.value}/partnership` },
        { text: 'Camp Items', to: `${baseRoute.value}/products` },
      ]

      if (storeCamp.value.isOPRLeague) {
        retval.push({ text: 'Online Registration', to: `${baseRoute.value}/registration` })
      }
      return retval
    })

    onMounted(async () => {
      const archivedRoute = `${baseRoute.value}/archive`
      if (storeCamp.value && !storeCamp.value.canEdit && ctx.root.$route.path !== archivedRoute) {
        await ctx.root.$router.push(archivedRoute)
      } else {
        reloadMinistryAgreement.value = !reloadMinistryAgreement.value
      }
    })

    watch(
      () => storeCamp.value.campStartDate,
      () => {
        reloadMinistryAgreement.value = !reloadMinistryAgreement.value
      },
      { deep: true }
    )

    return {
      tabs,
      reloadMinistryAgreement,
      storeCamp,
    }
  },
})
