

























import { defineComponent, ref, watch } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'

export default defineComponent({
  name: 'SearchInput',
  components: {
    TextInput,
  },
  props: {
    value: { type: String, required: true },
    searchButtonText: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: 'Search' },
    allowBlankInput: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const internalValue = ref('')

    function input() {
      ctx.emit('input', internalValue.value)
    }

    function searchClick() {
      ctx.emit('search-click', internalValue.value)
    }

    function change() {
      //trigger change if we don't have a search button
      ctx.emit('change', internalValue.value)
    }

    function handleInput() {
      //trigger only if we don't have a search button
      if (!props.searchButtonText || (props.searchButtonText && props.allowBlankInput)) {
        input()
      }
    }

    function handleButtonInput() {
      searchClick()
      input()
    }

    function handleChange() {
      if (!props.searchButtonText) {
        change()
      }
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      },
      { immediate: true }
    )

    return {
      internalValue,
      handleInput,
      handleChange,
      handleButtonInput,
    }
  },
})
