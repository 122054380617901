import { PendingOrderAdditionalProductInfo } from '@/GeneratedTypes/ListInfo/PendingOrderAdditionalProductInfo'
export function getEmptyPendingOrderAdditionalProductInfo(): PendingOrderAdditionalProductInfo {
  return {
    leagueID: 0,
    accountNumber: null,
    id: 0,
    typeProgramID: null,
    productID: 0,
    typeColorID: 'N/A',
    typeSizeID: 'N/A',
    quantity: 0,
    individualID: 0,
    jerseyNumber: '',
    typeParticipantID: null,
  }
}
