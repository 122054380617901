import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import communicationsClient from '@/clients/communicationsClient'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import { getEmptyPreviewMessage } from '@/lib/support/models/Communication/PreviewMessage'
import { CommunicationStatusData } from '@/models/Communication/CommunicationStatusData'

const getNewProviderInfo = () => {
  const EmailSMSProviderInfo: EmailSMSAccount = {
    emailSmsAccountID: -1,
    accountNumber: '',
    upwardLeagueID: '',
    emailAcctID: -1,
    emailAcctName: '',
    emailAcctApiKey: '',
    emailCredits: -1,
    emailMaxCredits: -1,
    isEmailAllowed: false,
    smsAcctID: -1,
    smsAcctName: '',
    smsAcctApiKey: '',
    smsCredits: -1,
    smsMaxCredits: -1,
    isSMSAllowed: false,
    smsTypeProviderID: -1,
    smsAssignedNumber: '',
  }

  return EmailSMSProviderInfo
}

interface CommunicationsState {
  currentProviderInfo: EmailSMSAccount
  messagePreview: PreviewMessage
  isTollFreeApplicationPending: boolean
  statuses: CommunicationStatusData[]
}

const CommunicationsState: CommunicationsState = {
  currentProviderInfo: getNewProviderInfo(),
  messagePreview: getEmptyPreviewMessage(),
  isTollFreeApplicationPending: false,
  statuses: new Array<CommunicationStatusData>(),
}

export enum mutationNames {
  setCurrent = 'setCurrent',
  setPreviewMessage = 'setPreviewMessage',
  setTollFreeApplicationPending = 'setTollFreeApplicationPending',
  setStatuses = 'setStatuses',
  clearStatuses = 'clearStatuses',
}

const mutations = defineMutations<CommunicationsState>()({
  [mutationNames.setCurrent](state, { item }: { item: EmailSMSAccount }) {
    state.currentProviderInfo = item
  },
  [mutationNames.setPreviewMessage](state, { item }: { item: PreviewMessage }) {
    state.messagePreview = item
  },
  [mutationNames.setTollFreeApplicationPending](state, { item }: { item: boolean }) {
    state.isTollFreeApplicationPending = item
  },
  [mutationNames.setStatuses](state, { item }: { item: CommunicationStatusData[] }) {
    state.statuses = item
  },
  [mutationNames.clearStatuses](state) {
    state.statuses = []
  },
})

export enum getterNames {
  currentItem = 'currentItem',
  isTollFreeApplicationPending = 'isTollFreeApplicationPending',
}

const getterTree = defineGetters<CommunicationsState>()({
  currentItem: (state) => state.currentProviderInfo,
  messagePreview: (state) => state.messagePreview,
  isTollFreeApplicationPending: (state) => state.isTollFreeApplicationPending,
  statuses: (state) => state.statuses,
})

export interface LeagueData {
  upwardID: string
  accountNumber: string
}

export enum actionNames {
  create = 'create',
  update = 'update',
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  validate = 'validate',
  cache = 'cache',
  loadFromCache = 'loadFromCache',
  beginCreating = 'beginCreating',
  retrieveMessagePreview = 'retrieveMessagePreview',
  retrieveStatuses = 'retrieveStatuses',
}

const actions = defineActions({
  async [actionNames.retrieveStatuses](
    { commit },
    { accountNumber, upwardLeagueID }: { accountNumber: string; upwardLeagueID: string }
  ): Promise<CommunicationStatusData[]> {
    const data = await communicationsClient.getCommunicationStatuses(accountNumber, upwardLeagueID)
    commit(mutationNames.setStatuses, { item: data })
    return data
  },
  async [actionNames.retrieveAndSetAsCurrent](
    { commit },
    { payload }: { payload: LeagueData }
  ): Promise<EmailSMSAccount | null> {
    try {
      if (payload == null) {
        throw new Error('payload required')
      } else {
        const providerInfo = await communicationsClient.retrieveProviderInfo(
          payload.upwardID,
          payload.accountNumber
        )

        commit(mutationNames.setCurrent, { item: providerInfo })

        return providerInfo
      }
    } catch (error) {
      throw new Error('communications store: unable to retrieve communications provider info')
    }
  },
  async [actionNames.retrieveMessagePreview](
    { commit },
    { previewMessage }: { previewMessage: PreviewMessage }
  ): Promise<PreviewMessage | null> {
    try {
      if (previewMessage == null) {
        throw new Error('payload required')
      } else {
        const preview = await communicationsClient.retrieveMessagePreview(previewMessage)

        commit(mutationNames.setPreviewMessage, { item: preview })

        return preview
      }
    } catch (error) {
      throw new Error('communications store: unable to retrieve communications provider info')
    }
  },
})

export const namespace = 'communications'

export const communications = {
  namespaced: true as true,
  state: CommunicationsState,
  getters: getterTree,
  mutations,
  actions,
}
