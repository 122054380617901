












































































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import DatesInput from '@/elements/DatesInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { DivisionGameSchedulerPreference } from '@/GeneratedTypes/DivisionGameSchedulerPreference'
import { getEmptyDivisionGameSchedulerPreference } from '@/lib/support/models/DivisionGameSchedulerPreference/data'
import { cloneDeep, isEqual } from 'lodash'
import { toDate } from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import { DivisionGameSchedulerExclusion } from '@/GeneratedTypes/DivisionGameSchedulerExclusion'

import store from '@/store'

@Component({
  components: { SelectInput, DatesInput },
})
export default class SchedulerSettingsPanel extends Vue {
  @Prop({ required: true, default: () => getEmptyDivisionGameSchedulerPreference(), type: Object })
  value!: DivisionGameSchedulerPreference
  internalValue!: DivisionGameSchedulerPreference

  get exclusionDates(): DivisionGameSchedulerExclusion[] {
    return this.dates.map((x) => ({ excludeDate: x }))
  }

  get datesSorted() {
    return this.dates.sort((x, y) => (x.valueOf() > y.valueOf() ? 1 : -1))
  }

  @Watch('value', { immediate: true })
  valueUpdated() {
    if (!isEqual(this.value, this.internalValue)) {
      this.assignment = this.value.typeScheduleAssignmentID || 'EARLIESTTIME'
      this.oddTeamPreference = this.value.typeOddTeamCountSchedulingID || 'DOUBLEHEADER'
      this.gamesPerWeek = this.value.gamesPerWeek || 1
      this.dates = this.value?.exclusionDates?.map((x) => toDate(x.excludeDate)) || []

      this.internalValue = cloneDeep(this.value)
      this.detectChangeAndFire()
    }
  }

  @Watch('value.divisionID')
  async divisionChanged() {
    await this.retrieveTeams()
  }

  async retrieveTeams() {
    if (!this.value.typeProgramID || !this.$route.params.id) return
    await store.dispatch.scheduling.loadTeams({
      id: this.$route.params.id,
      divisionID: this.value.divisionID,
      programID: this.value.typeProgramID,
    })
  }

  dates: Date[] = []
  assignment = 'EARLIESTTIME'
  oddTeamPreference = 'DOUBLEHEADER'
  gamesPerWeek = 0
  assignmentChange(v: string) {
    this.assignment = v
  }

  get calculatedSettings(): DivisionGameSchedulerPreference {
    return {
      ...this.internalValue,
      typeScheduleAssignmentID: this.assignment,
      typeOddTeamCountSchedulingID: this.oddTeamPreference,
      gamesPerWeek: this.gamesPerWeek,
      exclusionDates: this.exclusionDates,
    }
  }

  private get hasOddTeam(): boolean {
    return this.$store.direct.getters.scheduling.teams.length % 2 === 1
  }

  /***
   * Fire a change if the value is different than the calculated settings
   */
  detectChangeAndFire() {
    if (!isEqual(this.value, this.calculatedSettings)) {
      this.input()
    }
  }

  @Emit()
  input() {
    return this.calculatedSettings
  }
}
