export const AddIconCssClass = 'fa fa-plus'
export const AddIcon = {
  name: 'AddIcon',
  data() {
    return {
      iconCssClass: AddIconCssClass,
    }
  },
  template: '<i :class="iconCssClass"></i>',
}

export const EditIconCssClass = 'fa fa-pencil-alt'
export const EditIcon = {
  name: 'EditIcon',
  data() {
    return {
      iconCssClass: EditIconCssClass,
    }
  },
  template: '<i :class="iconCssClass"></i>',
}

export const DeleteIconCssClass = 'fas fa-minus-circle'
export const DeleteIcon = {
  name: 'DeleteIcon',
  data() {
    return {
      iconCssClass: DeleteIconCssClass,
    }
  },
  template: '<i :class="iconCssClass"></i>',
}

export const SaveIconCssClass = 'fa fa-check'
export const SaveIcon = {
  name: 'SaveIcon',
  data() {
    return {
      iconCssClass: SaveIconCssClass,
    }
  },
  template: '<i :class="iconCssClass"></i>',
}

export const SuccessIconCssClass = 'fa fa-check'
export const SuccessIcon = {
  name: 'SuccessIcon',
  data() {
    return {
      iconCssClass: SuccessIconCssClass,
    }
  },
  template: '<i :class="iconCssClass"></i>',
}

export const CloneIconCssClass = 'far fa-clone'
export const CloneIcon = {
  name: 'CloneIcon',
  data() {
    return {
      iconCssClass: CloneIconCssClass,
    }
  },
  template: '<i :class="iconCssClass"></i>',
}

export const HelpIconCssClass = 'fa fa-question-circle'
export const HelpIcon = {
  name: 'HelpIcon',
  data() {
    return {
      iconCssClass: HelpIconCssClass,
    }
  },
  template: '<i :class="iconCssClass"></i>',
}
