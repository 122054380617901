




























import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import uuid from 'uuid'

import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'

export default defineComponent({
  name: 'ClinicOverview',
  components: {
    BaseInputWrapper,
  },
  props: {
    value: { type: Array as PropType<number[] | null>, required: true },
    id: { type: String, default: '', required: false },
    min: { type: Number, default: 0, required: false },
    max: { type: Number, default: 10, required: false },
    subLabel: { type: String, required: false },
    subLabelInline: { type: Boolean, default: false, required: false },
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
    readonly: { type: Boolean, default: false, required: false },
  },
  setup(props, ctx) {
    const guid = ref(uuid.v4())
    const local = ref([0, 10])

    const elementId = computed(() => props.id || `range-slider-${guid.value}`)

    function change() {
      ctx.emit('change', local.value)
    }

    function input() {
      ctx.emit('input', local.value)
    }

    function setDefaults() {
      local.value = [props.min, props.max]
      input()
    }

    watch(
      () => props.max,
      () => {
        local.value = [local.value[0], props.max]
        input()
        change()
      },
      { immediate: true }
    )

    watch(
      () => props.min,
      () => {
        local.value = [props.min, local.value[1]]
        input()
        change()
      },
      { immediate: true }
    )

    watch(
      () => props.value,
      () => {
        if (!props.value || !props.value.length) {
          setDefaults()
          return
        }
        local.value = [props.value[0], props.value[1]]
      },
      { immediate: true }
    )

    return {
      elementId,
      local,
      change,
      input,
    }
  },
})
