





























import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'TabMenu',
  props: {
    value: { required: true, type: String, default: () => 'P' },
    disabled: { required: false, type: Boolean, default: false },
  },
  setup(props, ctx) {
    const currentTab = ref('P')

    watch(
      () => props.value,
      () => {
        if (props.value != currentTab.value) {
          currentTab.value = props.value
        }
      },
      { immediate: true }
    )

    watch(
      () => currentTab.value,
      () => {
        input()
      }
    )

    function input() {
      ctx.emit('input', currentTab.value)
    }

    return {
      currentTab,
    }
  },
})
