






















































import { defineComponent, computed, PropType } from '@vue/composition-api'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import ProgramIcon from '@/components/ProgramIcon.vue'
import date from '@/filters/date'
import ProgramOverview from '@/components/ProgramOverview.vue'
import SmartRouterLink from '@/elements/SmartRouterLink.vue'

export default defineComponent({
  name: 'ClinicOverview',
  components: {
    ProgramIcon,
    ProgramOverview,
    SmartRouterLink,
  },
  filters: {
    date,
  },
  props: {
    program: { type: Object as PropType<LeagueInfoCondensed>, required: true },
    showNameOnly: { type: Boolean, required: false, default: false },
    link: { type: String, required: false, default: '' },
  },
  setup(props) {
    const baseLink = computed(() => `/programs/clinic/${props.program.upwardLeagueID}`)

    const canEdit = computed(() => !props.program.isHistorical)

    return {
      baseLink,
      canEdit,
    }
  },
})
