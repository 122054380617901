


















import { defineComponent, computed, ref, onUnmounted } from '@vue/composition-api'
import FullBody from '@/components/FullBody.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'
import Loading from '@/elements/Loading.vue'

import store from '@/store'

import { Partner } from '@/models/Partner'
import { useFeatureFlags } from '@/services/useFeatureFlags'

export default defineComponent({
  name: 'CampIndex',
  components: {
    FullBody,
    HorizontalTabs,
    Loading,
    AlertCloak,
  },
  setup(props, ctx) {
    const currentCamp = computed(() => store.getters.camps.currentItem)
    const alertTypes = ref([AlertCloakTypes.PARTNER])
    const uncloak = ref(false)

    const loading = ref(false)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    //async created hack from https://stackoverflow.com/questions/64117116/how-can-i-use-async-await-in-the-vue-3-0-setup-function-using-typescript
    const run = (asyncFn: () => Promise<void>) => asyncFn()

    //private async created() {
    // Reset ministryAgreementSigned so that every time a camp
    // is loaded, it's campStartDate is checked.
    run(async () => {
      store.commit.authorization.resetMinistryAgreementIsSigned()

      if (ctx.root.$route.params.id) {
        loading.value = true
        await store.dispatch.camps.retrieveAndSetAsCurrent({ id: ctx.root.$route.params.id })
        await store.dispatch.partners.retrieveAndSetPartners({ id: ctx.root.$route.params.id })
        loading.value = false
      } else {
        // new camp, skip alerts
        uncloak.value = true
      }
    })
    //end created

    onUnmounted(() => {
      store.commit.partners.setPartners({ item: [] as Partner[] })
      store.commit.productFlow.clear()
      store.commit.leagueAbstraction.clear()
    })

    const tabs = computed(() => {
      const features = useFeatureFlags().features.value
      return [
        { text: 'Setup', to: `/programs/camp/${routeId.value}setup`, disabled: !id.value },
        { text: 'Participants', to: `/programs/camp/${routeId.value}participants`, disabled: !id.value },
        { text: 'Volunteers', to: `/programs/camp/${routeId.value}volunteers`, disabled: !id.value },
        {
          text: 'Divisions/Teams',
          to: `/programs/camp/${routeId.value}divisions_and_teams`,
          disabled: !id.value,
        },
        { text: 'Scheduling', disabled: !id.value },
        { text: 'Communications', to: `/programs/camp/${routeId.value}communications`, disabled: !id.value },
        features.LEAGUE_ORDERS
          ? { text: 'Ordering', to: `/programs/camp/${routeId.value}ordering`, disabled: !id.value }
          : {},
        { text: 'Reports', to: `/programs/camp/${routeId.value}reports`, disabled: !id.value },
        { text: 'Resources', to: `/programs/camp/${routeId.value}resources`, disabled: !id.value },
      ]
    })

    return {
      loading,
      alertTypes,
      id,
      uncloak,
      currentCamp,
      tabs,
    }
  },
})
