import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { RestServiceResult } from '@/services/restService'
import { ActionsImpl } from 'direct-vuex'

export function getGenericUpwardTypes<T>(clientMethod: () => Promise<RestServiceResult<T[]>>) {
  return {
    genericTypesState: {
      items: [],
    } as GenericTypeState<T>,
    getterTree: {
      [getterNames.allItems]: (state) => state.items,
      [getterNames.byUpwardTypeId]: (state) => (upwardTypeId: string) =>
        state.items.find((x) => x.upwardTypeID == upwardTypeId) ?? null,
      [getterNames.byId]: (state) => (id: number) => state.items.find((x) => x.id == id) ?? null,
    } as GetterTree<GenericTypeState<T>, RootState>,
    mutations: {
      [mutationNames.setAll](state, { items }) {
        state.items = items
      },
    } as MutationTree<GenericTypeState<T>>,
    actions: {
      async [actionNames.fetchAll]({ commit, getters }, { force = false }): Promise<boolean> {
        if (getters[getterNames.allItems].length && !force) {
          return false
        }

        const result = await clientMethod()

        if (result.isSuccess) {
          const items = result.data
          commit(mutationNames.setAll, { items })
          return true
        }

        return false
      },
    } as ActionTree<GenericTypeState<T>, RootState> & ActionsImpl,
  }
}

export type UpwardTypeBase<T = {}> = {
  id: number
  upwardTypeID: string
} & T

export interface GenericTypeState<T> {
  items: UpwardTypeBase<T>[]
}

export enum getterNames {
  allItems = 'allItems',
  byUpwardTypeId = 'byUpwardTypeId',
  byId = 'byId',
}

export enum mutationNames {
  setAll = 'setAll',
}

export enum actionNames {
  fetchAll = 'fetchAll',
}

export const namespace = 'genericUpwardTypes'
