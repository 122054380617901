




























import { computed, defineComponent, PropType } from '@vue/composition-api'
import currency from '@/filters/currency'
import { CFormatOrder } from '@/models/Order/xStartupOrderExt'

export default defineComponent({
  name: 'CostSummary',
  props: {
    order: { type: Object as PropType<CFormatOrder>, required: true },
  },
  setup(props) {
    const subTotal = computed(() => {
      const subTotal = (props.order?.orderLines || (props.order as CFormatOrder)?.orderLinesExt)
        ?.map((x) => x.extendedCost)
        .reduce((x, a) => a + x, 0)
      return subTotal
    })

    const discount = computed(() => props.order?.longevityDiscountAmount ?? 0)

    const shipping = computed(
      () => props.order.shippingInfo?.shipMethods?.filter((x) => x.selected == true)[0].cost ?? 0
    )

    const total = computed(() => subTotal.value + shipping.value + salesTax.value - discount.value)

    const salesTax = computed(() => props.order.salesTax)

    return {
      subTotal,
      discount,
      shipping,
      salesTax,
      total,
      currency,
    }
  },
})
