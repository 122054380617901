/**
 * Store contains minimum fields for at least camp and league. For league orders this simplifies
 * some of the special casing we use to make an order. Later, if this works, we might be able to
 * extend it elsewhere where basics like account number and league id are necessary.
 *
 */
import { defineMutations, defineGetters } from 'direct-vuex'
import store from '@/store'
import {
  campToAbstraction,
  connectToAbstraction,
  clinicToAbstraction,
  getEmptyLeagueAbstraction,
  LeagueAbstraction,
  leagueToAbstraction,
} from '@/models/LeagueAbstraction/LeagueAbstraction'
import { League } from '@/GeneratedTypes/League'
import { Camp } from '@/GeneratedTypes/Camp'
import { Connect } from '@/GeneratedTypes/Connect'
import { Clinic } from '@/GeneratedTypes/Clinic'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { LeagueTypes } from '@/lib/common/LeagueTypes'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import { isCheer } from '@/lib/common/ProgramTypes'

export enum ProgramTypes {
  CAMP = 'CAMP',
  LEAGUE = 'LEAGUE',
}

interface LeaguesState {
  currentItem: LeagueAbstraction
  hasCheer: boolean | null
  leagueUpwardProgramTypes: UpwardProgramTypeID[]
  programType: UpwardOpportunityTypes
}

function initState() {
  return {
    currentItem: getEmptyLeagueAbstraction(),
    hasCheer: null,
    leagueUpwardProgramTypes: [],
    programType: UpwardOpportunityTypes.LEAGUE,
  } as LeaguesState
}
const leaguesState = initState()

/**
 * mostly use the currentItem.
 */
export enum getterNames {
  currentItem = 'currentItem',
  hasCheer = 'hasCheer',
  shortPrograms = 'shortPrograms',
  isInPartnership = 'isInPartnership',
  userIsLeagueOwner = 'useIsLeagueOwner',
  leagueUpwardProgamTypes = 'leagueUpwardProgramTypeID',
  programType = 'programType',
  isUpwardSelect = 'isUpwardSelect',
  isByAge = 'isByAge',
}

const getterTree = defineGetters<LeaguesState>()({
  currentItem: (state) => state.currentItem,
  hasCheer: (state): boolean => {
    if (state.currentItem.programs) {
      return state.currentItem.programs.length > 1
    } else {
      return false
    }
  },
  isInPartnership: (state) => {
    const accounts = state.currentItem.accounts
    if (accounts) {
      return accounts.length > 1
    }
    return false
  },
  userIsLeagueOwner: (state) => {
    //The user who is logged in is a member of the account that owns this league
    if (!getterTree.isInPartnership) return true
    const loggedInAccount = store.getters.authorization.firstAccountNumber
    const owner = state.currentItem.accounts?.find((a) => a.accountNumber == loggedInAccount && a.leagueOwner)
    if (owner) return true
    return false
  },
  shortPrograms: (state) =>
    state.currentItem.programs?.map((p) => {
      const cheer = 'Cheer'
      const typeId = p.typeProgramID
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
      return { label: isCheer(p.typeProgramID) ? cheer : typeId, id: p.typeProgramID }
    }) as { label: string; id: string }[],
  leagueUpwardProgramTypeID: (state) => {
    return getLeagueProgramTypes(state.currentItem)
  },
  programType: (state) => {
    return state.programType
  },
  refLabel: (state) => {
    return state.currentItem.roles?.find((x) => x.id == RolesEnum.REFEREE)?.description ?? 'Referee'
  },
  isUpwardSelect: (state) => {
    return state.currentItem.typeLeagueID === LeagueTypes.UPWARDSELECT
  },
  isByAge: (state) => {
    return state.currentItem.ageCutoffDate !== null
  },
})

export enum leagueAbstractionMutationNames {
  setCurrentLeague = 'setCurrentLeague',
  setCurrentCamp = 'setCurrentCamp',
  setLeagueUpwardProgramTypes = 'setLeagueUpwardProgramTypes',
}

const mutations = defineMutations<LeaguesState>()({
  setCurrentLeague(state, { item }: { item: League }) {
    state.currentItem = leagueToAbstraction(item)
    state.programType = UpwardOpportunityTypes.LEAGUE
    store.commit.gradeTypes.updateDescriptionsFromLeague({ descriptions: item.gradeDescriptions ?? [] })
  },
  setCurrentCamp(state, { item }: { item: Camp }) {
    state.currentItem = campToAbstraction(item)
    state.programType = UpwardOpportunityTypes.CAMP
    store.commit.gradeTypes.updateDescriptionsFromLeague({ descriptions: item.gradeDescriptions ?? [] })
  },
  setCurrentConnect(state, { item }: { item: Connect }) {
    state.currentItem = connectToAbstraction(item)
    state.programType = UpwardOpportunityTypes.CONNECT
    store.commit.gradeTypes.updateDescriptionsFromLeague({ descriptions: item.gradeDescriptions ?? [] })
  },
  setCurrentClinic(state, { item }: { item: Clinic }) {
    state.currentItem = clinicToAbstraction(item)
    state.programType = UpwardOpportunityTypes.CLINIC
    store.commit.gradeTypes.updateDescriptionsFromLeague({ descriptions: item.gradeDescriptions ?? [] })
  },
  clear(state) {
    Object.assign(state, initState())
  },
})

function getLeagueProgramTypes(item: LeagueAbstraction): UpwardProgramTypeID[] {
  const retval: UpwardProgramTypeID[] = []
  item.programs?.forEach((x) => {
    const prog = store.getters.programTypes.byUpwardTypeId(x.typeProgramID)
    if (prog) {
      retval.push(prog)
    }
  })
  return retval
}

export const leagueAbstractionNamespace = 'leagueAbstraction'

export const leagueAbstraction = {
  namespaced: true as true,
  state: leaguesState,
  getters: getterTree,
  mutations,
}
