




import { defineComponent, onMounted } from '@vue/composition-api'
import store from '@/store'

export default defineComponent({
  name: 'Index',
  setup() {
    onMounted(async () => {
      //go ahead and get some key UpwardTypes loaded so that they are ready when needed
      await store.dispatch.leagueTypes.fetchAll(false)
      await store.dispatch.programTypes.fetchAll({ force: false })
      await store.dispatch.colorTypes.fetchAll({ force: false })
      await store.dispatch.gradeTypes.fetchAll(false)
    })

    return {}
  },
})
