


















































import { defineComponent, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import SearchInput from '@/elements/SearchInput.vue'
import { OrderHistorySearchByEnum } from '@/models/Dashboard/OrderHistory'
import { useHoldDialog, useOrderHistory } from '@/views/AdminDash/vues/OrderHistory/useOrderHistory'
import OrderItem from '@/views/AdminDash/vues/OrderHistory/OrderItem.vue'
import Loading from '@/elements/Loading.vue'
import HoldReasonDialog from '@/views/AdminDash/vues/OrderHistory/HoldReasonDialog.vue'
import { OrderFindInfo } from '@/GeneratedTypes/Order/ListInfo/OrderFindInfo'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'

export default defineComponent({
  components: { HoldReasonDialog, Loading, OrderItem, Alert, RadioGroupInput, SearchInput },
  setup(p, ctx) {
    const searchByList = [
      { id: OrderHistorySearchByEnum.ORDER_ID, label: 'By Order ID' },
      { id: OrderHistorySearchByEnum.ACCOUNT_NUMBER, label: 'Account Number' },
      { id: OrderHistorySearchByEnum.HOLDS, label: 'Show On Holds' },
      { id: OrderHistorySearchByEnum.PARTIALS, label: 'Show Partially Shipped' },
    ]
    const searchBy = ref(OrderHistorySearchByEnum.ORDER_ID.toString())
    const searchTerm = ref('')
    const uh = useOrderHistory()
    function runSearch() {
      const val = parseInt(searchBy.value)
      if (val == OrderHistorySearchByEnum.ORDER_ID) {
        uh.searchOrders({ upwardOrderID: searchTerm.value })
      } else if (val == OrderHistorySearchByEnum.ACCOUNT_NUMBER) {
        uh.searchOrders({ accountNumber: searchTerm.value })
      } else if (val == OrderHistorySearchByEnum.HOLDS) {
        uh.searchOrders({ typeOrderStatusID: 'ONHOLD' })
      } else {
        uh.searchOrders({ typeOrderStatusID: 'PARTIALLY_SHIPPED' })
      }
    }
    const errorMessage = ref('')

    async function clicked(o: OrderInfoExt) {
      await ctx.root.$router.push(`admin-dashboard/order-detail/${o.upwardLeagueID}/${o.upwardOrderID}`)
    }

    const uho = useHoldDialog()
    const releaseMessage = ref('')
    const showReleaseMessage = ref(false)
    async function releaseOrder(o: OrderFindInfo, reason: string) {
      try {
        await uho.release(o, reason)
        releaseMessage.value = 'Order Released'
        showReleaseMessage.value = true
      } catch (e) {
        throw e
      } finally {
        runSearch()
      }
    }

    return {
      ...uh,
      releaseOrder,
      showReleaseMessage,
      releaseMessage,
      alertType: AlertTypeEnum.NOTICE,
      ...uho,

      searchBy,
      searchTerm,
      searchByList,
      errorMessage,
      clicked,
      runSearch,
    }
  },
})
