


















import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'
import { isEqual } from 'lodash'

export default defineComponent({
  name: 'DayOfWeekMultiPicker',
  components: {
    CheckboxGroupInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    value: { type: Array as PropType<string[]>, required: true },
    horizontal: { type: Boolean, default: false, required: false },
  },
  setup(props, ctx) {
    const internalValue = ref<string[]>(props.value)

    const options = ref([
      { value: 'Sunday', text: 'Su' },
      { value: 'Monday', text: 'M' },
      { value: 'Tuesday', text: 'Tu' },
      { value: 'Wednesday', text: 'W' },
      { value: 'Thursday', text: 'Th' },
      { value: 'Friday', text: 'F' },
      { value: 'Saturday', text: 'Sa' },
    ])

    function handleChange() {
      if (!isEqual(internalValue.value, props.value)) {
        ctx.emit('input', internalValue.value)
        ctx.emit('change', internalValue.value)
      }
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    return {
      internalValue,
      options,
      handleChange,
    }
  },
})
