import { GridColumn, GridColumnAlignEnum } from '@/models/Grid/GridColumn'
import { PlayerEvaluation } from '@/GeneratedTypes/PlayerEvaluation'

type OurEventType<T> = (r: T, newval: number) => Promise<void> | void

function templateClosure(event: OurEventType<PlayerEvaluation>) {
  return () => ({
    template: {
      props: { data: {} },

      template: `
            <div @click.stop>
              <input
                onClick="this.select()"
                class="form-control text-right"
                type="number"
                step="any"
                :max="data.maximumValue"
                :min="data.minimumValue"
                :value="data.activityValue"
                @change="sendEvent" />
            </div>
        `,
      methods: {
        sendEvent(e: MouseEvent) {
          e.stopPropagation()
          e.preventDefault()
          //@ts-ignore -- data is a magic method on this template
          event(this.data && this.data, parseFloat((e.target as HTMLInputElement).value))
        },
      },
    },
  })
}

export const EvaluationEditGridColumns = (event: OurEventType<PlayerEvaluation>): GridColumn[] => [
  {
    field: 'activityText',
    headerText: 'Activity',
    textAlign: '',
    width: 200,
    format: '',
  } as GridColumn,
  {
    field: 'activityValue',
    headerText: 'Score',
    textAlign: GridColumnAlignEnum.RIGHT,
    width: 0,
    template: templateClosure(event),
  } as GridColumn,
]
