import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { computed, reactive, UnwrapRef } from '@vue/composition-api'
import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { useParticipants } from '@/views/Programs/Divisions/TeamManagement/logic/participants'
import { useTeams } from '@/views/Programs/Divisions/common/js/teams'
import { useCoaches } from '@/views/Programs/Divisions/common/js/coaches'
import { CoachToTeamNumberType } from '@/views/Programs/Divisions/TeamManagement/types'
import { difference } from 'lodash'
import { useFacilities } from '@/views/Programs/Divisions/TeamManagement/logic/facilities'
import upwardTypesClient from '@/clients/upwardTypesClient'
import { UpwardTapeDownReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardTapeDownReasonTypeID'

interface ManagerState {
  division: LeagueDivisionInfo
  programID: string
  participantsInTeams: number[]
  teamsOffField: number[]
  coachToTeams: CoachToTeamNumberType[]
  tapeDownReasons: UpwardTapeDownReasonTypeID[]
  refreshing: { working: boolean }
  currentView: string
  coachRefreshing: boolean
  participantsRefreshing: boolean
  assignedParticipantSorting: string
}

const state: ManagerState = {
  division: getEmptyLeagueDivisionInfo(),
  programID: '',
  coachToTeams: [],
  participantsInTeams: [],
  teamsOffField: [],
  tapeDownReasons: [],
  refreshing: { working: false },
  currentView: 'P',
  coachRefreshing: false,
  participantsRefreshing: false,
  assignedParticipantSorting: 'lastName',
}
/**
 * manage state with in module scope
 */
let stateRef: UnwrapRef<ManagerState>
let participantOps: ReturnType<typeof useParticipants>
let teamOps: ReturnType<typeof useTeams>
let coachOps: ReturnType<typeof useCoaches>
let facilityOps: ReturnType<typeof useFacilities>
export function useManagerLogic() {
  if (!stateRef) {
    stateRef = reactive(state) as UnwrapRef<ManagerState>

    participantOps = useParticipants()
    teamOps = useTeams()
    coachOps = useCoaches()
    facilityOps = useFacilities()
  }

  async function unassignDivisionParticipant(teams: DivisionTeamInfo[], unassignLocked: boolean) {
    await teamOps.unassignAllDivisionParticipants(teams, unassignLocked)
    if (teams.length) {
      await teamOps.refreshTeams(
        teams[0].upwardLeagueID ?? '',
        stateRef.division.divisionID,
        stateRef.programID
      )
    }
    teamOps.clearOperationProgress()
  }

  async function refresh() {
    stateRef.refreshing.working = true
    const a: Promise<unknown>[] = []
    if (!stateRef.tapeDownReasons.length) {
      stateRef.tapeDownReasons = await upwardTypesClient.retrieveTapeDownReasons()
    }
    // do we have an actual division object?
    if (stateRef.division?.divisionID > 0) {
      a.push(facilityOps.refreshFacilities(stateRef.division?.upwardLeagueID ?? ''))
      a.push(
        teamOps.refreshTeams(
          stateRef.division?.upwardLeagueID ?? '',
          stateRef.division.divisionID,
          stateRef.programID
        )
      )
      if (stateRef.currentView == 'C') {
        a.push(coachOps.refreshCoaches(stateRef.division?.upwardLeagueID ?? ''))
      }

      if (stateRef.currentView == 'P') {
        a.push(
          participantOps.refreshParticipants(stateRef.division?.upwardLeagueID ?? '', stateRef.programID)
        )
      }

      // wait on elements to update
      Promise.all(a).then(() => (stateRef.refreshing.working = false))

      teamOps.clearOperationProgress()
    }
  }
  const setDivisionAndRefresh = async (division: LeagueDivisionInfo | undefined) => {
    if (!division) return
    stateRef.division = division
    stateRef.programID = division.typeProgramID ?? ''
    await refresh()
  }

  const setCurrentView = async (view: string) => {
    // P = particiapnt; C = coach
    if (view == stateRef.currentView) return // stop unnecessary refresh
    stateRef.currentView = view
    if (stateRef.currentView == 'C' && stateRef.division?.upwardLeagueID && !stateRef.refreshing.working) {
      stateRef.coachRefreshing = true
      await coachOps.refreshCoaches(stateRef.division?.upwardLeagueID ?? '')
      stateRef.coachRefreshing = false
    }

    if (stateRef.currentView == 'P' && stateRef.division?.upwardLeagueID && stateRef.programID) {
      stateRef.participantsRefreshing = true
      await participantOps.refreshParticipants(stateRef.division?.upwardLeagueID ?? '', stateRef.programID)
      stateRef.participantsRefreshing = false
    }
  }

  const participantsInTeams = computed(() => {
    let rv: number[] = []
    teamOps.teams.value.forEach((x) => {
      if (x && x.players) {
        rv = [...rv, ...x.players.map((y) => y.individualID)]
      }
    })
    return rv
  })

  /**
   * Toggles a team from the manager field and back to the list
   * @param teamID
   */
  function toggleTeam(teamID: number) {
    const i = stateRef.teamsOffField.indexOf(teamID)
    if (i >= 0) {
      stateRef.teamsOffField.splice(i, 1)
    } else {
      stateRef.teamsOffField.push(teamID)
    }
  }

  /**
   * Sets all the teams that are on the team field.
   * Useful for dragging or loading new teams.
   * @param teams
   */
  function setTeamsOnField(teams: number[]) {
    stateRef.teamsOffField = difference(
      teamOps.teams.value.map((x) => x.teamID),
      teams
    )
  }

  const teamsOnField = computed(() =>
    teamOps.teams.value.filter((x) => stateRef.teamsOffField.indexOf(x.teamID) < 0)
  )

  const participants = computed(() => participantOps.participants.value)

  const assignedParticipantCount = computed(() => {
    return teamOps.teams.value.reduce((cnt, t) => {
      return (t.players?.length ?? 0) + cnt
    }, 0)
  })

  async function addCoachToTeam(teamID: number, coach: LeagueCoachProgramInfoFLAT) {
    await teamOps.addCoachToTeam(teamID, coach)
    coachOps.incrementCoachTeamCount(coach.individualID, 1)
  }

  function removeCoach(teamID: number, coachID: number) {
    teamOps.removeCoach(teamID, coachID)
    coachOps.incrementCoachTeamCount(coachID, -1)
  }

  return {
    participantsLoading: computed(() => participantOps.loading.value),
    teamsLoading: computed(() => teamOps.loading.value),
    teamStructureChanging: teamOps.teamStructureChanging,
    teamsProgress: computed(() => teamOps.operationProgress.value),
    currentProgramID: computed(() => stateRef.programID),
    participantsInTeams,
    coaches: computed(() => coachOps.coaches.value),
    facilities: computed(() => facilityOps.facilities.value),
    tapeDownReasons: computed(() => stateRef.tapeDownReasons),
    refresh,
    setDivisionAndRefresh,
    setCurrentView,
    toggleTeam,
    setTeamsOnField,
    teamsOnField,
    unassignDivisionParticipant,
    addCoachToTeam,
    togglePlayerLock: teamOps.togglePlayerLock,
    togglePlayerPosition: teamOps.togglePlayerPosition,
    changeHeadCoach: teamOps.changeHeadCoach,
    removeCoach,
    removeParticipant: teamOps.removeParticipant,
    addParticipantToTeam: teamOps.addParticipantToTeam,
    participants,
    teams: computed(() => teamOps.teams.value),
    draftedParticipantCount: computed(() => assignedParticipantCount.value),
    refreshing: computed(() => stateRef.refreshing.working),
    coachRefreshing: computed(() => stateRef.coachRefreshing),
    participantsRefreshing: computed(() => stateRef.participantsRefreshing),
    assignedParticipantSort: computed(() => stateRef.assignedParticipantSorting),
  }
}
