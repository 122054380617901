import { RouteConfig } from 'vue-router'
import Index from '@/views/Programs/Index.vue'
import CreateOrEdit from '@/views/Programs/CreateOrEdit.vue'
import New from '@/views/Programs/New.vue'
import Welcome from '@/views/Programs/Welcome.vue'
import ChooseEditOrNew from '@/views/Programs/ChooseEditOrNew.vue'
import Estimator from '@/views/Estimate/Estimator.vue'

import connect from '@/router/connect'
import clinics from '@/router/clinics'
import camps from '@/router/camps'
import leagues from '@/router/leagues'
import digitalLeague from '@/router/digitalleague'
const baseName = 'Programs'

export default [
  {
    path: '/programs',
    component: Index,
    children: [
      {
        path: '/',
        component: CreateOrEdit,
        children: [
          {
            path: '/',
            name: `${baseName}-ChooseEditOrNew`,
            component: ChooseEditOrNew,
          },
          {
            path: 'new',
            name: `${baseName}-New`,
            component: New,
          },
          {
            path: 'welcome',
            name: `${baseName}-Welcome`,
            component: Welcome,
          },
          {
            path: 'estimator/:offeringConfigId?',
            name: `${baseName}-Estimator`,
            component: Estimator,
          },
        ],
      },
      ...clinics,
      ...connect,
      ...camps,
      ...leagues,
      ...digitalLeague,
    ],
  },
] as RouteConfig[]
