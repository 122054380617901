import { RouteConfig } from 'vue-router'
const OrdersIndex = () => import('@/views/Programs/Orders/OrdersIndex.vue')
const OrderLeague = () => import('@/views/Programs/Orders/OrderLeague.vue')
const Awards = () => import('@/views/Programs/Orders/Awards.vue')
const Returns = () => import('@/views/Programs/Orders/Returns.vue')
const ShippingTimelines = () => import('@/views/Programs/Orders/ShippingTimelines.vue')
const Exchanges = () => import('@/views/Programs/Orders/Exchanges.vue')
const OrderHistory = () => import('@/views/Programs/Orders/OrderHistory.vue')
const OrderFrame = () => import('@/views/Programs/Orders/OrderFrame.vue')
const OrderDetailPage = () => import('@/views/Programs/Orders/OrderDetailPage.vue')

import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import { idExpression } from '@/lib/support/router/idHelper'

export default function (programType: string) {
  programType = programType ?? 'league'
  let orderType = OrderTypesEnum.startup
  if (programType === 'clinic') {
    orderType = OrderTypesEnum.clinic
  }
  return [
    {
      path: 'ordering',
      component: OrdersIndex,
      children: [
        {
          path: 'league/:step',
          component: OrderLeague,
        },
        {
          path: 'league',
          redirect: `/programs/${programType}/${idExpression}/ordering/league/0`,
        },
        {
          path: 'awards',
          component: Awards,
        },
        {
          path: 'exchanges',
          component: Exchanges,
        },
        {
          path: 'returns',
          component: Returns,
        },
        {
          path: 'shippingtimelines',
          component: ShippingTimelines,
        },
        {
          path: 'history',
          component: OrderHistory,
        },
        {
          path: 'history/order-detail/:orderid',
          component: OrderDetailPage,
        },
        {
          path: 'products/:orderType/:step',
          component: OrderFrame,
        },
        {
          path: 'products/',
          redirect: `/programs/${programType}/${idExpression}/ordering/products/${orderType}/select`,
        },
        {
          path: '/',
          redirect: `/programs/${programType}/${idExpression}/ordering/shippingtimelines`,
        },
      ],
    },
  ] as RouteConfig[]
}
