




























import { defineComponent, computed, PropType } from '@vue/composition-api'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import currency from '@/filters/currency'
import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { isCheer } from '@/lib/common/ProgramTypes'

export default defineComponent({
  name: 'ProgramOverview',
  filters: {
    currency,
  },
  props: {
    program: { type: Object as PropType<LeagueInfoCondensed>, required: true },
    isCamp: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const showCounts = computed((): boolean => {
      const opportunityType = store.getters.leagueAbstraction.programType
      if (
        opportunityType == UpwardOpportunityTypes.CLINIC ||
        opportunityType == UpwardOpportunityTypes.CONNECT
      ) {
        return false
      }
      return true
    })

    const showAmounts = computed(
      (): boolean => props.program && props.program.isOPRLeague && !props.program.isHistorical
    )

    const hasCheer = computed((): boolean => props.program && isCheer(props.program.typeProgramID))

    const outstandingBalanceLink = computed(() => {
      if (props.isCamp) {
        return `/programs/camp/${props.program.upwardLeagueID}/reports/dynamic/outstandingbalance`
      }
      return `/programs/league/${props.program.upwardLeagueID}/reports/dynamic/outstandingbalance`
    })

    return {
      showCounts,
      hasCheer,
      showAmounts,
      outstandingBalanceLink,
    }
  },
})
