






















































































































































import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
  PropType,
  nextTick,
} from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import Modal from '@/components/Modal.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'

import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import DateInput from '@/elements/DateInput.vue'
import { League } from '@/GeneratedTypes/League'
import dayjs from 'dayjs'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { timeStringSplit } from '@/lib/support/utilities/time/time'
import { AvailabilityToScheduleBlocks } from '@/lib/support/models/ScheduleBlocks/AvailabilityToScheduleBlock'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'
import {
  getEmptyPracticeModalInfoType,
  PracticeModalInfoType,
} from '@/models/Scheduling/PracticeModalInfoType'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import TimePicker from '@/elements/TimePicker2.vue'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { getCoachNights } from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import Loading from '@/elements/Loading.vue'
import { cloneDeep } from 'lodash'
import store from '@/store'

export default defineComponent({
  name: 'PracticeModal',
  components: { Loading, TimePicker, DateInput, SelectInput, Modal },
  props: {
    teamList: { type: Array as PropType<DivisionTeamInfo[]>, default: () => [] },
    division: { type: Object as PropType<LeagueDivisionInfo>, default: () => getEmptyLeagueDivisionInfo() },
    practice: {
      type: Object as PropType<PracticeModalInfoType>,
      default: () => getEmptyPracticeModalInfoType(),
    },
    duration: { type: Number, default: 0 },
    facilityList: { type: Array as PropType<LeagueFacility[]>, default: () => [] },
    eventType: { type: String as PropType<FacilityEventEnum>, default: () => FacilityEventEnum.PRACTICES },
    loading: { type: Boolean, default: () => false },
    league: { type: Object as PropType<League>, default: () => getEmptyLeague() },
    programID: { type: String, default: () => '' },
    title: { type: String, default: () => 'Practice' },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)
    const storeLeague = computed(() => store.getters.leagues.currentItem)
    const day = ref(new Date())
    const startTime = ref('')
    const endTime = ref('')
    const facilityID = ref(0)
    const lastDay = ref<Date | null>(null)
    const teamID = ref(0)
    const id = ref(0)
    const unwatch = ref(() => {
      return
    })
    const repeat = ref(true)

    const changeType = ref('wholeSeason')

    const currentFacility = computed((): LeagueFacility | undefined =>
      props.facilityList?.find((x) => x.facilityID == facilityID.value)
    )

    /**
     * Return what we are scheduling
     */
    const practiceNoun = computed(() => {
      if (props.eventType == FacilityEventEnum.PRACTICES) {
        return 'practices'
      } else {
        return 'appointment'
      }
    })

    const futureNoun = computed(() => {
      if (props.eventType == FacilityEventEnum.PRACTICES) {
        return 'practices'
      } else {
        return 'games'
      }
    })

    const isNewPractice = computed(() => id.value === 0)

    const showPracticeExclusionsForNew = computed(() => {
      return (
        props.eventType == FacilityEventEnum.PRACTICES &&
        repeat.value &&
        props.division.exclusionDates &&
        props.division.exclusionDates.length > 0
      )
    })

    const showPracticeExclusions = computed(() => {
      return (
        props.eventType == FacilityEventEnum.PRACTICES &&
        changeType.value == 'wholeSeason' &&
        props.division.exclusionDates &&
        props.division.exclusionDates.length > 0
      )
    })

    const dayName = computed(() => {
      if (!day.value) {
        return ''
      }

      const dayObj = days.find((x) => x.value === day.value.getDay())
      return dayObj?.name || ''
    })

    const availability = computed(() => {
      const facility = currentFacility.value
      if (facility) {
        const myDay = day.value.getDay()
        //** contains the schedule blocks that are for this facility
        return AvailabilityToScheduleBlocks(facility?.availability ?? [], props.eventType)?.filter(
          (x) => x.day == myDay
        )
      }
      return []
    })

    function enableLastDay() {
      const end = timeStringSplit(endTime.value)
      if (!repeat.value) {
        lastDay.value = dayjs(day.value).hour(end[0]).minute(end[1]).toDate()
      } else {
        lastDay.value = storeLeague.value.seasonEndDate || dayjs(day.value).add(12, 'week').toDate()
      }
    }

    const practiceValue = computed(
      (): PracticeModalInfoType => {
        const start = timeStringSplit(startTime.value)
        const end = timeStringSplit(endTime.value)
        return {
          id: id.value,
          startDate: dayjs(day.value).hour(start[0]).minute(start[1]).toDate(),
          endDate: dayjs(day.value).hour(end[0]).minute(end[1]).toDate(),
          teamID: teamID.value,
          lastDate: lastDay.value,
          facilityID: facilityID.value,
          updateSeason: changeType.value === 'wholeSeason',
        }
      }
    )

    function startTimeChanged() {
      endTime.value = interval(startTime.value, props.duration)
    }

    onMounted(() => {
      nextTick(() => {
        unwatch.value = watch(() => startTime.value, startTimeChanged)
        enableLastDay()
      })
    })

    onUnmounted(() => {
      unwatch.value()
    })

    watch(
      () => props.practice,
      () => {
        id.value = props.practice.id
        day.value = props.practice.endDate
        startTime.value = props.practice.startDate
          ? dayjs(props.practice.startDate).format('HH:mm')
          : '8:00AM'
        endTime.value = props.practice.endDate ? dayjs(props.practice.endDate).format('HH:mm') : ''
        teamID.value = props.practice.teamID
        facilityID.value = props.practice.facilityID
        repeat.value = true
        if (isNewPractice.value) {
          enableLastDay()
        } else {
          lastDay.value = props.practice.lastDate
        }
      },
      { immediate: true, deep: true }
    )

    function interval(time: string, duration: number) {
      const start = timeStringSplit(time)
      return dayjs().hour(start[0]).minute(start[1]).add(duration, 'minutes').format('HH:mm')
    }

    const shouldSubmitShow = computed(() => {
      if (
        practiceValue.value.facilityID &&
        practiceValue.value.teamID &&
        !isNaN(practiceValue.value.endDate.valueOf()) &&
        !isNaN(practiceValue.value.startDate.valueOf())
      ) {
        return true
      }

      return false
    })

    function shortDateFormat(val: Date) {
      return dayjs(val).format('MMM D')
    }

    /**
     * Caller will need to close the box.
     */
    function confirmed() {
      ctx.emit('confirmed', cloneDeep(practiceValue.value))
    }

    /**
     * Caller will need to close the box.
     */
    function deleted() {
      ctx.emit('deleted', cloneDeep(practiceValue.value))
    }

    function cancel() {
      closeModal()
    }

    return {
      isModalOpen,
      dayjs,
      getCoachNights,
      dayName,
      isNewPractice,
      practiceNoun,
      teamID,
      facilityID,
      day,
      id,
      availability,
      futureNoun,
      startTime,
      endTime,
      repeat,
      enableLastDay,
      showPracticeExclusionsForNew,
      shortDateFormat,
      lastDay,
      changeType,
      showPracticeExclusions,
      shouldSubmitShow,
      deleted,
      cancel,
      confirmed,
    }
  },
})
