

































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import Card from '@/components/Card.vue'
import RegistrationTransactionsPanel from '@/views/AdminDash/vues/RegistrationTransactionsPanel.vue'
import UserManagementPanel from '@/views/AdminDash/vues/UserManagementPanel.vue'
import RecentOrderHistoryPanel from '@/views/AdminDash/vues/RecentOrderHistoryPanel.vue'
import ReturnsOrderPanel from '@/views/AdminDash/vues/ReturnsOrderPanel.vue'
import PartnerSearchPanel from '@/views/AdminDash/vues/PartnerSearchPanel.vue'
import InfoModal from '@/components/InfoModal.vue'
import store from '@/store'
import ProgramSearchPanel from '@/views/AdminDash/vues/ProgramSearchPanel.vue'
import { AlertInfo } from '@/GeneratedTypes/ListInfo/AlertInfo'
import alertClient from '@/clients/alertClient'

export default defineComponent({
  components: {
    Card,
    ProgramSearchPanel,
    PartnerSearchPanel,
    RecentOrderHistoryPanel,
    ReturnsOrderPanel,
    UserManagementPanel,
    RegistrationTransactionsPanel,
    InfoModal,
  },

  setup(p, ctx) {
    const disabled = ref(false)
    const adminAlerts = ref<AlertInfo[] | null>(null)
    const showAdminAlertsModal = ref(false)

    // quick guard of this component.
    onMounted(async () => {
      if (!store.getters.authorization.isCurrentUserAnAdmin) {
        disabled.value = true
      }

      adminAlerts.value = await alertClient.retrieveAdminAlerts()
      showAdminAlertsModal.value = adminAlerts.value != null && adminAlerts.value.length > 0
    })

    const isUserReallyASuperAdmin = computed(() => store.getters.authorization.isCurrentUserASuperAdmin)

    async function impersonate(payload: { userName?: string; accountNumber: string }) {
      await store.dispatch.authorization.impersonate(payload)
      await ctx.root.$router.push('/')
    }
    return { disabled, impersonate, isUserReallyASuperAdmin, showAdminAlertsModal, adminAlerts }
  },
})
