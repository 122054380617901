










import { defineComponent, ref } from '@vue/composition-api'
import PageContent from '@/components/PageContent.vue'

export default defineComponent({
  name: 'GrantApplication',
  components: {
    PageContent,
  },
  setup() {
    const cmsDocumentId = ref('grantApplication')

    return {
      cmsDocumentId,
    }
  },
})
