































import { defineComponent, ref, computed, watch, PropType, nextTick } from '@vue/composition-api'
import uuid from 'uuid'

import InputLabel from '@/elements/InputLabel.vue'
import { isEqual } from 'lodash'

export default defineComponent({
  name: 'DatesInput',
  components: {
    InputLabel,
  },
  props: {
    required: { type: Boolean, default: false, required: false },
    strictMode: { type: Boolean, default: false, required: false },
    label: { type: String, default: '', required: false },
    placeholder: { type: String, default: '', required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    showHelp: { type: Boolean, default: false, required: false },
    minDate: { type: (null as unknown) as PropType<Date | null>, default: () => null, required: false },
    maxDate: { type: (null as unknown) as PropType<Date | null>, default: () => null, required: false },
    value: { required: true, type: Array as PropType<Date[]> },
    allowedDateMethod: { type: Function, default: () => true, required: false },
    dateHasEventMethod: { type: Function, default: () => false, required: false },
    scrollToDate: { type: String, default: undefined, required: false },
  },
  setup(props, ctx) {
    const internalValue = ref<string[]>([])
    const inputId = ref('')

    function setInternalValue() {
      if (!props.value) {
        internalValue.value = []
      } else {
        internalValue.value = props.value.map((x) => x.toISOString().split('T')[0])
      }
    }

    //private created()
    setInternalValue()
    inputId.value = 'datepicker-' + uuid.v4()
    if (props.scrollToDate) {
      internalValue.value.push(props.scrollToDate)
      nextTick(() => internalValue.value.pop())
    }

    const internalValueAsString = computed(() => internalValue.value.map((x) => new Date(x + 'T00:00:00')))

    function handleChange() {
      ctx.emit('input', internalValueAsString.value)
    }

    watch(
      () => internalValue.value,
      () => {
        const intValue = internalValueAsString.value
        if (!isEqual(intValue, props.value)) {
          handleChange()
        }
      }
    )

    watch(
      () => props.value,
      () => {
        const intValue = internalValue.value.map((x) => new Date(x))
        if (!isEqual(intValue, props.value)) {
          setInternalValue()
        }
      }
    )

    return {
      inputId,
      internalValue,
    }
  },
})
