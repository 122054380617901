import { RouteConfig } from 'vue-router'
import Setup from '@/views/Connects/Setup.vue'
import Information from '@/views/Connects/Information.vue'
import ConnectIndex from '@/views/Connects/ConnectIndex.vue'
import orders from '@/router/orders'
import resources from './resources'
import { idExpression } from '@/lib/support/router/idHelper'
import Archive from '@/views/Connects/Archive.vue'
import Leadership from '@/views/Connects/Leadership.vue'

export default [
  {
    path: `connect/${idExpression}`,
    component: ConnectIndex,
    children: [
      {
        path: '/',
        redirect: `/programs/connect/${idExpression}/setup`,
      },
      {
        path: 'setup',
        component: Setup,
        children: [
          {
            path: '/',
            redirect: `/programs/connect/${idExpression}/setup/information`,
          },
          {
            path: 'information',
            component: Information,
          },
          {
            path: 'leadership',
            component: Leadership,
          },
          {
            path: 'archive',
            component: Archive,
          },
        ],
      },
      ...resources('Connect'),
      ...orders('connect'),
    ],
  },
] as RouteConfig[]
