














import { defineComponent, ref, watch } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'

export default defineComponent({
  name: 'PasswordInput',
  components: {
    TextInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    placeholder: { type: String, default: '', required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: 'lock', required: false },
    value: { type: String, required: true },
  },
  setup(props, ctx) {
    const internalValue = ref(props.value)

    function handleInput() {
      ctx.emit('input', internalValue.value)
    }

    function handleChange() {
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    return { internalValue, handleInput, handleChange }
  },
})
