
















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import Loading from '@/elements/Loading.vue'
import { getEmptyLeagueListItem, LeagueListItemSeed } from '@/lib/support/models/LeagueListItem/data'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import { League } from '@/GeneratedTypes/League'

import { namespace } from 'vuex-class'
import { getterNames as leagueGetters, namespace as leagueStoreName } from '@/store/leagues'
import {
  actionNames as facilitiesActions,
  getterNames as facilitiesGetters,
  namespace as facilitiesStoreName,
} from '@/store/facilities'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import {
  actionNames as divisionActions,
  getterNames as divisionGetters,
  mutationNames as divisionMutations,
  namespace as divisionNamespace,
} from '@/store/divisions'

import Calendar from '@/elements/Calendar.vue'
import HorizontalTabs, { TabType } from '@/components/HorizontalTabs.vue'
import HorizontalTabFilter, { FilterTabType } from '@/components/HorizontalTabFilter.vue'

import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import DivisionSelect from '@/components/DivisionSelect.vue'
import SelectInput from '@/elements/SelectInput.vue'
import dayjs from 'dayjs'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import {
  isNullDate,
  SERVER_DATE_FORMAT,
  toDate,
} from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import { UpdateEnlistedEventType } from '@/models/Scheduler'

import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'
import { AvailabilityToScheduleBlocks } from '@/lib/support/models/ScheduleBlocks/AvailabilityToScheduleBlock'
import { FacilityEventInfo } from '@/GeneratedTypes/ListInfo/FacilityEventInfo'
import PracticeModal from '@/views/Programs/Scheduling/Practices/PracticeModal.vue'
import { getCoachNights, getEmptyDivisionTeamInfo } from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import {
  getEmptyPracticeModalInfoType,
  PracticeModalInfoType,
} from '@/models/Scheduling/PracticeModalInfoType'
import { DivisionTeamPracticeAppointmentInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamPracticeAppointmentInfo'
import { undefinedIsZero } from '@/lib/support/utilities/numbers/undefinedIsZero'
import { DivisionTeamPracticeAppointmentInfoModified } from '@/models/Practices/DivisionTeamPracticeAppointmentInfoModified'
import store from '@/store'
import { cloneDeep } from 'lodash'
import { DeleteIcon, AddIcon } from '@/elements/Icons'
import CheckboxInput from '@/elements/CheckboxInput.vue'

import ScheduleDragItem from '@/views/Programs/Scheduling/Squads/ScheduleDragItem.vue'
import { CalendarData } from '@/elements/Calendar/types'
import * as colors from '@/views/Programs/Scheduling/ext/colors'
import { CoachConflictTypeEnum } from '@/models/TeamManagement/CoachConflictTypeEnum'

const league = namespace(leagueStoreName)
const facilities = namespace(facilitiesStoreName)

const divisions = namespace(divisionNamespace)

const WEEK_IN_SECONDS = 7 * 24 * 3600 * 1000
const START_DAY = 1 //monday

@Component({
  components: {
    ConfirmationModal,
    Alert,
    SelectInput,
    DivisionSelect,
    HorizontalTabs,
    HorizontalTabFilter,
    Calendar,
    TypeProgramTab,
    PracticeModal,
    DeleteIcon,
    AddIcon,
    CheckboxInput,
    Loading,
    ScheduleDragItem,
  },
  methods: {
    getCoachNights,
  },
})
export default class PracticeScheduler extends Vue {
  /** getters */
  @league.Getter(leagueGetters.currentItem) league!: League
  @facilities.Getter(facilitiesGetters.items) facilities!: LeagueFacility[]
  @divisions.Mutation(divisionMutations.setDivision) setDivision!: ({
    division,
  }: {
    division: LeagueDivisionInfo
  }) => void
  @divisions.Getter(divisionGetters.allItems) divisionList!: (s: UpwardLeagueIDType) => LeagueDivisionInfo[]
  @divisions.Getter(divisionGetters.currentDivision) currentDivision!: LeagueDivisionInfo
  @divisions.Action(divisionActions.fetchAll) fetchAllDivisions!: ({
    upwardLeagueId,
    force,
  }: {
    upwardLeagueId: UpwardLeagueIDType
    force: boolean
  }) => Promise<boolean>
  @facilities.Action(facilitiesActions.load)
  private readonly retrieveFacilities!: ({ id }: { id: UpwardLeagueIDType }) => LeagueFacility[] | null

  private readonly retrieveEvents = store.dispatch.scheduling.loadEvents

  private readonly loadTeams = store.dispatch.scheduling.loadTeams

  private showUnscheduledOnly = true

  addPractices = store.dispatch.scheduling.addPractices
  clearPractices = store.dispatch.scheduling.clearPractices

  deletePractices = store.dispatch.scheduling.deletePractice

  get coachConflictTypeEnum(): typeof CoachConflictTypeEnum {
    return CoachConflictTypeEnum
  }

  get statusText() {
    return store.getters.scheduling.statusText
  }
  get events() {
    return store.getters.scheduling.events
  }
  get teams() {
    return this.$store.direct.getters.scheduling.teams
  }

  get hasDivisions(): boolean {
    if (this.currentDivision) return true
    return false
  }

  get divisionLink() {
    return `/programs/league/${this.$route.params.id}/divisions_and_teams/divisions`
  }

  get teamLink() {
    return `/programs/league/${this.$route.params.id}/divisions_and_teams`
  }

  get practiceDuration(): number {
    if (this.currentDivision) return this.currentDivision.practiceLength
    return 0
  }

  /***
   * Actual program ID passed from index (e.g. FOOTBALL )
   */
  @Prop({ type: String, required: true })
  programID!: string

  @Prop({ type: Number, required: false, default: () => -1 })
  divisionID!: number

  alertType = AlertTypeEnum.NOTICE

  private loading = false
  private divisionLoading = true
  private loadingTeams = true

  private currentSelectedFacility = ''

  //** this is the epoch of the start of the week **/
  private week = 0
  private internalDivisionID = 0

  @Watch('divisionID', { immediate: true })
  private updateDivisionID() {
    this.internalDivisionID = this.divisionID
  }

  private get division(): LeagueDivisionInfo {
    return (
      this.practiceDivisionList.find((x) => x.divisionID == this.internalDivisionID) ??
      getEmptyLeagueDivisionInfo()
    )
  }

  /**
   * Filtered by current division
   */
  get teamsList() {
    return this.teams.filter((t) => t.divisionID == this.internalDivisionID)
  }

  get scheduleTeamsList() {
    let teams = cloneDeep(this.teamsList)

    if (this.showUnscheduledOnly) {
      teams = this.teamsList.filter((t) => !t.practices || t.practices?.length <= 0)
    }

    return teams.map((t) => {
      return {
        name: t.teamName,
        start: null,
        end: null,
        class: '',
        id: t.teamID,
        headCoachName: t.headCoachName,
        nights: getCoachNights(t),
        color: colors.eventBgColor,
        textColor: colors.eventTextColor,
        division: t.divisionName,
      }
    })
  }

  get practiceDivisionList() {
    return this.divisionList(this.leagueID)
  }

  @Watch('divisionSelect', { immediate: true })
  divisionSelectUpdated() {
    if (this.divisionSelect.length) {
      this.internalDivisionID = this.divisionSelect[0].divisionID
    }
  }

  @Watch('divisionID')
  divisionIDUpdated() {
    this.refreshTeams()
  }

  private currentPractice: PracticeModalInfoType = getEmptyPracticeModalInfoType()

  /**
   *  Make a practice using the team selected
   */
  makeNewPractice(practiceID?: number, teamID?: number): PracticeModalInfoType {
    let dateList: DivisionTeamPracticeAppointmentInfo[] = []
    const team = this.teams.find((x) => x.teamID == teamID ?? 0)
    const practices = cloneDeep(team?.practices)
    if (practices?.length) {
      dateList =
        practices?.sort((a, b) =>
          undefinedIsZero(a?.practiceStart?.valueOf()) > undefinedIsZero(b?.practiceStart?.valueOf()) ? 1 : -1
        ) ?? []
    }

    const lastDateOnSchedule = dateList.length
      ? dateList[dateList.length - 1].practiceStart ?? new Date()
      : new Date()
    const twelveWeeksLater = dayjs(this.league.firstPracticeDate ?? new Date())
      .add(12, 'week')
      .toDate()

    let lastDate =
      lastDateOnSchedule.valueOf() > twelveWeeksLater.valueOf() ? lastDateOnSchedule : twelveWeeksLater

    if (this.league.seasonEndDate) {
      lastDate = this.league.seasonEndDate
    }

    const defaultStartDt = this.league.firstPracticeDate ?? new Date()

    return {
      id: practiceID ?? 0,
      teamID: team?.teamID || 0,
      lastDate,
      startDate: dateList.length && dateList[0].practiceStart ? dateList[0].practiceStart : defaultStartDt,
      endDate: dateList.length && dateList[0].practiceEnd ? dateList[0].practiceEnd : new Date(),
      facilityID: this.currentFacilityID,
      updateSeason: false,
    }
  }

  eventClick(e: CalendarData) {
    this.eventSelected({
      id: e.id,
      event: { end: new Date(), start: new Date(), title: '' },
    })
  }

  /***
   * Event is selected from the calendar
   ***/
  private eventSelected(e: UpdateEnlistedEventType) {
    const event = this.events.find((x) => x.eventID == parseInt(this.eventid2id(e.id) ?? '0'))
    if (event) {
      const practice = this.teamPractices.find((x) => event.eventID == x.id)
      if (practice) {
        this.currentPractice = {
          ...this.makeNewPractice(parseInt(this.eventid2id(e.id) ?? '0'), practice.teamID),
          id: practice.id,
          teamID: practice.teamID,
          endDate: toDate(practice.practiceEnd) ?? new Date(), //practice end -- time as just a time in localtime
          startDate: toDate(practice.practiceStart) ?? new Date(), //practice start --local time
          facilityID: practice.facilityID,
        }
        this.practiceModalShowing = true
      }
    }
  }

  teamToClear = 0

  get teamsListAndAllTeams() {
    return [{ ...getEmptyDivisionTeamInfo(), teamID: 0, teamName: 'All Teams' }, ...this.teamsList]
  }

  confirmClearPracticesShowing = false
  clickClearPractices() {
    this.confirmClearPracticesShowing = true
  }
  async confirmClearPractices(b: boolean) {
    if (b) {
      this.confirmClearPracticesShowing = false
      this.showPracticesCleared = true
      await this.clearPractices({
        id: this.leagueID,
        divisionID: this.internalDivisionID,
        programID: this.programID,
        teamIDs: this.teamToClear ? [this.teamToClear] : this.divisionTeams.map((x) => x.teamID),
      })
    }
    this.confirmClearPracticesShowing = false
  }
  showPracticesCleared = false

  private practiceModalShowing = false

  /**
   * Button on the top right "+ Add Practice" clicked.
   */
  private clickAddPractice(start: Date | null = null) {
    this.currentPractice = this.makeNewPractice()

    if (start) {
      this.currentPractice.startDate = start
    } else {
      this.currentPractice.startDate = dayjs(this.currentPractice.startDate)
        .hour(8)
        .minute(0)
        .second(0)
        .toDate()
    }

    // add practice we will use default practice length
    this.currentPractice.endDate = dayjs(this.currentPractice.startDate)
      .add(this.division.practiceLength, 'm')
      .toDate()

    this.practiceModalShowing = true
  }

  // return the days of the week to display on the calendar as an array of
  // numbers (e.g. sunday = 0)
  get weekdays(): number[] {
    const facility = this.facilities.find((x) => x.facilityID == this.currentFacilityID)

    if (facility) {
      return AvailabilityToScheduleBlocks(facility.availability ?? [], this.event).map((f) => f.day)
    }
    return [0, 1, 2, 3, 4, 5, 6]
  }

  /** ID of currently selected facility **/
  get currentFacilityID() {
    return parseInt(this.currentSelectedFacility)
  }
  get currentFacility() {
    return this.currentSelectedFacility
  }
  set currentFacility(facility: string) {
    this.currentSelectedFacility = facility
  }

  get firstGameDate(): string {
    const practicedate = this.league.firstPracticeDate ?? new Date()
    return dayjs(practicedate).format('YYYY-MM-DD')
  }

  /***
   * Used to constrain the calendar
   *
   */
  get startEpoch(): number {
    const gamedate = this.league.firstPracticeDate ?? this.league.firstGameDate ?? new Date()
    return dayjs(gamedate).day(START_DAY).hour(0).minute(0).millisecond(0).valueOf() //get epoch of sunday before game day
  }

  /***
   * This logic will change per Thomas
   */
  get endEpoch(): number {
    // 16 WEEKS LATER
    return this.startEpoch + WEEK_IN_SECONDS * 16
  }

  @Watch('division')
  divisionUpdated() {
    this.refreshTeams()
  }

  @Watch('practiceModalShowing')
  private async onPracticeModalShowingChange() {
    if (!this.practiceModalShowing) {
      // Modal just closed
      await this.refreshEvents()
    }
  }

  /** -- these computed properties will set what games are visible in the UI **/

  /**
   * does the given game meet facility criteria
   */
  private meetsFacilityCriteria(game: FacilityEventInfo | DivisionTeamPracticeAppointmentInfo) {
    if (!this.currentFacilityID) {
      return true
    }
    return game?.facilityID == this.currentFacilityID
  }

  /**
   * does the given game meet division criteria?
   * */
  private meetsDivisionCriteria(game: FacilityEventInfo) {
    return game.divisionID == this.internalDivisionID
  }

  /**
   * Returns all games that are scheduled for the active facility, grey if not meeting
   * round and division criteria.
   */
  private get schedule(): CalendarData[] {
    return [
      ...this.events
        .filter((x) => !isNullDate(toDate(x.eventStart)) && this.meetsFacilityCriteria(x))
        .map((x) => ({
          start: dayjs(x.eventStart ?? '').format('YYYY-MM-DDTHH:mm'),
          end: dayjs(x.eventEnd ?? '').format('YYYY-MM-DDTHH:mm'),
          name: `${x.eventName}`,
          class: this.meetsFacilityCriteria(x) && this.meetsDivisionCriteria(x) ? 'active' : 'inactive',
          id: this.id2eventid(x.eventID.toString()),
          color: this.color(x, 'COLOR'),
          textColor: this.color(x, 'TEXT'),
          division: x.divisionName ?? '',
        })),
    ]
  }

  private color(e: FacilityEventInfo, type: 'COLOR' | 'TEXT') {
    if (
      e.typeFacilityEventID == FacilityEventEnum.SQUADS ||
      e.typeFacilityEventID == FacilityEventEnum.GAMES
    ) {
      return type == 'COLOR' ? colors.inactiveBgColor : colors.inactiveTextColor
    } else if (e.typeFacilityEventID == FacilityEventEnum.PRACTICES) {
      if (this.meetsDivisionCriteria(e)) {
        return type == 'COLOR' ? colors.eventBgColor : colors.eventTextColor
      } else {
        return type == 'COLOR' ? colors.eventInactiveBgColor : colors.eventInactiveTextColor
      }
    }
  }

  id2eventid(s: string) {
    return this.event + s
  }

  eventid2id(s: string) {
    if (!s) {
      return null
    }

    const rv = s.split(this.event)
    if (rv.length > 1) {
      return rv[1]
    }

    return null
  }

  private get teamPractices(): DivisionTeamPracticeAppointmentInfo[] {
    let rv: DivisionTeamPracticeAppointmentInfo[] = []
    this.teams
      .map((x) => x.practices)
      .forEach((x) => {
        if (x) {
          rv = [...rv, ...x]
        }
      })
    return rv
  }

  protected teamFromList(id: number) {
    return this.teams.find((x) => x.teamID == id) || getEmptyDivisionTeamInfo()
  }

  @Watch('endEpoch')
  changeInEndEpoch() {
    if (this.week > this.endEpoch) {
      this.week = dayjs(this.endEpoch).day(START_DAY).subtract(7, 'day').valueOf()
    }
  }

  @Watch('startEpoch')
  changeInStartEpoch() {
    if (this.week < this.startEpoch) {
      this.week = this.startEpoch
    }
  }

  // External event dragged onto the calendar
  eventDropped(ev: CalendarData) {
    const start = dayjs(ev.start)

    if (this.validDayOfWeek(start.day())) {
      const teamID = ev.id
      this.currentPractice = this.makeNewPractice(0, Number(teamID))
      this.currentPractice.startDate = start.toDate()
      this.currentPractice.endDate = dayjs(this.currentPractice.startDate)
        .add(this.division.practiceLength, 'm')
        .toDate()
      this.currentPractice.teamID = Number(teamID)
      this.currentPractice.lastDate = this.league.seasonEndDate
      this.practiceModalShowing = true
    }
  }

  validDayOfWeek(dayOfWeek: number) {
    return this.weekdays.some((d) => d == dayOfWeek)
  }

  /***
   * Receives a moved element on the calendar, time is correct when we get it on move.
   */
  eventMoved(e: CalendarData) {
    const practiceId = Number(this.eventid2id(e.id))

    if (!practiceId) {
      return
    }

    const event = this.events.find((x) => x.eventID === practiceId)
    const practice = this.teamPractices.find((x) => x.id === practiceId)

    if (!event || !practice) {
      return
    }

    const start = dayjs(e.start)
    const end = dayjs(e.end)
    this.currentPractice = {
      ...this.makeNewPractice(practiceId, practice.teamID),
      id: practice.id,
      teamID: practice.teamID,
      startDate: start.toDate(),
      endDate: end.toDate(),
      facilityID: practice.facilityID,
    }

    this.practiceModalShowing = true
  }

  showDeleteConfirmed = false
  async deletePractice(newelement: PracticeModalInfoType) {
    this.practiceSaving = true
    try {
      if (newelement.updateSeason) {
        await this.deleteFuturePractices(newelement)
      } else {
        const team = this.teams.find((x) => x.teamID == newelement.teamID)
        if (team && newelement.id) {
          await this.deletePractices({
            id: this.leagueID,
            teamID: team.teamID,
            practiceIDs: [newelement.id],
            programID: this.programID,
            divisionID: this.internalDivisionID,
          })
        }
      }
      this.practiceModalShowing = false
      this.showDeleteConfirmed = true
    } finally {
      this.practiceSaving = false
    }
  }

  basicPractice(t: PracticeModalInfoType): DivisionTeamPracticeAppointmentInfo {
    return {
      practiceEnd: new Date(),
      practiceStart: new Date(),
      teamID: t.teamID,
      facilityID: t.facilityID,
      id: 0,
      divisionID: this.internalDivisionID,
      facilityName: '',
      leagueID: this.league.id,
      typeProgramID: this.programID,
    }
  }

  async deleteFuturePractices(modalInfo: PracticeModalInfoType) {
    // get original start time.
    const eventStart = this.events.find((e) => e.eventID === modalInfo.id)?.eventStart
    if (eventStart) {
      const evtStart = new Date(eventStart)

      // find all future events for this team
      const eventsToUpdate = this.events.filter(
        (e) =>
          e.leagueID === this.league.id &&
          e.typeProgramID == this.programID &&
          e.divisionID == this.internalDivisionID &&
          e.facilityID == modalInfo.facilityID &&
          e.typeFacilityEventID == this.event &&
          e.eventStart &&
          new Date(e.eventStart).getHours() == evtStart.getHours() &&
          new Date(e.eventStart).getMinutes() == evtStart.getMinutes() &&
          new Date(e.eventStart) >= modalInfo.startDate
      )
      const eventsToDelete = eventsToUpdate.map((x) => x.eventID)
      if (eventsToDelete.indexOf(modalInfo.id) < 0) {
        eventsToDelete.push(modalInfo.id)
      }
      await this.deletePractices({
        id: this.leagueID,
        teamID: modalInfo.teamID,
        practiceIDs: eventsToDelete,
        programID: this.programID,
        divisionID: this.internalDivisionID,
      })
    }
  }

  /**
   * Used after a modal is confirmed
   */
  practiceSaving = false
  async confirmPractice(modalInfo: PracticeModalInfoType) {
    this.practiceSaving = true
    try {
      if (modalInfo.updateSeason) {
        await this.deleteFuturePractices(modalInfo)
        modalInfo.id = 0
      }

      //expressed in milliseconds
      const practiceLength = modalInfo.endDate.valueOf() - modalInfo.startDate.valueOf()

      if (modalInfo.id === 0) {
        const lastDate = modalInfo.lastDate ?? modalInfo.endDate
        const practices: DivisionTeamPracticeAppointmentInfoModified[] = []
        const activeStart = dayjs(modalInfo.startDate)
        const eodLastDay = dayjs(lastDate).hour(24).minute(0).toDate().valueOf()
        for (let week = 0; activeStart.add(week, 'week').toDate().valueOf() < eodLastDay; week++) {
          if (
            !(
              this.division.exclusionDates &&
              this.division.exclusionDates.find((x) =>
                activeStart.add(week, 'week').isSame(dayjs(x.excludeDate!), 'day')
              )
            )
          ) {
            practices.push({
              ...this.basicPractice(modalInfo),

              practiceStart: activeStart.add(week, 'week').format(SERVER_DATE_FORMAT),
              practiceEnd: activeStart
                .add(week, 'week')
                .add(practiceLength, 'millisecond')
                .format(SERVER_DATE_FORMAT),
            })
          }
        }
        await this.addPractices({ id: this.leagueID, programID: this.programID, practices })
      } else {
        // replaces a practice that was clicked on

        await this.addPractices({
          id: this.leagueID,
          programID: this.programID,
          practices: [
            {
              ...this.basicPractice(modalInfo),

              practiceStart: dayjs(modalInfo.startDate).format('YYYY-MM-DDTHH:mm:ss'),
              practiceEnd: dayjs(modalInfo.endDate).format('YYYY-MM-DDTHH:mm:ss'),
              id: modalInfo.id,
            },
          ],
        })
      }

      this.practiceModalShowing = false
    } catch (e) {
      throw e
    } finally {
      this.practiceSaving = false
    }
  }

  event = FacilityEventEnum.PRACTICES

  /***
   * Restricts tabs to the facilities that have programming for the given event
   */
  get facilitiesAsTabs(): TabType[] {
    return (
      this.facilitiesToFilter
        .filter((y) => y.selected)
        .map((x) => ({
          id: x.id,
          description: x.description,
        })) ?? []
    )
  }

  private facilitiesToFilter: FilterTabType[] = []
  private eventFacilities: LeagueFacility[] = []

  @Watch('facilities', { immediate: true })
  facilitiesUpdated() {
    this.facilitiesToFilter =
      this.facilities
        .filter((y) => (y.availability || []).findIndex((z) => z.typeFacilityEventID == this.event) >= 0)
        .map((x) => ({
          id: x.facilityID.toString(),
          description: x.facilityName,
          selected: true,
        })) ?? []
    this.eventFacilities = this.facilities.filter(
      (y) => (y.availability || []).findIndex((z) => z.typeFacilityEventID == this.event) >= 0
    )
  }

  facilityFilterUpdated(selectedFilters: string[]) {
    this.facilitiesToFilter.forEach((x) => {
      x.selected = selectedFilters.includes(x.id)
    })
  }

  refreshLeagues() {
    this.divisionLoading = true
    this.fetchAllDivisions({ upwardLeagueId: this.leagueID, force: true })
    this.divisionLoading = false
  }

  async refreshAll() {
    await this.refreshLeagues()
    await this.refreshTeams()
    await this.refreshEvents()
  }

  get divisionSelect() {
    return this.practiceDivisionList.filter((x) => x.typeProgramID == this.programID)
  }

  get divisionTeams() {
    return this.teams.filter((x) => x.divisionID == this.internalDivisionID)
  }

  get hasTeams() {
    if (this.loadingTeams) return -1
    if (this.teamsList.length) return 1
    return 0
  }

  /**
   * Get teams
   */
  private async refreshTeams() {
    this.loadingTeams = true
    if (this.programID) {
      await this.loadTeams({
        id: this.leagueID,

        programID: this.programID,
      })
    }
    this.loadingTeams = false
  }

  private async refreshEvents() {
    await this.retrieveEvents({ id: this.leagueID })
  }

  /**
   * Get teams
   */
  private async refreshFacilities() {
    await this.retrieveFacilities({ id: this.leagueID })
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */

  get leagueID() {
    return this.$route.params.id
  }

  public async mounted() {
    try {
      await this.refreshLeagues()
      await this.refreshFacilities()
      await this.refreshEvents()
    } finally {
      // !! used to emit actual fase value instead of aborting emit
      this.$emit('scheduleLoading', { val: false })
    }
  }

  get tabs(): LeagueListItem {
    if (this.league && this.league.programs) {
      return LeagueProgams2LeagueListItem(this.league.programs)
    }
    return getEmptyLeagueListItem(new LeagueListItemSeed())
  }

  @Watch('internalDivisionID', { immediate: true })
  private onSelectedItemChange() {
    if (this.internalDivisionID !== this.divisionID) {
      const division = this.practiceDivisionList.find((item) => item.divisionID === this.internalDivisionID)

      if (division) {
        this.setDivision({ division: division })
      }
    }
  }
}
