






import { defineComponent, ref, computed } from '@vue/composition-api'
import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import BlankParticipantEvalForm from '@/views/Programs/Reports/DynamicReports/BlankParticipantEvalForm.vue'
import ParticipantEvalForm from '@/views/Programs/Reports/DynamicReports/ParticipantEvalForm.vue'
import ParticipantEvalActivity from '@/views/Programs/Reports/DynamicReports/ParticipantEvalActivity.vue'
import CoachRoster from '@/views/Programs/Reports/DynamicReports/CoachRoster.vue'
import MailingLabels from '@/views/Programs/Reports/DynamicReports/MailingLabels.vue'
import Siblings from '@/views/Programs/Reports/DynamicReports/Siblings.vue'
import GameSchedule from '@/views/Programs/Reports/DynamicReports/GameSchedule.vue'
import GameScheduleByTeam from '@/views/Programs/Reports/DynamicReports/GameScheduleByTeam.vue'
import PracticeSchedule from '@/views/Programs/Reports/DynamicReports/PracticeSchedule.vue'
import ParticipantIntros from '@/views/Programs/Reports/DynamicReports/ParticipantIntros.vue'
import Payments from '@/views/Programs/Reports/DynamicReports/Payments.vue'
import PaymentsByType from '@/views/Programs/Reports/DynamicReports/PaymentsByType.vue'
import MissingEvals from '@/views/Programs/Reports/DynamicReports/MissingEvals.vue'
import OutstandingBalance from '@/views/Programs/Reports/DynamicReports/OutstandingBalance.vue'
import RefereeSchedule from '@/views/Programs/Reports/DynamicReports/RefereeSchedule.vue'
import PlayerConflict from '@/views/Programs/Reports/DynamicReports/PlayerConflict.vue'
import CoachConflict from '@/views/Programs/Reports/DynamicReports/CoachConflict.vue'
import store from '@/store'

export default defineComponent({
  name: 'DynamicReports',
  components: {
    ActiveReport,
    ParticipantEvalForm,
    ParticipantEvalActivity,
    BlankParticipantEvalForm,
    CoachRoster,
    MailingLabels,
    Siblings,
    GameSchedule,
    GameScheduleByTeam,
    PracticeSchedule,
    ParticipantIntros,
    Payments,
    PaymentsByType,
    MissingEvals,
    OutstandingBalance,
    RefereeSchedule,
    PlayerConflict,
    CoachConflict,
  },
  setup(props, ctx) {
    const reports = ref<{
      [reportName: string]: {
        name: string
        component: string
      }
    }>({
      blankevalform: {
        name: 'Blank Participant Evaluation Form',
        component: 'BlankParticipantEvalForm',
      },
      evalform: {
        name: 'Participant Evaluation Form',
        component: 'ParticipantEvalForm',
      },
      evalactivity: {
        name: 'Participant Eval Activity Scores',
        component: 'ParticipantEvalActivity',
      },
      missingevals: {
        name: 'Missing Evaluations',
        component: 'MissingEvals',
      },
      coaches: {
        name: 'Coaches Report',
        component: 'CoachRoster',
      },
      mailinglabels: {
        name: 'Mailing Labels (Avery 5160)',
        component: 'MailingLabels',
      },
      siblings: {
        name: 'Siblings Report',
        component: 'Siblings',
      },
      practice: {
        name: 'Practice Schedule',
        component: 'PracticeSchedule',
      },
      game: {
        name: 'Game Schedules',
        component: 'GameSchedule',
      },
      gamebyteam: {
        name: 'Game Schedules by Team',
        component: 'GameScheduleByTeam',
      },
      referee: {
        name: `${store.getters.leagueAbstraction.refLabel} Schedules`,
        component: 'RefereeSchedule',
      },
      intros: {
        name: 'Participant Introductions',
        component: 'ParticipantIntros',
      },
      payments: {
        name: 'Payments Report',
        component: 'Payments',
      },
      paymentsbytype: {
        name: 'Payments By Type Report',
        component: 'PaymentsByType',
      },
      outstandingbalance: {
        name: 'Outstanding Balance Report',
        component: 'OutstandingBalance',
      },
      playerconflict: {
        name: 'Player Requests Not Honored',
        component: 'PlayerConflict',
      },
      coachconflict: {
        name: 'Coach Conflicts',
        component: 'CoachConflict',
      },
    })

    const reportName = computed(() => ctx.root.$route.params.reportName)

    const reportComponent = computed(() => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return ''
      }
      return reports.value[reportName.value].component
    })

    return {
      reportComponent,
    }
  },
})
