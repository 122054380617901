



































































import { defineComponent, watch, onMounted } from '@vue/composition-api'
import { getProps, useTextlike } from '@/composables/TextLike'
import TextLikeWrapper from '@/elements/TextLikeWrapper.vue'

export default defineComponent({
  name: 'TextInput',
  components: {
    TextLikeWrapper,
  },
  props: {
    tabIndex: { type: String, default: '', required: false },
    inputType: { type: String, default: 'text', required: false },
    maxlength: { required: false },
    placeholder: { type: String, default: '', required: false },
    underlineOnly: { type: Boolean, default: false, required: false },
    value: { type: String, default: '', required: false },
    setFocus: { type: Boolean, default: false, required: false },
    ...getProps(),
  },
  setup(props, ctx) {
    const { iconClick, internalValue, handleInput, handleChange, handleBlur } = useTextlike<string>(
      props,
      ctx
    )

    function setInternalValue() {
      internalValue.value = props.value
    }

    watch(
      () => props.setFocus,
      (b: boolean) => {
        if (b) {
          const elementToFocus = ctx.refs.inputField as HTMLInputElement
          if (elementToFocus) {
            elementToFocus.focus()
          }
        }
      },
      { immediate: true }
    )

    watch(
      () => props.value,
      () => {
        setInternalValue()
      },
      { immediate: true }
    )

    onMounted(() => {
      setInternalValue()
    })

    return {
      iconClick,
      internalValue,
      handleInput,
      handleChange,
      handleBlur,
    }
  },
})
