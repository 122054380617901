import * as gradeStore from '@/store/gradeTypes'

function getAllGrades(): string[] {
  return gradeStore.gradeTypes.state.items.map((x) => x.upwardTypeID!)
}

function getAllGradeDescriptions(): string[] {
  return gradeStore.gradeTypes.state.items.map((x) => x.description!)
}

function getAllAges(): string[] {
  return gradeStore.gradeTypes.state.items.map((x) => x.ageByCutoff!.toString())
}

export const initialGrades: string[] = ['K5', '1st', '2nd', '3rd', '4th', '5th']

export function getGrades(includeInvalid?: boolean): string[] {
  if (includeInvalid) {
    return ['Invalid', ...getAllGrades()]
  }
  return getAllGrades()
}

export function getGradeDescriptions(includeBlank?: boolean): string[] {
  if (includeBlank) {
    return ['', ...getAllGradeDescriptions()]
  }
  return getAllGradeDescriptions()
}

export function getAges(includeInvalid?: boolean): string[] {
  if (includeInvalid) {
    return ['Invalid', ...getAllAges()]
  }
  return getAllAges()
}

export function getDefaultGrades(includeInvalid?: boolean): string[] {
  if (includeInvalid) {
    return ['Invalid', ...initialGrades]
  }
  return initialGrades
}
