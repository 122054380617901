











































































import CampSetupMixin from '@/views/Camps/CampSetupMixin'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { cloneDeep } from 'lodash'

import Loading from '@/elements/Loading.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import CurrencyWarning from '@/components/ProductFlow/ProductSetupStepper/CurrencyWarning.vue'
import SetupStepperCamp from '@/components/ProductFlow/ProductSetupStepper/SetupStepperCamp.vue'
import CurrencyInput from '@/elements/CurrencyInput.vue'
import ProductWarningModal from '@/views/Leagues/ProductWarningModal.vue'
import SetupCompleteConfirmation from '@/components/SetupCompleteConfirmation.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'

import { ValidationError } from 'src/models/UpwardVerificationDetails'

import * as campsStore from '@/store/camps'
import * as leagueAbstraction from '@/store/leagueAbstraction'
import * as promptToSave from '@/store/promptToSave'

import { Camp } from '@/GeneratedTypes/Camp'

import store from '@/store'

import campsClient from '@/clients/campsClient'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

@Component({
  components: {
    SetupCompleteConfirmation,
    Loading,
    SaveContinueButton,
    CurrencyWarning,
    SetupStepperCamp,
    CurrencyInput,
    ProductWarningModal,
    CheckboxInput,
  },
})
export default class Products extends Mixins(CampSetupMixin) {
  @Action(campsStore.actionNames.create, { namespace: campsStore.namespace })
  private readonly create!: ({ item }: { item: Camp }) => Promise<Camp | null>

  @Getter(leagueAbstraction.getterNames.currentItem, {
    namespace: leagueAbstraction.leagueAbstractionNamespace,
  })
  private readonly currentAbstractionItem!: boolean

  @Getter(promptToSave.getterNames.isDirty, { namespace: promptToSave.namespace })
  private readonly isDirty!: boolean

  private modalIsVisible = false
  private userWantsToIgnoreWarnings = false
  private warnings: ValidationError[] | null = null
  private showWarnings = false

  private earlyRegistrationCost: number | null = null
  private registrationCost: number | null = null
  private freeEarlyRegistrationFeeConfirmed = false
  private freeRegistrationFeeConfirmed = false

  logic = productFlowLogic()

  async mounted() {
    if (this.camp.isOPRLeague) {
      if (this.camp.upwardLeagueID) {
        this.nextRoute = `/programs/camp/${this.camp.upwardLeagueID}/setup/registration`
      } else {
        this.nextRoute = '/programs/camp/setup/registration'
      }
    } else {
      if (this.camp.upwardLeagueID) {
        this.nextRoute = '/'
      }
      this.afterSaveNew = this.afterSaveNewMethod
    }
    this.beforeSave = this.productBeforeSave
    this.getRegistrationCost()
    await store.dispatch.programTypes.fetchAll({ force: true })
  }

  get initialProductFlowLoadingComplete() {
    return store.getters.productOfferings.initialProductFlowLoadingComplete
  }

  get showCurrencyWarning() {
    if (store.getters.partners.currencyWarningNeeded && this.logic.isNewProdConfig.value) {
      return !store.getters.partners.hasAcceptedCurrencyWarning
    }
    return false
  }
  get isLoading() {
    if (this.logic.isNewProdConfig.value) {
      return this.loading
    } else {
      return !this.initialProductFlowLoadingComplete
    }
  }

  private get hasEarlyRegistration() {
    const isDate = this.camp.lateDate instanceof Date && !isNaN(this.camp.lateDate.valueOf())

    if (isDate) {
      const date = this.camp.lateDate as Date
      return date !== null
    }

    return this.camp.lateDate !== null
  }

  private newId = ''

  get selectedProductOffering() {
    return store.getters.productOfferings.selectedProductOfferingConfig
  }
  private async productBeforeSave() {
    this.camp.productOfferingConfig = this.selectedProductOffering
    this.camp.productOfferingConfig.accountNumber = null

    /*****************************************************************
     ** If this is a new camp, or the user wants to ignore warnings
     ** skip validation and return true
     *****************************************************************/

    if (this.userWantsToIgnoreWarnings || this.logic.isNewProdConfig.value) {
      this.userWantsToIgnoreWarnings = false
      return true
    }

    //Validation only used for warnings. Broken rules handled in the update call
    const result = await campsClient.validateExisting(this.camp.upwardLeagueID ?? '', cloneDeep(this.camp))
    this.warnings = result.data?.warnings ?? null
    return result && result.data ? result.data?.warnings.length < 1 : true
  }

  private async afterSaveNewMethod() {
    const savedCamp = await this.create({ item: this.camp })
    if (savedCamp) {
      this.newId = savedCamp.upwardLeagueID ?? ''
    }
    this.modalIsVisible = true
  }

  private onModalStateChange() {
    this.$router.push(`/programs/camp/${this.storeCamp.upwardLeagueID}/setup/information`)
  }

  ignoreWarnings() {
    this.userWantsToIgnoreWarnings = true
    this.saveAndContinue()
  }

  @Watch('isDirty')
  dirtyChanged() {
    if (!this.isDirty) this.userWantsToIgnoreWarnings = false
  }

  @Watch('warnings')
  warningsChanged() {
    this.showWarnings = this.warnings ? this.warnings.length > 0 : false
  }

  @Watch('registrationCost')
  private updateregistrationCost() {
    if (this.camp.programs === null || this.camp.programs.length === 0) {
      return
    }
    const program = this.camp.programs[0]
    program.registrationFee = this.registrationCost ?? 0
  }

  @Watch('earlyRegistrationCost')
  private updateEarlyRegistrationCost() {
    if (this.camp.programs === null || this.camp.programs.length === 0) {
      return
    }
    const program = this.camp.programs[0]
    program.earlyRegistrationFee = this.earlyRegistrationCost ?? 0
  }

  @Watch('freeEarlyRegistrationFeeConfirmed')
  private updateFreeEarlyRegistrationFeeConfirmed() {
    if (this.camp.programs === null || this.camp.programs.length === 0) {
      return
    }
    const program = this.camp.programs[0]
    program.freeEarlyRegistrationFeeConfirmed = this.freeEarlyRegistrationFeeConfirmed
  }

  @Watch('freeRegistrationFeeConfirmed')
  private updateFreeRegistrationFeeConfirmed() {
    if (this.camp.programs === null || this.camp.programs.length === 0) {
      return
    }
    const program = this.camp.programs[0]
    program.freeRegistrationFeeConfirmed = this.freeRegistrationFeeConfirmed
  }

  private getRegistrationCost() {
    if (this.camp.programs === null || this.camp.programs.length === 0) {
      return
    }
    const program = this.camp.programs[0]
    this.registrationCost = program.registrationFee
    this.earlyRegistrationCost = program.earlyRegistrationFee
    this.freeEarlyRegistrationFeeConfirmed = program.freeEarlyRegistrationFeeConfirmed
    this.freeRegistrationFeeConfirmed = program.freeRegistrationFeeConfirmed
  }
}
