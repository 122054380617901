





































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import uuid from 'uuid'

import alertClient from '@/clients/alertClient'

import Loading from '@/elements/Loading.vue'
import FullBody from '@/components/FullBody.vue'

import { AlertInfo } from '@/GeneratedTypes/ListInfo/AlertInfo'
import sanitizeHtml from 'sanitize-html'
import store from '@/store'

export enum AlertCloakTypes {
  ORDER,
  SALES_TAX,
  LEAGUE,
  PARTNER,
}

export default defineComponent({
  name: 'AlertCloak',
  components: {
    Loading,
    FullBody,
  },
  props: {
    type: { type: Array as PropType<number[]>, required: true },
    uncloak: { type: Boolean, default: false, required: false },
  },
  setup(props) {
    //start AlertCloakMixin
    const alerts = ref<AlertInfo[]>([])
    const notShowing = ref(false)

    const hasAlerts = computed(() => alerts.value.length > 0)

    const sanitizedMessage = computed(() => {
      const msg = currentAlert.value && currentAlert.value.message ? currentAlert.value.message : ''
      return sanitizeHtml(msg, { allowedTags: sanitizeHtml.defaults.allowedTags.concat('u') })
    })

    const currentAlert = computed((): AlertInfo | null => {
      if (alerts.value.length) return alerts.value[0]
      return null
    })

    function hiding() {
      if (!currentAlert.value) return
      const alert = { ...currentAlert.value } as AlertInfo
      alertClient.hideAlerts(alert).then()
      close()
    }

    function close() {
      const alert = { ...currentAlert.value } as AlertInfo
      alerts.value = alerts.value.filter((a) => a.id != alert.id)
    }

    async function hide() {
      notShowing.value = true
      await hiding()
      notShowing.value = false
    }
    //end AlertCloackMixin

    const accountId = computed(() => store.getters.authorization.firstAccountNumber)
    const currentLeague = computed(() => store.getters.leagues.currentItem)

    const guid = ref(uuid.v4())

    async function fetchAlerts() {
      const calls: Promise<AlertInfo[] | null>[] = []

      if (props.uncloak) {
        alerts.value = []
        return
      }

      if (props.type.includes(AlertCloakTypes.SALES_TAX)) {
        calls.push(alertClient.retrieveSalesTaxAlerts(accountId.value))
      }

      if (props.type.includes(AlertCloakTypes.LEAGUE)) {
        calls.push(alertClient.retrieveLeagueAlerts(upwardId.value))
      }

      if (props.type.includes(AlertCloakTypes.PARTNER)) {
        calls.push(alertClient.retrievePartnerAlerts(accountId.value))
      }

      if (props.type.includes(AlertCloakTypes.ORDER)) {
        calls.push(alertClient.retrieveOrderAlerts(upwardId.value))
      }
      const results = await Promise.all(calls)
      results.forEach((a) => {
        if (!a) return
        alerts.value = [...alerts.value, ...a]
      })
    }

    const upwardId = computed(() => {
      if (currentLeague.value && currentLeague.value.upwardLeagueID) {
        return currentLeague.value.upwardLeagueID
      }
      return ''
    })

    const ready = computed(() => accountId.value != '0' && upwardId.value != '')

    watch(
      () => ready.value,
      async () => {
        if (ready.value) {
          await fetchAlerts()
        }
      },
      { immediate: true }
    )

    watch(
      () => props.uncloak,
      () => {
        if (props.uncloak) {
          alerts.value = []
        }
      },
      { immediate: true }
    )

    return {
      hasAlerts,
      close,
      sanitizedMessage,
      currentAlert,
      hide,
      notShowing,
      guid,
    }
  },
})
