





























































































































































































































































































































import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import * as authorizationStore from '@/store/authorization'
import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import TextInput from '@/elements/TextInput.vue'
import DateTimeInput from '@/elements/DateTimeInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import CurrencyInput from '@/elements/CurrencyInput.vue'
import EFTAuthorizationRequestModal from '@/components/EFTAuthorizationRequestModal.vue'
import { EFTRequestStatus } from '@/models/Partner/EFTRequestStatus'
import partnersClient from '@/clients/partnersClient'
import * as leaguesStore from '@/store/leagues'
import { Action, Getter } from 'vuex-class'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { League } from '@/GeneratedTypes/League'
import SetupCompleteConfirmation from '@/components/SetupCompleteConfirmation.vue'
import ManageDiscountCodes from '@/components/ManageDiscountCodes.vue'
import ManageCapacityRules from '@/components/ManageCapacityRules.vue'
import ManageBypassLinks from '@/components/ManageBypassLinks.vue'
import IntegerInput from '@/elements/IntegerInput.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import { getGradeUsage, GradeUsage } from '@/services/gradeService'
import properCase from '@/filters/properCase'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import dayjs from 'dayjs'
import store from '@/store'
import Modal from '@/components/Modal.vue'

@Component({
  components: {
    Loading,
    TextInput,
    DateTimeInput,
    CheckboxInput,
    MemoInput,
    YesNoInput,
    InputLabel,
    CurrencyInput,
    SetupCompleteConfirmation,
    ManageDiscountCodes,
    ManageCapacityRules,
    ManageBypassLinks,
    IntegerInput,
    HorizontalTabs,
    SaveContinueButton,
    HtmlEditor,
    EFTAuthorizationRequestModal,
    Modal,
  },
})
export default class Registration extends Mixins(LeagueSetupMixin) {
  @Action(leaguesStore.actionNames.create, { namespace: leaguesStore.namespace })
  private readonly create!: ({ item }: { item: League }) => Promise<League | null>

  @Getter(authorizationStore.getterNames.firstAccountNumber, { namespace: authorizationStore.namespace })
  private accountNumber!: string

  // TODO Where do these hook into the league object
  private hasMultiChildDiscount = false
  private modalIsVisible = false
  private maxNumberOfRegistrations = 0
  private showManageCapactiy = false
  private sportCapacityMessage = ''
  private cheerCapacityMessage = ''
  private newId = ''
  private selectedCapacityTab = 'sport'
  private eftBankAcctLast4 = ''
  private onlineRegistrationBeginDate: Date | null = null
  private onlineRegistrationEndDate: Date | null = null
  private registrationLoaded = false
  private eftModalIsVisible = false
  private eftStatus: EFTRequestStatus | null = null
  private showCreditCardHelp = false
  async mounted() {
    this.afterSaveNew = this.afterSaveNewMethod
    this.beforeSave = this.beforeSaveProcessing

    if (this.league.upwardLeagueID) {
      this.nextRoute = '/'
    }

    this.eftStatus = await partnersClient.getEFTRequestStatus(this.accountNumber)
  }

  get showAccessCode(): boolean {
    return this.eftStatus?.status != 'declined' && this.eftStatus?.status != 'voided' && this.accessCode != ''
  }

  get accessCode(): string {
    return this.eftStatus?.accessCode ?? ''
  }

  get envStatus(): string {
    return this.eftStatus?.status ?? ''
  }

  get isGlobalPartner(): boolean {
    return (this.league.accounts && this.league.accounts[0].accountNumber.startsWith('G')) ?? false
  }

  get partner() {
    return store.getters.partners.partnerInfo
  }

  get ccEnabled() {
    //for some reason this doesn't work
    //return this.eftBankAcctLast4 && this.eftBankAcctLast4 != ''
    if (this.eftBankAcctLast4 && this.eftBankAcctLast4 != '') {
      return true
    } else {
      return false
    }
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    this.hasMultiChildDiscount =
      this.league.registrationInfo != null &&
      (this.league.registrationInfo.twoChildDiscountAmount > 0 ||
        this.league.registrationInfo.threeChildDiscountAmount > 0 ||
        this.league.registrationInfo.fourOrMoreChildDiscountAmount > 0)

    this.showManageCapactiy =
      ((this.league.programs &&
        this.league.programs[0].registrationCapacitiesByGradeGender &&
        this.league.programs[0].registrationCapacitiesByGradeGender.length > 0) ||
        (this.league.programs && this.league.programs[0].capacity > 0)) ??
      false

    this.sportCapacityMessage = this.league.programs ? this.league.programs[0].capacityMessage ?? '' : ''

    if (this.league.registrationInfo?.onlineRegistrationBeginDate) {
      this.onlineRegistrationBeginDate = this.league.registrationInfo?.onlineRegistrationBeginDate
    } else if (this.league.registrationBeginDate) {
      this.onlineRegistrationBeginDate = this.league.registrationBeginDate
      this.onlineRegistrationBeginDate.setHours(0, 0, 0, 0)
    }

    if (this.league.registrationInfo?.onlineRegistrationEndDate) {
      this.onlineRegistrationEndDate = this.league.registrationInfo?.onlineRegistrationEndDate
    } else if (this.league.registrationEndDate) {
      this.onlineRegistrationEndDate = this.league.registrationEndDate
      this.onlineRegistrationEndDate.setHours(23, 59, 59, 0)
    }

    if (this.league.programs && this.league.programs.length > 1) {
      this.cheerCapacityMessage = this.league.programs ? this.league.programs[1].capacityMessage ?? '' : ''
    }

    if (
      this.league.registrationInfo &&
      !this.league.registrationInfo.contactLine1 &&
      this.league.partnerContact?.firstName
    ) {
      this.league.registrationInfo.contactLine1 = `${this.league.partnerContact.firstName} ${this.league.partnerContact.lastName}`
    }

    if (
      this.league.registrationInfo &&
      !this.league.registrationInfo.contactLine4 &&
      this.league.partnerContact?.primaryPhoneNumber
    ) {
      this.league.registrationInfo.contactLine4 = this.league.partnerContact.primaryPhoneNumber
    }

    if (
      this.league.registrationInfo &&
      !this.league.registrationInfo.contactLine5 &&
      this.league.partnerContact?.emailAddress
    ) {
      this.league.registrationInfo.contactLine5 = this.league.partnerContact.emailAddress
    }

    // Always set the league.registrationInfo.allowSizingEntry to true per 2635
    if (this.league && this.league.registrationInfo) {
      this.league.registrationInfo.allowSizingEntry = true
    }

    if (this.league && this.league.accounts && this.league.accounts.length > 0) {
      if (this.accountNumber) {
        this.eftBankAcctLast4 = this.partner?.eftBankAcctLast4 ?? ''

        if (this.league.registrationInfo && !this.league?.registrationInfo.contactLine2) {
          this.league.registrationInfo.contactLine2 = `${this.partner?.address1_Line1} ${this.partner?.address1_Line2}`
        }

        if (this.league.registrationInfo && !this.league?.registrationInfo.contactLine3) {
          this.league.registrationInfo.contactLine3 = `${this.partner?.address1_City}, ${this.partner?.address1_StateOrProvince} ${this.partner?.address1_PostalCode}`
        }
      }
    }

    if (this.league && this.league.registrationInfo && (this.isGlobalPartner || !this.ccEnabled)) {
      this.league.registrationInfo.allowCreditCard = false
    }

    this.registrationLoaded = true
  }

  // eslint-disable-next-line require-await
  private async beforeSaveProcessing() {
    if (
      this.onlineRegistrationBeginDate &&
      this.onlineRegistrationEndDate &&
      this.onlineRegistrationBeginDate >= this.onlineRegistrationEndDate
    ) {
      throw new Error('Registration begin date cannot be before the end date')
    }

    if (this.league.registrationInfo && this.onlineRegistrationBeginDate) {
      this.league.registrationInfo.onlineRegistrationBeginDate = this.onlineRegistrationBeginDate
    }
    if (this.league.registrationInfo && this.onlineRegistrationEndDate) {
      this.league.registrationInfo.onlineRegistrationEndDate = this.onlineRegistrationEndDate
    }
    if (!this.showManageCapactiy && this.league && this.league.programs) {
      this.sportCapacityMessage = ''
      this.cheerCapacityMessage = ''
      for (let i = 0; i < this.league.programs.length; i++) {
        this.league.programs[i].capacity = 0
        this.league.programs[i].capacityMessage = ''
        this.league.programs[i].registrationCapacitiesByGradeGender = []
      }
    }

    if (this.league.registrationInfo && !this.showManageCapactiy) {
      this.league.registrationInfo.allowWaitlist = false
    }
  }

  private async afterSaveNewMethod() {
    const savedLeague = await this.create({ item: this.league })
    if (savedLeague) {
      this.newId = savedLeague.upwardLeagueID!
    }

    this.modalIsVisible = true
  }

  private onModalStateChange() {
    this.$router.push(`/programs/league/${this.newId}/setup/information`)
  }

  private async eftAuthSent() {
    this.eftModalIsVisible = false
    this.eftStatus = await partnersClient.getEFTRequestStatus(this.accountNumber)
  }

  private getLeagueGradesList(index: number): GradeUsage[] {
    if (this.league.programs && this.league.programs.length > index) {
      const typeProgramId = this.league.programs[index].typeProgramID

      return getGradeUsage(this.league, typeProgramId)
    }
    return []
  }

  @Watch('league.programs', { immediate: true, deep: true })
  private addCapacityMessage() {
    this.updateCapacityMessage()
  }

  @Watch('cheerCapacityMessage')
  @Watch('sportCapacityMessage')
  private addCapacityMessageWhenCapacityMessageUpdated() {
    this.updateCapacityMessage()
  }

  @Watch('showManageCapactiy')
  private setDirty() {
    if (this.registrationLoaded) {
      this.setIsDirty({ isDirty: true })
    }

    if (this.league.registrationInfo && !this.showManageCapactiy) {
      this.league.registrationInfo.allowWaitlist = false
    }
  }

  @Watch('onlineRegistrationEndDate')
  endDateChange(newVal: string, oldVal: string) {
    if (newVal == 'Invalid Date') return
    if (oldVal == '') {
      this.onlineRegistrationEndDate = dayjs(this.onlineRegistrationEndDate ?? '')
        .hour(23)
        .minute(59)
        .second(59)
        .toDate()
    }
  }

  private updateCapacityMessage() {
    if (this.league.programs && this.league.programs[0].registrationCapacitiesByGradeGender) {
      this.league.programs[0].capacityMessage = this.sportCapacityMessage
      for (const rcbgg of this.league.programs[0].registrationCapacitiesByGradeGender) {
        rcbgg.capacityMessage = this.sportCapacityMessage
      }
    }

    if (
      this.league.programs &&
      this.league.programs.length > 1 &&
      this.league.programs[1].registrationCapacitiesByGradeGender
    ) {
      this.league.programs[1].capacityMessage = this.cheerCapacityMessage
      for (const rcbgg of this.league.programs[1].registrationCapacitiesByGradeGender) {
        rcbgg.capacityMessage = this.cheerCapacityMessage
      }
    }
  }

  private showCheerCapacities(): boolean {
    return (this.league.programs && this.league.programs.length > 1) ?? false
  }

  private getSportName(): string {
    if (this.league.programs && this.league.programs.length > 0) {
      return properCase(this.league.programs[0].typeProgramID)
    }
    return ''
  }
}
