














import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'

export default defineComponent({
  name: 'IntegerPicker',
  components: {
    SelectInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    value: { type: (null as unknown) as PropType<number | null>, required: true },
  },
  setup(props, ctx) {
    const internalValue = ref('')
    const integerList = ref<number[]>([])

    //created()
    for (let i = props.min; i <= props.max; i++) {
      integerList.value.push(i)
    }
    internalValue.value = convertToString(props.value)
    //end created

    function convertToString(value: number | null) {
      return value === null || isNaN(value) ? '' : `${value}`
    }

    function convertToNumber(value: string): number | null {
      const valueAsNumber = Math.round(Number(value))

      if (isNaN(valueAsNumber)) {
        return null
      }

      return valueAsNumber
    }

    function handleChange() {
      const valueAsNumber = convertToNumber(internalValue.value)
      ctx.emit('input', valueAsNumber)
      ctx.emit('change', valueAsNumber)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = convertToString(props.value)
      }
    )

    return {
      internalValue,
      integerList,
      handleChange,
    }
  },
})
