



















































































import { Component, Vue } from 'vue-property-decorator'
import SectionHeader from '@/components/SectionHeader.vue'
import ShippingAddressSection from '@/components/ShippingAddressSection.vue'
import ShippingAddressDisplay from '@/components/ShippingAddressDisplay.vue'
import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import * as ordersStore from '@/store/orders'
import OrderListSection from '@/components/Orders/OrderListSection.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { Getter } from 'vuex-class'
import date from '@/filters/date'
import { computed } from '@vue/composition-api'
import { useNoProductSelector } from '@/views/Programs/Orders/compositions/useProductSelector'
import { SectionNameConversion } from '@/views/Programs/Orders/vues/history/ext/SectionNames'

@Component({
  components: {
    SectionHeader,
    ShippingAddressSection,
    OrderListSection,
    InputLabel,
    ShippingAddressDisplay,
  },
  filters: {
    date,
  },
})
export default class Confirmation extends Vue {
  @Getter(ordersStore.getterNames.lastCompletedOrder, { namespace: ordersStore.namespace })
  private readonly lastCompletedOrder!: xStartupOrderExt

  private get orderType() {
    return SectionNameConversion[this.lastCompletedOrder.upwardOrderType ?? '']
  }

  private get selectorMethod() {
    return useNoProductSelector({ currentOrderProductsRef: computed(() => this.lastCompletedOrder.products) })
  }

  private get shippingMethods() {
    if (
      !this.lastCompletedOrder ||
      !this.lastCompletedOrder.shippingInfo ||
      !this.lastCompletedOrder.shippingInfo.shipMethods
    )
      return

    return this.lastCompletedOrder.shippingInfo.shipMethods.filter((method) => {
      return method.selected
    })
  }
}
