

















import { defineComponent, computed } from '@vue/composition-api'
import { InviteStatus, WaitlistActionsEnum } from '@/views/Programs/Reports/WebviewReports/lib/Waitlist'

export default defineComponent({
  name: 'WaitlistActions',
  props: {
    status: { type: String, required: false, default: InviteStatus.WAITING },
  },
  setup(props, ctx) {
    function clicked(action: string) {
      ctx.emit('click', action)
    }

    const canRemove = computed(() => props.status != InviteStatus.INVITATIONSENT)
    const canSend = computed(() => props.status == InviteStatus.WAITING)
    const canCancel = computed(() => props.status == InviteStatus.INVITATIONSENT)
    const canResend = computed(
      () => props.status == InviteStatus.INVITATIONEXPIRED || props.status == InviteStatus.INVITATIONSENT
    )
    return { clicked, canRemove, canSend, canResend, canCancel, WaitlistActionsEnum }
  },
})
