


































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'

import SelectInput from '@/elements/SelectInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import TextInput from '@/elements/TextInput.vue'

import DivisionHeader from '@/views/Programs/Divisions/vues/DivisionHeader.vue'
import { HeaderValue } from '@/views/Programs/Divisions/ext/headerValue'
import PracticeScheduler from '@/views/Programs/Scheduling/Practices/PracticeScheduler.vue'
import Loading from '@/elements/Loading.vue'
import store from '@/store'

export default defineComponent({
  name: 'Practices',
  components: {
    PracticeScheduler,
    SelectInput,
    CheckboxInput,
    TextInput,
    DivisionHeader,
    Loading,
  },
  setup(props, ctx) {
    const facilities = computed(() => store.getters.facilities.items)
    const headerValues = ref<HeaderValue | null>(null)

    const programID = ref('')
    const divisionID = ref(-1)
    const loading = ref(false)

    const leagueID = computed(() => ctx.root.$route.params.id)

    const hasFacilites = computed(() => facilities.value.length > 0)

    function onDivisionChanged(headers: HeaderValue | null) {
      if (headers) {
        headerValues.value = headers
      }
      programID.value = headers?.selectedDivision?.typeProgramID ?? ''
      divisionID.value = headers?.selectedDivision?.divisionID ?? -1
    }

    function onLoadingChange(status: { val: boolean }) {
      loading.value = status.val
    }

    onMounted(async () => {
      loading.value = true
      try {
        await store.dispatch.facilities.load({ id: leagueID.value })
      } finally {
        loading.value = false
      }
    })

    return {
      loading,
      hasFacilites,
      leagueID,
      divisionID,
      programID,
      onDivisionChanged,
      onLoadingChange,
    }
  },
})
