

























































import { defineComponent, ref, watch, computed, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import { ScheduleBlocks } from '@/models/elements/Scheduler'
import Modal from '@/components/Modal.vue'
import {
  AugmentedFacilityAvailability,
  dayIDToName,
  dayNameToID,
  FacilityAvailabilityByDay as FacilityAvailabilityByDayExt,
} from '@/views/Programs/Scheduling/Games/ext/augmented_facility_availability'
import { millisecondsToTimeString, timeStringToMilliseconds } from '@/lib/support/utilities/time/time'
import TimePicker from '@/elements/TimePicker2.vue'
import { cloneDeep } from 'lodash'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
class DivisionDayPreferencesModalError extends RuntimeException {
  name = 'Division Day Preference Error'
}

export default defineComponent({
  name: 'DivisionDayPreferencesModal',
  components: {
    Modal,
    TimePicker,
  },
  props: {
    superSchedule: { type: Array as PropType<ScheduleBlocks[]>, required: true, default: () => [] },
    subSchedule: { type: Array as PropType<ScheduleBlocks[]>, required: true, default: () => [] },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)
    const internalSubSchedule = ref<ScheduleBlocks[]>([])

    function confirmed() {
      updateSchedule()
      closeModal()
    }

    /***
     * Toggle the day, resets on re-click, disables on click
     * @param day 0-6
     */
    function toggleAssigned(day: number) {
      if (facilityAvailabilityByDay.value[day].assigned) {
        internalSubSchedule.value = cloneDeep(internalSubSchedule.value.filter((x) => x.day != day))
      } else {
        const f = props.superSchedule.find((x) => x.day == day)
        if (f) {
          internalSubSchedule.value.push({
            day: f.day,
            timeStart: f.timeStart,
            timeEnd: f.timeEnd,
          })
        } else {
          throw new DivisionDayPreferencesModalError('Cannot find the day given ' + day)
        }
      }
    }

    function updateTimeStart(index: number, time: string) {
      const y = internalSubSchedule.value.map((x) => {
        return {
          timeStart: x.day == index ? timeStringToMilliseconds(time) : x.timeStart,
          timeEnd: x.timeEnd,
          day: x.day,
        }
      })
      internalSubSchedule.value = y
    }

    function updateTimeEnd(index: number, time: string) {
      internalSubSchedule.value = cloneDeep(
        internalSubSchedule.value.map((x) => ({
          timeStart: x.timeStart,
          timeEnd: x.day == index ? timeStringToMilliseconds(time) : x.timeEnd,
          day: x.day,
        }))
      )
    }

    const facilityAvailabilityByDay = computed((): AugmentedFacilityAvailability[] => {
      return FacilityAvailabilityByDayExt(props.superSchedule, internalSubSchedule.value)
    })

    const areTimesValid = computed((): boolean => {
      const b: boolean =
        internalSubSchedule.value.findIndex(
          (x) => isNaN(x.timeStart) || isNaN(x.timeEnd) || x.timeStart > x.timeEnd
        ) < 0
      return b
    })

    function cancel() {
      closeModal()
    }

    watch(
      () => props.subSchedule,
      () => {
        internalSubSchedule.value = cloneDeep(props.subSchedule)
      },
      { immediate: true }
    )

    function updateSchedule() {
      ctx.emit('update-schedule', internalSubSchedule.value)
    }

    return {
      isModalOpen,
      dayNameToID,
      dayIDToName,
      millisecondsToTimeString,
      facilityAvailabilityByDay,
      toggleAssigned,
      updateTimeStart,
      updateTimeEnd,
      cancel,
      areTimesValid,
      confirmed,
    }
  },
})
