













import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import { ValidationError } from 'src/models/UpwardVerificationDetails'

export default defineComponent({
  components: { Modal },
  props: {
    warnings: { type: Array as PropType<ValidationError[]>, required: false },
    value: { type: Boolean, required: false, default: false },
  },
  setup(props, { emit }) {
    const internalVisible = ref(false)

    function save() {
      emit('save')
      close()
    }

    function close() {
      internalVisible.value = false
    }

    watch(
      () => props.value,
      () => (internalVisible.value = props.value),
      { immediate: true }
    )

    watch(
      () => internalVisible.value,
      () => emit('input', internalVisible.value)
    )

    return {
      internalVisible,
      close,
      save,
    }
  },
})
