import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardProductOfferingProductPackageTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProductOfferingProductPackageTypeID'
import { defineActions } from 'direct-vuex'

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardProductOfferingProductPackageTypeID>(
  upwardTypesClient.retrieveProductOfferingProductPackageTypes
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardProductOfferingProductPackageTypeID> = {
  items: [],
}

const getterTree: GetterTree<
  genericUpwardTypes.GenericTypeState<UpwardProductOfferingProductPackageTypeID>,
  RootState
> = {
  ...generic.getterTree,
}

const mutations: MutationTree<
  genericUpwardTypes.GenericTypeState<UpwardProductOfferingProductPackageTypeID>
> = {
  ...generic.mutations,
}

const actions = defineActions({
  ...generic.actions,
})

export const getterNames = {
  ...genericUpwardTypes.getterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

export const namespace = 'productOfferingProductPackageTypes'

export const productOfferingProductPackageTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
