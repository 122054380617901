import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardOrderStatusTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardOrderStatusTypeID'
import { defineActions } from 'direct-vuex'

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardOrderStatusTypeID>(
  upwardTypesClient.retrieveReturnStatusTypes
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardOrderStatusTypeID> = {
  items: [],
}

const getterTree: GetterTree<genericUpwardTypes.GenericTypeState<UpwardOrderStatusTypeID>, RootState> = {
  ...generic.getterTree,
}

const mutations: MutationTree<genericUpwardTypes.GenericTypeState<UpwardOrderStatusTypeID>> = {
  ...generic.mutations,
}

const actions = defineActions({
  ...generic.actions,
})

export const getterNames = {
  ...genericUpwardTypes.getterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

export const namespace = 'returnStatusTypes'

export const returnStatusTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
