import { LeagueDivisionContactInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionContactInfo'

export function getEmptyLeagueDivisionContactInfo(): LeagueDivisionContactInfo {
  return {
    address: '',
    cityStateZip: '',
    emailAddress: '',
    fullName: '',
    latitude: 0,
    longitude: 0,
    phone: '',
  }
}
