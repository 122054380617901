

















import { defineComponent, ref, watch, onMounted } from '@vue/composition-api'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import divisionsClient from '@/clients/divisionsClient'
import Loading from '@/elements/Loading.vue'
import {
  groupByGender,
  GradesGroupedByGender,
} from '@/lib/support/models/GeneratedTypes/ListInfo/LeagueProgramGenderGradeInfo'
import store from '@/store'

export default defineComponent({
  name: 'DivisionGradeGenderList',
  components: {
    SimpleGrid,
    Loading,
  },
  props: {
    ProgramId: { type: String, required: false, default: null },
    isByAge: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const isLoading = ref(true)
    const gradeGenderList = ref<GradesGroupedByGender[]>([])
    const columns = ref<GridColumn[]>([
      {
        field: props.isByAge ? 'ageByCutoff' : 'gradeDescription',
        headerText: props.isByAge ? 'Age' : 'Grade',
        width: 200,
      } as GridColumn,
      { field: 'femaleCount', headerText: 'Girls' } as GridColumn,
      { field: 'maleCount', headerText: 'Boys' } as GridColumn,
      { field: 'total', headerText: 'Total' } as GridColumn,
    ])

    onMounted(async () => {
      await loadList()
    })

    async function loadList() {
      isLoading.value = true
      try {
        if (props.ProgramId) {
          const list = await divisionsClient.retrieveGradeGenderList(
            ctx.root.$route.params.id,
            props.ProgramId
          )
          if (list) {
            gradeGenderList.value = groupByGender(list).map((x) => {
              return {
                ...x,
                ageByCutoff: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID)?.ageByCutoff ?? '',
                gradeDescription: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID)?.description ?? '',
              }
            })
          }
        } else {
          gradeGenderList.value = []
        }
      } finally {
        isLoading.value = false
      }
    }

    watch(
      () => props.ProgramId,
      async () => {
        await loadList()
      }
    )

    return {
      isLoading,
      gradeGenderList,
      columns,
    }
  },
})
