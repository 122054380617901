




































import { defineComponent } from '@vue/composition-api'
import CurrentPrograms from '@/components/CurrentPrograms.vue'
import FullBody from '@/components/FullBody.vue'

export default defineComponent({
  name: 'ChooseEditOrNew',
  components: {
    CurrentPrograms,
    FullBody,
  },
})
