










































































































import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'
import divisionsClient from '@/clients/divisionsClient'
import teamsClient from '@/clients/teamsClient'

import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'

import { getGradesListFromAccount } from '@/services/gradeService'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import { isCheer } from '@/lib/common/ProgramTypes'
import store from '@/store'

export default defineComponent({
  name: 'MailingLabels',
  components: {
    SelectInput,
    CheckboxInput,
    TextInput,
    ActiveReport,
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const includeParticipants = ref(true)
    const includeVolunteers = ref(false)
    const prefixText = ref('To the Parents Of')
    const filterByGrade = ref(false)
    const selectedStartGrade = ref('0')
    const selectedEndGrade = ref('0')

    const selectedProgram = ref('')

    const selectedDivision = ref(-1)
    const divisions = ref([{}])

    const selectedTeam = ref(-1)
    const teams = ref([{}])

    const loading = ref(false)
    const loadStatus = ref('')

    const isByAge = computed(() => {
      if (isCamp.value) {
        return camp.value.ageCutoffDate !== null
      } else {
        return league.value.ageCutoffDate !== null
      }
    })

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    const programs = computed((): string[] => {
      if (isCamp.value) {
        return camp.value.programs?.map((p) => p.typeProgramID) ?? []
      }
      return league.value.programs?.map((p) => p.typeProgramID) ?? []
    })

    const hasCheer = computed((): boolean => {
      if (isCamp.value) {
        return false
      }

      return (league.value && league.value.programs?.some((p) => isCheer(p.typeProgramID))) ?? false
    })

    function setGrades() {
      if (!filterByGrade.value) {
        selectedStartGrade.value = '0'
        selectedEndGrade.value = '0'
      }
    }

    //const gradesList = computed(() => getGrades(false))
    const gradesList = computed(() => {
      let retval: UpwardGradeTypeID[] = []
      const accts = isCamp.value ? camp.value.accounts : league.value.accounts
      const acct = accts?.find((x) => x.accountNumber == accountNumber.value)
      if (acct) {
        retval = getGradesListFromAccount(acct, selectedProgram.value, 'E')
      }
      return retval
    })

    onMounted(async () => {
      selectedProgram.value = typeProgramID.value

      await loadTeams()
    })

    watch(
      () => selectedProgram.value,
      async () => {
        selectedDivision.value = -1
        divisions.value = []
        const myDivisions =
          (await divisionsClient.retrieveByProgram(upwardLeagueId.value, selectedProgram.value)) ?? []

        //default is all
        divisions.value.push({
          divisionID: 0,
          divisionName: 'All' ?? '',
        })

        myDivisions.forEach((r) =>
          divisions.value.push({
            divisionID: r.divisionID,
            divisionName: r.divisionName ?? '',
          })
        )
      }
    )

    watch(
      () => selectedDivision.value,
      async () => {
        await loadTeams()
      }
    )

    async function loadTeams() {
      selectedTeam.value = -1
      teams.value = []
      const myTeams =
        (await teamsClient.retrieveTeamList({
          leagueId: upwardLeagueId.value,
          divisionId: selectedDivision.value,
          typeProgramId: selectedProgram.value,
        })) ?? []

      //default is all
      teams.value.push({
        teamID: 0,
        teamName: 'All' ?? '',
      })

      myTeams.forEach((r) =>
        teams.value.push({
          teamID: r.teamID,
          teamName: r.teamName ?? '',
        })
      )
    }

    function reportLoaded() {
      loading.value = false
    }

    const incomplete = computed(() => selectedDivision.value == -1 || selectedTeam.value == -1)

    async function loadReport() {
      loading.value = true
      let startGrade = selectedStartGrade.value
      let endGrade = selectedEndGrade.value

      loadStatus.value = 'Downloading Report Data . . .'
      const data = await Promise.all([
        reportsClient.getMailingLabels(
          upwardLeagueId.value,
          accountNumber.value,
          selectedTeam.value.toString(),
          selectedDivision.value.toString(),
          includeParticipants.value,
          includeVolunteers.value,
          filterByGrade.value,
          startGrade,
          endGrade,
          selectedProgram.value
        ),
      ])

      loadStatus.value = 'Downloading Report Templates . . .'
      const reportTemplates = await Promise.all([reportsClient.getReportTemplate('mailingLabels')])

      loadStatus.value = 'Processing Report Templates . . .'
      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')

      const mainData = data[0]

      const parameters = [
        {
          Value: [prefixText.value],
          Name: 'PrefixText',
        },
      ]

      report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)

      loadStatus.value = 'Loading Report . . .'
      const activeReport: any = ctx.refs.activereport
      activeReport.loadReportData(report, { ReportParams: parameters })
    }

    return {
      hasCheer,
      loading,
      isByAge,
      programs,
      selectedProgram,
      divisions,
      selectedDivision,
      teams,
      selectedTeam,
      prefixText,
      includeParticipants,
      includeVolunteers,
      filterByGrade,
      gradesList,
      selectedStartGrade,
      selectedEndGrade,
      incomplete,
      loadStatus,
      loadReport,
      reportLoaded,
      setGrades,
    }
  },
})
