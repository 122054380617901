import { SavedProductOfferingConfigGenderGrade } from '@/GeneratedTypes/SavedProductOfferingConfigGenderGrade'

export interface ProgramInformation {
  id: number
  name: string | null
  playerEstimate: string
  cheerEstimate: string
  sportCoachEstimate: string
  cheerCoachEstimate: string
  firstPracticeDateEstimate: Date | null
  grades: SavedProductOfferingConfigGenderGrade[] | null
}

export function getEmptyProgramInformation(): ProgramInformation {
  return {
    id: 0,
    name: '',
    playerEstimate: '',
    cheerEstimate: '',
    sportCoachEstimate: '',
    cheerCoachEstimate: '',
    firstPracticeDateEstimate: null,
    grades: null,
  }
}
