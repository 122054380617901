









































import { defineComponent, ref, onMounted } from '@vue/composition-api'
//import DivisionGrid from '@/components/DivisionGrid.vue'
import DivisionForm from '@/components/DivisionForm.vue'
import DivisionStandingList from '@/components/DivisionStandingList.vue'
import DivisionGameList from '@/components/DivisionGameList.vue'
import programsClient from '@/clients/programsClient'
import divisionsClient from '@/clients/divisionsClient'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import Loading from '@/elements/Loading.vue'
import store from '@/store'
import DivisionHeader from '@/views/Programs/Divisions/vues/DivisionStandingScoresHeader.vue'
import { HeaderStandingScoreValue } from './ext/headerStandingScoreValue'

export default defineComponent({
  name: 'Divisions',
  components: {
    //    DivisionGrid,
    DivisionGameList,
    DivisionForm,
    DivisionStandingList,
    Loading,
    DivisionHeader,
  },
  setup(props, ctx) {
    const loading = ref(false)
    const divisions = ref<LeagueDivisionInfo[]>([])
    const allDivisions = ref<LeagueDivisionInfo[] | null>(null)
    const division = ref<LeagueDivision | null>(null)
    const leaguePrograms = ref<LeagueListItem>({} as LeagueListItem)
    const headerValues = ref<HeaderStandingScoreValue | null>(null)

    const selectedDivisionId = ref(-1)
    const selectedProgramId = ref('')
    const selectedTeamId = ref(-1)
    const selectedRoundId = ref(-1)
    const currentIncrementID = ref(0)
    const incompleteGamesOnly = ref(false)
    const leagueId = ctx.root.$route.params.id

    onMounted(async () => {
      try {
        loading.value = true
        await loadPrograms()
        await loadDivisions()
      } finally {
        loading.value = false
      }
    })

    async function loadPrograms() {
      const UpwId = ctx.root.$route.params.id
      try {
        const programs = await programsClient.retrieve(UpwId)
        if (programs) {
          leaguePrograms.value = programs
          selectedProgramId.value = programs.programTypeID!
        }
      } catch (error) {
        throw error
      }
    }

    async function loadDivisions() {
      const UpwId = ctx.root.$route.params.id

      try {
        allDivisions.value = await divisionsClient.retrieveAll(UpwId)
        divisionsFilteredByProgram()
      } catch (error) {
        throw error
      }
    }

    function divisionsFilteredByProgram() {
      if (allDivisions.value) {
        divisions.value = allDivisions.value.filter((division) => {
          return division.typeProgramID === selectedProgramId.value
        })
      }
    }

    async function onProgramChanged(program: string) {
      selectedProgramId.value = program
      loading.value = true
      await loadDivisions()
      loading.value = false
    }

    function onDivisionChanged(headers: HeaderStandingScoreValue | null) {
      if (headers) {
        headerValues.value = headers
        store.commit.divisions.setDivision({ division: headers.selectedDivision })
      }
      selectedTeamId.value = headers?.selectedTeamID ?? -1
      selectedRoundId.value = headers?.selectedRoundID ?? -1
      incompleteGamesOnly.value = headers?.incompleteGamesOnly ?? false
      selectedDivisionId.value = headers?.selectedDivision?.divisionID ?? -1
      selectedProgramId.value = headers?.selectedDivision?.typeProgramID ?? ''
      currentIncrementID.value += 1
    }

    function onIncompleteGameChanged(headers: HeaderStandingScoreValue | null) {
      if (headers) {
        headerValues.value = headers
      }
      selectedTeamId.value = headers?.selectedTeamID ?? -1
      selectedRoundId.value = headers?.selectedRoundID ?? -1
      incompleteGamesOnly.value = headers?.incompleteGamesOnly ?? false
      selectedDivisionId.value = headers?.selectedDivision?.divisionID ?? -1
      selectedProgramId.value = headers?.selectedDivision?.typeProgramID ?? ''
    }

    function onTeamRoundChanged(headers: HeaderStandingScoreValue | null) {
      if (headers) {
        headerValues.value = headers
      }
      selectedTeamId.value = headers?.selectedTeamID ?? -1
      selectedRoundId.value = headers?.selectedRoundID ?? -1
      incompleteGamesOnly.value = headers?.incompleteGamesOnly ?? false
      selectedDivisionId.value = headers?.selectedDivision?.divisionID ?? -1
      selectedProgramId.value = headers?.selectedDivision?.typeProgramID ?? ''
    }

    function onGameScoreChanged(headers: HeaderStandingScoreValue | null) {
      if (headers) {
        headerValues.value = headers
        store.dispatch.scheduling.loadGames({ id: leagueId })
      }

      selectedTeamId.value = headers?.selectedTeamID ?? -1
      selectedRoundId.value = headers?.selectedRoundID ?? -1
      incompleteGamesOnly.value = headers?.incompleteGamesOnly ?? false
      selectedDivisionId.value = headers?.selectedDivision?.divisionID ?? -1
      selectedProgramId.value = headers?.selectedDivision?.typeProgramID ?? ''
      currentIncrementID.value += 1
    }

    return {
      loading,
      divisions,
      leaguePrograms,
      selectedProgramId,
      currentIncrementID,
      selectedDivisionId,
      onProgramChanged,
      division,
      DivisionHeader,
      onDivisionChanged,
      onIncompleteGameChanged,
      onTeamRoundChanged,
      onGameScoreChanged,
      selectedTeamId,
      selectedRoundId,
      incompleteGamesOnly,
    }
  },
})
