import restService from '@/services/restService'
import { MinistryAgreement } from '@/GeneratedTypes/Partner/MinistryAgreement.ts'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'

const checkMinistryAgreementStatus = async (
  accountNumber: UpwardLeagueIDType
): Promise<MinistryAgreement | null> => {
  const results = await restService.get<MinistryAgreement>(`partners/${accountNumber}/ministryAgreement`)

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in ministryAgreementClient.checkMinistryAgreementStatus')
  }
}

interface ESignatureType {
  fullname: string
  email: string
}

const signAgreement = async (accountNumber: UpwardLeagueIDType, name: string, email: string) => {
  const results = await restService.post<MinistryAgreement>(`partners/${accountNumber}/ministryAgreement`, {
    fullname: name,
    email,
  } as ESignatureType)

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in ministryAgreementClient.signAgreement')
  }
}

export default {
  checkMinistryAgreementStatus,
  signAgreement,
}
