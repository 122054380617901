




























































































import { defineComponent, computed, ref, watch } from '@vue/composition-api'

import teamsClient from '@/clients/teamsClient'
import { TeamGenerationData } from '@/models/Teams/TeamGenerationData'
import { TeamGeneratorGuidelineInfo } from '@/GeneratedTypes/ListInfo/TeamGeneratorGuidelineInfo'

import IntegerInput from '@/elements/IntegerInput.vue'
import Modal from '@/components/Modal.vue'
import SelectInput from '@/elements/SelectInput.vue'
import TextInput from '@/elements/TextInput.vue'
import ToggleSlot from '@/elements/ToggleSlot.vue'
import YesNoInput from '@/elements/YesNoInput.vue'

export default defineComponent({
  name: 'AutoCreateTeamsModal',
  components: {
    IntegerInput,
    Modal,
    SelectInput,
    TextInput,
    ToggleSlot,
    YesNoInput,
  },
  props: {
    value: { type: Boolean, required: true },
    teamSquadText: { type: String, required: true },
    upwardLeagueId: { type: String, default: '', required: false },
    divisionId: { type: Number, default: 0, required: false },
    typeProgramId: { type: String, default: '', required: false },
    hasTeams: { type: Boolean, default: false, required: false },
  },
  setup(props, ctx) {
    const modalIsVisible = ref(props.value)
    const generationData = ref<TeamGenerationData>(getEmptyGenerationData())

    function getEmptyGenerationData() {
      return {
        typeTeamCategoryID: '',
        prefix: null,
        removeDupes: false,
        teamCount: 0,
      }
    }

    const guidelineInfo = ref<TeamGeneratorGuidelineInfo | null>(null)

    const lowerCaseTeamSquadText = computed(() => props.teamSquadText.toLowerCase())

    function hideAutoGenerationModal() {
      modalIsVisible.value = false
    }

    async function createRecommendedTeams() {
      if (props.upwardLeagueId && props.divisionId && props.typeProgramId) {
        await teamsClient.generateTeams({
          leagueId: props.upwardLeagueId,
          divisionId: props.divisionId,
          typeProgramId: props.typeProgramId,
          teamGenerationData: generationData.value,
        })
      }
      modalIsVisible.value = false
    }

    async function doShowAutoGenerationModal() {
      if (props.upwardLeagueId && props.divisionId && props.typeProgramId) {
        guidelineInfo.value = await teamsClient.getTeamGenerationGuidelines({
          leagueId: props.upwardLeagueId,
          divisionId: props.divisionId,
          typeProgramId: props.typeProgramId,
        })
      } else {
        guidelineInfo.value = null
      }

      if (guidelineInfo.value) {
        modalIsVisible.value = true
      }
    }

    watch(
      () => modalIsVisible.value,
      async () => {
        if (modalIsVisible.value != props.value) {
          ctx.emit('input', modalIsVisible.value)
        }

        if (modalIsVisible.value) {
          await doShowAutoGenerationModal()
        }
      }
    )

    watch(
      () => props.value,
      () => {
        if (modalIsVisible.value != props.value) {
          modalIsVisible.value = props.value
        }
        generationData.value = getEmptyGenerationData()
      }
    )

    return {
      guidelineInfo,
      modalIsVisible,
      generationData,
      lowerCaseTeamSquadText,
      hideAutoGenerationModal,
      createRecommendedTeams,
    }
  },
})
