import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'

export function getEmptyLeagueDivisionInfo(): LeagueDivisionInfo {
  return {
    accountNumber: '',
    divisionID: 0,
    divisionName: '',
    gameCount: 0,
    gameLength: 0,
    gender: 'E',
    grades: [],
    exclusionDates: [],
    isInCheerSchedulerMapping: false,
    hasGameSchedulerPreferences: false,
    maxRoundNumber: 0,
    leagueID: 0,
    participantCount: 0,
    practiceLength: 0,
    upwardLeagueID: '',
    typePracticePlanID: '',
    lastPracticeDate: null,
    overrideNumberOfSegments: 0,
    typeLeagueScoringID: null,
    showStandingsTeamPage: false,
    typeGameFormatID: '',
    typeProgramID: '',
    orderedPlayerCount: 0,
    allowTeamColorSelection: false,
  }
}

/**
 * Returns if this is a purely generated LDI
 * coordinate this with logic and seeding above.
 *
 */
export function IsEmptyLeagueDivisionInfo(l: LeagueDivisionInfo | null) {
  return !!(!l || !l.accountNumber)
}
