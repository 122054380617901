













import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'UpwardValidationObserver',
  components: { ValidationObserver },
  setup(props, ctx) {
    const upwardObserver = ref(ctx.refs.upwObserver)
    const timeoutHandle = ref<NodeJS.Timeout | null>(null)

    const isValid = computed(() => {
      const obs = upwardObserver.value as InstanceType<typeof ValidationObserver>
      return obs ? obs.flags.valid : false
    })

    onMounted(() => {
      upwardObserver.value = ctx.refs.upwObserver
    })

    function reset() {
      const obs = upwardObserver.value as InstanceType<typeof ValidationObserver>
      if (obs) {
        obs.reset()
      }
    }

    async function validate() {
      const obs = upwardObserver.value as InstanceType<typeof ValidationObserver>
      if (obs) {
        return await obs.validate()
      }
      return false
    }

    watch(
      () => isValid.value,
      () => {
        if (timeoutHandle.value) {
          clearTimeout(timeoutHandle.value)
        }

        timeoutHandle.value = setTimeout(() => {
          ctx.emit('valid', isValid.value)
          timeoutHandle.value = null
        }, 250)
      },
      { immediate: true }
    )

    return {
      reset,
      validate,
    }
  },
})
