















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SidebarItem',
  props: {
    iconClass: { type: String, required: true },
    text: { type: String, required: true },
    exact: { type: Boolean, default: false, required: false },
  },
})
