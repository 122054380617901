import { LeagueProgramGenderGradeInfo } from '@/GeneratedTypes/ListInfo/LeagueProgramGenderGradeInfo.ts'

export const groupByGender = (list: LeagueProgramGenderGradeInfo[]) => {
  const gradesGroupedByGender: GradesGroupedByGender[] = []

  const grades = [...new Set(list.map((item) => item.typeGradeID))]
  grades.forEach((grade) => {
    const female =
      list.find((item) => item.gender === 'F' && item.typeGradeID === grade)?.participantCount || 0
    const male = list.find((item) => item.gender === 'M' && item.typeGradeID === grade)?.participantCount || 0
    gradesGroupedByGender.push({
      typeGradeID: grade,
      femaleCount: female,
      maleCount: male,
      total: male + female,
    })
  })
  return gradesGroupedByGender
}

export interface GradesGroupedByGender {
  typeGradeID: string | null
  femaleCount: number
  maleCount: number
  total: number
}
