
















import { defineComponent, PropType } from '@vue/composition-api'
import { SlideType } from '@/components/Carousel/types/Slide'

export default defineComponent({
  props: { slide: { type: Object as PropType<SlideType>, required: true } },
})
