






import { defineComponent, ref, computed } from '@vue/composition-api'
import CoachRoster from '@/views/AllAccess/Reports/CoachRoster.vue'

export default defineComponent({
  name: 'DynamicReports',
  components: {
    CoachRoster,
  },
  setup(props, ctx) {
    const reports = ref<{
      [reportName: string]: {
        name: string
        component: string
      }
    }>({
      coachroster: {
        name: 'Coaches Report',
        component: 'CoachRoster',
      },
    })

    const reportName = computed(() => ctx.root.$route.params.reportName)

    const reportComponent = computed(() => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return ''
      }
      return reports.value[reportName.value].component
    })

    return {
      reportComponent,
    }
  },
})
