




















import Modal from '@/components/Modal.vue'
import DateTimeInput from '@/elements/InlineDateTimeInput2.vue'
import { defineComponent, ref, computed } from '@vue/composition-api'
import uuid from 'uuid'
export default defineComponent({
  name: 'ModelDateTimePicker',
  props: {
    show: { type: Boolean, required: true },
    title: { type: String, required: false, default: 'Pick date & time' },
    cancelLabel: { type: String, required: false, default: 'Cancel' },
    pickLabel: { type: String, required: false, default: 'Pick' },
  },
  components: {
    Modal,
    DateTimeInput,
  },
  setup(props, { emit }) {
    const id = computed(() => uuid.v4())
    const now = new Date(Date.now())
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1)
    const dateValue = ref(today)
    const cancelled = () => {
      emit('cancelled')
    }
    const picked = () => {
      emit('picked', dateValue.value)
    }

    return { dateValue, cancelled, picked, id }
  },
})
