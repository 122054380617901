







import { defineComponent } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
export default defineComponent({
  name: 'PreviewButton',
  components: { Loading },
  props: { loading: { type: Boolean, required: false, defautl: true } },
  setup(props, { emit }) {
    function click() {
      emit('click')
    }
    return { click }
  },
})
