






















import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'
import uuid from 'uuid'

export default defineComponent({
  name: 'ToggleSwitchInput',
  components: {
    InputLabel,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    value: { type: Boolean, required: true },
    enabled: { type: Boolean, required: false, default: true },
  },
  setup(props, ctx) {
    const guid = uuid.v4()

    const internalValue = ref(props.value)

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    const elementId = computed(() => `toggleswitch-${guid}`)

    return {
      internalValue,
      handleChange,
      elementId,
    }
  },
})
