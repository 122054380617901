










import { defineComponent, computed } from '@vue/composition-api'
import PageContent from '@/components/PageContent.vue'

export default defineComponent({
  name: 'Training',
  components: { PageContent },
  setup(props, ctx) {
    const pageDocumentMap = new Map<string, string>([
      ['annual', 'annualtraining'],
      ['topical', 'topicaltraining'],
      ['whatsnew', 'whatsnew'],
      ['gospelvideoguide', 'gospelVideoGuide'],
      ['trainingdocuments', 'trainingDocuments'],
      ['shippingTimelines', 'shippingTimelines'],
    ])

    const pageName = computed(() => ctx.root.$route.params.pageName)

    const cmsDocumentId = computed(() => pageDocumentMap.get(pageName.value) ?? '')

    return {
      cmsDocumentId,
    }
  },
})
