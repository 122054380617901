




















































































import { computed, defineComponent, PropType, ref, watch, onMounted } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

import InputLabel from '@/elements/InputLabel.vue'
import ReturnsOrderListSection from './ReturnsOrderListSection.vue'
import ShippingAddressDisplay from '@/components/ShippingAddressDisplay.vue'
import OrderShipper from '@/components/OrderHistory/OrderShippers.vue'
import CostSummary from '@/components/OrderHistory/CostSummary.vue'
import MemoInput from '@/elements/MemoInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import SelectInput from '@/elements/SelectInput.vue'

import store from '@/store'
import ordersClient from '@/clients/ordersClient'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'
import { xReturnOrder } from '@/GeneratedTypes/xOrder/xReturnOrder'
import { SectionNameConversion } from '@/views/Programs/Orders/vues/history/ext/SectionNames'
import { ReturnOrderStatus, getEmptyReturnOrderStatus } from '@/models/Order/ReturnOrderStatus'
import { UpwardOrderStatusTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardOrderStatusTypeID'

export default defineComponent({
  name: 'ReturnsOrderDetail',
  components: {
    ReturnsOrderListSection,
    InputLabel,
    ShippingAddressDisplay,
    OrderShipper,
    CostSummary,
    MemoInput,
    CheckboxInput,
    SelectInput,
  },
  props: {
    order: { type: Object as PropType<xReturnOrder>, required: true },
    orderInfo: { type: Object as PropType<OrderInfoExt>, required: true },
  },
  setup(props, ctx) {
    const currStatusNote = ref(props.orderInfo.statusNote)
    const moveToNextStatus = ref(false)
    const statusButtonText = ref('Save Status Note')
    const statusButtonDisabled = ref(true)
    const statusCheckboxText = ref('')
    const statusCheckboxEnabled = ref(false)
    const statusNoteBlockVisible = ref(props.orderInfo.upwardOrderStatusID != 'COMPLETE')
    const rmaStatusModel = ref<ReturnOrderStatus>(getEmptyReturnOrderStatus())
    const selectedStatus = ref('')
    const statusList = computed(() =>
      cloneDeep(props.orderInfo.statusList ?? []).sort((a, b) =>
        a.valueOf() == b.valueOf() ? 0 : a.valueOf() > b.valueOf() ? -1 : 1
      )
    )

    const statusText = computed(() => props.orderInfo.upwardOrderStatusID ?? 'n/a')
    const orderDate = computed(() => props.orderInfo?.createDate && formatDate(props.orderInfo.createDate))
    function formatDate(d: Date) {
      return dayjs(d).format('MMM D, YYYY h:mm a')
    }
    const orderTitle = computed(() => SectionNameConversion[props.order.upwardOrderType ?? ''])
    const returnStatuses = ref<UpwardOrderStatusTypeID[]>([])

    onMounted(() => {
      store.dispatch.returnStatusTypes.fetchAll(false) //get these cached up
      returnStatuses.value = cloneDeep(store.getters.returnStatusTypes.allItems)
    })

    watch(
      () => store.getters.returnStatusTypes.allItems,
      () => {
        returnStatuses.value = cloneDeep(store.getters.returnStatusTypes.allItems)
      }
    )

    watch(
      () => currStatusNote.value,
      () => {
        updateStatusNoteSection()
      },
      { immediate: true }
    )

    watch(
      () => props.orderInfo,
      () => {
        updateStatusNoteSection()
      }
    )

    function updateStatusNoteSection() {
      if (props.orderInfo.upwardOrderStatusID == 'NEW') {
        statusCheckboxText.value = 'Send to Accounting'
        statusButtonDisabled.value = false
        if (currStatusNote.value) {
          statusButtonText.value = 'Save Status Note'
          statusCheckboxEnabled.value = true
        } else {
          statusButtonText.value = 'Place under SCM Review'
          statusCheckboxEnabled.value = false
          moveToNextStatus.value = false
        }
      } else {
        statusCheckboxText.value = 'Change status'
        statusButtonText.value = 'Save Status Note'
        if (currStatusNote.value) {
          statusCheckboxEnabled.value = true
          statusButtonDisabled.value = false
        } else {
          statusButtonDisabled.value = true
          statusCheckboxEnabled.value = false
          moveToNextStatus.value = false
        }
      }
    }

    async function saveNote() {
      //call the save and refresh the order so the status section gets refreshed
      rmaStatusModel.value.fromTypeOrderStatusID = props.orderInfo.upwardOrderStatusID ?? ''
      rmaStatusModel.value.toTypeOrderStatusID = props.orderInfo.upwardOrderStatusID ?? '' //default; change if needed below
      rmaStatusModel.value.note = currStatusNote.value

      //check edge case first
      if (props.orderInfo.upwardOrderStatusID == 'NEW') {
        if (moveToNextStatus.value) {
          rmaStatusModel.value.toTypeOrderStatusID = 'RETURNS_ACCOUNTING'
        } else {
          rmaStatusModel.value.toTypeOrderStatusID = 'RETURNS_SCM'
        }
      } else {
        if (moveToNextStatus.value) {
          rmaStatusModel.value.toTypeOrderStatusID = selectedStatus.value
        }
      }

      await ordersClient.setRMAStatus(props.orderInfo.upwardOrderID ?? '', rmaStatusModel.value).then(() => {
        ctx.emit('status-changed')
      })

      moveToNextStatus.value = false
    }

    const showNextStatus = computed(
      () => moveToNextStatus.value && props.orderInfo.upwardOrderStatusID !== 'NEW'
    )

    return {
      statusText,
      orderDate,
      statusList,
      formatDate,
      orderTitle,
      currStatusNote,
      saveNote,
      moveToNextStatus,
      statusButtonText,
      statusButtonDisabled,
      statusCheckboxText,
      statusCheckboxEnabled,
      statusNoteBlockVisible,
      selectedStatus,
      returnStatuses,
      showNextStatus,
    }
  },
})
