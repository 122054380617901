





































































import { DisplayType } from '@/models/Calendar/enums'
import { defineComponent, PropType, ref, watch, computed } from '@vue/composition-api'
import { Container } from 'vue-smooth-dnd'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'

import CalendarOptions from '@/elements/Calendar/CalendarOptions.vue'

import { ClickEvent, ClickDate } from '@/elements/Calendar/types'
import { SpecialEventCalendarData } from '@/views/Programs/Scheduling/SpecialEvents/ext/types'
import { programTypes } from '@/store/programTypes'

export type VCalenderType = Vue & { move: (amount: number) => void; title: string }

export default defineComponent({
  name: 'SpecialEventCalendar',
  components: { CalendarOptions, Container },
  props: {
    startDate: { type: String, required: false, default: () => dayjs().format('YYYY-MM-DD') },
    now: { type: String, required: false, default: () => dayjs().format('YYYY-MM-DD HH:mm:ss') },
    weekdays: { type: Array as PropType<number[]>, required: false, default: () => [0, 1, 2, 3, 4, 5, 6] }, // checkbox of weekdays
    groupName: { type: String, required: true }, // used to link the calendar and matchup dnd containers
    events: {
      type: Array as PropType<SpecialEventCalendarData[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const lWeekdays = ref([1])
    const lEvents = ref<SpecialEventCalendarData[]>([])
    const start = ref('2022-01-02') // begining of the date range displayed by the calendar
    const end = ref('2022-01-08') // ending of the date range displayed by the calendar
    const allowableDays = ref([0])
    const calendarTitle = ref('')

    let initialDate = ref<null | string>(null)
    const maxDays = computed(() => lWeekdays.value.length)

    function getEventTime(evt: SpecialEventCalendarData) {
      if (evt.timed) {
        return formatTime(evt.start)
      } else {
        return ''
      }
    }

    function prevMonth() {
      if (ctx.refs.thisCalendar) {
        ;(ctx.refs.thisCalendar as VCalenderType).move(-1)
      }
    }

    function nextMonth() {
      if (ctx.refs.thisCalendar) {
        ;(ctx.refs.thisCalendar as VCalenderType).move(1)
      }
    }

    function monthChanged() {
      calendarTitle.value = (ctx.refs.thisCalendar as VCalenderType).title
    }

    function getEventHover(evt: SpecialEventCalendarData) {
      let retval = evt.eventLabel

      if (evt.typeProgramID && evt.typeProgramID != 'NONE') {
        if (evt.divisionID > 0) {
          if (evt.teamID > 0) {
            retval = `${retval}\n${evt.teamName} (${evt.divisionName}) `
          } else {
            retval = `${retval}\n${evt.divisionName}`
          }
        } else {
          const programDesc =
            programTypes.state.items.find((p) => p.upwardTypeID == evt.typeProgramID)?.description ??
            evt.typeProgramID
          retval = `${retval}\n${programDesc}`
        }
      }
      if (evt.facilityID > 0) {
        retval = `${retval}\nLocation: ${evt.facilityName}`
      }
      if (evt.eventEnd) {
        retval = `${retval}\nEnds at ${formatTime(evt.end)}`
      }
      return retval
    }

    function getEventColor(event: any) {
      return event.color ? event.color : '#0475aa'
    }
    function getEventTextColor(event: any) {
      return event.textColor ? event.textColor : '#000'
    }

    /***************************************
     **   DRAG-AND-DROP WITHIN CALENDAR    *
     ***************************************/
    const eventDuration = ref<number | null>(null) // event duration in minutes
    let startXY: { x: number | null; y: number | null } = { x: null, y: null } // x and y when the mouse first clicks an event
    let isAllowed = true // is the day the event is being dragged to allowed; zero based

    function eventClick(e: ClickEvent) {
      if (isAllowed) {
        ctx.emit('click:event', e.event)
      }

      clear()
    }

    function dateClick(e: ClickDate) {
      if (isAllowed) {
        ctx.emit('click:date', e)
      }

      clear()
    }

    function moreClick(e: ClickDate) {
      //click:more has same event args as ClickDate so share
      if (isAllowed) {
        ctx.emit('click:more', e)
      }

      clear()
    }

    function allowed(dayOfWeek: number) {
      isAllowed = allowableDays.value.some((d) => d == dayOfWeek)
      return isAllowed
    }

    function clear() {
      eventDuration.value = null
      startXY.x = null
      startXY.y = null
      isAllowed = true
    }

    function formatTime(dt: string) {
      return dayjs(dt).format('h:mma')
    }

    /************************
     **   Watchers          *
     ************************/
    watch(
      () => props.weekdays,
      () => {
        allowableDays.value = props.weekdays
        lWeekdays.value = props.weekdays
      },
      { immediate: true }
    )
    watch(
      () => props.startDate,
      () => (initialDate.value = props.startDate),
      { immediate: true }
    )
    watch(
      () => props.events,
      () => (lEvents.value = cloneDeep(props.events)),
      { immediate: true }
    )
    return {
      initialDate,
      lWeekdays,
      maxDays,
      start,
      end,
      lEvents,
      formatTime,
      DisplayType,
      getEventColor,
      getEventTextColor,
      getEventTime,
      getEventHover,
      monthChanged,
      calendarTitle,
      eventClick,
      dateClick,
      moreClick,
      allowed,
      prevMonth,
      nextMonth,
    }
  },
})
