















































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import Vue from 'vue'
import EnlargeableProduct from './EnlargeableProduct.vue'
import SelectorCard from '@/components/SelectorCard.vue'
import SelectInput from '@/elements/SelectInput.vue'
import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { ProductOfferingProductColorInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductColorInfo'
import { getEmptyProductOfferingProductInfoList } from '@/lib/support/models/ProductOfferingProductInfo/ProductOfferingProductInfo'
import { getEmptySavedProductOfferingConfigDetail } from '@/lib/support/models/SavedProductOfferingConfigDetail/SavedProductOfferingConfigDetail'
import { TypeProductOption } from '@/lib/support/models/UpwardTypes/TypeProductOption'
import { getSelectedIndexes } from '@/lib/common/ProductOfferingConfig'
import { sortOffering } from '@/services/offeringService'
import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'
import { TypeProductOfferingProductPackage } from '@/lib/support/models/UpwardTypes/TypeProductOfferingProductPackageID'
import store from '@/store'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import { getColorDropDownItems } from '@/lib/common/upwardColorTypes'

interface SelectedProductColor {
  productID: number
  selectedColor: string | null
}

export default defineComponent({
  name: 'ProductPicker',
  components: {
    EnlargeableProduct,
    SelectorCard,
    SelectInput,
  },
  props: {
    products: {
      type: Array as PropType<ProductOfferingProductInfo[]>,
      default: getEmptyProductOfferingProductInfoList,
      required: false,
    },
    selectMultiple: { type: Boolean, default: true, required: false },
    required: { type: Boolean, default: true, required: false },
    category: { type: Number, default: 0, required: false },
    selectable: { type: Boolean, required: true },
    package: { type: String, required: false, default: '' },
    productOfferingId: { type: Number, required: true },
    groupID: { type: Number, default: 0, required: false },
  },
  setup(props) {
    const productOfferingConfig = computed(() => store.getters.productOfferings.productOfferingConfig)
    const valueLocal = ref<number | number[] | null>(props.selectMultiple ? [] : null)
    const selectedColors = ref<SelectedProductColor[]>([])
    const showColorMessage = ref<boolean[]>([])

    const logic = productFlowLogic()

    onMounted(async () => {
      await store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up
      valueLocal.value = getLocal()
      selectedColors.value = getSelectedColorsArray(productOfferingConfig.value.details, sortedProducts.value)
      setPackage()
    })

    function getLocal() {
      const indexes = getSelectedIndexes(
        productOfferingConfig.value.details,
        sortedProducts.value,
        props.category
      )
      if (props.selectMultiple) {
        return indexes ?? []
      } else {
        if (Array.isArray(indexes)) {
          return indexes[0]
        } else {
          return indexes
        }
      }
    }

    function setPackage() {
      if (!props.package) return
      resetSelection()

      if (props.package == TypeProductOfferingProductPackage.CUSTOM) return

      if (props.selectMultiple) {
        selectAllInPackage()
      } else {
        selectFirstInPackage()
      }
    }

    function selectAllInPackage() {
      sortedProducts.value.forEach((p, i) => {
        const poppInfo = p.productPackages?.find(
          (x) => x.typeProductOfferingProductPackageID == props.package
        )
        if (poppInfo) {
          if (Array.isArray(valueLocal.value)) {
            valueLocal.value.push(i)
          } else {
            valueLocal.value = i
          }
          if (p.colors && p.colors.length > 0) {
            const psc = selectedColors.value.find((x) => x.productID == p.productID)
            if (psc) {
              psc.selectedColor =
                poppInfo.typeColorID ??
                p.colors?.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))[0].typeColorID ??
                ''
            }
          }
        }
      })
    }

    function selectFirstInPackage() {
      const matchIdx = sortedProducts.value.findIndex((p) =>
        p.productPackages?.some((x) => x.typeProductOfferingProductPackageID == props.package)
      )
      if (matchIdx > -1) {
        valueLocal.value = matchIdx
      }
    }

    function manageButtonAction(p: ProductOfferingProductInfo, index: number, toggle: () => void) {
      if (p.colors && p.colors.length === 0) {
        Vue.set(showColorMessage.value, index, false)
        toggle()
      } else {
        Vue.set(showColorMessage.value, index, true)
      }
    }

    function getProductSelectedColor(id: number) {
      let retval: string | null = null
      const psc = selectedColors.value.find((x) => x.productID == id)
      if (psc != null) {
        retval = psc.selectedColor
      }
      return retval
    }

    function controlColorCard(color: string, productId: number, productIndex: number) {
      if (!color) {
        deselectButtonOnColorCard(productIndex)
      } else {
        selectButtonOnColorCard(color, productId, productIndex)
      }
      Vue.set(showColorMessage.value, productIndex, false)
    }

    function selectButtonOnColorCard(color: string, productId: number, productIndex: number) {
      const psc = selectedColors.value.find((x) => x.productID == productId)
      if (psc) {
        psc.selectedColor = color
      }
      if (Array.isArray(valueLocal.value)) {
        valueLocal.value.push(productIndex)
        valueLocal.value = [...new Set(valueLocal.value)]
      } else {
        valueLocal.value = productIndex
      }
    }

    function deselectButtonOnColorCard(productIndex: number) {
      if (Array.isArray(valueLocal.value)) {
        const idx = valueLocal.value.findIndex((v: number) => v === productIndex)
        valueLocal.value.splice(idx, 1)
      } else {
        valueLocal.value = null
      }
    }

    const sortedProducts = computed(() => sortOffering<ProductOfferingProductInfo>(props.products))

    function getColorDropDown(colors: ProductOfferingProductColorInfo[]) {
      return getColorDropDownItems(colors)
    }

    function getSelectedColorsArray(
      details: SavedProductOfferingConfigDetail[] | null,
      products: ProductOfferingProductInfo[]
    ) {
      const colors: SelectedProductColor[] = []
      if (!details) return colors

      products.forEach((p: ProductOfferingProductInfo) => {
        if (!p.colors || p.colors.length < 1) return // this prod has no colors

        const detail = details.find((i: SavedProductOfferingConfigDetail) => i.productID === p.productID)

        colors.push({ productID: p.productID, selectedColor: detail ? detail.typeColorID : null })
      })

      return colors
    }

    function convertToSaveFormat(product: ProductOfferingProductInfo) {
      if (!product) return
      const detail = getEmptySavedProductOfferingConfigDetail()
      detail.productID = product.productID
      detail.categoryID = props.category
      detail.typeProductOptionID = TypeProductOption.INCLUDED
      detail.productOfferingID = props.productOfferingId
      detail.typeColorID = 'N/A'
      if (product.colors && product.colors.length > 0) {
        const psc = selectedColors.value.find((x) => x.productID == product.productID)
        const color = psc?.selectedColor
        detail.typeColorID = color ? color : ''
      }
      return detail
    }

    function commitSavedProductDetails() {
      if (Array.isArray(valueLocal.value)) {
        // selectMultiple is true, so an array is returned from v-item-group
        arrayCommit()
      } else {
        // selectMultiple is false, so a single number is returned from v-item-group
        soloCommit()
      }

      logic.markAsDirty()
    }

    function arrayCommit() {
      const local = valueLocal.value as number[]

      const details = local.map((i: number) => {
        if (local !== null && sortedProducts.value) {
          return convertToSaveFormat(sortedProducts.value[i])
        }
      })

      if (details.length) {
        const productDetails = JSON.parse(JSON.stringify(details))
        setChangedProductDetails(productDetails)
      } else {
        // delete category for this productOfferingId
        const sel = getEmptySavedProductOfferingConfigDetail()
        sel.categoryID = props.category
        sel.productOfferingID = props.productOfferingId
        setChangedProductDetails([sel])
      }
    }

    function soloCommit() {
      const local = valueLocal.value as number
      let sel!: SavedProductOfferingConfigDetail | undefined

      if (sortedProducts.value) {
        sel = convertToSaveFormat(sortedProducts.value[local])
      }

      if (!sel) {
        // delete category for this productOfferingId
        sel = getEmptySavedProductOfferingConfigDetail()
        sel.categoryID = props.category
        sel.productOfferingID = props.productOfferingId
      }

      setChangedProductDetails([sel])
    }

    function setChangedProductDetails(details: SavedProductOfferingConfigDetail[]) {
      if (props.groupID && props.groupID > 0) {
        store.commit.productOfferings.setSelectedProductDetailsByGroup({
          productDetails: { groupID: props.groupID, details: details },
        })
      } else {
        store.commit.productOfferings.setSelectedProductDetails({ productDetails: details })
      }
    }

    function resetSelection() {
      if (Array.isArray(valueLocal.value)) {
        valueLocal.value = props.required ? [0] : []
      } else {
        valueLocal.value = props.required ? 0 : null
      }
      selectedColors.value = getSelectedColorsArray(productOfferingConfig.value.details, sortedProducts.value)
    }

    watch(
      () => selectedColors.value,
      () => {
        commitSavedProductDetails()
      },
      { deep: true }
    )

    watch(
      () => valueLocal.value,
      () => {
        commitSavedProductDetails()
      },
      { deep: true }
    )

    watch(
      () => props.package,
      () => {
        resetSelection()
        setPackage()
      }
    )

    function setSelected() {
      const indexes = getSelectedIndexes(
        productOfferingConfig.value.details,
        sortedProducts.value,
        props.category
      )
      valueLocal.value = props.selectMultiple ? indexes : indexes[0]
    }

    watch(
      () => productOfferingConfig.value.details,
      () => {
        setSelected()
      }
    )

    return {
      valueLocal,
      sortedProducts,
      manageButtonAction,
      showColorMessage,
      selectedColors,
      getProductSelectedColor,
      controlColorCard,
      getColorDropDown,
    }
  },
})
