















































































import { computed, defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import { filterNA, makeOrderLine, OrderProduct } from '@/models/Order/OrderProduct'
import EnlargeableProduct from '@/components/ProductFlow/ParticipantKitStepper/ProductPickers/EnlargeableProduct.vue'
import SelectInput from '@/elements/SelectInput.vue'
import NumberInput from '@/elements/NumberInput.vue'
import TextInput from '@/elements/TextInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import GridCard from '@/components/GridCard.vue'
import { cloneDeep } from 'lodash'
import { getEmptyOrderProductSize } from '@/models/Order/OrderProductSize'
import currency from '@/filters/currency'
import { UpwardSizeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardSizeTypeID'
import store from '@/store'
import { getEmptyPendingOrderAdditionalProductInfo } from '@/models/PendingOrderAdditionalProductInfo/data'

const ADD_TIMEOUT = 3000

interface IDDesc {
  id: string
  desc: string
}

export default defineComponent({
  components: {
    NumberInput,
    SelectInput,
    TextInput,
    CheckboxInput,
    EnlargeableProduct,
    GridCard,
  },
  methods: { filterNA },
  props: {
    product: { type: Object as PropType<OrderProduct>, required: true },
    validSizes: { type: Array as PropType<UpwardSizeTypeID[]>, required: true },
    isAddOnItem: { type: Boolean, required: false, default: false },
    showIsFreeCheckbox: { type: Boolean, required: false, default: false },
    isFreeCheckboxDefault: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const isAdding = ref(false)
    const makeQtyFree = ref(props.isFreeCheckboxDefault)
    const internalProduct = ref(props.product)
    /**
     * Internal product will reflect a product with productColorSize always defined.
     */
    watch(
      () => props.product,
      () => {
        internalProduct.value = cloneDeep(props.product)
        if (!internalProduct.value.productColorSizes || !internalProduct.value.productColorSizes.length) {
          internalProduct.value.productColorSizes = [getEmptyOrderProductSize()]
        }
      }
    )

    const unique = <T extends unknown>(key: keyof T) => (v: T, i: number, a: T[]) =>
      a.findIndex((y) => y[key] == v[key]) === i
    const colors = computed(() =>
      internalProduct.value.productColorSizes?.length
        ? internalProduct.value.productColorSizes
            ?.filter(unique('colorDescription'))
            .map((x) => ({ id: x.typeColorID, desc: x.colorDescription } as IDDesc))
        : []
    )
    const sizes = computed(() =>
      internalProduct.value.productColorSizes?.length
        ? internalProduct.value.productColorSizes
            .filter(unique('sizeDescription'))
            .filter(
              (y) => props.validSizes.find((x) => x.upwardTypeID == y.typeSizeID)?.isSystemInternal == false
            )
            .map((x) => ({ id: x.typeSizeID, desc: x.sizeDescription } as IDDesc))
        : []
    )
    const colorRef = ref(internalProduct.value.productColorSizes![0].typeColorID)
    const sizeRef = ref(internalProduct.value.productColorSizes![0].typeSizeID)
    const quantityRef = ref(0)
    const jerseyNoRef = ref<string | null>(null)
    const isCustomJersey = computed(() => internalProduct.value.requiresJerseyNumber)

    const showColors = computed(
      () =>
        colors.value &&
        (colors.value.length >= 2 || (colors.value.length === 1 && colors.value[0].id !== 'N/A'))
    )

    const addDisabled = computed(() => {
      return isCustomJersey.value
        ? quantityRef.value <= 0 ||
            !jerseyNoRef.value ||
            !(Number(jerseyNoRef.value) >= 0) ||
            !(Number(jerseyNoRef.value) <= 99)
        : quantityRef.value <= 0
    })

    /**
     * Resets drop down selections and quantity
     */
    function resetDropdownSelection() {
      colorRef.value = internalProduct.value.productColorSizes![0].typeColorID
      sizeRef.value = internalProduct.value.productColorSizes![0].typeSizeID
      quantityRef.value = internalProduct.value.orderQuantity || 0
    }

    onMounted(() => {
      resetDropdownSelection()
    })

    const currentVariation = computed(
      () =>
        internalProduct.value.productColorSizes!.find(
          (u) => u.typeColorID == colorRef.value && u.typeSizeID == sizeRef.value
        ) || getEmptyOrderProductSize()
    )

    const price = computed(
      () => (currentVariation.value?.costOffset ?? 0) + (internalProduct.value?.cost ?? 0)
    )
    const priceLine = computed(
      () =>
        `${price.value > 0 ? `${currency(price.value)}` : `FREE`} ${internalProduct.value.cmsUnitDescription}`
    )

    /**
     * Takes a size selection and finds a valid permutation, resetting color if
     * necessary
     * @param newSize
     */
    function selectSize(newSize: string) {
      // do we have a valid variation?
      const isSCCombo = internalProduct.value.productColorSizes!.find(
        (u) => u.typeColorID == colorRef.value && u.typeSizeID == newSize
      )

      let size = newSize
      let color = colorRef.value
      // no then, lets set our color to a valid one for the size
      if (!isSCCombo) {
        //find first variation with the new size
        const variation = internalProduct.value.productColorSizes!.find((u) => u.typeSizeID == newSize)

        //wild, no valid color or size.
        if (!variation) {
          resetDropdownSelection()
          return
        }
        color = variation.typeColorID
      }
      //we have a valid size and
      colorRef.value = color
      sizeRef.value = size
    }

    function selectColor(newColor: string) {
      // do we have a valid variation?
      const isSCCombo = internalProduct.value.productColorSizes!.find(
        (u) => u.typeSizeID == sizeRef.value && u.typeColorID == newColor
      )

      let color = newColor
      let size = sizeRef.value
      // no then, lets set our size to a valid one for the color
      if (!isSCCombo) {
        //find first variation with the new color
        const variation = internalProduct.value.productColorSizes!.find((u) => u.typeColorID == newColor)

        //no valid size or color.
        if (!variation) {
          resetDropdownSelection()
          return
        }
        size = variation.typeSizeID
      }
      //we have a valid color and
      sizeRef.value = size
      colorRef.value = color
    }

    function select() {
      isAdding.value = true
      if (props.isAddOnItem) {
        ctx.emit('product-update', addOnItem())
      } else {
        ctx.emit('product-update', {
          ...makeOrderLine(internalProduct.value, currentVariation.value),
          orderQuantity: quantityRef.value,
          orderFreeQuantity: makeQtyFree.value ? quantityRef.value : 0,
        })
      }
      quantityRef.value = 0
      setTimeout(() => (isAdding.value = false), ADD_TIMEOUT)
    }

    const leagueId = computed(() => store.getters.leagueAbstraction.currentItem.id)
    const account = computed(() => store.getters.authorization.firstAccountNumber)
    function addOnItem() {
      return {
        ...getEmptyPendingOrderAdditionalProductInfo(),
        quantity: quantityRef.value,
        jerseyNumber: jerseyNoRef.value ?? '',
        typeColorID: colorRef.value,
        typeSizeID: sizeRef.value,
        leagueID: leagueId.value,
        accountNumber: account.value,
        typeProgramID: internalProduct.value.typeProgramID,
        productID: Number(internalProduct.value.id),
      }
    }

    return {
      select,
      selectSize,
      selectColor,
      isAdding,
      colors,
      showColors,
      sizes,
      colorRef,
      sizeRef,
      quantityRef,
      priceLine,
      internalProduct,
      productSummary: false,
      jerseyNoRef,
      isCustomJersey,
      addDisabled,
      makeQtyFree,
    }
  },
})
