





































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import store from '@/store'
import userClient from '@/clients/userClient'

export default defineComponent({
  name: 'manage2FA',
  props: {},
  components: { Loading, ConfirmationModal },
  setup(props, ctx) {
    //const update2FA = store.dispatch.authorization.passwordChange

    const loading = ref(false)
    const is2FAEnabled = ref(false)
    const showEnableConfirmation = ref(false)
    const showDisableConfirmation = ref(false)

    const isAdmin = store.getters.authorization.isCurrentUserAnAdmin

    onMounted(async () => {
      await check2FAStatus()
    })

    async function check2FAStatus() {
      const email = store.getters.authorization.email
      const status = await userClient.get2FAStatus(email)
      is2FAEnabled.value = status == 'Enabled'
    }

    async function onDisableConfirmation(confirmed: boolean) {
      showDisableConfirmation.value = false
      if (confirmed) {
        loading.value = true
        try {
          const email = store.getters.authorization.email
          await userClient.set2FAStatus(email, false)
          await check2FAStatus()
        } finally {
          loading.value = false
        }
      }
    }

    async function onEnableConfirmation(confirmed: boolean) {
      showEnableConfirmation.value = false
      if (confirmed) {
        loading.value = true
        try {
          const email = store.getters.authorization.email
          await userClient.set2FAStatus(email, true)
          store.commit.authorization.clearCurrentCredentials() //log them out so they can get to the 2FA setup page
          ctx.root.$router.push('/')
        } finally {
          loading.value = false
        }
      }
    }

    return {
      loading,
      is2FAEnabled,
      isAdmin,
      showEnableConfirmation,
      showDisableConfirmation,
      onDisableConfirmation,
      onEnableConfirmation,
    }
  },
})
