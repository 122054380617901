import restService, { RestServiceResult } from '@/services/restService'
import { AuthToken } from '@/models/Authorization/AuthToken'
import { Credentials } from '@/models/Authorization/Credentials'
import { RequiredString } from '@/models/RequiredString'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { PasswordExpiredException } from '@/lib/common/exceptions/login/PasswordExpiredException'
import { LoginException } from '@/lib/common/exceptions/login/LoginException'
import { LockedAccountException } from '@/lib/common/exceptions/login//LockedAccountException'
import { UserNotFoundException } from '@/lib/common/exceptions/login/UserNotFoundException'
import { AxiosRequestConfig } from 'axios'
import { ProfilePayload } from '@/models/Authorization/ProfilePayload'

const baseUrl = 'authorization'
const appId = 'Streamline'

class AuthClientException extends GeneralError {
  name = 'Auth API Exception'
}

export interface ImpersonationPayload {
  userName?: string
  accountNumber: string
}

const login = async (username: string, password: string, accountNumber = '') => {
  const credentials = {
    username,
    password,
    appID: appId,
    accountNumber: accountNumber,
    newPassword: null,
  } as Credentials

  try {
    const t = await restService.post<AuthToken>(`${baseUrl}/login/`, credentials)
    if (t.status == 200) {
      return t.data as AuthToken
    }
  } catch (e) {
    const t = e as RestServiceResult<void>

    if (t.status === 401 && t.errorObject?.message == 'nomatch') {
      throw new UserNotFoundException('Login not found')
    }
    if (t.status === 403) {
      throw new PasswordExpiredException('Password has expired.')
    }
    if (t.status === 401 && t.errorObject?.message == 'locked') {
      throw new LockedAccountException('This account is locked. Please reset your password')
    }
  }
  throw new LoginException('logging in with authorization token')
}

const validateTOTP = async (totpCode: string) => {
  try {
    const t = await restService.post<AuthToken>(`${baseUrl}/validateTOTP/`, { value: totpCode })
    if (t.status == 200) {
      return t.data as AuthToken
    }
  } catch (e) {
    const t = e as RestServiceResult<void>

    if (t.status === 401 && t.errorObject?.message == 'locked') {
      throw new LockedAccountException('This account is locked. Please reset your password')
    }
  }

  throw new AuthClientException('validating totp')
}

const refreshToken = async () => {
  const t = await restService.post<AuthToken>(`${baseUrl}/tokenrefresh/`, null)
  if (t.isSuccess) {
    return t.data
  }
  throw new AuthClientException('refreshing auth token')
}

const passwordChange = async (username: string, password: string, newPassword: string) => {
  const credentials = {
    username,
    password,
    appID: appId,
    accountNumber: '',
    newPassword,
  } as Credentials

  return await restService.post<null>(`${baseUrl}/passwordchange/`, credentials)
}

const getSystemToken = async () => {
  return await restService.get<AuthToken>(`${baseUrl}/systemtoken/`)
}

const getPasswordResetLink = async (username: string) => {
  const data = {
    value: username,
  } as RequiredString

  const systemToken = await getSystemToken()
  return await restService.post<void>(`${baseUrl}/passwordreset`, data, {
    headers: {
      Authorization: `Bearer ${systemToken.data!.upwardJWToken}`,
    },
  } as AxiosRequestConfig)
}

const adminResetPassword = async (userID: string, password: string) => {
  const i = await restService.post(`${baseUrl}/passwordreset/admin/${userID}`, { value: password })
  if (i.isSuccess) {
    return i.data
  }
  throw new AuthClientException('resetting password failed')
}

const adminResetPasswordEmail = async (email: string) => {
  const i = await restService.post(`${baseUrl}/passwordreset/`, { value: email })
  if (i.isSuccess) {
    return i.data
  }
  throw new AuthClientException('resetting password failed')
}

const adminResetPasswordSMS = async (userID: string, phone_number: string) => {
  const i = await restService.post(`${baseUrl}/passwordreset/sms/${userID}`, { value: phone_number })
  if (i.isSuccess) {
    return i.data
  }
  throw new AuthClientException('resetting phone number failed')
}

const resetPassword = async (code: string, newPassword: string) => {
  const data = {
    value: newPassword,
  } as RequiredString

  const systemToken = await getSystemToken()
  return await restService.post<AuthToken>(`${baseUrl}/passwordreset/${code}`, data, {
    headers: {
      Authorization: `Bearer ${systemToken.data!.upwardJWToken}`,
    },
  } as AxiosRequestConfig)
}

const acceptInvitation = async (code: string, newPassword: string) => {
  const data = {
    value: newPassword,
  } as RequiredString

  const systemToken = await getSystemToken()
  return await restService.post<AuthToken>(`invitations/${code}/accept`, data, {
    headers: {
      Authorization: `Bearer ${systemToken.data!.upwardJWToken}`,
    },
  } as AxiosRequestConfig)
}

/***
 * Token might come on the URL and this will turn it into a login credential.
 * @param token
 * @return AuthToken
 */
const tokenLogin = async (token: string) => {
  const payload = {
    userToken: token,
  }

  try {
    const t = await restService.post<AuthToken>(`${baseUrl}/loginbytoken`, payload)
    if (t.isSuccess) {
      return t.data
    }
  } catch (e) {
    const t = e as RestServiceResult<void>

    if (t.status === 401 && t.errorObject?.message == 'nomatch') {
      throw new UserNotFoundException('Login not found')
    }
    if (t.status === 403) {
      throw new PasswordExpiredException('Password has expired.')
    }
    if (t.status === 401 && t.errorObject?.message == 'locked') {
      throw new LockedAccountException('This account is locked. Please reset your password')
    }
  }
  throw new LoginException('User or password combination not found.')
}

/***
 * Returns an auth token for impersonation.
 * @param userName - optional, use it to impersonate a user
 * @param accountNumber - account to impersonate
 * @return token
 */
const impersonate = async ({ userName, accountNumber }: ImpersonationPayload) => {
  if (!userName && !accountNumber) {
    throw new AuthClientException('When impersonating an account or username must be present.')
  }

  const payload = {
    username: userName ? userName : 'default',
    password: 'default',
    appID: 'default',
    accountNumber: accountNumber,
  }

  const r = await restService.post<AuthToken>(`${baseUrl}/impersonate`, payload)
  if (r.isSuccess) {
    return r.data
  }

  throw new AuthClientException('Invalid impersonation attempt', r.errorObject)
}

const updateProfile = async (p: ProfilePayload) => {
  const data: ProfilePayload = {
    username: p.hasOwnProperty('username') ? p.username : '',
    appID: appId,
  }

  if (p.hasOwnProperty('newUsername') && p.newUsername) {
    data['newUsername'] = p.newUsername
    data['usernameChangeCode'] =
      p.hasOwnProperty('usernameChangeCode') && p.usernameChangeCode ? p.usernameChangeCode : ''
  }

  if ((p.hasOwnProperty('newFullName'), p.newFullName)) {
    data['newFullName'] = p.newFullName
  }

  return await restService.post<AuthToken>('authorization/updateprofile', data)
}

export default {
  login,
  impersonate,
  refreshToken,
  passwordChange,
  getPasswordResetLink,
  resetPassword,
  acceptInvitation,
  updateProfile,
  tokenLogin,
  adminResetPassword,
  adminResetPasswordEmail,
  adminResetPasswordSMS,
  validateTOTP,
}
