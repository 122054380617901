import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'

enum AddressType {
  MAILING = 'MAILING',
  SHIPPING = 'SHIPPING',
}

export function stringToAddressTypeID(s: string | AddressType): string {
  if (typeof s == 'string') {
    switch (s) {
      case 'home':
        return AddressType.MAILING
      case 'work':
        return AddressType.SHIPPING
      case 'shipping':
        return AddressType.SHIPPING
      case 'mailing':
        return AddressType.MAILING
    }
  }
  return s
}

export function getEmptyIndividualAddress(seed?: IndividualAddressSeedInfo): IndividualAddress {
  return {
    addressID: 0,
    typeAddressID: seed?.typeAddressID || stringToAddressTypeID('mailing'),
    street1: '',
    street2: '',
    subdivision1: '', //city
    subdivision2: '', //state
    postalCode: '',
    typeCountryID: 'US',
    priority: 0,
  }
}

export class IndividualAddressSeedInfo {
  public typeAddressID: string = stringToAddressTypeID('mailing')
}
