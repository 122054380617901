import { DataTableHeader } from 'vuetify/types'
import store from '@/store'

export const headers: DataTableHeader[] = [
  { text: 'Round', value: 'round' },
  { text: 'Game Name', value: 'title' },
  { text: 'Date', value: 'prettyDate' },
  { text: 'Time', value: 'prettyTime' },
  { text: 'Location', value: 'location' },
  { text: `${store.getters.leagueAbstraction.refLabel}s`, value: 'referees' },
  { text: '', value: 'actions' },
]
