import ordersClient from '@/clients/ordersClient'
import { Ref, UnwrapRef } from '@vue/composition-api'
import { xStartupOrder } from '@/GeneratedTypes/xOrder/xStartupOrder'
import { xReturnOrderExt } from '@/models/Order/xReturnOrderExt'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'

export async function loadOrder(
  league: string,
  orderid: string,
  currentOrder: Ref<UnwrapRef<xStartupOrder | null>>,
  currentOrderInfo: Ref<UnwrapRef<OrderInfoExt | null>>,
  errorMessage: Ref<UnwrapRef<string>>
) {
  const timeout = new Promise((acc, rej) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      //basically a timeout, but instructed to explain to the user as so
      rej(new Error('Order loading error.'))
    }, 30 * 1000)
  })

  const getorder = async () => {
    if (currentOrderInfo && currentOrder) {
      ;[currentOrder.value, currentOrderInfo.value] = await Promise.all([
        ordersClient.getOrder({ leagueID: league, id: orderid }),
        ordersClient.getOrderInfo({
          leagueID: league,
          id: orderid,
        }),
      ])
    }
    errorMessage.value = ''
  }

  /*
        race a promise against a timeout that rejects and then another promise
        that's only job is to try to resolve two promises running in parallel
        that are expected to both complete successfully 😵
         */
  await Promise.race([timeout, getorder()])
}

export async function loadReturnsOrder(
  league: string,
  orderid: string,
  currentOrder: Ref<UnwrapRef<xReturnOrderExt | null>>,
  currentOrderInfo: Ref<UnwrapRef<OrderInfoExt | null>>,
  errorMessage: Ref<UnwrapRef<string>>
) {
  const timeout = new Promise((acc, rej) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      //basically a timeout, but instructed to explain to the user as so
      rej(new Error('Order loading error.'))
    }, 30 * 1000)
  })

  const getorder = async () => {
    if (currentOrderInfo && currentOrder) {
      ;[currentOrder.value, currentOrderInfo.value] = await Promise.all([
        ordersClient.getRMA({ leagueID: league, id: orderid }),
        ordersClient.getOrderInfo({
          leagueID: league,
          id: orderid,
        }),
      ])
    }
    errorMessage.value = ''
  }

  /*
        race a promise against a timeout that rejects and then another promise
        that's only job is to try to resolve two promises running in parallel
        that are expected to both complete successfully 😵
         */
  await Promise.race([timeout, getorder()])
}
