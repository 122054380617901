































import { defineComponent, computed, onMounted, nextTick } from '@vue/composition-api'

import Stepper from '@/elements/Stepper.vue'
import InfoStep from '@/components/ProductFlow/ProductSetupStepper/InfoStep.vue'
import AccessFeeStep from '@/components/ProductFlow/ProductSetupStepper/CampOnlySteps/AccessFeeStep.vue'
import UpwardSystemStep from '@/components/ProductFlow/ProductSetupStepper/CampOnlySteps/UpwardSystemStep.vue'
import StarterItemsStep from '@/components/ProductFlow/ProductSetupStepper/StarterItemsStep.vue'
import ParticipantKitStep from '@/components/ProductFlow/ProductSetupStepper/ParticipantKitStep.vue'
import RunningTotal from '@/components/ProductFlow/ProductSetupStepper/RunningTotal.vue'
import Loading from '@/elements/Loading.vue'

import { setupStepperLogic } from '@/components/ProductFlow/ProductSetupStepper/SetupStepperLogic'

import store from '@/store'

export default defineComponent({
  name: 'CampSetupStepper',
  props: {},
  components: {
    Stepper,
    InfoStep,
    AccessFeeStep,
    UpwardSystemStep,
    StarterItemsStep,
    ParticipantKitStep,
    RunningTotal,
    Loading,
  },
  setup() {
    const stepperLogic = setupStepperLogic()
    onMounted(async () => {
      stepperLogic.currentStep.value = 0
      await stepperLogic.initialize()
      store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up
      nextTick(() => (stepperLogic.currentStep.value = 1)) //trigger expansion of first step
    })
    const listOfPrograms = computed(() => store.getters.leagueAbstraction.leagueUpwardProgramTypeID)

    const loading = computed(() => {
      if (store.getters.productFlow.isNewProdConfig) {
        return stepperLogic.stepComponents.value.length == 0
      } else {
        return (
          !store.getters.productOfferings.initialProductFlowLoadingComplete &&
          stepperLogic.stepComponents.value.length == 0
        )
      }
    })

    return {
      listOfPrograms,
      loading,
      ...stepperLogic,
    }
  },
})
