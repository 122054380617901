export function cleanPhoneNumber(phoneNumber: string | null): string | null {
  if (!phoneNumber) {
    return phoneNumber
  }

  const digits = phoneNumber.match(/\d/g)
  return digits!.join('')
}

/***
 * Returns a formatted phone number
 * @param phoneAndAreaCode
 */
export function formatPhoneNumber(phoneAndAreaCode: string) {
  const cleanPhone = cleanPhoneNumber(phoneAndAreaCode) || ''

  if (cleanPhone.length === 10) {
    return `(${cleanPhone.substring(0, 3)}) ${cleanPhone.substring(3, 6)}-${cleanPhone.substring(6, 10)}`
  }

  if (cleanPhone.length === 7) {
    return `${cleanPhone.substring(0, 3)}-${cleanPhone.substring(3, 7)}`
  }

  return cleanPhone
}
