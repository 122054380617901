


































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'
import uuid from 'uuid'
import { HelpIcon } from './Icons'

export default defineComponent({
  name: 'CheckboxInput',
  props: {
    label: { type: String, default: '', required: false },
    inline: { type: Boolean, default: true, required: false },
    required: { type: Boolean, default: false, required: false },
    value: { type: Boolean, required: true },
    enabled: { type: Boolean, required: false, default: true },
    labelClass: { type: String, required: false },
    showHelp: { type: Boolean, default: false, required: false },
    noIndent: { type: Boolean, required: false, default: false },
  },
  components: {
    InputLabel,
    HelpIcon,
  },
  setup(props, ctx) {
    const guid = uuid.v4()

    const internalValue = ref(props.value)

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    function helpClicked() {
      ctx.emit('helpClicked')
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    const elementId = computed(() => `checkbox-${guid}`)

    return {
      internalValue,
      handleChange,
      elementId,
      helpClicked,
    }
  },
})
