

















































import { defineComponent, computed, ref, watch, onErrorCaptured } from '@vue/composition-api'
import '@/css'
import TheErrorRenderer from '@/singletons/TheErrorRenderer.vue'
import TheSidebar from '@/singletons/TheSidebar.vue'
import TheHeader from '@/singletons/TheHeader.vue'
import ThePromptToSave from '@/singletons/ThePromptToSave.vue'
import TheFooter from '@/singletons/TheFooter.vue'
import TheAuthWall from '@/singletons/TheAuthWall.vue'
import SiteLoading from '@/components/SiteLoading.vue'
import Notifications from '@/components/Notifications/Notifications.vue'
import { RouteMeta } from '@/router/'
import { convertToRestServiceResult, convertToVerificationDetails } from '@/services/restService'
import { UpwardExceptionResult, UpwardError } from '@/models/UpwardExceptionResult'
import store from '@/store'

export default defineComponent({
  name: 'MainApp',
  components: {
    TheErrorRenderer,
    TheAuthWall,
    TheSidebar,
    TheFooter,
    TheHeader,
    ThePromptToSave,
    Notifications,
    SiteLoading,
  },
  setup(props, ctx) {
    const isAuthenticated = computed(() => store.getters.authorization.isAuthenticated)
    const isImpersonated = computed(() => store.getters.authorization.isImpersonated)
    const codeReloadRequired = computed(() => store.getters.authorization.codeReloadRequired)
    const refreshTime = computed(() => store.getters.authorization.refreshTime)
    const isCurrentUserAnAdmin = computed(() => store.getters.authorization.isCurrentUserAnAdmin)
    const now = computed(() => store.getters.now)
    const userInactiveMS = computed(() => store.getters.userInactiveMS)

    const userHasBeenSeenThisTimePeriod = ref(false)
    const drawerOpen = ref<boolean | null>(true)
    const showNotifications = ref(false)

    function clickNotification() {
      showNotifications.value = !showNotifications.value
    }

    const unreadNotificationCount = computed(() => store.getters.alerts.unreadNotificationCount)

    watch(
      () => isAuthenticated.value,
      async () => {
        if (isAuthenticated.value) {
          console.log('caching upward types')
          await store.dispatch.gradeTypes.fetchAll(false)
          await store.dispatch.leagueTypes.fetchAll(false)
          await store.dispatch.programTypes.fetchAll({ force: false })
          await store.dispatch.colorTypes.fetchAll({ force: false })
          await store.dispatch.relationshipTypes.fetchAll({ force: false })
          await store.dispatch.sizeTypes.fetchAll({ force: false })
          await store.dispatch.paymentTypes.fetchAll({ force: false })
        }
      },
      { immediate: true }
    )

    watch(
      () => showNotifications.value,
      async () => {
        if (!showNotifications.value && unreadNotificationCount.value > 0) {
          await store.dispatch.alerts.markMyNotificationsRead()
        }
      }
    )

    function clickHamburger() {
      drawerOpen.value = !drawerOpen.value
    }

    const mini = computed(() => ctx.root.$vuetify.breakpoint.smAndDown)

    const isCurrentRoutePublic = computed(() =>
      ctx.root.$route.matched.some((r) => (r.meta as RouteMeta).isPublic)
    )

    watch(
      () => now.value,
      async () => {
        if (isAuthenticated.value) {
          if (userHasBeenSeenThisTimePeriod.value) {
            store.commit.updateUserLastSeen(null)
            userHasBeenSeenThisTimePeriod.value = false
          } else if (userInactiveMS.value >= 60 * 60 * 1000) {
            store.commit.authorization.clearCurrentCredentials()

            if (ctx.root.$route.fullPath !== '/') {
              await ctx.root.$router.push('/') //see #2787 - redirect to home on logout.
            }
            store.commit.errors.addError({
              item: {
                message: 'You have been logged out because of inactivity',
              } as UpwardExceptionResult,
            })

            return
          }

          if (refreshTime.value && now.value >= refreshTime.value) {
            store.dispatch.authorization.refreshToken()
          }
        }
      }
    )

    function watchForActivity() {
      const onActivity = () => (userHasBeenSeenThisTimePeriod.value = true)

      document.addEventListener('mousemove', onActivity, false)
      document.addEventListener('mousedown', onActivity, false)
      document.addEventListener('keypress', onActivity, false)
      document.addEventListener('touchmove', onActivity, false)
    }

    /**
     * #2031 brings in a requirement that the sidebar menu vary based on being on the admin dashboard
     *
     */
    const sideBarFlavor = computed<'regular' | 'dashboard'>(() => {
      if (isCurrentUserAnAdmin.value) {
        return 'dashboard'
      }
      return 'regular'
    })

    onErrorCaptured((err: any) => {
      // Validation result object
      const validationResult = convertToVerificationDetails(err)

      if (
        validationResult &&
        validationResult.data &&
        validationResult.data.brokenRules &&
        validationResult.data.brokenRules.length
      ) {
        store.commit.errors.addValidationError({ item: validationResult.data! })
        return false // Stop propagation
      }

      // Rest service result object
      const genericResult = convertToRestServiceResult(err)

      if (genericResult !== null && genericResult.errorObject !== null) {
        store.commit.errors.addError({ item: genericResult.errorObject })
        return false // Stop propagation
      }

      const generalError =
        typeof err === 'object' && err !== null && 'innerException' in err ? (err as UpwardError) : null

      // Unknown
      store.commit.errors.addError({
        item: {
          message: 'The following error occurred',
          errors: [
            generalError ??
              ({
                message: `${err}`,
              } as UpwardError),
          ],
        } as UpwardExceptionResult,
      })

      return true
    })

    //this is from the SWRefreshMixin mixin that is not actually being shared anymore
    const refreshing = ref(false)
    const registration = ref<any>(null)
    const updateExists = ref(false)

    function setServiceWorkerListener() {
      // Listen for our custom event from the SW registration
      document.addEventListener('swUpdated', updateAvailable, { once: true })

      if (navigator && navigator.serviceWorker) {
        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (refreshing.value) return
          refreshing.value = true
          // Here the actual reload of the page occurs
          window.location.reload()
        })
      }
    }

    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    function updateAvailable(event: any) {
      store.commit.authorization.setCodeReloadRequired({ val: true })
      registration.value = event.detail
      updateExists.value = true
    }

    // Called when the user accepts the update
    function refreshApp() {
      updateExists.value = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      //@ts-ignore
      if (!registration.value || !registration.value.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      //@ts-ignore
      registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
    //end SWRefreshMixin mixin code

    //this was in the created() method under the class style
    if (ctx.root.$route.query.token) {
      //User is trying to login using a passthrough token from another app.
      //Clear any old credentials.
      store.commit.authorization.clearCurrentCredentials()
    } else {
      store.dispatch.startTimekeeping(false)
      store.dispatch.authorization.tryLoadSavedToken()
      watchForActivity()
      setServiceWorkerListener()
    }

    return {
      isCurrentRoutePublic,
      codeReloadRequired,
      isAuthenticated,
      isImpersonated,
      showNotifications,
      drawerOpen,
      mini,
      sideBarFlavor,
      updateExists,
      clickHamburger,
      clickNotification,
      refreshApp,
    }
  },
})
