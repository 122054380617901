




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GridCard',
  props: {
    dark: { type: Boolean, required: false, default: false },
    isSelected: { type: Boolean, required: false, default: false },
  },
})
