






























import { defineComponent, PropType, computed } from '@vue/composition-api'
import { ScheduleGridData } from '@/views/Programs/Scheduling/ext/helper'
import { isNullDate, toDate } from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'

export default defineComponent({
  name: 'GameScheduleInfo',
  props: {
    games: { type: Array as PropType<DivisionGameInfo[]>, required: true },
    rounds: { type: Array as PropType<{ id: number; description: string }[]>, required: true },
    schedule: { type: Array as PropType<ScheduleGridData[]>, required: true },
    divisionId: { type: Number, required: true },
  },
  setup(props) {
    const hasNoSchedule = computed(() => unscheduledMatchupCount.value == 0 && props.schedule.length == 0)
    const unscheduledMatchupCount = computed(() => {
      const unscheduled = props.games.filter(
        (x) => isNullDate(toDate(x.gameStart)) && x.divisionID == props.divisionId
      )
      return unscheduled.length
    })

    return { unscheduledMatchupCount, hasNoSchedule }
  },
})
