




































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'WaitPage',
  setup() {
    const showWaitMessage = ref(true)
    const timerInterval = ref<any | null>()
    const timePassed = ref(0)
    const timeLimit = ref(12)

    const timeLeft = computed((): number => timeLimit.value - timePassed.value)

    function startTimer() {
      timerInterval.value = setInterval(() => (timePassed.value += 1), 1000)
    }

    onMounted(() => {
      startTimer()
    })

    watch(
      () => timeLeft.value,
      (val: number) => {
        if (val === 0) {
          clearInterval(timerInterval.value)
          showWaitMessage.value = false

          window.top.postMessage('timerFinished', '*')
        }
      }
    )

    return {
      showWaitMessage,
      timePassed,
      timeLimit,
    }
  },
})
