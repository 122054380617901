/**
 * gameStart is mistyped, and comes in as a string
 * @param s
 * @return Date associated with whatever is passed in
 */
import dayjs from 'dayjs'
export const NULL_DATE = dayjs('0001-01-01T00:00:00').toDate()

/**
 * Convert a string, Date, epoch or nothing into a date.
 * @param s - param
 * @return {Date}
 */
export function toDate(s: string | Date | number | null): Date {
  return dayjs(s ?? NULL_DATE).toDate()
}

/**
 * Asks the question if ti si a null date.
 * @param d - date
 * @return {boolean} if null date
 */
export function isNullDate(d: Date) {
  return !d || d.valueOf() <= NULL_DATE.valueOf()
}

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
