import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import store from '@/store'

export enum VolunteerSectionTypes {
  ALL = 'all',
  COACHES = 'coaches',
  COMMISSIONERS = 'commissioners',
  REFEREES = 'referees',
  CHEERDIRECTOR = 'cheerdirector',
  OTHER = 'others',
}

export enum VolunteerRoleIDs {
  COACHES = 3,
  REFEREES = 1,
}

const approvableRoles = [RolesEnum.COACH, RolesEnum.REFEREE]
/***
 * Program from a category, empty string if not.
 * @param category
 * @constructor
 */
export function CategoryNameToProgramName(category: string) {
  const parts = category.split('-')
  if (parts.length == 2) {
    return parts[1].toLocaleUpperCase()
  }

  return '' //if not hyphen then category is default (first program)
}

/***
 * category could contain a program, remove it.
 * @param category
 * @constructor
 */
export function CategoryNameWithoutProgram(category: string) {
  const parts = category.split('-')
  if (parts.length == 2) {
    return parts[0].toLowerCase()
  }
  return category
}

/***
 * removes a program if exists on the category and replaces it with a new program name.
 */
export function CategoryWithProgram(category: string, program: string) {
  return `${CategoryNameWithoutProgram(category)}-${program.toLowerCase()}`
}
/***
 * Makes a category into a series of roles.
 * @param category
 * @constructor
 */
export function CategoryNameToCategory(category: string) {
  switch (CategoryNameWithoutProgram(category)) {
    case VolunteerSectionTypes.ALL:
      return VolunteerSectionTypes.ALL
    case VolunteerSectionTypes.COACHES:
      return VolunteerSectionTypes.COACHES
    case VolunteerSectionTypes.COMMISSIONERS:
      return VolunteerSectionTypes.COMMISSIONERS
    case VolunteerSectionTypes.OTHER:
      return VolunteerSectionTypes.OTHER
    case VolunteerSectionTypes.REFEREES:
      return VolunteerSectionTypes.REFEREES
    case VolunteerSectionTypes.CHEERDIRECTOR:
      return VolunteerSectionTypes.CHEERDIRECTOR
    default:
      return VolunteerSectionTypes.ALL
  }
}

export function categoryToRoles(category: VolunteerSectionTypes): number[] {
  switch (CategoryNameWithoutProgram(category)) {
    case VolunteerSectionTypes.COACHES:
      return [3]
    case VolunteerSectionTypes.ALL:
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14] //excluded 10 - HalftimeCommissioner - no longer used
    case VolunteerSectionTypes.COMMISSIONERS:
      return [7, 8, 9, 11, 12, 14] //excluded 10 - HalftimeCommissioner - no longer used
    case VolunteerSectionTypes.REFEREES:
      return [1]
    case VolunteerSectionTypes.CHEERDIRECTOR:
      return [13]
    case VolunteerSectionTypes.OTHER:
      return [2, 4, 5, 6]
  }
  return []
}

export function categoryToTitle(category: string) {
  const cName = CategoryNameToCategory(category)
  return cName == VolunteerSectionTypes.REFEREES && store.getters.leagueAbstraction.refLabel
    ? store.getters.leagueAbstraction.refLabel + 's'
    : cName
}

export function RoleHasCoachPreferences(role: number | RolesEnum) {
  return role == RolesEnum.COACH
}

export function RoleRequiresApproval(role: number | RolesEnum) {
  return approvableRoles.indexOf(role) >= 0
}

export function RoleIDToRoleName(r: RolesEnum) {
  switch (r) {
    case RolesEnum.COACH:
      return 'Coach'
    case RolesEnum.COACHCOMMISSIONER:
      return 'CoachCommissioner'
    case RolesEnum.CHEERDIRECTOR:
      return 'CheerDirector'
    case RolesEnum.PRAYERCOMMISSIONER:
      return 'PrayerCommissioner'
    case RolesEnum.REFEREE:
      return 'Referee'
    case RolesEnum.REFEREECOMMISSIONER:
      return 'RefereeCommissioner'
    case RolesEnum.HALFTIMECOMMISSIONER:
      return 'HalftimeCommissioner'
    case RolesEnum.HOSTCOMMISSIONER:
      return 'HostCommissioner'
    case RolesEnum.ADCOMMISSIONER:
      return 'AdCommissioner'
    case RolesEnum.GAMEDAYCOMMISSIONER:
      return 'GamedayCommissioner'
  }
  return null
  //Cheer -- not sure what this is, doesn't map from email
}
