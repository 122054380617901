





























































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { DivisionGameSchedulerFacility } from '@/GeneratedTypes/DivisionGameSchedulerFacility'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { Container, Draggable, DropResult } from 'vue-smooth-dnd'

import { cloneDeep, isEqual } from 'lodash'
import FacilitySummaryBox from '@/views/Programs/Scheduling/Games/auto/FacilitySummaryBox.vue'
import { ScheduleBlocks } from '@/models/elements/Scheduler'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'
import { AvailabilityToScheduleBlocks } from '@/lib/support/models/ScheduleBlocks/AvailabilityToScheduleBlock'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import { DivisionGameSchedulerFacilityToScheduleBlocks } from '@/lib/support/models/ScheduleBlocks/DivisionGameSchedulerFacilityToScheduleBlocks'
import { dayIDToName } from '@/views/Programs/Scheduling/Games/ext/augmented_facility_availability'
import { millisecondsToTimeString } from '@/lib/support/utilities/time/time'
import DivisionDayPreferencesModal from '@/views/Programs/Scheduling/Games/auto/DivisionDayPreferencsModal.vue'
import { getEmptyLeagueFacility } from '@/lib/support/models/LeagueFacility/data'

class FacilityDropperException extends RuntimeException {
  name = 'Facility Dropper Runtime Error'
}

const PRIORITY_OFFSET = 1 //start priorities at this number
/***
 * The output of this is the DivisionGameSchedulerFacility[]
 */

@Component({
  components: { FacilitySummaryBox, DivisionDayPreferencesModal, Container, Draggable },
})
export default class FacilityDropper extends Vue {
  //** event we are displaying the dropper for
  event = FacilityEventEnum.GAMES
  @Prop({ type: Array, default: () => [] })
  facilityList!: LeagueFacility[]

  @Prop({ type: Array, default: () => [] })
  value!: DivisionGameSchedulerFacility[]

  @Prop({ type: String, default: 'Division' })
  private divisionName!: string

  facilityWarning = ''

  internalFacilityOptions: DivisionGameSchedulerFacility[] = []

  //** preferences box showing to edit time for a facility
  areDivisionPreferencesShowing = false
  //** actual availability to constrain the edit box.
  facilityAvailabilityUnderEdit: ScheduleBlocks[] = []
  //** availability edited by the user.
  facilityAssignedAvailabilityUnderEdit: ScheduleBlocks[] = []
  //** actual availability to constrain the edit box.
  facilityIDUnderEdit = 0
  /**
   * schedule blocks to plug into the facility availability
   * and notify parent
   * @param s
   */
  updateFacilityAvailability(s: ScheduleBlocks[]) {
    const f = cloneDeep(this.internalFacilityOptions)
    this.internalFacilityOptions = [
      ...f.filter((x) => x.facilityID != this.facilityIDUnderEdit),
      ...s.map((x) => ({
        facilityID: this.facilityIDUnderEdit,
        typeDayID: dayIDToName(x.day),
        startTime: millisecondsToTimeString(x.timeStart),
        endTime: millisecondsToTimeString(x.timeEnd),
        typeRoundNumberModeID: 'PRIMARY',
        priority: this.faclityPriority,
      })),
    ]
  }

  get faclityPriority() {
    const a = cloneDeep(this.internalFacilityOptions)
    return a.find((f) => f.facilityID == this.facilityIDUnderEdit)?.priority ?? 0
  }
  /**
   * Someone clicked edit facility availability, we'll build up an edit structure then
   * trigger the modal for edit, dismiss goes into the dustbin, and an update event
   * will communicate to the parent to build game scheduler preferences
   * @param id - facility id to build edit list off of.
   */
  editFacilityAvailability(id: number) {
    const f = this.facilityForID(id)
    if (f) {
      this.facilityIDUnderEdit = id
      this.facilityAvailabilityUnderEdit = this.superScheduleForFacility(f)
      this.facilityAssignedAvailabilityUnderEdit = this.subScheduleForFacility(f)
      this.areDivisionPreferencesShowing = true
    } else {
      throw new FacilityDropperException("Cannot choose a facility that isn't in the division: " + id)
    }
  }

  facilityForID(id: number) {
    return this.facilityList.find((x) => x.facilityID == id)
  }

  superScheduleForFacility(f: LeagueFacility): ScheduleBlocks[] {
    return AvailabilityToScheduleBlocks(f?.availability ?? [], this.event)
  }

  subScheduleForFacility(f: LeagueFacility): ScheduleBlocks[] {
    return DivisionGameSchedulerFacilityToScheduleBlocks(
      this.internalFacilityOptions.filter((x) => x.facilityID == f.facilityID) ?? []
    )
  }

  /**
   * Drag Handlers for inactive facilities
   */
  // get inactiveDragHandlers() {
  //   return [DivTarget('#active-target', (this.moveToActive as unknown) as DropHandler)]
  // }

  /**
   * sets order based on priority, shows facilities that have options.
   */
  get activeFacilityList(): LeagueFacility[] {
    //build array
    const copyFacilities: DivisionGameSchedulerFacility[] = cloneDeep(this.internalFacilityOptions)
    const facilityIDsInOrder = Array.from(
      // from unique elements
      new Set(
        // that are IDs sorted by priority
        copyFacilities.sort((x, y) => (x.priority > y.priority ? 1 : -1))?.map((x) => x.facilityID)
      )
    )
    // return ids mapped back to facilities
    return facilityIDsInOrder
      .map((x) => this.facilityList.find((y) => y.facilityID == x) ?? getEmptyLeagueFacility())
      .filter((y) => y.facilityID)
  }

  /**
   * trolls facility list looking for anything that doesn't have options set and hours for the
   * type of event we are dealing with.
   */
  get inactiveFacilityList(): LeagueFacility[] {
    return this.facilityList.filter(
      (y) =>
        y.facilityID &&
        this.internalFacilityOptions.findIndex((x) => x.facilityID == y.facilityID) < 0 &&
        (y.availability || []).findIndex((z) => z.typeFacilityEventID == this.event) >= 0
    )
  }

  /**
   * Takes a facility and copies the available hours to facility options
   */
  createFacilityOptions(f: LeagueFacility, priority: number): DivisionGameSchedulerFacility[] {
    return (
      (f.availability ?? [])
        .filter((x) => x.typeFacilityEventID == this.event)
        ?.map((x) => {
          return {
            facilityID: f.facilityID,
            typeDayID: x.typeDayID,
            startTime: '08:00:00', //default start to 8AM
            endTime: x.endTime,
            typeRoundNumberModeID: 'PRIMARY',
            priority: priority + PRIORITY_OFFSET,
          }
        }) ?? []
    )
  }

  /**
   * Move an active facility to the inactive list
   */
  moveToInactive(dropResult: DropResult) {
    const f = (dropResult.payload as unknown) as LeagueFacility
    if (dropResult && dropResult.addedIndex == null) return //not inactivating a facility, so everything below can be ignored
    if (this.activeFacilityList.length == 1) {
      this.toggleFacilityWarning(true)
      return false
    }
    this.internalFacilityOptions = cloneDeep(
      this.internalFacilityOptions.filter((x) => x.facilityID != f.facilityID)
    )
    //reorder priorities
    let id = 0
    let priority = 0
    this.internalFacilityOptions.forEach((f) => {
      if (id != f.facilityID) {
        priority++
        id = f.facilityID
      }
      f.priority = priority
    })
  }

  unassignFacility(facility: LeagueFacility) {
    if (this.activeFacilityList.length == 1) {
      this.toggleFacilityWarning(true)
      return false
    }
    this.internalFacilityOptions = cloneDeep(
      this.internalFacilityOptions.filter((x) => x.facilityID != facility.facilityID)
    )
    //reorder priorities
    let id = 0
    let priority = 0
    this.internalFacilityOptions.forEach((f) => {
      if (id != f.facilityID) {
        priority++
        id = f.facilityID
      }
      f.priority = priority
    })
  }

  toggleFacilityWarning(show: boolean) {
    this.facilityWarning = show
      ? 'At least one facility is required. Please add a new one before removing this one.'
      : ''
  }
  /**
   * Moves an inactive facility to active, sets priority.
   * @param f
   */
  moveToActive(dropResult: DropResult) {
    const f = (cloneDeep(dropResult.payload) as unknown) as LeagueFacility
    if (dropResult && dropResult.addedIndex == null) return //not activating facility, so everything below can be ignored
    const facilityCopy: DivisionGameSchedulerFacility[] = cloneDeep(this.internalFacilityOptions)
    const maxPriority = facilityCopy.length ? Math.max(...facilityCopy.map((f) => f.priority)) : 0
    this.internalFacilityOptions = cloneDeep([
      ...this.createFacilityOptions(f, maxPriority),
      ...facilityCopy.filter((x) => x.facilityID != f.facilityID),
    ])
    this.toggleFacilityWarning(false)
  }

  assignFacility(facility: LeagueFacility) {
    const facilityCopy: DivisionGameSchedulerFacility[] = cloneDeep(this.internalFacilityOptions)
    const maxPriority = facilityCopy.length ? Math.max(...facilityCopy.map((f) => f.priority)) : 0
    this.internalFacilityOptions = cloneDeep([
      ...this.createFacilityOptions(facility, maxPriority),
      ...facilityCopy.filter((x) => x.facilityID != facility.facilityID),
    ])
    this.toggleFacilityWarning(false)
  }

  moveFacilityUp(id: number) {
    const dgsf = this.internalFacilityOptions.find((x) => x.facilityID == id)
    if (dgsf && dgsf.priority > 1) {
      this.internalFacilityOptions.forEach((x) => {
        if (x.priority == dgsf.priority - 1) {
          x.priority++
        }
      })
      dgsf.priority--
    }
  }

  moveFacilityDown(id: number) {
    const dgsf = this.internalFacilityOptions.find((x) => x.facilityID == id)
    if (dgsf && dgsf.priority < this.internalFacilityOptions.length) {
      this.internalFacilityOptions.forEach((x) => {
        if (x.priority == dgsf.priority + 1) {
          x.priority--
        }
      })
      dgsf.priority++
    }
  }

  isTopPriority(id: number) {
    const dgsf = this.internalFacilityOptions.find((x) => x.facilityID == id)
    return dgsf && dgsf.priority == 1
  }

  isLowestPriority(id: number) {
    const dgsf = this.internalFacilityOptions.find((x) => x.facilityID == id)
    return dgsf && dgsf.priority == this.internalFacilityOptions.length
  }

  setActivePayload(idx: number) {
    return this.activeFacilityList[idx]
  }

  setInactivePayload(idx: number) {
    return this.inactiveFacilityList[idx]
  }

  @Watch('value', { immediate: true, deep: true })
  updateValue() {
    if (!isEqual(this.value, this.internalFacilityOptions)) {
      this.internalFacilityOptions = cloneDeep(this.value)
    }
  }

  @Watch('internalFacilityOptions', { immediate: true, deep: true })
  updatedInternalFacilityOptions() {
    if (!isEqual(this.value, this.internalFacilityOptions)) {
      this.input()
    }
  }

  @Emit()
  input() {
    return this.internalFacilityOptions
  }
}
