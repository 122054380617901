







































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import uuid from 'uuid'
import InputLabel from '@/elements/InputLabel.vue'
import DateInput from '@/elements/DateInput.vue'
import TimePicker from '@/elements/TimePicker2.vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'DateTimeInput',
  components: {
    InputLabel,
    DateInput,
    TimePicker,
  },
  props: {
    required: { type: Boolean, default: false, required: false },
    strictMode: { type: Boolean, default: false, required: false },
    label: { type: String, default: '', required: false },
    placeholder: { type: String, default: '', required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    minDate: {
      type: (null as unknown) as PropType<Date | null>,
      default: () => {
        const d = new Date()
        d.setDate(d.getDate() - 90)
        return d
      },
      required: false,
    },
    maxDate: {
      type: (null as unknown) as PropType<Date | null>,
      default: () => {
        const d = new Date()
        d.setDate(d.getDate() + 730)
        return d
      },
      required: false,
    },
    value: { required: true, type: (null as unknown) as PropType<Date | string | null> },
  },
  setup(props, ctx) {
    const internalDateValue = ref<Date | null>(null)
    const internalTimeValue = ref<string | null>(null)
    const inputId = ref('')

    const internalValue = computed(() => {
      const isValidTime = internalTimeValue.value != null && internalTimeValue.value != ''
      const isValidDate = internalDateValue.value != null

      if (isValidTime && isValidDate) {
        const timeParts = internalTimeValue.value?.split(':')
        return dayjs(internalDateValue.value ?? '')
          .hour(timeParts ? Number(timeParts[0]) : 0)
          .minute(timeParts ? Number(timeParts[1]) : 0)
          .toDate()
      } else if (!isValidTime && isValidDate) {
        return internalDateValue.value
      } else {
        return ''
      }
    })

    function setInternalValue() {
      if (!props.value || props.value === '0001-01-01T00:00:00') {
        internalDateValue.value = null
        internalTimeValue.value = ''
      } else if (typeof props.value === 'string') {
        internalDateValue.value = dayjs(props.value).toDate()
        internalTimeValue.value = dayjs(props.value).format('HH:mm:ss')
      } else {
        internalDateValue.value = dayjs(props.value).toDate()
        internalTimeValue.value = dayjs(props.value).format('HH:mm:ss')
      }
    }

    //private created()
    setInternalValue()
    inputId.value = 'datepicker-' + uuid.v4()

    function handleChange() {
      ctx.emit('input', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        if (props.value != internalValue.value) {
          setInternalValue()
        }
      }
    )

    watch(
      () => internalDateValue.value,
      (newVal, oldVal) => {
        if (oldVal == null && internalTimeValue.value == '') {
          internalTimeValue.value = dayjs().hour(0).minute(0).second(0).format('HH:ss:mm')
        }
      }
    )

    return {
      inputId,
      internalDateValue,
      handleChange,
      internalTimeValue,
    }
  },
})
