






























import { MultiSelectValue } from '@/lib/support/components/MultiSelect/MultiSelectValue'
import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import { mixin as clickaway } from 'vue-clickaway'
import { cloneDeep } from 'lodash'
export default defineComponent({
  name: 'DropDownMultiSelect',
  props: {
    label: { type: String, required: true },
    items: { type: Array as PropType<MultiSelectValue[]>, required: true, default: () => [] },
    value: { type: Array as PropType<MultiSelectValue[]>, required: true, default: () => [] },
  },
  mixins: [clickaway],
  setup(props, { emit }) {
    const selected = ref(buildDictionary(cloneDeep(props.items)))
    const show = ref(false)
    const theLabel = computed(() => {
      const s = getSelected()
      const count = s.length
      if (count == 0) return props.label
      const first = s[0].description
      if (count == 1) return first
      return `${first} (+${count - 1})`
    })
    function toggleDrop() {
      show.value = !show.value
    }
    function dropAway() {
      show.value = false
    }
    function getSelected() {
      const s = new Array<MultiSelectValue>()
      Object.keys(selected.value).forEach((key) => {
        if (selected.value[key].checked) {
          s.push(selected.value[key].value)
        }
      })
      return s
    }
    function select(value: MultiSelectValue, checked: boolean) {
      selected.value[value.id] = { value, checked } as any
      emit('input', cloneDeep(getSelected()))
    }
    function clear() {
      const d = buildDictionary(cloneDeep(props.items))
      selected.value = d
      emit('input', [])
    }
    function buildDictionary(items: MultiSelectValue[]) {
      const dict = {} as any
      for (let i = 0; i < items.length; i++) {
        const { id } = items[i]
        dict[id] = { value: items[i], checked: false } as any
      }
      return dict
    }
    function checked(item: MultiSelectValue) {
      return selected.value[item.id]?.checked ?? false
    }
    return { selected, toggleDrop, dropAway, show, select, checked, clear, theLabel }
  },
})
