

















































import { defineComponent, ref, watch, onMounted, PropType, computed } from '@vue/composition-api'
import { getProperGradeLabel } from '@/services/gradeService'

import programsClient from '@/clients/programsClient'
import { RegistrationCapacityByGradeGender } from '@/GeneratedTypes/RegistrationCapacityByGradeGender'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import {
  RegistrationCapacityCount,
  getEmptyRegistrationCapacityCount,
} from '@/models/Program/RegistrationCapacityCount'

import SelectInput from '@/elements/SelectInput.vue'
import IntegerInput from '@/elements/IntegerInput.vue'

export default defineComponent({
  name: 'ManageCapacityRule',
  components: { SelectInput, IntegerInput },
  props: {
    capacity: { type: Object as PropType<RegistrationCapacityByGradeGender>, require: true },
    upwardLeagueID: { type: String, require: true },
    typeProgramID: { type: String, require: true },
    isNewRow: { type: Boolean, require: true },
    isEditting: { type: Boolean, require: true },
    isByAge: { type: Boolean, require: false, default: false },
    availableStartGrades: { type: Array as PropType<UpwardGradeTypeID[]>, require: true },
    availableStopGrades: { type: Array as PropType<UpwardGradeTypeID[]>, require: true },
    availableGenders: { type: Array as PropType<string[]>, require: true },
  },
  setup(props, ctx) {
    const regCapCounts = ref<RegistrationCapacityCount>(getEmptyRegistrationCapacityCount())

    async function setRegistrationCount() {
      if (
        props.capacity?.startTypeGradeID &&
        props.capacity?.stopTypeGradeID &&
        props.capacity?.gender &&
        props.upwardLeagueID != ''
      ) {
        regCapCounts.value =
          (await programsClient.getRegistrationCount(props.upwardLeagueID!, {
            typeProgramID: props.typeProgramID!,
            gender: props.capacity?.gender,
            startGradeID: props.capacity?.startTypeGradeID,
            endGradeID: props.capacity?.stopTypeGradeID,
          })) ?? getEmptyRegistrationCapacityCount()
      } else {
        regCapCounts.value = getEmptyRegistrationCapacityCount()
      }
    }

    const startDisplayValue = computed(() => {
      return getProperGradeLabel(props.capacity?.startTypeGradeID ?? '', props.isByAge, '')
    })

    const endDisplayValue = computed(() => {
      return getProperGradeLabel(props.capacity?.stopTypeGradeID ?? '', props.isByAge, '')
    })

    function saveRow() {
      ctx.emit('save-row')
    }

    function editRow() {
      ctx.emit('edit-row', props.capacity?.id)
    }

    function deleteRow() {
      if (props.isNewRow) {
        ctx.emit('delete-row', null)
      } else {
        ctx.emit('delete-row', props.capacity?.id)
      }
    }

    watch(
      () => props.capacity?.startTypeGradeID,
      async () => {
        await setRegistrationCount()
      }
    )

    watch(
      () => props.capacity?.stopTypeGradeID,
      async () => {
        await setRegistrationCount()
      }
    )

    watch(
      () => props.capacity?.gender,
      async () => {
        await setRegistrationCount()
      }
    )

    onMounted(async () => {
      await setRegistrationCount()
    })

    return {
      regCapCounts,
      saveRow,
      editRow,
      deleteRow,
      startDisplayValue,
      endDisplayValue,
    }
  },
})
