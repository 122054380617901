






import { defineComponent, computed, ref, watch, onMounted, PropType, nextTick } from '@vue/composition-api'
import StartupOrderProductCard from './StartupOrderProductCard.vue'
import { getEmptyStartupOrderProductInfoList } from '@/lib/support/models/StartupOrderProductInfo'
import { sortOffering } from '@/services/offeringService'
import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'
import { SavedProductOfferingConfigStartupOrderProduct } from '@/GeneratedTypes/SavedProductOfferingConfigStartupOrderProduct'
import { cloneDeep } from 'lodash'
import store from '@/store'
import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

export default defineComponent({
  name: 'StartupOrderPicker',
  components: {
    StartupOrderProductCard,
  },
  props: {
    products: {
      type: Array as PropType<StartupOrderProductInfo[]>,
      default: getEmptyStartupOrderProductInfoList,
      required: false,
    },
    values: {
      type: Array as PropType<SavedProductOfferingConfigStartupOrderProduct[]>,
      default: getEmptyStartupOrderProductInfoList,
      required: false,
    },
  },
  setup(props) {
    const chosen = ref<SavedProductOfferingConfigStartupOrderProduct[]>([])
    const logic = productFlowLogic()

    onMounted(() => {
      nextTick(() => watch(chosen.value, chosenChanged, { immediate: true }))
    })

    const sortedProducts = computed(() => sortOffering<StartupOrderProductInfo>(localClone.value))

    const localClone = computed(() => cloneDeep(props.products))

    function buildProductArray(): SavedProductOfferingConfigStartupOrderProduct[] {
      const products = chosen.value.filter((x: SavedProductOfferingConfigStartupOrderProduct) => x !== null)
      return products ?? ([] as SavedProductOfferingConfigStartupOrderProduct[])
    }

    function chosenChanged() {
      store.commit.productOfferings.setDetailsStartupOrders({ details: buildProductArray() })
      logic.markAsDirty()
    }

    return {
      sortedProducts,
      chosen,
    }
  },
})
