import { OrderProduct } from '@/models/Order/OrderProduct'
import { OrderProductSize } from '@/models/Order/OrderProductSize'
let SortOrder = 7000 //just arbitrarily high

export function getEmptyOrderProduct(): OrderProduct {
  return {
    cmsProductDescription: '',
    cmsProductImageUrls: [],
    cmsProductName: 'temp name',
    cmsSizingGuideUrl: null,
    cmsUnitDescription: '',
    upwardProductID: '',
    typeProgramID: '',
    cost: 0,
    orderMinimumQuantity: 0,
    orderMaximumQuantity: 0,
    orderFreeQuantity: 0,
    orderSelectionRequired: true,
    individualID: 0,
    id: 0,
    typeProductGroupID: '',
    registrationLabel: '',
    extendedDescription: '',
    isActive: true,
    sortOrder: ++SortOrder,
    productColorSizes: [getEmptyOrderProductSize()],
    orderQuantity: 0,
    jerseyNumber: '',
    requiresJerseyNumber: false,
    hasSNumbers: false,
    duplicateProductMatchingKey: null,
  }
}

function getEmptyOrderProductSize(): OrderProductSize {
  return {
    typeSizeID: '',
    sizeDescription: '',
    costOffset: 0,
    typeColorID: '',
    colorDescription: '',
    exchangeCost: 0,
    orderQuantity: 0,
    orderFreeQuantity: 0,
    previouslyOrdered: 0,
  }
}

export function getEmptyOrderProductList(): OrderProduct[] {
  return [getEmptyOrderProduct()]
}
