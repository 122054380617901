import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'

export const getEmptyLeagueFacility = () => {
  const leagueFacility: LeagueFacility = {
    leagueID: 0,
    facilityID: 0,
    accountNumber: '',
    isShared: false,
    facilityName: '',
    active: false,
    street1: '',
    street2: '',
    subdivision1: '',
    subdivision2: '',
    postalCode: '',
    typeCountryID: '',
    childFacilities: [],
    availability: [],
  }

  return leagueFacility
}
