


















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { getEmptyXStartupOrderExt } from '@/lib/support/models/GeneratedTypes/xOrders/xStartupOrderExt'
import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import { OrderProductSelection } from '@/store/orders'
import {
  ProductSelectorLogic,
  useNoProductSelector,
} from '@/views/Programs/Orders/compositions/useProductSelector'
import { getFilteredProductList, OrderProductLine } from '@/models/Order/OrderProduct'
import currency from '@/filters/currency'
import CartLine from '@/components/Orders/CartLine.vue'

export default defineComponent({
  name: 'Cart',
  components: {
    CartLine,
  },
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    XStartupOrderExt: {
      type: Object as PropType<xStartupOrderExt>,
      default: getEmptyXStartupOrderExt,
      required: false,
    },
    title: { type: String, default: '', required: false },
    productsSelected: { type: Array as PropType<OrderProductSelection[]>, default: () => [], required: true },
    selectorMethod: {
      type: Function as PropType<ProductSelectorLogic>,
      default: useNoProductSelector,
      required: true,
    },
  },
  setup(props, ctx) {
    const products = computed(() =>
      getFilteredProductList(props.XStartupOrderExt.products, props.selectorMethod().isSelected)
    )

    function qty(p: OrderProductLine) {
      return p.productColorSize.orderQuantity
    }

    function qtyFree(p: OrderProductLine) {
      return p.productColorSize.orderFreeQuantity
    }

    function costOffset(p: OrderProductLine) {
      return (
        (p.productColorSize.orderQuantity - p.productColorSize.orderFreeQuantity) *
        p.productColorSize.costOffset
      )
    }

    function remove(p: OrderProductLine) {
      if (!props.readOnly) {
        ctx.emit('remove', p)
      }
    }

    return { products, currency, qty, qtyFree, costOffset, remove }
  },
})
