










































import { defineComponent, ref, computed } from '@vue/composition-api'
import PasswordInput from '@/elements/PasswordInput.vue'

import store from '@/store'

export default defineComponent({
  name: 'managePassword',
  props: {},
  components: { PasswordInput },
  setup(props, ctx) {
    const updatePassword = store.dispatch.authorization.passwordChange

    const currentPassword = ref('')
    const newPassword = ref('')
    const confirmNewPassword = ref('')
    const showConfirmPasswordError = ref(false)
    const loading = ref(false)

    async function submitPasswordChange() {
      showConfirmPasswordError.value = false
      loading.value = true
      try {
        const success = await updatePassword({
          password: currentPassword.value,
          newPassword: newPassword.value,
        })
        if (success) {
          newPassword.value = ''
          confirmNewPassword.value = ''
          currentPassword.value = ''
        }
      } finally {
        ctx.root.$router.push('/')
      }
    }

    const saveIsDisabled = computed(() => {
      return !(
        !loading.value &&
        currentPassword.value &&
        newPassword.value &&
        confirmNewPassword.value &&
        newPassword.value === confirmNewPassword.value
      )
    })

    function onNewPasswordsChange() {
      showConfirmPasswordError.value =
        !!newPassword.value && !!confirmNewPassword.value && newPassword.value !== confirmNewPassword.value
    }

    return {
      currentPassword,
      newPassword,
      confirmNewPassword,
      showConfirmPasswordError,
      loading,
      saveIsDisabled,
      submitPasswordChange,
      onNewPasswordsChange,
    }
  },
})
