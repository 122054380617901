import { DataTableHeader } from 'vuetify/types'
export const headers: DataTableHeader[] = [
  { text: 'Event', value: 'eventLabel' },
  { text: 'Date', value: 'prettyDate' },
  { text: 'Time', value: 'prettyTime' },
  { text: 'Location', value: 'facilityName' },
  { text: 'Program', value: 'prettyProgramName' },
  { text: 'Division', value: 'divisionName' },
  { text: 'Team', value: 'teamName' },
  { text: '', value: 'actions' },
]

export const showMoreHeaders: DataTableHeader[] = [
  { text: 'Event', value: 'eventLabel' },
  { text: 'Time', value: 'prettyTime' },
  { text: 'Location', value: 'facilityName' },
  { text: 'Program', value: 'prettyProgramName' },
  { text: 'Division', value: 'divisionName' },
  { text: 'Team', value: 'teamName' },
  { text: '', value: 'actions' },
]
