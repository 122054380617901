














import { defineComponent, ref, watch } from '@vue/composition-api'

import DateInput from '@/elements/DateInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import dayjs from 'dayjs'
interface EFTReportParametersType {
  BeginDate: Date
  EndDate: Date
}
export default defineComponent({
  components: { SelectInput, DateInput },
  props: {
    leagueID: { type: String, required: true, default: '' },
  },

  setup(props, ctx) {
    const beginDate = ref(new Date())
    const endDate = ref(new Date())
    const days = ref(0)
    const items = [
      { value: 0, label: '-Choose a time period-' },
      { value: 30, label: 'Last 30 Days' },
      { value: 60, label: 'Last 60 Days' },
      { value: 90, label: 'Last 90 Days' },
      { value: 365, label: 'Last 12 Months' },
    ]
    watch(
      () => days.value,
      () => {
        beginDate.value = dayjs(new Date()).subtract(days.value, 'd').toDate()
        updateParameters()
      }
    )

    function updateParameters() {
      ctx.emit('update-parameters', {
        BeginDate: beginDate.value,
        EndDate: endDate.value,
      } as EFTReportParametersType)
    }

    watch(
      () => endDate,
      () => {
        updateParameters()
      }
    )

    return { beginDate, endDate, days, items }
  },
})
