import restService from '@/services/restService'
import { Clinic } from '@/GeneratedTypes/Clinic'

const baseUrl = 'Clinics/'

const create = async (clinic: Clinic) => {
  return await restService.post<Clinic>(`${baseUrl}`, clinic)
}

const update = async (id: string, clinic: Clinic) => {
  return await restService.put<Clinic>(`${baseUrl}${id}`, clinic)
}

const retrieve = async (id: string) => {
  return await restService.get<Clinic>(`${baseUrl}${id}`)
}

const newTemplate = async (upwardLeagueTypeID: string) => {
  return await restService.get<Clinic>(`${baseUrl}new/${upwardLeagueTypeID}`)
}

export default {
  create,
  retrieve,
  update,
  newTemplate,
}
