









import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'NotificationsBtn',
  props: {
    count: { type: Number, required: false, default: 0 },
  },
  components: {},
  setup(props, { emit }) {
    function click() {
      emit('click')
    }
    return { click }
  },
})
