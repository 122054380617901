






import { defineComponent, reactive, provide } from '@vue/composition-api'

export default defineComponent({
  name: 'Accordion',
  setup() {
    const accordionData = reactive({
      currentGuid: '',
    })

    provide('accordionData', accordionData)

    return {
      accordionData,
    }
  },
})
