















































import { defineComponent, onMounted } from '@vue/composition-api'
import { useClinicSetup } from '@/composables/ClinicSetup'

import DateInput from '@/elements/DateInput.vue'
import Loading from '@/elements/Loading.vue'
import MemoInput from '@/elements/MemoInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import TextInput from '@/elements/TextInput.vue'

import Alert from '@/components/Alert.vue'
import FullBody from '@/components/FullBody.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import NumberInput from '@/elements/NumberInput.vue'

export default defineComponent({
  name: 'Information',
  components: {
    Alert,
    DateInput,
    FullBody,
    Loading,
    MemoInput,
    SelectInput,
    TextInput,
    SaveContinueButton,
    NumberInput,
  },
  setup(props, ctx) {
    const { clinic, loading, nextRoute, saveAndContinue, beforeRouteLeave } = useClinicSetup(ctx)

    onMounted(() => {
      if (clinic.value.upwardLeagueID) {
        nextRoute.value = `/programs/clinic/${clinic.value.upwardLeagueID}/setup/leadership`
      } else {
        nextRoute.value = '/programs/clinic/setup/leadership'
      }
    })

    return {
      saveAndContinue,
      clinic,
      loading,
      beforeRouteLeave,
    }
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeave(to, from, next)
  },
})
