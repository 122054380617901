

























































































































import DigitalSetupCompleteConfirmation from '@/views/Leagues/vues/DigitalSetupCompleteConfirmation.vue'

import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import * as leaguesStore from '@/store/leagues'

import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'

import DayOfWeekMultiPicker from '@/elements/DayOfWeekMultiPicker.vue'
import AdditionalParticipantInformation from '@/components/AdditionalParticipantInformation.vue'
import TimezoneSelect from '@/components/TimezoneSelect.vue'
import LeagueDevotionTrackSelection from '@/components/LeagueDevotionTrackSelection.vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import ContactForm from '@/components/ContactForm.vue'
import ContactPicker from '@/components/ContactPicker.vue'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { ContactToContactInfo, ContactInfoToContact, GetEmptyContactInfo } from '@/services/contactService'
import { getterNames, namespace } from '@/store/authorization'
import { Getter, Action } from 'vuex-class'
import DateInput from '@/elements/DateInput.vue'
import { League } from '@/GeneratedTypes/League'
import { loadGradeDataIntoProgram } from '@/services/gradeService'
import { getGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'

@Component({
  components: {
    TextInput,
    SelectInput,
    MemoInput,
    YesNoInput,
    DayOfWeekMultiPicker,
    SwitchSlot,
    Loading,
    AdditionalParticipantInformation,
    TimezoneSelect,
    ContactForm,
    ContactPicker,
    LeagueDevotionTrackSelection,
    DateInput,
    DigitalSetupCompleteConfirmation,
    SaveContinueButton,
    HtmlEditor,
  },
})
export default class DigitalInformation extends Mixins(LeagueSetupMixin) {
  @Action(leaguesStore.actionNames.create, { namespace: leaguesStore.namespace })
  private readonly create!: ({ item }: { item: League }) => Promise<League | null>

  @Getter(getterNames.accountNumbers, { namespace })
  private accountNumbers!: string[] | null

  private modalIsVisible = false
  private newId = ''
  private sportContact: ContactInfo = GetEmptyContactInfo()
  private sportSwitch = 'picker'
  private grades: string[] = []

  private get canChangeName(): boolean {
    return !(
      this.league &&
      this.league.programs &&
      this.league.programs.length > 0 &&
      this.league!.leagueDirectorPartnerID
    )
  }

  private onSportContactUpdated(contact: ContactInfo) {
    if (this.league) {
      this.league.partnerContact = ContactInfoToContact(contact)
      this.league.leagueDirectorPartnerID = contact.id
      this.sportContact = contact
    }
  }

  private clearContact() {
    this.onSportContactUpdated(GetEmptyContactInfo())
  }
  // eslint-disable-next-line require-await
  private async beforeSaveMethod() {
    // Clear the productOfferingConfig & registrationInfo per 2668
    this.league.productOfferingConfig = null
    this.league.registrationInfo = null
    this.league.otherRegistrationVendorName = 'N/A'

    // isMailIn per 2668
    this.league.isMailIn = true
  }

  private async afterSaveNewMethod() {
    const savedLeague = await this.create({ item: this.league })
    if (savedLeague) {
      this.newId = savedLeague.upwardLeagueID!
    }

    this.modalIsVisible = true
  }

  mounted() {
    this.afterSaveNew = this.afterSaveNewMethod
    this.beforeSave = this.beforeSaveMethod
    this.grades = getGrades(false)
  }

  private onModalStateChange() {
    this.$router.push(`/programs/digitalleague/${this.newId}/setup/information`)
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    if (this.league) {
      this.league.typeSystemsAccessID = 'NONE'
      this.league.isOPRLeague = false
      this.league.programs?.forEach((p) => {
        loadGradeDataIntoProgram(this.league, this.grades, p.typeProgramID, 'F')
        loadGradeDataIntoProgram(this.league, this.grades, p.typeProgramID, 'M')
      })
      this.sportContact = ContactToContactInfo(
        this.league.partnerContact!,
        this.league.leagueDirectorPartnerID
      )
    }
  }
}
