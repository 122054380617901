export default (value: number | string | null | undefined, digits = 2) => {
  if (typeof value !== 'number') {
    return value
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })

  return formatter.format(value)
}
