

















































































































































































































































import { defineComponent, computed, ref, watch, onMounted, nextTick } from '@vue/composition-api'

import teamsClient from '@/clients/teamsClient'
import ordersClient from '@/clients/ordersClient'

import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'

import numberToFixed from '@/filters/numberToFixed'

import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'

import Accordion from '@/components/Accordion.vue'
import AccordionItem from '@/components/AccordionItem.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import DivisionGradeGenderList from '@/components/DivisionGradeGenderList.vue'

import AutoCreateTeamsModal from '@/views/Programs/Divisions/vues/AutoCreateTeamsModal.vue'
import DivisionHeader from '@/views/Programs/Divisions/vues/DivisionHeader.vue'

import { getUpwardLeagueId } from '@/views/Programs/Divisions/ext/divisionUtils'
import { HeaderValue } from '@/views/Programs/Divisions/ext/headerValue'

import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { getColorDescription } from '@/lib/common/upwardColorTypes'

import store from '@/store'

export default defineComponent({
  name: 'Setup',
  components: {
    Accordion,
    AccordionItem,
    AutoCreateTeamsModal,
    ConfirmationModal,
    DivisionGradeGenderList,
    DivisionHeader,
    Loading,
    TextInput,
    SelectInput,
  },
  filters: {
    numberToFixed,
  },
  setup(props, ctx) {
    const teamsLoading = ref(false)
    const showDeleteConfirmation = ref(false)
    const showAutoGenerationModal = ref(false)
    const showCreateTeamModal = ref(false)
    const currentTeam = ref<DivisionTeamInfo | null>(null)

    const teams = ref<DivisionTeamInfo[]>([])
    const newTeam = ref<DivisionTeam | null>(null)
    const teamsInEditMode = ref<number[]>([])
    const headerValues = ref<HeaderValue | null>(null)

    const productItems = ref<ParticipantOrderProductInfo[]>([])
    const productWithTeamColors = ref<ParticipantOrderProductInfo | null>(null)

    const upwardLeagueId = computed(() => ctx.root.$route.params.id)

    const typeProgramId = computed(() => headerValues.value?.selectedTypeProgramID)

    const isLeague = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    )

    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)

    const hasDivisions = computed((): boolean => {
      if (headerValues.value) {
        if (headerValues.value.selectedDivision) return true
      }
      return false
    })

    //TNT - temporary hack until we get to Vue3 and can do refs
    const focusTeamNameInput = ref(false)

    onMounted(async () => {
      productItems.value = (await ordersClient.getLeagueOrderItems(upwardLeagueId.value)) ?? []
      onSelectedTypeProgramIDChanged()
      await store.dispatch.colorTypes.fetchAll(false) //get these cached up
    })

    function onSelectedTypeProgramIDChanged() {
      // await this.setDivisionCount()
      productWithTeamColors.value =
        productItems.value.find(
          (x) =>
            x.typeProgramID == typeProgramId.value &&
            x.isColorBasedOnTeamAssignment &&
            store.getters.leagueAbstraction.currentItem.productOfferingConfig.details?.some(
              (d) => d.productID == x.id || d.productID == x.parentProductID
            )
        ) ?? null
    }

    watch(
      () => headerValues.value?.selectedTypeProgramID,
      () => {
        onSelectedTypeProgramIDChanged()
      }
    )

    const hasTeams = computed(() => teams.value && teams.value.length > 0)

    const isCheer = computed(
      () => headerValues.value?.selectedDivision.typeProgramID === headerValues.value?.cheerProgramTypeID
    )

    const programHasTeamColors = computed(() => productWithTeamColors.value != null)

    const colorDDLBLabel = computed(() => {
      return productWithTeamColors.value == null
        ? ''
        : productWithTeamColors.value.collectColorInfoWithTeam
        ? 'Jersey Style'
        : 'Color'
    })

    function teamColorDesc(team: DivisionTeamInfo) {
      return team && team.typeColorID && team.typeColorID != 'N/A'
        ? getColorDescription(team.typeColorID)
        : 'Not Selected'
    }

    const availableColors = computed(() => {
      return productWithTeamColors.value
        ? store.state.colorTypes.items
            .filter(
              (x) =>
                x.upwardTypeID != 'N/A' &&
                (productWithTeamColors.value?.isColorBasedOnTeamAssignment || x.isTeamColor) &&
                productWithTeamColors.value?.productColorSizes?.some((c) => c.typeColorID! == x.upwardTypeID!)
            )
            .sort((a, b) => (a.description! == b.description! ? 0 : a.description! > b.description! ? 1 : -1))
        : []
    })

    function doShowAutoGenerationModal() {
      showAutoGenerationModal.value = true
    }

    function editTeam(team: DivisionTeam) {
      teamsInEditMode.value.push(team.teamID)
    }

    function isTeamInEditMode(team: DivisionTeam): boolean {
      return teamsInEditMode.value.some((x) => x === team.teamID)
    }

    const teamSquadText = computed(() => {
      if (
        headerValues.value &&
        headerValues.value.cheerProgramTypeID == headerValues.value.selectedTypeProgramID
      ) {
        return 'Squad'
      }
      return 'Team'
    })

    const teamSquadTextLower = computed(() => teamSquadText.value.toLowerCase())

    async function saveTeam(team: DivisionTeamInfo) {
      const divisionTeam = await getDivisionTeam(team)
      divisionTeam.teamName = team.teamName ?? ''
      divisionTeam.typeColorID = team.typeColorID
      const saved = await teamsClient.saveTeam({
        team: divisionTeam,
        leagueId: getUpwardLeagueId(ctx.root),
      })

      if (saved) {
        const index = teamsInEditMode.value.findIndex((x) => x === team.teamID)
        teamsInEditMode.value.splice(index, 1)
      }
    }

    async function getDivisionTeam(team: DivisionTeamInfo) {
      // Must convert to DivisionTeam from DivisionTeamInfo in order to save.
      // That conversion would involve multiple arrays of objects which each need to be
      // converted. This would go as much as 3 levels deep, so just fetch the correct
      // data model from db instead of making a maintence headache.
      return await teamsClient.getTeam(
        team.upwardLeagueID ?? '',
        team.typeProgramID ?? '',
        team.divisionID,
        team.teamID
      )
    }

    async function doShowCreateTeamModal() {
      newTeam.value = await teamsClient.getTeamTemplate()
      if (headerValues.value && headerValues.value.selectedDivision) {
        newTeam.value.leagueID = headerValues.value.selectedDivision.leagueID
        newTeam.value.typeProgramID = headerValues.value.selectedDivision.typeProgramID!
        newTeam.value.divisionID = headerValues.value.selectedDivision.divisionID
      }
      showCreateTeamModal.value = true

      nextTick(() => {
        /*
        const teamNameInput = ctx.refs.teamNameInput as typeof TextInput
        const teamNameTextbox = teamNameInput.$refs.inputField as HTMLInputElement
        teamNameTextbox.focus()
        */
        focusTeamNameInput.value = true
      })
    }

    function hideCreateTeamModal() {
      showCreateTeamModal.value = false
      focusTeamNameInput.value = false
    }

    async function createTeam() {
      if (newTeam.value) {
        const teamCreated = await teamsClient.createTeam({ team: newTeam.value })

        if (teamCreated && headerValues.value && headerValues.value.selectedDivision) {
          await loadTeams(headerValues.value.selectedDivision)
          showCreateTeamModal.value = false
          focusTeamNameInput.value = false
        }
      }
    }

    function onDeleteClick(team: DivisionTeamInfo) {
      currentTeam.value = team
      showDeleteConfirmation.value = true
    }

    async function onDeleteConfirmationResponse(confirmed: boolean) {
      showDeleteConfirmation.value = false

      if (confirmed && headerValues.value && headerValues.value.selectedDivision && currentTeam.value) {
        await teamsClient.deleteTeam({
          leagueId: getUpwardLeagueId(ctx.root),
          divisionId: headerValues.value.selectedDivision.divisionID,
          typeProgramId: headerValues.value.selectedDivision.typeProgramID!,
          teamId: currentTeam.value.teamID,
        })

        await loadTeams(headerValues.value.selectedDivision)
      }
    }

    async function loadTeams(division: LeagueDivisionInfo | null) {
      teamsLoading.value = true
      if (division) {
        const teamList = await teamsClient.retrieveTeamList({
          leagueId: getUpwardLeagueId(ctx.root),
          divisionId: division.divisionID,
          typeProgramId: division.typeProgramID!,
        })

        teams.value = [...teamList]
      }
      teamsLoading.value = false
    }

    async function onDivisionChanged(hdrVals: HeaderValue | null) {
      if (hdrVals) {
        headerValues.value = hdrVals
        await loadTeams(hdrVals.selectedDivision)
      }
    }

    watch(
      () => showAutoGenerationModal.value,
      async () => {
        if (!showAutoGenerationModal.value && headerValues.value?.selectedDivision) {
          await loadTeams(headerValues.value.selectedDivision)
        }
      }
    )

    function calculateTeamAverage(team: DivisionTeamInfo) {
      if (!team.playerCount) {
        return 0
      }

      var totalScore = team.players?.reduce((runningTotal, x) => runningTotal + x.athleticScore, 0) ?? 0
      return totalScore / team.playerCount
    }

    return {
      teamSquadText,
      teamSquadTextLower,
      onDivisionChanged,
      doShowCreateTeamModal,
      doShowAutoGenerationModal,
      showAutoGenerationModal,
      teamsLoading,
      hasDivisions,
      hasTeams,
      showCreateTeamModal,
      programHasTeamColors,
      colorDDLBLabel,
      isCheer,
      isLeague,
      newTeam,
      createTeam,
      availableColors,
      hideCreateTeamModal,
      teams,
      isTeamInEditMode,
      saveTeam,
      teamColorDesc,
      calculateTeamAverage,
      editTeam,
      onDeleteClick,
      headerValues,
      isByAge,
      currentTeam,
      showDeleteConfirmation,
      onDeleteConfirmationResponse,
      focusTeamNameInput,
    }
  },
})
