





































import { defineComponent, ref, watch, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { directive as onClickaway } from 'vue-clickaway'
import uuid from 'uuid'

export default defineComponent({
  name: 'Combobox',
  props: {
    list: { type: Array as PropType<string[]>, required: true },
    value: { type: String, required: true },
    textPropertyName: { type: String, required: false, default: '' },
    valuePropertyName: { type: String, required: false, default: '' },
    enabled: { type: Boolean, required: false, default: true },
  },
  directives: { onClickaway },
  components: {},
  setup(props, ctx) {
    const isOpen = ref(false)
    const selectedIndex = ref<number | null>(null)
    const selectedText = ref('')
    const id = ref(uuid.v4())

    const listItems = computed(() => {
      if (!props.list.length) return []
      return cloneDeep(props.list)
    })

    function open(open: boolean) {
      isOpen.value = open
    }

    function select(index: number) {
      if (!listItems.value) return
      selectedIndex.value = index
      selectedText.value = listItems.value[index]
      open(false)
    }

    function close() {
      open(false)
    }

    function input() {
      const i = listItems.value.findIndex((l) => l.toLowerCase().startsWith(selectedText.value.toLowerCase()))
      if (i > -1) {
        selectedIndex.value = i
        scrollTo(i)
      }
    }

    function setOption(index: number) {
      selectedIndex.value = index
      selectedText.value = listItems.value[index]
      scrollTo(index)
    }

    function scrollTo(optionIndex: number) {
      // get elements
      const listbox = document.getElementById(id.value)
      const option = document.getElementById(`option-${optionIndex}`)

      if (!listbox || !option) {
        //If scrollTo is called before the elements are available, call
        //it again in 1ms until elements are there.
        setTimeout(() => scrollTo(optionIndex), 100)
        return
      }

      // get listbox coordinates
      const parentOffsetHeight = listbox?.offsetHeight ?? 0
      const parentScrollTop = listbox?.scrollTop ?? 0

      // get option coordinates
      const offsetHeight = option?.offsetHeight ?? 0
      const offsetTop = option?.offsetTop ?? 0

      // relative location calculations
      const isAbove = offsetTop < parentScrollTop
      const isBelow = offsetTop + offsetHeight > parentScrollTop + parentOffsetHeight

      // scroll
      if (isAbove) {
        listbox.scrollTo(0, offsetTop)
      } else if (isBelow) {
        listbox.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight)
      }
    }

    function inputKeydown(e: any) {
      const { key } = e
      const max = props.list.length - 1
      const action = getActionFromKey(key, isOpen.value)
      switch (action) {
        case MenuActions.Next:
        case MenuActions.Last:
        case MenuActions.First:
        case MenuActions.Previous:
          e.preventDefault()
          return setOption(getUpdatedIndex(selectedIndex.value ?? 0, max, action))
        case MenuActions.CloseSelect:
          e.preventDefault()
          select(selectedIndex.value ?? 0)
          return open(false)
        case MenuActions.Close:
          return open(false)
        case MenuActions.Open:
          return open(true)
      }
    }

    // Relevant key
    const Keys = {
      Backspace: 'Backspace',
      Clear: 'Clear',
      Down: 'ArrowDown',
      End: 'End',
      Enter: 'Enter',
      Escape: 'Escape',
      Home: 'Home',
      Left: 'ArrowLeft',
      PageDown: 'PageDown',
      PageUp: 'PageUp',
      Right: 'ArrowRight',
      Space: ' ',
      Tab: 'Tab',
      Up: 'ArrowUp',
    }

    // Actions the listbox with execute
    const MenuActions = {
      Close: 0,
      CloseSelect: 1,
      First: 2,
      Last: 3,
      Next: 4,
      Open: 5,
      Previous: 6,
      Select: 7,
      Space: 8,
      Type: 9,
    }

    function getActionFromKey(key: string, menuOpen: boolean) {
      if (!menuOpen && key === Keys.Down) {
        return MenuActions.Open
      }

      if (key === Keys.Down) {
        return MenuActions.Next
      } else if (key === Keys.Up) {
        return MenuActions.Previous
      } else if (key === Keys.Home) {
        return MenuActions.First
      } else if (key === Keys.End) {
        return MenuActions.Last
      } else if (key === Keys.Escape) {
        return MenuActions.Close
      } else if (key === Keys.Enter) {
        return MenuActions.CloseSelect
      } else if (key === Keys.Tab) {
        return MenuActions.Close
      } else if (key === Keys.Backspace || key === Keys.Clear) {
        return MenuActions.Type
      } else if (key.length === 1) {
        return MenuActions.Open
      }
    }

    function getUpdatedIndex(current: number, max: number, action: number) {
      switch (action) {
        case MenuActions.First:
          return 0
        case MenuActions.Last:
          return max
        case MenuActions.Previous:
          return Math.max(0, current - 1)
        case MenuActions.Next:
          return Math.min(max, current + 1)
        default:
          return current
      }
    }

    watch(
      () => props.value,
      () => {
        selectedText.value = props.value
      },
      { immediate: true }
    )
    watch(
      () => selectedText.value,
      () => {
        ctx.emit('input', selectedText.value)
        input()
      },
      { immediate: true }
    )

    return {
      id,
      isOpen,
      open,
      close,
      select,
      selectedIndex,
      selectedText,
      input,
      inputKeydown,
      listItems,
    }
  },
})
