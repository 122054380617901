import { RouteConfig } from 'vue-router'
import Reports from '@/views/Programs/Reports/Index.vue'
import ExcelReports from '@/views/Programs/Reports/ExcelReports.vue'
import WebviewReports from '@/views/Programs/Reports/WebviewReports.vue'
import DynamicReports from '@/views/Programs/Reports/DynamicReports.vue'

export default [
  {
    path: 'reports',
    component: Reports,
    children: [
      {
        path: 'excel/:reportName',
        component: ExcelReports,
      },
      {
        path: 'web/:reportName',
        component: WebviewReports,
      },
      {
        path: 'dynamic/:reportName',
        component: DynamicReports,
      },
    ],
  },
] as RouteConfig[]
