




import { defineComponent, onMounted } from '@vue/composition-api'
import FullBody from '@/components/FullBody.vue'
import store from '@/store'

export default defineComponent({
  name: 'CreateOrEdit',
  components: {
    FullBody,
  },
  setup() {
    onMounted(async () => {
      await store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up
      await store.dispatch.programTypes.fetchAll(false) //get these cached up
    })

    return {}
  },
})
