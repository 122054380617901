














































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import NumberInput from '@/elements/NumberInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import DateTimeInput from '@/elements/DateTimeInput.vue'
import { LeagueRegistrationBypassLink } from '@/GeneratedTypes/LeagueRegistrationBypassLink'
import { SaveIcon } from '@/elements/Icons'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { ValidationProvider } from 'vee-validate'
import dayjs from 'dayjs'

@Component({
  components: {
    Modal,
    TextInput,
    NumberInput,
    CheckboxInput,
    DateTimeInput,
    SaveIcon,
    VeeValidateForm,
    ValidationProvider,
  },
})
export default class EditBypassLink extends Vue {
  private internalBypassLink: LeagueRegistrationBypassLink = this.defaultBypassLink()

  private internalModalIsVisible = false
  private modalTitle = 'Edit Private Registration Link'
  private bypassLinkURL = ''

  @Prop({ required: false })
  private readonly bypassLink!: LeagueRegistrationBypassLink | null

  @Prop({ required: false, default: false })
  private value = false

  @Prop({ required: true })
  private readonly maxDate!: Date

  @Prop({ required: true })
  private readonly upw!: string | null

  private showignoreCapacityRulesHelp = false

  @Watch('internalModalIsVisible')
  private onInternalModalIsVisibleChanged() {
    this.$emit('input', this.internalModalIsVisible)
    this.$emit('change', this.internalModalIsVisible)
  }

  @Watch('value')
  private onModalVisibilityChanged() {
    if (this.internalModalIsVisible !== this.value) {
      this.internalModalIsVisible = this.value
    }
  }

  private mounted() {
    this.internalModalIsVisible = this.value
    if (this.bypassLink) {
      this.internalBypassLink = this.bypassLink
    }
    this.setBypassLinkURL()
  }

  private get upwURL() {
    return this.upw == null || this.upw == '' ? '{Your LeagueID}' : this.upw
  }

  private get startDateRule() {
    return 'required|date_before:' + dayjs(this.maxDate).format('MM/DD/YYYY')
  }

  private get endDateRule() {
    return 'required|date_after_target:@startDate|date_before:' + dayjs(this.maxDate).format('MM/DD/YYYY')
  }

  @Watch('internalBypassLink.urlSuffix')
  private setBypassLinkURL() {
    this.bypassLinkURL =
      this.internalBypassLink.urlSuffix && this.internalBypassLink.urlSuffix != ''
        ? `${process.env.VUE_APP_PARTICIPANT_REGISTRATION_DOMAIN}${this.upwURL}/c/${this.internalBypassLink.urlSuffix}`
        : ''
  }

  private done() {
    this.$emit('onSave', this.internalBypassLink)
    this.toggleModal()
  }

  private cancel() {
    this.toggleModal()
  }

  private toggleModal() {
    this.internalModalIsVisible = !this.internalModalIsVisible
  }

  private showModal() {
    this.internalBypassLink = this.defaultBypassLink()
    this.internalModalIsVisible = true
  }

  @Watch('bypassLink')
  private updateBypassLink() {
    this.internalBypassLink = this.bypassLink || this.defaultBypassLink()
    this.setBypassLinkURL()
  }

  private defaultBypassLink(): LeagueRegistrationBypassLink {
    return {
      bypassLinkID: 0,
      urlSuffix: '',
      startDate: null,
      endDate: null,
      maxUsages: 0,
      numberOfUsages: 0,
      ignoreCapacityRules: false,
      active: true,
    } as LeagueRegistrationBypassLink
  }
}
