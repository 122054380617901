








































import { defineComponent, PropType } from '@vue/composition-api'
import { Route } from 'vue-router'
import { RouteResolver } from '@/lib/support/components/VerticalTabs/RouteResolver'
import { VerticalTabValue } from '@/lib/support/components/VerticalTabs/VerticalTabValue'
import MenuTab from '@/components/MenuTab.vue'

export default defineComponent({
  name: 'VerticalTabs',
  components: {
    MenuTab,
  },
  props: {
    tabs: { type: Array as PropType<VerticalTabValue[]>, required: true },
    IsOnRoute: {
      type: Function as PropType<RouteResolver>,
      required: false,
      default: ($route: Route, to: string) => $route?.path?.indexOf(to ?? '*never*') !== -1,
    },
  },
  setup() {
    /**
     * Working on the element, class keeping state since menu doesn't have
     * unique ids
     * @param tabElement
     */
    function toggleHidden(tabElement: HTMLElement) {
      if (tabElement.classList.contains('closed')) {
        tabElement.classList.remove('closed')
        tabElement.classList.add('open')
      } else {
        tabElement.classList.remove('open')
        tabElement.classList.add('closed')
      }
    }

    return {
      toggleHidden,
    }
  },
})
