













import { computed, defineComponent, PropType } from '@vue/composition-api'
import { OrderInfo } from '@/GeneratedTypes/Order/ListInfo/OrderInfo'
import OrderShipment from '@/components/OrderHistory/OrderShipment.vue'

export default defineComponent({
  name: 'OrderShippers',
  props: {
    order: { type: Object as PropType<OrderInfo>, required: true },
  },
  components: { OrderShipment },
  setup(props) {
    const shipmentCount = computed(() => (props.order.shipmentList ? props.order.shipmentList.length : 0))

    const shipmentMessage = computed(() => {
      const count = shipmentCount.value
      if (count > 0) {
        return `<div>Your order will arrive from ${count} distribution center${count > 1 ? 's' : ''}.</div>`
      } else {
        return `<div>Your order is being processed.</div><div> Please check back for tracking numbers.</div>`
      }
    })

    return { shipmentMessage }
  },
})
