
























































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import { GenderIdToText } from '@/lib/support/models/MiscMappings/Gender'
import { isByAge } from '@/lib/support/models/LeagueListItem/data'
import { GradeRangeArrayToText } from '@/lib/support/models/GeneratedTypes/ListInfo/LeagueDivisionGradeInfo'

interface GridData {
  gradeLabel: string
  genderLabel: string
  divisionName: string
  countLabel: number
  divisionObject: LeagueDivision
}

export default defineComponent({
  name: 'DivisionGrid',
  components: {
    SimpleGrid,
    TypeProgramTab,
    ConfirmationModal,
  },
  props: {
    programs: { type: Object as PropType<LeagueListItem>, required: true },
    divisions: { type: Array as PropType<LeagueDivision[]>, default: [], required: false },
    selectedProgram: { type: String, required: true },
  },
  setup(props, ctx) {
    const showDeleteConfirmation = ref(false)
    const selectedDivision = ref<LeagueDivision>(getEmptyDivision())
    const internalSelectedProgram = ref(props.selectedProgram)

    const programIsByAge = computed(() => isByAge(props.programs))

    const columns = computed((): GridColumn[] => {
      return [
        { field: 'divisionName', headerText: 'Division Name', width: 300 } as GridColumn,
        { field: 'genderLabel', headerText: 'Gender' } as GridColumn,
        { field: 'gradeLabel', headerText: programIsByAge.value ? 'Ages' : 'Grades' } as GridColumn,
        { field: 'countLabel', headerText: 'Participant Count', textAlign: 'Right' } as GridColumn,
      ]
    })

    const gridData = computed(() => {
      const grid: GridData[] = []
      if (props.divisions.length > 0) {
        props.divisions.forEach((division: LeagueDivision) => {
          grid.push({
            divisionName: division.divisionName,
            genderLabel: GenderIdToText(division.gender),
            gradeLabel: GradeRangeArrayToText(division.grades, programIsByAge.value),
            countLabel: division.participantCount,
            divisionObject: division,
          })
        })
      }
      return grid
    })

    function editClicked(div: GridData) {
      selectedDivision.value = div.divisionObject
      ctx.emit('onEdit', div.divisionObject)
    }

    function deleteClicked(div: GridData) {
      selectedDivision.value = div.divisionObject
      showDeleteConfirmation.value = true
    }

    function onDeleteConfirmationResponse(confirmed: boolean) {
      showDeleteConfirmation.value = false
      if (confirmed) {
        ctx.emit('onDeleteConfirmed', selectedDivision.value)
      }
    }

    function addNew() {
      ctx.emit('onAddNew')
    }

    watch(
      () => internalSelectedProgram.value,
      (newValue: string) => {
        ctx.emit('onProgramChanged', newValue)
      }
    )

    watch(
      () => props.selectedProgram,
      () => {
        internalSelectedProgram.value = props.selectedProgram
      }
    )

    function getEmptyDivision() {
      return {
        leagueID: 0,
        typeProgramID: '',
        divisionID: 0,
        accountNumber: '',
        divisionName: '',
        gender: '',
        gameCount: 0,
        practiceLength: 0,
        gameLength: 0,
        typeGameFormatID: '',
        typePracticePlanID: '',
        typeLeagueScoringID: null,
        showStandingsTeamPage: false,
        lastPracticeDate: null,
        overrideNumberOfSegments: 0,
        isInCheerSchedulerMapping: false,
        grades: [],
        exclusionDates: [],
        participantCount: 0,
      }
    }

    return {
      addNew,
      internalSelectedProgram,
      gridData,
      columns,
      editClicked,
      deleteClicked,
      showDeleteConfirmation,
      selectedDivision,
      onDeleteConfirmationResponse,
    }
  },
})
