import { render, staticRenderFns } from "./SimpleGrid.vue?vue&type=template&id=2b7d84da&scoped=true&"
import script from "./SimpleGrid.vue?vue&type=script&lang=ts&"
export * from "./SimpleGrid.vue?vue&type=script&lang=ts&"
import style0 from "./SimpleGrid.vue?vue&type=style&index=0&id=2b7d84da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7d84da",
  null
  
)

export default component.exports