import { DataTableHeader } from 'vuetify/types'
export interface DataTableSelection<T> {
  expand: (value: boolean) => void
  headers: DataTableHeader[]
  isExpanded: boolean
  isMobile: boolean
  isSelected: boolean
  item: T
  select: (value: boolean) => void
}

export interface DataTablePagination {
  page: number
  itemsPerPage: number
  pageStart: number
  pageStop: number
  pageCount: number
  itemsLength: number
}
export function getEmptyDataTablePagination() {
  return {
    page: 1,
    itemsPerPage: 15,
    pageStart: 1,
    pageStop: 1,
    pageCount: 1,
    itemsLength: 1,
  }
}
