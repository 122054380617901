import { RouteConfig } from 'vue-router'
import divisions from '@/router/divisions'
import scheduling from '@/router/scheduling'
import reports from '@/router/reports'
import Setup from '@/views/Camps/Setup.vue'
import Information from '@/views/Camps/Information.vue'
import CampIndex from '@/views/Camps/CampIndex.vue'
import KeyDates from '@/views/Camps/KeyDates.vue'
import Leadership from '@/views/Camps/Leadership.vue'
import Partnership from '@/views/Camps/Partnership.vue'
import Products from '@/views/Camps/Products.vue'
import Registration from '@/views/Camps/Registration.vue'
import resources from './resources'
import { idExpression } from '@/lib/support/router/idHelper'
import Archive from '@/views/Camps/Archive.vue'
import orders from '@/router/orders'
import participants from './participants'
import volunteers from './volunteers'
import communications from './communications'

export default [
  {
    path: `camp/${idExpression}`,
    component: CampIndex,
    children: [
      {
        path: '/',
        redirect: `/programs/camp/${idExpression}/setup`,
      },
      ...divisions('camp'),
      ...resources('Camp'),
      ...participants('camp'),
      ...orders('camp'),
      ...communications('camp'),
      ...volunteers('camp'),
      {
        path: 'setup',
        component: Setup,
        children: [
          {
            path: '/',
            redirect: `/programs/camp/${idExpression}/setup/information`,
          },
          {
            path: 'information',
            component: Information,
          },
          {
            path: 'dates',
            component: KeyDates,
          },
          {
            path: 'leadership',
            component: Leadership,
          },
          {
            path: 'partnership',
            component: Partnership,
          },
          {
            path: 'products',
            component: Products,
          },
          {
            path: 'registration',
            component: Registration,
          },
          {
            path: 'archive',
            component: Archive,
          },
        ],
      },
      ...scheduling,
      ...reports,
    ],
  },
] as RouteConfig[]
