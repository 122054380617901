















import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import EnlargeableProduct from './EnlargeableProduct.vue'
import SelectorCard from '@/components/SelectorCard.vue'
import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'
import { getEmptyProductOfferingInfo } from '@/lib/support/models/ProductOfferingInfo/ProductOfferingInfo'
import { sortOffering } from '@/services/offeringService'
import { TypeProductOfferingProductPackage } from '@/lib/support/models/UpwardTypes/TypeProductOfferingProductPackageID'
import store from '@/store'

export default defineComponent({
  name: 'ProductGroupPicker',
  components: {
    EnlargeableProduct,
    SelectorCard,
  },
  props: {
    productOfferings: {
      type: Array as PropType<ProductOfferingInfo[]>,
      default: getEmptyProductOfferingInfo,
      required: false,
    },
    selectable: { type: Boolean, required: true },
    firstProductOfferingId: { type: Number, required: true },
    package: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const valueLocal = ref<number | null>(null)

    onMounted(() => {
      store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up
      valueLocal.value = findId()
    })

    const defaultProductOfferingIndex = computed(() => {
      let index = props.productOfferings.findIndex((x: ProductOfferingInfo) => x.cost === 0)
      return index !== -1 ? index : 0
    })

    function findIndexByProductId(productOfferingId: number) {
      const index = props.productOfferings.findIndex((x: ProductOfferingInfo) => x.id === productOfferingId)
      return index !== -1 ? index : 0
    }

    function findId() {
      return props.firstProductOfferingId
        ? findIndexByProductId(props.firstProductOfferingId)
        : defaultProductOfferingIndex.value
    }

    const sortedProductOfferings = computed(() => sortOffering<ProductOfferingInfo>(props.productOfferings))

    watch(
      () => props.package,
      () => {
        if (!props.package) return

        let offeringIdx: number | null = null
        if (props.package == TypeProductOfferingProductPackage.CUSTOM) {
          offeringIdx = defaultProductOfferingIndex.value
        } else {
          const firstMatchingProduct = sortedProductOfferings.value.find((p) =>
            p.productPackages?.some((x) => x.typeProductOfferingProductPackageID == props.package)
          )
          if (firstMatchingProduct) {
            offeringIdx = findIndexByProductId(firstMatchingProduct?.id)
          }
        }

        if (offeringIdx == valueLocal.value) {
          //package change did not change offering
          if (valueLocal.value != null) {
            const sel = JSON.parse(JSON.stringify(sortedProductOfferings.value[valueLocal.value]))
            ctx.emit('input', { action: 'PackageChangeOnly', package: props.package, offering: sel })
          }
        } else {
          valueLocal.value = offeringIdx
        }
      }
    )

    watch(
      () => props.firstProductOfferingId,
      () => {
        valueLocal.value = findId()
      }
    )

    watch(
      () => valueLocal.value,
      () => {
        if (valueLocal.value !== null && sortedProductOfferings.value) {
          const sel = JSON.parse(JSON.stringify(sortedProductOfferings.value[valueLocal.value]))
          ctx.emit('input', { action: 'OfferingChanged', package: props.package, offering: sel })
        }
      }
    )

    return {
      valueLocal,
      sortedProductOfferings,
    }
  },
})
