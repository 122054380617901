









import { defineComponent, computed, onMounted, onUnmounted, ref } from '@vue/composition-api'

import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import divisionsClient from '@/clients/divisionsClient'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

import store from '@/store'
import { LeagueScoringTypes } from '@/lib/common/LeagueScoringTypes'

export default defineComponent({
  name: 'DivisionsAndTeams',
  components: {
    VerticalTabs,
    Loading,
    Modal,
  },
  setup(props, ctx) {
    const id = computed(() => ctx.root.$route.params.id)
    const routeId = computed(() => (id.value ? `${id.value}/` : ''))
    let divisions = ref<LeagueDivisionInfo[] | null>(null)

    onMounted(async () => {
      await store.dispatch.positionTypes.fetchAll(false) //get these cached up
      await store.dispatch.leagueScoringTypes.fetchAll(false)

      //get all divisions for this program
      const UpwId = ctx.root.$route.params.id
      divisions.value = await divisionsClient.retrieveAll(UpwId)
      //allDivisions.value = await divisionsClient.retrieveAll(UpwId)
    })

    const isLeague = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    )

    const hasScoringDiv = computed(
      () =>
        divisions.value?.some(
          (x) =>
            x.typeLeagueScoringID == LeagueScoringTypes.WIN_LOSS ||
            x.typeLeagueScoringID == LeagueScoringTypes.WIN_LOSS_SCORING
        ) ?? false
    )

    const baseRoute = computed(() => {
      if (ctx.root.$route.fullPath.indexOf('/camp/') > 0) {
        return `/programs/camp/${routeId.value}divisions_and_teams`
      }
      return `/programs/league/${routeId.value}divisions_and_teams`
    })

    const tabs = computed(() => [
      { text: 'Divisions', to: `${baseRoute.value}/divisions` },

      {
        text: 'Team Setup',
        tabs: [
          { text: 'Create Teams', to: `${baseRoute.value}/teams` },
          { text: 'Assign Coaches', to: `${baseRoute.value}/coaches` },
          ...(isLeague.value ? [{ text: 'Assign Practices', to: `${baseRoute.value}/practices` }] : []),
        ],
      },

      { text: 'Team Management', to: `${baseRoute.value}/team-management` },
      ...(isLeague.value && hasScoringDiv.value
        ? [{ text: 'Standings/Scores', to: `${baseRoute.value}/standings-scores` }]
        : []),
    ])

    onUnmounted(() => {
      store.commit.divisions.clearSelectedDivision(null)
    })

    return {
      tabs,
    }
  },
})
