export const days: {
  name: string
  abbr3: string
  abbr2: string
  selected: boolean
  enabled: boolean
  ordinal: 1 | 2 | 3 | 4 | 5 | 6 | 7 | null
  value: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null
}[] = [
  { name: 'Sunday', abbr3: 'Sun', abbr2: 'Su', selected: false, enabled: true, ordinal: 1, value: 0 },
  { name: 'Monday', abbr3: 'Mon', abbr2: 'Mo', selected: false, enabled: true, ordinal: 2, value: 1 },
  { name: 'Tuesday', abbr3: 'Tue', abbr2: 'Tu', selected: false, enabled: true, ordinal: 3, value: 2 },
  { name: 'Wednesday', abbr3: 'Wed', abbr2: 'We', selected: false, enabled: true, ordinal: 4, value: 3 },
  { name: 'Thursday', abbr3: 'Thu', abbr2: 'Th', selected: false, enabled: true, ordinal: 5, value: 4 },
  { name: 'Friday', abbr3: 'Fri', abbr2: 'Fr', selected: false, enabled: true, ordinal: 6, value: 5 },
  { name: 'Saturday', abbr3: 'Sat', abbr2: 'Sa', selected: false, enabled: true, ordinal: 7, value: 6 },
]
