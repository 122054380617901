import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardPartnerRoleTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerRoleTypeID'
import { ActionsImpl } from 'direct-vuex'

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardPartnerRoleTypeID>(
  upwardTypesClient.retrievePartnerRoles
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardPartnerRoleTypeID> = {
  items: [],
}

const getterTree: GetterTree<genericUpwardTypes.GenericTypeState<UpwardPartnerRoleTypeID>, RootState> = {
  ...generic.getterTree,
}

const mutations: MutationTree<genericUpwardTypes.GenericTypeState<UpwardPartnerRoleTypeID>> = {
  ...generic.mutations,
}

const actions: ActionTree<genericUpwardTypes.GenericTypeState<UpwardPartnerRoleTypeID>, RootState> &
  ActionsImpl = {
  ...generic.actions,
}

export const getterNames = {
  ...genericUpwardTypes.getterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

export const namespace = 'partnerRoleTypes'

export const partnerRoleTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
