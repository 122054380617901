














































































































import { defineComponent, computed } from '@vue/composition-api'
import { RouteMeta } from '@/router/'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import store from '@/store'

import NotificationsBtn from '@/components/Notifications/NotificationsBtn.vue'

export default defineComponent({
  name: 'TheHeader',
  components: {
    NotificationsBtn,
  },
  setup(props, ctx) {
    const isHomeRoute = computed(() => ctx.root.$route.matched.some((r) => (r.meta as RouteMeta).isHome))

    const { features } = useFeatureFlags()
    const fullName = computed(() => store.getters.authorization.fullName)
    const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const isImpersonated = computed(() => store.getters.authorization.isImpersonated)
    const impersonationAccountNumber = computed(() => store.getters.authorization.impersonationAccountNumber)
    const accountName = computed(() => store.getters.authorization.accountName)

    async function doLogout() {
      if (ctx.root.$route.fullPath !== '/') {
        await ctx.root.$router.push('/') //see #2787 - redirect to home on logout.
      }
      store.commit.authorization.clearCurrentCredentials()
    }

    const notificationsCount = computed(() => store.getters.alerts.unreadNotificationCount)

    const avatarText = computed(() => {
      if (fullName.value) {
        const firstInitial = fullName.value?.charAt(0)
        const lastSpace = fullName.value?.lastIndexOf(' ')
        let lastInitial = ''
        if (lastSpace && lastSpace > 0) {
          lastInitial = fullName.value?.charAt(lastSpace + 1)
        }
        return (firstInitial + lastInitial).toUpperCase()
      }
      return ''
    })

    async function endImpersonation() {
      await store.dispatch.authorization.unimpersonate()
      if (ctx.root.$route.name !== 'admin-dashboard') {
        await ctx.root.$router.push('/admin-dashboard')
      }
    }

    function notificationsClick() {
      ctx.emit('notificationsClick')
    }

    return {
      isHomeRoute,
      features,
      isImpersonated,
      notificationsCount,
      impersonationAccountNumber,
      accountName,
      firstAccountNumber,
      avatarText,
      fullName,
      notificationsClick,
      endImpersonation,
      doLogout,
    }
  },
})
