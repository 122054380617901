

















import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import { Genders } from '@/lib/support/models/MiscMappings/Gender'

export default defineComponent({
  name: 'GenderSelect',
  components: {
    SelectInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    value: { required: true, type: (null as unknown) as PropType<string | null> },
    blankItemText: { type: String, default: '', required: false },
    showBlankItem: { type: Boolean, default: true, required: false },
    adult: { type: Boolean, default: false, required: false },
    blankItemValue: { default: null, required: false },
    VeeValidateRules: { type: String, default: '', required: false },
    limitSelectionTo: { type: String as PropType<Genders>, default: Genders.both, required: false },
  },
  setup(props, ctx) {
    const internalValue = ref<string | null>(props.value)

    const female = computed(() => {
      return {
        name: props.adult ? 'Females' : 'Girls',
        abbreviation: Genders.female,
      }
    })

    const male = computed(() => {
      return {
        name: props.adult ? 'Males' : 'Boys',
        abbreviation: Genders.male,
      }
    })

    const both = computed(() => {
      return {
        name: 'Both',
        abbreviation: Genders.both,
      }
    })

    const options = computed(() => {
      if (props.limitSelectionTo == Genders.female) {
        return [female.value]
      }

      if (props.limitSelectionTo == Genders.male) {
        return [male.value]
      }

      if (props.limitSelectionTo == Genders.both) {
        return [female.value, male.value, both.value]
      }
    })

    watch(
      () => options.value,
      () => {
        internalValue.value = props.blankItemValue
        handleChange()
      }
    )

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    return {
      options,
      internalValue,
      handleChange,
    }
  },
})
