



























































import { defineComponent, ref, watch } from '@vue/composition-api'
import Vue from 'vue'

import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { TeamCoachInfo } from '@/GeneratedTypes/ListInfo/TeamCoachInfo.ts'

import teamsClient from '@/clients/teamsClient'

import Modal from '@/components/Modal.vue'
import SelectInput from '@/elements/SelectInput.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import CheckboxInput from '@/elements/CheckboxInput.vue'

class TeamCoach {
  teamNames: string
  coach: TeamCoachInfo

  get coachName(): string {
    return this.coach.formattedNameFirstLast ?? ''
  }

  get id(): string {
    return !this.coach ? '' : `${this.coach.teamID}:${this.coach.individualID}`
  }

  get displayValue(): string {
    return `${this.coachName} (${this.teamNames})`
  }

  constructor(teamName: string, coach: TeamCoachInfo) {
    this.teamNames = teamName
    this.coach = coach
  }
}

export default defineComponent({
  name: 'SwapCoachAssignment',
  components: {
    Modal,
    SelectInput,
    SwitchSlot,
    SimpleGrid,
    CheckboxInput,
  },
  props: {
    value: { type: Boolean, default: false, required: false },
    upwardLeagueId: { type: String, default: '', required: false },
    divisionId: { type: Number, default: 0, required: false },
    typeProgramId: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const modalIsVisible = ref(props.value)
    const modalTitle = ref('Swap Coach Assignment')
    const selectedCoach = ref('')
    const selectedTeam = ref('')
    const selectedSwapCoach = ref<TeamCoach>(new TeamCoach('', getEmptyTeamCoachInfo()))

    const coachList = ref<TeamCoach[]>([])
    const teamList = ref<DivisionTeamInfo[]>([])
    const filteredTeamList = ref<DivisionTeamInfo[]>([])

    const swapCoachList = ref<TeamCoach[]>([])
    const swapAction = ref('move')
    const saveButtonText = ref('Save')

    const headCoachTemplate = () => ({
      template: Vue.component('columnTemplate', {
        components: { /* eslint-disable vue/no-unused-components */ CheckboxInput },
        props: { data: {} },
        template: `<checkbox-input :enabled="false" :value="data.coach.headCoach" />`,
      }),
    })

    const teamGridColumns = ref<GridColumn[]>([
      { field: 'teamNames', headerText: 'Team Name' } as GridColumn,
      { field: 'coach.formattedNameFirstLast', headerText: 'Coach Name' } as GridColumn,
      { field: 'coach.headCoach', headerText: 'Head Coach', template: headCoachTemplate } as GridColumn,
    ])

    function toggleModal() {
      modalIsVisible.value = !modalIsVisible.value
    }

    function saveAndClose() {
      toggleModal()
      ctx.emit('saved')
    }

    watch(
      () => modalIsVisible.value,
      () => {
        if (modalIsVisible.value != props.value) {
          ctx.emit('input', modalIsVisible.value)
        }
      }
    )

    watch(
      () => props.value,
      async () => {
        if (modalIsVisible.value != props.value) {
          modalIsVisible.value = props.value
        }

        if (modalIsVisible.value) {
          saveButtonText.value = 'Save'
          await updateTeamList()
        }
      }
    )

    function handleRowSelected(args: TeamCoach) {
      selectedSwapCoach.value = args
    }

    async function updateTeamList() {
      if (props.upwardLeagueId && props.divisionId && props.typeProgramId) {
        const teams = await teamsClient.retrieveTeamList({
          leagueId: props.upwardLeagueId,
          divisionId: props.divisionId,
          typeProgramId: props.typeProgramId,
        })

        if (teams) {
          teamList.value = [...teams]

          coachList.value = getCoachesFromTeams()
          swapCoachList.value = getSwapCoachList()
        }
      }
    }

    function getCoachesFromTeams(): TeamCoach[] {
      const coaches: TeamCoach[] = []

      for (const team of teamList.value) {
        for (const coach of team.coaches!) {
          coaches.push(new TeamCoach(team.teamName!, coach))
        }
      }

      return coaches
    }

    function getSwapCoachList(): TeamCoach[] {
      if (selectedCoach.value) {
        const keys = selectedCoach.value.split(':')

        const selectedCoachTeams = coachList.value
          .filter((cl) => cl.coach.individualID === parseInt(keys[1]))
          .map((ct) => ct.coach.teamID)

        return coachList.value.filter(
          (cl) =>
            cl.coach.individualID !== parseInt(keys[1]) && selectedCoachTeams.indexOf(cl.coach.teamID) < 0
        )
      }
      return coachList.value
    }

    function getFilteredTeamList(): DivisionTeamInfo[] {
      if (selectedCoach.value) {
        const keys = selectedCoach.value.split(':')
        return teamList.value.filter((t) => t.teamID !== parseInt(keys[0]))
      }
      return teamList.value
    }

    watch(
      () => selectedCoach.value,
      () => {
        swapCoachList.value = getSwapCoachList()
        filteredTeamList.value = getFilteredTeamList()
      }
    )

    async function swapCoach() {
      saveButtonText.value = 'Saving . . .'
      if (!canSave()) {
        saveButtonText.value = 'Save'
        return
      }

      let success = false
      if (swapAction.value === 'move') {
        success = await moveCoachToTeam(selectedCoach.value, selectedTeam.value, 0)
      } else {
        success = await swapTeamCoaches(selectedCoach.value, selectedSwapCoach.value)
      }
      saveButtonText.value = 'Save'
      if (success) {
        saveAndClose()
      }
    }

    function canSave(): boolean {
      if (!selectedCoach.value || (swapAction.value !== 'move' && swapAction.value !== 'swap')) {
        return false
      }

      if (swapAction.value === 'move' && !selectedTeam.value) {
        return false
      }

      if (swapAction.value === 'swap' && !selectedSwapCoach.value) {
        return false
      }
      return true
    }

    async function moveCoachToTeam(
      selectedCoach: string,
      selectedTeam: string,
      coachCount: number
    ): Promise<boolean> {
      if (
        !selectedTeam ||
        !selectedCoach ||
        !props.upwardLeagueId ||
        !props.divisionId ||
        !props.typeProgramId
      ) {
        return false
      }

      const keys = selectedCoach.split(':')
      if (!keys || keys.length < 2) {
        return false
      }

      const previousTeamId = parseInt(keys[0])
      const coachId = parseInt(keys[1])
      // delete coach from previous team.
      let success = await teamsClient.removeCoachFromTeam(
        props.upwardLeagueId,
        props.typeProgramId,
        props.divisionId,
        previousTeamId,
        coachId
      )

      // add coach to new team.
      if (success) {
        const teamId = parseInt(selectedTeam)
        const team = teamList.value.find((t) => t.teamID === teamId)
        if (!team) {
          return false
        }
        const isHeadCoach = team.coachCount === coachCount

        success = await teamsClient.addCoachToTeam(
          props.upwardLeagueId,
          props.typeProgramId,
          props.divisionId,
          teamId,
          coachId,
          isHeadCoach
        )
      }

      return success
    }

    async function swapTeamCoaches(selectedCoach: string, selectedSwapCoach: TeamCoach): Promise<boolean> {
      if (
        !selectedSwapCoach ||
        !selectedCoach ||
        !props.upwardLeagueId ||
        !props.divisionId ||
        !props.typeProgramId
      ) {
        return false
      }

      const keys = selectedCoach.split(':')
      if (!keys || keys.length < 2) {
        return false
      }

      const teamId1 = keys[0]
      const teamId2 = selectedSwapCoach.coach.teamID.toString()

      let success = await moveCoachToTeam(selectedCoach, teamId2, 1)
      if (success) {
        success = await moveCoachToTeam(selectedSwapCoach.id, teamId1, 1)
      }
      return success
    }

    function getEmptyTeamCoachInfo(): TeamCoachInfo {
      return {
        leagueID: 0,
        typeProgramID: null,
        divisionID: 0,
        teamID: 0,
        divisionName: null,
        teamName: null,
        headCoach: false,
        individualID: 0,
        firstName: null,
        lastName: null,
        middleInitial: null,
        gender: null,
        birthDate: null,
        churchName: null,
        practiceNights: [],
        addresses: [],
        phoneNumbers: [],
        emailAddresses: [],
        thirdPartyClientIDs: [],
        formattedNameFirstLast: null,
        formattedNameLastFirst: null,
        formattedNamePrivate: null,
      } as TeamCoachInfo
    }

    return {
      modalIsVisible,
      modalTitle,
      selectedCoach,
      coachList,
      swapAction,
      selectedTeam,
      filteredTeamList,
      swapCoachList,
      teamGridColumns,
      handleRowSelected,
      toggleModal,
      saveButtonText,
      swapCoach,
    }
  },
})
