

























import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import CoachListElement from '@/views/Programs/Divisions/common/vue/CoachListElement.vue'
import { Container, ContainerProps, Draggable, DropResult } from 'vue-smooth-dnd'
import { cloneDeep } from 'lodash'
import { watch, defineComponent, PropType, ref } from '@vue/composition-api'

interface CoachOrderType {
  order: number
  coach: LeagueCoachProgramInfoFLAT
}

export default defineComponent({
  name: 'CoachList',
  components: {
    Container,
    Draggable,
    CoachListElement,
  },
  props: {
    coaches: { type: Array as PropType<LeagueCoachProgramInfoFLAT[]>, required: true },
    loading: { type: Boolean, required: false },
  },

  setup(props, ctx) {
    let coachOrder = ref<CoachOrderType[]>([])
    const selectedCoach = ref<LeagueCoachProgramInfoFLAT | null>(null)

    /**
     * Pulls the payload out of the container based on the index
     */
    function getPayload(index: number) {
      return coachOrder.value.find((x) => x.order == index)
    }

    /**
     * Lets us know if the payload will be accepted
     */
    function accepting(a: ContainerProps, info: Record<string, unknown>) {
      if (info && info.coach) {
        return true
      }
    }

    /**
     * action for when something is dropped on the field, in this case for reorder.
     */
    function onDrop(movedEvent: DropResult) {
      const f = getPayload(movedEvent.removedIndex)
      const g = getPayload(movedEvent.addedIndex)
      if (f && g) {
        const q = f.coach
        f.coach = g.coach
        g.coach = q
      }
    }

    watch(
      () => props.coaches,
      () => {
        let order = 0
        const copyList = cloneDeep(props.coaches)
          .sort((a, b) => (a.lastName ?? '').localeCompare(b.lastName ?? ''))
          .filter((c) => c.approved)

        coachOrder.value = copyList.map((x) => ({
          order: order++,
          coach: x,
        }))
      },
      { immediate: true }
    )

    const coachToTeamNumber = (coachId: number) =>
      props.coaches.find((c) => c.individualID === coachId)?.teamsCoachedCount ?? 0

    function selectCoach(coach: LeagueCoachProgramInfoFLAT | null) {
      if (coach) {
        selectedCoach.value = coach.individualID == selectedCoach.value?.individualID ? null : coach
      } else {
        selectedCoach.value = null
      }
    }

    function clearSelectedCoach() {
      selectedCoach.value = null
    }

    watch(
      () => selectedCoach.value,
      () => {
        ctx.emit('selectCoach', selectedCoach.value)
      }
    )

    return {
      coachOrder,
      onDrop,
      accepting,
      getPayload,
      coachToTeamNumber,
      selectedCoach,
      selectCoach,
      clearSelectedCoach,
    }
  },
})
