import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import { TeamCoachInfo } from '@/GeneratedTypes/ListInfo/TeamCoachInfo'

export function LeagueCoachProgramInfoFLATToTeamCoachInfo(i: LeagueCoachProgramInfoFLAT): TeamCoachInfo {
  return {
    divisionID: 0,
    formattedNameFirstLast: `${i.firstName} ${i.lastName}`,
    formattedNameLastFirst: `${i.lastName}, ${i.firstName}`,
    formattedNamePrivate: i.firstName,
    headCoach: false,
    practiceNights: [],
    teamID: 0,
    divisionName: null,
    teamName: null,
    typeProgramID: '',
    addresses: [],
    individualID: i.individualID,
    birthDate: i.birthDate,
    churchName: i.churchName,
    emailAddresses: [],
    firstName: i.firstName,
    gender: i.gender,
    lastName: i.lastName,
    leagueID: i.leagueID,
    middleInitial: i.middleInitial,
    phoneNumbers: [],
    thirdPartyClientIDs: [],
  }
}
