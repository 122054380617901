import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
// keep import on store before any module that includes vuex
//  https://github.com/paroi-tech/direct-vuex (...consequence of these circular dependencies...)
import store from '@/store/index'
import App from '@/App.vue'
import router from '@/router/index'
import PortalVue from 'portal-vue'
import CompositionApi from '@vue/composition-api'
Vue.use(CompositionApi)
import '@/registerServiceWorker'
import vuetify from './plugins/vuetify'

import LogRocket from 'logrocket'

if (process.env.VUE_APP_LOG_ROCKET_KEY) {
  LogRocket.init(process.env.VUE_APP_LOG_ROCKET_KEY, {
    network: {
      requestSanitizer: (request) => {
        // if the url contains 'authorization api calls'
        if (request.url.toLowerCase().indexOf('api/authorization') !== -1) {
          // ignore the request response pair
          return null
        }

        // otherwise log the request normally
        return request
      },
    },
  })
}

Vue.config.productionTip = false

Vue.use(PortalVue)
new Vue({
  router,
  store: store.original,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
