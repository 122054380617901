import { LeagueVolunteerRoleInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerRoleInfo'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'

export function leagueVolunteerRoleToLeagueVolunteerRoleInfo(
  a: LeagueVolunteerRole
): LeagueVolunteerRoleInfo {
  return {
    ...a,
    serverAuthorizedExpireDate: null,
    leagueID: 0,
    volunteerIndividualID: 0,
    udFs: [],
  }
}
