










































































import Vue from 'vue'

import Component from 'vue-class-component'
import Modal from '@/components/Modal.vue'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { DisplayablePromoCardMedia } from '@/models/PromoCards/PromoCardMedia'
import TextInput from '@/elements/TextInput.vue'
import { cloneDeep } from 'lodash'
import Loading from '@/elements/Loading.vue'
import Editor from '@/components/Editor.vue'
import PromoCardOverflowWarning from '@/views/Programs/Orders/vues/components/PromoCardOverflowWarning.vue'
import InputLabel from '@/elements/InputLabel.vue'
import FormatHelpButton from '@/views/Programs/Orders/vues/components/FormatHelpButton.vue'
import PreviewButton from '@/views/Programs/Orders/vues/components/PreviewButton.vue'
import ordersClient from '@/clients/ordersClient'
import store from '@/store'

@Component({
  components: {
    Loading,
    TextInput,
    Modal,
    Editor,
    PromoCardOverflowWarning,
    InputLabel,
    FormatHelpButton,
    PreviewButton,
  },
})
export default class PromoCardModal extends Vue {
  @Prop({ type: Object, default: null, required: false })
  private readonly value!: DisplayablePromoCardMedia

  private internalValue!: DisplayablePromoCardMedia

  @Prop({ type: Object, default: null, required: false })
  private readonly preview!: DisplayablePromoCardMedia | null
  internalPreview: DisplayablePromoCardMedia | null = null

  fetchingPdf = false

  /**
   * see comment at the top of Editor.vue for an explanation of why
   * 2 variables are used
   **/
  inputContent = ''
  outputContent = ''
  qrCodeURL = ''

  dirty = false
  private updateValue(v: string) {
    this.dirty = true
    this.outputContent = v
  }

  @Prop({ type: Boolean, default: false, required: false })
  private readonly showing!: boolean

  private modalIsVisible = false

  @Watch('showing', { immediate: true })
  showingUpdated() {
    this.modalIsVisible = this.showing
  }

  mounted() {
    this.internalPreview = null
  }

  @Watch('modalIsVisible')
  modalIsVisibleUpdated(n: boolean, o: boolean) {
    if (o && !n) {
      this.closed()
    }
  }

  async doPreview() {
    this.internalPreview = null
    this.fetchingPdf = true
    await this.saveAndFetchPdf()
    this.fetchingPdf = false
  }

  async saveAndFetchPdf() {
    const promoCard = cloneDeep(this.internalValue)
    promoCard.content = this.outputContent
    promoCard.qrCodeURL = this.qrCodeURL
    const pm = await ordersClient.savePromoCard(promoCard, true)

    if (pm) {
      this.internalPreview = pm
      this.dirty = false
    }
  }

  contentOverflowing = false
  overflowChanged(overflowing: boolean) {
    this.contentOverflowing = overflowing
  }

  @Emit()
  closed() {
    return this.modalIsVisible
  }

  get accountNumber() {
    return store.getters.authorization.firstAccountNumber
  }

  get upwardLeagueId() {
    return store.getters.leagueAbstraction.currentItem.upwardLeagueID
  }

  @Watch('value', { immediate: true })
  valueUpdated() {
    this.internalValue = cloneDeep(this.value)
    this.inputContent = this.value.content ?? ''
    this.outputContent = this.value.content ?? ''
    this.qrCodeURL = this.value.qrCodeURL ?? ''
    this.internalValue.accountNumber = this.accountNumber
    this.internalValue.upwardleagueID = this.upwardLeagueId
    this.doPreview()
  }
  url = ''
  @Watch('internalPreview')
  urlChange() {
    if (this.internalPreview && this.internalPreview.publicUrl)
      this.url = `https://drive.google.com/viewerng/viewer?embedded=true&cachebust=${Math.random()}&url=${
        this.internalPreview.publicUrl
      }`
    this.checkLoading()
  }

  @Watch('qrCodeURL')
  qrCodeURLChanged() {
    this.dirty = true
  }

  checkLoading() {
    setTimeout(() => {
      if (document.querySelector('iframe')?.contentDocument) {
        this.fetchingPdf = true
        // Document didn't load. Try again.
        this.urlChange()
        this.fetchingPdf = false
      }
    }, 3000)
  }

  /**
   * Close no save.
   */
  close() {
    this.modalIsVisible = false
  }

  @Emit()
  async update() {
    await this.saveAndFetchPdf()
    return cloneDeep(this.internalPreview)
  }

  isSaving = false
  save() {
    this.isSaving = true
    this.update()
  }

  setQRDefault() {
    this.qrCodeURL = this.internalValue.defaultRegistrationURL ?? ''
  }
}
