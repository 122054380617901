import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import campsClient from '@/clients/campsClient'
import { Camp } from '@/GeneratedTypes/Camp'
import sessionObjectsClient from '@/clients/sessionObjectsClient'
import { LeagueRegistration } from '@/GeneratedTypes/LeagueRegistration'
import { initializeNewProgramGradeData } from '@/services/gradeService'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { ActionsImpl } from 'direct-vuex'
import { getEmptyCamp } from '@/lib/support/models/Camp/data'
import { defineGetters } from 'direct-vuex'
import { uniqueUserIdCheck } from '@/store/helper'
import store from '@/store'

interface CampsState {
  currentItem: Camp
}

const campsState: CampsState = {
  currentItem: getEmptyCamp(),
}

export enum getterNames {
  currentItem = 'currentItem',
}

const getterTree = defineGetters<CampsState>()({
  currentItem: (state) => state.currentItem,
})

export enum mutationNames {
  setCurrent = 'setCurrent',
}

const mutations: MutationTree<CampsState> = {
  [mutationNames.setCurrent](state, { item }: { item: Camp }) {
    state.currentItem = item
    store.commit.leagueAbstraction.setCurrentCamp({ item })
  },
}

export enum actionNames {
  create = 'create',
  update = 'update',
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  validate = 'validate',
  cache = 'cache',
  loadFromCache = 'loadFromCache',
  beginCreating = 'beginCreating',
}

const actions: ActionTree<CampsState, RootState> & ActionsImpl = {
  async [actionNames.retrieveAndSetAsCurrent]({ commit }, { id }: { id: string }): Promise<Camp | null> {
    const result = await campsClient.retrieve(id)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })

      return result.data
    }

    return null
  },
  async [actionNames.create]({ commit, rootGetters }, { item }: { item: Camp }): Promise<Camp | null> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    const result = await campsClient.create(item)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })

      await sessionObjectsClient.delete(`camp.new.${uniqueUserId}`)
      return result.data
    }

    return null
  },
  async [actionNames.update]({ commit }, { item }: { item: Camp }): Promise<Camp | null> {
    const result = await campsClient.update(item.upwardLeagueID!, item)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })
      return result.data
    }

    return null
  },
  async [actionNames.validate](
    { commit },
    { item, ruleSet = '' }: { item: Camp; ruleSet: string }
  ): Promise<boolean> {
    const isNew = !item.upwardLeagueID

    const validationResult = await (isNew
      ? campsClient.validateNew(item, ruleSet)
      : campsClient.validateExisting(item.upwardLeagueID!, item))

    if (!validationResult.isSuccess) {
      return false
    }

    commit(mutationNames.setCurrent, { item: validationResult.data!.model })
    return true
  },
  async [actionNames.cache]({ commit, rootGetters }, { item }: { item: Camp }): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    const isNew = !item.upwardLeagueID
    const cacheKey = `camp.${isNew ? `new.${uniqueUserId}` : item.upwardLeagueID}`
    const cacheResult = await sessionObjectsClient.createOrUpdate(cacheKey, item)

    if (!cacheResult.isSuccess) {
      return false
    }

    commit(mutationNames.setCurrent, { item })
    return true
  },
  async [actionNames.loadFromCache]({ commit, rootGetters }): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    const result = await sessionObjectsClient.retrieve<Camp>(`camp.new.${uniqueUserId}`)

    if (!result.isSuccess) {
      return false
    }

    const item = result.data!
    commit(mutationNames.setCurrent, { item })

    return true
  },
  async [actionNames.beginCreating](
    { commit, dispatch },
    {
      name,
      typeLeagueID,
      publicDisplayName,
      partnerContact,
    }: { name: string; typeLeagueID: string; publicDisplayName: string; partnerContact: ContactInfo | null }
  ): Promise<boolean> {
    const result = await campsClient.newTemplate(typeLeagueID)

    if (!result.isSuccess) {
      return false
    }

    const item = result.data!

    item.publicDisplayName = publicDisplayName
    item.leagueName = name
    item.isOPRLeague = true
    item.canEdit = true

    if (item.programs != null && item.programs.length > 0) {
      const programTypeId = item.programs[0].typeProgramID

      initializeNewProgramGradeData(item, programTypeId)
    }

    // TODO: fix hard coded values.
    if (item.registrationInfo === null) {
      const registrationInfo: LeagueRegistration = {
        allowCreditCard: true,
        allowPayLater: true,
        allowSizingEntry: false,
        allowGeneralNotes: false,
        allowDonations: false,
        allowWaitlist: true,
        onlineRegistrationBeginDate: null,
        onlineRegistrationEndDate: null,
        donationExplanation: '',
        registrationStartMessage: '',
        confirmationMessage: '',
        twoChildDiscountAmount: 0,
        threeChildDiscountAmount: 0,
        fourOrMoreChildDiscountAmount: 0,
        allowDiscountCodes: false,
        emailLDOnDiscountCodeUse: false,
        discountCodes: [],
        bypassLinks: [],
        contactLine1: '',
        contactLine2: '',
        contactLine3: '',
        contactLine4: '',
        contactLine5: '',
      }

      item.registrationInfo = registrationInfo
    }

    if (partnerContact) {
      item.partnerContact = {
        firstName: partnerContact.firstName!,
        lastName: partnerContact.lastName!,
        emailAddress: partnerContact.emailAddress!,
        emailValid: partnerContact.emailValid,
        emailValidReason: partnerContact.emailValidReason,
        primaryPhoneNumber: partnerContact.primaryPhone!,
        primaryPhoneTypeID: partnerContact.primaryPhoneTypeID!,
        secondaryPhoneNumber: partnerContact.secondaryPhone,
        secondaryPhoneTypeID: partnerContact.secondaryPhoneTypeID,
        alternatePhoneNumber: partnerContact.alternatePhone,
        alternatePhoneTypeID: partnerContact.alternatePhoneTypeID,
        yearsExperience: partnerContact.yearsExperience,
        isFullTime: partnerContact.isFullTime,
        typePartnerRoleID: partnerContact.typePartnerRoleID!,
        typePreferredDayOfWeekID: partnerContact.preferredContactDayOfWeek!,
        typePreferredTimeOfDayID: partnerContact.preferredContactTimeOfDay!,
        typePreferredContactMethodID: partnerContact.preferredContactMethod!,
      }

      item.leagueDirectorPartnerID = partnerContact.id
    }

    commit(mutationNames.setCurrent, { item })

    await dispatch(actionNames.cache, { item })

    return true
  },
}

export const namespace = 'camps'

export const camps = {
  namespaced: true as true,
  state: campsState,
  getters: getterTree,
  mutations,
  actions,
}
