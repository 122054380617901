















































import { defineComponent, computed, ref } from '@vue/composition-api'

import SetupStepperLeague from '@/components/ProductFlow/ProductSetupStepper/SetupStepperLeague.vue'
import Loading from '@/elements/Loading.vue'
import InfoModal from '@/components/InfoModal.vue'

import { EstimateInitializer } from '@/lib/support/models/EstimateInitializer/EstimateInitializer'
import { PromptToSavePayload } from '@/models/PromptToSavePayload'
import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

import store from '@/store'

export default defineComponent({
  name: 'LeagueEstimator',
  props: {},
  components: { SetupStepperLeague, Loading, InfoModal },
  setup(props, { root }) {
    const logic = productFlowLogic()

    const isSavingEstimate = ref(false)
    const isStartingNewLeague = ref(false)

    const canBeSaved = computed(() => {
      if (store.getters.productFlow.isNewProdConfig) return true

      const lockEstimator = store.getters.productOfferings.selectedProductOfferings.some(
        (o) => o.active == false
      )
      return !lockEstimator
    })

    const showEstimateSavedModal = ref(false)

    async function saveEstimateClick() {
      await saveEstimate()
      showEstimateSavedModal.value = true
    }

    function closeEstimateSavedModal() {
      showEstimateSavedModal.value = false
    }

    async function saveEstimate(): Promise<SavedProductOfferingConfig | null> {
      isSavingEstimate.value = true
      try {
        if (!logic.productOfferingConfig.value.accountNumber) {
          store.commit.productOfferings.setAccountNumber({ id: logic.firstAccountNumber.value })
        }
        return await store.dispatch.productOfferings.upsertOfferings()
        store.commit.promptToSave.setIsDirty({ isDirty: false })
      } finally {
        isSavingEstimate.value = false
      }
    }

    const routeToCreateLeague = '/programs/league/setup/information'
    async function startLeague() {
      isStartingNewLeague.value = true
      store.commit.promptToSave.setIsDirty({ isDirty: false })
      try {
        const saveResult = await saveEstimate()
        if (saveResult) {
          const initializer: EstimateInitializer = {
            typeLeagueID: store.getters.productOfferings.productOfferingConfig.typeLeagueID ?? '',
            productOfferingConfig: saveResult,
          }
          const result = await store.dispatch.leagues.beginCreatingFromEstimate({ initializer })
          if (result) root.$router.push(routeToCreateLeague)
        }
      } finally {
        isStartingNewLeague.value = false
      }
    }

    const loadingComplete = computed(() => {
      const configId = store.getters.productOfferings.productOfferingConfig.id ?? null
      const loadComplete = store.getters.productOfferings.initialProductFlowLoadingComplete
      return configId ? loadComplete : true
    })

    root.$router.beforeResolve((route, redirect, next) => {
      store.dispatch.promptToSave.onNavigation({
        save: async () => {
          const result = await saveEstimate()
          return result ? true : false
        },
        continue: () => {
          next()
        },
      } as PromptToSavePayload)
    })
    return {
      canBeSaved,
      isSavingEstimate,
      isStartingNewLeague,
      saveEstimate,
      saveEstimateClick,
      startLeague,
      loadingComplete,
      showEstimateSavedModal,
      closeEstimateSavedModal,
    }
  },
})
