<template>
  <div class="product-select-css-grid mb-4"><slot></slot></div>
</template>

<script>
export default {
  name: 'ProductSelect',
}
</script>

<style scoped>
.product-select-css-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-auto-rows: minmax(175px, 1fr);
}
</style>
