



























import { defineComponent, computed, ref, watch, onMounted, onUnmounted, nextTick } from '@vue/composition-api'
import ShowToggle from '@/elements/ShowToggle.vue'

export default defineComponent({
  name: 'SideBySideScroll',
  components: { ShowToggle },
  props: {
    left: { type: String, default: '', required: false },
    right: { type: String, default: '', required: false },
    ready: { type: Boolean, default: false, required: false },
    useTopOffset: { type: Boolean, default: false, required: false },
    showToggles: { type: Boolean, default: true, required: false },
  },
  setup(props, ctx) {
    const aboveTheScroll = ref(0)
    const windowHeight = ref(0)
    const footerTop = ref(0)
    const top = ref(0)
    const showLeft = ref(true)
    const showRight = ref(true)

    onMounted(() => {
      window.addEventListener('resize', dimensions)
      dimensions()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', dimensions)
    })

    function dimensions() {
      nextTick(() => {
        const scrollArea = document.getElementsByClassName('scroll-area')
        const footer = document.getElementsByClassName('v-footer')
        const myTop = document.getElementsByClassName('side-by-side')
        if (scrollArea.length && footer.length && myTop.length) {
          aboveTheScroll.value = scrollArea[0].getBoundingClientRect().top
          footerTop.value = footer[0].getBoundingClientRect().height
          top.value = myTop[0].getBoundingClientRect().top
          windowHeight.value = window.innerHeight
        }
      })
    }

    function toggleVisible(side: string) {
      side === 'LEFT' ? (showLeft.value = !showLeft.value) : (showRight.value = !showRight.value)
      ctx.emit('toggled', side, side === 'LEFT' ? showLeft.value : showRight.value)
      dimensions()
    }

    const styleScrollHeight = computed(() => {
      let height = windowHeight.value - (aboveTheScroll.value + footerTop.value)
      if (props.useTopOffset) {
        height = height + top.value
      }
      return `max-height:${height}px`
    })

    watch(
      () => props.ready,
      () => {
        if (props.ready == true) {
          dimensions()
        }
      }
    )

    watch(
      () => [showRight.value, showLeft.value],
      () => {
        ctx.emit('showing', { left: showLeft.value, right: showRight.value })
      }
    )

    return {
      showLeft,
      showRight,
      toggleVisible,
      styleScrollHeight,
      dimensions,
    }
  },
})
