


















import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { CoachLinksBrokenReportType } from '@/clients/reportsClient'

export default defineComponent({
  props: {
    report: {
      type: Array as PropType<Array<CoachLinksBrokenReportType>>,
      default: () => [],
      required: true,
    },
  },

  setup(props) {
    const internalReport = ref<Array<CoachLinksBrokenReportType>>([])
    watch(
      () => props.report,
      () => (internalReport.value = props.report),
      { immediate: true }
    )

    return { internalReport }
  },
})
