















































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'

import CoachList from '@/views/Programs/Divisions/common/vue/CoachList.vue'
import CoachConflictList from '@/views/Programs/Divisions/TeamManagement/vues/CoachConflictList.vue'
import CoachConflictFilter from '@/views/Programs/Divisions/TeamManagement/vues/CoachConflictFilter.vue'
import TeamToggle from '@/views/Programs/Divisions/common/vue/TeamToggle.vue'
import SideBySideScroll from '@/elements/SideBySideScroll.vue'
import TeamCardField from '@/views/Programs/Divisions/Coaches/vues/TeamCardField.vue'
import TeamListSort from '@/views/Programs/Divisions/TeamManagement/vues/TeamListSort.vue'
import ShowToggle from '@/elements/ShowToggle.vue'
import Loading from '@/elements/Loading.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import TextInput from '@/elements/TextInput.vue'
import ToggleSwitchInput from '@/elements/ToggleSwitchInput.vue'

import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import { CoachConflictInfo } from '@/GeneratedTypes/ListInfo/CoachConflictInfo'

import { useManagerLogic } from '@/views/Programs/Divisions/Coaches/logic/ManagerLogic'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'
import volunteersClient from '@/clients/volunteersClient'

export default defineComponent({
  props: {
    division: { type: Object as PropType<LeagueDivisionInfo>, required: true },
    programID: { type: String, required: true },
  },
  components: {
    TeamCardField,
    CoachList,
    CoachConflictList,
    CoachConflictFilter,
    SideBySideScroll,
    TeamToggle,
    ShowToggle,
    Loading,
    SwitchSlot,
    TextInput,
    ToggleSwitchInput,
    TeamListSort,
  },

  setup(props) {
    const searchTerm = ref('')
    const manager = useManagerLogic()
    const { setCoachTeamListSort, getCoachTeamListSort } = userPreferences()
    const selectedCoach = ref<LeagueCoachProgramInfoFLAT | null>(null)
    const coachListChildRef = ref()
    const coachConflicts = ref<CoachConflictInfo[]>([])
    const upwardLeagueID = ref<string | null>(props.division.upwardLeagueID)

    const teamOptions = [
      { text: 'show/hide teams', value: 'team-toggle' },
      { text: 'sort teams', value: 'sort-teams' },
      { text: 'none', value: 'hide-all' },
    ]
    const teamSwitch = ref(teamOptions[0].value)
    const managerReady = computed(() => !manager.coachRefreshing.value)

    const coachListOptions = [
      { text: 'all coaches', value: 'all-coaches' },
      { text: 'show conflicts', value: 'show-coach-conflicts' },
    ]
    const coachListSwitch = ref(coachListOptions[0].value)

    const coachesInDivision = computed(() =>
      (manager.coaches.value ?? []).filter((x) => x.typeProgramID == props.programID)
    )

    const coachesToShow = computed(() => {
      const searchTermValue = searchTerm.value.toLowerCase()
      return searchTermValue
        ? coachesInDivision.value.filter(
            (c) =>
              c.firstName!.toLowerCase().indexOf(searchTermValue) >= 0 ||
              c.lastName!.toLowerCase().indexOf(searchTermValue) >= 0
          )
        : coachesInDivision.value
    })

    const sortTeamBy = computed({
      get(): string {
        return getCoachTeamListSort.value
      },
      set(sortBy: string) {
        setCoachTeamListSort(sortBy)
      },
    })

    function selectCoach(coach: LeagueCoachProgramInfoFLAT | null) {
      selectedCoach.value = coach
    }

    function addCoach() {
      coachListChildRef.value?.clearSelectedCoach()
    }

    async function refreshConflicts() {
      coachConflicts.value =
        (await volunteersClient.retrieveCoachConflicts(props.division.upwardLeagueID!)) ?? []
    }

    watch(
      () => manager.teamStructureChanging.value,
      async () => {
        if (!manager.teamStructureChanging.value) {
          await refreshConflicts()
        }
      },
      { immediate: true }
    )

    return {
      ...manager,
      coaches: computed(() => manager.coaches.value),
      coachesToShow,
      teamsChecked: computed(() => manager.teamsOnField.value?.map((x) => x.teamID) ?? []),
      managerReady,
      teamOptions,
      teamSwitch,
      searchTerm,
      isOnlyMatchingCoaches: manager.isOnlyMatchingCoaches,
      setIsOnlyMatchingCoaches: manager.setIsOnlyMatchingCoaches,
      sortTeamBy,
      selectedCoach,
      selectCoach,
      addCoach,
      coachListChildRef,
      coachConflicts,
      upwardLeagueID,
      coachListOptions,
      coachListSwitch,
    }
  },
})
