









import { defineComponent } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
export default defineComponent({
  name: 'SiteLoading',
  props: {},
  components: { Loading },
  setup() {
    return {}
  },
})
