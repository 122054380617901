import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import gamesClient from '@/clients/gamesClient'
import teamsClient from '@/clients/teamsClient'
import appointmentClient from '@/clients/appointmentClient'
import facilitiesClient from '@/clients/facilitiesClient'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { LeagueSpecialEventInfo } from '@/GeneratedTypes/ListInfo/LeagueSpecialEventInfo'
import { LeagueSpecialEvent } from '@/GeneratedTypes/LeagueSpecialEvent'
import { FacilityEventInfo } from '@/GeneratedTypes/ListInfo/FacilityEventInfo'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'

import { DivisionTeamPracticeAppointmentInfoModified } from '@/models/Practices/DivisionTeamPracticeAppointmentInfoModified'
import { CheerSquadGameAppointmentInfo } from '@/GeneratedTypes/ListInfo/CheerSquadGameAppointmentInfo'
import { defineActions, defineGetters, defineMutations } from 'direct-vuex'
import { moduleActionContext } from '@/store/index'
import { CheerSquadGameAppointmentInfoModified } from '@/models/Appointments/CheerSquadGameAppointmentInfoModified'
import { isEmptyCheerSquadAppointment } from '@/lib/support/models/CheerSquadGameAppointment/data'
import { DivisionGameInfoToDivisionGame } from '@/lib/support/models/DivisionGame/DivisionGameInfoToDivisionGame'

import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

/**
 * Short circuits a trip to the server for game info.
 * @param g
 * @param teams
 * @constructor
 */
function SynthesizeGameInfo(g: DivisionGameInfo, teams: DivisionTeamInfo[]): DivisionGameInfo {
  return {
    ...g,
    serverGameEnd: null,
    serverGameStart: null,
    divisionName: g.divisionName,
    homeTeamName: teams.find((x) => x.teamID == g.homeTeamID)?.teamName ?? 'n/a',
    awayTeamName: teams.find((x) => x.teamID == g.awayTeamID)?.teamName ?? 'n/a',
    facilityName: '',
    volunteers:
      g.volunteers?.map((v) => {
        return {
          ...v,
          leagueID: g.leagueID,
          gameID: g.gameID,
        }
      }) ?? [],
  }
}

/**
 * Short circuits a trip to the server for practice info.
 * @param g
 * @param teams
 * @constructor
 */

function teamName(id: number, teams: DivisionTeamInfo[]) {
  return teams.find((x) => x.teamID == id)?.teamName ?? 'n/a'
}

/**
 * Short circuits a trip to the server for game info.
 * @param g
 * @param teams
 * @constructor
 */

function SynthesizeGameToEvent(g: DivisionGameInfo, teams: DivisionTeamInfo[]): FacilityEventInfo {
  return {
    eventID: g.gameID,
    typeFacilityEventID: FacilityEventEnum.GAMES,
    eventStart: g.gameStart,
    eventEnd: g.gameEnd,
    eventName: `${teamName(g.homeTeamID, teams)} vs ${teamName(g.awayTeamID, teams)}`,
    roundNumber: g.roundNumber,
    facilityName: '',
    divisionID: g.divisionID,
    leagueID: g.leagueID,
    divisionName: g.divisionName,
    facilityID: g.facilityID,
    typeProgramID: g.typeProgramID,
  }
}

export interface leagueDivisionProgramParams {
  id: UpwardLeagueIDType
  divisionID: number
  programID: string
}

export interface leagueDivisionOptionalProgramParams {
  id: UpwardLeagueIDType
  divisionID?: number
  programID: string
}

class SchedulingException extends RuntimeException {
  name = 'Scheduling Exception'
}

interface SchedulingState {
  statusText: string
  gamesStatusText: string
  appointments: CheerSquadGameAppointmentInfo[]
  games: DivisionGameInfo[]
  events: FacilityEventInfo[]
  teams: DivisionTeamInfo[]
  specialEvents: LeagueSpecialEventInfo[]
  program: string //program selected.
  teamsLoading: boolean
  scheduleChanging: boolean
}
const state: SchedulingState = {
  games: [],
  events: [],
  teams: [],
  appointments: [],
  specialEvents: [],
  program: '',
  statusText: '',
  gamesStatusText: '',
  teamsLoading: false,
  scheduleChanging: false,
}

export enum mutationNames {
  setAppointments = 'setAppointments',
  setStatus = 'setStatus',
  setGames = 'setGames',
  setProgram = 'setProgram',
  setTeams = 'setTeams',
  updateGame = 'updateGame',
  removeGame = 'removeGame',
  setEvents = 'setEvents',
  setSpecialEvents = 'setSpecialEvents',
  setTeamsLoading = 'setTeamsLoading',
  setGamesStatusText = 'setGamesStatusText',
  setScheduleChanging = 'setScheduleChanging',
}
const mutations = defineMutations<SchedulingState>()({
  setAppointments(
    state: SchedulingState,
    { appointments }: { appointments: CheerSquadGameAppointmentInfo[] }
  ) {
    state.appointments = appointments
  },

  setStatus(state: SchedulingState, { status }: { status: string }) {
    state.statusText = status
  },
  setGameStatusText(state: SchedulingState, { status }: { status: string }) {
    state.gamesStatusText = status
  },
  setGames(state: SchedulingState, { games }: { games: DivisionGameInfo[] }) {
    state.games = games
  },
  setProgram(state: SchedulingState, { program }: { program: string }) {
    state.program = program
  },
  removeGame(state: SchedulingState, { gameID }: { gameID: number }) {
    const index = state.games.findIndex((x) => x.gameID === gameID)
    if (index >= 0) {
      state.games.splice(index, 1)
    }

    const eventIndex = state.events.findIndex(
      (x) => x.typeFacilityEventID == FacilityEventEnum.GAMES && x.eventID == gameID
    )
    if (eventIndex >= 0) {
      state.events.splice(eventIndex, 1)
    }
  },
  setTeams(state: SchedulingState, { teams }: { teams: DivisionTeamInfo[] }) {
    state.teams = teams
  },
  setEvents(state: SchedulingState, { events }: { events: FacilityEventInfo[] }) {
    state.events = events
  },
  setTeamsLoading(state: SchedulingState, { isLoading }: { isLoading: boolean }) {
    state.teamsLoading = isLoading
  },
  setScheduleChanging(state: SchedulingState, { isChanging }: { isChanging: boolean }) {
    state.scheduleChanging = isChanging
  },
  setSpecialEvents(state: SchedulingState, { specialEvents }: { specialEvents: LeagueSpecialEventInfo[] }) {
    state.specialEvents = specialEvents
  },

  /**
   * Will update the game and if the game isn't found it will add it.
   * @param state
   * @param teams
   */
  updateGame(state, payload: { game: DivisionGameInfo }) {
    const { game } = payload
    const i = state.games.findIndex((x) => x.gameID == game.gameID)
    if (i >= 0) {
      state.games.splice(i, 1, SynthesizeGameInfo(game, state.teams))
    } else {
      state.games.push(SynthesizeGameInfo(game, state.teams))
    }
    const k = state.events.findIndex(
      (x) => x.typeFacilityEventID == FacilityEventEnum.GAMES && x.eventID == game.gameID
    )
    if (k >= 0) {
      state.events.splice(k, 1, SynthesizeGameToEvent(game, state.teams))
    } else {
      state.events.push(SynthesizeGameToEvent(game, state.teams))
    }
  },
})

export enum getterNames {
  appointments = 'appointments',
  games = 'games',
  teams = 'teams',
  events = 'events',
  specialEvents = 'specialEvents',
  program = 'program',
  statusText = 'statusText',
  teamsLoading = 'teamsLoading',
  gamesStatusText = 'gamesStatusText',
  scheduleChanging = 'scheduleChanging',
}

const getters = defineGetters<SchedulingState>()({
  appointments: (state: SchedulingState) => state.appointments,
  events: (state: SchedulingState) => state.events,
  games: (state: SchedulingState) => state.games,
  program: (state: SchedulingState) => state.program,
  teams: (state: SchedulingState) => state.teams as DivisionTeamInfo[],
  specialEvents: (state: SchedulingState) => state.specialEvents,
  statusText: (state: SchedulingState) => state.statusText,
  gamesStatusText: (state: SchedulingState) => state.gamesStatusText,
  teamsLoading: (state: SchedulingState) => state.teamsLoading,
  scheduleChanging: (state: SchedulingState) => state.scheduleChanging,
})

const actions = defineActions({
  async loadAppointments(context, { id }: { id: UpwardLeagueIDType }) {
    const { commit } = actionContext(context)
    if (id === undefined) {
      throw new SchedulingException('Cannot load appointments, problem improper league passed.')
    }
    try {
      const appointments = await appointmentClient.retrieveAllAppointments(id)

      commit.setAppointments({ appointments: appointments ?? [] })
    } catch (exc: any) {
      throw new SchedulingException(`unable to retrieve appointments from league id: ${id}`, exc)
    }
  },

  async removeAppointment(
    { commit, dispatch },
    { id, appointmentID }: { id: UpwardLeagueIDType; appointmentID: number }
  ) {
    if (id === undefined) {
      throw new SchedulingException('Cannot load appointments, problem improper league passed.')
    }
    try {
      commit(mutationNames.setScheduleChanging, { isChanging: true })

      await appointmentClient.remove(id, appointmentID)
      commit(mutationNames.setScheduleChanging, { isChanging: false })
      await Promise.all([dispatch('loadAppointments', { id }), dispatch('loadEvents', { id })])
    } catch (exc: any) {
      throw new SchedulingException(`unable to retrieve appointments from league id: ${id}`, exc)
    }
  },

  async loadGames({ commit }, { id }: { id: UpwardLeagueIDType }) {
    if (id === undefined) {
      throw new SchedulingException('Cannot load games, problem improper league passed.')
    }
    try {
      const games = await gamesClient.retrieveAllGames(id)

      commit(mutationNames.setGames, { games })
    } catch (exc: any) {
      throw new SchedulingException(`unable to retrieve games from league id: ${id}`, exc)
    }
  },
  async deletePractice(
    { commit, dispatch },
    {
      id,
      teamID,
      programID,
      divisionID,
      practiceIDs,
    }: {
      id: UpwardLeagueIDType
      teamID: number
      divisionID: number
      programID: string
      practiceIDs: number[]
    }
  ) {
    if (!id) {
      throw new SchedulingException("Practice save can't continue the way it is set up.")
    }
    try {
      commit(mutationNames.setScheduleChanging, { isChanging: true })

      await teamsClient.deletePractices(id, programID, divisionID, teamID, practiceIDs)
      commit(mutationNames.setScheduleChanging, { isChanging: false })
      await Promise.all([dispatch('loadEvents', { id }), dispatch('loadTeams', { id, programID })])
    } catch (exc: any) {
      throw new SchedulingException(`unable to remove practice on ${teamID}`, exc)
    }
  },

  async addPractices(
    { commit, dispatch },
    {
      id,
      programID,
      practices,
    }: { id: UpwardLeagueIDType; programID: string; practices: DivisionTeamPracticeAppointmentInfoModified[] }
  ) {
    const teamMap = new Map<number, DivisionTeamPracticeAppointmentInfoModified[]>()
    practices
      .map((x) => x.teamID)
      .forEach((teamID) => {
        teamMap.set(
          teamID,
          practices.filter((x) => x.teamID == teamID)
        )
      })

    commit(mutationNames.setScheduleChanging, { isChanging: true })

    if (practices.length && teamMap.size) {
      // now we have a map of teamids and practices (usually this would be one)
      for (const k of teamMap.keys()) {
        await teamsClient.setPractices(id, k, teamMap.get(k) ?? [])
      }

      commit(mutationNames.setScheduleChanging, { isChanging: false })
      await dispatch('loadEvents', { id })
      await dispatch('loadTeams', { id, programID })
    }
  },
  async clearPractices(
    { commit, dispatch },
    {
      id,
      programID,
      divisionID,
      teamIDs,
    }: { id: UpwardLeagueIDType; programID: string; divisionID: number; teamIDs: number[] }
  ) {
    try {
      let i = 0
      commit(mutationNames.setScheduleChanging, { isChanging: true })
      commit(mutationNames.setStatus, { status: 'Clearing... 0%' })
      if (teamIDs.length) {
        for (const x of teamIDs) {
          i++
          commit(mutationNames.setStatus, {
            status: `Clearing... ${Math.floor((i * 100) / teamIDs.length)}%`,
          })
          await teamsClient.clearPractices(id, programID, divisionID, [x])
        }
        commit(mutationNames.setStatus, { status: 'Clearing... 100%' })
        commit(mutationNames.setStatus, { status: '' })
      }

      commit(mutationNames.setScheduleChanging, { isChanging: false })

      await dispatch('loadEvents', { id })
      await dispatch('loadTeams', { id, programID })
    } catch (exc: any) {
      throw new SchedulingException(`unable to clear practices form league id: ${id}`, exc)
    }
  },

  async clearAppointments(
    context,
    {
      id,

      teamIDs,
    }: { id: UpwardLeagueIDType; teamIDs: number[] }
  ) {
    try {
      let i = 0
      const { commit, dispatch } = actionContext(context)
      commit.setScheduleChanging({ isChanging: true })
      commit.setStatus({ status: 'Clearing... 0%' })
      if (teamIDs.length) {
        for (const x of teamIDs) {
          i++
          commit.setStatus({
            status: `Clearing... ${Math.floor((i * 100) / teamIDs.length)}%`,
          })
          await appointmentClient.clearAppointments(id, x)
        }
        commit.setStatus({ status: 'Clearing... 100%' })
        setTimeout(() => commit.setStatus({ status: '' }), 1000)
      }

      commit.setScheduleChanging({ isChanging: false })
      await dispatch.loadEvents({ id })
      await dispatch.loadAppointments({ id })
    } catch (exc: any) {
      throw new SchedulingException(`unable to clear practices form league id: ${id}`, exc)
    }
  },

  async clearGames(context, { id, gameIDs }: { id: UpwardLeagueIDType; gameIDs: number[] }) {
    let i = 0
    const { commit, dispatch } = actionContext(context)
    commit.setScheduleChanging({ isChanging: true })
    commit.setGameStatusText({ status: 'Clearing... 0%' })
    if (gameIDs.length) {
      for (const gameId of gameIDs) {
        i++
        commit.setGameStatusText({
          status: `Clearing... ${Math.floor((i * 100) / gameIDs.length)}%`,
        })
        await gamesClient.remove(id, gameId)
      }
      commit.setGameStatusText({ status: 'Clearing... 100%' })
      setTimeout(() => commit.setGameStatusText({ status: '' }), 1000)
    }

    commit.setScheduleChanging({ isChanging: false })

    await Promise.all([dispatch.loadGames({ id }), dispatch.loadEvents({ id })])
  },

  async loadEvents({ commit }, { id }: { id: UpwardLeagueIDType }) {
    if (id === undefined) {
      throw new SchedulingException('Cannot load events problem improper league passed.')
    }

    try {
      const events = await facilitiesClient.retrieveEvents(id)

      commit(mutationNames.setEvents, { events })
    } catch (exc: any) {
      throw new SchedulingException(`unable to retrieve events from league id: ${id}`, exc)
    }
  },
  async loadTeams({ commit }, p: leagueDivisionOptionalProgramParams) {
    if (!p.programID || !p.id) {
      throw new SchedulingException(
        `Cannot retrieve teams without a program id (${p.programID}) or league id (${p.id})`
      )
    }
    try {
      commit(mutationNames.setTeamsLoading, { isLoading: true })
      const teams = await teamsClient.retrieveTeamList({
        leagueId: p.id,
        divisionId: p.divisionID,
        typeProgramId: p.programID,
      })

      commit(mutationNames.setTeams, { teams })
    } catch (exc: any) {
      throw new SchedulingException(`unable to retrieve teams form league id: ${p.id}`, exc)
    } finally {
      commit(mutationNames.setTeamsLoading, { isLoading: false })
    }
  },
  async pairTeams({ commit, dispatch }, p: leagueDivisionProgramParams) {
    if (!p.id || !p.programID || !p.divisionID) {
      throw new SchedulingException("Pair teams can't continue the way it is set up.")
    }
    try {
      commit(mutationNames.setScheduleChanging, { isChanging: true })
      await gamesClient.pairTeams(p.id, p.programID, p.divisionID)
      commit(mutationNames.setScheduleChanging, { isChanging: false })
      await Promise.all([
        dispatch('loadGames', {
          id: p.id,
        }),
      ])
    } catch (exc: any) {
      throw new SchedulingException(`unable to pair teams  ${p.id}`, exc)
    }
  },
  async upsertGame({ commit }, { id, game }: { id: UpwardLeagueIDType; game: DivisionGameInfo }) {
    if (!id || !game) {
      throw new SchedulingException("Game save can't continue the way it is set up.")
    }

    const event = {
      ...DivisionGameInfoToDivisionGame(cloneDeep(game)),
      gameEnd: dayjs(game.gameEnd ?? '').format('YYYY-MM-DDTHH:mm:ss'),
      gameStart: dayjs(game.gameStart ?? '').format('YYYY-MM-DDTHH:mm:ss'),
    }

    await gamesClient.validate(id, event)
    commit(mutationNames.setScheduleChanging, { isChanging: true })
    const newGame = await gamesClient.save(id, event)
    game.gameID = newGame?.gameID ?? 0
    commit(mutationNames.updateGame, { game })
    commit(mutationNames.setScheduleChanging, { isChanging: false })
  },
  async upsertAppointments(
    context,
    { id, appointments }: { id: UpwardLeagueIDType; appointments: CheerSquadGameAppointmentInfoModified[] }
  ) {
    const { commit, dispatch } = actionContext(context)
    if (!id || !appointments) {
      throw new SchedulingException("Game save can't continue the way it is set up.")
    }

    const newappts = appointments.filter((x) => isEmptyCheerSquadAppointment(x))
    const updates = appointments.filter((x) => !isEmptyCheerSquadAppointment(x))

    commit.setScheduleChanging({ isChanging: true })
    await appointmentClient.save(id, newappts)
    for (let i = 0; i < updates.length; i++) {
      const apt = updates[i]
      await appointmentClient.update(id, apt)
    }
    commit.setScheduleChanging({ isChanging: false })
    await Promise.all([dispatch.loadAppointments({ id }), dispatch.loadEvents({ id })])
  },
  async removeGame(context, payload: { id: UpwardLeagueIDType; gameID: number }): Promise<void> {
    const { commit } = context
    const { id, gameID } = payload
    if (!id) {
      throw new SchedulingException("Game save can't continue the way it is set up.")
    }
    commit(mutationNames.setScheduleChanging, { isChanging: true })
    await gamesClient.remove(id, gameID)
    commit(mutationNames.removeGame, { gameID })
    commit(mutationNames.setScheduleChanging, { isChanging: false })
  },

  async loadSpecialEvents(context, { id }: { id: UpwardLeagueIDType }) {
    const { commit } = actionContext(context)
    if (id === undefined) {
      throw new SchedulingException('Cannot load special events, problem improper league passed.')
    }
    try {
      const specialEvents = await appointmentClient.retrieveAllSpecialEvents(id)

      commit.setSpecialEvents({ specialEvents: specialEvents ?? [] })
    } catch (exc: any) {
      throw new SchedulingException(`unable to retrieve special events from league id: ${id}`, exc)
    }
  },

  async upsertSpecialEvent(
    context,
    { id, specialEvent }: { id: UpwardLeagueIDType; specialEvent: LeagueSpecialEvent }
  ) {
    const { dispatch } = actionContext(context)
    if (specialEvent.eventID == 0) {
      await appointmentClient.saveSpecialEvent(specialEvent)
    } else {
      await appointmentClient.updateSpecialEvent(id, specialEvent)
    }
    await dispatch.loadSpecialEvents({ id: id })
  },

  async removeSpecialEvent(context, payload: { id: UpwardLeagueIDType; eventID: number }): Promise<void> {
    const { dispatch } = actionContext(context)
    const { id, eventID } = payload
    await appointmentClient.removeSpecialEvent(id, eventID)
    await dispatch.loadSpecialEvents({ id: id })
  },
})

export const namespace = 'scheduling'

export const scheduling = {
  namespaced: true as true,
  state,
  actions,
  getters,
  mutations,
}
const actionContext = (context: any) => moduleActionContext(context, scheduling)
