export interface OrderProductSize {
  typeSizeID: string
  typeColorID: string
  colorDescription: string
  sizeDescription: string
  costOffset: number
  exchangeCost: number
  orderQuantity: number
  orderFreeQuantity: number
  previouslyOrdered: number
}
let typeCounter = 0
export function getEmptyOrderProductSize() {
  return {
    typeSizeID: (--typeCounter).toString(),
    typeColorID: (--typeCounter).toString(),
    sizeDescription: '',
    colorDescription: '',
    costOffset: 0,
    orderQuantity: 0,
    orderFreeQuantity: 0,
    exchangeCost: 0,
    previouslyOrdered: 0,
  } as OrderProductSize
}
