import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'

export function getEmptyProductOfferingInfo(): ProductOfferingInfo {
  return {
    id: 0,
    typeProgramID: '',
    typeProductGroupID: '',
    description: '',
    sortOrder: 0,
    active: true,
    categories: null,
    isCheer: true,
    cost: 0,
    cmsProductName: '',
    cmsProductDescription: '',
    cmsProductImageUrls: [],
    productPackages: [],
    digitalFormatAvailable: null,
    productCostOffsetLabels: [],
  }
}
