

















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import Modal from '@/components/Modal.vue'

import * as authorizationStore from '@/store/authorization'
import partnersClient from '@/clients/partnersClient'
import TextInput from '@/elements/TextInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { EFTAuthorizationRequest } from '@/models/Partner/EFTAuthorizationRequest'
import { EFTRequestStatus } from '@/models/Partner/EFTRequestStatus'

@Component({
  components: {
    TextInput,
    Modal,
    VeeValidateForm,
  },
})
export default class EFTAuthorizationRequestModal extends Vue {
  @Prop({ type: Boolean, required: true })
  private isVisible!: boolean

  @Prop({ type: Object, required: false })
  eftStatus!: EFTRequestStatus | null

  @Getter(authorizationStore.getterNames.firstAccountNumber, { namespace: authorizationStore.namespace })
  private accountNumber!: string

  @Getter(authorizationStore.getterNames.fullName, { namespace: authorizationStore.namespace })
  private currentUserFullName!: string

  @Getter(authorizationStore.getterNames.email, { namespace: authorizationStore.namespace })
  private currentUserEmail!: string

  private name = ''
  private email = ''
  private isSaving = false
  private showModal = false
  private accessCode = ''

  mounted() {
    this.name = this.currentUserFullName
    this.email = this.currentUserEmail
  }

  get hasCollectedData() {
    return this.name && this.email
  }

  get accessCodeEnabled(): boolean {
    return this.eftStatus?.status != 'sent'
  }

  async sendClicked() {
    this.isSaving = true
    const eftAuth = {
      name: this.name,
      email: this.email,
      accessCode: this.accessCode,
    } as EFTAuthorizationRequest
    await partnersClient.sendEFTAuthorizationRequest(this.accountNumber, eftAuth)
    this.$emit('sent')
    this.isSaving = false
  }

  @Watch('eftStatus', { immediate: true, deep: true })
  private onEFTStatusChange() {
    this.accessCode =
      this.eftStatus?.status == 'sent' ? this.eftStatus?.accessCode : Math.random().toString(36).slice(2)
  }

  @Watch('isVisible')
  private onOpenOrClose() {
    this.showModal = this.isVisible
  }

  @Watch('showModal')
  private onShowModalChange() {
    if (!this.showModal) {
      this.$emit('sent')
    }
  }
}
