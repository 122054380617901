






































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'

import CurrencyInput from '@/elements/CurrencyInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import properCase from '@/filters/properCase'
import { isEqual } from 'lodash'

import { LeagueProgramGenderGrade } from 'src/GeneratedTypes/LeagueProgramGenderGrade'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import store from '@/store'

interface ProgramFees {
  typeProgramID: string
  earlyRegistrationFee: number | null
  registrationFee: number
  gradesByGender: LeagueProgramGenderGrade[] | null
  isEarlyFeeRegistration: boolean
  freeEarlyRegistrationFeeConfirmed: boolean
  freeRegistrationFeeConfirmed: boolean
}

export default defineComponent({
  name: 'ProgramRegistrationFee',
  components: {
    CurrencyInput,
    CheckboxInput,
  },
  props: {
    value: { type: Object as PropType<ProgramFees>, required: true },
    hasEarlyRegistration: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const programTypes = computed((): UpwardProgramTypeID[] => store.getters.programTypes.allItems)

    const program = ref<ProgramFees>({ ...props.value })

    const sportName = computed(() => {
      if (programTypes.value && programTypes.value.length > 0) {
        const programType = programTypes.value.find((x) => x.upwardTypeID === program.value.typeProgramID)
        if (programType) {
          return programType.isCheer ? 'Cheerleading' : programType.description
        }
      }

      return properCase(program.value.typeProgramID)
    })

    //private created()
    if (!program.value.isEarlyFeeRegistration) {
      program.value.earlyRegistrationFee = null
    }

    watch(
      () => props.value,
      () => {
        if (!isEqual(program.value, props.value)) {
          program.value = { ...props.value }
        }
      },
      { deep: true }
    )

    watch(
      () => program.value,
      () => {
        if (!isEqual(program.value, props.value)) {
          ctx.emit('input', program.value)
        }
      },
      { deep: true }
    )

    return {
      sportName,
      program,
    }
  },
})
