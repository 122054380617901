











































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { RegistrationCapacityByGradeGender } from '@/GeneratedTypes/RegistrationCapacityByGradeGender'
import { GradeUsage } from '@/services/gradeService'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import * as gradeStore from '@/store/gradeTypes'

import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import IntegerInput from '@/elements/IntegerInput.vue'
import ManageCapacityRule from './ManageCapacityRule.vue'

@Component({
  components: {
    Modal,
    TextInput,
    SelectInput,
    IntegerInput,
    ManageCapacityRule,
  },
})
export default class ManageCapacityRules extends Vue {
  private modalIsVisible = false
  private modalTitle = 'Manage Capacity Rules'
  private defaultManageButtonTitle = 'Manage Capacity Rules'
  private manageButtonTitle = this.defaultManageButtonTitle
  private isEditing = false
  private gradeIndex: Map<string, number> = new Map()
  private gradeUsages: Map<string, GradeUsage> = new Map()

  private editingRule: RegistrationCapacityByGradeGender = {
    id: 0,
    startTypeGradeID: '',
    stopTypeGradeID: '',
    gender: '',
    capacity: 0,
    capacityMessage: '',
  } as RegistrationCapacityByGradeGender

  private internalRegistrationCapacities: RegistrationCapacityByGradeGender[] = []
  private editId: number | null = null

  @Prop({ required: true })
  private readonly value!: RegistrationCapacityByGradeGender[] | null

  @Prop({ required: true })
  private readonly grades!: GradeUsage[] | null

  @Prop({ required: true })
  private readonly typeProgramID!: string

  @Prop({ required: true })
  private readonly upwardLeagueID!: string

  @Prop({ type: Boolean, required: false, default: false })
  private readonly isByAge!: boolean

  @Getter(gradeStore.getterNames.byUpwardTypeId, { namespace: gradeStore.namespace })
  private getUpwardGradeTypeID!: (typeID: string) => UpwardGradeTypeID | null

  private mounted() {
    if (this.value) {
      this.internalRegistrationCapacities = this.value
      this.updateGradeUsageMap()
    }
  }

  private done() {
    this.$emit('onSave', this.internalRegistrationCapacities)
    this.handleChange()
    this.toggleModal()
  }

  private cancel() {
    this.toggleModal()
  }

  private toggleModal() {
    this.modalIsVisible = !this.modalIsVisible
  }

  private addNewRule() {
    this.editingRule = {
      id: (this.internalRegistrationCapacities?.length + 1 ?? 1) * -1,
      startTypeGradeID: '',
      stopTypeGradeID: '',
      gender: '',
      capacity: 0,
      capacityMessage: '',
    } as RegistrationCapacityByGradeGender

    this.editId = null
    this.isEditing = true
  }

  private isValid(items: RegistrationCapacityByGradeGender) {
    const errMsg: string[] = []
    if (!items.startTypeGradeID) {
      errMsg.push(' Start Grade is required.')
    }

    if (!items.stopTypeGradeID) {
      errMsg.push(' End Grade is required.')
    }

    if (!items.gender) {
      errMsg.push(' Gender is required.')
    }

    if (errMsg.length > 0) {
      throw errMsg
    }
    return true
  }

  private saveRow() {
    if (this.editingRule) {
      if (this.isValid(this.editingRule)) {
        if (this.internalRegistrationCapacities) {
          this.internalRegistrationCapacities.push(this.editingRule)
        } else {
          this.internalRegistrationCapacities = [this.editingRule]
        }
        this.updateGradeUsageMap()
        this.isEditing = false
      }
    }
  }

  private deleteRow(id: number | null) {
    this.isEditing = false
    this.editId = null
    if (id) {
      const itemToDeleteIndex = this.internalRegistrationCapacities.findIndex((item) => item.id === id)
      this.internalRegistrationCapacities.splice(itemToDeleteIndex, 1)
      this.updateGradeUsageMap()
    }
  }

  private editRow(id: number) {
    this.editId = id
    this.isEditing = false
  }

  private saveRowEdit() {
    const rule = this.internalRegistrationCapacities.find((item) => item.id === this.editId)
    if (rule && this.isValid(rule)) {
      this.editId = null
      this.updateGradeUsageMap()
    }
  }

  private handleChange() {
    this.$emit('input', this.internalRegistrationCapacities)
    this.$emit('change', this.internalRegistrationCapacities)
  }

  @Watch('value')
  private onValueChanged() {
    if (this.value) {
      if (this.internalRegistrationCapacities !== this.value) {
        this.internalRegistrationCapacities = this.value
        this.updateGradeUsageMap()
      }
    }
  }

  @Watch('grades', { immediate: true })
  private updateGradeRelatedVariables() {
    this.gradeIndex = new Map()
    if (this.grades) {
      for (let i = 0; i < this.grades.length; i++) {
        this.gradeIndex.set(this.grades[i].grade, i)
      }
    }
  }

  @Watch('internalRegistrationCapacities.length')
  private oninternalRegistrationCapacitiesChanged() {
    this.manageButtonTitle = `${this.defaultManageButtonTitle} ${
      this.internalRegistrationCapacities.length > 0
        ? '(' + this.internalRegistrationCapacities.length + ')'
        : ''
    }`
  }

  private updateGradeUsageMap() {
    if (this.grades) {
      this.gradeUsages = new Map()
      for (const gradeUsage of this.grades) {
        this.gradeUsages.set(gradeUsage.grade, gradeUsage)
      }

      for (const cap of this.internalRegistrationCapacities) {
        const startIndex: number | undefined = this.gradeIndex.get(cap.startTypeGradeID)
        const stopIndex: number | undefined = this.gradeIndex.get(cap.stopTypeGradeID)

        if (startIndex !== undefined && stopIndex !== undefined) {
          for (let j = startIndex; j <= stopIndex; j++) {
            const gradeId = this.grades[j]
            const gradeUsage = this.gradeUsages.get(gradeId.grade)
            if (gradeUsage) {
              gradeUsage.femaleUsed = gradeUsage.femaleUsed || cap.gender === 'F' || cap.gender === 'E'
              gradeUsage.maleUsed = gradeUsage.maleUsed || cap.gender === 'M' || cap.gender === 'E'
            }
          }
        }
      }
    }
  }

  private availableStartGrades(capacity: RegistrationCapacityByGradeGender): UpwardGradeTypeID[] {
    if (this.grades) {
      const gradeList = this.grades.map((g) => g.grade)
      const startGrades = gradeList.filter((item) => {
        const gradeUsage = this.gradeUsages.get(item)
        const gradeIsGreaterThenStopGrade =
          capacity.stopTypeGradeID &&
          this.gradeIndex.get(item)! > this.gradeIndex.get(capacity.stopTypeGradeID)!

        if (gradeIsGreaterThenStopGrade) {
          return false
        }

        const capacityGradeMatchesStartGrade =
          capacity &&
          capacity.startTypeGradeID &&
          gradeUsage &&
          gradeUsage.grade === capacity.startTypeGradeID

        if (capacityGradeMatchesStartGrade) {
          return true
        }

        const capacityGradeMatchesStopGrade =
          capacity && capacity.stopTypeGradeID && gradeUsage && gradeUsage.grade === capacity.stopTypeGradeID

        if (capacityGradeMatchesStopGrade) {
          return true
        }

        return gradeUsage && (!gradeUsage.femaleUsed || !gradeUsage.maleUsed)
      })
      return startGrades.map((x) => this.getUpwardGradeTypeID(x)!)
    }
    return []
  }

  private availableStopGrades(capacity: RegistrationCapacityByGradeGender): UpwardGradeTypeID[] {
    if (this.grades) {
      const gradeList = this.grades.map((g) => g.grade)
      const endGrades = gradeList.filter((item) => {
        const gradeUsage = this.gradeUsages.get(item)
        const gradeIsLessThenStartGrade =
          capacity.startTypeGradeID &&
          this.gradeIndex.get(item)! < this.gradeIndex.get(capacity.startTypeGradeID)!

        if (gradeIsLessThenStartGrade) {
          return false
        }

        const capacityGradeMatchesStartGrade =
          capacity &&
          capacity.startTypeGradeID &&
          gradeUsage &&
          gradeUsage.grade === capacity.startTypeGradeID

        if (capacityGradeMatchesStartGrade) {
          return true
        }

        const capacityGradeMatchesStopGrade =
          capacity && capacity.stopTypeGradeID && gradeUsage && gradeUsage.grade === capacity.stopTypeGradeID

        if (capacityGradeMatchesStopGrade) {
          return true
        }

        return gradeUsage && (!gradeUsage.femaleUsed || !gradeUsage.maleUsed)
      })

      return endGrades.map((x) => this.getUpwardGradeTypeID(x)!)
    }
    return []
  }

  private availableGenders(
    capacity: RegistrationCapacityByGradeGender
  ): Array<{ gender: string; id: string }> {
    if (capacity.gender === 'E' || !this.grades) {
      return [
        { gender: 'Male', id: 'M' },
        { gender: 'Female', id: 'F' },
        { gender: 'Both', id: 'E' },
      ]
    }

    const startIndex: number | undefined = this.gradeIndex.get(capacity.startTypeGradeID)
    const stopIndex: number | undefined = this.gradeIndex.get(capacity.stopTypeGradeID)

    let maleAvailable = true
    let femaleAvailable = true

    if (startIndex !== undefined && stopIndex !== undefined) {
      for (let j = startIndex; j <= stopIndex; j++) {
        const gradeId = this.grades[j]
        const gradeUsage = this.gradeUsages.get(gradeId.grade)
        if (gradeUsage) {
          if (gradeUsage.femaleUsed) {
            femaleAvailable = false
          }
          if (gradeUsage.maleUsed) {
            maleAvailable = false
          }
        }
      }
    }

    if (capacity.gender === 'F') {
      femaleAvailable = true
    }

    if (capacity.gender === 'M') {
      maleAvailable = true
    }

    const genders: Array<{ gender: string; id: string }> = []

    if (maleAvailable) {
      genders.push({ gender: 'Male', id: 'M' })
    }

    if (femaleAvailable) {
      genders.push({ gender: 'Female', id: 'F' })
    }

    if (femaleAvailable && maleAvailable) {
      genders.push({ gender: 'Both', id: 'E' })
    }
    return genders
  }
}
