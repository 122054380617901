
































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import InputLabel from '@/elements/InputLabel.vue'
import PhoneTypeSelect from '@/components/PhoneTypeSelect.vue'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import MaskedInputElement from '@/elements/MaskedInputElement.vue'

export default defineComponent({
  name: 'PhoneInput',
  components: {
    TextInput,
    InputLabel,
    PhoneTypeSelect,
    MaskedInputElement,
  },
  props: {
    value: { type: Object as PropType<IndividualPhone>, required: true },
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
  },
  setup(props, ctx) {
    const phoneTypeId = ref('')
    const extension = ref('')
    const areaCodeAndNumber = ref('')

    const newPhoneObject = computed(() => {
      const phone: IndividualPhone = {
        extension: extension.value,
        phoneNumber: areaCodeAndNumber.value,
        phoneID: props.value.phoneID,
        typePhoneID: phoneTypeId.value,
        priority: props.value.priority,
        allowSMS: props.value.allowSMS,
      }

      return phone
    })

    const isValid = computed(() => {
      return areaCodeAndNumber.value.length >= 10
    })

    function onChange() {
      // don't fire a change unless we have a complete object.
      if (isValid.value) {
        ctx.emit('input', newPhoneObject.value)
        ctx.emit('change', newPhoneObject.value)
      }
    }

    watch(
      () => props.value,
      () => {
        if (
          extension.value !== props.value.extension ||
          areaCodeAndNumber.value !== props.value.phoneNumber ||
          phoneTypeId.value !== props.value.typePhoneID
        ) {
          phoneTypeId.value = props.value.typePhoneID
          extension.value = props.value.extension ?? ''
          areaCodeAndNumber.value = props.value.phoneNumber.replace(/[()-]/g, '')
        }
      },
      { deep: true, immediate: true }
    )

    return {
      areaCodeAndNumber,
      extension,
      phoneTypeId,
      onChange,
    }
  },
})
