











































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'
import InputLabel from '@/elements/InputLabel.vue'
import TextInput from '@/elements/TextInput.vue'
import RangeSlider from '@/elements/RangeSlider.vue'
import Modal from '@/components/Modal.vue'
import GradeRangeSlider from '@/elements/GradeRangeSlider.vue'
import GradeRangeDropdowns from '@/elements/GradeRangeDropdowns.vue'
import store from '@/store'

interface ListItem {
  value: string
  text: string
}
export default defineComponent({
  name: 'Filters',
  components: {
    RadioGroupInput,
    CheckboxGroupInput,
    CheckboxInput,
    InputLabel,
    TextInput,
    RangeSlider,
    Modal,
    GradeRangeSlider,
    GradeRangeDropdowns,
  },
  props: {
    value: { type: Boolean, required: true, default: false },
  },
  setup(props, ctx) {
    const internalValue = ref(false)
    const {
      getFilterTarget,
      getGender,
      getHideLocked,
      getHeightRange,
      getScoreRange,
      getGradeRange,
      getHeightMinMax,
      getScoreMinMax,
      setGender,
      setFilterTarget,
      setHideLocked,
      setHeightRange,
      setScoreRange,
      setGradeRange,
      resetFilters,
      usingHeight,
      usingScore,
    } = userPreferences()
    const targets: ListItem[] = [
      { value: 'UNASSIGNED', text: 'Unassigned Participants' },
      { value: 'TEAMS', text: 'Teams' },
      { value: 'BOTH', text: 'Both' },
    ]
    const genders: ListItem[] = [
      { value: 'F', text: 'Girls' },
      { value: 'M', text: 'Boys' },
    ]

    const isByAge = store.getters.leagueAbstraction.isByAge

    function clear() {
      resetFilters()
      internalValue.value = false
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      },
      { immediate: true }
    )

    watch(
      () => internalValue.value,
      () => ctx.emit('input', internalValue.value)
    )

    return {
      targets,
      target: computed(() => getFilterTarget.value),
      genders,
      gender: computed(() => getGender.value),
      hideLocked: computed(() => getHideLocked.value),
      setGender,
      setFilterTarget,
      setHideLocked,
      setHeightRange,
      setScoreRange,
      setGradeRange,
      clear,
      height: computed(() => getHeightRange.value),
      heightRange: computed(() => getHeightMinMax.value),
      grades: computed(() => getGradeRange.value),
      score: computed(() => getScoreRange.value),
      scoreRange: computed(() => getScoreMinMax.value),
      showHeight: usingHeight,
      showScore: usingScore,
      internalValue,
      isByAge,
    }
  },
})
