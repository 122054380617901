/***
 * To add a feature flag, add it to the enum below.
 *
 * To exclude a feature from a build use the .env rules and set VUE_APP_FEATURES_EXCLUDED
 *
 * To use a feature flag add a setup function (e.g.
 * ```
 * @Component({
 *   setup(){ return({...useFeatureFlags()})}
 * }
 * {
 *   ...
 * }
 * ```
 * to test a feature flag after setup in a template do something like this:
 * ```
 * <div v-if="features.ADMIN">
 * ```
 *
 */

import { computed, Ref, ref } from '@vue/composition-api'
import featureClient from '@/clients/featureClient'
/**
 * Official List of what can and cannot be turned on.
 *
 * format is {{ section }} double underscore {{ feature name }}
 *
 */

export enum FeaturesEnum {
  ADMIN = 'ADMIN',
  COACH_APP = 'COACH_APP',
  COMPONENT_TEST = 'COMPONENT_TEST',
  DIVISIONS_TEAMS = 'DIVISIONS_TEAMS',
  FEATURES = 'FEATURES', //** the features menu item
  HOME__SEARCH_AND_NOTIFICATION = 'HOME__SEARCH_AND_NOTIFICATION',
  THE_AUTH_WALL__REDIRECT_TO_MOBILE = 'THE_AUTH_WALL__REDIRECT_TO_MOBILE',
  LEAGUE_ORDERS = 'LEAGUE_ORDERS',
  ORDERS__LEAGUE = 'ORDERS__LEAGUE', // orders menu, league item.
  PARTICIPANTS__BATCH_EVAL = 'PARTICIPANTS__BATCH_EVAL',
  PARTICIPANTS__CLONE = 'PARTICIPANTS__CLONE',
  VOLUNTEERS__COACH_INVITE = 'VOLUNTEERS__COACH_INVITE',
  REPORTS = 'REPORTS',
  RETURNS = 'RETURNS',
  SCHEDULING = 'SCHEDULING',
  TEAM_PAGES = 'TEAM_PAGES',
  GROUP_MESSAGES = 'GROUP_MESSAGES',
  OPTOUT = 'OPTOUT',
  COMMUNICATION_v1 = 'COMMUNICATION_v1',
  __UNSET__ = '__UNSET__', //** place holder for unset feature flags, don't use this value or you'll re-bootstrap features
}

type FeatureMap = Record<FeaturesEnum, boolean>

//** this will contain the things we want turned off, so a blacklist of strings from above.
let featuresExcludedArr: Ref<FeaturesEnum[]> | undefined

export function useFeatureFlags() {
  if (!featuresExcludedArr) {
    featuresExcludedArr = ref<FeaturesEnum[]>([FeaturesEnum.__UNSET__])
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    bootstrapFeatures()
  }
  function isFeatureInitialized() {
    return (featuresExcludedArr?.value ?? []).indexOf(FeaturesEnum.__UNSET__) >= 0
  }

  /**
   * Map of features with on off values that are reactive based on feature state.
   */
  const features = computed(() => {
    // @ts-ignore
    const v!: FeatureMap = {} as FeatureMap
    for (const i in FeaturesEnum) {
      v[i as FeaturesEnum] = isFeatureOn(i as FeaturesEnum)
    }
    return v as FeatureMap
  })

  /**
   * Tells you if the given feature is on, probably using the reactive
   * version (features.xyz) is more efficient.
   * @return {boolean} y/n
   * @param feature
   */
  function isFeatureOn(feature: FeaturesEnum) {
    let x = false

    if (isFeatureInitialized()) {
      bootstrapFeatures().then(() => {
        x = (featuresExcludedArr?.value ?? []).indexOf(feature) < 0
      })
    } else {
      x = (featuresExcludedArr?.value ?? []).indexOf(feature) < 0
    }
    return x
  }

  /**
   * Dynamically update the excluded features set.
   * @param {FeaturesEnum[]} features to exclude
   */
  function setExcludedFeatures(features: FeaturesEnum[]) {
    if (features && Array.isArray(features)) {
      if (isFeatureInitialized()) {
        bootstrapFeatures().then(() => {
          featuresExcludedArr!.value = features
        })
      } else {
        featuresExcludedArr!.value = features
      }
    }
  }

  /**
   * Load features, as said earlier, the .env file is considered
   * an override to the packages ./features-excluded.json
   * I would think of features-excluded as being things that are definitely
   * not ready for production and the .env file things you want to
   * keep on to test.
   */
  async function bootstrapFeatures() {
    if (process.env.VUE_APP_FEATURES_EXCLUDED) {
      try {
        featuresExcludedArr!.value = JSON.parse(process.env.VUE_APP_FEATURES_EXCLUDED)
      } catch (e) {
        console.warn(
          `Tried and couldn't parse FEATURES_EXCLUDED from .env "${process.env.VUE_APP_FEATURES_EXCLUDED}" Leave it blank if you don't intend to use it. `
        )
        throw e
      }
    } else {
      try {
        featuresExcludedArr!.value = await featureClient.getExcludedFeatures()
      } catch (e) {
        console.warn(
          `Tried and couldn't parse ./features-excluded.json. Leave a blank array "[]" in the file for every feature on.`
        )
        throw e
      }
    }
  }
  return {
    setExcludedFeatures,
    isFeatureOn,
    bootstrapFeatures,
    featuresExcluded: computed(() => featuresExcludedArr!.value),
    features,
    /**
     * List of features that are considered valid to ask about.
     */
    featuresValid: computed(() => FeaturesEnum),
  }
}
