/**
 * Grab the features-excluded.json -- later we can make this way more complicated
 * We expect to see a JSON array of feature flags of excluded features.
 */
const getExcludedFeatures = async function getExcludedFeatures() {
  const f = await fetch('/features-excluded.json')
  if (f && f.ok) {
    return await f.json()
  }
}

export default { getExcludedFeatures }
