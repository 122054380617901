

























































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import InputLabel from '@/elements/InputLabel.vue'
import uuid from 'uuid'
import { ComponentArgumentRuntimeException } from '@/lib/components/exceptions/ComponentArgumentRuntimeException'
import { getProps, useSelectList } from '@/composables/SelectListProperties'

export default defineComponent({
  name: 'SelectInput',
  components: {
    ValidationProvider,
    InputLabel,
  },
  props: {
    label: { type: String, default: '', required: false },
    tabIndex: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    enabled: { type: Boolean, default: true, required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    id: { type: String, default: '', required: false },
    value: { default: null, required: true },
    blankItemText: { type: String, default: '', required: false },
    blankItemValue: { default: null, required: false },
    showBlankItem: { type: Boolean, default: false, required: false },
    name: { type: String, default: '', required: false },
    subLabel: { type: String, default: '', required: false },
    subLabelInline: { type: Boolean, default: false, required: false },
    VeeValidateRules: { type: String, default: '', required: false },
    ...getProps(),
  },
  setup(props, ctx) {
    const { getOptionValue, getOptionKey, getOptionText } = useSelectList(props)
    const guid = ref(uuid.v4())
    const internalValue = ref<any | null>(props.value)

    const elementId = computed(() => props.id || `select-${guid.value}`)

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        if (internalValue.value !== props.value) {
          internalValue.value = props.value
        }
      },
      { deep: true, immediate: true }
    )

    function assertItemListType() {
      if (!Array.isArray(props.itemsList) && !(props.itemsList instanceof Object)) {
        throw new ComponentArgumentRuntimeException(
          `Select Input ${props.name || props.label} should have an item list of type Array or Object`
        )
      }
    }

    watch(
      () => props.itemsList,
      () => {
        assertItemListType()
      },
      { immediate: true }
    )

    return {
      internalValue,
      elementId,
      handleChange,
      getOptionValue,
      getOptionKey,
      getOptionText,
    }
  },
})
