



























































import { defineComponent, computed, PropType } from '@vue/composition-api'

import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'
import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { UpwardPositionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPositionTypeID'
import { getProperGradeLabel } from '@/services/gradeService'

export default defineComponent({
  name: 'ParticipantListElement',
  props: {
    participant: { type: Object as PropType<LeaguePlayerInfo>, required: true },
    selectedPlayerID: { type: Number, required: false, default: 0 },
  },
  setup(props, ctx) {
    const { getColumns, getIsByAge } = userPreferences()
    const carPoolLink = computed(() => {
      const p = props.participant
      if (p.actualCarpoolLinkLastName) {
        return `${p.actualCarpoolLinkFirstName} ${p.actualCarpoolLinkLastName}`
      }
      return ''
    })

    const coachLink = computed(() => {
      const p = props.participant
      if (p.actualCoachLinkLastName) {
        return `${p.actualCoachLinkFirstName} ${p.actualCoachLinkLastName}`
      }
      if (p.pendingCoachLinkLastName) {
        return `${p.pendingCoachLinkFirstName} ${p.pendingCoachLinkLastName} (pending)`
      }
      return ''
    })
    const churchName = computed(() => {
      return props.participant.churchName
    })
    const generalNotes = computed(() => {
      const g = props.participant.generalNotes
      return g
    })
    const practiceNights = computed(() => {
      return props.participant?.practiceNightExclusions
        ?.map((x) => x.typeDayID ?? '')
        .map((x) => days.find((y) => y.name == x)?.abbr3)
        ?.join(',')
    })
    const sportUsesHeight = computed(() => {
      return (
        props.participant?.typeProgramID == 'BASKETBALL' || props.participant?.typeProgramID == 'VOLLEYBALL'
      )
    })

    const isLeague = computed(() => {
      return store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    })

    const isSport = computed(() => {
      return (
        props.participant?.typeProgramID != 'BASKETBALLCHEERLEADING' &&
        props.participant?.typeProgramID != 'FOOTBALLCHEERLEADING'
      )
    })

    const positionType = computed(() => {
      return store.getters.positionTypes.allItems.find(
        (x: UpwardPositionTypeID) => x.typeProgramID == props.participant.typeProgramID
      )
    })

    const typeGradeIDValue = computed(() =>
      getProperGradeLabel(props.participant.typeGradeID!, getIsByAge.value, '')
    )

    const showPositionScore = computed(() => {
      return !!positionType.value
    })

    const positionScoreLabel = computed(() => {
      return !!positionType.value ? `${positionType.value.description} Score` : ''
    })

    const showScore = computed(() => getColumns.value.find((c) => c.id == 'score')?.value)
    const showExperience = computed(() => getColumns.value.find((c) => c.id == 'experience')?.value)
    const showGrade = computed(() => getColumns.value.find((c) => c.id == 'grade')?.value)
    const showGender = computed(() => getColumns.value.find((c) => c.id == 'gender')?.value)
    const showHeight = computed(() => getColumns.value.find((c) => c.id == 'height')?.value)
    const showExclude = computed(() => getColumns.value.find((c) => c.id == 'cannot practice on')?.value)
    const showCarpool = computed(() => getColumns.value.find((c) => c.id == 'carpool link')?.value)
    const showCoach = computed(() => getColumns.value.find((c) => c.id == 'coach link')?.value)
    const showNotes = computed(() => getColumns.value.find((c) => c.id == 'player notes')?.value)
    const showChurch = computed(() => getColumns.value.find((c) => c.id == 'church')?.value)

    const isSelected = computed(() => props.participant.individualID == props.selectedPlayerID)

    function selectPlayer() {
      ctx.emit('selectPlayer', props.participant)
    }

    return {
      carPoolLink,
      coachLink,
      practiceNights,
      sportUsesHeight,
      isSport,
      isByAge: computed(() => getIsByAge.value),
      isUpwardSelect: store.getters.leagueAbstraction.isUpwardSelect,
      showScore,
      showExperience,
      showGrade,
      showGender,
      showHeight,
      showExclude,
      showCarpool,
      showCoach,
      showNotes,
      showChurch,
      isLeague,
      churchName,
      generalNotes,
      isSelected,
      selectPlayer,
      showPositionScore,
      positionScoreLabel,
      typeGradeIDValue,
    }
  },
})
