









































import { RegistrationTransactionParticipantExtended } from '@/models/Registration/RegistrationTransactionParticipantExt'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import TextInput from '@/elements/TextInput.vue'
import Modal from '@/components/Modal.vue'
import { doWhatType, RefundRemoveTransactionType } from '@/models/Registration/RefundRemoveTransactionType'

export default defineComponent({
  components: {
    TextInput,
    SelectInput,
    Modal,
  },
  props: {
    registration: {
      type: Object as PropType<RegistrationTransactionParticipantExtended>,
    },
    showing: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, ctx) {
    function markComplete() {
      alert('hi')
    }

    const internalShowing = ref(false)
    watch(
      () => props.showing,
      () => (internalShowing.value = props.showing),
      { immediate: true }
    )

    const areWeReadyToConfirm = computed(() => doWhat.value != undefined && !!reason.value)
    function confirmed() {
      const args = {
        type: doWhatType.REFUND,
        reason: reason.value,
        transaction: props.registration,
      } as RefundRemoveTransactionType
      if (doWhat.value == doWhatType.REFUND) {
        ctx.emit('refund', args)
      } else {
        ctx.emit('remove', { ...args, type: doWhatType.REMOVE })
      }
    }
    function cancel() {
      ctx.emit('cancel')
      internalShowing.value = false
    }

    const doWhat = ref(doWhatType.REFUND)

    const reason = ref('')
    return {
      markComplete,
      doWhatList: [
        { id: doWhatType.REFUND, label: 'Refund' },
        { id: doWhatType.REMOVE, label: 'Remove' },
      ],
      doWhat,
      reason,

      confirmed,
      internalShowing,
      areWeReadyToConfirm,
      cancel,
    }
  },
})
