import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardReturnReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardReturnReasonTypeID'
import { defineActions } from 'direct-vuex'

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardReturnReasonTypeID>(
  upwardTypesClient.retrieveReturnReasonTypes
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardReturnReasonTypeID> = {
  items: [],
}

const getterTree: GetterTree<genericUpwardTypes.GenericTypeState<UpwardReturnReasonTypeID>, RootState> = {
  ...generic.getterTree,
}

const mutations: MutationTree<genericUpwardTypes.GenericTypeState<UpwardReturnReasonTypeID>> = {
  ...generic.mutations,
}

const actions = defineActions({
  ...generic.actions,
})

export const getterNames = {
  ...genericUpwardTypes.getterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

export const namespace = 'returnReasonTypes'

export const returnReasonTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
