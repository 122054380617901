import { computed, ref } from '@vue/composition-api'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import teamsClient from '@/clients/teamsClient'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import { LeagueCoachProgramInfoFLATToTeamCoachInfo } from '@/lib/support/models/ListInfo/TeamCoachInfo/LeagueCoachProgramInfoFLATToTeamCoachInfo'
import { cloneDeep } from 'lodash'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { LeaguePlayerInfoToTeamPlayerInfo } from '@/lib/support/models/ListInfo/TeamPlayerInfo/LeaguePlayerInfoToTeamPlayerInfo'
import { TapeDownReasonEnum } from '@/lib/support/models/ListInfo/TeamPlayerInfo/data'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { TeamPlayer } from '@/GeneratedTypes/TeamPlayer'
import { UpwardPositionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPositionTypeID'
import store from '@/store'

interface TeamListState {
  lastLeagueID: string
  lastDivisionID: number
  lastProgramID: string
}

/**
 * Small team manager, state is a set of teams, manipulated with the exposed values
 */
function useTeamsRO() {
  const teams = ref<DivisionTeamInfo[]>([])
  const loading = ref(false)
  const operationProgress = ref('')
  const teamStructureChanging = ref(false)

  async function unassignDivisionParticipants(teams: DivisionTeamInfo[], unassignLocked: boolean) {
    try {
      teamStructureChanging.value = true
      let i = 0
      const teamCount = teams.length
      operationProgress.value = 'Clearing... 0%'
      if (teamCount) {
        for (const t of teams) {
          i++
          operationProgress.value = `Clearing... ${Math.floor((i * 100) / teamCount)}%`

          const team = await teamsClient.getTeam(
            t.upwardLeagueID ?? '',
            t.typeProgramID ?? '',
            t.divisionID,
            t.teamID
          )
          team.players = unassignLocked ? [] : lockedPlayers(t.players)
          await teamsClient.saveTeam({ team, leagueId: t.upwardLeagueID ?? '' })
        }
        operationProgress.value = 'Complete'
      }
    } catch (exc) {
      throw new Error(`unable to clear participants form teams. ${exc.message}`)
    } finally {
      teamStructureChanging.value = false
    }
  }

  function lockedPlayers(players: TeamPlayerInfo[] | null): TeamPlayer[] {
    if (!players) return []
    //get locked players
    const unlocked = players.filter((p) => p.typeTapeDownReasonID == 'LOCKEDTOTEAM')
    //convert from TeamPlayerInfo to TeamPlayer
    return unlocked.map((u) => {
      return {
        playerIndividualID: u.individualID,
        typePositionID: u.typePositionID,
        typeTapeDownReasonID: u.typeTapeDownReasonID,
        manualAssignment: u.manualAssignment,
        draftNumber: u.draftNumber,
        rosterRanking: u.rosterRanking,
        draftExceptions: u.draftExceptions,
      } as TeamPlayer
    })
  }

  async function refreshTeams(leagueID: string, divisionID: number, programID: string) {
    loading.value = true
    teams.value = await teamsClient.retrieveTeamList({
      leagueId: leagueID,
      divisionId: divisionID,
      typeProgramId: programID,
    })
    loading.value = false
  }

  /**
   * Adds the given coach to the given team.
   * @param teamID
   * @param coach
   * @return - returns if we will make coach a head coach
   */
  function addCoachToTeam(teamID: number, coach: LeagueCoachProgramInfoFLAT) {
    const team = teams.value.find((t) => t.teamID == teamID)
    const hasHeadCoach = team?.coaches?.some((y) => y.headCoach)
    addCoachToLocalState(teamID, coach, teams.value)
    return !hasHeadCoach
  }

  /**
   * Maintain the local state of teams so that the user's
   * experience isn't deteriorated by a refresh with each
   * drag/drop
   */

  function addCoachToLocalState(
    teamID: number,
    coach: LeagueCoachProgramInfoFLAT,
    teamList: DivisionTeamInfo[]
  ) {
    const team = teamList.find((t) => t.teamID == teamID)
    const teamIdx = teamList.findIndex((x) => x.teamID == teamID)
    const hasHeadCoach = team?.coaches?.some((y) => y.headCoach)
    const newteam = cloneDeep(teamList[teamIdx])

    if (teamIdx >= 0) {
      return teamList.splice(teamIdx, 1, {
        ...newteam,
        coaches: [
          ...(newteam?.coaches?.filter((x) => x.individualID != coach.individualID) ?? []),
          { ...LeagueCoachProgramInfoFLATToTeamCoachInfo(coach), headCoach: !hasHeadCoach },
        ],
      })
    }
    return [] as DivisionTeamInfo[]
  }

  /**
   * Quick utility triggers an update event on the team list.
   * @param team
   */
  function updateTeam(team: DivisionTeamInfo) {
    const index = teams.value.findIndex((x) => x.teamID == team.teamID)
    if (index >= 0) {
      teams.value.splice(index, 1, cloneDeep(team))
    }
  }

  function addParticipantToTeam(teamID: number, player: LeaguePlayerInfo) {
    const team = teams.value.find((x) => x.teamID == teamID)

    if (team) {
      const p = LeaguePlayerInfoToTeamPlayerInfo(player)
      p.teamID = teamID
      updateTeam({
        ...team,
        players: [...(team?.players?.filter((x) => x.individualID != player.individualID) ?? []), { ...p }],
      })
    }
  }

  function removeCoach(teamID: number, coachID: number) {
    removeLocalCoach(teamID, coachID, teams.value)
  }

  /**
   * Maintain the local state of teams so that the user's
   * experience isn't deteriorated by a refresh with each
   * drag/drop
   */
  function removeLocalCoach(teamID: number, coachID: number, teamList: DivisionTeamInfo[]) {
    const team = teamList.find((x) => x.teamID == teamID)
    if (team) {
      const newTeam = { ...team, coaches: team?.coaches?.filter((x) => x.individualID != coachID) ?? [] }
      const index = teamList.findIndex((x) => x.teamID == team.teamID)
      if (index >= 0) {
        teamList.splice(index, 1, cloneDeep(newTeam))
      }
    }
  }

  function removeParticipant(teamID: number, participantID: number) {
    const team = teams.value.find((x) => x.teamID == teamID)
    if (team) {
      updateTeam({ ...team, players: team?.players?.filter((x) => x.individualID != participantID) ?? [] })
    }
  }

  function changeHeadCoach(teamID: number, coachID: number) {
    const team = teams.value.find((x) => x.teamID == teamID)
    if (team) {
      updateTeam({
        ...team,
        coaches: team?.coaches?.map((x) => ({ ...x, headCoach: x.individualID == coachID })) ?? [],
      })
    }
  }

  function clearOperationProgress() {
    operationProgress.value = ''
  }

  /***
   * Toggles our player lock, changes state
   * @param teamID
   * @param playerID
   * @return {boolean} new state of lock
   */
  function togglePlayerLock(teamID: number, playerID: number) {
    let newState = false
    const team = teams.value.find((x) => x.teamID == teamID)
    if (team) {
      const player = team.players?.find((x) => x.individualID == playerID)
      if (player) {
        player.typeTapeDownReasonID =
          player.typeTapeDownReasonID == TapeDownReasonEnum.LOCKED_TO_TEAM
            ? ''
            : TapeDownReasonEnum.LOCKED_TO_TEAM
        newState = !!(player.typeTapeDownReasonID == TapeDownReasonEnum.LOCKED_TO_TEAM)
      }
      updateTeam(team)
    }
    return newState
  }

  /***
   * Toggles our player position, changes state
   * @param teamID
   * @param playerID
   * @return {string} new state of position
   */
  function togglePlayerPosition(teamID: number, playerID: number) {
    let newState = ''
    const team = teams.value.find((x) => x.teamID == teamID)
    if (team) {
      const posType = store.getters.positionTypes.allItems.find(
        (x: UpwardPositionTypeID) => x.typeProgramID == team.typeProgramID
      )
      const player = team.players?.find((x) => x.individualID == playerID)
      if (player && posType) {
        player.typePositionID = player.typePositionID == posType.upwardTypeID ? '' : posType.upwardTypeID
        newState = player.typePositionID ?? ''
      }
      updateTeam(team)
    }
    return newState
  }

  return {
    refreshTeams,
    addCoachToTeam,
    removeCoach,
    changeHeadCoach,
    addParticipantToTeam,
    togglePlayerLock,
    togglePlayerPosition,
    removeParticipant,
    updateTeam,
    unassignDivisionParticipants,
    clearOperationProgress,
    loading: computed(() => loading.value),
    operationProgress,
    teams,
    teamStructureChanging: computed(() => teamStructureChanging.value),
  }
}

/**
 * Adds read/write API functions
 */
export function useTeams() {
  const state: TeamListState = { lastDivisionID: 0, lastLeagueID: '', lastProgramID: '' }
  const manager = useTeamsRO()
  const teamStructureChanging = ref(false)

  function saveTeam(teamID: number) {
    return cloneDeep(manager.teams.value.find((x) => x.teamID == teamID))
  }

  async function refreshTeams(leagueID: string, divisionID: number, programID: string) {
    await manager.refreshTeams(leagueID, divisionID, programID)

    state.lastProgramID = programID
    state.lastLeagueID = leagueID
    state.lastDivisionID = divisionID
  }

  async function addCoachToTeam(teamID: number, coach: LeagueCoachProgramInfoFLAT) {
    teamStructureChanging.value = true
    const team = saveTeam(teamID)
    const isHead = manager.addCoachToTeam(teamID, coach)
    try {
      await teamsClient.addCoachToTeam(
        state.lastLeagueID,
        state.lastProgramID,
        state.lastDivisionID,
        teamID,
        coach.individualID,
        isHead
      )
    } catch (e) {
      team && manager.updateTeam(team)
      throw e
    } finally {
      teamStructureChanging.value = false
    }
  }

  async function addParticipantToTeam(teamID: number, player: LeaguePlayerInfo) {
    teamStructureChanging.value = true
    const team = saveTeam(teamID)
    manager.addParticipantToTeam(teamID, player)
    try {
      await teamsClient.addPlayerToTeam(
        state.lastLeagueID,
        state.lastProgramID,
        state.lastDivisionID,
        teamID,
        player.individualID,
        false
      )
    } catch (e) {
      team && manager.updateTeam(team)
      throw e
    } finally {
      teamStructureChanging.value = false
    }
  }

  async function removeCoach(teamID: number, coachID: number) {
    teamStructureChanging.value = true
    const team = saveTeam(teamID)
    manager.removeCoach(teamID, coachID)
    try {
      await teamsClient.removeCoachFromTeam(
        state.lastLeagueID,
        state.lastProgramID,
        state.lastDivisionID,
        teamID,
        coachID
      )
    } catch (e) {
      team && manager.updateTeam(team)
      throw e
    } finally {
      teamStructureChanging.value = false
    }
  }

  async function removeParticipant(teamID: number, participantID: number) {
    teamStructureChanging.value = true
    const team = saveTeam(teamID)
    manager.removeParticipant(teamID, participantID)
    try {
      await teamsClient.removePlayerFromTeam(
        state.lastLeagueID,
        state.lastProgramID,
        state.lastDivisionID,
        teamID,
        participantID
      )
    } catch (e) {
      team && manager.updateTeam(team)
      throw e
    } finally {
      teamStructureChanging.value = false
    }
  }

  async function changeHeadCoach(teamID: number, coachID: number) {
    const team = saveTeam(teamID)
    manager.changeHeadCoach(teamID, coachID)
    try {
      await teamsClient.makeCoachHeadCoach(
        state.lastLeagueID,
        state.lastProgramID,
        state.lastDivisionID,
        teamID,
        coachID
      )
    } catch (e) {
      team && manager.updateTeam(team)
      throw e
    }
  }

  async function togglePlayerLock(teamID: number, playerID: number) {
    teamStructureChanging.value = true
    const team = saveTeam(teamID)
    const player = team?.players?.find((x) => x.individualID == playerID)
    const newLockState = manager.togglePlayerLock(teamID, playerID)
    try {
      await teamsClient.updatePlayerOnTeam(
        state.lastLeagueID,
        state.lastProgramID,
        state.lastDivisionID,
        teamID,
        playerID,
        newLockState,
        player?.typePositionID ?? ''
      )
    } catch (e) {
      team && manager.updateTeam(team)
      throw e
    } finally {
      teamStructureChanging.value = false
    }
  }

  async function togglePlayerPosition(teamID: number, playerID: number) {
    teamStructureChanging.value = true
    const team = saveTeam(teamID)
    const player = team?.players?.find((x) => x.individualID == playerID)
    const newPositionState = manager.togglePlayerPosition(teamID, playerID)
    try {
      await teamsClient.updatePlayerOnTeam(
        state.lastLeagueID,
        state.lastProgramID,
        state.lastDivisionID,
        teamID,
        playerID,
        !!((player?.typeTapeDownReasonID ?? '') == TapeDownReasonEnum.LOCKED_TO_TEAM),
        newPositionState
      )
    } catch (e) {
      team && manager.updateTeam(team)
      throw e
    } finally {
      teamStructureChanging.value = false
    }
  }

  return {
    refreshTeams,
    addCoachToTeam,
    removeCoach,
    changeHeadCoach,
    addParticipantToTeam,
    togglePlayerLock,
    togglePlayerPosition,
    removeParticipant,
    unassignAllDivisionParticipants: manager.unassignDivisionParticipants,
    loading: manager.loading,
    teams: manager.teams,
    operationProgress: manager.operationProgress,
    clearOperationProgress: manager.clearOperationProgress,
    teamStructureChanging: computed(() => teamStructureChanging.value || manager.teamStructureChanging.value),
  }
}
