
















import { defineComponent, PropType, ref } from '@vue/composition-api'
import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'
import { cloneDeep } from 'lodash'

export interface FilterTabType {
  id: string
  description: string
  selected: boolean
}

export default defineComponent({
  name: 'HorizontalTabFilter',
  components: { CheckboxGroupInput },
  props: {
    filters: { type: Array as PropType<FilterTabType[]>, required: true },
    label: { type: String, required: true, default: '' },
  },
  setup(props, ctx) {
    const internalFilters = cloneDeep(props.filters)
    const filtersToShow = ref<string[]>(props.filters.map((x) => x.id))

    function setFiltersToShow(selectedFilters: string[]) {
      filtersToShow.value = selectedFilters
      ctx.emit('filter-update', selectedFilters)
    }

    return {
      internalFilters,
      filtersToShow,
      setFiltersToShow,
    }
  },
})
