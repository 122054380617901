import { DivisionGame } from '@/GeneratedTypes/DivisionGame'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'

export function DivisionGameInfoToDivisionGame(g: DivisionGameInfo): DivisionGame {
  return {
    awayTeamID: g.awayTeamID,
    divisionID: g.divisionID,
    facilityID: g.facilityID,
    gameEnd: g.gameEnd,
    gameID: g.gameID,
    gameStart: g.gameStart,
    homeTeamID: g.homeTeamID,
    leagueID: g.leagueID,
    roundNumber: g.roundNumber,
    typeProgramID: g.typeProgramID ?? '',
    volunteers: g.volunteers,
    winningTeamID: g.winningTeamID,
    homeTeamScore: g.homeTeamScore,
    awayTeamScore: g.awayTeamScore,
    typeDivisionGameID: g.typeDivisionGameID,
  }
}
