import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import { LeagueTypeGradeInfo } from 'src/GeneratedTypes/ListInfo/LeagueTypeGradeInfo'
import { defineActions } from 'direct-vuex'

export enum customGetterNames {
  getTypeIDByAge = 'getTypeIDByAge',
}

export enum customMutationNames {
  updateDescriptionsFromLeague = 'updateDescriptionsFromLeague',
}

export const getterNames = {
  ...genericUpwardTypes.getterNames,
  ...customGetterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
  ...customMutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardGradeTypeID>(
  upwardTypesClient.retrieveGradeTypes
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardGradeTypeID> = {
  items: [],
}

const getterTree: GetterTree<genericUpwardTypes.GenericTypeState<UpwardGradeTypeID>, RootState> = {
  ...generic.getterTree,
  [getterNames.getTypeIDByAge]: (state) => (age: number) =>
    state.items.find((x) => x.ageByCutoff == age) ?? null,
}

const customMutations: MutationTree<genericUpwardTypes.GenericTypeState<UpwardGradeTypeID>> = {
  [mutationNames.updateDescriptionsFromLeague](
    state,
    { descriptions }: { descriptions: LeagueTypeGradeInfo[] }
  ): void {
    const grades: UpwardGradeTypeID[] = state.items
    if (grades.length == descriptions.length) {
      descriptions.forEach((x) => {
        const grade = grades.find((g) => g.upwardTypeID == x.upwardTypeID)
        if (grade) {
          grade.description = x.description
          grade.alternateDescription = x.alternateDescription
        } else {
          throw new Error('League/Grade Description mismatch')
        }
      })
    } else {
      throw new Error('League/Grade Description length mismatch')
    }
  },
}

const mutations: MutationTree<genericUpwardTypes.GenericTypeState<UpwardGradeTypeID>> = {
  ...generic.mutations,
  ...customMutations,
}

const actions = defineActions({
  ...generic.actions,
})

export const namespace = 'gradeTypes'

export const gradeTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
