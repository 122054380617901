export enum DisplayType {
  'MONTH' = 'month',
  'WEEK' = 'week',
  'DAY' = 'day',
  'CUSTOM_WEELKLY' = 'custom-weekly',
  'CUSTOM_DAILY' = 'custom-daily',
  'CATEGORY' = 'category',
}

export enum OverlapMode {
  STACK = 'stack',
  COLUMN = 'column',
}
