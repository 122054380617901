









import { defineComponent, PropType } from '@vue/composition-api'
import { Container, Draggable, DropResult } from 'vue-smooth-dnd'
import { CalendarData } from '@/elements/Calendar/types'

export default defineComponent({
  name: 'CalendarableEvents',
  components: { Container, Draggable },
  props: {
    groupName: { type: String, required: true },
    events: {
      type: Array as PropType<CalendarData[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const getPayload = (index: number) => {
      return props.events[index]
    }
    function onDrop(movedEvent: DropResult) {
      ctx.emit('dropped', getPayload(movedEvent.removedIndex))
    }

    return { getPayload, onDrop }
  },
})
