export interface StartingSelection {
  leagueTypeId: number | null
  opportunityTypeId: number | null
  upwardTypeId: string
  name: string
}

export function getEmptyStartingSelection(): StartingSelection {
  return {
    leagueTypeId: null,
    opportunityTypeId: null,
    upwardTypeId: '',
    name: '',
  }
}
