import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'

export function LeaguePlayerInfoToTeamPlayerInfo(a: LeaguePlayerInfo): TeamPlayerInfo {
  return <TeamPlayerInfo>{
    evaluationScore: a.evaluationScore,
    addresses: a.addresses,
    athleticScore: a.athleticScore,
    birthDate: a.birthDate,
    churchName: a.churchName,
    divisionID: 0,
    divisionName: null,
    teamName: null,
    draftNumber: 0,
    rosterRanking: '',
    coachAssignedJerseyNumber: null,
    emailAddresses: a.emailAddresses,
    extraScore: a.extraScore,
    firstName: a.firstName,
    formattedNameFirstLast: a.formattedNameFirstLast,
    formattedNameLastFirst: a.formattedNameLastFirst,
    formattedNamePrivate: a.formattedNamePrivate,
    gender: a.gender,
    grade: a.typeGradeID,
    individualID: a.individualID,
    lastName: a.lastName,
    leagueID: a.leagueID,
    manualAssignment: false,
    middleInitial: '',
    phoneNumbers: a.phoneNumbers,
    positionScore: a.positionScore,
    teamID: 0,
    thirdPartyClientIDs: [],
    typePositionID: '',
    typeProgramID: '',
    typeTapeDownReasonID: '',
    draftExceptions: [],
    contacts: [],
    yearsExperience: 0,
    typeGradeID: a.typeGradeID,
  }
}
