






















































































import { defineComponent, ref, watch, PropType, onMounted } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'
import { GridColumn } from '../models/Grid/GridColumn'
import { SFTemplateType } from '@/models/SyncFusionTypes'
import { ComponentArgumentRuntimeException } from '@/lib/components/exceptions/ComponentArgumentRuntimeException'
import { EditIconCssClass, DeleteIconCssClass } from '@/elements/Icons'

export default defineComponent({
  name: 'SimpleGrid',
  components: {
    InputLabel,
  },
  methods: {
    getColumnComponent: (data: SFTemplateType) => {
      const c = data()
      return c?.template
    },
  },
  props: {
    /**
     * Original interface property
     */
    columns: { type: Array as PropType<GridColumn[]>, required: false },
    /**
     * Original interface property
     */
    gridData: { type: Array as PropType<Record<symbol | string, unknown>[] | null>, required: false },
    /**
     * Not sure why these names are reversed, but try to keep a synonymous interface with grid.
     * this is a pass through with the watch below to the internal property
     */
    gridColumns: { type: Array as PropType<GridColumn[]>, required: false },
    /**
     * Again provides a synonymous interface as Grid.vue
     * this is a pass through with the watch below to the internal property
     */
    data: { type: Array as PropType<Record<symbol | string, unknown>[] | null>, required: false },
    showEditButton: { type: Boolean, required: false, default: false },
    showDeleteButton: { type: Boolean, required: false, default: false },
    showDeleteButtonHeader: { type: Boolean, required: false, default: false },
    showEditButtonHeader: { type: Boolean, required: false, default: false },
    editButtonInnerHtml: { type: String, required: false, default: `<i class="${EditIconCssClass}"></i>` },
    deleteButtonInnerHtml: {
      type: String,
      required: false,
      default: `<i class="${DeleteIconCssClass}"></i>`,
    },
    deleteButtonClasses: { type: String, required: false, default: 'btn btn-light btn-sm' },
    editButtonClasses: { type: String, required: false, default: 'btn btn-light btn-sm' },
    tableStriped: { type: Boolean, required: false, default: false },
    disableHeaderSort: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const internalData = ref<Record<symbol | string, unknown>[] | null>(null)
    const internalColumns = ref<GridColumn[] | null>(null)

    /**
     * @param column
     * @private
     */
    function calcwidth(column: GridColumn) {
      if (column.width) {
        if (Number.isInteger(column.width)) {
          return `width:${column.width}px;`
        } else {
          return 'width:${column.width};'
        }
      }
    }

    function alignment(column: GridColumn) {
      return column?.textAlign?.toLowerCase() ?? ''
    }

    function handleEditClicked(entity: Record<symbol | string, unknown>) {
      ctx.emit('editButtonClicked', entity)
    }

    function handleDeleteClicked(entity: Record<symbol | string, unknown>) {
      ctx.emit('deleteButtonClicked', entity)
    }

    const selectedIndex = ref(-1)

    function handleRowClicked(index: number, entity: Record<symbol | string, unknown>, eventName: string) {
      //eventName: rowClicked or rowDoubleClicked
      ctx.emit(eventName, entity)
      selectedIndex.value = index
    }

    onMounted(() => {
      internalColumns.value = props.columns || props.gridColumns || []
      internalData.value = props.data || props.gridData || []

      if (!internalColumns.value) {
        throw new ComponentArgumentRuntimeException(
          'SimpleGrid specify the columns property (as a blank array at least)'
        )
      }
      if (!internalData.value) {
        throw new ComponentArgumentRuntimeException(
          'SimpleGrid specify the data property (as a blank array at least)'
        )
      }
    })

    const bit = ref(1)
    const sortColumn = ref('')

    function headerClick(column: GridColumn) {
      if (props.disableHeaderSort || !internalData.value) return
      sortColumn.value = column.field ?? ''
      internalData.value.sort((a, b) => {
        const val1 = resolvePath(a, sortColumn.value, '')
        const val2 = resolvePath(b, sortColumn.value, '')
        return val1 > val2 ? bit.value * -1 : bit.value * 1
      })
      bit.value = bit.value * -1
    }

    function resolvePath(object: object, path: string, defaultValue: string) {
      return path
        .split(/[\.\[\]\'\"]/)
        .filter((p: any) => p)
        .reduce((o: any, p: any) => (o ? o[p] : defaultValue), object)
    }

    watch(
      () => props.columns,
      () => {
        internalColumns.value = [...(props.columns ?? [])]
      }
    )

    watch(
      () => props.gridColumns,
      () => {
        internalColumns.value = [...(props.gridColumns ?? [])]
      }
    )

    watch(
      () => props.data,
      () => {
        internalData.value = [...(props.data ?? [])]
      }
    )

    watch(
      () => props.gridData,
      () => {
        internalData.value = [...(props.gridData ?? [])]
      }
    )

    return {
      internalColumns,
      headerClick,
      internalData,
      handleRowClicked,
      alignment,
      resolvePath,
      handleEditClicked,
      handleDeleteClicked,
      calcwidth,
      selectedIndex,
      sortColumn,
    }
  },
})
