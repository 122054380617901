import restService from '@/services/restService'
import { Connect } from '@/GeneratedTypes/Connect'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'

const baseUrl = 'connects/'

const create = async (connect: Connect) => {
  return await restService.post<Connect>(`${baseUrl}`, connect)
}

const update = async (id: string, connect: Connect) => {
  return await restService.put<Connect>(`${baseUrl}${id}`, connect)
}

const retrieve = async (id: string) => {
  return await restService.get<Connect>(`${baseUrl}${id}`)
}

const validateNew = async (connect: Connect, ruleSet = '') => {
  return await restService.post<UpwardVerificationDetails>(`${baseUrl}validate`, connect, {
    params: {
      ruleSet,
    },
  })
}

const validateExisting = async (id: string, connect: Connect) => {
  return await restService.post<UpwardVerificationDetails>(`${baseUrl}${id}/validate`, connect)
}

const newTemplate = async (upwardLeagueTypeID: string) => {
  return await restService.get<Connect>(`${baseUrl}new/${upwardLeagueTypeID}`)
}

export default {
  create,
  retrieve,
  update,
  validateNew,
  validateExisting,
  newTemplate,
}
