import { GridColumn } from '@/models/Grid/GridColumn'

export function downloadCSV(columns: GridColumn[], data: { [key: string]: any }, fileName: string) {
  const csvData =
    'data:text/csv;charset=utf-8,' +
    columns.map((x) => `"${x.headerText}"`).join(',') +
    '\n' +
    data
      .map((x: { [key: string]: any }) =>
        columns
          .map((y) => {
            const key = y.field ?? ''
            return `"${x[key]}"`
          })
          .join(',')
      )
      .join('\n')

  const encodedUri = encodeURI(csvData)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', fileName)
  document.body.appendChild(link) // Required for FF

  link.click()
}
