































































import Alert from '@/components/Alert.vue'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import PasswordInput from '@/elements/PasswordInput.vue'
import { Component, Vue } from 'vue-property-decorator'
import authClient from '@/clients/authorizationClient'
import { RestServiceResult } from '@/services/restService'
import { AuthToken } from '@/models/Authorization/AuthToken'

@Component({
  components: {
    Alert,
    TextInput,
    Loading,
    PasswordInput,
  },
})
export default class ResetPassword extends Vue {
  private code = ''
  private newPassword = ''
  private confirmNewPassword = ''
  private isLoading = false
  private showConfirmPasswordError = false
  private showCode = true
  private isDone = false
  private currentYear = new Date().getFullYear()

  private get title() {
    return this.isInvite ? 'Accept Invite' : 'Reset Password'
  }

  private get isInvite() {
    return this.$route.name === 'AcceptInvite'
  }

  private onNewPasswordsChange() {
    this.showConfirmPasswordError =
      !!this.newPassword && !!this.confirmNewPassword && this.newPassword !== this.confirmNewPassword
  }

  private get allowSubmit() {
    return (
      !this.isLoading &&
      this.code &&
      this.newPassword &&
      this.confirmNewPassword &&
      this.newPassword === this.confirmNewPassword
    )
  }

  private async onSubmit() {
    if (this.isLoading || !this.allowSubmit) {
      return
    }

    this.isLoading = true

    try {
      let rsr: RestServiceResult<AuthToken>
      if (this.isInvite) {
        rsr = await authClient.acceptInvitation(this.code, this.newPassword)
      } else {
        rsr = await authClient.resetPassword(this.code, this.newPassword)
      }

      if (rsr.isSuccess) {
        this.isDone = true
      }
    } finally {
      this.isLoading = false
    }
  }

  private created() {
    const code = this.$route.query.code ?? this.$route.params.code

    if (code && typeof code === 'string') {
      this.code = code
      this.showCode = false
    }
  }
}
