




































import { defineComponent, ref } from '@vue/composition-api'
import { candy, headers, Candy } from './data'

import DataTable from '@/elements/DataTable/DataTable.vue'
import TextInput from '@/elements/TextInput.vue'

import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

export default defineComponent({
  name: 'DataTableTest',
  components: { DataTable, TextInput },
  setup() {
    let row = ref<Candy | {}>({})
    let rowDblClick = ref<Candy | {}>({})
    const action = ref('')
    const search = ''

    function rowSelected(x: DataTableSelection<Candy>) {
      row.value = x.item
    }

    function dblClick(x: DataTableSelection<Candy>) {
      rowDblClick.value = x.item
    }

    function actionEvent(a: Candy) {
      action.value = `Event Fired for ${a.name}`
    }

    return { headers, candy, row, rowSelected, dblClick, rowDblClick, actionEvent, action, search }
  },
})
