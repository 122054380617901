import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'

export function getEmptyProductOfferingProductInfo(): ProductOfferingProductInfo {
  return {
    productID: 0,
    sortOrder: 0,
    selectByDefault: false,
    active: true,
    colors: null,
    productCost: 0,
    upwardProductID: '',
    cmsProductName: '',
    cmsProductDescription: '',
    cmsProductImageUrls: [],
    cmsUnitDescription: '',
    productPackages: [],
    digitalFormatAvailable: null,
    productCostOffsetLabels: [],
    groupID: 0,
  }
}

export function getEmptyProductOfferingProductInfoList(): ProductOfferingProductInfo[] {
  return [getEmptyProductOfferingProductInfo()]
}
