





























import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import PlayerEvaluationEditGrid from '@/components/PlayerEvaluationEditGrid.vue'
import { LeaguePlayerSeedInfo } from '@/lib/support/models/LeaguePlayerInfo/data'
import participantsClient from '@/clients/participantsClient'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { getEmptyLeaguePlayer } from '@/lib/support/models/LeaguePlayer/data'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  components: { Loading, PlayerEvaluationEditGrid },
  props: {
    ParticipantID: {
      type: Number,
      required: true,
      default: 0,
    },
    LeagueID: {
      type: String,

      default: '',
    },
  },
  setup(props, ctx) {
    const internalLeagueID = ref<string>(ctx?.parent?.$route?.params?.id ?? props.LeagueID)
    const participant = ref<LeaguePlayer>(getEmptyLeaguePlayer(new LeaguePlayerSeedInfo()))

    /**
     * Loads a participant from server
     */
    async function refreshParticipant() {
      participant.value = getEmptyLeaguePlayer(new LeaguePlayerSeedInfo())
      participant.value =
        (await participantsClient.retrieveParticipant(internalLeagueID.value, props.ParticipantID)) ??
        getEmptyLeaguePlayer(new LeaguePlayerSeedInfo())
    }

    /**
     * Calculates if we are in a loading state, note that the default player won't have a program assigned
     */
    const loading = computed(() => {
      participant.value.programs?.length && participant.value.programs[0].evaluations?.length
    })
    /**
     * Save a given participant including evaluation.
     */
    const saveParticipant = async () => {
      if (participant.value.programs?.length) {
        await participantsClient.save(internalLeagueID.value, participant.value)
        await refreshParticipant()
      }
    }
    watch(() => props.LeagueID, refreshParticipant, { immediate: true })
    watch(() => props.ParticipantID, refreshParticipant, { immediate: true })
    return { participant, saveParticipant, loading }
  },
})
