























import { defineComponent, ref, computed } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import store from '@/store'
import { UpwardGPOrderTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGPOrderTypeID'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'

export default defineComponent({
  components: {
    SelectInput,
    MemoInput,
  },
  setup() {
    const orderTypeRef = ref(store.getters.orders.currentOrderInternalOrderType ?? 'MERCH_ORDER')
    const adminNoteRef = ref(store.getters.orders.currentOrderAdminNote ?? '')

    const orderTypes = computed(() =>
      store.getters.gpOrderTypes.allItems.filter(
        (x: UpwardGPOrderTypeID) => x.isErrorOrderType || x.upwardTypeID == 'MERCH_ORDER'
      )
    )

    function selectOrderType(selectedType: string) {
      //add processing instruction to xOrder if an error order
      orderTypeRef.value = selectedType
      store.commit.orders.setCurrentOrderInternalOrderType({
        orderType: OrderTypesEnum.manual,
        item: selectedType == 'MERCH_ORDER' ? null : selectedType,
      })
    }

    function adminNoteChange(newValue: string) {
      store.commit.orders.setCurrentOrderAdminNote({ orderType: OrderTypesEnum.manual, item: newValue })
    }

    return {
      orderTypeRef,
      adminNoteRef,
      orderTypes,
      selectOrderType,
      adminNoteChange,
    }
  },
})
