











import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { GenderIdToText } from '@/lib/support/models/MiscMappings/Gender'
import { GradeRangeArrayToText } from '@/lib/support/models/GeneratedTypes/ListInfo/LeagueDivisionGradeInfo'

interface GridData {
  divisionName: string | null
  gender: string
  gradeRange: string
}

export default defineComponent({
  name: 'DivisionImportModal',
  components: {
    ConfirmationModal,
    SimpleGrid,
  },
  props: {
    previousDivisions: { type: Array as PropType<LeagueDivisionInfo[]>, required: true },
    show: { type: Boolean, required: false, default: false },
    isByAge: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    function onYesNoResponse(confirmation: boolean) {
      ctx.emit('onResponse', confirmation)
    }

    const modalIsVisible = ref(false)

    onMounted(() => {
      modalIsVisible.value = props.show
    })

    const columns = computed((): GridColumn[] => {
      return [
        { field: 'divisionName', headerText: 'Division' } as GridColumn,
        { field: 'gender', headerText: 'Gender' } as GridColumn,
        { field: 'gradeRange', headerText: props.isByAge ? 'Age' : 'Grade' } as GridColumn,
      ]
    })

    const gridData = computed(() => {
      const grid: GridData[] = []
      if (props.previousDivisions.length > 0) {
        props.previousDivisions.forEach((division: LeagueDivisionInfo) => {
          grid.push({
            divisionName: division.divisionName,
            gender: GenderIdToText(division.gender),
            gradeRange: GradeRangeArrayToText(division.grades, props.isByAge),
          })
        })
      }
      return grid
    })

    watch(
      () => props.show,
      (newValue: boolean) => {
        modalIsVisible.value = newValue
      }
    )

    return {
      modalIsVisible,
      onYesNoResponse,
      gridData,
      columns,
    }
  },
})
