





















import { defineComponent } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import store from '@/store'

export default defineComponent({
  name: 'UpwardSystemStep',
  props: {
    step: { type: Number, required: true },
  },
  components: { Step },
  setup() {
    const logic = productFlowLogic()
    const items = [
      {
        iconClass: 'fas fa-bullhorn fa-lg',
        text: 'Advertise your local camp on the Upward League Finder page.',
      },
      {
        iconClass: 'fas fa-money-bill-wave fa-lg',
        text: 'A quick and easy player registration tool where Upward covers all credit card fees.',
      },
      {
        iconClass: 'fas fa-mobile-alt fa-lg',
        text:
          'A mobile-friendly web app that includes coach training resources, energy games, skill stations, devotions, scrimmages format, and other Upward specific functionality.',
      },
      {
        iconClass: 'fas fa-mobile-alt fa-lg',
        text: `A mobile-friendly web app that includes training and resources for other key roles including the camp director, ${store.getters.leagueAbstraction.refLabel.toLowerCase()}s and the prayer commissioner.`,
      },
      {
        iconClass: 'fas fa-sms fa-lg',
        text:
          'A communication system that will allow for text or email exchanges with parents on their teams.',
      },
      {
        iconClass: 'far fa-list-alt fa-lg',
        text: 'Tools to setup your camp and order/exchange products from Upward.',
      },
      {
        iconClass: 'fas fa-chalkboard-teacher fa-lg',
        text: 'A wealth of camp management resources to guide, train, and equip leaders of any level.',
      },
    ]
    return { items, ...logic }
  },
})
