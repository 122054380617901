






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Loading',
  props: {
    isSmall: { type: Boolean, required: false, default: false },
  },
})
