









import SectionHeader from '@/components/SectionHeader.vue'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import { filterNA, getFilteredProductList, OrderProduct } from '@/models/Order/OrderProduct.ts'
import { getEmptyOrderProductList } from '@/lib/support/models/GeneratedTypes/xOrders/xOrderProduct.ts'
import currency from '@/filters/currency'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ProductSelectorLogic } from '@/views/Programs/Orders/compositions/useProductSelector'
interface OrderList {
  productLabel: string
  cost: string
  orderQuantity: number
  size: string
}
export default defineComponent({
  components: {
    SectionHeader,
    SimpleGrid,
  },
  props: {
    orderProductList: {
      type: Array as PropType<OrderProduct[]>,
      default: getEmptyOrderProductList,
      required: false,
    },
    sectionName: { type: String, required: true },
    selectorMethod: {
      type: Function as PropType<ProductSelectorLogic>,
      required: true,
    },
  },
  setup(props) {
    const columns = computed(() => {
      if (orderList.value.some((x) => filterNA(x.size))) {
        return [
          { field: 'productLabel', headerText: 'Item Name', width: 300 } as GridColumn,
          { field: 'type', headerText: 'Type' } as GridColumn,
          { field: 'size', headerText: 'Size' } as GridColumn,
          { field: 'cost', headerText: 'Cost' } as GridColumn,
          { field: 'orderQuantity', headerText: 'Quantity' } as GridColumn,
        ] as GridColumn[]
      } else {
        return [
          { field: 'productLabel', headerText: 'Item Name', width: 300 } as GridColumn,
          { field: 'type', headerText: 'Type' } as GridColumn,
          { field: 'cost', headerText: 'Cost' } as GridColumn,
          { field: 'orderQuantity', headerText: 'Quantity' } as GridColumn,
        ] as GridColumn[]
      }
    })
    const ps = props.selectorMethod()
    const orderList = computed(() => {
      if (props.orderProductList && props.orderProductList.length) {
        return getFilteredProductList(props.orderProductList, ps.isSelected).map((product) => ({
          productLabel: product.cmsProductName ?? 'n/a',
          type: product.productColorSize.colorDescription ?? '',
          size: product.productColorSize.sizeDescription ?? '',
          cost: getCost(
            product.productColorSize.orderQuantity - product.productColorSize.orderFreeQuantity > 0
              ? product.productColorSize.costOffset + product.cost
              : 0
          ),
          orderQuantity: product.productColorSize.orderQuantity,
          previouslyOrdered: product.productColorSize.previouslyOrdered,
        })) as OrderList[]
      }
      return [] as OrderList[]
    })

    function getCost(cost: number) {
      return currency(cost)
    }

    return { getCost, orderList, columns }
  },
})
