import connectsClient from '@/clients/connect'
import { Connect } from '@/GeneratedTypes/Connect'
import { defineActions, defineMutations, defineGetters } from 'direct-vuex'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { getEmptyConnect } from '@/lib/support/models/Connect/data'
import sessionObjectsClient from '@/clients/sessionObjectsClient'
import { uniqueUserIdCheck } from '@/store/helper'
import store from '@/store'

interface ConnectsState {
  currentItem: Connect
}

const connectsState: ConnectsState = {
  currentItem: getEmptyConnect(),
}

export enum getterNames {
  currentItem = 'currentItem',
}

const getterTree = defineGetters<ConnectsState>()({
  currentItem: (state) => state.currentItem,
})

export enum mutationNames {
  setCurrent = 'setCurrent',
}

const mutations = defineMutations<ConnectsState>()({
  setCurrent(state, { item }: { item: Connect }) {
    state.currentItem = item
    store.commit.leagueAbstraction.setCurrentConnect({ item })
  },
})

export enum actionNames {
  create = 'create',
  update = 'update',
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  cache = 'cache',
  loadFromCache = 'loadFromCache',
  beginCreating = 'beginCreating',
}

const actions = defineActions({
  async [actionNames.retrieveAndSetAsCurrent]({ commit }, { id }: { id: string }): Promise<Connect | null> {
    const result = await connectsClient.retrieve(id)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })
      return result.data
    }

    return null
  },
  async [actionNames.create]({ commit, rootGetters }, { item }: { item: Connect }): Promise<Connect | null> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    const result = await connectsClient.create(item)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })
      await sessionObjectsClient.delete(`connect.new.${uniqueUserId}`)
      return result.data
    }

    return null
  },
  async [actionNames.update](
    { commit },
    { id, item }: { id: string; item: Connect }
  ): Promise<Connect | null> {
    const result = await connectsClient.update(id, item)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })
      return result.data
    }

    return null
  },
  async [actionNames.cache]({ commit, rootGetters }, { item }: { item: Connect }): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    const isNew = !item.upwardLeagueID
    const cacheKey = `connect.${isNew ? `new.${uniqueUserId}` : item.upwardLeagueID}`
    const cacheResult = await sessionObjectsClient.createOrUpdate(cacheKey, item)

    if (!cacheResult.isSuccess) {
      return false
    }

    commit(mutationNames.setCurrent, { item })
    return true
  },
  async [actionNames.loadFromCache]({ commit, rootGetters }): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    const result = await sessionObjectsClient.retrieve<Connect>(`connect.new.${uniqueUserId}`)

    if (!result.isSuccess) {
      return false
    }

    const item = result.data!
    commit(mutationNames.setCurrent, { item })
    return true
  },
  async [actionNames.beginCreating](
    { commit, dispatch },
    {
      name,
      typeLeagueID,
      partnerContact,
    }: { name: string; typeLeagueID: string; partnerContact: ContactInfo | null }
  ): Promise<boolean> {
    const result = await connectsClient.newTemplate(typeLeagueID)

    if (!result.isSuccess) {
      return false
    }

    const item = result.data!
    item.leagueName = name
    item.typeLeagueID = typeLeagueID
    item.typeCountryID = 'US' //hack since API doesn't return this for some reason

    if (partnerContact) {
      item.partnerContact = {
        firstName: partnerContact.firstName!,
        lastName: partnerContact.lastName!,
        emailAddress: partnerContact.emailAddress!,
        emailValid: partnerContact.emailValid,
        emailValidReason: partnerContact.emailValidReason,
        primaryPhoneNumber: partnerContact.primaryPhone!,
        primaryPhoneTypeID: partnerContact.primaryPhoneTypeID!,
        secondaryPhoneNumber: partnerContact.secondaryPhone,
        secondaryPhoneTypeID: partnerContact.secondaryPhoneTypeID,
        alternatePhoneNumber: partnerContact.alternatePhone,
        alternatePhoneTypeID: partnerContact.alternatePhoneTypeID,
        yearsExperience: partnerContact.yearsExperience,
        isFullTime: partnerContact.isFullTime,
        typePartnerRoleID: partnerContact.typePartnerRoleID!,
        typePreferredDayOfWeekID: partnerContact.preferredContactDayOfWeek!,
        typePreferredTimeOfDayID: partnerContact.preferredContactTimeOfDay!,
        typePreferredContactMethodID: partnerContact.preferredContactMethod!,
      }

      item.leagueDirectorPartnerID = partnerContact.id
    }
    commit(mutationNames.setCurrent, { item })

    await dispatch(actionNames.cache, { item })

    return true
  },
})

export const namespace = 'connects'

export const connects = {
  namespaced: true as true,
  state: connectsState,
  getters: getterTree,
  mutations,
  actions,
}
