import { SlideType, SlideButton } from '@/components/Carousel/types/Slide'

export function createDeck(nextHandler: (id: number) => void, navigateHandler: (path: string) => void) {
  const participantAssignmentQuestion = {
    id: 1,
    order: 1,
    title: 'Are all participants entered and assigned to teams?',
    subtitle: 'Subtitle',
    body:
      '<b>Body Text accepts HTML</b>, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta.',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Yes',
        fnc: () => nextHandler(participantKitQuestion.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'No',
        fnc: () => nextHandler(participantAssignmentWarning.id),
      } as SlideButton,
    ],
  } as SlideType

  const participantKitQuestion = {
    id: 2,
    order: 2,
    title: 'Is this what you want ordered for each participant?',
    body:
      '<b>Body Text accepts HTML</b>, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta.',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Yes',
        fnc: () => nextHandler(coachAssignmentQuestion.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'No',
        fnc: () => nextHandler(participantKitChange.id),
      } as SlideButton,
    ],
  } as SlideType

  const coachAssignmentQuestion = {
    id: 3,
    order: 3,
    title: 'Have all coaches been added and assigned to teams?',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Yes',
        fnc: () => nextHandler(readyToOrder.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'No',
        fnc: () => nextHandler(coachAssignmentWarning.id),
      } as SlideButton,
    ],
  } as SlideType

  const readyToOrder = {
    id: 5,
    order: 5,
    title: 'You are ready to start your order.',
    buttons: null,
  } as SlideType

  const participantAssignmentWarning = {
    id: 6,
    order: 6,
    title: 'Players not assigned to teams will not appear on this order.',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Navigate to other slide',
        fnc: () => nextHandler(participantKitQuestion.id),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'Link to vue router path',
        fnc: () => navigateHandler('/'),
      } as SlideButton,
    ],
  } as SlideType

  const participantKitChange = {
    id: 6,
    order: 6,
    title: 'You can make changes by going to Setup > Product and Pricing',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Continue to Order',
        fnc: () => navigateHandler('/'),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'Assign Participants to Teams',
        fnc: () => navigateHandler('/'),
      } as SlideButton,
    ],
  } as SlideType

  const coachAssignmentWarning = {
    id: 6,
    order: 6,
    title: 'Coaches not assigned to teams will not appear on this order.',
    buttons: [
      {
        id: 1,
        order: 1,
        label: 'Continue to Order',
        fnc: () => navigateHandler('/'),
      } as SlideButton,
      {
        id: 2,
        order: 2,
        label: 'Assign Participants to Teams',
        fnc: () => navigateHandler('/'),
      } as SlideButton,
    ],
  } as SlideType

  return [
    participantAssignmentQuestion,
    participantKitQuestion,
    coachAssignmentQuestion,
    readyToOrder,
    participantAssignmentWarning,
  ] as SlideType[]
}
