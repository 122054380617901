








































import { Component, Vue, Watch } from 'vue-property-decorator'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import FacilityTimeWarningModal from '@/views/Programs/Scheduling/Games/ext/FacilityTimeWarningModal.vue'
import { LeagueInfoMoreThanOneProgram } from '@/lib/support/models/LeagueListItem/data'
import { League } from '@/GeneratedTypes/League'

import { namespace } from 'vuex-class'
import { getterNames as leagueGetters, namespace as leagueStoreName } from '@/store/leagues'
import { getterNames as schedulingGetters, namespace as schedulingStoreName } from '@/store/scheduling'

const league = namespace(leagueStoreName)
const schedulingStore = namespace(schedulingStoreName)

import {
  getterNames as facilitiesGetters,
  actionNames as facilitiesActions,
  namespace as facilitiesStoreName,
} from '@/store/facilities'

class SchedulerData {
  program = ''
  divisionID = 0
  excludedDates: Date[] = []
  preferences = getEmptyDivisionGameSchedulerPreference()
}

import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { getEmptyDivisionGameSchedulerPreference } from '@/lib/support/models/DivisionGameSchedulerPreference/data.ts'
import DivisionPreferences from '@/views/Programs/Scheduling/Games/auto/DivisionPreferences.vue'
import { DivisionGameSchedulerPreference } from '@/GeneratedTypes/DivisionGameSchedulerPreference'

const facilities = namespace(facilitiesStoreName)

import {
  getterNames as divisionGetters,
  actionNames as divisionActions,
  namespace as divisionNamespace,
} from '@/store/divisions'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { DivisionAutoSchedulerStatus } from '@/models/Scheduling/DivisionAutoSchedulerStatus'
import { DivisionStatusEnum } from '@/models/Scheduling/DivisionStatusEnum'
import gamesClient from '@/clients/gamesClient'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'

const divisions = namespace(divisionNamespace)

@Component({
  components: {
    TypeProgramTab,
    DivisionPreferences,
    FacilityTimeWarningModal,
  },
  methods: {
    LeagueInfoMoreThanOneProgram,
  },
})
export default class AutomaticScheduling extends Vue {
  /** getters */
  @league.Getter(leagueGetters.currentItem) league!: League
  @schedulingStore.Getter(schedulingGetters.teamsLoading) teamsLoading!: Boolean
  @facilities.Getter(facilitiesGetters.items) facilities!: LeagueFacility[]

  @facilities.Action(facilitiesActions.load)
  private readonly retrieveFacilities!: ({ id }: { id: string }) => LeagueFacility[] | null

  @divisions.Getter(divisionGetters.allItems) divisionList!: (s: string) => LeagueDivisionInfo[]
  @divisions.Action(divisionActions.fetchAll) fetchAllDivisions!: ({
    upwardLeagueId,
    force,
  }: {
    upwardLeagueId: UpwardLeagueIDType
    force: boolean
  }) => Promise<boolean>

  divisionID = 0
  showTimeWarning = false

  async clickSchedule() {
    const date = new Date()
    const dateString = `${date.getUTCFullYear()}-${('0' + date.getUTCMonth()).slice(-2)}-${(
      '0' + date.getUTCDate()
    ).slice(-2)}T`
    const startDate = new Date(`${dateString}08:00:00.000-00:00`)
    const endDate = new Date(`${dateString}21:00:00.000-00:00`)

    var hasFacilityTimesOutsideRange = this.settings.some(
      (f) =>
        f.divisionID === this.divisionID &&
        f.facilityPreferences?.some(
          (a) =>
            new Date(`${dateString}${(a.startTime + ':00').substring(0, 8)}.000-00:00`) < startDate ||
            new Date(`${dateString}${(a.endTime + ':00').substring(0, 8)}.000-00:00`) > endDate
        )
    )

    this.showTimeWarning = hasFacilityTimesOutsideRange
    if (!hasFacilityTimesOutsideRange) {
      await this.continueScheduling()
    }
  }

  get gamepath() {
    return `/programs/league/${this.$route.params.id}/scheduling/games`
  }

  isReadyToSchedule = false
  async continueScheduling() {
    const scheduleSettings = this.settings.find((s) => {
      return s.divisionID === this.divisionID
    })
    if (!scheduleSettings) {
      throw Error('No division settings. Nothing scheduled')
    }

    const schedulingStatus = this.divisionStatusList.find(
      (ds) =>
        ds.division.divisionID === scheduleSettings.divisionID &&
        ds.division.typeProgramID === scheduleSettings.typeProgramID
    )
    if (!schedulingStatus) {
      return
    }

    await gamesClient.schedule(
      this.upwardLeagueID,
      scheduleSettings.typeProgramID,
      scheduleSettings.divisionID,
      {
        clearExistingSchedule: schedulingStatus?.shouldOverwrite ?? false,
        createDefaultPairings: (schedulingStatus?.scheduledGames ?? 0) <= 0,
        generateGameSchedule: true,
      }
    )
    this.$router.push(`/programs/league/${this.upwardLeagueID}/scheduling/manual/${this.divisionID}`)
  }

  settings: DivisionGameSchedulerPreference[] = []

  update(s: DivisionGameSchedulerPreference) {
    if (this.settings && s && s.divisionID) {
      var setting = this.settings.find((l) => l.divisionID === s.divisionID)
      if (setting) {
        //remove previous setting
        const settingToRemoveIndex = this.settings.indexOf(setting)
        this.settings.splice(settingToRemoveIndex, 1)
      }
      this.settings.push(s)
    }
  }

  @Watch('divisionsList', { immediate: true })
  async divisionsListUpdate() {
    for (const divisionInfo of this.divisionsList) {
      const divisionPreference = await gamesClient.getPreferences(
        this.upwardLeagueID,
        divisionInfo?.typeProgramID ?? '',
        divisionInfo?.divisionID ?? 0
      )
      if (divisionPreference) {
        this.update(divisionPreference)
      }
    }
  }

  /** other variables**/
  private loading = true
  get programID() {
    if (this.league && this.league.programs?.length) {
      return this.league.programs[0].typeProgramID || ''
    }
    return ''
  }

  get upwardLeagueID() {
    return this.$route.params.id
  }

  public async mounted() {
    this.divisionID = parseInt(this.$route.params.divisionID) ?? 0
    await this.retrieveFacilities({ id: this.upwardLeagueID })
    await this.fetchAllDivisions({ upwardLeagueId: this.upwardLeagueID, force: true })

    this.loading = false
  }

  get divisionsList() {
    return this.divisionList(this.league?.upwardLeagueID ?? '')
  }

  get divisionStatusList(): DivisionAutoSchedulerStatus[] {
    let priority = 1

    return this.divisionsList
      .filter((x) => x.typeProgramID == this.programID)

      .map((x) => ({
        division: { ...(x as LeagueDivision) },
        shouldSkip: false,
        shouldOverwrite: false,
        status: DivisionStatusEnum.PENDING,
        priority: priority++,
        scheduledGames: -1,
        preferences:
          this.settings.find((y) => y.divisionID == x.divisionID) ??
          getEmptyDivisionGameSchedulerPreference(),
      }))
  }
  data = new SchedulerData()
  step = 'DivisionPreferences'
}
