import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { EmailSMSLeagueMessageInfo } from '@/GeneratedTypes/Communication/EmailSMSLeagueMessage'

export function getEmptyPreviewMessage(): PreviewMessage {
  return {
    accountNumber: '',
    upwardLeagueID: '',
    selectedGroups: [],
    teams: [],
    individualContactInfo: [],
    practiceNight: null,
    startGrade: 'K3',
    endGrade: '12th',
    communicationType: '',
    displayName: '',
    replyTo: '',
    emailSubject: '',
    messageBody: '',
    to: [],
    toDelimited: '',
    paymentStatus: '',
    evalStatus: '',
    coachTeamStatus: '',
    typeProgramID: null,
    viewAllLeagueContacts: true,
    practiceFacilityIDs: [],
    gender: 'E',
  }
}

export interface MessageData {
  recipientTokens: string[]
  individualRecipients: string[]
  recipientCount: number
  subject: string
  attachments: string[]
  messageBody: string
  sentDate: Date | null
  messageType: string
  thirdPartyResponse: string
}

export function getEmptyMessageData(): MessageData {
  return {
    recipientTokens: [],
    individualRecipients: [],
    recipientCount: 0,
    subject: '',
    attachments: [],
    messageBody: '',
    sentDate: null,
    messageType: '',
    thirdPartyResponse: '',
  } as MessageData
}
export function toMessageDataFromEmailSMSLeagueMessageInfo(message: EmailSMSLeagueMessageInfo | null) {
  const messageData = getEmptyMessageData()
  if (!message) return messageData
  messageData.individualRecipients = message.recipients ? message.recipients : []
  messageData.recipientCount = message.recipients ? message.recipients.length : 0
  messageData.subject = message.emailSubject ? message.emailSubject : ''
  messageData.messageBody = message.messageBody ? message.messageBody : ''
  messageData.sentDate = message.createDateLocal
  messageData.messageType = message.messageType ? message.messageType : ''
  messageData.thirdPartyResponse = message.thirdPartyResponse ? message.thirdPartyResponse : ''

  if (message.emailAttachments && message.emailAttachments.length > 0) {
    for (let i = 0; i < message.emailAttachments.length; i++) {
      messageData.attachments.push(message.emailAttachments[i].name || '')
    }
  }
  return messageData
}

export function toMessageDataFromPreviewMessage(message: PreviewMessage | null) {
  const messageData = getEmptyMessageData()
  if (!message) return messageData
  messageData.recipientTokens = message.selectedGroups
  messageData.individualRecipients = message.to
  messageData.recipientCount = message.to.length
  messageData.subject = message.emailSubject || ''
  messageData.messageBody = message.messageBody
  messageData.sentDate = null
  messageData.messageType = ''
  messageData.thirdPartyResponse = ''
  return messageData
}
