



























import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'HorizontalNavTabs',
  props: {
    tabs: { type: Array, required: true },
    value: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const internalValue = ref('')

    watch(
      () => internalValue,
      () => {
        if (internalValue.value !== props.value) {
          ctx.emit('input', internalValue.value)
          ctx.emit('change', internalValue.value)
        }
      }
    )

    watch(
      () => props.value,
      () => {
        if (internalValue.value !== props.value) {
          internalValue.value = props.value
        }
      },
      { immediate: true }
    )

    function tabClicked(id: string) {
      internalValue.value = id
    }

    return {
      internalValue,
      tabClicked,
    }
  },
})
