


















































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import DivisionGrid from '@/components/DivisionGrid.vue'
import DivisionForm from '@/components/DivisionForm.vue'
import InfoModal from '@/components/InfoModal.vue'
import DivisionImportModal from '@/components/DivisionImportModal.vue'
import DivisionGradeGenderList from '@/components/DivisionGradeGenderList.vue'
import programsClient from '@/clients/programsClient'
import divisionsClient from '@/clients/divisionsClient'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { isByAge } from '@/lib/support/models/LeagueListItem/data.ts'
import Loading from '@/elements/Loading.vue'
import store from '@/store'
import { LeagueScoringTypes } from '@/lib/common/LeagueScoringTypes'

export default defineComponent({
  name: 'Divisions',
  components: {
    DivisionGrid,
    DivisionForm,
    DivisionGradeGenderList,
    InfoModal,
    DivisionImportModal,
    Loading,
  },
  setup(props, ctx) {
    const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const loading = ref(false)
    const mode = ref<'EDIT' | 'VIEW'>('VIEW')
    const divisions = ref<LeagueDivisionInfo[]>([])
    const previousDivisions = ref<LeagueDivisionInfo[] | []>([])
    const allDivisions = ref<LeagueDivisionInfo[] | null>(null)
    const division = ref<LeagueDivision | null>(null)
    const selectedProgramId = ref('')
    const cheerProgamId = ref('')
    const sportProgramId = ref('')
    const leaguePrograms = ref<LeagueListItem>({} as LeagueListItem)
    const showAddDivisionModal = ref(false)
    const showImportModal = ref(false)

    const programIsByAge = computed(() => isByAge(leaguePrograms.value))

    function onEditCanceled() {
      mode.value = 'VIEW'
    }

    onMounted(async () => {
      try {
        loading.value = true
        await loadPrograms()
        await loadDivisions()
        if (divisionCount.value === 0) {
          await loadPreviousDivisions()
          if (previousDivisions.value.length > 0) {
            showImportModal.value = true
          } else {
            showAddDivisionModal.value = true
            await newDivision()
          }
        }
      } finally {
        loading.value = false
      }
    })

    async function loadPrograms() {
      const UpwId = ctx.root.$route.params.id
      try {
        const programs = await programsClient.retrieve(UpwId)
        if (programs) {
          leaguePrograms.value = programs
          selectedProgramId.value = programs.programTypeID!
          cheerProgamId.value = programs.cheerProgramTypeID!
          sportProgramId.value = programs.programTypeID!
        }
      } catch (error) {
        throw error
      }
    }

    async function loadDivisions() {
      const UpwId = ctx.root.$route.params.id

      try {
        allDivisions.value = await divisionsClient.retrieveAll(UpwId)
        divisionsFilteredByProgram()
      } catch (error) {
        throw error
      }
    }

    function divisionsFilteredByProgram() {
      if (allDivisions.value) {
        divisions.value = allDivisions.value.filter((division) => {
          return division.typeProgramID === selectedProgramId.value
        })
      }
    }

    async function loadPreviousDivisions() {
      const UpwId = ctx.root.$route.params.id

      try {
        const divs = await divisionsClient.retrieveAllPreviousLeagueDivisions(
          UpwId,
          firstAccountNumber.value,
          leaguePrograms.value
        )
        if (divs) {
          previousDivisions.value = divs
          if (divisionCount.value > 0) {
          }
        }
      } catch (error) {
        throw error
      }
    }

    const divisionCount = computed(() => {
      if (allDivisions.value) {
        return allDivisions.value.length
      } else {
        return 0
      }
    })

    function divisionsWithExclusions(div: LeagueDivision) {
      return allDivisions.value
        ? allDivisions.value.filter(
            (x) => x.exclusionDates && x.exclusionDates.length > 0 && x.divisionID != div.divisionID
          )
        : []
    }

    async function onDivisionSaved(div: LeagueDivision) {
      selectedProgramId.value = div.typeProgramID
      await loadDivisions()
      mode.value = 'VIEW'
    }

    async function onDeleteDivision(div: LeagueDivision) {
      await divisionsClient.remove(ctx.root.$route.params.id, selectedProgramId.value, div.divisionID)
      loading.value = true
      await loadDivisions()
      loading.value = false
    }

    async function onProgramChanged(program: string) {
      selectedProgramId.value = program
      loading.value = true
      await loadDivisions()
      loading.value = false
    }

    async function onImportResponse(confirmation: boolean) {
      if (confirmation) {
        await divisionsClient.importAllDivisions(
          ctx.root.$route.params.id,
          previousDivisions.value[0].upwardLeagueID,
          firstAccountNumber.value
        )
        await loadDivisions()
        showImportModal.value = false
        mode.value = 'VIEW'
      }

      if (!confirmation) {
        showImportModal.value = false
        await newDivision()
      }
    }

    function onEditDivision(div: LeagueDivision) {
      division.value = div
      mode.value = 'EDIT'
    }

    async function addNew() {
      await newDivision()
    }

    async function newDivision() {
      loading.value = true
      const newDiv = await divisionsClient.retrieveTemplate()
      if (newDiv !== null) {
        newDiv.gameLength = 60 //default to most common length
        newDiv.practiceLength = 60 //default to most common length
        newDiv.typeLeagueScoringID = LeagueScoringTypes.NOSTANDINGS
      }
      division.value = newDiv
      mode.value = 'EDIT'
      loading.value = false
    }

    return {
      loading,
      divisions,
      mode,
      leaguePrograms,
      selectedProgramId,
      onProgramChanged,
      onDeleteDivision,
      onEditDivision,
      addNew,
      division,
      divisionsWithExclusions,
      onEditCanceled,
      onDivisionSaved,
      programIsByAge,
      showAddDivisionModal,
      showImportModal,
      previousDivisions,
      onImportResponse,
    }
  },
})
