import jwtService from '@/services/jwtService'
import { AuthTokenCredentials } from '@/models/Authorization/AuthTokenCredentials'
import authorizationClient, { ImpersonationPayload } from '@/clients/authorizationClient'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import { Commit } from 'vuex'
import credentialsService from '@/services/credentialsService'
import { AuthToken } from '@/models/Authorization/AuthToken'
import { TwoFactorAuthRequiredException } from '@/lib/common/exceptions/login//TwoFactorAuthRequiredException'
import {
  namespace as partnerNamespace,
  mutationNames as partnerMutationNames,
  actionNames as partnerActionNames,
} from '@/store/partners'
import { ProfilePayload } from '@/models/Authorization/ProfilePayload'

/*
activeAccount: this account is the same as the logged in user
if the league has no partnerships. If there are partnerships,
and it's the host partner who is logged in, this is the account
they are viewing/manipulating. This value is sent in the header of
all requests.
*/
interface AuthState extends AuthTokenCredentials {
  timeSet: Date | null
  isMinistryAgreementSigned: boolean
  codeReloadRequired: boolean
  activeAccount: string | null
}

const authState: AuthState = {
  accountNumbers: [],
  email: null,
  fullName: null,
  expiration: null,
  timeSet: null,
  accountName: null,
  impersonatedAccount: '0',
  impersonationActive: false,
  activities: null,
  roles: null,
  daysUntilPasswordExpiration: null,
  isMinistryAgreementSigned: false,
  userData: '',
  codeReloadRequired: false,
  leagueRoles: [],
  activeAccount: null,
  totpInfo: 'GOOD',
}

export enum getterNames {
  isAuthenticated = 'isAuthenticated',
  fullName = 'fullName',
  accountName = 'accountName',
  firstAccountNumber = 'firstAccountNumber',
  email = 'email',
  refreshTime = 'refreshTime',
  daysUntilPasswordExpiration = 'daysUntilPasswordExpiration',
  accountNumbers = 'accountNumbers',
  isMinistryAgreementSigned = 'isMinistryAgreementSigned',
  isImpersonated = 'isImpersonated',
  isCurrentUserAnAdmin = 'isCurrentUserAnAdmin',
  isCurrentUserASuperAdmin = 'isCurrentUserASuperAdmin',
  isCurrentUserASuperUser = 'isCurrentUserASuperUser',
  impersonationAccountNumber = 'impersonationAccountNumber',
  userData = 'userData',
  roles = 'roles',
  codeReloadRequired = 'codeReloadRequired',
  leagueRoles = 'leagueRoles',
  activeAccount = 'activeAccount',
  isSystemsAndSupport = 'isSystemsAndSupport',
  isUpwardStaff = 'isUpwardStaff',
  isMultiAccountUser = 'isMultiAccountUser',
  totpInfo = 'totpInfo',
}

const getterTree = defineGetters<AuthState>()({
  isAuthenticated: (state, getters, rootState) => {
    if (state.codeReloadRequired) return false

    if (state.totpInfo != 'GOOD') return false

    if (state.timeSet === null || state.expiration === null) {
      return false
    }

    if (state.accountNumbers && state.accountNumbers.length > 1) {
      // if a user is connected to more that one account, they are not fully authenticated
      // until they have picked just one using TheAuthWall.
      return false
    }

    const now = rootState.now
    return now <= state.expiration
  },
  fullName: (state) => {
    return state.fullName
  },
  userData: (state) => {
    return state.userData
  },

  isMultiAccountUser: (state) => {
    return (state?.accountNumbers?.length ?? 0) > 1
  },

  activeAccount: (state) => state.activeAccount,

  isMinistryAgreementSigned: (state) => {
    return state.isMinistryAgreementSigned
  },
  isImpersonated: (state) => {
    return credentialsService.isImpersonating(state)
  },
  impersonationAccountNumber: (state) => {
    return state.impersonatedAccount
  },
  roles: (state) => {
    return state.roles ?? []
  },
  isCurrentUserASuperAdmin: (state, getters) => {
    return (state?.roles?.some((x: string) => x === 'SuperAdmin') ?? false) && !getters.isImpersonated
  },

  isCurrentUserAnAdmin: (state, getters) =>
    (state?.roles?.some(
      (x: string) => x === 'SuperAdmin' || x === 'SystemsAndSupport' || x === 'UpwardStaff'
    ) ??
      false) &&
    !getters.isImpersonated,

  isCurrentUserASuperUser: (state, getters) => {
    const hasSuperUserRole = state?.roles?.some(
      (x) => ['SuperAdmin', 'SystemsAndSupport', 'UpwardStaff', 'Director', 'DataInput'].indexOf(x) >= 0
    )
    const isImpersonated = getters.isImpersonated
    return hasSuperUserRole || isImpersonated
  },

  isSystemsAndSupport: (state) => {
    return state?.roles?.some((x: string) => x === 'SystemsAndSupport') ?? false
  },

  isUpwardStaff: (state) => {
    return state?.roles?.some((x: string) => x === 'UpwardStaff') ?? false
  },

  accountNumbers: (state) => {
    return state.accountNumbers
  },
  firstAccountNumber: (state) => {
    if (state.accountNumbers && state.accountNumbers.length > 0) {
      return state.accountNumbers[0]
    }
    return ''
  },
  email: (state) => {
    if (state.email) {
      return state.email
    }
    return ''
  },
  accountName: (state) => {
    return state.accountName
  },
  refreshTime: (state) => {
    if (state.timeSet === null || state.expiration === null) {
      return null
    }

    const msPerMinute = 60 * 1000
    return new Date(state.expiration.getTime() - 10 * msPerMinute) //give us a 10 minute buffer in case there is a time difference to the server
  },
  daysUntilPasswordExpiration: (state) => {
    return state.daysUntilPasswordExpiration
  },
  codeReloadRequired: (state) => {
    return state.codeReloadRequired
  },
  leagueRoles: (state) => {
    return state.leagueRoles
  },
  totpInfo: (state) => {
    return state.totpInfo
  },
})

export enum mutationNames {
  setCurrentCredentials = 'setCurrentCredentials',
  clearCurrentCredentials = 'clearCurrentCredentials',
  setMinistryAgreementIsSigned = 'setMinistryAgreementIsSigned',
  resetMinistryAgreementIsSigned = 'resetMinistryAgreementIsSigned',
  setCodeReloadRequired = 'setCodeReloadRequired',
  setActiveAccount = 'setActiveAccount',
}

const mutations = defineMutations<AuthState>()({
  setCurrentCredentials(state, { credentials }: { credentials: AuthTokenCredentials }) {
    if (credentials) {
      Object.assign(state, { ...state, ...credentials }) //state is a superset of credentials.
      state.timeSet = new Date()
      state.activeAccount =
        credentials.accountNumbers && credentials.accountNumbers.length > 0
          ? credentials.accountNumbers[0].toString()
          : null
    }
  },
  setMinistryAgreementIsSigned(state) {
    state.isMinistryAgreementSigned = true
  },
  resetMinistryAgreementIsSigned(state) {
    state.isMinistryAgreementSigned = false
  },
  setActiveAccount(state) {
    state.isMinistryAgreementSigned = false
  },
  setCodeReloadRequired(state, { val }: { val: boolean }) {
    //used to suspend a user's authentication without logging
    // them out. This value is returned by isAuthentication unless
    // it's null, in which case it's ignored.
    state.codeReloadRequired = val
  },
  clearCurrentCredentials(state) {
    jwtService.clearStoredAuthToken()
    state.accountNumbers = []
    state.email = null
    state.fullName = null
    state.expiration = null
    state.timeSet = null
    state.accountName = null
    state.activities = null
    state.roles = null
    state.impersonatedAccount = '0'
  },
})

export enum actionNames {
  login = 'login',
  refreshToken = 'refreshToken',
  passwordChange = 'passwordChange',
  updateProfile = 'updateProfile',
  impersonate = 'impersonate',
  loginByToken = 'loginByToken',
  validateTOTP = 'validateTOTP',
}

const actions = defineActions({
  /**
   * attempt a login, state will change if successfully
   *
   * @param commit
   * @param email
   * @param password
   * @throws UserNotFoundException|LoginException|PasswordExpiredException
   * - these are passed up through the underlying auth service. The
   * authorization logic requires seeing these exceptions, so don't suppress them.
   *
   */
  async login({ commit, dispatch }, { email, password, accountNumber }): Promise<AuthToken> {
    const restResult = await authorizationClient.login(email, password, accountNumber)
    const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)
    commit(mutationNames.setCurrentCredentials, { credentials })

    //if 2FA, stop anything else
    if (credentials?.totpInfo == 'GOOD') {
      await handleSuccessfulLogin(credentials, dispatch)
    }

    return restResult
  },

  async validateTOTP({ commit, dispatch }, totpCode: string): Promise<AuthToken> {
    const restResult = await authorizationClient.validateTOTP(totpCode)
    const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)
    commit(mutationNames.setCurrentCredentials, { credentials })

    if (credentials?.totpInfo == 'GOOD') {
      await handleSuccessfulLogin(credentials, dispatch)
    }

    return restResult
  },

  /**
   * attempt a login, state will change if successfully
   *
   * @param commit
   * @param email
   * @param password
   * @throws UserNotFoundException|LoginException|PasswordExpiredException
   * - these are passed up through the underlying auth service. The
   * authorization logic requires seeing these exceptions, so don't suppress them.
   *
   */
  async loginByTokenWithImpersonation({ commit, dispatch }, { chit }: { chit: string }): Promise<Boolean> {
    const [token, impersonatingAccountNumber] = chit.split('.')
    const who = { userName: undefined, accountNumber: impersonatingAccountNumber } as ImpersonationPayload
    let usersAuthToken = null

    try {
      //login with the user's token
      usersAuthToken = await authorizationClient.tokenLogin(token)
    } catch (e) {
      // logout
      commit(mutationNames.clearCurrentCredentials)
      throw e
    }

    const credentials = jwtService.getCredentialsFromNewAuthToken(usersAuthToken)
    if (credentials?.totpInfo != 'GOOD') {
      commit(mutationNames.setCurrentCredentials, { credentials })
      const ex = new TwoFactorAuthRequiredException('2FA Required for this login')
      ex.accountToImpersonateAfterAuth = impersonatingAccountNumber
      throw ex
    }

    if (usersAuthToken && impersonatingAccountNumber) {
      //Impersonate and persist credentials
      await passthoughImpersonation(usersAuthToken, who, commit)
      return true
    } else if (usersAuthToken) {
      //persist credentials
      commit(mutationNames.setCurrentCredentials, { credentials })
      await setPartner(credentials, dispatch)
      return true
    }
    return false
  },

  async impersonate({ commit, dispatch }, who: { userName?: string; accountNumber: string }) {
    const restResult = await authorizationClient.impersonate(who)
    jwtService.backupToken()
    const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)
    commit(mutationNames.setCurrentCredentials, { credentials })
    await setPartner(credentials, dispatch)
  },

  async unimpersonate({ commit }) {
    const restResult = await jwtService.retrieveOldToken()
    if (restResult) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)
      commit(`${partnerNamespace}/${partnerMutationNames.setPartnerInfo}`, { item: null }, { root: true })
      commit(mutationNames.setCurrentCredentials, { credentials })
    }
  },

  async passwordChange({ commit, state }, { password, newPassword }): Promise<boolean> {
    const email = state.email

    if (!email) {
      return false
    }

    const restResult = await authorizationClient.passwordChange(email, password, newPassword)

    if (restResult.isSuccess) {
      commit(mutationNames.clearCurrentCredentials)
      return true
    }

    return false
  },
  async refreshToken({ commit }): Promise<boolean> {
    const restResult = await authorizationClient.refreshToken()

    try {
      const credentials = jwtService.getCredentialsFromNewAuthToken(restResult)
      commit(mutationNames.setCurrentCredentials, { credentials })
      return true
    } catch (e) {
      return false
    }
  },
  async tryLoadSavedToken({ commit, dispatch }): Promise<boolean> {
    const credentials = jwtService.getCredentialsFromStoredAuthToken()
    if (credentials) {
      commit(mutationNames.setCurrentCredentials, { credentials })

      await setPartner(credentials, dispatch)
      return true
    }

    return false
  },
  async updateProfile(
    { commit, state },
    { payload }: { payload: ProfilePayload }
  ): Promise<{ incompleteToken: boolean }> {
    payload.username = state.email

    if (!payload.username) {
      throw new Error('Missing username')
    }

    const restResult = await authorizationClient.updateProfile(payload)

    if (restResult.isSuccess) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(restResult.data)
      const hasMultipleAccounts = (restResult.data?.accountNumbers?.length ?? 0) > 1
      if (hasMultipleAccounts) {
        return { incompleteToken: true }
      } else {
        commit(mutationNames.setCurrentCredentials, { credentials })
        return { incompleteToken: false }
      }
    }
    throw new Error('Update Profile Failed')
  },
})

const passthoughImpersonation = async (
  usersAuthToken: AuthToken,
  who: ImpersonationPayload,
  commit: Commit
) => {
  jwtService.setHTTPHeaderAndLocalStorage(usersAuthToken)

  //Attempt to impersonate. If the user's permissions are insufficient to impersonate, this will fail.
  try {
    const impersonatedAuthToken = await authorizationClient.impersonate(who)
    if (impersonatedAuthToken) {
      const credentials = jwtService.getCredentialsFromNewAuthToken(impersonatedAuthToken)
      commit(mutationNames.setCurrentCredentials, { credentials })
    }
  } catch (e) {
    // logout
    commit(mutationNames.clearCurrentCredentials)
  }
}

async function setPartner(credential: AuthTokenCredentials | null, dispatch: any) {
  if (credential && credential.accountNumbers && credential.accountNumbers.length == 1) {
    const accountNumber = credential.accountNumbers[0]

    await dispatch(
      `${partnerNamespace}/${partnerActionNames.retrievePartnerInfo}`,
      { accountNumber },
      { root: true }
    )
  } else {
    throw new Error('Cannot load partner. Incorrect account numbers')
  }
}

async function handleSuccessfulLogin(credentials: AuthTokenCredentials | null, dispatch: any) {
  if (credentials && credentials.accountNumbers && credentials?.accountNumbers.length == 1) {
    await setPartner(credentials, dispatch)
  }
}

export const namespace = 'authorization'

export const authorization = {
  namespaced: true as true,
  state: authState,
  getters: getterTree,
  actions,
  mutations,
}
