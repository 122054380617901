















import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import ProductDetail from './ProductDetail.vue'
import { getEmptyProductOfferingInfo } from '@/lib/support/models/ProductOfferingInfo/ProductOfferingInfo'
import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'
import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'
import PackageIcon from '@/components/ProductFlow/ParticipantKitStepper/Packages/PackageIcon.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import { ProdConfigTypes } from '@/lib/common/ProdConfigTypes'

export default defineComponent({
  name: 'EnlargeableProduct',
  components: {
    Modal,
    ProductDetail,
    PackageIcon,
  },
  props: {
    product: {
      type: Object as PropType<ProductOfferingInfo | ProductOfferingProductInfo | StartupOrderProductInfo>,
      default: getEmptyProductOfferingInfo,
      required: false,
    },
    showPrice: { type: Boolean, default: true, required: false },
  },
  setup(props) {
    const logic = productFlowLogic()

    // apparently there are nameless products out there.
    const productName = computed(() => props.product.cmsProductName || 'N/A')

    const kits = computed(() => {
      const p = { ...props.product } as any
      if (p.hasOwnProperty('productPackages')) {
        return p.productPackages
      }
      return ''
    })

    const showIcons = computed(
      () => logic.isNewProdConfig.value || logic.prodConfigType.value == ProdConfigTypes.ESTIMATE
    )

    const isModalShown = ref(false)

    function showModal() {
      isModalShown.value = true
    }

    return {
      showIcons,
      kits,
      isModalShown,
      productName,
      showModal,
    }
  },
})
