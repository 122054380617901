





























import { RegistrationTransactionParticipantInfoDetails } from '@/models/Registration/RegistrationTransactionParticipantInfoDetails'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import TextInput from '@/elements/TextInput.vue'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  components: {
    TextInput,
    SelectInput,
    Modal,
  },
  props: {
    chargeback: {
      type: Object as PropType<RegistrationTransactionParticipantInfoDetails>,
      required: true,
    },
    showing: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, ctx) {
    const internalShowing = ref(false)

    watch(
      () => props.showing,
      () => (internalShowing.value = props.showing),
      { immediate: true }
    )

    watch(
      () => internalShowing.value,
      () => {
        if (!internalShowing.value) {
          ctx.emit('close')
        }
      },
      { immediate: true }
    )

    function close() {
      ctx.emit('close')
      internalShowing.value = false
    }

    const gardians = computed(() => {
      if (props.chargeback && props.chargeback.players && props.chargeback.players.length) {
        return props.chargeback.players
      }
      return []
    })

    return {
      internalShowing,
      gardians,
      close,
    }
  },
})
