import jsPDF from 'jspdf'

/**
 * HTML Element to a PDF document based on jsPDF.
 * Documentation is here: http://raw.githack.com/MrRio/jsPDF/master/docs/
 * @param node
 * @param options
 */
export async function html2pdf(node: HTMLElement, options?: { filename?: string }) {
  const doc = new jsPDF({ format: 'letter' })
  return await doc.html(node, {
    x: 15,
    y: 15,
    filename: options?.filename ?? 'report.pdf',
    margin: 15,
    html2canvas: { allowTaint: true, scale: 0.2 },
    callback: (doc1) => {
      doc1.save(options?.filename ?? 'report.pdf')
    },
  })
}
