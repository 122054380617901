




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'StepperContent',
  props: {
    step: { type: [String, Number], required: false, default: '1' },
    defaultButton: { type: Boolean, required: false, default: true },
    isButtonDisabled: { type: Boolean, required: false, default: true },
    buttonLabel: { type: String, required: false, default: 'Continue' },
  },
})
