












































































import { defineComponent, ref, computed, watch, onMounted, WritableComputedRef } from '@vue/composition-api'
import VerticalTabs from '@/components/VerticalTabs.vue'
import RoleResources from '@/views/Programs/Resources/RoleResources.vue'
import ResourceList from '@/views/Programs/Resources/ResourceList.vue'
import AdCommissionerResources from '@/views/Programs/Resources/AdCommissionerResources.vue'
import { Location } from 'vue-router'
import programsClient from '@/clients/programsClient'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import contentManagementClient from '@/clients/contentManagementClient'
import { Resource } from '@/models/Program/Resource'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'
import Loading from '@/elements/Loading.vue'
import PageContent from '@/components/PageContent.vue'
import ToggleSwitchInput from '@/elements/ToggleSwitchInput.vue'
import ResourceTile from './ResourceTile.vue'
import localStorageService from '@/services/localStorageService'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import store from '@/store'

export default defineComponent({
  name: 'Resources',
  components: {
    VerticalTabs,
    Loading,
    RoleResources,
    AdCommissionerResources,
    PageContent,
    ToggleSwitchInput,
    ResourceList,
    ResourceTile,
  },
  setup(props, ctx) {
    const storeleague = computed(() => store.getters.leagues.currentItem)
    const storeCamp = computed(() => store.getters.camps.currentItem)
    const storeClinic = computed(() => store.getters.clinics.currentItem)
    const storeConnect = computed(() => store.getters.connects.currentItem)

    const leagueListItem = ref<LeagueListItem | null>(null)
    const resources = ref<Resource[]>([])
    const marketingResources = ref<Resource[]>([])
    const allResources = ref<Resource[]>([])
    const featuredResources = ref<Resource[]>([])
    const loading = ref(false)
    const resourcesViewTypeId = ref('resourcesViewType')
    const resourcesViewTypeDefault = ref('v1')
    const resourcesViewTypeCurrent = ref('v1')

    const isAdCommissioner = computed(() => currentRole.value?.id === RolesEnum.ADCOMMISSIONER)

    const resourcesViewType: WritableComputedRef<string> = computed({
      get(): string {
        return localStorageService.getRaw(resourcesViewTypeId.value) ?? resourcesViewTypeDefault.value
      },
      set(t: string): void {
        localStorageService.set(resourcesViewTypeId.value, t)
      },
    })

    const classicView = ref(resourcesViewType.value === 'classic')

    watch(
      () => classicView.value,
      () => {
        const myResourcesViewType = classicView.value
          ? resourcesViewTypeDefault.value
          : resourcesViewTypeCurrent.value
        resourcesViewType.value = myResourcesViewType
      }
    )

    const directorId = 'director'
    const directorRole: TypeLeagueVolunteerRole = {
      id: 0,
      description: 'Director',
      sortOrder: 0,
      system: true,
      isGameRole: false,
      udfDefinitions: null,
      allowInOPR: true,
      allowInOVR: true,
    }

    const cheerDirectorId = 'cheer-director'
    const cheerDirectorRole: TypeLeagueVolunteerRole = {
      id: 0,
      description: 'Cheer Director',
      sortOrder: 0,
      system: true,
      isGameRole: false,
      udfDefinitions: null,
      allowInOPR: true,
      allowInOVR: true,
    }

    const programId = computed(() => ctx.root.$route.params.id)

    const isLeague = computed(() => ctx.root.$route.path.includes('league'))

    const programRoles = computed(() => {
      if (!programId.value) {
        return null
      }

      if (storeCamp.value && storeCamp.value.upwardLeagueID === programId.value) {
        return storeCamp.value.roles
      }

      if (storeleague.value && storeleague.value.upwardLeagueID === programId.value) {
        return storeleague.value.roles
      }

      if (storeClinic.value && storeClinic.value.upwardLeagueID === programId.value) {
        return [directorRole]
      }

      if (storeConnect.value && storeConnect.value.upwardLeagueID === programId.value) {
        return [directorRole]
      }

      return null
    })

    const roleId = computed(() => ctx.root.$route.params.roleId)

    const currentRoleResources = computed(() => {
      if (!currentRole.value || !currentRole.value.description) {
        return []
      }

      const currentRoleDesc = currentRole.value.description
      return resources.value.filter((r) => r.role && r.role.indexOf(currentRoleDesc) !== -1)
    })

    const currentRole = computed(() => {
      if (!roleId.value || !programRoles.value) {
        return null
      }

      if (roleId.value === directorId) {
        return directorRole
      }

      if (roleId.value === cheerDirectorId) {
        return cheerDirectorRole
      }

      return programRoles.value.find((r) => r.id.toString() === roleId.value)
    })

    const roles = computed(() => {
      if (!programRoles.value) {
        return []
      }

      const roles = programRoles.value.slice().sort((a, b) => a.sortOrder - b.sortOrder)
      return roles
    })

    const tabs = computed(() => {
      const tabData = roles.value
        .filter((role) => {
          if (!role.description || role.id === 0) {
            return false
          }

          const roleDesc = role.description
          return resources.value.some((r) => r.role && r.role.indexOf(roleDesc) !== -1)
        })
        .map((r) => ({
          text: r.description,
          to: getResourceTabRoute(`${r.id}`),
        }))

      tabData.unshift({
        text: directorRole.description,
        to: getResourceTabRoute(directorId),
      })

      return tabData
    })

    watch(
      () => roles.value,
      () => {
        if (!roleId.value && roles.value.length) {
          const path = getResourceTabRoute(directorId)
          ctx.root.$router.push(path)
        }
      },
      { immediate: true }
    )

    function getResourceTabRoute(id: string): Location {
      return {
        name: ctx.root.$route.name || undefined,
        params: {
          ...ctx.root.$route.params,
          roleId: id.toString(),
        },
      }
    }

    async function fetchProgram() {
      loading.value = true
      leagueListItem.value = await programsClient.retrieve(programId.value)
      loading.value = false
    }

    onMounted(async () => {
      window.scrollTo(0, 0)
      await fetchProgram()
      await fetchResources()
    })

    async function fetchResources() {
      try {
        resources.value = []
        const upwardLeagueID = leagueListItem.value!.upwardLeagueID! ?? ''
        const cheerProgramTypeID =
          leagueListItem.value!.cheerProgramTypeID! ?? leagueListItem.value!.programTypeID! ?? ''

        const leagueTypeAbbreviation = leagueListItem.value!.leagueTypeAbbreviation! ?? ''
        const productYear = leagueListItem.value!.myLeagueInfo!.productYear! ?? ''

        const volunteerResources = contentManagementClient.getVolunteerResources(
          upwardLeagueID,
          cheerProgramTypeID,
          'Director'
        )
        const myMarketingResources = contentManagementClient.getMarketingResources(
          leagueTypeAbbreviation,
          productYear,
          ''
        )
        const [volunteers, marketing]: Array<any> = await Promise.allSettled([
          volunteerResources,
          myMarketingResources,
        ])
        marketingResources.value = marketing.value.data.filter(dateFilter).map((r: Resource) => {
          r.leagueDirectoryTaxonomy = ['Planning / Advertising']
          return r
        })
        let myResources: Resource[] = volunteers.value.data.filter(dateFilter)
        if (isLeague.value) {
          myResources = myResources.filter(devotionTrackFilter)
        }
        resources.value = myResources
        allResources.value = myResources.concat(marketingResources.value)
        featuredResources.value = allResources.value.filter((r) => r.featuredItem)
      } catch (err) {
        console.error(err)
        loading.value = false
      }
    }

    function dateFilter(r: Resource) {
      const now = new Date()
      return (
        r.expirationDate === null ||
        r.expirationDate === '0001-01-01T00:00:00' ||
        new Date(r.expirationDate) > now
      )
    }

    function devotionTrackFilter(r: Resource) {
      return r.devotionTrack == null || r.devotionTrack == storeleague.value.typeDevotionTrackID
    }

    return {
      classicView,
      loading,
      tabs,
      isAdCommissioner,
      currentRole,
      leagueListItem,
      marketingResources,
      currentRoleResources,
      featuredResources,
      allResources,
    }
  },
})
