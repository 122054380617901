





























import { defineComponent, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { TransactionSearchByEnum } from '@/models/Dashboard/TransactionSearch'
import SearchInput from '@/elements/SearchInput.vue'
import { useRegistrationFinder } from '@/views/AdminDash/vues/RegistrationTransactionPanel/useRegistrationFinder'
import OPRTransactionItem from '@/views/AdminDash/vues/RegistrationTransactionPanel/OPRTransactionItem.vue'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  components: { OPRTransactionItem, RadioGroupInput, SearchInput, Loading },
  setup() {
    const searchByList = [
      { id: TransactionSearchByEnum.CONFIRMATION, label: 'Confirmation #' },
      { id: TransactionSearchByEnum.INVOICE, label: 'Invoice #' },
      { id: TransactionSearchByEnum.LEAGUE, label: 'League Number' },
    ]
    const loading = ref(false)
    const searchTerm = ref('')
    const searchBy = ref<TransactionSearchByEnum>(TransactionSearchByEnum.CONFIRMATION)

    const registrationFinder = useRegistrationFinder()
    const errorMessage = ref('')
    async function search() {
      loading.value = true
      try {
        await registrationFinder.findRegistration({
          accountNumber: '',
          confirmationNumber: searchBy.value == TransactionSearchByEnum.CONFIRMATION ? searchTerm.value : '',
          invoiceNumber: searchBy.value == TransactionSearchByEnum.INVOICE ? searchTerm.value : null,
          lastName: '',
          upwardLeagueID: searchBy.value == TransactionSearchByEnum.LEAGUE ? searchTerm.value : '',
          creditCardLastFour: '',
        })
        if (!registrationFinder.registrationResults.value?.length) {
          errorMessage.value = 'No results found.'
        }
      } catch (e) {
        errorMessage.value =
          'Error: ' + (e?.message || e?.errorObject?.message || 'Problem with search criteria.')
      } finally {
        loading.value = false
      }
    }

    return {
      search,
      searchBy,
      errorMessage,
      loading,
      searchTerm,
      searchByList,
      results: registrationFinder.registrationResults,
    }
  },
})
