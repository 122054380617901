

















import { defineComponent, ref, watch } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import SelectInput from '@/elements/SelectInput.vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'WaitlistExpirationModal',
  components: { Modal, SelectInput },
  props: {
    value: { type: Boolean, required: true, default: false },
    playerName: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const selected = ref('168') // 7 days in hours
    const internal = ref(false)
    const list = [
      { text: '3 hours', value: '3' },
      { text: '12 hours', value: '12' },
      { text: '24 hours', value: '24' },
      { text: '36 hours', value: '36' },
      { text: '2 days', value: '48' },
      { text: '3 days', value: '72' },
      { text: '4 days', value: '96' },
      { text: '5 days', value: '120' },
      { text: '6 days', value: '144' },
      { text: '7 days', value: '168' },
    ]

    function send() {
      const expires = dayjs().add(Number(selected.value), 'h')
      ctx.emit('selected', expires.format('YYYY-MM-DDTHH:mm:ss'))
    }

    watch(
      () => props.value,
      () => (internal.value = props.value)
    )

    watch(
      () => internal.value,
      () => ctx.emit('input', internal.value)
    )
    return { list, selected, send, internal }
  },
})
