import { LeagueSpecialEvent } from '@/GeneratedTypes/LeagueSpecialEvent'
import { LeagueSpecialEventInfo } from '@/GeneratedTypes/ListInfo/LeagueSpecialEventInfo'

export function getEmptyLeagueSpecialEvent(): LeagueSpecialEvent {
  return {
    leagueID: 0,
    eventID: 0,
    typeProgramID: 'NONE',
    divisionID: 0,
    teamID: 0,
    facilityID: 0,
    eventStart: null,
    eventEnd: null,
    eventLabel: '',
    eventDescription: '',
  }
}

export function getLSEfromLSEInfo(lseInfo: LeagueSpecialEventInfo): LeagueSpecialEvent {
  return {
    leagueID: lseInfo.leagueID,
    eventID: lseInfo.eventID,
    typeProgramID: lseInfo.typeProgramID,
    divisionID: lseInfo.divisionID,
    teamID: lseInfo.teamID,
    facilityID: lseInfo.facilityID,
    eventStart: lseInfo.eventStart,
    eventEnd: lseInfo.eventEnd,
    eventLabel: lseInfo.eventLabel ?? '',
    eventDescription: lseInfo.eventDescription,
  }
}
