















import { defineComponent, watch, onMounted, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'

export default defineComponent({
  name: 'YesNoInput',
  components: {
    RadioGroupInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    value: { type: Boolean, required: true, default: false },
    subText: { type: String, default: '', required: false },
    subTextWithLink: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const internalValue = ref(false)

    const radioItemList: object[] = [
      {
        Value: true,
        Text: 'Yes',
      },
      {
        Value: false,
        Text: 'No',
      },
    ]

    function onChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    onMounted(() => {
      internalValue.value = props.value
    })

    return {
      internalValue,
      radioItemList,
      onChange,
    }
  },
})
