

































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import inviteClient from '@/clients/inviteClient'
import UpwardValidationObserver from '@/components/UpwardValidationObserver.vue'

@Component({
  components: {
    Modal,
    TextInput,
    MemoInput,
    YesNoInput,
    UpwardValidationObserver,
  },
})
export default class AddUserModal extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  private readonly value!: boolean

  @Prop({ type: String, required: true })
  private readonly accountNumber!: string

  modalIsVisible = this.value
  friendlyName = ''
  email = ''
  phone = ''
  message = ''
  sendSms = false
  role = ''
  private hasValidationError = false

  @Watch('value')
  handleValueChanged() {
    if (this.value !== this.modalIsVisible) {
      this.modalIsVisible = this.value
    }
  }

  @Watch('modalIsVisible')
  handleModalIsVisibleChanged() {
    if (this.value !== this.modalIsVisible) {
      this.$emit('input', this.modalIsVisible)
    }
  }

  @Watch('sendSms')
  handleSendSmsChanged() {
    if (!this.sendSms) {
      this.phone = ''
    }
  }

  async sendInvite() {
    this.hasValidationError = false
    const obs = this.$refs.observer as InstanceType<typeof UpwardValidationObserver>
    const isValid = await obs.validate()

    if (!isValid) {
      this.hasValidationError = true
      return
    }

    this.modalIsVisible = false

    await inviteClient.invitePartnerUser(
      this.email,
      this.accountNumber,
      this.friendlyName,
      this.role,
      this.message,
      this.phone,
      null
    )

    this.$emit('inviteSent')
  }
}
