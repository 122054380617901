import { Vue, Component } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import * as leaguesStore from '@/store/leagues'

import { Getter, Action, Mutation } from 'vuex-class'
import { League } from '@/GeneratedTypes/League'
import { PromptToSavePayload } from '@/models/PromptToSavePayload'
import * as promptToSaveStore from '@/store/promptToSave'

@Component({
  beforeRouteLeave(route, redirect, next) {
    const self = this as LeagueSetupMixin

    self.onNavigation({
      save: async () => {
        return await self.save()
      },
      continue: () => {
        next()
      },
    } as PromptToSavePayload)
  },
})
export default class LeagueSetupMixin extends Vue {
  protected league = {} as League
  protected initialLoadDone = false
  protected loading = true
  protected nextRoute = ''
  protected beforeSave: null | (() => Promise<void | boolean>) = null
  protected afterUpdateExisting: null | (() => Promise<void> | void) = null
  protected afterSaveNew: null | (() => Promise<void>) = null

  @Getter(leaguesStore.getterNames.currentItem, { namespace: leaguesStore.namespace })
  protected readonly storeLeague!: League

  @Getter(leaguesStore.getterNames.isUpwardSelect, { namespace: leaguesStore.namespace })
  protected readonly isUpwardSelect!: boolean

  @Action(leaguesStore.actionNames.cache, { namespace: leaguesStore.namespace })
  private readonly cache!: ({ item }: { item: League }) => boolean

  @Action(leaguesStore.actionNames.validate, { namespace: leaguesStore.namespace })
  private readonly validate!: ({ item, ruleSet }: { item: League; ruleSet: string }) => boolean

  @Action(leaguesStore.actionNames.loadFromCache, { namespace: leaguesStore.namespace })
  private readonly loadFromCache!: () => boolean

  @Action(leaguesStore.actionNames.update, { namespace: leaguesStore.namespace })
  private readonly update!: ({ item }: { item: League }) => boolean

  @Mutation(promptToSaveStore.mutationNames.setIsDirty, { namespace: promptToSaveStore.namespace })
  protected readonly setIsDirty!: ({ isDirty }: { isDirty: boolean }) => void

  @Action(promptToSaveStore.actionNames.onNavigation, { namespace: promptToSaveStore.namespace })
  private readonly onNavigation!: ({}: PromptToSavePayload) => void

  private get isDigital(): boolean {
    return this.$route.path.indexOf('digitalleague') > 0
  }

  protected get isByAge() {
    return this.league.ageCutoffDate != null
  }

  private get ruleSet() {
    const pathParts = this.$route.path.split('/')

    if (!pathParts.length) {
      return ''
    }

    const thisPath = pathParts[pathParts.length - 1]

    switch (thisPath) {
      case 'information':
        return this.isDigital ? '' : 'PROGRAMINFO'
      case 'dates':
        return 'KEYDATES'
      case 'leadership':
        return 'LEADERSHIP'
      case 'partnership':
        return 'PARTNERSHIP'
      case 'products':
        return 'PRICING'
      default:
        return ''
    }
  }

  protected async saveAndContinue() {
    const result = await this.save()
    if (result != false) this.continue()
  }

  private async created() {
    this.league = { ...cloneDeep(this.storeLeague) }

    if (!this.league.typeLeagueID) {
      try {
        await this.loadFromCache()
      } catch (err) {
        this.$router.push('/programs/new/')
      }
    }

    this.loading = false
    this.initialLoadDone = true
  }

  protected async save() {
    if (this.beforeSave) {
      const r = await this.beforeSave()
      if (r === false) return false
    }

    if (this.league.upwardLeagueID) {
      await this.updateExistingLeague()

      if (this.afterUpdateExisting) {
        await this.afterUpdateExisting()
      }
    } else {
      await this.saveNewLeague()

      if (this.afterSaveNew) {
        await this.afterSaveNew()
      }
    }

    this.setIsDirty({ isDirty: false })
  }

  private continue() {
    if (this.nextRoute) {
      this.$router.push(this.nextRoute)
    }
  }

  private async saveNewLeague() {
    if (this.league.upwardLeagueID) {
      return
    }

    this.loading = true
    try {
      await this.validate({ item: this.league, ruleSet: this.ruleSet })
      await this.cache({ item: this.storeLeague })
    } catch (err) {
      throw err
    } finally {
      this.loading = false
    }
  }

  private async updateExistingLeague() {
    if (!this.league.upwardLeagueID) {
      return
    }

    this.loading = true
    try {
      await this.update({ item: this.league })
    } catch (err) {
      throw err
    } finally {
      this.loading = false
    }
  }

  mounted() {
    this.$nextTick(() => this.$watch('league', this.onLeagueChange, { deep: true }))
  }

  private onLeagueChange() {
    this.setIsDirty({ isDirty: true })
  }
}
