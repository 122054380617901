



















































import { millisecondsToTimeString } from '@/lib/support/utilities/time/time'

import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { getEmptyLeagueFacility } from '@/lib/support/models/LeagueFacility/data'
import dayjs from 'dayjs'
import { ScheduleBlocks } from '@/models/elements/Scheduler'
import {
  AugmentedFacilityAvailability,
  dayIDToName,
  dayNameToID,
  FacilityAvailabilityByDay,
} from '@/views/Programs/Scheduling/Games/ext/augmented_facility_availability'
import { EditIcon, DeleteIcon } from '@/elements/Icons'

@Component({
  components: {
    EditIcon,
    DeleteIcon,
  },
  methods: { dayjs, dayNameToID, dayIDToName, millisecondsToTimeString },
})
export default class FacilitySummaryBox extends Vue {
  @Prop({ required: true, default: () => getEmptyLeagueFacility(), type: Object })
  facility!: LeagueFacility

  @Prop({ required: true, default: () => [], type: Array })
  superSchedule!: ScheduleBlocks[]

  @Prop({ required: true, default: () => [], type: Array })
  subSchedule!: ScheduleBlocks[]

  @Prop({ type: Boolean, default: true, required: false })
  private readonly upArrowEnabled!: boolean

  @Prop({ type: Boolean, default: true, required: false })
  private readonly downArrowEnabled!: boolean

  isCollapsed = false

  @Emit()
  editClicked() {
    return this.facility.facilityID
  }

  @Emit()
  removeClicked() {
    return this.facility
  }

  @Emit()
  upClicked() {
    return this.facility.facilityID
  }

  @Emit()
  downClicked() {
    return this.facility.facilityID
  }

  get facilityAvailabilityByDay(): AugmentedFacilityAvailability[] {
    return FacilityAvailabilityByDay(this.superSchedule, this.subSchedule)
  }
}
