import { IndividualPhoneInfo } from '@/GeneratedTypes/ListInfo/IndividualPhoneInfo'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'

export function individualPhoneToIndividualPhoneInfo(i: IndividualPhone): IndividualPhoneInfo {
  return {
    phoneID: i.phoneID,
    phoneNumber: i.phoneNumber,
    priority: i.priority,
    extension: i.extension,
    typePhoneID: i.typePhoneID,
    individualID: 0,
  }
}
