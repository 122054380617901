import { RouteConfig } from 'vue-router'
import Index from '@/views/Training/Index.vue'
import Training from '@/views/Training/Training.vue'

const baseName = 'Training'

export default [
  {
    path: '/training',
    component: Index,
    children: [
      {
        path: '',
        redirect: '/training/annual',
      },
      {
        path: '/training/:pageName',
        name: `${baseName}-Page`,
        component: Training,
      },
    ],
  },
] as RouteConfig[]
