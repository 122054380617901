import restService from '@/services/restService'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { DraftResultRecord } from '@/models/Reports/DraftResultRecord'
import { ReportTemplateResource } from '@/models/Reports/ReportTemplateResource'

export interface CoachLinksOverageType {
  CoachIndividualID: number
  CoachName: string
  DivisionName: string
  FemaleGradesToCoach: string | null
  MaleGradesToCoach: string | null
  Grade: string
  Gender: string
  ParticipantName: string
}

export interface CoachLinksBrokenReportType {
  ParticipantName: string
  RequestedCoachName: string
  DivisionName: string
}

export interface CarpoolLinksBrokenReportType {
  ParticipantName: string
  RequestedParticipantName: string
  DivisionName: string
}

export interface EvaluationsMissingType {
  ParticipantName: string
  Gender: string
  Grade: string
  ActivityText: string
  ActivityValue: string | null
  ParticipantID: number
}

const baseUrl = 'reports'

export async function getCoachLinksBroken(id: UpwardLeagueIDType, typeProgramId: string, divisionId: number) {
  const results = await restService.post<Array<CoachLinksBrokenReportType>>(
    `${baseUrl}/${id}/coachlinksbroken`,
    {
      parameters: {
        DivisionID: divisionId,
        TypeProgramID: typeProgramId,
      },
    }
  )
  if (results.isSuccess) {
    return results.data ?? []
  } else {
    throw new Error('Error in coachlinksbroken')
  }
}

export async function getCoachLinksOverage(
  id: UpwardLeagueIDType,
  typeProgramId: string,
  divisionId: number
) {
  const results = await restService.post<Array<CoachLinksOverageType>>(`${baseUrl}/${id}/coachlinksoverage`, {
    parameters: {
      DivisionID: divisionId,
      TypeProgramID: typeProgramId,
    },
  })
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in coachlinksoverage')
  }
}

export async function getCarPoolLinks(id: UpwardLeagueIDType, typeProgramId: string, divisionId: number) {
  const results = await restService.post<Array<CarpoolLinksBrokenReportType>>(
    `${baseUrl}/${id}/carpoollinksbroken`,
    {
      parameters: {
        DivisionID: divisionId,
        TypeProgramID: typeProgramId,
      },
    }
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in carpoollinksbroken')
  }
}

export async function getEvaluationsMissing(
  id: UpwardLeagueIDType,
  typeProgramId: string,
  divisionId: number
) {
  const results = await restService.post<Array<EvaluationsMissingType>>(
    `${baseUrl}/${id}/evaluationsmissing`,
    {
      parameters: {
        DivisionID: divisionId,
        TypeProgramID: typeProgramId,
      },
    }
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in missing evaluations report')
  }
}

/**
 * get a report from the server as a generic request to the report service endpoint
 * @param reportName
 * @param upwardLeagueId - league id
 * @param typeProgramId - optional, defaults the parameter for most reports
 * @param params - parameter object when specified becomes the parameters for the body of the report, false and undefined dont' go in the parameters for the report
 */
export async function getReport(
  reportName: string,
  upwardLeagueId: string,
  typeProgramId: string,
  params?: Record<string, unknown> | undefined | boolean
): Promise<Record<string, unknown>[] | null> {
  let body = {
    parameters: {},
  }
  if (typeProgramId && !params) {
    body = {
      parameters: {
        TypeProgramID: typeProgramId,
      },
    }
  }
  if (params) {
    body.parameters = params
  }
  const result = await restService.post<any[]>(`${baseUrl}/${upwardLeagueId}/${reportName}`, body)

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving report.')
  }
}

export async function saveAs(jsonData: string, saveType: string, fileName: string): Promise<Blob | null> {
  const result = await restService.post<Blob>(
    `${baseUrl}/savespreadsheet`,
    {
      JSONData: jsonData,
      saveType: saveType,
      url: `${baseUrl}/savespreadsheet`,
      fileName: fileName,
    },
    {
      responseType: 'blob',
    }
  )
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred saving the spreadsheet file.')
  }
}

const getDraftLog = async (
  upwardLeagueId: string,
  typeProgramId: string,
  divisionId: number
): Promise<DraftResultRecord[] | null> => {
  const result = await restService.post<DraftResultRecord[]>(`${baseUrl}/${upwardLeagueId}/draftlog`, {
    parameters: {
      DivisionID: divisionId,
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the draft report.')
  }
}

const getEvaluationData = async (
  upwardLeagueId: string,
  accountNumber: string,
  createBlankReport: boolean,
  typeProgramId: string,
  showAll = '1',
  gender = 'E',
  filterByDate = false,
  filterByGrade = false,
  startDate = '1900-01-01',
  endDate = '2099-01-01',
  startGrade = '0',
  endGrade = '0',
  noEvalScoresOnly = false
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/evaluation`, {
    parameters: {
      AccountNumber: accountNumber,
      ShowAll: showAll,
      Gender: gender,
      FilterByDate: filterByDate ? '1' : '0',
      FilterByGrade: filterByGrade ? '1' : '0',
      StartDate: startDate,
      EndDate: endDate,
      StartGrade: startGrade,
      EndGrade: endGrade,
      NoEvalScoresOnly: noEvalScoresOnly ? '1' : '0',
      CreateBlankReport: createBlankReport,
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the evaluation report.')
  }
}

const getEvaluationActivityData = async (
  upwardLeagueId: string,
  accountNumber: string,
  typeProgramId: string,
  startGrade = '0',
  endGrade = '0'
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/evaluationbyactivity`, {
    parameters: {
      AccountNumber: accountNumber,
      StartGradeID: startGrade,
      EndGradeID: endGrade,
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the evaluation report.')
  }
}

const getReportTemplate = async (templateID: string): Promise<ReportTemplateResource | null> => {
  const result = await restService.get<ReportTemplateResource>(
    `contentmanagement/reporttemplates/${templateID}`
  )

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the report template.')
  }
}

const getEvaluationQuestionsData = async (
  upwardLeagueId: string,
  accountNumber: string,
  typeProgramId: string
): Promise<string | null> => {
  // this will go away once the api is update to use string instead of int.
  //const typeProgram = typeProgramId.toLocaleLowerCase() == 'football' ? 4 : 3

  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/evaluationquestionssubreport`, {
    parameters: {
      AccountNumber: accountNumber,
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the evaluation questions report.')
  }
}

const getEvaluationSizeData = async (
  upwardLeagueId: string,
  accountNumber: string,
  leagueId: number,
  typeProgramId: string,
  createBlankReport: boolean
): Promise<string | null> => {
  // this will go away once the api is update to use string instead of int.
  //const typeProgram = typeProgramId.toLocaleLowerCase() == 'football' ? 4 : 3

  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/evaluationsizingsubreport`, {
    parameters: {
      LeagueID: leagueId,
      AccountNumber: accountNumber,
      TypeProgramID: typeProgramId,
      CreateBlankReport: createBlankReport,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the evaluation size report.')
  }
}

const getCoachRoster = async (
  upwardLeagueId: string,
  accountNumber: string,
  teamId: string,
  divisionId: string,
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/roster`, {
    parameters: {
      AccountNumber: accountNumber,
      TeamID: teamId ?? '0',
      DivisionID: divisionId ?? '0',
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the coach roster report.')
  }
}

const getCoachRosterByTeamRosterID = async (
  upwardLeagueId: string,
  accountNumber: string,
  typeProgramID: string,
  teamId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/teamroster`, {
    parameters: {
      AccountNumber: accountNumber,
      TypeProgramID: typeProgramID,
      UpwardTeamID: teamId ?? '',
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the coach roster report.')
  }
}

const getCoachSubReport = async (
  upwardLeagueId: string,
  accountNumber: string,
  teamId: string,
  divisionId: string,
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/coachsubreport`, {
    parameters: {
      AccountNumber: accountNumber,
      TeamID: teamId === '0' ? '' : teamId ?? '',
      DivisionID: divisionId === '0' ? '' : divisionId ?? '',
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the coach sub report.')
  }
}

const getMailingLabels = async (
  upwardLeagueId: string,
  accountNumber: string,
  teamId: string,
  divisionId: string,
  includeParticipants: boolean,
  includeVolunteers: boolean,
  filterByGrade = false,
  startGrade = '0',
  endGrade = '0',
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/mailinglabel`, {
    parameters: {
      AccountNumber: accountNumber,
      TeamID: teamId ?? '0',
      DivisionID: divisionId ?? '0',
      IncludeParticipants: includeParticipants ? '1' : '0',
      IncludeVolunteers: includeVolunteers ? '1' : '0',
      FilterByGrade: filterByGrade ? '1' : '0',
      StartGrade: startGrade,
      EndGrade: endGrade,
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the mailing label report.')
  }
}

const getGameSchedule = async (
  upwardLeagueId: string,
  divisionId: string,
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/gameschedule`, {
    parameters: {
      TypeProgramID: typeProgramId,
      DivisionID: divisionId,
      PreserveColumnNames: true,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the game schedule.')
  }
}

const getGameScheduleByTeam = async (
  upwardLeagueId: string,
  divisionId: string,
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/gameschedulebyteam`, {
    parameters: {
      TypeProgramID: typeProgramId,
      DivisionID: divisionId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the game schedule by team.')
  }
}

const getPracticeSchedule = async (
  upwardLeagueId: string,
  divisionId: string,
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/practiceschedule`, {
    parameters: {
      TypeProgramID: typeProgramId,
      DivisionID: divisionId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the practice schedule.')
  }
}

const getSiblingsReport = async (upwardLeagueId: string, accountNumber: string): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/sibling`, {
    parameters: {
      AccountNumber: accountNumber,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the sibling report.')
  }
}

const getParticipantIntroReport = async (
  upwardLeagueId: string,
  accountNumber: string,
  teamId: string,
  divisionId: string,
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/participantintroduction`, {
    parameters: {
      AccountNumber: accountNumber,
      TeamID: teamId === '0' ? '' : teamId ?? '',
      DivisionID: divisionId === '0' ? '' : divisionId ?? '',
      TypeProgramID: typeProgramId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the participant introduction report.')
  }
}

const getPaymentReportData = async (
  upwardLeagueId: string,
  accountNumber: string,
  paymentStatus: number
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/payments`, {
    parameters: {
      AccountNumber: accountNumber,
      PaymentStatus: paymentStatus,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the participant payment report.')
  }
}

const getShippedCompletedOrdersData = async (
  statusBeginDate: string,
  statusEndDate: string,
  orderType: string,
  orderStatus: string,
  pageSize: number
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/ordersadmin/shippedcompletedorders`, {
    parameters: {
      StatusBeginDate: statusBeginDate,
      StatusEndDate: statusEndDate,
      OrderType: orderType,
      OrderStatus: orderStatus,
      PageSize: pageSize,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the Shipped Completed Orders report.')
  }
}

const getMinistryImpactData = async (
  accountNumber: string,
  compareType: string,
  beginDate: string,
  endDate: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/admindashboard/ministryimpact`, {
    parameters: {
      accountNumber: accountNumber,
      compareType: compareType,
      beginDate: beginDate,
      endDate: endDate,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the Ministry Impact report.')
  }
}

const getOutstandingBalanceReportData = async (
  upwardLeagueId: string,
  accountNumber: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/outstandingbalance`, {
    parameters: {
      AccountNumber: accountNumber,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the outstanding balance report.')
  }
}

const getRefereeSchedule = async (
  upwardLeagueId: string,
  divisionId: string,
  typeProgramId: string
): Promise<string | null> => {
  const result = await restService.post<string>(`${baseUrl}/${upwardLeagueId}/refereeschedule`, {
    parameters: {
      TypeProgramID: typeProgramId,
      DivisionID: divisionId,
    },
  })

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving the schedule.')
  }
}

export default {
  getCoachLinksBroken,
  getCarPoolLinks,
  getCoachLinksOverage,
  getEvaluationsMissing,
  getReport,
  saveAs,
  getDraftLog,
  getEvaluationData,
  getEvaluationActivityData,
  getReportTemplate,
  getEvaluationQuestionsData,
  getEvaluationSizeData,
  getCoachRoster,
  getCoachSubReport,
  getMailingLabels,
  getGameSchedule,
  getGameScheduleByTeam,
  getSiblingsReport,
  getPracticeSchedule,
  getCoachRosterByTeamRosterID,
  getParticipantIntroReport,
  getPaymentReportData,
  getOutstandingBalanceReportData,
  getRefereeSchedule,
  getShippedCompletedOrdersData,
  getMinistryImpactData,
}
