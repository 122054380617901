import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import { ref } from '@vue/composition-api'
import volunteersClient from '@/clients/volunteersClient'

export function useCoaches() {
  const coaches = ref<LeagueCoachProgramInfoFLAT[]>([])

  async function refreshCoaches(leagueID: string) {
    coaches.value = (await volunteersClient.getCoachProgramListFLAT(leagueID, null, null)) ?? []
  }

  async function refreshCoachesById(
    leagueID: string,
    typeProgramID: string,
    divisionID: number,
    isOnlyMatching: boolean
  ) {
    if (isOnlyMatching) {
      coaches.value =
        (await volunteersClient.getCoachProgramListFLAT(leagueID, typeProgramID, divisionID)) || []
    } else {
      coaches.value = (await volunteersClient.getCoachProgramListFLAT(leagueID, null, null)) || []
    }
  }

  function incrementCoachTeamCount(coachid: number, increment: number) {
    const coach = coaches.value.find((x) => x.individualID == coachid)
    if (coach) {
      coach.teamsCoachedCount += increment
    }
  }

  return {
    coaches,
    refreshCoaches,
    refreshCoachesById,
    incrementCoachTeamCount,
  }
}
