import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'

export function getEmptySavedProductOfferingConfigDetail(): SavedProductOfferingConfigDetail {
  return {
    productOfferingID: 0,
    categoryID: 0,
    productID: 0,
    typeColorID: 'N/A',
    typeProductOptionID: '',
    lockedInCost: 0,
    costToCharge: 0,
    gradesByGender: null,
  }
}
