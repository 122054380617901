

































































import { Action } from 'vuex-class'
import CampSetupMixin from '@/views/Camps/CampSetupMixin'
import SelectInput from '@/elements/SelectInput.vue'
import IntegerPicker from '@/elements/IntegerPicker.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'

import Loading from '@/elements/Loading.vue'
import PartnerForm from '@/components/PartnerForm.vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount'

import * as partnesrStore from '@/store/partners'
import { Partner } from '@/models/Partner'

@Component({
  components: {
    PartnerForm,
    Loading,
    SelectInput,
    IntegerPicker,
    SaveContinueButton,
  },
})
export default class Partnership extends Mixins(CampSetupMixin) {
  @Action(partnesrStore.actionNames.retrieveAndSetPartners, { namespace: partnesrStore.namespace })
  private readonly retrieveAndSetPartners!: ({ id }: { id: string }) => Partner[]

  private partnership = ''
  private churchCount = 1
  private partnerDirty = false

  mounted() {
    if (this.camp.upwardLeagueID) {
      this.nextRoute = `/programs/camp/${this.camp.upwardLeagueID}/setup/products`
    } else {
      this.nextRoute = '/programs/camp/setup/products'
    }

    this.churchCount = this.camp.accounts?.length ?? 1
    this.partnership = this.churchCount > 1 ? 'one' : 'solo'
    this.$nextTick(() => this.$watch('camp.accounts', this.partnerChanged, { deep: true }))
    this.afterUpdateExisting = this.updatePartner
    this.afterSaveNew = this.updatePartner
  }
  private async updatePartner() {
    if (this.partnerDirty) {
      await this.retrieveAndSetPartners({ id: this.camp.upwardLeagueID ?? '' })
    }
  }

  private partnerChanged() {
    this.partnerDirty = true
  }

  @Watch('churchCount')
  private onChurchCountChanged() {
    const accountDelta = this.churchCount - (this.camp.accounts?.length ?? 1)

    if (this.camp.accounts) {
      for (let i = 0; i < accountDelta; i++) {
        this.camp.accounts.push({
          accountNumber: (-this.camp.accounts.length).toString(),
          leagueOwner: false,
          expectLeagueOrder: false,
          coachShirtRequired: false,
          grades: this.camp.accounts[0].grades,
        } as LeagueAccount)
      }
    }

    if (this.camp.accounts && accountDelta < 0) {
      this.camp.accounts = this.camp.accounts?.splice(0, this.churchCount)
    }
  }

  private get typeProgramId(): string {
    if (this.camp.programs && this.camp.programs.length > 0) {
      return this.camp.programs[0].typeProgramID
    }
    return ''
  }
}
