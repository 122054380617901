import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
export function getEmptyUpwardProgramTypeID(): UpwardProgramTypeID {
  return {
    averageNumberTeams: 0,
    averageNumberPlayers: 0,
    maxEvalScore: 0,
    draftVariance: 0,
    minPlayersPerTeam: 0,
    maxPlayersPerTeam: 0,
    suggestedPlayersPerTeam: 0,
    parentProgramID: 0,
    playersPerCoachShirt: 0,
    orderUnassignedParticipant: false,
    shortDescription: '',
    isCamp: false,
    isCheer: false,
    isPilot: false,
    active: false,
    numberOfSegments: 0,
    id: 0,
    upwardTypeID: '',
    description: '',
  }
}

export function getEmptyUpwardProgramTypeIDList(): UpwardProgramTypeID[] {
  return [getEmptyUpwardProgramTypeID()]
}
