














import Modal from '@/components/Modal.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { ModalConfirmationSettings } from '@/lib/components/ModalConfirmation/settings'
export default defineComponent({
  name: 'ModalConfirmation',
  components: { Modal },
  data() {
    return {
      message: '',
      title: '',
      showOk: true,
      showCancel: true,
      showClose: true,
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resolvePromise: function (result: boolean): void {
        /**/
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      rejectPromise: function (result: boolean): void {
        /**/
      },
      isSmall: false,
    }
  },
  methods: {
    show(message?: string, settings?: ModalConfirmationSettings) {
      return new Promise((resolve, reject) => {
        this.message = message ?? ''
        this.title = settings?.title ?? ''
        this.showOk = settings?.showOk ?? true
        this.showCancel = settings?.showCancel ?? true
        this.showClose = settings?.showClose ?? true
        this.resolvePromise = (result: boolean) => {
          resolve(result)
          this.showModal = false
        }
        this.rejectPromise = (result: boolean) => {
          reject(result)
          this.showModal = false
        }
        this.isSmall = settings?.isSmall ?? false
        this.showModal = true
        this.okLabel = settings?.okLabel ?? 'Ok'
        this.cancelLabel = settings?.cancelLabel ?? 'Cancel'
      })
    },
    hide() {
      this.showModal = false
    },
  },
  setup() {
    const showModal = ref(false)
    return { showModal }
  },
})
