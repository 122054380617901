














import { defineComponent } from '@vue/composition-api'
import StepHeader from '@/elements/StepperHeader.vue'

export default defineComponent({
  name: 'StepperStep',
  components: {
    StepHeader,
  },
  props: {
    complete: { type: Boolean, required: false, default: false },
    editable: { type: Boolean, required: false, default: false },
    step: { type: [String, Number], required: false, default: '1' },
    title: { type: String, required: false, default: '' },
    tag: { type: String, required: false, default: '' },
    caption: { type: String, required: false, default: '' },
    color: { type: String, required: false, default: 'primary' },
  },
  setup(props, ctx) {
    /**
     * Pass up a click event only when you have an active element
     * @param e
     */
    function handleClick(e: Event) {
      if (props.editable) {
        ctx.emit('click', e)
      }
    }

    return {
      handleClick,
    }
  },
})
