import { RegistrationTransactionParticipantExtended } from '@/models/Registration/RegistrationTransactionParticipantExt'
import { registrationClient, RegistrationSearchParams } from '@/clients/registrationClient'
import { ref } from '@vue/composition-api'

export function useRegistrationFinder() {
  const registrationResults = ref<RegistrationTransactionParticipantExtended[]>([])
  async function findRegistration(terms: RegistrationSearchParams) {
    registrationResults.value = (await registrationClient.search(terms)) ?? []
  }

  return { registrationResults, findRegistration }
}
