





















































































































































































import { defineComponent, PropType, ref, watch, reactive, computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import store from '@/store'

import { UpwardLeagueIDType } from '@/lib/support/models/League/data'

import inviteClient from '@/clients/inviteClient'
import { registrationClient } from '@/clients/registrationClient'

import Loading from '@/elements/Loading.vue'
import SelectInput from '@/elements/SelectInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import Alert from '@/components/Alert.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import WaitlistFormModal from '@/views/Programs/Reports/WebviewReports/WaitlistFormModal.vue'

import { WaitlistActionsEnum, InviteStatus } from '@/views/Programs/Reports/WebviewReports/lib/Waitlist'
import WaitlistExpirationModal from '@/views/Programs/Reports/WebviewReports/WaitlistExpirationModal.vue'
import ActionProgress from '@/views/Programs/Reports/WebviewReports/ActionProgress.vue'
import WaitlistActions from '@/views/Programs/Reports/WebviewReports/WaitlistActions.vue'

import { RegistrationInvite } from '@/models/Registration/RegistrationInvite'
import { RegistrationWaitlist } from '@/models/RegistrationWaitlist'

import { formatPhoneNumber } from '@/services/phoneNumberService'
import { maxStringLength } from '@/filters/maxStringLength'
import InfoModal from '@/components/InfoModal.vue'
import SendEmailModal from '@/components/Communication/SendEmailModal.vue'
import router from '@/router/index'
enum RemoveEnum {
  BULK_DECLINED,
  BULK_ACCECPTED,
  SINGLE,
}

export default defineComponent({
  name: 'WaitlistReport',
  components: {
    ConfirmationModal,
    Alert,
    WaitlistActions,
    WaitlistExpirationModal,
    Loading,
    CheckboxInput,
    ActionProgress,
    SelectInput,
    SendEmailModal,
    InfoModal,
    WaitlistFormModal,
  },
  props: {
    reportData: {
      type: Array as PropType<RegistrationWaitlist[]>,
      required: true,
      default: () => [],
    },
    league: {
      type: String as PropType<UpwardLeagueIDType>,
      required: true,
      default: '',
    },
    leagueNumber: {
      type: Number,
      required: true,
      default: 0,
    },
    program: {
      type: String,
      required: true,
      default: '',
    },
    allowWaitlist: { type: Boolean, require: true },
  },
  setup(props, ctx) {
    const internalReportData = reactive<{ data: RegistrationWaitlist[] }>({ data: [] })
    const fmtPhone = formatPhoneNumber
    const maxLen = maxStringLength
    const idUpdating = ref(0)
    const isConfirming = ref(false)
    const progress = ref<string | null>(null)
    const removeTargetDesc = ref('')
    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)

    /*
     * Filtering
     */
    const inviteStatusDisplay = [
      { value: 'ALL', text: 'All' },
      { value: 'WAITING', text: 'Waiting for Invitation' },
      { value: 'INVITATIONSENT', text: 'Invitation Sent' },
      { value: 'INVITATIONACCEPTED', text: 'Invitation Accepted' },
      { value: 'INVITATIONEXPIRED', text: 'Invitation Expired' },
      { value: 'INVITATIONDECLINED', text: 'Invitation Declined' },
    ]
    const filter = ref(inviteStatusDisplay[0].value)

    /*
     * Manage Single Row Actions
     */

    const pendingID = ref(0)
    function actionClicked(id: number, action: string) {
      setPendingParticipant(id)
      switch (action) {
        case WaitlistActionsEnum.SEND:
          return sendInvite()
        case WaitlistActionsEnum.CANCEL:
          return cancelInvite()
        case WaitlistActionsEnum.REMOVE:
          return removeFromWaitlist()
      }
    }

    function participantById(id: number) {
      return internalReportData.data.find((x) => x.ID == id)
    }

    function setPendingParticipant(id: number) {
      const participant = participantById(id)
      removeTargetDesc.value = participant ? `${participant.ChildFirstName} ${participant.ChildLastName}` : ''
      pendingID.value = id
    }

    /**
     * Export
     **/
    function downloadCsv() {
      const headerTitles = [
        'Player Name',
        isByAge.value ? 'Age' : 'Grade',
        'Gender',
        'Contact Name',
        'Phone',
        'Email',
        'Submitted',
        'Invite Sent',
        'Invite Expired',
        'Invite Declined',
        'Status',
        'Notes',
      ]

      const csvLines = ['data:text/csv;charset=utf-8,', headerTitles.map((t) => `"${t}"`).join(',')]

      internalReportData.data.map((r) => {
        const rowValues = [
          r.ChildFirstName + ' ' + r.ChildLastName,
          r.Grade,
          r.Gender,
          r.ParentFullName,
          r.Phone,
          r.Email,
          waitlistDate(r.WaitListDate),
          waitlistDate(r.InvitationSent),
          waitlistDate(r.InvitationExpire),
          waitlistDate(r.InvitationDeclined),
          r.UpwardWaitListStatusID,
          r.Note,
        ]

        const csvLine = rowValues.map((v) => `"${(v || '').replace(/["]+/g, '')}"`).join(',')
        csvLines.push(csvLine)
      })

      const csvData = csvLines.join('\n')
      var encodedUri = encodeURI(csvData)
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'waitlist_report.csv')
      document.body.appendChild(link) // Required for FF

      link.click()
    }

    /*
     * Remove from waitlist
     */
    const removeTarget = ref<number | null>(null)
    async function confirmRemove(b: boolean) {
      if (b) {
        switch (removeTarget.value) {
          case RemoveEnum.BULK_DECLINED:
            return removeAllDeclined()
          case RemoveEnum.BULK_ACCECPTED:
            return removeAllAccepted()
          case RemoveEnum.SINGLE:
            await remove(pendingID.value)
        }
      }
      isConfirming.value = false
      removeTargetDesc.value = ''
      removeTarget.value = null
    }

    function removeFromWaitlist() {
      isConfirming.value = true
      removeTarget.value = RemoveEnum.SINGLE
    }

    function removeAccepted() {
      removeTargetDesc.value = 'all accepted invitations'
      isConfirming.value = true
      removeTarget.value = RemoveEnum.BULK_ACCECPTED
    }

    function removeDeclined() {
      removeTargetDesc.value = 'all declined invitations'
      isConfirming.value = true
      removeTarget.value = RemoveEnum.BULK_DECLINED
    }

    async function remove(id: number) {
      idUpdating.value = id
      await inviteClient.unwaitlist(props.league, id)
      internalReportData.data = internalReportData.data.filter((x) => x.ID != id)
      idUpdating.value = 0
    }

    async function removeAllAccepted() {
      isConfirming.value = false
      const accepted = internalReportData.data
        .filter((x) => x.UpwardWaitListStatusID == InviteStatus.INVITATIONACCEPTED)
        .map((x) => x.ID)
      await removeBulk(accepted, 'accepted', remove)
    }

    async function removeAllDeclined() {
      isConfirming.value = false
      const declined = internalReportData.data
        .filter((x) => x.UpwardWaitListStatusID == InviteStatus.INVITATIONDECLINED)
        .map((x) => x.ID)
      await removeBulk(declined, 'declined', remove)
    }

    async function removeBulk(ids: number[], type: string, fnc: (id: number) => void) {
      let i = 0
      if (!ids.length) {
        progress.value = `No ${type} invitations to remove`
        return
      }

      if (ids.length) {
        progress.value = `Removing all ${type} ...`
        for (const id of ids) {
          i++

          progress.value = `Removing all ${type}... ${Math.floor((i * 100) / ids.length)}%`
          await fnc(id)
        }

        progress.value = `Removed ${ids.length} ${type} invitations`
        internalReportData.data = internalReportData.data.filter((x) => !ids.includes(x.ID))
      }
    }

    /**
     * Cancel Invite
     */
    async function cancelInvite() {
      idUpdating.value = pendingID.value
      const i = internalReportData.data.find((x) => x.ID == pendingID.value)
      if (!i) return
      const results = await registrationClient.update(props.league, pendingID.value, {
        id: i.ID,
        leagueID: props.leagueNumber,
        typeProgramID: props.program,
        invitationCode: '',
        registrationTransactionID: 0,
        childFirstName: i.ChildFirstName,
        childLastName: i.ChildLastName,
        childMiddleInitial: i.ChildMiddleInitial,
        typeGradeID: i.Grade,
        gender: i.Gender,
        email: i.Email,
        note: i.Note,
        parentFullName: i.ParentFullName,
        phone: i.Phone,
        typeWaitListStatusID: InviteStatus.WAITING,
        invitationSent: null,
        invitationDeclined: null,
        invitationExpire: null,
      })

      //update client side waitlist
      if (results) {
        internalReportData.data.forEach((i) => {
          if (i.ID == pendingID.value) {
            i.UpwardWaitListStatusID = InviteStatus.WAITING
            i.UpwardWaitListStatusDescription = ''
            i.InvitationDeclined = results.invitationDeclined
            i.InvitationExpire = results.invitationExpire
            i.InvitationSent = results.invitationSent
          }
        })
      }
      idUpdating.value = 0
    }

    const showAddWaitlistModal = ref(false)
    function doShowAddWaitlistModal() {
      showAddWaitlistModal.value = true
    }
    function onWaitlistSubmitted() {
      showAddWaitlistModal.value = false
      ctx.emit('refresh')
    }

    /**
     * Send Invite
     */
    const selectedIds = ref([] as number[])
    const selectAll = ref(false)
    function selecting(selected: boolean, waitlistId: number) {
      if (selected) {
        selectedIds.value.push(waitlistId)
      } else {
        selectedIds.value = selectedIds.value.filter((v) => v != waitlistId)
      }
    }

    const showExpirationModal = ref(false)
    function sendInvite() {
      showExpirationModal.value = true
    }

    async function expirationSelected(expire: Date) {
      await sendSingleInvite({
        SentDate: new Date(),
        InviteExpireDate: expire,
        WaitListIDs: [pendingID.value],
      })
    }

    async function bulkExpirationSelected(expire: Date) {
      showBulkExpirationModal.value = false
      const selected = internalReportData.data.filter((x) => x.selected).map((y) => y.ID)
      await inviteBulk({ SentDate: new Date(), InviteExpireDate: expire, WaitListIDs: selected })
    }

    const showBulkExpirationModal = ref(false)
    function inviteAllSelected() {
      showBulkExpirationModal.value = true
    }
    const showEmailComposerModal = ref(false)
    function emailAllSelected() {
      showEmailComposerModal.value = true
    }
    const spinner = ref(false)
    async function sendSingleInvite(inviteList: RegistrationInvite) {
      const id = inviteList.WaitListIDs[0]
      idUpdating.value = id
      showExpirationModal.value = false
      const results = await registrationClient.sendInvite(props.league, props.program, inviteList)
      const updated = results?.find((p) => p.id == id)

      //update client-side waitlist
      if (updated) {
        internalReportData.data.forEach((x) => {
          if (x.ID == id) {
            x.InvitationExpire = updated.invitationExpire
            x.InvitationDeclined = updated.invitationDeclined
            x.InvitationSent = updated.invitationSent
            x.UpwardWaitListStatusID = updated.typeWaitListStatusID ?? ''
            x.UpwardWaitListStatusDescription = 'Invitation send'
          }
        })
      }
      idUpdating.value = 0
    }

    async function inviteBulk(invite: RegistrationInvite) {
      try {
        progress.value = 'Sending invitations'
        spinner.value = true
        await registrationClient.sendInvite(props.league, props.program, invite)
      } finally {
        progress.value = ''
        spinner.value = false
      }
      ctx.emit('refresh')
    }

    /*
     * Display Formatting
     */
    function fullStatus(
      status: string,
      description: string,
      expire: Date | null,
      decline: Date | null,
      sent: Date | null
    ) {
      switch (status) {
        case InviteStatus.WAITING:
          return ''
        case InviteStatus.INVITATIONSENT:
          return `
          <div class="small">${description}</div><div>${dayjs(sent ?? '').format('MM/DD h:mm a')}</div>
          <div class="small mt-2">Expires</div></div>${dayjs(expire ?? '').format('MM/DD h:mm a')}</div>
          `
        case InviteStatus.INVITATIONACCEPTED:
          return `<div class="small font-weight-bold"><i class="fas fa-check-circle"></i> ${description}</div>`
        case InviteStatus.INVITATIONEXPIRED:
          return `
          <div class="small mt-2 font-weight-bold">
            <i class="fas fa-exclamation-triangle"></i> ${description}
          </div>
          <div>${dayjs(expire ?? '').format('MM/DD h:mm a')}</div>
          `
        case InviteStatus.INVITATIONDECLINED:
          return `
          <div class="small font-weight-bold"><i class="fas fa-times-circle"></i> ${description} </div>
          <div>${dayjs(decline ?? '').format('MM/DD/YY')}</div>
          `
      }
    }

    /**
     * General use
     */
    function waitlistDate(d: Date | null) {
      if (d) {
        return dayjs(d).format('MM/DD h:mm a')
      }
      return ''
    }

    const showSelectAll = () => {
      // don't show selectAll for invitation accepted and invitation declined
      return filter.value != inviteStatusDisplay[3].value && filter.value != inviteStatusDisplay[5].value
    }

    function sendable(status: string) {
      return (
        status == InviteStatus.WAITING ||
        status == InviteStatus.INVITATIONSENT ||
        status == InviteStatus.INVITATIONEXPIRED
      )
    }

    const showCapacityWarning = computed(() => progress.value == '' || progress.value == null)

    /**
     * Watchers
     */

    watch(
      () => [props.reportData, props.program],
      () => {
        progress.value = ''
        filter.value = inviteStatusDisplay[0].value
        selectAll.value = false
        const data = cloneDeep(props.reportData) as RegistrationWaitlist[]
        internalReportData.data = data
          .map((x) => ({ ...x, selected: false, show: true }))
          .filter((x) => x.UpwardProgramID == props.program)
          .sort((a, b) => (a.WaitListDate == b.WaitListDate ? 0 : a.WaitListDate < b.WaitListDate ? -1 : 1))
      },
      { immediate: true }
    )

    watch(
      () => selectAll.value,
      () => {
        internalReportData.data.forEach((x) => {
          if (sendable(x.UpwardWaitListStatusID) && x.show) {
            x.selected = selectAll.value
          }
        })
      }
    )
    watch(
      () => filter.value,
      () => {
        selectAll.value = false
        internalReportData.data.forEach((x) => {
          x.show = filter.value == inviteStatusDisplay[0].value || filter.value == x.UpwardWaitListStatusID
          x.selected = false
        })
      }
    )

    function close() {
      progress.value = null
    }
    function emailSendCompleted() {
      showEmailComposerModal.value = false
      showEmailSentConfirmation.value = true
    }
    const selectedEmails = computed(() => {
      return internalReportData.data.filter((x) => x.selected).map((y) => y.Email.toLowerCase())
    })
    const showEmailSentConfirmation = ref(false)
    function closeEmailSentConfirmation() {
      showEmailSentConfirmation.value = false
      ctx.emit('refresh')
    }
    function viewMessageHistory() {
      const currentRoutePart = 'reports/web/waitlist'
      const historyRoutePart = 'communications/history'
      const historyRoute = router.currentRoute.fullPath.replace(currentRoutePart, historyRoutePart)
      router.push(historyRoute)
    }

    return {
      isConfirming,
      isByAge,
      confirmRemove,
      waitlistDate,
      removeTargetDesc,
      downloadCsv,
      actionClicked,
      expirationSelected,
      showExpirationModal,
      fullStatus,
      internalReportData,
      idUpdating,
      fmtPhone,
      selecting,
      selectedIds,
      sendable,
      selectAll,
      removeAccepted,
      removeDeclined,
      bulkExpirationSelected,
      showBulkExpirationModal,
      inviteAllSelected,
      progress: computed(() => progress.value),
      spinner,
      filter,
      inviteStatusDisplay,
      maxLen,
      showSelectAll: computed(showSelectAll),
      close,
      showCapacityWarning,
      emailAllSelected,
      showEmailComposerModal,
      emailSendCompleted,
      selectedEmails,
      showEmailSentConfirmation,
      closeEmailSentConfirmation,
      viewMessageHistory,
      upwardLeagueID: props.league,
      typeProgramID: props.program,
      showAddWaitlistModal,
      doShowAddWaitlistModal,
      onWaitlistSubmitted,
    }
  },
})
