








































import ModalDatePicker from '@/components/ModalDateTimePicker.vue'
import { defineComponent, ref } from '@vue/composition-api'
export default defineComponent({
  components: {
    ModalDatePicker,
  },
  setup() {
    const show = ref(false)
    const pickedDateTime: Date | null = null
    return { show, pickedDateTime }
  },
})
