











































import { defineComponent, computed } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import store from '@/store'

export default defineComponent({
  name: 'UpwardBaseFeeStep',
  props: {
    step: { type: Number, required: true },
  },
  components: { Step },
  setup() {
    const logic = productFlowLogic()
    const typeLeagueID = computed(() => logic.leagueAbstraction.value.typeLeagueID)
    const variation = computed(() =>
      typeLeagueID.value === 'LT_VOLLEYBALL' ? 'two different devotion tracks ' : 'Age-appropiate devotions '
    )

    const bigItems = [
      {
        title: 'MyUpward.org League Director Tools',
        iconClass: 'fas fa-user-shield fa-lg',
        bullets: [
          'Online player registration, including waitlist functionality and capacity limits',
          'Payment processing & transaction fees are included',
          'Tools to setup your league and order/exchange products from Upward',
          'Tools to manage all your participants and volunteers.',
          'A wealth of league management resources to guide, train, and equip leaders of any level.',
          'League drafting that includes auto, manual or a combination to build balanced teams. Also included are optional carpool and coach links',
          'Schedule builder for practices and games through managed facilities',
          'Communicate through email or text across the league and the ability to schedule send both',
          'Extensive reporting to help manage your program',
        ],
      },
      {
        title: 'Coach Tools',
        iconClass: 'fas fa-chalkboard-teacher',
        bullets: [
          'Practice devotions to help coaches in ministry each week that include a devotion Scouting Report to help them grow too.',
          `A convenient Upward Sports app that helps coaches stay on track and includes detailed ${
            logic.isUpwardSelect.value ? '' : 'practice plans, '
          } practice devotions, ${
            logic.isUpwardSelect.value ? '' : 'skills & drills videos, '
          } rosters, game day management & other functionality`,
          'A stress free Upward Sports substitution system to help coaches balance playing time.',
          'Email and text communication tools to ensure parents stay informed and connect with coaches',
        ],
      },
    ]

    const items = [
      {
        title: 'Ministry Plan & Resources',
        iconClass: 'fas fa-users fa-lg',
        text:
          'A complete ministry strategy that includes access to Gospel videos and age-appropriate devotions in the coach playbooks, halftime messages to connect with families, a “Scouting Report” to equip coaches for devotions and grow in their faith & the Upward Sports app.',
      },
      {
        title: 'Player Kit Convenience',
        iconClass: 'fas fa-box fa-lg',
        text:
          'Best in class uniform options with innovative designs. Player and coach items are sorted and individually boxed inside a team box. Hand the box to each coach, and know you look organized and detailed',
      },
      {
        title: 'League Management Resources',
        iconClass: 'fas fa-book-open fa-lg',
        text: `League director manual, Upward Sports player evaluation process, coach & ${store.getters.leagueAbstraction.refLabel.toLowerCase()} training resources, parent welcome flyers, and many other important league documents and procedures`,
      },
      {
        title: 'Advertising Resources',
        iconClass: 'fas fa-bullhorn fa-lg',
        text:
          'An advertising strategy that includes professionally designed, first-class E-flyers, social media images, website images & layered InDesign Files',
      },
      {
        title: 'Volunteer Role Resources',
        iconClass: 'fas fa-book fa-lg',
        text: `Coach playbooks, practice plans, ${store.getters.leagueAbstraction.refLabel.toLowerCase()} handbooks, game day devotions, prayer ministry resources, and more.`,
      },
      {
        title: 'Parent Tools',
        iconClass: 'fas fa-user-friends fa-lg',
        bullets: [
          'A quick and easy online registration process where Upward covers all credit card fees',
          'Team pages that let parents access schedules and alerts from the league.',
        ],
      },
      {
        title: 'Consultation Services',
        iconClass: 'fas fa-hands-helping fa-lg',
        text:
          'We are with you! One-on-one consultation services to help you with ideas, planning, and strategies for a successful sports ministry',
      },
      {
        title: 'Support Center Assistance',
        iconClass: 'fas fa-headset fa-lg',
        text:
          'A responsive support center that can answer any question and assist you as needs arise - 864-949-5701',
      },
      {
        title: 'Shipping Included',
        iconClass: 'fas fa-truck fa-lg',
        text: 'Shipping included on all starter, league, add-on & merchandise orders',
      },
      {
        title: 'Custom Branding',
        iconClass: 'fas fa-tshirt fa-lg',
        text: `We can help you customize jerseys to include your church name or sponsors`,
      },
      {
        title: 'Tools For Other Volunteers',
        iconClass: 'fas fa-bible fa-lg',
        bullets: [
          'Upward Sports app that includes training resources, role-specific resources & applicable communication resources',
          'A role-specific app that equips & trains your team',
        ],
      },
    ]

    return { bigItems, items, variation, ...logic }
  },
})
