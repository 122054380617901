










import { defineComponent, ref } from '@vue/composition-api'
import FullBody from '@/components/FullBody.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'

export default defineComponent({
  name: 'Index',
  components: {
    FullBody,
    HorizontalTabs,
  },
  setup() {
    const tabs = ref([
      { text: 'Billing', to: '/account/billing' },
      { text: 'Manage Admin Users', to: '/account/usermanagement' },
      { text: 'Social Media', to: '/account/socialmedia' },
    ])

    return {
      tabs,
    }
  },
})
