export interface RegistrationType {
  registrationTypes: string[]
  onlineRegistrationType: string
}

interface ProgramWithRegistrationInfo {
  isMailIn: boolean
  isOPRLeague: boolean
  otherRegistrationVendorName: string | null
  leagueRegistrationURL: string | null
}

export function getProgramRegistrationOption(program: ProgramWithRegistrationInfo): RegistrationType | null {
  const registrationOption = { registrationTypes: [], onlineRegistrationType: '' } as RegistrationType

  if (program.isMailIn === undefined) {
    return null
  }

  if (program.isMailIn) {
    registrationOption.registrationTypes.push('paper')
  }
  if (program.isOPRLeague) {
    registrationOption.registrationTypes.push('online')
    registrationOption.onlineRegistrationType = 'upward'
  }
  if (program.otherRegistrationVendorName) {
    registrationOption.registrationTypes.push('online')
    registrationOption.onlineRegistrationType = 'other'
  }
  if (registrationOption.registrationTypes.length === 0) {
    registrationOption.registrationTypes.push('online')
    registrationOption.onlineRegistrationType = 'upward'
  }
  return registrationOption
}

export function setProgramRegistrationOption(
  program: ProgramWithRegistrationInfo,
  registrationOption: RegistrationType
) {
  if (program.isMailIn === undefined) {
    return
  }

  program.isMailIn = registrationOption.registrationTypes.indexOf('paper') >= 0
  program.isOPRLeague =
    registrationOption.registrationTypes.indexOf('online') >= 0 &&
    registrationOption.onlineRegistrationType !== 'other'
  const isMailInOnly: boolean = program.isMailIn && registrationOption.registrationTypes.length === 1

  if (isMailInOnly || program.isOPRLeague) {
    program.otherRegistrationVendorName = ''
    program.leagueRegistrationURL = ''
  }
}
