







import { defineComponent, PropType } from '@vue/composition-api'
import SectionHeader from '@/components/SectionHeader.vue'
import ShippingMethodSelect from '@/components/ShippingMethodSelect.vue'
import { xShipMethodBase } from 'src/GeneratedTypes/xOrder/xShipMethodBase'
import { getEmptyXShipMethodBase } from '@/lib/support/models/GeneratedTypes/xOrders/xShippingMethodBase.ts'

export default defineComponent({
  name: 'ShippingMethodSection',
  components: {
    SectionHeader,
    ShippingMethodSelect,
  },
  props: {
    value: { type: Object as PropType<xShipMethodBase>, default: getEmptyXShipMethodBase, required: false },
  },
  setup(props, ctx) {
    function update(val: xShipMethodBase[]) {
      ctx.emit('input', val)
    }

    return {
      update,
    }
  },
})
