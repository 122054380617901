





























import { defineComponent, PropType, computed, watch, ref, onMounted } from '@vue/composition-api'
import dayjs from 'dayjs'

import { NotificationInfo } from '@/models/Notification/NotificationInfo'
import LessButton from '@/components/LessButton.vue'
import MoreButton from '@/components/MoreButton.vue'
import NotificationCard from '@/components/Notifications/NotificationCard.vue'
export default defineComponent({
  name: 'Notification',
  props: {
    notification: { type: Object as PropType<NotificationInfo>, required: true },
  },
  components: { LessButton, MoreButton, NotificationCard },
  setup(props) {
    const msgArea = ref<Element | null>(null)
    const height = ref('')
    const totalHeight = ref(0)
    const showFlipper = ref(false)
    const expanded = ref(false)
    const smallHeight = '75px'
    const fmtDt = computed(() => {
      return dayjs(props.notification.startDate ?? '').format('MMM D')
    })

    function sizeText() {
      if (msgArea && msgArea.value && msgArea.value.scrollHeight) {
        const h = msgArea.value.scrollHeight
        totalHeight.value = h
        if (h > 100) {
          height.value = smallHeight
          showFlipper.value = true
        } else {
          height.value = `${totalHeight.value}px`
        }
      }
    }

    const flipper = computed(() => {
      return expanded.value ? LessButton : MoreButton
    })

    function click(val: 'LESS' | 'MORE') {
      if (val == 'LESS') {
        height.value = smallHeight
        expanded.value = false
      } else {
        height.value = `${totalHeight.value}px`
        expanded.value = true
      }
    }

    onMounted(() => {
      sizeText()
    })
    watch(
      () => props.notification,
      () => sizeText(),
      { immediate: true }
    )
    return { fmtDt, click, showFlipper, flipper, msgArea, height, expanded }
  },
})
