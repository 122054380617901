








































































import { Action } from 'vuex-class'
import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import SelectInput from '@/elements/SelectInput.vue'
import IntegerPicker from '@/elements/IntegerPicker.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'

import Loading from '@/elements/Loading.vue'
import PartnerForm from '@/components/PartnerForm.vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount'

import * as partnesrStore from '@/store/partners'
import { Partner } from '@/models/Partner'

@Component({
  components: {
    PartnerForm,
    Loading,
    SelectInput,
    IntegerPicker,
    SaveContinueButton,
  },
})
export default class Partnership extends Mixins(LeagueSetupMixin) {
  @Action(partnesrStore.actionNames.retrieveAndSetPartners, { namespace: partnesrStore.namespace })
  private readonly retrieveAndSetPartners!: ({ id }: { id: string }) => Partner[]

  private partnership = 'solo'
  private churchCount = 1
  private partnerDirty = false

  mounted() {
    if (this.league.upwardLeagueID) {
      this.nextRoute = `/programs/league/${this.league.upwardLeagueID}/setup/products`
    } else {
      this.nextRoute = '/programs/league/setup/products'
    }
    this.churchCount = this.league.accounts?.length ?? 1
    this.partnership = this.churchCount > 1 ? 'one' : 'solo'
    this.$nextTick(() => this.$watch('league.accounts', this.partnerChanged, { deep: true }))
    this.afterUpdateExisting = this.updatePartner
    this.afterSaveNew = this.updatePartner
  }

  private async updatePartner() {
    if (this.partnerDirty) {
      await this.retrieveAndSetPartners({ id: this.league.upwardLeagueID ?? '' })
    }
  }

  @Watch('churchCount')
  private onChurchCountChanged() {
    const accountDelta = this.churchCount - (this.league.accounts?.length ?? 1)

    if (this.league.accounts) {
      for (let i = 0; i < accountDelta; i++) {
        this.league.accounts.push({
          accountNumber: (-this.league.accounts.length).toString(),
          leagueOwner: false,
          expectLeagueOrder: false,
          coachShirtRequired: false,
          grades: this.league.accounts[0].grades,
        } as LeagueAccount)
      }
    }

    if (this.league.accounts && accountDelta < 0) {
      this.league.accounts = this.league.accounts?.splice(0, this.churchCount)
    }
  }

  private partnerChanged() {
    this.partnerDirty = true
  }

  private get typeProgramId(): string {
    if (this.league.programs && this.league.programs.length > 0) {
      return this.league.programs[0].typeProgramID
    }
    return ''
  }

  private get cheerTypeProgramId(): string {
    if (this.league.programs && this.league.programs.length > 1) {
      return this.league.programs[1].typeProgramID
    }
    return ''
  }
}
