











































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { getEmptyLeagueListItem, LeagueListItemSeed } from '@/lib/support/models/LeagueListItem/data'
import { HeaderStandingScoreValue } from '../ext/headerStandingScoreValue'
import programsClient from '@/clients/programsClient'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import DivisionSelect from '@/components/DivisionStandingScoreSelect.vue'
import store from '@/store'
import teamsClient from '@/clients/teamsClient'

export default defineComponent({
  name: 'DivisionHeader',
  components: {
    DivisionSelect,
    SelectInput,
    CheckboxInput,
  },
  props: {
    title: { required: false, type: String, default: '' },
    disabled: { required: false, type: Boolean, default: false },
  },
  setup(props, ctx) {
    const currentDivision = computed(() => store.getters.divisions.currentDivision)

    const programs = ref<LeagueListItem>(getEmptyLeagueListItem(new LeagueListItemSeed()))
    const selectedProgram = ref<string | null>('')
    const division = ref<LeagueDivisionInfo | null>(null)
    const loading = ref(false)

    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const selectedTeam = ref(0)
    const teams = ref([{}])
    const selectedRound = ref(-1)
    const filterByWeek = ref(false)
    const incompleteGamesOnly = ref(false)
    const roundList = computed(() => {
      const roundlimit = division.value?.maxRoundNumber || 16
      const rounds = Array.from(Array(roundlimit), (x, i) => ({ id: i + 1, description: `Round ${i + 1}` }))
      rounds.unshift({ id: 0, description: 'All' })
      return rounds
    })

    onMounted(async () => {
      if (currentDivision.value) {
        division.value = currentDivision.value
      }

      await loadPrograms()
      await loadTeams()
      emitValues()
      selectedRound.value = 0
    })

    watch(
      () => division.value,
      async () => {
        await loadTeams()
      }
    )

    async function loadPrograms() {
      loading.value = true

      try {
        const progs = await programsClient.retrieve(ctx.root.$route.params.id)
        if (progs) {
          programs.value = progs

          if (division.value) {
            selectedProgram.value = division.value.typeProgramID
          } else if (progs.programTypeID) {
            selectedProgram.value = progs.programTypeID
          }
        }
      } finally {
        loading.value = false
      }
    }
    async function loadTeams() {
      selectedTeam.value = 0
      teams.value = []
      const myTeams =
        (await teamsClient.retrieveTeamList({
          leagueId: upwardLeagueId.value,
          divisionId: division.value?.divisionID,
          typeProgramId: division.value?.typeProgramID ?? '',
        })) ?? []

      //default is all
      teams.value.push({
        teamID: 0,
        teamName: 'All' ?? '',
      })

      myTeams.forEach((r) =>
        teams.value.push({
          teamID: r.teamID,
          teamName: r.teamName ?? '',
        })
      )
    }

    watch([() => division.value, () => selectedProgram.value], () => {
      if (loading.value) return
      emitValues()
    })

    watch([() => selectedTeam.value, () => selectedRound.value], () => {
      if (loading.value) return
      emitValuesTeamWeek()
    })

    watch([() => incompleteGamesOnly.value], () => {
      if (loading.value) return
      emitValuesIncompleteGames()
    })

    function emitValues() {
      if (!division.value) return
      const headerValues = {
        selectedDivision: division.value,
        cheerProgramTypeID: programs.value.cheerProgramTypeID,
        selectedTypeProgramID: selectedProgram.value,
        selectedTeamID: selectedTeam.value,
        selectedRoundID: selectedRound.value,
        incompleteGamesOnly: incompleteGamesOnly.value,
      } as HeaderStandingScoreValue

      ctx.emit('divisionScoreStandingHeaderChanged', headerValues)
    }

    function emitValuesTeamWeek() {
      if (!division.value) return
      const headerValues = {
        selectedDivision: division.value,
        cheerProgramTypeID: programs.value.cheerProgramTypeID,
        selectedTypeProgramID: selectedProgram.value,
        selectedTeamID: selectedTeam.value,
        selectedRoundID: selectedRound.value,
        incompleteGamesOnly: incompleteGamesOnly.value,
      } as HeaderStandingScoreValue
      ctx.emit('divisionScoreStandingHeaderTeamRoundChanged', headerValues)
    }

    function emitValuesIncompleteGames() {
      if (!division.value) return
      const headerValues = {
        selectedDivision: division.value,
        cheerProgramTypeID: programs.value.cheerProgramTypeID,
        selectedTypeProgramID: selectedProgram.value,
        selectedTeamID: selectedTeam.value,
        selectedRoundID: selectedRound.value,
        incompleteGamesOnly: incompleteGamesOnly.value,
      } as HeaderStandingScoreValue

      ctx.emit('divisionScoreStandingHeaderIncompleteGamesChanged', headerValues)
    }

    const showHeader = computed(() => {
      if (loading.value) return false
      return division.value
    })

    return {
      showHeader,
      programs,
      selectedProgram,
      division,
      upwardLeagueId,
      filterByWeek,
      roundList,
      selectedRound,
      teams,
      selectedTeam,
      incompleteGamesOnly,
    }
  },
})
