
















































































import { defineComponent, ref, computed, onMounted, onUnmounted } from '@vue/composition-api'
import partnersClient from '@/clients/partnersClient'
import contentManagementClient from '@/clients/contentManagementClient'

import { PartnerSupportPage } from '@/models/PageContent/PartnerSupportPage'

import TheChat from '@/singletons/TheChat.vue'
import FullBody from '@/components/FullBody.vue'
import Loading from '@/elements/Loading.vue'
import store from '@/store'

export default defineComponent({
  name: 'Contact',
  components: {
    FullBody,
    Loading,
    TheChat,
  },
  setup() {
    const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const partnerSupportPage = ref<PartnerSupportPage | null>(null)
    const loading = ref(true)
    async function updatePartnerSupportPage() {
      const currentAccountInfo = await partnersClient.getPartnerInfo(firstAccountNumber.value)
      partnerSupportPage.value = await contentManagementClient.getPartnerSupportRep(
        currentAccountInfo?.address1_StateOrProvince ?? ''
      )
      loading.value = false
    }

    onMounted(async () => {
      await updatePartnerSupportPage()
      setChatClasses('genesys-app')
    })

    onUnmounted(() => {
      setChatClasses('genesys-app d-none')
    })

    function setChatClasses(classes: string) {
      //workaround: Make GensysChat visible on this page only
      const chat = document.getElementsByClassName('genesys-app')
      for (var i = 0; i < chat.length; i++) {
        chat[i].className = classes
      }
    }

    return {
      partnerSupportPage,
      loading,
    }
  },
})
