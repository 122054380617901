import { computed, ref, watch, onMounted, nextTick, SetupContext } from '@vue/composition-api'
import { Route, NavigationGuardNext } from 'vue-router'
import { cloneDeep } from 'lodash'
import { Connect } from '@/GeneratedTypes/Connect'
import { PromptToSavePayload } from '@/models/PromptToSavePayload'
import store from '@/store'

export function useConnectSetup(ctx: SetupContext) {
  function beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    store.dispatch.promptToSave.onNavigation({
      save: async () => {
        await save()
      },
      continue: () => {
        next()
      },
    } as PromptToSavePayload)
  }

  const connect = ref<Connect>({} as Connect)
  const loading = ref(false)
  const initialLoadDone = ref(false)
  const nextRoute = ref('')
  const beforeSave = ref<null | (() => Promise<void>)>(null)
  const afterUpdateExisting = ref<null | (() => void | Promise<void>)>(null)
  const afterSaveNew = ref<null | (() => Promise<void>)>(null)

  const storeConnect = computed(() => store.getters.connects.currentItem)

  async function saveAndContinue() {
    await save()
    continueNext()
  }

  onMounted(() => {
    nextTick(() => watch(connect.value, onConnectChange, { deep: true }))
  })

  //async created hack from https://stackoverflow.com/questions/64117116/how-can-i-use-async-await-in-the-vue-3-0-setup-function-using-typescript
  const run = (asyncFn: () => Promise<void>) => asyncFn()
  run(async () => {
    connect.value = cloneDeep(storeConnect.value)

    if (!connect.value.typeLeagueID) {
      loading.value = true

      try {
        await store.dispatch.connects.loadFromCache()
        connect.value = cloneDeep(storeConnect.value)
      } catch (err) {
        ctx.root.$router.push('/programs/new/')
      } finally {
        loading.value = false
      }
    }

    initialLoadDone.value = true
  })

  async function save() {
    loading.value = true

    try {
      if (beforeSave.value) {
        await beforeSave.value()
      }
      if (connect.value.upwardLeagueID) {
        await updateExistingConnect()

        if (afterUpdateExisting.value) {
          await afterUpdateExisting.value()
        }
      } else {
        await saveNewConnect()

        if (afterSaveNew.value) {
          await afterSaveNew.value()
        }
      }

      store.commit.promptToSave.setIsDirty({ isDirty: false })
    } finally {
      loading.value = false
    }
  }

  function continueNext() {
    if (nextRoute.value) {
      ctx.root.$router.push(nextRoute.value)
    }
  }

  async function saveNewConnect() {
    if (connect.value.upwardLeagueID) {
      return
    }

    try {
      await store.dispatch.connects.cache({ item: connect.value })
    } catch (err) {
      throw err
    }
  }

  async function updateExistingConnect() {
    if (!connect.value.upwardLeagueID) {
      return
    }

    try {
      await store.dispatch.connects.update({ id: connect.value.upwardLeagueID, item: connect.value })
    } catch (err) {
      throw err
    }
  }

  function onConnectChange() {
    store.commit.promptToSave.setIsDirty({ isDirty: true })
  }

  return {
    connect,
    storeConnect,
    loading,
    nextRoute,
    afterSaveNew,
    afterUpdateExisting,
    initialLoadDone,
    save,
    saveAndContinue,
    beforeRouteLeave,
  }
}
