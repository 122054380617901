





















import { defineComponent, ref, onMounted } from '@vue/composition-api'

import { Viewer } from '@grapecity/activereports-vue'
import { Core, PdfExport } from '@grapecity/activereports'

import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'

Core.PageReport.LicenseKey = process.env.VUE_APP_ACTIVE_REPORT_JS_KEY ?? ''

Core.FontStore.registerFonts('/Fonts/fontsConfig.json')

export default defineComponent({
  name: 'ActiveReport',
  components: {
    Viewer,
    Loading,
    Modal,
  },
  props: {
    status: { type: String, default: '' },
    reportName: { type: String, default: 'DynamicReport' },
    loading: { type: Boolean, default: true },
  },
  setup(props, ctx) {
    const showExporting = ref(false)

    const currentPage = ref(0)

    const report = ref<any>(null)

    function documentLoaded() {
      ctx.emit('report-loaded')
    }

    function reportLoaded(args: any) {
      report.value = args.report
    }

    function exportReportProgress(pageNumber: number) {
      currentPage.value = pageNumber
    }

    function exportCancel() {
      return !showExporting.value // continue export. Return true to cancel export process
    }

    function exportPdf() {
      showExporting.value = true
      currentPage.value = 0

      report.value.run().then((document: any) =>
        PdfExport.exportDocument(document, undefined, exportReportProgress, exportCancel).then(
          (result: any) => {
            result.download(props.reportName)
            showExporting.value = false
          }
        )
      )
    }

    onMounted(() => {
      const viewer: any = ctx.refs.reportViewer
      const pdfExportButton = {
        key: '$pdfExportButtonKey',
        iconCssClass: 'far fa-file-pdf',
        enabled: true,
        action: exportPdf,
      }
      viewer.Viewer().toolbar.addItem(pdfExportButton) //now you want to remove everything except pdfExportButton and the navigation block.
      viewer.Viewer().toolbar.updateLayout({
        default: ['$navigation', '$print', '$pdfExportButtonKey', '$refresh', '$zoom'],
      })
    })

    function loadReportData(report: any, reportOptions: any) {
      const viewer: any = ctx.refs.reportViewer
      viewer.open(report, reportOptions)
    }

    return {
      showExporting,
      currentPage,
      reportLoaded,
      documentLoaded,
      loadReportData,
    }
  },
})
