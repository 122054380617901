



























import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import Stepper from '@/elements/Stepper.vue'
import ProductOfferingStep from '@/components/ProductFlow/ParticipantKitStepper/ProductOfferingStep.vue'
import ProductStep from '@/components/ProductFlow/ParticipantKitStepper/ProductStep.vue'
import Packages from '@/components/ProductFlow/ParticipantKitStepper/Packages/Packages.vue'

import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'
import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'
import { ProductOfferingCategoryInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingCategoryInfo'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

import { sortOffering } from '@/services/offeringService'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

import store from '@/store'
import { ProdConfigTypes } from '@/lib/common/ProdConfigTypes'

export default defineComponent({
  name: 'ParticipantKitStepper',
  props: {
    isCheer: { type: Boolean, required: false },
  },
  components: {
    Stepper,
    ProductOfferingStep,
    ProductStep,
    Packages,
  },
  setup(props) {
    const logic = productFlowLogic()
    const selectedProductOfferingId = ref(0)

    const currentStep = computed({
      get() {
        if (!props.isCheer) {
          return store.getters.productFlow.currentParticipantKit1Step
        } else {
          return store.getters.productFlow.currentParticipantKit2Step
        }
      },
      set(v: number) {
        if (!props.isCheer) {
          store.commit.productFlow.setCurrentParticipantKit1Step({ step: v })
        } else {
          store.commit.productFlow.setCurrentParticipantKit2Step({ step: v })
        }
      },
    })

    /*
     * Packages
     */
    const selectedPackageForOfferings = ref<string | null>(null)
    const selectedPackageForChildProducts = ref<string | null>(null)
    function packageClick(pkg: string) {
      selectedPackageForOfferings.value = pkg
    }

    const showPackages = computed(() => {
      if (store.getters.productFlow.prodConfigType == ProdConfigTypes.ESTIMATE) {
        return true
      }
      if (
        store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE &&
        logic.isNewProdConfig.value
      ) {
        return true
      }
      return false
    })

    const showStepper = computed(() => {
      if (store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.CAMP) {
        return true
      }

      if (logic.isNewProdConfig.value) {
        return selectedPackageForOfferings.value != null &&
          store.getters.productOfferings.initialProductFlowLoadingComplete
          ? true
          : false
      } else {
        return true
      }
    })

    const isByAge = computed(() => {
      return store.getters.leagueAbstraction.isByAge
    })

    /*
     * Calculate the list of child products
     */
    const savedProducts = computed((): SavedProductOfferingConfigDetail[] =>
      //This represents details that have previously been saved in a product config
      cloneDeep(store.getters.productOfferings.productOfferingConfig.details ?? [])
    )

    const childProducts = computed(() => {
      //get the correct catalog for the type (cheer.sport)
      const catalogs = cloneDeep(store.getters.productOfferings.displayableProductCatalogs)
      return catalogs.find((c) => c.id == selectedProductOfferingId.value) ?? ({} as ProductOfferingInfo)
    })

    function productOfferingChanged(result: {
      action: string
      package: string
      offering: ProductOfferingInfo
    }) {
      if (result.action == 'PackageChangeOnly') {
        selectedPackageForChildProducts.value = result.package
      } else if (result.action == 'OfferingChanged') {
        selectedProductOfferingId.value = result.offering.id
        selectedPackageForChildProducts.value = result.package
      }
      logic.kitStepClicked(!props.isCheer, 1)
    }

    const activeChildProducts = computed(() => {
      // filter out inactive products that are not already saved to a league or estimate
      const cleanProducts = cloneDeep(childProducts.value)

      cleanProducts.categories?.forEach((c) => {
        if (!c.products || !c.products.length) return
        c.products = c.products?.filter((p) => {
          if (!p.active) {
            return savedProducts.value.some((savedProd) => savedProd.productID == p.productID)
          }
          return true
        })
      })
      return cleanProducts
    })

    const displayableCategories = computed((): ProductOfferingCategoryInfo[] => {
      const products = cloneDeep(activeChildProducts.value)
      if (!products || !products.categories) {
        return []
      } else {
        return sortOffering<ProductOfferingCategoryInfo>(products.categories)
      }
    })

    const maxAccessibleStep = computed(() => {
      return (childProducts.value.categories?.length ?? 0) + 1
    })

    watch(
      () => childProducts.value,
      () => {
        if (!logic.isNewProdConfig.value) {
          if (!props.isCheer) {
            store.commit.productFlow.setMaxTouchedParticipantKit1Step({
              step: maxAccessibleStep.value,
            })
          } else {
            store.commit.productFlow.setMaxTouchedParticipantKit2Step({
              step: maxAccessibleStep.value,
            })
          }
        }
      },
      { immediate: true }
    )

    return {
      ...logic,
      currentStep,
      displayableCategories,
      productOfferingChanged,
      selectedProductOfferingId,
      packageClick,
      showPackages,
      selectedPackageForOfferings,
      selectedPackageForChildProducts,
      showStepper,
      isByAge,
    }
  },
})
