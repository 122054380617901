import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { UpwardExceptionResult } from '@/models/UpwardExceptionResult'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import {
  UpwardExceptionResultWithTimestamp,
  UpwardValidationDetailsWithTimestamp,
} from '@/models/error_timestamp_types'

interface ErrorsState {
  errorItems: UpwardExceptionResultWithTimestamp[]
  validationItems: UpwardValidationDetailsWithTimestamp[]
}

const errorsState: ErrorsState = {
  errorItems: [],
  validationItems: [],
}

export enum getterNames {
  allErrors = 'allErrors',
  allValidationErrors = 'allValidationErrors',
  currentError = 'currentError',
  currentValidationError = 'currentValidationError',
}

const getters: GetterTree<ErrorsState, RootState> = {
  [getterNames.allErrors]: (state) => state.errorItems,
  [getterNames.allValidationErrors]: (state) => state.validationItems,
  [getterNames.currentError]: (state) =>
    state.errorItems?.length ? state.errorItems[state.errorItems.length - 1] : null,
  [getterNames.currentValidationError]: (state) =>
    state.validationItems?.length ? state.validationItems[state.validationItems.length - 1] : null,
}

/**
 * Change strategy for if we keep history of errors
 * if false we still track errors vs validation problems but we
 * only hang on to the last validation error and last error
 */
const KEEP_ERROR_HISTORY_FEATURE = false

export enum mutationNames {
  addError = 'addError',
  clearCurrentError = 'clearCurrentError',
  clearAll = 'clearAll',
  addValidationError = 'addValidationError',
  clearCurrentValidationError = 'clearCurrentValidationError',
}

const mutations: MutationTree<ErrorsState> = {
  [mutationNames.addError](state, { item }: { item: UpwardExceptionResult }) {
    if (!KEEP_ERROR_HISTORY_FEATURE) {
      state.errorItems = []
    }
    state.errorItems.push({ error: item, timestamp: new Date() })
  },
  [mutationNames.clearCurrentError](state) {
    if (state.errorItems.length) {
      state.errorItems.pop()
    }
  },
  [mutationNames.addValidationError](state, { item }: { item: UpwardVerificationDetails }) {
    if (!KEEP_ERROR_HISTORY_FEATURE) {
      state.validationItems = []
    }
    state.validationItems.push({ error: item, timestamp: new Date() })
  },
  [mutationNames.clearCurrentValidationError](state) {
    if (state.validationItems.length) {
      state.validationItems.pop()
    }
  },
  [mutationNames.clearAll](state) {
    state.errorItems = []
    state.validationItems = []
  },
}

export const namespace = 'errors'

export const errors = {
  namespaced: true as true,
  state: errorsState,
  getters,
  mutations,
}
