














import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import store from '@/store'

export default defineComponent({
  name: 'TimezoneSelect',
  components: {
    SelectInput,
  },
  props: {
    value: { type: (null as unknown) as PropType<string | null>, required: true },
  },
  setup(props, ctx) {
    const isLoading = ref(false)
    const selectedItem = ref<string | null>(props.value)
    const items = computed(() => store.getters.timezoneTypes.allItems)

    async function loadItems() {
      if (isLoading.value) {
        return
      }

      isLoading.value = true
      await store.dispatch.timezoneTypes.fetchAll(false)
      isLoading.value = false
    }

    onMounted(async () => {
      await loadItems()
    })

    watch(
      () => selectedItem.value,
      () => {
        if (props.value !== selectedItem.value) {
          ctx.emit('input', selectedItem.value)
          ctx.emit('change', selectedItem.value)
        }
      }
    )

    watch(
      () => props.value,
      () => {
        selectedItem.value = props.value
      }
    )

    return {
      isLoading,
      selectedItem,
      items,
    }
  },
})
