import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { PromptToSavePayload } from '@/models/PromptToSavePayload'
import { ActionsImpl } from 'direct-vuex'
interface PromptToSaveState {
  isDirty: boolean
  modalIsVisible: boolean
  payload: PromptToSavePayload | null
}
const promptToSaveState: PromptToSaveState = {
  isDirty: false,
  modalIsVisible: false,
  payload: null,
}
export enum getterNames {
  isDirty = 'isDirty',
  modalIsVisible = 'modalIsVisible',
}
const getters: GetterTree<PromptToSaveState, RootState> = {
  [getterNames.isDirty]: (state) => state.isDirty,
  [getterNames.modalIsVisible]: (state) => state.modalIsVisible,
}
export enum mutationNames {
  setIsDirty = 'setIsDirty',
}
enum internalMutationNames {
  setModalIsVisible = 'setModalIsVisible',
  setPayload = 'setPayload',
}
const mutations: MutationTree<PromptToSaveState> = {
  [mutationNames.setIsDirty](state, { isDirty }: { isDirty: boolean }) {
    state.isDirty = isDirty
  },
  [internalMutationNames.setModalIsVisible](state, { modalIsVisible }: { modalIsVisible: boolean }) {
    state.modalIsVisible = modalIsVisible
  },
  [internalMutationNames.setPayload](state, payload: PromptToSavePayload) {
    state.payload = payload
  },
}
export enum actionNames {
  onNavigation = 'onNavigation',
  onYes = 'onYes',
  onNo = 'onNo',
  onCancel = 'onCancel',
}
const actions: ActionTree<PromptToSaveState, RootState> & ActionsImpl = {
  [actionNames.onNavigation]({ commit, state }, payload: PromptToSavePayload) {
    if (!state.isDirty) {
      payload?.continue()
      return
    }
    commit(internalMutationNames.setPayload, payload)
    commit(internalMutationNames.setModalIsVisible, { modalIsVisible: true })
  },
  async [actionNames.onYes]({ commit, state }) {
    commit(internalMutationNames.setModalIsVisible, { modalIsVisible: false })
    commit(mutationNames.setIsDirty, { isDirty: false })
    const r = await state.payload?.save()
    if (r != false) {
      state.payload?.continue()
      commit(internalMutationNames.setPayload, null)
    }
  },
  [actionNames.onNo]({ commit, state }) {
    commit(internalMutationNames.setModalIsVisible, { modalIsVisible: false })
    commit(mutationNames.setIsDirty, { isDirty: false })
    state.payload?.continue()
    commit(internalMutationNames.setPayload, null)
  },
  [actionNames.onCancel]({ commit }) {
    commit(internalMutationNames.setModalIsVisible, { modalIsVisible: false })
    commit(internalMutationNames.setPayload, null)
  },
}
export const namespace = 'promptToSave'
export const promptToSave = {
  namespaced: true as true,
  state: promptToSaveState,
  getters,
  actions,
  mutations,
}
