








import { defineComponent, ref, computed } from '@vue/composition-api'
import Carousel from '@/components/Carousel/Carousel.vue'
import { createDeck } from './sampleDeck'

export default defineComponent({
  components: { Carousel },
  setup(props, ctx) {
    const current = ref(0)

    const next = (slideId: number) => (current.value = slideId)
    const navigate = (path: string) => ctx.root.$router.push(path)
    const setCurrent = (slideId: number) => (current.value = slideId)

    const deck = createDeck(next, navigate)
    current.value = deck[0].id

    return {
      deck,
      current: computed(() => current.value),
      setCurrent,
    }
  },
})
