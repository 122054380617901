











import { defineComponent, ref, watch } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'

export default defineComponent({
  name: 'PartnerRoleSelectInput',
  components: {
    SelectInput,
  },
  props: {
    value: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const internalValue = ref(props.value)

    watch(
      () => props.value,
      () => {
        if (props.value !== internalValue.value) {
          internalValue.value = props.value
        }
      }
    )

    watch(
      () => internalValue.value,
      () => {
        if (props.value !== internalValue.value) {
          ctx.emit('input', internalValue.value)
        }
      }
    )

    return {
      internalValue,
    }
  },
})
