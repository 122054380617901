














import { defineComponent, ref } from '@vue/composition-api'
import EFTReport from '@/views/Programs/Reports/WebviewReports/EFTReport.vue'
import reportsClient from '@/clients/reportsClient'
import Loading from '@/elements/Loading.vue'
import EFTReportControls from '@/components/EFTReportControls.vue'

export default defineComponent({
  components: {
    EFTReport,
    EFTReportControls,
    Loading,
  },

  setup(props, ctx) {
    const params = ref<Record<string, unknown>>({})
    const loading = ref(false)
    const dataSource = ref<Record<string, unknown>[]>([])
    const leagueID = ref(ctx.root.$route.params.id)

    async function updateParams(p: Record<string, unknown>) {
      params.value = p
      await loadReport()
    }

    async function loadReport() {
      if (
        params.value &&
        (params.value?.BeginDate as Date).valueOf() != (params.value?.EndDate as Date).valueOf()
      ) {
        try {
          loading.value = true
          dataSource.value =
            (await reportsClient.getReport('registrationeftpayment', '0', '', params.value)) ?? []
          if (!dataSource.value) {
            dataSource.value = []
          }
        } finally {
          loading.value = false
        }
      } else {
        loading.value = true
      }
    }

    return { dataSource, loading, leagueID, updateParams }
  },
})
