import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import localStorageService from '@/services/localStorageService'

export function setCache(key: string, order: xStartupOrderExt) {
  if (!order) return

  const item = { createdDate: new Date(), order: order }
  localStorageService.set(key, item)
}

export function getCached(key: string) {
  const result = localStorageService.getParsed<LocalStorageOrder>(key)
  const OneDay = 1000 * 60 * 60 * 24 // in milliseconds

  if (!result) return null
  if (+new Date() - +new Date(result.createdDate) > OneDay) {
    deleteCached(key)
    return null
  } else {
    return result?.order
  }
}

export function deleteCached(key: string) {
  return localStorageService.remove(key)
}

interface LocalStorageOrder {
  createdDate: Date
  order: xStartupOrderExt
}
