import { render, staticRenderFns } from "./SiteLoading.vue?vue&type=template&id=6041ed78&scoped=true&"
import script from "./SiteLoading.vue?vue&type=script&lang=ts&"
export * from "./SiteLoading.vue?vue&type=script&lang=ts&"
import style0 from "./SiteLoading.vue?vue&type=style&index=0&id=6041ed78&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6041ed78",
  null
  
)

export default component.exports