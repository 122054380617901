


















































































import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import Loading from '@/elements/Loading.vue'
import DateInput from '@/elements/DateInput.vue'
import EvaluationForm from '@/components/EvaluationForm/EvaluationForm.vue'
import { Component, Mixins } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import dayjs from 'dayjs'

let useTestData: any = null
if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
  import('@/views/Leagues/testData/keydates').then((x) => (useTestData = x))
}

@Component({
  components: {
    DateInput,
    EvaluationForm,
    Loading,
    Modal,
    SaveContinueButton,
  },
})
export default class KeyDates extends Mixins(LeagueSetupMixin) {
  private numberOfEvaluations = 1
  private awardHelpIsVisible = false
  private seasonendHelpIsVisible = false

  mounted() {
    if (this.league.upwardLeagueID) {
      this.nextRoute = `/programs/league/${this.league.upwardLeagueID}/setup/leadership`
    } else {
      this.nextRoute = '/programs/league/setup/leadership'
    }

    if (useTestData) {
      useTestData.setKeyDatesForNewLeague(this.league)
    }

    this.beforeSave = this.setDefaults
  }

  // eslint-disable-next-line require-await
  private async setDefaults() {
    if (
      this.league.registrationBeginDate &&
      this.league.registrationInfo &&
      !this.league.registrationInfo.onlineRegistrationBeginDate
    ) {
      this.league.registrationInfo.onlineRegistrationBeginDate = dayjs(this.league.registrationBeginDate)
        .hour(0)
        .minute(0)
        .second(0)
        .toDate()
    }

    if (
      this.league.registrationEndDate &&
      this.league.registrationInfo &&
      !this.league.registrationInfo.onlineRegistrationEndDate
    ) {
      this.league.registrationInfo.onlineRegistrationEndDate = dayjs(this.league.registrationEndDate)
        .hour(23)
        .minute(59)
        .second(59)
        .toDate()
    }

    // Changes to early registration end date needs to update registration fee to pass validation.
    if (this.league.upwardLeagueID && this.league.lateDate && this.league.programs) {
      if (this.league.programs.length > 0 && !this.league.programs[0].earlyRegistrationFee) {
        this.league.programs[0].earlyRegistrationFee = this.league.programs[0].registrationFee
      }

      if (this.league.programs.length > 1 && !this.league.programs[1].earlyRegistrationFee) {
        this.league.programs[1].earlyRegistrationFee = this.league.programs[1].registrationFee
      }
    }

    if (this.league.upwardLeagueID && !this.league.lateDate && this.league.programs) {
      if (this.league.programs.length > 0) {
        this.league.programs[0].earlyRegistrationFee = null
      }

      if (this.league.programs.length > 1) {
        this.league.programs[1].earlyRegistrationFee = null
      }
    }

    if (this.league.firstPracticeDate && this.league.firstPracticeDate < new Date('4/1/2021')) {
      throw new Error('First practice date cannot be less then 4/1/2021')
    }
  }
}
