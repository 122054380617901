














































import { defineComponent, PropType, computed, onMounted, ref } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'
import ProductPicker from './ProductPickers/ProductPicker.vue'
import OptionalProductPicker from './ProductPickers/OptionalProductPicker.vue'
import GradeGenderPicker from './ProductPickers/GradeGenderPicker.vue'

import { ProductOfferingCategoryInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingCategoryInfo'
import { OfferingOptionType } from '@/lib/support/models/UpwardTypes/OfferingOptionType'
import { ProductOfferingTypes } from '@/lib/common/ProductOfferingTypes'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

export default defineComponent({
  name: 'ProductStep',
  props: {
    step: { type: Number, required: true },
    isSport: { type: Boolean, required: true },
    isByAge: { type: Boolean, required: false, default: false },
    category: { type: Object as PropType<ProductOfferingCategoryInfo>, required: true },
    productOfferingId: { type: Number, required: true },
    selectedPackage: { type: String, required: false, default: '' },
  },
  components: { ProductPicker, Step, OptionalProductPicker, GradeGenderPicker },
  setup(props, { emit }) {
    const logic = productFlowLogic()
    const maxTouchedStep = computed(() =>
      props.isSport ? logic.kit1MaxTouchedStep.value : logic.kit2MaxTouchedStep.value
    )
    const useTestData = ref(false)
    onMounted(() => {
      if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
        useTestData.value = true
      }
      emit('mounted', props.category.categoryID)
    })

    function componentName(typeProductOfferingOptionID: string) {
      switch (typeProductOfferingOptionID) {
        case OfferingOptionType.OPTIONAL:
          return 'OptionalProductPicker'
        case OfferingOptionType.ADDBYGRADEGENDER:
          return 'GradeGenderPicker'
        case OfferingOptionType.HYBRID:
          return 'HybridProductPicker'
        default:
          return 'ProductPicker'
      }
    }

    const isRequired = computed(() => props.category.typeProductOfferingOptionID == OfferingOptionType.SELECT)

    const isSelectMultiple = computed(
      () => props.category.typeProductOfferingOptionID == OfferingOptionType.ADD
    )

    const isSingleProduct = computed(() => props.category.products?.length == 1)

    const type = computed(() => (props.isSport ? ProductOfferingTypes.SPORT : ProductOfferingTypes.CHEER))

    function groupProducts(groupID: number) {
      return props.category.products?.filter((x) => x.groupID == groupID) || []
    }

    function groupIsSingleProduct(groupID: number) {
      return groupProducts(groupID).length == 1
    }

    function groupIsRequired(typeProductOfferingOptionID: string) {
      return typeProductOfferingOptionID == OfferingOptionType.SELECT
    }

    function groupIsSelectMultiple(typeProductOfferingOptionID: string) {
      return typeProductOfferingOptionID == OfferingOptionType.ADD
    }

    return {
      useTestData,
      ...logic,
      maxTouchedStep,
      componentName,
      isSingleProduct,
      isRequired,
      isSelectMultiple,
      type,
      groupProducts,
      groupIsSingleProduct,
      groupIsRequired,
      groupIsSelectMultiple,
    }
  },
})
