import { RouteConfig } from 'vue-router'
import reports from '@/router/reports'
import divisions from '@/router/divisions'
import orders from '@/router/orders'
import scheduling from '@/router/scheduling'
import Setup from '@/views/Leagues/Setup.vue'
import LeagueIndex from '@/views/Leagues/LeagueIndex.vue'
import Information from '@/views/Leagues/Information.vue'
import DigitalInformation from '@/views/Leagues/DigitalInformation.vue'
import KeyDates from '@/views/Leagues/KeyDates.vue'
import Leadership from '@/views/Leagues/Leadership.vue'
import Partnership from '@/views/Leagues/Partnership.vue'
import Products from '@/views/Leagues/Products.vue'
import Registration from '@/views/Leagues/Registration.vue'
import Archive from '@/views/Leagues/Archive.vue'
import participants from './participants'
import volunteers from './volunteers'
import communications from './communications'
import resources from './resources'
import { idExpression } from '@/lib/support/router/idHelper'

export default [
  {
    path: `league/${idExpression}`,
    component: LeagueIndex,
    children: [
      {
        path: '/',
        redirect: `/programs/league/${idExpression}/setup`,
      },
      ...orders('league'),
      ...divisions('league'),
      ...communications('league'),
      ...participants('league'),
      ...resources('League'),
      ...volunteers('league'),
      {
        path: 'setup',
        component: Setup,
        children: [
          {
            path: '/',
            redirect: `/programs/league/${idExpression}/setup/information`,
          },
          {
            path: 'information',
            component: Information,
          },
          {
            path: 'dates',
            component: KeyDates,
          },
          {
            path: 'leadership',
            component: Leadership,
          },
          {
            path: 'partnership',
            component: Partnership,
          },
          {
            path: 'products',
            component: Products,
          },
          {
            path: 'registration',
            component: Registration,
          },
          {
            path: 'archive',
            component: Archive,
          },
        ],
      },
      {
        path: 'digitalsetup',
        component: Setup,
        children: [
          {
            path: '/',
            redirect: `/programs/league/${idExpression}/digitalsetup/information`,
          },
          {
            path: 'information',
            component: DigitalInformation,
          },
        ],
      },
      ...scheduling,
      ...reports,
    ],
  },
] as RouteConfig[]
