import { RouteConfig } from 'vue-router'
import Scheduling from '@/views/Programs/Scheduling/Index.vue'
import Facilities from '@/views/Programs/Scheduling/Facilities/Facilities.vue'

import Practices from '@/views/Programs/Scheduling/Practices.vue'
import GameSchedule from '@/views/Programs/Scheduling/Games/GameSchedule.vue'
import Games from '@/views/Programs/Scheduling/Games/Games.vue'
import AutomaticScheduling from '@/views/Programs/Scheduling/Games/AutomaticScheduling.vue'
import Squads from '@/views/Programs/Scheduling/Squads/Squads.vue'
import SquadsAutomaticScheduling from '@/views/Programs/Scheduling/Squads/SquadsAutomaticScheduling.vue'
import SquadsSchedule from '@/views/Programs/Scheduling/Squads/SquadsSchedule.vue'
import SpecialEventSchedule from '@/views/Programs/Scheduling/SpecialEvents/SpecialEventSchedule.vue'

import { idExpression } from '@/lib/support/router/idHelper'

// const baseName = 'Scheduling'

export default [
  {
    path: 'scheduling',
    component: Scheduling,
    children: [
      {
        path: '/',
        redirect: `/programs/league/${idExpression}/scheduling/facilities`,
      },
      {
        path: 'facilities',
        component: Facilities,
      },
      {
        path: 'games/',
        component: Games,
      },
      {
        path: 'auto/',
        component: AutomaticScheduling,
      },
      {
        path: 'auto/:divisionID',
        component: AutomaticScheduling,
      },
      {
        path: 'finalize/',
        component: AutomaticScheduling,
      },
      {
        path: 'cheer',
        component: Squads,
      },
      {
        path: 'cheer/finalize',
        component: SquadsAutomaticScheduling,
      },
      {
        path: 'cheer/auto',
        component: SquadsAutomaticScheduling,
      },
      {
        path: 'cheer/auto:divisionID',
        component: SquadsAutomaticScheduling,
      },
      {
        path: 'manual/',
        component: GameSchedule,
      },
      {
        path: 'manual/:divisionID',
        component: GameSchedule,
      },
      {
        path: 'cheer/manual',
        component: SquadsSchedule,
      },
      {
        path: 'cheer/manual/:divisionID',
        component: SquadsSchedule,
      },
      // works for both cheer and non-cheer
      {
        path: 'practices/:program?',
        component: Practices,
      },
      {
        path: 'specialEvents',
        component: SpecialEventSchedule,
      },
    ],
  },
] as RouteConfig[]
