
















import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'

import GradeGenderCard from './GradeGenderCard.vue'
import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { getEmptyProductOfferingProductInfoList } from '@/lib/support/models/ProductOfferingProductInfo/ProductOfferingProductInfo'
import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail.ts'
import { getEmptySavedProductOfferingConfigDetail } from '@/lib/support/models/SavedProductOfferingConfigDetail/SavedProductOfferingConfigDetail'
import { ProductOfferingTypes } from '@/lib/common/ProductOfferingTypes'
import { sortOffering } from '@/services/offeringService'
import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import store from '@/store'

export default defineComponent({
  name: 'GradeGenderPicker',
  components: {
    GradeGenderCard,
  },
  props: {
    products: {
      type: Array as PropType<ProductOfferingProductInfo[]>,
      default: getEmptyProductOfferingProductInfoList,
      required: false,
    },
    values: {
      type: Array as PropType<SavedProductOfferingConfigDetail[]>,
      default: getEmptyProductOfferingProductInfoList,
      required: false,
    },
    category: { type: Number, default: 0, required: false },
    selectable: { type: Boolean, required: true },
    package: { type: String, required: false, default: '' },
    productOfferingId: { type: Number, required: true },
    isByAge: { type: Boolean, required: false, default: false },
    type: {
      type: Number as PropType<ProductOfferingTypes>,
      required: false,
      default: () => ProductOfferingTypes.SPORT,
    },
  },
  setup(props) {
    const chosen = ref<SavedProductOfferingConfigDetail[]>([])
    const logic = productFlowLogic()

    const sortedProducts = computed(() => sortOffering<ProductOfferingProductInfo>(props.products))

    function buildConfigDetailArray() {
      const configDetails: SavedProductOfferingConfigDetail[] = []

      chosen.value.forEach((option: SavedProductOfferingConfigDetail | null, i: number) => {
        if (option === null) return

        const configDetail = getEmptySavedProductOfferingConfigDetail()
        configDetail.categoryID = props.category
        configDetail.typeProductOptionID = option.typeProductOptionID
        configDetail.productID = props.products[i].productID
        configDetail.productOfferingID = props.productOfferingId
        configDetail.gradesByGender = option.gradesByGender
        configDetail.typeColorID = 'N/A'

        configDetails.push(configDetail)
      })
      return configDetails
    }

    watch(
      () => chosen.value,
      () => {
        const details = buildConfigDetailArray()

        if (details.length) {
          const productDetails = JSON.parse(JSON.stringify(details))
          store.commit.productOfferings.setSelectedProductDetails({ productDetails })
        } else {
          const sel = getEmptySavedProductOfferingConfigDetail()
          sel.categoryID = props.category
          sel.productOfferingID = props.productOfferingId
          store.commit.productOfferings.setSelectedProductDetails({ productDetails: [sel] })
        }
        logic.markAsDirty()
      }
    )

    return {
      sortedProducts,
      chosen,
    }
  },
})
