/**********************************
 * Event colors are for the primary event being displayed. For example, on the squad
 * scheduler, the squad events would be the primary event so squads for the current division would,
 * be event[Bg/Text]Color, and the squads for other divisions would be eventInactive[Bg/Text]Color.
 *
 * inactive[Bg/Text]Color is used for the secondary events. For example, on squad scheduler
 * games are also displayed, so the games would use inactive[Bg/Text]Color.
 *********************************/

export const eventBgColor = '#76FF03'
export const eventTextColor = '#1B5E20'
export const inactiveBgColor = 'rgba(38,50,56,0.4)'
export const inactiveTextColor = '#ECEFF1'
export const eventInactiveBgColor = 'rgba(100,221,23,0.5)'
export const eventInactiveTextColor = '#558B2F'
