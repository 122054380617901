import { Contact } from '@/GeneratedTypes/Partner/Contact'
import { PhoneTypeEnum } from '@/lib/support/models/IndividualPhone/data'

export function getEmptyContact(): Contact {
  return {
    alternatePhoneNumber: '',
    alternatePhoneTypeID: null,
    emailAddress: '',
    emailValid: 'true',
    emailValidReason: '',
    firstName: '',
    isFullTime: false,
    lastName: '',
    primaryPhoneNumber: '',
    primaryPhoneTypeID: PhoneTypeEnum.WORK,
    secondaryPhoneNumber: '',
    secondaryPhoneTypeID: null,
    typePartnerRoleID: '',
    typePreferredContactMethodID: '',
    typePreferredDayOfWeekID: 'Monday',
    typePreferredTimeOfDayID: '8:00',
    yearsExperience: 0,
  }
}
