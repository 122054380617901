


































import { defineComponent, ref, onMounted, computed } from '@vue/composition-api'
import store from '@/store'
import loading from '@/elements/Loading.vue'
import { AgGridVue } from '@ag-grid-community/vue'
import { AllCommunityModules } from '@ag-grid-community/all-modules'

import volunteersClient from '@/clients/volunteersClient'

import { capitalize } from 'lodash'
import moment from 'moment'

export default defineComponent({
  name: 'CoachConflict',
  components: {
    loading,
    AgGridVue,
  },
  setup() {
    const isLoading = ref(false)
    const modules = ref(AllCommunityModules)
    const gridOptions: any = { suppressColumnVirtualisation: true }

    const league = computed(() => store.getters.leagueAbstraction.currentItem)
    let api: any = null
    let columnApi: any = null
    let rowData: any = ref(null)

    onMounted(async () => {
      await loadReportData()
    })

    function capitalizeFormatter(str: any) {
      return capitalize(str.value)
    }

    function dateFormatter(params: any) {
      return params.value ? moment(params.value).format('MM/DD/YYYY hh:mm A') : ''
    }

    function onReady(params: any) {
      api = params.api
      columnApi = params.columnApi
      let columns = []
      columns.push({
        field: 'firstName',
        headerName: 'First Name',
      })
      columns.push({
        field: 'lastName',
        headerName: 'Last Name',
      })
      columns.push({
        field: 'middleInitial',
        headerName: 'Middle',
      })
      columns.push({
        field: 'gender',
        headerName: 'Gender',
      })
      columns.push({
        field: 'conflictDescription',
        headerName: 'Conflict Description',
      })
      columns.push({
        field: 'teamName',
        headerName: 'Team',
      })
      columns.push({
        field: 'divisionName',
        headerName: 'Division',
      })
      columns.push({
        field: 'typeProgramID',
        headerName: 'Program',
        valueFormatter: capitalizeFormatter,
      })
      columns.push({
        field: 'appointmentStart',
        headerName: 'Conflict Start',
        valueFormatter: dateFormatter,
      })
      columns.push({
        field: 'appointmentEnd',
        headerName: 'Conflict End',
        valueFormatter: dateFormatter,
      })
      columns.push({
        field: 'coachLinkFirstName',
        headerName: 'Coach Link First',
      })
      columns.push({
        field: 'coachLinkLastName',
        headerName: 'Coach Link Last',
      })
      columns.push({
        field: 'registrationNote',
        headerName: 'Registration Note',
      })
      columns.push({
        field: 'teamsCoached',
        headerName: 'Teams Coached',
      })
      columns.push({
        field: 'linkTeamsCoached',
        headerName: 'Teams Coached By Linked Coach',
      })

      api.setColumnDefs(columns)
    }

    async function loadReportData() {
      isLoading.value = true

      try {
        const conflictList =
          (await volunteersClient.retrieveCoachConflicts(league.value.upwardLeagueID!)) ?? []

        rowData.value = conflictList
      } catch (err) {
        rowData.value = []
      }

      isLoading.value = false
    }

    function onFirstDataRendered() {
      const cols = [
        'firstName',
        'lastName',
        'middleInitial',
        'gender',
        'conflictDescription',
        'teamName',
        'divisionName',
        'typeProgramID',
        'appointmentStart',
        'appointmentEnd',
        'coachLinkFirstName',
        'coachLinkLastName',
        'registrationNote',
        'teamsCoached,',
        'linkTeamsCoached',
      ]
      columnApi.applyColumnState({
        state: [
          { colId: 'lastName', sort: 'asc', sortIndex: 0 },
          { colId: 'firstName', sort: 'asc', sortIndex: 1 },
        ],
        defaultState: { sort: null },
      })
      columnApi.autoSizeColumns(cols, false)
    }

    function downloadCsv() {
      const parms = { processCellCallback: csvProcessCellCallback }
      api.exportDataAsCsv(parms)
    }

    function csvProcessCellCallback(params: any) {
      switch (params.column.getColId()) {
        case 'typeProgramID':
          return capitalizeFormatter(params)
        case 'appointmentStart':
          return dateFormatter(params)
        case 'appointmentEnd':
          return dateFormatter(params)
        default:
          return params.value
      }
    }

    return {
      downloadCsv,
      isLoading,
      modules,
      onReady,
      onFirstDataRendered,
      rowData,
      gridOptions,
    }
  },
})
