import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { UpwardProductGroupTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProductGroupTypeID'
import upwardTypesClient from '@/clients/upwardTypesClient'
import { ActionsImpl } from 'direct-vuex'

interface ProductGroupTypesState {
  items: { [id: string]: UpwardProductGroupTypeID[] } // key is leagueUpwardTypeId
}

const productGroupTypeState: ProductGroupTypesState = {
  items: {},
}

export enum getterNames {
  byLeagueUpwardTypeId = 'byLeagueUpwardTypeId',
}

const getters: GetterTree<ProductGroupTypesState, RootState> = {
  [getterNames.byLeagueUpwardTypeId]: (state) => (leagueUpwardTypeId: string) => {
    return state.items[leagueUpwardTypeId] || []
  },
}

export enum mutationNames {
  setForLeagueUpwardTypeId = 'setForLeagueUpwardTypeId',
}

const mutations: MutationTree<ProductGroupTypesState> = {
  [mutationNames.setForLeagueUpwardTypeId](state, { items, leagueUpwardTypeId }) {
    Vue.set(state.items, leagueUpwardTypeId, items)
  },
}

export enum actionNames {
  fetchForProductGroupTypes = 'fetchForProductGroupTypes',
}

const actions: ActionTree<ProductGroupTypesState, RootState> & ActionsImpl = {
  async [actionNames.fetchForProductGroupTypes](
    { commit },
    {
      leagueUpwardTypeId,
      programStartDate = null,
    }: { leagueUpwardTypeId: string; programStartDate: Date | null }
  ): Promise<number> {
    const result = await upwardTypesClient.retrieveProductGroupTypes(leagueUpwardTypeId, programStartDate)

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setForLeagueUpwardTypeId, { items, leagueUpwardTypeId })
      return result.data ? result.data.length : 0
    }

    return 0
  },
}

export const namespace = 'productGroupTypes'

export const productGroupTypes = {
  namespaced: true as true,
  state: productGroupTypeState,
  getters,
  actions,
  mutations,
}
