import restService from '@/services/restService'
import { Camp } from '@/GeneratedTypes/Camp'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'

const baseUrl = 'Camps/'

const create = async (camp: Camp) => {
  return await restService.post<Camp>(`${baseUrl}`, camp)
}

const update = async (id: string, camp: Camp) => {
  return await restService.put<Camp>(`${baseUrl}${id}`, camp)
}

const retrieve = async (id: string) => {
  return await restService.get<Camp>(`${baseUrl}${id}`)
}

const validateNew = async (camp: Camp, ruleSet = '') => {
  return await restService.post<UpwardVerificationDetails>(`${baseUrl}validate`, camp, {
    params: {
      ruleSet,
    },
  })
}

const validateExisting = async (id: string, camp: Camp) => {
  return await restService.post<UpwardVerificationDetails>(`${baseUrl}${id}/validate`, camp)
}

const newTemplate = async (upwardLeagueTypeID: string) => {
  return await restService.get<Camp>(`${baseUrl}new/${upwardLeagueTypeID}`)
}

export default {
  create,
  retrieve,
  update,
  validateNew,
  validateExisting,
  newTemplate,
}
