














import { defineComponent, watch, ref, computed } from '@vue/composition-api'

import SelectInput from '@/elements/SelectInput.vue'

interface SortOptionType {
  text: string
  key: string
}

export default defineComponent({
  name: 'TeamListSort',
  components: { SelectInput },
  props: {
    value: { type: String, required: true },
    program: { type: String, required: true },
  },
  setup(props, { emit }) {
    const sortBy = ref('')
    const list: SortOptionType[] = [
      { text: 'Team Name: A to Z', key: 'team_name_asc' },
      { text: 'Team Name: Z to A', key: 'team_name_desc' },
      { text: 'Average Eval Score: Low to High', key: 'avg_eval_asc' },
      { text: 'Average Eval Score: High to Low', key: 'avg_eval_desc' },
      { text: 'Average Years of Experience: Low to High', key: 'avg_years_asc' },
      { text: 'Average Years of Experience: High to Low', key: 'avg_years_desc' },
    ]

    const optionList = computed(() => {
      let options = [...list]
      const hideScore =
        props.program == 'BASKETBALLCHEERLEADING' ||
        props.program == 'FOOTBALLCHEERLEADING' ||
        props.program == 'UPWARDSELECTMSS'
      if (hideScore) options = options.filter((i) => !i.key.includes('avg_eval'))
      return options
    })

    watch(
      () => props.value,
      () => (sortBy.value = props.value),
      { immediate: true }
    )

    function update(sortBy: string) {
      emit('change', sortBy)
      emit('input', sortBy)
    }

    return {
      options: optionList,
      update,
      sortBy,
    }
  },
})
