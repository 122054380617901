

































import { defineComponent, ref, watch } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import MemoInput from '@/elements/MemoInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import TextInput from '@/elements/TextInput.vue'
import { getEmptyOrderXMLEdit } from '@/models/Order/OrderXMLEdit'
import ordersClient from '@/clients/ordersClient'

export default defineComponent({
  name: 'OrderXMLEditDialog',
  components: { Modal, MemoInput, CheckboxInput, TextInput },
  props: {
    visible: { type: Boolean, required: true, default: false },
    orderID: { type: String, required: true, default: false },
  },
  setup(props, ctx) {
    const internalVisible = ref(false)
    const xmlEdit = ref(getEmptyOrderXMLEdit())
    watch(
      () => props.visible,
      () => (internalVisible.value = props.visible)
    )

    watch(
      () => props.orderID,
      async () => await refresh(),
      { immediate: true }
    )

    async function refresh() {
      xmlEdit.value = getEmptyOrderXMLEdit() //reset to defaults
      xmlEdit.value.upwardOrderID = props.orderID
      xmlEdit.value.orderXML = (await ordersClient.getRawOrderXML(props.orderID)) ?? ''
    }

    async function saveXML() {
      const saved = await ordersClient.editOrderXML(xmlEdit.value)
      if (saved) {
        ctx.emit('close')
      }
    }

    const close = (b: Boolean) => !b && ctx.emit('close')

    return {
      internalVisible,
      xmlEdit,
      saveXML,
      close,
    }
  },
})
