
















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'

/* components */
import Calendar from '@/elements/Calendar.vue'
import HorizontalTabs, { TabType } from '@/components/HorizontalTabs.vue'
import DivisionSelect from '@/components/DivisionSelect.vue'
import SelectInput from '@/elements/SelectInput.vue'
import ScheduleDragItem from '@/views/Programs/Scheduling/Squads/ScheduleDragItem.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import ToggleLinks from '@/elements/ToggleLinks.vue'
import PracticeModal from '@/views/Programs/Scheduling/Practices/PracticeModal.vue'
import SquadScheduleInfo from '@/views/Programs/Scheduling/Games/vues/SquadScheduleInfo.vue'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'
import EditBtn from '@/elements/DataTable/vue/EditBtn.vue'
import DeleteBtn from '@/elements/DataTable/vue/DeleteBtn.vue'

/* models and utils */
import * as colors from '@/views/Programs/Scheduling/ext/colors'
import { getEmptyLeagueListItem, LeagueListItemSeed } from '@/lib/support/models/LeagueListItem/data'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import { League } from '@/GeneratedTypes/League'
import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { CalendarWeek } from '@/models/Calendar/CalendarWeek'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { ScheduleGridData, scheduleSort } from '@/views/Programs/Scheduling/ext/helper'
import { headers } from '@/views/Programs/Scheduling/Squads/ext/headers'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

import {
  isNullDate,
  SERVER_DATE_FORMAT,
  toDate,
} from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import { UpdateEnlistedEventType } from '@/models/Scheduler'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'
import { AvailabilityToScheduleBlocks } from '@/lib/support/models/ScheduleBlocks/AvailabilityToScheduleBlock'
import { FacilityEventInfo } from '@/GeneratedTypes/ListInfo/FacilityEventInfo'
import {
  getEmptyPracticeModalInfoType,
  PracticeModalInfoType,
} from '@/models/Scheduling/PracticeModalInfoType'
import { CheerSquadGameAppointmentInfoModified } from '@/models/Appointments/CheerSquadGameAppointmentInfoModified'

import { CheerSquadGameAppointmentInfo } from '@/GeneratedTypes/ListInfo/CheerSquadGameAppointmentInfo'
import { getCoachNights, getEmptyDivisionTeamInfo } from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import { eventid2id, id2eventid } from '@/views/Programs/Scheduling/ext/id_utils'
import { CalendarData } from '@/elements/Calendar/types'

/** store **/
import store from '@/store'
import { getterNames as leagueGetters, namespace as leagueStoreName } from '@/store/leagues'
import {
  actionNames as facilitiesActions,
  getterNames as facilitiesGetters,
  namespace as facilitiesStoreName,
} from '@/store/facilities'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import {
  actionNames as divisionActions,
  getterNames as divisionGetters,
  namespace as divisionNamespace,
} from '@/store/divisions'

/* plugin */
import isBetween from 'dayjs/plugin/isBetween'
import { CoachConflictTypeEnum } from '@/models/TeamManagement/CoachConflictTypeEnum'

const league = namespace(leagueStoreName)
const facilities = namespace(facilitiesStoreName)
const divisions = namespace(divisionNamespace)

const WEEK_IN_SECONDS = 7 * 24 * 3600 * 1000
const START_DAY = 0 //sunday

@Component({
  components: {
    DataTable,
    DeleteBtn,
    EditBtn,
    ToggleLinks,
    ConfirmationModal,
    Alert,
    SelectInput,
    DivisionSelect,
    HorizontalTabs,
    Calendar,
    PracticeModal,
    SquadScheduleInfo,
    ScheduleDragItem,
  },
  methods: {
    getCoachNights,
  },
  data() {
    return { headers }
  },
})
export default class SquadsSchedule extends Vue {
  /** getters */
  @league.Getter(leagueGetters.currentItem) league!: League
  @facilities.Getter(facilitiesGetters.items) facilities!: LeagueFacility[]
  @divisions.Getter(divisionGetters.allItems) divisionList!: (s: string) => LeagueDivisionInfo[]
  @divisions.Action(divisionActions.fetchAll) fetchAllDivisions!: ({
    upwardLeagueId,
    force,
  }: {
    upwardLeagueId: UpwardLeagueIDType
    force: boolean
  }) => Promise<boolean>
  @facilities.Action(facilitiesActions.load)
  private readonly retrieveFacilities!: ({ id }: { id: string }) => LeagueFacility[] | null

  get teams() {
    return this.$store.direct.getters.scheduling.teams
  }

  get appointments() {
    return this.$store.direct.getters.scheduling.appointments
  }
  get events() {
    return this.$store.direct.getters.scheduling.events
  }
  get statusText() {
    return this.$store.direct.getters.scheduling.statusText
  }

  get duration(): number {
    if (this.division) return this.division.gameLength
    return 0
  }

  get coachConflictTypeEnum(): typeof CoachConflictTypeEnum {
    return CoachConflictTypeEnum
  }

  private readonly loadAppointments = store.dispatch.scheduling.loadAppointments
  private readonly removeAppointment = store.dispatch.scheduling.removeAppointment
  private readonly upsertAppointments = store.dispatch.scheduling.upsertAppointments
  private readonly loadTeams = store.dispatch.scheduling.loadTeams
  private readonly retrieveEvents = store.dispatch.scheduling.loadEvents
  private readonly clearAppointments = store.dispatch.scheduling.clearAppointments
  private viewtype = 'list' //alternate is calendar

  private loading = true
  private readonly alertType = AlertTypeEnum.NOTICE
  private currentSelectedFacility = ''
  range: CalendarWeek | null = null

  currentRange(r: CalendarWeek) {
    this.range = r
  }

  //** this is the epoch of the start of the week **/
  private week = 0
  divisionID = 0

  schedulingType = FacilityEventEnum.GAMES

  get gameDivisionList() {
    return this.divisionList(this.leagueID).filter((x) => x.typeProgramID == this.programID)
  }

  private async refreshEvents() {
    await this.retrieveEvents({ id: this.leagueID })
  }

  auto() {
    this.$router.push('../cheer/auto')
  }

  get division() {
    return this.gameDivisionList.find((x) => x.divisionID == this.divisionID) ?? getEmptyLeagueDivisionInfo()
  }

  private currentAppointment: PracticeModalInfoType = getEmptyPracticeModalInfoType()

  /************************
   *   Grid Events
   ***********************/
  doubleClickGrid(e: DataTableSelection<ScheduleGridData>) {
    this.eventSelected({
      id: id2eventid(this.event, e.item.id.toString()),
      event: { end: new Date(), start: new Date(), title: '' },
    })
  }

  editItem(e: ScheduleGridData) {
    this.eventSelected({
      id: id2eventid(this.event, e.id.toString()),
      event: { end: new Date(), start: new Date(), title: '' },
    })
  }

  /************************
   *   Calendar Events
   ***********************/

  eventSelected(e: UpdateEnlistedEventType) {
    const id = eventid2id(this.event, e.id) || '0'
    const game = this.appointments.find((x) => x.id == parseInt(id))
    if (game) {
      this.currentAppointment = this.CSGAI2PracticeModal(game)
      this.schedulingModalShowing = true
    }
  }

  // External event dragged onto the calendar
  eventDropped(ev: CalendarData) {
    const start = dayjs(ev.start)

    if (this.validDayOfWeek(start.day())) {
      const teamID = ev.id
      this.currentAppointment = cloneDeep(this.makeNewAppointment())
      this.currentAppointment.startDate = start.toDate()
      this.currentAppointment.endDate = dayjs(this.currentAppointment.startDate)
        .add(this.division.gameLength, 'm')
        .toDate()
      this.currentAppointment.teamID = Number(teamID)
      this.currentAppointment.lastDate = this.league.seasonEndDate
      this.schedulingModalShowing = true
    }
  }

  // event moved from within the calendar
  eventMoved(ev: CalendarData) {
    const start = dayjs(ev.start)
    const end = dayjs(ev.end)

    const id = eventid2id(this.event, ev.id) || '0'
    const game = this.appointments.find((x) => x.id == parseInt(id))
    if (game) {
      this.confirmAppointment({
        ...this.CSGAI2PracticeModal(game),
        endDate: end.toDate(),
        startDate: start.toDate(),
        lastDate: null,
      })
    }
  }

  eventClick(e: CalendarData) {
    this.eventSelected({
      id: e.id,
      event: { end: new Date(), start: new Date(), title: '' },
    })
  }

  validDayOfWeek(dayOfWeek: number) {
    return this.weekdays.some((d) => d == dayOfWeek)
  }
  /**********************
   * Calendar Options
   ********************/
  get weekdays(): number[] {
    const facility = this.facilities.find((x) => x.facilityID == this.currentFacilityID)

    if (facility) {
      return AvailabilityToScheduleBlocks(facility.availability ?? [], FacilityEventEnum.GAMES).map(
        (f) => f.day
      )
    }
    return [0, 1, 2, 3, 4, 5, 6]
  }

  /***
   * CSGAI2PracticeModal
   */
  CSGAI2PracticeModal(i: CheerSquadGameAppointmentInfo): PracticeModalInfoType {
    return {
      teamID: i.teamID,
      facilityID: i.facilityID,
      endDate: toDate(i.gameEnd) ?? new Date(),
      startDate: toDate(i.gameStart) ?? new Date(),
      lastDate: null,
      id: i.id,
      updateSeason: false,
    }
  }

  /***************************
   * Clear all appointements
   ***************************/
  teamToClear = 0

  get teamsListAndAllTeams() {
    return [{ ...getEmptyDivisionTeamInfo(), teamID: 0, teamName: 'All Squads' }, ...this.teamsList]
  }

  confirmClearAppointmentsShowing = false
  clickClearAppointments() {
    this.confirmClearAppointmentsShowing = true
  }
  async confirmClearAppointments(b: boolean) {
    if (b) {
      this.confirmClearAppointmentsShowing = false
      this.showPracticesCleared = true
      await this.clearAppointments({
        id: this.leagueID,

        teamIDs: this.teamToClear ? [this.teamToClear] : this.divisionTeams.map((x) => x.teamID),
      })
    }
    this.confirmClearAppointmentsShowing = false
  }

  showPracticesCleared = false

  /**********************
   * Add/Edit Modal
   **********************/
  schedulingModalShowing = false
  clickAddGame() {
    this.currentAppointment = cloneDeep(this.makeNewAppointment())
    this.schedulingModalShowing = true
  }

  /**
   * Returns a "game round" list for the drop down.
   * Apparently 1..n+1
   */
  get roundList() {
    const roundlimit = this.division.maxRoundNumber || 16
    return Array.from(Array(roundlimit), (x, i) => ({ id: i + 1, description: ` ${i + 1}` }))
  }

  private readonly round = 1 //default

  /** ID of currently selected facilty **/
  get currentFacilityID() {
    return parseInt(this.currentSelectedFacility)
  }
  get currentFacility() {
    return this.currentSelectedFacility
  }
  set currentFacility(facility: string) {
    this.currentSelectedFacility = facility
  }

  /***
   * Used to constrain the calendar
   *
   */

  get firstGameDate(): string {
    const gamedate = this.league.firstGameDate ?? new Date()
    return dayjs(gamedate).format('YYYY-MM-DD')
  }

  /***
   * This logic will change per Thomas
   */
  get endEpoch(): number {
    // 16 WEEKS LATER
    return this.startEpoch + WEEK_IN_SECONDS * 16
  }

  /***
   * Build a new game on add game click
   */
  makeNewAppointment(): PracticeModalInfoType {
    const start = dayjs(this.startEpoch).hour(8)
    const end = start.add(this.division.gameLength ?? 0, 'm')
    return {
      ...getEmptyPracticeModalInfoType(),
      facilityID: this.currentFacilityID,
      startDate: start.toDate(),
      endDate: end.toDate(),
      lastDate: null,
    }
  }

  get startEpoch(): number {
    const gamedate = this.league.firstGameDate ?? new Date()
    return dayjs(gamedate).day(START_DAY).hour(0).minute(0).millisecond(0).valueOf() //get epoch of sunday before game day
  }

  /**
   * Filtered by current division
   */
  get teamsList() {
    return this.teams.filter((t) => t.divisionID == this.divisionID)
  }
  get unscheduledTeams() {
    const start = this.range ? this.range.start : null
    const end = this.range ? this.range.end : null
    const scheduledSquads: string[] = [
      ...new Set(
        this.gridSchedule
          .filter((i) => {
            if (start && end) {
              return dayjs(i.start).isBetween(start, end, 'day', '[]')
            }
            return false
          })
          .map((s) => s.title)
      ),
    ]
    return this.teamsList
      .filter((t) => !scheduledSquads.some((tname) => tname == t.teamName))
      .map((t) => {
        return {
          name: t.teamName,
          start: null,
          end: null,
          class: '',
          id: t.teamID,
          headCoachName: t.headCoachName,
          nights: getCoachNights(t),
          color: colors.eventBgColor,
          textColor: colors.eventTextColor,
          division: t.divisionName,
        }
      })
  }

  /**
   * Returns all games that are scheduled for the active facility.
   * Appointment is grey and inactive if not meeting division criteria.
   */
  private get schedule(): CalendarData[] {
    return this.events
      .filter((x) => x.eventStart && x.eventEnd && this.meetsFacilityCriteria(x))
      .map((x) => ({
        start: dayjs(x.eventStart ?? '').format('YYYY-MM-DDTHH:mm'),
        end: dayjs(x.eventEnd ?? '').format('YYYY-MM-DDTHH:mm'),
        name: x.eventName || '',
        class: this.meetsDivisionCriteria(x) && this.meetsTypeCriteria(x) ? 'active' : 'inactive',
        id: id2eventid(this.event, x.eventID.toString()),
        division: x.divisionName ?? '',
        color: this.color(x, 'COLOR'),
        textColor: this.color(x, 'TEXT'),
      }))
  }

  private color(e: FacilityEventInfo, type: 'COLOR' | 'TEXT') {
    if (
      e.typeFacilityEventID == FacilityEventEnum.PRACTICES ||
      e.typeFacilityEventID == FacilityEventEnum.GAMES
    ) {
      return type == 'COLOR' ? colors.inactiveBgColor : colors.inactiveTextColor
    } else if (e.typeFacilityEventID == FacilityEventEnum.SQUADS) {
      if (this.meetsDivisionCriteria(e)) {
        return type == 'COLOR' ? colors.eventBgColor : colors.eventTextColor
      } else {
        return type == 'COLOR' ? colors.eventInactiveBgColor : colors.eventInactiveTextColor
      }
    }
  }

  private meetsFacilityCriteria(game: DivisionGameInfo | FacilityEventInfo) {
    return game?.facilityID == this.currentFacilityID
  }

  private meetsTypeCriteria(game: FacilityEventInfo) {
    return game.typeFacilityEventID == FacilityEventEnum.SQUADS
  }

  private meetsDivisionCriteria(game: DivisionGameInfo | FacilityEventInfo) {
    return game.divisionID == this.divisionID
  }

  teamFromID(teamID: number) {
    return this.teams.find((x) => x.teamID == teamID)
  }

  divisionFromID(divisionID: number) {
    return this.gameDivisionList.find((x) => x.divisionID == divisionID)
  }
  facilityFromID(id: number) {
    return this.facilities.find((x) => x.facilityID == id)
  }

  /**
   * Returns all games for the criteria.
   */
  private get gridSchedule(): ScheduleGridData[] {
    return this.appointments
      .filter((x) => !isNullDate(toDate(x.gameStart)) && x.divisionID === this.divisionID)
      .map((x: CheerSquadGameAppointmentInfo) => ({
        start: dayjs(x.gameStart ?? '').toDate(),
        end: dayjs(x.gameEnd ?? '').toDate(),
        prettyDate: dayjs(x.gameStart ?? '').format('MMM DD'),
        prettyTime: dayjs(x.gameStart ?? '').format('h:mmA') + ' - ' + dayjs(x.gameEnd ?? '').format('h:mmA'),
        title: `${this.teamFromID(x.teamID)?.teamName}`,
        id: x.id,
        location: this.facilityFromID(x.facilityID)?.facilityName ?? '',
        division: this.divisionFromID(x.divisionID)?.divisionName ?? '',
        round: 0,
      }))
      .sort(scheduleSort)
  }

  idTODelete = 0
  showDeleteConfirmed = false
  showDeleteConfirmation = false

  /***
   * This is called when delete is selected off the grid
   */

  async deleteConfirmed(b: boolean) {
    if (b) {
      await this.removeAppointment({ id: this.leagueID, appointmentID: this.idTODelete })
      this.showDeleteConfirmed = true
    }
    this.showDeleteConfirmation = false
  }

  deleteItem(r: ScheduleGridData) {
    this.idTODelete = r.id
    this.showDeleteConfirmation = true
  }

  async deleteAppointment(newelement: PracticeModalInfoType) {
    await this.removeAppointment({ id: this.leagueID, appointmentID: newelement.id })
    this.schedulingModalShowing = false
    this.showDeleteConfirmed = true
  }

  @Watch('endEpoch')
  changeInEndEpoch() {
    if (this.week > this.endEpoch) {
      this.week = dayjs(this.endEpoch).day(START_DAY).subtract(7, 'day').valueOf()
    }
  }

  @Watch('startEpoch')
  changeInStartEpoch() {
    if (this.week < this.startEpoch) {
      this.week = this.startEpoch
    }
  }

  @Watch('division')
  divisionUpdated() {
    this.refreshTeams()
  }

  @Watch('gameDivisionList', { immediate: true })
  gameDivisionListUpdated() {
    if (this.gameDivisionList.length) {
      this.divisionID = this.gameDivisionList[0].divisionID
    }
  }

  /**
   * Used after a modal is confirmed
   */
  async confirmAppointment(modalInfo: PracticeModalInfoType) {
    const newgames: CheerSquadGameAppointmentInfoModified[] = []

    if (!modalInfo.lastDate) {
      newgames.push({ ...this.basicAppointmentInfo(modalInfo) })
    } else {
      const apptlength = modalInfo.endDate.valueOf() - modalInfo.startDate.valueOf()
      if (modalInfo.lastDate || modalInfo.id === 0) {
        const lastDate = modalInfo.lastDate ?? modalInfo.endDate

        const activestart = dayjs(modalInfo.startDate)
        const eodLastDay = dayjs(lastDate).hour(24).minute(0).toDate().valueOf()
        for (let week = 0; activestart.add(week, 'week').toDate().valueOf() < eodLastDay; week++) {
          newgames.push({
            ...this.basicAppointmentInfo(modalInfo),

            gameStart: activestart.add(week, 'week').format(SERVER_DATE_FORMAT),
            gameEnd: activestart.add(week, 'week').add(apptlength, 'millisecond').format(SERVER_DATE_FORMAT),
          })
        }
      }
    }

    try {
      if (newgames.length > 0) {
        await this.upsertAppointments({ id: this.leagueID, appointments: newgames })
      }
    } catch (e) {
      throw e
    }

    this.schedulingModalShowing = false
  }

  basicAppointmentInfo(modalInfo: PracticeModalInfoType): CheerSquadGameAppointmentInfoModified {
    return {
      gameStart: dayjs(modalInfo.startDate).format(SERVER_DATE_FORMAT),
      gameEnd: dayjs(modalInfo.endDate).format(SERVER_DATE_FORMAT),
      divisionID: this.divisionID,
      facilityID: modalInfo.facilityID,
      facilityName: '',
      leagueID: this.league.id,
      id: modalInfo.id,
      teamID: modalInfo.teamID,
      typeProgramID: this.programID,
    }
  }

  event = FacilityEventEnum.GAMES

  /***
   * Restricts tabs to the facilities that have programming for the given event
   */
  get facilitiesAsTabs(): TabType[] {
    return (
      this.facilities
        .filter((y) => (y.availability || []).findIndex((z) => z.typeFacilityEventID == this.event) >= 0)
        .map((x) => ({
          id: x.facilityID.toString(),
          description: x.facilityName,
        })) ?? []
    )
  }

  private eventFacilities: LeagueFacility[] = []

  @Watch('facilities', { immediate: true })
  facilitiesUpdated() {
    this.eventFacilities = this.facilities.filter(
      (y) => (y.availability || []).findIndex((z) => z.typeFacilityEventID == this.event) >= 0
    )
  }

  refreshAppointments() {
    this.loadAppointments({ id: this.leagueID })
  }

  refreshLeagues() {
    this.fetchAllDivisions({ upwardLeagueId: this.leagueID, force: true })
  }

  get divisionTeams() {
    return this.teams.filter((x) => x.divisionID == this.divisionID)
  }

  /**
   * Get teams
   */
  private async refreshTeams() {
    if (this.programID) {
      await this.loadTeams({
        id: this.leagueID,

        programID: this.programID,
      })
    }
  }

  /**
   * Get teams
   */
  private async refreshFacilities() {
    await this.retrieveFacilities({ id: this.leagueID })
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */

  get programID() {
    if (this.league && this.league.programs && this.league.programs?.length > 1) {
      return this.league.programs[1].typeProgramID || ''
    }
    return ''
  }

  get leagueID() {
    return this.$route.params.id
  }

  public async mounted() {
    dayjs.extend(isBetween)
    await Promise.all([
      this.refreshLeagues(),
      this.refreshFacilities(),
      this.refreshAppointments(),
      this.refreshEvents(),
    ])
    await this.refreshTeams()
    this.loading = false
  }

  get tabs(): LeagueListItem {
    if (this.league && this.league.programs) {
      return LeagueProgams2LeagueListItem(this.league.programs)
    }
    return getEmptyLeagueListItem(new LeagueListItemSeed())
  }
}
