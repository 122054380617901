import { IndividualAddressInfo } from '@/GeneratedTypes/ListInfo/IndividualAddressInfo'
import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'

export function individualAddressInfoToIndividualAddress(a: IndividualAddressInfo): IndividualAddress {
  return {
    addressID: a.addressID,
    postalCode: a.postalCode ?? '',
    priority: a.priority,
    street1: a.street1 ?? '',
    street2: a.street2,
    subdivision1: a.subdivision1 ?? '',
    subdivision2: a.subdivision2 ?? '',
    typeAddressID: a.typeAddressID ?? '',
    typeCountryID: a.typeCountryID,
  }
}

export function individualAddressInfoArrToIndividualAddressArr(
  a: IndividualAddressInfo[] | null
): IndividualAddress[] {
  if (a == null) {
    return []
  }
  return a.map((x) => individualAddressInfoToIndividualAddress(x))
}
