






import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'ShowToggle',
  props: {
    label: String,
    show: Boolean,
  },
  setup(props, ctx) {
    const showLabel = '<i class="far fa-eye"></i> show'
    const hideLabel = '<i class="far fa-eye-slash"></i> hide'
    function toggle() {
      ctx.emit('clicked', !props.show)
    }

    const fullLabel = computed(() => {
      if (props.show) return `${hideLabel} ${props.label}`
      return `${showLabel} ${props.label}`
    })
    return {
      toggle,
      fullLabel,
    }
  },
})
