

















































































































import { defineComponent, computed, PropType, ref } from '@vue/composition-api'
import Vue from 'vue'
import { PlayerConflictInfo } from '@/GeneratedTypes/ListInfo/PlayerConflictInfo'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { DivisionTeamPracticeAppointmentInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamPracticeAppointmentInfo'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import participantsClient from '@/clients/participantsClient'
import teamsClient from '@/clients/teamsClient'
import { uniq } from 'lodash'
import { toDate } from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import { getProperGradeLabel } from '@/services/gradeService'
import store from '@/store'

interface SiblingInfo {
  label: string
  value: string
  class: string
}

export default defineComponent({
  name: 'PlayerConflictListElement',
  props: {
    conflict: { type: Object as PropType<PlayerConflictInfo>, required: true },
  },
  setup(props, ctx) {
    const siblings = ref<SiblingInfo[]>([])

    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)

    const carPoolLink = computed(() => {
      const c = props.conflict
      if (c.carpoolLinkFirstName) {
        return `${c.carpoolLinkFirstName} ${c.carpoolLinkLastName}`
      }
      return ''
    })

    const coachLink = computed(() => {
      const c = props.conflict
      if (c.coachLinkFirstName) {
        return `${c.coachLinkFirstName} ${c.coachLinkLastName}`
      }
      return ''
    })
    const practiceNights = computed(() => {
      return props.conflict?.practiceNightExclusions
        ?.map((x) => x.typeDayID ?? '')
        .map((x) => days.find((y) => y.name == x)?.abbr3)
        ?.join(',')
    })

    const teamPracticeNights = computed(() => {
      return props.conflict?.teamPracticeNights
        ?.map((x) => x.upwardTypeID ?? '')
        .map((x) => days.find((y) => y.name == x)?.abbr3)
        ?.join(',')
    })

    const teamCoaches = computed(() => {
      return props.conflict?.teamCoaches?.map((x) => x.formattedNameFirstLast ?? '')?.join(',')
    })

    const manuallyAssigned = computed(() => {
      return props.conflict.manualAssignment ? 'Yes' : 'No'
    })

    const lockedToTeam = computed(() => {
      return props.conflict.playerIsLockedToTeam ? 'Yes' : 'No'
    })

    const showCarpoolLink = computed(() => {
      return props.conflict.typeDraftExceptionID === 'CARPOOLLINKNOTHONORED'
    })

    const showCoaches = computed(() => {
      return props.conflict.typeDraftExceptionID === 'PARENTCOACHLINKNOTHONORED'
    })

    const showPracticeNights = computed(() => {
      return (
        props.conflict.typeDraftExceptionID === 'PRACTICENIGHTEXCLUSIONNOTHONORED' ||
        props.conflict.typeDraftExceptionID === 'SIBLINGLINKNOTHONORED'
      )
    })

    const showSiblingInfoLink = computed(() => {
      return props.conflict.typeDraftExceptionID === 'SIBLINGLINKNOTHONORED'
    })

    const showUDFs = computed(() => {
      return udfsWithValues.value?.length > 0
    })

    const udfsWithValues = computed(() => {
      return props.conflict.udFs?.filter((x) => x.udfValue) ?? []
    })

    const exceptionIconClass = computed(() => {
      switch (props.conflict.typeDraftExceptionID) {
        case 'CARPOOLLINKNOTHONORED':
          return 'fas fa-car-side'
        case 'PARENTCOACHLINKNOTHONORED':
          return 'fas fa-user'
        case 'PRACTICENIGHTEXCLUSIONNOTHONORED':
          return 'fas fa-calendar-alt'
        case 'SIBLINGLINKNOTHONORED':
          return 'fas fa-user-friends'
        default:
          return 'far fa-question-circle'
      }
    })

    async function scrollToTeam() {
      //this is all ugly.  I'm sure there is a better way.  But it works I guess, so....
      const selector = "div[class-name='team-droppable'][data-id='" + props.conflict.teamID.toString() + "']"
      let div = document.querySelector(selector)
      if (!div) {
        ctx.emit('toggle-team', props.conflict.teamID) //assume team is not visible yet;  turn it on
        await Vue.nextTick() //...give team box time to load  (for some reason it takes two of these calls to get the next line to return something)
        await Vue.nextTick(() => (div = document.querySelector(selector))) //...try to find new team box)
      }

      if (div) {
        div.scrollIntoView({ behavior: 'smooth' })
        const playerDiv = div.querySelector(
          "div[class~='team-participant-element'][data-id='" + props.conflict.individualID.toString() + "']"
        )
        if (playerDiv) {
          if (!playerDiv.className.includes('flashing')) {
            //if the player is not already being flashed, then flash them
            const orgClassName = playerDiv.className
            playerDiv.className += ' flashing'
            setTimeout(() => (playerDiv.className = orgClassName), 2000)
          }
        }
      }
    }

    async function loadSiblingInfo() {
      if (siblings.value.length === 0) {
        const siblingList =
          (await participantsClient.retrieveSiblingLinks(
            ctx.root.$route.params.id,
            props.conflict.individualID
          )) ?? []
        if (siblingList) {
          const calls: Promise<void>[] = []
          siblingList.forEach((sibling: LeaguePlayerInfo) => calls.push(getSiblingTeamInfo(sibling)))
          await Promise.all(calls)
        }
      }
    }

    async function getSiblingTeamInfo(sibling: LeaguePlayerInfo) {
      const teamsInfo = await teamsClient.retrieveTeamInfo({
        leagueID: ctx.root.$route.params.id,
        individualId: sibling.individualID,
      })
      let assignmentInfo = 'Not assigned to a team'
      if (teamsInfo && teamsInfo.length > 0) {
        assignmentInfo = `${teamsInfo[0].divisionName} (${teamsInfo[0].teamName}) ${practiceNightFormatter(
          teamsInfo[0].practices
        )}`
      }
      siblings.value.push({
        label: sibling.formattedNameFirstLast!,
        value: assignmentInfo,
        class: '',
      })
    }

    function practiceNightFormatter(nights: DivisionTeamPracticeAppointmentInfo[] | null) {
      let retval = 'No practices scheduled'

      if (nights && nights.length > 0) {
        retval = uniq(nights.map((x) => toDate(x.practiceStart).getDay() ?? -1))
          .map((x) => days.find((y) => y.value == x)?.abbr3)
          ?.join(',')
      }

      return retval
    }

    function showAdminNoteModal() {
      ctx.emit('show-admin-note-modal')
    }

    return {
      carPoolLink,
      coachLink,
      practiceNights,
      teamPracticeNights,
      lockedToTeam,
      manuallyAssigned,
      teamCoaches,
      showCarpoolLink,
      showCoaches,
      showPracticeNights,
      showSiblingInfoLink,
      showUDFs,
      udfsWithValues,
      exceptionIconClass,
      scrollToTeam,
      siblings,
      loadSiblingInfo,
      showAdminNoteModal,
      isByAge,
      getProperGradeLabel,
    }
  },
})
