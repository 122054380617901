











import { defineComponent, ref, computed, watch } from '@vue/composition-api'

export enum AlertTypeEnum {
  DANGER = 'danger',
  NOTICE = 'notice',
}

export default defineComponent({
  name: 'Alert',
  props: {
    value: { type: Boolean, required: true },
    dismissable: { type: Boolean, required: false, default: true },
    timeout: { type: Number, required: false, default: 10 * 1000 },
    type: { type: String, required: false, default: AlertTypeEnum.DANGER },
  },
  setup(props, ctx) {
    const timeoutHandle = ref<NodeJS.Timeout | null>(null)

    function dismiss() {
      ctx.emit('input', false)
      ctx.emit('change', false)
    }

    const typeClass = computed(() => {
      switch (props.type) {
        case AlertTypeEnum.DANGER:
          return 'toast-danger'
        case AlertTypeEnum.NOTICE:
          return 'toast-notice'
      }
    })

    const classNames = computed(() => `${props.value ? 'show d-block' : ''} ${typeClass.value}`)

    watch(
      () => props.value,
      () => {
        if (!props.value && timeoutHandle.value) {
          clearTimeout(timeoutHandle.value)
        }

        if (props.value && props.timeout) {
          timeoutHandle.value = setTimeout(dismiss, props.timeout)
        }
      }
    )

    return { classNames, dismiss }
  },
})
