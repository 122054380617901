import { computed, ref } from '@vue/composition-api'
import facilitiesClient from '@/clients/facilitiesClient'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'

export function useFacilities() {
  const facilities = ref<LeagueFacility[]>([])

  async function refreshFacilities(leagueID: string) {
    facilities.value = (await facilitiesClient.retrieveAll(leagueID, false)) ?? []
  }

  return {
    facilities: computed(() => facilities.value),
    refreshFacilities,
  }
}
