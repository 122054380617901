









import { defineComponent, computed, onMounted } from '@vue/composition-api'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import store from '@/store'

export default defineComponent({
  name: 'Setup',
  components: {
    VerticalTabs,
    Loading,
  },
  setup(props, ctx) {
    const connect = computed(() => store.getters.connects.currentItem)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const baseRoute = computed(() => `/programs/connect/${routeId.value}setup`)

    const tabs = computed(() => {
      if (connect.value && !connect.value.canEdit) {
        return []
      }
      return [
        { text: 'Program Information', to: `/programs/connect/${routeId.value}setup/information` },
        { text: 'Director', to: `/programs/connect/${routeId.value}setup/leadership` },
      ]
    })

    onMounted(async () => {
      const archivedRoute = `${baseRoute.value}/archive`
      if (connect.value && !connect.value.canEdit && ctx.root.$route.path !== archivedRoute) {
        await ctx.root.$router.push(archivedRoute)
      }
    })

    return {
      tabs,
    }
  },
})
