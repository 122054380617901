
































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'
import uuid from 'uuid'

export default defineComponent({
  name: 'ComboBoxInput',
  components: {
    BaseInputWrapper,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    multiple: { type: Boolean, default: false, required: false },
    chips: { type: Boolean, default: false, required: false },
    dense: { type: Boolean, default: false, required: false },
    deletableChips: { type: Boolean, default: false, required: false },
    enabled: { type: Boolean, default: true, required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    id: { type: String, default: '', required: false },
    value: { required: true },
    textPropertyName: { type: String, default: '', required: false },
    valuePropertyName: { type: String, default: '', required: false },
    placeholder: { type: String, default: '', required: false },
    itemsList: { type: Array, required: true },
  },
  setup(props, ctx) {
    const guid = ref(uuid.v4())
    const internalValue = ref<any | null>(props.value)

    const elementId = computed(() => props.id || `combobox-${guid.value}`)

    watch(
      () => internalValue.value,
      () => {
        ctx.emit('input', internalValue.value)
      }
    )

    watch(
      () => props.value,
      () => {
        if (internalValue.value !== props.value) {
          internalValue.value = props.value
        }
      }
    )

    const valueProperty = computed(() => {
      if (!props.valuePropertyName && props.textPropertyName) {
        return props.textPropertyName
      }
      return props.valuePropertyName
    })

    const textProperty = computed(() => {
      if (!props.textPropertyName && props.valuePropertyName) {
        return props.valuePropertyName
      }
      return props.textPropertyName
    })

    return {
      elementId,
      internalValue,
      textProperty,
      valueProperty,
    }
  },
})
