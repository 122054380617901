










import { computed, defineComponent, PropType } from '@vue/composition-api'

import { filterNA, OrderProductLine } from '@/models/Order/OrderProduct'

export default defineComponent({
  name: 'CartLine',
  methods: { filterNA },
  props: {
    product: {
      type: Object as PropType<OrderProductLine>,
      required: true,
    },
  },
  setup(props) {
    // in a normalized product qty selection each product has a variation
    const variation = computed(() => props.product.productColorSize)

    const variationDesc = computed(() => {
      if (filterNA(variation.value.sizeDescription)) {
        return `(${filterNA(variation.value.sizeDescription)})`
      }
      return ''
    })

    return { variation, variationDesc }
  },
})
