

















import { defineComponent, PropType } from '@vue/composition-api'
import { VerticalTabValue } from '@/lib/support/components/VerticalTabs/VerticalTabValue'
import { Route } from 'vue-router'
import { RouteResolver } from '@/lib/support/components/VerticalTabs/RouteResolver'

export default defineComponent({
  name: 'MenuTab',
  props: {
    tab: { type: Object as PropType<VerticalTabValue>, required: true },
    IsOnRoute: {
      type: Function as PropType<RouteResolver>,
      required: false,
      default: ($route: Route, to: string) => $route.path.indexOf(to) !== -1,
    },
  },
})
