import { LeaguePracticeNight } from '@/GeneratedTypes/LeaguePracticeNight'
import { FacilityAvailability } from '@/GeneratedTypes/FacilityAvailability'
import { eventTypes } from '@/store/facilities'

class FacilityAvailabilityModel implements FacilityAvailability {
  id!: number
  typeDayID!: string
  typeFacilityEventID!: string
  startTime!: string
  endTime!: string
}

export const FACILITY_DEFAULT_TIMES = {
  start: '07:00',
  end: '22:00',
}

const updatePracticeDays = (
  days: LeaguePracticeNight[] | null,
  availability: FacilityAvailability[] | null
) => {
  if (days) {
    //add practice days that don't exist
    if (availability) {
      days.forEach((d) => {
        const existingDay = availability.find(
          (a) => a.typeDayID == d.typeDayID && a.typeFacilityEventID == eventTypes.Practices
        )

        // add practice day to availability if it doesn't exist
        if (!existingDay) {
          const practice: FacilityAvailability = new FacilityAvailabilityModel()
          practice.typeDayID = d.typeDayID
          practice.typeFacilityEventID = eventTypes.Practices
          practice.startTime = FACILITY_DEFAULT_TIMES.start
          practice.endTime = FACILITY_DEFAULT_TIMES.end
          availability.push(practice)
        }
      })
    }
  }

  //remove practice days that are not selected
  const daysToRemove: string[] = []

  if (availability && days) {
    availability.forEach((a) => {
      if (a.typeFacilityEventID == eventTypes.Practices) {
        const selectedDay = days.find((d) => d.typeDayID == a.typeDayID)

        if (!selectedDay || days.length === 0) {
          daysToRemove.push(a.typeDayID.toString())
        }
      }
    })
  }

  if (availability) {
    daysToRemove.forEach((item) => {
      const dayIndx = availability.findIndex(
        (a) => a.typeDayID === item && a.typeFacilityEventID == eventTypes.Practices
      )

      if (dayIndx > -1) {
        availability.splice(dayIndx, 1)
      }
    })
  }
}

const updateGameDays = (days: string[] | null, availability: FacilityAvailability[] | null) => {
  if (days) {
    //add practice days that don't exist
    if (availability) {
      days.forEach((d) => {
        const existingDay = availability.find(
          (a) => a.typeDayID == d && a.typeFacilityEventID == eventTypes.Games
        )

        // add practice day to availability if it doesn't exist
        if (!existingDay) {
          const game: FacilityAvailability = new FacilityAvailabilityModel()
          game.typeDayID = d
          game.typeFacilityEventID = eventTypes.Games
          game.startTime = FACILITY_DEFAULT_TIMES.start
          game.endTime = FACILITY_DEFAULT_TIMES.end
          availability.push(game)
        }
      })
    }
  }

  //remove game days that are not selected
  const daysToRemove: string[] = []

  if (availability && days) {
    availability.forEach((a) => {
      if (a.typeFacilityEventID == eventTypes.Games) {
        const selectedDay = days.find((d) => d == a.typeDayID)

        if (!selectedDay || days.length === 0) {
          daysToRemove.push(a.typeDayID.toString())
        }
      }
    })
  }

  if (availability) {
    daysToRemove.forEach((item) => {
      const dayIndx = availability.findIndex(
        (a) => a.typeDayID === item && a.typeFacilityEventID == eventTypes.Games
      )

      if (dayIndx > -1) {
        availability.splice(dayIndx, 1)
      }
    })
  }
}

export default {
  updatePracticeDays,
  updateGameDays,
}
