

















































import { defineComponent, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import SearchInput from '@/elements/SearchInput.vue'
import { ReturnsOrderSearchByEnum } from '@/models/Dashboard/ReturnsOrder'
import { useHoldDialog, useOrderHistory } from '@/views/AdminDash/vues/OrderHistory/useOrderHistory'
import OrderItem from '@/views/AdminDash/vues/OrderHistory/OrderItem.vue'
import Loading from '@/elements/Loading.vue'
import HoldReasonDialog from '@/views/AdminDash/vues/OrderHistory/HoldReasonDialog.vue'
import { OrderFindInfo } from '@/GeneratedTypes/Order/ListInfo/OrderFindInfo'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'

export default defineComponent({
  components: { HoldReasonDialog, Loading, OrderItem, Alert, RadioGroupInput, SearchInput },
  setup(p, ctx) {
    const searchByList = [
      { id: ReturnsOrderSearchByEnum.NEW, label: 'New Returns' },
      { id: ReturnsOrderSearchByEnum.RETURNS_SCM, label: 'Under Review by SCM' },
      { id: ReturnsOrderSearchByEnum.RETURNS_ACCOUNTING, label: 'Ready for Accounting Review' },
      { id: ReturnsOrderSearchByEnum.RETURNS_PS, label: 'Ready for Partner Support' },
      { id: ReturnsOrderSearchByEnum.RETURNS_PENDING_ITEM_RETURN, label: 'Pending Physical Item Return' },
      { id: ReturnsOrderSearchByEnum.ORDER_ID, label: 'Search by Return Order ID' },
      { id: ReturnsOrderSearchByEnum.ACCOUNT_NUMBER, label: 'Search by Account Number' },
    ]

    const retOrderTypeString = 'RETURNORDER'
    const searchBy = ref(ReturnsOrderSearchByEnum.NEW.toString())
    const searchTerm = ref('')
    const uh = useOrderHistory()
    function runSearch() {
      const val = parseInt(searchBy.value)
      if (val == ReturnsOrderSearchByEnum.NEW) {
        uh.searchOrders({ typeOrderID: retOrderTypeString, typeOrderStatusID: 'NEW' })
      } else if (val == ReturnsOrderSearchByEnum.RETURNS_SCM) {
        uh.searchOrders({ typeOrderID: retOrderTypeString, typeOrderStatusID: 'RETURNS_SCM' })
      } else if (val == ReturnsOrderSearchByEnum.RETURNS_ACCOUNTING) {
        uh.searchOrders({ typeOrderID: retOrderTypeString, typeOrderStatusID: 'RETURNS_ACCOUNTING' })
      } else if (val == ReturnsOrderSearchByEnum.RETURNS_PS) {
        uh.searchOrders({ typeOrderID: retOrderTypeString, typeOrderStatusID: 'RETURNS_PS' })
      } else if (val == ReturnsOrderSearchByEnum.RETURNS_PENDING_ITEM_RETURN) {
        uh.searchOrders({ typeOrderID: retOrderTypeString, typeOrderStatusID: 'RETURNS_PENDING_ITEM_RETURN' })
      } else if (val == ReturnsOrderSearchByEnum.ORDER_ID) {
        uh.searchOrders({ typeOrderID: retOrderTypeString, upwardOrderID: searchTerm.value })
      } else if (val == ReturnsOrderSearchByEnum.ACCOUNT_NUMBER) {
        uh.searchOrders({ typeOrderID: retOrderTypeString, accountNumber: searchTerm.value })
      }
    }
    const errorMessage = ref('')

    async function clicked(o: OrderInfoExt) {
      await ctx.root.$router.push(
        `admin-dashboard/returns-order-detail/${o.upwardLeagueID}/${o.upwardOrderID}`
      )
    }

    const uho = useHoldDialog()
    const releaseMessage = ref('')
    const showReleaseMessage = ref(false)
    async function releaseOrder(o: OrderFindInfo, reason: string) {
      try {
        await uho.release(o, reason)
        releaseMessage.value = 'Order Released'
        showReleaseMessage.value = true
      } catch (e) {
        throw e
      } finally {
        runSearch()
      }
    }

    return {
      ...uh,
      releaseOrder,
      showReleaseMessage,
      releaseMessage,
      alertType: AlertTypeEnum.NOTICE,
      ...uho,

      searchBy,
      searchTerm,
      searchByList,
      errorMessage,
      clicked,
      runSearch,
    }
  },
})
