import { namespace as authNamespace, getterNames as authGetterName } from '@/store/authorization'

export const uniqueUserIdCheck = (rootGetters: any) => {
  let uniqueUserId = rootGetters[`${authNamespace}/${authGetterName.userData}`]
  const account = rootGetters[`${authNamespace}/${authGetterName.firstAccountNumber}`]
  uniqueUserId = uniqueUserId.replace(/[^a-zA-Z0-9 ]/g, '')
  if (!uniqueUserId || uniqueUserId == '') {
    throw new Error('cannot proceed without uniqueUserId')
  } else {
    return `${account}.${uniqueUserId}`
  }
}
