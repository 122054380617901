

































































































































































import { defineComponent, computed, PropType } from '@vue/composition-api'
import { getProps, useTeamCard } from '@/composables/TeamCard'
import { getTotalTeamHeight } from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import { TeamPlayerInfoToLeaguePlayerInfo } from '@/lib/support/models/TeamPlayerInfo/TeamPlayerInfoToLeaguePlayerInfo'
import { Container, ContainerProps, DropResult, Draggable } from 'vue-smooth-dnd'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { TapeDownReasonEnum } from '@/lib/support/models/ListInfo/TeamPlayerInfo/data'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'
import { useManagerLogic } from '@/views/Programs/Divisions/TeamManagement/logic/ManagerLogic'
import { sortFunctions } from '@/views/Programs/Divisions/TeamManagement/logic/teamSort'
import {
  participantFilters,
  assignedParticipantFilter,
} from '@/views/Programs/Divisions/TeamManagement/logic/participantFilters'

import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { UpwardPositionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPositionTypeID'
import { isCheer } from '@/lib/common/ProgramTypes'
import { getProperGradeLabel } from '@/services/gradeService'

export default defineComponent({
  name: 'TeamCard',
  components: { Container, Draggable, CheckboxInput },
  props: {
    selectedPlayer: { type: Object as PropType<LeaguePlayerInfo>, required: false, default: () => null },
    ...getProps(),
  },
  setup(props, ctx) {
    const {
      showAssignCoach,
      evalScore,
      yearsExperienceAvg,
      practiceInfo,
      acceptingCoaches,
      droppingCoaches,
      changeHeadCoach,
      removeCoach,
    } = useTeamCard(props, ctx)

    const userPrefs = userPreferences()
    const manager = useManagerLogic()

    const showScore = computed(() => userPrefs.getTeamColumns.value.find((c) => c.id == 'score')?.value)

    const showPosition = computed(() => userPrefs.getTeamColumns.value.find((c) => c.id == 'position')?.value)

    const showHeight = computed(() => userPrefs.getTeamColumns.value.find((c) => c.id == 'height')?.value)

    const showExperience = computed(
      () => userPrefs.getTeamColumns.value.find((c) => c.id == 'experience')?.value
    )

    const showGrade = computed(() => userPrefs.getTeamColumns.value.find((c) => c.id == 'grade')?.value)

    const showExclude = computed(
      () => userPrefs.getTeamColumns.value.find((c) => c.id == 'cannot practice on')?.value
    )

    const isByAge = computed(() => userPrefs.getIsByAge.value)

    const showingHeight = computed(() => props.team.typeProgramID == 'BASKETBALL')

    const isFiltering = computed(() => userPrefs.getIsFilteringTeams.value)

    const assignedParticipantSort = computed(() => userPrefs.getTeamSort.value)

    const isLeague = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    )

    const isUpwardSelect = computed(() => store.getters.leagueAbstraction.isUpwardSelect)

    const filteredParticipants = computed(() => {
      let p = props.team.players ?? []
      if (userPrefs.getFilterTarget.value != 'UNASSIGNED') {
        p = p
          ?.filter((p) =>
            participantFilters(
              p,
              userPrefs.getGender.value ?? [],
              userPrefs.getHeightRange.value,
              userPrefs.getScoreRange.value
            )
          )
          .filter((p) =>
            assignedParticipantFilter(p, userPrefs.getHideLocked.value, userPrefs.getGradeRange.value)
          )
          .sort((a: TeamPlayerInfo, b: TeamPlayerInfo) => {
            const fnc = sortFunctions[assignedParticipantSort.value]
            const isDesc = assignedParticipantSort.value.indexOf('_desc') > 0
            return fnc(a, b, isDesc)
          })
      }
      return p.map((x) => {
        return {
          ...x,
          ageByCutoff: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID)?.ageByCutoff ?? '',
        }
      })
    })

    /**
     * Returns a description of the player count
     */
    const playerCount = computed(() => {
      if (props.team.players?.length) {
        const boysCount = props.team.players.filter((x) => x.gender == 'M').length
        const girlsCount = props.team.players.filter((x) => x.gender == 'F').length

        if (girlsCount && boysCount) {
          const boysString = boysCount === 1 ? `${boysCount} boy` : `${boysCount} boys`
          const girlsString = girlsCount === 1 ? `${girlsCount} girl` : `${girlsCount} girls`

          return `(${boysString} ${girlsString})`
        }

        return `(${boysCount + girlsCount})`
      }
      return '' // no players
    })

    /***
     * Returns a height for basketball teams or nothing.
     */
    const heightAvg = computed(() => {
      if (showingHeight.value && props.team.players?.length) {
        const score = getTotalTeamHeight(props.team) / props.team.players?.length

        if (score && score >= 0) {
          return score?.toFixed(2)
        }
      }
      return ''
    })

    function notes(p: TeamPlayerInfo) {
      const trr = props.tapeDownReasons.find((x) => x.upwardTypeID == p.typeTapeDownReasonID)
      if (trr && p.typeTapeDownReasonID != 'LOCKEDTOTEAM') {
        switch (p.typeTapeDownReasonID) {
          case 'CARPOOLLINK':
            return (
              trr.verbiageTemplate
                ?.replaceAll('{0}', p.formattedNameFirstLast ?? '')
                .replaceAll('{1}', linkedToPlayer(p) ?? '') ?? ''
            )
          default:
            return (
              trr.verbiageTemplate
                ?.replaceAll('{0}', p.formattedNameFirstLast ?? '')
                .replaceAll('{1}', props.team.teamName ?? '') ?? ''
            )
        }
      }
      return ''
    }

    function linkedToPlayer(p: TeamPlayerInfo) {
      const participant = manager.participants.value.find((fp) => fp.individualID == p.individualID)
      return participant
        ? `${participant.actualCarpoolLinkFirstName} ${participant.actualCarpoolLinkLastName}`
        : 'carpool link not found'
    }

    function playerYearsExperienceByTeamPlayer(player: TeamPlayerInfo) {
      const p = manager.participants.value.find(
        (pp) => pp.individualID === player.individualID
      ) as LeaguePlayerInfo

      return p.yearsExperience
    }

    function playerExclusionByLeaguePlayer(p: LeaguePlayerInfo, useShort: boolean) {
      return p.practiceNightExclusions && p.practiceNightExclusions.length
        ? (useShort
            ? p.practiceNightExclusions[0].typeDayID?.substring(0, 3)
            : p.practiceNightExclusions[0].typeDayID) ?? ''
        : useShort
        ? ''
        : 'none'
    }

    function playerExclusionByTeamPlayer(player: TeamPlayerInfo) {
      const p = manager.participants.value.find(
        (pp) => pp.individualID === player.individualID
      ) as LeaguePlayerInfo

      return playerExclusionByLeaguePlayer(p, true)
    }

    function playerInfo(player: TeamPlayerInfo) {
      const p = manager.participants.value.find(
        (pp) => pp.individualID === player.individualID
      ) as LeaguePlayerInfo

      if (!p) return
      const list: { label: string; value: string; class: string }[] = []
      list.push({ label: 'Experience', value: `${p.yearsExperience.toString()} yr(s)`, class: '' })
      list.push({ label: 'Gender', value: p.gender == 'F' ? 'girl' : 'boy', class: '' })
      list.push({
        label: isByAge.value ? 'Age' : 'Grade',
        value: isByAge.value
          ? store.getters.gradeTypes.byUpwardTypeId(p.typeGradeID)?.ageByCutoff ?? ''
          : store.getters.gradeTypes.byUpwardTypeId(p.typeGradeID)?.description ?? p.typeGradeID,
        class: '',
      })

      const exclusion = playerExclusionByLeaguePlayer(p, false)

      list.push({ label: 'Exclusion', value: exclusion, class: '' })

      list.push({
        label: 'Coach Link',
        value: `${p.actualCoachLinkFirstName} ${p.actualCoachLinkLastName}`,
        class: 'd-block',
      })

      list.push({
        label: 'Carpool Link',
        value: `${p.actualCarpoolLinkFirstName} ${p.actualCarpoolLinkLastName}`,
        class: 'd-block',
      })

      return list
    }

    const isCheerSquad = computed(() => isCheer(props.team.typeProgramID))

    function locked(t: TeamPlayerInfo) {
      return t.typeTapeDownReasonID == TapeDownReasonEnum.LOCKED_TO_TEAM
    }

    function droppingParticipants(e: DropResult) {
      if (e.addedIndex != null && e.addedIndex >= 0 && e.payload?.participant) {
        let p = e.payload.participant as LeaguePlayerInfo | TeamPlayerInfo
        const exists = props.team.players?.find((player) => player.individualID == p.individualID)
        if (exists) return

        if (e.payload.originator == 'team') {
          const teamParticipant = e.payload.participant as TeamPlayerInfo
          p = TeamPlayerInfoToLeaguePlayerInfo(teamParticipant)
          removeParticipant(teamParticipant.teamID, p.individualID)
        } else {
          p = e.payload.participant as LeaguePlayerInfo
        }
        ctx.emit('add-participant', props.team.teamID, p)
      }
    }

    function acceptingParticipants(e: ContainerProps, a: Record<string, unknown>) {
      return a && a.participant
    }

    function getParticipantPayload(index: number) {
      const payload = filteredParticipants.value[index]
      return { order: index, participant: { ...payload }, originator: 'team' }
    }

    function removeParticipant(teamID: number, participantID: number) {
      ctx.emit('remove-participant', teamID, participantID)
    }

    function togglePlayerLock(playerId: number) {
      ctx.emit('toggle-player-lock', props.team.teamID, playerId)
    }

    function toggleTeam() {
      ctx.emit('toggle-team', props.team.teamID)
    }

    const showAssignCoachOrPlayer = computed(() => showAssignCoach.value || props.selectedPlayer)

    function addSelectedCoachOrPlayer() {
      if (props.selectedCoach) {
        ctx.emit('add-coach', props.team.teamID, props.selectedCoach)
      }

      if (props.selectedPlayer) {
        ctx.emit('add-participant', props.team.teamID, props.selectedPlayer)
      }
    }

    const positionType = computed(() => {
      return store.getters.positionTypes.allItems.find(
        (x: UpwardPositionTypeID) => x.typeProgramID == props.team.typeProgramID
      )
    })

    const showingPosition = computed(() => !!positionType.value)

    const positionLabel = computed(() => (!!positionType.value ? positionType.value.description : ''))

    function positionToggle(playerID: number) {
      ctx.emit('toggle-player-position', props.team.teamID, playerID)
    }

    return {
      toggleTeam,
      addSelectedCoachOrPlayer,
      showAssignCoachOrPlayer,
      evalScore,
      heightAvg,
      yearsExperienceAvg,
      practiceInfo,
      acceptingCoaches,
      droppingCoaches,
      changeHeadCoach,
      removeCoach,
      playerCount,
      showScore,
      isCheerSquad,
      isLeague,
      isUpwardSelect,
      isByAge,
      showPosition,
      showingPosition,
      positionLabel,
      showHeight,
      showingHeight,
      showGrade,
      showExperience,
      showExclude,
      isFiltering,
      acceptingParticipants,
      droppingParticipants,
      getParticipantPayload,
      filteredParticipants,
      positionToggle,
      playerYearsExperienceByTeamPlayer,
      playerExclusionByTeamPlayer,
      notes,
      togglePlayerLock,
      locked,
      removeParticipant,
      playerInfo,
      getProperGradeLabel,
    }
  },
})
