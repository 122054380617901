










import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'
import MinistryAgreementModal from '@/components/MinistryAgreementModal.vue'
import store from '@/store'

export default defineComponent({
  name: 'Setup',
  components: {
    VerticalTabs,
    Loading,
    Modal,
    MinistryAgreementModal,
  },
  setup(props, ctx) {
    const storeLeague = computed(() => store.getters.leagues.currentItem)
    const reloadMinistryAgreement = ref(false)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const baseRoute = computed(() => `/programs/league/${routeId.value}setup`)

    const isDigital = computed(() => ctx.root.$route.path.indexOf('digitalleague') > 0)

    const tabs = computed(() => {
      if (storeLeague.value && !storeLeague.value.canEdit) {
        return []
      }

      if (isDigital.value) {
        return [
          { text: 'Program Information', to: `/programs/digitalleague/${routeId.value}setup/information` },
        ]
      }

      const retval = [
        { text: 'Program Information', to: `${baseRoute.value}/information` },
        { text: 'Key Dates', to: `${baseRoute.value}/dates` },
        { text: 'Director', to: `${baseRoute.value}/leadership` },
        { text: 'Partnership', to: `${baseRoute.value}/partnership` },
        { text: 'Build Player Kit', to: `${baseRoute.value}/products` },
      ]

      if (storeLeague.value.isOPRLeague) {
        retval.push({ text: 'Online Registration', to: `${baseRoute.value}/registration` })
      }

      return retval
    })

    onMounted(async () => {
      const archivedRoute = `${baseRoute.value}/archive`
      if (storeLeague.value && !storeLeague.value.canEdit && ctx.root.$route.path !== archivedRoute) {
        await ctx.root.$router.push(archivedRoute)
      } else {
        reloadMinistryAgreement.value = !reloadMinistryAgreement.value
      }
    })

    watch(
      () => storeLeague.value.seasonEndDate,
      () => {
        reloadMinistryAgreement.value = !reloadMinistryAgreement.value
      },
      { deep: true }
    )

    return {
      tabs,
      reloadMinistryAgreement,
      storeLeague,
    }
  },
})
