


























import { defineComponent, computed } from '@vue/composition-api'

import StartupOrderPicker from '@/components/ProductFlow/ParticipantKitStepper/ProductPickers/StartupOrderPicker.vue'
import Step from '@/components/ProductFlow/Step.vue'
import Loading from '@/elements/Loading.vue'

import store from '@/store'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

export default defineComponent({
  name: 'StarterOrderItemStep',
  props: { step: { type: Number, required: true } },
  components: { Step, StartupOrderPicker, Loading },
  setup() {
    const logic = productFlowLogic()

    const loadingOfferings = computed(() => store.getters.productFlow.productOfferingLoading)
    const starupProducts = computed(() => store.getters.productOfferings.availableStartupProducts)

    return { starupProducts, loadingOfferings, ...logic }
  },
})
