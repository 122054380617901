






























import { defineComponent, computed } from '@vue/composition-api'
import { getProps, useTextlike } from '@/composables/TextLike'
import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'

export default defineComponent({
  name: 'TextLikeWrapper',
  components: {
    BaseInputWrapper,
  },
  props: {
    ...getProps(),
  },
  setup(props, ctx) {
    const { iconClick } = useTextlike<unknown>(props, ctx)

    const hasIcon = computed(() => !!(props.prependIcon || props.appendIcon))

    return {
      hasIcon,
      iconClick,
    }
  },
})
