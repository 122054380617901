import restService from '@/services/restService'
import { EmailSMSAccount } from '@/GeneratedTypes/Communication/EmailSMSAccount'
import { EmailSMSLeagueSetting } from '@/GeneratedTypes/Communication/EmailSMSLeagueSetting'
import { EmailSMSLeagueMessageInfo } from '@/GeneratedTypes/Communication/EmailSMSLeagueMessage'
import { EmailMessage } from '@/GeneratedTypes/Communication/EmailMessage'
import { SMSMessage } from '@/GeneratedTypes/Communication/SMSMessage'
import { ContactBuilder } from '@/models/Communication/ContactBuilder'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { GroupMessageParameters } from '@/models/GroupMessageParameters'
import { GroupMessageContact } from '@/models/GroupMessageContact'
import { ScheduledCommunication } from '@/GeneratedTypes/Communication/ScheduledCommunication'
import { EmailSmsMassCommunicationQueue } from '@/GeneratedTypes/Communication/EmailSmsMassCommunicationQueue'
import { TwilioApplicationRequest } from '@/GeneratedTypes/Communication/TwilioApplicationRequest'
import { CommunicationStatusData } from '@/models/Communication/CommunicationStatusData'
import {
  EmailSuppressionStatus,
  emptyEmailSuppressionStatus,
} from '@/models/Communication/EmailSuppressionStatus'

const baseUrl = 'communications/'

enum MessageTypes {
  Email,
  Sms,
}

enum PreviewMessageTypes {
  Email = 'email',
  Sms = 'sms',
}

const retrieveProviderInfo = async (upwID: string, accountNumber: string) => {
  const results = await restService.get<EmailSMSAccount>(
    `${baseUrl}management/providerInfo/${accountNumber}/${upwID}`
  )

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in communicationsClient.retrieveProviderInfo')
  }
}

const retrieveMessagePreview = async (preview: PreviewMessage) => {
  const results = await restService.post<PreviewMessage>(`${baseUrl}messagepreview`, preview)

  if (!results.isSuccess) {
    throw new Error(`Error in retrieveMessagePreview`)
  }

  return results.data
}

const retrieveLeagueSettings = async (upwID: string, emailSMSAccountID: number) => {
  const results = await restService.get<EmailSMSLeagueSetting>(
    `${baseUrl}management/leagueSettings/${emailSMSAccountID}/${upwID}`
  )

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in communicationsClient.retrieveLeagueSettings')
  }
}

const updateLeagueSettings = async (setting: EmailSMSLeagueSetting) => {
  const results = await restService.put<EmailSMSLeagueSetting>(`${baseUrl}management/leagueSettings`, setting)
  if (!results.isSuccess) {
    throw new Error('Error in communicationsClient.updateLeagueSettings')
  }
}

const retrieveMessages = async (
  upwID: string,
  emailSMSAccountID: number,
  messageType: MessageTypes,
  showArchived = false
) => {
  const messages = messageType == MessageTypes.Email ? 'emailmessages' : 'smsmessages'

  const results = await restService.get<EmailSMSLeagueMessageInfo[]>(
    `${baseUrl}${messages}/${emailSMSAccountID}/${upwID}?excludeArchive=${!showArchived}`
  )

  if (!results.isSuccess) {
    throw new Error('Error in communicationsClient.retrieveEmailMessages')
  }

  return results.data
}

const retrieveMessage = async (emailSmsMessageID: number, messageType: MessageTypes) => {
  const messages = messageType == MessageTypes.Email ? 'emailmessages' : 'smsmessages'

  const results = await restService.get<EmailSMSLeagueMessageInfo>(
    `${baseUrl}${messages}/${emailSmsMessageID}`
  )

  if (!results.isSuccess) {
    throw new Error('Error in communicationsClient.retrieveEmailMessages')
  }

  return results.data
}

const sendEmailMessage = async (message: EmailMessage) => {
  // REMOVE
  const results = await restService.post<EmailMessage>(`${baseUrl}sendemail`, message)

  if (!results.isSuccess) {
    throw new Error(`Error sending email message`)
  }

  return results.data
}

const sendSMSMessage = async (message: SMSMessage) => {
  // REMOVE
  const results = await restService.post<SMSMessage>(`${baseUrl}sendsms`, message)

  if (!results.isSuccess) {
    throw new Error(`Error sending sms message`)
  }

  return results.data
}

const sendMessage = async (message: EmailMessage, type: string) => {
  const results = await restService.post<EmailMessage | SMSMessage>(`${baseUrl}${type}`, message)

  if (!results.isSuccess) {
    throw new Error(`Error sending message`)
  }

  return results.data
}

const getContactBuilder = async (
  upwID: string,
  accountNumber: string,
  playerPaymentStatus = '',
  playerEvalStatus = '',
  coachTeamStatus = '',
  programTypeID = '',
  viewAllLeagueContacts = false
) => {
  const results = await restService.get<ContactBuilder>(
    `${baseUrl}contactbuilder/${accountNumber}/${upwID}/${programTypeID}`,
    {
      playerPaymentStatus,
      playerEvalStatus,
      coachTeamStatus,
      viewAllLeagueContacts,
    }
  )

  if (!results.isSuccess) {
    throw new Error('Error retrieving contact builder: communicationsClient getContactBuilder')
  }

  return results.data
}

const sendTeamPageLinkGroupMessage = async (
  upwardLeagueId: string,
  params: GroupMessageParameters
): Promise<GroupMessageContact[] | null> => {
  const result = await restService.post<GroupMessageContact[]>(
    `${baseUrl}masscommunications/${upwardLeagueId}/teampagelink`,
    { parameters: params }
  )

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred sending the team page link group message.')
  }
}

const sendTeamRosterGroupMessage = async (
  upwardLeagueId: string,
  params: GroupMessageParameters
): Promise<GroupMessageContact[] | null> => {
  const result = await restService.post<GroupMessageContact[]>(
    `${baseUrl}masscommunications/${upwardLeagueId}/teamroster`,
    { parameters: params }
  )

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred sending the team roster group message.')
  }
}
const getQueuedGroupMessage = async (messageID: number): Promise<EmailSmsMassCommunicationQueue | null> => {
  const result = await restService.get<EmailSmsMassCommunicationQueue>(
    `${baseUrl}masscommunications/queued/${messageID}`
  )
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving queued group messages.')
  }
}
const getScheduledCommunications = async (
  emailSMSAccountID: number,
  upwardLeagueID: string
): Promise<ScheduledCommunication[]> => {
  const endpoint = `${baseUrl}scheduledmessages/${emailSMSAccountID}/${upwardLeagueID}`
  const response = await restService.get<ScheduledCommunication[]>(endpoint)
  if (response.isSuccess) {
    const data = response.data || new Array<ScheduledCommunication>()
    return data
  } else throw new Error('A problem occurred while attempting to retrieve scheduled communications.')
}
const deleteScheduledCommunication = async (upwardType: string, messageID: number): Promise<void> => {
  const endpoint = `${baseUrl}scheduledmessages/${upwardType}/${messageID}`
  const response = await restService.delete(endpoint)
  if (!response.isSuccess) throw new Error('A problem occrred while deleting the scheduled communication.')
}
const getTollFreeApp = async (accountNumber: string): Promise<TwilioApplicationRequest | null> => {
  const result = await restService.get<TwilioApplicationRequest>(
    `${baseUrl}twilioapplication/${accountNumber}`
  )
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred retrieving Toll Free Number Application.')
  }
}
const canSubmitTollFreeApp = async (accountNumber: string): Promise<boolean | null> => {
  const result = await restService.get<boolean>(
    `${baseUrl}twilioapplication/applicationallowed/${accountNumber}`
  )
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('A problem occurred checking for ability to submit Toll Free Number Application.')
  }
}
const createTwilioTollFreeApplication = async (twilioApplicationRequest: TwilioApplicationRequest) => {
  const result = await restService.post(`${baseUrl}twilioapplication`, twilioApplicationRequest)
  if (!result.isSuccess) {
    throw new Error('A problem occurred sending Toll Free Number Application Request.')
  }
}
const updateTwilioTollFreeApplication = async (twilioApplicationRequest: TwilioApplicationRequest) => {
  const result = await restService.put(`${baseUrl}twilioapplication`, twilioApplicationRequest)
  if (!result.isSuccess) {
    throw new Error('A problem occurred updating Toll Free Number Application Request.')
  }
}
const getPendingApplicationsCSV = async () => {
  return await restService.get<string>(`${baseUrl}twilioapplication/PendingCSV`)
}
const setPendingApplicationsToSubmitted = async () => {
  return await restService.put(`${baseUrl}twilioapplication/ProcessPending`, null)
}

const getCommunicationStatuses = async (
  accountNumber: string,
  upwardLeagueID: string
): Promise<CommunicationStatusData[]> => {
  const response = await restService.get<CommunicationStatusData[]>(
    `${baseUrl}statuses/${accountNumber}/${upwardLeagueID}`
  )
  if (!response.isSuccess) throw new Error('A problem occurred retrieving communication statuses.')

  return response.data ?? new Array<CommunicationStatusData>()
}
const getTwilioApplicationRequestList = async (
  status = 'PENDING',
  search = ''
): Promise<TwilioApplicationRequest[]> => {
  const response = await restService.get<TwilioApplicationRequest[]>(
    `${baseUrl}twilioapplication/list/${status}/${search}`
  )
  if (!response.isSuccess) throw new Error('A problem occurred retrieving Twilio Application Requests.')

  return response.data ?? new Array<TwilioApplicationRequest>()
}

const submitTwilioVerificationRequest = async (accountNumber: string) => {
  const response = await restService.post(`${baseUrl}twilioapplication/submit/${accountNumber}`, null)
  if (!response.isSuccess) throw new Error('A problem occurred submitting Twilio Verification Request.')
}
const completeTwilioApplicationRequest = async (accountNumber: string) => {
  const response = await restService.post(`${baseUrl}twilioapplication/complete/${accountNumber}`, null)
  if (!response.isSuccess) throw new Error('A problem occurred completing Twilio Application Request.')
}
const revertTwilioApplicationRequest = async (accountNumber: string) => {
  const response = await restService.post(`${baseUrl}twilioapplication/revert/${accountNumber}`, null)
  if (!response.isSuccess) throw new Error('A problem occurred reverting Twilio Application Request.')
}

const getEmailSuppressionStatus = async (email: string): Promise<EmailSuppressionStatus> => {
  const response = await restService.get<EmailSuppressionStatus>(`${baseUrl}suppressioncheck/${email}`)

  if (!response.isSuccess && response.status != 404)
    throw new Error('A problem occurred checking suppression status.')

  if (response.status == 404) return emptyEmailSuppressionStatus()
  else return response.data ?? emptyEmailSuppressionStatus()
}

export default {
  MessageTypes,
  PreviewMessageTypes,
  retrieveProviderInfo,
  retrieveLeagueSettings,
  updateLeagueSettings,
  retrieveMessages,
  retrieveMessage,
  sendEmailMessage,
  sendMessage,
  sendSMSMessage,
  getContactBuilder,
  retrieveMessagePreview,
  sendTeamPageLinkGroupMessage,
  sendTeamRosterGroupMessage,
  getScheduledCommunications,
  deleteScheduledCommunication,
  getQueuedGroupMessage,
  getTollFreeApp,
  canSubmitTollFreeApp,
  createTwilioTollFreeApplication,
  getPendingApplicationsCSV,
  setPendingApplicationsToSubmitted,
  getCommunicationStatuses,
  getTwilioApplicationRequestList,
  updateTwilioTollFreeApplication,
  submitTwilioVerificationRequest,
  completeTwilioApplicationRequest,
  revertTwilioApplicationRequest,
  getEmailSuppressionStatus,
}
