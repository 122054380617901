import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { GameVolunteerInfo } from '@/GeneratedTypes/ListInfo/GameVolunteerInfo'

export const divisionGames = (list: DivisionGameInfo[]) => {
  // Sort the list by teamWins (descending) and teamName (ascending)
  list.sort((a, b) => {
    return (a.homeTeamName || '').localeCompare(b.homeTeamName || '') // Ascending order
  })

  // Directly map the sorted list to the desired format
  const games: divisionGame[] = list.map((item) => ({
    leagueID: item.leagueID,
    typeProgramID: item.typeProgramID,
    divisionID: item.divisionID,
    gameID: item.gameID,
    roundNumber: item.roundNumber,
    homeTeamID: item.homeTeamID,
    awayTeamID: item.awayTeamID,
    winningTeamID: item.winningTeamID,
    homeTeamScore: item.homeTeamScore,
    awayTeamScore: item.awayTeamScore,
    typeDivisionGameID: item.typeDivisionGameID,
    facilityID: item.facilityID,
    gameStart: item.gameStart,
    gameEnd: item.gameEnd,
    serverGameStart: item.serverGameStart,
    serverGameEnd: item.serverGameEnd,
    volunteers: item.volunteers,
    divisionName: item.divisionName,
    homeTeamName: item.homeTeamName,
    awayTeamName: item.awayTeamName,
    winningTeamName: item.winningTeamName,
    facilityName: item.facilityName,
    prettyStartDate: '',
    prettyStartTime: '',
    prettyEndDate: '',
    prettyEndTime: '',
    prettyTimeRange: '',
    gameName: '',
    gameResult: '',
  }))

  return games
}

export interface divisionGame {
  leagueID: number
  typeProgramID: string | null
  divisionID: number
  gameID: number
  roundNumber: number
  homeTeamID: number
  awayTeamID: number
  winningTeamID: number
  homeTeamScore: number | null
  awayTeamScore: number | null
  typeDivisionGameID: string | null
  facilityID: number
  gameStart: Date | null
  gameEnd: Date | null
  serverGameStart: Date | null
  serverGameEnd: Date | null
  volunteers: GameVolunteerInfo[] | null
  divisionName: string | null
  homeTeamName: string | null
  awayTeamName: string | null
  winningTeamName: string | null
  facilityName: string | null
  prettyStartDate: String | null
  prettyStartTime: String | null
  prettyEndDate: String | null
  prettyEndTime: String | null
  prettyTimeRange: String | null
  gameName: String
  gameResult: String | null
}
