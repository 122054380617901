/**
 * Rest Operations primarily for CRUD on Game Scheduler
 */

import restService, { RestServiceResult } from '@/services/restService'

import { UpwardLeagueIDType } from '@/lib/support/models/League/data'

import { GeneralError } from '@/lib/common/exceptions/GeneralError'

import { DivisionGameInfo } from '@/GeneratedTypes/ListInfo/DivisionGameInfo'
import { DivisionGame } from '@/GeneratedTypes/DivisionGame'
import { isEmptyDivisionGame } from '@/lib/support/models/DivisionGame/data'
import { DivisionGameModified } from '@/models/Games/DivisionGameModified'
import { DivisionGameSchedulerPreference } from '@/GeneratedTypes/DivisionGameSchedulerPreference'

const baseUrl = 'games'
function ourURL(leagueID: UpwardLeagueIDType) {
  return `${baseUrl}/${leagueID}`
}
const divisionUrl = 'divisions'
function divisionURL(leagueID: UpwardLeagueIDType, program: string, divisionId: number) {
  return `${divisionUrl}/${leagueID}/${program}/${divisionId}`
}

class GameClientException extends GeneralError {
  name = 'Games API Error'
}

export interface ScheduleOptionsType {
  createDefaultPairings: boolean
  generateGameSchedule: boolean
  clearExistingSchedule: boolean
}

export default class gamesClient {
  /**
 * Returns all games based on a LeagueID or LeagueID and ProgramType
 * @param leagueID UPW### league

 * @return array of league volunteers from the league id
 */
  static async retrieveAllGames(leagueID: UpwardLeagueIDType): Promise<DivisionGameInfo[] | null> {
    const x = await restService.get<DivisionGameInfo[]>(`${ourURL(leagueID)}/`)
    if (!x.isSuccess) {
      throw new GameClientException('Could not get list of volunteers')
    }
    return x.data
  }

  static async pairTeams(leagueID: UpwardLeagueIDType, program: string, divisionID: number): Promise<void> {
    const x = await restService.post<void>(
      `${divisionURL(leagueID, program, divisionID)}/generateSportDivisionGameSchedule`,
      {
        createDefaultPairings: true,
        generateGameSchedule: false,
        clearExistingSchedule: true,
      }
    )
    if (!x.isSuccess) {
      throw new GameClientException('Could not pair games')
    }
    return
  }

  static async validate(
    leagueID: UpwardLeagueIDType,
    game: DivisionGameModified
  ): Promise<DivisionGame | null> {
    let url = `${baseUrl}/verificationDetails`
    if (!isEmptyDivisionGame(game)) {
      url = ourURL(leagueID) + `/${game.gameID}/verificationDetails`
    }

    const x = await restService.post<DivisionGame>(url, game)
    if (!x.isSuccess) {
      throw new GameClientException('Could not validate game')
    }
    return x.data
  }

  static async save(leagueID: UpwardLeagueIDType, game: DivisionGameModified): Promise<DivisionGame | null> {
    let x: RestServiceResult<DivisionGame> | null
    if (!isEmptyDivisionGame(game)) {
      const url = ourURL(leagueID) + `/${game.gameID}`
      x = await restService.put(url, game)
    } else {
      const url = `${baseUrl}`
      x = await restService.post(url, game)
    }

    if (!x || !x?.isSuccess) {
      throw new GameClientException('Could not save game')
    }
    return x.data
  }

  static async remove(leagueID: UpwardLeagueIDType, gameID: number): Promise<DivisionGame | null> {
    const url = ourURL(leagueID) + `/${gameID}`
    const x = await restService.delete<DivisionGame>(url)

    if (!x || !x?.isSuccess) {
      throw new GameClientException('Could not save game')
    }
    return x.data
  }

  static async schedule(
    leagueID: UpwardLeagueIDType,
    program: string,
    divisionID: number,
    options: ScheduleOptionsType
  ) {
    const y = await restService.post<DivisionGameSchedulerPreference>(
      divisionURL(leagueID, program, divisionID) + '/generateSportDivisionGameSchedule',
      options
    )
    if (!y.isSuccess) {
      throw new GameClientException('Issue with game scheduling', y.errorObject)
    }
  }

  static async savePreferences(
    leagueID: UpwardLeagueIDType,
    program: string,
    divisionID: number,
    preferences: DivisionGameSchedulerPreference
  ) {
    const y = await restService.post<DivisionGameSchedulerPreference>(
      divisionURL(leagueID, program, divisionID) + '/gameSchedulerPreferences/verificationDetails',
      preferences
    )
    if (y && y.isSuccess) {
      const x = await restService.put<DivisionGameSchedulerPreference>(
        divisionURL(leagueID, program, divisionID) + '/gameSchedulerPreferences',
        preferences
      )

      if (!x || !x?.isSuccess) {
        throw new GameClientException('Could not save game')
      }
      return x.data
    }
  }

  static async getPreferences(leagueID: UpwardLeagueIDType, program: string, divisionID: number) {
    const y = await restService.get<DivisionGameSchedulerPreference>(
      divisionURL(leagueID, program, divisionID) + '/gameSchedulerPreferences'
    )
    if (y && y.isSuccess) {
      return y.data
    }
  }
}
