

























































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { League } from '@/GeneratedTypes/League'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { DivisionGameSchedulerPreference } from '@/GeneratedTypes/DivisionGameSchedulerPreference'
import {
  getEmptyDivisionGameSchedulerPreference,
  isValidDivisionGameSchedulerPreference,
} from '@/lib/support/models/DivisionGameSchedulerPreference/data'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import DivisionSettings from '@/views/Programs/Scheduling/Games/auto/DivisionSettings.vue'
import { isEqual, cloneDeep, difference } from 'lodash'
import SelectInput from '@/elements/SelectInput.vue'
import gamesClient from '@/clients/gamesClient'
import { isCheer } from '@/lib/common/ProgramTypes'

@Component({
  components: {
    SelectInput,
    DivisionSettings,
    ConfirmationModal,
    RadioGroupInput,
  },
})
export default class DivisionPreferences extends Vue {
  get division(): LeagueDivisionInfo {
    const x = this.divisionList.find((x) => x.divisionID == this.internalDivisionID)
    if (!x) {
      return getEmptyLeagueDivisionInfo()
    } else return x
  }

  @Prop({ type: Array, default: () => [] })
  divisionList!: LeagueDivisionInfo[]

  settings: DivisionGameSchedulerPreference = getEmptyDivisionGameSchedulerPreference()
  @Prop({ type: Array, default: () => [] })
  facilityList!: LeagueFacility[]
  @Prop({ type: Object, default: () => getEmptyLeague() })
  league!: League
  @Prop({ type: String, default: () => '' })
  programID!: string
  @Prop({ type: Number, required: true })
  value!: number

  internalDivisionID = 0

  @Watch('value')
  updateInternalDivisionID() {
    if (this.internalDivisionID !== this.value) {
      this.internalDivisionID = this.value
    }
  }

  settingsByDivision = new Array<{ id: number; settings: DivisionGameSchedulerPreference }>()

  get leagueID() {
    return this.$route.params.id
  }

  async loadDivisionPreferences() {
    for (const x of this.divisionList) {
      const settings = await gamesClient.getPreferences(this.leagueID, this.programID, x.divisionID)
      if (settings) {
        this.putDivisionPreference(x.divisionID, settings)
      }
    }
  }

  @Watch('divisionList', { immediate: true })
  divisionListUpdated() {
    if (!this.internalDivisionID && this.divisionList.length) {
      this.internalDivisionID = this.divisionList[0].divisionID
    }
    //load preferences too
    this.loadDivisionPreferences()
  }

  @Watch('internalDivisionID')
  async divisionIDUpdated() {
    if (this.internalDivisionID !== this.value) {
      this.$emit('input', this.internalDivisionID)
    }

    if (this.internalDivisionID) {
      const savedSettings = this.settingsByDivision.find((x) => x.id == this.internalDivisionID)
      if (savedSettings) {
        this.settings = cloneDeep(savedSettings.settings)
      } else {
        const settings = await gamesClient.getPreferences(
          this.league.upwardLeagueID ?? '',
          this.programID,
          this.internalDivisionID
        )
        if (settings) {
          this.settings = settings
        } else {
          this.settings = getEmptyDivisionGameSchedulerPreference()
        }
      }
    }
  }

  clickCopySettings() {
    this.showCopySettingsModal = true
  }

  showCopySettingsModal = false
  divisionToCopy = 0
  async confirmCopySettings(b: boolean) {
    if (b && this.divisionToCopy) {
      const settings = this.settingsByDivision.find((x) => x.id == this.divisionToCopy)
      if (settings) {
        this.settings = cloneDeep(settings.settings)
        this.settings.divisionID = this.internalDivisionID
        await this.saveSettings()
      }
    }
    this.showCopySettingsModal = false
  }

  showApplyAllSettingsConfirmationModal = false
  /**
   * Triggered when apply confirm is made
   */
  confirmApplyAllSettings(b: boolean) {
    if (b) {
      //Sets the divisions to all the same things.

      this.settingsByDivision = this.divisionList.map((x) => ({
        id: x.divisionID,
        settings: {
          ...this.settings,
          divisionID: x.divisionID,
        },
      }))
      // save everything copied.
      this.settingsByDivision.forEach(async (x) => await this.savePreferences(x.settings))
      //move on to the schedule screen
      this.clickSchedule()
    }
    this.showApplyAllSettingsConfirmationModal = false
  }

  /**
   *
   * Pushes given preferences to the server
   */
  async savePreferences(p: DivisionGameSchedulerPreference) {
    await gamesClient.savePreferences(this.league.upwardLeagueID ?? '', p.typeProgramID, p.divisionID, p)
  }

  /***
   * save settings that are current if valid, show a message.
   */
  async saveBackSettings() {
    if (this.areSettingsValid) {
      await this.savePreferences(this.settings)
      this.$emit('update', this.settings)
    }
  }

  /**
   * Update division preference by the division ID -- will save back so that menu change will
   * pull the right division preferences
   * @return boolean if division was updated, doesn't have to be if no changes.
   */
  putDivisionPreference(id: number, settings: DivisionGameSchedulerPreference) {
    const existingSettings = this.settingsByDivision.find((x) => x.id == id)
    if (!existingSettings || !isEqual(existingSettings, settings)) {
      // remove the existing settings
      this.settingsByDivision = this.settingsByDivision.filter((x) => x.id != id)
      // add the new settings

      this.settingsByDivision.push({ id, settings })
      if (this.internalDivisionID == id) {
        this.settings = settings
      }
      return true //did update preferences, changes detected
    }
    return false //did not update preferences, no changes
  }

  async updateSettings(d: DivisionGameSchedulerPreference) {
    if (!isEqual(this.settings, d)) {
      this.settings = cloneDeep(d)
      await this.saveSettings()
    }
  }

  async saveSettings() {
    if (this.putDivisionPreference(this.settings.divisionID, this.settings)) {
      await this.saveBackSettings()
    }
  }

  getSettings(divisionID: number) {
    return this.settingsByDivision.find((x) => x.id == divisionID)
  }

  settingsValid(s: DivisionGameSchedulerPreference | undefined) {
    return !!s && isValidDivisionGameSchedulerPreference(s)
  }

  get areSettingsValid(): boolean {
    return this.settingsValid(this.settings)
  }

  /***
   * Returns a select list with divisions name and id
   */
  get divisionSelectList() {
    return this.divisionList
      .filter((x) => x.typeProgramID == this.programID)
      .map((x) => ({
        divisionName: `${x.divisionName}`,
        divisionID: x.divisionID,
      }))
  }

  /***
   * Returns a list of selections of divisions with settings
   * that are not the current division for drop down.
   */
  get divisionsWithSettings() {
    const divisionsWithSettings = Array.from(
      this.settingsByDivision.filter((y) => y.id != this.internalDivisionID).map((y) => y.id)
    )
    return this.divisionList.filter(
      (x) => divisionsWithSettings.indexOf(x.divisionID) >= 0 && !isCheer(x.typeProgramID)
    )
  }

  /***
   * Returns the list of division IDs that is not yet completely filled out.
   */
  divisionsNeedingSettings() {
    const divisionsWithSettings = Array.from(this.settingsByDivision.map((y) => y.id))
    const divisions = Array.from(this.divisionList.map((x) => x.divisionID))
    const diff = difference(divisions, divisionsWithSettings)

    return diff
  }

  nextDivision() {
    const x = this.divisionsNeedingSettings()
    if (x.length) {
      this.internalDivisionID = x[0]
    }
  }

  @Emit()
  clickSchedule() {
    return this.settings
  }
}
