


































import NoteItem from '@/views/AdminDash/vues/OrderHistory/NoteItem.vue'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import Modal from '@/components/Modal.vue'
import { xStartupOrder } from '@/GeneratedTypes/xOrder/xStartupOrder'
import ordersClient from '@/clients/ordersClient'
import MemoInput from '@/elements/MemoInput.vue'
import { OrderNoteInfo } from '@/GeneratedTypes/Order/ListInfo/OrderNoteInfo'

export default defineComponent({
  name: 'IntegrationNotesDialog',
  components: { MemoInput, NoteItem, CheckboxInput, Modal },
  props: {
    visible: { type: Boolean, required: true, default: false },
    order: { type: Object as PropType<xStartupOrder | null>, required: false },
    orderID: { type: Number, required: false, default: false },
    notes: { type: Array as PropType<OrderNoteInfo[]>, required: true, default: () => [] },
  },

  setup(props, ctx) {
    const internalVisible = ref(false)
    watch(
      () => props.visible,
      () => (internalVisible.value = props.visible)
    )

    const internalNotes = ref('')
    const internalIntegrate = ref(false)

    const canSaveNote = computed(() => !!internalNotes.value.length)

    function refresh() {
      internalNotes.value = ''
      internalIntegrate.value = false
    }

    async function saveNote() {
      await ordersClient.putNote(props.orderID, {
        noteID: 0,
        noteText: internalNotes.value.substr(0, 4000),
        gpIntegrate: internalIntegrate.value,
      })
      refresh()
      ctx.emit('change')
    }

    async function removeNote(n: OrderNoteInfo) {
      await ordersClient.deleteNote(props.orderID, n.noteID)
      refresh()
      ctx.emit('change')
    }

    watch(
      () => props.order,
      () => {
        refresh()
      },
      { immediate: true }
    )

    const close = (b: Boolean) => !b && ctx.emit('close')

    return {
      internalVisible,
      internalNotes,
      internalIntegrate,
      saveNote,
      removeNote,
      close,
      canSaveNote,
    }
  },
})
