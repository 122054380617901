











































import uuid from 'uuid'
import InputLabel from '@/elements/InputLabel.vue'
/// <reference path="@/modules/vuejs-datepicker.d.ts" />
import Datepicker from 'vuejs-datepicker'
import TimePicker from '@/elements/TimePicker2.vue'
import dayjs from 'dayjs'
import { defineComponent, onMounted, computed, watch, ref } from '@vue/composition-api'
import { VueConstructor } from 'vue'

export default defineComponent({
  name: 'InlineDateTimeInput2',
  props: {
    required: { type: Boolean, required: false, default: false },
    strictMode: { type: Boolean, required: false, default: false },
    label: { type: String, required: false, default: '' },
    inline: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: '' },
    prependIcon: { type: String, required: false, default: '' },
    appendIcon: { type: String, required: false, default: '' },
    minDate: {
      type: Date,
      required: false,
      default: () => {
        return new Date(Date.now())
      },
    },
    maxDate: {
      type: Date,
      required: false,
      default: () => {
        const d = new Date()
        d.setDate(d.getDate() + 730)
        return d
      },
    },
    value: {
      type: Date,
      required: true,
      default: () => {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate())
      },
    },
  },
  components: {
    InputLabel,
    Datepicker: (Datepicker as unknown) as VueConstructor,
    TimePicker,
  },
  setup(props: any, { emit }) {
    const inputId = ref('datepicker-')
    const internalDateValue = ref<Date | string | null>(null)
    const internalTimeValue = ref<string | null>('')
    onMounted(() => {
      inputId.value += uuid.v4()
    })
    const minTime = computed(() => {
      const today = new Date(Date.now())
      const date = dayjs(internalDateValue.value ?? undefined).toDate()
      const isToday =
        today.getDate() === date.getDate() &&
        today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear()
      if (isToday) {
        const nowMinutes = today.getMinutes()
        const nowHours = today.getHours()
        return nowMinutes > 30 ? `${nowHours + 1}:00:00` : `${nowHours}:30:00`
      }
      return '00:00:00'
    })
    const disabledDates = computed(() => {
      const now = new Date()
      const min = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      return {
        to: min,
        from: props.maxDate,
      }
    })
    const internalValue = computed(() => {
      const isValidTime = internalTimeValue.value != null && internalTimeValue.value != ''
      const isValidDate = internalDateValue.value != null
      const now = new Date(Date.now())
      let date = now
      if (isValidTime && isValidDate) {
        const timeParts = internalTimeValue.value?.split(':')
        date = dayjs(internalDateValue.value ?? '')
          .hour(timeParts ? Number(timeParts[0]) : 0)
          .minute(timeParts ? Number(timeParts[1]) : 0)
          .toDate()
      } else if (!isValidTime && isValidDate) {
        const timeParts = minTime.value?.split(':')
        date = dayjs(internalDateValue.value ?? '')
          .hour(timeParts ? Number(timeParts[0]) : 0)
          .minute(timeParts ? Number(timeParts[1]) : 0)
          .toDate()
      }
      return date < now || date > props.maxDate ? now : date
    })
    watch(
      () => internalDateValue.value,
      () => {
        internalTimeValue.value = minTime.value
      },
      { immediate: true }
    )
    watch(
      () => props.value,
      () => {
        if (props.value != internalValue.value) {
          if (!props.value || props.value === '0001-01-01T00:00:00') {
            internalDateValue.value = null
            internalTimeValue.value = ''
          } else if (typeof props.value === 'string') {
            internalDateValue.value = dayjs(props.value).toDate()
          } else {
            internalDateValue.value = dayjs(props.value).toDate()
          }
        }
      },
      { immediate: true }
    )

    const handleDateInput = (event: any) => {
      const timestamp = Date.parse(event.target.value)
      const now = new Date(Date.now())
      const date = isNaN(timestamp) ? now : new Date(timestamp)
      const validDate = date < now || date > props.maxDate ? now : date
      internalDateValue.value = validDate
    }
    const handleChange = () => {
      emit('input', internalValue.value)
    }

    return {
      handleChange,
      inputId,
      internalDateValue,
      internalTimeValue,
      minTime,
      handleDateInput,
      disabledDates,
    }
  },
})
