




















































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'

import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import {
  LeagueInfoMoreThanOneProgram,
  LeagueListItemSeed,
  getEmptyLeagueListItem,
} from '@/lib/support/models/LeagueListItem/data'

import Modal from '@/components/Modal.vue'
import Loading from '@/elements/Loading.vue'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import WaitlistReport from '@/views/Programs/Reports/WebviewReports/WaitlistReport.vue'
import EFTReportControls from '@/components/EFTReportControls.vue'
import EFTReport from '@/views/Programs/Reports/WebviewReports/EFTReport.vue'
import CommunicationsOptOutReport from '@/views/Programs/Reports/WebviewReports/CommunicationsOptOutReport.vue'
import store from '@/store'

export default defineComponent({
  name: 'WebviewReports',
  components: {
    Modal,
    Loading,
    TypeProgramTab,
    WaitlistReport,
    EFTReport,
    EFTReportControls,
    CommunicationsOptOutReport,
  },
  setup(props, ctx) {
    const leagueAbstraction = computed(() => store.getters.leagueAbstraction.currentItem)
    const dataSource = ref<Record<string, unknown>[]>([])
    const currentProgram = ref('')
    const currentReport = ref('')
    const loading = ref(false)
    const params = ref<Record<string, unknown> | boolean>(false)

    const reports = ref<{
      [reportName: string]: {
        name: string
        path: string
        parameters: any
      }
    }>({
      waitlist: {
        name: 'Online Registration Waitlist',
        path: 'registrationwaitlist',
        parameters: {},
      },
      'financial-details': {
        name: 'Online Registration Financial Details',
        path: 'registrationeftpayment',
        parameters: false,
      },
      communicationsoptout: {
        name: 'Email / SMS Opt Outs',
        path: 'registrationcommunicationsoptout',
        parameters: {},
      },
    })

    const reportName = computed((): string => ctx.root.$route.params.reportName)

    const currentReportDefaultParams = computed((): Record<string, unknown> | boolean => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return false
      }
      return reports.value[reportName.value].parameters
    })

    const currentReportPath = computed((): string => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return ''
      }
      return reports.value[reportName.value].path
    })

    const currentReportName = computed((): string => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return ''
      }
      return reports.value[reportName.value].name
    })

    const tabs = computed(
      (): LeagueListItem => {
        if (leagueAbstraction.value && leagueAbstraction.value.programs) {
          return LeagueProgams2LeagueListItem(leagueAbstraction.value.programs)
        }
        return getEmptyLeagueListItem(new LeagueListItemSeed())
      }
    )

    const typeProgramID = computed(() =>
      leagueAbstraction.value.programs ? leagueAbstraction.value.programs[0].typeProgramID : ''
    )

    onMounted(async () => {
      if (typeProgramID.value) {
        currentProgram.value = typeProgramID.value
      }

      if (currentReportPath.value) {
        await reportSelected(currentReportPath.value)
      }
    })

    function updateParams(p: Record<string, unknown>) {
      params.value = p
    }

    /**
     * Report selected from the menu, will reset the data, set the parameters and try to load the report.
     * @param report
     * @private
     */
    async function reportSelected(report: string) {
      currentReport.value = report
      params.value = currentReportDefaultParams.value
      dataSource.value = []
      await loadReport()
    }

    watch(
      () => params.value,
      async () => {
        await loadReport()
      }
    )

    watch(
      () => currentReportPath.value,
      async () => {
        await reportSelected(currentReportPath.value)
      }
    )

    /**
     * Attempt to load the report, may not go if parameters are not set.
     * @private
     */
    async function loadReport() {
      if (params.value && currentReport.value) {
        try {
          loading.value = true
          let ds = await reportsClient.getReport(
            currentReport.value,
            ctx.root.$route.params.id,
            currentProgram.value,
            params.value
          )
          if (!ds) {
            ds = []
          }
          dataSource.value = ds
        } finally {
          loading.value = false
        }
      }
    }

    return {
      LeagueInfoMoreThanOneProgram,
      currentReportName,
      currentReport,
      tabs,
      currentProgram,
      loading,
      dataSource,
      leagueAbstraction,
      loadReport,
      updateParams,
    }
  },
})
