import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { ScheduleBlocks } from '@/models/elements/Scheduler'

/**
 * Schedule blocks of assigned availability vs. actual availability.
 * assigned is what the user has chosen
 * available is what the facility default is.
 * assigned undefined or empty -- not used
 * available undefined or empty - not available.
 */
export interface AugmentedFacilityAvailability {
  assigned?: ScheduleBlocks
  available?: ScheduleBlocks
}

export function dayNameToID(dayid: string) {
  return days.find((y) => y.name == dayid)?.value || 0
}

export function dayIDToName(dayid: number) {
  return days.find((y) => y.value == dayid)?.name ?? 'n/a'
}

export function FacilityAvailabilityByDay(
  superSchedule: ScheduleBlocks[],
  subSchedule: ScheduleBlocks[]
): AugmentedFacilityAvailability[] {
  const daymap = new Array(days.length)
  for (let i = 0; i < days.length; i++) {
    const superDay = superSchedule.findIndex((x) => x.day == i)
    const subDay = subSchedule.findIndex((x) => x.day == i)
    daymap[i] = {
      assigned: subDay >= 0 ? subSchedule[subDay] : undefined,
      available: superDay >= 0 ? superSchedule[superDay] : undefined,
    }
  }
  return daymap
}
