























































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import store from '@/store'

export default defineComponent({
  name: 'Games',
  components: {
    TypeProgramTab,
  },
  setup(props, ctx) {
    const facilities = computed(() => store.getters.facilities.items)
    const games = computed(() => store.getters.scheduling.games)

    const loading = ref(true)
    /***
     * Returns the structure to define the tabs based on loaded league
     */
    /** index into this.leagues.programs[] variables  */

    onMounted(async () => {
      await Promise.all([
        store.dispatch.facilities.load({ id: ctx.root.$route.params.id ?? '' }),
        store.dispatch.scheduling.loadGames({ id: ctx.root.$route.params.id ?? '' }),
      ])
      if (games.value.length) {
        await ctx.root.$router.push('manual')
      }

      loading.value = false
    })

    return {
      loading,
      facilities,
    }
  },
})
