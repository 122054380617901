










import { defineComponent, PropType, computed } from '@vue/composition-api'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'

export default defineComponent({
  name: 'SquadScheduleInfo',
  props: {
    unscheduled: { type: Array as PropType<DivisionTeamInfo[]>, required: true },
  },
  setup(props) {
    const unscheduledTeamCount = computed(() => props.unscheduled.length)

    return { unscheduledTeamCount }
  },
})
