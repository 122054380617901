









import { defineComponent, computed } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import store from '@/store'

export default defineComponent({
  name: 'ThePromptToSave',
  components: {
    Modal,
  },
  setup() {
    const isVisible = computed(() => store.getters.promptToSave.modalIsVisible)

    return {
      isVisible,
      onYes: store.dispatch.promptToSave.onYes,
      onNo: store.dispatch.promptToSave.onNo,
      onCancel: store.dispatch.promptToSave.onCancel,
    }
  },
})
