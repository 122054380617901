import restService from '@/services/restService'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'

import { TeamGeneratorGuidelineInfo } from '@/GeneratedTypes/ListInfo/TeamGeneratorGuidelineInfo'
import { TeamGenerationData } from '@/models/Teams/TeamGenerationData'
import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'
import { DivisionTeamPracticeAppointment } from '@/GeneratedTypes/DivisionTeamPracticeAppointment'
import { DivisionTeamPracticeAppointmentModified } from '@/models/Practices/DivisionTeamPracticeModified'
import { DivisionTeamPracticeAppointmentInfoModified } from '@/models/Practices/DivisionTeamPracticeAppointmentInfoModified'

const baseUrl = 'teams'

function practiceInfo2practice(
  p: DivisionTeamPracticeAppointmentInfoModified
): DivisionTeamPracticeAppointmentModified {
  return {
    id: p.id,
    practiceEnd: p.practiceEnd,
    practiceStart: p.practiceStart,
    facilityID: p.facilityID,
  }
}
function ourURL(leagueID: string) {
  return `${baseUrl}/${leagueID}`
}

class TeamsClientException extends GeneralError {
  name = 'Participant API Exception'
}

export default class teamsClient {
  /**
   * Grab team information for a given individual.
   * @param leagueID
   * @param individualId
   */
  static async retrieveTeamInfo({
    leagueID,
    individualId,
  }: {
    leagueID: UpwardLeagueIDType
    individualId: PlayerInfoIDType
  }): Promise<DivisionTeamInfo[]> {
    const suffix = `?individualID=${individualId}`
    const x = await restService.get<DivisionTeamInfo[]>(`${ourURL(leagueID)}${suffix}`)
    if (x.isSuccess) {
      return x.data ?? []
    }
    throw new TeamsClientException(`Error retrieving team information for ${individualId}`)
  }

  /**
   * Retrieve all teams for a league
   * @param leagueID
   */
  static async retrieveLeagueTeams({
    leagueID,
  }: {
    leagueID: UpwardLeagueIDType
  }): Promise<DivisionTeamInfo[]> {
    const x = await restService.get<DivisionTeamInfo[]>(ourURL(leagueID))
    if (x.isSuccess) {
      return x.data ?? []
    }
    throw new TeamsClientException(`Error retrieving team information for ${leagueID}`)
  }

  /**
   * Grab a list of teams for a given league and division.
   * @param leagueId
   * @param divisionId
   * @param typeProgramId
   */
  static async retrieveTeamList({
    leagueId,
    divisionId,
    typeProgramId,
  }: {
    leagueId: UpwardLeagueIDType
    divisionId?: number
    typeProgramId: string
  }): Promise<DivisionTeamInfo[]> {
    const x = await restService.get<DivisionTeamInfo[]>(
      `${ourURL(leagueId)}/${typeProgramId}` + (divisionId !== undefined ? `/${divisionId}` : ``)
    )
    if (x.isSuccess) {
      return x.data ?? []
    }
    throw new TeamsClientException(
      `Error retrieving team list for ${leagueId} ${typeProgramId} ${divisionId}`
    )
  }

  /**
   * Generate teams for a given league and division.
   * @param leagueId
   * @param divisionId
   * @param typeProgramId
   */
  static async getTeamGenerationGuidelines({
    leagueId,
    divisionId,
    typeProgramId,
  }: {
    leagueId: UpwardLeagueIDType
    divisionId: number
    typeProgramId: string
  }): Promise<TeamGeneratorGuidelineInfo> {
    const x = await restService.get<TeamGeneratorGuidelineInfo>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/guidelineInfo`
    )
    if (x.isSuccess) {
      return x.data!
    }
    throw new TeamsClientException(
      `Error creating teams generation guidelines ${leagueId} ${typeProgramId} ${divisionId}`
    )
  }

  /**
   * Generate teams for a given league and division.
   * @param leagueId
   * @param divisionId
   * @param typeProgramId
   */
  static async generateTeams({
    leagueId,
    divisionId,
    typeProgramId,
    teamGenerationData,
  }: {
    leagueId: UpwardLeagueIDType
    divisionId: number
    typeProgramId: string
    teamGenerationData: TeamGenerationData
  }): Promise<DivisionTeamInfo[]> {
    const x = await restService.post<DivisionTeamInfo[]>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/generateTeams`,
      teamGenerationData
    )
    if (x.isSuccess) {
      return x.data ?? []
    }
    throw new TeamsClientException(
      `Error creating teams ${leagueId} ${typeProgramId} ${divisionId} ${teamGenerationData}`
    )
  }

  /**
   * Generate new team template.
   */
  static async getTeamTemplate(): Promise<DivisionTeam> {
    const x = await restService.get<DivisionTeam>(`${baseUrl}/new`)
    if (x.isSuccess) {
      return x.data!
    }
    throw new TeamsClientException(`Error getting new team template.`)
  }

  /**
   * Delete a team.
   * @param leagueId
   * @param divisionId
   * @param typeProgramId
   * @param teamId
   * @param teamGenerationData
   */
  static async deleteTeam({
    leagueId,
    divisionId,
    typeProgramId,
    teamId,
  }: {
    leagueId: UpwardLeagueIDType
    divisionId: number
    typeProgramId: string
    teamId: number
  }): Promise<boolean> {
    const x = await restService.delete<DivisionTeamInfo>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/${teamId}`
    )
    if (x.isSuccess) {
      return true
    }
    throw new TeamsClientException(
      `Error deleting team for ${leagueId} ${typeProgramId} ${divisionId} ${teamId}`
    )
  }

  /**
   * Attempts to create the specified team.
   * @param team
   */
  static async createTeam({ team }: { team: DivisionTeam }): Promise<boolean> {
    const x = await restService.post<DivisionTeamInfo[]>(`${baseUrl}`, team)
    if (x.isSuccess) {
      return true
    }
    throw new TeamsClientException(`Error creating team`)
  }

  /**
   * Attempts to create the specified team.
   * @param team
   * @param leagueId
   */
  static async saveTeam({
    team,
    leagueId,
  }: {
    team: DivisionTeam
    leagueId: UpwardLeagueIDType
  }): Promise<boolean> {
    const x = await restService.put<DivisionTeamInfo[]>(
      `${ourURL(leagueId)}/${team.typeProgramID}/${team.divisionID}/${team.teamID}`,
      team
    )
    if (x.isSuccess) {
      return true
    }
    throw new TeamsClientException(`Error saving team`)
  }

  /**
   * Returns a list of approved coaches by division, which accounts for the coach's age preferences.
   * @param leagueId
   * @param typeProgramId
   * @param divisionId
   * @param teamId
   * @param individualId
   * @return Promise<boolean>
   */
  static async addCoachToTeam(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number,
    teamId: number,
    individualId: number,
    isHeadCoach: boolean
  ): Promise<boolean> {
    //{{url}}/teams/{upwardleagueid}/{typeProgramId}/{teamId}/{divisionId}/coaches/{individualid}
    const x = await restService.post<number>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/${teamId}/coaches/${individualId}`,
      { headCoach: isHeadCoach }
    )

    if (!x.isSuccess) {
      throw new TeamsClientException(`Could not add coach to team. ${x.errorObject?.message}`)
    }
    return true
  }

  /**
   * Returns a list of approved coaches by division, which accounts for the coach's age preferences.
   * @param leagueId
   * @param typeProgramId
   * @param divisionId
   * @param teamId
   * @param individualId
   * @return Promise<boolean>
   */
  static async makeCoachHeadCoach(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number,
    teamId: number,
    individualId: number
  ): Promise<boolean> {
    //{{url}}/teams/{upwardleagueid}/{typeProgramId}/{teamId}/{divisionId}/coaches/{individualid}
    const x = await restService.put<number>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/${teamId}/coaches/${individualId}`,
      { headCoach: true }
    )

    if (!x.isSuccess) {
      throw new TeamsClientException(`Could not add coach to team. ${x.errorObject?.message}`)
    }
    return true
  }

  /***
   * It's possible to return a team as DivisionTeam or DivisionTeamInfo. This returns a DivisionTeam.
   * DivsionTeamInfo has more information in it.
   * @param leagueID
   * @param programID
   * @param divisionID
   * @param teamid
   */
  static async getTeam(leagueID: UpwardLeagueIDType, programID: string, divisionID: number, teamid: number) {
    if (!programID || !divisionID || !teamid) {
      throw new TeamsClientException(`Getting teams we need a program (${programID}, team and division`)
    }
    const x = await restService.get<DivisionTeam>(`${ourURL(leagueID)}/${programID}/${divisionID}/${teamid}`)
    if (x.isSuccess) {
      return x.data!
    }
    throw new TeamsClientException(`Error getting new team id ${teamid}.`)
  }

  /**
   * This is a weird mashup of services with the effect of replacing the practice schedule on a
   * per team basis.
   * @param leagueID
   * @param teamID, string not a number, so like a "8000123" looking thing
   * @param practices
   */
  static async setPractices(
    leagueID: UpwardLeagueIDType,
    teamID: number,
    practices: DivisionTeamPracticeAppointmentInfoModified[]
  ) {
    if (practices && practices.length) {
      const newPractices: DivisionTeamPracticeAppointmentModified[] = []
      const teamID = practices[0].teamID
      const programID = practices[0].typeProgramID
      const divisionID = practices[0].divisionID
      const team = await teamsClient.getTeam(leagueID, programID || '', divisionID, teamID)
      // first part replaces existing ids that are specified.
      team.practices &&
        team.practices.forEach((existingPractice) => {
          const pti = practices.find((x) => x.id == existingPractice.id)
          if (pti) {
            newPractices.push({ ...practiceInfo2practice(pti), id: 0 })
          } else {
            newPractices.push({
              ...(({ ...existingPractice, id: 0 } as unknown) as DivisionTeamPracticeAppointmentModified),
            })
          }
        })
      // then write anything with a 0 for a practice id.
      practices.forEach((newPractice) => {
        if (newPractice.id === 0) {
          newPractices.push({ ...practiceInfo2practice(newPractice), id: 0 })
        }
      })
      // I know there is is a lot of gymnastics going on here, but the bottom line is that the
      // date serialization doesn't take the TZ into account on the server when it comes from
      // a date so we pre-do it in the component as a string and then pass it to the
      team.practices = (newPractices as unknown[]) as DivisionTeamPracticeAppointment[]
      await teamsClient.saveTeam({ team, leagueId: leagueID })
    }
  }

  /**
   * This is a weird mashup of services with the effect of replacing the practice schedule on a
   * per team basis.
   * @param leagueID
   * @param programID
   * @param divisionID
   * @param teamID
   * @param practiceIDs
   */
  static async deletePractices(
    leagueID: UpwardLeagueIDType,
    programID: string,
    divisionID: number,

    teamID: number,
    practiceIDs: number[]
  ) {
    const team = await teamsClient.getTeam(leagueID, programID, divisionID, teamID)
    let newPractices: DivisionTeamPracticeAppointment[] = []

    // first part replaces existing ids that are specified.
    if (team.practices) {
      newPractices = team.practices.filter(
        (existingPractice) => practiceIDs.findIndex((x) => x == existingPractice.id) < 0
      )
    }

    team.practices = newPractices
    await teamsClient.saveTeam({ team, leagueId: leagueID })
  }

  /**
   * This is a weird mashup of services with the effect of replacing the practice schedule on a
   * per team basis.
   * @param leagueID
   * @param programID
   * @param divisionID
   * @param teamIDs - string array of upward team ids
   */
  static async clearPractices(
    leagueID: UpwardLeagueIDType,
    programID: string,
    divisionID: number,
    teamIDs: number[]
  ) {
    for (let i = 0; i < teamIDs.length; i++) {
      const teamID = teamIDs[i]
      const team = await teamsClient.getTeam(leagueID, programID, divisionID, teamID)

      team.practices = []
      await teamsClient.saveTeam({ team, leagueId: leagueID })
    }
  }

  /**
   * Removes a coach from a team.
   * @param leagueId
   * @param typeProgramId
   * @param divisionId
   * @param teamId
   * @param individualId
   * @return Promise<boolean>
   */
  static async removeCoachFromTeam(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number,
    teamId: number,
    individualId: number
  ): Promise<boolean> {
    //{{url}}/teams/{upwardleagueid}/{typeProgramId}/{teamId}/{divisionId}/coaches/{individualid}
    const x = await restService.delete<number>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/${teamId}/coaches/${individualId}`
    )

    if (!x.isSuccess) {
      throw new TeamsClientException(`Could not add coach to team. ${x.errorObject?.message}`)
    }
    return true
  }

  /**
   * Returns a list of approved coaches by division, which accounts for the coach's age preferences.
   * @param leagueId
   * @param typeProgramId
   * @param divisionId
   * @param teamId
   * @param individualId
   * @return Promise<boolean>
   */
  static async addPlayerToTeam(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number,
    teamId: number,
    individualId: number,
    lockPlayerToTeam: boolean
  ): Promise<boolean> {
    //{{url}}/teams/{upwardleagueid}/{typeProgramId}/{teamId}/{divisionId}/coaches/{individualid}
    const x = await restService.post<number>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/${teamId}/players/${individualId}`,
      {
        typePositionID: '',
        lockPlayerToTeam: lockPlayerToTeam,
      }
    )

    if (!x.isSuccess) {
      throw new TeamsClientException(`Could not add coach to team. ${x.errorObject?.message}`)
    }
    return true
  }

  static async updatePlayerOnTeam(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number,
    teamId: number,
    individualId: number,
    lockPlayerToTeam: boolean,
    typePositionID: string
  ): Promise<boolean> {
    const x = await restService.put<number>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/${teamId}/players/${individualId}`,
      {
        typePositionID: typePositionID,
        lockPlayerToTeam: lockPlayerToTeam,
      }
    )

    if (!x.isSuccess) {
      throw new TeamsClientException(`Could not update player on team. ${x.errorObject?.message}`)
    }
    return true
  }

  static async removePlayerFromTeam(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number,
    teamId: number,
    individualId: number
  ): Promise<boolean> {
    const x = await restService.delete<number>(
      `${ourURL(leagueId)}/${typeProgramId}/${divisionId}/${teamId}/players/${individualId}`
    )

    if (!x.isSuccess) {
      throw new TeamsClientException(`Could not remove player from the team. ${x.errorObject?.message}`)
    }
    return true
  }
}
