import { generateIsSelected, OrderProduct } from '@/models/Order/OrderProduct'
import store from '@/store'
import { computed, ComputedRef, onMounted } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { OrderProductSelection } from '@/store/orders'

/**
 * Encapsulates logic where products are selected before quantities are ordered.
 * This one uses the store automatically and is geared toward startup orders where the
 * products are chosen before the quantities are known.
 */
export function useProductSelector() {
  return function useProductSelector() {
    /**
     * toggles a product
     * @param product
     */
    function toggleProduct(product: OrderProduct) {
      //productSelected is readonly.
      let t = (cloneDeep(productsSelected.value) as unknown) as OrderProductSelection[]

      const f = t.find((x) => x.id == product.id)
      if (f) {
        t = t.filter((x) => x.id != product.id)
      } else {
        t.push({ id: product.id, upwardProductID: product.upwardProductID, variation: '' })
      }
      setCurrentOrderSelectedProducts({ list: t })
    }

    function isSelected(product: OrderProduct) {
      return generateIsSelected(productsSelected.value)(product)
    }

    const productsSelected = computed(() => store.getters.orders.productsSelected)

    const currentOrderProducts = computed(() => store.getters.orders.currentOrderProducts)

    let setCurrentOrderSelectedProducts = store.commit.orders.setCurrentOrderSelectedProducts
    onMounted(() => {
      setCurrentOrderSelectedProducts = store.commit.orders.setCurrentOrderSelectedProducts
    })

    return { toggleProduct, isSelected, currentOrderProducts, productsSelected }
  }
}
export type ProductSelectorLogic = ReturnType<typeof useProductSelector>
export type ProductSelectorLogicGenerator = typeof useNoProductSelector
export interface ProductSelectorLogicGeneratorParams {
  currentOrderProductsRef: ComputedRef<readonly OrderProduct[]>
}
/**
 * Encapsulates logic where products are not pre-selected before quantities are ordered.
 * All products from the product selector will be returned on this (e.g. everything available is available)
 *
 */
export function useNoProductSelector(p: ProductSelectorLogicGeneratorParams) {
  const useNoProductSelector: ProductSelectorLogic = () => {
    function toggleProduct() {
      //no op toggling a product
    }

    // here we are emulating the type of

    function isSelected() {
      return true
    }

    const productsSelected = computed(() =>
      currentOrderProducts.value.map(
        (x) => ({ id: x.id, upwardProductID: x.upwardProductID, variation: '' } as OrderProductSelection)
      )
    )

    const currentOrderProducts = computed(() => p.currentOrderProductsRef.value)

    return { toggleProduct, isSelected, currentOrderProducts, productsSelected }
  }
  return useNoProductSelector
}
