









import { defineComponent, computed } from '@vue/composition-api'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'
import { Route } from 'vue-router'
import FullBody from '@/components/FullBody.vue'
import store from '@/store'

export default defineComponent({
  name: 'Index',
  components: {
    VerticalTabs,
    Loading,
    Modal,
    FullBody,
  },
  setup(props, ctx) {
    const storeLeague = computed(() => store.getters.leagues.currentItem)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const baseRoute = computed(() => `/programs/league/${routeId.value}scheduling`)

    function resolver($route: Route, to: string | undefined) {
      if (!to) {
        return false
      }
      //games matches games
      if ($route.path.match(/.*scheduling\/(auto|manual|game)$/) && to.match(/.*games$/)) {
        return true
      }

      //prevents .../practices from matching /practices/cheer
      if ($route.path.match(/.*practices$/) && to.match(/.*cheer$/)) {
        return false
      }

      //prevents .../practices/cheer from matching /practices
      if ($route.path.match(/.*cheer$/) && to.match(/.*practices$/)) {
        return false
      }

      return $route.path.indexOf(to) !== -1
    }

    const tabs = computed(() => {
      let retval = [
        { text: 'Facilities', to: `${baseRoute.value}/facilities` },
        {
          text: 'Teams',
          tabs: [
            { text: 'Team Games', to: `${baseRoute.value}/games` },
            { text: 'Team Practices', to: `${baseRoute.value}/practices` },
          ],
        },
      ]

      if (storeLeague.value && storeLeague.value.programs && storeLeague.value.programs?.length > 1) {
        retval = [
          ...retval,
          ...[
            {
              text: 'Squads',
              tabs: [
                { text: 'Squads ', to: `${baseRoute.value}/cheer` },
                { text: 'Squad Practices', to: `${baseRoute.value}/practices/cheer` },
              ],
            },
          ],
        ]
      }

      retval = [...retval, ...[{ text: 'Special Events', to: `${baseRoute.value}/specialEvents` }]]

      return retval
    })

    return {
      tabs,
      resolver,
    }
  },
})
