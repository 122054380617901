































































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { RegistrationTransactionParticipantExtended } from '@/models/Registration/RegistrationTransactionParticipantExt'
import { RegistrationTransactionParticipantInfoDetails } from '@/models/Registration/RegistrationTransactionParticipantInfoDetails'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { CompleteRegistrationTransactionType } from '@/models/Registration/CompleteRegistrationTransactionType'
import CompleteRegistrationTransaction from '@/views/AdminDash/vues/RegistrationTransactionPanel/CompleteRegistrationTransaction.vue'
import RefundRemoveTransaction from '@/views/AdminDash/vues/RegistrationTransactionPanel/RefundRemoveTransaction.vue'
import ChargebackDetails from '@/views/AdminDash/vues/RegistrationTransactionPanel/ChargebackDetails.vue'
import { registrationClient } from '@/clients/registrationClient'
import { RegistrationStatusEnum } from '@/models/Registration/RegistrationStatusEnum'
import { RefundRemoveTransactionType } from '@/models/Registration/RefundRemoveTransactionType'

export default defineComponent({
  components: { RefundRemoveTransaction, CompleteRegistrationTransaction, ChargebackDetails },
  props: {
    transaction: { type: Object as PropType<RegistrationTransactionParticipantExtended>, required: true },
  },

  setup(props) {
    const internalTransaction = ref<RegistrationTransactionParticipantExtended | null>(null)
    let chargebackDetails = ref<RegistrationTransactionParticipantInfoDetails[] | null>(null)

    watch(
      () => props.transaction,
      () => (internalTransaction.value = cloneDeep(props.transaction)),
      { immediate: true }
    )

    const date = computed(() => {
      if (internalTransaction.value && internalTransaction.value.transactionCompletedDate) {
        const d = dayjs(internalTransaction.value.transactionCompletedDate)
        // some of these transactions dates are the minute that Christ was born, we won't display those.
        if (d.valueOf() > 100) {
          return d.format('MMM D YYYY h:mm a')
        }
        return ''
      }
    })

    const isMarkCompleteShowing = ref(false)
    const isRefundShowing = ref(false)
    const isChargebackDetailsShowing = ref(false)

    async function onMarkComplete(info: CompleteRegistrationTransactionType) {
      try {
        await registrationClient.complete(
          info.transaction.upwardLeagueID,
          info.transaction.leagueID,
          info.transaction.id,
          info.type,
          info.authorization,
          info.last4
        )
        if (internalTransaction.value) {
          internalTransaction.value.typeRegistrationTransactionStatusID = RegistrationStatusEnum.COMPLETE
        }
        isMarkCompleteShowing.value = false
      } catch (e) {
        throw e
      }
    }

    const markRefundRemove = (refund: boolean) => async (info: RefundRemoveTransactionType) => {
      try {
        await registrationClient.refund(
          info.transaction.upwardLeagueID,
          info.transaction.leagueID,
          info.transaction.id,
          info.reason,

          refund
        )
        if (internalTransaction.value) {
          internalTransaction.value.typeRegistrationTransactionStatusID = refund
            ? RegistrationStatusEnum.REFUNDED
            : RegistrationStatusEnum.REMOVED
        }
        isRefundShowing.value = false
      } catch (e) {
        throw e
      }
    }

    async function showChargebackDetails(upwardLeagueId: string, transactionId: number) {
      console.log('showChargebackDetails', upwardLeagueId, transactionId)
      await getChargebackDetails(upwardLeagueId, transactionId)
      isChargebackDetailsShowing.value = true
    }

    async function getChargebackDetails(upwardLeagueId: string, transactionId: number) {
      try {
        console.log('getChargebackDetails', upwardLeagueId, transactionId)
        chargebackDetails.value = await registrationClient.getChargebackDetails(upwardLeagueId, transactionId)
        console.log(chargebackDetails.value)
      } catch (e) {
        throw e
      }
    }

    function onChargebackClose() {
      isChargebackDetailsShowing.value = false
    }

    const onRefundComplete = markRefundRemove(true)
    const onRemoveComplete = markRefundRemove(false)

    function markComplete() {
      isMarkCompleteShowing.value = true
    }

    function refund() {
      isRefundShowing.value = true
    }

    return {
      date,
      markComplete,
      refund,
      isRefundShowing,
      onMarkComplete,
      onRemoveComplete,
      onRefundComplete,
      isMarkCompleteShowing,
      internalTransaction,
      RegistrationStatusEnum,
      showChargebackDetails,
      isChargebackDetailsShowing,
      chargebackDetails,
      onChargebackClose,
    }
  },
})
