








































import Alert from '@/components/Alert.vue'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import { Component, Vue } from 'vue-property-decorator'
import authClient from '@/clients/authorizationClient'
import localStorageService from '@/services/localStorageService'

const localStorageEmailKey = 'authwall-remembered-email'
const rememberedEmail = localStorageService.getRaw(localStorageEmailKey)

@Component({
  components: {
    Alert,
    TextInput,
    Loading,
  },
})
export default class ForgotPassword extends Vue {
  private email = rememberedEmail || ''
  private isLoading = false
  private successIsVisible = false
  private currentYear = new Date().getFullYear()

  private get allowSubmit() {
    return !this.isLoading && this.email && !this.successIsVisible
  }
  get title() {
    if (this.isExpiredPassword) return 'Expired Password'
    if (this.isLockedAccount) return 'Account Locked'
    return 'Forgot Password'
  }

  get message() {
    if (this.isLockedAccount)
      return 'Your account is locked. Enter your email and click submit to reset your password. This will unlock your account.'
    if (this.isExpiredPassword)
      return 'Your password has expired. Please click submit to request a new password.'
    return ''
  }

  private get isExpiredPassword() {
    return this.$route.path === '/expired-password'
  }

  private get isLockedAccount() {
    return this.$route.path === '/locked-account'
  }

  private created() {
    if (typeof this.$route.query.email === 'string') {
      this.email = this.$route.query.email
    }
  }

  private async onSubmit() {
    this.successIsVisible = false

    if (this.isLoading) {
      return
    }

    this.isLoading = true
    try {
      const rsr = await authClient.getPasswordResetLink(this.email)

      if (rsr.isSuccess) {
        this.successIsVisible = true
      }
    } finally {
      this.isLoading = false
    }
  }
}
