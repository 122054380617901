
















import { defineComponent, ref, watch, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ButtonGroup',
  props: {
    options: {
      type: Array as PropType<Array<{ text: string; slotName: string; value: string }>>,
      required: true,
    },
    value: { type: String, required: true },
  },
  setup(props, ctx) {
    const internalValue = ref<string | null>(null)

    function onInternalValueChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    function select(val: any) {
      internalValue.value = val
      onInternalValueChange()
    }

    //private created() {
    setInternalValue()
    //}

    function setInternalValue() {
      internalValue.value = props.value ? props.value : props.options.length ? props.options[0].value : null
    }

    watch(
      () => props.value,
      () => {
        setInternalValue()
      }
    )

    watch(
      () => internalValue.value,
      () => {
        onInternalValueChange()
      }
    )

    return {
      internalValue,
      select,
    }
  },
})
