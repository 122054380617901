



















































































import { computed, defineComponent, ref, watch, PropType } from '@vue/composition-api'
import { TeamGeneratorGuidelineInfo } from '@/GeneratedTypes/ListInfo/TeamGeneratorGuidelineInfo'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'

export interface DraftOptions {
  drafting: string
  practiceNightsFirst: boolean
}

export default defineComponent({
  props: {
    teamSquadText: { type: String, required: true },
    hasPlayersAssigned: { type: Boolean, required: false, default: false },
    guidelineInfo: {
      type: Object as PropType<TeamGeneratorGuidelineInfo | null>,
      required: false,
      default: null,
    },
  },
  components: {
    RadioGroupInput,
  },
  setup(props, ctx) {
    const practiceNightsFirst = ref(false)
    const draftingMode = ref('DRAFTUNLOCKEDONLY')

    const lowerCaseTeamSquadText = computed(() => props.teamSquadText.toLowerCase())

    watch(
      () => draftingMode.value,
      () => emitData()
    )

    watch(
      () => practiceNightsFirst.value,
      () => emitData()
    )

    function emitData() {
      ctx.emit('selected', {
        drafting: draftingMode.value,
        practiceNightsFirst: practiceNightsFirst.value,
      } as DraftOptions)
    }

    return {
      lowerCaseTeamSquadText,
      draftingMode,
      practiceNightsFirst,
    }
  },
})
