import axios, { AxiosResponse, AxiosRequestConfig, AxiosRequestTransformer } from 'axios'
import { UpwardExceptionResult } from '@/models/UpwardExceptionResult'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import dayjs from 'dayjs'

// By default axios uses JSON.stringify to serialize Date objects. JSON.stringify
// automatically converts local time to UTC so 10:00:00 Eastern is converted to 3pm
// before transmission.
//
// dateTransformer() override the default axios behavior and transmits
// the absolute time (e.g. 10:00:00). This means that leagues cannot support timezones,
// but because there was no way to differentiate between all the times already in the
// db that lost their timezone information, and leagues in different time zones are rare,
// this is the approach that was agreed upon.
const dateTransformer = (data: any): any => {
  if (data instanceof Date) {
    return dayjs(data).format('YYYY-MM-DDTHH:mm:ss')
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer)
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, dateTransformer(value)]))
  }
  return data
}
const axiosInstance = axios.create({
  transformRequest: [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
})

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url && config.method && config.method == 'post') {
      if (config.url.endsWith('authorization/login/') || config.url.endsWith('authorization/login')) {
        if (config && config.headers) {
          config.headers.Authorization = ''
        }
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

/**
 * Return the APP-wide API server base URL
 */
export function getAPIBaseURL() {
  return process.env.VUE_APP_ROOT_API + '/api'
}

export interface RestServiceResult<T> {
  errorObject: UpwardExceptionResult | null
  isSuccess: boolean
  status: number
  data: T | null
}

export function convertToRestServiceResult<T>(instance: any) {
  const implementsInterface =
    typeof instance === 'object' &&
    instance !== null &&
    'errorObject' in instance &&
    'isSuccess' in instance &&
    'data' in instance

  return implementsInterface ? (instance as RestServiceResult<T>) : null
}

export function convertToVerificationDetails(instance: any) {
  const implementsInterface =
    typeof instance === 'object' &&
    instance !== null &&
    'errorObject' in instance &&
    'isSuccess' in instance &&
    'data' in instance &&
    instance.data &&
    'brokenRules' in instance.data &&
    'model' in instance.data

  return implementsInterface ? (instance as RestServiceResult<UpwardVerificationDetails>) : null
}

export const isUpwardExceptionResult = (instance: any) => {
  return typeof instance === 'object' && instance !== null && 'message' in instance && 'errors' in instance
}

const getRestServiceResult = <T>(axiosResponse: AxiosResponse<any>) => {
  const isSuccessCode = Math.floor(axiosResponse.status / 100) === 2
  const isErrorObject = isUpwardExceptionResult(axiosResponse.data)

  return {
    data: isErrorObject ? null : (axiosResponse.data as T),
    isSuccess: isSuccessCode && !isErrorObject,
    status: axiosResponse.status,
    errorObject: isErrorObject
      ? axiosResponse.data
      : ({
          message: axiosResponse.statusText || 'Unknown error',
        } as UpwardExceptionResult),
  } as RestServiceResult<T>
}

const wrapApiCall = async <T>(callback: () => Promise<AxiosResponse<any>>) => {
  return await callback()
    .then((result) => {
      return getRestServiceResult<T>(result)
    })
    .catch((error) => {
      if (error.response) {
        throw getRestServiceResult<T>(error.response)
      }

      throw {
        data: null,
        isSuccess: false,
        status: 0,
        errorObject: {
          message: error,
          errors: null,
          id: null,
        },
      } as RestServiceResult<T>
    })
}

const post = <T>(
  resource: string,
  data: object | null | string,
  config: AxiosRequestConfig | undefined = undefined
) => {
  const fullUrl = `${getAPIBaseURL()}/${resource}`
  return wrapApiCall<T>(() => axiosInstance.post(fullUrl, data, config))
}

const put = <T>(
  resource: string,
  data: object | null,
  config: AxiosRequestConfig | undefined = undefined
) => {
  const fullUrl = `${getAPIBaseURL()}/${resource}`
  return wrapApiCall<T>(() => axiosInstance.put(fullUrl, data, config))
}

const get = <T>(resource: string, params = {}, other_config: AxiosRequestConfig | undefined = undefined) => {
  const fullUrl = `${getAPIBaseURL()}/${resource}`
  const args = { method: 'get', url: fullUrl, ...(other_config ?? {}), params }
  return wrapApiCall<T>(() => axiosInstance(args as AxiosRequestConfig))
}

const deleteMethod = <T>(resource: string, data?: object | null) => {
  const fullUrl = `${getAPIBaseURL()}/${resource}`
  return wrapApiCall<T>(() => axiosInstance.delete(fullUrl, { data }))
}

const patch = <T>(resource: string, data: object | null) => {
  const fullUrl = `${getAPIBaseURL()}/${resource}`
  return wrapApiCall<T>(() => axiosInstance.patch(fullUrl, data))
}

const setToken = (token: string | null | undefined) => {
  axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : ''
}
const setAccountHeader = (accountNumber: string | null) => {
  axios.defaults.headers.common['AccountNumber'] = accountNumber ?? ''
}

const getToken = () => {
  return axios.defaults.headers.common.Authorization
}

export default {
  get,
  post,
  put,
  delete: deleteMethod,
  patch,
  setToken,
  getToken,
  setAccountHeader,
}
