













import { defineComponent, ref } from '@vue/composition-api'
import CurrentPrograms from '@/components/CurrentPrograms.vue'
export default defineComponent({
  name: 'Resources',
  components: {
    CurrentPrograms,
  },
  setup() {
    const noProgramsTest = ref(
      'There are no active programs. Please <a href="/programs/new">create a program</a> to view resources.'
    )

    return {
      noProgramsTest,
    }
  },
})
