
































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import Loading from '@/elements/Loading.vue'
import SelectorCard from './SelectorCard.vue'

import { DevotionTrackInfo, getEmptyDevotionTrackInfo } from '@/models/ContentManagement/DevotionTrack'

import contentManagementClient from '@/clients/contentManagementClient'
import store from '@/store'

export default defineComponent({
  name: 'LeagueDevotionTrackSelection',
  components: {
    RadioGroupInput,
    Loading,
    SelectorCard,
  },
  props: {
    value: { type: String, required: true },
  },
  setup(props, ctx) {
    const league = computed(() => store.getters.leagues.currentItem)
    const selectedDevotion = ref('')
    const isLoading = ref(false)
    const devoTrackInfo = ref<DevotionTrackInfo>(getEmptyDevotionTrackInfo())

    onMounted(async () => {
      await loadDevoInfo()
    })

    watch(
      () => selectedDevotion.value,
      () => {
        if (props.value !== selectedDevotion.value) {
          ctx.emit('input', selectedDevotion.value)
        }
      }
    )

    watch(
      () => props.value,
      () => {
        if (props.value !== selectedDevotion.value) {
          selectedDevotion.value = props.value
        }
      },
      { immediate: true }
    )

    async function loadDevoInfo() {
      await store.dispatch.leagueTypes.fetchAll(false)
      const leagueAbbreviation = store.getters.leagueTypes.byUpwardTypeId(league.value.typeLeagueID)
      if (isLoading.value || !leagueAbbreviation.abbreviation) return
      isLoading.value = true

      try {
        const result = await contentManagementClient.getDevotionTracks(
          leagueAbbreviation.abbreviation,
          league.value.typeProductGroupID
        )
        devoTrackInfo.value = result.data || getEmptyDevotionTrackInfo()
      } finally {
        isLoading.value = false
      }
    }

    return {
      devoTrackInfo,
      selectedDevotion,
    }
  },
})
