


































































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import LeagueOverview from '@/components/LeagueOverview.vue'
import ClinicOverview from '@/components/ClinicOverview.vue'
import ConnectOverview from '@/components/ConnectOverview.vue'
import CampOverview from '@/components/CampOverview.vue'
import ResumeProgram from '@/components/ResumeProgram.vue'
import programsClient from '@/clients/programsClient'
import Loading from '@/elements/Loading.vue'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'

export default defineComponent({
  name: 'CurrentPrograms',
  components: {
    ConnectOverview,
    LeagueOverview,
    ClinicOverview,
    CampOverview,
    Loading,
    ResumeProgram,
  },
  props: {
    resourceOnlyView: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: '' },
    noProgramsText: { type: String, required: false, default: '' },
    includeHistorical: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const programs = ref<LeagueInfoCondensed[]>([])

    const programsToShow = ref(2)
    const partialProgramCount = ref(0)

    const isLoading = ref(false)

    const resourceLink = computed(() => {
      if (props.resourceOnlyView) {
        return '/resources'
      }
      return ''
    })

    async function fetchAll() {
      if (props.resourceOnlyView) {
        programsToShow.value = 10
      }

      isLoading.value = true
      try {
        const restResult = await programsClient.retrieveAll(props.includeHistorical)

        if (restResult.isSuccess && restResult.data !== null) {
          programs.value = restResult.data
        }

        if (props.includeHistorical) {
          programs.value = programs.value.filter((p) => p.isHistorical)
        }
      } finally {
        isLoading.value = false
      }
    }

    const noPrograms = computed(
      () => !isLoading.value && programs.value && programs.value.length == 0 && partialProgramCount.value == 0
    )

    function setPartialCount(count: number) {
      partialProgramCount.value = count
    }

    onMounted(async () => {
      await fetchAll()
    })

    return {
      isLoading,
      noPrograms,
      programsToShow,
      programs,
      resourceLink,
      setPartialCount,
    }
  },
})
