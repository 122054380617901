import restService from '@/services/restService'

const baseUrl = 'sessionobjects/'

const createOrUpdate = async (key: string, model: object) => {
  return await restService.post<null>(`${baseUrl}${key}/10080`, model)
}

const retrieve = async <T>(key: string) => {
  return await restService.get<T>(`${baseUrl}${key}`)
}

const deleteMethod = async (key: string) => {
  return await restService.delete<null>(`${baseUrl}${key}`)
}

const retrieveData = async <T>(key: string) => {
  const r = await restService.get<T>(`${baseUrl}${key}`)
  return r && r.data ? r.data : null
}

export default {
  createOrUpdate,
  retrieve,
  delete: deleteMethod,
  retrieveData,
}
