import { Clinic } from '@/GeneratedTypes/Clinic'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'

export function getEmptyClinic(): Clinic {
  return {
    clinicDate: new Date(),
    id: 0,
    typeLeagueID: '',
    leagueName: '',
    typeProductGroupID: '',
    upwardLeagueID: null,
    programMissionObjective: '',
    typeTimezoneID: 'EASTERN', //default value to pass API validation
    typeCountryID: 'US',
    leagueDirectorPartnerID: 0,
    partnerContact: getEmptyContact(),
    canEdit: true,
    canEditReason: null,
    playerEstimate: null,
    leagueFinderURLOverride: null,
    programs: [],
    gradeDescriptions: [],
  }
}
