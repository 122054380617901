var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white d-flex"},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('div',[_vm._t("header")],2),_c('div',{staticClass:"d-flex justify-content-between align-items-start border-top border-right border-left"},[_c('div',{staticClass:"d-flex mt-5"},[_c('div',[_c('button',{staticClass:"btn",on:{"click":_vm.prevMonth}},[_c('i',{staticClass:"fas fa-caret-left med"})]),_c('button',{staticClass:"btn",on:{"click":_vm.nextMonth}},[_c('i',{staticClass:"fas fa-caret-right med"})]),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.calendarTitle))])])]),_c('CalendarOptions',{attrs:{"daysToShow":_vm.lWeekdays,"showTime":false},on:{"update:daysToShow":function($event){_vm.lWeekdays=$event},"update:days-to-show":function($event){_vm.lWeekdays=$event}}})],1),_c('Container',{staticClass:"border-bottom",staticStyle:{"height":"60vh","min-height":"38em"},attrs:{"group-name":_vm.groupName,"behaviour":"drop-zone"}},[_c('VCalendar',{ref:"thisCalendar",staticClass:"upw-daily-calendar",attrs:{"events":_vm.lEvents,"type":_vm.DisplayType.MONTH,"weekdays":_vm.lWeekdays,"max-days":_vm.maxDays,"start":_vm.start,"end":_vm.end,"event-color":_vm.getEventColor,"event-text-color":_vm.getEventTextColor},on:{"click:event":_vm.eventClick,"click:date":_vm.dateClick,"click:more":_vm.moreClick,"change":_vm.monthChanged},scopedSlots:_vm._u([{key:"interval",fn:function(ref){
var date = ref.date;
var time = ref.time;
var weekday = ref.weekday;
return [_c('div',{staticClass:"v-calendar-daily__day-interval",class:{ 'invalid-day': !_vm.allowed(weekday) }})]}},{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
var eventSummary = ref.eventSummary;
return [_c('div',{staticClass:"pl-1 upw-event",class:event.class},[_c('div',[_c('div',{attrs:{"title":_vm.getEventHover(event)}},[(event.timed)?_c('span',{staticClass:"font-weight-bold pr-2"},[_vm._v(_vm._s(_vm.getEventTime(event)))]):_vm._e(),_vm._v(_vm._s(event.name)+" ")])]),(event.division)?_c('div',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(_vm._s(event.division))])]):_vm._e()])]}}]),model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }