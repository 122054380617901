
































import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import Loading from '@/elements/Loading.vue'
import ContactPickerAndEditor from '@/components/ContactPickerAndEditor.vue'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { getterNames, namespace } from '@/store/authorization'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { ContactToContactInfo, ContactInfoToContact } from '@/services/contactService'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    ContactPickerAndEditor,
    Loading,
    SaveContinueButton,
  },
})
export default class Leadership extends Mixins(LeagueSetupMixin) {
  @Getter(getterNames.accountNumbers, { namespace })
  private accountNumbers!: string[] | null

  private sportContact: ContactInfo | null = null

  mounted() {
    this.afterUpdateExisting = this.afterUpdateExistingMethod

    if (this.league.upwardLeagueID) {
      this.nextRoute = `/programs/league/${this.league.upwardLeagueID}/setup/partnership`
    } else {
      this.nextRoute = '/programs/league/setup/partnership'
    }
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    if (this.initialLoadDone) {
      if (this.league) {
        this.sportContact = ContactToContactInfo(
          this.league.partnerContact!,
          this.league.leagueDirectorPartnerID
        )
      }
    }
  }

  private async onSubmit() {
    this.league.partnerContact = this.sportContact
      ? ContactInfoToContact(this.sportContact)
      : getEmptyContact()
    this.league.leagueDirectorPartnerID = this.sportContact ? this.sportContact.id : 0
    await this.save()
  }

  private get canChangeName(): boolean {
    return !(
      this.league &&
      this.league.programs &&
      this.league.programs.length > 0 &&
      this.league.leagueDirectorPartnerID
    )
  }

  private get isExisting() {
    return !!this.league.upwardLeagueID
  }

  private get isClean() {
    return this.storeLeague.leagueDirectorPartnerID === this.league.leagueDirectorPartnerID
  }

  private afterUpdateExistingMethod() {
    this.league = { ...cloneDeep(this.storeLeague) }
  }
}
