import { DivisionGameSchedulerPreference } from '@/GeneratedTypes/DivisionGameSchedulerPreference'

export function getEmptyDivisionGameSchedulerPreference(): DivisionGameSchedulerPreference {
  return {
    typeScheduleAssignmentID: '',
    typeProgramID: '',
    typeOddTeamCountSchedulingID: '',
    leagueID: 0,
    gamesPerWeek: 0,
    facilityPreferences: [],
    exclusionDates: [],
    divisionID: 0,
    byeGamesStartAfterRoundNumber: 0,
    byeGamesPerTeam: 0,
  }
}

export function isValidDivisionGameSchedulerPreference(s: DivisionGameSchedulerPreference) {
  return (
    !!s &&
    !!s.facilityPreferences?.length &&
    !!s.typeProgramID &&
    !!s.typeOddTeamCountSchedulingID &&
    !!s.gamesPerWeek
  )
}
