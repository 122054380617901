








































import { defineComponent } from '@vue/composition-api'
import { getProps, useTeamCard } from '@/composables/TeamCard'
import { Container, Draggable } from 'vue-smooth-dnd'

export default defineComponent({
  name: 'CoachTeamCard',
  components: { Container, Draggable },
  props: {
    ...getProps(),
  },
  setup(props, ctx) {
    const {
      addSelectedCoach,
      showAssignCoach,
      evalScore,
      yearsExperienceAvg,
      practiceInfo,
      acceptingCoaches,
      droppingCoaches,
      changeHeadCoach,
      removeCoach,
    } = useTeamCard(props, ctx)

    function toggleTeam() {
      ctx.emit('toggle-team', props.team.teamID)
    }

    return {
      toggleTeam,
      addSelectedCoach,
      showAssignCoach,
      evalScore,
      yearsExperienceAvg,
      practiceInfo,
      acceptingCoaches,
      droppingCoaches,
      changeHeadCoach,
      removeCoach,
    }
  },
})
