/**
 * Builds an event ID out of an ID and a prefix, this can help disambiguate ids coming from different sources
 * (e.g. events which can be practices or games )
 * @param prefix - prefix that makes this ID uniques (e.g. "GAMES")
 * @param s - id of the thing as a string
 */
export function id2eventid(prefix: string, s: string) {
  return prefix + s
}

/**
 * returns the original id from a combined id.
 * @param searchprefix - prefix originally used
 * @param s - id to break up
 */
export function eventid2id(searchprefix: string, s: string | null): string {
  const rv = s?.split(searchprefix)
  if (rv && rv.length > 1) {
    return rv[1] as string
  }
  return '0'
}
