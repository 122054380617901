







import { defineComponent, ref } from '@vue/composition-api'
import Tree from '@/components/Tree.vue'
import { teams } from './data'
export default defineComponent({
  name: 'TreeTest',
  props: {},
  data() {
    return { teams }
  },
  components: { Tree },
  setup() {
    const tree = ref([])
    const selected = ref([])

    return { tree, selected }
  },
})
