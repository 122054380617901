









































import { Component, Vue, Prop } from 'vue-property-decorator'

import SectionHeader from '@/components/SectionHeader.vue'
import { getFilteredProductList } from '@/models/Order/OrderProduct.ts'

import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import {
  getEmptyXStartupOrderExt,
  getSalesTaxNotCalculatedYet,
} from '@/lib/support/models/GeneratedTypes/xOrders/xStartupOrderExt'
import { xShipMethodBase } from '@/GeneratedTypes/xOrder/xShipMethodBase'
import { OrderProductSelection } from '@/store/orders'
import currency from '@/filters/currency'

import { ProductSelectorLogic } from '@/views/Programs/Orders/compositions/useProductSelector'

@Component({
  components: {
    SectionHeader,
  },
  methods: { currency },
})
export default class OrderSummary extends Vue {
  @Prop({ type: Object, default: getEmptyXStartupOrderExt, required: false })
  private readonly XStartupOrderExt!: xStartupOrderExt

  @Prop({ type: Boolean, default: false, required: false })
  readOnly!: boolean

  @Prop({ type: String, default: '', required: false })
  private readonly title!: string

  //*** description of the thing we are totaling (e.g. add-on items, or order).
  @Prop({ type: String, default: 'Order', required: false })
  private readonly summaryThing!: string

  //*** only show total.
  @Prop({ type: Boolean, default: false, required: false })
  private readonly totalOnly!: boolean

  @Prop({ type: Array, default: () => [], required: true })
  productsSelected!: OrderProductSelection[]

  @Prop({ type: Function, required: true })
  selectorMethod!: ProductSelectorLogic

  private get subTotal() {
    if (!this.XStartupOrderExt || !this.XStartupOrderExt.products) {
      return 0
    }
    return getFilteredProductList(this.XStartupOrderExt.products, this.selectorMethod().isSelected)
      .map(
        (x) =>
          (x.cost + x.productColorSize.costOffset) *
          (x.productColorSize.orderQuantity - x.productColorSize.orderFreeQuantity)
      )
      .reduce((pv, v) => pv + v, 0)
  }

  get isSalesTaxCalculatedYet() {
    return !getSalesTaxNotCalculatedYet(this.XStartupOrderExt)
  }

  /**
   * returns a numeric sales tax
   */
  get salestax() {
    if (!this.isSalesTaxCalculatedYet) {
      return 0
    } else {
      return this.XStartupOrderExt.salesTax
    }
  }
  /**
   * returns a numeric sales tax
   */
  get discount() {
    if (this.XStartupOrderExt?.longevityDiscountAmount) {
      return this.XStartupOrderExt?.longevityDiscountAmount
    }
    return 0
  }
  private get shippingCost() {
    //if sales tax has not been calculated then shipping is not ready to show.
    if (!this.isSalesTaxCalculatedYet) {
      return 0
    }

    if (
      !this.XStartupOrderExt ||
      !this.XStartupOrderExt.shippingInfo ||
      !this.XStartupOrderExt.shippingInfo.shipMethods
    )
      return 0

    const selMethod = this.XStartupOrderExt.shippingInfo?.shipMethods.find((method: xShipMethodBase) => {
      return method.selected
    })

    return selMethod?.cost || 0
  }

  private get showBasedOn() {
    return (
      ((this.XStartupOrderExt.shippingInfo && this.XStartupOrderExt.shippingInfo.shipMethods?.length) ?? 0) >
      1
    )
  }

  /**
   * Returns a numeric total
   * @private
   */
  private get total() {
    if (!this.isSalesTaxCalculatedYet) return this.subTotal
    else {
      return this.subTotal + this.shippingCost + this.XStartupOrderExt.salesTax
    }
  }
}
