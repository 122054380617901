import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { getEmptyLeagueListItem, LeagueListItemSeed } from '@/lib/support/models/LeagueListItem/data'

/***
 * Limited function gets us from a LeagueProgram array
 * to a description of the league as a league list item.
 * Moves cheer and primary league into the structure.
 *
 * @param pts
 * @constructor
 */
export function LeagueProgams2LeagueListItem(pts: LeagueProgram[]): LeagueListItem {
  const lli = getEmptyLeagueListItem(new LeagueListItemSeed())
  if (pts.length > 1) {
    if (pts[1]) {
      lli.cheerProgramTypeID = pts[1].typeProgramID
      lli.cheerProgramTypeDescription = 'Cheer'
    }
    lli.programTypeID = pts[0].typeProgramID
    lli.programTypeDescription = pts[0].typeProgramID
  }

  return lli
}
