import { CalendarData } from '@/elements/Calendar/types'

export const testEvents: CalendarData[] = [
  {
    start: '2022-03-25T16:00:00',
    end: '2022-03-25T17:30:00',
    name: 'Falcons Practice',
    class: 'practice-active',
    id: 'PRACTICES429571',
    color: '#00BFA5',
    textColor: '#004D40',
  },
  {
    start: '2022-03-26T16:00:00',
    end: '2022-03-26T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429572',
  },
  {
    start: '2022-04-02T16:00:00',
    end: '2022-04-02T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429573',
  },
  {
    start: '2022-04-09T16:00:00',
    end: '2022-04-09T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429574',
  },
  {
    start: '2022-04-16T16:00:00',
    end: '2022-04-16T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429575',
  },
  {
    start: '2022-04-23T16:00:00',
    end: '2022-04-23T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429576',
  },
  {
    start: '2022-04-30T16:00:00',
    end: '2022-04-30T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429577',
  },
  {
    start: '2022-05-07T16:00:00',
    end: '2022-05-07T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429578',
  },
  {
    start: '2022-05-14T16:00:00',
    end: '2022-05-14T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429579',
  },
  {
    start: '2022-05-21T16:00:00',
    end: '2022-05-21T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429580',
  },
  {
    start: '2022-05-28T16:00:00',
    end: '2022-05-28T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429581',
  },
  {
    start: '2022-06-04T16:00:00',
    end: '2022-06-04T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429582',
  },
  {
    start: '2022-06-10T16:00:00',
    end: '2022-06-10T17:30:00',
    name: 'Polar Bears Practice',
    class: 'practice-active',
    id: 'PRACTICES429583',
  },
  {
    start: '2022-03-26T10:00:00',
    end: '2022-03-26T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429584',
  },
  {
    start: '2022-04-02T10:00:00',
    end: '2022-04-02T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429585',
  },
  {
    start: '2022-04-09T10:00:00',
    end: '2022-04-09T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429586',
  },
  {
    start: '2022-04-16T10:00:00',
    end: '2022-04-16T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429587',
  },
  {
    start: '2022-04-23T10:00:00',
    end: '2022-04-23T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429588',
  },
  {
    start: '2022-04-30T10:00:00',
    end: '2022-04-30T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429589',
  },
  {
    start: '2022-05-07T10:00:00',
    end: '2022-05-07T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429590',
  },
  {
    start: '2022-05-14T10:00:00',
    end: '2022-05-14T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429591',
  },
  {
    start: '2022-05-21T10:00:00',
    end: '2022-05-21T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429592',
  },
  {
    start: '2022-05-28T10:00:00',
    end: '2022-05-28T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429593',
  },
  {
    start: '2022-06-04T10:00:00',
    end: '2022-06-04T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-active',
    id: 'PRACTICES429594',
  },
  {
    start: '2022-06-10T10:00:00',
    end: '2022-06-10T11:30:00',
    name: 'Cobras Practice',
    class: 'practice-inactive',
    id: 'PRACTICES429595',
  },
  {
    start: '2022-04-02T08:00:00',
    end: '2022-04-02T09:30:00',
    name: 'Wolves at Polar Bears',
    class: 'game-active  draggable',
    id: 'GAMES3185518',
  },
  {
    start: '2022-04-02T10:30:00',
    end: '2022-04-02T13:00:00',
    name: 'Cobras at Leopards',
    class: 'game-active  draggable',
    id: 'GAMES3185519',
  },
  {
    start: '2022-04-09T11:30:00',
    end: '2022-04-09T13:00:00',
    name: 'Polar Bears at Leopards',
    class: 'game-active',
    id: 'GAMES3185521',
  },
  {
    start: '2022-04-09T08:00:00',
    end: '2022-04-09T09:30:00',
    name: 'Cobras at Cougars',
    class: 'game-active',
    id: 'GAMES3185523',
  },
  {
    start: '2022-04-16T08:00:00',
    end: '2022-04-16T09:30:00',
    name: 'Polar Bears at Cobras',
    class: 'game-active',
    id: 'GAMES3185525',
  },
  {
    start: '2022-04-16T11:30:00',
    end: '2022-04-16T13:00:00',
    name: 'Cougars at Wolves',
    class: 'game-active',
    id: 'GAMES3185526',
  },
  {
    start: '2022-04-23T08:00:00',
    end: '2022-04-23T09:30:00',
    name: 'Falcons at Cobras',
    class: 'game-active',
    id: 'GAMES3185527',
  },
  {
    start: '2022-04-23T11:30:00',
    end: '2022-04-23T13:00:00',
    name: 'Polar Bears at Cougars',
    class: 'game-active',
    id: 'GAMES3185529',
  },
  {
    start: '2022-04-30T08:00:00',
    end: '2022-04-30T09:30:00',
    name: 'Falcons at Polar Bears',
    class: 'game-active',
    id: 'GAMES3185531',
  },
  {
    start: '2022-04-30T11:30:00',
    end: '2022-04-30T13:00:00',
    name: 'Cougars at Leopards',
    class: 'game-active',
    id: 'GAMES3185532',
  },
  {
    start: '2022-05-07T11:30:00',
    end: '2022-05-07T13:00:00',
    name: 'Leopards at Cobras',
    class: 'game-active',
    id: 'GAMES3185534',
  },
  {
    start: '2022-05-07T08:00:00',
    end: '2022-05-07T09:30:00',
    name: 'Falcons at Cougars',
    class: 'game-active',
    id: 'GAMES3185535',
  },
  {
    start: '2022-05-14T08:00:00',
    end: '2022-05-14T09:30:00',
    name: 'Leopards at Polar Bears',
    class: 'game-active',
    id: 'GAMES3185536',
  },
  {
    start: '2022-05-14T11:30:00',
    end: '2022-05-14T13:00:00',
    name: 'Falcons at Wolves',
    class: 'game-active',
    id: 'GAMES3185537',
  },
  {
    start: '2022-05-21T11:30:00',
    end: '2022-05-21T13:00:00',
    name: 'Falcons at Leopards',
    class: 'game-active',
    id: 'GAMES3185539',
  },
  {
    start: '2022-05-21T08:00:00',
    end: '2022-05-21T09:30:00',
    name: 'Cobras at Polar Bears',
    class: 'game-active',
    id: 'GAMES3185540',
  },
  {
    start: '2022-05-28T11:30:00',
    end: '2022-05-28T13:00:00',
    name: 'Cobras at Falcons',
    class: 'game-active',
    id: 'GAMES3185542',
  },
  {
    start: '2022-05-28T08:00:00',
    end: '2022-05-28T09:30:00',
    name: 'Wolves at Leopards',
    class: 'game-active',
    id: 'GAMES3185543',
  },
  {
    start: '2022-06-04T11:30:00',
    end: '2022-06-04T13:00:00',
    name: 'Wolves at Cobras',
    class: 'game-active',
    id: 'GAMES3185545',
  },
  {
    start: '2022-06-04T08:00:00',
    end: '2022-06-04T09:30:00',
    name: 'Polar Bears at Falcons',
    class: 'game-active',
    id: 'GAMES3185546',
  },
  {
    start: '2022-03-25T17:30:00',
    end: '2022-03-25T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429608',
    color: '#29B6F6',
    textColor: '#E1F5FE',
  },
  {
    start: '2022-04-01T17:30:00',
    end: '2022-04-01T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429609',
  },
  {
    start: '2022-04-08T17:30:00',
    end: '2022-04-08T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429610',
  },
  {
    start: '2022-04-15T17:30:00',
    end: '2022-04-15T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429611',
  },
  {
    start: '2022-04-22T17:30:00',
    end: '2022-04-22T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429612',
  },
  {
    start: '2022-04-29T17:30:00',
    end: '2022-04-29T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429613',
  },
  {
    start: '2022-05-06T17:30:00',
    end: '2022-05-06T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429614',
  },
  {
    start: '2022-05-13T17:30:00',
    end: '2022-05-13T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429615',
  },
  {
    start: '2022-05-20T17:30:00',
    end: '2022-05-20T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429616',
  },
  {
    start: '2022-05-27T17:30:00',
    end: '2022-05-27T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429617',
  },
  {
    start: '2022-06-03T17:30:00',
    end: '2022-06-03T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429618',
  },
  {
    start: '2022-06-10T17:30:00',
    end: '2022-06-10T19:00:00',
    name: 'Panthers Practice',
    class: 'practice-active',
    id: 'PRACTICES429619',
  },
]

export const testMatchups = [
  {
    id: '3185578',
    name: 'Wolves at Polar Bears',
    gameID: 3185578,
    end: '1901-01-01T05:00:00.000Z',
    start: '1901-01-01T05:00:00.000Z',
  },
  {
    id: '3185579',
    name: 'Cobras at Leopards',
    gameID: 3185579,
    end: '1901-01-01T05:00:00.000Z',
    start: '1901-01-01T05:00:00.000Z',
  },
  {
    id: '3185580',
    name: 'Cougars at Falcons',
    gameID: 3185580,
    end: '1901-01-01T05:00:00.000Z',
    start: '1901-01-01T05:00:00.000Z',
  },
]
