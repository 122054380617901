











































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'

import SelectInput from '@/elements/SelectInput.vue'

import CheckboxInput from '@/elements/CheckboxInput.vue'

import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import store from '@/store'
import { isCheer } from '@/lib/common/ProgramTypes'

export default defineComponent({
  name: 'BlankParticipantEvalForm',
  components: {
    SelectInput,
    CheckboxInput,
    ActiveReport,
  },
  setup(props, ctx) {
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)

    const selectedProgram = ref('')

    const hideCarpoolLinks = ref(false)
    const hidePracticeNightExclusion = ref(false)
    const schoolYear = ref('21-22') //hardcoded for now - TW
    const loadStatus = ref('')
    const loading = ref(false)

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    const programs = computed((): string[] => {
      if (isCamp.value) {
        return camp.value.programs?.map((p) => p.typeProgramID) ?? []
      }
      return league.value.programs?.map((p) => p.typeProgramID) ?? []
    })

    const hasCheer = computed((): boolean => {
      if (isCamp.value) {
        return false
      }

      return (league.value && league.value.programs?.some((p) => isCheer(p.typeProgramID))) ?? false
    })

    onMounted(() => {
      selectedProgram.value = typeProgramID.value
    })

    function reportLoaded() {
      loading.value = false
    }

    async function loadReport() {
      loading.value = true
      let leagueId = 0
      let typeProgramId = ''
      let createBlankReport = true

      if (isCamp.value) {
        leagueId = camp.value.id
        typeProgramId = selectedProgram.value
      } else {
        leagueId = league.value.id
        typeProgramId = selectedProgram.value
      }

      loadStatus.value = 'Downloading Form Data . . .'
      const data = await Promise.all([
        reportsClient.getEvaluationData(
          upwardLeagueId.value,
          accountNumber.value,
          createBlankReport,
          typeProgramId
        ),
        reportsClient.getEvaluationSizeData(
          upwardLeagueId.value,
          accountNumber.value,
          leagueId,
          typeProgramId,
          createBlankReport
        ),
        reportsClient.getEvaluationQuestionsData(upwardLeagueId.value, accountNumber.value, typeProgramId),
      ])

      loadStatus.value = 'Downloading Form Templates . . .'
      const reportTemplates = await Promise.all([
        reportsClient.getReportTemplate('blankevalform'),
        reportsClient.getReportTemplate('evalsizessubreport'),
        reportsClient.getReportTemplate('evalquestionssubreport'),
      ])

      loadStatus.value = 'Processing Form Templates . . .'
      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')
      const sizeSubReport = JSON.parse(reportTemplates[1]?.jsonContent ?? '')
      const questionSubReport = JSON.parse(reportTemplates[2]?.jsonContent ?? '')

      const mainData = data[0]?.slice(0, 1)

      const parameters = [
        {
          Value: [hidePracticeNightExclusion.value],
          Name: 'HidePracticeNights',
        },
        {
          Value: [hideCarpoolLinks.value],
          Name: 'HideCarpoolLinks',
        },
        {
          Value: [`${leagueId}`],
          Name: 'LeagueID',
        },
        {
          Value: [`${accountNumber.value}`],
          Name: 'AccountNumber',
        },
        {
          Value: [`${schoolYear.value}`],
          Name: 'SchoolYear',
        },
        {
          Value: [store.getters.leagueAbstraction.refLabel],
          Name: 'RefLabel',
        },
      ]

      report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)
      sizeSubReport.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(data[1])
      questionSubReport.DataSources[0].ConnectionProperties.ConnectString =
        'jsondata=' + JSON.stringify(data[2])

      const resourceLocator = {
        getResource: function (name: string) {
          if (name === 'eval-sizes-sub-report.rdlx-json') return sizeSubReport
          if (name === 'eval-questions-sub-report.rdlx-json') return questionSubReport
        },
      }

      loadStatus.value = 'Loading Form . . .'
      const activeReport: any = ctx.refs.activereport
      activeReport.loadReportData(report, { ReportParams: parameters, ResourceLocator: resourceLocator })
    }

    return {
      hasCheer,
      programs,
      selectedProgram,
      loading,
      hideCarpoolLinks,
      hidePracticeNightExclusion,
      loadStatus,
      loadReport,
      reportLoaded,
    }
  },
})
