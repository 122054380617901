


















import { defineComponent, PropType } from '@vue/composition-api'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'

export default defineComponent({
  name: 'PartnerList',
  props: {
    partners: { type: Array as PropType<AccountInfo[]>, required: true },
  },
  setup(props, ctx) {
    function selectPartner(partner: AccountInfo) {
      ctx.emit('selected', partner)
    }

    return { selectPartner }
  },
})
