
























import ParticipantListElement from '@/views/Programs/Divisions/TeamManagement/vues/ParticipantListElement.vue'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { Container, ContainerProps, Draggable, DropResult } from 'vue-smooth-dnd'
import { cloneDeep } from 'lodash'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
interface ParticipantOrderType {
  participant: LeaguePlayerInfo
  order: number
}

/***
 * Component hosts a sortable list of participants
 */
export default defineComponent({
  name: 'ParticipantList',
  components: { Container, Draggable, ParticipantListElement },
  props: { participants: { type: Array as PropType<LeaguePlayerInfo[]>, required: true }, loading: Boolean },
  setup(props, ctx) {
    const participantOrder = ref<ParticipantOrderType[]>([])
    const selectedPlayer = ref<LeaguePlayerInfo | null>(null)
    /**
     * our payload is the participant at the given index.
     */
    const getPayload = (index: number) => {
      return participantOrder.value.find((x) => x.order == index)
    }

    /**
     * this container accepts things that have participants
     */
    const accepting = (a: ContainerProps, info: Record<string, unknown>) => {
      return info && info.participant
    }
    /**
     * reorder players on the list
     */
    function onDrop(movedEvent: DropResult) {
      const f = getPayload(movedEvent.removedIndex)
      const g = getPayload(movedEvent.addedIndex)
      if (f && g) {
        const q = f.participant
        f.participant = g.participant
        g.participant = q
      }
    }

    /***
     * When we get new participants, then sort by last name and set the order.
     */
    watch(
      () => props.participants,
      () => {
        let order = 0
        const copyList = cloneDeep(props.participants)
        participantOrder.value = copyList.map((x) => ({
          order: order++,
          participant: x,
          originator: 'list',
        }))
      },
      { immediate: true }
    )

    function selectPlayer(player: LeaguePlayerInfo | null) {
      if (player) {
        selectedPlayer.value = player.individualID == selectedPlayer.value?.individualID ? null : player
      } else {
        selectedPlayer.value = null
      }
    }

    function clearSelectedPlayer() {
      selectedPlayer.value = null
    }

    watch(
      () => selectedPlayer.value,
      () => {
        ctx.emit('selectPlayer', selectedPlayer.value)
      }
    )

    return {
      onDrop,
      accepting,
      getPayload,
      participantOrder,
      selectedPlayer,
      selectPlayer,
      clearSelectedPlayer,
    }
  },
})
