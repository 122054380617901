import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { cloneDeep } from 'lodash'
import { PlayerContactTypeEnum } from '@/lib/support/models/PlayerContact/data'
export function getGenderFromRole(role: PlayerContactTypeEnum) {
  switch (role) {
    case PlayerContactTypeEnum.STEPMOTHER:
    case PlayerContactTypeEnum.MOTHER:
    case PlayerContactTypeEnum.GRANDMOTHER:
    case PlayerContactTypeEnum.AUNT:
      return 'F'
    case PlayerContactTypeEnum.FATHER:
    case PlayerContactTypeEnum.GRANDFATHER:
    case PlayerContactTypeEnum.UNCLE:
    case PlayerContactTypeEnum.STEPFATHER:
      return 'M'
    default:
      return 'M' // #2888 - default to male
  }
}
/**
 * Approximates a League Volunteer from a Player Contact, this should not be used without
 * the template functions to create a new volunteer, but it can feed some fields or serve as a placeholder
 * in the system.
 * @param p incoming player contact
 * @return LeagueVolunteer
 */
export function PlayerContactToLeagueVolunteer(p: PlayerContact): LeagueVolunteer {
  return {
    individualID: p.individualID,
    accountNumber: '',
    churchName: null,
    staff: false,
    thirdPartyClientIDs: [],
    typeSizeID: '',
    coachPreferences: { programs: [], practiceNights: [] },
    roles: [],
    gender: getGenderFromRole(p.typeRelationshipID as PlayerContactTypeEnum),
    birthDate: new Date(),
    leagueID: 0,
    firstName: p.firstName,
    lastName: p.lastName,
    addresses: p.addresses,
    phoneNumbers: p.phoneNumbers,
    middleInitial: p.middleInitial,
    emailAddresses: p.emailAddresses,
    optOutEmails: p.optOutEmails,
    optOutSMS: p.optOutSMS,
    inviteAllowed: p.inviteAllowed,
    registrationNote: null,
  }
}

/**
 * Copies league volunteer from existing into another league volunteer record if the target copyIntoVolunteer has empty fields.
 * @param existingLeagueVolunteer
 * @param copyIntoVolunteer
 * @constructor
 */
export function FillMinimumLVDetail(
  existingLeagueVolunteer: LeagueVolunteer | PlayerContact,
  copyIntoVolunteer: LeagueVolunteer
) {
  const c = cloneDeep(copyIntoVolunteer)
  if (!c.birthDate) {
    c.birthDate = new Date()
  }
  if (!c.gender) {
    c.gender = 'M'
  }
  c.lastName = c.lastName || existingLeagueVolunteer?.lastName || 'new'
  c.firstName = c.firstName || existingLeagueVolunteer?.firstName || 'volunteer'
  if (c.addresses?.length == 0 || !c.addresses![0].typeAddressID) {
    c.addresses = existingLeagueVolunteer?.addresses ?? []
  }
  if (c.emailAddresses?.length == 0 || !c.emailAddresses![0].typeEmailID) {
    c.emailAddresses = existingLeagueVolunteer?.emailAddresses ?? []
  }
  if (c.phoneNumbers?.length == 0 || !c.phoneNumbers![0].typePhoneID) {
    c.phoneNumbers = existingLeagueVolunteer?.phoneNumbers ?? []
  }
  if (c.thirdPartyClientIDs?.length == 0 || !c.thirdPartyClientIDs![0].typeThirdPartyClientID) {
    c.thirdPartyClientIDs = []
  }
  return c
}
