export interface EvaluationFormOption {
  id: string
  title: string
  description: string // accepts html
}

const league: EvaluationFormOption = {
  id: 'league',
  title: 'Evaluation Information For Parents',
  description: `<p>
  The information you enter below will serve as the default text for your promo cards to advertise
  your league. If you enable online registration, this text will also appear in the registration
  confirmation emails.
</p>
<p>
  Information you enter should include the next steps for your league, such as evaluation dates,
  times, locations, and grade levels evaluated.
</p>`,
}

const camp: EvaluationFormOption = {
  id: 'camp',
  title: 'Camp Schedule Information For Parents',
  description: `<p>
  The information you enter below will serve as the default text for your promo cards to advertise
  your camp. If you enable online registration, this text will also appear at the top of the registration 
  form and on the confirmation email after registration.
</p>`,
}

export const EvaluationFormOptions = new Map([
  ['league', league],
  ['camp', camp],
])
