import restService from '@/services/restService'
import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'
import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'
import { ProductOfferingStartupProducts } from '@/models/ProductOfferingConfig/ProductOfferingStartupProducts'

const baseUrl = 'productOffering/'

const noProductGroupError = 'CommandDefaultProductGroup.DataPortal_Execute method call failed'
const retrieveTopLevelProducts = async (
  accountNumber: string,
  leagueType: string,
  firstPracticeDate: null | Date
) => {
  let dt: string | null = null
  dt = convertToLocalDateString(firstPracticeDate)
  if (!dt) throw new Error('productOfferingClient.retrieveTopLevelProducts: missing firstPracticeDate')
  try {
    const results = await restService.get<ProductOfferingStartupProducts>(
      `${baseUrl}${accountNumber}/${leagueType}/${dt}`
    )
    return results.data
  } catch (err: any) {
    if (err && err.errorObject) {
      if (err.errorObject.message.includes(noProductGroupError))
        err.errorObject.message =
          'The dates you selected are too far in the future to create a league at this time.'
    }
    throw err
  }
}

const retrieveChildOfferings = async (id: number) => {
  const results = await restService.get<ProductOfferingInfo>(`${baseUrl}${id}`)

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in productOfferingClient.retrieveChildOfferings')
  }
}

const retrieveProductOfferingConfigList = async (accountNumber: string, leagueType: string) => {
  const results = await restService.get<SavedProductOfferingConfig[]>(
    `${baseUrl}savedConfigs/${accountNumber}/${leagueType}`
  )

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in productOfferingClient.retrieveProductOfferingConfigList')
  }
}

const retrieveProductOfferingConfig = async (id: string) => {
  const results = await restService.get<SavedProductOfferingConfig>(`${baseUrl}savedConfig/${id}`)

  if (results.isSuccess) {
    return results.data
  } else {
    throw new Error('Error in productOfferingClient.retrieveProductOfferingConfig')
  }
}

const upsertOffering = async (offeringConfig: SavedProductOfferingConfig) => {
  const url = `${baseUrl}savedConfig`
  //@ts-ignore
  offeringConfig.firstPracticeDateEstimate = convertToLocalDateString(
    offeringConfig.firstPracticeDateEstimate
  )

  if (offeringConfig.id) {
    return await restService.put<SavedProductOfferingConfig>(`${url}/${offeringConfig.id}`, offeringConfig)
  } else {
    return await restService.post<SavedProductOfferingConfig>(url, offeringConfig)
  }
}

function convertToLocalDateString(dt: string | Date | null): string | null {
  if (dt) {
    if (dt instanceof Date) {
      dt = dt
    } else {
      dt = new Date(dt)
    }
    const month = dt.getMonth() + 1
    const monthStr = month < 10 ? `0${month}` : month
    const day = dt.getDate()
    const dayStr = day < 10 ? `0${day}` : day
    const dateStr = `${dt.getFullYear()}-${monthStr}-${dayStr}`
    return dateStr
  } else {
    return null
  }
}

export default {
  retrieveTopLevelProducts,
  retrieveChildOfferings,
  retrieveProductOfferingConfig,
  retrieveProductOfferingConfigList,
  upsertOffering,
}
