


































import { defineComponent, PropType, computed } from '@vue/composition-api'

import CheckboxGroupInput from '@/elements/CheckboxGroupInput.vue'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import SelectInput from '@/elements/SelectInput.vue'

export default defineComponent({
  name: 'CalendarOptions',
  components: { SelectInput, CheckboxGroupInput },
  props: {
    daysToShow: { type: Array as PropType<number[]>, required: false, default: () => [0, 1, 2, 3, 4, 5, 6] },
    startTime: { type: String, required: false, default: '08:00' }, //must be HH:mm
    endTime: { type: String, required: false, default: '22:00' }, //must be HH:mm
    showTime: { type: Boolean, required: false, default: true },
  },
  setup(props, ctx) {
    const startTimes = Array.from(Array(16)).map((x, i) => {
      const time = i + 7
      return {
        value: `${time.toString().padStart(2, '0')}:00`,
        label: time % 12 == 0 ? (time < 12 ? 'midnight' : 'noon') : `${time % 12} ${time < 12 ? 'AM' : 'PM'}`,
      }
    })

    const endTimes = computed(() => {
      return startTimes.filter((y: any) => y.value > lStartTime.value ?? '00:00')
    })

    //local daysToShow
    const lDaysToShow = computed({
      get: () => props.daysToShow.map((d) => d.toString()).sort((a, b) => (a > b ? 1 : -1)),
      set: (value) =>
        ctx.emit(
          'update:daysToShow',
          value.map((v) => Number(v)).sort((a, b) => (a > b ? 1 : -1))
        ),
    })

    //local start time
    const lStartTime = computed({
      get: () => props.startTime,
      set: (value) => ctx.emit('update:startTime', value),
    })

    //local end time
    const lEndTime = computed({
      get: () => props.endTime,
      set: (value) => ctx.emit('update:endTime', value),
    })

    return { days, startTimes, endTimes, lDaysToShow, lStartTime, lEndTime }
  },
})
