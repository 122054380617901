/**
 * Rest Operation primarily for fixing mistyped email addresses
 */

import { IndividualEmailCheck } from '@/models/IndividualEmailCheck'
import axios, { AxiosRequestConfig } from 'axios'

const baseUrl =
  process.env.VUE_APP_FIXEMAILSERVICE_API || `https://fixemailservices.azurewebsites.net/api/FixEmail`

const azureKey = process.env.VUE_APP_AZURE_FUNCTION_KEY

const getCorrectedEmail = async (emailAddress: IndividualEmailCheck) => {
  const response = await axios.post<string>(`${baseUrl}`, emailAddress, {
    headers: {
      'x-functions-key': azureKey,
    },
  } as AxiosRequestConfig)

  if (response) {
    return response.data
  } else {
    return emailAddress.email
  }
}

export default {
  getCorrectedEmail,
}
