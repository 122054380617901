















































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import { useClinicSetup } from '@/composables/ClinicSetup'

import { ContactToContactInfo, ContactInfoToContact, GetEmptyContactInfo } from '@/services/contactService'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'

import ContactPickerAndEditor from '@/components/ContactPickerAndEditor.vue'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'
import { cloneDeep } from 'lodash'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import SetupCompleteConfirmation from '@/views/Clinics/vues/SetupCompleteConfirmation.vue'
import store from '@/store'

export default defineComponent({
  name: 'Leadership',
  components: {
    ContactPickerAndEditor,
    SaveContinueButton,
    SetupCompleteConfirmation,
  },
  setup(props, ctx) {
    const {
      clinic,
      storeClinic,
      loading,
      saveAndContinue,
      beforeRouteLeave,
      afterSaveNew,
      afterUpdateExisting,
      initialLoadDone,
      save,
    } = useClinicSetup(ctx)

    const accountNumbers = computed(() => store.getters.authorization.accountNumbers)
    const sportContact = ref<ContactInfo>(GetEmptyContactInfo())
    const newId = ref('')
    const modalIsVisible = ref(false)

    onMounted(() => {
      afterSaveNew.value = afterSaveNewMethod
      afterUpdateExisting.value = afterUpdateExistingMethod
    })

    watch(
      () => initialLoadDone.value,
      () => {
        if (initialLoadDone.value && clinic.value) {
          sportContact.value = ContactToContactInfo(
            clinic.value.partnerContact!,
            clinic.value.leagueDirectorPartnerID
          )
        }
      },
      { immediate: true }
    )

    const canChangeName = computed(() => !(clinic.value && clinic.value!.leagueDirectorPartnerID))

    async function onSubmit() {
      clinic.value.partnerContact = sportContact.value
        ? ContactInfoToContact(sportContact.value)
        : getEmptyContact()
      clinic.value.leagueDirectorPartnerID = sportContact.value ? sportContact.value.id : 0

      afterSaveNew.value = null

      await save()
      if (clinic.value.partnerContact) {
        sportContact.value = ContactToContactInfo(
          clinic.value!.partnerContact!,
          clinic.value!.leagueDirectorPartnerID
        )
      }
      afterSaveNew.value = afterSaveNewMethod
    }

    const isClean = computed(
      () => storeClinic.value.leagueDirectorPartnerID === clinic.value.leagueDirectorPartnerID
    )

    function afterUpdateExistingMethod() {
      clinic.value = { ...cloneDeep(storeClinic.value) }
      ctx.root.$router.push(`/programs/clinic/${clinic.value.upwardLeagueID}/setup/information`)
    }

    async function onModalStateChange() {
      await ctx.root.$router.push('/')
    }

    async function afterSaveNewMethod() {
      const result = await store.dispatch.clinics.create({ item: clinic.value })

      if (result) {
        clinic.value = { ...result }
        newId.value = clinic.value.upwardLeagueID ?? ''
      }
      modalIsVisible.value = true
    }

    return {
      saveAndContinue,
      clinic,
      loading,
      initialLoadDone,
      beforeRouteLeave,
      accountNumbers,
      sportContact,
      onSubmit,
      canChangeName,
      isClean,
      modalIsVisible,
      onModalStateChange,
    }
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeave(to, from, next)
  },
})
