


































import { defineComponent } from '@vue/composition-api'
import FullBody from '@/components/FullBody.vue'
import ProgramCreationPicker from '@/components/ProgramCreationPicker.vue'
import PreviousEstimates from '@/components/PreviousEstimates.vue'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  components: {
    FullBody,
    ProgramCreationPicker,
    Loading,
    PreviousEstimates,
  },
  name: 'Welcome',
})
