import { RouteConfig } from 'vue-router'

import { idExpression } from '@/lib/support/router/idHelper'

const ParticipantsView = () => import('@/views/Programs/Participants/ParticipantsView.vue')
const ParticipantsIndex = () => import('@/views/Programs/Participants/ParticipantsIndex.vue')
const ParticipantEdit = () => import('@/views/Programs/Participants/ParticipantEdit.vue')
const ParticipantsBatchEdit = () => import('@/views/Programs/Participants/ParticipantsBatchEdit.vue')

export default function (programType: string) {
  programType = programType ?? 'league'
  return [
    {
      path: 'participants',
      component: ParticipantsIndex,
      children: [
        {
          path: '/',
          redirect: `/programs/${programType}/${idExpression}/participants/list`,
        },
        {
          path: 'list',
          component: ParticipantsView,
          name: `list-participant-${programType}`,
        },
        {
          path: 'import',
          component: ParticipantsView,
          name: `import-participant-${programType}`,
        },
        {
          path: 'transfer',
          component: ParticipantsView,
          name: `transfer-participant-${programType}`,
        },
        {
          path: 'edit/:pid',
          component: ParticipantEdit,
          name: `edit-participant-${programType}`,
        },
        {
          path: 'new',
          component: ParticipantEdit,
          name: `new-participant-${programType}`,
        },
        {
          path: 'former',
          component: ParticipantsView,
          name: `former-participant-${programType}`,
        },
        {
          path: 'search',
          component: ParticipantsView,
          name: `search-participant-${programType}`,
        },
        {
          path: 'copy/:pid/:old_league',
          component: ParticipantEdit,
          name: `copy-participant-${programType}`,
        },
        {
          path: 'delete/:pid',
          component: ParticipantsView,
          name: `delete-participant-${programType}`,
        },
        {
          path: 'batch',
          component: ParticipantsBatchEdit,
        },
      ],
    },
  ] as RouteConfig[]
}
