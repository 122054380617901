import { RouteConfig } from 'vue-router'
import DivisionsAndTeams from '@/views/Programs/Divisions/DivisionsAndTeams.vue'
import Divisions from '@/views/Programs/Divisions/Divisions.vue'
import Participants from '@/views/Programs/Divisions/Participants.vue'
import Coaches from '@/views/Programs/Divisions/Coaches.vue'
import Teams from '@/views/Programs/Divisions/Teams.vue'
import Practices from '@/views/Programs/Divisions/Practices.vue'
import TeamManagement from '@/views/Programs/Divisions/TeamManagement.vue'
import StandingsAndScores from '@/views/Programs/Divisions/StandingsAndScores.vue'
import { idExpression } from '@/lib/support/router/idHelper'

export default function (programType: string) {
  programType = programType ?? 'league'
  return [
    {
      path: 'divisions_and_teams',
      component: DivisionsAndTeams,
      children: [
        {
          path: '/',
          redirect: `/programs/${programType}/${idExpression}/divisions_and_teams/divisions`,
        },
        {
          path: 'divisions',
          component: Divisions,
        },
        {
          path: 'teams',
          component: Teams,
        },
        {
          path: 'coaches',
          component: Coaches,
        },
        {
          path: 'practices',
          component: Practices,
        },
        {
          path: 'participants',
          component: Participants,
        },
        {
          path: 'team-management',
          component: TeamManagement,
        },
        {
          path: 'standings-scores',
          component: StandingsAndScores,
        },
      ],
    },
  ] as RouteConfig[]
}
