import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { computed, ref } from '@vue/composition-api'
import participantsClient from '@/clients/participantsClient'

export function useParticipants() {
  const participants = ref<LeaguePlayerInfo[]>([])
  const loading = ref(false)
  async function refreshParticipants(leagueID: string, programID: string) {
    loading.value = true
    participants.value = (await participantsClient.retrieve(leagueID, programID)) ?? []
    loading.value = false
  }

  return {
    refreshParticipants,
    loading: computed(() => loading.value),
    participants,
  }
}
