










import { defineComponent, ref } from '@vue/composition-api'
import FullBody from '@/components/FullBody.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'

export default defineComponent({
  name: 'Index',
  components: {
    FullBody,
    HorizontalTabs,
  },
  setup() {
    const tabs = ref([
      { text: 'Info', to: '/myprofile/info' },
      { text: 'Password', to: '/myprofile/password' },
      { text: 'Two Factor Authentication', to: '/myprofile/2FA' },
    ])

    return {
      tabs,
    }
  },
})
