


















































import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn, GridColumnAlignEnum } from '@/models/Grid/GridColumn'
import currency from '@/filters/currency'
import { computed, defineComponent, PropType, ref, onMounted, ComputedRef } from '@vue/composition-api'
import { CFormatOrder, OrderLineExt } from '@/models/Order/xStartupOrderExt'
import { programTypes } from '@/store/programTypes'

export default defineComponent({
  components: {
    SimpleGrid,
  },
  props: {
    order: {
      type: Object as PropType<CFormatOrder>,

      required: true,
    },
  },
  setup(props) {
    const divisionPanelsValue = ref<Number[]>([])

    const columns = computed(() => {
      return [
        { field: 'name', headerText: 'Name' },
        { field: 'productLabel', headerText: 'Item Name' },
        { field: 'type', headerText: 'Type' },
        { field: 'size', headerText: 'Size' },
        { field: 'orderQuantity', headerText: 'Quantity' },
        { field: 'cost', headerText: 'Cost', textAlign: GridColumnAlignEnum.RIGHT },
        { field: 'program', headerText: 'Program' },
      ] as GridColumn[]
    })

    function getProductLabel(x: OrderLineExt) {
      if (x.cmsProductName) {
        return x.cmsProductName
      }
      if (props.order?.products) {
        const prod = props.order?.products?.find((p) => p.upwardProductID == x.productID)
        if (prod && prod.cmsProductName) {
          return prod.cmsProductName
        }
      }
      if (x.productID) {
        return x.productID
      }
      return 'n/a'
    }

    function supressNA(s: string) {
      if (s == 'N/A' || s == 'Not Applicable' || s == 'One Size') {
        return ''
      }
      return s
    }

    function getCost(cost: number) {
      return currency(cost)
    }

    interface OrderListItem {
      productLabel: string
      program: string | null
      type: string
      size: string
      cost: string | null | undefined
      orderQuantity: number
      name: string
    }

    interface TeamListItem {
      teamName: string
      orderLines: OrderListItem[]
    }

    interface DivisionListItem {
      divisionName: string
      divsionItemCount: number
      teams: TeamListItem[]
      panelValue: Number[]
      expandDisabled: ComputedRef
      collapseDisabled: ComputedRef
    }

    const divisionList = ref<DivisionListItem[]>([])

    onMounted(() => {
      let retval = [] as DivisionListItem[]

      const sortedLines = props.order.orderLinesExt.sort((a, b) => {
        const divSortNameA = a.cargo?.participantDivisionName || 'zzzzzzzzzzzzzzzzzz'
        const divSortNameB = b.cargo?.participantDivisionName || 'zzzzzzzzzzzzzzzzzz'

        const teamSortNameA = a.cargo?.participantTeamName || 'zzzzzzzzzzzzzzzzzz'
        const teamSortNameB = b.cargo?.participantTeamName || 'zzzzzzzzzzzzzzzzzz'

        const playerSortNameA = a.participantName || a.cargo?.participantName || 'zzzzzzzzzzzzzzzzzz'
        const playerSortNameB = b.participantName || b.cargo?.participantName || 'zzzzzzzzzzzzzzzzzz'

        if (divSortNameA < divSortNameB) return -1
        if (divSortNameA > divSortNameB) return 1
        if (teamSortNameA < teamSortNameB) return -1
        if (teamSortNameA > teamSortNameB) return 1
        if (playerSortNameA < playerSortNameB) return -1
        if (playerSortNameA > playerSortNameB) return 1
        return 0
      })

      sortedLines.forEach((ordLine) => {
        //remove lines with no quantity as well as system and access fees
        if (
          ordLine.quantity &&
          ordLine.productID != 'UPWARDACCESSFEE' &&
          ordLine.productID != 'UPWARDSYSTEMSFEE'
        ) {
          const divName = ordLine.cargo?.participantDivisionName || 'Unassigned'
          const teamName = ordLine.cargo?.participantTeamName || 'Unassigned'
          const ordLineDetail = {
            productLabel: getProductLabel(ordLine),
            program:
              programTypes.state.items.find((x) => x.upwardTypeID == ordLine.typeProgramID)?.description ??
              ordLine.typeProgramID,
            type: supressNA(ordLine.colorDescription || ordLine.typeColorID || ''),
            size: supressNA(ordLine.sizeDescription || ordLine.typeSizeID || ''),
            cost: currency(ordLine.extendedCost),
            orderQuantity: ordLine.quantity,
            name: ordLine.participantName || ordLine.cargo?.participantName || 'Miscellaneous',
          } as OrderListItem

          let div = retval.find((x) => x.divisionName == divName)
          if (div) {
            div.divsionItemCount++
            let team = div.teams.find((x) => x.teamName == teamName)
            if (team) {
              //suppress repeating values
              if (team.orderLines.find((x) => x.name == ordLineDetail.name)) {
                ordLineDetail.name = ''
              }
              team.orderLines.push(ordLineDetail)
            } else {
              team = { teamName: teamName, orderLines: [ordLineDetail] }
              div.teams.push(team)
            }
          } else {
            div = {
              divisionName: divName,
              divsionItemCount: 1,
              teams: [{ teamName: teamName, orderLines: [ordLineDetail] }],
              panelValue: [],
              expandDisabled: computed(() => div?.panelValue.length === div?.teams.length),
              collapseDisabled: computed(() => div?.panelValue.length === 0),
            }
            retval.push(div)
          }
        }
      })

      divisionList.value = retval
    })

    function expandAllDivision(div: DivisionListItem, index: Number) {
      for (let i = 0; i < div.teams.length; i++) {
        if (div.panelValue.indexOf(i) === -1) {
          div.panelValue.push(i)
        }
      }
      if (divisionPanelsValue.value.indexOf(index) === -1) {
        divisionPanelsValue.value.push(index)
      }
    }

    function collapseAllDivision(div: DivisionListItem) {
      //div.panelValue = [] //this does not work.  use splice below
      div.panelValue.splice(0, div.teams.length)
    }

    function divPanelChange(div: DivisionListItem, index: Number) {
      if (divisionPanelsValue.value.indexOf(index) > -1) {
        //div is being collapsed
        collapseAllDivision(div)
      }
    }

    return {
      getCost,
      columns,
      divisionList,
      divisionPanelsValue,
      expandAllDivision,
      collapseAllDivision,
      divPanelChange,
    }
  },
})
