


























import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import * as authorizationStore from '@/store/authorization'

import PageContent from '@/components/PageContent.vue'
import TextInput from '@/elements/TextInput.vue'

import partnerClient from '@/clients/partnersClient'

import { UpwardPartnerSocialMediaTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerSocialMediaTypeID'
import { SocialMedia } from '@/GeneratedTypes/Partner/SocialMedia'

@Component({
  components: { PageContent, TextInput },
})
export default class PartnerSocialMedia extends Vue {
  @Getter(authorizationStore.getterNames.firstAccountNumber, { namespace: authorizationStore.namespace })
  private accountNumber!: string

  private socialMediaAccountTypes: UpwardPartnerSocialMediaTypeID[] = []
  private socialMediaData: SocialMedia[] = []
  private scores: Record<string, string> = {}
  private loaded = false

  private socialMediaUrl(typeSocialMediaID: string): string {
    return this.socialMediaData.find((d) => d.typeSocialMediaID === typeSocialMediaID)?.url ?? ''
  }

  async mounted() {
    this.socialMediaAccountTypes = (await partnerClient.retreivePartnerSocialMediaTypes()) ?? []
    this.socialMediaData = (await partnerClient.retreivePartnerSocialMediaData(this.accountNumber)) ?? []
    this.socialMediaAccountTypes.forEach((item) => {
      this.scores[item.upwardTypeID!] = ''
    })
    this.socialMediaData.forEach((item) => {
      this.scores[item.typeSocialMediaID] = item.url ?? ''
    })
    this.loaded = true
  }

  async save() {
    const socialMediaDataToSave: SocialMedia[] = []

    this.socialMediaAccountTypes.forEach((item) => {
      const existingData = this.socialMediaData.find((d) => d.typeSocialMediaID === item.upwardTypeID!)
      const data: SocialMedia = {
        id: existingData?.id || 0,
        accountNumber: this.accountNumber,
        typeSocialMediaID: item.upwardTypeID!,
        url: this.scores[item.upwardTypeID!] || '',
      }

      if (data.url?.trim()) {
        socialMediaDataToSave.push(data)
      }
    })

    await partnerClient.updatePartnerSocialMediaData(this.accountNumber, socialMediaDataToSave)
    this.socialMediaData = (await partnerClient.retreivePartnerSocialMediaData(this.accountNumber)) ?? []
  }
}
