import { defineMutations, defineGetters, defineActions } from 'direct-vuex'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardProgramTypeID>(
  upwardTypesClient.retrieveProgramTypes
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardProgramTypeID> = {
  items: [],
}

const getterTree = defineGetters<genericUpwardTypes.GenericTypeState<UpwardProgramTypeID>>()({
  ...generic.getterTree,
})

const mutations = defineMutations<genericUpwardTypes.GenericTypeState<UpwardProgramTypeID>>()({
  ...generic.mutations,
})

const actions = defineActions({
  ...generic.actions,
})

export const getterNames = {
  ...genericUpwardTypes.getterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

export const namespace = 'programTypes'

export const programTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
