import { Commit } from 'vuex'
import volunteersClient from '@/clients/volunteersClient'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { RolesEnum, VolunteerIDType } from '@/lib/support/models/LeagueVolunteer/data'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import { VolunteerID } from '@/lib/support/models/LeagueVolunteer/data'
import {
  leagueVolunteerToLeagueVolunteerInfo,
  leagueVolunteerToLeagueVolunteerInfoMerge,
} from '@/lib/support/models/LeagueVolunteer/leagueVolunteerInfoToLeagueVolunteer'
import { LeagueVolunteerRoleInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerRoleInfo'
import dayjs from 'dayjs'
import { InviteContact } from '@/models/VolunteerManagement/Invitations/InviteContact'
import inviteClient from '@/clients/inviteClient'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { defineActions, defineMutations, defineGetters } from 'direct-vuex'
import { moduleActionContext } from '@/store/index'
import { cloneDeep } from 'lodash'
import { DataTablePagination, getEmptyDataTablePagination } from '@/models/DataTable/DataTableSelection'

/***
 * Builds the store for volunteers.
 * LeagueVolunteerInfo is the query model
 * LeagueVolunteer is the commit model
 *
 */

const NEW_VOLUNTEER = -1
const NO_ACTIVE_VOLUNTEER = -2
class VolunteerRoleException extends GeneralError {
  name = 'Volunteer Role Exception'
}

class VolunteerException extends GeneralError {
  name = 'VolunteerException'
}
class VolunteerRuntimeException extends RuntimeException {
  name = 'Volunteer Runtime Exception'
}

function throwError(message: string, commit?: Commit, error?: Error) {
  commit && commit(mutationNames.setError, { message, error })
  throw new VolunteerException(message, error)
}

enum VolunteerRetrieveEnum {
  edit,
  nothing,
}
interface VolunteerState {
  currentItem?: LeagueVolunteerInfo
  volunteers: LeagueVolunteerInfo[]
  lastLeagueLoaded: string
  lastError: string
  lastException: Error | null
  volunteerUnderEdit: LeagueVolunteer | null
  volunteerUnderEditId: VolunteerIDType
  currentProgram: string
  operation: VolunteerRetrieveEnum
  loading: boolean
  status: string
  referees: LeagueVolunteerInfo[]
  pagination: DataTablePagination
}

const volunteerState: VolunteerState = {
  currentItem: undefined,
  volunteers: [],
  lastLeagueLoaded: '',
  lastError: '',
  lastException: null,
  volunteerUnderEdit: null, //initial state null.
  volunteerUnderEditId: NO_ACTIVE_VOLUNTEER,
  currentProgram: '',
  operation: VolunteerRetrieveEnum.nothing,
  loading: false,
  status: '',
  referees: [],
  pagination: getEmptyDataTablePagination(),
}

export interface LoadProgramVolunteersArgs {
  leagueId: string
  shouldForce?: boolean
  typeProgramId?: string
}

export interface LeanVolunteersByProgramRoleArgs {
  leagueId: string
  shouldForce?: boolean
  typeProgramId: string
  roleIDs: number[] | null
}

export interface SingleVolunteerArgs {
  volunteer: LeagueVolunteerInfo
}

export interface SingleEditVolunteerArgs {
  volunteer: LeagueVolunteer
}

export enum getterNames {
  currentItem = 'currentItem',
  volunteers = 'volunteers',
  volunteerUnderEdit = 'volunteerUnderEdit',
  currentProgram = 'currentProgram',
  loading = 'loading',
  status = 'status',
  referees = 'referees',
  pagination = 'pagination',
}

const getterTree = defineGetters<VolunteerState>()({
  currentItem: (state) => state.currentItem,
  volunteers: (state) => state.volunteers,
  volunteerUnderEdit: (state) => state.volunteerUnderEdit,
  currentProgram: (state) => state.currentProgram,
  loading: (state) => state.loading,
  status: (state) => state.status,
  referees: (state) => state.referees,
  pagination: (state) => state.pagination,
})

export enum mutationNames {
  setCurrent = 'setCurrent',
  setUpdatedVolunteer = 'setUpdatedVolunteer',
  setUpdatedAndMergeVolunteer = 'setUpdatedAndMergeVolunteer',
  setVolunteers = 'setVolunteers',
  setError = 'setError',
  clearVolunteerUnderEdit = 'clearVolunteerUnderEdit',
  setLastLeagueLoaded = 'setLastLeagueLoaded',
  setVolunteerUnderEdit = 'setVolunteerUnderEdit',
  setVolunteerUnderEditId = 'setVolunteerUnderEditId',
  setCurrentProgram = 'setCurrentProgram',
  setOperation = 'setOperation',
  setAllApproved = 'setAllApproved',
  removeVolunteer = 'removeVolunteer',
  setAllInvited = 'setAllInvited',
  setLoading = 'setLoading',
  clearCurrent = 'clearCurrent',
  forceVolunteerListReload = 'forceVolunteerListReload',
  setStatus = 'setStatus',
  setReferees = 'setReferees',
  setPagination = 'setPagination,',
}

function changeRole(
  x1: LeagueVolunteerRoleInfo,
  role: RolesEnum,
  approved: boolean
): LeagueVolunteerRoleInfo {
  return {
    ...x1,
    approved: (x1.approved = x1.roleID == role ? approved : x1.approved),
  }
}

/**
 * Returns next year as "YYYY-MM-DD"
 */
function nextyear() {
  return dayjs().add(1, 'year').format('YYYY-MM-DD')
}

const mutations = defineMutations<VolunteerState>()({
  /***
   * Optimistically sets a batch invite.
   * @param state
   * @param role
   * @param ids
   */
  setAllInvited(state: VolunteerState, { ids }: { role: RolesEnum; ids: InviteContact[] }) {
    // role can be accessed but isn't used here. batch invite seems to be flat at the volunterinfo level
    state.volunteers = state.volunteers.map((x) => ({
      ...x,
      //proxy for field that will be added.
      inviteExpireDate: ids.findIndex((i) => i.id == x.individualID) >= 0 ? nextyear() : x.inviteExpireDate,
    }))
  },
  setAllApproved(
    state: VolunteerState,
    { ids, role, approved }: { ids: VolunteerIDType[]; approved: boolean; role: RolesEnum }
  ) {
    state.volunteers = state.volunteers.map((x) => ({
      ...x,
      roles:
        x?.roles?.map((x1) =>
          ids.indexOf(x.individualID) >= 0 ? changeRole(x1, role, approved) : { ...x1 }
        ) || [],
    }))
  },
  setLoading(state: VolunteerState, loading: boolean) {
    state.loading = loading
  },
  setStatus(state: VolunteerState, status: string) {
    state.status = status
  },
  setReferees(state: VolunteerState, referees: LeagueVolunteerInfo[]) {
    state.referees = referees
  },
  removeVolunteer(state: VolunteerState, { id }: { id: VolunteerIDType }) {
    if (state.volunteerUnderEdit?.individualID == id) {
      state.volunteerUnderEditId = NO_ACTIVE_VOLUNTEER
      state.volunteerUnderEdit = null
    }

    state.volunteers = state.volunteers.filter((x) => x.individualID != id)
  },

  setOperation(state: VolunteerState, status: VolunteerRetrieveEnum) {
    state.operation = status
  },
  setCurrent(state: VolunteerState, { volunteer }: SingleVolunteerArgs) {
    if (volunteer === undefined) {
      throw new VolunteerRuntimeException('volunteer undefined, check arguments.')
    }
    state.currentItem = volunteer
  },
  clearCurrent(state: VolunteerState) {
    state.currentItem = undefined
  },

  setVolunteers(state: VolunteerState, { items }: { items: LeagueVolunteerInfo[] }) {
    if (items === undefined) {
      throw new VolunteerRuntimeException('items undefined, check arguments.')
    }
    state.volunteers = items
  },
  setError(state: VolunteerState, { error, exception }: { error: string; exception?: Error }) {
    if (error === undefined) {
      throw new VolunteerRuntimeException('Error undefined, check arguments.')
    }
    //@todo - propagate to general error -
    state.lastError = error
    state.lastException = exception || null
    console.warn('volunteer store', error, exception)
  },
  forceVolunteerListReload(state: VolunteerState) {
    state.lastLeagueLoaded = Math.random().toString()
  },
  setLastLeagueLoaded(state: VolunteerState, { leagueID }: { leagueID: string }) {
    if (leagueID == undefined) {
      throw new VolunteerRuntimeException('Error undefined, check arguments.')
    }
    state.lastLeagueLoaded = leagueID
  },
  setVolunteerUnderEdit(state: VolunteerState, { volunteer }: SingleEditVolunteerArgs) {
    if (volunteer === undefined) {
      throw new VolunteerRuntimeException(
        'Error undefined volunteer argument in setVolunteerUnderEdit, check arguments.'
      )
    }
    state.volunteerUnderEdit = volunteer
  },
  clearVolunteerUnderEdit(state: VolunteerState) {
    state.volunteerUnderEdit = null
    state.volunteerUnderEditId = NO_ACTIVE_VOLUNTEER
  },
  setVolunteerUnderEditId(state: VolunteerState, { id }: { id: VolunteerIDType }) {
    state.volunteerUnderEditId = id
  },

  setCurrentProgram(state: VolunteerState, { program }: { program: string }) {
    state.currentProgram = program
  },
  setUpdatedVolunteer(state: VolunteerState, { volunteer }: SingleVolunteerArgs) {
    if (volunteer === undefined) {
      throw new VolunteerRuntimeException('volunteer undefined, check arguments.')
    }
    const idx = state.volunteers.findIndex((x) => x.individualID == volunteer.individualID)
    if (idx >= 0) {
      state.volunteers.splice(idx, 1, volunteer)
    } else {
      state.volunteers.push(volunteer)
    }
  },
  setUpdatedAndMergeVolunteer(state: VolunteerState, { volunteer }: { volunteer: LeagueVolunteer }) {
    if (volunteer === undefined) {
      throw new VolunteerRuntimeException('volunteer undefined, check arguments.')
    }
    const idx = state.volunteers.findIndex((x) => x.individualID == volunteer.individualID)
    const existingVolunteer = cloneDeep(state.volunteers[idx])
    if (idx >= 0) {
      const merged = leagueVolunteerToLeagueVolunteerInfoMerge(volunteer, existingVolunteer)
      state.volunteers.splice(idx, 1, merged)
    } else {
      state.volunteers.push(leagueVolunteerToLeagueVolunteerInfo(volunteer))
    }
  },
  setPagination(state, { pagination }: { pagination: DataTablePagination }) {
    state.pagination = pagination
  },
})

export enum actionNames {
  removeVolunteer = 'removeVolunteer',
  loadProgramVolunteers = 'loadProgramVolunteers',
  setCurrentVolunteerByID = 'setCurrentVolunteerByID',
  getNewTemplate = 'getNewTemplate',
  getVolunteer = 'getVolunteer',
  upsertVolunteer = 'upsertVolunteer',
  transferVolunteers = 'transferVolunteers',
  setAllApproved = 'setAllApproved',
  setAllInvited = 'setAllInvited',
  fetchReferees = 'fetchReferees',
}

export interface InviteAllParams {
  ids: InviteContact[]

  leagueID: string
}

export interface LeagueRoleParams {
  ids: VolunteerIDType[]
  leagueID: string
  role: RolesEnum
  approved: boolean
}

export interface VolunteerDeleteArgs {
  upwardLeagueID: UpwardLeagueIDType
  id: VolunteerIDType
  roles: number[]
}
const actions = defineActions({
  async setAllInvited({ commit }, { ids, leagueID }: InviteAllParams) {
    commit(mutationNames.setLoading, true)
    await inviteClient.inviteVolunteers({ leagueID, invitations: ids })
    commit(mutationNames.setLoading, false)

    commit(mutationNames.setAllInvited, { ids })
  },

  async setAllInvitedViaSms({ commit }, { ids, leagueID }: InviteAllParams) {
    commit(mutationNames.setLoading, true)
    await inviteClient.inviteVolunteersWithSms({ leagueID, invitations: ids })
    commit(mutationNames.setLoading, false)

    commit(mutationNames.setAllInvited, { ids })
  },

  async setAllApproved({ commit }, { leagueID, role, ids, approved }: LeagueRoleParams) {
    commit(mutationNames.setLoading, true)

    await volunteersClient.approve(leagueID, approved, role, ids)
    commit(mutationNames.setLoading, false)

    commit(mutationNames.setAllApproved, {
      ids,
      role,
      approved,
    })
  },

  async upsertVolunteer(
    { commit },
    {
      leagueID,
      volunteer,
      forceNew = false,
    }: { leagueID: string; volunteer: LeagueVolunteer; forceNew: boolean }
  ) {
    commit(mutationNames.setLoading, true)
    await volunteersClient.validate(leagueID, volunteer, forceNew)
    const newvolunteer = await volunteersClient.save(leagueID, volunteer, forceNew)
    commit(mutationNames.setLoading, false)
    if (newvolunteer) {
      commit(mutationNames.setUpdatedAndMergeVolunteer, { volunteer: newvolunteer })
      commit(mutationNames.setVolunteerUnderEditId, { id: NO_ACTIVE_VOLUNTEER })
    }
  },
  /***
   * Grab the specified participant as an edit (won't use the selected due to new and copy UC's)
   *
   */
  async getVolunteer(ctx, { id }: { id: VolunteerIDType }): Promise<void> {
    let template = null
    const { commit, state } = actionContext(ctx)

    try {
      if (state.volunteerUnderEditId == id && state.operation == VolunteerRetrieveEnum.edit) {
        return //nothing to communicate.
      }
      commit.setOperation(VolunteerRetrieveEnum.edit)
      commit.setVolunteerUnderEditId({ id })

      template = await volunteersClient.retrieveVolunteer(state.lastLeagueLoaded, id)
      if (!template) {
        //load failed.
        commit.setOperation(VolunteerRetrieveEnum.nothing)
        commit.clearVolunteerUnderEdit()
        throwError(`Server returned an invalid template for ${id}`)
        return
      } else {
        commit.setVolunteerUnderEdit({ volunteer: template })
      }
    } catch (ex) {
      throwError(ex.message, ctx.commit, ex)
    }
  },
  /***
   * Get referees for a league
   *
   */
  async fetchReferees(ctx, { upwId, force = true }: { upwId: string; force?: boolean }): Promise<Boolean> {
    const { commit, state } = actionContext(ctx)
    if (!force && state.referees.length) return true

    try {
      const result = await volunteersClient.getVolunteerByRole(upwId, 1) // roleId 1 == referee

      if (result) {
        commit.setReferees(result)
        return true
      }
    } catch (ex: any) {
      throwError(ex.message, ctx.commit, ex)
    }
    return false
  },
  /***
   * Loads volunteers, leagueid and program type are found in the state machine
   * @param commit
   * @param state
   * @param leagueid
   * @param typeProgramId
   */
  async loadProgramVolunteers({ commit, state }, { leagueId, shouldForce }: LoadProgramVolunteersArgs) {
    try {
      if (state.lastLeagueLoaded != leagueId || shouldForce) {
        commit(mutationNames.setLoading, true)

        const result = await volunteersClient.retrieve(leagueId)

        commit(mutationNames.setLastLeagueLoaded, { leagueID: leagueId })
        commit(mutationNames.setVolunteers, { items: result })
      }
    } catch (ex) {
      throwError('Error with the retrieval of volunteers', commit, ex)
    } finally {
      commit(mutationNames.setLoading, false)
    }
  },
  /***
   * Sets a current application state for a single selected volunteer.
   * @param commit
   * @param context
   * @param id
   */
  async setCurrentVolunteerByID(context, { id }: { id: VolunteerIDType }) {
    const { commit, dispatch, state } = actionContext(context)
    try {
      if (!state.volunteers) {
        throwError("Can't find an ID on an empty list of volunteers", context.commit)
        return
      }

      // do we already have it as current item?
      if (state.currentItem && VolunteerID(state.currentItem) == id) {
        return
      }

      // go ahead and preload the template, the vues will shuffle it in when it gets there, preferring the
      // league volunteer we already have in memory as a place to start.

      const index = state.volunteers.findIndex((x) => VolunteerID(x) == id)
      if (index >= 0) {
        //if we have retrieved a list of lean or otherwise limited info classes (likely)
        //   then get the full info for this volunteer in case the data is needed downstream
        commit.setCurrent({
          volunteer:
            state.volunteers[index].addresses == null //this was a lean-ish class
              ? (await volunteersClient.getFullVolunteerInfo(
                  state.lastLeagueLoaded, //yikes
                  VolunteerID(state.volunteers[index])
                )) ?? state.volunteers[index] //if for some reason this returns null, then just use the existing info class
              : state.volunteers[index],
        })

        await dispatch.getVolunteer({ id: VolunteerID(state.volunteers[index]) })

        return
      }
    } catch (ex) {
      commit.clearCurrent()
      throwError(ex.message, context.commit, ex)
    }
    throwError('Volunteer not current in edit', context.commit)
  },
  /**
   * Remove the list of roles that would be associated with the roles editable on the screen requesting the delete
   * if all roles are removed then delete the individual.
   * @param context
   * @param upwardLeagueID
   * @param id
   * @param roles
   */
  async removeVolunteer(context, { upwardLeagueID, id, roles }: VolunteerDeleteArgs) {
    const { state } = actionContext(context)
    const { commit } = context
    const volunteer = state.volunteers.find((x) => x.individualID == id)
    if (volunteer) {
      const v = cloneDeep(volunteer)

      for (const x of roles) {
        if (v.roles?.length == 1) {
          if (v.roles.indexOf(v.roles![0]) >= 0) {
            //last role remove on a volunteer is a delete per API requirements (see #2631)
            //or Todd says "you can't delete a roleless volunteer"
            try {
              await volunteersClient.remove(upwardLeagueID, id)
            } catch (e) {
              throw new VolunteerRoleException(`Volunteer removal request failed on server`)
            }
            v.roles = []
          } else {
            throw new VolunteerRoleException(
              `Role removal request from a person that doesn't appear to have that role`
            )
          }
        } else {
          try {
            await volunteersClient.removeRole(upwardLeagueID, id, x)
          } catch (e) {
            throw new VolunteerRoleException(
              'Volunteer has teams or other connections that have to be undone before this role can be removed.',
              e
            )
          }
          const roleIndex = v.roles?.findIndex((y) => y.roleID == x)
          if (roleIndex != undefined && roleIndex >= 0) {
            v.roles?.splice(roleIndex, 1)
          }
        }
      }

      if (v.roles?.length == 0) {
        commit(mutationNames.removeVolunteer, { id })
      } else {
        commit(mutationNames.setUpdatedVolunteer, { volunteer: v })
      }
    }
  },
  /***
   * Grab the specified volunteer (won't use the selected due to new and copy UC's)
   */
  async getNewTemplate({ commit, state }, { id }: { id?: VolunteerIDType }): Promise<void> {
    let template = null

    try {
      if (state.volunteerUnderEditId == id) {
        return //nothing to communicate.
      }
      commit(mutationNames.setVolunteerUnderEditId, { id: id ?? NEW_VOLUNTEER })
      commit(mutationNames.setLoading, true)

      template = await volunteersClient.retrieveTemplate(state.lastLeagueLoaded, id)
      commit(mutationNames.setLoading, false)

      if (template) {
        template = removeExtraneousTemplateInfo(template)
      }
      commit(mutationNames.setVolunteerUnderEdit, { volunteer: template })
    } catch (ex) {
      throwError(ex.message, commit, ex)
    }
    if (!template) {
      //load failed.
      commit(mutationNames.setVolunteerUnderEditId, { id: NO_ACTIVE_VOLUNTEER })
      throwError(`Server returned an invalid template for ${id}`)
      return
    }
  },
  async transferVolunteers(
    context,
    { leagueID, ids, newAccount }: { leagueID: string; ids: number[]; newAccount: string }
  ) {
    const { commit } = actionContext(context)
    let q = 0
    for (let i1 = 0; i1 < ids.length; i1++) {
      const i = ids[i1]
      q++
      commit.setStatus(`Saving ${((q * 100) / ids.length).toFixed(1)}% `)
      const p = await volunteersClient.retrieveVolunteer(leagueID, i)
      if (p) {
        p.accountNumber = newAccount

        try {
          await volunteersClient.save(leagueID, p)
          commit.setUpdatedVolunteer({ volunteer: leagueVolunteerToLeagueVolunteerInfo(p) })
        } catch (e: any) {
          commit.setStatus(e.getMessage())
        }
      }
    }
    commit.setStatus(`Done`)
  },
  async loadLeanVolunteersByProgramRole(
    { commit, state },
    { leagueId, shouldForce, typeProgramId, roleIDs }: LeanVolunteersByProgramRoleArgs
  ) {
    try {
      if (state.lastLeagueLoaded != leagueId || shouldForce) {
        commit(mutationNames.setLoading, true)

        const result =
          roleIDs === null
            ? await volunteersClient.getLeanVolunteerListByProgram(leagueId, typeProgramId)
            : await volunteersClient.getLeanVolunteerListByProgramAndRoles(leagueId, typeProgramId, roleIDs)

        commit(mutationNames.setLastLeagueLoaded, { leagueID: leagueId })
        commit(mutationNames.setVolunteers, { items: result })
      }
    } catch (ex) {
      throwError('Error with the retrieval of volunteers', commit, ex)
    } finally {
      commit(mutationNames.setLoading, false)
    }
  },
})

function removeExtraneousTemplateInfo(x: LeagueVolunteer): LeagueVolunteer {
  return {
    ...x,
    coachPreferences: null,
    roles: [],
  }
}

export const namespace = 'volunteers'

export const volunteers = {
  namespaced: true as true,
  state: volunteerState,
  getters: getterTree,
  mutations,
  actions,
}
const actionContext = (context: any) => moduleActionContext(context, volunteers)
