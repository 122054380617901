






















import { defineComponent, PropType } from '@vue/composition-api'

import StepperContent from '@/elements/StepperContent.vue'
import StepperStep from '@/elements/StepperStep.vue'
import { StepperHooks } from '@/components/ProductFlow/models/StepperHooks'

export default defineComponent({
  name: 'Step',
  props: {
    step: { type: Number, required: true },
    maxTouchedStep: { type: Number, required: true },
    buttonLabel: { type: String, required: false, default: 'Next' },
    title: { type: String, required: false, default: '' },
    buttonDisabled: { type: Boolean, required: false, default: false },
    hookDefinitions: { type: Object as PropType<StepperHooks | null>, required: false, default: () => null },
    defaultButton: { type: Boolean, required: false, default: true },
  },
  components: { StepperContent, StepperStep },
  setup(props, { emit }) {
    async function localNextStep() {
      let isValid = true
      if (props.hookDefinitions && props.hookDefinitions.beforeNextStep) {
        isValid = props.hookDefinitions.beforeNextStep()
      }
      if (isValid) {
        nextStep()
      }

      if (props.hookDefinitions && props.hookDefinitions.afterNextStep) {
        await props.hookDefinitions.afterNextStep()
      }
    }

    function nextStep() {
      emit('nextStep')
    }

    function clicked() {
      emit('clicked', props.step)
    }

    return {
      localNextStep,
      clicked,
    }
  },
})
