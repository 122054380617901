


























import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'

export default defineComponent({
  name: 'IntegerInput',
  components: {
    InputLabel,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    placeholder: { type: String, default: '', required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    value: { required: true, type: (null as unknown) as PropType<number | null> },
    min: { type: Number, default: 0, required: false },
    max: { type: Number, default: null, required: false },
  },
  setup(props, ctx) {
    const internalValue = ref(convertToString(props.value))

    function convertToString(value: number | null) {
      return value === null || isNaN(value) ? '' : `${value}`
    }

    function convertToNumber(value: string): number | null {
      const valueAsNumber = Math.round(Number(value))

      if (isNaN(valueAsNumber)) {
        return null
      }

      return valueAsNumber
    }

    function handleChange() {
      const valueAsNumber = convertToNumber(internalValue.value)
      ctx.emit('change', valueAsNumber)
    }

    function handleInput() {
      const valueAsNumber = convertToNumber(internalValue.value)
      ctx.emit('input', valueAsNumber)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = convertToString(props.value)
      }
    )

    return {
      internalValue,
      handleChange,
      handleInput,
    }
  },
})
