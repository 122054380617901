import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import teamsClient from '@/clients/teamsClient'
import { cloneDeep } from 'lodash'

interface TeamState {
  teams: DivisionTeamInfo[]
}

const teamState: TeamState = {
  teams: [] as DivisionTeamInfo[],
}

export enum getterNames {
  teams = 'teams',
}

const getterTree = defineGetters<TeamState>()({
  teams: (state) => {
    return state.teams
  },
})

export enum mutationNames {
  setTeams = 'setTeams',
}

const mutations = defineMutations<TeamState>()({
  setTeams(state, { teams }: { teams: DivisionTeamInfo[] }) {
    state.teams = teams
  },
})

export enum actionNames {
  fetchTeams = 'fetchTeams',
}

const actions = defineActions({
  async fetchTeams({ commit }, { upwId }: { upwId: string }) {
    const teams = await teamsClient.retrieveLeagueTeams({ leagueID: upwId })
    commit(mutationNames.setTeams, { teams: teams ?? ([] as DivisionTeamInfo[]) })
    return cloneDeep(teams)
  },
})

export const namespace = 'teams'

export const teams = {
  namespaced: true as true,
  state: teamState,
  getters: getterTree,
  actions,
  mutations,
}
