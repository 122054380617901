



















import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import NumberInput from '@/elements/NumberInput.vue'
import EnlargeableProduct from './EnlargeableProduct.vue'
import SelectorCard from '@/components/SelectorCard.vue'
import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'
import { getEmptyStartupOrderProductInfo } from '@/lib/support/models/StartupOrderProductInfo'
import { SavedProductOfferingConfigStartupOrderProduct } from '@/GeneratedTypes/SavedProductOfferingConfigStartupOrderProduct'
import { getEmptySavedProductOfferingConfigStartupOrderProduct } from '@/lib/support/models/SavedProductOfferingConfigStartupOrderProduct/SavedProductOfferingConfigStartupOrderProduct'
import store from '@/store'

export default defineComponent({
  name: 'StartupOrderProductCard',
  components: {
    NumberInput,
    EnlargeableProduct,
    SelectorCard,
  },
  props: {
    product: {
      type: Object as PropType<StartupOrderProductInfo>,
      default: getEmptyStartupOrderProductInfo,
      required: false,
    },
    value: {
      type: Object as PropType<SavedProductOfferingConfigStartupOrderProduct>,
      default: null,
      required: false,
    },
  },
  setup(props, ctx) {
    const pocStarterOrderItems = computed(() => store.getters.productOfferings.pocStarterOrderItems)
    const quantity = ref<number | null>(0)

    onMounted(() => {
      setSaved()
    })

    watch(
      () => pocStarterOrderItems.value,
      () => {
        setSaved()
      }
    )

    function setSaved() {
      if (!pocStarterOrderItems.value) return
      const savedProduct = pocStarterOrderItems.value.find(
        (x: SavedProductOfferingConfigStartupOrderProduct) => x.productID === props.product.id
      )
      quantity.value = savedProduct ? savedProduct.quantity : null
    }

    function buildStartupOrderProduct(): SavedProductOfferingConfigStartupOrderProduct | null {
      if (!quantity.value || quantity.value < 1) return null
      const prod = getEmptySavedProductOfferingConfigStartupOrderProduct()
      prod.productID = props.product.id
      prod.quantity = quantity.value
      return prod
    }

    watch(
      () => quantity.value,
      () => {
        ctx.emit('input', buildStartupOrderProduct())
      }
    )

    return {
      quantity,
    }
  },
})
