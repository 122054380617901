import { render, staticRenderFns } from "./Calendar.vue?vue&type=template&id=4fa4e644&scoped=true&"
import script from "./Calendar.vue?vue&type=script&lang=ts&"
export * from "./Calendar.vue?vue&type=script&lang=ts&"
import style0 from "./Calendar.vue?vue&type=style&index=0&id=4fa4e644&scoped=true&lang=scss&"
import style1 from "./Calendar.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./Calendar.vue?vue&type=style&index=2&id=4fa4e644&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa4e644",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCalendar } from 'vuetify/lib/components/VCalendar';
installComponents(component, {VCalendar})
