import { getEmptyLeagueDivisionContactInfo } from '@/lib/support/models/LeagueDivisionContactInfo/data'
import { LeagueDivisionExtendedInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionExtendedInfo'

export function getEmptyLeagueDivisionExtendedInfo(): LeagueDivisionExtendedInfo {
  return {
    brokenCarpoolLinkCount: 0,
    brokenCoachLinkCount: 0,
    coachLinksExceededCount: 0,
    contact: getEmptyLeagueDivisionContactInfo(),
    divisionID: 0,
    leagueID: 0,
    missingEvalScoresCount: 0,
    typeProgramID: '',
  }
}
