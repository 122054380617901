interface Node<T> {
  [key: string]: any
  children?: T[]
}

interface Tree {
  id: number
  name: string
  address: string
  children?: Tree[]
}

export const teams: Array<Node<Tree>> = [
  {
    id: 1,
    name: 'Neighborhood',
    children: [
      {
        id: 10,
        name: 'South End',
        address: '1 Partridge Rd',
        children: [
          {
            id: 20,
            name: 'Turtledove',
            address: '2 Turtledove Ln',
          },
          {
            id: 21,
            name: 'Chicken',
            address: '3 Chicken St',
          },
          {
            id: 22,
            name: 'Calling Bird',
            address: '4 Calling Bird Cir',
          },
        ],
      },
      {
        id: 11,
        name: 'North End',
        address: '1st St',
        children: [
          {
            id: 23,
            name: 'Ball',
            address: '8 Ball Rd',
          },
          {
            id: 24,
            name: 'Innings',
            address: '9 Innings Cir',
          },
        ],
      },
    ],
  },
]
