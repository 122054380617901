























































import { defineComponent, ref, watch, onMounted, computed } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import store from '@/store'
import loading from '@/elements/Loading.vue'
import { AgGridVue } from '@ag-grid-community/vue'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { UpwardDayTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardDayTypeID'
import { PlayerPracticeNightExclusionInfo } from '@/GeneratedTypes/ListInfo/PlayerPracticeNightExclusionInfo'
import { TeamCoachInfo } from '@/GeneratedTypes/ListInfo/TeamCoachInfo'
import { PlayerUDFValueInfo } from '@/GeneratedTypes/ListInfo/PlayerUDFValueInfo'

import divisionsClient from '@/clients/divisionsClient'
import participantsClient from '@/clients/participantsClient'

import { capitalize } from 'lodash'
import { getProperGradeLabel } from '@/services/gradeService'

export default defineComponent({
  name: 'PlayerConflict',
  components: {
    SelectInput,
    loading,
    AgGridVue,
  },
  setup() {
    const selectedProgram = ref('@@start@@')
    const selectedDivision = ref(-1)
    const programs = ref([{}]) //league.value.programs?.map((x) => x.typeProgramID) ?? []
    const divisions = ref([{}])
    const isLoading = ref(false)
    const modules = ref(AllCommunityModules)
    const gridOptions: any = { suppressColumnVirtualisation: true }

    const league = computed(() => store.getters.leagueAbstraction.currentItem)
    let api: any = null
    let columnApi: any = null
    let rowData: any = ref(null)

    onMounted(() => {
      programs.value = []
      if (league.value.programs) {
        if (league.value.programs.length > 1) {
          programs.value.push({
            programID: '',
            programName: 'All' ?? '',
          })
          league.value.programs.forEach((r) =>
            programs.value.push({
              programID: r.typeProgramID,
              programName: r.typeProgramID ?? '',
            })
          )
          selectedProgram.value = ''
        } else {
          selectedProgram.value = league.value.programs[0].typeProgramID
        }
      } else {
        selectedProgram.value = '' //default this and trigger the watcher anyway
      }
    })

    watch(
      () => selectedProgram.value,
      async () => {
        divisions.value = []
        //default is all
        divisions.value.push({
          divisionID: 0,
          divisionName: 'All' ?? '',
        })
        if (selectedProgram.value !== '') {
          const divisionList =
            (await divisionsClient.retrieveByProgram(league.value.upwardLeagueID!, selectedProgram.value)) ??
            []

          divisionList.forEach((r) =>
            divisions.value.push({
              divisionID: r.divisionID,
              divisionName: r.divisionName ?? '',
            })
          )
        }
        if (selectedDivision.value == 0) {
          //if we were already on the ALL division the WATCH won't fire so do it manually
          await loadReportData() //ugly.  would rather trigger the watcher directly but couldn't find a way to do so
        } else {
          selectedDivision.value = 0
        }
      }
    )

    watch(
      () => selectedDivision.value,
      async () => {
        await loadReportData()
      }
    )

    function capitalizeFormatter(str: any) {
      return capitalize(str.value)
    }

    function booleanFormatter(bool: any) {
      return bool.value ? 'Yes' : ''
    }

    function teamPracticeNightFormatter(nights: any) {
      //surely there is a wiz-bang way of doing this on one line
      let names: string[] = []
      nights.value.forEach((n: UpwardDayTypeID) => names.push(n.description!))
      return names.join(', ')
    }

    function practiceNightExclusionFormatter(nights: any) {
      //surely there is a wiz-bang way of doing this on one line
      let names: string[] = []
      nights.value.forEach((n: PlayerPracticeNightExclusionInfo) => names.push(n.typeDayID!))
      return names.join(', ')
    }

    function teamCoachesFormatter(coaches: any) {
      //surely there is a wiz-bang way of doing this on one line
      let names: string[] = []
      coaches.value.forEach((c: TeamCoachInfo) => names.push(c.formattedNameFirstLast!))
      return names.join(', ')
    }

    function udfFormatter(udfs: any) {
      //surely there is a wiz-bang way of doing this on one line
      let values: string[] = []
      udfs.value.forEach((u: PlayerUDFValueInfo) =>
        u.udfValue && u.udfValue != '' ? values.push(u.label! + ': ' + u.udfValue!) : null
      )
      return values.join(', ')
    }

    function gradeToAgeFormatter(grade: any) {
      return getProperGradeLabel(grade.value, true, '')
    }

    function gradeDescriptionFormatter(grade: any) {
      return getProperGradeLabel(grade.value, false, '')
    }

    function onReady(params: any) {
      api = params.api
      columnApi = params.columnApi
      let columns = []
      columns.push({
        field: 'firstName',
        headerName: 'First Name',
      })
      columns.push({
        field: 'lastName',
        headerName: 'Last Name',
      })
      columns.push({
        field: 'middleInitial',
        headerName: 'Middle',
      })
      columns.push({
        field: 'gender',
        headerName: 'Gender',
      })
      if (store.getters.leagueAbstraction.isByAge) {
        columns.push({
          field: 'typeGradeID',
          headerName: 'Age',
          valueFormatter: gradeToAgeFormatter,
        })
      } else {
        columns.push({
          field: 'typeGradeID',
          headerName: 'Grade',
          valueFormatter: gradeDescriptionFormatter,
        })
      }
      columns.push({
        field: 'draftExceptionDescription',
        headerName: 'Conflict Description',
      })
      columns.push({
        field: 'teamName',
        headerName: 'Team',
      })
      columns.push({
        field: 'divisionName',
        headerName: 'Division',
      })
      columns.push({
        field: 'typeProgramID',
        headerName: 'Program',
        valueFormatter: capitalizeFormatter,
      })
      columns.push({
        field: 'manualAssignment',
        headerName: 'Manual Assignment?',
        valueFormatter: booleanFormatter,
      })
      columns.push({
        field: 'playerIsLockedToTeam',
        headerName: 'Locked To Team?',
        valueFormatter: booleanFormatter,
      })
      columns.push({
        field: 'coachLinkFirstName',
        headerName: 'Coach Link First',
      })
      columns.push({
        field: 'coachLinkLastName',
        headerName: 'Coach Link Last',
      })
      columns.push({
        field: 'teamCoaches',
        headerName: 'Team Coaches',
        valueFormatter: teamCoachesFormatter,
      })
      columns.push({
        field: 'carpoolLinkFirstName',
        headerName: 'Carpool Link First',
      })
      columns.push({
        field: 'carpoolLinkLastName',
        headerName: 'Carpool Link Last',
      })
      columns.push({
        field: 'teamPracticeNights',
        headerName: 'Team Practice Nights',
        valueFormatter: teamPracticeNightFormatter,
      })
      columns.push({
        field: 'practiceNightExclusions',
        headerName: 'Practice Nights Exclusions',
        valueFormatter: practiceNightExclusionFormatter,
      })
      columns.push({
        field: 'udFs',
        headerName: 'UDFs',
        valueFormatter: udfFormatter,
      })
      columns.push({
        field: 'generalNotes',
        headerName: 'General Notes',
      })
      columns.push({
        field: 'adminNotes',
        headerName: 'Admin Notes',
      })

      api.setColumnDefs(columns)
    }

    async function loadReportData() {
      isLoading.value = true

      try {
        const conflictList =
          (await participantsClient.retrievePlayerConflicts(
            league.value.upwardLeagueID!,
            selectedProgram.value,
            selectedDivision.value
          )) ?? []

        rowData.value = conflictList
      } catch (err) {
        rowData.value = []
      }

      isLoading.value = false
    }

    function onFirstDataRendered() {
      const cols = [
        'firstName',
        'lastName',
        'middleInitial',
        'gender',
        'typeGradeID',
        'draftExceptionDescription',
        'teamName',
        'divisionName',
        'typeProgramID',
        'manualAssignment',
        'playerIsLockedToTeam',
        'coachLinkFirstName',
        'coachLinkLastName',
        'teamCoaches',
        'carpoolLinkFirstName',
        'carpoolLinkLastName',
        'teamPracticeNights,',
        'practiceNightExclusions',
      ] //basically all columns except the UDF and note columns at the end
      columnApi.applyColumnState({
        state: [
          { colId: 'lastName', sort: 'asc', sortIndex: 0 },
          { colId: 'firstName', sort: 'asc', sortIndex: 1 },
        ],
        defaultState: { sort: null },
      })
      columnApi.autoSizeColumns(cols, false)
    }

    function downloadCsv() {
      const parms = { processCellCallback: csvProcessCellCallback }
      api.exportDataAsCsv(parms)
    }

    function csvProcessCellCallback(params: any) {
      switch (params.column.getColId()) {
        case 'typeProgramID':
          return capitalizeFormatter(params)
        case 'manualAssignment':
          return booleanFormatter(params)
        case 'playerIsLockedToTeam':
          return booleanFormatter(params)
        case 'teamPracticeNights':
          return teamPracticeNightFormatter(params)
        case 'practiceNightExclusions':
          return practiceNightExclusionFormatter(params)
        case 'teamCoaches':
          return teamCoachesFormatter(params)
        case 'udFs':
          return udfFormatter(params)
        default:
          return params.value
      }
    }

    return {
      downloadCsv,
      selectedProgram,
      selectedDivision,
      programs,
      divisions,
      isLoading,
      modules,
      onReady,
      onFirstDataRendered,
      rowData,
      gridOptions,
    }
  },
})
