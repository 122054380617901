/*
 * Logic that is shared across SetupSteppers (e.g. League, Camp)
 */

import { computed, onUnmounted, watch, ref } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import { SavedProductOfferingConfigGenderGrade } from '@/GeneratedTypes/SavedProductOfferingConfigGenderGrade'
import { OfferingsByProgram } from '@/components/ProductFlow/models/OfferingsByProgram'
import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'

import store from '@/store'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import { ProdConfigTypes } from '@/lib/common/ProdConfigTypes'
import { stepListNames } from '@/components/ProductFlow/ProductSetupStepper/data'
import dayjs from 'dayjs'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'

export interface StepComponent {
  name: string
  index: number
  stepTitle: string
  nextOfferingIndex: number
  isCheer: boolean
}

export function setupStepperLogic() {
  const viewedSteps = computed(() => store.getters.productFlow.viewedSteps)
  const currentUpwardProgrmType = computed(() => store.getters.productFlow.currentProgramType)
  const logic = productFlowLogic()
  const stepComponents = ref<StepComponent[]>([])

  /*
   * Monitor loading of child products.
   * Fetching child products is the last fetch when
   * loading the stepper.
   */
  watch(
    () => [
      store.getters.productOfferings.childProductsLoadedCount,
      store.getters.leagueAbstraction.leagueUpwardProgramTypeID,
    ],
    () => {
      const loadedCount = store.getters.productOfferings.childProductsLoadedCount
      const programs =
        logic.prodConfigType.value == ProdConfigTypes.ESTIMATE
          ? store.getters.productOfferings.offeringProgramTypes
          : store.getters.leagueAbstraction.leagueUpwardProgramTypeID
      let loadingComplete = false
      if (programs.length) {
        loadingComplete = loadedCount >= programs.length
      } else {
        loadingComplete = false
      }
      if (!store.getters.productOfferings.initialProductFlowLoadingComplete && loadingComplete) {
        setTimeout(
          () => store.commit.productOfferings.setInitialProductFlowLoadingComplete({ status: true }),
          1000
        )
      }
    }
  )

  /*
   * Manage state of steps
   */

  function openAllSteps() {
    const steps = store.getters.productFlow.steps
    steps.forEach((step) => {
      store.commit.productFlow.addViewedStep({ step })
    })
  }

  const currentStep = computed({
    get() {
      return store.getters.productFlow.currentProductSetupStep
    },
    set(v: number) {
      store.commit.productFlow.setCurrentProductSetupStep({ step: v })
    },
  })

  function maxoutProductStepperTouchedSteps() {
    store.commit.productFlow.setMaxTouchedProductSetupStep({ step: viewedSteps.value.length + 1 })
  }

  /*
   * Warnings
   */

  const showCurrencyWarning = computed(() => {
    if (store.getters.partners.currencyWarningNeeded && logic.isNewProdConfig.value) {
      return !store.getters.partners.hasAcceptedCurrencyWarning
    }
    return false
  })

  /*
   * Initialization
   */

  async function initialize() {
    primeStepperValues()
    await primeProductOfferingStore()
  }

  function primeStepperValues() {
    logic.setProdConfigType({ type: ProdConfigTypes.LEAGUE })
    if (isNewProgram.value) {
      logic.setIsNewProdConfig({ isNew: true })
    } else {
      logic.setIsNewProdConfig({ isNew: false })
    }
  }

  async function primeProductOfferingStore() {
    await store.dispatch.programTypes.fetchAll({ force: true })

    if (logic.isNewProdConfig.value) {
      const item = cloneDeep(logic.leagueAbstraction.value.productOfferingConfig)

      //prime accounts
      const accounts = logic.leagueAbstraction.value.accounts ?? []
      if (accounts.length) {
        item.gradesByGender = accounts[0].grades as SavedProductOfferingConfigGenderGrade[]
      }
      //prime firstPracticeDate
      item.firstPracticeDateEstimate = logic.leagueAbstraction.value.productSeasonDate

      //prime typeLeagueID
      if (item.typeLeagueID == '') {
        item.typeLeagueID = store.getters.creationParameters.leagueType
      } else {
        item.typeLeagueID = logic.leagueAbstraction.value.typeLeagueID
      }
      //update productOffering store
      store.commit.productOfferings.setProductOfferingConfig({ item })
      setStepList()
    } else {
      const item = cloneDeep(logic.leagueAbstraction.value.productOfferingConfig)
      store.commit.productOfferings.setProductOfferingConfig({ item })
      await logic.fetchProductCatalog()
      setStepList()
      await openAllSteps()
      maxoutProductStepperTouchedSteps()
    }
  }

  async function initializeEstimate(prodConfigId: number) {
    primeStepperValuesEstimate(prodConfigId)
    await primeProductOfferingStoreEstimate(prodConfigId)
  }

  function primeStepperValuesEstimate(prodConfigId: number) {
    logic.setProdConfigType({ type: ProdConfigTypes.ESTIMATE })
    if (prodConfigId != 0) {
      logic.setIsNewProdConfig({ isNew: false })
    } else {
      logic.setIsNewProdConfig({ isNew: true })
    }
  }

  async function primeProductOfferingStoreEstimate(prodConfigId: number) {
    if (logic.isNewProdConfig.value) {
      if (!store.getters.productOfferings.productOfferingConfig.typeLeagueID) {
        const id = store.getters.creationParameters.leagueType ?? ''
        store.commit.productOfferings.setPOTypeLeagueID({ id })
      }
      //set default grade/gender combos
      if (store.getters.leagueAbstraction.currentItem.accounts) {
        const grades = store.getters.leagueAbstraction.currentItem.accounts[0].grades?.map((x) => ({
          typeProgramID: x.typeProgramID,
          gender: x.gender,
          typeGradeID: x.typeGradeID,
        })) as SavedProductOfferingConfigGenderGrade[]
        store.commit.productOfferings.setGradeGender({ gradeGenders: grades })
      }
      setStepList()
    } else {
      await store.dispatch.productOfferings.fetchProductOfferingConfig({
        configId: prodConfigId.toString(),
      })
      //put stub league in abstraction so things work correctly downstream
      await store.dispatch.leagues.beginCreating({
        name: store.getters.productOfferings.productOfferingConfig.name ?? '',
        typeLeagueID: store.getters.productOfferings.productOfferingConfig.typeLeagueID ?? '',
        publicDisplayName: store.getters.productOfferings.productOfferingConfig.accountNumber ?? '',
        partnerContact: null,
        addToCache: false,
      })
      await logic.fetchProductCatalog()
      setStepList()
      await openAllSteps()
      maxoutProductStepperTouchedSteps()
    }

    await store.dispatch.programTypes.fetchAll({ force: true })
  }

  function setStepList() {
    if (store.getters.leagueAbstraction.leagueUpwardProgramTypeID.length == 0) {
      return
    }

    const stepList: string[] = []
    stepComponents.value = []
    const isCamp = store.getters.leagueAbstraction.leagueUpwardProgramTypeID[0].isCamp
    const hasSystemFee = dayjs(
      logic.prodConfigType.value == ProdConfigTypes.ESTIMATE
        ? store.getters.productOfferings.productOfferingConfig.firstPracticeDateEstimate ?? undefined
        : store.getters.leagueAbstraction.currentItem.productSeasonDate ?? undefined
    ).isBefore('2024/07/01', 'day')

    let i = 1
    stepComponents.value.push({
      name: 'InfoStep',
      index: i++,
    } as StepComponent)
    stepList.push(stepListNames.programInfo)

    stepComponents.value.push({
      name: isCamp ? 'AccessFeeStep' : hasSystemFee ? 'UpwardAccessStep' : 'UpwardBaseFeeStep',
      index: i++,
    } as StepComponent)
    stepList.push('UPWARDACCESSFEE')

    if (hasSystemFee) {
      stepComponents.value.push({
        name: isCamp ? 'UpwardSystemStep' : 'UpwardSystemsStep',
        index: i++,
      } as StepComponent)
      stepList.push('UPWARDSYSTEMSFEE')
    }

    stepComponents.value.push({
      name: 'StarterItemsStep',
      index: i++,
    } as StepComponent)
    stepList.push(stepListNames.startupOrderItems)

    if (isCamp) {
      stepComponents.value.push({
        name: 'ParticipantKitStep',
        index: i,
        stepTitle: 'Camp Items',
        nextOfferingIndex: 1,
        isCheer: false,
      } as StepComponent)
      stepList.push(stepListNames.players)
    } else {
      store.getters.leagueAbstraction.leagueUpwardProgramTypeID.forEach((x) => {
        let currIndex = 0
        stepComponents.value.push({
          name: 'ParticipantKitStep',
          index: i++,
          stepTitle: prettyProgramName(x),
          nextOfferingIndex: currIndex + 1,
          isCheer: x.isCheer,
        } as StepComponent)
        stepList.push(x.isCheer ? stepListNames.cheer : stepListNames.players)
        currIndex++
      })
    }

    store.commit.productFlow.setStepList({ stepList: stepList })
  }

  watch(
    () => store.getters.productOfferings.productOfferingConfig.firstPracticeDateEstimate,
    (newDate, oldDate) => {
      if (
        //prevent infinte loop
        !dayjs(oldDate ?? undefined).isSame(dayjs(newDate ?? undefined)) &&
        logic.prodConfigType.value == ProdConfigTypes.ESTIMATE
      ) {
        setStepList()
      }
    }
  )

  function prettyProgramName(program: UpwardProgramTypeID) {
    if (program) {
      if (program.isCamp) {
        return 'Build Camp Kit'
      }
      return `Build ${program.shortDescription} Kit` ?? 'Kit'
    }
    return 'Kit'
  }

  const isNewProgram = computed(() => {
    const id = logic.leagueAbstraction.value.upwardLeagueID
    const detailsCount = logic.leagueAbstraction.value.productOfferingConfig.details?.length ?? 0
    if (id || detailsCount > 0) {
      return false
    }
    return true
  })

  /*
   * Calculate Programs
   */
  const availableProductGroups = computed(() => store.getters.productOfferings.availableProductGroups)

  function buildOfferingByProgram() {
    const cheer = [] as ProductOfferingInfo[]
    const sport = [] as ProductOfferingInfo[]
    availableProductGroups.value.forEach((a) => {
      if (a.isCheer) {
        cheer.push(a)
      } else {
        sport.push(a)
      }
      const offeringsByProgram: OfferingsByProgram[] = [
        ...(sport.length ? [{ isCheer: false, offerings: [...sport] }] : []),
        ...(cheer.length ? [{ isCheer: true, offerings: [...cheer] }] : []),
      ]
      store.commit.productOfferings.setOfferingByProgarm({ offeringsByProgram })
    })
  }

  const listOfPrograms = computed(() => store.getters.leagueAbstraction.leagueUpwardProgramTypeID)
  watch(
    () => listOfPrograms.value,
    () => {
      //set default program to sport
      if (listOfPrograms.value.length && !currentUpwardProgrmType.value) {
        const program = listOfPrograms.value.find((p) => !p.isCheer)
        if (program) {
          store.commit.productFlow.setCurrentTypeProgram({ program })
        }
      }
    },
    { immediate: true }
  )

  /*
   * Watchers
   */

  watch(
    () => availableProductGroups.value,
    () => {
      if (availableProductGroups.value && availableProductGroups.value.length) {
        buildOfferingByProgram()
      }
    },
    { immediate: true }
  )

  /*
   * Unmount
   */

  onUnmounted(() => {
    store.commit.productOfferings.clear()
    store.commit.productFlow.clear()
  })

  return {
    openAllSteps,
    currentStep,
    stepComponents,
    showCurrencyWarning,
    buildOfferingByProgram,
    viewedSteps,
    currentUpwardProgrmType,
    initialize,
    initializeEstimate,
  }
}
