import { defineActions, defineGetters, defineMutations } from 'direct-vuex'
import { getFilteredProductList, OrderProduct } from '@/models/Order/OrderProduct'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import ordersClient from '@/clients/ordersClient'
import {
  getEmptyXStartupOrderExt,
  setSalesTaxNotCalculatedYet,
  ToXStartupOrderExt,
} from '@/lib/support/models/GeneratedTypes/xOrders/xStartupOrderExt'
import { xStartupOrder } from '@/GeneratedTypes/xOrder/xStartupOrder'
import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import { getEmptyOrderProductSize, OrderProductSize } from '@/models/Order/OrderProductSize'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { xShipToBase } from '@/GeneratedTypes/xOrder/xShipToBase'
import { xShipMethodBase } from '@/GeneratedTypes/xOrder/xShipMethodBase'
import { cloneDeep, remove } from 'lodash'
import { moduleActionContext } from '@/store/index'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import { deleteCached, getCached, setCache } from '@/lib/support/store/orders/cache'
import { setProduct } from '@/lib/support/store/orders/operations'

interface OrderState {
  currentOrderTemplate?: xStartupOrderExt
  lastCompletedOrder?: xStartupOrderExt
  selectedProducts: OrderProductSelection[]
  selectedCards: number[]
  internalOrderTypeUpdateHack: number
}

export interface OrderProductSelection {
  upwardProductID: string
  variation: string
  id: number
}

const orderState: OrderState = {
  currentOrderTemplate: undefined,
  lastCompletedOrder: undefined,
  selectedProducts: [],
  selectedCards: [],
  internalOrderTypeUpdateHack: 1,
}

export enum getterNames {
  currentOrderTemplate = 'currentOrderTemplate',
  lastCompletedOrder = 'lastCompletedOrder',
  currentOrderProducts = 'currentOrderProducts',
  currentOrderShipTo = 'currentOrderShipTo',
  currentOrderShippingMethods = 'currentOrderShippingMethods',
  currentOrderNotes = 'currentOrderNotes',
  currentOrderInternalOrderType = 'currentOrderInternalOrderType',
  currentOrderAdminNote = 'currentOrderAdminNote',
  productsSelected = 'productsSelected',
  cardsSelected = 'cardsSelected',
}

class OrderRuntimeException extends RuntimeException {
  name = 'Order Problem'
}

const getterTree = defineGetters<OrderState>()({
  currentOrderTemplate: (state) => state.currentOrderTemplate,
  lastCompletedOrder: (state) => state.lastCompletedOrder,
  currentOrderProducts: (state) =>
    cloneDeep(state.currentOrderTemplate?.products)
      ?.filter((p) => p.isActive)
      ?.sort((x, y) => (x.sortOrder == y.sortOrder ? 0 : x.sortOrder > y.sortOrder ? 1 : -1)) || [],
  currentOrderShipTo: (state) => state.currentOrderTemplate?.shippingInfo?.shipTo || {},
  currentOrderNotes: (state) => state.currentOrderTemplate?.notes || '',
  currentOrderShippingMethods: (state) => state.currentOrderTemplate?.shippingInfo?.shipMethods || [],
  currentOrderInternalOrderType: (state) => {
    const hack = state.internalOrderTypeUpdateHack
    const pi = state.currentOrderTemplate?.processingInstructions?.find(
      (x) => x.type == 'SET_INTERNAL_ORDERTYPE'
    )
    return pi && hack > 0 ? pi.value : null //hack will always be > 0
  },
  currentOrderAdminNote: (state) =>
    state.currentOrderTemplate?.processingInstructions?.find((x) => x.type == 'ADD_ADMINISTRATIVE_NOTE')
      ?.value ?? null,
  productsSelected: (state) => state.selectedProducts,
  cardsSelected: (state) => state.selectedCards,
})

export enum mutationNames {
  setCurrentOrderTemplate = 'setCurrentOrderTemplate',
  setLastCompletedOrder = 'setLastCompletedOrder',
  setCurrentOrderProducts = 'setCurrentOrderProducts',
  setCurrentOrderShipTo = 'setCurrentOrderShipTo',
  setCurrentOrderShippingMethods = 'setCurrentOrderShippingMethods',
  setCurrentOrderNotes = 'setCurrentOrderNotes',
  setCurrentOrderInternalOrderType = 'setCurrentOrderInternalOrderType',
  setCurrentOrderAdminNote = 'setCurrentOrderAdminNote',
  setSelectedProducts = 'setSelectedProducts',
  setPurchaseOrderNumber = 'setPurchaseOrderNumber',
  setCardsSelected = 'setCardsSelected',
  setCurrentOrderSelectedProducts = 'setCurrentOrderSelectedProducts',
  resetCurrentOrder = 'resetCurrentOrder',
  resetSalesTaxAndShipping = 'resetSalesTaxAndShipping',
}

/**
 * Cache key creation based on the parameter of league id.
 * Prior keys did not pull new orders templates on league change.
 * @param orderType
 * @param upwardLeagueID
 */
function makeKey(orderType: OrderTypesEnum, upwardLeagueID: string | null) {
  return `${orderType}-${upwardLeagueID ?? ''}}`
}

interface CardDescriptionType {
  name: string
  id: string
}

const mutations = defineMutations<OrderState>()({
  resetSalesTaxAndShipping(state, { orderType }: { orderType: OrderTypesEnum }) {
    state.currentOrderTemplate = setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate))
    setCache(
      makeKey(orderType, state.currentOrderTemplate?.upwardLeagueID ?? ''),
      state.currentOrderTemplate || getEmptyXStartupOrderExt()
    )
  },
  /**
   * Clear out the old orders detail and zero out sales tax and shipping.
   * @param state
   * @param orderType
   */
  resetCurrentOrder(state, { orderType }: { orderType: OrderTypesEnum }) {
    state.currentOrderTemplate = setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate))
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.orderLines = []
      const products = cloneDeep(state.currentOrderTemplate?.products ?? [])

      products.forEach((x, index) => {
        state.currentOrderTemplate!.products.splice(index, 1, {
          ...x,
          productColorSizes: cloneDeep(x.productColorSizes ?? []).map((u) => ({
            ...u,
            orderQuantity: 0,
            orderFreeQuantity: 0,
          })),
        })
      })
    }
    setCache(
      makeKey(orderType, state.currentOrderTemplate?.upwardLeagueID ?? ''),
      state.currentOrderTemplate || getEmptyXStartupOrderExt()
    )
  },
  setCurrentOrderTemplate(state, { orderType, item }: { orderType: OrderTypesEnum; item: xStartupOrderExt }) {
    state.currentOrderTemplate = item
    setCache(makeKey(orderType, item.upwardLeagueID), item)
  },
  /**
   * Chooses the promo cards that are available for purchase,
   * we do this by creating fake variations which allow the product card
   * make those selectable.
   * @param state
   * @param cards
   */
  setCardsSelected(state, { cards }: { cards: CardDescriptionType[] }) {
    let prods = cloneDeep(state.currentOrderTemplate?.products ?? [])
    const promocard = cloneDeep(prods.find((x) => x.upwardProductID == 'PROMOCARD'))
    if (promocard) {
      promocard.productColorSizes = cards.map(
        (x) =>
          ({
            ...getEmptyOrderProductSize(),
            colorDescription: x.name,
            typeColorID: x.id.toString(),
          } as OrderProductSize)
      )
      prods = prods.filter((x) => x.upwardProductID != 'PROMOCARD')
      prods.push(promocard)
      if (state.currentOrderTemplate) {
        state.currentOrderTemplate.products = prods

        setCache(
          makeKey(
            state.currentOrderTemplate.upwardOrderType as OrderTypesEnum,
            state.currentOrderTemplate.upwardLeagueID
          ),
          state.currentOrderTemplate
        )
      }
    }
  },
  setLastCompletedOrder(
    state,
    { orderType, item }: { orderType: OrderTypesEnum; item?: xStartupOrderExt | null }
  ) {
    if (!orderType) return
    state.lastCompletedOrder = item ?? undefined
    if (item) {
      setCache(`${makeKey(orderType, item.upwardLeagueID)}_completed`, item)
    }
  },
  /**
   * These products are selected in the first round of product selection.
   * @param state
   * @param orderType
   * @param list
   */
  setCurrentOrderSelectedProducts(state, { list }: { list: OrderProductSelection[] }) {
    state.selectedProducts = cloneDeep(list)
  },

  setCurrentOrderProducts(state, { orderType, item }: { orderType: OrderTypesEnum; item: OrderProduct[] }) {
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.products = item
      state.currentOrderTemplate = setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate))
      setCache(
        makeKey(orderType, state.currentOrderTemplate?.upwardLeagueID ?? ''),
        state.currentOrderTemplate ?? getEmptyXStartupOrderExt()
      )
    }
  },
  async setCurrentOrderShipTo(state, { orderType, item }: { orderType: OrderTypesEnum; item: xShipToBase }) {
    if (state.currentOrderTemplate && state.currentOrderTemplate.shippingInfo) {
      state.currentOrderTemplate.shippingInfo.shipTo = item
      await setCache(
        makeKey(orderType, state.currentOrderTemplate.upwardLeagueID),
        state.currentOrderTemplate
      )
    }
  },
  setCurrentOrderNotes(state, { orderType, item }: { orderType: OrderTypesEnum; item: string }) {
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.notes = item
      setCache(makeKey(orderType, state.currentOrderTemplate.upwardLeagueID), state.currentOrderTemplate)
    }
  },
  setPurchaseOrderNumber(state, { orderType, item }: { orderType: OrderTypesEnum; item: string }) {
    if (state.currentOrderTemplate) {
      state.currentOrderTemplate.purchaseOrderNumber = item
      setCache(makeKey(orderType, state.currentOrderTemplate.upwardLeagueID), state.currentOrderTemplate)
    }
  },
  setCurrentOrderShippingMethods(
    state,
    { orderType, item }: { orderType: OrderTypesEnum; item: xShipMethodBase[] }
  ) {
    if (state.currentOrderTemplate && state.currentOrderTemplate.shippingInfo) {
      state.currentOrderTemplate.shippingInfo.shipMethods = item
      setCache(makeKey(orderType, state.currentOrderTemplate.upwardLeagueID), state.currentOrderTemplate)
    }
  },
  setCurrentOrderInternalOrderType(
    state,
    { orderType, item }: { orderType: OrderTypesEnum; item: string | null }
  ) {
    if (state.currentOrderTemplate) {
      //remove existing processing instruction if it exists
      if (state.currentOrderTemplate.processingInstructions) {
        remove(state.currentOrderTemplate.processingInstructions, (x) => x.type == 'SET_INTERNAL_ORDERTYPE')
      }

      if (item != null && item != '') {
        //add
        if (!state.currentOrderTemplate.processingInstructions) {
          state.currentOrderTemplate.processingInstructions = []
        }

        state.currentOrderTemplate.processingInstructions.push({
          type: 'SET_INTERNAL_ORDERTYPE',
          value: item,
        })
      }

      state.internalOrderTypeUpdateHack++

      setCache(makeKey(orderType, state.currentOrderTemplate.upwardLeagueID), state.currentOrderTemplate)
    }
  },
  setCurrentOrderAdminNote(state, { orderType, item }: { orderType: OrderTypesEnum; item: string | null }) {
    if (state.currentOrderTemplate) {
      //remove existing processing instruction if it exists
      if (state.currentOrderTemplate.processingInstructions) {
        remove(state.currentOrderTemplate.processingInstructions, (x) => x.type == 'ADD_ADMINISTRATIVE_NOTE')
      }

      if (item != null && item != '') {
        //add
        if (!state.currentOrderTemplate.processingInstructions) {
          state.currentOrderTemplate.processingInstructions = []
        }

        state.currentOrderTemplate.processingInstructions.push({
          type: 'ADD_ADMINISTRATIVE_NOTE',
          value: item,
        })
      }

      setCache(makeKey(orderType, state.currentOrderTemplate.upwardLeagueID), state.currentOrderTemplate)
    }
  },
})

export enum actionNames {
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  validateOrder = 'validateOrder',
  createOrder = 'createOrder',
  retrieveLastOrder = 'retrieveLastOrder',
  setCurrentOrderProducts = 'setCurrentOrderProducts',
  clearCachedOrder = 'clearCachedOrder',
}

const actions = defineActions({
  /**
   * Sets the orders quantity for a given product in the current orders.
   * @param state
   * @param context
   * @param orderType
   * @param productID - upward product id
   * @param colorID
   * @param sizeID
   * @param quantity - 0 for delete, otherwise the quantity
   */

  setProductQuantity(
    context,
    {
      orderType,
      productID,
      colorID,
      sizeID,
      quantity,
      quantityFree,
    }: {
      orderType: OrderTypesEnum
      productID: number
      colorID: string
      sizeID: string
      quantity: number
      quantityFree: number
    }
  ) {
    const { state, commit } = ordersActionContext(context)

    // when adding a product invalidate sales tax.
    commit.setCurrentOrderTemplate({
      orderType,
      item: setSalesTaxNotCalculatedYet(cloneDeep(state.currentOrderTemplate)) ?? getEmptyXStartupOrderExt(),
    })

    //then update quantity, size, color on product id.
    const products = cloneDeep(state.currentOrderTemplate?.products ?? [])

    commit.setCurrentOrderProducts({
      orderType,
      item: setProduct(products, { productID, sizeID, colorID, quantity, quantityFree }),
    })
  },

  /**
   * Grab an orders by type, if it exists in cache then continue to
   * use it, otherwise grab a template from the server.
   * Also, if the league has changed grab another orders template.
   * @param commit
   * @param context
   * @param id league id
   * @param orderType orders type from enum
   */
  async retrieveAndSetAsCurrent(
    context,
    { id, orderType }: { id: string; orderType: OrderTypesEnum }
  ): Promise<xStartupOrderExt | null> {
    const { commit } = ordersActionContext(context)
    let orderData!: xStartupOrderExt | null
    // Is orders cached in localStorage?
    orderData = getCached(makeKey(orderType, id))

    if (!orderData) {
      // Data doesn't exist in localStorage, fetch orders template from orders API
      orderData = await ordersClient.retrieveTemplate(id, orderType)
    } else {
      //set initial selected products based on products with quantities.
      const curproducts = getFilteredProductList(orderData.products, () => true)
        .map((x) => ({ id: x.id, upwardProductID: x.upwardProductID, variation: '' }))
        .filter((x, i, a) => i == a.indexOf(x))
      commit.setCurrentOrderSelectedProducts({ list: curproducts })

      //
    }
    //by this time we have orders data.
    if (orderData) {
      commit.setCurrentOrderTemplate({ orderType, item: orderData })
      commit.resetCurrentOrder({ orderType: orderType })
    }
    return orderData
  },
  async validateOrder(
    { commit },
    { orderType, id }: { orderType: OrderTypesEnum; id: string }
  ): Promise<xStartupOrder | null> {
    let result = null
    if (orderState.currentOrderTemplate) {
      result = await ordersClient.verificationDetails(id, orderState.currentOrderTemplate)
      if (result) {
        commit(mutationNames.setCurrentOrderTemplate, {
          orderType: orderType,
          item: ToXStartupOrderExt(result, orderState.currentOrderTemplate),
        })
      }
    }
    return result
  },
  /***
   * Submit an orders and reset orders state.
   * @param commit
   * @param dispatch
   * @param id
   * @param orderType
   */
  async createOrder(
    { commit, dispatch },
    { id, orderType }: { id: string; orderType: OrderTypesEnum }
  ): Promise<xStartupOrder | null> {
    let result = null

    if (orderState.currentOrderTemplate) {
      // place the orders

      if (
        orderState?.currentOrderTemplate?.orderLines?.some(
          (x) => !x.productUniqueID && x.productID == 'PROMOCARD'
        )
      ) {
        throw new OrderRuntimeException(
          'There is a problem with the promo card, please remove the card and choose an alternative promocard variation.'
        )
      }

      result = await ordersClient.createOrder(id, orderState.currentOrderTemplate)

      if (result) {
        // put this orders in lastCompletedOrder. This info is displayed on confirmation page.
        commit(mutationNames.setLastCompletedOrder, {
          orderType: orderType,
          item: cloneDeep(ToXStartupOrderExt(result, orderState.currentOrderTemplate)),
        })

        // delete the orders from localStorage
        deleteCached(makeKey(orderType, orderState.currentOrderTemplate.upwardLeagueID ?? ''))
        commit(mutationNames.resetCurrentOrder, { orderType: orderType })

        // repopulate currentOrderTemplate so it's ready for next orders
        await dispatch(actionNames.retrieveAndSetAsCurrent, {
          id: id,
          orderType: orderType,
        })
      }
    }
    return result
  },
  retrieveLastOrder(
    { commit },
    { orderType, upwardLeagueID }: { orderType: OrderTypesEnum; upwardLeagueID: UpwardLeagueIDType }
  ): void {
    let result = null
    result = getCached(`${makeKey(orderType, upwardLeagueID)}_completed`)
    commit(mutationNames.setLastCompletedOrder, {
      orderType: orderType,
      item: result,
    })
    return
  },
  clearCachedOrder(
    {},
    { orderType, upwardLeagueID }: { orderType: OrderTypesEnum; upwardLeagueID: UpwardLeagueIDType }
  ): void {
    deleteCached(makeKey(orderType, upwardLeagueID))
  },
})

export const namespace = 'orders'

export const orders = {
  namespaced: true as true,
  state: orderState as OrderState,
  getters: getterTree,
  mutations,
  actions,
}

const ordersActionContext = (context: any) => moduleActionContext(context, orders)
