import { programTypes } from '@/store/programTypes'

export function isCheer(typeProgramID: string | null) {
  let retval = false
  const program = programTypes.state.items.find((x) => x.upwardTypeID === typeProgramID)
  if (program) {
    retval = program.isCheer!
  }

  return retval
}

export function programTypeDescription(typeProgramID: string | null) {
  let retval: string | null = null
  const program = programTypes.state.items.find((x) => x.upwardTypeID === typeProgramID)
  if (program) {
    retval = program.shortDescription
  }

  return retval
}

export const ProgramTypes: Record<string, string> = {
  BASKETBALL: 'Basketball',
  BASKETBALLCHEERLEADING: 'Cheerleading',
  SOCCER: 'Soccer',
  FOOTBALL: 'Football',
  BASKETBALLCAMP: 'Basketball',
  CHEERLEADINGCAMP: 'Cheerleading',
  SOCCERCAMP: 'Soccer ',
  FOOTBALLCHEERLEADING: 'Cheerleading',
  FOOTBALLCAMP: 'Football',
  VOLLEYBALL: 'Volleyball',
  BASEBALL: 'Baseball',
  SOFTBALL: 'Softball',
  UPWARDSELECTMSS: 'Upward Select',
}
