import restService from '@/services/restService'
import { AlertInfo } from '@/GeneratedTypes/ListInfo/AlertInfo'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'
import { NotificationInfo } from '@/models/Notification/NotificationInfo'

const alertUrl = 'alerts/'

const retrieveLeagueAlerts = async (upwId: string): Promise<AlertInfo[] | null> => {
  try {
    const result = await restService.get<AlertInfo[]>(`${alertUrl}program/${upwId}`)
    if (result.isSuccess && result.data?.length) {
      return result.data
    } else {
      return Promise.resolve(null)
    }
  } catch (e) {
    if (e.status == 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.retrieveLeagueAlerts')
    }
  }
}

const retrieveSalesTaxAlerts = async (accountNumber: string): Promise<AlertInfo[] | null> => {
  try {
    const result = await restService.get<AlertInfo[]>(`${alertUrl}salestax/${accountNumber}`)
    if (result.isSuccess && result.data?.length) {
      return result.data
    } else {
      return Promise.resolve(null)
    }
  } catch (e) {
    if (e.status == 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.retrieveSalesTaxAlerts')
    }
  }
}

const retrieveOrderAlerts = async (accountNumber: string): Promise<AlertInfo[] | null> => {
  try {
    const result = await restService.get<AlertInfo[]>(`${alertUrl}orders/${accountNumber}`)
    if (result.isSuccess && result.data?.length) {
      return result.data
    } else {
      return Promise.resolve(null)
    }
  } catch (e) {
    if (e.status == 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.retrieveSalesTaxAlerts')
    }
  }
}

const hideAlerts = async (alert: AlertInfo): Promise<AlertInfo | null> => {
  try {
    const result = await restService.post<AlertInfo>(`${alertUrl}hide/${alert.id}`, alert)
    if (result.isSuccess && result.data) {
      return result.data
    } else {
      return Promise.resolve(null)
    }
  } catch (e) {
    if (e.status == 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.hideAlerts')
    }
  }
}

const retrievePartnerAlerts = async (accountNumber: string): Promise<AlertInfo[] | null> => {
  // Only sales tax alerts for now, but there will be other partner alerts later.
  try {
    const results = await restService.get<AlertInfo[]>(`${alertUrl}partner/${accountNumber}`)
    if (results.isSuccess) {
      return results.data
    } else {
      return Promise.resolve(null)
    }
  } catch (e) {
    if (e.status == 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.retrievePartnerAlerts')
    }
  }
}

const retrieveMyNotifications = async (): Promise<NotificationInfo[] | null> => {
  try {
    const result = await restService.get<NotificationInfo[]>(`${alertUrl}mynotifications`)
    if (result.isSuccess && result.data?.length) {
      return result.data
    } else {
      return Promise.resolve(null)
    }
  } catch (e) {
    if (e.status == 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.retrieveMyNotifications')
    }
  }
}

const retrieveAdminAlerts = async (): Promise<AlertInfo[] | null> => {
  try {
    const result = await restService.get<AlertInfo[]>(`${alertUrl}admin`)
    if (result.isSuccess && result.data?.length) {
      return result.data
    } else {
      return Promise.resolve(null)
    }
  } catch (e) {
    if (e.status == 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.retrieveAdminAlerts')
    }
  }
}

const markNotificationRead = async (id: number): Promise<Boolean> => {
  const result = await restService.post<NotificationInfo>(`${alertUrl}mynotifications/${id}/markasread`, null)
  if (result.isSuccess && result.data) {
    return Promise.resolve(true)
  } else {
    return Promise.resolve(false)
  }
}

const retrieveTeamPageAlerts = async (upwId: string) => {
  try {
    const results = await restService.get<TeamPageAlertInfo[]>(`${alertUrl}teams/byLeague/${upwId}`)
    if (results.isSuccess) {
      return results.data
    } else {
      throw new Error('Error in alertClient.retrieveTeamPageAlerts')
    }
  } catch (e) {
    if (e.status === 404) {
      return Promise.resolve(null)
    } else {
      throw new Error('Error in alertClient.retrieveTeamPageAlerts')
    }
  }
}

const saveTeamPageAlert = async (upwId: string, alert: TeamPageAlertInfo) => {
  const results = await restService.post<TeamPageAlertInfo>(`${alertUrl}teams/${upwId}`, alert)

  if (!results.isSuccess) {
    throw new Error(`Error in alertClient.saveTeamPageAlert`)
  }
  return results.data
}

const removeTeamPageAlert = async (alertId: number) => {
  const results = await restService.delete<void>(`${alertUrl}${alertId}`)

  if (!results.isSuccess) {
    throw new Error(`Error in alertClient.removeTeamPageAlert`)
  }
  return results.isSuccess
}

export default {
  retrieveLeagueAlerts,
  retrievePartnerAlerts,
  retrieveSalesTaxAlerts,
  hideAlerts,
  retrieveOrderAlerts,
  retrieveMyNotifications,
  markNotificationRead,
  retrieveTeamPageAlerts,
  saveTeamPageAlert,
  removeTeamPageAlert,
  retrieveAdminAlerts,
}
