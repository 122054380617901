























































import { defineComponent, ref, computed, watch, PropType, nextTick } from '@vue/composition-api'
import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import uuid from 'uuid'
import { cloneDeep, isEqual } from 'lodash'

export default defineComponent({
  name: 'CheckboxGroupInput',
  components: {
    BaseInputWrapper,
    CheckboxInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    enabled: { type: Boolean, default: true, required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    id: { type: String, default: '', required: false },
    value: { type: Array as PropType<string[]>, required: true, default: () => [] },
    textPropertyName: { type: String, default: '', required: false },
    valuePropertyName: { type: String, default: '', required: false },
    itemClass: { type: String, default: '', required: false },
    itemsList: { type: Array as PropType<Record<string, string>[]>, required: true, default: () => [] },
    horizontal: { type: Boolean, default: false, required: false },
    collapsible: { type: Boolean, default: false, required: false },
  },
  setup(props, ctx) {
    const guid = uuid.v4()
    const internalValue = ref<string[]>([])
    const isExpanded = ref(false)
    const filteredText = ref('')

    function isChecked(item: Record<string, string>, index: number) {
      const thing = getOptionValue(item, index)
      const otherthing = internalValue.value.indexOf(thing)

      return otherthing >= 0
    }

    function getOptionValue(item: Record<string, string>, index: number): string {
      if (props.valuePropertyName !== '') {
        return item[props.valuePropertyName].toString()
      }
      if (props.textPropertyName !== '') {
        return item[props.textPropertyName].toString()
      }
      return index.toString()
    }

    function getOptionText(item: Record<string, string>): string {
      if (props.textPropertyName !== '') {
        return item[props.textPropertyName].toString()
      }
      return item.toString()
    }

    const elementId = computed(() => props.id || `checkbox-${guid}`)

    function handleChange(val: string) {
      const index = internalValue.value?.indexOf(val)

      /* This filter takes care of multiple values appearing in the array like ['1','1','2']   */
      if (index !== undefined && index >= 0) {
        internalValue.value = internalValue.value.filter((x) => x !== val)
      } else {
        internalValue.value.push(val)
      }
      input()
      change()

      //what happens if our internal state isn't the same as our props?
      //this will force a recompare.
      nextTick(() => onValueChanged())
    }

    function clearAll() {
      internalValue.value = []

      input()
      change()
    }

    function selectAll() {
      internalValue.value = props.itemsList.map((x, i) => getOptionValue(x, i))

      input()
      change()
    }

    watch(
      () => internalValue.value,
      () => {
        updateFilteredText()
      },
      { immediate: true, deep: true }
    )

    watch(
      () => isExpanded.value,
      () => {
        updateFilteredText()
      },
      { immediate: true }
    )

    function updateFilteredText() {
      if (props.collapsible && !isExpanded.value) {
        //no need to calculate this if not a collapsible group
        if (internalValue.value.length == props.itemsList.length) {
          filteredText.value = 'All'
        } else if (internalValue.value.length == 0) {
          filteredText.value = 'None'
        } else {
          filteredText.value = 'Some have been filtered out'
        }
      } else {
        filteredText.value = ''
      }
    }

    function input() {
      ctx.emit('input', internalValue.value)
    }

    function change() {
      ctx.emit('change', internalValue.value)
    }

    // see explanation below
    const redisplay = ref(false)

    watch(
      () => props.value,
      () => onValueChanged(),
      { immediate: true }
    )

    function onValueChanged() {
      const equal = isEqual(props.value, internalValue.value)
      internalValue.value = cloneDeep(props.value)

      if (!equal) {
        // internal state is the state the checkboxes were left in which may or may
        // not trigger a value prop update, this fixes up discrepencies where the
        // UI state does not match the value state.
        redisplay.value = true
        nextTick(() => (redisplay.value = false))
      }
    }

    return {
      elementId,
      filteredText,
      isExpanded,
      redisplay,
      getOptionValue,
      isChecked,
      handleChange,
      getOptionText,
      selectAll,
      clearAll,
    }
  },
})
