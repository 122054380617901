import { UpwardExceptionResult } from '@/models/UpwardExceptionResult'

export class GeneralError extends Error {
  public name = 'General Error'
  public innerException: Error | UpwardExceptionResult | null = null
  constructor(message: string, exception?: Error | UpwardExceptionResult | null) {
    super(message)
    this.message = message
    this.innerException = exception ?? null
  }
}
