
















import { computed, defineComponent, onMounted } from '@vue/composition-api'
import Order from '@/views/Programs/Orders/vues/Order.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import store from '@/store'

export default defineComponent({
  name: 'ManualOrderFrame',
  components: {
    Order,
    AlertCloak,
  },

  setup(props, ctx) {
    onMounted(() => {
      store.dispatch.gpOrderTypes.fetchAll(false) //get these cached up
    })

    return {
      alertTypes: [AlertCloakTypes.SALES_TAX],
      orderStep: computed(() => ctx.root.$route.params.step),
      orderType: computed(() => OrderTypesEnum.manual),
      orderStepChanged: async (step: string) => {
        const lowerStep = step.toLocaleLowerCase()
        if (ctx.root.$route.params.step != lowerStep) {
          await ctx.root.$router.push({
            params: { ...ctx.root.$router.currentRoute.params, step: lowerStep },
          })
        }
      },
    }
  },
})
