




// @ts-nocheck

import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TheChat extends Vue {
  private initChat() {
    ;(function (g, e, n, es, ys) {
      g['_genesysJs'] = e
      g[e] =
        g[e] ||
        function () {
          ;(g[e].q = g[e].q || []).push(arguments)
        }
      g[e].t = 1 * new Date()
      g[e].c = es
      ys = document.createElement('script')
      ys.async = 1
      ys.src = n
      ys.charset = 'utf-8'
      document.head.appendChild(ys)
    })(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
      environment: 'use1',
      deploymentId: '8cb067fc-97b0-4d65-ba21-49f3e7d6a801',
    })
  }

  private mounted() {
    if (!this.exists()) {
      this.initChat()
    }
  }

  private exists() {
    const chat = document.getElementsByClassName('genesys-app')
    return chat.length > 0
  }
}
