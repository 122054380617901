import { computed, ref } from '@vue/composition-api'
import ordersClient from '@/clients/ordersClient'
import { OrderLineRollupInfo } from '@/models/Order/xStartupOrderExt'
import { Rollup } from '@/views/Programs/Orders/league/types/Rollup'

export function useOrderRollup() {
  const orderRollup = ref<OrderLineRollupInfo[] | null>(null)

  const getRollup = async (upwId: string, orderId: string) => {
    orderRollup.value = await ordersClient.getOrderRollup({
      leagueID: upwId,
      id: orderId,
    })
  }

  const getEmptyRollup = () => {
    return { position: 999, warehouse: '', items: [], total: 0, discount: 0 } as Rollup
  }

  /*
  Group shipments by internalOrderPosition
  */
  const rollup = computed(() => {
    if (!orderRollup.value || orderRollup.value.length == 0) return []
    const formatted: Rollup[] = [getEmptyRollup()]

    orderRollup.value.forEach((r) => {
      //Group items by shippment
      let shippment = formatted.find((s) => s.position == r.internalOrderPosition)
      if (!shippment) {
        shippment = getEmptyRollup()
        shippment.position = r.internalOrderPosition
        formatted.push(shippment)
      }
      shippment.warehouse = r.warehouseName ?? ''

      if (r.upwardProductID == 'UPWARDDISCOUNT') {
        shippment.discount += r.extendedCost
      } else {
        shippment.total += r.extendedCost
      }
      shippment.items.push(r)
    })

    //remove any empty groups
    return formatted.filter((i) => i.items.length > 0)
  })

  return {
    getRollup,
    rawRollup: computed(() => orderRollup.value),
    rollup,
  }
}
