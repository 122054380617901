





































import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { CoachLinksOverageType } from '@/clients/reportsClient'

interface ParticipantBreakout {
  name: string
  gender: string
  grade: string
}

interface CoachBreakout {
  CoachName: string
  MaleGradesToCoach: string | null
  FemaleGradesToCoach: string | null
  participants: ParticipantBreakout[]
}

export default defineComponent({
  props: {
    report: {
      type: Array as PropType<Array<CoachLinksOverageType>>,
      default: () => [],
      required: true,
    },
  },

  setup(props) {
    const internalReport = ref<Array<CoachBreakout>>([])
    watch(
      () => props.report,
      () => {
        internalReport.value = []
        props.report.forEach((x) => {
          let z = internalReport.value.find((a) => a.CoachName == x.CoachName)
          if (!z) {
            z = {
              CoachName: x.CoachName,
              MaleGradesToCoach: x.MaleGradesToCoach,
              FemaleGradesToCoach: x.FemaleGradesToCoach,
              participants: [],
            }
            internalReport.value.push(z)
          }
          if (z) {
            z.participants.push({ gender: x.Gender, name: x.ParticipantName, grade: x.Grade })
          }
        })
      },
      { immediate: true }
    )

    return { internalReport }
  },
})
