import { Contact } from '@/GeneratedTypes/Partner/Contact'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'

import { cleanPhoneNumber } from '@/services/phoneNumberService'

export function GetEmptyContactInfo(): ContactInfo {
  return {
    firstName: '',
    lastName: '',
    fullName: '',
    emailAddress: '',
    emailValid: 'true',
    emailValidReason: '',
    primaryPhone: '',
    primaryPhoneTypeID: null,
    secondaryPhone: null,
    secondaryPhoneTypeID: null,
    alternatePhone: null,
    alternatePhoneTypeID: null,
    yearsExperience: 0,
    isFullTime: false,
    typePartnerRoleID: null,
    preferredContactDayOfWeek: null,
    preferredContactTimeOfDay: null,
    preferredContactMethod: null,
    id: 0,
    trained: false,
  }
}

export function ContactToContactInfo(contact: Contact | null, id: number): ContactInfo {
  if (contact) {
    return {
      firstName: contact.firstName!,
      lastName: contact.lastName!,
      fullName: `${contact.firstName} ${contact.lastName}`,
      emailAddress: contact.emailAddress!,
      emailValid: contact.emailValid,
      emailValidReason: contact.emailValidReason,
      primaryPhone: cleanPhoneNumber(contact.primaryPhoneNumber!),
      primaryPhoneTypeID: contact.primaryPhoneTypeID!,
      secondaryPhone: cleanPhoneNumber(contact.secondaryPhoneNumber),
      secondaryPhoneTypeID: contact.secondaryPhoneTypeID,
      alternatePhone: cleanPhoneNumber(contact.alternatePhoneNumber),
      alternatePhoneTypeID: contact.alternatePhoneTypeID,
      yearsExperience: contact.yearsExperience,
      isFullTime: contact.isFullTime,
      typePartnerRoleID: contact.typePartnerRoleID!,
      preferredContactDayOfWeek: contact.typePreferredDayOfWeekID!,
      preferredContactTimeOfDay: contact.typePreferredTimeOfDayID!,
      preferredContactMethod: contact.typePreferredContactMethodID!,
      id,
      trained: false,
    }
  }

  const emptyContact = GetEmptyContactInfo()
  emptyContact.id = id
  return emptyContact
}

export function ContactInfoToContact(contactInfo: ContactInfo): Contact {
  return {
    firstName: contactInfo.firstName!,
    lastName: contactInfo.lastName!,
    emailAddress: contactInfo.emailAddress!,
    emailValid: contactInfo.emailValid,
    emailValidReason: contactInfo.emailValidReason,
    primaryPhoneNumber: cleanPhoneNumber(contactInfo.primaryPhone!),
    primaryPhoneTypeID: contactInfo.primaryPhoneTypeID!,
    secondaryPhoneNumber: cleanPhoneNumber(contactInfo.secondaryPhone),
    secondaryPhoneTypeID: contactInfo.secondaryPhoneTypeID,
    alternatePhoneNumber: cleanPhoneNumber(contactInfo.alternatePhone),
    alternatePhoneTypeID: contactInfo.alternatePhoneTypeID,
    yearsExperience: contactInfo.yearsExperience,
    isFullTime: contactInfo.isFullTime,
    typePartnerRoleID: contactInfo.typePartnerRoleID!,
    typePreferredDayOfWeekID: contactInfo.preferredContactDayOfWeek!,
    typePreferredTimeOfDayID: contactInfo.preferredContactTimeOfDay!,
    typePreferredContactMethodID: contactInfo.preferredContactMethod!,
  }
}
