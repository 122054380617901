




























































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import SelectorCard from '@/components/SelectorCard.vue'
import SelectInput from '@/elements/SelectInput.vue'
import EnlargeableProduct from './EnlargeableProduct.vue'
import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { getEmptyProductOfferingProductInfo } from '@/lib/support/models/ProductOfferingProductInfo/ProductOfferingProductInfo'
import uuid from 'uuid'
import { getGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import { getProperGradeLabel } from '@/services/gradeService'
import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'
import { getEmptySavedProductOfferingConfigDetail } from '@/lib/support/models/SavedProductOfferingConfigDetail/SavedProductOfferingConfigDetail'
import { ProductOfferingTypes } from '@/lib/common/ProductOfferingTypes'
import { TypeProductOption } from '@/lib/support/models/UpwardTypes/TypeProductOption'
import { SavedProductOfferingConfigGenderGrade } from '@/GeneratedTypes/SavedProductOfferingConfigGenderGrade'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { getProductByID, programByType, buildGradeArray } from '@/lib/common/ProductOfferingConfig'
import { SavedProductOfferingConfigDetailGenderGrade } from '@/GeneratedTypes/SavedProductOfferingConfigDetailGenderGrade.ts'
import { cloneDeep } from 'lodash'
import { TypeProductOfferingProductPackage } from '@/lib/support/models/UpwardTypes/TypeProductOfferingProductPackageID'
import store from '@/store'

interface GradeGender {
  id: string
  startGrade: string
  endGrade: string
  gender: string
  gradeList: string[]
}

export default defineComponent({
  name: 'GradeGenderCard',
  components: {
    RadioGroupInput,
    SelectInput,
    SelectorCard,
    EnlargeableProduct,
  },
  props: {
    product: {
      type: Object as PropType<ProductOfferingProductInfo>,
      default: getEmptyProductOfferingProductInfo,
      required: false,
    },
    category: { type: Number, default: 0, required: false },
    value: { type: Object as PropType<any>, required: false },
    selectable: { type: Boolean, required: true },
    package: { type: String, required: false, default: '' },
    isByAge: { type: Boolean, required: false, default: false },
    type: {
      type: Number as PropType<ProductOfferingTypes>,
      required: false,
      default: () => ProductOfferingTypes.SPORT,
    },
  },
  setup(props, ctx) {
    const productOfferingConfig = computed(() => store.getters.productOfferings.productOfferingConfig)
    const gender = ref(0)
    const startGrade = ref('')
    const endGrade = ref('')
    const gridError = ref('')
    const allGrades = ref(getGrades(false))
    const gridData = ref<GradeGender[]>([])
    const localGradeGender = ref<SavedProductOfferingConfigGenderGrade[] | null>([])

    const choices = [
      { value: 'ALL', label: 'PROVIDE FOR ALL PARTICIPANTS' },
      { value: 'EXCLUDED', label: 'DO NOT PROVIDE THIS ITEM' },
      { value: 'SELECTED', label: 'PROVIDE ONLY TO SELECTIONS BELOW' },
    ]

    const programChoice = ref(choices[1].value)

    const headers = ref<{ text: string; value: string; sortable?: boolean }[]>([])

    onMounted(async () => {
      await store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up
      setLocalGradeGender()
      setValues()
      setDefaultGrades()
      setPackage()
      headers.value = props.isByAge
        ? [
            { text: 'Gender', value: 'gender' },
            { text: 'Start Age', value: 'startGrade' },
            { text: 'End Age', value: 'endGrade' },
            { text: '', value: 'actions', sortable: false },
          ]
        : [
            { text: 'Gender', value: 'gender' },
            { text: 'Start', value: 'startGrade' },
            { text: 'End', value: 'endGrade' },
            { text: '', value: 'actions', sortable: false },
          ]
    })

    const isCardSelected = computed((): boolean => {
      if (programChoice.value == choices[0].value) return true
      if (programChoice.value === choices[2].value && gridData.value.length > 0) return true
      return false
    })

    function setValues() {
      const savedProduct = getProductByID(
        cloneDeep(productOfferingConfig.value.details),
        props.product.productID,
        props.category
      )
      if (!savedProduct) return
      setRadioButtons(savedProduct)
      if (!savedProduct.gradesByGender || savedProduct.gradesByGender.length < 1) return
      populateGridOfGrades(savedProduct)
    }

    function setRadioButtons(savedProduct: SavedProductOfferingConfigDetail) {
      const gradeCount = savedProduct.gradesByGender ? savedProduct.gradesByGender.length : 0
      if (savedProduct.typeProductOptionID === 'INCLUDED' && gradeCount === 0) {
        programChoice.value = choices[0].value
      }

      if (savedProduct.typeProductOptionID === 'INCLUDED' && gradeCount > 0) {
        programChoice.value = choices[2].value
      }
    }

    const showGradeGender = computed(() => programChoice.value == choices[2].value)

    const gradeList = computed(() => {
      const myGender = genders.value[gender.value]
      switch (myGender) {
        case 'Boys':
          return boyGrades.value
        case 'Girls':
          return girlGrades.value
        default:
          return []
      }
    })

    const genders = computed(() => {
      const retval: string[] = []
      if (boyGrades.value.length) retval.push('Boys')
      if (girlGrades.value.length) retval.push('Girls')
      return retval
    })

    function deleteItem(item: GradeGender) {
      clearGridError()
      const x = gridData.value.filter((i: GradeGender) => {
        return i.id !== item.id
      })
      gridData.value = x
      if (gridData.value.length === 0) programChoice.value = choices[1].value
    }

    function addClicked() {
      if (!gradeList.value) return
      if (valid()) {
        gridData.value.push({
          id: uuid.v4(),
          gender: genders.value[gender.value],
          startGrade: getProperGradeLabel(startGrade.value, props.isByAge, ''),
          endGrade: getProperGradeLabel(endGrade.value, props.isByAge, ''),
          gradeList: getSelectedGradeList(),
        })
      }
    }

    function getSelectedGradeList() {
      if (!gradeList.value) return []
      const startIndex = gradeList.value.findIndex((x) => x.upwardTypeID == startGrade.value)
      const endIndex = gradeList.value.findIndex((x) => x.upwardTypeID == endGrade.value)
      return gradeList.value.slice(startIndex, endIndex + 1).map((x) => x.upwardTypeID!)
    }

    function invalidDups() {
      const newEntry = getSelectedGradeList()
      let dupes!: string[]
      gridData.value.forEach((row: GradeGender) => {
        if (row.gender != genders.value[gender.value]) return
        dupes = newEntry
          .filter((i: string) => row.gradeList.includes(i))
          .map((x) => getProperGradeLabel(x, props.isByAge, ''))
      })

      if (dupes && dupes.length > 0) {
        let myGender = ''
        if (props.type === ProductOfferingTypes.SPORT) myGender = ` for ${genders.value[gender.value]}`
        return `No duplicates. Theses ${
          props.isByAge ? 'ages' : 'grades'
        } are already selected ${myGender}: ${dupes.join(', ')} `
      }
      return false
    }

    function startLessThanEnd() {
      const error =
        gradeList.value.findIndex((x) => x.upwardTypeID == endGrade.value) <
        gradeList.value.findIndex((x) => x.upwardTypeID == startGrade.value)
      if (error) return `Start grade must be less than end grade`
      return false
    }

    function valid() {
      const dupes = invalidDups()
      const orderError = startLessThanEnd()
      if (!dupes && !orderError) return true
      if (orderError) gridError.value = orderError
      if (dupes) gridError.value = dupes
      return false
    }

    function clearGridError() {
      gridError.value = ''
    }

    function setDefaultGrades() {
      if (gradeList.value) {
        startGrade.value = gradeList.value[0].upwardTypeID!
        endGrade.value = gradeList.value[gradeList.value.length - 1].upwardTypeID!
      }
    }

    function emitData() {
      let data!: SavedProductOfferingConfigDetail | null
      const progChoice = programChoice.value
      const gridLength = gridData.value.length
      if (progChoice == choices[1].value) {
        data = null
      } else if (progChoice == choices[2].value && gridLength < 1) {
        data = null
      } else {
        data = buildSavedProductOfferingConfigDetail()
      }

      ctx.emit('input', data)
      ctx.emit('change', data)
    }

    function buildSavedProductOfferingConfigDetail() {
      const data: SavedProductOfferingConfigDetail = getEmptySavedProductOfferingConfigDetail()
      data.typeProductOptionID = TypeProductOption.INCLUDED
      data.productOfferingID = props.product.productID
      if (programChoice.value === choices[2].value) {
        data.gradesByGender = createGradeGenderObjectArray()
      }
      return data
    }

    function createGradeGenderObjectArray() {
      let arr: SavedProductOfferingConfigDetailGenderGrade[] = []
      gridData.value.forEach((row: GradeGender) => {
        row.gradeList.forEach((i: string) => {
          const obj = {
            gender: row.gender === 'Boys' ? 'M' : 'F',
            typeGradeID: i,
          } as SavedProductOfferingConfigDetailGenderGrade
          arr.push(obj)
          return arr
        })
      })
      return arr
    }

    function populateGridOfGrades(savedProduct: SavedProductOfferingConfigDetail) {
      const boyGrades: string[][] = reassembleGradesList(savedProduct, 'M')
      const girlGrades: string[][] = reassembleGradesList(savedProduct, 'F')
      gridData.value = []
      if (girlGrades) loadGridFromSaved(girlGrades, 'Girls')
      if (boyGrades) loadGridFromSaved(boyGrades, 'Boys')
    }

    function loadGridFromSaved(rows: string[][], genderParm: 'Boys' | 'Girls') {
      rows.forEach((row: string[]) => {
        gridData.value.push({
          id: uuid.v4(),
          gender: genderParm,
          startGrade: getProperGradeLabel(row[0], props.isByAge, ''),
          endGrade: getProperGradeLabel(row[row.length - 1], props.isByAge, ''),
          gradeList: row,
        })
      })
    }

    function reassembleGradesList(savedProduct: SavedProductOfferingConfigDetail, type: 'M' | 'F') {
      //extrapolate the rows to display in the grid from the grade
      //objects returned by the database.
      //
      //This is done by looking for gaps in the grades array (when it's compared
      //to the saved values returned from the db)
      const listOfReassembledGrades: string[][] = []
      let gradesForOneRowInGrid: string[] = []
      if (!savedProduct.gradesByGender) return []
      const savedGradesForOneGender = savedProduct.gradesByGender.reduce(
        (acc: string[], g: SavedProductOfferingConfigDetailGenderGrade) => {
          if (g.gender === type) acc.push(g.typeGradeID)
          return acc
        },
        []
      )
      allGrades.value.forEach((g: string, i: number) => {
        if (savedGradesForOneGender.includes(g)) {
          // if there is a match, add to the row
          gradesForOneRowInGrid.push(g)
        } else {
          if (gradesForOneRowInGrid.length > 0) {
            // if there is no match, it's a gap.
            // Push what's in the row so far to the array
            // of all rows
            listOfReassembledGrades.push(gradesForOneRowInGrid)
            //clear the array that holds one row in order
            //to start next row
            gradesForOneRowInGrid = []
          }
        }
        //add the last row if it includes the last elemnt in the
        //grades array
        if (gradesForOneRowInGrid.length > 0 && i === allGrades.value.length - 1) {
          listOfReassembledGrades.push(gradesForOneRowInGrid)
        }
      })
      return listOfReassembledGrades
    }

    function genderChange() {
      setDefaultGrades()
      clearGridError()
    }

    const boyGrades = computed(() => buildGradesForGender('M'))

    const girlGrades = computed(() => buildGradesForGender('F'))

    const leaguePrograms = computed(
      () => store.getters.leagueAbstraction.leagueUpwardProgramTypeID as UpwardProgramTypeID[]
    )

    function buildGradesForGender(genderParm: 'F' | 'M') {
      const progType =
        props.type === ProductOfferingTypes.SPORT
          ? programByType(leaguePrograms.value, 'SPORT')
          : programByType(leaguePrograms.value, 'CHEER')
      if (!progType) return []
      return buildGradeArray(localGradeGender.value, genderParm, progType.upwardTypeID)
    }

    const showAddGradeWarning = computed(
      () => programChoice.value == choices[2].value && gridData.value.length < 1
    )

    function setPackage() {
      if (!props.package) return
      gridData.value = []

      if (props.package == TypeProductOfferingProductPackage.CUSTOM) {
        programChoice.value = choices[1].value
        return
      }
      if (
        props.product.productPackages?.some((x) => x.typeProductOfferingProductPackageID == props.package)
      ) {
        programChoice.value = choices[0].value
      } else {
        programChoice.value = choices[1].value
      }
    }

    watch(
      () => props.package,
      () => {
        setPackage()
      }
    )

    watch(
      () => programChoice.value,
      () => {
        emitData()
        clearGridError()
      }
    )

    watch(
      () => gridData.value,
      () => {
        emitData()
      },
      { deep: true }
    )

    watch(
      () => gradeList.value,
      () => {
        setDefaultGrades()
      }
    )

    watch(
      () => startGrade.value,
      () => {
        clearGridError()
      }
    )

    watch(
      () => endGrade.value,
      () => {
        clearGridError()
      }
    )

    function setLocalGradeGender() {
      localGradeGender.value = JSON.parse(JSON.stringify(productOfferingConfig.value.gradesByGender))
    }

    watch(
      () => productOfferingConfig.value,
      () => {
        setLocalGradeGender()
        setValues()
      },
      { deep: true }
    )

    return {
      isCardSelected,
      programChoice,
      choices,
      showGradeGender,
      gender,
      genders,
      genderChange,
      startGrade,
      gradeList,
      endGrade,
      addClicked,
      showAddGradeWarning,
      gridError,
      gridData,
      headers,
      deleteItem,
    }
  },
})
