











































































import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'
import divisionsClient from '@/clients/divisionsClient'
import teamsClient from '@/clients/teamsClient'

import SelectInput from '@/elements/SelectInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import { isCheer } from '@/lib/common/ProgramTypes'
import store from '@/store'

export default defineComponent({
  name: 'CoachRoster',
  components: {
    SelectInput,
    CheckboxInput,
    ActiveReport,
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const selectedReport = ref('Select a Report')
    const selectedProgram = ref('')

    const selectedDivision = ref(-1)
    const divisions = ref([{}])

    const selectedTeam = ref(-1)
    const teams = ref([{}])

    const loading = ref(false)
    const loadStatus = ref('')

    const hideEvalScores = ref(false)

    const reportTypes = 'Select a Report,Coach Roster,Parent Roster'

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    const programs = computed((): string[] => {
      if (isCamp.value) {
        return camp.value.programs?.map((p) => p.typeProgramID) ?? []
      }
      return league.value.programs?.map((p) => p.typeProgramID) ?? []
    })

    const hasCheer = computed((): boolean => {
      if (isCamp.value) {
        return false
      }

      return (league.value && league.value.programs?.some((p) => isCheer(p.typeProgramID))) ?? false
    })

    const reports = computed((): string[] => reportTypes.split(','))

    onMounted(async () => {
      selectedProgram.value = typeProgramID.value
      await loadTeams()
    })

    watch(
      () => [selectedReport.value, selectedProgram.value],
      async () => {
        selectedDivision.value = -1
        divisions.value = []
        const myDivisions =
          (await divisionsClient.retrieveByProgram(upwardLeagueId.value, selectedProgram.value)) ?? []

        //default is all
        divisions.value.push({
          divisionID: 0,
          divisionName: 'All' ?? '',
        })

        myDivisions.forEach((r) =>
          divisions.value.push({
            divisionID: r.divisionID,
            divisionName: r.divisionName ?? '',
          })
        )
      }
    )

    watch(
      () => selectedDivision.value,
      async () => {
        await loadTeams()
      }
    )

    async function loadTeams() {
      selectedTeam.value = -1
      teams.value = []
      const myTeams =
        (await teamsClient.retrieveTeamList({
          leagueId: upwardLeagueId.value,
          divisionId: selectedDivision.value,
          typeProgramId: selectedProgram.value,
        })) ?? []

      //default is all
      teams.value.push({
        teamID: 0,
        teamName: 'All' ?? '',
      })

      myTeams.forEach((r) =>
        teams.value.push({
          teamID: r.teamID,
          teamName: r.teamName ?? '',
        })
      )
    }

    function reportLoaded() {
      loading.value = false
    }

    const incomplete = computed(() => {
      const isIncomplete =
        selectedReport.value.trim() == 'Select a Report' ||
        selectedDivision.value == -1 ||
        selectedTeam.value == -1
      return isIncomplete
    })

    async function loadReport() {
      loading.value = true

      loadStatus.value = 'Downloading Report Data . . .'
      const data = await Promise.all([
        reportsClient.getCoachRoster(
          upwardLeagueId.value,
          accountNumber.value,
          selectedTeam.value.toString(),
          selectedDivision.value.toString(),
          selectedProgram.value
        ),
        reportsClient.getCoachSubReport(
          upwardLeagueId.value,
          accountNumber.value,
          selectedTeam.value.toString(),
          selectedDivision.value.toString(),
          selectedProgram.value
        ),
      ])

      loadStatus.value = 'Downloading Report Templates . . .'
      const reportTemplates = await Promise.all([
        reportsClient.getReportTemplate('coachroster'),
        reportsClient.getReportTemplate('coachsubreport'),
        reportsClient.getReportTemplate('parentroster'),
      ])

      const mainData = data[0]
      const subData = data[1]

      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')
      const subReport = JSON.parse(reportTemplates[1]?.jsonContent ?? '')
      const parentReport = JSON.parse(reportTemplates[2]?.jsonContent ?? '')

      loadStatus.value = 'Loading Report . . .'
      const activeReport: any = ctx.refs.activereport

      const parameters = [
        {
          Value: [hideEvalScores.value],
          Name: 'HideEvalScores',
        },
      ]

      const resourceLocator = {
        getResource: function (name: string) {
          if (name === 'coach-sub-report.rdlx-json') return subReport
        },
      }

      subReport.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(subData)

      if (selectedReport.value.trim() == 'Coach Roster') {
        report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)
        activeReport.loadReportData(report, { ReportParams: parameters, ResourceLocator: resourceLocator })
      } else if (selectedReport.value.trim() == 'Parent Roster') {
        parentReport.DataSources[0].ConnectionProperties.ConnectString =
          'jsondata=' + JSON.stringify(mainData)
        activeReport.loadReportData(parentReport, { ResourceLocator: resourceLocator })
      }
    }

    return {
      hasCheer,
      reports,
      selectedReport,
      loading,
      programs,
      selectedProgram,
      divisions,
      selectedDivision,
      teams,
      selectedTeam,
      hideEvalScores,
      incomplete,
      loadStatus,
      loadReport,
      reportLoaded,
    }
  },
})
