

























import { defineComponent } from '@vue/composition-api'
import { getProps } from '@/composables/BaseInputWrapperProps'
import InputLabel from '@/elements/InputLabel.vue'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  name: 'BaseInputWrapper',
  components: {
    InputLabel,
    ValidationProvider,
  },
  props: {
    ...getProps(),
  },
})
