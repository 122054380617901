



























import { defineComponent } from '@vue/composition-api'
import Combobox from '@/elements/Combobox.vue'

export default defineComponent({
  name: 'ComboboxTest',
  props: {},
  components: { Combobox },
  setup() {
    const val1 = '11:00am'
    const list1 = [
      '9:00am',
      '10:00am',
      '11:00am',
      '12:00pm',
      '12:15',
      '12:30pm',
      '1:00pm',
      '1:30pm',
      '2:00pm',
    ]
    const val2 = 'Christina'
    const list2 = ['Amy', 'Brenda', 'Cristina', 'Eva', 'Faith', 'Gin', 'Heather', 'Illea']
    const val3 = 'Heather'
    const list3 = [
      { name: 'Amy', id: 11 },
      { name: 'Brenda', id: 2 },
      { name: 'Cristina', id: 35 },
      { name: 'Eva', id: 4 },
      { name: 'Faith', id: 5 },
      { name: 'Heather', id: 62 },
      { name: 'Illea', id: 7 },
    ].map((l) => l.name)
    return { list1, val1, list2, val2, list3, val3 }
  },
})
