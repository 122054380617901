import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { LeaguePracticeNight } from '@/GeneratedTypes/LeaguePracticeNight'
import leagueClient from '@/clients/leaguesClient'
import programsClient from '@/clients/programsClient'
import facilitiesClient from '@/clients/facilitiesClient'
import jwtService from '@/services/jwtService'
import { League } from '@/GeneratedTypes/League'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { getEmptyLeagueFacility } from '@/lib/support/models/LeagueFacility/data'
import { ActionsImpl } from 'direct-vuex'
import credentialsService from '@/services/credentialsService'
import { FACILITY_DEFAULT_TIMES } from '@/services/facilityService'

export enum eventTypes {
  Games = 'GAMES',
  Practices = 'PRACTICES',
}

export enum viewModes {
  list = 'list',
  create = 'create',
  edit = 'edit',
}

export enum getterNames {
  currentItem = 'currentItem',
  items = 'items',
  editorItem = 'editorItem',
  viewMode = 'viewMode',
  emptyFacility = 'emptyFacility',
  currentLeague = 'currentLeague',
}

export enum actionNames {
  create = 'create',
  update = 'update',
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  validate = 'validate',
  cache = 'cache',
  loadFromCache = 'loadFromCache',
  load = 'load',
  set = 'set',
  beginCreating = 'beginCreating',
  endCreating = 'endCreating',
  beginEdit = 'beginEdit',
  endEdit = 'endEdit',
  loadPracticeNights = 'loadPracticeNights',
  loadSelectedPracticeDays = 'loadSelectedPracticeDays',
  loadGroup = 'loadGroup',
  createNewFacility = 'createNewFacility',
  getEmptyFacility = 'getEmptyFacility',
  save = 'save',
  delete = 'delete',
  copyFacility = 'copyFacility',
  beginCreatingFrom = 'beginCreatingFrom',
}

export enum mutationNames {
  setCurrent = 'setCurrent',
  setItems = 'setItems',
  setInEdit = 'setInEdit',
  updateViewMode = 'updateViewMode',
  addItem = 'addItem',
  removeItem = 'removeItem',
  setPracticeNights = 'setPracticeNights',
  setPracticeDays = 'setPracticeDays',
  setInEditItem = 'setInEditItem',
  setCurrentLeague = 'setCurrentLeague',
  setEmptyFacility = 'setEmptyFacility',
}

interface LeagueFacilityState {
  currentItem: LeagueFacility
  items: LeagueFacility[]
  viewMode: viewModes
  practiceNights: LeaguePracticeNight[] | null
  practiceDays: string[]
  inEdit: LeagueFacility
  emptyFacility: LeagueFacility | null
  currentLeague: League
}

const mutations: MutationTree<LeagueFacilityState> = {
  [mutationNames.setCurrent](state: LeagueFacilityState, { item }: { item: LeagueFacility }) {
    state.currentItem = item
  },
  [mutationNames.setItems](state: LeagueFacilityState, { items }: { items: LeagueFacility[] }) {
    state.items = items

    if (items && items.length > 0) {
      state.currentItem = items[0]
    }
  },
  [mutationNames.updateViewMode](state: LeagueFacilityState, { item }: { item: viewModes }) {
    state.viewMode = item
  },
  [mutationNames.addItem](state: LeagueFacilityState, { item }: { item: LeagueFacility }) {
    state.items.push(item)
  },
  [mutationNames.removeItem](state: LeagueFacilityState, { id }: { id: number }) {
    const itemToRemove = state.items.find((f) => f.facilityID === id)

    if (itemToRemove) {
      const itemIndex = state.items.indexOf(itemToRemove)
      state.items.slice(itemIndex, 1)
    }
  },
  [mutationNames.setPracticeNights](state: LeagueFacilityState, { item }: { item: LeaguePracticeNight[] }) {
    state.practiceNights = item
  },
  [mutationNames.setPracticeDays](state: LeagueFacilityState, { item }: { item: string[] }) {
    state.practiceDays = item
  },
  [mutationNames.setInEditItem](state: LeagueFacilityState, { item }: { item: LeagueFacility }) {
    state.inEdit = item
  },
  [mutationNames.setCurrentLeague](state: LeagueFacilityState, { item }: { item: League }) {
    state.currentLeague = item
  },
  [mutationNames.setEmptyFacility](state: LeagueFacilityState, { item }: { item: LeagueFacility }) {
    state.emptyFacility = item
  },
}

const getters: GetterTree<LeagueFacilityState, RootState> = {
  [getterNames.currentItem]: (state: LeagueFacilityState) => state.currentItem,
  [getterNames.items]: (state: LeagueFacilityState) => state.items,
  [getterNames.emptyFacility]: () => getEmptyLeagueFacility(),
  [getterNames.editorItem]: (state: LeagueFacilityState) => state.inEdit,
  [getterNames.currentLeague]: (state: LeagueFacilityState) => state.currentLeague,
  [getterNames.viewMode]: (state: LeagueFacilityState) => state.viewMode,
}

const leagueFacilityState: LeagueFacilityState = {
  currentLeague: getEmptyLeague(),
  currentItem: getEmptyLeagueFacility(),
  inEdit: getEmptyLeagueFacility(),
  emptyFacility: null,
  items: new Array<LeagueFacility>(),
  viewMode: viewModes.list,
  practiceNights: [],
  practiceDays: [],
}

const actions: ActionTree<LeagueFacilityState, RootState> & ActionsImpl = {
  async [actionNames.load]({ commit }, { id }: { id: string }): Promise<LeagueFacility[] | null> {
    const facilities = await facilitiesClient.retrieveAll(id)

    const league = await leagueClient.retrieve(id)

    commit(mutationNames.setCurrentLeague, { item: league })

    if (facilities) {
      commit(mutationNames.setItems, { items: facilities })
      return facilities
    }

    return null
  },
  // eslint-disable-next-line require-await
  async [actionNames.set]({ commit }, { id }: { id: number }): Promise<LeagueFacility | null> {
    const facility = leagueFacilityState.items.find((item) => item.facilityID === id)

    if (facility) {
      commit(mutationNames.setCurrent, { item: facility })

      return facility
    }

    return null
  },
  // eslint-disable-next-line require-await
  async [actionNames.beginCreating]({ commit, state }): Promise<void> {
    commit(mutationNames.updateViewMode, { item: viewModes.create })

    const facility: LeagueFacility = getEmptyLeagueFacility()
    const credentials = jwtService.getCredentialsFromStoredAuthToken()
    const accountNumber = credentialsService.getPrimaryAccountFromCredentials(credentials)
    facility.accountNumber = (accountNumber || '').toString()
    facility.leagueID = state.currentLeague ? state.currentLeague.id : -1
    facility.availability = []

    commit(mutationNames.setInEditItem, { item: facility })
  },
  // eslint-disable-next-line require-await
  async [actionNames.beginCreatingFrom](
    { commit, state },
    { facility }: { facility: LeagueFacility }
  ): Promise<void> {
    const credentials = jwtService.getCredentialsFromStoredAuthToken()
    const accountNumber = credentialsService.getPrimaryAccountFromCredentials(credentials)
    facility.accountNumber = (accountNumber || '').toString()
    facility.leagueID = state.currentLeague ? state.currentLeague.id : -1

    commit(mutationNames.setInEditItem, { item: facility })
    commit(mutationNames.updateViewMode, { item: viewModes.create })
  },
  [actionNames.endCreating]({ commit }): void {
    commit(mutationNames.updateViewMode, { item: viewModes.list })
  },
  [actionNames.beginEdit]({ commit }): void {
    commit(mutationNames.updateViewMode, { item: viewModes.edit })
    commit(mutationNames.setInEditItem, { item: leagueFacilityState.currentItem })
  },
  [actionNames.endEdit]({ commit }): void {
    commit(mutationNames.updateViewMode, { item: viewModes.list })
  },
  // eslint-disable-next-line require-await
  async [actionNames.loadPracticeNights]({ commit, state }): Promise<LeaguePracticeNight[] | null> {
    let practiceNights: LeaguePracticeNight[] | null = []

    try {
      if (state.currentLeague) {
        commit(mutationNames.setPracticeNights, { item: state.currentLeague.practiceNights })
        // practice nights for facilities should used the days for the leagues, but ignore the league practice night times
        practiceNights = (state.currentLeague.practiceNights as LeaguePracticeNight[]).map((n) => ({
          typeDayID: n.typeDayID,
          startTime: FACILITY_DEFAULT_TIMES.start,
          endTime: FACILITY_DEFAULT_TIMES.end,
        }))

        return practiceNights
      }

      return null
    } catch (error) {
      throw new error('problem retrieving practice nights')
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [actionNames.copyFacility]({ commit, state }, { id }: { id: string }): Promise<void> {
    //todo: fill out
  },
  // the practice days selected at league set up
  async [actionNames.loadSelectedPracticeDays]({ commit }, { id }: { id: string }): Promise<string[] | null> {
    let selectedDays: string[] | null = []

    try {
      const program = await programsClient.retrieve(id)
      if (program) {
        commit(mutationNames.setPracticeDays, { item: program.practiceDays })
        selectedDays = program.practiceDays
      }
    } catch (error) {
      throw new error('problem retrieving program practice days')
    }

    return selectedDays
  },
  async [actionNames.loadGroup]({}, { id }: { id: string }): Promise<string[] | null> {
    const groupData = await facilitiesClient.retrieveGroups(id)

    if (groupData && groupData.data) {
      return groupData.data
    }
    return null
  },

  async [actionNames.validate]({ state }): Promise<boolean> {
    if (state.viewMode == viewModes.create) {
      const facility = state.inEdit
      const leagueId = state.currentLeague.upwardLeagueID || ''

      const validationResult = await facilitiesClient.validateNew(leagueId, facility)

      if (!validationResult.isSuccess) return false
    }

    return true
  },
  async [actionNames.save]({ commit, state }): Promise<LeagueFacility | null> {
    const facility = state.inEdit
    const leagueId = state.currentLeague.upwardLeagueID || ''

    if (state.viewMode === viewModes.create) {
      const createResults = await facilitiesClient.createNew(leagueId, facility)

      if (!createResults.isSuccess) {
        throw new Error('unable to create new Facility')
      }

      commit(mutationNames.addItem, { item: createResults.data })

      return createResults.data
    } else {
      //update facility
      const updateResults = await facilitiesClient.update(leagueId, facility)
      const facilities = await facilitiesClient.retrieveAll(leagueId)

      if (!updateResults.isSuccess) {
        throw updateResults.errorObject || new Error('unable to update Facility')
      }

      if (facilities) {
        commit(mutationNames.setItems, { items: facilities })
      }

      return updateResults.data
    }
  },
  async [actionNames.delete]({ state }): Promise<boolean> {
    const leagueID = state.currentLeague.upwardLeagueID

    if (leagueID) {
      const facilityID = state.currentItem.facilityID

      const deleteResult = await facilitiesClient.deleteFacility(leagueID, facilityID)

      return deleteResult.isSuccess
    }

    return false
  },
}

export const namespace = 'facilities'

export const facilities = {
  namespaced: true as true,
  state: leagueFacilityState,
  actions,
  getters,
  mutations,
  ...viewModes,
}
