import { ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import partnersClient from '@/clients/partnersClient'
import { ActionsImpl } from 'direct-vuex'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'
import { Partner } from '@/models/Partner'

import { defineGetters, defineMutations } from 'direct-vuex'

import programsClient from '@/clients/programsClient'

interface PartnerState {
  partnerInfo: AccountInfo | null
  partners: Partner[]
  hasAcceptedCurrencyWarning: boolean
}

const partnerState: PartnerState = {
  partnerInfo: {} as AccountInfo,
  partners: [] as Partner[],
  hasAcceptedCurrencyWarning: false,
}

export enum getterNames {
  hasInfo = 'hasInfo',
  partnerInfo = 'partnerInfo',
  partners = 'partners',
  isGlobal = 'isGlobal',
  hasAcceptedCurrencyWarning = 'hasAcceptedCurrencyWarning',
  currencyWarningNeeded = 'currencyWarningNeeded',
}

const getterTree = defineGetters<PartnerState>()({
  [getterNames.hasInfo]: (state) => !!state.partnerInfo && Object.keys(state.partnerInfo).length > 0,
  [getterNames.partnerInfo]: (state) => state.partnerInfo,
  [getterNames.partners]: (state) => state.partners,
  [getterNames.isGlobal]: (state) => state.partnerInfo?.isGlobal,
  [getterNames.hasAcceptedCurrencyWarning]: (state) => state.hasAcceptedCurrencyWarning,
  [getterNames.currencyWarningNeeded]: (state) => {
    return state.partnerInfo?.isGlobal && !state.hasAcceptedCurrencyWarning
  },
})

export enum mutationNames {
  setPartnerInfo = 'setPartnerInfo',
  setPartners = 'setPartners',
  setHasAcceptedCurrencyWarning = 'setHasAcceptedCurrencyWarning',
}

const mutations = defineMutations<PartnerState>()({
  [mutationNames.setPartnerInfo](state, { item }: { item: AccountInfo | null }) {
    state.partnerInfo = item
  },
  [mutationNames.setPartners](state, { item }: { item: Partner[] }) {
    state.partners = item
  },
  [mutationNames.setHasAcceptedCurrencyWarning](state, { hasAccecpted }: { hasAccecpted: boolean }) {
    state.hasAcceptedCurrencyWarning = hasAccecpted
  },
})

export enum actionNames {
  retrievePartnerInfo = 'retrievePartnerInfo',
  retrieveAndSetPartners = 'retrieveAndSetPartners',
}

const actions: ActionTree<PartnerState, RootState> & ActionsImpl = {
  async [actionNames.retrievePartnerInfo](
    { commit, getters },
    { accountNumber, force = false }: { accountNumber: string; force: boolean }
  ): Promise<AccountInfo | null> {
    if (
      getters[getterNames.hasInfo] &&
      getters[getterNames.partnerInfo]?.accountNumber == accountNumber && //4473
      !force
    ) {
      return null
    }
    const result = await partnersClient.getPartnerInfo(accountNumber)

    if (result) {
      commit(mutationNames.setPartnerInfo, { item: result })
      return result
    }

    return null
  },
  async retrieveAndSetPartners({ commit }, { id }: { id: string }): Promise<Partner[] | null> {
    const result = await programsClient.retrieve(id)

    if (result) {
      const accounts = result.myLeagueInfo?.accounts
      const partners: Partner[] | undefined = accounts?.map((a) => {
        return { ...a, name: a.partnerInfo?.name ?? '' }
      })
      const multiple = partners && partners.length > 1 ? true : false
      commit(mutationNames.setPartners, { item: multiple ? partners : [] })
    }

    return null
  },
}

export const namespace = 'partners'

export const partners = {
  namespaced: true as true,
  state: partnerState,
  getters: getterTree,
  mutations,
  actions,
}
