import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'

export function getEmptySavedProductOfferingConfig(): SavedProductOfferingConfig {
  return {
    id: 0,
    name: '',
    typeLeagueID: '',
    typeProductGroupID: '',
    accountNumber: '',
    playerEstimate: 0,
    cheerEstimate: 0,
    sportCoachEstimate: 0,
    cheerCoachEstimate: 0,
    firstPracticeDateEstimate: null,
    details: null,
    gradesByGender: null,
    startupOrderProducts: null,
  }
}
