import { Vue } from 'vue-property-decorator'
import Router from 'vue-router'

import programs from '@/router/programs'
import account from '@/router/account'
import training from '@/router/training'
import myProfile from '@/router/myProfile'
import adminDashboard from '@/router/adminDashboard'
import allAccess from '@/router/allAccess'

import Home from '@/views/Home.vue'
import WaitPage from '@/views/WaitPage.vue'
import Partner from '@/views/Partner.vue'
import Planning from '@/views/Planning.vue'
import Resources from '@/views/Resources.vue'
import Contact from '@/views/Contact.vue'
import ComponentTest from '@/views/ComponentTest.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import FeatureInterface from '@/views/FeatureInterface.vue'

Vue.use(Router)

export interface RouteMeta {
  isPublic: boolean
  isHome: boolean
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        isHome: true,
        isPublic: false,
      } as RouteMeta,
    },
    ...adminDashboard,
    ...allAccess,
    {
      path: '/waitpage',
      name: 'Wait Page',
      component: WaitPage,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    {
      path: '/planning',
      name: 'Planning',
      component: Planning,
      meta: {
        isHome: false,
        isPublic: false,
      } as RouteMeta,
    },
    ...programs,
    ...training,
    {
      path: '/resources',
      name: 'Resources',
      component: Resources,
      meta: {
        isHome: false,
        isPublic: false,
      } as RouteMeta,
    },
    {
      path: '/partner',
      name: 'Partner',
      component: Partner,
      meta: {
        isHome: false,
        isPublic: false,
      } as RouteMeta,
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        isHome: false,
        isPublic: false,
      } as RouteMeta,
    },
    ...account,
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    {
      path: '/expired-password',
      name: 'ExpiredPassword',
      component: ForgotPassword,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    {
      path: '/locked-account',
      name: 'LockedAccount',
      component: ForgotPassword,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    {
      path: '/resetpassword/:code?',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    {
      path: '/acceptinvite/:code?',
      name: 'AcceptInvite',
      component: ResetPassword,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    {
      path: '/componenttest/:name?',
      name: 'ComponentTest',
      component: ComponentTest,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    {
      path: '/features/',
      name: 'FeatureInterface',
      component: FeatureInterface,
      meta: {
        isHome: false,
        isPublic: true,
      } as RouteMeta,
    },
    ...myProfile,
  ],
})

export default router
