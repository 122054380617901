import { getProps as getBaseProps } from '@/composables/BaseInputWrapperProps'
import { ref, SetupContext } from '@vue/composition-api'

export const getProps = () => ({
  prependIcon: { type: String, default: '', required: false },
  appendIcon: { type: String, default: '', required: false },
  ...getBaseProps(),
})

export function useTextlike<T>(props: any, ctx: SetupContext) {
  const internalValue = ref<T>()

  function iconClick() {
    ctx.emit('icon-click', true)
  }

  function change() {
    ctx.emit('change', internalValue.value)
  }

  function blur() {
    ctx.emit('blur', internalValue.value)
  }

  function input() {
    ctx.emit('input', internalValue.value)
  }

  function handleInput() {
    input()
  }

  function handleBlur() {
    blur()
  }

  function handleChange() {
    change()
  }

  return {
    iconClick,
    internalValue,
    handleInput,
    handleBlur,
    handleChange,
    input,
    blur,
    change,
  }
}
