import uuid from 'uuid'

export const getProps = () => ({
  label: { type: String, default: '', required: false },
  required: { type: Boolean, default: false, required: false },
  enabled: { type: Boolean, default: true, required: false },
  subText: { type: String, default: '', required: false },
  id: { type: String, default: () => uuid.v4(), required: false },
  name: { type: String, default: '', required: false },
  size: { type: String, default: '', required: false },
  subLabel: { type: String, default: '', required: false },
  subLabelInline: { type: Boolean, default: false, required: false },
  VeeValidateRules: { type: String, default: '', required: false },
  veeValidateMode: { type: String, default: 'aggressive', required: false },
  showHelp: { type: Boolean, default: false, required: false },
  wrapperClass: { type: String, default: '', required: false },
  subTextWithLink: { type: String, default: '', required: false },
})
