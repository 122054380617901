























import { defineComponent } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

export default defineComponent({
  name: 'UpwardSystemsStep',
  props: { step: { type: Number, required: true } },
  components: { Step },
  setup() {
    const logic = productFlowLogic()
    const items = [
      {
        title: 'League Director Tools',
        iconClass: 'fas fa-user-shield fa-lg',
        bullets: [
          'Tools to setup your league and order/exchange products from Upward',
          'Tools to manage all your participants and volunteers.',
          'A wealth of league management resources to guide, train, and equip leaders of any level.',
          'League management functionality where you can draft teams and build schedules for practices, games, and facilities.',
          'Communication tools to easily text or email anyone within the entire program.',
          'Extensive reporting tools to help manage your program.',
        ],
      },
      {
        title: 'Coach Tools',
        iconClass: 'fas fa-chalkboard-teacher',
        bullets: [
          `UpwardSports app that includes coach training resources, ${
            logic.isUpwardSelect.value ? '' : 'practice plans, skills and drills videos, '
          } devotions, rosters, and other Upward specific functionality.`,
          'A communication system that will allow for text or email exchanges with parents on their teams.',
        ],
      },
      {
        title: 'Tools For Other Volunteers',
        iconClass: 'fas fa-users fa-lg',
        bullets: [
          'UpwardSports app that includes training resources, role-specific resources, and applicable communication resources.',
          'A role-specific app that equips and trains volunteers.',
        ],
      },
      {
        title: 'Parent Tools',
        iconClass: 'fas fa-user-friends fa-lg',
        bullets: [
          'A quick and easy player registration tool where Upward covers all credit card fees.',
          'Team pages that let parents access schedules and alerts from the league.',
        ],
      },
    ]
    return { items, ...logic }
  },
})
