















import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import store from '@/store'

export default defineComponent({
  name: 'OpportunityTypeSelect',
  components: {
    SelectInput,
  },
  props: {
    value: { required: true },
  },
  setup(props, ctx) {
    const items = computed(() => store.getters.opportunityTypes.allItems)

    const isLoading = ref(false)
    const selectedItem = ref(props.value)

    async function loadItems() {
      if (isLoading.value) {
        return
      }

      isLoading.value = true
      await store.dispatch.opportunityTypes.fetchAll(false)
      isLoading.value = false
    }

    onMounted(async () => {
      await loadItems()
    })

    watch(
      () => props.value,
      () => {
        selectedItem.value = props.value
      }
    )

    function onChange() {
      ctx.emit('input', selectedItem.value)
      ctx.emit('change', selectedItem.value)
    }

    return {
      isLoading,
      selectedItem,
      items,
      onChange,
    }
  },
})
