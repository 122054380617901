








































































import { defineComponent, computed, ref, watch, onBeforeMount } from '@vue/composition-api'

import { AgGridVue } from '@ag-grid-community/vue'
import { AllCommunityModules } from '@ag-grid-community/all-modules'

import {
  LeagueInfoMoreThanOneProgram,
  LeagueListItemSeed,
  getEmptyLeagueListItem,
} from '@/lib/support/models/LeagueListItem/data'
import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import { LeagueListItem } from '@/models/Program/LeagueListItem'

import reportsClient from '@/clients/reportsClient'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import loading from '@/elements/Loading.vue'
import store from '@/store'
import InfoIcon from '@/components/InfoIcon.vue'

export default defineComponent({
  name: 'ExcelReports',
  components: {
    AgGridVue,
    TypeProgramTab,
    loading,
    InfoIcon,
  },
  setup(props, ctx) {
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)

    const rowData = ref<any>(null)
    const gridOptions = ref<any>(null)
    const api = ref<any>(null)
    const modules = ref<any>(AllCommunityModules)

    const currentProgram = ref('')
    const currentReport = ref('')
    const currentSelectedReportType = ref('') //options: Participant or Volunteer
    const isLoading = ref(true)
    const isParticipant = ref(true)
    const isVolunteer = ref(false)

    function stringSorter(a: string, b: string) {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    }

    const reports = ref<{
      [reportName: string]: {
        name: string
        path: string
        showPrograms: boolean
        defaultColDefs?: {}
        columnDefs?: {}
        sortModel?: any[]
      }
    }>({
      participants: {
        name: 'Participants',
        path: 'participant',
        showPrograms: true,
        defaultColDefs: {
          unSortIcon: true,
        },
        columnDefs: {
          FirstName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          LastName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          IndividualId: {
            hide: true,
          },
        },
        sortModel: [{ colId: 'LastName', sort: 'asc' }],
      },
      churchAffiliation: {
        name: 'Church Affiliations',
        path: 'churchAffiliation',
        showPrograms: false,
        columnDefs: {
          FirstName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          LastName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          Guardian1FirstName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          Guardian1LastName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          Grade: {
            headerName: store.getters.leagueAbstraction.isByAge ? 'Age' : 'Grade',
          },
        },
      },
      coaches: {
        name: 'Coaches',
        path: 'coach',
        showPrograms: true,
        columnDefs: {
          FirstName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          LastName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          FemaleGradesToCoach: {
            headerName: store.getters.leagueAbstraction.isByAge ? 'FemaleAgesToCoach' : 'FemaleGradesToCoach',
          },
          MaleGradesToCoach: {
            headerName: store.getters.leagueAbstraction.isByAge ? 'MaleAgesToCoach' : 'MaleGradesToCoach',
          },
        },
      },
      referees: {
        name: `${store.getters.leagueAbstraction.refLabel}s`,
        path: 'referee',
        showPrograms: false,
        columnDefs: {
          FirstName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          LastName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
        },
      },
      commissioners: {
        name: 'Commissioners',
        path: 'commissioner',
        showPrograms: false,
        columnDefs: {
          FirstName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          LastName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
        },
      },
      volunteers: {
        name: 'Volunteers',
        path: 'volunteer',
        showPrograms: false,
        columnDefs: {
          FirstName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
          LastName: {
            sortingOrder: ['asc', 'desc'],
            comparator: stringSorter,
          },
        },
      },
      'game-schedule': {
        name: 'Game Schedule',
        path: 'gameschedule',
        showPrograms: true,
        columnDefs: {
          StartTimeSortOrder: {
            hide: true,
          },
        },
      },
      'practice-schedule': {
        name: 'Practice Schedule',
        path: 'practiceschedule',
        showPrograms: true,
        columnDefs: {
          StartTimeSortOrder: {
            hide: true,
          },
        },
      },
      'division-teams': {
        name: 'Division / Teams',
        path: 'divisionteam',
        showPrograms: true,
      },
      'camp-director': {
        name: 'Camp Director',
        path: 'campdirector',
        showPrograms: false,
        columnDefs: {
          IndividualId: {
            hide: true,
          },
        },
      },
    })

    const currentReportType = computed({
      get() {
        return currentSelectedReportType.value
      },
      set(reportType: string) {
        currentSelectedReportType.value = reportType

        if (reportType == 'PARTICIPANT') {
          isParticipant.value = true
          isVolunteer.value = false
        } else {
          isParticipant.value = false
          isVolunteer.value = true
        }
      },
    })

    const reportName = computed((): string => ctx.root.$route.params.reportName)

    const currentReportName = computed((): string => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return ''
      }
      return reports.value[reportName.value].name
    })

    const currentReportPath = computed((): string => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return ''
      }
      return reports.value[reportName.value].path
    })

    const displayProgramPath = computed((): boolean => {
      if (!reportName.value || !reports.value[reportName.value]) {
        return false
      }
      return reports.value[reportName.value].showPrograms
    })

    const showParticipantVolunteerTabs = computed((): boolean => {
      if (reportName.value && reportName.value == 'churchAffiliation') {
        return true
      }

      return false
    })

    const reportTypeParams = computed(() => {
      if (reportName.value && reportName.value == 'churchAffiliation') {
        return { ReportType: currentSelectedReportType.value } as Record<string, unknown>
      }

      return false
    })

    const columnDefs = computed((): any => {
      const report = reports.value[reportName.value]
      return report?.columnDefs ?? {}
    })

    function getColumnDefs(name: string) {
      return columnDefs.value[name] ?? {}
    }

    const defaultColDefs = computed((): any => {
      const report = reports.value[reportName.value]
      return report?.defaultColDefs ?? {}
    })

    function getDefaultColDefs() {
      return defaultColDefs.value ?? {}
    }

    const sortModel = computed(() => {
      const report = reports.value[reportName.value]
      return report.sortModel ?? []
    })

    const tabs = computed(
      (): LeagueListItem => {
        if (programs.value) {
          return LeagueProgams2LeagueListItem(programs.value)
        }
        return getEmptyLeagueListItem(new LeagueListItemSeed())
      }
    )

    const programs = computed(() => {
      if (isCamp.value) {
        return camp.value.programs
      }
      return league.value.programs
    })

    onBeforeMount(() => {
      gridOptions.value = {}
    })

    const isCamp = computed((): boolean => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    async function onReady(params: any) {
      api.value = params.api

      if (programs.value) {
        currentProgram.value = programs.value[0].typeProgramID
      }

      if (currentReportPath.value) {
        await loadReport(currentReportPath.value)
      }

      currentSelectedReportType.value = 'PARTICIPANT'
    }

    function exportGrid() {
      api.value.exportDataAsCsv()
    }

    watch(
      () => currentProgram.value,
      async () => {
        await loadReport(currentReport.value)
      }
    )

    watch(
      () => currentReportPath.value,
      async () => {
        if (currentReportPath.value) {
          await loadReport(currentReportPath.value)
        }
      }
    )

    watch(
      () => currentSelectedReportType.value,
      async () => {
        await loadReport(currentReport.value)
      }
    )

    async function loadReport(report: string) {
      isLoading.value = true
      if (report) {
        currentReport.value = report

        let columns = []

        try {
          let dataSource = await reportsClient.getReport(
            report,
            ctx.root.$route.params.id,
            currentProgram.value,
            reportTypeParams.value
          )
          if (!dataSource) {
            dataSource = []
          }

          if (dataSource.length > 0) {
            var fieldNames = Object.keys(dataSource[0])
            var defColDefs = getDefaultColDefs()
            for (var name of fieldNames) {
              columns.push({
                field: name,
                headerName: name,
                ...defColDefs,
                ...getColumnDefs(name),
              })
            }
          }

          rowData.value = dataSource
          // need to clear columns before setting columns
          api.value.setColumnDefs([])
          api.value.setColumnDefs(columns)
          api.value.setSortModel(sortModel.value)
        } catch (err) {
          rowData.value = []
        }
      }
      isLoading.value = false
    }

    return {
      LeagueInfoMoreThanOneProgram,
      currentReportName,
      tabs,
      displayProgramPath,
      currentProgram,
      showParticipantVolunteerTabs,
      isParticipant,
      currentReportType,
      isVolunteer,
      isLoading,
      exportGrid,
      gridOptions,
      rowData,
      modules,
      onReady,
    }
  },
})
