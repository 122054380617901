




































import CampSetupMixin from '@/views/Camps/CampSetupMixin'

import Loading from '@/elements/Loading.vue'
import DateInput from '@/elements/DateInput.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import { getDateWithTime } from '@/lib/support/utilities/time/time'
import EvaluationForm, { EFTypes } from '@/components/EvaluationForm/EvaluationForm.vue'

import { Component, Mixins } from 'vue-property-decorator'

let useTestData: any = null
if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
  import('@/views/Camps/testData/keydates').then((x) => (useTestData = x))
}

@Component({
  components: {
    DateInput,
    Loading,
    EvaluationForm,
    SaveContinueButton,
  },
})
export default class KeyDates extends Mixins(CampSetupMixin) {
  efTypes = EFTypes

  mounted() {
    if (this.camp.upwardLeagueID) {
      this.nextRoute = `/programs/camp/${this.camp.upwardLeagueID}/setup/leadership`
    } else {
      this.nextRoute = '/programs/camp/setup/leadership'
    }

    if (useTestData) {
      useTestData.setKeyDatesForNewCamp(this.camp)
    }

    this.beforeSave = this.setDefaults
  }

  // eslint-disable-next-line require-await
  private async setDefaults() {
    if (
      this.camp.registrationBeginDate &&
      this.camp.registrationInfo &&
      !this.camp.registrationInfo.onlineRegistrationBeginDate
    ) {
      this.camp.registrationInfo.onlineRegistrationBeginDate = getDateWithTime(
        this.camp.registrationBeginDate,
        0,
        0,
        0
      )
    }

    // Changes to early registration end date needs to update registration fee to pass validation.
    if (this.camp.upwardLeagueID && this.camp.lateDate && this.camp.programs) {
      if (this.camp.programs.length > 0 && !this.camp.programs[0].earlyRegistrationFee) {
        this.camp.programs[0].earlyRegistrationFee = this.camp.programs[0].registrationFee
      }

      if (this.camp.programs.length > 1 && !this.camp.programs[1].earlyRegistrationFee) {
        this.camp.programs[1].earlyRegistrationFee = this.camp.programs[1].registrationFee
      }
    }

    if (this.camp.upwardLeagueID && !this.camp.lateDate && this.camp.programs) {
      if (this.camp.programs.length > 0) {
        this.camp.programs[0].earlyRegistrationFee = null
      }

      if (this.camp.programs.length > 1) {
        this.camp.programs[1].earlyRegistrationFee = null
      }
    }
  }
}
