












import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import reportsClient from '@/clients/reportsClient'
import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import store from '@/store'

export default defineComponent({
  name: 'CoachRoster',
  components: {
    ActiveReport,
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const loading = ref(true)
    const loadStatus = ref('')

    const upwardLeagueId = computed(() => ctx.root.$route.params.id)

    const typeProgramId = computed(() => ctx.root.$route.params.typeProgramId)

    const teamId = computed(() => ctx.root.$route.params.teamId)

    onMounted(async () => {
      await loadReport()
    })

    function reportLoaded() {
      loading.value = false
    }

    async function loadReport() {
      loadStatus.value = 'Downloading Report Data . . .'

      const data = await Promise.all([
        reportsClient.getCoachRosterByTeamRosterID(
          upwardLeagueId.value,
          accountNumber.value,
          typeProgramId.value,
          teamId.value
        ),
        reportsClient.getCoachSubReport(
          upwardLeagueId.value,
          accountNumber.value,
          '',
          '',
          typeProgramId.value
        ),
      ])

      loadStatus.value = 'Downloading Report Templates . . .'
      const reportTemplates = await Promise.all([
        reportsClient.getReportTemplate('coachroster'),
        reportsClient.getReportTemplate('coachsubreport'),
      ])

      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')
      const subReport = JSON.parse(reportTemplates[1]?.jsonContent ?? '')
      const mainData = data[0]
      const subData = data[1]
      report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)
      subReport.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(subData)
      const resourceLocator = {
        getResource: function (name: string) {
          if (name === 'coach-sub-report.rdlx-json') return subReport
        },
      }

      loadStatus.value = 'Loading Report . . .'
      const activeReport: any = ctx.refs.activereport
      activeReport.loadReportData(report, { ResourceLocator: resourceLocator })
    }

    return {
      loadStatus,
      loading,
      reportLoaded,
    }
  },
})
