



























































import uuid from 'uuid'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import EditBypassLink from '@/components/EditBypassLink.vue'
import { LeagueRegistrationBypassLink } from '@/GeneratedTypes/LeagueRegistrationBypassLink'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '../models/Grid/GridColumn'
import { SaveIcon } from '@/elements/Icons'
import dayjs from 'dayjs'

@Component({
  components: {
    Modal,
    TextInput,
    EditBypassLink,
    SimpleGrid,
    SaveIcon,
  },
})
export default class ManageBypassLinks extends Vue {
  private readonly guid = uuid.v4()
  private modalIsVisible = false
  private defaultManageButtonTitle = 'Manage Private Registration Links'
  private manageButtonTitle = this.defaultManageButtonTitle
  private modalTitle = 'Manage Private Registration Links'
  private wasCancelled = false
  private showEditScreen = false
  private activeBypassLink: LeagueRegistrationBypassLink | null = null

  public get gridId() {
    return `grid-${this.guid}`
  }

  @Prop({ required: true })
  private readonly bypassLinks!: LeagueRegistrationBypassLink[] | null

  @Prop({ required: true })
  private readonly maxDate!: Date | null

  @Prop({ required: true })
  private readonly upw!: string | null

  private gridColumns: GridColumn[] = [
    {
      field: 'urlSuffix',
      headerText: 'URL Suffix',
      textAlign: '',
      width: 0,
      format: '',
      customRender: this.renderURLSuffix,
    } as GridColumn,
    {
      field: 'startDate',
      headerText: 'Start Date',
      textAlign: '',
      width: 0,
      format: '',
      customRender: this.renderDate,
    } as GridColumn,
    {
      field: 'endDate',
      headerText: 'End Date',
      textAlign: '',
      width: 0,
      format: '',
      customRender: this.renderDate,
    } as GridColumn,
    {
      field: 'maxUsages',
      headerText: 'Max Usages',
      textAlign: '',
      width: 0,
      format: '',
    } as GridColumn,
    {
      field: 'numberOfUsages',
      headerText: '# of Usages',
      textAlign: '',
      width: 0,
      format: '',
    } as GridColumn,
    {
      field: 'ignoreCapacityRules',
      headerText: 'Ignore Capacity Rules',
      textAlign: 'center',
      width: 0,
      format: '',
      customRender: this.renderIgnore,
    } as GridColumn,
    {
      field: 'active',
      headerText: 'Active',
      textAlign: 'center',
      width: 0,
      format: '',
      customRender: this.renderActive,
    } as GridColumn,
  ]

  private internalBypassLinks: LeagueRegistrationBypassLink[] = []

  @Watch('modalIsVisible')
  private onModalVisibilityChanged() {
    this.wasCancelled = false
  }

  @Watch('internalBypassLinks.length')
  private onInternalBypassLinksChanged() {
    this.manageButtonTitle = `${this.defaultManageButtonTitle} ${
      this.internalBypassLinks.length > 0 ? '(' + this.internalBypassLinks.length + ')' : ''
    }`
  }

  private done() {
    this.$emit('onSave', this.bypassLinks)
    this.toggleModal()
  }

  private toggleModal() {
    this.modalIsVisible = !this.modalIsVisible
  }

  private mounted() {
    if (this.bypassLinks) {
      this.internalBypassLinks = this.bypassLinks
    } else {
      this.internalBypassLinks = []
    }
  }

  private saveBypassLink(bypassLink: LeagueRegistrationBypassLink) {
    if (bypassLink.bypassLinkID === 0) {
      bypassLink.bypassLinkID = -1 * (this.bypassLinks!.length + 1)
      this.bypassLinks?.push(bypassLink)
      this.internalBypassLinks = this.bypassLinks!
    }

    this.activeBypassLink = null
  }

  private handleEditBottonClicked(entity: any) {
    this.activeBypassLink = entity
    this.showEditScreen = true
  }

  private handleDeleteBottonClicked(entity: any) {
    const index = this.internalBypassLinks.indexOf(entity)
    if (index < 0) {
      return
    }

    this.internalBypassLinks.splice(index, 1)
  }

  private renderActive(value: any) {
    if (value) {
      return '<div class="text-center green--text">Yes</div>'
    }
    return '<div class="text-center red--text">No</div>'
  }

  private renderIgnore(value: any) {
    if (value) {
      return '<div class="text-center red--text">Yes</div>'
    }
    return '<div class="text-center green--text">No</div>'
  }

  private renderDate(value: Date) {
    return dayjs(value).format('M/D/YY h:mm a')
  }

  private renderURLSuffix(value: string) {
    let retval = value
    if (this.upw) {
      const bypassLink = this.internalBypassLinks.find((x) => x.urlSuffix == value)
      if (bypassLink && bypassLink.bypassLinkID > 0) {
        const url = `${process.env.VUE_APP_PARTICIPANT_REGISTRATION_DOMAIN}${this.upw}/c/${value}`
        retval = `<a href="${url}" target="_blank">${value}</a>`
      }
    }

    return retval
  }
}
