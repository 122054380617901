









import { defineComponent, computed } from '@vue/composition-api'

import VerticalTabs from '@/components/VerticalTabs.vue'

export default defineComponent({
  name: 'Billing',
  components: {
    VerticalTabs,
  },
  setup() {
    const baseRoute = `/account/billing`

    const tabs = computed(() => [
      { text: 'EFT Reports', to: `${baseRoute}/eft` },
      { text: 'Military Discount', to: `${baseRoute}/military` },
      { text: 'Grant Application', to: `${baseRoute}/grant` },
      { text: 'Billing Questions?', to: `${baseRoute}/questions` },
    ])

    return {
      tabs,
    }
  },
})
