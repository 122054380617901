// This Is Proper Case
// This is sentence case
// ThisIsPascalCase
// thisIsCamelCase
export default (value: string | null | undefined) => {
  if (!value) {
    return ''
  }

  // https://www.codeproject.com/articles/11009/proper-case-javascript-function
  return value.toLowerCase().replace(/^(.)|\s(.)/g, ($1) => $1.toUpperCase())
}
