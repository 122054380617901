




































import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { OrderFindInfo } from '@/GeneratedTypes/Order/ListInfo/OrderFindInfo'
import TextInput from '@/elements/TextInput.vue'
import Modal from '@/components/Modal.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'

export default defineComponent({
  name: 'HoldReasonDialog',
  props: {
    order: { type: Object as PropType<OrderFindInfo>, required: false },
    visible: { type: Boolean, required: true },
  },
  components: { VeeValidateForm, TextInput, Modal },
  setup(props, ctx) {
    const reason = ref('')
    const internalVisibleRef = ref(false)

    watch(
      () => props.visible,
      () => {
        if (props.visible != internalVisibleRef.value) {
          internalVisibleRef.value = props.visible
        }
      },
      { immediate: true }
    )

    function close(b: boolean) {
      internalVisibleRef.value = b
      if (!b) {
        reason.value = ''
        ctx.emit('close')
      }
    }
    function confirm() {
      ctx.emit('confirm', props.order, reason.value)
    }

    return { reason, confirm, internalVisibleRef, close }
  },
})
