


















import { getFilteredProductList, OrderProductLine } from '@/models/Order/OrderProduct.ts'
import store from '@/store'
import { computed, defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import { ProductSelectorLogic } from '@/views/Programs/Orders/compositions/useProductSelector'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import ProductQuantitySelector from '@/views/Programs/Orders/vues/components/ProductQuantitySelector.vue'
import ProductSelect from '@/components/Orders/ProductSelect.vue'
import ordersClient from '@/clients/ordersClient'
import { UpwardSizeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardSizeTypeID'

export default defineComponent({
  name: 'SelectQuantities',
  components: {
    ProductSelect,
    ProductQuantitySelector,
  },
  props: {
    selectorMethod: { type: Function as PropType<ProductSelectorLogic>, required: true },
    orderType: { type: String as PropType<OrderTypesEnum>, required: true },
  },

  setup(props, ctx) {
    const currentOrderProducts = computed(() => store.getters.orders.currentOrderProducts)
    const setProductQuantity = store.dispatch.orders.setProductQuantity
    const ps = props.selectorMethod()
    const sizes = ref<UpwardSizeTypeID[]>([])

    onMounted(async () => {
      sizes.value = await ordersClient.getSizes()
    })
    computed(async () => {
      return await ordersClient.getSizes()
    })
    const hasQuantities = computed(
      () => !!getFilteredProductList(currentOrderProducts.value, ps.isSelected).length
    )
    const showIsFreeCheckbox = computed(() => props.orderType == OrderTypesEnum.manual)

    const isErrorOrder = computed(
      () =>
        props.orderType == OrderTypesEnum.manual &&
        (store.getters.orders.currentOrderInternalOrderType ?? '').startsWith('X')
    )

    /**
     * Disable or enable the next button based on product quantities selected.
     */
    watch(
      () => hasQuantities.value,
      (b) => {
        if (b) {
          ctx.emit('on-enable')
        } else {
          ctx.emit('on-disable')
        }
      },
      { immediate: true }
    )

    /**
     * Update the store with the quantity specified.
     * @param product
     */
    async function update(product: OrderProductLine) {
      await setProductQuantity({
        orderType: props.orderType,
        productID: product.id,
        colorID: product.productColorSize.typeColorID,
        sizeID: product.productColorSize.typeSizeID,
        quantity: product.orderQuantity,
        quantityFree: product.orderFreeQuantity,
      })
    }

    return { update, ...ps, sizes, showIsFreeCheckbox, isErrorOrder }
  },
})
