export interface OrderXMLEdit {
  upwardOrderID: string
  orderXML: string
  reason: string | null
  clearNotification: boolean
}

export function getEmptyOrderXMLEdit() {
  return {
    upwardOrderID: '',
    orderXML: '',
    reason: null,
    clearNotification: false,
  }
}
