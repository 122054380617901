import { RouteConfig } from 'vue-router'
import Index from '@/views/Account/Index.vue'
import UserManagement from '@/views/Account/UserManagement.vue'
import Billing from '@/views/Account/Billing.vue'
import PartnerSocialMedia from '@/views/Account/PartnerSocialMedia.vue'
import EftReports from '@/views/Account/EftReports.vue'
import Questions from '@/views/Account/Questions.vue'
import MilitaryDiscount from '@/views/Account/MilitaryDiscount.vue'
import GrantApplication from '@/views/Account/GrantApplication.vue'

const baseName = 'Account'

export default [
  {
    path: '/account',
    component: Index,
    children: [
      {
        path: '',
        redirect: '/account/billing/eft',
      },
      {
        path: 'usermanagement',
        name: `${baseName}-User-Management`,
        component: UserManagement,
      },
      {
        path: 'billing',
        name: `${baseName}-Billing`,
        component: Billing,
        children: [
          {
            path: 'eft',
            name: `EFT Reports`,
            component: EftReports,
          },
          {
            path: 'military',
            name: `Military Discounts`,
            component: MilitaryDiscount,
          },
          {
            path: 'grant',
            name: `Upward Assist Grant Application`,
            component: GrantApplication,
          },
          {
            path: 'questions',
            name: `Billing Questions`,
            component: Questions,
          },
        ],
      },
      {
        path: 'socialmedia',
        name: `${baseName}-Social-Media`,
        component: PartnerSocialMedia,
      },
    ],
  },
] as RouteConfig[]
