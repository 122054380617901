











































































// TODO: Once warnings are included in the API, make sure they show (already in markup)

import { defineComponent, computed, PropType } from '@vue/composition-api'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import ProgramIcon from '@/components/ProgramIcon.vue'
import date from '@/filters/date'
import ProgramOverview from '@/components/ProgramOverview.vue'

export default defineComponent({
  name: 'CampOverview',
  components: {
    ProgramIcon,
    ProgramOverview,
  },
  filters: {
    date,
  },
  props: {
    program: { type: Object as PropType<LeagueInfoCondensed>, required: true },
    showNameOnly: { type: Boolean, required: false, default: false },
    link: { type: String, required: false, default: '' },
  },
  setup(props) {
    const registrationUrl = computed(
      () => `${process.env.VUE_APP_PARTICIPANT_REGISTRATION_DOMAIN}${props.program.upwardLeagueID}`
    )

    const volunteerApplicationUrl = computed(
      () => `${process.env.VUE_APP_PARTICIPANT_REGISTRATION_DOMAIN}${props.program.upwardLeagueID}/volunteer`
    )

    const baseLink = computed(() => {
      const base = `/programs/camp/${props.program.upwardLeagueID}`
      if (props.link) {
        return `${base}${props.link}`
      }
      return base
    })

    const defaultLink = computed(() => {
      if (!props.showNameOnly) {
        return `${baseLink.value}/setup/information`
      }
      return baseLink.value
    })

    return {
      defaultLink,
      baseLink,
      registrationUrl,
      volunteerApplicationUrl,
    }
  },
})
