













import { defineComponent, computed } from '@vue/composition-api'

import CheckboxInput from '@/elements/CheckboxInput.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

export default defineComponent({
  name: 'TeamColumnSelector',
  components: { CheckboxInput },
  setup() {
    const { setTeamColumns, getTeamColumns, usingHeight, usingScore, usingPosition } = userPreferences()

    const update = (filterID: string) => {
      setTeamColumns(filterID)
    }

    const columnsList = () => {
      let columns = getTeamColumns.value

      if (!usingHeight.value) columns = columns.filter((c) => c.id != 'height')
      if (!usingPosition.value) columns = columns.filter((c) => c.id != 'position')
      if (!usingScore.value) columns = columns.filter((c) => c.id != 'score')

      return columns
    }

    return {
      update,
      columns: computed(columnsList),
    }
  },
})
