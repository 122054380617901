import { leagueScoringTypes } from '@/store/leagueScoringTypes'

export function isNoStanding(typeLeagueScoringID: string | null) {
  let retval = false
  const leagueScoring = leagueScoringTypes.state.items.find((x) => x.upwardTypeID === typeLeagueScoringID)
  if (leagueScoring) {
    retval = leagueScoring.isNoStanding!
  }

  return retval
}

export function isWinLoss(typeLeagueScoringID: string | null) {
  let retval = false
  const leagueScoring = leagueScoringTypes.state.items.find((x) => x.upwardTypeID === typeLeagueScoringID)
  if (leagueScoring) {
    retval = leagueScoring.isWinLoss!
  }

  return retval
}

export function isWinLossScoring(typeLeagueScoringID: string | null) {
  let retval = false
  const leagueScoring = leagueScoringTypes.state.items.find((x) => x.upwardTypeID === typeLeagueScoringID)
  if (leagueScoring) {
    retval = leagueScoring.isWinLossScoring!
  }

  return retval
}

export const LeagueScoringTypes: Record<string, string> = {
  NOSTANDINGS: 'NOSTANDINGS',
  WIN_LOSS: 'WIN_LOSS',
  WIN_LOSS_SCORING: 'WIN_LOSS_SCORING',
}
