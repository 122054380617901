import { RestServiceResult } from '@/services/restService'
import { uniq } from 'lodash'

interface RestResultArray<T> extends RestServiceResult<T[]> {
  data: Array<T> | null
}

export function CombineDataArray<T>(values: RestResultArray<T>[]) {
  // pick both or one, or none of the values
  if (values[0].data && values[1].data) {
    return uniq([...values[0].data, ...values[1].data])
  }
  if (values[0].data && !values[1].data) {
    return values[0].data
  }
  if (values[1].data && !values[0].data) {
    return values[1].data
  }
  return []
}
