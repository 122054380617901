




import { defineComponent, computed } from '@vue/composition-api'
import CoachApp from '@/CoachApp.vue'
import MainApp from '@/MainApp.vue'
import store from '@/store'

export default defineComponent({
  name: 'App',
  components: { CoachApp, MainApp },
  setup(props, ctx) {
    const isCurrentUserASuperUser = computed(() => store.getters.authorization.isCurrentUserASuperUser)
    const userToken = computed(() => store.getters.authorization.userData)
    const isAuthenticated = computed(() => store.getters.authorization.isAuthenticated)
    const isMultiAccountUser = computed(() => store.getters.authorization.isMultiAccountUser)
    const displayComponent = computed(() => {
      if (ctx.root.$router.currentRoute.path.indexOf('/allaccess/') === 0) {
        return CoachApp
      }

      if (isCurrentUserASuperUser.value || !isAuthenticated.value || isMultiAccountUser.value) {
        return MainApp
      }

      redirect()
    })

    function redirect() {
      const url = `${process.env.VUE_APP_URL_MOBILE_REDIRECT}?token=${encodeURIComponent(
        userToken.value ?? ''
      )}`
      store.commit.authorization.clearCurrentCredentials()
      location.href = url
    }

    return { displayComponent }
  },
})
