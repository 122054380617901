























































import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'
import divisionsClient from '@/clients/divisionsClient'
import teamsClient from '@/clients/teamsClient'

import SelectInput from '@/elements/SelectInput.vue'

import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import { isCheer } from '@/lib/common/ProgramTypes'
import store from '@/store'

export default defineComponent({
  name: 'ParticipantIntros',
  components: {
    SelectInput,
    ActiveReport,
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const selectedProgram = ref('')

    const selectedDivision = ref(-1)
    const divisions = ref([{}])

    const selectedTeam = ref(-1)
    const teams = ref([{}])

    const loading = ref(false)
    const loadStatus = ref('')

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    const programs = computed((): string[] => {
      if (isCamp.value) {
        return camp.value.programs?.map((p) => p.typeProgramID) ?? []
      }
      return league.value.programs?.map((p) => p.typeProgramID) ?? []
    })

    const hasCheer = computed((): boolean => {
      if (isCamp.value) {
        return false
      }

      return (league.value && league.value.programs?.some((p) => isCheer(p.typeProgramID))) ?? false
    })

    onMounted(async () => {
      selectedProgram.value = typeProgramID.value

      await loadTeams()
    })

    watch(
      () => selectedProgram.value,
      async () => {
        selectedDivision.value = -1
        divisions.value = []
        const myDivisions =
          (await divisionsClient.retrieveByProgram(upwardLeagueId.value, selectedProgram.value)) ?? []

        //default is all
        divisions.value.push({
          divisionID: 0,
          divisionName: 'All' ?? '',
        })

        myDivisions.forEach((r) =>
          divisions.value.push({
            divisionID: r.divisionID,
            divisionName: r.divisionName ?? '',
          })
        )
      }
    )

    watch(
      () => selectedDivision.value,
      async () => {
        await loadTeams()
      }
    )

    async function loadTeams() {
      selectedTeam.value = -1
      teams.value = []
      const myTeams =
        (await teamsClient.retrieveTeamList({
          leagueId: upwardLeagueId.value,
          divisionId: selectedDivision.value,
          typeProgramId: selectedProgram.value,
        })) ?? []

      //default is all
      teams.value.push({
        teamID: 0,
        teamName: 'All' ?? '',
      })

      myTeams.forEach((r) =>
        teams.value.push({
          teamID: r.teamID,
          teamName: r.teamName ?? '',
        })
      )
    }

    function reportLoaded() {
      loading.value = false
    }

    const incomplete = computed(() => selectedDivision.value == -1 || selectedTeam.value == -1)

    async function loadReport() {
      loading.value = true
      loadStatus.value = 'Downloading Report Data . . .'
      const data = await Promise.all([
        reportsClient.getParticipantIntroReport(
          upwardLeagueId.value,
          accountNumber.value,
          selectedTeam.value.toString(),
          selectedDivision.value.toString(),
          selectedProgram.value
        ),
        reportsClient.getCoachSubReport(
          upwardLeagueId.value,
          accountNumber.value,
          selectedTeam.value.toString(),
          selectedDivision.value.toString(),
          selectedProgram.value
        ),
      ])

      loadStatus.value = 'Downloading Report Templates . . .'
      const reportTemplates = await Promise.all([
        reportsClient.getReportTemplate('participantintros'),
        reportsClient.getReportTemplate('coachsubreport'),
      ])

      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')
      const subReport = JSON.parse(reportTemplates[1]?.jsonContent ?? '')

      const mainData = data[0]
      const subData = data[1]

      report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)
      subReport.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(subData)

      const resourceLocator = {
        getResource: function (name: string) {
          if (name === 'coach-sub-report.rdlx-json') return subReport
        },
      }

      loadStatus.value = 'Loading Report . . .'
      const activeReport: any = ctx.refs.activereport
      activeReport.loadReportData(report, { ResourceLocator: resourceLocator })
    }

    return {
      hasCheer,
      loading,
      programs,
      selectedProgram,
      divisions,
      selectedDivision,
      teams,
      selectedTeam,
      loadStatus,
      incomplete,
      loadReport,
      reportLoaded,
    }
  },
})
