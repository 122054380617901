















































import { defineComponent, computed, ref, onMounted, PropType } from '@vue/composition-api'
import currency from '@/filters/currency'
import { maxStringLength } from '@/filters/maxStringLength'
import { getEmptyProductOfferingInfo } from '@/lib/support/models/ProductOfferingInfo/ProductOfferingInfo'
import { CostOffsetLabelInfo } from '@/GeneratedTypes/ListInfo/CostOffsetLabelInfo'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

import store from '@/store'

interface MinInterface {
  cmsProductName: string | null
  cmsProductDescription: string | null
  cmsUnitDescription: string | null
  cmsProductImageUrls?: string[] | null
  cost?: number
  productCost?: number
  productID?: number
  id?: number
  defaultGPTypePriceLevelID?: string
  productCostOffsetLabels?: CostOffsetLabelInfo[]
}

interface DisplayPrice {
  label: string
  formattedCost: string
}

export default defineComponent({
  name: 'ProductDetail',
  props: {
    product: {
      type: Object as PropType<MinInterface>,
      default: getEmptyProductOfferingInfo,
      required: false,
    },
    showPrice: { type: Boolean, default: true, required: false },
    small: { type: Boolean, default: true, required: false },
  },
  setup(props) {
    const savedProductOffering = computed(() => store.getters.productOfferings.productOfferingConfig)
    const selectedProductOfferings = computed(() => store.getters.productOfferings.selectedProductOfferings)
    const currentImg = ref(0)
    const showTestData = ref(false)

    onMounted(() => {
      if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
        showTestData.value = true
      }
    })

    const images = computed(() => {
      const imgs = props.product.cmsProductImageUrls
      if (!imgs || imgs.length === 0) {
        return ['/img/noImgSmall.png']
      }
      return imgs
    })

    function description(productDescription: string): string {
      if (props.small) return maxStringLength(productDescription, 200)
      return productDescription
    }

    function getUnitDescription<T extends MinInterface>(product: T): string {
      return product.cmsUnitDescription || ''
    }

    function getPrices<T extends MinInterface>(product: T): DisplayPrice[] | null | undefined {
      let prices: DisplayPrice[] = []
      let cost = 0
      const lockedInCost = lockedInProductCost.value
      if (lockedInCost) cost = lockedInCost //for previously saved items
      if (product.cost) cost = product.cost // for StartupOrderProductInfo or ProductOfferingInfo
      if (product.productCost) cost = product.productCost //ProductOfferingProductInfo
      if (product.productCostOffsetLabels && product.productCostOffsetLabels.length > 0) {
        prices = product.productCostOffsetLabels.map((o) => {
          return {
            label: o.label,
            formattedCost: cost == 0 ? 'FREE' : currency(cost + o.costOffset),
          } as DisplayPrice
        })
      } else {
        //CAMPS
        if (store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.CAMP) {
          //ProductOfferingInfo only
          if (product.cost == 0) {
            prices.push({ label: '', formattedCost: 'Click next to see price' })
          } else {
            prices.push({ label: '', formattedCost: currency(cost) ?? '' })
          }
        } else {
          //NOT CAMP
          prices.push({ label: '', formattedCost: cost == 0 ? 'FREE' : currency(cost) ?? '' })
        }
      }
      return prices
    }

    const lockedInProductCost = computed((): number | null => {
      //StartupOrderProductInfo
      if (props.product.hasOwnProperty('defaultGPTypePriceLevelID')) {
        // There are no locked in prices for StartupOrderProductInfo
        return null
      }

      // ProductOfferingProductInfo
      if (props.product.productID) {
        const savedProduct = savedProductOffering.value.details?.find(
          (p) => p.productID === props.product.productID
        )
        if (savedProduct) {
          return savedProduct.lockedInCost
        }
      }

      // ProductOfferingInfo
      if (props.product.id) {
        const offering = selectedProductOfferings.value.find((o) => o.id == props.product.id)
        return offering?.cost ?? null
      }

      return null
    })

    return {
      currentImg,
      images,
      showTestData,
      description,
      getPrices,
      getUnitDescription,
    }
  },
})
