import restService from '@/services/restService'
import { RestServiceResult } from '@/services/restService'
import { League } from '@/GeneratedTypes/League'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { LeagueProgramAugusta } from '@/models/League/LeagueProgramAugusta'
import { CarPoolLinkLabelOverrideInfo } from '@/GeneratedTypes/ListInfo/CarPoolLinkLabelOverrideInfo'
import { LeagueTypes } from '@/lib/common/LeagueTypes'

type AwardProductOptionInfo = Record<string, unknown>

const baseLeagueUrl = 'Leagues/'
const baseSelectUrl = 'Selects/'

function getUrl(league: League) {
  return league.typeLeagueID === LeagueTypes.UPWARDSELECT ? baseSelectUrl : baseLeagueUrl
}

class LeagueClientException extends Error {}

const create = async (league: League) => {
  return await restService.post<League>(`${getUrl(league)}`, league)
}

const update = async (id: string, league: League) => {
  return await restService.put<League>(`${getUrl(league)}${id}`, league)
}

const retrieve = async (id: string) => {
  const c = await restService.get<League>(`${baseLeagueUrl}${id}`) //API will figure out which type to return (League vs Select)
  if (c.isSuccess) {
    return c.data
  }
  throw new LeagueClientException('retrieve failed on id = ' + id)
}

const noProdCatalogRule =
  'rule://upward.business.setdefaultproductgroup-upward.business.league-/BaseFirstPracticeDate'

const validateNew = async (league: League, ruleSet = '') => {
  try {
    return await restService.post<UpwardVerificationDetails>(`${getUrl(league)}validate`, league, {
      params: {
        ruleSet,
      },
    })
  } catch (err: any) {
    if (err.data && err.data.brokenRules) {
      const e = err.data as UpwardVerificationDetails
      const i = e.brokenRules.findIndex((r) => r.messageID == noProdCatalogRule)
      if (i > -1) {
        e.brokenRules[i].message =
          'The dates you selected are too far in the future to create a league at this time.'
      }
    }
    throw err
  }
}

const validateExisting = async (id: string, league: League) => {
  try {
    return await restService.post<UpwardVerificationDetails>(`${getUrl(league)}${id}/validate`, league)
  } catch (e: any) {
    throw e
  }
}

const newTemplate = async (upwardLeagueTypeID: string) => {
  return await restService.get<League>(
    `${
      upwardLeagueTypeID === LeagueTypes.UPWARDSELECT ? baseSelectUrl : baseLeagueUrl
    }new/${upwardLeagueTypeID}`
  )
}

const retrieveWinnerKitProductList = async (programTypeID: string, productGroupID: string) => {
  return await restService.get<ParticipantOrderProductInfo[]>(
    `${baseLeagueUrl}winnerKitProducts/${programTypeID}/${productGroupID}`
  )
}

const retrieveAwardProductOptions = async (programTypeID: string, productGroupID: string) => {
  return getRestResult<AwardProductOptionInfo[]>(
    await restService.get<AwardProductOptionInfo[]>(
      `${baseLeagueUrl}awardProductOptions/${programTypeID}/${productGroupID}`
    ),
    'leagueClient.retrieveAwardProductOptions'
  )
}

function getRestResult<T>(result: RestServiceResult<T>, methodName: string): T {
  if (result.isSuccess) {
    return result.data as T
  } else {
    throw new Error(`Error in ${methodName}`)
  }
}

const retrieveLeagueProgramAugusta = async (id: string, progID: string) => {
  const c = await restService.get<LeagueProgramAugusta>(
    `${baseLeagueUrl}leagueProgramAugusta/${id}/${progID}`
  )
  if (c.isSuccess) {
    return c.data
  }
  throw new LeagueClientException('retrieve failed on id/prog = ' + id + '/' + progID)
}

const createLeagueProgramAugusta = async (lpa: LeagueProgramAugusta) => {
  try {
    return await restService.post<LeagueProgramAugusta>(`${baseLeagueUrl}leagueProgramAugusta`, lpa)
  } catch (e) {
    throw e
  }
}

const updateLeagueProgramAugusta = async (id: string, lpa: LeagueProgramAugusta) => {
  try {
    return await restService.put<LeagueProgramAugusta>(
      `${baseLeagueUrl}leagueProgramAugusta/${id}/${lpa.typeProgramID}`,
      lpa
    )
  } catch (e) {
    throw e
  }
}

const retrieveCarPoolLinkLabelOverride = async () => {
  const c = await restService.get<CarPoolLinkLabelOverrideInfo[]>(`${baseLeagueUrl}carpoollinklabeloverride/`)
  if (c.isSuccess) {
    return c.data
  }
  throw new LeagueClientException('car pool link retrieve failed. ')
}

export default {
  create,
  retrieve,
  update,
  validateNew,
  validateExisting,
  newTemplate,
  retrieveWinnerKitProductList,
  retrieveAwardProductOptions,
  retrieveLeagueProgramAugusta,
  createLeagueProgramAugusta,
  updateLeagueProgramAugusta,
  retrieveCarPoolLinkLabelOverride,
}
