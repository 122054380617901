



















import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'
import VerticalTabs from '@/components/VerticalTabs.vue'
import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

export default defineComponent({
  name: 'Reports',
  components: {
    VerticalTabs,
  },
  setup(props, ctx) {
    const id = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)
    const isLeague = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    )
    const isUpwardSelect = computed(() => store.getters.leagueAbstraction.isUpwardSelect)

    const selectedReport = ref<string[]>([])
    const openNode = ref<string[]>([])

    const baseRoute = computed(() => {
      if (isCamp.value) {
        return `/programs/camp/${id.value}/reports`
      }
      return `/programs/league/${id.value}/reports`
    })

    const evaluationText = 'Evaluations'
    const scheduleText = 'Schedules'
    const participantText = 'Participants / Teams'
    //const churchAffiliationText = 'Church Affiliation'
    //const volunteerText = 'Volunteers'
    const financialText = 'Financial'
    const communicationText = 'Communication'
    const excelText = 'Excel Reports'

    const excelReports = computed(() => `${baseRoute.value}/excel`)
    const evaluationReports = computed(() => `${baseRoute.value}/evaluations`)
    const scheduleReports = computed(() => `${baseRoute.value}/schedule`)
    const participantReports = computed(() => `${baseRoute.value}/participant`)
    const churchAffiliationReports = computed(() => `${baseRoute.value}/churchAffiliation`)
    const volunteerReports = computed(() => `${baseRoute.value}/volunteer`)
    const financialReports = computed(() => `${baseRoute.value}/financial`)
    const communicationReports = computed(() => `${baseRoute.value}/communication`)
    const webReports = computed(() => `${baseRoute.value}/web`)
    const dynamicReports = computed(() => `${baseRoute.value}/dynamic`)

    watch(
      () => selectedReport.value,
      (selectedReport: any[]) => {
        if (
          selectedReport &&
          selectedReport.length == 1 &&
          selectedReport[0] !== evaluationReports.value &&
          selectedReport[0] !== scheduleReports.value &&
          selectedReport[0] !== participantReports.value &&
          selectedReport[0] !== churchAffiliationReports.value &&
          selectedReport[0] !== volunteerReports.value &&
          selectedReport[0] !== financialReports.value &&
          selectedReport[0] !== communicationReports.value &&
          selectedReport[0] !== excelReports.value &&
          selectedReport[0] !== ctx.root.$route.path
        ) {
          ctx.root.$router.push(selectedReport[0])
        }
      },
      { deep: true }
    )

    const reports = computed(() => {
      return [
        ...(isLeague.value && !isUpwardSelect.value
          ? [
              {
                id: 1,
                name: evaluationText,
                url: evaluationReports.value,
                children: [
                  {
                    id: 2,
                    name: 'Blank Participant Evaluation',
                    url: `${dynamicReports.value}/blankevalform`,
                  },
                  {
                    id: 3,
                    name: 'Participant Evaluation',
                    url: `${dynamicReports.value}/evalform`,
                  },
                  {
                    id: 4,
                    name: 'Evaluation Activity Scores',
                    url: `${dynamicReports.value}/evalactivity`,
                  },
                  {
                    id: 5,
                    name: 'Missing Evaluation',
                    url: `${dynamicReports.value}/missingevals`,
                  },
                ],
              },
            ]
          : []),
        ...(isLeague.value
          ? [
              {
                id: 8,
                name: scheduleText,
                url: scheduleReports.value,
                children: [
                  {
                    id: 9,
                    name: 'Practice Schedules',
                    url: `${dynamicReports.value}/practice`,
                  },
                  {
                    id: 10,
                    name: 'Game Schedules',
                    url: `${dynamicReports.value}/game`,
                  },
                  {
                    id: 11,
                    name: 'Game Schedules By Team',
                    url: `${dynamicReports.value}/gamebyteam`,
                  },
                  ...(!isUpwardSelect.value
                    ? [
                        {
                          id: 12,
                          name: `${store.getters.leagueAbstraction.refLabel} Schedule`,
                          url: `${dynamicReports.value}/referee`,
                        },
                      ]
                    : []),
                  {
                    id: 47,
                    name: 'Coach Conflicts',
                    url: `${dynamicReports.value}/coachconflict`,
                  },
                ],
              },
            ]
          : []),
        {
          id: 14,
          name: participantText,
          url: participantReports.value,
          children: [
            {
              id: 15,
              name: 'Online Registration Waitlist',
              url: `${webReports.value}/waitlist`,
            },
            {
              id: 17,
              name: 'Team Rosters',
              url: `${dynamicReports.value}/coaches`,
            },
            {
              id: 18,
              name: 'Participant Intros',
              url: `${dynamicReports.value}/intros`,
            },
            {
              id: 19,
              name: 'Siblings list (participants at same address)',
              url: `${dynamicReports.value}/siblings`,
            },
            ...(isLeague.value
              ? [
                  {
                    id: 20,
                    name: 'Player Requests Not Honored',
                    url: `${dynamicReports.value}/playerconflict`,
                  },
                ]
              : []),
          ],
        },
        {
          id: 25,
          name: financialText,
          url: financialReports.value,
          children: [
            {
              id: 26,
              name: 'Online Registration Financial Details',
              url: `${webReports.value}/financial-details`,
            },
            {
              id: 27,
              name: 'Payments',
              url: `${dynamicReports.value}/payments`,
            },
            {
              id: 28,
              name: 'Payments By Type',
              url: `${dynamicReports.value}/paymentsbytype`,
            },
            {
              id: 29,
              name: 'Outstanding Balances',
              url: `${dynamicReports.value}/outstandingbalance`,
            },
          ],
        },
        {
          id: 32,
          name: communicationText,
          url: communicationReports.value,
          children: [
            {
              id: 33,
              name: 'Mailing labels (avery 5160)',
              url: `${dynamicReports.value}/mailinglabels`,
            },
            {
              id: 34,
              name: 'Email / SMS Opt Out Report',
              url: `${webReports.value}/communicationsoptout`,
            },
          ],
        },
        {
          id: 35,
          name: excelText,
          url: excelReports.value,
          children: [
            { id: 36, name: 'Church Affiliation', url: `${excelReports.value}/churchAffiliation` },
            { id: 37, name: 'Participants', url: `${excelReports.value}/participants` },
            { id: 38, name: 'Coaches', url: `${excelReports.value}/coaches` },
            ...(!isUpwardSelect.value
              ? [
                  {
                    id: 39,
                    name: `${store.getters.leagueAbstraction.refLabel}s`,
                    url: `${excelReports.value}/referees`,
                  },
                ]
              : []),
            ...(!isUpwardSelect.value
              ? [{ id: 40, name: 'Commissioners', url: `${excelReports.value}/commissioners` }]
              : []),
            ...(!isUpwardSelect.value
              ? [{ id: 41, name: 'Volunteers', url: `${excelReports.value}/volunteers` }]
              : []),
            ...(isLeague.value
              ? [{ id: 42, name: 'Game Schedule', url: `${excelReports.value}/game-schedule` }]
              : []),
            ...(isLeague.value
              ? [{ id: 43, name: 'Practice Schedule', url: `${excelReports.value}/practice-schedule` }]
              : []),
            { id: 45, name: 'Division / Teams', url: `${excelReports.value}/division-teams` },
            ...(isCamp.value
              ? [{ id: 46, name: 'Camp Director', url: `${excelReports.value}/camp-director` }]
              : []),
          ],
        },
      ]
    })

    onMounted(() => {
      selectedReport.value = [ctx.root.$route.path]

      setOpenNode(ctx.root.$route.path)
    })

    function setOpenNode(reportPath: string) {
      var reportParent = reports.value.find((r) => r.children.find((c) => c.url === reportPath))

      if (reportParent) {
        openNode.value = [reportParent.url]
      }
    }

    return {
      selectedReport,
      reports,
      openNode,
    }
  },
})
