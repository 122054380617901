export enum ResourceFileType {
  pdf = 'pdf',
  zip = 'zip',
  jpg = 'jpg',
  png = 'png',
  ppt = 'ppt',
  mp4 = 'mp4',
  keynote = 'keynote',
  youtube = 'youtube',
  unknown = 'unknown',
}

export enum ResourceFileTypeCategory {
  pdf = 'pdf',
  archive = 'archive',
  image = 'image',
  slides = 'slides',
  video = 'video',
  unknown = 'unknown',
}

export default class resourceService {
  static getResourceFileType(url: string | null) {
    url = (url || '').toLowerCase()

    if (url.endsWith('.pdf')) {
      return ResourceFileType.pdf
    }

    if (url.endsWith('.zip')) {
      return ResourceFileType.zip
    }

    if (url.endsWith('.jpg') || url.endsWith('.jpeg')) {
      return ResourceFileType.jpg
    }

    if (url.endsWith('.png')) {
      return ResourceFileType.png
    }

    if (url.endsWith('.ppt') || url.endsWith('.pptx')) {
      return ResourceFileType.ppt
    }

    if (url.endsWith('.key')) {
      return ResourceFileType.keynote
    }

    if (url.endsWith('.mp4')) {
      return ResourceFileType.mp4
    }

    if (url.indexOf('youtu.be') >= 0 || url.indexOf('youtube.') >= 0) {
      return ResourceFileType.youtube
    }

    return ResourceFileType.unknown
  }

  static getResourceFileTypeCategory(resourceFileType: ResourceFileType) {
    switch (resourceFileType) {
      case ResourceFileType.jpg:
      case ResourceFileType.png:
        return ResourceFileTypeCategory.image
      case ResourceFileType.zip:
        return ResourceFileTypeCategory.archive
      case ResourceFileType.pdf:
        return ResourceFileTypeCategory.pdf
      case ResourceFileType.ppt:
      case ResourceFileType.keynote:
        return ResourceFileTypeCategory.slides
      case ResourceFileType.youtube:
      case ResourceFileType.mp4:
        return ResourceFileTypeCategory.video
      default:
        return ResourceFileTypeCategory.unknown
    }
  }

  static getResourceFileTypeIcon(resourceFileType: ResourceFileType) {
    switch (resourceFileType) {
      case ResourceFileType.jpg:
      case ResourceFileType.png:
        return 'fa fa-file-image'
      case ResourceFileType.zip:
        return 'fa fa-file-archive'
      case ResourceFileType.pdf:
        return 'fa fa-file-pdf'
      case ResourceFileType.ppt:
        return 'fa fa-file-powerpoint'
      case ResourceFileType.youtube:
        return 'fab fa-youtube'
      case ResourceFileType.mp4:
        return 'fa fa-file-video'
      case ResourceFileType.keynote:
        return 'fab fa-apple'
      default:
        return 'fa fa-file'
    }
  }
}
