









import { defineComponent, PropType, computed } from '@vue/composition-api'
import { OrderTrackingNumberInfo } from '@/GeneratedTypes/Order/ListInfo/OrderTrackingNumberInfo'

export default defineComponent({
  name: 'TrackingNumber',
  props: {
    trackingNumberInfo: { type: Object as PropType<OrderTrackingNumberInfo>, required: true },
  },
  setup(props) {
    const trackingURL = computed(() =>
      props.trackingNumberInfo.carrierURLBasePath?.replace('{0}', props.trackingNumberInfo.trackingNumber!)
    )

    return { trackingURL }
  },
})
