
















































import Modal from '@/components/Modal.vue'
import FullBody from '@/components/FullBody.vue'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    Modal,
    FullBody,
  },
})
export default class DigitalSetupCompleteConfirmation extends Vue {
  @Prop({ type: Boolean, required: true })
  private readonly value!: boolean

  @Prop({ type: String, required: false, default: '' })
  private readonly upwardLeagueId!: string

  private modalIsVisible = this.value

  private hideModal() {
    this.modalIsVisible = false
    this.onModalChange()
  }

  @Watch('value')
  private onValueChange() {
    this.modalIsVisible = this.value
  }

  private onModalChange() {
    this.$emit('input', this.modalIsVisible)
    this.$emit('change', this.modalIsVisible)
  }

  private goToResources() {
    this.$router.push(`/programs/digitalleague/${this.upwardLeagueId}/resources/`)
  }
}
