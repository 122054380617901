import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { getEmptyPracticeNightExclusions } from '@/lib/support/models/PlayerPracticeNightExclusion/data'
import { getEmptyPlayerUDFs } from '@/lib/support/models/PlayerUDFValue/data'
import { PlayerInfoIDType } from '@/lib/support/models/LeaguePlayerInfo/data'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'

export const getEmptyLeaguePlayer = (props?: LeaguePlayerSeedInfo) => {
  const rv: LeaguePlayer = {
    accountNumber: newAccountID(),
    leagueID: props?.leagueID ?? 0,
    birthDate: new Date('1970-01-01 00:00'),
    churchName: '',
    firstName: '',
    gender: 'M',
    generalNotes: '',
    individualID: newPlayerId(),
    isImported: false,
    lastName: '',
    medicalNotes: '',
    middleInitial: '',
    udFs: getEmptyPlayerUDFs(),
    practiceNightExclusions: getEmptyPracticeNightExclusions(),
    contacts: [],
    programs: [],
    thirdPartyClientIDs: [],
    typeGradeID: 'K3',
    gradeIsOverridden: false,
  }

  return rv
}

export class LeaguePlayerSeedInfo {
  typeProgramID = ''
  leagueID = 0
}

function newAccountID(): string {
  return '0'
}

function newPlayerId(): PlayerInfoIDType {
  return 0
}

export function PlayerId(p: LeaguePlayer | LeaguePlayerInfo) {
  return p.individualID
}

/**
 * Returns if the account is newly created.
 * @param p
 */
export function IsNewPlayer(p: LeaguePlayer) {
  return PlayerId(p) == newPlayerId()
}
