import dayjs from 'dayjs'

export interface PromoCardMedia {
  marketingID: number
  accountNumber: string | null
  upwardleagueID: string | null
  name: string | null
  qrCodeURL: string | null
  content: string | null
  pageWidth: number
  pageHeight: number
  publicUrl: string | null
  status: string | null
  shellUrl: string
  productUniqueID: string
  defaultRegistrationURL: string | null
}

/**
 * A version of the same with the front of the card calculated or
 * filled in by a TBD method.
 */
export type DisplayablePromoCardMedia = PromoCardMedia

export function getEmptyPromoCardMedia(): PromoCardMedia {
  return {
    marketingID: 0,
    accountNumber: '0',
    qrCodeURL: null,
    content: '',
    name: 'New Promo Card ' + dayjs().format('M/D/YY h:mm a'),
    pageHeight: 0,
    pageWidth: 0,
    publicUrl: '',
    status: '',
    upwardleagueID: '',
    shellUrl: '',
    productUniqueID: '',
    defaultRegistrationURL: null,
  }
}

export function getEmptyDisplayablePromoCardMedia(): DisplayablePromoCardMedia {
  return {
    ...getEmptyPromoCardMedia(),
  }
}
