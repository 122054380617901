


















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { DisplayablePromoCardMedia } from '../../../../../models/PromoCards/PromoCardMedia'

@Component({})
export default class PromoCardDisplay extends Vue {
  @Prop({ type: Object, required: true })
  card!: DisplayablePromoCardMedia

  @Emit()
  edit() {
    return this.card
  }

  @Emit()
  copy() {
    return this.card
  }
  @Emit()
  remove() {
    return this.card
  }
}
