








import { defineComponent } from '@vue/composition-api'
import ModalConfirmation from '@/components/ModalConfirmation.vue'
export default defineComponent({
  components: { ModalConfirmation },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(props, { refs }) {
    const delayedConfirm = () => {
      return simulateTask()
        .then(() => {
          return (refs.modalConfirmation as any).show()
        })
        .then((result) => {
          console.log('You answered: ', result ? 'Ok' : 'Cancel')
        })
    }
    const simulateTask = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve('done')
        }, 1000)
      })
    }
    return { delayedConfirm }
  },
})
