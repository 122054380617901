

























import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { getEmptyLeagueListItem, LeagueListItemSeed } from '@/lib/support/models/LeagueListItem/data'

import { HeaderValue } from '@/views/Programs/Divisions/ext/headerValue'

import programsClient from '@/clients/programsClient'

import TypeProgramTab from '@/components/TypeProgramTab.vue'
import DivisionSelect from '@/components/DivisionSelect.vue'
import store from '@/store'

export default defineComponent({
  name: 'DivisionHeader',
  components: {
    DivisionSelect,
    TypeProgramTab,
  },
  props: {
    title: { required: false, type: String, default: '' },
    disabled: { required: false, type: Boolean, default: false },
  },
  setup(props, ctx) {
    const currentDivision = computed(() => store.getters.divisions.currentDivision)
    const hasCheer = computed(() => store.getters.leagueAbstraction.hasCheer)

    const programs = ref<LeagueListItem>(getEmptyLeagueListItem(new LeagueListItemSeed()))
    const selectedProgram = ref<string | null>('')
    const division = ref<LeagueDivisionInfo | null>(null)
    const loading = ref(false)

    const upwardLeagueId = computed(() => ctx.root.$route.params.id)

    onMounted(async () => {
      if (currentDivision.value) {
        division.value = currentDivision.value
      }

      await loadPrograms()
      emitValues()
    })

    async function loadPrograms() {
      loading.value = true

      try {
        const progs = await programsClient.retrieve(ctx.root.$route.params.id)
        if (progs) {
          programs.value = progs

          if (division.value) {
            selectedProgram.value = division.value.typeProgramID
          } else if (progs.programTypeID) {
            selectedProgram.value = progs.programTypeID
          }
        }
      } finally {
        loading.value = false
      }
    }

    watch(
      () => division.value,
      () => {
        if (loading.value) return

        emitValues()
      }
    )

    function emitValues() {
      if (!division.value) return
      const headerValues = {
        selectedDivision: division.value,
        cheerProgramTypeID: programs.value.cheerProgramTypeID,
        selectedTypeProgramID: selectedProgram.value,
      } as HeaderValue

      ctx.emit('divisionChanged', headerValues)
    }

    const showHeader = computed(() => {
      if (loading.value) return false
      return division.value || hasCheer.value
    })

    return {
      showHeader,
      hasCheer,
      programs,
      selectedProgram,
      division,
      upwardLeagueId,
    }
  },
})
