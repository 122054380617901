import { Vue, Component } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import * as campsStore from '@/store/camps'
import { Getter, Action, Mutation } from 'vuex-class'
import { Camp } from '@/GeneratedTypes/Camp'
import { PromptToSavePayload } from '@/models/PromptToSavePayload'
import * as promptToSaveStore from '@/store/promptToSave'

@Component({
  beforeRouteLeave(route, redirect, next) {
    const self = this as CampSetupMixin

    self.onNavigation({
      save: async () => {
        await self.save()
      },
      continue: () => {
        next()
      },
    } as PromptToSavePayload)
  },
})
export default class CampSetupMixin extends Vue {
  public camp = {} as Camp
  public loading = false
  protected initialLoadDone = false
  public nextRoute = ''
  protected beforeSave: null | (() => Promise<void | boolean>) = null
  protected afterUpdateExisting: null | (() => void | Promise<void>) = null
  protected afterSaveNew: null | (() => Promise<void>) = null

  @Getter(campsStore.getterNames.currentItem, { namespace: campsStore.namespace })
  protected readonly storeCamp!: Camp

  @Action(campsStore.actionNames.cache, { namespace: campsStore.namespace })
  private readonly cache!: ({ item }: { item: Camp }) => boolean

  @Action(campsStore.actionNames.validate, { namespace: campsStore.namespace })
  private readonly validate!: ({ item, ruleSet }: { item: Camp; ruleSet: string }) => boolean

  @Action(campsStore.actionNames.loadFromCache, { namespace: campsStore.namespace })
  private readonly loadFromCache!: () => boolean

  @Action(campsStore.actionNames.update, { namespace: campsStore.namespace })
  private readonly update!: ({ item }: { item: Camp }) => boolean

  @Mutation(promptToSaveStore.mutationNames.setIsDirty, { namespace: promptToSaveStore.namespace })
  protected readonly setIsDirty!: ({ isDirty }: { isDirty: boolean }) => void

  @Action(promptToSaveStore.actionNames.onNavigation, { namespace: promptToSaveStore.namespace })
  private readonly onNavigation!: ({}: PromptToSavePayload) => void

  protected get isByAge() {
    return this.camp.ageCutoffDate != null
  }

  private get ruleSet() {
    const pathParts = this.$route.path.split('/')

    if (!pathParts.length) {
      return ''
    }

    const thisPath = pathParts[pathParts.length - 1]

    switch (thisPath) {
      case 'information':
        return 'PROGRAMINFO'
      case 'dates':
        return 'KEYDATES'
      case 'leadership':
        return 'LEADERSHIP'
      case 'partnership':
        return 'PARTNERSHIP'
      case 'products':
        return 'PRICING'
      default:
        return ''
    }
  }

  protected async saveAndContinue() {
    const result = await this.save()
    if (result != false) this.continue()
  }

  mounted() {
    this.$nextTick(() => this.$watch('camp', this.onCampChange, { deep: true }))
  }

  private async created() {
    this.camp = cloneDeep(this.storeCamp)

    if (!this.camp.typeLeagueID) {
      this.loading = true

      try {
        await this.loadFromCache()
        this.camp = cloneDeep(this.storeCamp)
      } catch (err) {
        this.$router.push('/programs/new/')
      } finally {
        this.loading = false
      }
    }
    this.initialLoadDone = true
  }

  protected async save() {
    this.loading = true

    try {
      if (this.beforeSave) {
        const r = await this.beforeSave()
        if (r === false) return false
      }

      if (this.camp.upwardLeagueID) {
        await this.updateExistingCamp()

        if (this.afterUpdateExisting) {
          await this.afterUpdateExisting()
        }
      } else {
        await this.saveNewCamp()

        if (this.afterSaveNew) {
          await this.afterSaveNew()
        }
      }

      this.setIsDirty({ isDirty: false })
    } finally {
      this.loading = false
    }
  }

  private continue() {
    if (this.nextRoute) {
      this.$router.push(this.nextRoute)
    }
  }

  private async saveNewCamp() {
    if (this.camp.upwardLeagueID) {
      return
    }

    try {
      await this.validate({ item: this.camp, ruleSet: this.ruleSet })
      await this.cache({ item: this.camp })
    } catch (err) {
      throw err
    }
  }

  private async updateExistingCamp() {
    if (!this.camp.upwardLeagueID) {
      return
    }

    try {
      await this.update({ item: this.camp })
    } catch (err) {
      throw err
    }
  }

  private onCampChange() {
    this.setIsDirty({ isDirty: true })
  }
}
