
























import { computed, defineComponent, ref } from '@vue/composition-api'
import { FeaturesEnum, useFeatureFlags } from '@/services/useFeatureFlags'

export default defineComponent({
  setup() {
    const ff = useFeatureFlags()
    const allGood = ref(true)
    const allFlags = computed(() => {
      const arr = []
      for (const i in ff.featuresValid.value) {
        if (i == FeaturesEnum.__UNSET__) continue
        arr.push(i)
      }
      return JSON.stringify(arr)
    })
    const featureJSON = ref(JSON.stringify(ff.featuresExcluded.value))
    function setFeaturesFromText() {
      try {
        const ffval = JSON.parse(featureJSON.value)
        ff.setExcludedFeatures(ffval)
        allGood.value = true
      } catch (e) {
        allGood.value = false
        throw e
      }
    }
    return { allGood, allFlags, ...ff, featureJSON, setFeaturesFromText }
  },
})
