import { ResourceItem } from '@/models/Program/ResourceItem'
import axios from 'axios'

const url = 'https://resourceusageapiservices.azurewebsites.net/api/resourceusage'

const postResourceUsage = async (resourceItem: ResourceItem) => {
  await axios.post(url, resourceItem)
}

export default {
  postResourceUsage,
}
