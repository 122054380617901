import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'

export enum PhoneTypeEnum {
  HOME = 'HOME',
  WORK = 'WORK',
  MOBILE = 'MOBILE',
}

/***
 * Return an empty phone number
 * @param seed - optional template for values.
 */
export function getEmptyPhone(seed?: PhoneSeedInfo): IndividualPhone {
  return {
    phoneNumber: '',
    extension: '',
    allowSMS: false,
    phoneID: seed?.phoneID || 0,
    typePhoneID: seed?.typePhoneID || '',
    priority: 0,
  }
}
//nothing to set now.
export class PhoneSeedInfo {
  phoneID = 0
  typePhoneID = PhoneTypeEnum.HOME
}

/**
 * Convert human understood name into type id
 * @param s {string} the human name 'work','mobile','cell' for starters
 * @return - upward type id
 */
export function NameToType(s: string): string {
  switch (s.toLocaleLowerCase()) {
    case 'work':
      return PhoneTypeEnum.WORK
    case 'mobile':
    case 'cell':
      return PhoneTypeEnum.MOBILE
    default:
      return PhoneTypeEnum.HOME
  }
}

export function mobilePhoneSeed() {
  const c = new PhoneSeedInfo()
  c.typePhoneID = PhoneTypeEnum.MOBILE
  return c
}

export function homePhoneSeed() {
  const c = new PhoneSeedInfo()
  c.typePhoneID = PhoneTypeEnum.HOME
  return c
}

let nextPhoneSeed = -1
export function decrementingPhoneIDStrategy() {
  const c = new PhoneSeedInfo()
  c.phoneID = nextPhoneSeed--
  return c
}
