import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'

export const getEmptyTeamPageAlertInfo = () => {
  const retval: TeamPageAlertInfo = {
    id: 0,
    typeAlertMessageID: null,
    typeAlertTargetID: null,
    typeAlertPriorityID: null,
    message: '',
    startDate: null,
    expireDate: null,
    expireViewCount: 0,
    registeredViewCount: 0,
    userCanHide: false,
    userDidHide: false,
    typeProgramID: 'NONE',
    divisionID: 0,
    divisionName: null,
    teamID: 0,
    teamName: null,
    upwardTeamID: '',
  }

  return retval
}
