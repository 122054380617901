import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { getEmptyLeaguePlayerInfo, LeaguePlayerSeedInfo } from '@/lib/support/models/LeaguePlayerInfo/data'

export function leaguePlayerToLeaguePlayerInfo(lp: LeaguePlayer): LeaguePlayerInfo {
  return {
    ...getEmptyLeaguePlayerInfo(new LeaguePlayerSeedInfo()),
    individualID: lp.individualID,
    typeProgramID: lp.programs?.length ? lp.programs[0].typeProgramID : '',
    typeGradeID: lp.typeGradeID,
    middleInitial: lp.middleInitial,
    medicalNotes: lp.medicalNotes,
    leagueID: lp.leagueID,
    lastName: lp.lastName,
    isImported: lp.isImported,
    generalNotes: lp.generalNotes,
    gender: lp.gender,
    firstName: lp.firstName,
    churchName: lp.churchName,
    accountNumber: lp.accountNumber,
    birthDate: lp.birthDate,
    formattedNameFirstLast: lp.firstName,
    formattedNameLastFirst: lp.lastName,
    formattedNamePrivate: lp.firstName,
    isPlayerSizingComplete: false,
  }
}
