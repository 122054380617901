import { RouteConfig } from 'vue-router'

const VolunteerView = () => import('@/views/Programs/Volunteers/VolunteerView.vue')
const VolunteersIndex = () => import('@/views/Programs/Volunteers/VolunteersIndex.vue')
const VolunteerEdit = () => import('@/views/Programs/Volunteers/VolunteerEdit.vue')

import { idExpression } from '@/lib/support/router/idHelper'

export default function (programType: string) {
  programType = programType ?? 'league'
  const routes = [
    {
      path: 'volunteers',
      component: VolunteersIndex,
      children: [
        {
          path: '/',
          redirect: `/programs/${programType}/${idExpression}/volunteers/list/coaches`,
        },
        {
          path: 'list/:type',
          component: VolunteerView,
          name: 'list-volunteer',
        },
        {
          path: 'delete/:type/:vid',
          component: VolunteerView,
          name: 'delete-volunteer',
        },
        {
          path: 'edit/:type/:vid',
          component: VolunteerEdit,
          name: 'edit-volunteer',
        },
        {
          path: 'new/:type',
          component: VolunteerEdit,
          name: 'new-volunteer',
        },
        {
          path: 'search/:type',
          component: VolunteerView,
          name: 'search-volunteer',
        },
        {
          path: 'add-former/:type',
          component: VolunteerView,
          name: 'former-volunteer',
        },
        {
          path: 'copy/:type/:vid',
          component: VolunteerEdit,
          name: 'copy-volunteer',
        },
        {
          path: 'transfer/:type',
          component: VolunteerView,
          name: 'transfer-volunteer',
        },
      ],
    },
  ] as RouteConfig[]

  routes[0]?.children?.forEach((r) => (r.name = `${r.name}-${programType}`))
  return routes
}
