







import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'NotificationCard',
  props: {},
  components: {},
  setup() {
    return {}
  },
})
