
















import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import FacilityDropper from '@/views/Programs/Scheduling/Games/auto/FacilityDropper.vue'
import SchedulerSettingsPanel from '@/views/Programs/Scheduling/Games/auto/SchedulerSettingsPanel.vue'
import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { DivisionGameSchedulerPreference } from '@/GeneratedTypes/DivisionGameSchedulerPreference'
import { getEmptyDivisionGameSchedulerPreference } from '@/lib/support/models/DivisionGameSchedulerPreference/data'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { League } from '@/GeneratedTypes/League'
import { isEqual, cloneDeep } from 'lodash'
import { DivisionGameSchedulerFacility } from '@/GeneratedTypes/DivisionGameSchedulerFacility'

/***
 * The product of this component is a DivisionGameSchedulerPreference
 */

export default defineComponent({
  name: 'DivisionSettings',
  components: { FacilityDropper, SchedulerSettingsPanel },
  props: {
    division: {
      type: Object as PropType<LeagueDivisionInfo>,
      required: true,
      default: () => getEmptyLeagueDivisionInfo(),
    },
    facilityList: { type: Array as PropType<LeagueFacility[]>, required: true, default: () => [] },
    league: { type: Object as PropType<League>, default: () => getEmptyLeague(), required: true },
    programID: { type: String, default: () => '', required: true },
    value: {
      type: Object as PropType<DivisionGameSchedulerPreference>,
      default: () => getEmptyDivisionGameSchedulerPreference(),
    },
  },
  setup(props, ctx) {
    const internalValue = ref<DivisionGameSchedulerPreference>(getEmptyDivisionGameSchedulerPreference())

    const facilityOptions = ref<DivisionGameSchedulerFacility[]>([])

    watch(
      () => props.value,
      () => {
        internalValue.value = cloneDeep(props.value)
        facilityOptions.value = cloneDeep(props.value.facilityPreferences) || []
      },
      { immediate: true }
    )

    const calculatedValue = computed(
      (): DivisionGameSchedulerPreference => {
        return cloneDeep({
          ...internalValue.value,
          facilityPreferences: facilityOptions.value,
          typeProgramID: props.programID,
          leagueID: props.league.id,

          divisionID: props.division.divisionID,
        })
      }
    )

    function input() {
      const newValue = calculatedValue.value
      if (!isEqual(newValue, props.value)) {
        ctx.emit('input', calculatedValue.value)
      }
    }

    return {
      input,
      facilityOptions,
      internalValue,
    }
  },
})
