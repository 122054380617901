import { LeagueVolunteerRoleInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerRoleInfo'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'

export function leagueVolunteerRoleInfoToLeagueVolunteerRole(
  l: LeagueVolunteerRoleInfo
): LeagueVolunteerRole {
  return {
    roleID: l.roleID,
    approved: l.approved,
    udFs: [],
    authorizedExpireDate: l.authorizedExpireDate,
    upwardAuthInvitationID: l.upwardAuthInvitationID,
  }
}
