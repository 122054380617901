












import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'PromoCardOverflowWarning',
  props: {
    value: { type: Boolean, required: true },
    isDirty: { type: Boolean, require: true },
  },
  setup(props, { emit }) {
    function close() {
      emit('input', false)
    }
    return { close }
  },
})
