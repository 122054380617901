



































































import { defineComponent, computed, ref, watch, nextTick } from '@vue/composition-api'

import Modal from '@/components/Modal.vue'
import WaitPage from '@/views/WaitPage.vue'

import ministryAgreementClient from '@/clients/ministryAgreementClient'
import { agreementText } from '@/lib/components/MinistryAgreementModal/agreement'
import TextInput from '@/elements/TextInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import store from '@/store'

export default defineComponent({
  name: 'MinistryAgreementModal',
  components: {
    TextInput,
    Modal,
    WaitPage,
    VeeValidateForm,
  },
  props: {
    reload: { type: Boolean, required: true },
    compareDate: { type: [String, Date], required: false, default: null },
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const isMinistryAgreementSigned = computed(() => store.getters.authorization.isMinistryAgreementSigned)
    const isSystemsAndSupport = computed(() => store.getters.authorization.isSystemsAndSupport)
    const isUpwardStaff = computed(() => store.getters.authorization.isUpwardStaff)

    const ministryAgreementRequired = ref(false)
    const ministryAgreementShowClosed = ref(false)
    const showWaitPage = ref(false)
    const name = ref('')
    const email = ref('')

    const hasCollectedData = computed(() => name.value && email.value)

    //step =1 show agreement, step=2 accept agreement, also showWaitPage = false, agreement, otherwise waiting for accept.
    const step = ref(1)

    function nextClicked() {
      step.value = 2
    }

    function backClicked() {
      step.value = 1
    }

    async function agreeClicked() {
      showWaitPage.value = true
      await ministryAgreementClient.signAgreement(accountNumber.value, name.value, email.value)
      store.commit.authorization.setMinistryAgreementIsSigned()
      ministryAgreementRequired.value = false
      ministryAgreementShowClosed.value = true
    }

    const scrolled = ref(false)

    function handleScroll() {
      scrolled.value = scrolled.value || isScrolledToBottom()
    }

    function isScrolledToBottom() {
      const container = ctx.refs.container as HTMLElement
      const parent = container.parentElement as HTMLElement
      const distanceToScroll = parent.scrollHeight - parent.clientHeight
      const percentScrolled =
        distanceToScroll > 0 ? (container.scrollTop || parent.scrollTop) / distanceToScroll : 1
      return percentScrolled >= 0.9
    }

    watch(
      () => props.reload,
      async () => {
        await updateMinistryAgreementRequired()
      }
    )

    watch(
      () => props.compareDate,
      async () => {
        await checkMinistryAgreementRequired()
      }
    )

    async function checkMinistryAgreementRequired() {
      const status = await ministryAgreementClient.checkMinistryAgreementStatus(accountNumber.value)
      if (status) {
        store.commit.authorization.setMinistryAgreementIsSigned()
        ministryAgreementRequired.value = true
      }
      let isExpired = false
      if (props.compareDate && status && status.expireDate) {
        const endDate = new Date(props.compareDate)
        const expirationDate = new Date(status.expireDate)
        isExpired = expirationDate < endDate
      }
      const shouldRequireSigniture = !status || status.expired || isExpired
      if (isSystemsAndSupport.value || isUpwardStaff.value) {
        ministryAgreementRequired.value = false
      } else {
        ministryAgreementRequired.value = shouldRequireSigniture
      }
      ministryAgreementShowClosed.value = !ministryAgreementRequired.value
      showWaitPage.value = ministryAgreementShowClosed.value
    }

    async function updateMinistryAgreementRequired() {
      if (!isMinistryAgreementSigned.value) {
        await checkMinistryAgreementRequired()
      }
    }

    watch(
      () => ministryAgreementRequired.value,
      () => {
        if (ministryAgreementRequired.value) {
          nextTick(() => (scrolled.value = scrolled.value || isScrolledToBottom()))
        }
      },
      { immediate: true }
    )

    return {
      agreementText,
      ministryAgreementRequired,
      ministryAgreementShowClosed,
      handleScroll,
      showWaitPage,
      step,
      name,
      email,
      scrolled,
      nextClicked,
      backClicked,
      agreeClicked,
      hasCollectedData,
    }
  },
})
