export interface LeagueProgramAugusta {
  leagueID: number
  typeProgramID: string
  products: LeagueProgramAugustaProduct[]
}

export interface LeagueProgramAugustaProduct {
  upwardProductID: string
  productDescription: string
  sNumbers: LeagueProgramAugustaSNumber[]
}
export interface LeagueProgramAugustaSNumber {
  typeColorID: string
  adultMaleProductItemCode: string
  adultFemaleProductItemCode: string
  youthMaleProductItemCode: string
  youthFemaleProductItemCode: string
}

export function getEmptyLeagueProgramAugusta(): LeagueProgramAugusta {
  return {
    leagueID: 0,
    typeProgramID: '',
    products: [getEmptyLeagueProgramAugustaProduct()],
  }
}

export function getEmptyLeagueProgramAugustaProduct(): LeagueProgramAugustaProduct {
  return {
    upwardProductID: '',
    productDescription: '',
    sNumbers: [getEmptyLeagueProgramAugustaSNumber()],
  }
}

export function getEmptyLeagueProgramAugustaSNumber(): LeagueProgramAugustaSNumber {
  return {
    typeColorID: '',
    adultMaleProductItemCode: '',
    adultFemaleProductItemCode: '',
    youthMaleProductItemCode: '',
    youthFemaleProductItemCode: '',
  }
}
