























import { defineComponent, ref, watch, onMounted, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import Loading from '@/elements/Loading.vue'
import divisionsClient from '@/clients/divisionsClient'
import { TypeProgramGameFormatInfo } from '@/GeneratedTypes/ListInfo/TypeProgramGameFormatInfo.ts'

export default defineComponent({
  name: 'GameFormatSelect',
  components: {
    SelectInput,
    Loading,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    value: { type: (null as unknown) as PropType<string | null>, required: true },
    blankItemText: { type: String, default: '', required: false },
    showBlankItem: { type: Boolean, default: false, required: false },
    blankItemValue: { type: String, default: '', required: false },
    programTypeId: { type: String, required: true },
    VeeValidateRules: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const internalValue = ref<string | null>(props.value)
    const isLoading = ref(false)
    const gameFormatList = ref<TypeProgramGameFormatInfo[]>([])

    async function fetchAll() {
      if (isLoading.value) {
        return false
      }
      const gameFormats = await divisionsClient.retrieveGameFormats(props.programTypeId)

      if (gameFormats) {
        gameFormatList.value = gameFormats
      }

      isLoading.value = false
    }

    onMounted(async () => {
      await fetchAll()
    })

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    return {
      isLoading,
      gameFormatList,
      internalValue,
      handleChange,
    }
  },
})
