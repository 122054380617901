/**
 * Rest Operations primarily for CRUD on Game Scheduler
 */

import restService from '@/services/restService'

import { UpwardLeagueIDType } from '@/lib/support/models/League/data'

import { GeneralError } from '@/lib/common/exceptions/GeneralError'

import { CheerSquadGameAppointmentInfo } from '@/GeneratedTypes/ListInfo/CheerSquadGameAppointmentInfo'
import { CheerSquadSchedulerMapping } from '@/GeneratedTypes/CheerSquadSchedulerMapping'
import { CheerSquadGameAppointment } from '@/GeneratedTypes/CheerSquadGameAppointment'
import { isEmptyCheerSquadAppointment } from '@/lib/support/models/CheerSquadGameAppointment/data'
import { CheerSquadGameAppointmentInfoModified } from '@/models/Appointments/CheerSquadGameAppointmentInfoModified'

import { LeagueSpecialEventInfo } from '@/GeneratedTypes/ListInfo/LeagueSpecialEventInfo'
import { LeagueSpecialEvent } from '@/GeneratedTypes/LeagueSpecialEvent'

const baseUrl = 'appointments'

function ourCheerURL(leagueID: UpwardLeagueIDType) {
  return `${baseUrl}/cheer/${leagueID}`
}

function ourSpecialEventURL(leagueID: UpwardLeagueIDType) {
  return `${baseUrl}/specialEVent/${leagueID}`
}

function programURL(leagueID: UpwardLeagueIDType) {
  return `programs/${leagueID}`
}

class AppointmentClientException extends GeneralError {
  name = 'Appointments API Error'
}

export interface CheerScheduleOptions {
  offset?: number
}

export default class appointmentClient {
  /**
 * Returns all appointments based on a LeagueID or LeagueID and ProgramType
 * @param leagueID UPW### league

 * @return array of league volunteers from the league id
 */
  static async retrieveAllAppointments(
    leagueID: UpwardLeagueIDType
  ): Promise<CheerSquadGameAppointmentInfo[] | null> {
    const x = await restService.get<CheerSquadGameAppointmentInfo[]>(`${ourCheerURL(leagueID)}/`)
    if (!x.isSuccess) {
      throw new AppointmentClientException('Could not get list of volunteers')
    }
    return x.data
  }

  static async validate(
    leagueID: UpwardLeagueIDType,
    appointment: CheerSquadGameAppointmentInfoModified
  ): Promise<CheerSquadGameAppointment | null> {
    let url = `${baseUrl}/cheer/verificationDetails`
    if (!isEmptyCheerSquadAppointment(appointment)) {
      url = ourCheerURL(leagueID) + `/${appointment.id}/verificationDetails`
    }

    const x = await restService.post<CheerSquadGameAppointment>(url, appointment)
    if (!x.isSuccess) {
      throw new AppointmentClientException('Could not validate appointment')
    }
    return x.data
  }

  static async update(
    leagueID: UpwardLeagueIDType,
    appointment: CheerSquadGameAppointmentInfoModified
  ): Promise<CheerSquadGameAppointment | null> {
    const url = ourCheerURL(leagueID) + `/${appointment.id}`
    const x = await restService.put<CheerSquadGameAppointment>(url, appointment)

    if (!x || !x?.isSuccess) {
      throw new AppointmentClientException('Could not save appointment')
    }
    return x.data
  }

  static async save(
    leagueID: UpwardLeagueIDType,
    appointment: CheerSquadGameAppointmentInfoModified[]
  ): Promise<CheerSquadGameAppointment[] | null> {
    const url = `${baseUrl}/cheer/${leagueID}`
    const x = await restService.post<CheerSquadGameAppointment[]>(url, appointment)

    if (!x || !x?.isSuccess) {
      throw new AppointmentClientException('Could not save appointment')
    }
    return x.data
  }

  static async remove(
    leagueID: UpwardLeagueIDType,
    appointmentID: number
  ): Promise<CheerSquadGameAppointment | null> {
    const url = ourCheerURL(leagueID) + `/${appointmentID}`
    const x = await restService.delete<CheerSquadGameAppointment>(url)

    if (!x || !x?.isSuccess) {
      throw new AppointmentClientException('Could not save appointment')
    }
    return x.data
  }

  static async schedule(leagueID: UpwardLeagueIDType, options: CheerScheduleOptions) {
    const y = await restService.post<void>(programURL(leagueID) + '/generateCheerGamedaySchedule', options)
    if (!y?.isSuccess) {
      throw new AppointmentClientException('Issue with appointment scheduling', y.errorObject)
    }
  }

  static async clearSchedule(leagueID: UpwardLeagueIDType) {
    const y = await restService.post<void>(programURL(leagueID) + '/clearCheerGamedaySchedule', {})
    if (!y?.isSuccess) {
      throw new AppointmentClientException('Issue with squad schedule clearing', y.errorObject)
    }
  }

  static async clearAppointments(leagueID: UpwardLeagueIDType, squadid: number) {
    const y = await restService.delete<void>(ourCheerURL(leagueID) + `/bySquad/${squadid}`)
    if (!y?.isSuccess) {
      throw new AppointmentClientException('Issue with clearing appointments ', y.errorObject)
    }
  }

  static async savePreferences(
    leagueID: UpwardLeagueIDType,

    preferences: CheerSquadSchedulerMapping[]
  ) {
    const x = await restService.put<void>(programURL(leagueID) + '/cheerSquadSchedulerMapping', preferences)

    if (!x || !x?.isSuccess) {
      throw new AppointmentClientException('Could not save appointment')
    }
    return x.data
  }

  static async getPreferences(leagueID: UpwardLeagueIDType) {
    const y = await restService.get<CheerSquadSchedulerMapping[]>(
      programURL(leagueID) + '/cheerSquadSchedulerMapping'
    )
    if (y && y.isSuccess) {
      return y.data
    } else {
      return []
    }
  }

  static async retrieveAllSpecialEvents(
    leagueID: UpwardLeagueIDType,
    typeProgramID: string | null | undefined = undefined,
    divisionID = 0,
    teamID = 0,
    facilityID = 0
  ): Promise<LeagueSpecialEventInfo[] | null> {
    let suffix = ''
    if (typeProgramID || divisionID > 0 || teamID > 0 || facilityID > 0) {
      suffix = '?'
      if (typeProgramID) {
        suffix = `${suffix}programTypeID=${typeProgramID}&`
      }
      if (divisionID > 0) {
        suffix = `${suffix}divID=${divisionID}&`
      }
      if (teamID > 0) {
        suffix = `${suffix}teamID=${teamID}&`
      }
      if (facilityID > 0) {
        suffix = `${suffix}facilityID=${facilityID}&`
      }
      //remove trailing ampersand
      suffix = suffix.slice(0, -1)
    }
    const x = await restService.get<LeagueSpecialEventInfo[]>(`${ourSpecialEventURL(leagueID)}/${suffix}`)
    if (!x.isSuccess) {
      throw new AppointmentClientException('Could not get list of special events')
    }
    return x.data
  }

  static async validateSpecialEvent(
    leagueID: UpwardLeagueIDType,
    event: LeagueSpecialEvent
  ): Promise<LeagueSpecialEvent | null> {
    let url = `${baseUrl}/specialEvent/verificationDetails`
    if (event.eventID > 0) {
      url = ourSpecialEventURL(leagueID) + `/${event.eventID}/verificationDetails`
    }

    const x = await restService.post<LeagueSpecialEvent>(url, event)
    if (!x.isSuccess) {
      throw new AppointmentClientException('Could not validate special event')
    }
    return x.data
  }

  static async updateSpecialEvent(
    leagueID: UpwardLeagueIDType,
    event: LeagueSpecialEvent
  ): Promise<LeagueSpecialEvent | null> {
    const url = ourSpecialEventURL(leagueID) + `/${event.eventID}`
    const x = await restService.put<LeagueSpecialEvent>(url, event)

    if (!x || !x?.isSuccess) {
      throw new AppointmentClientException('Could not update special event')
    }
    return x.data
  }

  static async saveSpecialEvent(event: LeagueSpecialEvent): Promise<LeagueSpecialEvent[] | null> {
    const url = `${baseUrl}/specialEvent`
    const x = await restService.post<LeagueSpecialEvent[]>(url, event)

    if (!x || !x?.isSuccess) {
      throw new AppointmentClientException('Could not create special event')
    }
    return x.data
  }

  static async removeSpecialEvent(leagueID: UpwardLeagueIDType, eventID: number) {
    const url = ourSpecialEventURL(leagueID) + `/${eventID}`
    const x = await restService.delete<void>(url)

    if (!x || !x?.isSuccess) {
      throw new AppointmentClientException('Could not delete special event')
    }
  }
}
