import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { getEmptyIndividualAddress } from '@/lib/support/models/IndividualAddress/data'
import { getEmptyLeagueCoach } from '@/lib/support/models/LeagueCoach/data'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'

export type VolunteerIDType = number

export function getEmptyLeagueVolunteer(seed?: LeagueVolunteerSeedInfo): LeagueVolunteer {
  return {
    accountNumber: newAccountID(),
    leagueID: seed?.leagueID ?? 0,
    addresses: [getEmptyIndividualAddress()],
    birthDate: new Date('1970-01-01 00:00'),
    churchName: '',
    firstName: '',
    gender: 'M',
    individualID: newVolunteerID(),
    lastName: '',
    middleInitial: '',
    coachPreferences: getEmptyLeagueCoach(), //default null...?
    emailAddresses: [],
    phoneNumbers: [],
    roles: [],
    staff: false,
    typeSizeID: '',
    thirdPartyClientIDs: [],
    optOutEmails: false,
    optOutSMS: false,
    inviteAllowed: true,
    registrationNote: '',
  }
}

export enum RolesEnum {
  REFEREE = 1,
  PRAYERPARTNER = 2,
  COACH = 3,
  DEVOTIONLEADER = 4,
  TEAMPARENT = 5,
  VENDOR = 6,
  COACHCOMMISSIONER = 7,
  REFEREECOMMISSIONER = 8,
  ADCOMMISSIONER = 9,
  HALFTIMECOMMISSIONER = 10,
  PRAYERCOMMISSIONER = 11,
  HOSTCOMMISSIONER = 12,
  CHEERDIRECTOR = 13,
  GAMEDAYCOMMISSIONER = 14,
}

/**
 * Returns if this volunteer was created from new Template
 * @param l
 */
export function isNewVolunteer(l: LeagueVolunteer) {
  return l.individualID == 0
}

/**
 * Returns if this volunteer was imported
 * @param l
 */
export function isImportedVolunteer(editVolunteer: LeagueVolunteer) {
  return !editVolunteer.leagueID && !editVolunteer.accountNumber
}

/**
 * Return a signal for an empty ID
 */
function newVolunteerID(): VolunteerIDType {
  return 0
}
function newAccountID() {
  return '0'
}

export class LeagueVolunteerSeedInfo {
  leagueID = 0
}

/**
 * Logic to extract out the id of the record.
 * @param l
 * @return record id
 * @constructor
 */
export function VolunteerID(l: LeagueVolunteer | LeagueVolunteerInfo) {
  return l.individualID
}

/**
 * Logic to answer if this is a new volunteer or has been previously saved
 * @param l volunteer (template has sent back "" for an
 * @return {boolean} is it a new volunteer
 */
export function IsNewVolunteer(l: LeagueVolunteer) {
  return l.individualID <= 0
}
