





















































































import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'

import { HardBreak, Heading, Bold, Italic, Underline, History } from 'tiptap-extensions'
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'Editor',
  props: {
    value: { type: String, required: true },
  },
  components: { EditorContent, EditorMenuBar, EditorMenuBubble },
  setup(props, ctx) {
    const internalValue = ref('')

    //** should contain our editor element ref
    const editorElement = ref<Element | null>(null)
    //** will contain the tiptap editing area.
    const editorAreaElement = computed(() => editorElement.value?.querySelector('.ProseMirror'))
    //** editor container used to style overflow
    const editorContainer = computed(() => document.querySelector('.editor-container '))
    let scrollHeightLast = 0

    watch(
      () => props.value,
      (v) => {
        internalValue.value = v
        editor && editor.value && editor.value.setContent(v)
      },
      { immediate: true }
    )

    function contentOverflows() {
      const hasScrollBar = editorAreaElement.value?.clientHeight != editorAreaElement.value?.scrollHeight
      const hasScrolled = (editorAreaElement.value?.scrollHeight ?? -1) > scrollHeightLast
      isContentOverflowing.value = hasScrollBar && hasScrolled
      return isContentOverflowing.value
    }

    const isContentOverflowing = ref(false)
    watch(
      () => isContentOverflowing.value,
      () => {
        ctx.emit('overflowChanged', isContentOverflowing.value)
      },
      { immediate: true }
    )

    function onUpdate({ getHTML }: { getHTML: () => string }) {
      if (contentOverflows()) {
        scrollToBottom()
      }
      ctx.emit('input', getHTML())
    }

    function onInit() {
      setTimeout(() => {
        if (contentOverflows()) {
          scrollToBottom()
        }
      })
    }

    function scrollToBottom() {
      if (editorContainer.value) {
        editorContainer.value.scrollTop = editorContainer.value.scrollHeight
      }
    }

    const editor = ref<Editor | null>(null)
    onMounted(() => {
      editor.value = new Editor({
        extensions: [
          new HardBreak(),
          new Heading({ levels: [1, 2, 3, 5, 6] }),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
        ],
        content: internalValue.value,
        onInit,
        onUpdate,
      })
    })
    onUnmounted(() => editor.value?.destroy())
    return { editor, internalValue, editorElement, isContentOverflowing }
  },
})
