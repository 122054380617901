


























import { defineComponent, computed } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import store from '@/store'

export default defineComponent({
  name: 'UpwardAccessStep',
  props: {
    step: { type: Number, required: true },
  },
  components: { Step },
  setup() {
    const logic = productFlowLogic()
    const typeLeagueID = computed(() => logic.leagueAbstraction.value.typeLeagueID)
    const variation = computed(() =>
      typeLeagueID.value === 'LT_VOLLEYBALL' ? 'two different devotion tracks ' : 'Age-appropiate devotions '
    )

    const items = [
      {
        title: 'Free Shipping',
        iconClass: 'fas fa-truck fa-lg',
        text: ' Free shipping on all starter, league, add-on, and merchandise orders.',
      },
      {
        title: 'Ministry Resouces',
        iconClass: 'fas fa-bible fa-lg',
        text: `Access to gospel videos and ${variation.value} in the coach playbooks, practices and Upward Sports App`,
      },
      {
        title: 'Advertising Resources',
        iconClass: 'fas fa-bullhorn fa-lg',
        text: 'E-flyers, memes, website images, and layered InDesign files.',
      },
      {
        title: 'Support Center Assistance',
        iconClass: 'fas fa-headset fa-lg',
        text: 'A support center that you can call to receive assistance as the need arises.',
      },
      {
        title: 'Consultation Services',
        iconClass: 'fas fa-hands-helping fa-lg',
        text:
          'One-on-one consultation services to help you with any challenges you may have and strategically plan for your upcoming programs.',
      },
      {
        title: 'League Management Resources',
        iconClass: 'fas fa-book-open fa-lg',
        text: `League director manual, evaluation resources, coach and ${store.getters.leagueAbstraction.refLabel.toLowerCase()} training resources, parent welcome flyers, and many other forms and surveys you can use within your league.`,
      },
      {
        title: 'Volunteer Role Resources',
        iconClass: 'fas fa-book fa-lg',
        text: `Coach playbooks, practice plans, ${store.getters.leagueAbstraction.refLabel.toLowerCase()} handbooks, game day devotions, prayer ministry resources, and more.`,
      },
    ]
    return { items, variation, ...logic }
  },
})
