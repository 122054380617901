import { OrderProduct } from '@/models/Order/OrderProduct'
import { cloneDeep } from 'lodash'

export function setProduct(
  products: OrderProduct[],
  {
    productID,
    quantity,
    quantityFree,
    colorID,
    sizeID,
  }: { productID: number; colorID: string; sizeID: string; quantity: number; quantityFree: number }
) {
  const pI = products.findIndex((x) => x.id == productID)

  if (pI >= 0) {
    const p = products[pI]
    const qI = p.productColorSizes?.findIndex((y) => y.typeSizeID == sizeID && y.typeColorID == colorID) ?? -1
    if (qI >= 0) {
      const q = cloneDeep(p.productColorSizes![qI])
      q.orderQuantity = quantity
      q.orderFreeQuantity = quantityFree
      p.productColorSizes!.splice(qI, 1, q)
      products.splice(pI, 1, p)
    }
  }
  return products
}
