

















import { defineComponent, ref, watch, onMounted } from '@vue/composition-api'
import { isEqual } from 'lodash'

export default defineComponent({
  name: 'ButtonPicker',
  props: {
    options: { type: Array, required: true },
    label: { type: String, required: true },
    value: { type: Object, required: false, default: null },
  },
  setup(props, ctx) {
    const selected = ref(999)

    onMounted(() => {
      selected.value = getIndexFromOptions()
    })

    function buttonLabel(item: any) {
      return item[props.label]
    }

    function getIndexFromOptions() {
      const i = props.options.findIndex((y: any) => {
        return isEqual(y, props.value)
      })
      return i < 0 ? 0 : i
    }

    function emitSelectedObject() {
      ctx.emit('input', props.options[selected.value])
      ctx.emit('change', props.options[selected.value])
    }

    watch(
      () => props.value,
      () => {
        selected.value = getIndexFromOptions()
      }
    )

    watch(
      () => selected.value,
      () => {
        emitSelectedObject()
      }
    )

    return {
      selected,
      buttonLabel,
    }
  },
})
