






















































































import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'
import divisionsClient from '@/clients/divisionsClient'

import SelectInput from '@/elements/SelectInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'

import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import store from '@/store'
import { isCheer } from '@/lib/common/ProgramTypes'

export default defineComponent({
  name: 'GameSchedule',
  components: {
    SelectInput,
    CheckboxInput,
    ActiveReport,
  },
  setup(props, ctx) {
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const selectedReport = ref('Select a Report')
    const selectedProgram = ref('')

    const selectedDivision = ref(-1)
    const divisions = ref([{}])

    const selectedWeek = ref(-1)

    const loading = ref(false)
    const loadStatus = ref('')

    const displayHeadCoach = ref(false)
    const displayRefereeName = ref(false)

    const filterByWeek = ref(false)

    const weekNumbers = ref([
      { weekName: 'All', weekValue: '-1' },
      { weekName: '1', weekValue: '1' },
      { weekName: '2', weekValue: '2' },
      { weekName: '3', weekValue: '3' },
      { weekName: '4', weekValue: '4' },
      { weekName: '5', weekValue: '5' },
      { weekName: '6', weekValue: '6' },
      { weekName: '7', weekValue: '7' },
      { weekName: '8', weekValue: '8' },
      { weekName: '9', weekValue: '9' },
      { weekName: '10', weekValue: '10' },
      { weekName: '11', weekValue: '11' },
      { weekName: '12', weekValue: '12' },
    ])

    const reportTypes = 'Select a Report,By Round/Week,By Location,By Division'

    const refLabel = computed(() => store.getters.leagueAbstraction.refLabel)
    const isUpwardSelect = computed(() => store.getters.leagueAbstraction.isUpwardSelect)

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    const programs = computed((): string[] => {
      if (isCamp.value) {
        return camp.value.programs?.map((p) => p.typeProgramID) ?? []
      }
      return league.value.programs?.map((p) => p.typeProgramID) ?? []
    })

    const hasCheer = computed((): boolean => {
      if (isCamp.value) {
        return false
      }

      return (league.value && league.value.programs?.some((p) => isCheer(p.typeProgramID))) ?? false
    })

    const reports = computed((): string[] => reportTypes.split(','))

    onMounted(() => {
      selectedProgram.value = typeProgramID.value
    })

    watch(
      () => [selectedReport.value, selectedProgram.value],
      async () => {
        selectedDivision.value = -1
        selectedWeek.value = -1
        divisions.value = []

        const myDivisions =
          (await divisionsClient.retrieveByProgram(upwardLeagueId.value, selectedProgram.value)) ?? []

        //default is all
        divisions.value.push({
          divisionID: 0,
          divisionName: 'All' ?? '',
        })

        myDivisions.forEach((r) =>
          divisions.value.push({
            divisionID: r.divisionID,
            divisionName: r.divisionName ?? '',
          })
        )

        filterByWeek.value = selectedReport.value.trim() == 'By Round/Week'
      }
    )

    function reportLoaded() {
      loading.value = false
    }

    const incomplete = computed(
      () => selectedReport.value.trim() == 'Select a Report' || selectedDivision.value == -1
    )

    async function loadReport() {
      loading.value = true

      loadStatus.value = 'Downloading Report Data . . .'
      const data = await Promise.all([
        reportsClient.getGameSchedule(
          upwardLeagueId.value,
          selectedDivision.value.toString(),
          selectedProgram.value
        ),
      ])

      loadStatus.value = 'Downloading Report Templates . . .'
      const reportTemplates = await Promise.all([
        reportsClient.getReportTemplate('gamesScheduleByRound'),
        reportsClient.getReportTemplate('gamesScheduleByLocation'),
        reportsClient.getReportTemplate('gamesScheduleByDivision'),
      ])

      const roundReport = JSON.parse(reportTemplates[0]?.jsonContent ?? '')
      const locationReport = JSON.parse(reportTemplates[1]?.jsonContent ?? '')
      const divisionReport = JSON.parse(reportTemplates[2]?.jsonContent ?? '')

      const mainData = data[0]

      loadStatus.value = 'Loading Report . . .'
      const activeReport: any = ctx.refs.activereport

      const parameters = [
        {
          Value: [displayHeadCoach.value],
          Name: 'DisplayHeadCoach',
        },
        {
          Value: [selectedWeek.value],
          Name: 'SelectedWeekNumber',
        },
        {
          Value: [displayRefereeName.value],
          Name: 'DisplayReferees',
        },
      ]

      if (selectedReport.value.trim() == 'By Round/Week') {
        roundReport.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)

        activeReport.loadReportData(roundReport, { ReportParams: parameters })
      } else if (selectedReport.value.trim() == 'By Location') {
        locationReport.DataSources[0].ConnectionProperties.ConnectString =
          'jsondata=' + JSON.stringify(mainData)

        activeReport.loadReportData(locationReport, { ReportParams: parameters })
      } else {
        divisionReport.DataSources[0].ConnectionProperties.ConnectString =
          'jsondata=' + JSON.stringify(mainData)

        activeReport.loadReportData(divisionReport, { ReportParams: parameters })
      }
    }

    return {
      reports,
      selectedReport,
      loading,
      hasCheer,
      programs,
      selectedProgram,
      divisions,
      selectedDivision,
      filterByWeek,
      weekNumbers,
      selectedWeek,
      displayHeadCoach,
      displayRefereeName,
      isUpwardSelect,
      refLabel,
      incomplete,
      loadStatus,
      loadReport,
      reportLoaded,
    }
  },
})
