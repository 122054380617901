




















import { defineComponent, computed, inject } from '@vue/composition-api'
import { VueConstructor } from 'vue'
import uuid from 'uuid'

/// <reference path="@/modules/vue-slide-up-down.d.ts" />
import SlideUpDown from 'vue-slide-up-down'

export default defineComponent({
  name: 'AccordionItem',
  components: {
    SlideUpDown: (SlideUpDown as unknown) as VueConstructor,
  },
  setup() {
    const accordionData = inject('accordionData', { currentGuid: '' })
    const guid = uuid.v4()

    function toggle() {
      accordionData.currentGuid = isExpanded.value ? '' : guid
    }

    const isExpanded = computed(() => accordionData.currentGuid === guid)

    return {
      isExpanded,
      toggle,
      accordionData,
    }
  },
})
