var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{class:[{ 'data-table-zebra': _vm.tableStriped, 'table-hover': _vm.tableStriped }, 'simple-grid'],staticStyle:{"width":"100%"}},[(_vm.internalColumns)?_c('thead',[_c('tr',[_vm._l((_vm.internalColumns),function(column,index){return _c('th',{key:index,staticClass:"py-2 text-nowrap",class:{
          'go-left': _vm.alignment(column) === 'left',
          'go-right': _vm.alignment(column) === 'right',
          clickable: !_vm.disableHeaderSort,
        },style:(_vm.calcwidth(column)),on:{"click":function($event){return _vm.headerClick(column)}}},[_c('div',[_c('span',[_vm._v(_vm._s(column.headerText || column.field))]),(_vm.sortColumn == column.field)?_c('i',{staticClass:"pl-1 fas fa-sort"}):_vm._e()])])}),(_vm.showDeleteButtonHeader)?_c('th',{staticClass:"py-1"}):_vm._e(),(_vm.showEditButtonHeader)?_c('th',{staticClass:"py-1"}):_vm._e()],2)]):_vm._e(),(_vm.internalData && _vm.internalColumns)?_c('tbody',_vm._l((_vm.internalData),function(entry,index){return _c('tr',{key:index,class:{ 'selected-row': _vm.selectedIndex == index },on:{"click":function($event){return _vm.handleRowClicked(index, entry, 'rowClicked')},"dblclick":function($event){return _vm.handleRowClicked(index, entry, 'rowDoubleClicked')}}},[_vm._l((_vm.internalColumns),function(columns,index){return _c('td',{key:index,staticClass:"py-2",class:{
          'go-left': _vm.alignment(columns) === 'left',
          'go-right': _vm.alignment(columns) === 'right',
        }},[(columns.template)?_c('span',[_c(_vm.getColumnComponent(columns.template),{tag:"component",attrs:{"data":entry}})],1):(columns.customRender)?_c('span',{domProps:{"innerHTML":_vm._s(
            columns.customRender(
              _vm.resolvePath(entry, columns.field, '') && _vm.resolvePath(entry, columns.field, '')
            )
          )}}):_c('span',[_vm._v(_vm._s(_vm.resolvePath(entry, columns.field, '') && _vm.resolvePath(entry, columns.field, '')))])])}),(_vm.showEditButton)?_c('td',{staticClass:"py-2"},[_c('button',{class:_vm.editButtonClasses,domProps:{"innerHTML":_vm._s(_vm.editButtonInnerHtml)},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditClicked(entry)}}})]):_vm._e(),(_vm.showDeleteButton)?_c('td',{staticClass:"py-2"},[_c('button',{class:_vm.deleteButtonClasses,domProps:{"innerHTML":_vm._s(_vm.deleteButtonInnerHtml)},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteClicked(entry)}}})]):_vm._e()],2)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }