



import { defineComponent, computed } from '@vue/composition-api'
import { productOfferingProductPackageTypes } from '@/store/productOfferingProductPackageTypes'
export default defineComponent({
  name: 'name',
  props: {
    packageId: { type: String, required: true },
  },
  components: {},
  setup(props) {
    const icon = computed(
      () =>
        productOfferingProductPackageTypes.state.items.find((p) => p.upwardTypeID == props.packageId)
          ?.icons ?? ''
    )
    const iconClass = computed(
      () =>
        productOfferingProductPackageTypes.state.items.find((p) => p.upwardTypeID == props.packageId)
          ?.class ?? ''
    )
    const cssClasses = computed(() => `${iconClass.value} ${icon.value}`)
    return { cssClasses }
  },
})
