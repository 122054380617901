export interface Result {
  recipient: string
  type: string
  source: string
  description: string
  created: string
  updated: string
  subaccount_id: string
  non_transactional: boolean
}

export interface EmailSuppressionStatus {
  results: Result[]
  links: any[]
  total_count: number
}

export function emptyEmailSuppressionStatus(): EmailSuppressionStatus {
  return {
    results: [],
    links: [],
    total_count: 0,
  }
}
