
































import { defineComponent, computed, ref, onMounted, PropType } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
import SmallCard from '@/components/SmallCard.vue'
import partnersClient from '@/clients/partnersClient'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { EditIcon } from '@/elements/Icons.ts'

export default defineComponent({
  name: 'ContactPicker',
  components: {
    SmallCard,
    Loading,
    EditIcon,
  },
  props: {
    value: { type: Object as PropType<ContactInfo | null>, required: true },
    accountNumber: { required: true, type: String },
  },
  setup(props, ctx) {
    const contacts = ref<ContactInfo[]>([])
    const isLoading = ref(true)

    async function select(contact: ContactInfo) {
      await refreshData()
      contact = contacts.value.find((c) => c.id === contact.id) || contact

      ctx.emit('input', contact)
      ctx.emit('change', contact)
    }

    function clearSelection() {
      ctx.emit('input', null)
      ctx.emit('change', null)
    }

    async function refreshData() {
      isLoading.value = true
      const result = await partnersClient.retrieveAccountContacts(props.accountNumber)

      if (result.isSuccess) {
        contacts.value = result.data!
      }

      isLoading.value = false
    }

    onMounted(async () => {
      await refreshData()
    })

    const selectedId = computed(() => {
      if (!props.value) {
        return null
      }

      return props.value.id
    })

    function onClickNew() {
      clearSelection()
    }

    const selectedContact = computed(() => {
      const trainedContact = contacts.value.find((c) => c.id === selectedId.value)

      if (trainedContact) {
        return trainedContact
      }

      // Sometimes the current director is not returned in the list of "trained leaders"
      return props.value?.fullName?.trim() ? props.value : null
    })

    const contactsToDisplay = computed(() => {
      if (!selectedContact.value || contacts.value.findIndex((c) => c.id === selectedId.value) > -1) {
        return contacts.value
      }

      return [selectedContact.value, ...contacts.value]
    })

    return {
      isLoading,
      contactsToDisplay,
      selectedId,
      onClickNew,
      select,
    }
  },
})
