























































import { defineComponent, ref, computed } from '@vue/composition-api'
import SidebarItem from '@/components/SidebarItem.vue'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import store from '@/store'

export default defineComponent({
  name: 'TheSidebar',
  components: {
    SidebarItem,
  },
  props: {
    flavor: { type: String, required: false, default: 'regular' },
  },
  setup(props, ctx) {
    const { features } = useFeatureFlags()
    const userToken = computed(() => store.getters.authorization.userData)
    const impersonationAccountNumber = computed(() => store.getters.authorization.impersonationAccountNumber)
    const isCurrentUserASuperUser = computed(() => store.getters.authorization.isCurrentUserASuperUser)

    const activeListItem = ref(0)

    function clickedDirectorPortal() {
      const mobileSiteURL = process?.env?.VUE_APP_URL_MOBILE_REDIRECT
      const chit = `${userToken.value}.${impersonationAccountNumber.value}`
      const url = `${mobileSiteURL}?token=${encodeURIComponent(chit)}`
      document.location.assign(url)
    }

    /**
     * Quick boolean, are we a regular menu
     */
    const regularFlavor = computed(() => props.flavor == 'regular')

    /**
     * Are we a dashboard side menu
     */
    const dashboardFlavor = computed(() => props.flavor == 'dashboard')

    function navigate(route: string) {
      if (ctx.root.$route.path === route) return
      ctx.root.$router.push(route).catch(() => {
        //err
      })
    }

    return {
      features,
      activeListItem,
      regularFlavor,
      dashboardFlavor,
      isCurrentUserASuperUser,
      navigate,
      clickedDirectorPortal,
      logout: store.commit.authorization.clearCurrentCredentials,
    }
  },
})
