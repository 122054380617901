import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
export type PlayerInfoIDType = number
import { getEmptyAddress } from '@/lib/support/models/IndividualAddressInfo/data'

export const getEmptyLeaguePlayerInfo = (props: LeaguePlayerSeedInfo) => {
  const rv: LeaguePlayerInfo = {
    accountNumber: '',
    leagueID: props.leagueID,
    typeProgramID: props.typeProgramID,
    active: true,
    addresses: [getEmptyAddress()],
    adminNotes: '',
    amountExpectedToPay: 0,
    registrationFeeExpectedToPay: 0,
    amountPaid: 0,
    athleticScore: 0,
    birthDate: new Date('1970-01-01 00:00'),
    churchName: '',
    emailAddresses: [],
    evaluationScore: 0,
    firstName: '',
    formattedNameFirstLast: '',
    formattedNameLastFirst: '',
    formattedNamePrivate: '',
    gender: 'M',
    generalNotes: '',
    individualID: newPlayerID(),
    isImported: false,
    isPlayerSizingComplete: false,
    lastName: '',
    medicalNotes: '',
    middleInitial: '',
    pendingCoachLinkFirstName: '',
    pendingCoachLinkIndividualID: 0,
    pendingCoachLinkLastName: '',
    phoneNumbers: [],
    positionScore: 0,
    registrationCompletionInformation: '',
    registrationTransactionID: 0,
    thirdPartyClientIDs: [],
    typeGradeID: 'K3',
    yearsExperience: 0,
    hasBrokenCarpoolLink: false,
    hasBrokenCoachLink: false,
    actualCoachLinkIndividualID: 0,
    actualCoachLinkFirstName: '',
    actualCoachLinkLastName: '',
    actualCarpoolLinkIndividualID: 0,
    actualCarpoolLinkFirstName: '',
    actualCarpoolLinkLastName: '',
    extraScore: 0,
    practiceNightExclusions: [],
    products: [],
    udFs: null,
    jerseyNumber: null,
    gradeIsOverridden: false,
    doNotDraft: false,
  }

  return rv
}

export class LeaguePlayerSeedInfo {
  typeProgramID = ''
  leagueID = 0
}

export function newPlayerID(): PlayerInfoIDType {
  return 0
}
