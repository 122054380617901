
























import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { CarpoolLinksBrokenReportType } from '@/clients/reportsClient'
import { downloadCSV } from '@/lib/common/csv/downloadCSV'
import { GridColumn } from '@/models/Grid/GridColumn'

export default defineComponent({
  props: {
    report: {
      type: Array as PropType<Array<CarpoolLinksBrokenReportType>>,
      default: () => [],
      required: true,
    },
  },

  setup(props) {
    const internalReport = ref<Array<CarpoolLinksBrokenReportType>>([])
    const columns: GridColumn[] = [
      { field: 'DivisionName', headerText: 'Division' },
      { field: 'ParticipantName', headerText: 'Participant Making Request' },
      { field: 'RequestedParticipantName', headerText: 'Requested Participant' },
    ]

    function download() {
      downloadCSV(columns, internalReport.value, 'carpool_request_not_linked.csv')
    }

    watch(
      () => props.report,
      () => (internalReport.value = props.report),
      { immediate: true }
    )

    return { internalReport, download }
  },
})
