























import { defineComponent, ref, watch } from '@vue/composition-api'
import { getProps, useSelectList } from '@/composables/SelectListProperties'

export default defineComponent({
  name: 'ToggleLinks',
  props: {
    value: { type: String, required: true, default: () => '' },
    ...getProps(),
  },
  setup(props, ctx) {
    const { getOptionValue, getOptionKey, getOptionText } = useSelectList(props)
    const internalValue = ref('')

    function select(item: Record<symbol, string>, index: number) {
      internalValue.value = getOptionValue(item, index)
      input()
    }

    function input() {
      ctx.emit('input', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      },
      { immediate: true }
    )

    return {
      internalValue,
      select,
      getOptionValue,
      getOptionKey,
      getOptionText,
    }
  },
})
