











































































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { getEmptyUpwardProgramTypeIDList } from '@/lib/support/models/UpwardTypes/UpwardProgramTypeID'
import { programByType } from '@/lib/common/ProductOfferingConfig'
import { SavedProductOfferingConfig } from 'src/GeneratedTypes/SavedProductOfferingConfig'
import UpwardValidationObserver from '@/components/UpwardValidationObserver.vue'
import IntegerInput from '@/elements/IntegerInput.vue'
import TextInput from '@/elements/TextInput.vue'
import DateInput from '@/elements/DateInput.vue'
import GradeGenderEstimateSelector from '@/components/GradeGenderEstimateSelector.vue'
import {
  ProgramInformation,
  getEmptyProgramInformation,
} from '@/components/ProductFlow/ProductSetupStepper/ProgramInformation/ProgramInformationTypes'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import store from '@/store'

let useTestData: any = null
if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
  import('./testData').then((x) => (useTestData = x))
}

export default defineComponent({
  name: 'ProgramInformationForm',
  components: {
    TextInput,
    IntegerInput,
    DateInput,
    UpwardValidationObserver,
    GradeGenderEstimateSelector,
  },
  props: {
    isEstimate: { type: Boolean, required: false, default: true },
    leaguePrograms: {
      type: Array as PropType<UpwardProgramTypeID[]>,
      default: getEmptyUpwardProgramTypeIDList,
      required: false,
    },
  },
  setup(props, ctx) {
    const startingSelection = computed(() => store.getters.leagues.startingSelection)
    const productOfferingConfig = computed(() => store.getters.productOfferings.productOfferingConfig)

    const localInfo = ref<ProgramInformation>(getEmptyProgramInformation())
    const isValid = ref(false)
    const dateLimits = ref({
      min: '',
      max: '',
    })

    const logic = productFlowLogic()

    onMounted(() => {
      defaultDateRange()
      if (productOfferingConfig.value.firstPracticeDateEstimate) {
        setLocalData()
      } else {
        setLocalGrades() //this should always be done
      }
      setTestData()
    })

    function defaultDateRange() {
      dateLimits.value.min = new Date().toLocaleDateString()
      dateLimits.value.max = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toLocaleDateString()
    }

    const hasCheer = computed(() => {
      const cheerProgram = programByType(props.leaguePrograms, 'CHEER')
      return cheerProgram ? true : false
    })

    function onValidEvent(v: boolean) {
      isValid.value = v && areEstimatedValuesValid()
    }

    function sendDataToStore() {
      logic.markAsDirty()
      const info = prepData(localInfo.value)
      store.commit.productOfferings.setProductOfferingConfig({ item: info })
    }

    function prepData(info: ProgramInformation) {
      const clone = cloneDeep(productOfferingConfig.value) as SavedProductOfferingConfig // get copy with Details and Starter Orders intact
      clone.name = info.name
      clone.cheerCoachEstimate = toNumber(info.cheerCoachEstimate)
      clone.cheerEstimate = toNumber(info.cheerEstimate)
      clone.playerEstimate = toNumber(info.playerEstimate)
      clone.sportCoachEstimate = toNumber(info.sportCoachEstimate)
      if (props.isEstimate) {
        // setting gradegender is handled in ProductOfferingConfig for non-estimates
        clone.gradesByGender = info.grades ? [...cloneDeep(info.grades)] : []
        if (info.firstPracticeDateEstimate) {
          clone.firstPracticeDateEstimate = info.firstPracticeDateEstimate
        }
      }

      return clone
    }

    function toNumber(val: string) {
      const n = Number(val)
      return isNaN(n) ? 0 : n
    }

    function setLocalData() {
      localInfo.value.name = productOfferingConfig.value.name || estimateName.value
      localInfo.value.cheerCoachEstimate = nonZero(productOfferingConfig.value.cheerCoachEstimate)
      localInfo.value.cheerEstimate = nonZero(productOfferingConfig.value.cheerEstimate)
      localInfo.value.playerEstimate = nonZero(productOfferingConfig.value.playerEstimate)
      localInfo.value.sportCoachEstimate = nonZero(productOfferingConfig.value.sportCoachEstimate)
      localInfo.value.firstPracticeDateEstimate = defaultFirstPracticeDate(
        productOfferingConfig.value.firstPracticeDateEstimate
      )
      setLocalGrades()
    }

    function setLocalGrades() {
      if (productOfferingConfig.value.gradesByGender && productOfferingConfig.value.gradesByGender.length) {
        localInfo.value.grades = JSON.parse(JSON.stringify(productOfferingConfig.value.gradesByGender))
      } else {
        localInfo.value.grades = null
      }
    }

    const estimateName = computed(() => {
      const today = dayjs().format('M-D-YY')
      return startingSelection.value ? `${startingSelection.value.name} ${today}` : ''
    })

    function nonZero(val: number): string {
      if (!val) return ''
      return val === 0 ? '' : val.toString()
    }

    function defaultFirstPracticeDate(configDate: Date | null) {
      if (!configDate) return null
      return configDate
    }

    watch(
      () => productOfferingConfig.value,
      () => {
        setTestData()
        setLocalData()
      }
    )

    watch(
      () => isValid.value,
      () => {
        ctx.emit('valid', isValid.value)
      }
    )

    function setTestData() {
      //if process.env.VUE_APP_USE_TEST_DATA == 'YES' this will run
      if (useTestData) {
        if (!productOfferingConfig.value.playerEstimate) {
          useTestData.setDatesForNewEstimate(hasCheer.value)
        }
      }
    }

    watch(
      () => [
        localInfo.value.playerEstimate,
        localInfo.value.cheerEstimate,
        localInfo.value.sportCoachEstimate,
        localInfo.value.cheerCoachEstimate,
      ],
      () => {
        isValid.value = areEstimatedValuesValid()
      }
    )

    function areEstimatedValuesValid() {
      const playerEstimate = parseInt(localInfo.value.playerEstimate)
      const cheerEstimate = parseInt(localInfo.value.cheerEstimate)
      const sportCoachEstimate = parseInt(localInfo.value.sportCoachEstimate)
      const cheerCoachEstimate = parseInt(localInfo.value.cheerCoachEstimate)

      if (!playerEstimate || playerEstimate <= 0) {
        return false
      }
      if (hasCheer.value && (!cheerEstimate || cheerEstimate <= 0)) {
        return false
      }
      if (!sportCoachEstimate || sportCoachEstimate <= 0) {
        return false
      }
      if (hasCheer.value && (!cheerCoachEstimate || cheerCoachEstimate <= 0)) {
        return false
      }
      if (props.isEstimate && !localInfo.value.firstPracticeDateEstimate) {
        return false
      }
      return true
    }

    return {
      onValidEvent,
      localInfo,
      sendDataToStore,
      dateLimits,
      hasCheer,
    }
  },
})
