




























import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import Modal from '@/components/Modal.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'

import * as facilityStore from '@/store/facilities'

import { DataTableSelection } from '@/models/DataTable/DataTableSelection'
import { DataTableHeader } from 'vuetify/types'

@Component({
  components: {
    Modal,
    DataTable,
  },
})
export default class GroupList extends Vue {
  modalIsVisible = false
  isDirty = false

  @Action(facilityStore.actionNames.loadGroup, {
    namespace: facilityStore.namespace,
  })
  private readonly loadGroups!: ({ id }: { id: string }) => string[] | null

  /**
   * mounted
   */
  public async mounted() {
    const leagueID = this.$route.params.id
    const facilityGroups = await this.loadGroups({ id: leagueID.toString() })

    if (facilityGroups) {
      const groupData: any[] = []
      facilityGroups.forEach((g: string) => {
        groupData.push({ name: g })
        this.groups = groupData
      })
    }
  }
  public toggle() {
    this.modalIsVisible = !this.modalIsVisible
  }

  private updateSelections(row: any) {
    this.selectedGroup = row.row.name
  }

  rowSelected(x: DataTableSelection<{ name: string }>) {
    this.selectedGroup = x.item.name
  }

  private cancel() {
    this.modalIsVisible = false
  }
  private select() {
    this.modalIsVisible = false
    this.$emit('onGroupSelectionChanged', this.selectedGroup)
  }

  groups: any[] | null = []
  selectedGroup!: string

  headers: DataTableHeader[] = [{ text: 'Group Name', value: 'name' }]
}
