












































import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import VerficationCode, { ProfileCredentials } from '@/views/MyProfile/VerificationCode.vue'

import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { namespace, actionNames, getterNames, mutationNames } from '@/store/authorization'
import { ProfilePayload } from '@/models/Authorization/ProfilePayload'

@Component({
  components: {
    Loading,
    TextInput,
    VerficationCode,
  },
})
export default class Info extends Vue {
  private loading = false
  private fullName = ''
  private email = ''
  private redirect = false

  @Getter(getterNames.fullName, { namespace })
  private currentFullName!: string | null

  @Getter(getterNames.email, { namespace })
  private currentEmail!: string | null

  @Getter(getterNames.daysUntilPasswordExpiration, { namespace })
  private daysUntilPasswordExpiration!: number | null

  @Mutation(mutationNames.clearCurrentCredentials, { namespace })
  private logout!: () => void

  @Action(actionNames.updateProfile, { namespace })
  private updateUserProfile!: ({
    payload,
  }: {
    payload: ProfilePayload
  }) => Promise<{ incompleteToken: boolean }>

  private get showDaysUntilExpiration() {
    return this.daysUntilPasswordExpiration !== null && this.daysUntilPasswordExpiration < 10
  }

  get canSave() {
    return this.email && this.fullName
  }

  verify = false
  get credentials() {
    return {
      newEmail: this.email,
      newFullName: this.fullName,
    } as ProfileCredentials
  }
  private async saveUser() {
    if (this.isNewEmail || this.isNewFullName) {
      this.verify = this.isNewEmail
      await this.updateProfile()
    }
  }

  get isNewEmail() {
    return this.email !== this.currentEmail
  }
  get isNewFullName() {
    return this.fullName !== this.currentFullName
  }

  private async updateProfile() {
    this.loading = true
    try {
      const r = await this.updateUserProfile({
        payload: {
          newUsername: this.isNewEmail ? this.email : null,
          newFullName: this.isNewFullName ? this.fullName : null,
        },
      })

      if (r?.incompleteToken) {
        this.redirect = true
        setTimeout(this.logout, 6000)
      }
    } finally {
      this.loading = false
    }
  }

  cancelVerification() {
    this.verify = false
  }

  private mounted() {
    this.fullName = this.currentFullName ?? ''
    this.email = this.currentEmail ?? ''
  }
}
