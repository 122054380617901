



































import { defineComponent, ref, watch } from '@vue/composition-api'

import ReportModal from '@/components/ReportModal.vue'

import reportsClient from '@/clients/reportsClient'

import { DraftResultRecord } from '@/models/Reports/DraftResultRecord'

export default defineComponent({
  name: 'DraftSummaryReportModal',
  components: {
    ReportModal,
  },
  props: {
    divisionName: { type: String, required: true },
    value: { type: Boolean, required: true },
    divisionId: { type: Number, default: 0, required: false },
    typeProgramId: { type: String, default: '', required: false },
    upwardLeagueId: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const modalIsVisible = ref(props.value)
    const reportResults = ref<DraftResultRecord[]>([])

    async function doShowDraftResultsModal() {
      if (props.upwardLeagueId && props.divisionId && props.typeProgramId) {
        let results = await reportsClient.getDraftLog(
          props.upwardLeagueId,
          props.typeProgramId,
          props.divisionId
        )

        if (!results) {
          results = []
        }

        reportResults.value = results
      }

      modalIsVisible.value = true
    }

    watch(
      () => modalIsVisible.value,
      async () => {
        if (modalIsVisible.value != props.value) {
          ctx.emit('input', modalIsVisible.value)
        }

        if (modalIsVisible.value) {
          await doShowDraftResultsModal()
        }
      }
    )

    watch(
      () => props.value,
      () => {
        if (modalIsVisible.value != props.value) {
          modalIsVisible.value = props.value
        }
      }
    )

    return {
      modalIsVisible,
      reportResults,
    }
  },
})
