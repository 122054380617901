/**
 * Rest Operations for invitations
 */

import restService from '@/services/restService'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'

import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { RoleIDToRoleName } from '@/views/Programs/Volunteers/ext/roleutils'
import { InviteContact } from '@/models/VolunteerManagement/Invitations/InviteContact'
import { ApplicationInvitation } from '@/GeneratedTypes/Authorize/ApplicationInvitation'

const baseUrl = 'invitations/'
function ourURL() {
  return `${baseUrl}`
}

const DEFAULT_APPLICATION_ID = 8 //no idea why

interface TextRoles {
  roleID: string
}

class InvitePostParams {
  constructor({
    accountNumber,
    individualID,
    recipient,
    email,
    upwardLeagueID,
    phone,
    roles,
  }: {
    accountNumber: string
    individualID: number
    recipient: string
    email: string
    upwardLeagueID: UpwardLeagueIDType
    phone: string
    roles: TextRoles[]
  }) {
    this.accountNumber = accountNumber
    this.individualID = individualID
    this.recipient = recipient
    this.email = email
    this.upwardLeagueID = upwardLeagueID
    this.roles = roles
    this.phone = phone
  }
  id: string | null = null
  applicationID = DEFAULT_APPLICATION_ID
  accountNumber = '0'
  //** name of recipient
  recipient = ''
  //** email of recipient
  email = ''
  //** blank for our uses
  invitationMessage = ''
  //** individual id
  individualID = 0
  //** upward ID
  upwardLeagueID: UpwardLeagueIDType = ''
  //** phone
  phone = ''
  //** weirdly text roles.
  roles: TextRoles[] = []
}

interface InviteArgs {
  //** UPW
  leagueID: UpwardLeagueIDType
  //** I think this comes off the contact info, nessesary to complete API

  invitations: InviteContact[]
}

class InviteClientException extends GeneralError {
  name = 'Invite API Error'
}

export default class inviteClient {
  /**
   * Sends or resends an invitation
   * @param args UPW### league and id
   * @return array of league volunteers from the league id
   */
  static async inviteVolunteers(args: InviteArgs): Promise<void> {
    const emailInvites: InvitePostParams[] = []

    args.invitations.forEach((x) => {
      const roles = x.roles.map((u) => ({ roleID: RoleIDToRoleName(u) || '' })) || []
      emailInvites.push(
        new InvitePostParams({
          individualID: x.id,
          upwardLeagueID: args.leagueID,
          email: x.email,
          accountNumber: x.accountNumber,
          recipient: x.name,
          phone: x.phone,
          roles: roles.filter((r) => r.roleID),
        })
      )
    })

    const result = await restService.post<void>(`${ourURL()}volunteer`, emailInvites)
    if (result.isSuccess) {
      return
    }

    throw new InviteClientException('Invite server error while sending email.')
  }

  /**
   * Sends or resends invitations with SMS
   * @param args UPW### league and id
   * @return array of league volunteers from the league id
   */
  static async inviteVolunteersWithSms(args: InviteArgs): Promise<void> {
    const smsInvites: InvitePostParams[] = []

    args.invitations.forEach((x) => {
      const roles = x.roles.map((u) => ({ roleID: RoleIDToRoleName(u) || '' })) || []
      smsInvites.push(
        new InvitePostParams({
          individualID: x.id,
          upwardLeagueID: args.leagueID,
          email: x.email,
          accountNumber: x.accountNumber,
          recipient: x.name,
          phone: x.phone,
          roles: roles.filter((r) => r.roleID),
        })
      )
    })

    const result = await restService.post<void>(`${ourURL()}sms`, smsInvites)
    if (result.isSuccess) {
      return
    }

    throw new InviteClientException('Invite server error while sending SMS.')
  }

  static async invitePartnerUser(
    email: string,
    accountNumber: string,
    recipient: string,
    role: string,
    message: string,
    phone: string,
    id: string | null
  ): Promise<void> {
    const y: InvitePostParams[] = []

    y.push({
      id,
      applicationID: DEFAULT_APPLICATION_ID,
      accountNumber: accountNumber,
      recipient: recipient,
      email: email,
      invitationMessage: message,
      individualID: 0,
      upwardLeagueID: '',
      phone: phone,
      roles: [
        {
          roleID: 'Director',
        },
      ],
    })

    /*
        Task 4735 removed the data input role and now director is the only option. 
        Some of the plumbing for "roles" needed to stay so RoleID gets populated with 'Director'. 
        RoleID is now set to 'Director' instead of 'role' so the selection would default in AddUserModal.vue.
    */
    const x = await restService.post<void>(`${ourURL()}${phone ? 'sms' : ''}`, y)
    if (x.isSuccess) {
      return
    }

    throw new InviteClientException('Invite server error communications error.')
  }

  static async getOpenPartnerInvitations(accountNumber: string): Promise<ApplicationInvitation[] | null> {
    const x = await restService.get<ApplicationInvitation[]>(`${ourURL()}open/${accountNumber}`)
    if (x.isSuccess) {
      return x.data?.filter((i) => (i.roles?.filter((r) => r.roleID == 'Director') ?? []).length > 0) ?? []
    }

    throw new InviteClientException('Invite server error communications error.')
  }

  static async deleteInvitation(invitationId: string): Promise<void> {
    const x = await restService.delete<void>(`${ourURL()}${invitationId}`)
    if (x.isSuccess) {
      return
    }

    throw new InviteClientException('Invite server error communications error.')
  }

  static async unwaitlist(leagueID: UpwardLeagueIDType, waitListID: number): Promise<void> {
    try {
      const x = await restService.delete<void>(`Registrations/waitlist/${leagueID}/${waitListID}`)
      if (x.isSuccess) {
        return
      }
    } catch {
      throw new InviteClientException('Could not remove entry from waitlist.')
    }

    throw new InviteClientException('Invite server error communications error.')
  }
}
