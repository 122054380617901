










import { defineComponent } from '@vue/composition-api'
import PageContent from '@/components/PageContent.vue'

export default defineComponent({
  name: 'Questions',
  components: {
    PageContent,
  },
})
