

















































































































import { computed, defineComponent, PropType, ref, onMounted } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

import InputLabel from '@/elements/InputLabel.vue'
import OrderListSection from './OrderListSection.vue'
import ShippingAddressDisplay from '@/components/ShippingAddressDisplay.vue'
import OrderShipper from '@/components/OrderHistory/OrderShippers.vue'
import CostSummary from '@/components/OrderHistory/CostSummary.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import OrderRollup from '@/views/Programs/Orders/league/components/OrderRollup.vue'
import ParticipantOrderGrouped from './ParticipantOrderGrouped.vue'

import { OrderInfo } from '@/GeneratedTypes/Order/ListInfo/OrderInfo'
import { CFormatOrder } from '@/models/Order/xStartupOrderExt'
import { SectionNameConversion } from '@/views/Programs/Orders/vues/history/ext/SectionNames'
import { useOrderRollup } from '@/views/Programs/Orders/compositions/userOrderRollup'
import store from '@/store'

export default defineComponent({
  name: 'OrderDetail',
  components: {
    OrderListSection,
    InputLabel,
    ShippingAddressDisplay,
    OrderShipper,
    CostSummary,
    SwitchSlot,
    OrderRollup,
    ParticipantOrderGrouped,
  },
  props: {
    order: { type: Object as PropType<CFormatOrder>, required: true },
    orderInfo: { type: Object as PropType<OrderInfo>, required: true },
  },
  setup(props) {
    const trackingNumbers = computed(() => props.orderInfo.trackingNumbers?.map((x) => x.trackingNumber))
    const shippingMethods = computed(() => props.order.shippingInfo?.shipMethods?.filter((x) => x.selected))
    const shipDate = computed(() =>
      props.orderInfo.shipDate
        ? dayjs(props.orderInfo.shipDate).format('MMM D, YYYY')
        : 'Order has not shipped'
    )

    const statusList = computed(() =>
      cloneDeep(props.orderInfo.statusList ?? []).sort((a, b) =>
        a.valueOf() == b.valueOf() ? 0 : a.valueOf() > b.valueOf() ? -1 : 1
      )
    )

    const statusText = computed(() => props.orderInfo.upwardOrderStatusID ?? 'n/a')
    const orderDate = computed(() => props.orderInfo?.createDate && formatDate(props.orderInfo.createDate))
    function formatDate(d: Date) {
      return dayjs(d).format('MMM D, YYYY h:mm a')
    }
    const orderTitle = computed(() => SectionNameConversion[props.order.upwardOrderType ?? ''])

    const orderRollup = useOrderRollup()
    orderRollup.getRollup(props.order?.upwardLeagueID ?? '', props.order?.upwardOrderID ?? '')

    const viewOptions = [
      { text: 'Price Breakdown', value: 'price-breakdown' },
      { text: 'Grouped By Division/Team', value: 'grouped' },
      { text: 'Sortable Listing', value: 'raw' },
    ]
    const viewSwitch = ref(props.orderInfo?.typeOrderID === 'LEAGUEORDER' ? viewOptions[0].value : 'raw')

    onMounted(() => {
      store.dispatch.programTypes.fetchAll(false) //go ahead and cache these up
    })

    return {
      statusText,
      trackingNumbers,
      shippingMethods,
      shipDate,
      orderDate,
      statusList,
      formatDate,
      orderTitle,
      viewOptions,
      viewSwitch,
      rollups: computed(() => orderRollup.rollup.value),
    }
  },
})
