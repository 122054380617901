import { RegistrationCapacityWaitList } from '@/GeneratedTypes/RegistrationCapacityWaitList'

export function getEmptyRegistrationCapacityWaitList(): RegistrationCapacityWaitList {
  return {
    id: 0,
    leagueID: 0,
    typeProgramID: '',
    parentFullName: '',
    childFirstName: '',
    childLastName: '',
    childMiddleInitial: null,
    typeGradeID: '',
    gender: '',
    phone: null,
    email: null,
    note: '',
    invitationCode: null,
    registrationTransactionID: 0,
    invitationSent: null,
    invitationExpire: null,
    invitationDeclined: null,
  }
}
