import restService from '@/services/restService'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import { RegistrationCountRequest } from '@/models/Program/RegistrationCountRequest'
import { RegistrationCapacityByGradeGenderInfo } from '@/GeneratedTypes/ListInfo/RegistrationCapacityByGradeGenderInfo'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { ImportProductMappingInfo } from '@/GeneratedTypes/ListInfo/ImportProductMappingInfo'
import { RegistrationCapacityCount } from '@/models/Program/RegistrationCapacityCount'

class ProgramClientException extends GeneralError {
  name = 'Program Client API Error'
}

const baseUrl = 'programs/'

const retrieveImportProductMapping = async (upwId: string) => {
  const results = await restService.get<ImportProductMappingInfo[]>(`${baseUrl}${upwId}/importProductMapping`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieveImportProductMapping')
  }
}

const retrieveAll = async (includeHistorical = false) => {
  return await restService.get<LeagueInfoCondensed[]>(`${baseUrl}condensed`, { includeHistorical })
}

const retrieve = async (leagueID: string) => {
  const results = await restService.get<LeagueListItem>(`${baseUrl}${leagueID}`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieve')
  }
}

const getCurrentCapacities = async (leagueID: string, programTypeID: string) => {
  const results = await restService.get<RegistrationCapacityByGradeGenderInfo[]>(
    `${baseUrl}${leagueID}/currentCapacity/${programTypeID}`
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieve')
  }
}

const getRegistrationCount = async (leagueID: string, request: RegistrationCountRequest) => {
  const results = await restService.post<RegistrationCapacityCount>(
    `${baseUrl}${leagueID}/registrationCount`,
    request
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieve')
  }
}

const overrideDraftByProgram = async (leagueID: string, typeProgramID: string, overrideDraft: boolean) => {
  const results = await restService.put<void>(
    `${baseUrl}${leagueID}/overridedraft/${typeProgramID}/${overrideDraft}`,
    {}
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.overrideDraftByProgram')
  }
}

const overrideDraft = async (leagueID: string, overrideDraft: boolean) => {
  const results = await restService.put<void>(`${baseUrl}${leagueID}/overridedraft/${overrideDraft}`, {})
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.overrideDraft')
  }
}

const setPrivacy = async (leagueID: string, isPrivate: boolean) => {
  const results = await restService.put<void>(
    `${baseUrl}${leagueID}/programPrivacy/?isPrivate=${isPrivate}`,
    {}
  )
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieve')
  }
}

const setCancelled = async (leagueID: string, reason: string) => {
  const results = await restService.post<void>(`${baseUrl}${leagueID}/bailProgram`, { reason })
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieve')
  }
}
const removeCheer = async (leagueID: string) => {
  const results = await restService.delete<void>(`${baseUrl}${leagueID}/removecheerleading`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieve')
  }
}

const deleteLeague = async (leagueID: string, reason: string) => {
  const results = await restService.delete<void>(`${baseUrl}${leagueID}/removeprogram`, new Object(reason))
  if (results.isSuccess) {
    return results.data
  } else {
    throw new ProgramClientException('Error in programsClient.retrieve')
  }
}

export default {
  retrieveAll,
  retrieve,
  deleteLeague,
  removeCheer,
  setCancelled,
  setPrivacy,
  getCurrentCapacities,
  getRegistrationCount,
  retrieveImportProductMapping,
  overrideDraft,
  overrideDraftByProgram,
}
