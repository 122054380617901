



















































import { defineComponent, ref, computed, watch, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'
import divisionsClient from '@/clients/divisionsClient'

import SelectInput from '@/elements/SelectInput.vue'

import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import { isCheer } from '@/lib/common/ProgramTypes'
import store from '@/store'

export default defineComponent({
  name: 'PracticeSchedule',
  components: {
    SelectInput,
    ActiveReport,
  },
  setup(props, ctx) {
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const selectedReport = ref('Select a Report')
    const selectedProgram = ref('')

    const selectedDivision = ref(-1)
    const divisions = ref([{}])

    const loading = ref(false)
    const loadStatus = ref('')

    const reportTypes = 'Select a Report,By Week,By Location'

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    const programs = computed((): string[] => {
      if (isCamp.value) {
        return camp.value.programs?.map((p) => p.typeProgramID) ?? []
      }
      return league.value.programs?.map((p) => p.typeProgramID) ?? []
    })

    const hasCheer = computed((): boolean => {
      if (isCamp.value) {
        return false
      }

      return (league.value && league.value.programs?.some((p) => isCheer(p.typeProgramID))) ?? false
    })

    const reports = computed((): string[] => reportTypes.split(','))

    onMounted(() => {
      selectedProgram.value = typeProgramID.value
    })

    watch(
      () => [selectedReport.value, selectedProgram.value],
      async () => {
        selectedDivision.value = -1
        divisions.value = []
        const myDivisions =
          (await divisionsClient.retrieveByProgram(upwardLeagueId.value, selectedProgram.value)) ?? []

        //default is all
        divisions.value.push({
          divisionID: 0,
          divisionName: 'All' ?? '',
        })

        myDivisions.forEach((r) =>
          divisions.value.push({
            divisionID: r.divisionID,
            divisionName: r.divisionName ?? '',
          })
        )
      }
    )

    function reportLoaded() {
      loading.value = false
    }

    const incomplete = computed(
      () => selectedReport.value.trim() == 'Select a Report' || selectedDivision.value == -1
    )

    async function loadReport() {
      loading.value = true
      loadStatus.value = 'Downloading Report Data . . .'
      const data = await Promise.all([
        reportsClient.getPracticeSchedule(
          upwardLeagueId.value,
          selectedDivision.value.toString(),
          selectedProgram.value
        ),
      ])

      loadStatus.value = 'Downloading Report Templates . . .'
      const reportTemplates = await Promise.all([
        reportsClient.getReportTemplate('practiceScheduleByWeek'),
        reportsClient.getReportTemplate('practiceScheduleByLocation'),
      ])

      const weekReport = JSON.parse(reportTemplates[0]?.jsonContent ?? '')
      const locationReport = JSON.parse(reportTemplates[1]?.jsonContent ?? '')

      const mainData = data[0]

      loadStatus.value = 'Loading Report . . .'
      const activeReport: any = ctx.refs.activereport

      if (selectedReport.value.trim() == 'By Week') {
        weekReport.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)

        activeReport.loadReportData(weekReport, null)
      } else if (selectedReport.value.trim() == 'By Location') {
        locationReport.DataSources[0].ConnectionProperties.ConnectString =
          'jsondata=' + JSON.stringify(mainData)

        activeReport.loadReportData(locationReport, null)
      }
    }

    return {
      hasCheer,
      reports,
      selectedReport,
      loading,
      programs,
      selectedProgram,
      divisions,
      selectedDivision,
      incomplete,
      loadStatus,
      loadReport,
      reportLoaded,
    }
  },
})
