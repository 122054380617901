

























































































































































































































































import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import DateInput from '@/elements/DateInput.vue'
import DayOfWeekMultiPicker from '@/elements/DayOfWeekMultiPicker.vue'
import GradeGenderSelector, { SelectedGradeGender } from '@/components/GradeGenderSelector.vue'
import AdditionalParticipantInformation from '@/components/AdditionalParticipantInformation.vue'
import TimezoneSelect from '@/components/TimezoneSelect.vue'
import Modal from '@/components/Modal.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { LeagueVolunteerRoleUDFDefinition } from '@/GeneratedTypes/LeagueVolunteerRoleUDFDefinition'
import { LeaguePlayerUDFDefinition } from '@/GeneratedTypes/LeaguePlayerUDFDefinition'
import { CarPoolLinkLabelOverrideInfo } from '@/GeneratedTypes/ListInfo/CarPoolLinkLabelOverrideInfo'
import leaguesClient from '@/clients/leaguesClient'
import { getCoachUdfs, updateUdfs } from '@/services/udfService'
import {
  getProgramRegistrationOption,
  setProgramRegistrationOption,
  RegistrationType,
} from '@/services/registrationInfoService'
import { getDateWithTime } from '@/lib/support/utilities/time/time.ts'
import dayjs from 'dayjs'

let useTestData: any = null
if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
  import('@/views/Leagues/testData/information').then((x) => (useTestData = x))
}

interface RunLeagueByTypeOption {
  description: string
  value: string
}

@Component({
  components: {
    TextInput,
    SelectInput,
    MemoInput,
    YesNoInput,
    DateInput,
    DayOfWeekMultiPicker,
    SwitchSlot,
    Loading,
    AdditionalParticipantInformation,
    TimezoneSelect,
    Modal,
    GradeGenderSelector,
    SaveContinueButton,
    HtmlEditor,
  },
})
export default class Information extends Mixins(LeagueSetupMixin) {
  private internalPracticeNights: string[] = []
  private helpIsVisible = false
  private gradeGenders = {} as SelectedGradeGender
  private registrationOption: RegistrationType = { registrationTypes: [], onlineRegistrationType: '' }
  private leagueRunByType = 'GRADE'
  private runLeagueByTypeList = [
    { description: 'By Grades', value: 'GRADE' },
    { description: 'By Age', value: 'AGE' },
  ] as RunLeagueByTypeOption[]

  protected allCarPoolLinkLabelOverrides:
    | CarPoolLinkLabelOverrideInfo[]
    | null = [] as CarPoolLinkLabelOverrideInfo[]
  private maxDate = ''
  private minDate = ''
  private lastCutoffDate = null as Date | null
  private cutOffHelpIsVisible = false

  async mounted() {
    if (useTestData) {
      useTestData.setInformationForNewLeague(this.league)
    }
    this.leagueRunByType = this.league.ageCutoffDate ? 'AGE' : 'GRADE'
    this.maxDate = dayjs(this.league.firstPracticeDate ?? '')
      .add(1, 'year')
      .format('MM/DD/YYYY')
    this.minDate = dayjs(this.league.firstPracticeDate ?? '')
      .subtract(1, 'year')
      .format('MM/DD/YYYY')
    this.lastCutoffDate = this.league.ageCutoffDate

    this.allCarPoolLinkLabelOverrides = await leaguesClient.retrieveCarPoolLinkLabelOverride()
  }

  gradeGenderChange(gradeGenders: SelectedGradeGender) {
    this.gradeGenders = gradeGenders
    if (this.league.accounts) {
      this.league.accounts[0].grades = gradeGenders.accountGrades
    }
    if (this.league.programs) {
      if (this.league.programs.length > 0) {
        this.league.programs[0].gradesByGender = gradeGenders.sportProgramGrades
      }
      if (this.league.programs.length > 1) {
        this.league.programs[1].gradesByGender = gradeGenders.cheerProgramGrades
      }
    }
  }

  @Watch('leagueRunByType')
  private onRunByTypeChange() {
    if (this.leagueRunByType == 'GRADE') {
      this.lastCutoffDate = this.league.ageCutoffDate
      this.league.ageCutoffDate = null
    } else {
      this.league.ageCutoffDate = this.lastCutoffDate //put it back to what it was in case they accidentally changed it
    }
  }

  @Watch('league.practiceNights', { immediate: true })
  private onLeaguePracticeNightsChange() {
    this.internalPracticeNights = []

    if (!this.league.practiceNights) {
      return
    }

    for (const pn of this.league.practiceNights) {
      this.internalPracticeNights.push(pn.typeDayID)
    }
  }

  private onInternalPracticeNightsChange() {
    this.league.practiceNights = []

    for (const typeDayID of this.internalPracticeNights) {
      this.league.practiceNights.push({
        typeDayID,
        startTime: '00:00:01',
        endTime: '23:59:59',
      })
    }
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    if (this.league.upwardLeagueID) {
      this.nextRoute = `/programs/league/${this.league.upwardLeagueID}/setup/dates`
    } else {
      this.nextRoute = '/programs/league/setup/dates'
    }
    this.setRegistrationOption()

    this.beforeSave = this.handleBeforeSave
  }

  // eslint-disable-next-line require-await
  private async handleBeforeSave() {
    if (this.league.upwardLeagueID && this.league.isOPRLeague && !this.storeLeague.isOPRLeague) {
      this.league.registrationInfo = {
        allowCreditCard: true,
        allowPayLater: true,
        // Always set the league.registrationInfo.allowSizingEntry to true per 2635
        allowSizingEntry: true,
        allowGeneralNotes: false,
        allowDonations: false,
        allowWaitlist: true,
        onlineRegistrationBeginDate: getDateWithTime(this.league.registrationBeginDate, 0, 0, 0),
        onlineRegistrationEndDate: getDateWithTime(
          this.league.registrationEndDate ?? this.league.firstEvalDate,
          23,
          59,
          59
        ),
        donationExplanation: '',
        registrationStartMessage: '',
        confirmationMessage: '',
        twoChildDiscountAmount: 0,
        threeChildDiscountAmount: 0,
        fourOrMoreChildDiscountAmount: 0,
        allowDiscountCodes: false,
        emailLDOnDiscountCodeUse: false,
        discountCodes: [],
        bypassLinks: [],
        contactLine1: '',
        contactLine2: '',
        contactLine3: '',
        contactLine4: '',
        contactLine5: '',
      }
      this.nextRoute = '/programs/league/setup/registration'
    }
    if (this.league.productOfferingConfig && this.league.programs) {
      const prog0 = this.league.programs[0] //because typescript
      this.league.productOfferingConfig.gradesByGender =
        prog0.gradesByGender?.map((x) => ({
          typeProgramID: prog0.typeProgramID,
          gender: x.gender,
          typeGradeID: x.typeGradeID,
        })) ?? []

      if (this.league.programs.length > 1) {
        const prog1 = this.league.programs[1] //because typescript
        this.league.productOfferingConfig.gradesByGender.push(
          ...(prog1.gradesByGender?.map((x) => ({
            typeProgramID: prog1.typeProgramID,
            gender: x.gender,
            typeGradeID: x.typeGradeID,
          })) ?? [])
        )
      }
    }
  }

  private setRegistrationOption() {
    const registrationOption = getProgramRegistrationOption(this.league)

    if (registrationOption !== null) {
      this.registrationOption = registrationOption
    }
  }

  @Watch('registrationOption', { deep: true })
  private updateRegistrationOptions() {
    if (this.loading) {
      return
    }

    setProgramRegistrationOption(this.league, this.registrationOption)
  }

  private saveUdfs(playerUdfs: LeaguePlayerUDFDefinition[], coachUdfs: LeagueVolunteerRoleUDFDefinition[]) {
    updateUdfs(this.league, playerUdfs, coachUdfs)
  }

  private getCoachUdfList() {
    return getCoachUdfs(this.league)
  }
}
