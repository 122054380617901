import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'

export function getEmptyStartupOrderProductInfo(): StartupOrderProductInfo {
  return {
    isActive: true,
    id: 0,
    upwardProductID: '',
    typeProgramID: '',
    typeProductGroupID: '',
    registrationLabel: '',
    cost: 0,
    sortOrder: 0,
    productColorSizes: null,
    cmsProductName: '',
    cmsProductDescription: '',
    cmsProductImageUrls: [] as string[],
    cmsSizingGuideUrl: '',
    cmsUnitDescription: '',
    digitalFormatAvailable: '',
  }
}

export function getEmptyStartupOrderProductInfoList(): StartupOrderProductInfo[] {
  return [getEmptyStartupOrderProductInfo()]
}
