































































































































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import Modal from '@/components/Modal.vue'
import SelectInput from '@/elements/SelectInput.vue'
import DateInput from '@/elements/DateInput.vue'
import TimePicker from '@/elements/TimePicker2.vue'
import TextInput from '@/elements/TextInput.vue'
import MemoInput from '@/elements/MemoInput.vue'

import { LeagueProgramInfo } from '@/GeneratedTypes/ListInfo/LeagueProgramInfo'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { LeagueSpecialEvent } from '@/GeneratedTypes/LeagueSpecialEvent'

import dayjs from 'dayjs'
import { timeStringSplit } from '@/lib/support/utilities/time/time'
import Loading from '@/elements/Loading.vue'
import { cloneDeep } from 'lodash'
import { programTypes } from '@/store/programTypes'

export default defineComponent({
  name: 'SpecialEventModal',
  components: { Loading, TimePicker, DateInput, SelectInput, Modal, TextInput, MemoInput },
  props: {
    leaguePrograms: { type: Array as PropType<LeagueProgramInfo[]>, required: true },
    divisionList: { type: Array as PropType<LeagueDivisionInfo[]>, required: true },
    teamList: { type: Array as PropType<DivisionTeamInfo[]>, default: () => [] },
    facilityList: { type: Array as PropType<LeagueFacility[]>, default: () => [] },
    specialEvent: { type: Object as PropType<LeagueSpecialEvent>, required: true },
    loading: { type: Boolean, default: () => false },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)
    const internalSpecialEvent = ref<LeagueSpecialEvent>({ ...props.specialEvent })
    const selectedTypeProgramID = ref('NONE')
    const day = ref(new Date())
    const startTime = ref('')
    const endTime = ref('')
    const allDayNoTime = ref(true)

    const programList = computed(() => {
      if (props.leaguePrograms.length == 1) {
        selectedTypeProgramID.value = props.leaguePrograms[0].typeProgramID!
      }
      return programTypes.state.items.filter((p) =>
        props.leaguePrograms.find((x) => p.upwardTypeID == x.typeProgramID)
      )
    })

    const divisionListForProgram = computed(() =>
      props.divisionList.filter((x) => x.typeProgramID == selectedTypeProgramID.value)
    )

    const teamListForDivision = computed(() => {
      return props.teamList.filter(
        (x) =>
          x.typeProgramID == selectedTypeProgramID.value &&
          x.divisionID == internalSpecialEvent.value.divisionID
      )
    })

    const isNewSpecialEvent = computed(() => internalSpecialEvent.value.eventID === 0)

    watch(
      () => divisionListForProgram.value,
      () => {
        const div = divisionListForProgram.value.find(
          (x) => x.divisionID == internalSpecialEvent.value.divisionID
        )
        if (!div) {
          internalSpecialEvent.value.divisionID = 0
          internalSpecialEvent.value.teamID = 0
        }
      }
    )

    watch(
      () => teamListForDivision.value,
      () => {
        const team = teamListForDivision.value.find((x) => x.teamID == internalSpecialEvent.value.teamID)
        if (!team) {
          internalSpecialEvent.value.teamID = 0
        }
      }
    )

    watch(
      () => props.specialEvent,
      () => {
        internalSpecialEvent.value = cloneDeep(props.specialEvent)
        const startJS = dayjs(internalSpecialEvent.value.eventStart ?? '')
        const endJS = dayjs(internalSpecialEvent.value.eventEnd ?? '')

        day.value = startJS.toDate()
        if (startJS.startOf('day').isSame(startJS) && internalSpecialEvent.value.eventEnd == null) {
          startTime.value = ''
          endTime.value = ''
          allDayNoTime.value = true
        } else {
          allDayNoTime.value = isNewSpecialEvent.value
          if (internalSpecialEvent.value.eventEnd != null) {
            startTime.value = startJS.format('HH:mm')
            endTime.value = endJS.format('HH:mm')
          } else {
            startTime.value = startJS.format('HH:mm')
            endTime.value = ''
          }
        }

        if (props.leaguePrograms.length == 1) {
          selectedTypeProgramID.value = props.leaguePrograms[0].typeProgramID!
        } else {
          selectedTypeProgramID.value = internalSpecialEvent.value.typeProgramID ?? 'NONE'
        }
      },
      { immediate: true, deep: true }
    )

    const shouldSubmitShow = computed(() => {
      return (
        internalSpecialEvent.value.eventLabel &&
        day.value &&
        (allDayNoTime.value || (startTime.value && (!endTime.value || endTime.value > startTime.value)))
      )
    })

    /**
     * Caller will need to close the box.
     */
    function confirmed() {
      //deal with type program id
      internalSpecialEvent.value.typeProgramID = selectedTypeProgramID.value

      if (props.leaguePrograms.length <= 1) {
        if (internalSpecialEvent.value.divisionID == 0) {
          //if this is a singular program league and there is no division, then unset the program since we set it above
          internalSpecialEvent.value.typeProgramID = 'NONE'
        }
      }

      //deal with event start/end
      if (allDayNoTime.value) {
        internalSpecialEvent.value.eventStart = dayjs(day.value).hour(0).minute(0).toDate()
        internalSpecialEvent.value.eventEnd = null
      } else {
        const start = timeStringSplit(startTime.value)
        internalSpecialEvent.value.eventStart = dayjs(day.value).hour(start[0]).minute(start[1]).toDate()
        if (endTime.value) {
          const end = timeStringSplit(endTime.value)
          internalSpecialEvent.value.eventEnd = dayjs(day.value).hour(end[0]).minute(end[1]).toDate()
        } else {
          internalSpecialEvent.value.eventEnd = null
        }
      }

      if (internalSpecialEvent.value.typeProgramID == 'NONE') {
        internalSpecialEvent.value.divisionID = 0
      }

      if (internalSpecialEvent.value.divisionID == 0) {
        internalSpecialEvent.value.teamID = 0
      }

      ctx.emit('confirmed', cloneDeep(internalSpecialEvent.value))
    }

    /**
     * Caller will need to close the box.
     */
    function deleted() {
      ctx.emit('deleted', cloneDeep(internalSpecialEvent.value))
    }

    function cancel() {
      closeModal()
    }

    return {
      isModalOpen,
      dayjs,
      isNewSpecialEvent,
      internalSpecialEvent,
      day,
      allDayNoTime,
      startTime,
      endTime,
      selectedTypeProgramID,
      programList,
      divisionListForProgram,
      teamListForDivision,
      shouldSubmitShow,
      deleted,
      cancel,
      confirmed,
    }
  },
})
