import { RouteConfig } from 'vue-router'
import Resources from '@/views/Resources.vue'
import Dashboard from '@/views/AdminDash/Dashboard.vue'
import RegistrationTransactionsScreen from '@/views/AdminDash/vues/RegistrationTransactionsScreen.vue'
import OrderSearchScreen from '@/views/AdminDash/vues/OrderSearchScreen.vue'
import OrderDetailScreen from '@/views/AdminDash/vues/OrderHistory/OrderDetailScreen.vue'
import AdminTools from '@/views/AdminDash/vues/AdminTools/Tools.vue'
import ReturnsOrderDetailScreen from '@/views/AdminDash/vues/ReturnsOrderDetailScreen.vue'
import MinistryImpactReport from '@/views/AdminDash/vues/OrdersAdmin/Reports/MinistryImpactReport.vue'
import ManualOrderFrame from '@/views/AdminDash/vues/OrdersAdmin/ManualOrders/ManualOrderFrame.vue'

export default [
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: Dashboard,
    children: [],
  },
  {
    path: '/admin-dashboard/resources',
    // for now (pending #2093)
    component: Resources,
  },
  {
    path: '/admin-dashboard/opr-search',
    component: RegistrationTransactionsScreen,
  },
  {
    path: '/admin-dashboard/order-search',
    component: OrderSearchScreen,
  },
  {
    path: '/admin-dashboard/:id/manual-order/:step',
    component: ManualOrderFrame,
  },
  {
    path: '/admin-dashboard/order-detail/:id/:orderid',
    component: OrderDetailScreen,
  },
  {
    path: '/admin-dashboard/returns-order-detail/:id/:orderid',
    component: ReturnsOrderDetailScreen,
  },
  {
    path: '/admin-dashboard/order-search/order-detail/:id/:orderid',
    component: OrderDetailScreen,
  },
  {
    path: '/AdminDash/Reports/MinistryImpact/:acct',
    component: MinistryImpactReport,
  },
  {
    path: '/AdminDash/AdminTools',
    component: AdminTools,
  },
] as RouteConfig[]
