



























import { Component, Vue, Prop } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import { Getter } from 'vuex-class'
import facilitiesClient from '@/clients/facilitiesClient'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import * as facilityStore from '@/store/facilities'
import DataTable from '@/elements/DataTable/DataTable.vue'
import { DataTableHeader } from 'vuetify/types'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'
import { renderGameDays, renderPracticeDays } from '@/views/Programs/Scheduling/Facilities/ext/logic'

interface Facility extends LeagueFacility {
  prettyGameDays: string[]
  prettyPracticeDays: string[]
}

@Component({
  components: {
    Modal,
    DataTable,
  },
})
export default class FacilityImport extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  private readonly isVisible!: boolean

  @Getter(facilityStore.getterNames.currentItem, {
    namespace: facilityStore.namespace,
  })
  private currentFacility!: LeagueFacility

  facilities: LeagueFacility[] | null = []
  program!: string
  lastLeagueID!: string

  private selectedFacility: LeagueFacility | null = null

  public async mounted() {
    const leagueID = this.$route.params.id

    if (leagueID && this.lastLeagueID != leagueID) {
      this.lastLeagueID = leagueID
      this.facilities = await this.retrieveFacilities(leagueID)
    }
  }

  private async retrieveFacilities(id: string): Promise<LeagueFacility[] | null> {
    const facilties = await facilitiesClient.retrieveAll(id, true)

    return (facilties as unknown) as LeagueFacility[]
  }

  get getFacilities() {
    if (this.facilities && this.facilities.length > 0) {
      return this.facilities
        .sort((a, b) => (a.facilityName > b.facilityName ? 1 : -1))
        .map((f) => {
          return {
            ...f,
            prettyGameDays: renderGameDays(f.availability ?? []),
            prettyPracticeDays: renderPracticeDays(f.availability ?? []),
          }
        })
    }

    return []
  }

  get canSelect() {
    return this.selectedFacility != null
  }

  private rowSelected(row: DataTableSelection<Facility>) {
    this.selectedFacility = row.item
  }

  private selectForImport() {
    if (!this.selectedFacility) {
      return
    }

    this.$emit('import', this.selectedFacility)
    this.close()
  }

  private close() {
    this.$emit('closed')
  }

  get headers(): DataTableHeader[] {
    return [
      { text: 'Facility', value: 'facilityName' },
      { text: 'Available For Games', value: 'prettyGameDays' },
      { text: 'Available For Practices', value: 'prettyPracticeDays' },
    ]
  }
}
