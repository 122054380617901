import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import programsClient from '@/clients/programsClient'
import { ImportProductMappingInfo } from '@/GeneratedTypes/ListInfo/ImportProductMappingInfo'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { CoachProduct } from 'src/GeneratedTypes/CoachProduct'
import { isCheer } from '@/lib/common/ProgramTypes'

export function getEmptyLeagueProgram(): LeagueProgram {
  return {
    typeProgramID: '',
    approveAdminAddedVolunteers: false,
    approveQuickAddVolunteers: false,

    capacity: 0,
    capacityMessage: '',
    earlyRegistrationFee: 0,
    gradesByGender: [],
    isEarlyFeeRegistration: false,
    noLimitsDraft: false,

    registrationCapacitiesByGradeGender: [],
    registrationFee: 0,
    freeEarlyRegistrationFeeConfirmed: false,
    freeRegistrationFeeConfirmed: false,
  }
}

/**
 * Returns if a program has a given grade and gender.
 * @param lp
 * @param gender
 * @param grade
 * @constructor
 */
export function ProgramHasGradeAndGender(lp: LeagueProgram, gender: string, grade: string) {
  const x = lp.gradesByGender?.find((x) => x.gender == gender && x.typeGradeID == grade)
  return x
}

export function getProducts(volunteerTemplate: LeagueVolunteer, lp: LeagueProgram): CoachProduct[] {
  const p = volunteerTemplate.coachPreferences?.programs?.find((p) => p.typeProgramID == lp.typeProgramID)
    ?.products
  return p || []
}

export const isCheerProgram = (program: string) => program && isCheer(program)

export interface LeagueProductMapping {
  leagueProductMappings: ImportProductMappingInfo[]
  columnNames: string[]
}

export async function leagueProductImportMapping(upwId: string): Promise<LeagueProductMapping> {
  // fetch the mapping of the import column names to ids for the products on this league
  let uniqueColumnNames: string[] = []
  const mappings = await programsClient.retrieveImportProductMapping(upwId)
  if (mappings) {
    uniqueColumnNames = [...new Set(mappings.map((p) => p.importColumnName))] as string[]
  }
  return {
    leagueProductMappings: mappings || ([] as ImportProductMappingInfo[]),
    columnNames: uniqueColumnNames,
  }
}
