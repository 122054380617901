














import { computed, defineComponent, watch } from '@vue/composition-api'

import SelectInput from '@/elements/SelectInput.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

interface SortOptionType {
  text: string
  key: string
}

export default defineComponent({
  name: 'ParticipantSort',
  components: { SelectInput },
  props: {
    program: { type: String, required: true },
  },
  setup(props) {
    const { setSort, getSort } = userPreferences()
    const list: SortOptionType[] = [
      { text: 'Last Name: A to Z', key: 'name_asc' },
      { text: 'Last Name: Z to A', key: 'name_desc' },
      { text: 'Score: Low to High', key: 'score_asc' },
      { text: 'Score: High to Low', key: 'score_desc' },
      { text: 'Experience: Low to High', key: 'exp_asc' },
      { text: 'Experience: High to Low', key: 'exp_desc' },
      { text: 'Grade: Low to High', key: 'grade_asc' },
      { text: 'Grade: High to Low', key: 'grade_desc' },
      { text: 'Gender: Girls then Boys', key: 'gender_asc' },
      { text: 'Gender: Boys then Girls', key: 'gender_desc' },
      { text: 'Height: Low to High', key: 'height_asc' },
      { text: 'Height: High to Low', key: 'height_desc' },
    ]

    const optionList = computed(() => {
      let options = [...list]
      const showHeight = props.program == 'BASKETBALL' || props.program == 'VOLLEYBALL'
      const hideScore =
        props.program == 'BASKETBALLCHEERLEADING' ||
        props.program == 'FOOTBALLCHEERLEADING' ||
        props.program == 'UPWARDSELECTMSS'
      if (!showHeight) options = options.filter((i) => !i.key.includes('height'))
      if (hideScore) options = options.filter((i) => !i.key.includes('score'))
      return options
    })

    const update = (sortBy: string) => {
      setSort(sortBy)
    }

    watch(
      () => props.program,
      () => {
        const exists = optionList.value.findIndex((a) => a.key == getSort.value) > 0
        if (!exists) update(optionList.value[0].key)
      },
      { immediate: true }
    )

    return {
      sort: computed(() => getSort.value),
      options: optionList,
      update,
    }
  },
})
