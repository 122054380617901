/***
 * The syncFusion grid has been removed. This type is no
 * longer related to the syncFusion library. The name is
 * left over from before its removal.
 */
import { SFTemplateType } from '@/models/SyncFusionTypes'

export interface GridColumn {
  // ** field to reference in record (e.g. "fname") -- apparently can be blank for a blank column */
  field?: string
  // ** header column text (e.g. "First Name") */
  headerText?: string
  // ** header template should be recognized by SF grid.
  headerTemplate?: SFTemplateType
  textAlign?: string | GridColumnAlignEnum
  width?: number | string

  format?: string
  /** -- uses a vue component as a grid template, data is defined as an empty function, and the column is injected as this.data to the component  */
  template?: SFTemplateType
  /** warning this function does not work with Grid.vue,
   * possibly refactor with template to maintain some flexibility with the more complex implementation
   * @deprecated
   **/
  customRender?: (f: string | number | null | object, r?: Record<symbol | string, unknown>) => string
}

export enum GridColumnAlignEnum {
  RIGHT = 'Right',
  LEFT = 'Left',
}
