import { defineActions, defineGetters, defineMutations } from 'direct-vuex'
import { moduleActionContext } from '@/store/index'
import {
  getEmptyXLeagueOrderDetailProduct,
  LeagueOrderState,
  LOSStates,
  newLOS,
  replaceProduct,
  setProductInPlayers,
  setProductSizeInCoaches,
  SizeAffectsEnum,
  SizeUpdatedPayload,
} from '@/lib/support/store/leagueOrder/leagueOrderState'
import ordersClient, { SizeTypeEnum, ValidateLeagueOrderShippingPayload } from '@/clients/ordersClient'
import { xLeagueOrderDetail, xLeagueOrderDetailProduct } from '@/GeneratedTypes/xOrder/xStartupOrder'
import { PlayerMissingSize } from '@/models/Order/PlayerMissingSize'
import { cloneDeep } from 'lodash'
import { isCheerProgram } from '@/lib/support/models/LeagueProgram/data'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import { getEmptyXStartupOrderExt } from '@/lib/support/models/GeneratedTypes/xOrders/xStartupOrderExt'
import { setProduct } from '@/lib/support/store/orders/operations'
import { makeOrderLine, OrderProductLine } from '@/models/Order/OrderProduct'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { getEmptyOrderProduct } from '@/lib/support/models/GeneratedTypes/xOrders/xOrderProduct'
import { PendingOrderAdditionalProductInfo } from '@/GeneratedTypes/ListInfo/PendingOrderAdditionalProductInfo'

interface LeagueOrderStateRootInterface {
  los: LeagueOrderState
}

const orderState: LeagueOrderStateRootInterface = {
  los: newLOS('', ['']),
}

export enum getterNames {
  root = 'root',
}

/*
class LeagueOrderRuntimeException extends RuntimeException {
  name = 'Order Problem'
}
*/
const getterTree = defineGetters<LeagueOrderStateRootInterface>()({ root: (state) => state.los })

export enum mutationNames {
  setOrderStatus = 'setOrderStatus',
  setPrograms = 'setPrograms',
  eraseState = 'eraseState',
  setMissingSizing = 'setMissingSizing',
  setMissingVolunteerSizing = 'setMissingVolunteerSizing',
  setParticipantProductLoading = 'setParticipantProductLoading',
  setProduct = 'setProduct',
  setLoading = 'setLoading',
  setValidated = 'setValidated',
  incrementDoNotOrderWarningCount = 'incrementDoNotOrderWarningCount',
  incrementSkipThisOrderWarningCount = 'incrementSkipThisOrderWarningCount',
  setPendingAddOnItems = 'setPendingAddOnItems',
  addPendingAddOnItems = 'addPendingAddOnItems',
}

/**
 * Sets program sizing for the volunteer or player sizing using the first-is-sport rule
 * @param sizingArr
 * @param program
 * @param sizing
 */
function setProgramSizing({
  sizingArr,
  program,
  sizing,
}: {
  sizingArr: PlayerMissingSize[][]
  program: string
  sizing: PlayerMissingSize[]
}) {
  const newsizing = cloneDeep(sizing)
  if (isCheerProgram(program)) {
    if (sizingArr.length > 1) {
      sizingArr.splice(1, 1, newsizing)
    } else {
      sizingArr.push(newsizing)
    }
  } //sport program is index 0
  else {
    sizingArr.splice(0, 1, newsizing)
  }
}

const mutations = defineMutations<LeagueOrderStateRootInterface>()({
  eraseState(state) {
    state.los = newLOS('', [''])
  },
  /**
   * Clear out the old orders detail and zero out sales tax and shipping.
   * @param state
   * @param status
   */
  setOrderStatus(state, status: xLeagueOrderDetail) {
    const filterbyprogram = (program: string) =>
      state.los.orderStatus.filter((x) => x.typeProgramID != program)
    //prune state if initialized with nothing-values
    state.los.orderStatus = filterbyprogram('')
    state.los.orderStatus = filterbyprogram(status.typeProgramID)

    state.los.orderStatus.push(cloneDeep(status))
  },
  setLoading(state, { loading }: { loading: boolean }) {
    state.los.loading = loading
  },
  setPendingAddOnItems(state, { items }: { items: PendingOrderAdditionalProductInfo[] }) {
    state.los.pendingAddOnItems = items
  },
  addPendingAddOnItems(state, { item }: { item: PendingOrderAdditionalProductInfo }) {
    state.los.pendingAddOnItems.push(item)
  },
  setPrograms(state, { programs }: { programs: string[] }) {
    state.los.programs = cloneDeep(programs)
  },
  incrementDoNotOrderWarningCount(state) {
    state.los.doNotOrderWarningCount = state.los.doNotOrderWarningCount + 1
  },
  incrementSkipThisOrderWarningCount(state) {
    state.los.skipThisOrderWarningCount = state.los.skipThisOrderWarningCount + 1
  },
  setMissingSizing(state, { program, sizing }: { program: string; sizing: PlayerMissingSize[] }) {
    setProgramSizing({ sizingArr: state.los.sizing, program, sizing })
  },
  setValidated(state, { validated }: { validated: boolean }) {
    state.los.validated = cloneDeep(validated)
  },
  setTemplate(state, { template }: { template: xStartupOrderExt }) {
    state.los.template = cloneDeep(template)
  },
  /**
   * Used to update the current program and step
   * @param state
   * @param currentProgram, sometimes optional
   * @param currentStep, what the UI should reflect
   */
  setCurrentStep(state, { currentProgram, currentStep }: { currentProgram: string; currentStep: LOSStates }) {
    state.los.currentStep = currentStep
    state.los.currentProgram = currentProgram
  },

  setParticipantProductLoading(
    state,
    { participantID, productID, loading }: { participantID: number; productID: number; loading: boolean }
  ) {
    const i = state.los.productIndividualPending.indexOf(`${participantID}-${productID}`)
    if (i >= 0) {
      if (!loading) {
        state.los.productIndividualPending.splice(i, 1)
      }
    } else if (loading) {
      state.los.productIndividualPending.push(`${participantID}-${productID}`)
    }
  },

  /**
   * takes product size and propagates it through the various places where it is held in state
   * @param state
   * @param participantID
   * @param product
   * @param program
   */
  setProductSize(
    state,
    {
      participantID,
      product,
      program,
    }: { participantID: string; product: xLeagueOrderDetailProduct; program: string }
  ) {
    //first address it in the program.
    const programIndex = state.los.programs.findIndex((x) => x == program)
    if (programIndex >= 0) {
      // replace the missing sizes  for players in the program.
      const programreplacement = replaceProduct(state.los.sizing[programIndex], participantID, product)
      if (programreplacement) {
        state.los.sizing[programIndex] = cloneDeep(programreplacement)
      }
    }
    //next in the product sizing for everyone.
    const programStatusIx = state.los.orderStatus.findIndex((x) => x.typeProgramID == program)
    if (programStatusIx >= 0) {
      const newProgram = cloneDeep(state.los.orderStatus[programStatusIx])
      // enter the program, drill down to the team.
      newProgram.divisions.forEach((div) => {
        div.teams.forEach((team) => {
          team.coaches = setProductSizeInCoaches(team.coaches, participantID, product)
          team.players = setProductInPlayers(team.players, participantID, product)
        })
      })

      state.los.orderStatus[programStatusIx] = newProgram
    }
  },
})

export enum actionNames {
  setOrderParams = 'createOrder',
  sizingUpdate = 'sizingUpdate',
  resetOrder = 'resetOrder',
  validateOrder = 'validateOrder',
  confirmOrder = 'confirmOrder',
  addOrUpdate_AddOnProduct = 'addOrUpdate_AddOnProduct',
  remove_AddOnProduct = 'remove_AddOnProduct',
}

const actions = defineActions({
  async sizingUpdate(context, a: SizeUpdatedPayload) {
    const { commit } = ordersActionContext(context)
    commit.setParticipantProductLoading({
      participantID: a.participantID,
      productID: a.product.productID,
      loading: true,
    })
    const sendproduct = {
      ProductID: a.product.productID,
      TypeColorID: a.product.typeColorID,
      TypeSizeID: a.product.typeSizeID,
      Quantity: a.product.quantity,
      DoNotOrder: a.product?.doNotOrder ?? false,
      SkipNextOrder: a.product?.skipNextOrder ?? false,
    }
    try {
      commit.setLoading({ loading: true })
      await ordersClient.setMissingSize(
        a.whoIsUpdated == SizeAffectsEnum.PLAYER ? SizeTypeEnum.PLAYER : SizeTypeEnum.VOLUNTEER,
        a.upwardLeagueID,
        a.program,
        a.participantID,
        [sendproduct]
      )
      commit.setProductSize({
        participantID: a.participantID.toString(),
        program: a.program,
        product: a.product,
      })
    } finally {
      //commit.setParticipantProduct
      commit.setParticipantProductLoading({
        participantID: a.participantID,
        productID: a.product.productID,
        loading: false,
      })
      commit.setLoading({ loading: false })
    }
  },
  /**
   * Specifically set a product that was ordered for an individual to
   * be replaced by another, when quantity is zero it is removed.
   *
   * @param context
   * @param a
   */
  async addToExchange(context, a: SizeUpdatedPayload) {
    const { commit, state } = ordersActionContext(context)
    commit.setParticipantProductLoading({
      participantID: a.participantID,
      productID: a.product.productID,
      loading: true,
    })
    try {
      commit.setLoading({ loading: true })

      await ordersClient.updateProductQuantity(
        a.upwardLeagueID,
        a.leagueID,

        state.los.template.accountNumber ?? '0',
        {
          ...makeOrderLine(getEmptyOrderProduct(), {
            exchangeCost: 0,
            orderQuantity: a.product.quantity,
            orderFreeQuantity: 0,
            costOffset: 0,
            typeColorID: a.product.typeColorID,
            typeSizeID: a.product.typeSizeID,
            colorDescription: '',
            previouslyOrdered: 0,
            sizeDescription: '',
          }),
          id: a.product.productID,
          individualID: a.participantID,
          orderQuantity: a.product.quantity,
          typeProgramID: a.program,
          jerseyNumber: a.product.jerseyNumber,
        }
      )
      commit.setProductSize({
        participantID: a.participantID.toString(),
        program: a.program,
        product: { ...a.product, replaced: !!a.product.quantity },
      })
    } finally {
      //commit.setParticipantProduct
      commit.setParticipantProductLoading({
        participantID: a.participantID,
        productID: a.product.productID,
        loading: false,
      })
      commit.setLoading({ loading: false })
    }
  },
  /**
   * Remove from exchange list.
   * @param context
   * @param a
   */
  async removeFromExchange(context, a: SizeUpdatedPayload) {
    const { dispatch } = ordersActionContext(context)

    const newPayload = cloneDeep(a)
    newPayload.product.quantity = 0

    await dispatch.addToExchange(newPayload)
  },

  /**
   * Used when the league is simply not set.
   * @param state
   * @param context
   */

  async resetOrder(
    context,
    {
      league,
      programs,
    }: {
      league: string
      programs: string[]
    }
  ) {
    const { commit } = ordersActionContext(context)
    commit.eraseState()
    commit.setPrograms({ programs })
    try {
      commit.setLoading({ loading: true })
      const template =
        (await ordersClient.retrieveTemplate(league, OrderTypesEnum.league)) ?? getEmptyXStartupOrderExt()

      const pendingProduct = await ordersClient.getPendingProduct(league, template?.accountNumber ?? '0')
      if (pendingProduct) {
        commit.setPendingAddOnItems({ items: pendingProduct })
      }

      if (programs?.length) {
        for (const program of programs) {
          const [sizing, os] = await Promise.all([
            ordersClient.getMissingSizes(league, program, SizeTypeEnum.PLAYER),
            ordersClient.getLeagueOrderStatus(league, program),
          ])

          commit.setOrderStatus(os)
          commit.setMissingSizing({ program, sizing })
        }
      }

      //set sizes sent up from pending additional products.
      // reflect current pending product quantity for add-on items.
      pendingProduct.forEach((product) => {
        if (product.individualID) {
          commit.setProductSize({
            participantID: product.individualID.toString(),
            product: {
              ...getEmptyXLeagueOrderDetailProduct(),
              productID: product.productID,
              quantity: product.quantity,
              doNotOrder: false,
              skipNextOrder: false,
              typeColorID: product.typeColorID ?? '',
              typeSizeID: product.typeSizeID ?? '',
              replaced: true,
            },
            program: product.typeProgramID ?? '',
          })
        }
      })

      commit.setTemplate({ template })
    } finally {
      commit.setLoading({ loading: false })
    }
  },

  async validateOrder(context, p: ValidateLeagueOrderShippingPayload) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })
      commit.setValidated({ validated: false })
      const rv = await ordersClient.validateOrderShipping(p)

      if (rv) {
        commit.setTemplate({
          template: cloneDeep({
            ...state.los.template,
            shippingInfo: rv.shippingInfo,
            notes: rv.notes,
            orderLines: rv.orderLines,
            purchaseOrderNumber: rv.purchaseOrderNumber,
            totalPlayerCount: rv.totalPlayerCount,
            salesTax: rv.salesTax,
            longevityDiscountAmount: rv.longevityDiscountAmount,
            totalOrderedProductAmount: rv.totalOrderedProductAmount,
            upwardOrderID: rv.upwardOrderID,
          } as xStartupOrderExt),
        })
        commit.setValidated({ validated: true })
      }
    } finally {
      commit.setLoading({ loading: false })
    }
  },

  async confirmOrder(context, p: ValidateLeagueOrderShippingPayload) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })
      commit.setValidated({ validated: false })
      const rv = await ordersClient.confirmOrder(p)
      if (rv) {
        commit.setTemplate({
          template: cloneDeep({
            ...state.los.template,
            shippingInfo: rv.shippingInfo,
            notes: rv.notes,
            purchaseOrderNumber: rv.purchaseOrderNumber,
            totalParticipantCount: rv.totalParticipantCount,
            salesTax: rv.salesTax,
            orderLines: rv.orderLines,
            longevityDiscountAmount: rv.longevityDiscountAmount,
            totalOrderedProductAmount: rv.totalOrderedProductAmount,
            upwardOrderID: rv.upwardOrderID,
            clientOrderDate: rv.clientOrderDate,
          } as xStartupOrderExt),
        }) // } )
        commit.setValidated({ validated: true })
      }
    } finally {
      commit.setLoading({ loading: false })
    }
  },

  async addProduct(
    context,
    {
      league,
      leagueID,
      account,
      product,
    }: { league: UpwardLeagueIDType; leagueID: number; account: string; product: OrderProductLine }
  ) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })

      await ordersClient.updateProductQuantity(league, leagueID, account, product)

      const newProducts = setProduct(cloneDeep(state.los.template.products), {
        productID: product.id,
        sizeID: product.productColorSize.typeSizeID,
        colorID: product.productColorSize.typeColorID,
        quantity: product.orderQuantity,
        quantityFree: 0,
      })

      commit.setTemplate({
        template: cloneDeep({
          ...state.los.template,
          products: newProducts,
        } as xStartupOrderExt),
      }) // } )
    } finally {
      commit.setLoading({ loading: false })
    }
  },
  async addOrUpdate_AddOnProduct(
    context,
    { upwId, product }: { upwId: string; product: PendingOrderAdditionalProductInfo }
  ) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })
      const exist = state.los.pendingAddOnItems.find((p) => findExistingProduct(p, product))
      let newProduct!: PendingOrderAdditionalProductInfo

      if (exist) {
        // **** update existing add-on product ****
        product.id = exist.id
        newProduct = await ordersClient.updateAddOnProduct(upwId, product)

        //remove existing add-on product
        const clean = cloneDeep(state.los.pendingAddOnItems).filter((p) => !findExistingProduct(p, product))
        commit.setPendingAddOnItems({ items: clean })
      } else {
        // **** add new  add-on product ****
        newProduct = await ordersClient.newAddOnProduct(product)
      }
      commit.addPendingAddOnItems({ item: newProduct })
    } finally {
      commit.setLoading({ loading: false })
    }
  },
  async remove_AddOnProduct(
    context,
    { upwId, product }: { upwId: string; product: PendingOrderAdditionalProductInfo }
  ) {
    const { commit, state } = ordersActionContext(context)
    try {
      commit.setLoading({ loading: true })

      //remove from store
      const clean = cloneDeep(state.los.pendingAddOnItems).filter((p) => !findExistingProduct(p, product))
      commit.setPendingAddOnItems({ items: clean })

      //DELETE
      await ordersClient.deleteProductByIndex(upwId, product.accountNumber ?? '', product.id)
    } finally {
      commit.setLoading({ loading: false })
    }
  },
})

function findExistingProduct(
  old: PendingOrderAdditionalProductInfo,
  newProd: PendingOrderAdditionalProductInfo
) {
  return (
    old.productID == newProd.productID &&
    old.typeSizeID == newProd.typeSizeID &&
    old.typeColorID == newProd.typeColorID &&
    old.jerseyNumber == newProd.jerseyNumber
  )
}

export const namespace = 'leagueOrders'

export const leagueOrders = {
  namespaced: true as true,
  state: orderState as LeagueOrderStateRootInterface,
  getters: getterTree,
  mutations,
  actions,
}

const ordersActionContext = (context: any) => moduleActionContext(context, leagueOrders)
