import { computed, onMounted, ref, watch } from '@vue/composition-api'
import ordersClient, { OrderSearchParams } from '@/clients/ordersClient'
import { OrderFindInfo } from '@/GeneratedTypes/Order/ListInfo/OrderFindInfo'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'
import store from '@/store'

export function useOrderHistory() {
  const orderHistoryRef = ref<OrderFindInfo[]>([])
  const loadingOrders = ref(false)
  const orderErrors = ref('')
  watch(
    () => orderErrors.value,
    (a) => {
      if (a) {
        setTimeout(() => (orderErrors.value = ''), 20000)
      }
    }
  )

  onMounted(
    async () => await store.dispatch.leagueTypes.fetchAll(false) //go ahead and get these cached up
  )

  const orderTypes = computed(async () => await ordersClient.getOrderStati())

  const programFor = (order: OrderInfoExt) =>
    store.getters.leagueTypes.byUpwardTypeId(order.typeLeagueID)?.description ?? 'n/a'

  async function searchOrders(p: OrderSearchParams) {
    loadingOrders.value = true
    orderErrors.value = ''
    orderHistoryRef.value = []

    try {
      orderHistoryRef.value = (await ordersClient.searchOrderHistory(p)) ?? []
      if (!orderHistoryRef.value?.length) {
        orderErrors.value = 'No results returned'
      }
    } catch (e) {
      orderErrors.value = e?.message || e?.errorObject?.message || 'Search failed with an unexpected error'

      throw e
    } finally {
      loadingOrders.value = false
    }
  }

  return { searchOrders, orderHistoryRef, orderTypes, loadingOrders, orderErrors, programFor }
}

export function useHoldDialog() {
  const isHoldVisible = ref(false)
  const holdingOrder = ref<OrderFindInfo | null>(null)
  function popupReleaseDialog(o: OrderFindInfo) {
    isHoldVisible.value = true
    holdingOrder.value = o
  }

  async function release(o: OrderFindInfo, reason: string) {
    try {
      await ordersClient.releaseFromHold(o.upwardLeagueID || '', o.upwardOrderID ?? '', reason)
    } catch (e) {
      throw e
    } finally {
      closeHold()
    }
  }

  function closeHold() {
    isHoldVisible.value = false
  }

  return { closeHold, release, isHoldVisible, popupReleaseDialog, holdingOrder }
}
