/**
 * information gleaned from the practice modal
 */
export interface PracticeModalInfoType {
  //** practice id, 0 is not based on a practice
  id: number
  teamID: number
  startDate: Date
  endDate: Date
  facilityID: number
  //*** null is no last date, single practice.
  lastDate: Date | null
  updateSeason: boolean
}

export function getEmptyPracticeModalInfoType(): PracticeModalInfoType {
  return {
    id: 0,
    teamID: 0,
    startDate: new Date(),
    endDate: new Date(),
    facilityID: 0,
    lastDate: new Date(),
    updateSeason: false,
  }
}
