




































import { defineComponent, computed, ref, watch, onMounted, onUnmounted, PropType } from '@vue/composition-api'
import { LeagueListItem } from '@/models/Program/LeagueListItem'

export default defineComponent({
  name: 'TypeProgramTab',
  props: {
    programs: { type: Object as PropType<LeagueListItem>, required: true },
    value: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const internalValue = ref(props.value)

    function select(programId: string) {
      internalValue.value = programId
      ctx.emit('input', internalValue.value)
    }

    const cheerLabel = computed(() => {
      if (props.programs.cheerProgramTypeDescription) {
        return 'CHEER'
      } else {
        return ''
      }
    })

    /**
     * quick property gives us a list of valid program ids for input.
     * first value is non-cheer program id.
     */
    const validProgramTypes = computed((): string[] => {
      const rv: string[] = []
      if (props.programs && props.programs.programTypeID) {
        rv.push(props.programs.programTypeID)
      }
      if (props.programs && props.programs.cheerProgramTypeID) {
        rv.push(props.programs.cheerProgramTypeID)
      }
      return rv
    })

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    /***
     * Adds some resiliency to the return value, either '', or in a valid value.
     * autoselect first element type on program prop assignment.
     * Should leave it alone if there is already a valid value
     * @param newLLI
     */
    watch(
      () => props.programs,
      (newLLI: LeagueListItem) => {
        programTypesChange(newLLI)
      }
    )

    function programTypesChange(newLLI: LeagueListItem) {
      props.programs = newLLI

      if (
        validProgramTypes.value.length &&
        validProgramTypes.value.findIndex((x) => x == internalValue.value) == -1
      ) {
        select(validProgramTypes.value[0])
      }
    }

    onMounted(() => {
      // on assignment and on mount we want the value to be an active tab.
      // i.e. there shouldn't be an intermediate state where one tab or the other is not selected.
      programTypesChange(props.programs)
    })

    onUnmounted(() => {
      ctx.emit('destroyed')
    })

    return {
      internalValue,
      cheerLabel,
      select,
    }
  },
})
