import { OrderProductSize } from 'src/models/Order/OrderProductSize'
import { OrderProductSelection } from '@/store/orders'
export interface OrderProduct extends CMSProduct {
  // Key fields
  upwardProductID: string
  typeProgramID: string

  cost: number
  individualID: number
  requiresJerseyNumber: boolean
  hasSNumbers: boolean
  // Order properties
  orderMinimumQuantity: number
  orderMaximumQuantity: number
  orderFreeQuantity: number
  orderSelectionRequired: boolean
  //used to select product uniquely
  id: number
  // used to choose qty and size.
  productColorSizes: OrderProductSize[] | null

  // Other info (if not used by the UI, remove.)
  typeProductGroupID: string
  registrationLabel: string | null
  extendedDescription: string | null
  isActive: boolean
  sortOrder: number
  orderQuantity: number
  jerseyNumber: string
  duplicateProductMatchingKey: string | null
}

export interface OrderProductLine extends Omit<OrderProduct, 'productColorSizes'> {
  productColorSize: OrderProductSize
  individualID: number
}

export function makeOrderLine(product: OrderProduct, variation: OrderProductSize): OrderProductLine {
  return { ...product, individualID: 0, productColorSize: variation }
}

/**
 * Returns if a size has been selected by size string.
 * @param s size tag
 * @return s if it is not a null-desc
 */
export function isSizeUnselected(s: string) {
  return s == 'NONE'
}

/**
 * Filters the null-descriptions of a variation or size to nothing
 * @param s
 * @return s if it is not a null-desc
 */
export function filterNA(s: string) {
  if (s == 'One Size' || s == 'Not Applicable' || s == 'NA' || s == 'N/A') {
    return ''
  }
  return s
}

/**
 * products coming out of cms
 */
export interface CMSProduct {
  cmsProductDescription: string | null
  cmsProductImageUrls: string[] | null
  cmsUnitDescription: string | null
  cmsSizingGuideUrl: string | null
  cmsProductName: string | null
}

/**
 * Returns a list of products that are selected and have qty > 1
 * Will normalize the products with size/color > 2 to duplicate the product with only that size/color
 * but for now no products in here match that criteria.
 * @param products
 * @param isSelected
 * @return {OrderProduct[]} with only productColorSizes set to null,[], or one for each qty>1
 */
export function getFilteredProductList(
  products: OrderProduct[] | Readonly<OrderProduct[]>,
  isSelected: (n: OrderProduct) => boolean
): OrderProductLine[] {
  const rv: OrderProductLine[] = []

  products.forEach((x) => {
    if (isSelected(x) && x.productColorSizes && x.productColorSizes.length) {
      x.productColorSizes?.forEach((y) => {
        if (y.orderQuantity > 0) {
          rv.push(makeOrderLine(x, y))
        }
      })
    }
  })

  return rv
}

/**
 * Takes a list of products and generates an is-selected function from the scope that accepts one products
 * @param productsSelected - list of products selected
 */
export function generateIsSelected(
  productsSelected: OrderProductSelection[] | Readonly<OrderProductSelection[]>
) {
  return (product: OrderProduct) => {
    if (product && product.upwardProductID && product.id && productsSelected && productsSelected.length) {
      return (
        productsSelected.findIndex(
          (x) => x.upwardProductID == product.upwardProductID && x.id == product.id
        ) >= 0 ?? false
      )
    }
    return false
  }
}
