
































import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'

import SelectorCard from '@/components/SelectorCard.vue'
import SelectInput from '@/elements/SelectInput.vue'
import EnlargeableProduct from './EnlargeableProduct.vue'
import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { getEmptyProductOfferingProductInfo } from '@/lib/support/models/ProductOfferingProductInfo/ProductOfferingProductInfo'
import { TypeProductOption } from '@/lib/support/models/UpwardTypes/TypeProductOption'

import { getProductOptionID, getProductPrice } from '@/lib/common/ProductOfferingConfig'
import { OptionalProductSelection } from './model/OptionProductSelection'
import CurrencyInput from '@/elements/CurrencyInput.vue'
import { TypeProductOfferingProductPackage } from '@/lib/support/models/UpwardTypes/TypeProductOfferingProductPackageID'
import store from '@/store'

export default defineComponent({
  name: 'OptionalProductCard',
  components: {
    SelectorCard,
    SelectInput,
    EnlargeableProduct,
    CurrencyInput,
  },
  props: {
    product: {
      type: Object as PropType<ProductOfferingProductInfo>,
      default: getEmptyProductOfferingProductInfo,
      required: false,
    },
    value: { type: Object as PropType<OptionalProductSelection | null>, default: null, required: false },
    category: { type: Number, default: 0, required: false },
    selectable: { type: Boolean, required: true },
    package: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const productOfferingConfig = computed(() => store.getters.productOfferings.productOfferingConfig)
    const internal = ref<OptionalProductSelection | null>(null)

    onMounted(async () => {
      await store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up
      const productOptionID = getProductOptionID(
        productOfferingConfig.value.details,
        props.product.productID,
        props.category
      )

      const productPrice = getProductPrice(
        productOfferingConfig.value.details,
        props.product.productID,
        props.category
      )

      internal.value = {
        selection: productOptionID ? productOptionID : props.value,
        price: productPrice ?? props.product.productCost ?? 0,
      } as OptionalProductSelection

      setPackage()
    })

    const options = [
      { id: TypeProductOption.INCLUDED, label: 'Include For All Participants' },
      { id: TypeProductOption.NOTAVAILABLE, label: 'Do Not Offer' },
      { id: TypeProductOption.OPTIONAL, label: 'Optional To Parents' },
    ]

    const isCardSelected = computed(
      () => internal.value?.selection === options[0].id || internal.value?.selection === options[2].id
    )

    watch(
      () => props.package,
      () => {
        setPackage()
      }
    )

    function setPackage() {
      if (!props.package || !internal.value) return

      internal.value.selection = options[1].id //reset

      if (props.package == TypeProductOfferingProductPackage.CUSTOM) return

      if (
        props.product.productPackages?.some((x) => x.typeProductOfferingProductPackageID == props.package)
      ) {
        const prodPackage = props.product.productPackages?.find(
          (x) => x.typeProductOfferingProductPackageID == props.package
        )
        if (prodPackage && prodPackage.typeProductOptionID != TypeProductOption.NOTSET) {
          internal.value.selection = prodPackage.typeProductOptionID ?? options[0].id
        } else {
          internal.value.selection = options[0].id
        }
      }
    }

    watch(
      () => internal.value,
      () => {
        ctx.emit('input', internal.value)
      }
    )

    return {
      isCardSelected,
      internal,
      options,
    }
  },
})
