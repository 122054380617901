import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'

import { leagueVolunteerRoleToLeagueVolunteerRoleInfo } from '@/lib/support/models/LeagueVolunteerRoleInfo/leagueVolunteerRoleToLeagueVounteerRoleInfo'
import { leagueVolunteerRoleInfoToLeagueVolunteerRole } from '@/lib/support/models/LeagueVolunteerRole/leagueVolunteerRoleInfoToLeagueVolunteerRole'
import { individualAddressInfoToIndividualAddress } from '@/lib/support/models/IndividualAddress/individualAddressInfoMapper'
import { individualEmailInfoToIndividualEmail } from '@/lib/support/models/IndividualEmail/individualEmailInfoToIndividualEmail'
import { individualPhoneInfoToIndividualPhone } from '@/lib/support/models/IndividualPhone/individualPhoneInfoToIndividualPhone'
import { LeagueCoach } from '@/GeneratedTypes/LeagueCoach'
import { LeagueCoachInfo } from '@/GeneratedTypes/ListInfo/LeagueCoachInfo'
import { CoachProgramInfo } from '@/GeneratedTypes/ListInfo/CoachProgramInfo'
import { CoachProgram } from '@/GeneratedTypes/CoachProgram'
import { individualEmailToIndividualEmailInfo } from '@/lib/support/models/ListInfo/IndividualEmailInfo/individaulEmailToIndividualEmailInfo'
import { individualPhoneToIndividualPhoneInfo } from '@/lib/support/models/ListInfo/IndividualPhoneInfo/individualPhoneToIndividualPhoneInfo'

function CoachProgramInfoToCoachProgram(x: CoachProgramInfo): CoachProgram {
  return {
    assistantCoachLink: null,
    gendersAndGrades: [],
    assistantCoachPreferred: false,
    products: [],
    typeProgramID: x.typeProgramID ?? '',
  }
}

function LeagueCoachInfoToLeagueCoach(x: LeagueCoachInfo): LeagueCoach {
  return {
    practiceNights: [],
    programs: x.programs?.map((x) => CoachProgramInfoToCoachProgram(x)) || [],
  }
}

export function leagueVolunteerInfoToLeagueVolunteer(incoming: LeagueVolunteerInfo): LeagueVolunteer {
  return {
    coachPreferences: incoming?.coachPreferences
      ? LeagueCoachInfoToLeagueCoach(incoming?.coachPreferences)
      : null,
    thirdPartyClientIDs: [],
    leagueID: incoming.leagueID,
    accountNumber: incoming.accountNumber ?? '0',
    individualID: incoming.individualID,
    firstName: incoming.firstName ?? '',
    lastName: incoming.lastName ?? '',
    roles: incoming?.roles?.map((x) => leagueVolunteerRoleInfoToLeagueVolunteerRole(x)) || [],
    addresses: incoming?.addresses?.map((x) => individualAddressInfoToIndividualAddress(x)) || [],
    emailAddresses: incoming?.emailAddresses?.map((x) => individualEmailInfoToIndividualEmail(x)) || [],
    phoneNumbers: incoming?.phoneNumbers?.map((x) => individualPhoneInfoToIndividualPhone(x)) || [],
    birthDate: incoming.birthDate,
    churchName: incoming.churchName,
    gender: incoming.gender,
    middleInitial: incoming.middleInitial,
    staff: incoming.staff,
    typeSizeID: incoming.typeSizeID,
    optOutEmails: incoming.optOutEmails,
    optOutSMS: incoming.optOutSMS,
    inviteAllowed: incoming.inviteAllowed,
    registrationNote: incoming.registrationNote ?? '',
  }
}

export function leagueVolunteerToLeagueVolunteerInfo(incoming: LeagueVolunteer): LeagueVolunteerInfo {
  const x = {
    ...incoming,
    accountNumber: incoming.accountNumber,
    roles: incoming?.roles?.map((x) => leagueVolunteerRoleToLeagueVolunteerRoleInfo(x)) ?? [],
    addresses: null,
    registrationTransactionID: 0,
    active: true,
    formattedNameFirstLast: incoming.firstName,
    formattedNameLastFirst: incoming.lastName,
    formattedNamePrivate: `${incoming.firstName} ${incoming.lastName}`,
    phoneNumbers: incoming.phoneNumbers?.map((x) => individualPhoneToIndividualPhoneInfo(x)) || [],
    emailAddresses: incoming.emailAddresses?.map((x) => individualEmailToIndividualEmailInfo(x)) || [],
    thirdPartyClientIDs: [],
    coachRoleApproved: false,
    refereeRoleApproved: false,
    inviteAcceptDate: null,
    inviteExpireDate: null,
    optOutEmails: incoming.optOutEmails,
    optOutSMS: incoming.optOutSMS,
    inviteAllowed: incoming.inviteAllowed,
    /*     inviteAllowed: incoming..emailAddresses
      ? incoming.emailAddresses.length > 0
        ? isNullOrEmpty(incoming.emailAddresses[0].valid)
          ? false
          : incoming.emailAddresses[0].valid.toLowerCase() === 'true'
        : false
      : false, */
  } as LeagueVolunteerInfo
  return x
}

export function isNullOrEmpty(value: any) {
  if (!value) return true

  if (value.length == 0) return true

  return false
}

// Merges the information from LeagueVolunteer with LeagueVolunteerInfo giving
// preference to the values in League Volunteer
export function leagueVolunteerToLeagueVolunteerInfoMerge(
  lv: LeagueVolunteer,
  lvi: LeagueVolunteerInfo
): LeagueVolunteerInfo {
  const x = {
    ...lv,
    accountNumber: lv.accountNumber,
    roles: lv?.roles?.map((x) => leagueVolunteerRoleToLeagueVolunteerRoleInfo(x)) ?? [],
    addresses: lvi.addresses,
    registrationTransactionID: lvi.registrationTransactionID,
    active: lvi.active,
    formattedNameFirstLast: lv.firstName,
    formattedNameLastFirst: lv.lastName,
    formattedNamePrivate: `${lv.firstName} ${lv.lastName}`,
    phoneNumbers: lv.phoneNumbers?.map((x) => individualPhoneToIndividualPhoneInfo(x)) || [],
    emailAddresses: lv.emailAddresses?.map((x) => individualEmailToIndividualEmailInfo(x)) || [],
    thirdPartyClientIDs: lvi.thirdPartyClientIDs,
    coachRoleApproved: lvi.coachRoleApproved,
    refereeRoleApproved: lvi.refereeRoleApproved,
    inviteAcceptDate: lvi.inviteAcceptDate,
    inviteExpireDate: lvi.inviteExpireDate,
    optOutEmails: lvi.optOutEmails,
    optOutSMS: lvi.optOutSMS,
  } as LeagueVolunteerInfo
  return x
}
