





import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'EditBnt',
  props: {},
  components: {},
  setup(props, { emit }) {
    function click() {
      emit('click')
    }
    return { click }
  },
})
