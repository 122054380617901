import { render, staticRenderFns } from "./RefundRemoveTransaction.vue?vue&type=template&id=292e68bd&scoped=true&"
import script from "./RefundRemoveTransaction.vue?vue&type=script&lang=ts&"
export * from "./RefundRemoveTransaction.vue?vue&type=script&lang=ts&"
import style0 from "./RefundRemoveTransaction.vue?vue&type=style&index=0&id=292e68bd&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "292e68bd",
  null
  
)

export default component.exports