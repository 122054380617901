


















import { defineComponent, ref, watch } from '@vue/composition-api'
import uuid from 'uuid'
import { TheMask } from 'vue-the-mask'

export default defineComponent({
  name: 'MaskedInputElement',
  components: {
    TheMask,
  },
  props: {
    id: { type: String, default: () => 'masked-' + uuid.v4(), required: false },
    name: { type: String, default: '', required: false },
    enabled: { type: Boolean, default: true, required: false },
    inputType: { type: String, default: 'text', required: false },
    placeholder: { type: String, default: '', required: false },
    mask: { type: String, default: '', required: false },
    value: { type: String, required: true },
  },
  setup(props, ctx) {
    const internalValue = ref(props.value)

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    watch(
      () => internalValue.value,
      () => {
        ctx.emit('input', internalValue.value)
        ctx.emit('change', internalValue.value)
      }
    )

    return {
      internalValue,
    }
  },
})
