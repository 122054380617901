





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'InputLabel',
  props: {
    label: { type: String, default: '', required: false },
    inputId: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    showHelp: { type: Boolean, default: false, required: false },
    subLabel: { type: String, required: false },
    subLabelInline: { type: Boolean, default: false, required: false },
    labelClass: { type: String, default: 'text-uppercase', required: false },
    enabled: { type: Boolean, default: true, required: false },
  },
})
