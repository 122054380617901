import { defineGetters, defineMutations } from 'direct-vuex'
interface CreationParametersState {
  opportunityType: string | null
  leagueType: string | null
}

const creationParametersState: CreationParametersState = {
  opportunityType: null,
  leagueType: null,
}

export enum getterNames {
  opportunityType = 'opportunityType',
  leagueType = 'leagueType',
}

const getterTree = defineGetters<CreationParametersState>()({
  opportunityType: (state) => {
    return state.opportunityType
  },
  leagueType: (state) => {
    return state.leagueType
  },
})

export enum mutationNames {
  setOpportunityType = 'setOpportunityType',
  setleagueType = 'setLeagueType',
}

const mutations = defineMutations<CreationParametersState>()({
  setOpportunityType(state, { opportunityType }: { opportunityType: string | null }) {
    state.opportunityType = opportunityType
  },
  setLeagueType(state, { leagueType }: { leagueType: string | null }) {
    state.leagueType = leagueType
  },
})

export const namespace = 'creationParameters'

export const creationParameters = {
  namespaced: true as true,
  state: creationParametersState,
  getters: getterTree,
  mutations,
}
