import leaguesClient from '@/clients/leaguesClient'
import { League } from '@/GeneratedTypes/League'
import { LeagueTypes } from '@/lib/common/LeagueTypes'
import sessionObjectsClient from '@/clients/sessionObjectsClient'
import { initializeNewProgramGradeData, populateProgramGrades } from '@/services/gradeService'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { EstimateInitializer } from '@/lib/support/models/EstimateInitializer/EstimateInitializer'
import { defineMutations, defineActions, defineGetters } from 'direct-vuex'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { uniqueUserIdCheck } from '@/store/helper'
import { StartingSelection } from '@/models/League/StartingSelection'
import { isCheer } from '@/lib/common/ProgramTypes'
import store from '@/store'

interface LeaguesState {
  currentItem: League
  startingSelection: StartingSelection | null
}

const leaguesState: LeaguesState = {
  currentItem: getEmptyLeague(),
  startingSelection: null,
}

export enum getterNames {
  currentItem = 'currentItem',
  startingSelection = 'startingSelection',
  isUpwardSelect = 'isUpwardSelect',
}

const getterTree = defineGetters<LeaguesState>()({
  currentItem: (state) => state.currentItem,
  startingSelection: (state) => state.startingSelection,
  isUpwardSelect: (state) => state.currentItem.typeLeagueID == LeagueTypes.UPWARDSELECT,
})

export enum mutationNames {
  setCurrent = 'setCurrent',
  setStartingSelection = 'setStartingSelection',
}
const mutations = defineMutations<LeaguesState>()({
  setCurrent(state, { item }: { item: League }) {
    state.currentItem = item
    store.commit.leagueAbstraction.setCurrentLeague({ item })
  },
  setStartingSelection(state, { selection }: { selection: StartingSelection }) {
    state.startingSelection = selection
  },
})

export enum actionNames {
  create = 'create',
  update = 'update',
  retrieveAndSetAsCurrent = 'retrieveAndSetAsCurrent',
  validate = 'validate',
  cache = 'cache',
  loadFromCache = 'loadFromCache',
  beginCreating = 'beginCreating',
  beginCreatingFromEstimate = 'beginCreatingFromEstimate',
}

const actions = defineActions({
  async retrieveAndSetAsCurrent({ commit }, { id }: { id: string }): Promise<League | null> {
    const result = await leaguesClient.retrieve(id)

    if (result) {
      if (result.programs?.some((p) => isCheer(p.typeProgramID) ?? false) && result?.productOfferingConfig) {
        if (!result.productOfferingConfig?.cheerCoachEstimate) {
          result.productOfferingConfig.cheerCoachEstimate = 1
        }

        if (!result.productOfferingConfig?.cheerEstimate) {
          result.productOfferingConfig.cheerEstimate = 1
        }
      }

      if (result?.productOfferingConfig) {
        if (!result.productOfferingConfig.sportCoachEstimate) {
          result.productOfferingConfig.sportCoachEstimate = 1
        }

        if (!result.productOfferingConfig.playerEstimate) {
          result.productOfferingConfig.playerEstimate = 1
        }
      }
      commit(mutationNames.setCurrent, { item: result })
    }

    return null
  },
  async create({ commit, rootGetters }, { item }: { item: League }): Promise<League | null> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)
    const result = await leaguesClient.create(item)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })
      await sessionObjectsClient.delete(`league.new.${uniqueUserId}`)
      return result.data
    }

    return null
  },
  async update({ commit }, { item }: { item: League }): Promise<League | null> {
    const result = await leaguesClient.update(item.upwardLeagueID!, item)

    if (result.isSuccess && result.data) {
      commit(mutationNames.setCurrent, { item: result.data })
      return result.data
    }

    return null
  },
  async validate({ commit }, { item, ruleSet = '' }: { item: League; ruleSet: string }): Promise<boolean> {
    const isNew = !item.upwardLeagueID

    const validationResult = await (isNew
      ? leaguesClient.validateNew(item, ruleSet)
      : leaguesClient.validateExisting(item.upwardLeagueID!, item))

    if (!validationResult.isSuccess) {
      return false
    }

    commit(mutationNames.setCurrent, { item: validationResult.data!.model })
    return true
  },
  async cache({ commit, rootGetters }, { item }: { item: League }): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)

    const isNew = !item.upwardLeagueID
    const cacheKey = `league.${isNew ? `new.${uniqueUserId}` : item.upwardLeagueID}`
    const cacheResult = await sessionObjectsClient.createOrUpdate(cacheKey, item)

    if (!cacheResult.isSuccess) {
      return false
    }

    commit(mutationNames.setCurrent, { item })
    return true
  },
  async loadFromCache({ commit, rootGetters }): Promise<boolean> {
    const uniqueUserId = uniqueUserIdCheck(rootGetters)

    const result = await sessionObjectsClient.retrieve<League>(`league.new.${uniqueUserId}`)

    if (!result.isSuccess) {
      return false
    }

    const item = result.data!

    commit(mutationNames.setCurrent, { item })

    return true
  },
  async beginCreatingFromEstimate(
    { dispatch },
    { initializer }: { initializer: EstimateInitializer }
  ): Promise<boolean> {
    const result = await leaguesClient.newTemplate(initializer.typeLeagueID)

    if (!result.isSuccess) {
      return false
    }

    let item = result.data!
    item = commonInit(item)
    initializer.productOfferingConfig.accountNumber = null
    item.firstPracticeDate = initializer.productOfferingConfig.firstPracticeDateEstimate
    item.leagueName = initializer.productOfferingConfig.name ?? ''
    item.productOfferingConfig = initializer.productOfferingConfig
    item.publicDisplayName = store.getters.authorization.accountName
    if (item.accounts) item.accounts[0].grades = initializer.productOfferingConfig.gradesByGender
    item = populateProgramGrades(item, initializer.productOfferingConfig.gradesByGender)

    await dispatch(actionNames.cache, { item })

    return true
  },
  async beginCreating(
    { commit, dispatch },
    {
      name,
      typeLeagueID,
      publicDisplayName,
      partnerContact,
      addToCache,
    }: {
      name: string
      typeLeagueID: string
      publicDisplayName: string
      partnerContact: ContactInfo | null
      addToCache: boolean
    }
  ): Promise<boolean> {
    const result = await leaguesClient.newTemplate(typeLeagueID)

    if (!result.isSuccess) {
      return false
    }

    let item = result.data!
    item = commonInit(item)
    item.leagueName = name
    item.publicDisplayName = publicDisplayName
    if (item.programs != null && item.programs.length > 0) {
      item.programs.forEach((p: LeagueProgram) => {
        initializeNewProgramGradeData(item, p.typeProgramID)
      })
    }

    if (partnerContact) {
      item.partnerContact = {
        firstName: partnerContact.firstName!,
        lastName: partnerContact.lastName!,
        emailAddress: partnerContact.emailAddress!,
        emailValid: partnerContact.emailValid,
        emailValidReason: partnerContact.emailValidReason,
        primaryPhoneNumber: partnerContact.primaryPhone!,
        primaryPhoneTypeID: partnerContact.primaryPhoneTypeID!,
        secondaryPhoneNumber: partnerContact.secondaryPhone,
        secondaryPhoneTypeID: partnerContact.secondaryPhoneTypeID,
        alternatePhoneNumber: partnerContact.alternatePhone,
        alternatePhoneTypeID: partnerContact.alternatePhoneTypeID,
        yearsExperience: partnerContact.yearsExperience,
        isFullTime: partnerContact.isFullTime,
        typePartnerRoleID: partnerContact.typePartnerRoleID!,
        typePreferredDayOfWeekID: partnerContact.preferredContactDayOfWeek!,
        typePreferredTimeOfDayID: partnerContact.preferredContactTimeOfDay!,
        typePreferredContactMethodID: partnerContact.preferredContactMethod!,
      }

      item.leagueDirectorPartnerID = partnerContact.id
    }

    commit(mutationNames.setCurrent, { item })

    if (addToCache) {
      await dispatch(actionNames.cache, { item })
    }

    return true
  },
})

function commonInit(item: League): League {
  item.isOPRLeague = true
  item.canEdit = true

  if (!item.typeSystemsAccessID) {
    item.typeSystemsAccessID = 'FULL'
  }

  if (!item.registrationInfo) {
    item.registrationInfo = {
      allowCreditCard: true,
      allowPayLater: true,
      // Always set the league.registrationInfo.allowSizingEntry to true per 2635
      allowSizingEntry: true,
      allowGeneralNotes: false,
      allowDonations: false,
      allowWaitlist: false,
      onlineRegistrationBeginDate: null,
      onlineRegistrationEndDate: null,
      donationExplanation: '',
      registrationStartMessage: '',
      confirmationMessage: '',
      twoChildDiscountAmount: 0,
      threeChildDiscountAmount: 0,
      fourOrMoreChildDiscountAmount: 0,
      allowDiscountCodes: false,
      emailLDOnDiscountCodeUse: false,
      discountCodes: [],
      bypassLinks: [],
      contactLine1: '',
      contactLine2: '',
      contactLine3: '',
      contactLine4: '',
      contactLine5: '',
    }
  }
  return item
}

export const namespace = 'leagues'

export const leagues = {
  namespaced: true as true,
  state: leaguesState,
  getters: getterTree,
  mutations,
  actions,
}
