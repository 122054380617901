
















import { defineComponent, PropType } from '@vue/composition-api'
import { CalendarData } from '@/elements/Calendar/types'

export default defineComponent({
  name: 'ScheduleDragItem',

  props: {
    item: { type: Object as PropType<CalendarData>, reuired: true },
    showCoaches: { type: Boolean, reuired: false, default: true },
  },
})
