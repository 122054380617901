













import { defineComponent, computed } from '@vue/composition-api'
export default defineComponent({
  name: 'Card',
  props: {
    cardBodyClasses: { type: String, default: 'card-body' },
  },
  setup(props, ctx) {
    const showHeader = computed(() => ctx.slots.header != null)

    return {
      showHeader,
    }
  },
})
