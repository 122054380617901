import restService from '@/services/restService'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import { FacilityEventInfo } from '@/GeneratedTypes/ListInfo/FacilityEventInfo'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'

const baseUrl = 'facilities/'

class FacilitiesClientRuntimeException extends RuntimeException {
  name = 'Facilities Client'
}

const retrieveAll = async (leagueId: string, historical = false) => {
  // todo : for dev purposes hard coding league id
  // leagueId = 'UPW80002'
  const result = await restService.get<LeagueFacility[]>(`${baseUrl}${leagueId}?historical=${historical}`)

  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('a problem occurred retrieving league facilities')
  }
}

const retrieve = async (leagueId: string, id: string) =>
  await restService.get<LeagueFacility>(`${baseUrl}${leagueId}/${id}`)

const retrieveGroups = async (leagueId: string) =>
  await restService.get<string[]>(`${baseUrl}${leagueId}/groups`)

const retrieveNew = async () => await restService.get<LeagueFacility>(`${baseUrl}new`)

const validateNew = async (leagueId: string, facility: LeagueFacility, ruleSet = '') => {
  return await restService.post<UpwardVerificationDetails>(`${baseUrl}${leagueId}/validate`, facility, {
    params: {
      ruleSet,
    },
  })
}

const retrieveEvents = async (leagueId: string) => {
  const x = await restService.get<FacilityEventInfo[]>(`facilityEvents/${leagueId}`)
  if (x.isSuccess) {
    return x.data
  }
  throw new FacilitiesClientRuntimeException('trying to get facility events ', x.errorObject)
}

const retrieveEventsForDivision = async (leagueId: string, programId: string, divisionId: number) => {
  const x = await restService.get<FacilityEventInfo[]>(
    `facilityEvents/${leagueId}/${programId}/${divisionId}`
  )
  if (x.isSuccess) {
    return x.data
  }
  throw new FacilitiesClientRuntimeException('trying to get facility events for division', x.errorObject)
}

const createNew = async (leagueId: string, facility: LeagueFacility) => {
  return await restService.post<LeagueFacility>(`${baseUrl}${leagueId}`, facility, {})
}

const update = async (leagueId: string, facility: LeagueFacility) => {
  return await restService.put<LeagueFacility>(`${baseUrl}${leagueId}/${facility.facilityID}`, facility)
}

const deleteFacility = async (leagueId: string, facilityId: number) => {
  return await restService.delete<LeagueFacility>(`${baseUrl}${leagueId}/${facilityId}`)
}

export default {
  retrieveAll,
  retrieve,
  retrieveGroups,
  retrieveNew,
  validateNew,
  createNew,
  update,
  deleteFacility,
  retrieveEvents,
  retrieveEventsForDivision,
}
