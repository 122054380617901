

















import { defineComponent, ref, computed, watch, PropType, nextTick } from '@vue/composition-api'
import ContactPicker from '@/components/ContactPicker.vue'
import ContactForm from '@/components/ContactForm.vue'
import Modal from '@/components/Modal.vue'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { Contact } from '@/GeneratedTypes/Partner/Contact'
import { ContactInfoToContact, ContactToContactInfo } from '@/services/contactService'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  name: 'ContactPickerAndEditor',
  components: {
    ContactPicker,
    ContactForm,
    Modal,
    Loading,
  },
  props: {
    value: { type: Object as PropType<ContactInfo>, required: true },
    accountNumber: { required: true, type: String },
    isLoading: { required: false, type: Boolean, default: false },
    canChangeName: { required: false, type: Boolean, default: true },
    isClean: { required: true, type: Boolean },
  },
  setup(props, ctx) {
    const internalValue = ref<ContactInfo | null>(null)
    const editingValue = ref<Contact | null>(null)
    const isModalVisible = ref(false)
    const didSubmit = ref(false)

    function onSubmit() {
      ctx.emit(
        'input',
        ContactToContactInfo(editingValue.value, internalValue.value ? internalValue.value.id : 0)
      )
      ctx.emit('submit')

      nextTick(() => {
        didSubmit.value = true
      })
    }

    function onChange(contact: ContactInfo | null) {
      internalValue.value = contact
      editingValue.value = contact ? ContactInfoToContact(contact) : null
      isModalVisible.value = true
      didSubmit.value = false
    }

    function hideModal() {
      isModalVisible.value = false
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      },
      { immediate: true }
    )

    watch(
      () => [didSubmit.value, props.isClean],
      () => {
        if (!didSubmit.value || !props.isClean || !isModalVisible.value) {
          return
        }

        isModalVisible.value = false
        didSubmit.value = false
      }
    )

    const editingValueAsContactInfo = computed(() =>
      ContactToContactInfo(editingValue.value, internalValue.value ? internalValue.value.id : 0)
    )

    const modalTitle = computed(() => {
      if (!editingValueAsContactInfo.value.fullName || !editingValueAsContactInfo.value.id) {
        return 'New Contact'
      }

      return editingValueAsContactInfo.value.fullName
    })

    const isNewMode = computed(() => !editingValueAsContactInfo.value.id)

    return {
      isModalVisible,
      modalTitle,
      editingValue,
      isNewMode,
      onChange,
      onSubmit,
      hideModal,
    }
  },
})
