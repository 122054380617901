



















import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import productOfferingClient from '@/clients/productOfferingClient'
import { SavedProductOfferingConfig } from '@/GeneratedTypes/SavedProductOfferingConfig'
import Loading from '@/elements/Loading.vue'
import store from '@/store'

export default defineComponent({
  name: 'PreviousEstimates',
  components: {
    Loading,
  },
  setup(props, ctx) {
    const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)

    const configList = ref<SavedProductOfferingConfig[] | null>([])
    const isLoading = ref(false)

    onMounted(async () => {
      isLoading.value = true
      try {
        const list = await productOfferingClient.retrieveProductOfferingConfigList(
          firstAccountNumber.value,
          ''
        )

        //Only show last 5 configs. (Items sorted by API)
        configList.value = list ? list.slice(0, 5) : ([] as SavedProductOfferingConfig[])
      } finally {
        isLoading.value = false
        configCountChanged()
      }
    })

    function configCountChanged() {
      ctx.emit('configCountChanged', configList.value?.length)
    }

    return {
      isLoading,
      configList,
    }
  },
})
