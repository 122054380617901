















import { defineComponent } from '@vue/composition-api'
import store from '@/store'
export default defineComponent({
  name: 'CurrencyWarning',
  setup() {
    function click() {
      store.commit.partners.setHasAcceptedCurrencyWarning({ hasAccecpted: true })
    }
    return { click }
  },
})
