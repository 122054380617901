





















import { defineComponent, ref, onMounted, watch, computed } from '@vue/composition-api'
import dayjs from 'dayjs'

import Notification from '@/components/Notifications/Notification.vue'
import NotificationCard from '@/components/Notifications/NotificationCard.vue'
import Loading from '@/elements/Loading.vue'

import store from '@/store'

export default defineComponent({
  name: 'notifications',
  props: {
    value: { type: Boolean, required: true },
  },
  components: { Notification, Loading, NotificationCard },
  setup(props, { emit }) {
    const open = ref(false)
    const loading = ref(false)
    const retrieveNotifications = store.dispatch.alerts.fetchNotifications

    onMounted(async () => {
      loading.value = true
      await retrieveNotifications()
      loading.value = false
    })

    const notifications = computed(() => {
      return store.getters.alerts.notifications
        .concat()
        .sort((a, b) => (dayjs(a.startDate ?? '').isBefore(dayjs(b.startDate ?? '')) ? 1 : -1))
    })
    const count = computed(() => notifications.value.length ?? 0)

    watch(
      () => props.value,
      () => (open.value = props.value),
      { immediate: true }
    )

    watch(
      () => open.value,
      () => {
        emit('input', open.value)
      },
      { immediate: true }
    )

    return { notifications, open, loading, count }
  },
})
