



















































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'

import SelectInput from '@/elements/SelectInput.vue'

import CheckboxInput from '@/elements/CheckboxInput.vue'

import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'

import { getGradesListFromAccount } from '@/services/gradeService'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import store from '@/store'

export default defineComponent({
  name: 'ParticipantEvalActivity',
  components: {
    SelectInput,
    CheckboxInput,
    ActiveReport,
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const selectedProgram = ref('')
    const filterByGrade = ref(false)
    const selectedStartGrade = ref('0')
    const selectedEndGrade = ref('0')

    const loading = ref(false)
    const loadStatus = ref('')

    const isByAge = computed(() => {
      if (isCamp.value) {
        return camp.value.ageCutoffDate !== null
      } else {
        return league.value.ageCutoffDate !== null
      }
    })

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    function setGrades() {
      if (!filterByGrade.value) {
        selectedStartGrade.value = '0'
        selectedEndGrade.value = '0'
      }
    }

    //const gradesList = computed(() => getGrades(false))
    const gradesList = computed(() => {
      let retval: UpwardGradeTypeID[] = []
      const accts = isCamp.value ? camp.value.accounts : league.value.accounts
      const acct = accts?.find((x) => x.accountNumber == accountNumber.value)
      if (acct) {
        retval = getGradesListFromAccount(acct, selectedProgram.value, 'E')
      }
      return retval
    })

    onMounted(() => {
      selectedProgram.value = typeProgramID.value
    })

    function reportLoaded() {
      loading.value = false
    }

    async function loadReport() {
      loading.value = true
      let typeProgramId = selectedProgram.value
      let startGrade = selectedStartGrade.value
      let endGrade = selectedEndGrade.value

      loadStatus.value = 'Downloading Form Data . . .'
      const data = await Promise.all([
        reportsClient.getEvaluationActivityData(
          upwardLeagueId.value,
          accountNumber.value,
          typeProgramId,
          startGrade,
          endGrade
        ),
      ])

      loadStatus.value = 'Downloading Form Templates . . .'
      const reportTemplates = await Promise.all([reportsClient.getReportTemplate('evaluationactivities')])

      loadStatus.value = 'Processing Form Templates . . .'
      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')

      const mainData = data[0]

      report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)

      loadStatus.value = 'Loading Form . . .'
      const activeReport: any = ctx.refs.activereport
      activeReport.loadReportData(report)
    }

    return {
      loading,
      filterByGrade,
      gradesList,
      selectedStartGrade,
      selectedEndGrade,
      loadStatus,
      isByAge,
      loadReport,
      reportLoaded,
      setGrades,
    }
  },
})
