















































import { defineComponent, onMounted } from '@vue/composition-api'
import { useConnectSetup } from '@/composables/ConnectSetup'
import DateInput from '@/elements/DateInput.vue'
import Loading from '@/elements/Loading.vue'
import MemoInput from '@/elements/MemoInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import TextInput from '@/elements/TextInput.vue'
import NumberInput from '@/elements/NumberInput.vue'

import Alert from '@/components/Alert.vue'
import FullBody from '@/components/FullBody.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'

export default defineComponent({
  name: 'Information',
  components: {
    Alert,
    DateInput,
    FullBody,
    Loading,
    MemoInput,
    SelectInput,
    TextInput,
    SaveContinueButton,
    NumberInput,
  },
  setup(props, ctx) {
    const { connect, loading, nextRoute, saveAndContinue, beforeRouteLeave } = useConnectSetup(ctx)

    onMounted(() => {
      if (connect.value.upwardLeagueID) {
        nextRoute.value = `/programs/connect/${connect.value.upwardLeagueID}/setup/leadership`
      } else {
        nextRoute.value = '/programs/connect/setup/leadership'
      }
    })

    return {
      saveAndContinue,
      connect,
      loading,
      beforeRouteLeave,
    }
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeave(to, from, next)
  },
})
