




























































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'
import { Resource } from '@/models/Program/Resource'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import ResourceTile from './ResourceTile.vue'
import resourceService, { ResourceFileType, ResourceFileTypeCategory } from '@/services/resourceService'

export default defineComponent({
  name: 'AdCommissionerResources',
  components: {
    VerticalTabs,
    Loading,
    TextInput,
    SelectInput,
    ResourceTile,
  },
  props: {
    marketingResources: { type: Array as PropType<Resource[]>, required: true },
    role: { type: Object as PropType<TypeLeagueVolunteerRole>, required: false, default: null },
    leagueListItem: { type: Object as PropType<LeagueListItem>, required: false, default: null },
  },
  setup(props) {
    const loading = ref(false)
    const fileTypeCategory = ref<ResourceFileTypeCategory | null>(null)
    const searchKeyword = ref('')
    const committedFileTypeCategory = ref<null | ResourceFileTypeCategory>(null)
    const committedSearchKeyword = ref('')

    /** A human friendly sentence about the current committed search filters */
    const searchDesciption = computed(() => {
      if (committedFileTypeCategory.value && committedSearchKeyword.value) {
        return `Here are ${props.role?.description} ${committedFileTypeCategory.value} resources containing the keyword "${committedSearchKeyword.value}":`
      }

      if (committedSearchKeyword.value) {
        return `Here are ${props.role?.description} resources containing the keyword "${committedSearchKeyword.value}":`
      }

      if (committedFileTypeCategory.value) {
        return `Here are ${props.role?.description} ${committedFileTypeCategory.value} resources:`
      }

      return ''
    })

    /** The options for the file type filter */
    const fileTypeCategories = computed(() => {
      const categories = [] as { value: ResourceFileTypeCategory; text: string }[]

      if (resourcesWithType.value.findIndex((r) => r.category === ResourceFileTypeCategory.pdf) >= 0) {
        categories.push({
          value: ResourceFileTypeCategory.pdf,
          text: 'PDF',
        })
      }

      if (resourcesWithType.value.findIndex((r) => r.category === ResourceFileTypeCategory.archive) >= 0) {
        categories.push({
          value: ResourceFileTypeCategory.archive,
          text: 'Archive',
        })
      }

      if (resourcesWithType.value.findIndex((r) => r.category === ResourceFileTypeCategory.image) >= 0) {
        categories.push({
          value: ResourceFileTypeCategory.image,
          text: 'Image',
        })
      }

      if (resourcesWithType.value.findIndex((r) => r.category === ResourceFileTypeCategory.slides) >= 0) {
        categories.push({
          value: ResourceFileTypeCategory.slides,
          text: 'Slides',
        })
      }

      if (resourcesWithType.value.findIndex((r) => r.category === ResourceFileTypeCategory.video) >= 0) {
        categories.push({
          value: ResourceFileTypeCategory.video,
          text: 'Video',
        })
      }

      return categories
    })

    /** All of the resources with the calculated file type and file type category */
    const resourcesWithType = computed((): {
      resource: Resource
      type: ResourceFileType
      category: ResourceFileTypeCategory
    }[] => {
      return props.marketingResources.map((r) => {
        const fileType = resourceService.getResourceFileType(r.resourceUrl)

        return {
          resource: r,
          type: fileType,
          category: resourceService.getResourceFileTypeCategory(fileType),
        }
      })
    })

    /** Has the user entered any search criteria? */
    const hasSearchCriteria = computed(
      () => committedSearchKeyword.value.length > 0 || committedFileTypeCategory.value !== null
    )

    /** This should return the resources that are display on the page.
     * Resources are displayed if the filters are such that the resource matches
     */
    const displayedResources = computed(() => {
      if (!hasSearchCriteria.value) {
        return []
      }

      let resources = resourcesWithType.value

      if (committedSearchKeyword.value) {
        resources = resources.filter(
          (r) =>
            r.resource.title.toLowerCase().includes(committedSearchKeyword.value) ||
            (r.resource.shortDescriptionContent || '').toLowerCase().includes(committedSearchKeyword.value)
        )
      }

      if (committedFileTypeCategory.value) {
        resources = resources.filter((r) => r.category === committedFileTypeCategory.value)
      }

      // Display unique resources
      return resources
        .map((r) => r.resource)
        .filter((e, i, arr) => arr.findIndex((a) => a.resourceID === e.resourceID) === i)
    })

    /** Event handler for the filter submit button.
     * This should update the displayed resources based on the filter values.
     */
    function doSearch() {
      committedSearchKeyword.value = searchKeyword.value.trim().toLowerCase()
      committedFileTypeCategory.value = fileTypeCategory.value
    }

    watch(
      () => props.role,
      () => {
        // Reset the filters
        fileTypeCategory.value = null
        searchKeyword.value = ''
        committedSearchKeyword.value = ''
        committedFileTypeCategory.value = null
      }
    )

    return {
      loading,
      fileTypeCategory,
      fileTypeCategories,
      searchKeyword,
      hasSearchCriteria,
      searchDesciption,
      displayedResources,
      doSearch,
    }
  },
})
