






















































































































































































































































import CampSetupMixin from '@/views/Camps/CampSetupMixin'
import SetupCompleteConfirmation from '@/components/SetupCompleteConfirmation.vue'
import * as authorizationStore from '@/store/authorization'

import Loading from '@/elements/Loading.vue'
import InputLabel from '@/elements/InputLabel.vue'
import TextInput from '@/elements/TextInput.vue'
import DateTimeInput from '@/elements/DateTimeInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import CurrencyInput from '@/elements/CurrencyInput.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import EFTAuthorizationRequestModal from '@/components/EFTAuthorizationRequestModal.vue'
import { EFTRequestStatus } from '@/models/Partner/EFTRequestStatus'
import partnersClient from '@/clients/partnersClient'
import * as campsStore from '@/store/camps'
import { Action, Getter } from 'vuex-class'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Camp } from '@/GeneratedTypes/Camp'
import ManageDiscountCodes from '@/components/ManageDiscountCodes.vue'
import ManageCapacityRules from '@/components/ManageCapacityRules.vue'
import ManageBypassLinks from '@/components/ManageBypassLinks.vue'
import IntegerInput from '@/elements/IntegerInput.vue'
import { getGradeUsage, GradeUsage } from '@/services/gradeService'
import { getDateWithTime } from '@/lib/support/utilities/time/time.ts'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import store from '@/store'
import Modal from '@/components/Modal.vue'

@Component({
  components: {
    Loading,
    TextInput,
    DateTimeInput,
    CheckboxInput,
    MemoInput,
    YesNoInput,
    InputLabel,
    CurrencyInput,
    SetupCompleteConfirmation,
    ManageDiscountCodes,
    ManageCapacityRules,
    ManageBypassLinks,
    IntegerInput,
    SaveContinueButton,
    HtmlEditor,
    EFTAuthorizationRequestModal,
    Modal,
  },
})
export default class Registration extends Mixins(CampSetupMixin) {
  @Action(campsStore.actionNames.create, { namespace: campsStore.namespace })
  private readonly create!: ({ item }: { item: Camp }) => Promise<Camp | null>

  @Getter(authorizationStore.getterNames.firstAccountNumber, { namespace: authorizationStore.namespace })
  private accountNumber!: string

  // TODO Where do these hook into the league object
  private hasMultiChildDiscount = false
  private modalIsVisible = false
  private maxNumberOfRegistrations = 0
  private showManageCapactiy = false
  private capacityMessage = ''
  private newId = ''
  private eftBankAcctLast4 = ''
  private onlineRegistrationBeginDate: Date | null = null
  private onlineRegistrationEndDate: Date | null = null
  private registrationLoaded = false
  private eftModalIsVisible = false
  private eftStatus: EFTRequestStatus | null = null
  private showCreditCardHelp = false

  get isGlobalPartner(): boolean {
    return (this.camp.accounts && this.camp.accounts[0].accountNumber.startsWith('G')) ?? false
  }

  get partner() {
    return store.getters.partners.partnerInfo
  }

  get ccEnabled() {
    //for some reason this doesn't work
    //return this.eftBankAcctLast4 && this.eftBankAcctLast4 != ''
    if (this.eftBankAcctLast4 && this.eftBankAcctLast4 != '') {
      return true
    } else {
      return false
    }
  }

  async mounted() {
    this.afterSaveNew = this.afterSaveNewMethod
    this.beforeSave = this.beforeSaveProcessing

    this.eftStatus = await partnersClient.getEFTRequestStatus(this.accountNumber)

    if (this.camp.registrationInfo) {
      this.camp.registrationInfo.allowSizingEntry = true
    }

    this.hasMultiChildDiscount =
      this.camp.registrationInfo != null &&
      (this.camp.registrationInfo.twoChildDiscountAmount > 0 ||
        this.camp.registrationInfo.threeChildDiscountAmount > 0 ||
        this.camp.registrationInfo.fourOrMoreChildDiscountAmount > 0)

    this.showManageCapactiy =
      ((this.camp.programs &&
        this.camp.programs[0].registrationCapacitiesByGradeGender &&
        this.camp.programs[0].registrationCapacitiesByGradeGender.length > 0) ||
        (this.camp.programs && this.camp.programs[0].capacity > 0)) ??
      false

    if (this.camp.registrationInfo?.onlineRegistrationBeginDate) {
      this.onlineRegistrationBeginDate = this.camp.registrationInfo?.onlineRegistrationBeginDate
    }

    if (this.camp.registrationInfo?.onlineRegistrationEndDate) {
      this.onlineRegistrationEndDate = this.camp.registrationInfo?.onlineRegistrationEndDate
    } else if (this.camp.registrationEndDate) {
      this.onlineRegistrationEndDate = getDateWithTime(this.camp.registrationEndDate, 23, 59, 59)
    }

    if (
      this.camp.registrationInfo &&
      !this.camp.registrationInfo.onlineRegistrationBeginDate &&
      this.camp.registrationBeginDate
    ) {
      this.camp.registrationInfo.onlineRegistrationBeginDate = getDateWithTime(
        this.camp.registrationBeginDate,
        0,
        0,
        0
      )
    }

    this.capacityMessage = this.camp.programs ? this.camp.programs[0].capacityMessage ?? '' : ''

    if (
      this.camp.registrationInfo &&
      !this.camp.registrationInfo.contactLine1 &&
      this.camp.partnerContact?.firstName
    ) {
      this.camp.registrationInfo.contactLine1 = `${this.camp.partnerContact.firstName} ${this.camp.partnerContact.lastName}`
    }

    if (
      this.camp.registrationInfo &&
      !this.camp.registrationInfo.contactLine4 &&
      this.camp.partnerContact?.primaryPhoneNumber
    ) {
      this.camp.registrationInfo.contactLine4 = this.camp.partnerContact.primaryPhoneNumber
    }

    if (
      this.camp.registrationInfo &&
      !this.camp.registrationInfo.contactLine5 &&
      this.camp.partnerContact?.emailAddress
    ) {
      this.camp.registrationInfo.contactLine5 = this.camp.partnerContact.emailAddress
    }

    if (this.camp && this.camp.accounts && this.camp.accounts.length > 0) {
      if (this.accountNumber) {
        this.eftBankAcctLast4 = this.partner?.eftBankAcctLast4 ?? ''

        if (this.camp.registrationInfo && !this.camp?.registrationInfo.contactLine2) {
          this.camp.registrationInfo.contactLine2 = `${this.partner?.address1_Line1} ${this.partner?.address1_Line2}`
        }

        if (this.camp.registrationInfo && !this.camp?.registrationInfo.contactLine3) {
          this.camp.registrationInfo.contactLine3 = `${this.partner?.address1_City}, ${this.partner?.address1_StateOrProvince} ${this.partner?.address1_PostalCode}`
        }
      }
    }

    if (this.camp && this.camp.registrationInfo && (this.isGlobalPartner || !this.ccEnabled)) {
      this.camp.registrationInfo.allowCreditCard = false
    }

    if (this.camp.upwardLeagueID) {
      this.nextRoute = '/'
    }

    this.registrationLoaded = true
  }

  // eslint-disable-next-line require-await
  private async beforeSaveProcessing() {
    if (this.camp.registrationInfo && this.onlineRegistrationBeginDate) {
      this.camp.registrationInfo.onlineRegistrationBeginDate = this.onlineRegistrationBeginDate
    }
    if (this.camp.registrationInfo && this.onlineRegistrationEndDate) {
      this.camp.registrationInfo.onlineRegistrationEndDate = this.onlineRegistrationEndDate
    }
    if (!this.showManageCapactiy && this.camp && this.camp.programs) {
      this.capacityMessage = ''
      for (let i = 0; i < this.camp.programs.length; i++) {
        this.camp.programs[i].capacity = 0
        this.camp.programs[i].capacityMessage = ''
        this.camp.programs[i].registrationCapacitiesByGradeGender = []
      }
    }
  }

  private async afterSaveNewMethod() {
    const savedCamp = await this.create({ item: this.camp })
    if (savedCamp) {
      this.newId = savedCamp.upwardLeagueID ?? ''
    }
    this.modalIsVisible = true
  }

  private onModalStateChange() {
    this.$router.push(`/programs/camp/${this.storeCamp.upwardLeagueID}/setup/information`)
  }

  get showAccessCode(): boolean {
    return this.eftStatus?.status != 'declined' && this.eftStatus?.status != 'voided' && this.accessCode != ''
  }

  get accessCode(): string {
    return this.eftStatus?.accessCode ?? ''
  }

  get envStatus(): string {
    return this.eftStatus?.status ?? ''
  }

  private async eftAuthSent() {
    this.eftModalIsVisible = false
    this.eftStatus = await partnersClient.getEFTRequestStatus(this.accountNumber)
  }

  private getCampGradesList(): GradeUsage[] {
    if (this.camp.programs && this.camp.programs.length > 0) {
      const typeProgramId = this.camp.programs[0].typeProgramID

      return getGradeUsage(this.camp, typeProgramId)
    }
    return []
  }

  @Watch('camp.programs', { immediate: true, deep: true })
  private addCapacityMessage() {
    const message = this.camp.programs ? this.camp.programs[0].capacityMessage ?? this.capacityMessage : ''
    this.updateCapacityMessage(message)
  }

  @Watch('capacityMessage')
  private addCapacityMessageWhenCapacityMessageUpdated() {
    this.updateCapacityMessage()
  }

  @Watch('showManageCapactiy')
  private setDirty() {
    if (this.registrationLoaded) {
      this.setIsDirty({ isDirty: true })
    }
  }

  private updateCapacityMessage(m?: string) {
    const message = m ?? this.capacityMessage
    if (this.camp.programs && this.camp.programs[0].registrationCapacitiesByGradeGender) {
      this.camp.programs[0].capacityMessage = message
      for (const rcbgg of this.camp.programs[0].registrationCapacitiesByGradeGender) {
        rcbgg.capacityMessage = message
      }
    }
  }
}
