












































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'
import ButtonGroup from '@/elements/ButtonGroup.vue'
import SelectInput from '@/elements/SelectInput.vue'
import uuid from 'uuid'

export default defineComponent({
  name: 'SwitchSlot',
  components: {
    InputLabel,
    ButtonGroup,
    SelectInput,
  },
  props: {
    label: { type: String, default: '', required: false },
    mode: { type: String, default: 'radio', required: false },
    showBorder: { type: Boolean, default: true, required: false },
    value: { required: false, default: '' },
    options: { type: Array as PropType<Array<{ text: string; value: string | string[] }>>, required: true },
    enabled: { type: Boolean, default: true, required: false },
  },
  setup(props, ctx) {
    const selectedValue = ref(props.value || (props.options.length ? props.options[0].value : null))
    const guid = uuid.v4()
    const name = `switchslot-${guid}`

    const slotNames = computed(() => {
      if (!selectedValue.value) {
        return []
      }

      if (!Array.isArray(selectedValue.value)) {
        return [selectedValue.value]
      }

      return selectedValue.value
    })

    watch(
      () => selectedValue.value,
      () => {
        ctx.emit('input', selectedValue.value)
        ctx.emit('change', selectedValue.value)
      }
    )

    watch(
      () => props.value,
      () => {
        selectedValue.value = props.value || (props.options.length ? props.options[0].value : null)
      }
    )

    return {
      selectedValue,
      name,
      slotNames,
    }
  },
})
