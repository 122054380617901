




























































import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { xShipToBase } from 'src/GeneratedTypes/xOrder/xShipToBase'
import { getEmptyxShipToBase } from '@/lib/support/models/GeneratedTypes/xOrders/xShipToBase.ts'

export default defineComponent({
  name: 'AddAddressModal',
  components: {
    Modal,
    TextInput,
    VeeValidateForm,
  },
  props: {
    value: { type: Object as PropType<xShipToBase>, default: getEmptyxShipToBase, required: false },
  },
  setup(props, ctx) {
    const internalValue = ref<xShipToBase>({ ...props.value })

    const modalIsVisible = ref(false)
    const modalTitle = ref('Starter Kit - Shipping Address')

    function save() {
      ctx.emit('save', internalValue.value)
      toggleModal()
    }

    function toggleModal() {
      modalIsVisible.value = !modalIsVisible.value
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = { ...props.value }
      },
      { deep: true }
    )

    return {
      modalIsVisible,
      modalTitle,
      internalValue,
      save,
      toggleModal,
    }
  },
})
