import { LeagueListItem } from '@/models/Program/LeagueListItem'

export function getEmptyLeagueListItem(seed: LeagueListItemSeed): LeagueListItem {
  return {
    opportunityTypeID: -1,
    opportunityTypeDescription: '',
    programTypeDescription: '',
    cheerProgramTypeDescription: 'CHEER',
    programTypeID: '',
    cheerProgramTypeID: '',
    leagueTypeAbbreviation: '',
    maleGradeMin: 'K3',
    maleGradeMax: '12',
    femaleGradeMin: 'K3',
    femaleGradeMax: '12',
    eitherGradeMax: '12',
    eitherGradeMin: 'K3',
    femaleCheerGradeMin: 'K3',
    femaleCheerGradeMax: '12',
    maleCheerGradeMin: 'K3',
    maleCheerGradeMax: '12',
    eitherCheerGradeMin: 'K3',
    eitherCheerGradeMax: '12',
    practiceDays: [],
    isOPRLeague: false,
    leagueName: '',
    canDelete: true,
    leagueID: seed.leagueID,
    upwardLeagueID: seed.upwardLeagueID,
    typeLeagueID: -1,
    participantCount: 0,
    participantCheerCount: 0,
    firstEvalDate: null,
    evalDescription: null,
    firstPracticeDate: null,
    firstGameDate: null,
    onlineRegistrationBeginDate: null,
    onlineRegistrationEndDate: null,
    registrationBeginDate: null,
    registrationEndDate: null,
    isHost: false,
    partnerCount: 0,
    seasonEndDate: null,
    grades: null,
    current: true,
    myLeagueInfo: null,
    // leagueWarnings: LeagueWarning,[] | null;
  }
}

export class LeagueListItemSeed {
  leagueID = -1
  upwardLeagueID = ''
}

export function LeagueInfoMoreThanOneProgram(lli: LeagueListItem): boolean {
  return !!lli.cheerProgramTypeID
}

export function isByAge(lli: LeagueListItem): boolean {
  return lli.myLeagueInfo ? lli.myLeagueInfo.ageCutoffDate != null : false
}
