import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import {
  xLeagueOrderDetail,
  xLeagueOrderDetailCoach,
  xLeagueOrderDetailParticipant,
  xLeagueOrderDetailProduct,
} from '@/GeneratedTypes/xOrder/xStartupOrder'
import { PlayerMissingSize } from '@/models/Order/PlayerMissingSize'
import { cloneDeep } from 'lodash'
import { getEmptyXStartupOrderExt } from '@/lib/support/models/GeneratedTypes/xOrders/xStartupOrderExt'
import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import { PendingOrderAdditionalProductInfo } from '@/GeneratedTypes/ListInfo/PendingOrderAdditionalProductInfo'

export enum LOSStates {
  PRE_STEP,
  PARTICIPANT_MISSING_SIZES,
  PARTICIPANT_EXCHANGE,
  COACH_EXCHANGE,
  GET_PARTICIPANT_DETAIL_PREFERENCE,
  REVIEW_PARTICIPANT_DETAIL,
  REVIEW_SHIPPING,
  REVIEW_ORDER,
  CONFIRM_ORDER,
  REVIEW_ADDON_ITEMS,
  NEW_PARTICIPANTS,
  NEW_COACHES,
}

export interface LeagueOrderState {
  template: xStartupOrderExt
  //*** players that are missing size by program (0= sport 1 = cheer)
  sizing: PlayerMissingSize[][]

  programs: string[] //program for sport and if set, cheer
  currentProgram: string
  currentStep: LOSStates
  orderStatus: xLeagueOrderDetail[]
  productIndividualPending: string[]
  loading: boolean
  validated: boolean
  doNotOrderWarningCount: number
  skipThisOrderWarningCount: number
  pendingAddOnItems: PendingOrderAdditionalProductInfo[]
}

export function getEmptyXLeagueOrderDetailProduct(): xLeagueOrderDetailProduct {
  return {
    categoryID: 0,
    cmsProductDescription: '',
    cmsProductImageUrls: [],
    cmsProductName: '',
    doNotOrder: false,
    skipNextOrder: false,
    lockedInCost: 0,
    productID: 0,
    quantity: 0,
    typeColorID: '',
    typeSizeID: '',
    replaced: false,
    jerseyNumber: '',
    parentProductID: 0,
  }
}
export const ORDER_COUNT_NOT_INITIALIZED = 99e1
export function getEmptyLeagueOrderDetail(league: UpwardLeagueIDType, program: string) {
  return {
    divisions: [],
    orderCount: ORDER_COUNT_NOT_INITIALIZED, // possible that this was throwing us into the wrong state.
    coachCount: 0,
    playerCount: 0,
    coachProductsAvailable: false,
    programCost: 0,
    typeProgramID: program,
    upwardLeagueID: league,
    upwardOrderType: 'LEAGUEORDER',
  } as xLeagueOrderDetail
}

export function newLOS(league: UpwardLeagueIDType, programs: string[]) {
  let newprograms = cloneDeep(programs)
  if (!programs?.length) {
    newprograms = [''] //initial order state.
  }
  const rv = {
    sizing: [[] as PlayerMissingSize[]],
    volunteerSizing: [[] as PlayerMissingSize[]],
    template: getEmptyXStartupOrderExt(),
    programs: newprograms,
    currentProgram: newprograms[0],
    currentStep: LOSStates.PRE_STEP,
    orderStatus: [getEmptyLeagueOrderDetail(league, newprograms[0])],
    productIndividualPending: [],
    loading: false,
    validated: false,
    doNotOrderWarningCount: 0,
    skipThisOrderWarningCount: 0,
    pendingAddOnItems: [] as PendingOrderAdditionalProductInfo[],
  } as LeagueOrderState

  if (newprograms.length > 1) {
    rv.sizing.push([])
    rv.orderStatus.push(getEmptyLeagueOrderDetail(league, newprograms[1]))
  }
  return rv
}

export enum SizeAffectsEnum {
  COACH,
  PLAYER,
}

export interface SizeUpdatedPayload {
  whoIsUpdated: SizeAffectsEnum
  upwardLeagueID: UpwardLeagueIDType
  leagueID: number
  program: string
  participantID: number
  product: xLeagueOrderDetailProduct
}

/**
 * Go thru the PlayerMissingSize array and replace a product to update sizing
 * @param sizing
 * @param participantID
 * @param product
 * @return new sizing structure or null for no replacement done.
 */
export function replaceProduct(
  sizing: PlayerMissingSize[],
  participantID: string,
  product: xLeagueOrderDetailProduct
) {
  const recordIndex = sizing.findIndex((y) => y.individualID == participantID)
  if (recordIndex >= 0) {
    const productIndex = sizing[recordIndex].products.findIndex((x) => x.productID == product.productID)
    if (productIndex >= 0) {
      const copyOfSizing = cloneDeep(sizing)
      const copyOfRecord = cloneDeep(sizing[recordIndex])
      copyOfRecord.products.splice(
        productIndex,
        1,
        cloneDeep({
          ...copyOfRecord.products[productIndex],
          typeSizeID: product.typeSizeID,
          doNotOrder: product.doNotOrder,
          skipNextOrder: product.skipNextOrder,
          replaced: product.replaced ?? false,
        })
      )
      copyOfSizing.splice(recordIndex, 1, copyOfRecord)
      return copyOfSizing
    }
  }
  return null
}

/**
 * Replace the product size in the coach teams sizing structure.
 * @param teamCoaches
 * @param participantID
 * @param product
 */
export function setProductSizeInCoaches(
  teamCoaches: xLeagueOrderDetailCoach[],
  participantID: string,
  product: xLeagueOrderDetailProduct
) {
  const coaches = cloneDeep(teamCoaches)
  const cI = coaches.findIndex((c) => c.individualID == parseInt(participantID))
  if (cI >= 0) {
    const pI = coaches[cI].products.findIndex((p) => p.productID == product.productID)
    if (pI >= 0) {
      coaches[cI].products.splice(pI, 1, {
        ...coaches[cI].products[pI],
        typeSizeID: product.typeSizeID,
        doNotOrder: product.doNotOrder,
        skipNextOrder: product.skipNextOrder,
        replaced: product.replaced ?? false,
      })
    }
  }
  return coaches
}

/**
 * Replace the product in players in the teams sizing structure.
 * @param teamPlayers
 * @param participantID
 * @param product
 */
export function setProductInPlayers(
  teamPlayers: xLeagueOrderDetailParticipant[],
  participantID: string,
  product: xLeagueOrderDetailProduct
) {
  const players = cloneDeep(teamPlayers)
  const cI = players.findIndex((c) => c.individualID == parseInt(participantID))
  if (cI >= 0) {
    const pI = players[cI].products.findIndex((p) => p.productID == product.productID)
    if (pI >= 0) {
      players[cI].products.splice(pI, 1, {
        ...players[cI].products[pI],
        typeSizeID: product.typeSizeID,
        doNotOrder: product.doNotOrder,
        skipNextOrder: product.skipNextOrder,
        replaced: product.replaced ?? false,
      })
    }
  }
  return players
}
