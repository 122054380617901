import { IndividualAddressInfo } from '@/GeneratedTypes/ListInfo/IndividualAddressInfo'
import { stringToAddressTypeID } from '@/lib/support/models/IndividualAddress/data'

export function getEmptyAddress(seed?: AddressSeedInfo): IndividualAddressInfo {
  return {
    individualID: 0,
    addressID: 0,
    typeAddressID: seed?.typeAddressID || stringToAddressTypeID('home'),
    street1: '',
    street2: '',
    subdivision1: '', //city
    subdivision2: '', //state
    postalCode: '',
    typeCountryID: 'US',
    priority: 0,
  }
}

export class AddressSeedInfo {
  public typeAddressID: string = stringToAddressTypeID('home')
}
