













import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import currency from '@/filters/currency'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { xReturnOrderExt, xReturnOrderLineExt } from '@/models/Order/xReturnOrderExt'
import { downloadCSV } from '@/lib/common/csv/downloadCSV'

export default defineComponent({
  components: {
    SimpleGrid,
  },
  props: {
    order: {
      type: Object as PropType<xReturnOrderExt>,

      required: true,
    },
  },
  setup(props) {
    const columns = computed(() => {
      return [
        { field: 'productLabel', headerText: 'Item Name', width: 300 },
        { field: 'gpItems', headerText: 'GP Item Code(s)' },
        { field: 'program', headerText: 'Program' },
        { field: 'color', headerText: 'Color' },
        { field: 'size', headerText: 'Size' },
        { field: 'orderQuantity', headerText: 'Quantity' },
        { field: 'reason', headerText: 'Reason For Return' },
        { field: 'cargo', headerText: 'Player(s) Name(s)' },
      ] as GridColumn[]
    })

    interface OrderListItem {
      productLabel: string
      gpItems: string
      program: string | null
      color: string | null
      size: string | null
      orderQuantity: number
      reason: string
      cargo: string | null
    }
    const orderList = computed(() =>
      props.order?.orderLinesExt
        ?.filter((x) => x.quantity)
        .map(
          (x) =>
            ({
              productLabel: x.cmsProductName,
              gpItems: getGPItemCodes(x),
              program: x.programDescription,
              color: x.colorDescription,
              size: x.sizeDescription,
              orderQuantity: x.quantity,
              reason: x.reasonDescription,
              cargo: x.notes,
            } as OrderListItem)
        )
    )

    function getGPItemCodes(extLine: xReturnOrderLineExt) {
      if (extLine.gpItemCodes.length > 0) {
        return extLine.gpItemCodes.join()
      } else {
        return 'Unknown'
      }
    }

    function getCost(cost: number) {
      return currency(cost)
    }

    function download() {
      downloadCSV(columns.value, orderList.value, 'return_order_items.csv')
    }

    return { getCost, orderList, columns, download }
  },
})
