


















import { defineComponent, computed } from '@vue/composition-api'
import Accordion from '@/components/Accordion.vue'
import AccordionItem from '@/components/AccordionItem.vue'

import DataTable from '@/views/TestComponents/DataTableTest/DataTableTest.vue'
import TreeTest from '@/views/TestComponents/Tree/TreeTest.vue'
import CarouselTest from '@/views/TestComponents/CarouselTest/CarouselTest.vue'
import ComboboxTest from '@/views/TestComponents/ComboboxTest.vue'
import CalendarTest from '@/views/TestComponents/CalendarTest/CalendarTest.vue'
import ModalDateTimePicker from '@/views/TestComponents/ModalDateTimePickerTest.vue'
import DropDownMultiSelectTest from '@/views/TestComponents/DropDownMultiSelectTest.vue'
import ModalConfirmationTest from '@/views/TestComponents/ModalConfirmationTest.vue'

const testComponents: { [key: string]: object } = {
  DataTable,
  CalendarTest,
  TreeTest,
  ComboboxTest,
  CarouselTest,
  ModalDateTimePicker,
  DropDownMultiSelectTest,
  ModalConfirmationTest,
}

export default defineComponent({
  name: 'ComponentTest',
  components: {
    ...testComponents,
    Accordion,
    AccordionItem,
  },
  setup(props, ctx) {
    const componentsToRender = computed(() => {
      if (!ctx.root.$route.params.name) {
        return testComponents
      }

      const search = ctx.root.$route.params.name.toLowerCase()
      const keys = Object.keys(testComponents).filter((k) => k.toLowerCase().indexOf(search) !== -1)
      const toRender: { [key: string]: object } = {}

      for (const key of keys) {
        toRender[key] = testComponents[key]
      }

      return toRender
    })

    return {
      componentsToRender,
    }
  },
})
