













import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'SmartRouterLink',
  props: {
    to: { type: String, required: true },
    text: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  components: {},
  setup() {
    return {}
  },
})
