








































































































import { defineComponent, computed, PropType, ref } from '@vue/composition-api'
import { CoachConflictElement } from '@/models/TeamManagement/CoachConflictElement'
import dayjs from 'dayjs'
import { CoachConflictInfo } from '@/GeneratedTypes/ListInfo/CoachConflictInfo'
import { CoachConflictTypeEnum } from '@/models/TeamManagement/CoachConflictTypeEnum'

export default defineComponent({
  name: 'CoachConflictListElement',
  props: {
    coachConflict: { type: Object as PropType<CoachConflictElement>, required: true },
    isCalendarMode: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const showAllConflicts = ref(false)
    const coachLink = computed(() => {
      const c = props.coachConflict.conflict
      if (c.coachLinkFirstName) {
        return `${c.coachLinkFirstName} ${c.coachLinkLastName}`
      }
      return ''
    })

    const isCoachLinkConflict = computed(() => {
      return props.coachConflict.conflict.typeCoachConflictID === CoachConflictTypeEnum.CoachLinkNotHonored
    })

    const exceptionIconClass = computed(() => {
      switch (props.coachConflict.conflict.typeCoachConflictID) {
        case CoachConflictTypeEnum.CoachLinkNotHonored:
          return 'fas fa-user'
        case CoachConflictTypeEnum.PracticeDoubleBooked:
          return 'fas fa-calendar-alt'
        case CoachConflictTypeEnum.GameDoubleBooked:
          return 'far fa-calendar-alt'
        default:
          return 'far fa-question-circle'
      }
    })

    const allConflictType = computed(() => {
      return props.coachConflict.conflict.typeCoachConflictID === CoachConflictTypeEnum.PracticeDoubleBooked
        ? 'practice'
        : 'game'
    })

    function formatDate(d: Date | null) {
      return d ? dayjs(d).format('MMM DD hh:mm a') : ''
    }

    function formatTime(d: Date | null) {
      return d ? dayjs(d).format('hh:mm a') : ''
    }

    function formatAppt(info: CoachConflictInfo) {
      return `${info.teamName} (${info.divisionName}): ${formatDate(info.appointmentStart)} - ${formatTime(
        info.appointmentEnd
      )}`
    }

    return {
      showAllConflicts,
      coachLink,
      isCoachLinkConflict,
      exceptionIconClass,
      formatDate,
      formatTime,
      formatAppt,
      allConflictType,
    }
  },
})
