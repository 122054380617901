import { computed, reactive, ref, watch } from '@vue/composition-api'
import localStorageService from '@/services/localStorageService'
import { useManagerLogic } from '@/views/Programs/Divisions/TeamManagement/logic/ManagerLogic'
import { CoachConflictTypeEnum } from '@/models/TeamManagement/CoachConflictTypeEnum'
import store from '@/store'

interface ParticipantColumns {
  label: string
  value: boolean
  id: string
}

interface PersistedPreferences {
  v: number
  columns: ParticipantColumns[]
  teamColumns: ParticipantColumns[]
  sortBy: string // sorts unassigned participants
  teamSortBy: string // sorts players within a team
  teamListSortBy: string //sorts the list of teams
  coachTeamListSortBy: string //sorts the list of teams
}

interface PreferenceState {
  filterTarget: string
  gender: string[] | null
  hideLocked: boolean
  heightRange: number[]
  scoreRange: number[]
  gradeRange: string[]
  isFiltering: boolean
  isByAge: boolean
}

const persistedPreferences: PersistedPreferences = {
  v: 5,
  columns: [
    { id: 'score', label: 'score', value: true },
    { id: 'experience', label: 'experience', value: true },
    { id: 'grade', label: 'grade', value: true },
    { id: 'gender', label: 'gender', value: true },
    { id: 'height', label: 'height', value: false },
    { id: 'cannot practice on', label: 'cannot practice on', value: false },
    { id: 'coach link', label: 'coach link', value: false },
    { id: 'carpool link', label: 'carpool link', value: false },
    { id: 'church', label: 'church', value: false },
    { id: 'player notes', label: 'player notes', value: false },
  ],
  teamColumns: [
    { id: 'score', label: 'score', value: true },
    { id: 'position', label: 'position', value: true },
    { id: 'height', label: 'height', value: true },
    { id: 'grade', label: 'grade', value: true },
    { id: 'experience', label: 'experience', value: false },
    { id: 'cannot practice on', label: 'cannot practice on', value: false },
  ],
  sortBy: 'name_asc',
  teamSortBy: 'name_asc',
  teamListSortBy: 'team_name_asc',
  coachTeamListSortBy: 'team_name_asc',
}

const preferenceState: PreferenceState = {
  filterTarget: 'BOTH',
  gender: ['F', 'M'],
  hideLocked: false,
  heightRange: [0, 0],
  scoreRange: [0, 0],
  gradeRange: ['K3', '12th'],
  isFiltering: false,
  isByAge: false,
}

export interface FilterTracking {
  label: string
  value: string
}

export interface ConflictFilter {
  id: string
  label: string
  value: boolean
  typeDraftExceptionID: string
}

interface PersistedConflictPreferences {
  v: number
  filters: ConflictFilter[]
}

const persistedConflictPreferences: PersistedConflictPreferences = {
  v: 2,
  filters: [
    {
      id: 'carpool link not honored',
      label: 'carpool link not honored',
      value: true,
      typeDraftExceptionID: 'CARPOOLLINKNOTHONORED',
    },
    {
      id: 'parent / coach link not honored',
      label: 'parent / coach link not honored',
      value: true,
      typeDraftExceptionID: 'PARENTCOACHLINKNOTHONORED',
    },
    {
      id: 'sibling link not honored',
      label: 'sibling link not honored',
      value: true,
      typeDraftExceptionID: 'SIBLINGLINKNOTHONORED',
    },
    {
      id: 'practice night exclusion not honored',
      label: 'practice night exclusion not honored',
      value: true,
      typeDraftExceptionID: 'PRACTICENIGHTEXCLUSIONNOTHONORED',
    },
  ],
}

export interface CoachConflictFilter {
  id: string
  label: string
  value: boolean
  typeCoachConflictID: string
}

interface PersistedCoachConflictPreferences {
  v: number
  filters: CoachConflictFilter[]
}

const persistedCoachConflictPreferences: PersistedCoachConflictPreferences = {
  v: 2,
  filters: [
    {
      id: 'coach link not honored',
      label: 'coach link not honored',
      value: true,
      typeCoachConflictID: CoachConflictTypeEnum.CoachLinkNotHonored,
    },
    {
      id: 'practice double booked',
      label: 'practice double booked',
      value: true,
      typeCoachConflictID: CoachConflictTypeEnum.PracticeDoubleBooked,
    },
    {
      id: 'game double booked',
      label: 'game double booked',
      value: true,
      typeCoachConflictID: CoachConflictTypeEnum.GameDoubleBooked,
    },
  ],
}

export default function userPreferences() {
  const { participants, currentProgramID } = useManagerLogic()
  //initialize
  const teamMgtPreferenceKey = 'team-mgt-preferences'
  const teamMgConflictPreferencesKey = 'team-mgt-conflict-preferences'
  const teamMgCoachConflictPreferencesKey = 'team-mgt-coach-conflict-preferences'
  const storedPreferences = localStorageService.getParsed<PersistedPreferences>(teamMgtPreferenceKey)
  const storedConflictPreferences = localStorageService.getParsed<PersistedConflictPreferences>(
    teamMgConflictPreferencesKey
  )
  const storedCoachConflictPreferences = localStorageService.getParsed<PersistedCoachConflictPreferences>(
    teamMgCoachConflictPreferencesKey
  )

  if (storedPreferences) {
    //clear existing object from local storage if json shape changes
    if (storedPreferences.v === persistedPreferences.v) {
      persistedPreferences.columns = storedPreferences.columns
      persistedPreferences.sortBy = storedPreferences.sortBy
      persistedPreferences.teamListSortBy = storedPreferences.teamListSortBy
      persistedPreferences.teamSortBy = storedPreferences.teamSortBy
    } else {
      localStorageService.set(teamMgtPreferenceKey, persistedPreferences)
    }
  }

  if (storedConflictPreferences) {
    //clear existing object from local storage if json shape changes
    if (storedConflictPreferences.v === persistedConflictPreferences.v) {
      persistedConflictPreferences.filters = storedConflictPreferences.filters
    } else {
      localStorageService.set(teamMgConflictPreferencesKey, persistedConflictPreferences)
    }
  }

  if (storedCoachConflictPreferences) {
    //clear existing object from local storage if json shape changes
    if (storedCoachConflictPreferences.v === persistedCoachConflictPreferences.v) {
      persistedCoachConflictPreferences.filters = storedCoachConflictPreferences.filters
    } else {
      localStorageService.set(teamMgCoachConflictPreferencesKey, persistedCoachConflictPreferences)
    }
  }

  const state = reactive(persistedPreferences)
  const preferences = reactive(preferenceState)
  const conflictState = reactive(persistedConflictPreferences)
  const coachConflictState = reactive(persistedCoachConflictPreferences)
  const showHeader = ref(true)

  const resetFilters = () => {
    setScoreRange([getScoreMinMax.value.min, getScoreMinMax.value.max])
    setHeightRange([getHeightMinMax.value.min, getHeightMinMax.value.max])
    setFilterTarget('BOTH')
    setGender(['F', 'M'])
    setGradeRange(['K3', '12th'])
    setHideLocked(false)
  }

  const setColumns = (columnID: string) => {
    state.columns.forEach((c) => {
      if (c.id === columnID) c.value = !c.value
    })
    localStorageService.set(teamMgtPreferenceKey, state)
  }

  const setTeamColumns = (columnID: string) => {
    state.teamColumns.forEach((c) => {
      if (c.id === columnID) c.value = !c.value
    })
    localStorageService.set(teamMgtPreferenceKey, state)
  }

  const setConflictFilters = (filterID: string) => {
    conflictState.filters.forEach((f) => {
      if (f.id === filterID) f.value = !f.value
    })
    localStorageService.set(teamMgConflictPreferencesKey, conflictState)
  }

  const setCoachConflictFilters = (filterID: string) => {
    coachConflictState.filters.forEach((f) => {
      if (f.id === filterID) f.value = !f.value
    })
    localStorageService.set(teamMgCoachConflictPreferencesKey, coachConflictState)
  }

  const setShowHeader = (value: boolean) => (showHeader.value = value)

  const setSort = (sort: string) => {
    state.sortBy = sort
    localStorageService.set(teamMgtPreferenceKey, state)
  }

  const setTeamSort = (sort: string) => {
    state.teamSortBy = sort
    localStorageService.set(teamMgtPreferenceKey, state)
  }

  const setTeamListSort = (sort: string) => {
    state.teamListSortBy = sort
    localStorageService.set(teamMgtPreferenceKey, state)
  }

  const setCoachTeamListSort = (sort: string) => {
    state.coachTeamListSortBy = sort
    localStorageService.set(teamMgtPreferenceKey, state)
  }

  const setHeightRange = (range: number[]) => {
    preferences.heightRange = range
  }

  const setScoreRange = (range: number[]) => {
    preferences.scoreRange = range
  }

  const setGradeRange = (range: string[]) => {
    preferences.gradeRange = range
  }

  const setFilterTarget = (target: string) => {
    preferences.filterTarget = target
  }

  const setGender = (gender: string[]) => {
    if (!gender.length) preferences.gender = null
    preferences.gender = [...gender]
  }

  const setHideLocked = (hide: boolean) => {
    preferences.hideLocked = hide
  }

  const setIsByAge = (iba: boolean) => {
    preferences.isByAge = iba
    const col = state.columns.find((x) => x.id == 'grade')
    if (col) {
      col.label = iba ? 'age' : 'grade'
    }
    const teamCol = state.teamColumns.find((x) => x.id == 'grade')
    if (teamCol) {
      teamCol.label = iba ? 'age' : 'grade'
    }
    localStorageService.set(teamMgtPreferenceKey, state)
  }

  const getShowHeader = computed(() => showHeader.value)
  const getColumns = computed(() => state.columns)
  const getTeamColumns = computed(() => state.teamColumns)
  const getSort = computed(() => state.sortBy)
  const getTeamSort = computed(() => state.teamSortBy)
  const getTeamListSort = computed(() => state.teamListSortBy)
  const getCoachTeamListSort = computed(() => state.coachTeamListSortBy)
  const getHideLocked = computed(() => preferences.hideLocked)
  const getIsByAge = computed(() => preferences.isByAge)
  const getGender = computed(() => preferences.gender)
  const getFilterTarget = computed(() => preferences.filterTarget)
  const getHeightRange = computed(() => preferences.heightRange)
  const getScoreRange = computed(() => preferences.scoreRange)
  const getGradeRange = computed(() => preferences.gradeRange)
  const getHeightMinMax = ref<{ min: number; max: number }>({ min: 0, max: 0 })
  const getScoreMinMax = ref<{ min: number; max: number }>({ min: 0, max: 0 })
  const getConflictFilters = computed(() => conflictState.filters)
  const getCoachConflictFilters = computed(() => coachConflictState.filters)

  watch(
    () => participants.value,
    () => {
      getHeightMinMax.value = range(participants.value.map((p: any) => p.extraScore))
      getScoreMinMax.value = range(participants.value.map((p: any) => p.evaluationScore))
      resetFilters()
    },
    { immediate: true }
  )

  const getIsFilteringTeams = computed(() => {
    if (getFilterTarget.value == 'UNASSIGNED') return false
    return filtersDirty() || !hideLockedClean.value || !gradeFilterClean.value
  })

  const getIsFilteringUnassigned = computed(() => {
    if (getFilterTarget.value == 'TEAMS') return false
    return filtersDirty() || !gradeFilterClean.value
  })
  const heightFilterClean = computed(() => {
    if (getHeightMinMax.value.max == 0) return true
    return usingHeight.value
      ? getHeightRange.value[0] == getHeightMinMax.value.min &&
          getHeightRange.value[1] == getHeightMinMax.value.max
      : true
  })

  const hideLockedClean = computed(() => (usingHideLock.value ? !getHideLocked.value : true))

  const scoreFilterClean = computed(() => {
    if (getHeightMinMax.value.max == 0) return true
    return usingScore.value
      ? getScoreRange.value[0] == getScoreMinMax.value.min &&
          getScoreRange.value[1] == getScoreMinMax.value.max
      : true
  })

  const gradeFilterClean = computed(() => {
    const lastIndex = getGradeRange.value.length - 1
    return getGradeRange.value[0] == 'K3' && getGradeRange.value[lastIndex] == '12th'
  })

  const genderFilterClean = computed(() => getGender.value?.length == 2)

  const usingHeight = computed(() => {
    return currentProgramID.value == 'BASKETBALL' || currentProgramID.value == 'VOLLEYBALL'
  })

  const usingPosition = computed(() => {
    return (
      currentProgramID.value == 'FOOTBALL' ||
      currentProgramID.value == 'BASEBALL' ||
      currentProgramID.value == 'SOFTBALL'
    )
  })

  const usingHideLock = computed(() => {
    return getFilterTarget.value != 'UNASSIGNED'
  })

  const usingScore = computed(() => {
    return !(
      currentProgramID.value == 'BASKETBALLCHEERLEADING' ||
      currentProgramID.value == 'FOOTBALLCHEERLEADING' ||
      currentProgramID.value == 'UPWARDSELECTMSS'
    )
  })
  function filtersDirty(): boolean {
    return !genderFilterClean.value || !heightFilterClean.value || !scoreFilterClean.value
  }

  const currentFilters = computed(() => {
    const list = [] as FilterTracking[]
    let heightRange = 'all'
    let scoreRange = 'all'
    let gradeRange = 'all'
    let genders = 'no one'

    let target = 'unassigned and teams'

    if (getFilterTarget.value != 'BOTH') {
      target = getFilterTarget.value.toLocaleLowerCase()
    }
    list.push({ label: 'Target', value: target })

    if (getGender.value && getGender.value.length) {
      genders = getGender.value?.join(',') ?? ''
      genders = genders.replace('F', 'girls')
      genders = genders.replace('M', 'boys')
    }
    list.push({ label: 'Gender', value: genders })

    if (usingHeight.value && getHeightMinMax.value.max != 0) {
      if (!heightFilterClean.value) {
        heightRange = `${getHeightRange.value[0]} to ${getHeightRange.value[1]}`
      }
      list.push({ label: 'Height', value: heightRange })
    }

    if (usingScore.value && getScoreMinMax.value.max != 0) {
      if (!scoreFilterClean.value) {
        scoreRange = `${getScoreRange.value[0]} to ${getScoreRange.value[1]}`
      }
      list.push({ label: 'Score', value: scoreRange })
    }

    if (getFilterTarget.value != 'UNASSIGNED') {
      list.push({ label: 'Hide Locked', value: `${getHideLocked.value}` })
    }

    if (!gradeFilterClean.value) {
      gradeRange = `${
        getIsByAge.value
          ? store.getters.gradeTypes.byUpwardTypeId(getGradeRange.value[0])?.ageByCutoff ?? '??'
          : getGradeRange.value[0]
      } to ${
        getIsByAge.value
          ? store.getters.gradeTypes.byUpwardTypeId(getGradeRange.value[getGradeRange.value.length - 1])
              ?.ageByCutoff ?? '??'
          : getGradeRange.value[getGradeRange.value.length - 1]
      }`
      list.push({ label: getIsByAge.value ? 'Ages' : 'Grades', value: gradeRange })
    }
    return list
  })

  function range(list: number[]) {
    const min = Math.min(...list)
    const max = Math.max(...list)
    return {
      min: isFinite(min) ? min : 0,
      max: isFinite(max) ? max : 0,
    }
  }

  return {
    setColumns,
    setTeamColumns,
    setSort,
    setTeamSort,
    setTeamListSort,
    setCoachTeamListSort,
    setGender,
    setHideLocked,
    setIsByAge,
    setFilterTarget,
    setHeightRange,
    setScoreRange,
    setGradeRange,
    setShowHeader,
    getShowHeader,
    getColumns,
    getTeamColumns,
    getSort,
    getTeamSort,
    getTeamListSort,
    getCoachTeamListSort,
    getFilterTarget,
    getHideLocked,
    getIsByAge,
    getGender,
    getIsFilteringTeams,
    getIsFilteringUnassigned,
    getHeightRange, // value to filter on
    getScoreRange, // value to filter on
    getGradeRange,
    getHeightMinMax, //min/max value for the height slider
    getScoreMinMax, // min/max value for the score sliter
    resetFilters,
    usingHeight,
    usingPosition,
    usingScore,
    currentFilters,
    setConflictFilters,
    getConflictFilters,
    setCoachConflictFilters,
    getCoachConflictFilters,
  }
}
