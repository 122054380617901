import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store/rootState'
import upwardTypesClient from '@/clients/upwardTypesClient'
import * as genericUpwardTypes from '@/store/genericUpwardTypes'
import { UpwardColorTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardColorTypeID'
import { defineActions } from 'direct-vuex'

const generic = genericUpwardTypes.getGenericUpwardTypes<UpwardColorTypeID>(
  upwardTypesClient.retrieveColorTypes
)

const typeState: genericUpwardTypes.GenericTypeState<UpwardColorTypeID> = {
  items: [],
}

const getterTree: GetterTree<genericUpwardTypes.GenericTypeState<UpwardColorTypeID>, RootState> = {
  ...generic.getterTree,
}

const mutations: MutationTree<genericUpwardTypes.GenericTypeState<UpwardColorTypeID>> = {
  ...generic.mutations,
}

const actions = defineActions({
  ...generic.actions,
})

export const getterNames = {
  ...genericUpwardTypes.getterNames,
}

export const mutationNames = {
  ...genericUpwardTypes.mutationNames,
}

export const actionNames = {
  ...genericUpwardTypes.actionNames,
}

export const namespace = 'colorTypes'

export const colorTypes = {
  namespaced: true as true,
  state: typeState,
  getters: getterTree,
  actions,
  mutations,
}
