















import CoachConflictListElement from '@/views/Programs/Divisions/TeamManagement/vues/CoachConflictListElement.vue'
import { CoachConflictInfo } from '@/GeneratedTypes/ListInfo/CoachConflictInfo'
import { CoachConflictElement } from '@/models/TeamManagement/CoachConflictElement'
import { CoachConflictTypeEnum } from '@/models/TeamManagement/CoachConflictTypeEnum'
import { Container } from 'vue-smooth-dnd'
import { cloneDeep, groupBy } from 'lodash'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'
import dayjs from 'dayjs'

/***
 * Component hosts a sortable list of participants
 */
export default defineComponent({
  name: 'CoachConflictList',
  components: { Container, CoachConflictListElement },
  props: {
    conflicts: { type: Array as PropType<CoachConflictInfo[]>, required: true },
    loading: Boolean,
    upwardLeagueID: { type: String, required: true },
    isCalendarMode: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const { getCoachConflictFilters } = userPreferences()
    const coachConflictOrder = ref<CoachConflictElement[]>([])

    /***
     * When we get new participants, then sort by last name and set the order.
     */
    watch(
      () => props.conflicts,
      () => {
        applyFilters()
      },
      { immediate: true }
    )

    watch(
      () => getCoachConflictFilters.value,
      () => {
        applyFilters()
      },
      { immediate: true, deep: true }
    )

    function applyFilters() {
      if (props.conflicts && props.conflicts.length > 0) {
        const copyList = cloneDeep(props.conflicts)
        const filteredList = copyList.filter((c) => {
          return getCoachConflictFilters.value.some(
            (f) => f.value && f.typeCoachConflictID == c.typeCoachConflictID
          )
        })

        const groupedByCoachList = groupBy(filteredList, (x) => x.individualID)
        const groupedList = Object.keys(groupedByCoachList).map((x) => {
          return {
            individualID: x,
            dictConflictsByType: groupBy(groupedByCoachList[x], (g) => g.typeCoachConflictID),
          }
        })

        const newList = ref([] as CoachConflictElement[])
        for (const coach of groupedList) {
          for (const [typeKey, conflictsByType] of Object.entries(coach.dictConflictsByType)) {
            if (typeKey == CoachConflictTypeEnum.CoachLinkNotHonored) {
              //push all of these
              newList.value.push(
                ...conflictsByType.map((x) => ({
                  conflict: x,
                  key: x.typeCoachConflictID + '_' + x.individualID,
                  matchingConflicts: [] as CoachConflictInfo[],
                }))
              )
            } else {
              //only push the first conflict with the other matching ones with it
              newList.value.push({
                conflict: conflictsByType[0],
                key: typeKey + '_' + coach.individualID,
                matchingConflicts: conflictsByType.sort((a: CoachConflictInfo, b: CoachConflictInfo) => {
                  return dayjs(a.appointmentStart ?? dayjs()).isAfter(dayjs(b.appointmentStart ?? dayjs()))
                    ? 1
                    : -1
                }),
              })
            }
          }
        }

        coachConflictOrder.value = newList.value.sort((a: CoachConflictElement, b: CoachConflictElement) => {
          return (
            a.conflict.formattedNameLastFirst?.localeCompare(b.conflict.formattedNameLastFirst ?? '') ?? 0
          )
        })
      } else {
        coachConflictOrder.value = []
      }
    }

    const toggleTeam = (teamID: number) => ctx.emit('toggle-team', teamID)

    return {
      coachConflictOrder,
      toggleTeam,
    }
  },
})
