import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { TapeDownReasonEnum } from '@/lib/support/models/ListInfo/TeamPlayerInfo/data'
import { filterGrades } from '@/services/gradeService'

interface FillerPlayer {
  extraScore: number
  evaluationScore: number
  gender: string | null
}

interface AssignedPlayer {
  typeTapeDownReasonID: string | null
  grade: string | null
  typeGradeID: string | null
}

/* 
Filters that apply to assigned participants (TeamPlayerInfo)
and unassigned participants (LeaguePlayerInfo).
*/
export function participantFilters(
  x: FillerPlayer,
  gender: string[],
  heightRange: number[],
  scoreRange: number[]
) {
  let genderPasses = true
  let heightPasses = true
  let scorePasses = true
  if (gender) {
    genderPasses = gender.indexOf(x.gender ?? '') >= 0
  }
  heightPasses = x.extraScore >= heightRange[0] && x.extraScore <= heightRange[1]
  scorePasses = x.evaluationScore >= scoreRange[0] && x.evaluationScore <= scoreRange[1]

  return genderPasses && heightPasses && scorePasses
}

/*
Filters that apply to unassigned participants only
(LeaguePLayerInfo).
*/

export function unassignedParticipantFilters(x: LeaguePlayerInfo, gradeRange: string[]) {
  return filterGrades(gradeRange[0], gradeRange[gradeRange.length - 1], x.typeGradeID)
}

/*
Filters that apply to assigned participants only.
*/

export function assignedParticipantFilter(x: AssignedPlayer, hideLocked: boolean, gradeRange: string[]) {
  let passesLocked = true
  let passesGrade = true
  if (hideLocked) {
    passesLocked = x.typeTapeDownReasonID != TapeDownReasonEnum.LOCKED_TO_TEAM
  }
  passesGrade = filterGrades(gradeRange[0], gradeRange[gradeRange.length - 1], x.typeGradeID)
  return passesLocked && passesGrade
}
