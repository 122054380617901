/*
 * Logic that is shared across the overall product flow
 */
import { computed } from '@vue/composition-api'

import store from '@/store'
import { ProdConfigTypes } from '@/lib/common/ProdConfigTypes'
import { LeagueTypes } from '@/lib/common/LeagueTypes'

export function productFlowLogic() {
  /* productFlow Store */
  const isNewProdConfig = computed(() => store.getters.productFlow.isNewProdConfig)
  const prodConfigType = computed(() => store.getters.productFlow.prodConfigType)
  const currentTypeProgramId = computed(() => store.getters.productFlow.currentProgramTypeId)
  const setCurrentProductSetupStep = store.commit.productFlow.setCurrentProductSetupStep
  const setCurrentTypeProgram = store.commit.productFlow.setCurrentTypeProgram
  const productSetupMaxTouchedStep = computed(() => store.getters.productFlow.maxTouchedProductSetupStep)
  const setProdConfigType = store.commit.productFlow.setProdConfigType
  const setIsNewProdConfig = store.commit.productFlow.setIsNewProdConfig

  /* leagueAbstraction store */
  const currentTypeLeagueId = computed(() => store.getters.leagueAbstraction.currentItem.typeLeagueID)
  const leagueAbstraction = computed(() => store.getters.leagueAbstraction.currentItem)

  /* productOffering Store */
  const productOfferingConfig = computed(() => store.getters.productOfferings.productOfferingConfig)

  /* authorization Store */
  const firstAccountNumber = computed(() => store.getters.authorization.firstAccountNumber)

  const isUpwardSelect = computed(
    () =>
      (prodConfigType.value == ProdConfigTypes.ESTIMATE
        ? productOfferingConfig.value.typeLeagueID
        : leagueAbstraction.value.typeLeagueID) == LeagueTypes.UPWARDSELECT
  )

  /*
   * ProductSetupStepper Logic
   */
  function productSetupNextStep(currentStep: number) {
    const steps = store.getters.productFlow.steps
    const stepName = steps[currentStep]
    store.commit.productFlow.addViewedStep({ step: stepName })

    const next = currentStep + 1
    setCurrentProductSetupStep({ step: next })
    setMaxTouchedProductSetupStep(next)
  }

  function setMaxTouchedProductSetupStep(nextStep: number) {
    const max = store.getters.productFlow.maxTouchedProductSetupStep
    if (nextStep >= max) {
      store.commit.productFlow.setMaxTouchedProductSetupStep({ step: nextStep })
    }
  }

  function markAsDirty() {
    if (store.getters.productOfferings.initialProductFlowLoadingComplete) {
      store.commit.promptToSave.setIsDirty({ isDirty: true })
    }
  }

  function getUpwardProgramTypeByIsCheer(isCheer: boolean) {
    return store.getters.leagueAbstraction.leagueUpwardProgramTypeID.find((p) => p.isCheer == isCheer)
  }

  function productSetupStepClicked(step: number) {
    setCurrentProductSetupStep({ step })
  }

  /*
   * ParticipantKitStepper 1 Logic
   */
  const kit1MaxTouchedStep = computed(() => store.getters.productFlow.maxTouchedParticipantKit1Step)

  function kit1NextStep(currentStep: number) {
    const next = currentStep + 1
    store.commit.productFlow.setCurrentParticipantKit1Step({ step: next })
    setMaxTouchedParticipantKit1Step(next)
  }

  function setMaxTouchedParticipantKit1Step(nextStep: number) {
    const max = store.getters.productFlow.maxTouchedParticipantKit1Step
    if (nextStep >= max) {
      store.commit.productFlow.setMaxTouchedParticipantKit1Step({ step: nextStep })
    }
  }

  function kit1Clicked(step: number) {
    store.commit.productFlow.setCurrentParticipantKit1Step({ step })
  }

  /*
   * ParticipantKitStepper 2 Logic
   */
  const kit2MaxTouchedStep = computed(() => store.getters.productFlow.maxTouchedParticipantKit2Step)
  function kit2Clicked(step: number) {
    store.commit.productFlow.setCurrentParticipantKit2Step({ step })
  }

  function setMaxTouchedParticipantKit2Step(nextStep: number) {
    const max = store.getters.productFlow.maxTouchedParticipantKit2Step
    if (nextStep >= max) {
      store.commit.productFlow.setMaxTouchedParticipantKit2Step({ step: nextStep })
    }
  }

  function kit2NextStep(currentStep: number) {
    const next = currentStep + 1
    store.commit.productFlow.setCurrentParticipantKit2Step({ step: next })
    setMaxTouchedParticipantKit2Step(next)
  }

  /*
   * Step actions
   */
  function nextStep(isSport: boolean, step: number) {
    if (isSport) {
      kit1NextStep(step)
    } else {
      kit2NextStep(step)
    }
  }

  function kitStepClicked(isSport: boolean, step: number) {
    if (isSport) {
      kit1Clicked(step)
    } else {
      kit2Clicked(step)
    }
  }

  /*
   * Fetch product catalog
   */
  async function fetchProductCatalog() {
    store.commit.productFlow.setProductOfferingLoading({ loading: true })

    const firstPracticeDate =
      prodConfigType.value == ProdConfigTypes.ESTIMATE
        ? productOfferingConfig.value.firstPracticeDateEstimate
        : leagueAbstraction.value.productSeasonDate

    const leagueType =
      prodConfigType.value == ProdConfigTypes.ESTIMATE
        ? productOfferingConfig.value.typeLeagueID
        : leagueAbstraction.value.typeLeagueID
    try {
      await store.dispatch.productOfferings.fetchTopLevelProducts({
        accountNumber: firstAccountNumber.value,
        leagueType: leagueType ?? '',
        firstPracticeDate,
      })
    } finally {
      store.commit.productFlow.setProductOfferingLoading({ loading: false })
    }
  }

  /*
   * Utilites
   */

  function adjustIndex(i: number) {
    // Add 1 to convert 0 base index to 1 base
    // Add another 1 to account for product-group-picker (offering) in the stepper
    return i + 2
  }

  return {
    leagueAbstraction,
    productOfferingConfig,
    currentTypeProgramId,
    currentTypeLeagueId,
    firstAccountNumber,
    productSetupNextStep,
    productSetupMaxTouchedStep,
    productSetupStepClicked,
    nextStep,
    kitStepClicked,
    kit1MaxTouchedStep,
    kit2MaxTouchedStep,
    adjustIndex,
    fetchProductCatalog,
    markAsDirty,
    setProdConfigType,
    setIsNewProdConfig,
    isNewProdConfig,
    prodConfigType,
    setCurrentTypeProgram,
    getUpwardProgramTypeByIsCheer,
    isUpwardSelect,
  }
}
