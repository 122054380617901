/**
 * Rest Operations primarily for CRUD on LeaguePlayerInfo
 */

import restService, { RestServiceResult } from '@/services/restService'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'
import {
  IsNewVolunteer,
  RolesEnum,
  VolunteerID,
  VolunteerIDType,
} from '@/lib/support/models/LeagueVolunteer/data'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { VolunteerSearchArgs } from '@/GeneratedTypes/VolunteerSearchArgs'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import dayjs from 'dayjs'

import { CombineDataArray } from '@/lib/support/models/RestServiceResult/CombineDataArray'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { LeagueVolunteerRole } from '@/GeneratedTypes/LeagueVolunteerRole'
import { LeagueCoach } from '@/GeneratedTypes/LeagueCoach'
import { CoachConflictInfo } from '@/GeneratedTypes/ListInfo/CoachConflictInfo'
import { FillMinimumLVDetail } from '@/lib/support/models/LeagueVolunteer/PlayerContactToLeagueVolunteer'
import { cloneDeep, uniq } from 'lodash'
import { RoleIDToRoleName } from '@/views/Programs/Volunteers/ext/roleutils'
import { AxiosRequestConfig } from 'axios'

const baseUrl = 'volunteers/'
function ourURL(leagueID: string) {
  return `${baseUrl}${leagueID}`
}

interface VolunteerClientSearchArgs {
  leagueID: UpwardLeagueIDType
  first?: string
  last?: string
  email?: string
  phone?: string
  orSearch?: boolean
  role: RolesEnum[]
  includePastLeagues?: boolean
  historyLimit?: null | Date
  useStartsWith?: boolean
  coachTypeProgramID?: string
}
export class VolunteerClientException extends GeneralError {
  name = 'Volunteer API Error'
}

export default class volunteersClient {
  /**
 * Returns volunteers based on a LeagueID or LeagueID and ProgramType
 * @param leagueID UPW### league

 * @return array of league volunteers from the league id
 */
  static async retrieve(leagueID: UpwardLeagueIDType): Promise<LeagueVolunteerInfo[] | null> {
    const x = await restService.get<LeagueVolunteerInfo[]>(`${ourURL(leagueID)}/`)
    if (!x.isSuccess) {
      throw new VolunteerClientException('Could not get list of volunteers')
    }
    return x.data
  }

  /**
   * Remove the role off a volunteer, if the returned volunteer has no roles left then remove
   * the volunteer from the database.
   * @param leagueID
   * @param volunteerID
   * @param roleID
   */
  static async removeRole(
    leagueID: UpwardLeagueIDType,

    volunteerID: VolunteerIDType,
    roleID: number
  ) {
    const x = await restService.delete<LeagueVolunteer>(`${ourURL(leagueID)}/${volunteerID}/${roleID}`)
    if (!x.isSuccess) {
      throw new VolunteerClientException('Server could not remove ' + volunteerID)
    }
    //remove a volunteer if no roles.
    if (x.data && !x.data.roles?.length) {
      await volunteersClient.remove(leagueID, volunteerID)
    }
    return x.data
  }

  /**
   * Remove a volunteer by ID from the server.
   * @param leagueID
   * @param volunteerID
   */
  static async remove(
    leagueID: UpwardLeagueIDType,

    volunteerID: VolunteerIDType
  ) {
    const x = await restService.delete<LeagueVolunteer>(`${ourURL(leagueID)}/${volunteerID}`)
    if (!x.isSuccess) {
      throw new VolunteerClientException('Server could not remove ' + volunteerID)
    }
    return x.data
  }

  /***
   * Run the validation routine
   * @param leagueID
   * @param volunteer
   * @param forceAsNew - if we are treating this as new player to this league (e.g. imported from a different league)
   * @throws Error object -- has validation errors.
   */
  static async validate(leagueID: UpwardLeagueIDType, volunteer: LeagueVolunteer, forceAsNew = false) {
    let idpart = '/' // if new this isn't set.

    if (!IsNewVolunteer(volunteer) && !forceAsNew) {
      idpart = '/' + VolunteerID(volunteer) + '/'
    }
    const x = await restService.post<LeagueVolunteer>(`${ourURL(leagueID)}${idpart}validate`, volunteer)
    if (x.isSuccess) {
      return true
    }
    throw x.errorObject // this makes the exceptions show on the big red box.
  }

  /***
   * Run the save routine
   * @param leagueID
   * @param volunteer
   * @param forceAsNew - overrides the check that normally assumes volunteer id of 0 is a new volunteer (used in existing individual)
   * @throws Error object -- has validation errors.
   */
  static async save(
    leagueID: UpwardLeagueIDType,
    volunteer: LeagueVolunteer,
    forceAsNew?: boolean,
    xlsImport = false
  ) {
    const isNewPlayer = IsNewVolunteer(volunteer)
    let x: RestServiceResult<LeagueVolunteer> | null = null

    // volunteer import requires special header
    const header: AxiosRequestConfig = { headers: { AutoMapPendingLinks: true } }
    const config = xlsImport ? header : undefined

    if (isNewPlayer || forceAsNew) {
      x = await restService.post<LeagueVolunteer>(`${ourURL(leagueID)}`, volunteer, config)
    } else {
      const idpart = '/' + VolunteerID(volunteer) + '/'
      x = await restService.put<LeagueVolunteer>(`${ourURL(leagueID)}${idpart}`, volunteer, config)
    }

    if (!x.isSuccess) {
      throw new VolunteerClientException('Problem with volunteer save. ', x.errorObject)
    }
    if (x.isSuccess) {
      return x.data
    }
    return x.data
  }

  /***
   * Search former volunteers.
   * @param leagueID {string} - league to search
   * @param first {string} - first or general search (for an or search)
   * @param last {string} - last name or general search
   * @param email {string} - email or general search (for an or search)
   * @param phone {string} - phone number or general search
   * @param orSearch {boolean} - and/or search boolean flag.
   */
  static async search({
    leagueID,
    first,
    last,
    email,
    phone,
    role,
    orSearch,
    includePastLeagues,
    historyLimit,
    useStartsWith,
    coachTypeProgramID,
  }: VolunteerClientSearchArgs): Promise<VolunteerSearchInfo[]> {
    const params: VolunteerSearchArgs = {
      currentUpwardLeagueID: leagueID,
      currentLeagueID: 0,
      firstName: first ?? null,
      lastName: last ?? null,
      email: email ?? null,
      phone: phone ?? null,
      historyLimit: typeof historyLimit === 'undefined' ? dayjs().subtract(3, 'year').toDate() : historyLimit,
      includeCurrentLeague: true,
      includePastLeagues: includePastLeagues ?? true,
      // we'll just accept the first role, in the future multiple searches if necessary
      roleID: role[0] ?? null,
      useStartsWith: typeof useStartsWith == 'undefined' ? true : useStartsWith,
      accountNumber: null,
      coachTypeProgramID: coachTypeProgramID ?? null,
    }
    const otherParams: VolunteerSearchArgs = { ...params }
    const endpointURI = `volunteers/search`
    let result: VolunteerSearchInfo[] = []

    // in an or search take the first name and do a last or first search.
    if (orSearch) {
      params.lastName = null
      otherParams.firstName = null
      otherParams.lastName = params.firstName

      const values = await Promise.all<
        RestServiceResult<VolunteerSearchInfo[]>,
        RestServiceResult<VolunteerSearchInfo[]>
      >([
        restService.post<VolunteerSearchInfo[]>(endpointURI, params),
        restService.post<VolunteerSearchInfo[]>(endpointURI, otherParams),
      ])
      result = cloneDeep(uniq(CombineDataArray<VolunteerSearchInfo>(values)))
    } else {
      const x = await restService.post<VolunteerSearchInfo[]>(endpointURI, params)
      if (!x.isSuccess) {
        throw new VolunteerClientException('Problem with the retrieval of search information')
      }
      if (x.data) {
        result = cloneDeep(x?.data)
      }
    }

    return result
  }

  /**
   * Returns a template, null or empty volunteer ID will be a new template, otherwise gets the template
   * corresponding to the participant passed, expect null on error, exception on error.
   * @param leagueID
   * @param volunteerID - or nothing for a new template
   * @param program - program we are adding to
   * @param role - role we are adding.
   * @return Promise<LeagueVolunteer|null>
   */
  static async retrieveTemplateWithRole(
    leagueID: UpwardLeagueIDType,
    program: string,
    role: RolesEnum,
    volunteerID?: VolunteerIDType
  ): Promise<LeagueVolunteer | null> {
    let url = `volunteers/${leagueID}/newForRole/${program}/${(
      RoleIDToRoleName(role) ?? ''
    ).toLocaleUpperCase()}`
    if (volunteerID) {
      url = `volunteers/${leagueID}/newExistingForRole/${volunteerID}/${program}/${(
        RoleIDToRoleName(role) ?? ''
      ).toLocaleUpperCase()}`
    }

    const x = await restService.get<LeagueVolunteer>(url)
    if (!x.isSuccess) {
      throw new VolunteerClientException(`Could not retrieve a blank volunteer ${volunteerID}`)
    }
    return x.data
  }

  /**
   * Returns a template, null or empty volunteer ID will be a new template, otherwise gets the template
   * corresponding to the participant passed, expect null on error, exception on error.
   * @param leagueID
   * @param volunteerID - or nothing for a new template
   * @return Promise<LeagueVolunteer|null>
   */
  static async retrieveTemplate(
    leagueID: UpwardLeagueIDType,
    volunteerID?: VolunteerIDType
  ): Promise<LeagueVolunteer | null> {
    const suffix = volunteerID && volunteerID > 0 ? `/${volunteerID}` : ''
    const x = await restService.get<LeagueVolunteer>(`${ourURL(leagueID)}/new${suffix}`)
    if (!x.isSuccess) {
      throw new VolunteerClientException(`Could not retrieve a blank volunteer ${volunteerID}`)
    }
    return x.data
  }

  /**
   * Does way more shifting things around then most client methods, chases down if a
   * given id is registered as a volunteer, if all roles are removed then
   * it will delete the volunteer record, this method supports player contact role adding
   * methods.
   * @param leagueID
   * @param id
   * @param existingLeagueVolunteer - this happens when we are using an existing league volunteer (e.g. parent defined on participant)
   * @param roles
   * @param coachPrefs
   */
  static async setVolunteerRoles(
    leagueID: UpwardLeagueIDType,
    id: number,
    existingLeagueVolunteer: LeagueVolunteer | undefined,
    roles: LeagueVolunteerRole[],
    coachPrefs: LeagueCoach
  ): Promise<LeagueVolunteer | null> {
    let c,
      y: LeagueVolunteer | null = null

    let isNew = id < 0 // <0 is a player contact created, an obviously bad id.
    if (!isNew) {
      // we don't know for sure yet if the ID is in the DB.
      c = await volunteersClient.retrieveVolunteer(leagueID, id)
    }

    // no contact or no leagueID, this is not a registered volunteer.
    if (!c || !c?.leagueID) {
      c = (await volunteersClient.retrieveTemplate(leagueID, id)) ?? null
      isNew = true
    }

    if (c) {
      c.roles = roles
      c.gender = existingLeagueVolunteer?.gender ?? ''

      // probably not required but cannot find null-error on backend, so no nulls
      if (coachPrefs) {
        c.coachPreferences = coachPrefs
      }

      if (existingLeagueVolunteer) {
        c = FillMinimumLVDetail(existingLeagueVolunteer, c)
      }

      if (c.roles?.length) {
        y = await volunteersClient.save(leagueID, c, isNew)
      } // no roles, remove volunteer
      else {
        if (isNew) {
          return c
        }
        y = await volunteersClient.remove(leagueID, c.individualID)
      }
    }
    return y
  }

  /**
   * Returns a template, null or empty volunteer ID will be a new template, otherwise gets the template
   * corresponding to the participant passed, expect null on error, exception on error.
   * @param leagueID
   * @param volunteerID - or nothing for a new template
   * @return Promise<LeagueVolunteer|null>
   */
  static async retrieveVolunteer(
    leagueID: UpwardLeagueIDType,
    volunteerID: VolunteerIDType
  ): Promise<LeagueVolunteer | null> {
    const x = await restService.get<LeagueVolunteer>(`${ourURL(leagueID)}/${volunteerID}`)
    if (!x.isSuccess) {
      throw new VolunteerClientException(`Could not retrieve a volunteer ID ${volunteerID}`)
    }
    return x.data
  }

  static async approve(leagueID: UpwardLeagueIDType, approved: boolean, roleid: number, who: number[]) {
    const x = await restService.post<LeagueVolunteer>(
      `${ourURL(leagueID)}/batchapproval/${roleid}/${approved ? 'true' : 'false'}`,
      who
    )
    if (!x.isSuccess) {
      throw new VolunteerClientException(`Could not approve volunteers for role ${roleid} as ${approved}`)
    }
    return x.data
  }

  /**
   * Returns a list of approved coaches by division, which accounts for the coach's age preferences.
   * @param leagueId
   * @param typeProgramId
   * @param divisionId
   * @return Promise<LeagueVolunteer|null>
   */
  static async getByDivisionId(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    divisionId: number
  ): Promise<LeagueVolunteer[] | null> {
    const x = await restService.get<LeagueVolunteer[]>(
      `${baseUrl}approvedCoachesByDivision/${leagueId}/${typeProgramId}/${divisionId}`
    )

    if (!x.isSuccess) {
      throw new VolunteerClientException(
        `Could not get a list of volunteers for ${leagueId}, ${typeProgramId}, ${divisionId}.`
      )
    }
    return x.data
  }

  /**
   * Returns a list of approved coaches by division, which accounts for the coach's age preferences.
   * @param leagueId
   * @return Promise<LeagueVolunteer|null>
   */
  static async getAllCoaches(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string
  ): Promise<LeagueVolunteer[] | null> {
    const x = await restService.get<LeagueVolunteer[]>(`${baseUrl}${leagueId}/Roles?roleIDs=3`)

    if (!x.isSuccess) {
      throw new VolunteerClientException(`Could not get a list of volunteers for ${leagueId}.`)
    }

    const coachList =
      x.data?.filter(
        (c) =>
          !typeProgramId || c.coachPreferences?.programs?.find((cp) => cp.typeProgramID == typeProgramId, 0)
      ) ?? []

    return coachList
  }

  /**
   * Returns a list of approved volunteers by RoleID.
   * @param leagueId
   * @param roleId
   * @return Promise<LeagueVolunteer|null>
   */
  static async getVolunteerByRole(
    leagueId: UpwardLeagueIDType,
    roleId: number
  ): Promise<LeagueVolunteerInfo[] | null> {
    const x = await restService.get<LeagueVolunteerInfo[]>(`${baseUrl}${leagueId}/Roles?roleIDs=${roleId}`)

    if (!x.isSuccess) {
      throw new VolunteerClientException(`Could not get a list of volunteers by role for ${leagueId}.`)
    }
    return x.data
  }

  static async getLeanVolunteerListByProgram(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string
  ): Promise<LeagueVolunteerInfo[] | null> {
    const x = await restService.get<LeagueVolunteerInfo[]>(`${baseUrl}${leagueId}/byProgram/${typeProgramId}`)

    if (!x.isSuccess) {
      throw new VolunteerClientException(
        `Could not get a list of volunteers by program for ${leagueId}/${typeProgramId}.`
      )
    }
    return x.data
  }

  static async getLeanVolunteerListByProgramAndRoles(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string,
    roleIDs: number[]
  ): Promise<LeagueVolunteerInfo[] | null> {
    const roleIDQueryString = roleIDs.join(',')
    const x = await restService.get<LeagueVolunteerInfo[]>(
      `${baseUrl}${leagueId}/byProgramAndRoles/${typeProgramId}?roleIDs=${roleIDQueryString}`
    )

    if (!x.isSuccess) {
      throw new VolunteerClientException(
        `Could not get a list of volunteers by program/role for ${leagueId}/${typeProgramId}/${roleIDQueryString}.`
      )
    }
    return x.data
  }

  static async getFullVolunteerInfo(
    leagueId: UpwardLeagueIDType,
    volunteerID: VolunteerIDType
  ): Promise<LeagueVolunteerInfo | null> {
    const x = await restService.get<LeagueVolunteerInfo>(`${baseUrl}info/${leagueId}/${volunteerID}`)

    if (!x.isSuccess) {
      throw new VolunteerClientException(`Could not get info for volunteer ${leagueId}/${volunteerID}.`)
    }
    return x.data
  }

  static async getCoachProgramListFLAT(
    leagueId: UpwardLeagueIDType,
    typeProgramId: string | null,
    divisionId: number | null
  ): Promise<LeagueCoachProgramInfoFLAT[] | null> {
    if (typeProgramId && divisionId) {
      const x = await restService.get<LeagueCoachProgramInfoFLAT[]>(
        `${baseUrl}coachProgramListApprovedFLAT/${leagueId}/${typeProgramId}/${divisionId}`
      )
      if (!x.isSuccess) {
        throw new VolunteerClientException(
          `Could not get a list of coaches for ${leagueId}, ${typeProgramId}, ${divisionId}.`
        )
      }
      return x.data
    } else {
      const x = await restService.get<LeagueCoachProgramInfoFLAT[]>(
        `${baseUrl}coachProgramListApprovedFLAT/${leagueId}`
      )

      if (!x.isSuccess) {
        throw new VolunteerClientException(`Could not get a list of coaches for ${leagueId}.`)
      }
      return x.data
    }
  }

  static async retrieveCoachConflicts(leagueID: UpwardLeagueIDType): Promise<CoachConflictInfo[] | null> {
    if (leagueID) {
      const x = await restService.get<CoachConflictInfo[]>(`${ourURL(leagueID)}/coachConflicts`)
      if (x.isSuccess) {
        return x.data
      }
      throw new VolunteerClientException('Problem with retrieval of the coach conflicts.')
    } else {
      return []
    }
  }
}
