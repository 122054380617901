import restService, { RestServiceResult } from '@/services/restService'
import { CFormatOrder, xStartupOrderExt, OrderLineRollupInfo } from '@/models/Order/xStartupOrderExt'
import { xLeagueOrderDetail, xStartupOrder } from '@/GeneratedTypes/xOrder/xStartupOrder'
import { xReturnOrderExt } from '@/models/Order/xReturnOrderExt'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import { ToXStartupOrder } from '@/lib/support/models/GeneratedTypes/xOrders/xStartupOrderExt'
import { UpwardVerificationDetails } from '@/models/UpwardVerificationDetails'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { getEmptyDisplayablePromoCardMedia, PromoCardMedia } from '@/models/PromoCards/PromoCardMedia'
import { ParticipantOrderProductInfo } from '@/GeneratedTypes/ListInfo/ParticipantOrderProductInfo'
import { OrderInfo } from '@/GeneratedTypes/Order/ListInfo/OrderInfo'
import { UpwardSizeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardSizeTypeID'
import { PlayerMissingSize } from '@/models/Order/PlayerMissingSize'
import { xShippingInfoBase } from '@/GeneratedTypes/xOrder/xShippingInfoBase'
import { OrderProductLine } from '@/models/Order/OrderProduct'
import { cloneDeep } from 'lodash'
import { OrderFindInfo } from '@/GeneratedTypes/Order/ListInfo/OrderFindInfo'
import { OrderNoteInfo } from '@/GeneratedTypes/Order/ListInfo/OrderNoteInfo'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'
import { PendingOrderAdditionalProductInfo } from '@/GeneratedTypes/ListInfo/PendingOrderAdditionalProductInfo'
import { PendingReturnOrderProduct } from '@/GeneratedTypes/PendingReturnOrderProduct'
import { PendingReturnOrderProductInfo } from '@/GeneratedTypes/ListInfo/PendingReturnOrderProductInfo'
import { OrderProduct } from '@/models/Order/OrderProduct'
import { ReturnOrderStatus } from '@/models/Order/ReturnOrderStatus'
import { OrderXMLEdit } from '@/models/Order/OrderXMLEdit'

const baseUrl = 'orders/'

class OrderClientException extends GeneralError {
  name = 'Order Client API Error'
}

const getOrderList = async (id: string) => {
  const results = await restService.get<xStartupOrder[]>(`${baseUrl}${id}`)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new OrderClientException('Error in ordersClient.getOrderList')
  }
}

const retrieveTemplate = async (id: string, orderType: OrderTypesEnum) => {
  const results = await restService.get<xStartupOrderExt>(`${baseUrl}${id}/new`, {
    upwardOrderTypeID: orderType.toUpperCase(),
  })
  if (results.isSuccess) {
    return results.data
  } else {
    throw new OrderClientException('Error in ordersClient.retrieveTemplate')
  }
}

const verificationDetails = async (id: string, order: xStartupOrderExt) => {
  const convertedOrder = ToXStartupOrder(order)
  const results = await restService.post<UpwardVerificationDetails>(
    `${baseUrl}${id}/verificationDetails`,
    convertedOrder
  )
  if (results.isSuccess) {
    return results.data?.model as xStartupOrder
  } else {
    throw new OrderClientException('Error in ordersClient.verificationDetails')
  }
}

const createOrder = async (id: string, order: xStartupOrderExt) => {
  const convertedOrder = ToXStartupOrder(order)
  const results = await restService.post<xStartupOrder>(`${baseUrl}${id}`, convertedOrder)
  if (results.isSuccess) {
    return results.data
  } else {
    throw new OrderClientException('Error in ordersClient.createOrder')
  }
}

const getPromoCards = async (LeagueID: UpwardLeagueIDType) => {
  const results = await restService.get<PromoCardMedia[]>('pdfmedia/promocard/' + LeagueID)
  if (results.isSuccess) {
    return results.data ?? []
  } else {
    throw new OrderClientException('pdf media collection request')
  }
}

const generateRMA = async (leagueID: UpwardLeagueIDType, account: string, note: string | null) => {
  const results = await restService.post<string>(`${baseUrl}${leagueID}/generateRMA`, {
    accountNumber: account,
    notes: note,
  })
  if (results.isSuccess) {
    return results.data ?? ''
  } else {
    throw new OrderClientException('generate RMA request')
  }
}

const getRMA = async ({ leagueID, id }: { leagueID: string; id: string }) => {
  const url = `orders/${leagueID}/retrieveRMA/${id}`
  const results = await restService.get<xReturnOrderExt>(url)
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException(`could not gather RMA information for ${leagueID} and ${id}`)
}

const setRMAStatus = async (upwardOrderID: string, status: ReturnOrderStatus) => {
  const url = `orders/returnsOrderStatus/${upwardOrderID}`
  const results = await restService.post(url, status)
  if (results.isSuccess) {
    return true
  }
  throw new OrderClientException(`could not set RMA status for ${upwardOrderID}`)
}

interface MediaInfo {
  defaultContent: string
  mediaShellUrl: string
  defaultRegistrationURL: string | null
}

/**
 * Pulls a promo card template information from the server and builds an
 * empty card.
 * @param LeagueID
 * @return card
 */
const getPromoCardTemplate = async (LeagueID: UpwardLeagueIDType) => {
  const card = getEmptyDisplayablePromoCardMedia()
  const text = await restService.get<MediaInfo>('pdfmedia/mediainfo/' + LeagueID, { escapeString: false })
  if (text.isSuccess) {
    card.upwardleagueID = LeagueID
    card.content = text.data?.defaultContent ?? ''
    card.shellUrl = text.data?.mediaShellUrl ?? ''
    card.defaultRegistrationURL = text.data?.defaultRegistrationURL ?? ''
    return card
  }
  throw new OrderClientException(`trouble getting promo card template for league ${LeagueID}`)
}

const getLeagueOrderItems = async (LeagueID: UpwardLeagueIDType) => {
  const t = await restService.get<ParticipantOrderProductInfo[]>(`${baseUrl}${LeagueID}/products`, {
    upwardOrderTypeID: 'LEAGUEORDER',
  })
  if (t.isSuccess) {
    return t.data
  }
  throw new OrderClientException('could not gather league items required by participant')
}

const getOrderHistory = async ({ league, account }: { league?: string; account?: string }) => {
  let url = 'orders/'
  if (league) {
    if (account) {
      url = `orders/${league}?accountNumber=${account}`
    } else {
      url = `orders/${league}`
    }
  } else if (account) {
    url = `orders/account/${account}`
  }
  const results = await restService.get<OrderInfo[]>(url)
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException('could not gather order information')
}

const releaseFromHold = async (upwardLeagueID: UpwardLeagueIDType, orderID: string, reason: string) => {
  const results = await restService.post<void>(`${baseUrl}${upwardLeagueID}/${orderID}/releaseFromHold`, {
    value: reason,
  })
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException('could not release order')
}

const resendToASB = async (upwardLeagueID: UpwardLeagueIDType, orderID: string) => {
  const results = await restService.post<void>(`${baseUrl}${upwardLeagueID}/${orderID}/augustaresend`, {})
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException('could not resend order to Augusta')
}

export interface OrderSearchParams {
  upwardOrderID?: string
  accountNumber?: string
  upwardLeagueID?: string
  typeOrderID?: string
  typeOrderStatusID?: string
  sort?: string
  sortDirection?: string
  pageSize?: number
  pageIndex?: number
}

const searchOrderHistory = async ({
  upwardOrderID,
  accountNumber,
  upwardLeagueID,
  typeOrderID,
  typeOrderStatusID,
  sort,
  sortDirection,
  pageSize,
  pageIndex,
}: OrderSearchParams) => {
  const url = 'orders/search'

  const results = await restService.post<OrderFindInfo[]>(url, {
    upwardOrderID,
    accountNumber,
    upwardLeagueID,
    typeOrderID,
    typeOrderStatusID,
    pageIndex: pageIndex || 1,
    pageSize: pageSize || 100,
    sort: sort || 'StatusDate',
    sortDirection: sortDirection || 'DESC',
  })
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException('could not gather order information')
}

const getOrder = async ({ leagueID, id }: { leagueID: string; id: string }) => {
  const url = `orders/${leagueID}/${id}`
  const results = await restService.get<CFormatOrder>(url, { format: 'c' })
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException(`could not gather order information for ${leagueID} and ${id}`)
}

const getOrderRollup = async ({ leagueID, id }: { leagueID: string; id: string }) => {
  const url = `orders/${leagueID}/${id}`
  const results = await restService.get<OrderLineRollupInfo[]>(url, { format: 'r' })
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException(`could not gather order information for ${leagueID} and ${id}`)
}

const getOrderInfo = async ({ leagueID, id }: { leagueID: string; id: string }) => {
  const url = `orders/${leagueID}/${id}`
  const results = await restService.get<OrderInfoExt>(url, { format: 'i' })
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException(`could not gather order information for ${leagueID} and ${id}`)
}

/**
 * Save ( or preview ) a promo card
 * @param card - card info
 * @param isPreview - don't save it, just preview
 * @return {PromoCardMedia} media information about the card.
 */
const savePromoCard = async (card: PromoCardMedia, isPreview: boolean) => {
  const newcard = await restService.post<PromoCardMedia>(
    `pdfmedia/promocard${isPreview ? '/preview' : ''}`,
    card
  )
  if (newcard.isSuccess) {
    return newcard.data
  }
  throw new OrderClientException('saving card failed')
}

/**
 * Remove  a promo card
 * @return nothing, but throws ane exception on error
 * @param id
 */
const deletePromoCard = async (id: number) => {
  const deadcard = await restService.delete<void>(`pdfmedia/promocard/${id}`)
  if (deadcard.isSuccess) {
    return deadcard.data
  } else throw new OrderClientException(`problem deleting card id ${id}`)
}
interface StatusResult {
  active: boolean
  sortOrder: number
  upwardTypeID: string
  description: string
}
let orderStati: { id: string; sortOrder: number; description: string }[] = []
const getOrderStati = async () => {
  if (!orderStati.length) {
    const si = await restService.get<StatusResult[]>('upwardTypes/orderStatus')
    if (si.isSuccess && si.data?.length) {
      orderStati = si.data
        .filter((x) => x.active == true)
        .map((x) => ({
          id: x.upwardTypeID ?? '',
          description: x.description,
          sortOrder: x.sortOrder ?? -100,
        }))
        .sort((a, b) => (a.sortOrder == b.sortOrder ? 0 : a.sortOrder > b.sortOrder ? 1 : -1))
    }
  }
  return orderStati
}

let sizes: UpwardSizeTypeID[] = []
const getSizes = async () => {
  if (!sizes.length) {
    const si = await restService.get<UpwardSizeTypeID[]>('upwardTypes/size')
    if (si.isSuccess && si.data?.length) {
      sizes = si.data.sort((a, b) => (a.sortOrder == b.sortOrder ? 0 : a.sortOrder > b.sortOrder ? 1 : -1))
    }
  }
  return sizes
}
//api/orderproducts/UPW80147/SOCCER?type=LEAGUEORDER
const getLeagueOrderStatus = async (
  league: UpwardLeagueIDType,
  program: string
): Promise<xLeagueOrderDetail> => {
  const si = await restService.get<xLeagueOrderDetail>(`orders/${league}/participantproducts/${program}`)
  if (si.isSuccess && si.data) {
    return si.data
  }

  throw new OrderClientException('Could not retrieve list of sizes for the order')
}

export interface OrderTypeInfo {
  active: boolean
  sortOrder: number
  id: number
  upwardTypeID: string
  description: string
}

let cacheValidOrderTypes: OrderTypeInfo[] = []
let cacheValidOrderStati: OrderTypeInfo[] = []

const getValidOrderTypes = async () => {
  if (!cacheValidOrderTypes.length) {
    cacheValidOrderTypes = (await restService.get<OrderTypeInfo[]>(`upwardTypes/order`)).data ?? []
  }
  return cacheValidOrderTypes
}

const getValidOrderStati = async () => {
  if (!cacheValidOrderStati.length) {
    cacheValidOrderStati = (await restService.get<OrderTypeInfo[]>(`upwardTypes/orderStatus`)).data ?? []
  }
  return cacheValidOrderStati
}

/**
 * Returns participants that are missing sizing info used in the first step of reconciling a league orders.
 * @param league
 * @param account
 */
const getPendingProduct = async (league: UpwardLeagueIDType, account: string) => {
  const si = await restService.get<PendingOrderAdditionalProductInfo[]>(
    `orders/pendingAdditionalProductList/${league}/${account}`
  )
  if (si.isSuccess) {
    return si.data ?? ([] as PendingOrderAdditionalProductInfo[])
  }

  throw new OrderClientException('Trouble pending products')
}

export enum SizeTypeEnum {
  PLAYER = 'PLAYER',
  VOLUNTEER = 'COACH',
}

/**
 * Returns participants that are missing sizing info used in the first step of reconciling a league orders.
 * @param league
 * @param program
 * @param type
 */
const getMissingSizes = async (league: UpwardLeagueIDType, program: string, type: SizeTypeEnum) => {
  const root = SizeTypeEnum.VOLUNTEER == type ? 'volunteers' : 'participants'
  const si = await restService.get<PlayerMissingSize[]>(`${root}/${league}/missingSizes/${program}`, {
    participantType: type,
  })
  if (si.isSuccess) {
    return si.data ?? []
  }

  throw new OrderClientException('Trouble getting missing sizes')
}
export interface ProductPayload {
  ProductID: number
  TypeColorID: string
  TypeSizeID: string
  Quantity: number
  DoNotOrder: boolean
}

export interface ValidateLeagueOrderShippingPayload {
  upwardLeagueID: UpwardLeagueIDType
  accountNumber: string
  notes: string
  purchaseOrderNumber: string
  shippingInfo: xShippingInfoBase
  processingInstructions: string[]
}

/**
 * confirm a league order
 * @param p
 */
const confirmOrder = async (p: ValidateLeagueOrderShippingPayload) => {
  const si = await restService.post<xStartupOrderExt>(`orders/${p.upwardLeagueID}`, {
    ...p,
    upwardOrderType: 'LEAGUEORDER',
  })

  if (si.isSuccess && si.data) {
    return si.data
  }

  throw new OrderClientException('Verification error on order')
}

/**
 * validate shipping for a league order, similiar to verificationDetails above, but using league specific payload.
 * @param p
 */
const validateOrderShipping = async (p: ValidateLeagueOrderShippingPayload) => {
  const si = await restService.post<{ brokenRules: string[]; model: xStartupOrderExt }>(
    `orders/${p.upwardLeagueID}/verificationDetails`,
    {
      ...p,
      upwardOrderType: 'LEAGUEORDER',
    }
  )

  if (si.isSuccess && si.data) {
    return si.data?.model
  }

  throw new OrderClientException('Verification error on order')
}

/**
 * sets size on volunteers/participants
 * @param who -player or volunteer
 * @param league
 * @param program - program name.
 * @param participantID - participant id.
 * @param products - products to update size on
 */
const setMissingSize = async (
  who: SizeTypeEnum,
  league: UpwardLeagueIDType,
  program: string,
  participantID: number,
  products: ProductPayload[]
) => {
  const service = who === SizeTypeEnum.PLAYER ? 'participants' : 'volunteers'
  const si = await restService.post<void>(
    `${service}/${league}/${participantID}/${program}/productsizes`,
    products
  )
  if (si.isSuccess) {
    return si.data
  }
  throw new OrderClientException('Trouble setting missing sizes')
}
/**
 * Removes a product on the server side using the product Index.
 * @param league
 * @param account
 * @param productIndexID
 */
const deleteProductByIndex = async (league: UpwardLeagueIDType, account: string, productIndexID: number) => {
  let thing: RestServiceResult<PendingOrderAdditionalProductInfo> | null = null

  thing = await restService.delete<PendingOrderAdditionalProductInfo>(
    `orders/pendingAdditionalProduct/${league}/${account}/${productIndexID}`
  )

  if (thing?.isSuccess) {
    return true
  }
  throw new OrderClientException('Trouble updating pending product quantity')
}
/**
 * Put product for a league order. Sets product quantity on the server for a league order.
 * @param league
 * @param account
 * @param product
 */
const putProduct = async (
  league: UpwardLeagueIDType,
  account: string,
  product: PendingOrderAdditionalProductInfo
) => {
  let thing: RestServiceResult<PendingOrderAdditionalProductInfo> | null = null
  if (product.id) {
    thing = await restService.put<PendingOrderAdditionalProductInfo>(
      `orders/pendingAdditionalProduct/${league}/${account}/${product.id}`,
      product
    )
  } else {
    thing = await restService.post<PendingOrderAdditionalProductInfo>(
      `orders/pendingAdditionalProduct`,
      product
    )
  }

  if (thing?.isSuccess && thing.data) {
    return thing.data
  }
  throw new OrderClientException('Trouble updating pending product quantity')
}

/**
 * Put product for a league order. Sets product quantity on the server for a league order.
 * @param league
 * @param account
 * @param product
 */
const newAddOnProduct = async (product: PendingOrderAdditionalProductInfo) => {
  const result = await restService.post<PendingOrderAdditionalProductInfo>(
    `orders/pendingAdditionalProduct`,
    product
  )

  if (result?.isSuccess && result.data) {
    return result.data
  }
  throw new OrderClientException('Trouble updating pending product quantity')
}

const updateAddOnProduct = async (upwId: string, product: PendingOrderAdditionalProductInfo) => {
  const result = await restService.put<PendingOrderAdditionalProductInfo>(
    `orders/pendingAdditionalProduct/${upwId}/${product.accountNumber}/${product.id}`,
    product
  )

  if (result?.isSuccess && result.data) {
    return result.data
  }
  throw new OrderClientException('Trouble updating pending product quantity')
}

/**
 * This is the find product logic for two scenarios.
 * a) you pass an individual ordering a product in which case the size needs to be replaced.
 * b) you pass a color/size combo that isn't attached to a person, so you want that quantity updated.
 * @param league
 * @param account
 * @param id
 * @param typeColorID
 * @param individualID
 * @param typeSizeID
 * @return matching product on the server.
 */
async function findProduct(
  league: string,
  account: string,
  id: number,
  typeColorID: string,
  individualID: number,
  typeSizeID: string
) {
  const products = await getPendingProduct(league, account)
  const p = products.find((x) =>
    individualID
      ? x.productID == id && x.typeColorID == typeColorID && x.individualID == individualID
      : x.productID == id && x.typeColorID == typeColorID && x.typeSizeID == typeSizeID
  )
  if (p && !individualID) p.individualID = 0
  return p
}

/**
 * For a league order, will sift through existing pending products and update the one passed to
 * it from the UI.
 *
 * If product.orderQuantity is zero, it will delete the pending product.
 *
 *
 * Also, it will add a product it can't find, for non-zero quantities.
 *
 *
 *
 * @param league
 * @param leagueID
 * @param account
 * @param product
 */
const updateProductQuantity = async (
  league: UpwardLeagueIDType,
  leagueID: number,
  account: string,
  product: OrderProductLine
) => {
  const existingProduct = await findProduct(
    league,
    account,
    product.id,
    product.productColorSize.typeColorID,
    product.individualID,
    product.productColorSize.typeSizeID
  )

  if (existingProduct) {
    //remove an existing product
    if (product.orderQuantity == 0) {
      await deleteProductByIndex(league, account, existingProduct.id)
    } //if not deleting then update the character
    else {
      const newProduct = cloneDeep({
        ...existingProduct,
        quantity: product.orderQuantity,
        typeSizeID: product.productColorSize.typeSizeID,
      })
      await putProduct(league, account, newProduct)
    }
  } //got to be a new product with an order-able quantity.
  else {
    if (product.orderQuantity) {
      const newProduct = {
        quantity: product.orderQuantity,
        typeColorID: product.productColorSize.typeColorID,
        accountNumber: account.toString(),
        id: 0,
        individualID: product.individualID,
        leagueID: leagueID,
        productID: product.id,
        typeProgramID: product.typeProgramID,
        typeSizeID: product.productColorSize.typeSizeID,
        jerseyNumber: product.jerseyNumber,
      } as PendingOrderAdditionalProductInfo
      await putProduct(league, account, newProduct)
    }
  }
}

/**
 * Order ID is a number not the league-dash-number
 * @param orderID
 */
const getNotes = async (orderID: number) => {
  const thing = await restService.get<OrderNoteInfo[]>(`orders/notes/${orderID}`)

  if (thing?.isSuccess) {
    return thing.data
  }
  throw new OrderClientException('Trouble getting order notes')
}

const getNotesByUpwardOrderID = async (upwardOrderID: string) => {
  const thing = await restService.get<OrderNoteInfo[]>(`orders/notes/upwardOrderID/${upwardOrderID}`)

  if (thing?.isSuccess) {
    return thing.data
  }
  throw new OrderClientException('Trouble getting order notes')
}

/**
 * Order ID is a number not the league-dash-number
 * @param orderID
 */
const putNote = async (orderID: number, note: OrderNoteInfo) => {
  const rv = await restService.post<void>(`orders/notes/${orderID}`, note)

  if (rv?.isSuccess) {
    return true
  }
  throw new OrderClientException('Trouble updating order note')
}

/**
 * Order ID is a number not the league-dash-number
 * @param orderID
 */
const deleteNote = async (orderID: number, noteID: number) => {
  const rv = await restService.delete<void>(`orders/notes/${orderID}/${noteID}`)

  if (rv?.isSuccess) {
    return true
  }
  throw new OrderClientException('Trouble deleting order note')
}

const getPendingReturnProductList = async (league: UpwardLeagueIDType, account: string) => {
  const si = await restService.get<PendingReturnOrderProductInfo[]>(
    `orders/pendingReturnOrderProductList/${league}/${account}`
  )
  if (si.isSuccess) {
    return si.data ?? ([] as PendingReturnOrderProductInfo[])
  }

  throw new OrderClientException('Trouble pending return products')
}

const deletePendingReturnProductByIndex = async (
  league: UpwardLeagueIDType,
  account: string,
  productIndexID: number
) => {
  let thing: RestServiceResult<PendingReturnOrderProduct> | null = null

  thing = await restService.delete<PendingReturnOrderProduct>(
    `orders/pendingReturnOrderProduct/${league}/${account}/${productIndexID}`
  )

  if (thing?.isSuccess) {
    return true
  }
  throw new OrderClientException('Trouble deleting pending return product')
}

const updatePendingReturnProduct = async (upwId: string, product: PendingReturnOrderProduct) => {
  const result = await restService.put<PendingReturnOrderProduct>(
    `orders/pendingReturnOrderProduct/${upwId}/${product.accountNumber}/${product.id}`,
    product
  )

  if (result?.isSuccess && result.data) {
    return result.data
  }
  throw new OrderClientException('Trouble updating pending return product')
}

const newPendingReturnProduct = async (product: PendingReturnOrderProduct) => {
  const result = await restService.post<PendingReturnOrderProduct>(
    `orders/pendingReturnOrderProduct`,
    product
  )

  if (result?.isSuccess && result.data) {
    return result.data
  }
  throw new OrderClientException('Trouble creating pending return product')
}

const getReturnsOrderProductList = async (league: UpwardLeagueIDType, account: string) => {
  const si = await restService.get<OrderProduct[]>(`orders/returnsOrderProductList/${league}/${account}`)
  if (si.isSuccess) {
    return si.data ?? ([] as OrderProduct[])
  }

  throw new OrderClientException('Trouble pending return products')
}

async function findPendingReturnProduct(
  league: string,
  account: string,
  typeProgramID: string,
  productID: number,
  typeColorID: string,
  typeSizeID: string,
  typeReturnReasonID: string
) {
  const products = await getPendingReturnProductList(league, account)
  const p = products.find(
    (x) =>
      x.typeProgramID == typeProgramID &&
      x.productID == productID &&
      x.typeColorID == typeColorID &&
      x.typeSizeID == typeSizeID &&
      x.typeReturnReasonID == typeReturnReasonID
  )
  return p
}

const addOrUpdate_PendingReturnProduct = async (
  upw: UpwardLeagueIDType,
  product: PendingReturnOrderProduct
) => {
  const existingProduct = await findPendingReturnProduct(
    upw,
    product.accountNumber,
    product.typeProgramID,
    product.productID,
    product.typeColorID,
    product.typeSizeID,
    product.typeReturnReasonID
  )

  if (existingProduct) {
    let newCargo = existingProduct.cargo
    if (newCargo && newCargo.length > 0) {
      newCargo += ', '
    }

    newCargo += product.cargo ?? ''

    const newProduct = {
      leagueID: existingProduct.leagueID,
      accountNumber: existingProduct.accountNumber,
      id: existingProduct.id,
      typeProgramID: existingProduct.typeProgramID,
      productID: existingProduct.productID,
      typeColorID: existingProduct.typeColorID,
      typeSizeID: existingProduct.typeSizeID,
      quantity: existingProduct.quantity + product.quantity,
      typeReturnReasonID: existingProduct.typeReturnReasonID,
      cargo: newCargo,
    } as PendingReturnOrderProduct

    await updatePendingReturnProduct(upw, newProduct)
  } //got to be a new product with an order-able quantity.
  else {
    await newPendingReturnProduct(product)
  }
}

const getRawOrderXML = async (upwardOrderid: string) => {
  const url = `orders/${upwardOrderid}/rawOrderXML`
  const results = await restService.get<string>(url)
  if (results.isSuccess) {
    return results.data
  }
  throw new OrderClientException(`could not get Order information for ${upwardOrderid}`)
}

const editOrderXML = async (xml: OrderXMLEdit) => {
  const result = await restService.post<void>(`orders/${xml.upwardOrderID}/editOrderXML`, xml)

  if (result?.isSuccess) {
    return true
  }
  throw new OrderClientException('Trouble updating order xml')
}

export default {
  getNotes,
  getNotesByUpwardOrderID,
  deleteNote,
  putNote,
  retrieveTemplate,
  updateProductQuantity,
  getLeagueOrderItems,
  verificationDetails,
  createOrder,
  getOrderInfo,
  releaseFromHold,
  getOrderList,
  getPromoCards,
  deletePromoCard,
  savePromoCard,
  getPromoCardTemplate,
  getOrderHistory,
  getOrder,
  searchOrderHistory,
  getOrderStati,
  getPendingProduct,
  getMissingSizes,
  getSizes,
  getLeagueOrderStatus,
  setMissingSize,
  validateOrderShipping,
  confirmOrder,
  generateRMA,
  getRMA,
  setRMAStatus,
  getValidOrderTypes,
  getValidOrderStati,
  getOrderRollup,
  updateAddOnProduct,
  newAddOnProduct,
  deleteProductByIndex,
  getPendingReturnProductList,
  deletePendingReturnProductByIndex,
  updatePendingReturnProduct,
  newPendingReturnProduct,
  getReturnsOrderProductList,
  addOrUpdate_PendingReturnProduct,
  getRawOrderXML,
  editOrderXML,
  resendToASB,
}
