












import { defineComponent, ref, computed } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import ModalMultiSelect from '@/components/ModalMultiSelect.vue'
import { days as upwardDays } from '../../../../lib/support/models/UpwardTypes/UpdwardDayTypeID/days'

export default defineComponent({
  name: 'AvailableGameDays',
  components: {
    ModalMultiSelect,
    Modal,
  },
  setup(props, ctx) {
    const currentDays = ref<string[]>([])
    const days = ref(upwardDays)

    const list = computed(() => days.value.map((x) => ({ id: x.name, description: x.name })))

    const disableds = ref<string[]>([])

    const selected = computed(() => currentDays.value)

    const modalIsVisible = ref(false)

    function toggle(myCurrentDays: string[]) {
      currentDays.value = myCurrentDays
      modalIsVisible.value = !modalIsVisible.value
    }

    function save(selected: string[]) {
      ctx.emit('gameDaysUpdated', selected)
    }

    return {
      modalIsVisible,
      list,
      disableds,
      selected,
      save,
      toggle,
    }
  },
})
