import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import divisionsClient from '@/clients/divisionsClient'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { ActionsImpl } from 'direct-vuex'

interface DivisionsState {
  items: { [id: string]: LeagueDivisionInfo[] }
  selectedDivision: LeagueDivisionInfo | null
}

const divisionsState: DivisionsState = {
  items: {},
  selectedDivision: null,
}

export enum getterNames {
  allItems = 'allItems',
  currentDivision = 'currentDivision',
}

const getterTree: GetterTree<DivisionsState, RootState> = {
  [getterNames.allItems]: (state) => (leagueUpwardTypeId: string) => {
    return state.items[leagueUpwardTypeId] || []
  },
  [getterNames.currentDivision]: (state) => state.selectedDivision,
}

export enum mutationNames {
  setAll = 'setAll',
  setDivision = 'setDivision',
  clearSelectedDivision = 'clearSelectedDivision',
}

const mutations: MutationTree<DivisionsState> = {
  [mutationNames.setAll](state, { items, leagueUpwardTypeId }) {
    Vue.set(state.items, leagueUpwardTypeId, items)

    // update selected division to keep it current with any changes
    if (state.selectedDivision) {
      const currentDivision = state.items[leagueUpwardTypeId].find(
        (d) => d.divisionID == state.selectedDivision?.divisionID
      )
      if (currentDivision) state.selectedDivision = currentDivision
    }
  },
  [mutationNames.setDivision](state, { division }: { division: LeagueDivisionInfo | null }) {
    state.selectedDivision = division
  },
  [mutationNames.clearSelectedDivision](state) {
    state.selectedDivision = null
  },
}

export enum actionNames {
  fetchAll = 'fetchAll',
}

const actions: ActionTree<DivisionsState, RootState> & ActionsImpl = {
  /***
   * Pulls in the leagues for a given league id (e.g. "UPW80000")
   * @param commit
   * @param getters
   * @param upwardLeagueId
   * @param force
   */
  async [actionNames.fetchAll](
    { commit, getters },
    { upwardLeagueId, force }: { upwardLeagueId: UpwardLeagueIDType; force: boolean }
  ): Promise<boolean> {
    const itemList = getters[getterNames.allItems](upwardLeagueId)
    if (itemList && itemList.length && !force) {
      return false
    }

    const result = await divisionsClient.retrieveAll(upwardLeagueId)

    if (result) {
      commit(mutationNames.setAll, { leagueUpwardTypeId: upwardLeagueId, items: result })
      return true
    }

    return false
  },
}

export const namespace = 'divisions'

export const divisions = {
  namespaced: true as true,
  state: divisionsState,
  getters: getterTree,
  actions,
  mutations,
}
