














































import { RegistrationTransactionParticipantExtended } from '@/models/Registration/RegistrationTransactionParticipantExt'
import { PropType, defineComponent, ref, watch, computed } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import TextInput from '@/elements/TextInput.vue'
import Modal from '@/components/Modal.vue'
import { CompleteRegistrationTransactionType } from '@/models/Registration/CompleteRegistrationTransactionType'
import { CreditCardTypeEnum } from '@/models/Registration/CreditCardTypeEnum'

export default defineComponent({
  components: {
    TextInput,
    SelectInput,
    Modal,
  },
  props: {
    registration: {
      type: Object as PropType<RegistrationTransactionParticipantExtended>,
    },
    showing: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, ctx) {
    const ccList = [
      { id: CreditCardTypeEnum.VISA, desc: 'Visa' },
      { id: CreditCardTypeEnum.MASTERCARD, desc: 'Master Card' },
      { id: CreditCardTypeEnum.DISCOVER, desc: 'Discover' },
      { id: CreditCardTypeEnum.AMEX, desc: 'American Express' },
    ]
    const internalShowing = ref(false)
    watch(
      () => props.showing,
      () => (internalShowing.value = props.showing),
      { immediate: true }
    )

    const areWeReadyToConfirm = computed(() => !!ccType.value && !!ccAuthorization.value && !!ccLast4.value)
    function confirmed() {
      ctx.emit('confirm', {
        transaction: props.registration,
        type: ccType.value,
        authorization: ccAuthorization.value,
        last4: ccLast4.value,
      } as CompleteRegistrationTransactionType)
    }
    function cancel() {
      ctx.emit('cancel')
      internalShowing.value = false
    }
    const ccType = ref('')
    const ccAuthorization = ref('')
    const ccLast4 = ref('')
    return {
      ccList,
      ccType,
      ccLast4,
      ccAuthorization,
      confirmed,
      internalShowing,
      areWeReadyToConfirm,
      cancel,
    }
  },
})
