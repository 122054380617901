import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import { NotificationInfo } from '@/models/Notification/NotificationInfo'
import alertClient from '@/clients/alertClient'
import { cloneDeep } from 'lodash'
interface AlertState {
  notifications: NotificationInfo[]
}

const alertState: AlertState = {
  notifications: [] as NotificationInfo[],
}

export enum getterNames {
  notifications = 'notifications',
  unreadNotificationCount = 'unreadNotificationCount',
}

const getterTree = defineGetters<AlertState>()({
  notifications: (state) => {
    return state.notifications
  },
  unreadNotificationCount: (state) => {
    return state.notifications.filter((n) => !n.userHasRead).length
  },
})

export enum mutationNames {
  setNotifications = 'setNotifications',
}

const mutations = defineMutations<AlertState>()({
  setNotifications(state, { notifications }: { notifications: NotificationInfo[] }) {
    state.notifications = notifications
  },
})

export enum actionNames {
  fetchNotifications = 'fetchNotifications',
}

const actions = defineActions({
  async fetchNotifications({ commit }) {
    const notifications = await alertClient.retrieveMyNotifications()
    commit(mutationNames.setNotifications, { notifications: notifications ?? ([] as NotificationInfo[]) })
  },
  async markMyNotificationsRead({ commit, getters }): Promise<Boolean> {
    const n = cloneDeep(getters[getterNames.notifications] as NotificationInfo[])
    const calls: Array<Promise<Boolean>> = []
    n.forEach((n) => {
      calls.push(alertClient.markNotificationRead(n.id))
      n.userHasRead = true
    })
    const results = await Promise.all(calls)
    const success = results.some((r) => !r)
    if (success) {
      commit(mutationNames.setNotifications, { notifications: n })
    }
    return success
  },
})

export const namespace = 'alerts'

export const alerts = {
  namespaced: true as true,
  state: alertState,
  getters: getterTree,
  actions,
  mutations,
}
