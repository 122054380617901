import restService from '@/services/restService'
import { Resource } from '@/models/Program/Resource'
import { LegalResource } from '@/models/Legal/LegalResource'
import { PageContentDocument } from '@/models/PageContent/PageContentDocument'
import { PartnerSupportPage } from '@/models/PageContent/PartnerSupportPage'
import { BannerContent } from '@/models/PageContent/BannerContent'
import { DevotionTrackInfo } from '@/models/ContentManagement/DevotionTrack'

const baseUrl = 'ContentManagement/'

const getDevotionTracks = async (programType: string, typeProductGroupID: string) => {
  return await restService.get<DevotionTrackInfo>(`${baseUrl}devotiontracks`, {
    programType,
    typeProductGroupID,
  })
}

const getVolunteerResources = async (upw: string, typeProgramID: string, role: string) => {
  return await restService.get<Resource[]>(`${baseUrl}volunteerresources/${upw}`, {
    typeProgramID,
    role,
  })
}

const getMarketingResources = async (programType: string, productYear: string, role: string) => {
  return await restService.get<Resource[]>(`${baseUrl}volunteerresources/marketing`, {
    programType,
    productYear,
    role,
  })
}

const getLegalResource = async (documentID: string, version: number) => {
  return await restService.get<LegalResource>(`${baseUrl}legalresource/${documentID}`, {
    version,
  })
}

const getPageContentDocument = async (documentID: string) => {
  const result = await restService.get<PageContentDocument>(`${baseUrl}pagecontent/${documentID}`)
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('Error in contentManagementClient.getPageContentDocument')
  }
}

const getPartnerSupportRep = async (state: string) => {
  const result = await restService.get<PartnerSupportPage>(`${baseUrl}partnersupportrep/?state=${state}`)
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('Error in contentManagementClient.getPartnerSupportRep')
  }
}

const getGatewayBanners = async () => {
  const result = await restService.get<BannerContent[]>(`${baseUrl}gatewaybanners`)
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('Error in contentManagementClient.getPartnerSupportRep')
  }
}

const deleteProductCache = async (orderType: string) => {
  const result = await restService.delete(`${baseUrl}productcatalog/cachedelete?productType=` + orderType)
  if (result.isSuccess) {
    return result.data
  } else {
    throw new Error('Error in contentManagementClient.deleteProductCache')
  }
}

export default {
  getDevotionTracks,
  getVolunteerResources,
  getLegalResource,
  getPageContentDocument,
  getPartnerSupportRep,
  getGatewayBanners,
  getMarketingResources,
  deleteProductCache,
}
