































import { defineComponent, PropType, ref } from '@vue/composition-api'
import { OrderNoteInfo } from '@/GeneratedTypes/Order/ListInfo/OrderNoteInfo'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default defineComponent({
  name: 'NoteItem',
  components: { ConfirmationModal },
  props: {
    note: { type: Object as PropType<OrderNoteInfo>, required: true },
  },
  setup(props, ctx) {
    const isConfirming = ref(false)
    function remove() {
      isConfirming.value = true
    }

    function response(b: boolean) {
      isConfirming.value = false
      if (b) {
        ctx.emit('remove-note', props.note)
      }
    }

    return { response, remove, isConfirming }
  },
})
