















import { defineComponent, computed } from '@vue/composition-api'
import store from '@/store'
import TheAuthWall from '@/singletons/TheAuthWall.vue'
import TheErrorRenderer from '@/singletons/TheErrorRenderer.vue'

export default defineComponent({
  name: 'CoachApp',
  components: { TheErrorRenderer, TheAuthWall },
  setup(props, ctx) {
    const isAuthenticated = computed(() => store.getters.authorization.isAuthenticated)
    const codeReloadRequired = computed(() => store.getters.authorization.codeReloadRequired)
    const leagueRoles = computed(() => store.getters.authorization.leagueRoles)
    const showReport = computed(
      () =>
        (leagueRoles.value &&
          leagueRoles.value.length > 0 &&
          leagueRoles.value.some((lr) => lr.UpwardLeagueID == ctx.root.$route.params.id)) ||
        !leagueRoles.value ||
        leagueRoles.value.length == 0
    )

    return { showReport, isAuthenticated, codeReloadRequired }
  },
})
