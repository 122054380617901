













































































import { cloneDeep } from 'lodash'
import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import SetupCompleteConfirmation from '@/components/SetupCompleteConfirmation.vue'
import LeagueDevotionTrackSelection from '@/components/LeagueDevotionTrackSelection.vue'
import SetupStepperLeague from '@/components/ProductFlow/ProductSetupStepper/SetupStepperLeague.vue'
import ProgramRegistrationFee from '@/components/ProgramRegistrationFee.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'
import ProductWarningModal from '@/views/Leagues/ProductWarningModal.vue'
import CurrencyWarning from '@/components/ProductFlow/ProductSetupStepper/CurrencyWarning.vue'
import Loading from '@/elements/Loading.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ValidationError } from 'src/models/UpwardVerificationDetails'

import * as leaguesStore from '@/store/leagues'
import * as leagueAbstraction from '@/store/leagueAbstraction'
import * as promptToSave from '@/store/promptToSave'
import store from '@/store'

import leaguesClient from '@/clients/leaguesClient'

import { Action, Getter } from 'vuex-class'
import { League } from '@/GeneratedTypes/League'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

@Component({
  components: {
    SetupCompleteConfirmation,
    LeagueDevotionTrackSelection,
    SetupStepperLeague,
    ProgramRegistrationFee,
    SaveContinueButton,
    ProductWarningModal,
    CurrencyWarning,
    Loading,
  },
})
export default class Products extends Mixins(LeagueSetupMixin) {
  @Action(leaguesStore.actionNames.create, { namespace: leaguesStore.namespace })
  private readonly create!: ({ item }: { item: League }) => Promise<League | null>

  @Getter(promptToSave.getterNames.isDirty, { namespace: promptToSave.namespace })
  private readonly isDirty!: boolean

  @Getter(leagueAbstraction.getterNames.currentItem, {
    namespace: leagueAbstraction.leagueAbstractionNamespace,
  })
  private readonly currentAbstractionItem!: boolean

  private modalIsVisible = false
  private userWantsToIgnoreWarnings = false
  private warnings: ValidationError[] | null = null
  private showWarnings = false

  logic = productFlowLogic()

  async mounted() {
    if (this.league.isOPRLeague) {
      if (this.league.upwardLeagueID) {
        this.nextRoute = `/programs/league/${this.league.upwardLeagueID}/setup/registration`
      } else {
        this.nextRoute = '/programs/league/setup/registration'
      }
    } else {
      if (this.league.upwardLeagueID) {
        this.nextRoute = '/'
      }
      this.afterSaveNew = this.afterSaveNewMethod
    }
    this.beforeSave = this.productBeforeSave
    await store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //go ahead and get these cached up
  }

  get initialProductFlowLoadingComplete() {
    return store.getters.productOfferings.initialProductFlowLoadingComplete
  }

  get showCurrencyWarning() {
    if (store.getters.partners.currencyWarningNeeded && this.logic.isNewProdConfig.value) {
      return !store.getters.partners.hasAcceptedCurrencyWarning
    }
    return false
  }

  get isLoading() {
    if (this.logic.isNewProdConfig.value) {
      return this.loading
    } else {
      return !this.initialProductFlowLoadingComplete
    }
  }

  private get hasEarlyRegistration() {
    const isDate = this.league.lateDate instanceof Date && !isNaN(this.league.lateDate.valueOf())

    if (isDate) {
      const date = this.league.lateDate as Date
      return date !== null
    }

    return this.league.lateDate !== null
  }

  private newId = ''

  get selectedProductOffering() {
    return store.getters.productOfferings.selectedProductOfferingConfig
  }

  private async productBeforeSave() {
    this.league.productOfferingConfig = this.selectedProductOffering
    this.league.productOfferingConfig.accountNumber = null

    /*****************************************************************
     ** If this is a new league, or the user wants to ignore warnings
     ** skip validation and return true
     *****************************************************************/

    if (this.userWantsToIgnoreWarnings || this.logic.isNewProdConfig.value || !this.league.upwardLeagueID) {
      this.userWantsToIgnoreWarnings = false
      return true
    }

    //Validation only used for warnings. Broken rules handled in the update call
    const result = await leaguesClient.validateExisting(
      this.league.upwardLeagueID ?? '',
      cloneDeep(this.league)
    )
    this.warnings = result.data?.warnings ?? null
    return result && result.data ? result.data?.warnings.length < 1 : true
  }

  private async afterSaveNewMethod() {
    const savedLeague = await this.create({ item: this.league })
    if (savedLeague) {
      this.newId = savedLeague.upwardLeagueID!
    }
    this.modalIsVisible = true
  }

  /*
   * Calculate saveDisabled
   */

  get maxTouchedParticipantKit1Step() {
    return store.getters.productFlow.maxTouchedParticipantKit1Step
  }
  get maxTouchedParticipantKit2Step() {
    return store.getters.productFlow.maxTouchedParticipantKit2Step
  }
  get saveDisabled() {
    // SPORT
    let sportCanSave = true
    const sportCatalog = store.getters.productOfferings.displayableProductCatalogs.filter(
      (c) => !c.isCheer
    )[0]
    if (sportCatalog && sportCatalog.categories) {
      sportCanSave = this.maxTouchedParticipantKit1Step >= sportCatalog.categories.length + 1
    }

    // CHEER
    let cheerCanSave = true
    if (store.getters.leagueAbstraction.hasCheer) {
      const cheerCatalog = store.getters.productOfferings.displayableProductCatalogs.filter(
        (c) => c.isCheer
      )[0]
      if (cheerCatalog && cheerCatalog.categories) {
        cheerCanSave = this.maxTouchedParticipantKit2Step >= cheerCatalog.categories?.length + 1
      }
    }

    return !sportCanSave || !cheerCanSave
  }

  private onModalStateChange() {
    this.$router.push(`/programs/league/${this.storeLeague.upwardLeagueID}/setup/information`)
  }

  ignoreWarnings() {
    this.userWantsToIgnoreWarnings = true
    this.saveAndContinue()
  }

  @Watch('isDirty')
  dirtyChanged() {
    if (!this.isDirty) this.userWantsToIgnoreWarnings = false
  }
  @Watch('warnings')
  warningsChanged() {
    this.showWarnings = this.warnings ? this.warnings.length > 0 : false
  }
}
