import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { UpwardOpportunityTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardOpportunityTypeID'
import upwardTypesClient from '@/clients/upwardTypesClient'
import { ActionsImpl } from 'direct-vuex'

interface OpportunityTypesState {
  items: UpwardOpportunityTypeID[]
}

const opportunityTypesState: OpportunityTypesState = {
  items: [],
}

export enum getterNames {
  allItems = 'allItems',
}

const getterTree: GetterTree<OpportunityTypesState, RootState> = {
  [getterNames.allItems]: (state) => state.items,
}

export enum mutationNames {
  setAll = 'setAll',
}

const mutations: MutationTree<OpportunityTypesState> = {
  [mutationNames.setAll](state, { items }) {
    state.items = items
  },
}

export enum actionNames {
  fetchAll = 'fetchAll',
}

const actions: ActionTree<OpportunityTypesState, RootState> & ActionsImpl = {
  async [actionNames.fetchAll]({ commit, getters }, force = true): Promise<boolean> {
    if (getters[getterNames.allItems].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrieveOpportunityTypes()

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setAll, { items })
      return true
    }

    return false
  },
}

export const namespace = 'opportunityTypes'

export const opportunityTypes = {
  namespaced: true as true,
  state: opportunityTypesState,
  getters: getterTree,
  actions,
  mutations,
}
