
























































































































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import IntegerInput from '@/elements/IntegerInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'

import PreferredContactDaySelect from '@/components/PreferredContactDaySelect.vue'
import PreferredContactMethodSelect from '@/components/PreferredContactMethodSelect.vue'
import PreferredContactTimeSelect from '@/components/PreferredContactTimeSelect.vue'
import PartnerRoleTypeSelect from '@/components/PartnerRoleTypeSelect.vue'

import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import { Contact } from '@/GeneratedTypes/Partner/Contact'
import { cleanPhoneNumber } from '@/services/phoneNumberService'
import emailServiceClient from '@/clients/emailServiceClient'
import { IndividualEmailCheck } from '@/models/IndividualEmailCheck'
export default defineComponent({
  name: 'ContactForm',
  components: {
    TextInput,
    PhoneInput,
    IntegerInput,
    YesNoInput,
    PreferredContactTimeSelect,
    PreferredContactMethodSelect,
    PreferredContactDaySelect,
    PartnerRoleTypeSelect,
  },
  props: {
    value: { type: Object as PropType<Contact | null>, required: true },
    canChangeName: { type: Boolean, required: false, default: true },
  },
  setup(props, ctx) {
    const internalContact = ref<Contact>(getEmptyContact())
    const primaryPhoneNumber = ref<IndividualPhone>(createIndividualPhoneNumber('', ''))
    const secondaryPhoneNumber = ref<IndividualPhone | null>(null)
    const alternatePhoneNumber = ref<IndividualPhone | null>(null)
    const confirmEmail = ref('')

    watch(
      () => internalContact.value,
      () => {
        if (JSON.stringify(props.value) !== JSON.stringify(internalContact.value)) {
          ctx.emit('input', { ...internalContact.value })
        }
      },
      { deep: true }
    )

    watch(
      () => props.value,
      () => {
        if (JSON.stringify(props.value) === JSON.stringify(internalContact.value)) {
          return
        }

        internalContact.value = props.value ? { ...props.value } : getEmptyContact()

        internalContact.value.primaryPhoneTypeID = internalContact.value.primaryPhoneTypeID ?? ''
        internalContact.value.secondaryPhoneTypeID = internalContact.value.secondaryPhoneTypeID ?? ''
        internalContact.value.alternatePhoneTypeID = internalContact.value.alternatePhoneTypeID ?? ''

        if (internalContact.value.emailAddress) {
          confirmEmail.value = internalContact.value.emailAddress
        } else {
          confirmEmail.value = ''
        }

        if (internalContact.value.primaryPhoneNumber) {
          primaryPhoneNumber.value = createIndividualPhoneNumber(
            internalContact.value.primaryPhoneNumber,
            internalContact.value.primaryPhoneTypeID
          )
        } else {
          primaryPhoneNumber.value = createIndividualPhoneNumber('', '')
        }

        if (internalContact.value.secondaryPhoneNumber) {
          secondaryPhoneNumber.value = createIndividualPhoneNumber(
            internalContact.value.secondaryPhoneNumber,
            internalContact.value.secondaryPhoneTypeID ?? ''
          )
        } else {
          secondaryPhoneNumber.value = null
        }

        if (internalContact.value.alternatePhoneNumber) {
          alternatePhoneNumber.value = createIndividualPhoneNumber(
            internalContact.value.alternatePhoneNumber,
            internalContact.value.alternatePhoneTypeID ?? ''
          )
        } else {
          alternatePhoneNumber.value = null
        }
      },
      { immediate: true, deep: true }
    )

    watch(
      () => primaryPhoneNumber.value,
      () => {
        if (primaryPhoneNumber.value && primaryPhoneNumber.value.phoneNumber) {
          internalContact.value.primaryPhoneNumber = `${primaryPhoneNumber.value.phoneNumber}${primaryPhoneNumber.value.extension}`
          internalContact.value.primaryPhoneTypeID = primaryPhoneNumber.value.typePhoneID ?? ''
          ctx.emit('input', { ...internalContact.value })
        }
      }
    )

    watch(
      () => secondaryPhoneNumber.value,
      () => {
        if (secondaryPhoneNumber.value) {
          internalContact.value.secondaryPhoneNumber = `${secondaryPhoneNumber.value.phoneNumber}${secondaryPhoneNumber.value.extension}`
          internalContact.value.secondaryPhoneTypeID = secondaryPhoneNumber.value.typePhoneID ?? ''
          ctx.emit('input', { ...internalContact.value })
        }
      }
    )

    watch(
      () => alternatePhoneNumber.value,
      () => {
        if (alternatePhoneNumber.value) {
          internalContact.value.alternatePhoneNumber = `${alternatePhoneNumber.value.phoneNumber}${alternatePhoneNumber.value.extension}`
          internalContact.value.alternatePhoneTypeID = alternatePhoneNumber.value.typePhoneID ?? ''
          ctx.emit('input', { ...internalContact.value })
        }
      }
    )

    function createIndividualPhoneNumber(phoneNumber: string, phoneTypeId: string): IndividualPhone {
      let number = cleanPhoneNumber(phoneNumber)
      let extension = ''

      if (number && number.length > 10) {
        extension = number.substring(10)
        number = number.substring(0, 10)
      }

      return {
        extension,
        phoneNumber: number ?? '',
        phoneID: 0,
        typePhoneID: phoneTypeId ?? '',
        priority: 0,
        allowSMS: true,
      }
    }

    const hasEmailMismatch = computed(() => {
      return (
        (confirmEmail.value || internalContact.value.emailAddress) &&
        confirmEmail.value !== internalContact.value.emailAddress
      )
    })

    async function onEmailConfirm() {
      try {
        //run email address through auto-correction
        if (internalContact.value.emailAddress && confirmEmail.value) {
          const correctedEmail = await emailServiceClient.getCorrectedEmail({
            email: internalContact.value.emailAddress,
          } as IndividualEmailCheck)

          if (correctedEmail) {
            internalContact.value.emailAddress = correctedEmail
            confirmEmail.value = correctedEmail
            return
          }
        }
      } catch {
        // Ignore these errors since this is more of a convenience feature and not required to work
        // to register successfully
      }
    }

    function addPhoneNumber(phoneType: string) {
      if (phoneType === 'secondary') {
        secondaryPhoneNumber.value = createIndividualPhoneNumber('', '')
      }

      if (phoneType === 'alternate') {
        alternatePhoneNumber.value = createIndividualPhoneNumber('', '')
      }
    }

    function removePhoneNumber(phoneType: string) {
      if (phoneType === 'secondary') {
        secondaryPhoneNumber.value = null
        if (alternatePhoneNumber.value) {
          secondaryPhoneNumber.value = alternatePhoneNumber.value
          alternatePhoneNumber.value = null
        }
      }

      if (phoneType === 'alternate') {
        alternatePhoneNumber.value = null
      }
    }

    function getEmptyContact(): Contact {
      const contact: Contact = {
        firstName: '',
        lastName: '',
        emailAddress: null,
        emailValid: 'true',
        emailValidReason: '',
        primaryPhoneNumber: null,
        primaryPhoneTypeID: '',
        secondaryPhoneNumber: null,
        secondaryPhoneTypeID: '',
        alternatePhoneNumber: null,
        alternatePhoneTypeID: '',
        yearsExperience: 0,
        isFullTime: false,
        typePartnerRoleID: '',
        typePreferredDayOfWeekID: '',
        typePreferredTimeOfDayID: '',
        typePreferredContactMethodID: '',
      }

      return contact
    }

    return {
      internalContact,
      primaryPhoneNumber,
      secondaryPhoneNumber,
      alternatePhoneNumber,
      confirmEmail,
      hasEmailMismatch,
      addPhoneNumber,
      removePhoneNumber,
      onEmailConfirm,
    }
  },
})
