









































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'

/* components */
import SpecialEventCalendar from '@/views/Programs/Scheduling/SpecialEvents/SpecialEventCalendar.vue'
import SelectInput from '@/elements/SelectInput.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import ToggleLinks from '@/elements/ToggleLinks.vue'
import SpecialEventModal from '@/views/Programs/Scheduling/SpecialEvents/SpecialEventModal.vue'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'
import EditBtn from '@/elements/DataTable/vue/EditBtn.vue'
import DeleteBtn from '@/elements/DataTable/vue/DeleteBtn.vue'
import Modal from '@/components/Modal.vue'

/* models and utils */
import * as colors from '@/views/Programs/Scheduling/SpecialEvents/ext/colors'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { getEmptyLeagueListItem, LeagueListItemSeed } from '@/lib/support/models/LeagueListItem/data'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { headers, showMoreHeaders } from '@/views/Programs/Scheduling/SpecialEvents/ext/headers'
import { SpecialEventGridData } from '@/views/Programs/Scheduling/SpecialEvents/ext/types'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

import { LeagueSpecialEventInfo } from '@/GeneratedTypes/ListInfo/LeagueSpecialEventInfo'
import { LeagueSpecialEvent } from '@/GeneratedTypes/LeagueSpecialEvent'
import { getEmptyLeagueSpecialEvent, getLSEfromLSEInfo } from '@/lib/support/models/LeagueSpecialEvent/data'
import { SpecialEventCalendarData } from '@/views/Programs/Scheduling/SpecialEvents/ext/types'
import { ClickDate } from '@/elements/Calendar/types'

/** store **/
import store from '@/store'
import {
  actionNames as facilitiesActions,
  getterNames as facilitiesGetters,
  namespace as facilitiesStoreName,
} from '@/store/facilities'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import {
  actionNames as divisionActions,
  getterNames as divisionGetters,
  namespace as divisionNamespace,
} from '@/store/divisions'
import { programTypes } from '@/store/programTypes'

import programsClient from '@/clients/programsClient'

const facilities = namespace(facilitiesStoreName)
const divisions = namespace(divisionNamespace)

const START_DAY = 0 //sunday

@Component({
  components: {
    DataTable,
    DeleteBtn,
    EditBtn,
    ToggleLinks,
    ConfirmationModal,
    Alert,
    SelectInput,
    SpecialEventCalendar,
    SpecialEventModal,
    Modal,
  },
  data() {
    return { headers, showMoreHeaders }
  },
})
export default class SpecialEventSchedule extends Vue {
  /** getters */
  @facilities.Getter(facilitiesGetters.items) facilities!: LeagueFacility[]
  @divisions.Getter(divisionGetters.allItems) divisionList!: (s: string) => LeagueDivisionInfo[]
  @divisions.Action(divisionActions.fetchAll) fetchAllDivisions!: ({
    upwardLeagueId,
    force,
  }: {
    upwardLeagueId: UpwardLeagueIDType
    force: boolean
  }) => Promise<boolean>
  @facilities.Action(facilitiesActions.load)
  private readonly retrieveFacilities!: ({ id }: { id: string }) => LeagueFacility[] | null

  get teams() {
    return this.$store.direct.getters.teams.teams
  }

  get specialEvents() {
    return this.$store.direct.getters.scheduling.specialEvents
  }

  get statusText() {
    return this.$store.direct.getters.scheduling.statusText
  }

  private readonly loadSpecialEvents = store.dispatch.scheduling.loadSpecialEvents
  private readonly upsertSpecialEvent = store.dispatch.scheduling.upsertSpecialEvent
  private readonly removeSpecialEvent = store.dispatch.scheduling.removeSpecialEvent
  private readonly fetchTeams = store.dispatch.teams.fetchTeams
  private viewtype = 'list' //alternate is calendar

  private league: LeagueListItem = {} as LeagueListItem

  private loading = true

  private showMoreModalShowing = false
  private showMoreTitle = ''
  private showMoreSchedule: SpecialEventGridData[] = []

  private readonly alertType = AlertTypeEnum.NOTICE

  get leagueDivisionList() {
    return this.divisionList(this.leagueID)
  }

  get programList() {
    return this.league.myLeagueInfo?.programs ?? []
  }

  private currentSpecialEvent: LeagueSpecialEvent = getEmptyLeagueSpecialEvent()

  /************************
   *   Grid Events
   ***********************/
  doubleClickGrid(e: DataTableSelection<SpecialEventGridData>) {
    this.editItem(e.item)
  }

  editItem(e: SpecialEventGridData | SpecialEventCalendarData) {
    this.currentSpecialEvent = getLSEfromLSEInfo(e)
    this.showMoreModalShowing = false
    this.schedulingModalShowing = true
  }

  /************************
   *   Calendar Events
   ***********************/
  eventClick(e: SpecialEventCalendarData) {
    this.editItem(e)
  }

  dateClick(e: ClickDate) {
    this.currentSpecialEvent = cloneDeep(this.makeNewSpecialEvent())
    this.currentSpecialEvent.eventStart = dayjs(e.date).toDate()
    this.schedulingModalShowing = true
  }

  moreClick(e: ClickDate) {
    const startDayJS = dayjs(e.date)
    this.showMoreTitle = `Special Events for ${startDayJS.format('MMM D YYYY')}`
    this.showMoreSchedule = this.gridSchedule.filter((x) =>
      dayjs(x.eventStart ?? '').isSame(startDayJS, 'day')
    )
    this.showMoreModalShowing = true
  }

  /**********************
   * Calendar Options
   ********************/
  get weekdays(): number[] {
    return [0, 1, 2, 3, 4, 5, 6] //just return them all for special events
  }

  /**********************
   * Add/Edit Modal
   **********************/
  schedulingModalShowing = false
  clickAddSpecialEvent() {
    this.currentSpecialEvent = cloneDeep(this.makeNewSpecialEvent())
    this.schedulingModalShowing = true
  }

  /***
   * Used to constrain the calendar
   *
   */

  get calendarStartDate(): string {
    if (this.specialEvents.length > 0) {
      return dayjs(this.specialEvents[0].eventStart ?? new Date()).format('YYYY-MM-DD')
    } else {
      return this.firstEvalDate
    }
  }

  get firstGameDate(): string {
    const gamedate = this.league.myLeagueInfo?.firstGameDate ?? new Date()
    return dayjs(gamedate).format('YYYY-MM-DD')
  }

  get firstEvalDate(): string {
    const evalDate = this.league.myLeagueInfo?.firstEvalDate ?? new Date()
    return dayjs(evalDate).format('YYYY-MM-DD')
  }

  get seasonEndDate(): string {
    const sed = this.league.myLeagueInfo?.seasonEndDate ?? new Date()
    return dayjs(sed).format('YYYY-MM-DD')
  }

  makeNewSpecialEvent(): LeagueSpecialEvent {
    const start = dayjs(this.startEpoch).hour(0)
    return {
      ...getEmptyLeagueSpecialEvent(),
      leagueID: this.league.leagueID,
      eventStart: start.toDate(),
    }
  }

  get startEpoch(): number {
    const gamedate = this.league.myLeagueInfo?.firstGameDate ?? new Date()
    return dayjs(gamedate).day(START_DAY).hour(0).minute(0).millisecond(0).valueOf() //get epoch of sunday before game day
  }

  private get schedule(): SpecialEventCalendarData[] {
    return this.specialEvents.map((x) => ({
      ...x,
      name: x.eventLabel ?? '',
      start: dayjs(x.eventStart ?? '').format('YYYY-MM-DDTHH:mm'),
      end: x.eventEnd ? dayjs(x.eventEnd).format('YYYY-MM-DDTHH:mm') : '',
      timed: !(
        dayjs(x.eventStart ?? '').isSame(dayjs(x.eventStart ?? '').startOf('day')) && x.eventEnd == null
      ),
      class: 'active',
      color: this.getEventColor(x, 'COLOR'),
      textColor: this.getEventColor(x, 'TEXT'),
    }))
  }

  private getEventColor(evt: LeagueSpecialEventInfo, type: 'COLOR' | 'TEXT'): string {
    let retval = type == 'COLOR' ? colors.genericEventBgColor : colors.genericEventTextColor

    if (evt.typeProgramID && evt.typeProgramID != 'NONE') {
      if (evt.divisionID > 0) {
        if (evt.teamID > 0) {
          retval = type == 'COLOR' ? colors.teamEventBgColor : colors.teamEventTextColor
        } else {
          retval = type == 'COLOR' ? colors.divisionEventBgColor : colors.divisionEventTextColor
        }
      } else {
        retval = type == 'COLOR' ? colors.programEventBgColor : colors.programEventTextColor
      }
    }
    return retval
  }

  private get gridSchedule(): SpecialEventGridData[] {
    return this.specialEvents.map((x: LeagueSpecialEventInfo) => ({
      ...x,
      prettyDate: dayjs(x.eventStart ?? '').format('MMM DD'),
      prettyTime: this.getPrettyTime(x.eventStart!, x.eventEnd),
      prettyProgramName:
        x.typeProgramID == 'NONE'
          ? null
          : programTypes.state.items.find((p) => p.upwardTypeID == x.typeProgramID)?.description ??
            x.typeProgramID,
    }))
  }

  private getPrettyTime(evtStart: Date, evtEnd: Date | null): string {
    const startJS = dayjs(evtStart)
    const endJS = dayjs(evtEnd ?? '')
    if (startJS.startOf('day').isSame(startJS) && evtEnd == null) {
      return ''
    } else {
      if (evtEnd != null) {
        return startJS.format('h:mmA') + ' - ' + endJS.format('h:mmA')
      } else {
        return startJS.format('h:mmA')
      }
    }
  }

  idTODelete = 0
  showDeleteConfirmed = false
  showDeleteConfirmation = false

  /***
   * This is called when delete is selected off the grid
   */

  async deleteConfirmed(b: boolean) {
    if (b) {
      await this.removeSpecialEvent({ id: this.leagueID, eventID: this.idTODelete })
      this.showDeleteConfirmed = true
    }
    this.showDeleteConfirmation = false
  }

  deleteItem(r: SpecialEventGridData) {
    this.idTODelete = r.eventID
    this.showMoreModalShowing = false
    this.showDeleteConfirmation = true
  }

  async deleteSpecialEvent(evt: LeagueSpecialEvent) {
    await this.removeSpecialEvent({ id: this.leagueID, eventID: evt.eventID })
    this.schedulingModalShowing = false
    this.showDeleteConfirmed = true
  }

  async confirmSpecialEvent(evt: LeagueSpecialEvent) {
    await this.upsertSpecialEvent({ id: this.leagueID, specialEvent: evt })
    this.schedulingModalShowing = false
  }

  refreshSpecialEvents() {
    this.loadSpecialEvents({ id: this.leagueID })
  }

  refreshLeagues() {
    this.fetchAllDivisions({ upwardLeagueId: this.leagueID, force: true })
  }

  /**
   * Get teams
   */
  private async refreshTeams() {
    await this.fetchTeams({ upwId: this.leagueID })
  }

  /**
   * Get facilities
   */
  private async refreshFacilities() {
    await this.retrieveFacilities({ id: this.leagueID })
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */

  get programID() {
    if (
      this.league &&
      this.league.myLeagueInfo &&
      this.league.myLeagueInfo.programs &&
      this.league.myLeagueInfo.programs?.length > 1
    ) {
      return this.league.myLeagueInfo.programs[1].typeProgramID || ''
    }
    return ''
  }

  get leagueID() {
    return this.$route.params.id
  }

  public async mounted() {
    store.dispatch.programTypes.fetchAll(false) //go ahead and cache these up
    this.league =
      (await programsClient.retrieve(this.leagueID)) ?? getEmptyLeagueListItem(new LeagueListItemSeed())
    await Promise.all([
      this.refreshLeagues(),
      this.refreshFacilities(),
      this.refreshSpecialEvents(),
      this.refreshTeams(),
    ])
    this.loading = false
  }
}
