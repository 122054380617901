import dayjs, { Dayjs } from 'dayjs'
import { Week } from '@/elements/Calendar/types'

export function createWeeks(minEventDate: Date) {
  const list: Week[] = []

  // starting from the minEventDate, find the previous Sunday
  let sunday = previousSunday(dayjs(minEventDate))

  //create a list of weeks that spans 16 weeks from today or the first game date
  let id = 0
  do {
    const saturday = sunday.add(6, 'd')
    list.push({
      id: id++,
      text: `${sunday.format('MMM D')} - ${saturday.format('MMM D YYYY')}`,
      start: sunday,
      end: saturday,
    })
    sunday = saturday.add(1, 'd')
  } while (id < 16)
  return list
}

export function previousSunday(dt: Dayjs) {
  let sunday = dt
  while (sunday.format('d') != '0') {
    sunday = sunday.subtract(1, 'd')
  }
  return sunday
}

export function nextSaturday(dt: Dayjs) {
  let saturday = dt
  while (saturday.format('d') != '6') {
    saturday = saturday.add(1, 'd')
  }
  return saturday
}

export function getDateById(id: number, type: 'start' | 'end', weeks: Week[]) {
  const dt = weeks.find((w) => w.id == id)
  return dt ? dayjs(dt[type]).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
}

export function roundTime(dt: Dayjs, roundTo = 15, down = true) {
  const time = dt.valueOf()
  const roundDownTime = roundTo * 60 * 1000

  const rounded = down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime))
  return dayjs(rounded)
}
