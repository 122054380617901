import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'

import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'
import partnersClient from '@/clients/partnersClient'

import { ActionsImpl } from 'direct-vuex'

interface NearByAccountsState {
  items: { [id: string]: AccountInfo[] } // key is leagueUpwardTypeId
}

const nearByAccountsState: NearByAccountsState = {
  items: {},
}

export enum getterNames {
  getAccountsInRadius = 'getAccountsInRadius',
}

const getters: GetterTree<NearByAccountsState, RootState> = {
  [getterNames.getAccountsInRadius]: (state) => (accountNumber: string) => {
    return state.items[accountNumber] || []
  },
}

export enum mutationNames {
  setAccountsInRadius = 'setAccountsInRadius',
}

const mutations: MutationTree<NearByAccountsState> = {
  [mutationNames.setAccountsInRadius](state, { items, accountNumber }) {
    Vue.set(state.items, accountNumber, items)
  },
}

export enum actionNames {
  fetchAccountsInRadius = 'fetchAccountsInRadius',
}

const actions: ActionTree<NearByAccountsState, RootState> & ActionsImpl = {
  async [actionNames.fetchAccountsInRadius]({ commit, state }, { accountNumber, force = false }) {
    const archived = state.items[accountNumber] || []

    if (archived.length && !force) {
      return false
    }

    const items = await partnersClient.getAccountsNearBy(accountNumber)

    if (items) {
      commit(mutationNames.setAccountsInRadius, { items, accountNumber })
      return true
    }

    return false
  },
}

export const namespace = 'partnerNearByAccounts'

export const partnerNearByAccounts = {
  namespaced: true as true,
  state: nearByAccountsState,
  getters,
  actions,
  mutations,
}
