






























































































































import { computed, defineComponent, PropType, ref, watch, reactive, onMounted } from '@vue/composition-api'

import ParticipantList from '@/views/Programs/Divisions/TeamManagement/vues/ParticipantList.vue'
import PlayerConflictList from '@/views/Programs/Divisions/TeamManagement/vues/PlayerConflictList.vue'
import CoachConflictList from '@/views/Programs/Divisions/TeamManagement/vues/CoachConflictList.vue'
import CoachList from '@/views/Programs/Divisions/common/vue/CoachList.vue'
import TabMenu from '@/views/Programs/Divisions/TeamManagement/vues/TabMenu.vue'
import UnassignDivisionParticipants from '@/views/Programs/Divisions/TeamManagement/vues/UnassignDivisionParticipants.vue'
import TeamToggle from '@/views/Programs/Divisions/common/vue/TeamToggle.vue'
import SideBySideScroll from '@/elements/SideBySideScroll.vue'
import ColumnSelector from '@/views/Programs/Divisions/TeamManagement/vues/ColumnSelector.vue'
import TeamColumnSelector from '@/views/Programs/Divisions/TeamManagement/vues/TeamColumnSelector.vue'
import ParticipantSort from '@/views/Programs/Divisions/TeamManagement/vues/ParticipantSort.vue'
import PlayerConflictFilter from '@/views/Programs/Divisions/TeamManagement/vues/PlayerConflictFilter.vue'
import CoachConflictFilter from '@/views/Programs/Divisions/TeamManagement/vues/CoachConflictFilter.vue'
import TeamSort from '@/views/Programs/Divisions/TeamManagement/vues/TeamSort.vue'
import TeamListSort from '@/views/Programs/Divisions/TeamManagement/vues/TeamListSort.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'
import TeamCardField from '@/views/Programs/Divisions/TeamManagement/vues/TeamCardField.vue'
import ShowToggle from '@/elements/ShowToggle.vue'
import DraftOptionsInfo from '@/views/Programs/Divisions/TeamManagement/DraftOptionsInfo.vue'
import Loading from '@/elements/Loading.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'

import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { PlayerConflictInfo } from '@/GeneratedTypes/ListInfo/PlayerConflictInfo'
import { CoachConflictInfo } from '@/GeneratedTypes/ListInfo/CoachConflictInfo'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'

import { useManagerLogic } from '@/views/Programs/Divisions/TeamManagement/logic/ManagerLogic'
import { sortFunctions } from '@/views/Programs/Divisions/TeamManagement/logic/participantSort'
import {
  participantFilters,
  unassignedParticipantFilters,
} from '@/views/Programs/Divisions/TeamManagement/logic/participantFilters'

import participantsClient from '@/clients/participantsClient'
import volunteersClient from '@/clients/volunteersClient'
import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

export enum tmTabType {
  COACHES = 'C',
  PARTICIPANTS = 'P',
}

export default defineComponent({
  props: {
    division: { type: Object as PropType<LeagueDivisionInfo>, required: true },
    programID: { type: String, required: true },
  },
  components: {
    TeamCardField,
    TabMenu,
    CoachList,
    ParticipantList,
    PlayerConflictList,
    CoachConflictList,
    SideBySideScroll,
    UnassignDivisionParticipants,
    TeamToggle,
    ColumnSelector,
    TeamColumnSelector,
    ParticipantSort,
    PlayerConflictFilter,
    CoachConflictFilter,
    ShowToggle,
    DraftOptionsInfo,
    Loading,
    SwitchSlot,
    TeamSort,
    TeamListSort,
  },

  setup(props, ctx) {
    const sideBySide = ref<typeof SideBySideScroll | null>(null)
    const currentTab = ref<tmTabType | string>(tmTabType.PARTICIPANTS)
    const resize = ref(true)
    const showDraftChoice = ref(false)
    const userWantsManualDraft = ref(false)
    const sideBySideState = reactive({ left: true, right: true })
    const playerConflicts = ref<PlayerConflictInfo[]>([])
    const coachConflicts = ref<CoachConflictInfo[]>([])
    const upwardLeagueID = ref<string | null>(props.division.upwardLeagueID)
    const manager = useManagerLogic()
    const {
      getSort,
      getTeamSort,
      getFilterTarget,
      getGender,
      getHeightRange,
      getScoreRange,
      getGradeRange,
      getIsFilteringUnassigned,
      currentFilters,
      getIsFilteringTeams,
      getShowHeader,
      setShowHeader,
      setTeamListSort,
      setIsByAge,
      getTeamListSort,
    } = userPreferences()

    const teamOptions = [
      { text: 'show/hide teams', value: 'team-toggle' },
      { text: 'show/hide fields', value: 'team-field-toggle' },
      { text: 'sort assigned participants', value: 'sort-assigned' },
      { text: 'sort teams', value: 'sort-teams' },
      { text: 'remove all', value: 'remove-all' },
      { text: 'none', value: 'hide-all' },
    ]
    const teamSwitch = ref(teamOptions[0].value)

    const playerOptions = [
      { text: 'show/hide fields', value: 'field-toggle' },
      { text: 'sort unassigned participants', value: 'sort-unassigned' },
      { text: 'none', value: 'hide-all' },
      { text: 'show conflicts', value: 'show-conflicts' },
    ]

    const coachOptions = [
      { text: 'all coaches', value: 'all-coaches' },
      { text: 'show conflicts', value: 'show-coach-conflicts' },
    ]

    let lastPlayerOption = playerOptions[0].value
    let lastCoachOption = coachOptions[0].value

    const unassignedOptions = ref(playerOptions)

    const unassignedSwitch = ref(lastPlayerOption)

    const selectedCoach = ref<LeagueCoachProgramInfoFLAT | null>(null)
    const selectedPlayer = ref<LeaguePlayerInfo | null>(null)
    const coachListChildRef = ref()
    const playerListChildRef = ref()

    onMounted(() => setIsByAge(store.getters.leagueAbstraction.isByAge))

    const participantsNotAssigned = computed(() => {
      const sort = getSort.value
      const isFilteringUnassigned = getFilterTarget.value != 'TEAMS'

      // General filtering
      const x = manager.participants.value.filter((x) => {
        const notInTeam = manager.participantsInTeams.value.indexOf(x.individualID) < 0
        const inProgram = x.typeProgramID == props.programID
        const inGender = props.division.gender == 'E' || x.gender === props.division.gender
        const inGrade =
          props.division?.grades?.length &&
          props.division.grades.findIndex((y) => x?.typeGradeID == y?.typeGradeID) >= 0
        return inProgram && inGrade && notInTeam && inGender
      })

      //Sorting
      let participants = x.sort((a: LeaguePlayerInfo, b: LeaguePlayerInfo) => {
        const fnc = sortFunctions[sort]
        const isDesc = sort.indexOf('_desc') > 0
        return fnc(a, b, isDesc)
      })

      //Filtering based on user selections
      if (isFilteringUnassigned) {
        return participants
          .filter((p) => {
            return participantFilters(p, getGender.value ?? [], getHeightRange.value, getScoreRange.value)
          })
          .filter((x) => unassignedParticipantFilters(x, getGradeRange.value))
      }
      return participants
    })

    function toggled(value: boolean) {
      resize.value = !!resize.value
      setShowHeader(value)
      ctx.emit('toggleHeader', value)
      if (sideBySide && sideBySide.value && sideBySide.value.data) {
        sideBySide.value.data.dimensions()
      }
    }

    const showFilters = computed(() => {
      const filtering = getIsFilteringUnassigned.value || getIsFilteringTeams.value
      return !loading.value && filtering
    })

    const loading = computed(() => manager.refreshing.value)
    // const participantsLoading = computed(() => manager.participantsRefreshing)
    const managerReady = computed(
      () => !manager.coachRefreshing.value && !manager.participantsRefreshing.value
    )

    function sideBySideStateChange(state: { left: boolean; right: boolean }) {
      sideBySideState.left = state.left
      sideBySideState.right = state.right
    }

    const showingTeamsOnly = computed(() => {
      return sideBySideState.left == false && sideBySideState.right === true
    })

    const draftedCount = computed(() => manager.draftedParticipantCount.value)

    function setShowDraftChoice() {
      if (
        isLeague.value &&
        currentTab.value == 'P' &&
        manager.draftedParticipantCount.value <= 0 &&
        !userWantsManualDraft.value
      ) {
        showDraftChoice.value = true
      } else {
        showDraftChoice.value = false
      }
    }

    function hideDraftChoice() {
      userWantsManualDraft.value = true
      setShowDraftChoice()
    }

    async function refreshConflicts() {
      let conflicts =
        (await participantsClient.retrievePlayerConflicts(
          props.division.upwardLeagueID!,
          props.programID,
          props.division.divisionID
        )) ?? []

      if (conflicts.length > 0) {
        conflicts.sort((a: PlayerConflictInfo, b: PlayerConflictInfo) => {
          return a?.formattedNameLastFirst?.localeCompare(b?.formattedNameLastFirst ?? '') ?? 0
        })
      }

      playerConflicts.value = conflicts

      let newCoachConflicts =
        (await volunteersClient.retrieveCoachConflicts(props.division.upwardLeagueID!)) ?? []

      /* no need to sort these since we lose the sorting downstream
      if (newCoachConflicts.length > 0) {
        newCoachConflicts.sort((a: CoachConflictInfo, b: CoachConflictInfo) => {
          return a?.formattedNameLastFirst?.localeCompare(b?.formattedNameLastFirst ?? '') ?? 0
        })
      }
      */

      coachConflicts.value = newCoachConflicts
    }

    const isLeague = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    )

    watch(
      () => manager.teamStructureChanging.value,
      async () => {
        if (!manager.teamStructureChanging.value) {
          await refreshConflicts()
        }
      },
      { immediate: true }
    )

    watch(
      () => currentTab.value,
      async () => {
        if (currentTab.value == 'P') {
          unassignedOptions.value = playerOptions
          unassignedSwitch.value = lastPlayerOption
        } else {
          unassignedOptions.value = coachOptions
          unassignedSwitch.value = lastCoachOption
        }

        if (sideBySide && sideBySide.value && sideBySide.value.data) {
          sideBySide.value.data.dimensions()
        }
        setShowDraftChoice()
        await manager.setCurrentView(currentTab.value)
        if (currentTab.value == 'C') {
          playerListChildRef.value?.clearSelectedPlayer()
        }
        if (currentTab.value == 'P') {
          coachListChildRef.value?.clearSelectedCoach()
        }
      },
      { immediate: true }
    )

    watch(
      () => unassignedSwitch.value,
      () => {
        if (unassignedSwitch.value === 'show-conflicts') {
          playerListChildRef.value?.clearSelectedPlayer()
        }

        if (currentTab.value == 'P') {
          lastPlayerOption = unassignedSwitch.value
        } else {
          lastCoachOption = unassignedSwitch.value
        }
      }
    )

    watch(
      () => draftedCount.value,
      () => {
        setShowDraftChoice()
      },
      { immediate: true }
    )

    watch(
      () => props.division,
      () => {
        userWantsManualDraft.value = false
        setShowDraftChoice()
      }
    )

    watch(
      () => props.programID,
      () => {
        userWantsManualDraft.value = false
        setShowDraftChoice()
      }
    )

    const sortTeamBy = computed({
      get(): string {
        return getTeamListSort.value
      },
      set(sortBy: string) {
        setTeamListSort(sortBy)
      },
    })

    function selectCoach(coach: LeagueCoachProgramInfoFLAT | null) {
      selectedCoach.value = coach
    }

    function addCoach() {
      coachListChildRef.value?.clearSelectedCoach()
    }

    function selectPlayer(player: LeaguePlayerInfo | null) {
      selectedPlayer.value = player
    }

    function addPlayer() {
      playerListChildRef.value?.clearSelectedPlayer()
    }

    return {
      ...manager,
      currentFilters,
      currentTab,
      participantsNotAssigned,
      getIsFilteringUnassigned,
      getIsFilteringTeams,
      showHeader: computed(() => getShowHeader.value),
      coachesInDivision: computed(() =>
        (manager.coaches.value ?? []).filter((x) => x.typeProgramID == props.programID)
      ),
      teamsChecked: computed(() => manager.teamsOnField.value?.map((x) => x.teamID) ?? []),
      toggled,
      sideBySide,
      showingTeamsOnly,
      sideBySideStateChange,
      showFilters,
      draftedCount,
      showDraftChoice,
      hideDraftChoice,
      userWantsManualDraft,
      managerReady,
      teamOptions,
      teamSwitch,
      unassignedOptions,
      unassignedSwitch,
      getTeamSort,
      playerConflicts,
      coachConflicts,
      upwardLeagueID,
      refreshConflicts,
      isLeague,
      sortTeamBy,
      selectedCoach,
      selectCoach,
      addCoach,
      coachListChildRef,
      selectedPlayer,
      selectPlayer,
      addPlayer,
      playerListChildRef,
    }
  },
})
