
































import { defineComponent, watch, computed, PropType, ref } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { getGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import * as gradeStore from '@/store/gradeTypes'
import store from '@/store'

export default defineComponent({
  name: 'GradeRangeDropdowns',
  props: {
    label: { type: String, required: false, default: '' },
    required: { type: Boolean, required: false, default: false },
    startGrade: { type: String, required: false, default: () => getGrades(false)[0] },
    endGrade: { type: String, required: false, default: () => getGrades(false)[getGrades(false).length - 1] },
    validGrades: {
      type: Array as PropType<UpwardGradeTypeID[]>,
      required: false,
      default: () => gradeStore.gradeTypes.state.items,
    },
    showRangeHint: { type: Boolean, required: false, default: false },
    isByAge: { type: Boolean, required: false, default: false },
  },
  components: {
    SelectInput,
    InputLabel,
  },

  setup(props, ctx) {
    const start = ref(props.startGrade)
    const end = ref(props.endGrade)
    function availableStartGrades(endGrade: string) {
      const endSortOrder = store.getters.gradeTypes.byUpwardTypeId(endGrade)?.sortOrder ?? 0
      return props.validGrades.filter((g) => g.sortOrder <= endSortOrder)
    }

    function availableStopGrades(startGrade: string) {
      const startSortOrder = store.getters.gradeTypes.byUpwardTypeId(startGrade)?.sortOrder ?? 0
      return props.validGrades.filter((g) => g.sortOrder >= startSortOrder)
    }

    function validGradeRange(min: string, max: string): string[] {
      const gradeList = getGrades(false)
      const idx1 = gradeList.indexOf(min)
      const idx2 = gradeList.indexOf(max) - idx1 + 1
      return gradeList.splice(idx1, idx2)
    }

    function emitGrades() {
      ctx.emit('change', validGradeRange(start.value, end.value))
    }

    const gradeRange = computed(() => {
      if (props.validGrades && props.validGrades.length > 0) {
        const grades = props.validGrades
        if (grades.length == 1) {
          return `( Allowed ${props.isByAge ? 'age' : 'grade'}: ${
            props.isByAge ? grades[0].ageByCutoff : grades[0].upwardTypeID
          } )`
        } else {
          return `( Allowed ${props.isByAge ? 'age' : 'grade'} range: ${
            props.isByAge ? grades[0].ageByCutoff : grades[0].upwardTypeID
          } - ${
            props.isByAge ? grades[grades.length - 1].ageByCutoff : grades[grades.length - 1].upwardTypeID
          } )`
        }
      }
    })

    watch(
      () => start.value,
      () => emitGrades(),
      { immediate: true }
    )
    watch(
      () => end.value,
      () => emitGrades(),
      { immediate: true }
    )

    watch(
      () => props.startGrade,
      () => (start.value = props.startGrade),
      { immediate: true }
    )

    watch(
      () => props.endGrade,
      () => (end.value = props.endGrade),
      { immediate: true }
    )

    return {
      availableStartGrades,
      availableStopGrades,
      gradeRange,
      start,
      end,
    }
  },
})
