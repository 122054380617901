

































































































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'

import SelectInput from '@/elements/SelectInput.vue'

import CheckboxInput from '@/elements/CheckboxInput.vue'

import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'

import DateInput from '@/elements/DateInput.vue'

import { isCheer } from '@/lib/common/ProgramTypes'

import dayjs from 'dayjs'

import store from '@/store'
import { getGradesListFromAccount } from '@/services/gradeService'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'

export default defineComponent({
  name: 'ParticipantEvalForm',
  components: {
    SelectInput,
    CheckboxInput,
    ActiveReport,
    DateInput,
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const league = computed(() => store.getters.leagues.currentItem)
    const camp = computed(() => store.getters.camps.currentItem)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const currentDateMinusOne = computed(() => dayjs().subtract(1, 'day').format('YYYY-MM-DD hh:mm'))
    const currentDate = computed(() => dayjs().format('YYYY-MM-DD hh:mm'))

    const selectedProgram = ref('')
    const selectedAllOrImported = ref('1')
    const selectedGender = ref('E')
    const filterByRegDate = ref(false)
    const selectedRegStartDate = ref(currentDateMinusOne.value)
    const selectedRegEndDate = ref(dayjs().format('YYYY-MM-DD hh:mm'))
    const filterByGrade = ref(false)
    const selectedStartGrade = ref('0')
    const selectedEndGrade = ref('0')

    const noEvalsOnly = ref(false)

    const isByAge = computed(() => {
      if (isCamp.value) {
        return camp.value.ageCutoffDate !== null
      } else {
        return league.value.ageCutoffDate !== null
      }
    })

    const importOptions = ref([
      { importName: 'All Players', importValue: '1' },
      { importName: 'Imported Players Only', importValue: '0' },
    ])

    const genderOptions = ref([
      { genderName: 'All Genders', genderValue: 'E' },
      { genderName: 'Male Only', genderValue: 'M' },
      { genderName: 'Female Only', genderValue: 'F' },
    ])

    const hideCarpoolLinks = ref(false)
    const hidePracticeNightExclusion = ref(false)
    const schoolYear = ref('21-22') //hardcoded for now - TW
    const loadStatus = ref('')
    const loading = ref(false)

    function setGrades() {
      if (!filterByGrade.value) {
        selectedStartGrade.value = '0'
        selectedEndGrade.value = '0'
      }
    }

    const dateLimits = ref({
      min: '2019-01-01 00:00',
      max: currentDate.value,
    })

    //const gradesList = computed(() => getGrades(false))
    const gradesList = computed(() => {
      let retval: UpwardGradeTypeID[] = []
      const accts = isCamp.value ? camp.value.accounts : league.value.accounts
      const acct = accts?.find((x) => x.accountNumber == accountNumber.value)
      if (acct) {
        retval = getGradesListFromAccount(acct, selectedProgram.value, selectedGender.value)
      }
      return retval
    })

    const typeProgramID = computed(() => {
      if (isCamp.value) {
        return camp.value?.programs ? camp.value?.programs[0].typeProgramID : ''
      } else {
        return league.value?.programs ? league.value?.programs[0].typeProgramID : ''
      }
    })

    const programs = computed((): string[] => {
      if (isCamp.value) {
        return camp.value.programs?.map((p) => p.typeProgramID) ?? []
      }
      return league.value.programs?.map((p) => p.typeProgramID) ?? []
    })

    const hasCheer = computed((): boolean => {
      if (isCamp.value) {
        return false
      }

      return (league.value && league.value.programs?.some((p) => isCheer(p.typeProgramID))) ?? false
    })

    onMounted(() => {
      selectedProgram.value = typeProgramID.value
    })

    function reportLoaded() {
      loading.value = false
    }

    async function loadReport() {
      loading.value = true
      let leagueId = 0
      let typeProgramId = ''
      let createBlankReport = false
      let showAll = selectedAllOrImported.value
      let gender = selectedGender.value
      let filterByDate = filterByRegDate.value
      let startDate = dayjs(selectedRegStartDate.value).format('YYYY-MM-DD')
      let endDate = dayjs(selectedRegEndDate.value).format('YYYY-MM-DD')
      let startGrade = selectedStartGrade.value
      let endGrade = selectedEndGrade.value
      let noEvalScoresOnly = noEvalsOnly.value

      if (isCamp.value) {
        leagueId = camp.value.id
        typeProgramId = selectedProgram.value
      } else {
        leagueId = league.value.id
        typeProgramId = selectedProgram.value
      }

      loadStatus.value = 'Downloading Form Data . . .'
      const data = await Promise.all([
        reportsClient.getEvaluationData(
          upwardLeagueId.value,
          accountNumber.value,
          createBlankReport,
          typeProgramId,
          showAll,
          gender,
          filterByDate,
          filterByGrade.value,
          startDate,
          endDate,
          startGrade,
          endGrade,
          noEvalScoresOnly
        ),
        reportsClient.getEvaluationSizeData(
          upwardLeagueId.value,
          accountNumber.value,
          leagueId,
          typeProgramId,
          createBlankReport
        ),
        reportsClient.getEvaluationQuestionsData(upwardLeagueId.value, accountNumber.value, typeProgramId),
      ])

      loadStatus.value = 'Downloading Form Templates . . .'
      const reportTemplates = await Promise.all([
        reportsClient.getReportTemplate('evalform'),
        reportsClient.getReportTemplate('evalsizessubreport'),
        reportsClient.getReportTemplate('evalquestionssubreport'),
      ])

      loadStatus.value = 'Processing Form Templates . . .'
      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')
      const sizeSubReport = JSON.parse(reportTemplates[1]?.jsonContent ?? '')
      const questionSubReport = JSON.parse(reportTemplates[2]?.jsonContent ?? '')

      const mainData = data[0]

      const parameters = [
        {
          Value: [hidePracticeNightExclusion.value],
          Name: 'HidePracticeNights',
        },
        {
          Value: [hideCarpoolLinks.value],
          Name: 'HideCarpoolLinks',
        },
        {
          Value: [`${typeProgramId}`],
          Name: 'TypeProgramID',
        },
        {
          Value: [`${leagueId}`],
          Name: 'LeagueID',
        },
        {
          Value: [`${accountNumber.value}`],
          Name: 'AccountNumber',
        },
        {
          Value: [`${schoolYear.value}`],
          Name: 'SchoolYear',
        },
        {
          Value: [store.getters.leagueAbstraction.refLabel],
          Name: 'RefLabel',
        },
      ]

      report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)
      sizeSubReport.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(data[1])
      questionSubReport.DataSources[0].ConnectionProperties.ConnectString =
        'jsondata=' + JSON.stringify(data[2])

      const resourceLocator = {
        getResource: function (name: string) {
          if (name === 'eval-sizes-sub-report.rdlx-json') return sizeSubReport
          if (name === 'eval-questions-sub-report.rdlx-json') return questionSubReport
        },
      }

      loadStatus.value = 'Loading Form . . .'
      const activeReport: any = ctx.refs.activereport
      activeReport.loadReportData(report, { ReportParams: parameters, ResourceLocator: resourceLocator })
    }

    return {
      hasCheer,
      loading,
      programs,
      selectedProgram,
      importOptions,
      selectedAllOrImported,
      genderOptions,
      selectedGender,
      filterByRegDate,
      selectedRegStartDate,
      selectedRegEndDate,
      dateLimits,
      filterByGrade,
      gradesList,
      selectedStartGrade,
      selectedEndGrade,
      noEvalsOnly,
      hideCarpoolLinks,
      hidePracticeNightExclusion,
      loadStatus,
      isByAge,
      loadReport,
      reportLoaded,
      setGrades,
    }
  },
})
