import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount'
import { LeagueAccountProgramGenderGrade } from '@/GeneratedTypes/LeagueAccountProgramGenderGrade'
import { LeagueProgramGenderGrade } from '@/GeneratedTypes/LeagueProgramGenderGrade'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import { getGrades, initialGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import { League } from '@/GeneratedTypes/League'
import { SavedProductOfferingConfigGenderGrade } from '@/GeneratedTypes/SavedProductOfferingConfigGenderGrade'
import { ProgramTypes } from '@/lib/common/ProgramTypes'
import store from '@/store'
import dayjs from 'dayjs'

export interface GradeUsage {
  grade: string
  femaleUsed: boolean
  maleUsed: boolean
}

export interface ProgramWithGrades {
  id: number
  accounts: LeagueAccount[] | null
  programs: LeagueProgram[] | null
}

export function sortGrades(a: string, b: string) {
  return (
    (store.getters.gradeTypes.byUpwardTypeId(a)?.sortOrder ?? 0) -
    (store.getters.gradeTypes.byUpwardTypeId(b)?.sortOrder ?? 0)
  )
}

/*
gradeMax: the max value of the range being accepting by the filtering
gradeMin: the min value of the range being accepting by the filtering
grade: the grade to be tested
*/
export function filterGrades(gradeMin: string, gradeMax: string, grade: string | null) {
  if (!grade) return true
  const grades = getGrades(false)
  const gradeIndex = grades.indexOf(grade)
  const maxGradeIndex = grades.indexOf(gradeMax)
  const minGradeIndex = grades.indexOf(gradeMin)
  return gradeIndex >= minGradeIndex && gradeIndex <= maxGradeIndex
}

export function getListFromSavedEstimate(
  grades: SavedProductOfferingConfigGenderGrade[],
  typeProgramId: string,
  gender: string
) {
  return grades
    .filter((g) => {
      return g.typeProgramID == typeProgramId && g.gender == gender
    })
    .map((g) => g.typeGradeID)
}

export function populateProgramGrades(
  league: League,
  productConfigGrades: SavedProductOfferingConfigGenderGrade[] | null
): League {
  if (!productConfigGrades) return league
  league.programs?.forEach((p, i) => {
    const grades = productConfigGrades.filter((g) => g.typeProgramID === p.typeProgramID)
    const converted = grades.map((g) => {
      return {
        overrideRegistrationFee: 0,
        overrideEarlyRegistrationFee: 0,
        gender: g.gender,
        typeGradeID: g.typeGradeID,
      }
    })
    if (league.programs) {
      league.programs[i].gradesByGender = converted
    }
  })
  return league
}

export function getGradesList(
  program: ProgramWithGrades,
  typeProgramId: string,
  gender: string
): UpwardGradeTypeID[] {
  const grades: UpwardGradeTypeID[] = []
  if (!program.accounts || program.accounts.length === 0 || program.accounts[0].grades === null) {
    return grades
  }

  return getGradesListFromAccount(program.accounts[0], typeProgramId, gender)
}

export function getGradesListFromAccount(
  account: LeagueAccount,
  typeProgramId: string,
  gender: string
): UpwardGradeTypeID[] {
  if (account !== null) {
    // There will be duplicates because of the grade for both Male and Female, so we need
    // to filter those out and only get unique values (https://stackoverflow.com/a/35092559/13215483)

    const uniqueGrades = [
      ...new Set(
        // @ts-ignore: Object is possibly 'null'.
        account.grades
          .filter(
            (g) => g.typeProgramID === typeProgramId && g.gender === (gender === 'E' ? g.gender : gender)
          )
          .map((g) => g.typeGradeID)
      ),
    ]
    return uniqueGrades.map((g) => store.getters.gradeTypes.byUpwardTypeId(g))
  }

  return []
}

export function getGradeUsage(program: ProgramWithGrades, typeProgramId: string): GradeUsage[] {
  const grades: GradeUsage[] = []

  if (!program.accounts || program.accounts.length === 0 || program.accounts[0].grades === null) {
    return grades
  }

  // There will be duplicates because of the grade for both Male and Female, so we need
  // to filter those out and only get unique values (https://stackoverflow.com/a/35092559/13215483)
  const uniqueGrades = [
    ...new Set(
      program.accounts[0].grades.filter((g) => g.typeProgramID === typeProgramId).map((g) => g.typeGradeID)
    ),
  ]

  const programGrades = program.accounts[0].grades.filter((g) => g.typeProgramID === typeProgramId)
  for (const grade of uniqueGrades) {
    const femaleGradeExists = programGrades.find((pg) => pg.gender === 'F' && pg.typeGradeID === grade)
    const maleGradeExists = programGrades.find((pg) => pg.gender === 'M' && pg.typeGradeID === grade)

    grades.push({ grade, femaleUsed: !femaleGradeExists, maleUsed: !maleGradeExists })
  }

  return grades
}
export function initializeNewProgramGradeData(program: ProgramWithGrades, programTypeId: string) {
  if (!programTypeId.includes('CHEER')) {
    loadGradeDataIntoProgram(program, initialGrades, programTypeId, 'M')
  }
  loadGradeDataIntoProgram(program, initialGrades, programTypeId, 'F')
}

export function loadGradeDataIntoAccount(
  account: LeagueAccount,
  grades: string[],
  programTypeId: string,
  gender: string
) {
  if (shouldReloadGradesForAccount(account, grades, programTypeId, gender)) {
    loadGradeInformationIntoAccount(account, grades, programTypeId, gender)
  }
}

export function loadGradeDataIntoProgram(
  program: ProgramWithGrades,
  grades: string[] | null,
  programTypeId: string,
  gender: string
) {
  if (!grades) return
  if (shouldReloadGrades(program, grades, programTypeId, gender)) {
    // We won't get here if program or accounts is null.
    // @ts-ignore: Object is possibly 'null'.
    loadGradeInformationIntoAccount(program.accounts[0], grades, programTypeId, gender)
    loadGradeInformationIntoProgram(program, grades, programTypeId, gender)
  }
}

function shouldReloadGrades(
  program: ProgramWithGrades,
  grades: string[],
  programTypeId: string,
  gender: string
): boolean {
  if (!program.accounts || program.accounts.length === 0) {
    return false
  }

  const account = program.accounts[0]
  return shouldReloadGradesForAccount(account, grades, programTypeId, gender)
}

function shouldReloadGradesForAccount(
  account: LeagueAccount,
  grades: string[],
  programTypeId: string,
  gender: string
): boolean {
  if (account.grades === null && grades === null) {
    return false
  }

  if (account.grades === null) {
    return true
  }

  let accountGrades = ''
  account.grades.forEach(
    (item) =>
      (accountGrades +=
        item.gender === (gender || 'M') && item.typeProgramID == programTypeId
          ? item.typeGradeID + item.typeProgramID
          : '')
  )

  let controlGrades = ''
  grades.forEach((item) => (controlGrades += item + programTypeId))
  return controlGrades !== accountGrades
}

function loadGradeInformationIntoAccount(
  account: LeagueAccount,
  grades: string[],
  programTypeId: string,
  gender: string
) {
  if (account.grades === null) {
    account.grades = []
  }
  // Get items not being updated and create a new array with only those items the updated ones will be added later.
  const itemsNotForProgram = account.grades.filter((g) => g.typeProgramID !== programTypeId)
  const itemsNotBeingUpdated = gender
    ? account.grades.filter((g) => g.gender !== gender && g.typeProgramID === programTypeId)
    : []

  account.grades = [...itemsNotForProgram, ...itemsNotBeingUpdated]

  for (const grade of grades) {
    if (gender === 'M') {
      const accountGradeMale = {
        typeGradeID: grade,
        gender: 'M',
        typeProgramID: programTypeId,
      } as LeagueAccountProgramGenderGrade
      account.grades.push(accountGradeMale)
    }

    if (gender === 'F') {
      const accountGradeFemale = {
        typeGradeID: grade,
        gender: 'F',
        typeProgramID: programTypeId,
      } as LeagueAccountProgramGenderGrade
      account.grades.push(accountGradeFemale)
    }
  }
}

function loadGradeInformationIntoProgram(
  program: ProgramWithGrades,
  grades: string[],
  programTypeId: string,
  gender: string
) {
  if (!program.programs || program.programs.length === 0) {
    return
  }

  const programDetails = program.programs.find((x) => x.typeProgramID === programTypeId)
  if (!programDetails) {
    return
  }

  if (programDetails.gradesByGender === null) {
    programDetails.gradesByGender = []
  }

  // Remove only the items we're concerned with.
  const itemsNotBeingUpdated = gender ? programDetails.gradesByGender.filter((g) => g.gender !== gender) : []
  programDetails.gradesByGender = itemsNotBeingUpdated

  for (const grade of grades) {
    if (gender === 'M') {
      const programGradeMale = {
        typeGradeID: grade,
        gender: 'M',
        overrideEarlyRegistrationFee: 0,
        overrideRegistrationFee: 0,
      } as LeagueProgramGenderGrade
      programDetails.gradesByGender.push(programGradeMale)
    }

    if (gender === 'F') {
      const programGradeFemale = {
        typeGradeID: grade,
        gender: 'F',
        overrideEarlyRegistrationFee: 0,
        overrideRegistrationFee: 0,
      } as LeagueProgramGenderGrade
      programDetails.gradesByGender.push(programGradeFemale)
    }
  }
}

export interface PrettyGradeGender {
  type: string
  gender: string
  grades: string
  min: string
  max: string
}
// convert the grade/genders returned under League.account.grades into displayable data
export function prettyGradeGender(acct: LeagueAccount): PrettyGradeGender[] {
  if (!acct.grades) return [] as PrettyGradeGender[]

  //get a unique list of possible grade/gender combinations
  const groups = [...new Set(acct.grades.map((y) => `${y.typeProgramID},${y.gender}`))]

  return groups.map((g) => {
    const group = g.split(',')
    const grades = getGradesListFromAccount(acct, group[0], group[1]).map((x) => x.upwardTypeID!)
    const sorted = grades.sort(sortGrades)
    return {
      type: ProgramTypes[group[0]],
      gender: group[1] == 'F' ? 'Girls' : 'Boys',
      grades: sorted.join(','),
      min: sorted[0],
      max: sorted[grades.length - 1],
    }
  })
}

export function getProperGradeLabel(typeGradeID: string, isByAge: boolean, ageSuffix: string) {
  const typeID = store.getters.gradeTypes.byUpwardTypeId(typeGradeID)
  if (isByAge) {
    if (typeID) {
      return `${typeID.ageByCutoff}${ageSuffix}`
    } else {
      return ''
    }
  } else {
    if (typeID) {
      return typeID.description
    } else {
      return typeGradeID
    }
  }
}

export function getGradeTypeIDFromBirthDate(
  ageCutoffDate: Date | null,
  birthDate: Date | null
): UpwardGradeTypeID | null {
  let retval = null
  if (ageCutoffDate && birthDate) {
    const age = Math.floor(
      (parseInt(dayjs(ageCutoffDate).format('YYYYMMDD')) - parseInt(dayjs(birthDate).format('YYYYMMDD'))) /
        10000
    )
    if (age > 2 && age < 18) {
      retval = store.getters.gradeTypes.getTypeIDByAge(age)
    }
  }

  return retval
}

export function getDateRangeForGrades(
  fromTypeGradeID: string,
  toTypeGradeID: string,
  ageCutoffDate: Date | null
): { fromDate: Date; toDate: Date } {
  let retval = {
    fromDate: dayjs(new Date()).subtract(19, 'year').add(1, 'day').toDate(),
    toDate: dayjs(new Date()).subtract(3, 'year').toDate(),
  }
  if (ageCutoffDate) {
    const fromTypeID = store.getters.gradeTypes.byUpwardTypeId(fromTypeGradeID)
    const toTypeID = store.getters.gradeTypes.byUpwardTypeId(toTypeGradeID)
    if (fromTypeID && toTypeID) {
      retval = {
        fromDate: dayjs(new Date(ageCutoffDate))
          .subtract(toTypeID.ageByCutoff + 1, 'year')
          .add(1, 'day')
          .toDate(),
        toDate: dayjs(new Date(ageCutoffDate)).subtract(fromTypeID.ageByCutoff, 'year').toDate(),
      }
    }
  }

  return retval
}

export const sliderGrades = getGrades(true)
