
















































































import { defineComponent } from '@vue/composition-api'
import store from '@/store'

export default defineComponent({
  name: 'CampLeagueStarterBox',
  props: {
    upwardLeagueId: { type: String, required: true, default: '' },
    isCamp: { type: Boolean, required: true, default: false },
  },
  setup() {
    const refLabel = store.getters.leagueAbstraction.refLabel

    return { refLabel, isUpwardSelect: store.getters.leagueAbstraction.isUpwardSelect }
  },
})
