import { computed, PropType, SetupContext } from '@vue/composition-api'
import dayjs from 'dayjs'

import { DropResult } from 'vue-smooth-dnd'

import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { ActualDivisionTeamInfo } from '@/models/Teams/ActualDivisionTeamInfo'
import { TeamCoachInfo } from 'src/GeneratedTypes/ListInfo/TeamCoachInfo'

import {
  getEmptyDivisionTeamInfo,
  getTotalYearsExperience,
} from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import { toDate } from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { UpwardTapeDownReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardTapeDownReasonTypeID'
import { getTotalTeamEvalScore } from '@/lib/support/models/ListInfo/DivisionTeamInfo/data'
import { LeagueCoachProgramInfoFLAT } from '@/GeneratedTypes/ListInfo/LeagueCoachProgramInfoFLAT'

export const getProps = () => ({
  team: {
    type: Object as PropType<ActualDivisionTeamInfo>,
    required: true,
    default: () => getEmptyDivisionTeamInfo(),
  },
  tapeDownReasons: {
    required: false,
    type: Array as PropType<UpwardTapeDownReasonTypeID[]>,
    default: () => [],
  },
  selectedCoach: {
    type: Object as PropType<LeagueCoachProgramInfoFLAT>,
    required: false,
    default: () => null,
  },
})

export function useTeamCard(props: any, ctx: SetupContext) {
  function droppingCoaches(e: DropResult) {
    // addedIndex is non-zero for a drop on a target
    if (e.addedIndex != null && e.addedIndex >= 0) {
      const coach = e.payload?.coach as LeagueVolunteer
      if (coach) {
        ctx.emit('add-coach', props.team.teamID, coach)
      }
    }
  }

  function acceptingCoaches(e: DropResult, a: Record<string, unknown>) {
    return a && a.coach
  }

  function removeCoach(teamID: number, coachID: number) {
    ctx.emit('remove-coach', teamID, coachID)
  }

  function changeHeadCoach(teamID: number, coachID: number) {
    ctx.emit('change-head-coach', teamID, coachID)
  }

  const practiceInfo = computed(() => {
    if (props.team.practices?.length) {
      const dayName =
        days.find((x) => x.value == toDate(props.team!.practices![0].practiceStart).getDay())?.abbr3 ?? ''
      const time = dayjs(toDate(props.team.practices[0].practiceStart)).format('h:mm')
      const time2 = dayjs(toDate(props.team.practices[0].practiceEnd)).format('h:mm A')
      return `${dayName} ${time}-${time2}`
    }
    return ''
  })

  /**
   * Returns the team's cumulative eval score.
   */
  const evalScore = computed(() => {
    if (props.team.players?.length) {
      const score = getTotalTeamEvalScore(props.team) / props.team.players?.length
      if (!score || score <= 0) {
        return ''
      }
      return score?.toFixed(2)
    }
    return ''
  })

  /**
   * Returns the team's cumulative years of experience.
   */
  const yearsExperienceAvg = computed(() => {
    if (props.team.players?.length) {
      const yearsExperience = getTotalYearsExperience(props.team) / props.team.players?.length
      return yearsExperience.toFixed(1)
    }
    return ''
  })

  const showAssignCoach = computed(
    () =>
      props.selectedCoach &&
      !props.team.coaches?.find((x: TeamCoachInfo) => x.individualID == props.selectedCoach.individualID)
  )

  function addSelectedCoach() {
    if (props.selectedCoach) {
      ctx.emit('add-coach', props.team.teamID, props.selectedCoach)
    }
  }

  return {
    droppingCoaches,
    acceptingCoaches,
    removeCoach,
    changeHeadCoach,
    practiceInfo,
    evalScore,
    yearsExperienceAvg,
    showAssignCoach,
    addSelectedCoach,
  }
}
