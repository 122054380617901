






































import { computed, defineComponent, ref, watch, onBeforeUnmount, onMounted } from '@vue/composition-api'

import { useReports } from '@/views/Programs/Divisions/TeamManagement/logic/reports'

import teamsClient from '@/clients/teamsClient'
import divisionsClient from '@/clients/divisionsClient'

import { TeamGeneratorGuidelineInfo } from '@/GeneratedTypes/ListInfo/TeamGeneratorGuidelineInfo'

import Modal from '@/components/Modal.vue'
import AutoTeamPlayerAssignmentChoices from '@/views/Programs/Divisions/vues/AutoTeamPlayerAssignmentChoices.vue'
import BrokenCarpoolLinkReport from '@/views/Programs/Divisions/TeamManagement/vues/reports/BrokenCarpoolLinkReport.vue'

export default defineComponent({
  props: {
    show: { type: Boolean, required: true },
    teamSquadText: { type: String, required: true },
    divisionId: { type: Number, required: false, default: false },
    hasPlayersAssigned: { type: Boolean, required: false, default: false },
    typeProgramId: { type: String, required: false, default: '' },
    upwardLeagueId: { type: String, required: false, default: '' },
  },
  components: {
    Modal,
    BrokenCarpoolLinkReport,
    AutoTeamPlayerAssignmentChoices,
  },
  setup(props, ctx) {
    const reports = useReports()
    const draftingMode = ref('DRAFTUNLOCKEDONLY')
    const guidelineInfo = ref<TeamGeneratorGuidelineInfo | null>(null)
    const modalIsVisible = ref(false)
    const practiceNightsFirst = ref(false)
    const overrideWarning = ref(false)

    const lowerCaseTeamSquadText = computed(() => props.teamSquadText.toLowerCase())
    modalIsVisible.value = props.show

    async function draftParticipants() {
      if (props.upwardLeagueId && props.divisionId && props.typeProgramId) {
        await divisionsClient.runPlayerDraft(
          props.upwardLeagueId,
          props.typeProgramId,
          props.divisionId,
          draftingMode.value,
          practiceNightsFirst.value
        )
        ctx.emit('playersDrafted')
      }
      modalIsVisible.value = false
    }

    async function fetchAndSetBrokenLinks() {
      await reports.fetchAndSetBrokenCarpoolLinks(props.upwardLeagueId, props.typeProgramId, props.divisionId)
    }

    async function doShowAutoGenerationModal() {
      if (props.upwardLeagueId && props.divisionId && props.typeProgramId) {
        guidelineInfo.value = await teamsClient.getTeamGenerationGuidelines({
          leagueId: props.upwardLeagueId,
          divisionId: props.divisionId,
          typeProgramId: props.typeProgramId,
        })
      } else {
        guidelineInfo.value = null
      }

      if (guidelineInfo.value) {
        modalIsVisible.value = true
      }
    }

    function hideAutoGenerationModal() {
      modalIsVisible.value = false
    }

    function overrideBrokenLinkWarning(val: boolean) {
      overrideWarning.value = val
    }

    function optionsSelected(choices: { drafting: string; practiceNightsFirst: boolean }) {
      draftingMode.value = choices.drafting
      practiceNightsFirst.value = choices.practiceNightsFirst
    }

    function goToParticipants() {
      const current = ctx.root.$route.fullPath.split(props.upwardLeagueId)
      ctx.root.$router.push(`${current[0]}${props.upwardLeagueId}/participants/list`)
    }

    const brokenLinksCount = computed(() => reports.reportCarpoolLinks.value.length)
    const readyToDraft = computed(() => {
      return brokenLinksCount.value == 0 || overrideWarning.value == true
    })

    watch(
      () => modalIsVisible.value,
      async () => {
        if (modalIsVisible.value != props.show) {
          ctx.emit('update', modalIsVisible.value)
        }
        if (modalIsVisible.value) {
          await doShowAutoGenerationModal()
        }
        overrideWarning.value = false
      }
    )

    watch(
      () => props.show,
      () => (modalIsVisible.value = props.show)
    )

    watch(
      () => props.divisionId,
      async () => await fetchAndSetBrokenLinks()
    )

    watch(
      () => props.typeProgramId,
      async () => await fetchAndSetBrokenLinks()
    )

    onMounted(async () => await fetchAndSetBrokenLinks())

    onBeforeUnmount(() => {
      ctx.emit('update', false)
      overrideWarning.value = false
    })

    return {
      hideAutoGenerationModal,
      draftParticipants,
      guidelineInfo,
      lowerCaseTeamSquadText,
      draftingMode,
      modalIsVisible,
      practiceNightsFirst,
      reportCarpoolLinks: reports.reportCarpoolLinks,
      optionsSelected,
      readyToDraft,
      overrideBrokenLinkWarning,
      brokenLinksCount,
      goToParticipants,
    }
  },
})
