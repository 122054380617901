import { Connect } from '@/GeneratedTypes/Connect'
import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'

export function getEmptyConnect(): Connect {
  return {
    connectDate: new Date(),
    id: 0,
    typeLeagueID: '',
    leagueName: '',
    typeProductGroupID: '',
    upwardLeagueID: null,
    programMissionObjective: '',
    typeTimezoneID: 'EASTERN',
    typeCountryID: 'US',
    leagueDirectorPartnerID: 0,
    partnerContact: getEmptyContact(),
    canEdit: true,
    canEditReason: null,
    playerEstimate: null,
    leagueFinderURLOverride: null,
    programs: [],
    gradeDescriptions: [],
  }
}
