






































import { defineComponent, ref, computed, watch, nextTick, onUnmounted } from '@vue/composition-api'
import { FocusTrap } from 'focus-trap-vue'

export default defineComponent({
  name: 'Modal',
  components: {
    FocusTrap,
  },
  props: {
    value: { type: Boolean, required: true },
    title: { type: String, default: '' },
    isSmall: { type: Boolean, required: false, default: false },
    showClose: { type: Boolean, required: false, default: true },
    headerClass: { type: String, required: false, default: 'bg-dark text-white' },
    headerIcon: { type: String, required: false, default: '' },
    closeIconClass: { type: String, required: false, default: 'fa fa-times' },
    /*
    ** renderConditionally = true uses v-if (pulls the modal in and out of the DOM)
    ** renderConditionally = false uses v-show (toggle the display without 
    destroying/recreating the component)
    */
    renderConditionally: { type: Boolean, required: false, default: true },
  },
  setup(props, ctx) {
    const enableFocusTrap = ref(false)

    function hide() {
      ctx.emit('input', false)
      ctx.emit('change', false)
    }

    onUnmounted(() => {
      // In some cases the parent component nagivates to a new
      // route without closing the modal (value = false).
      if (isVisible.value) {
        closeModal()
      }
    })

    function onBodyScroll() {
      ctx.emit('scroll')
    }

    const isVisible = computed(() => props.value)

    function disableBodyScrolling() {
      //stop page underneath modal from scrolling
      const el = document.body
      el.classList.add('hide-overflow')
    }

    function enableBodyScrolling() {
      const el = document.body
      el.classList.remove('hide-overflow')
    }

    function closeModal() {
      enableFocusTrap.value = false
      enableBodyScrolling()
      return
    }

    watch(
      () => isVisible.value,
      () => {
        if (!isVisible.value) {
          closeModal()
        } else {
          disableBodyScrolling()
        }

        // Set the focus on the first control in the modal. Use a nextTick to allow the modal
        // to fully render it's child elements
        nextTick(() => {
          const modalBody = ctx.refs['modalBody'] as HTMLDivElement | undefined
          if (!modalBody) {
            return
          }

          const firstElement = modalBody?.querySelector('input, select, textarea, button') as
            | HTMLInputElement
            | HTMLSelectElement
            | HTMLTextAreaElement
            | HTMLButtonElement
            | null

          if (firstElement) {
            // Only enable the focus trap if there is an element that can be focused
            enableFocusTrap.value = true

            // I don't understand why, but nextTick was not working for setting the focus. This
            // setTimeout however does cause the first control to gain focus
            setTimeout(() => firstElement.focus(), 250)
          }
        })
      }
    )

    return {
      isVisible,
      enableFocusTrap,
      hide,
      onBodyScroll,
    }
  },
})
