











































































import { defineComponent, watch, ref, computed, PropType } from '@vue/composition-api'
import { getProps, useTextlike } from '@/composables/TextLike'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import { getEmptyPhone, PhoneSeedInfo } from '@/lib/support/models/IndividualPhone/data'
import PhoneTypeSelect from '@/components/PhoneTypeSelect.vue'
import TextInput from '@/elements/TextInput.vue'
import TextLikeWrapper from '@/elements/TextLikeWrapper.vue'
import MaskedInputElement from '@/elements/MaskedInputElement.vue'
import { cleanPhoneNumber } from '@/services/phoneNumberService'

export default defineComponent({
  name: 'PhoneInputMask',
  components: {
    MaskedInputElement,
    TextInput,
    TextLikeWrapper,
    PhoneTypeSelect,
  },
  props: {
    value: {
      type: Object as PropType<IndividualPhone>,
      required: true,
      default: getEmptyPhone(new PhoneSeedInfo()),
    },
    placeholder: { type: String, required: false, default: '' },
    showExtension: { type: Boolean, default: false, required: false },
    showPhoneType: { type: Boolean, default: false, required: false },
    ...getProps(),
  },
  setup(props, ctx) {
    const {
      iconClick,
      internalValue,
      handleInput,
      handleChange,
      handleBlur,
      input,
    } = useTextlike<IndividualPhone>(props, ctx)

    const phoneTypeId = ref(props.value.typePhoneID)
    const extension = ref(props.value.extension)

    const internalNumber = ref(cleanPhoneNumber(props.value?.phoneNumber ?? ''))

    const hasOneOption = computed(
      () => (props.showExtension && !props.showPhoneType) || (!props.showExtension && !props.showPhoneType)
    )

    const hasNoOptions = computed(() => !props.showExtension && !props.showPhoneType)

    const hasTwoOptions = computed(() => props.showExtension && props.showPhoneType)

    const handleLocalChange = ref<((val: string) => void) | undefined>()
    const handleLocalBlur = ref<((val: string) => void) | undefined>()
    const handleLocalInput = ref<((val: string) => void) | undefined>()

    /**
     * Take an event handler and make sure it stores/processes
     * the intermediate value and then kick to the default handler
     * handler
     * @param handler
     */
    function handleAndKick(handler: () => void): (val: string) => void {
      return (val: string) => {
        internalNumber.value = cleanPhoneNumber(val)
        if (internalValue.value) {
          internalValue.value.phoneNumber = internalNumber.value ?? ''
        }
        handler()
      }
    }

    //created()
    //event handlers need to process intermediate value
    handleLocalBlur.value = handleAndKick(handleBlur)
    // SF does not property send an input event but Vue's
    // two-way binding expects input on a custom component
    handleLocalChange.value = handleAndKick(handleInput)
    handleLocalInput.value = handleAndKick(handleInput)

    function handleTypeChange(val: string) {
      phoneTypeId.value = val
      if (internalValue.value) {
        internalValue.value.typePhoneID = phoneTypeId.value
      }
      input() //sf register change, we register input
    }

    function handleExtChange(val: string) {
      extension.value = val
      if (internalValue.value) {
        internalValue.value.extension = extension.value
      }
      input() //sf register change, we register input
    }

    watch(
      () => props.value,
      () => {
        if (internalValue.value) {
          if (
            internalValue.value.extension !== props.value.extension ||
            internalValue.value.phoneNumber !== props.value.phoneNumber ||
            internalValue.value.typePhoneID !== props.value.typePhoneID
          ) {
            internalNumber.value = cleanPhoneNumber(props.value.phoneNumber)
            phoneTypeId.value = props.value.typePhoneID
            extension.value = props.value.extension
          }
        } else {
          //likely first pass.  set initial value
          internalValue.value = {
            extension: props.value.extension,
            phoneNumber: cleanPhoneNumber(props.value.phoneNumber),
            phoneID: props.value.phoneID,
            typePhoneID: props.value.typePhoneID,
            priority: props.value.priority,
            allowSMS: props.value.allowSMS,
          } as IndividualPhone

          //also set internal control values
          internalNumber.value = cleanPhoneNumber(props.value.phoneNumber)
          phoneTypeId.value = props.value.typePhoneID
          extension.value = props.value.extension
        }
      },
      { deep: true, immediate: true }
    )

    return {
      iconClick,
      internalValue,
      handleInput,
      handleChange,
      handleBlur,
      hasTwoOptions,
      hasOneOption,
      hasNoOptions,
      internalNumber,
      handleLocalBlur,
      handleLocalChange,
      handleLocalInput,
      extension,
      handleExtChange,
      phoneTypeId,
      handleTypeChange,
    }
  },
})
