

















































































































import Stepper from '@/elements/Stepper.vue'

import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import { Getter } from 'vuex-class'
import * as ordersStore from '@/store/orders'
import { OrderProductSelection } from '@/store/orders'
import { OrderProduct, OrderProductLine } from '@/models/Order/OrderProduct.ts'
import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'
import OrderSummary from '@/components/Orders/OrderSummary.vue'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import StepperStep from '@/elements/StepperStep.vue'
import StepperContent from '@/elements/StepperContent.vue'
import { getEmptyXStartupOrderExt } from '@/lib/support/models/GeneratedTypes/xOrders/xStartupOrderExt'
import store from '@/store'
import {
  ConfigurationWithoutPromoCard,
  ConfigurationWithPromoCard,
  ConfigurationForManualOrder,
  StepProperties,
} from '@/views/Programs/Orders/orders/lib/OrderConfiguration'
import Cart from '@/components/Orders/Cart.vue'
import { Route } from 'vue-router'
import { useNoProductSelector } from '@/views/Programs/Orders/compositions/useProductSelector'
import { computed } from '@vue/composition-api'

@Component({
  components: {
    Loading,
    TextInput,
    Stepper,
    StepperContent,
    Cart,
    StepperStep,
    OrderSummary,
  },
})
export default class Order extends Vue {
  @Prop({ type: String, required: true })
  orderType!: OrderTypesEnum
  // optional string, when it changes we look for a step and see if we can advance there.
  @Prop({ type: String, required: false, default: '' })
  orderStep!: string

  retrieveAndSetAsCurrent = store.dispatch.orders.retrieveAndSetAsCurrent
  retrieveLastOrder = store.dispatch.orders.retrieveLastOrder
  validateOrder = store.dispatch.orders.validateOrder
  createOrder = store.dispatch.orders.createOrder
  setProductQuantity = store.dispatch.orders.setProductQuantity
  resetSalesTaxAndShipping = store.commit.orders.resetSalesTaxAndShipping

  @Getter(ordersStore.getterNames.currentOrderProducts, { namespace: ordersStore.namespace })
  private readonly currentOrderProducts!: OrderProduct[]

  @Getter(ordersStore.getterNames.productsSelected, { namespace: ordersStore.namespace })
  private readonly productsSelected!: OrderProductSelection[]

  @Getter(ordersStore.getterNames.currentOrderTemplate, { namespace: ordersStore.namespace })
  private readonly currentOrderTemplate!: xStartupOrderExt

  @Getter(ordersStore.getterNames.lastCompletedOrder, { namespace: ordersStore.namespace })
  private readonly lastCompletedOrder!: xStartupOrderExt

  private loading = false

  disabled: number[] = []

  get productSelectorLogic() {
    if (this.currentStep == this.steps.length) {
      return useNoProductSelector({
        currentOrderProductsRef: computed(() => store.getters.orders.currentOrderProducts),
      })
    }
    return this.orderConfig.productSelectorLogicGenerator({
      currentOrderProductsRef: computed(() => store.getters.orders.currentOrderProducts),
    })
  }

  private async mounted() {
    // the first declaration sets the type, the second sets the bound methods.
    this.retrieveAndSetAsCurrent = store.dispatch.orders.retrieveAndSetAsCurrent
    this.retrieveLastOrder = store.dispatch.orders.retrieveLastOrder
    this.validateOrder = store.dispatch.orders.validateOrder
    this.createOrder = store.dispatch.orders.createOrder
    this.setProductQuantity = store.dispatch.orders.setProductQuantity
    this.resetSalesTaxAndShipping = store.commit.orders.resetSalesTaxAndShipping

    await this.reloadOrder()
  }

  private get title() {
    switch (this.orderType) {
      case OrderTypesEnum.gameday:
        return 'Merchandise Order'
      case OrderTypesEnum.startup:
        return 'Startup Order'
      case OrderTypesEnum.clinic:
        return 'Clinic Order'
      case OrderTypesEnum.manual:
        return 'Manual Order'
    }
  }

  /**
   * load order into store.
   */
  async reloadOrder() {
    this.loading = true
    await this.retrieveAndSetAsCurrent({ id: this.$route.params.id, orderType: this.orderType })

    //In case this is a browser refresh after an order has
    // been placed, go get the data from the last order.
    // This information is used on the confirmation page.
    await this.retrieveLastOrder({ orderType: this.orderType, upwardLeagueID: this.$route.params.id })
    this.loading = false
  }

  @Watch('$route')
  routeChanged(newroute: Route, oldroute: Route) {
    if (
      newroute.params.step == this.steps[0].label.toLocaleLowerCase() &&
      oldroute.params.step == this.steps[this.steps.length - 1].label.toLocaleLowerCase()
    ) {
      this.resetState()
    }
    if (
      this.currentOrderTemplate &&
      this.orderType == this.currentOrderTemplate.upwardOrderType &&
      newroute.params.step == this.steps[0].label.toLocaleLowerCase()
    ) {
      this.resetSalesTaxAndShipping({ orderType: this.orderType })
    }
  }

  @Watch('orderType')
  orderTypeChanged() {
    if (this.orderType) {
      this.resetState()
      this.reloadOrder()
    }
  }

  @Watch('currentStep', { immediate: true })
  currentStepChanged() {
    this.orderStepChanged()
  }

  /***
   * Communicates with the outside component, which might change route, or ignore the event
   */
  @Emit()
  orderStepChanged() {
    //0-index array steps are 1...n
    return this.steps[this.currentStep - 1].label
  }

  @Watch('orderStep')
  orderStepUpdated(step: string) {
    const stepIndex = this.steps.findIndex((x) => x.label.toLocaleUpperCase() == step.toLocaleUpperCase())
    if (stepIndex >= 0) {
      //0-index array but steps are 1...n
      if (stepIndex + 1 != this.currentStep) {
        this.nextStep(stepIndex + 1)
      }
    }
  }

  /**
   * Remove product will be a normalized product
   */
  removeProduct(p: OrderProductLine) {
    this.setProductQuantity({
      orderType: this.orderType,
      productID: p.id,
      sizeID: p.productColorSize.typeSizeID, //should be normalized by caller to have 1-index
      colorID: p.productColorSize.typeColorID,
      quantity: 0,
      quantityFree: 0,
    })
  }

  resetState() {
    this.currentStep = 1
    this.maxTouchedStep = 1
    this.disabled = []
  }

  /**
   * Gets the order steps and selection logic
   */
  private get orderConfig() {
    if (this.orderType == OrderTypesEnum.manual) {
      return ConfigurationForManualOrder
    }

    if (!this.currentOrderProducts || this.currentOrderProducts.length == 0) {
      return ConfigurationWithoutPromoCard
    }

    if (this.currentOrderProducts.find((x) => x.upwardProductID == 'PROMOCARD')) {
      return ConfigurationWithPromoCard
    } else {
      return ConfigurationWithoutPromoCard
    }
  }

  /**
   * Order steps are calculated from the product list.
   */
  private get steps() {
    return this.orderConfig.steps
  }
  /**
   * @todo waiting on #2506 not to return an error
   */
  private orderingWorks = true

  private async retrieveTaxesAndShipping() {
    if (this.orderingWorks) {
      this.loading = true
      try {
        await this.validateOrder({ orderType: this.orderType, id: this.$route.params.id })
      } finally {
        this.loading = false
      }
    }
  }

  private get minDisabledStep() {
    if (this.disabled.length) {
      return this.disabled.sort((a, b) => (a == b ? 0 : a > b ? 1 : -1))[0]
    }
    return Number.MAX_SAFE_INTEGER
  }

  private get isOnLastStep() {
    return this.currentStep == this.steps.length
  }

  private get orderInfo() {
    if (this.isOnLastStep) {
      return this.lastCompletedOrder || getEmptyXStartupOrderExt()
    } else {
      return this.currentOrderTemplate
    }
  }

  private async placeOrder() {
    if (this.orderingWorks) {
      this.loading = true
      try {
        await this.createOrder({ id: this.$route.params.id, orderType: this.orderType })
      } finally {
        this.loading = false
      }
    }
  }

  enable(step: number) {
    this.disabled = this.disabled.filter((x) => x != step)
  }

  disable(step: number) {
    this.disabled = this.disabled.filter((x) => x != step)
    this.disabled.push(step)
  }

  /**
   * pulls a property from the steps.
   * @param key
   * @private
   */
  private getStepProperty(key: keyof StepProperties) {
    const index = this.currentStep
    if (!this.steps || index < 1 || index > this.steps.length) {
      return ''
    }

    return this.steps[index - 1][key]
  }

  /**
   * Current step is 0 for uninitialized, and n+1 in the step array.
   * @private
   */
  private currentStep = 1

  /**
   * Set current step, 0=uninitialized, 1 corresponds to the step array above.
   * @param n step
   * @private
   */
  private async nextStep(n: number) {
    // if we are confirming an order then set the place order.
    if (this.steps[n - 1].label == 'CONFIRM') {
      await this.placeOrder()
    }
    if (this.steps[n - 1].label == 'REVIEW' /*|| this.steps[n - 1].label == 'SHIPPING'*/) {
      await this.retrieveTaxesAndShipping()
    }

    // if we are on the last step, can't undo.
    if (this.currentStep < this.steps.length) {
      this.currentStep = n
      this.maxTouchedStep = Math.max(this.currentStep, this.maxTouchedStep)
    }
  }

  private maxTouchedStep = 1
}
