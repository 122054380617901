



































































































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

import DivisionHeader from '@/views/Programs/Divisions/vues/DivisionHeader.vue'
import TeamManager from '@/views/Programs/Divisions/TeamManagement/TeamManager.vue'
import DraftSummaryReportModal from '@/views/Programs/Divisions/vues/DraftSummaryReportModal.vue'
import FilterModal from '@/views/Programs/Divisions/TeamManagement/vues/FilterModal.vue'
import ReportFooter from '@/views/Programs/Divisions/TeamManagement/vues/ReportFooter.vue'
import AutoTeamPlayerAssignmentModal from '@/views/Programs/Divisions/vues/AutoTeamPlayerAssignmentModal.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'

import { getEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import { HeaderValue } from '@/views/Programs/Divisions/ext/headerValue'
import { getUpwardLeagueId } from '@/views/Programs/Divisions/ext/divisionUtils'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { useManagerLogic } from '@/views/Programs/Divisions/TeamManagement/logic/ManagerLogic'
import { useReports } from '@/views/Programs/Divisions/TeamManagement/logic/reports'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

import divisionsClient from '@/clients/divisionsClient'
import teamsClient from '@/clients/teamsClient'
import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

export default defineComponent({
  name: 'TeamManagement',
  components: {
    TeamManager,
    Loading,
    DivisionHeader,
    ReportFooter,
    FilterModal,
    Modal,
    AutoTeamPlayerAssignmentModal,
    DraftSummaryReportModal,
  },
  setup(props, ctx) {
    const headerValues = ref<HeaderValue | null>(null)
    const allLeagueDivisions = ref<LeagueDivisionInfo[] | null>(null)
    const manager = useManagerLogic()
    const reports = useReports()
    const userPrefs = userPreferences()

    const programID = ref('')
    const divisionID = ref(-1)
    const teamsCount = ref(99)
    const showFilters = ref(false)
    const showReports = ref(false)
    const showHeaders = ref(true)
    const showAutoGenerationModal = ref(false)
    const showDraftSummaryReport = ref(false)

    function toggleHeader(val: any) {
      showHeaders.value = val
    }

    const upwardLeagueId = computed(() => getUpwardLeagueId(ctx.root))

    const division = computed(() => headerValues.value?.selectedDivision)

    const refreshingAll = computed(() => manager.refreshing.value)

    const isLeague = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
    )

    const reportCount = computed((): number => {
      let count = 0
      const s = reports.summary.value

      if (s.brokenCarpoolLinkCount > 0) count++
      if (s.brokenCoachLinkCount > 0) count++
      if (s.missingEvalScoresCount > 0) count++
      if (s.coachLinksExceededCount > 0) count++

      return count
    })

    async function onDivisionChanged(hdrVals: HeaderValue | null) {
      if (hdrVals) {
        headerValues.value = hdrVals
      }

      if (divisionID.value != hdrVals?.selectedDivision.divisionID) {
        manager.setDivisionAndRefresh(hdrVals?.selectedDivision)
      }
      programID.value = hdrVals?.selectedTypeProgramID ?? ''
      divisionID.value = hdrVals?.selectedDivision?.divisionID ?? -1

      await setTeamsCount(divisionID.value, programID.value)
    }

    onMounted(async () => {
      allLeagueDivisions.value = await divisionsClient.retrieveAll(upwardLeagueId.value)
    })

    const divisionCount = computed(() => {
      let count = allLeagueDivisions.value ? allLeagueDivisions.value.length : -1
      if (programID.value && allLeagueDivisions.value) {
        const divisions = allLeagueDivisions.value.filter((d) => d.typeProgramID == programID.value)
        count = divisions ? divisions.length : -1
      }
      return count
    })

    async function setTeamsCount(divID: number | null, typeProgramID: string) {
      if (divID === -1) {
        divID = null
      }

      if (previousDivisionID.value === divID && previousTypeProgramID.value === typeProgramID) {
        return
      }

      if (divID && typeProgramID) {
        previousDivisionID.value = divID
        previousTypeProgramID.value = typeProgramID

        const teams = await teamsClient.retrieveTeamList({
          leagueId: upwardLeagueId.value,
          divisionId: divID,
          typeProgramId: typeProgramID!,
        })
        teamsCount.value = 0
        if (teams) {
          teamsCount.value = teams.length
        }
      }
    }

    function clearFilter() {
      userPrefs.resetFilters()
    }

    function doShowAutoGenerationModal() {
      showAutoGenerationModal.value = true
    }

    async function onPlayersDrafted() {
      await manager.refresh()
      showDraftSummaryReport.value = true
    }

    const hasPlayersAssigned = computed(() => !!manager.teamsOnField.value.find((t) => t.playerCount > 0))

    function getTeamSquadText(lower: boolean): string {
      if (
        headerValues.value &&
        headerValues.value.selectedDivision &&
        headerValues.value.cheerProgramTypeID === headerValues.value.selectedDivision.typeProgramID
      ) {
        return lower ? 'squad' : 'Squad'
      }
      return lower ? 'team' : 'Team'
    }

    const previousDivisionID = ref<number | null>(null)
    const previousTypeProgramID = ref<string | null>(null)

    watch(
      () => headerValues.value?.selectedDivision,
      () => {
        const div = headerValues.value?.selectedDivision
        reports.refresh(div?.upwardLeagueID ?? '', div?.typeProgramID ?? '', div?.divisionID ?? 0)
      },
      { deep: true }
    )

    return {
      getEmptyLeagueDivisionInfo,
      showHeaders,
      onDivisionChanged,
      refreshingAll,
      isLeague,
      teamsCount,
      clearFilter,
      showFilters,
      divisionCount,
      reportCount,
      showReports,
      doShowAutoGenerationModal,
      showAutoGenerationModal,
      programID,
      divisionID,
      headerValues,
      toggleHeader,
      division,
      getTeamSquadText,
      hasPlayersAssigned,
      onPlayersDrafted,
      showDraftSummaryReport,
    }
  },
})
