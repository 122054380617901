























































import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { cloneDeep } from 'lodash'

interface CommunicationsOptOutDataType {
  ContactType: string
  ParticipantFirstName: string
  ParticipantLastName: string
  ContactFirstName: string
  ContactLastName: string
  ContactCommunicationType: string
  EmailPhone: string
}
//References IReportService.cs and CommunicationsOptOutReport.cs in API
export default defineComponent({
  name: 'CommunicationsOptOutReport',
  props: {
    reportData: {
      type: Array as PropType<CommunicationsOptOutDataType[]>,
      required: true,
      default: () => [],
    },
    league: {
      type: String as PropType<UpwardLeagueIDType>,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const sortedData = ref<CommunicationsOptOutDataType[]>([])
    const cullData = () => (sortedData.value = cloneDeep(props.reportData))

    watch(
      () => [props.reportData],
      () => cullData(),
      { immediate: true }
    )

    return {
      sortedData,
      sortedbyASC: true,
    }
  },
  mounted() {
    this.sortedData
  },
  methods: {
    //Plumbing for sortList
    sortList(sortBy: any) {
      if (this.sortedbyASC) {
        this.sortedData.sort((x: any, y: any) => (x[sortBy] > y[sortBy] ? -1 : 1))
        this.sortedbyASC = false
      } else {
        this.sortedData.sort((x: any, y: any) => (x[sortBy] < y[sortBy] ? -1 : 1))
        this.sortedbyASC = true
      }
    },
  },
})
