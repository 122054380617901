














































import { defineComponent, ref, computed, PropType } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import store from '@/store'

export interface ProfileCredentials {
  newEmail: string
  newFullName: string
}

export default defineComponent({
  name: 'VerificationCode',
  props: { credentials: { type: Object as PropType<ProfileCredentials>, required: true, default: '' } },
  components: { TextInput, Loading },
  setup(props, { emit, root }) {
    const updateProfile = store.dispatch.authorization.updateProfile

    const code = ref('')
    const error = ref('')
    const loading = ref(false)
    const verificationComplete = ref(false)
    const canSave = computed(() => code.value.length >= 10)
    const isMultiAccountUser = computed(() => store.getters.authorization.isMultiAccountUser)
    const show = ref(false)
    function cancel() {
      emit('cancel')
    }

    async function verify() {
      await saveProfile()
    }

    async function saveProfile() {
      loading.value = true
      try {
        const r = await updateProfile({
          payload: {
            newUsername: props.credentials.newEmail,
            usernameChangeCode: code.value,
          },
        })
        verificationComplete.value = true
        if (r.incompleteToken) {
          setTimeout(() => {
            store.commit.authorization.clearCurrentCredentials() //aka logout
            root.$router.push('/')
          }, 6000)
        }
      } finally {
        loading.value = false
      }
    }

    return { code, verify, canSave, error, cancel, loading, verificationComplete, isMultiAccountUser, show }
  },
})
