



























import { defineComponent, computed } from '@vue/composition-api'
import MemoInput from '@/elements/MemoInput.vue'
import ShippingAddressSection from '@/components/ShippingAddressSection.vue'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import { xShipToBase } from '@/GeneratedTypes/xOrder/xShipToBase.ts'
import store from '@/store'
import TextInput from '@/elements/TextInput.vue'

export default defineComponent({
  name: 'Shipping',
  components: {
    MemoInput,
    ShippingAddressSection,
    TextInput,
  },
  setup(props, ctx) {
    const currentOrderShipTo = computed(() => store.getters.orders.currentOrderShipTo)
    const currentOrderTemplate = computed(() => store.getters.orders.currentOrderTemplate)
    const currentOrderNotes = computed(() => store.getters.orders.currentOrderNotes)

    const orderType = computed(() => ctx.root.$route.params.orderType as OrderTypesEnum)

    function commitAddress(address: xShipToBase) {
      store.commit.orders.setCurrentOrderShipTo({ orderType: orderType.value, item: address })
    }

    function specialRequestChange(newValue: string) {
      store.commit.orders.setCurrentOrderNotes({ orderType: orderType.value, item: newValue })
    }

    function purchaseOrderNumberChanged(newValue: string) {
      store.commit.orders.setPurchaseOrderNumber({ orderType: orderType.value, item: newValue })
    }

    return {
      currentOrderShipTo,
      commitAddress,
      currentOrderNotes,
      specialRequestChange,
      currentOrderTemplate,
      purchaseOrderNumberChanged,
    }
  },
})
