import { LeagueVolunteerRoleUDFDefinition } from '@/GeneratedTypes/LeagueVolunteerRoleUDFDefinition'
import { LeaguePlayerUDFDefinition } from '@/GeneratedTypes/LeaguePlayerUDFDefinition'
import { TypeLeagueVolunteerRole } from '@/GeneratedTypes/TypeLeagueVolunteerRole'

interface ProgramWithUdfs {
  roles: TypeLeagueVolunteerRole[] | null
  playerUDFs: LeaguePlayerUDFDefinition[] | null
}

export function getCoachUdfs(program: ProgramWithUdfs | null): LeagueVolunteerRoleUDFDefinition[] {
  if (!program || !program.roles) {
    return []
  }

  const coachRoleResults = program.roles.filter((role) => role.id === 3)
  if (coachRoleResults && coachRoleResults.length > 0) {
    return coachRoleResults[0].udfDefinitions ?? []
  }
  return []
}

export function updateUdfs(
  program: ProgramWithUdfs | null,
  playerUdfs: LeaguePlayerUDFDefinition[],
  coachUdfs: LeagueVolunteerRoleUDFDefinition[]
) {
  if (!program) {
    return
  }

  program.playerUDFs = playerUdfs

  if (!program.roles) {
    return
  }

  const coachRoleResults = program.roles.filter((role) => role.id === 3)
  if (coachRoleResults && coachRoleResults.length > 0) {
    coachRoleResults[0].udfDefinitions = coachUdfs
  }
}
