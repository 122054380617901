












import { defineComponent, PropType } from '@vue/composition-api'
import SectionHeader from '@/components/SectionHeader.vue'
import ShippingAddressDisplay from '@/components/ShippingAddressDisplay.vue'
import AddAddressModal from '@/components/AddAddressModal.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { xShipToBase } from 'src/GeneratedTypes/xOrder/xShipToBase'
import { getEmptyxShipToBase } from '@/lib/support/models/GeneratedTypes/xOrders/xShipToBase.ts'

export default defineComponent({
  name: 'ShippingAddressSection',
  components: {
    SectionHeader,
    ShippingAddressDisplay,
    AddAddressModal,
    InputLabel,
  },
  props: {
    value: { type: Object as PropType<xShipToBase>, default: getEmptyxShipToBase, required: false },
    editable: { type: Boolean, default: false, required: false },
    showTitle: { type: Boolean, default: true, required: false },
  },
})
