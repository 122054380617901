



























































































































































































































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { cloneDeep } from 'lodash'

import { ProgramTypes } from '@/lib/common/ProgramTypes'

import LeagueSetupMixin from '@/views/Leagues/LeagueSetupMixin'
import Loading from '@/elements/Loading.vue'

import { getCoachUdfs } from '@/services/udfService'
import { getGradesList, getProperGradeLabel } from '@/services/gradeService'
import {
  getProgramRegistrationOption,
  setProgramRegistrationOption,
  RegistrationType,
} from '@/services/registrationInfoService'
import { getGradesListFromAccount } from '@/services/gradeService'

import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount.ts'
import { League } from '@/GeneratedTypes/League'

import date from '@/filters/date'
import properCase from '@/filters/properCase'
import currency from '@/filters/currency'
import { formatPhoneNumber } from '@/services/phoneNumberService'

import offeringClient from '@/clients/productOfferingClient'

import * as leaguesStore from '@/store/leagues'
import { ProductOfferingInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingInfo'

@Component({
  components: {
    Loading,
  },
  filters: {
    date,
    properCase,
    currency,
  },
  methods: {
    formatPhoneNumber,
    date,
    getProperGradeLabel,
  },
})
export default class Archive extends Mixins(LeagueSetupMixin) {
  @Getter(leaguesStore.getterNames.currentItem, { namespace: leaguesStore.namespace })
  private currentLeague!: League

  private girlGrades: string[] = []
  private boyGrades: string[] = []
  private cheerGrades: string[] = []
  private internalPracticeNights: string[] = []

  private registrationOption: RegistrationType = { registrationTypes: [], onlineRegistrationType: '' }

  private get hasCheer(): boolean {
    return (this.league.programs && this.league.programs.length > 1) ?? false
  }

  private get typeProgramId(): string {
    if (this.league.programs && this.league.programs.length > 0) {
      return this.league.programs[0].typeProgramID
    }
    return ''
  }

  private get hasEarlyRegistration(): boolean {
    const isDate = this.league.lateDate instanceof Date && !isNaN(this.league.lateDate.valueOf())

    if (isDate) {
      const date = this.league.lateDate as Date
      return date !== null
    }

    return this.league.lateDate !== null
  }

  @Watch('league.practiceNights', { immediate: true })
  private onLeaguePracticeNightsChange() {
    this.internalPracticeNights = []

    if (!this.league.practiceNights) {
      return
    }

    for (const pn of this.league.practiceNights) {
      this.internalPracticeNights.push(pn.typeDayID)
    }
  }

  private onInternalPracticeNightsChange() {
    this.league.practiceNights = []

    for (const typeDayID of this.internalPracticeNights) {
      this.league.practiceNights.push({
        typeDayID,
        startTime: '00:00:01',
        endTime: '23:59:59',
      })
    }
  }

  @Watch('initialLoadDone')
  private async onInitialLoadDone() {
    this.setGradesValue()
    this.setRegistrationOption()
    await this.getProducts()
  }

  formattedProgram(programTypeID: string) {
    return ProgramTypes[programTypeID]
  }
  productsLoading = false
  productList: (ProductOfferingInfo | null)[] = []
  async getProducts() {
    //get all product offering ids across all league types
    const offeringIds = [
      ...new Set(this.currentLeague.productOfferingConfig?.details?.map((p) => p.productOfferingID)),
    ]

    //build and execute api calls; one call for each offeringId
    const calls: Promise<ProductOfferingInfo | null>[] = []
    offeringIds.forEach((id: number) => calls.push(offeringClient.retrieveChildOfferings(id)))

    this.productsLoading = true
    const results = await Promise.all(calls)
    this.productsLoading = false

    //get all product ids across all league types
    const selProducts = this.currentLeague.productOfferingConfig?.details?.map((p) => {
      return { productOfferingID: p.productOfferingID, productID: p.productID }
    })

    //get list of selected products
    this.productList = this.filterToSelected(results, selProducts)
  }

  filterToSelected(
    results: (ProductOfferingInfo | null)[],
    selProducts: { productOfferingID: number; productID: number }[] | undefined
  ) {
    if (!selProducts) return []

    //remove fees
    results.forEach((r) => {
      if (r && r.categories?.length) {
        r.categories = r.categories.filter(
          (c) =>
            c.typeProductOfferingOptionID != 'UPWARDFEES' && c.typeProductOfferingOptionID != 'UPWARDDISCOUNT'
        )
      }
    })

    //filter catalog to only those products selected by the league
    results.forEach((r) => {
      r?.categories?.forEach((c) => {
        const p = c.products?.filter((p) =>
          selProducts.some((s) => s.productOfferingID == r.id && s.productID == p.productID)
        )
        c.products = p ?? null
      })
    })

    return results
  }

  private setRegistrationOption() {
    const registrationOption = getProgramRegistrationOption(this.league)

    if (registrationOption !== null) {
      this.registrationOption = registrationOption
    }
  }

  @Watch('registrationOption', { deep: true })
  private updateRegistrationOptions() {
    if (this.loading) {
      return
    }

    setProgramRegistrationOption(this.league, this.registrationOption)
  }

  private setGradesValue() {
    if (this.league.programs && this.league.programs.length > 0) {
      let typeProgramId = this.league.programs[0].typeProgramID
      this.boyGrades = cloneDeep(getGradesList(this.league, typeProgramId, 'M')).map((x) =>
        this.isByAge ? x.ageByCutoff.toString() : x.description!
      )
      this.girlGrades = cloneDeep(getGradesList(this.league, typeProgramId, 'F')).map((x) =>
        this.isByAge ? x.ageByCutoff.toString() : x.description!
      )

      if (this.league.programs.length > 1) {
        typeProgramId = this.league.programs[1].typeProgramID
        this.cheerGrades = cloneDeep(getGradesList(this.league, typeProgramId, 'F')).map((x) =>
          this.isByAge ? x.ageByCutoff.toString() : x.description!
        )
      }
    }
  }

  private getCoachUdfList() {
    return getCoachUdfs(this.league)
  }

  private getGradeString(account: LeagueAccount, isBoys: boolean, isCheer: boolean): string {
    if (account) {
      if (isCheer) {
        if (this.league.programs != null && this.league.programs.length > 0) {
          const programTypeId = this.league.programs[1].typeProgramID
          const cheerGrades = cloneDeep(getGradesListFromAccount(account, programTypeId, '')).map((x) =>
            this.isByAge ? x.ageByCutoff.toString() : x.description!
          )
          if (!cheerGrades || cheerGrades.length === 0) {
            return '-'
          }
          return `${cheerGrades[0]} - ${cheerGrades[cheerGrades.length - 1]}`
        }
      }
      if (isBoys) {
        const boyGrades = cloneDeep(getGradesListFromAccount(account, this.typeProgramId, 'M')).map((x) =>
          this.isByAge ? x.ageByCutoff.toString() : x.description!
        )
        if (!boyGrades || boyGrades.length === 0) {
          return '-'
        }
        return `${boyGrades[0]} - ${boyGrades[boyGrades.length - 1]}`
      }
      const girlGrades = cloneDeep(getGradesListFromAccount(account, this.typeProgramId, 'F')).map((x) =>
        this.isByAge ? x.ageByCutoff.toString() : x.description!
      )
      if (!girlGrades || girlGrades.length === 0) {
        return '-'
      }
      return `${girlGrades[0]} - ${girlGrades[girlGrades.length - 1]}`
    }
    return '-'
  }
}
