













































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { League } from '@/GeneratedTypes/League'
import { DivisionSquadMap, getEmptyDivisionSquadMap } from '@/models/Scheduling/DivisionSquadMap'
import { isEqual, cloneDeep } from 'lodash'
@Component({})
export default class CheerSchedulingGrid extends Vue {
  @Prop({ required: true, default: () => [] })
  divisionsList!: LeagueDivisionInfo[]

  @Prop({ required: true, default: () => getEmptyLeague() })
  league!: League

  @Prop({ type: Boolean, required: false, default: () => false })
  disabled!: League

  @Prop({ type: Array, required: false, default: () => [] })
  value!: DivisionSquadMap
  internalValue: DivisionSquadMap = getEmptyDivisionSquadMap()

  @Watch('value', { immediate: true })
  valueUpdated() {
    this.internalValue = cloneDeep(this.value)
  }

  @Watch('internalValue')
  internalValueUpdated() {
    if (!isEqual(this.value, this.internalValue)) {
      this.input()
    }
  }
  @Emit()
  input() {
    return this.internalValue
  }

  isMapping(sportDivision: number, cheerDivision: number) {
    return this.getMappingIndex(sportDivision, cheerDivision) >= 0
  }
  getMappingIndex(sportDivision: number, cheerDivision: number) {
    return this.internalValue.findIndex(
      (x) => x.sportDivisionID == sportDivision && x.cheerDivisionID == cheerDivision
    )
  }

  /**
   * Turn on or off the combo
   * @param sportDivision
   * @param cheerDivision
   */
  toggleMapping(sportDivision: number, cheerDivision: number) {
    const isConnected = this.isMapping(sportDivision, cheerDivision)
    if (isConnected) {
      const mappingIndex = this.getMappingIndex(sportDivision, cheerDivision)
      this.internalValue.splice(mappingIndex, 1)
    } else {
      this.internalValue.push({ sportDivisionID: sportDivision, cheerDivisionID: cheerDivision })
    }
  }

  get canShowGrid() {
    return this.sportDivisions?.length && this.cheerDivisions?.length
  }

  get sportProgramID() {
    if (this.league?.programs?.length) {
      return this.league.programs[0].typeProgramID
    }
    return ''
  }
  get cheerProgramID() {
    if (this.league && this.league?.programs && this.league?.programs?.length > 1) {
      return this.league.programs[1].typeProgramID
    }
    return '' //should never happen with valid data
  }

  get sportDivisions() {
    return this.divisionsList.filter((x) => x.typeProgramID == this.sportProgramID)
  }
  get cheerDivisions() {
    return this.divisionsList.filter((x) => x.typeProgramID == this.cheerProgramID)
  }
}
