import { DivisionGameSchedulerFacility } from '@/GeneratedTypes/DivisionGameSchedulerFacility'
import { ScheduleBlocks } from '@/models/elements/Scheduler'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { timeStringToMilliseconds } from '@/lib/support/utilities/time/time'

/**
 * Converts optiosn for DivisionGame to ScheduleBlocks
 * @param a - accepts a filtered version (by type and facility) blocks and translates to schedule blocks
 * @constructor
 */
export function DivisionGameSchedulerFacilityToScheduleBlocks(
  a: DivisionGameSchedulerFacility[]
): ScheduleBlocks[] {
  return a.map((x) => ({
    day: days.find((y) => y.name == x.typeDayID)?.value || 0,
    timeEnd: timeStringToMilliseconds(x.endTime),
    timeStart: timeStringToMilliseconds(x.startTime),
  }))
}
