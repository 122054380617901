



















import { defineComponent, computed, ref, onUnmounted } from '@vue/composition-api'

import FullBody from '@/components/FullBody.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import Loading from '@/elements/Loading.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'

import store from '@/store'

import { Partner } from '@/models/Partner'

export default defineComponent({
  name: 'LeagueIndex',
  components: {
    FullBody,
    HorizontalTabs,
    Loading,
    AlertCloak,
  },
  setup(props, ctx) {
    const currentLeague = computed(() => store.getters.leagues.currentItem)

    const alertTypes = ref([AlertCloakTypes.PARTNER, AlertCloakTypes.LEAGUE])
    const uncloak = ref(false)

    const loading = ref(false)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const isDigital = computed(() => ctx.root.$route.path.indexOf('digitalleague') > 0)

    const tabs = computed(() => {
      if (isDigital.value) {
        return [
          { text: 'Setup', to: `/programs/digitalleague/${routeId.value}setup/information` },
          { text: 'Ordering', to: `/programs/digitalleague/${routeId.value}ordering`, disabled: !id.value },
          { text: 'Resources', to: `/programs/digitalleague/${routeId.value}resources`, disabled: !id.value },
        ]
      }

      return [
        { text: 'Setup', to: `/programs/league/${routeId.value}setup` },
        { text: 'Participants', to: `/programs/league/${routeId.value}participants`, disabled: !id.value },
        { text: 'Volunteers', to: `/programs/league/${routeId.value}volunteers`, disabled: !id.value },
        {
          text: 'Divisions/Teams',
          to: `/programs/league/${routeId.value}divisions_and_teams`,
          disabled: !id.value,
        },
        { text: 'Scheduling', to: `/programs/league/${routeId.value}scheduling`, disabled: !id.value },
        {
          text: 'Communications',
          to: `/programs/league/${routeId.value}communications`,
          disabled: !id.value,
        },
        isLicenseProgram.value
          ? {}
          : { text: 'Ordering', to: `/programs/league/${routeId.value}ordering`, disabled: !id.value },
        { text: 'Reports', to: `/programs/league/${routeId.value}reports`, disabled: !id.value },
        { text: 'Resources', to: `/programs/league/${routeId.value}resources`, disabled: !id.value },
      ]
    })

    onUnmounted(() => {
      store.commit.partners.setPartners({ item: [] as Partner[] })
      store.commit.productFlow.clear()
      store.commit.leagueAbstraction.clear()
    })

    //async created hack from https://stackoverflow.com/questions/64117116/how-can-i-use-async-await-in-the-vue-3-0-setup-function-using-typescript
    const run = (asyncFn: () => Promise<void>) => asyncFn()

    //private async created() {
    // Reset ministryAgreementSigned so that every time a league
    // is loaded, it's seasonEndDate is checked.
    run(async () => {
      store.commit.authorization.resetMinistryAgreementIsSigned()
      if (ctx.root.$route.params.id) {
        loading.value = true
        await store.dispatch.leagues.retrieveAndSetAsCurrent({ id: ctx.root.$route.params.id })
        await store.dispatch.partners.retrieveAndSetPartners({ id: ctx.root.$route.params.id })
        loading.value = false
      } else {
        // new league, skip alerts
        uncloak.value = true
      }
    })
    //end created

    const isLicenseProgram = computed(() => false) //at this time we have no licensing leagues.  If this changes, make this data driven from upwardLeagueType (where TypeOpportunityID == 5)

    return {
      loading,
      alertTypes,
      id,
      uncloak,
      currentLeague,
      tabs,
    }
  },
})
