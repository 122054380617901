



























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import * as facilityStore from '@/store/facilities'
import { Action, Getter, Mutation } from 'vuex-class'
import { cloneDeep } from 'lodash'
import { viewModes, eventTypes } from '@/store/facilities'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import { FacilityAvailability } from '@/GeneratedTypes/FacilityAvailability'
import TextInput from '@/elements/TextInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import AvailablePracticeDays from './AvailablePracticeDays.vue'
import AvailableGameDays from './AvailableGameDays.vue'
import GroupList from './GroupList.vue'
import { LeaguePracticeNight } from '@/GeneratedTypes/LeaguePracticeNight'
import facilityServices from '@/services/facilityService'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    TextInput,
    AvailablePracticeDays,
    AvailableGameDays,
    GroupList,
    VeeValidateForm,
    ValidationObserver,
  },
})
export default class FacilityEditor extends Vue {
  @Action(facilityStore.actionNames.endEdit, {
    namespace: facilityStore.namespace,
  })
  private readonly endEdit!: () => void

  @Action(facilityStore.actionNames.endCreating, {
    namespace: facilityStore.namespace,
  })
  private readonly endCreating!: () => void

  @Action(facilityStore.actionNames.validate, {
    namespace: facilityStore.namespace,
  })
  private readonly validate!: () => boolean

  @Action(facilityStore.actionNames.save, {
    namespace: facilityStore.namespace,
  })
  private readonly save!: () => LeagueFacility

  @Getter(facilityStore.getterNames.viewMode, {
    namespace: facilityStore.namespace,
  })
  private viewMode!: viewModes

  @Getter(facilityStore.getterNames.editorItem, {
    namespace: facilityStore.namespace,
  })
  private inEditItem!: LeagueFacility

  @Mutation(facilityStore.mutationNames.setInEditItem, {
    namespace: facilityStore.namespace,
  })
  private setInEditItem!: ({ item }: { item: LeagueFacility }) => void

  private localInEditItem: LeagueFacility = this.getEmptyLeagueFacility()

  availability!: FacilityAvailability[] | null
  practiceDays!: string
  gameDays!: string
  groupDetails!: string
  loaded = false

  @Watch('viewMode', { immediate: true })
  private onViewModeChanged() {
    this.updateView()
  }
  @Watch('localInEditItem.availability', { immediate: true })
  private onAvailabilityChanged() {
    this.updateView()
  }

  @Watch('localInEditItem.street1', { immediate: true })
  private onStreet1Changed() {
    this.updateView()
  }

  public mounted() {
    this.localInEditItem = cloneDeep(this.inEditItem)
    this.updateView()
  }

  private isInEditMode() {
    return this.viewMode === viewModes.edit
  }

  private getEmptyLeagueFacility() {
    return {
      leagueID: 0,
      facilityID: 0,
      accountNumber: '',
      isShared: false,
      facilityName: '',
      active: false,
      street1: '',
      street2: '',
      subdivision1: '',
      subdivision2: '',
      postalCode: '',
      typeCountryID: '',
      childFacilities: [],
      availability: [],
    } as LeagueFacility
  }

  private getGroupDetails() {
    return this.parseGroupDetails(this.localInEditItem.street1)
  }
  private getPracticeDays() {
    return this.getDays(this.localInEditItem, eventTypes.Practices)
  }

  private getGameDays() {
    return this.getDays(this.localInEditItem, eventTypes.Games)
  }

  private getDays(facility: LeagueFacility, eventType: eventTypes): string {
    let days: string[] = []
    if (facility && facility.availability) {
      const availability = facility.availability
      for (const item of availability) {
        if (item.typeFacilityEventID === eventType) {
          const day = item.typeDayID
          days.push(day)
        }
      }
    }

    days = [...new Set(days)] //remove dups
    return this.sortDays(days).join(', ')
  }

  private sortDays(days: string[]) {
    const localDays = days
    if (localDays) {
      localDays.sort(function sortByDay(a, b) {
        // sort days by days of the week
        const sorter: any = {
          sunday: 0, // << if sunday is first day of week
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
        }
        const day1 = a.toLowerCase()
        const day2 = b.toLowerCase()
        return sorter[day1] - sorter[day2]
      })
    }
    return localDays
  }
  private parseGroupDetails(value: string | null): string {
    if (value) {
      return value.toString().replace('Facilities -', '')
    }
    return ''
  }

  private editPracticeDays() {
    const currentDays = this.practiceDays.replace(/\s/g, '').split(',')
    ;(this.$refs.availablePracticeDays as Vue & { toggle: (current: string[]) => void }).toggle(currentDays)
  }

  private editGameDays() {
    const currentDays = this.gameDays.replace(/\s/g, '').split(',')
    ;(this.$refs.availableGameDays as Vue & { toggle: (current: string[]) => void }).toggle(currentDays)
  }

  private selectGroup() {
    ;(this.$refs.groups as Vue & { toggle: () => void }).toggle()
  }

  private async saveItem() {
    // make sure form is valid
    const formIsValid = await (this.$refs.observer as Vue & { validate: () => boolean }).validate()
    if (formIsValid) {
      this.setInEditItem({ item: this.localInEditItem })
      // validate data structure before submitting
      const validData = await this.validate()

      if (validData) {
        await this.save()
        if (this.viewMode === viewModes.create) {
          this.endCreating()
        } else {
          this.endEdit()
        }
      }
    }
  }

  private cancelEdit() {
    if (this.viewMode === viewModes.edit) {
      this.endEdit()
    }

    if (this.viewMode === viewModes.create) {
      this.endCreating()
    }
  }

  private practiceDaysUpdated(days: LeaguePracticeNight[]) {
    facilityServices.updatePracticeDays(days, this.localInEditItem.availability)
    //update selected practices
    this.practiceDays = ''

    this.$nextTick(() => {
      this.updateView()
    })
  }

  private gameDaysUpdated(days: string[]) {
    facilityServices.updateGameDays(days, this.localInEditItem.availability)
    this.gameDays = ''

    this.$nextTick(() => {
      this.updateView()
    })
  }

  private groupSelectionChanged(group: string) {
    this.localInEditItem.street1 = group
    this.groupDetails = ''
    this.$nextTick(() => {
      this.updateView()
    })
  }

  private inputValueChanged(event: any) {
    const value = event.srcElement.value
    this.localInEditItem.street1 = value
  }

  private updateView() {
    this.practiceDays = this.getDays(this.localInEditItem, eventTypes.Practices)
    this.gameDays = this.getDays(this.localInEditItem, eventTypes.Games)
    this.groupDetails = this.parseGroupDetails(this.localInEditItem.street1)
  }
}
