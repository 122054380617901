







































import { defineComponent, watch, ref, PropType } from '@vue/composition-api'
import { getProps, useTextlike } from '@/composables/TextLike'
import InputLabel from '@/elements/InputLabel.vue'
import TextLikeWrapper from '@/elements/TextLikeWrapper.vue'

export default defineComponent({
  name: 'MemoInput',
  components: {
    InputLabel,
    TextLikeWrapper,
  },
  props: {
    rows: { type: (null as unknown) as PropType<number | null>, default: null, required: false },
    cols: { type: (null as unknown) as PropType<number | null>, default: null, required: false },
    maxLength: { type: (null as unknown) as PropType<number | null>, default: null, required: false },
    placeholder: { type: String, default: '', required: false },
    value: { type: (null as unknown) as PropType<string | null>, required: true, default: '' },
    isReadOnly: { type: Boolean, default: false, required: false },
    haltInput: { type: Boolean, default: false, required: false },
    ...getProps(),
  },
  setup(props, ctx) {
    const { iconClick } = useTextlike<string>(props, ctx)

    const internalValue = ref('')

    function handleInput() {
      ctx.emit('input', internalValue.value)
    }

    function handleChange() {
      ctx.emit('change', internalValue.value)
    }

    function handleBlur() {
      ctx.emit('blur', internalValue.value)
    }

    function canInput(event: KeyboardEvent) {
      if (props.haltInput && event.key != 'Backspace') {
        event.preventDefault()
      }
      return
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value ?? ''
      },
      { immediate: true }
    )

    return {
      iconClick,
      internalValue,
      handleInput,
      handleChange,
      handleBlur,
      canInput,
    }
  },
})
