import { render, staticRenderFns } from "./CoachListElement.vue?vue&type=template&id=2a537723&scoped=true&"
import script from "./CoachListElement.vue?vue&type=script&lang=ts&"
export * from "./CoachListElement.vue?vue&type=script&lang=ts&"
import style0 from "./CoachListElement.vue?vue&type=style&index=0&id=2a537723&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a537723",
  null
  
)

export default component.exports