









import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import contentManagementClient from '@/clients/contentManagementClient'
import { PageContentDocument } from '@/models/PageContent/PageContentDocument'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  name: 'PageContent',
  components: {
    Loading,
  },
  props: {
    documentId: { type: String, required: true },
    showTitle: { type: Boolean, default: true },
  },
  setup(props, ctx) {
    const isLoading = ref(false)

    const pageContentDocument = ref<PageContentDocument>({
      title: '',
      documentID: '',
      content: '',
      documentContent: '',
    })

    watch(
      () => props.documentId,
      async (documentId: string) => {
        await updatePageContent(documentId)
      }
    )

    async function updatePageContent(documentId: string) {
      isLoading.value = true

      const pcd = await contentManagementClient.getPageContentDocument(documentId)
      if (pcd) {
        pageContentDocument.value = pcd
      } else {
        pageContentDocument.value = {
          title: '',
          documentID: '',
          content: '',
          documentContent: '',
        }
      }

      isLoading.value = false
    }

    const markup = computed(() => {
      // forcing this href to open in new tab because there is no way
      // to do it directly in the CMS
      if (pageContentDocument.value.documentID == 'waitReadMeFirst') {
        return pageContentDocument.value.documentContent.replaceAll('href="', 'target="_blank" href="')
      } else {
        return pageContentDocument.value.documentContent
      }
    })

    watch(
      () => isLoading.value,
      () => {
        ctx.emit('isLoading', isLoading.value)
      }
    )

    watch(
      () => pageContentDocument.value,
      () => {
        ctx.emit('documentChange', pageContentDocument.value)
      }
    )

    onMounted(async () => {
      await updatePageContent(props.documentId)
    })

    return {
      isLoading,
      pageContentDocument,
      markup,
    }
  },
})
