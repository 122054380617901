
























































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import TypeProgramTab from '@/components/TypeProgramTab.vue'

import store from '@/store'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  name: 'Squads',
  components: {
    Loading,
    TypeProgramTab,
  },
  setup(props, ctx) {
    const games = computed(() => store.getters.scheduling.games)
    const appointments = computed(() => store.getters.scheduling.appointments)

    const hasGames = computed(() => !!games.value?.length)

    const hasAppointments = computed(() => !!appointments.value?.length)

    /** other variables**/
    const loading = ref(true)
    /***
     * Returns the structure to define the tabs based on loaded league
     */
    /** index into this.leagues.programs[] variables  */

    onMounted(async () => {
      loading.value = true
      await Promise.all([
        store.dispatch.scheduling.loadAppointments({ id: ctx.root.$route.params.id ?? '' }),
        store.dispatch.scheduling.loadGames({ id: ctx.root.$route.params.id ?? '' }),
      ])
      if (appointments.value?.length) {
        await ctx.root.$router.push('cheer/manual')
      }

      loading.value = false
    })

    return {
      loading,
      hasGames,
      hasAppointments,
    }
  },
})
