





















import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import { getListFromSavedEstimate } from '@/services/gradeService'

import { SavedProductOfferingConfigGenderGrade } from '@/GeneratedTypes/SavedProductOfferingConfigGenderGrade'

import InputLabel from '@/elements/InputLabel.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import GradeRangeSlider from '@/elements/GradeRangeSlider.vue'
import { isCheer } from '@/lib/common/ProgramTypes'

export default defineComponent({
  name: 'GradeGenderEstimateSelector',
  components: {
    InputLabel,
    CheckboxInput,
    GradeRangeSlider,
  },
  props: {
    value: { type: Array as PropType<SavedProductOfferingConfigGenderGrade[]>, required: false },
  },
  setup(props, ctx) {
    const local = ref<SavedProductOfferingConfigGenderGrade[] | null>(null)
    const girlGrades = ref<string[]>([])
    const boyGrades = ref<string[]>([])
    const cheerBoyGrades = ref<string[]>([])
    const cheerGirlGrades = ref<string[]>([])
    const includeGirls = ref(true)
    const includeBoys = ref(true)
    const includeGirlsCheer = ref(true)
    const includeBoysCheer = ref(false)

    function initValues() {
      initSport()
      initCheer()
    }

    function initSport() {
      if (sportProgramTypeId.value && local.value && local.value.length) {
        girlGrades.value = cloneDeep(getListFromSavedEstimate(local.value, sportProgramTypeId.value, 'F'))
        boyGrades.value = cloneDeep(getListFromSavedEstimate(local.value, sportProgramTypeId.value, 'M'))
        includeBoys.value = boyGrades.value.length > 0
        includeGirls.value = girlGrades.value.length > 0
      }
    }

    function initCheer() {
      if (cheerProgramTypeId.value && local.value && local.value.length) {
        cheerGirlGrades.value = cloneDeep(
          getListFromSavedEstimate(local.value, cheerProgramTypeId.value, 'F')
        )
        cheerBoyGrades.value = cloneDeep(getListFromSavedEstimate(local.value, cheerProgramTypeId.value, 'M'))
        includeBoysCheer.value = cheerBoyGrades.value.length > 0
        includeGirlsCheer.value = cheerGirlGrades.value.length > 0
      }
    }

    const sportProgramTypeId = computed((): string | null => {
      if (local.value) {
        return local.value.find((p) => !isCheer(p.typeProgramID))?.typeProgramID ?? null
      }
      return null
    })

    const cheerProgramTypeId = computed((): string | null => {
      if (local.value) {
        return local.value.find((p) => isCheer(p.typeProgramID))?.typeProgramID ?? null
      }
      return null
    })

    const hasCheer = computed((): boolean => {
      if (cheerProgramTypeId.value) return true
      return false
    })

    function mapGrades(grades: string[], typeProgramID: string | null, gender: string) {
      if (!typeProgramID) return [] as SavedProductOfferingConfigGenderGrade[]
      return grades.map(
        (g) =>
          ({
            typeProgramID: typeProgramID,
            gender: gender,
            typeGradeID: g,
          } as SavedProductOfferingConfigGenderGrade)
      )
    }

    watch(
      () => props.value,
      (
        oldVal: SavedProductOfferingConfigGenderGrade[] | undefined,
        newVal: SavedProductOfferingConfigGenderGrade[] | undefined
      ) => {
        if (JSON.stringify(oldVal) != JSON.stringify(newVal)) {
          local.value = JSON.parse(JSON.stringify(props.value))
          initValues()
        }
      }
    )
    watch(
      () => [
        includeGirls.value,
        includeBoys.value,
        includeGirlsCheer.value,
        includeBoysCheer.value,
        girlGrades.value,
        boyGrades.value,
        cheerGirlGrades.value,
        cheerBoyGrades.value,
      ],
      () => {
        emitChange()
      }
    )

    function emitChange() {
      let girls: SavedProductOfferingConfigGenderGrade[] = []
      let boys: SavedProductOfferingConfigGenderGrade[] = []
      let girlCheer: SavedProductOfferingConfigGenderGrade[] = []
      let boyCheer: SavedProductOfferingConfigGenderGrade[] = []

      if (includeGirls.value) {
        girls = mapGrades(girlGrades.value, sportProgramTypeId.value, 'F')
      }

      if (includeBoys.value) {
        boys = mapGrades(boyGrades.value, sportProgramTypeId.value, 'M')
      }

      if (includeGirlsCheer.value) {
        girlCheer = mapGrades(cheerGirlGrades.value, cheerProgramTypeId.value, 'F')
      }

      if (includeBoysCheer.value) {
        boyCheer = mapGrades(cheerBoyGrades.value, cheerProgramTypeId.value, 'M')
      }
      ctx.emit('input', [...girls, ...boys, ...girlCheer, ...boyCheer])
    }

    return {
      includeGirls,
      girlGrades,
      includeBoys,
      boyGrades,
      hasCheer,
      includeGirlsCheer,
      cheerGirlGrades,
      includeBoysCheer,
      cheerBoyGrades,
    }
  },
})
