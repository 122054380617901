














import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'

import OptionalProductCard from './OptionalProductCard.vue'
import { TypeProductOption } from '@/lib/support/models/UpwardTypes/TypeProductOption'
import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { getEmptyProductOfferingProductInfoList } from '@/lib/support/models/ProductOfferingProductInfo/ProductOfferingProductInfo'
import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail.ts'
import { getEmptySavedProductOfferingConfigDetail } from '@/lib/support/models/SavedProductOfferingConfigDetail/SavedProductOfferingConfigDetail'
import { sortOffering } from '@/services/offeringService'
import { OptionalProductSelection } from './model/OptionProductSelection'
import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'
import store from '@/store'

export default defineComponent({
  name: 'OptionalProductPicker',
  components: {
    OptionalProductCard,
  },
  props: {
    products: {
      type: Array as PropType<ProductOfferingProductInfo[]>,
      default: getEmptyProductOfferingProductInfoList,
      required: false,
    },
    values: {
      type: Array as PropType<SavedProductOfferingConfigDetail[]>,
      default: getEmptyProductOfferingProductInfoList,
      required: false,
    },
    category: { type: Number, default: 0, required: false },
    selectable: { type: Boolean, required: true },
    package: { type: String, required: false, default: '' },
    productOfferingId: { type: Number, required: true },
  },
  setup(props) {
    const chosen = ref<OptionalProductSelection[]>([])
    const logic = productFlowLogic()

    const sortedProducts = computed(() => sortOffering<ProductOfferingProductInfo>(props.products))

    function buildConfigDetailArray() {
      const configDetails: SavedProductOfferingConfigDetail[] = []

      chosen.value.forEach((option: OptionalProductSelection, i: number) => {
        if (!option || !option.selection || option.selection === TypeProductOption.NOTAVAILABLE) return
        const configDetail = getEmptySavedProductOfferingConfigDetail()

        configDetail.categoryID = props.category
        configDetail.typeProductOptionID = option.selection
        configDetail.productID = props.products[i].productID
        configDetail.productOfferingID = props.productOfferingId
        configDetail.typeColorID = 'N/A'

        if (option.selection === TypeProductOption.OPTIONAL) {
          configDetail.costToCharge = option.price ?? props.products[i].productCost
        }
        configDetails.push(configDetail)
      })

      return configDetails
    }

    watch(
      () => chosen.value,
      () => {
        const details = buildConfigDetailArray()

        if (details.length) {
          const productDetails = JSON.parse(JSON.stringify(details))
          store.commit.productOfferings.setSelectedProductDetails({ productDetails })
        } else {
          const sel = getEmptySavedProductOfferingConfigDetail()
          sel.categoryID = props.category
          sel.productOfferingID = props.productOfferingId
          store.commit.productOfferings.setSelectedProductDetails({ productDetails: [sel] })
        }
        logic.markAsDirty()
      },
      { deep: true }
    )

    return {
      sortedProducts,
      chosen,
    }
  },
})
