














































































































































































































import { defineComponent, ref, watch, computed, onMounted } from '@vue/composition-api'
import SearchInput from '@/elements/SearchInput.vue'
import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import programsClient from '@/clients/programsClient'
import leaguesClient from '@/clients/leaguesClient'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import MemoInput from '@/elements/MemoInput.vue'
import { ImpersonationPayload } from '@/clients/authorizationClient'
import { LeagueProgramAugusta, getEmptyLeagueProgramAugusta } from '@/models/League/LeagueProgramAugusta'
import { getColorDescription } from '@/lib/common/upwardColorTypes'
import store from '@/store'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import { LeagueAccountInfo } from 'src/GeneratedTypes/ListInfo/LeagueAccountInfo'

export default defineComponent({
  name: 'ProgramSearchPanel',
  components: { MemoInput, ConfirmationModal, Alert, SearchInput, Loading, TextInput },
  setup(p, ctx) {
    // set to an error if there is one.
    const error = ref('')
    //clear an error in 20 seconds automatically
    watch(
      () => error.value,
      (a) => {
        if (a) {
          setTimeout(() => (error.value = ''), 20000)
        }
      }
    )

    onMounted(() => {
      store.dispatch.colorTypes.fetchAll(false) //get these cached up
      store.dispatch.leagueTypes.fetchAll(false) //get these cached up
    })

    const searchTerm = ref('')
    const loading = ref(false)
    const showAlert = ref(false)
    const alertText = ref('')
    const isConfirmShowing = ref(false)
    const confirmText = ref('')
    const showReason = ref(false)
    const reason = ref('')
    const isSNumbersShowing = ref(false)
    const sNumbers = ref<LeagueProgramAugusta | null>(getEmptyLeagueProgramAugusta())

    const results = ref<LeagueListItem | null>(null)
    // when called from the search input, has the latest term, when called by the body of this function, will use the old value
    const searchResults = async (term?: string) => {
      try {
        loading.value = true
        error.value = ''

        if (term) {
          searchTerm.value = term
        }

        results.value = await programsClient.retrieve(searchTerm.value)
      } catch (e) {
        error.value = 'No results returned'

        results.value = null
      } finally {
        loading.value = false
      }
    }

    let confirmAction:
      | ((upwardLeagueID: string) => Promise<void>)
      | ((upwardLeagueID: string, reason: string) => Promise<void>)
      | null = null
    let successAlertText = ''
    let upwardLeagueID = ''

    async function confirmClick(b: boolean) {
      isConfirmShowing.value = false
      if (confirmAction && b) {
        try {
          loading.value = true

          await confirmAction(upwardLeagueID, reason.value)

          showAlert.value = true
          await searchResults()
          alertText.value = successAlertText
        } catch (e) {
          throw e
        } finally {
          loading.value = false
        }
      }
    }

    const wrapCallNotify = async (
      fn:
        | ((upwardLeagueID: string, reason: string) => Promise<void>)
        | ((upwardLeagueID: string) => Promise<void>),
      thingConfirming: string,
      successText: string,
      showReasonFlag?: boolean
      // eslint-disable-next-line require-await
    ) => {
      if (results.value) {
        confirmAction = fn
        upwardLeagueID = results.value.upwardLeagueID ?? ''
        confirmText.value = thingConfirming
        reason.value = ''
        successAlertText = successText
        showReason.value = !!showReasonFlag
        isConfirmShowing.value = true
      }
    }

    async function cancelClicked() {
      await wrapCallNotify(cancel, 'Cancel the Program', 'Program Cancelled', true)
    }

    async function removeCheerClicked() {
      await wrapCallNotify(removeCheer, 'Remove Cheer from the Program', 'Cheer Removed')
    }

    async function deleteProgramClicked() {
      await wrapCallNotify(deleteProgram, 'Delete the Program', 'Program removed', true)
    }

    async function privateClicked() {
      await wrapCallNotify((s: string) => setPrivacyMode(s, true), 'Mark Private', 'Program marked private')
    }

    async function manageSNumbers() {
      if (results.value) {
        sNumbers.value = await leaguesClient.retrieveLeagueProgramAugusta(
          results.value.upwardLeagueID!,
          results.value.programTypeID! //for now we can assume that the sport program is the only one with the custom jersey (ie: cheer does NOT offer these)
        )
        isSNumbersShowing.value = true
      }
    }

    async function notPrivateClicked() {
      await wrapCallNotify(
        (s: string) => setPrivacyMode(s, false),
        'Unmark Private',
        'Program no longer private'
      )
    }

    async function enableOverrideDraftClicked() {
      await wrapCallNotify(
        (s: string) => overrideDraft(s, true),
        'Enable Draft Override',
        'Draft Override Enabled',
        false
      )
    }

    async function disableOverrideDraftClicked() {
      await wrapCallNotify(
        (s: string) => overrideDraft(s, false),
        'Disable Draft Override',
        'Draft Override Disabled',
        false
      )
    }

    async function cancel(upwardLeagueID: string, reason: string) {
      await programsClient.setCancelled(upwardLeagueID, reason)
    }
    async function removeCheer(upwardLeagueID: string) {
      await programsClient.removeCheer(upwardLeagueID)
    }

    async function deleteProgram(upwardLeagueID: string, reason: string) {
      await programsClient.deleteLeague(upwardLeagueID, reason)
    }

    async function overrideDraft(upwardLeagueID: string, overrideDraft: boolean) {
      await programsClient.overrideDraft(upwardLeagueID, overrideDraft)
    }

    async function setPrivacyMode(upwardLeagueID: string, isPrivate: boolean) {
      await programsClient.setPrivacy(upwardLeagueID, isPrivate)
    }

    async function setSNumbers(b: boolean) {
      let hasError = false
      if (results.value && sNumbers.value && b) {
        try {
          await leaguesClient.updateLeagueProgramAugusta(results.value.upwardLeagueID!, sNumbers.value)
        } catch (e) {
          hasError = true
          throw e //just let the built-in handler deal with this. sick of trying to get "error.value" to work.
        }
      }

      isSNumbersShowing.value = hasError
    }

    function getLeagueOwner(): LeagueAccountInfo | null {
      let retval = null
      if (results.value && results.value.myLeagueInfo && results.value.myLeagueInfo.accounts) {
        const account = results.value.myLeagueInfo.accounts.find((x) => x.leagueOwner)
        if (account) {
          retval = account
        }
      }

      return retval
    }

    function getLeagueOwnerName(): string | null {
      let retval = null
      const account = getLeagueOwner()
      if (account != null) {
        retval = account.partnerInfo?.name ?? null
      }

      return retval
    }

    function impersonate() {
      const account = getLeagueOwner()
      if (account != null) {
        const accountNumber = account.accountNumber
        ctx.emit('impersonate', { accountNumber } as ImpersonationPayload)
      }
    }

    const hasCustomJersey = computed(() =>
      results.value?.myLeagueInfo?.productOfferingConfig?.details?.some((d) => d.hasSNumbers)
    )

    async function placeManualOrderClicked() {
      const account = getLeagueOwner()
      if (account != null) {
        const accountNumber = account.accountNumber
        await store.dispatch.authorization.impersonate({ accountNumber } as ImpersonationPayload)
        store.dispatch.orders.clearCachedOrder({
          orderType: OrderTypesEnum.manual,
          upwardLeagueID: results.value?.upwardLeagueID ?? '',
        })

        await ctx.root.$router.push(`/admin-dashboard/${results.value?.upwardLeagueID ?? ''}/manual-order/0`)
      }
    }

    function isEven(row: number) {
      return row % 2 == 0
    }

    const isCamp = computed(
      () => store.getters.leagueTypes.byUpwardTypeId(results.value?.myLeagueInfo?.typeLeagueID)?.isCamp
    )

    const showManualOrder = computed(
      () =>
        !isCamp.value &&
        (store.getters.authorization.isCurrentUserASuperAdmin ||
          store.getters.authorization.isSystemsAndSupport)
    )

    return {
      searchTerm,
      loading,
      searchResults,
      showAlert,
      alertText,
      error,
      confirmClick,
      cancelClicked,
      removeCheerClicked,
      deleteProgramClicked,
      privateClicked,
      notPrivateClicked,
      results,
      showReason,
      reason,
      enableOverrideDraftClicked,
      disableOverrideDraftClicked,
      placeManualOrderClicked,
      confirmText,
      isConfirmShowing,
      alertType: AlertTypeEnum.NOTICE,
      impersonate,
      hasCustomJersey,
      manageSNumbers,
      setSNumbers,
      isSNumbersShowing,
      sNumbers,
      getColorDescription,
      isEven,
      showManualOrder,
      getLeagueOwner,
      getLeagueOwnerName,
    }
  },
})
