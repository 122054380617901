





























import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import uuid from 'uuid'

import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'

import {
  getGrades,
  getDefaultGrades,
  getAges,
  getGradeDescriptions,
} from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import { sortGrades } from '@/services/gradeService.ts'

export default defineComponent({
  name: 'GradeRangeSlider',
  components: {
    BaseInputWrapper,
  },
  props: {
    value: { type: Array as PropType<string[]>, required: true },
    id: { type: String, default: '', required: false },
    min: { type: String, default: 'K3', required: false },
    max: { type: String, default: '12th', required: false },
    subLabel: { type: String, required: false },
    subLabelInline: { type: Boolean, default: false, required: false },
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
    readonly: { type: Boolean, default: false, required: false },
    showAges: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const guid = uuid.v4()
    const allGrades = ref(getGrades(false))
    const allAges = ref(getAges(false))
    const allGradeDescs = ref(getGradeDescriptions(false))
    const tickLabels = ref(props.showAges ? [...allAges.value] : [...allGradeDescs.value])

    const maxTick = computed(() => allGrades.value.indexOf(props.max) + 1)
    const minTick = computed(() => allGrades.value.indexOf(props.min) + 1)

    const local = ref([minTick.value, maxTick.value])
    const internalValue = ref<string[] | null>(null)

    const elementId = computed(() => props.id || `grade-slider-${guid}`)

    function change(val: any) {
      const list: number[] = [...local.value]
      if (val[0] <= minTick.value) {
        list[0] = minTick.value
      }
      if (val[1] >= maxTick.value) {
        list[1] = maxTick.value
      }
      local.value = [...list]
      ctx.emit('change', selectedGrades())
    }

    function input() {
      ctx.emit('input', selectedGrades())
    }

    function selectedGrades(): string[] {
      const grades = getGrades(false)
      const selGrades = grades.slice(local.value[0] - 1, local.value[1])
      return selGrades
    }

    function setDefaults() {
      local.value = [1, 15]
      ctx.emit('input', getDefaultGrades())
    }

    watch(
      () => props.showAges,
      () => {
        tickLabels.value = props.showAges ? [...allAges.value] : [...allGrades.value]
      }
    )

    watch(
      () => props.value,
      () => {
        if (!props.value || !props.value.length) {
          setDefaults()
          return
        }
        internalValue.value = [...props.value]
        internalValue.value?.sort(sortGrades)
        const min = allGrades.value.indexOf(internalValue.value[0]) + 1
        const max = allGrades.value.indexOf(internalValue.value[internalValue.value.length - 1]) + 1
        local.value = [min, max]
      },
      { immediate: true }
    )

    return {
      elementId,
      tickLabels,
      local,
      change,
      input,
    }
  },
})
