































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { LeagueDivision } from '@/GeneratedTypes/LeagueDivision'
import { DivisionPracticeExclusion } from '@/GeneratedTypes/DivisionPracticeExclusion'
import { FacilityEventInfo } from '@/GeneratedTypes/ListInfo/FacilityEventInfo'
import { cloneDeep } from 'lodash'
import TextInput from '@/elements/TextInput.vue'
import NumberInput from '@/elements/NumberInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import DatesInput from '@/elements/DatesInput.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import GradeRangeDropdowns from '@/elements/GradeRangeDropdowns.vue'
import GenderSelect from '@/elements/GenderSelect.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import Loading from '@/elements/Loading.vue'
import GameFormatSelect from '@/elements/GameFormatSelect.vue'
import PracticePlanSelect from '@/elements/PracticePlanSelect.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import { getGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import divisionsClient from '@/clients/divisionsClient'
import facilitiesClient from '@/clients/facilitiesClient'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { Genders } from '@/lib/support/models/MiscMappings/Gender'
import { programList, programListType } from '@/lib/support/models/Program/LeagueListItem'
import LeagueScoringSelect from '@/components/LeagueScoringSelect.vue'

import { Getter } from 'vuex-class'
import * as authorizationStore from '@/store/authorization'
import SectionHeader from '@/components/SectionHeader.vue'
import * as leaguesStore from '@/store/leagues'
import { namespace as CampNamespace, getterNames as CampGetters } from '@/store/camps'

import { League } from '@/GeneratedTypes/League'
import { Camp } from '@/GeneratedTypes/Camp'
import InputLabel from '@/elements/InputLabel.vue'

import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { toDate } from '@/lib/support/models/ListInfo/DivisionGameInfo/time_util'
import dayjs from 'dayjs'
import { isCheer } from '@/lib/common/ProgramTypes'
import { isNoStanding } from '@/lib/common/LeagueScoringTypes'

let useTestData: any = null
if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
  import('@/components/testData/divisionForm').then((x) => (useTestData = x))
}

@Component({
  components: {
    TextInput,
    SelectInput,
    DatesInput,
    CheckboxInput,
    GenderSelect,
    RadioGroupInput,
    Loading,
    GameFormatSelect,
    PracticePlanSelect,
    VeeValidateForm,
    SectionHeader,
    NumberInput,
    InputLabel,
    GradeRangeDropdowns,
    ConfirmationModal,
    LeagueScoringSelect,
  },
})
export default class DivisionForm extends Vue {
  @Getter(authorizationStore.getterNames.firstAccountNumber, { namespace: authorizationStore.namespace })
  private firstAccountNumber!: string

  @Getter(leaguesStore.getterNames.currentItem, { namespace: leaguesStore.namespace })
  private currentLeague!: League

  @Getter(CampGetters.currentItem, { namespace: CampNamespace })
  private camp!: Camp

  @Prop({ type: Object, required: true })
  private readonly division!: LeagueDivision

  @Prop({ type: Array, default: () => [] })
  divisionsWithExclusions!: LeagueDivision[]

  @Prop({ type: Object, required: true })
  private readonly programs!: LeagueListItem

  @Prop({ type: String, required: true })
  private readonly selectedProgram!: string

  private isSaving = false
  private internalDivision: LeagueDivision = cloneDeep(this.division)
  private startGrade = 'K3'
  private endGrade = '12th'
  private validGradesForGender: string[] = []
  private selectedGrades: string[] = []
  private programList!: programListType[]
  private isNewDivision = false
  private dates: Date[] = []
  private divisionEvents: FacilityEventInfo[] = []
  private scrollToDate: string | undefined = undefined
  private hasPracticesScheduled = false
  private removePractices = false
  private showCopyExclusionsModal = false
  private divisionToCopy = 0
  private grades = getGrades(false)

  private created() {
    this.isNewDivision = this.division.leagueID === 0
    this.setProgram()
    if (useTestData) {
      useTestData.setFieldsForNewDivision(this.internalDivision)
    }
  }

  get isLeague() {
    return store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.LEAGUE
  }

  get isUpwardSelect() {
    return store.getters.leagueAbstraction.isUpwardSelect
  }

  get isByAge() {
    return store.getters.leagueAbstraction.isByAge
  }

  get exclusionDates(): DivisionPracticeExclusion[] {
    return this.dates.map((x) => ({ excludeDate: x }))
  }

  get datesSorted() {
    return this.dates.sort((x, y) => (x.valueOf() > y.valueOf() ? 1 : -1))
  }

  get minCalendarDate() {
    return dayjs(this.programs.myLeagueInfo!.firstPracticeDate!).toISOString().substr(0, 10)
  }

  get maxCalendarDate() {
    return dayjs(this.programs.myLeagueInfo!.seasonEndDate!).toISOString().substr(0, 10)
  }

  get validGradeTypeIDs() {
    return this.validGradesForGender.map((g) => store.getters.gradeTypes.byUpwardTypeId(g))
  }

  private shortDateFormat(val: Date) {
    return dayjs(val).format('MMM D')
  }

  clickCopyExclusions() {
    this.showCopyExclusionsModal = true
  }

  private confirmCopyExclusions(b: boolean) {
    if (b && this.divisionToCopy) {
      const div = this.divisionsWithExclusions.find((x) => x.divisionID == this.divisionToCopy)
      if (div) {
        this.dates = div.exclusionDates?.map((x) => toDate(x.excludeDate)) ?? []
      }
    }
    this.showCopyExclusionsModal = false
  }

  private get practicePlanLabel() {
    if (this.internalDivision.typeProgramID.includes('BASKETBALL')) {
      return 'Rule Level'
    } else {
      return 'Practice Plan'
    }
  }

  private setGrades(grades: string[]) {
    this.selectedGrades = grades
  }

  private get isCamp() {
    return this.programs.opportunityTypeDescription === 'Camp'
  }

  private get leagueId() {
    if (this.isCamp) {
      return this.camp.id
    } else {
      return this.currentLeague.id
    }
  }

  private get minGrade() {
    if (this.isCheer) {
      if (this.internalDivision.gender === Genders.both) {
        return this.programs.eitherCheerGradeMin
      }

      if (this.internalDivision.gender === Genders.male) {
        return this.programs.maleCheerGradeMin
      }

      if (this.internalDivision.gender === Genders.female) {
        return this.programs.femaleCheerGradeMin
      }
    }

    if (this.internalDivision.gender === Genders.both) {
      return this.programs.eitherGradeMin
    }

    if (this.internalDivision.gender === Genders.male) {
      return this.programs.maleGradeMin
    }

    if (this.internalDivision.gender === Genders.female) {
      return this.programs.femaleGradeMin
    }
    return
  }

  private get maxGrade() {
    if (this.isCheer) {
      if (this.internalDivision.gender === Genders.both) {
        return this.programs.eitherCheerGradeMax
      }

      if (this.internalDivision.gender === Genders.male) {
        return this.programs.maleCheerGradeMax
      }

      if (this.internalDivision.gender === Genders.female) {
        return this.programs.femaleCheerGradeMax
      }
    }

    if (this.internalDivision.gender === Genders.both) {
      return this.programs.eitherGradeMax
    }

    if (this.internalDivision.gender === Genders.male) {
      return this.programs.maleGradeMax
    }

    if (this.internalDivision.gender === Genders.female) {
      return this.programs.femaleGradeMax
    }
  }

  get validGenders(): string {
    //Cheer program
    if (this.isCheer) {
      if (this.programs.femaleCheerGradeMin !== '' && this.programs.maleCheerGradeMin !== '') {
        return Genders.both
      }

      if (this.programs.femaleCheerGradeMin !== '') {
        return Genders.female
      }

      if (this.programs.maleCheerGradeMin !== '') {
        return Genders.male
      }
    }

    //non-cheer program
    if (this.programs.femaleGradeMin !== '' && this.programs.maleGradeMin !== '') {
      return Genders.both
    }

    if (this.programs.femaleGradeMin !== '') {
      return Genders.female
    }

    if (this.programs.maleGradeMin !== '') {
      return Genders.male
    }

    return ''
  }

  private get isCheer() {
    return isCheer(this.internalDivision.typeProgramID)
  }

  private formatGradesForSave() {
    return this.selectedGrades.map((grade: string) => {
      return {
        typeGradeID: grade,
      }
    })
  }

  private get isNoStanding() {
    return isNoStanding(this.internalDivision.typeLeagueScoringID)
  }

  private setProgram() {
    if (this.isNewDivision) {
      this.programList = programList(this.programs)
      if (this.selectedProgram) {
        this.internalDivision.typeProgramID = this.selectedProgram
      }
    }
  }

  private async submit() {
    if (this.isNewDivision) {
      await this.saveNew()
    } else {
      await this.saveExisting()
    }
  }

  private async saveExisting() {
    this.isSaving = true
    this.internalDivision.exclusionDates = this.exclusionDates
    try {
      this.internalDivision.grades = this.formatGradesForSave()
      await divisionsClient.validateExisting(this.$route.params.id, this.internalDivision)
      await divisionsClient.update(this.$route.params.id, this.internalDivision, this.removePractices)
    } finally {
      this.isSaving = false
      this.$emit('onSaved', this.internalDivision)
    }
  }

  private async saveNew() {
    this.isSaving = true

    this.internalDivision.accountNumber = this.firstAccountNumber
    this.internalDivision.leagueID = this.leagueId
    this.internalDivision.exclusionDates = this.exclusionDates

    try {
      this.internalDivision.grades = this.formatGradesForSave()
      await divisionsClient.validateNew(this.internalDivision)
      await divisionsClient.create(this.internalDivision)
    } finally {
      this.isSaving = false
      this.$emit('onSaved', this.internalDivision)
    }
  }

  private cancel() {
    this.$emit('onCanceled')
  }

  private isPracticeNight(val: Date) {
    const djs = dayjs(val)
    const practiceNight =
      this.programs.myLeagueInfo?.practiceNights?.find((x) => x.typeDayID == djs.format('dddd')) ?? null
    return practiceNight != null
  }

  private hasPracticeEvent(val: Date) {
    const djs = dayjs(val)
    const practiceEvent =
      this.divisionEvents?.find(
        (x) =>
          x.eventStart &&
          x.typeFacilityEventID == FacilityEventEnum.PRACTICES &&
          djs.isSame(dayjs(x.eventStart), 'day')
      ) ?? null
    return practiceEvent != null ? 'red' : false
  }

  private divisionPracticeEvents(val: Date) {
    const djs = dayjs(val)
    return this.divisionEvents
      ?.filter(
        (x) =>
          x.eventStart &&
          x.typeFacilityEventID == FacilityEventEnum.PRACTICES &&
          djs.isSame(dayjs(x.eventStart), 'day')
      )
      .map((m) => ({
        description: m.eventName!.replace(' Practice', ''),
        startTime: dayjs(m.eventStart!).format('h:mm A'),
        endTime: dayjs(m.eventEnd!).format('h:mm A'),
      }))
  }

  private get showRemoveCheckbox() {
    return this.dates.find((x) => this.hasPracticeEvent(x))
  }

  @Watch('division', { immediate: true })
  private async onDivisionChange() {
    this.internalDivision = cloneDeep(this.division)
    this.initGrades()
    this.initDates()
    await this.initEvents()
  }

  private initGrades() {
    const divisionGrades = this.division.grades ?? []
    if (this.isNewDivision) {
      this.startGrade = this.validGradesForGender[0]
      this.endGrade = this.validGradesForGender[this.validGradesForGender.length - 1]
    } else {
      this.startGrade = divisionGrades[0].typeGradeID
      this.endGrade = divisionGrades[divisionGrades.length - 1].typeGradeID
    }
  }

  private initDates() {
    this.dates = this.internalDivision?.exclusionDates?.map((x) => toDate(x.excludeDate)) || []
    this.scrollToDate = this.dates.length == 0 ? this.minCalendarDate : undefined //if there are exclusions already here, the control will scroll to the last one in line by default so don't scroll elsewhere
  }

  private async initEvents() {
    this.divisionEvents =
      (await facilitiesClient.retrieveEventsForDivision(
        this.$route.params.id,
        this.internalDivision.typeProgramID,
        this.internalDivision.divisionID
      )) ?? []
    this.hasPracticesScheduled =
      (this.divisionEvents.find((x) => x.typeFacilityEventID == FacilityEventEnum.PRACTICES) ?? null) != null
  }

  onProgramChanged(val: string) {
    this.$emit('onProgramChanged', val)
  }

  @Watch('internalDivision.typeProgramID', { immediate: true })
  @Watch('internalDivision.gender', { immediate: true })
  genderChanged() {
    const startIndex = this.grades.findIndex((grade) => grade === this.minGrade)
    const endIndex = this.grades.findIndex((grade) => grade === this.maxGrade)
    this.validGradesForGender = this.grades.slice(startIndex, endIndex + 1)
    this.initGrades()
  }

  @Watch('isLeague', { immediate: true })
  isLeagueChanged() {
    if (!this.isLeague) {
      // The API requires these value, but there is no scheudling for camps
      // so these properties are set to a default
      this.internalDivision.gameCount = 8
      this.internalDivision.practiceLength = 60
      this.internalDivision.gameLength = 60
    }
  }

  availableStartGrades(endGrade: string) {
    return this.validGradesForGender.filter((g) => this.grades.indexOf(g) <= this.grades.indexOf(endGrade))
  }
  availableStopGrades(startGrade: string) {
    return this.validGradesForGender.filter((g) => this.grades.indexOf(g) >= this.grades.indexOf(startGrade))
  }
}
