import { IndividualEmail } from '@/GeneratedTypes/IndividualEmail'
import { IndividualEmailInfo } from '@/GeneratedTypes/ListInfo/IndividualEmailInfo'

export function individualEmailToIndividualEmailInfo(i: IndividualEmail): IndividualEmailInfo {
  return {
    emailAddress: i.emailAddress,
    valid: i.valid,
    validityReason: i.validityReason,
    individualID: 0,
    emailID: i.emailID,
    typeEmailID: i.typeEmailID,
    priority: i.priority,
  }
}
