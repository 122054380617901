




























import { defineComponent, PropType, ref } from '@vue/composition-api'

import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'

import CheckboxInput from '@/elements/CheckboxInput.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

import { useManagerLogic } from '@/views/Programs/Divisions/TeamManagement/logic/ManagerLogic'

export default defineComponent({
  name: 'UnassignAllDivisionParticipants',
  components: { ConfirmationModal, CheckboxInput },
  props: {
    division: { type: Object as PropType<LeagueDivisionInfo>, required: true },
    teams: { type: Array as PropType<DivisionTeamInfo[]>, required: true },
  },
  setup(props) {
    const showConfirmation = ref<boolean>(false)
    const manager = useManagerLogic()
    const unassignLocked = ref<boolean>(false)

    function confirm() {
      showConfirmation.value = true
    }

    async function confirmationResponse(response: boolean) {
      showConfirmation.value = false
      if (response) {
        await manager.unassignDivisionParticipant(props.teams, unassignLocked.value)
        unassignLocked.value = false
      }
    }

    return {
      showConfirmation,
      unassignLocked,
      confirm,
      confirmationResponse,
      progress: manager.teamsProgress,
    }
  },
})
