








import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'StepHeader',
  props: {
    title: { type: String, default: '', required: false },
    tag: { type: String, default: '', required: false },
    caption: { type: String, default: '', required: false },
  },
})
