





import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

export interface Node {
  [key: string]: any
  children?: any[]
}

export default defineComponent({
  name: 'Tree',
  props: {
    value: { type: Array as PropType<any>, required: false, default: () => [] },
    items: { type: Array as PropType<Node>, required: true },
  },
  components: {},
  setup(props, { emit }) {
    const local = ref<Node[]>([])

    const selected = computed({
      get() {
        return cloneDeep(props.value)
      },
      set(v: Node[]) {
        local.value = v
        emit('input', cloneDeep(v))
      },
    })

    return { selected }
  },
})
