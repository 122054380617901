





































































interface DisplayableProduct {
  cmsProductName: string | null
  cost: number
  costOffset: number
  typeParticipantID: string | null
  category: string | null
  categorySortOrder: number
  productID: number | null
  productSortOrder: number
  color: string
  optionalText: string
  hasGradeGender: boolean
  skipSimpleTotal: boolean
  isMultiPrice: boolean
  isOptionalApparel: boolean
}

import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import ButtonPicker from '@/elements/ButtonPicker.vue'
import Loading from '@/elements/Loading.vue'

import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'
import { ProductOfferingProductInfo } from 'src/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { ProductOfferingCategoryInfo } from 'src/GeneratedTypes/ListInfo/ProductOfferingCategoryInfo'
import { getEmptyUpwardProgramTypeID } from '@/lib/support/models/UpwardTypes/UpwardProgramTypeID'
import { SavedProductOfferingConfigStartupOrderProduct } from '@/GeneratedTypes/SavedProductOfferingConfigStartupOrderProduct'
import { StartupOrderProductInfo } from '@/GeneratedTypes/ListInfo/StartupOrderProductInfo'
import currency from '@/filters/currency'
import { campColorSelectionProducts } from '@/components/ProductFlow/data/CampColorProducts'

import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import store from '@/store'

export default defineComponent({
  name: 'RunningTotal',
  components: {
    ButtonPicker,
    Loading,
  },
  props: {
    isEstimate: { type: Boolean, required: false, default: true },
    leaguePrograms: {
      type: Array as PropType<UpwardProgramTypeID[]>,
      default: getEmptyUpwardProgramTypeID,
      required: false,
    },
    currentProductConfigProgram: {
      type: Object as PropType<UpwardProgramTypeID>,
      required: false,
      default: getEmptyUpwardProgramTypeID,
    },
    viewedSteps: { type: Array as PropType<string[]>, required: false, default: () => [] },
  },
  setup(props) {
    const productOfferingConfig = computed(() => store.getters.productOfferings.productOfferingConfig)
    const availableStartupProducts = computed(() => store.getters.productOfferings.availableStartupProducts)
    const availableProductGroups = computed(() => store.getters.productOfferings.availableProductGroups)
    const currentProgramType = computed(() => store.getters.productFlow.currentProgramType)
    const selectedProductOfferings = computed(() => store.getters.productOfferings.selectedProductOfferings)
    const productCatalogs = computed(() => store.getters.productOfferings.productCatalogs)
    const selectedProductDetails = computed(() => store.getters.productOfferings.selectedProductDetails)
    const detailsStartupOrder = computed(() => store.getters.productOfferings.detailsStartupOrder)

    const selectedProgram = ref<UpwardProgramTypeID>({} as UpwardProgramTypeID)
    const showOptionalProductText = ref(false)
    const showMultiPriceWarning = ref(false)
    const showAwardWarning = ref(false)

    const hasMultiplePrograms = computed(() => props.leaguePrograms.length > 1)

    function cost(cost: number, optionalText: string) {
      if (optionalText) return `${currency(cost)}${optionalText}`
      return currency(cost)
    }

    const sortedLeaguePrograms = computed(() => {
      const programs = cloneDeep(props.leaguePrograms)
      return programs.sort((a) => (a.isCheer ? 1 : -1))
    })

    const toolName = computed(() => (props.isEstimate ? 'Estimator Tool' : 'Product & Pricing Tool'))

    const discount = computed((): number => 0) //discounts are no longer offered

    function lockedInCost(productId: number): number | null {
      //Existing league will have a lockedInCost
      let lockedInObj: SavedProductOfferingConfigDetail | undefined = {} as SavedProductOfferingConfigDetail

      if (productOfferingConfig.value.details?.length) {
        lockedInObj = productOfferingConfig.value.details.find(
          (i: SavedProductOfferingConfigDetail) => i.productID === productId
        )
      }

      let retval: number | null = null
      if (lockedInObj) {
        retval = lockedInObj.lockedInCost
      }
      return retval
    }

    const uniform = computed((): DisplayableProduct[] => {
      const program = isCheer.value ? 'Cheer' : 'Players'

      //Hide if step has not been viewed on kit stepper.
      if (hideMe(program) || !availableProductGroups.value) return []
      const item = emptyDisplayableProduct()

      //Find the offering for this program type (cheer/sport).
      const uniform = selectedProductOfferings.value.find((p) => p.isCheer == isCheer.value)

      //Does 'Color Selection' exist. If yes, don't show offering.
      const colorSelectionExists = store.getters.productOfferings.selectedProductDetails.some((p) =>
        campColorSelectionProducts.includes(p.productID)
      )
      if (colorSelectionExists) {
        return []
      }

      //Build displayable item
      if (!uniform) return [item]
      item.cmsProductName = uniform.cmsProductName
      item.cost = uniform.cost
      item.productID = null
      item.isMultiPrice = uniform.productCostOffsetLabels ? uniform.productCostOffsetLabels.length > 0 : false
      if (item.isMultiPrice) {
        modifyForMultiPrice(item)
      }

      return [item]
    })

    const estimatedNumberOfCheerleaders = computed(() => productOfferingConfig.value.cheerEstimate ?? 0)

    const estimatedNumberOfPlayer = computed(() => productOfferingConfig.value.playerEstimate ?? 0)

    const startupOrderItems = computed((): DisplayableProduct[] => {
      const item = emptyDisplayableProduct()
      if (hideMe('Startup Order Items')) return []
      const allCosts = totalOfAllStartupOrderItems()
      if (allCosts === 0) return []

      item.cmsProductName = 'Starter Order Items'
      item.cost = allCosts / (estimatedNumberOfCheerleaders.value + estimatedNumberOfPlayer.value)
      return [item]
    })

    const participantProducts = computed((): DisplayableProduct[] => {
      //For camps only, don't show products until the user has selected a color.
      if (store.state.leagueAbstraction.programType == UpwardOpportunityTypes.CAMP) {
        if (store.state.productFlow.maxTouchedParticipantKit1Step == 1) {
          return []
        }
      }
      const products = createDisplayableProductArray()
      return products || []
    })

    const fees = computed((): DisplayableProduct[] => {
      const displayableFees = [] as DisplayableProduct[]

      const offs = categoriesForCurrentProgram.value
      if (!offs) return displayableFees

      const myFees = offs.find(
        (i: ProductOfferingCategoryInfo) => i.typeProductOfferingOptionID === 'UPWARDFEES'
      )

      if (!myFees || !myFees.products) return displayableFees

      myFees.products.forEach((p: ProductOfferingProductInfo) => {
        //const name = p.cmsProductName ?? ''
        if (hideMe(p.upwardProductID ?? '')) return
        const lic = lockedInCost(p.productID)
        displayableFees.push({
          cmsProductName: p.cmsProductName,
          cost: lic || p.productCost,
          costOffset: 0,
          typeParticipantID: myFees.typeParticipantID,
          category: myFees.description,
          categorySortOrder: 0,
          productID: p.productID,
          productSortOrder: 0,
          color: '',
          optionalText: '',
          skipSimpleTotal: false,
          hasGradeGender: false,
          isMultiPrice: false,
          isOptionalApparel: false,
        })
      })
      return displayableFees
    })

    const allProducts = computed(() => [
      ...fees.value,
      ...startupOrderItems.value,
      ...uniform.value,
      ...participantProducts.value,
    ])

    const participantSubtotal = computed(() => {
      if (!participantProducts.value) return
      const myFees = fees.value.reduce((t: number, p: DisplayableProduct) => t + p.cost, 0)
      const starupOrderTotal = startupOrderItems.value.reduce((t, p) => t + p.cost, 0)
      const uniformTotal = uniform.value.reduce((t, p) => t + p.cost, 0)
      const simpleProductTotal = participantProducts.value.reduce(
        (t, p) => (!p.skipSimpleTotal ? t + p.cost : t),
        0
      )
      const mostExpensiveGradeGenderAward = participantProducts.value.reduce(
        (t, p) => (p.hasGradeGender && p.category == 'Awards' && t < p.cost ? p.cost : t),
        0
      )
      return (
        Number(simpleProductTotal) +
        Number(myFees) +
        Number(starupOrderTotal) +
        Number(uniformTotal) +
        Number(mostExpensiveGradeGenderAward)
      )
    })

    const participantTotal = computed(() => Number(participantSubtotal.value) + Number(discount.value))

    function hideMe(step: string) {
      return !props.viewedSteps.includes(step)
    }

    function createDisplayableProductArray(): DisplayableProduct[] {
      let items: DisplayableProduct[] = []
      selectedProductDetailsForCurrentProgram.value.forEach(
        (selectedProduct: SavedProductOfferingConfigDetail) => {
          categoriesForCurrentProgram.value.forEach((cat: ProductOfferingCategoryInfo) => {
            cat.products?.forEach((offering: ProductOfferingProductInfo) => {
              if (offering.productID === selectedProduct.productID) {
                const item = buildDisplayableProduct(selectedProduct, cat, offering)
                if (!item) return
                items.push(cloneDeep(item))
              }
            })
          })
        }
      )
      return items.sort((a, b) => {
        if (a.categorySortOrder == b.categorySortOrder) {
          return a.productSortOrder > b.productSortOrder ? 1 : -1
        }
        return a.categorySortOrder > b.categorySortOrder ? 1 : -1
      })
    }

    const categoriesForCurrentProgram = computed(() => {
      if (selectedOfferingForCurrentProgram.value) {
        const catalogs = cloneDeep(productCatalogs.value)
        const info = catalogs.find((c) => c.id == selectedOfferingForCurrentProgram.value?.id)
        if (info && info.categories) {
          return info.categories.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        } else {
          return [] as ProductOfferingCategoryInfo[]
        }
      }

      return [] as ProductOfferingCategoryInfo[]
    })

    const selectedProductDetailsForCurrentProgram = computed(() => {
      if (selectedOfferingForCurrentProgram.value) {
        return selectedProductDetails.value.filter(
          (p) => p.productOfferingID == selectedOfferingForCurrentProgram.value?.id
        )
      }

      return [] as SavedProductOfferingConfigDetail[]
    })

    const selectedOfferingForCurrentProgram = computed(() => {
      return selectedProductOfferings.value.find((o) => o.isCheer == isCheer.value)
    })

    function totalOfAllStartupOrderItems() {
      if (!availableStartupProducts.value) return 0
      return detailsStartupOrder.value.reduce(
        (acc: number, y: SavedProductOfferingConfigStartupOrderProduct) => {
          const product = availableStartupProducts.value.find(
            (p: StartupOrderProductInfo) => p.id === y.productID
          )
          const cost = product ? product.cost * y.quantity : 0
          return acc + cost
        },
        0
      )
    }

    function modifyForAwards(item: DisplayableProduct) {
      item.optionalText = `${item.optionalText}º`
      item.skipSimpleTotal = true
    }

    function modifyForOptionalApparel(item: DisplayableProduct, cost: number) {
      item.cost = cost
      item.cmsProductName = `${item.cmsProductName} (Optional)`
      item.optionalText = `${item.optionalText}*`
      item.skipSimpleTotal = true
    }

    function modifyForMultiPrice(item: DisplayableProduct) {
      item.optionalText = `${item.optionalText}Ɨ`
    }

    function buildDisplayableProduct(
      selectedProduct: SavedProductOfferingConfigDetail,
      category: ProductOfferingCategoryInfo,
      offering: ProductOfferingProductInfo
    ) {
      const item = emptyDisplayableProduct()
      const cCount = coachCount()
      const pCount = participantCount()
      const liCost = lockedInCost(selectedProduct.productID)

      item.cmsProductName = offering.cmsProductName
      item.typeParticipantID = category.typeParticipantID
      item.category = category.description
      item.categorySortOrder = category.sortOrder
      item.productID = offering.productID
      item.productSortOrder = offering.sortOrder
      item.hasGradeGender = selectedProduct.gradesByGender != null
      item.cost = offering.productCost
      item.color = selectedProduct.typeColorID === 'N/A' ? '' : selectedProduct.typeColorID
      item.isOptionalApparel =
        item.category == 'Optional Apparel' && selectedProduct.typeProductOptionID == 'OPTIONAL'
      item.isMultiPrice = offering.productCostOffsetLabels
        ? offering.productCostOffsetLabels?.length > 0
        : false

      //override cost with lockedInCost
      if (liCost) item.cost = liCost

      //special treatment for optional items in optional apparel (let parents choose)
      if (item.isOptionalApparel) {
        modifyForOptionalApparel(item, selectedProduct.costToCharge)
      }

      //special treatment for awards that are gradeGender specific
      if (item.category == 'Awards' && item.hasGradeGender) {
        modifyForAwards(item)
      }

      //special treatment for products that have multiple possible prices but are not optional (let parents choose)
      if (item.isMultiPrice && !item.isOptionalApparel) {
        modifyForMultiPrice(item)
      }

      //coach products prices are divided by number of players
      if (category.typeParticipantID === 'COACH') {
        if (!cCount || !pCount) return
        item.cost = (item.cost * Number(cCount)) / pCount
      } else if (!category.typeParticipantID) {
        // the price is for the entire league so it needs to be divided by the total number of players
        item.cost = item.cost / pCount
      }
      return item
    }

    function coachCount() {
      return isCheer.value
        ? productOfferingConfig.value.cheerCoachEstimate
        : productOfferingConfig.value.sportCoachEstimate
    }

    function participantCount(): number {
      return isCheer.value
        ? productOfferingConfig.value.cheerEstimate
        : productOfferingConfig.value.playerEstimate
    }

    const show = computed(() => {
      if (allProducts.value.length > 0) return true
      return false
    })

    const participantType = computed(() => {
      return isCheer.value ? 'Cheerleader' : 'Player'
    })

    const isCheer = computed(() => (currentProgramType.value ? currentProgramType.value.isCheer : false))

    watch(
      () => props.currentProductConfigProgram,
      () => {
        if (!hasMultiplePrograms.value) return
        selectedProgram.value = props.currentProductConfigProgram
      }
    )

    watch(
      () => selectedProgram.value,
      () => {
        store.commit.productFlow.setCurrentTypeProgram({ program: selectedProgram.value })
      }
    )

    watch(
      () => allProducts.value,
      () => {
        if (!allProducts.value) return
        const modifiedProducts = allProducts.value.filter((p) => p.optionalText != '')
        let hasMultiple = false
        let hasOptional = false
        let hasAward = false

        modifiedProducts.forEach((p) => {
          if (p.hasGradeGender) {
            hasAward = true
          }
          if (p.isMultiPrice && !p.isOptionalApparel) {
            hasMultiple = true
          }
          if (p.isOptionalApparel) {
            hasOptional = true
          }
        })

        showOptionalProductText.value = hasOptional
        showMultiPriceWarning.value = hasMultiple
        showAwardWarning.value = hasAward
      }
    )

    function emptyDisplayableProduct() {
      return {
        cmsProductName: '',
        cost: 0,
        costOffset: 0,
        typeParticipantID: null,
        category: null,
        categorySortOrder: 0,
        productID: null,
        productSortOrder: 0,
        color: '',
        optionalText: '',
        hasGradeGender: false,
        skipSimpleTotal: false,
        isMultiPrice: false,
      } as DisplayableProduct
    }

    return {
      show,
      toolName,
      hasMultiplePrograms,
      sortedLeaguePrograms,
      selectedProgram,
      allProducts,
      participantType,
      discount,
      currency,
      cost,
      participantSubtotal,
      participantTotal,
      showOptionalProductText,
      showAwardWarning,
      showMultiPriceWarning,
    }
  },
})
