const keyPrefix = 'streamline_'

const getRaw = (key: string) => {
  const fullKey = getFullKey(key)
  return localStorage.getItem(fullKey)
}

const getParsed = <T>(key: string) => {
  const json = getRaw(key)

  if (json === null) {
    return null
  }

  return JSON.parse(json) as T | null
}

const set = (key: string, value: string | object) => {
  const fullKey = getFullKey(key)

  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }

  return localStorage.setItem(fullKey, value)
}

const remove = (key: string) => {
  const fullKey = getFullKey(key)
  localStorage.removeItem(fullKey)
}

const getFullKey = (key: string) => {
  return `${keyPrefix}${key}`
}

export default {
  getRaw,
  getParsed,
  set,
  remove,
}
