import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store/rootState'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'
import upwardTypesClient from '@/clients/upwardTypesClient'
import { ActionsImpl } from 'direct-vuex'

interface LeagueTypesState {
  items: UpwardLeagueTypeID[]
}

const leagueTypesState: LeagueTypesState = {
  items: [],
}

export enum getterNames {
  allItems = 'allItems',
  byOpportunityTypeId = 'byOpportunityTypeId',
  byUpwardTypeId = 'byUpwardTypeId',
  byId = 'byId',
}

const getters: GetterTree<LeagueTypesState, RootState> = {
  [getterNames.allItems]: (state) => state.items,
  [getterNames.byOpportunityTypeId]: (state) => (opportunityTypeId: string) =>
    state.items.filter((x) => x.typeOpportunityID == opportunityTypeId),
  [getterNames.byUpwardTypeId]: (state) => (upwardTypeId: string) =>
    state.items.find((x) => x.upwardTypeID == upwardTypeId) ?? null,
  [getterNames.byId]: (state) => (id: number) => state.items.find((x) => x.id == id) ?? null,
}

export enum mutationNames {
  setAll = 'setAll',
}

const mutations: MutationTree<LeagueTypesState> = {
  [mutationNames.setAll](state, { items }) {
    state.items = items
  },
}

export enum actionNames {
  fetchAll = 'fetchAll',
}

const actions: ActionTree<LeagueTypesState, RootState> & ActionsImpl = {
  async [actionNames.fetchAll]({ commit, getters }, force = true): Promise<boolean> {
    if (getters[getterNames.allItems].length && !force) {
      return false
    }

    const result = await upwardTypesClient.retrieveLeagueTypes()

    if (result.isSuccess) {
      const items = result.data
      commit(mutationNames.setAll, { items })
      return true
    }

    return false
  },
}

export const namespace = 'leagueTypes'

export const leagueTypes = {
  namespaced: true as true,
  state: leagueTypesState,
  getters,
  actions,
  mutations,
}
