import { getEmptyIndividualAddress } from '@/lib/support/models/IndividualAddress/data'
import { Dictionary } from 'vue-router/types/router'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'

export function getEmptyPlayerContact(seed?: PlayerContactSeed) {
  return {
    typeRelationshipID: seed?.typeRelationshipID || PlayerContactTypeEnum.OTHER,
    isEmergencyContact: false,
    priority: 0,
    individualID: seed?.individualID ?? 0,
    firstName: '',
    lastName: '',
    middleInitial: '',
    addresses: [getEmptyIndividualAddress()],
    emailAddresses: [],
    phoneNumbers: [],
    thirdPartyClientIDs: null,
    optOutEmails: false,
    optOutSMS: false,
    inviteAllowed: true,
  }
}

interface RelationshipMap {
  UpwardRelationshipTypeID: string
  Description: string
  SortOrder: number
}

export enum PlayerContactTypeEnum {
  FATHER = 'FATHER',
  OTHER = 'OTHER',
  MOTHER = 'MOTHER',
  LEGALGUARDIAN = 'LEGALGUARDIAN',
  AUNT = 'AUNT',
  UNCLE = 'UNCLE',
  STEPFATHER = 'STEPFATHER',
  STEPMOTHER = 'STEPMOTHER',
  GRANDFATHER = 'GRANDFATHER',
  GRANDMOTHER = 'GRANDMOTHER',
}

const RELATIONSHIPS: RelationshipMap[] = [
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.FATHER, Description: 'Father', SortOrder: 1 },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.MOTHER, Description: 'Mother', SortOrder: 2 },
  {
    UpwardRelationshipTypeID: PlayerContactTypeEnum.LEGALGUARDIAN,
    Description: 'Legal Guardian',
    SortOrder: 3,
  },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.AUNT, Description: 'Aunt', SortOrder: 4 },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.UNCLE, Description: 'Uncle', SortOrder: 5 },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.OTHER, Description: 'Other', SortOrder: 6 },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.STEPFATHER, Description: 'Step-Father', SortOrder: 7 },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.STEPMOTHER, Description: 'Step-Mother', SortOrder: 8 },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.GRANDFATHER, Description: 'Grand-Father', SortOrder: 9 },
  { UpwardRelationshipTypeID: PlayerContactTypeEnum.GRANDMOTHER, Description: 'Grand-Mother', SortOrder: 10 },
]

export function relationshipKVMap(): Dictionary<string> {
  const val: Dictionary<string> = {}
  RELATIONSHIPS.forEach((x) => {
    val[x.UpwardRelationshipTypeID] = x.Description
  })
  return val
}

export class PlayerContactSeed {
  typeRelationshipID: string = PlayerContactTypeEnum.OTHER
  individualID = 0
}

let playerID = -1
export function decrementingPlayerContactIDSeedStrategy() {
  const c = new PlayerContactSeed()
  c.individualID = playerID--
  return c
}

//4651
/*
function hasEmail(e: IndividualEmail[] | null) {
  return e && e.length && e.some((x) => x.emailAddress)
}
*/

function hasContact(c: PlayerContact) {
  return c && c.lastName && c.firstName
}

function hasPhone(c: IndividualPhone[] | null) {
  return c && c.length && c.some((x) => x.phoneNumber)
}

/**
 * Returns if this appears to be a completely filled out contact for creating a volunteer
 *
 * @param c contact to look at
 * @return is a complete contact
 */
export function isCompleteContact(c: PlayerContact) {
  return hasContact(c) && hasPhone(c.phoneNumbers) // && hasEmail(c.emailAddresses) // 4651
}

/**
 * This inspects a list of contacts and makes sure there is at least one
 * that is complete
 * @param c array of contacts to inspect
 */
export function hasCompleteContacts(c: PlayerContact[]) {
  return c.length && isCompleteContact(c[0])
}
