import { SavedProductOfferingConfigStartupOrderProduct } from '@/GeneratedTypes/SavedProductOfferingConfigStartupOrderProduct'

export function getEmptySavedProductOfferingConfigStartupOrderProduct(): SavedProductOfferingConfigStartupOrderProduct {
  return {
    productID: 0,
    typeColorID: 'N/A',
    typeSizeID: 'N/A',
    quantity: 0,
  }
}

export function getEmptySavedProductOfferingConfigStartupOrderProductList(): SavedProductOfferingConfigStartupOrderProduct[] {
  return [getEmptySavedProductOfferingConfigStartupOrderProduct()]
}
