








import { defineComponent, ref, watch, onMounted, PropType } from '@vue/composition-api'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { PlayerEvaluation } from '@/GeneratedTypes/PlayerEvaluation'
import { EvaluationEditGridColumns } from '@/lib/components/PlayerEvaluationEditModal/EvaluationEditGridColumns'
import { GridColumn } from '@/models/Grid/GridColumn'
import { isEqual } from 'lodash'

export default defineComponent({
  name: 'PlayerEvaluationEditGrid',
  components: {
    SimpleGrid,
  },
  props: {
    value: { type: Array as PropType<PlayerEvaluation[]>, default: [] },
  },
  setup(props, ctx) {
    const columns = ref<GridColumn[]>([])
    const updatingInternalEvaluation = ref(false)
    const internalEvaluation = ref<PlayerEvaluation[]>([])

    function setEditValue(data: PlayerEvaluation, value: number) {
      const i = internalEvaluation.value.findIndex((x) => x.activityID == data.activityID)
      if (i >= 0) {
        internalEvaluation.value[i].activityValue = value
      }
    }

    function setInternalEvaluation() {
      updatingInternalEvaluation.value = true
      if (props.value) {
        // create an immutable representation so we don't have bugs backtracking
        internalEvaluation.value = props.value
          .map((x) => ({ ...x }))
          .sort((a, b) => a.sortOrder - b.sortOrder)
      }
      updatingInternalEvaluation.value = false
    }

    watch(
      () => props.value,
      () => {
        if (!isEqual(props.value, internalEvaluation.value)) {
          setInternalEvaluation()
        }
      }
    )

    watch(
      () => internalEvaluation.value,
      () => {
        if (!updatingInternalEvaluation.value && !isEqual(props.value, internalEvaluation.value)) {
          ctx.emit('input', internalEvaluation.value)
        }
      },
      { deep: true }
    )

    onMounted(() => {
      setInternalEvaluation()

      columns.value = EvaluationEditGridColumns(setEditValue)
    })

    return {
      columns,
      internalEvaluation,
    }
  },
})
