





















import PlayerConflictListElement from '@/views/Programs/Divisions/TeamManagement/vues/PlayerConflictListElement.vue'
import PlayerAdminNoteEditModal from '@/views/Programs/Divisions/TeamManagement/vues/PlayerAdminNoteEditModal.vue'
import { PlayerConflictInfo } from '@/GeneratedTypes/ListInfo/PlayerConflictInfo'
import { Container } from 'vue-smooth-dnd'
import { cloneDeep } from 'lodash'
import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import participantsClient from '@/clients/participantsClient'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

interface ConflictOrderType {
  conflict: PlayerConflictInfo
  order: number
  key: string
}

/***
 * Component hosts a sortable list of participants
 */
export default defineComponent({
  name: 'PlayerConflictList',
  components: { Container, PlayerConflictListElement, PlayerAdminNoteEditModal },
  props: {
    conflicts: { type: Array as PropType<PlayerConflictInfo[]>, required: true },
    loading: Boolean,
    upwardLeagueID: { type: String, required: true },
  },
  setup(props, ctx) {
    const { getConflictFilters } = userPreferences()
    const isAdminNoteModalVisible = ref(false)
    const adminNoteConflict = ref<PlayerConflictInfo | null>(null)
    const conflictOrder = ref<ConflictOrderType[]>([])
    /**
     * our payload is the participant at the given index.
     */
    const getPayload = (index: number) => {
      return conflictOrder.value.find((x) => x.order == index)
    }

    /***
     * When we get new participants, then sort by last name and set the order.
     */
    watch(
      () => props.conflicts,
      () => {
        applyFilters()
      },
      { immediate: true }
    )

    watch(
      () => getConflictFilters.value,
      () => {
        applyFilters()
      },
      { immediate: true, deep: true }
    )

    function applyFilters() {
      if (props.conflicts && props.conflicts.length > 0) {
        let order = 0
        const copyList = cloneDeep(props.conflicts)
        conflictOrder.value = copyList
          .filter((c) => {
            return getConflictFilters.value.some(
              (f) => f.value && f.typeDraftExceptionID == c.typeDraftExceptionID
            )
          })
          .map((x) => ({
            order: order++,
            conflict: x,
            key: x.typeDraftExceptionID + '_' + x.individualID,
          }))
      } else {
        conflictOrder.value = []
      }
    }

    const toggleTeam = (teamID: number) => ctx.emit('toggle-team', teamID)

    const saveAdminNote = async (c: PlayerConflictInfo, note: string) => {
      try {
        const lp = await participantsClient.retrieveParticipant(props.upwardLeagueID, c.individualID)
        if (lp) {
          const prog = lp.programs?.find((x) => x.typeProgramID == c.typeProgramID)
          if (prog) {
            prog.adminNotes = note
            await participantsClient.save(props.upwardLeagueID, lp, false, false)
            ctx.emit('refresh-conflicts')
            isAdminNoteModalVisible.value = false
          }
        }
      } catch (e) {
        throw e
      }
    }

    const popupAdminNoteModal = (c: PlayerConflictInfo) => {
      adminNoteConflict.value = c
      isAdminNoteModalVisible.value = true
    }

    const closeAdminNote = () => {
      isAdminNoteModalVisible.value = false
    }

    return {
      getPayload,
      conflictOrder,
      toggleTeam,
      adminNoteConflict,
      isAdminNoteModalVisible,
      saveAdminNote,
      closeAdminNote,
      popupAdminNoteModal,
    }
  },
})
