export interface DivisionSquadMapElement {
  cheerDivisionID: number
  sportDivisionID: number
}

export type DivisionSquadMap = DivisionSquadMapElement[]

export function getEmptyDivisionSquadMap(): DivisionSquadMap {
  return []
}
