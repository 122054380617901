import { RegistrationTransactionParticipantExtended } from '@/models/Registration/RegistrationTransactionParticipantExt'

export enum doWhatType {
  REFUND,
  REMOVE,
}

export interface RefundRemoveTransactionType {
  transaction: RegistrationTransactionParticipantExtended
  type: doWhatType
  reason: string
}
