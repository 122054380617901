export interface RegistrationCapacityCount {
  currentCount: number
  waitlistCount: number
}

export function getEmptyRegistrationCapacityCount(): RegistrationCapacityCount {
  return {
    currentCount: 0,
    waitlistCount: 0,
  }
}
