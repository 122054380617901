

























































import { defineComponent, PropType, computed } from '@vue/composition-api'
import currency from '@/filters/currency'
import { Rollup } from '@/views/Programs/Orders/league/types/Rollup'
import { xStartupOrderExt } from '@/models/Order/xStartupOrderExt'

export default defineComponent({
  name: 'OrderRollup',
  components: {},
  methods: { currency },
  props: {
    rollups: { type: Array as PropType<Rollup[]>, required: true },
    order: { type: Object as PropType<xStartupOrderExt>, required: false },
  },
  setup(props) {
    const discount = computed(() => props.rollups.map((i) => i.discount).reduce((t, a) => a + t, 0))

    const subtotal = computed(() => {
      const rollupTotal = props.rollups.map((i) => i.total).reduce((t, a) => a + t, 0)
      return rollupTotal
    })

    const salesTax = computed(() => (props.order && props.order.salesTax ? props.order.salesTax : 0))

    const total = computed(() => {
      /*const totalProductAmount =
        props.order && props.order.totalOrderedProductAmount ? props.order.totalOrderedProductAmount : 0
      */
      return subtotal.value + salesTax.value + discount.value
    })

    return {
      subtotal,
      total,
      salesTax,
      discount,
    }
  },
})
