import { LeagueDivisionGradeInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionGradeInfo'
import store from '@/store'

export const GradeRangeArrayToText = (grades: LeagueDivisionGradeInfo[] | null, isByAge: boolean) => {
  let gradeRange: string | null = null
  if (grades) {
    if (grades.length === 1) {
      gradeRange = isByAge
        ? store.getters.gradeTypes.byUpwardTypeId(grades[0].typeGradeID)?.ageByCutoff
        : store.getters.gradeTypes.byUpwardTypeId(grades[0].typeGradeID)?.description
    }
    if (grades.length > 1) {
      gradeRange = `${
        isByAge
          ? store.getters.gradeTypes.byUpwardTypeId(grades[0].typeGradeID)?.ageByCutoff
          : store.getters.gradeTypes.byUpwardTypeId(grades[0].typeGradeID)?.description
      } - ${
        isByAge
          ? store.getters.gradeTypes.byUpwardTypeId(grades[grades.length - 1].typeGradeID)?.ageByCutoff
          : store.getters.gradeTypes.byUpwardTypeId(grades[grades.length - 1].typeGradeID)?.description
      }`
    }
  }
  return gradeRange ? gradeRange : ''
}
