














































































































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import CampSetupMixin from '@/views/Camps/CampSetupMixin'
import Loading from '@/elements/Loading.vue'

import { getCoachUdfs } from '@/services/udfService'
import { getGradesList, getProperGradeLabel } from '@/services/gradeService'
import {
  getProgramRegistrationOption,
  setProgramRegistrationOption,
  RegistrationType,
} from '@/services/registrationInfoService'
import { getGradesListFromAccount } from '@/services/gradeService'
import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount.ts'

import date from '@/filters/date'
import properCase from '@/filters/properCase'
import currency from '@/filters/currency'

@Component({
  components: {
    Loading,
  },
  filters: {
    date,
    properCase,
    currency,
  },
  methods: {
    getProperGradeLabel,
  },
})
export default class Archive extends Mixins(CampSetupMixin) {
  private girlGrades: string[] = []
  private boyGrades: string[] = []
  private internalPracticeNights: string[] = []

  private registrationOption: RegistrationType = { registrationTypes: [], onlineRegistrationType: '' }

  private get hasCheer(): boolean {
    return (this.camp.programs && this.camp.programs.length > 1) ?? false
  }

  private get typeProgramId(): string {
    if (this.camp.programs && this.camp.programs.length > 0) {
      return this.camp.programs[0].typeProgramID
    }
    return ''
  }

  private get hasEarlyRegistration(): boolean {
    const isDate = this.camp.lateDate instanceof Date && !isNaN(this.camp.lateDate.valueOf())

    if (isDate) {
      const date = this.camp.lateDate as Date
      return date !== null
    }

    return this.camp.lateDate !== null
  }

  @Watch('initialLoadDone')
  private onInitialLoadDone() {
    this.setGradesValue()
    this.setRegistrationOption()
  }

  private setRegistrationOption() {
    const registrationOption = getProgramRegistrationOption(this.camp)

    if (registrationOption !== null) {
      this.registrationOption = registrationOption
    }
  }

  @Watch('registrationOption', { deep: true })
  private updateRegistrationOptions() {
    if (this.loading) {
      return
    }

    setProgramRegistrationOption(this.camp, this.registrationOption)
  }

  private setGradesValue() {
    if (this.camp.programs && this.camp.programs.length > 0) {
      let typeProgramId = this.camp.programs[0].typeProgramID
      this.boyGrades = cloneDeep(getGradesList(this.camp, typeProgramId, 'M')).map((x) =>
        this.isByAge ? x.ageByCutoff.toString() : x.description!
      )
      this.girlGrades = cloneDeep(getGradesList(this.camp, typeProgramId, 'F')).map((x) =>
        this.isByAge ? x.ageByCutoff.toString() : x.description!
      )
    }
  }

  private getCoachUdfList() {
    return getCoachUdfs(this.camp)
  }

  private getGradeString(account: LeagueAccount, isBoys: boolean): string {
    if (account) {
      if (isBoys) {
        const boyGrades = cloneDeep(getGradesListFromAccount(account, this.typeProgramId, 'M')).map((x) =>
          this.isByAge ? x.ageByCutoff.toString() : x.description!
        )
        return `${boyGrades[0]} - ${boyGrades[boyGrades.length - 1]}`
      }
      const girlGrades = cloneDeep(getGradesListFromAccount(account, this.typeProgramId, 'F')).map((x) =>
        this.isByAge ? x.ageByCutoff.toString() : x.description!
      )
      return `${girlGrades[0]} - ${girlGrades[girlGrades.length - 1]}`
    }
    return ''
  }
}
