













import { defineComponent, computed } from '@vue/composition-api'

import CheckboxInput from '@/elements/CheckboxInput.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

export default defineComponent({
  name: 'ColumnSelect',
  components: { CheckboxInput },
  props: {
    program: { type: String, required: true },
  },
  setup(props) {
    const { setColumns, getColumns } = userPreferences()

    const update = (columnID: string) => {
      setColumns(columnID)
    }

    const columnList = () => {
      let columns = getColumns.value
      const showHeight = props.program == 'BASKETBALL' || props.program == 'VOLLEYBALL'
      const hideScore =
        props.program == 'BASKETBALLCHEERLEADING' ||
        props.program == 'FOOTBALLCHEERLEADING' ||
        props.program == 'UPWARDSELECTMSS'
      if (!showHeight) columns = columns.filter((c) => c.id != 'height')
      if (hideScore) columns = columns.filter((c) => c.id != 'score')

      return columns
    }

    return {
      update,
      columns: computed(columnList),
    }
  },
})
