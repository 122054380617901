/**
 * Rest Operations for user services
 */

import restService from '@/services/restService'

import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { RegistrationTransactionParticipantExtended } from '@/models/Registration/RegistrationTransactionParticipantExt'
import { RegistrationTransactionParticipantInfoDetails } from '@/models/Registration/RegistrationTransactionParticipantInfoDetails'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import { CreditCardTypeEnum } from '@/models/Registration/CreditCardTypeEnum'
import { RegistrationInvite } from '@/models/Registration/RegistrationInvite'
import { RegistrationCapacityWaitListInfo } from '@/GeneratedTypes/ListInfo/RegistrationCapacityWaitListInfo'
import { RegistrationCapacityWaitList } from '@/GeneratedTypes/RegistrationCapacityWaitList'

const baseUrl = 'Registrations'

class UserClientException extends GeneralError {
  name = 'User API Error'
}

export interface RegistrationSearchParams {
  accountNumber: string
  upwardLeagueID: string
  confirmationNumber: string
  creditCardLastFour: string
  lastName: string
  invoiceNumber: string | null
}

export class registrationClient {
  /**
   * Searches the list of registrations by the terms
   * @param term
   */
  static async search(terms: RegistrationSearchParams) {
    const x = await restService.post<RegistrationTransactionParticipantExtended[]>(`${baseUrl}/search`, terms)
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException('A problem occurred searching users.')
  }

  /**
   * This will remove or refund an OPR transaction.
   * @param league - string version
   * @param leagueID - league id (number version)
   * @param id - individual id
   * @param reason - user entered reason
   * @param refund - true will refund, false - will not refund
   */
  static async refund(
    league: UpwardLeagueIDType,
    leagueID: number,
    id: number,
    reason: string,
    refund: boolean
  ) {
    const x = await restService.post<RegistrationTransactionParticipantExtended[]>(
      `${baseUrl}/${league}/${id}/refund`,
      { id, leagueID, refund, reason }
    )
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException(`A problem occurred refunding user ${id}.`)
  }

  /**
   * This will mark the registration complete.
   * @param league - string version
   * @param leagueID - league id (number version)
   * @param id - individual id
   * @param typePaymentMethodID
   * @param authorizationCode
   * @param creditCardLastFour
   */
  static async complete(
    league: UpwardLeagueIDType,
    leagueID: number,
    id: number,
    typePaymentMethodID: CreditCardTypeEnum,
    authorizationCode: string,
    creditCardLastFour: string
  ) {
    const x = await restService.post<RegistrationTransactionParticipantExtended[]>(
      `${baseUrl}/${league}/${id}/complete`,
      { id, leagueID, typePaymentMethodID, authorizationCode, creditCardLastFour }
    )
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException(`A problem occurred refunding user ${id}.`)
  }

  /**
   * This will mark the registration complete.
   * @param league - string version
   * @param id - individual id
   */
  static async getChargebackDetails(league: UpwardLeagueIDType, id: number) {
    const x = await restService.get<RegistrationTransactionParticipantInfoDetails[]>(
      `${baseUrl}/${league}/${id}`
    )
    if (x.isSuccess) {
      return x.data
    }
    throw new UserClientException(`A problem occurred getting chargeback details ${id}.`)
  }

  /**
   * This will send an invitation to multiple particiants on the waitlist.
   * @param upwid
   * @param program
   * @param invitationList
   */
  static async sendInvite(upwid: UpwardLeagueIDType, program: string, invitationList: RegistrationInvite) {
    const x = await restService.post<RegistrationCapacityWaitListInfo[]>(
      `${baseUrl}/waitlist/invites/${upwid}/${program}`,
      invitationList
    )
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException(`A problem occurred sending registration invitations`)
  }

  /**
   * This will send an invitation to a particiant on the waitlist.
   * @param upwid
   * @param inviteId
   * @param invite
   */
  static async update(upwid: UpwardLeagueIDType, inviteId: number, invite: RegistrationCapacityWaitListInfo) {
    const x = await restService.put<RegistrationCapacityWaitListInfo>(
      `${baseUrl}/waitlist/${upwid}/${inviteId}`,
      invite
    )
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException(`A problem occurred updating an invitation`)
  }

  static async addToWaitlist(data: RegistrationCapacityWaitList) {
    return await restService.post<any>(`${baseUrl}/waitlist`, data)
  }
}
