




















import { defineComponent } from '@vue/composition-api'

import Step from '@/components/ProductFlow/Step.vue'

import { productFlowLogic } from '@/components/ProductFlow/ProductFlowLogic'

export default defineComponent({
  name: 'AccessFeeStep',
  props: { step: { type: Number, required: true } },
  components: { Step },
  setup() {
    const logic = productFlowLogic()
    const items = [
      {
        title: 'Free Shipping',
        iconClass: 'fas fa-truck fa-lg',
        text: ' Shipping included for all orders',
      },
      {
        title: 'Ministry Resouces',
        iconClass: 'fas fa-bible fa-lg',
        text: `Access to Camp Devotions, A Camp Parent Take Home Devotion, and a 30 Days of Prayer List`,
      },
      {
        title: 'Advertising Resources',
        iconClass: 'fas fa-bullhorn fa-lg',
        text: 'Advertising Posters, E-Flyers, and Social Media Memes',
      },
      {
        title: 'Camp Management Resources',
        iconClass: 'fas fa-headset fa-lg',
        text: 'Director Manual, Coach Playbook, Forms, Applications, and more!',
      },
      {
        title: 'Volunteer Role Resources',
        iconClass: 'fas fa-book fa-lg',
        text: 'Coach playbooks and Camp Devotions',
      },
      {
        title: 'Support Center Assistance',
        iconClass: 'fas fa-headset fa-lg',
        text: 'A support center that you can call to receive assistance as the need arises',
      },
      {
        title: 'Consultation Services',
        iconClass: 'fas fa-hands-helping fa-lg',
        text:
          'One-on-one consultation services to help you with any challenges you may have and strategically plan for your upcoming programs',
      },
    ]
    return { items, ...logic }
  },
})
