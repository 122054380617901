import { GetterTree, MutationTree, ActionTree, Commit } from 'vuex'
import { RootState } from '@/store/rootState'
import { TeamPageAlertInfo } from '@/GeneratedTypes/ListInfo/TeamPageAlertInfo'
import { League } from '@/GeneratedTypes/League'
import leagueClient from '@/clients/leaguesClient'
import alertClient from '@/clients/alertClient'
import { ActionsImpl } from 'direct-vuex'
import { getEmptyTeamPageAlertInfo } from '@/lib/support/models/TeamPageAlertInfo/data'
import { getEmptyLeague } from '@/lib/support/models/League/data'

export enum viewModes {
  list = 'list',
  create = 'create',
  edit = 'edit',
}

interface TeamPageAlertState {
  currentItem: TeamPageAlertInfo
  items: TeamPageAlertInfo[]
  viewMode: viewModes
  inEdit: TeamPageAlertInfo
  emptyFacility: TeamPageAlertInfo | null
  currentLeague: League
}

const teamPageAlertState: TeamPageAlertState = {
  currentItem: getEmptyTeamPageAlertInfo(),
  inEdit: getEmptyTeamPageAlertInfo(),
  emptyFacility: null,
  items: new Array<TeamPageAlertInfo>(),
  viewMode: viewModes.list,
  currentLeague: getEmptyLeague(),
}

export enum getterNames {
  currentItem = 'currentItem',
  items = 'items',
  editorItem = 'editorItem',
  viewMode = 'viewMode',
  emptyTeamPageAlert = 'emptyTeamPageAlert',
  currentLeague = 'currentLeague',
}

const getters: GetterTree<TeamPageAlertState, RootState> = {
  [getterNames.currentItem]: (state: TeamPageAlertState) => state.currentItem,
  [getterNames.items]: (state: TeamPageAlertState) => state.items,
  [getterNames.editorItem]: (state: TeamPageAlertState) => state.inEdit,
  [getterNames.viewMode]: (state: TeamPageAlertState) => state.viewMode,
  [getterNames.emptyTeamPageAlert]: () => getEmptyTeamPageAlertInfo(),
  [getterNames.currentLeague]: (state: TeamPageAlertState) => state.currentLeague,
}

export enum mutationNames {
  setCurrent = 'setCurrent',
  setItems = 'setItems',
  updateViewMode = 'updateViewMode',
  addItem = 'addItem',
  removeItem = 'removeItem',
  setInEditItem = 'setInEditItem',
  setEmptyTeamPageAlert = 'setEmptyTeamPageAlert',
  setCurrentLeague = 'setCurrentLeague',
}

const mutations: MutationTree<TeamPageAlertState> = {
  [mutationNames.setCurrent](state: TeamPageAlertState, { item }: { item: TeamPageAlertInfo }) {
    state.currentItem = item
  },
  [mutationNames.setItems](state: TeamPageAlertState, { items }: { items: TeamPageAlertInfo[] }) {
    state.items = items

    if (items && items.length > 0) {
      state.currentItem = items[0]
    }
  },
  [mutationNames.updateViewMode](state: TeamPageAlertState, { item }: { item: viewModes }) {
    state.viewMode = item
  },
  [mutationNames.addItem](state: TeamPageAlertState, { item }: { item: TeamPageAlertInfo }) {
    state.items.push(item)
  },
  [mutationNames.removeItem](state: TeamPageAlertState, { id }: { id: number }) {
    const itemToRemove = state.items.find((f) => f.id === id)

    if (itemToRemove) {
      const itemIndex = state.items.indexOf(itemToRemove)
      state.items.slice(itemIndex, 1)
    }
  },
  [mutationNames.setInEditItem](state: TeamPageAlertState, { item }: { item: TeamPageAlertInfo }) {
    state.inEdit = item
  },
  [mutationNames.setEmptyTeamPageAlert](state: TeamPageAlertState, { item }: { item: TeamPageAlertInfo }) {
    state.emptyFacility = item
  },
  [mutationNames.setCurrentLeague](state: TeamPageAlertState, { item }: { item: League }) {
    state.currentLeague = item
  },
}

export enum actionNames {
  load = 'load',
  set = 'set',
  beginCreating = 'beginCreating',
  endCreating = 'endCreating',
  beginEdit = 'beginEdit',
  endEdit = 'endEdit',
  save = 'save',
  delete = 'delete',
}

const actions: ActionTree<TeamPageAlertState, RootState> & ActionsImpl = {
  async [actionNames.load](
    { commit }: { commit: Commit },
    { id }: { id: string }
  ): Promise<TeamPageAlertInfo[] | null> {
    const alerts = await alertClient.retrieveTeamPageAlerts(id)
    const league = await leagueClient.retrieve(id)

    commit(mutationNames.setCurrentLeague, { item: league })

    commit(mutationNames.setItems, { items: alerts ?? [] })
    return alerts
  },
  [actionNames.set]({ commit }: { commit: Commit }, { id }: { id: number }): TeamPageAlertInfo | null {
    const alert = teamPageAlertState.items.find((item) => item.id === id)

    if (alert) {
      commit(mutationNames.setCurrent, { item: alert })

      return alert
    }

    return null
  },
  [actionNames.beginCreating]({ commit }: { commit: Commit }) {
    commit(mutationNames.updateViewMode, { item: viewModes.create })

    const alert: TeamPageAlertInfo = getEmptyTeamPageAlertInfo()

    commit(mutationNames.setInEditItem, { item: alert })
  },
  [actionNames.endCreating]({ commit }: { commit: Commit }): void {
    commit(mutationNames.updateViewMode, { item: viewModes.list })
  },
  [actionNames.beginEdit]({ commit }: { commit: Commit }): void {
    commit(mutationNames.updateViewMode, { item: viewModes.edit })
    commit(mutationNames.setInEditItem, { item: teamPageAlertState.currentItem })
  },
  [actionNames.endEdit]({ commit }: { commit: Commit }): void {
    commit(mutationNames.updateViewMode, { item: viewModes.list })
  },
  async [actionNames.save]({
    commit,
    state,
  }: {
    commit: Commit
    state: TeamPageAlertState
  }): Promise<TeamPageAlertInfo | null> {
    const alert = state.inEdit
    const leagueId = state.currentLeague.upwardLeagueID || ''

    const result = await alertClient.saveTeamPageAlert(leagueId, alert)
    const alerts = await alertClient.retrieveTeamPageAlerts(leagueId)
    if (alerts) {
      commit(mutationNames.setItems, { items: alerts })
    }

    return result
  },
  async [actionNames.delete]({ state }: { state: TeamPageAlertState }): Promise<boolean> {
    const alertID = state.currentItem.id

    const deleteResult = await alertClient.removeTeamPageAlert(alertID)

    return deleteResult
  },
}

export const namespace = 'teamPageAlerts'

export const teamPageAlerts = {
  namespaced: true as true,
  state: teamPageAlertState,
  actions,
  getters,
  mutations,
  ...viewModes,
}
