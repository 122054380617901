







import DropDownMultiSelect from '@/components/DropDownMultiSelect.vue'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { getGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'
import { MultiSelectValue } from '@/lib/support/components/MultiSelect/MultiSelectValue'
export default defineComponent({
  components: {
    DropDownMultiSelect,
  },
  setup() {
    const selections = ref([])
    const items = ref(
      getGrades(false).map((g) => {
        return { id: g, description: g } as MultiSelectValue
      })
    )
    watch(
      () => selections.value,
      () => {
        console.log(selections.value.length)
      }
    )
    return { items, selections }
  },
})
