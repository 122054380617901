









































































































import { DisplayType } from '@/models/Calendar/enums'
import { defineComponent, PropType, ref, watch, computed } from '@vue/composition-api'
import { Container } from 'vue-smooth-dnd'
import dayjs from 'dayjs'
import { CalendarTimestamp } from 'vuetify'
import { cloneDeep } from 'lodash'

import { OverlapMode } from '@/models/Calendar/enums'
import { CalendarWeek } from '@/models/Calendar/CalendarWeek'
import SwitchSlot from '@/elements/SwitchSlot.vue'

import CalendarNav from '@/elements/Calendar/CalendarNav.vue'
import CalendarOptions from '@/elements/Calendar/CalendarOptions.vue'
import CalendarableEvents from '@/elements/Calendar/CalendarableEvents.vue'
import CoachConflictList from '@/views/Programs/Divisions/TeamManagement/vues/CoachConflictList.vue'
import CoachConflictFilter from '@/views/Programs/Divisions/TeamManagement/vues/CoachConflictFilter.vue'
import { CoachConflictInfo } from '@/GeneratedTypes/ListInfo/CoachConflictInfo'
import volunteersClient from '@/clients/volunteersClient'
import store from '@/store'

import { createWeeks, getDateById, roundTime } from '@/elements/Calendar/helper'
import { CalendarTime, CalendarData, ClickEvent } from '@/elements/Calendar/types'

export default defineComponent({
  name: 'Calendar',
  components: {
    CalendarNav,
    CalendarOptions,
    Container,
    CalendarableEvents,
    SwitchSlot,
    CoachConflictList,
    CoachConflictFilter,
  },
  props: {
    startDate: { type: String, required: false, default: () => dayjs().format('YYYY-MM-DD') },
    now: { type: String, required: false, default: () => dayjs().format('YYYY-MM-DD HH:mm:ss') },
    weekdays: { type: Array as PropType<number[]>, required: false, default: () => [0, 1, 2, 3, 4, 5, 6] }, // checkbox of weekdays
    overlapMode: { type: String, required: false, default: OverlapMode.COLUMN },
    overlapThreshold: { type: Number, required: false, default: 30 },
    firstDay: { type: String, required: false }, // first date for the calendar. CalendarNav dates are calculated starting from this date
    firstTime: { type: String, required: false, default: '08:00' }, // controls the time initially displayed in the start dropdown
    lastTime: { type: String, required: false, default: '21:00' }, // controls the time initially displayed in the end dropdown
    intervalMinutes: { type: Number, required: false, default: 30 },
    defaultEventDuration: { type: Number, required: false, default: 60 }, // durattion to use when adding new event to calendar
    groupName: { type: String, required: true }, // used to link the calendar and matchup dnd containers
    selectedWeek: { type: Number, require: false, default: 0 }, //the week to select for CalendarNav
    events: {
      type: Array as PropType<CalendarData[]>,
      required: false,
      default: () => [],
    },
    matchups: {
      type: Array as PropType<CalendarData[]>,
      required: false,
      default: () => [],
    },
    upwardLeagueID: { type: String, required: true },
    coachConflictType: { type: String, required: false, default: 'NOTSET' },
  },
  setup(props, ctx) {
    const weekId = ref(0)
    const lWeekdays = ref([1])
    const lFirstTime = ref('')
    const lLastTime = ref('')
    const lEvents = ref<CalendarData[]>([])
    const lMatchups = ref<CalendarData[]>([])
    const start = ref('2022-01-02') // begining of the date range displayed by the calendar
    const end = ref('2022-01-08') // ending of the date range displayed by the calendar
    const allowableDays = ref([0])
    const coachConflicts = ref<CoachConflictInfo[]>([])

    const calendarListOptions = [
      { text: 'teams', value: 'all-teams' },
      { text: 'coach conflicts', value: 'show-coach-conflicts' },
    ]
    const calendarListSwitch = ref(calendarListOptions[0].value)

    watch(
      () => store.getters.scheduling.scheduleChanging,
      async () => {
        if (!store.getters.scheduling.scheduleChanging) {
          await refreshConflicts()
        }
      },
      { immediate: true }
    )

    async function refreshConflicts() {
      const newCoachConflicts = (await volunteersClient.retrieveCoachConflicts(props.upwardLeagueID!)) ?? []
      if (newCoachConflicts.length > 0) {
        coachConflicts.value = newCoachConflicts.filter(
          (c) => c.typeCoachConflictID == props.coachConflictType
        )
      } else {
        coachConflicts.value = []
      }
    }

    let initialDate = ref<null | string>(null)
    const maxDays = computed(() => lWeekdays.value.length)
    const minEventDate = computed(() => {
      lEvents.value.sort((a, b) => (dayjs(a.start).isBefore(b.start) ? -1 : 1))
      return lEvents.value.length ? lEvents.value[0].start : null
    })
    const weeks = computed(() => {
      if (props.firstDay) {
        return createWeeks(new Date(props.firstDay))
      } else {
        return createWeeks(minEventDate.value ? new Date(minEventDate.value) : dayjs().toDate())
      }
    })

    /*
      Number of intervals between the firstTime and lastTime based on the intervalMinutes
    */
    const intervalCount = computed(() => {
      const s = lFirstTime.value.split(':').map((p) => Number(p))
      const e = lLastTime.value.split(':').map((p) => Number(p))
      const totalIntervalMinutes = dayjs().hour(e[0]).minute(e[1]).diff(dayjs().hour(s[0]).minute(s[1]), 'm')
      return Math.ceil(totalIntervalMinutes / props.intervalMinutes)
    })

    function intervalFormat(t: CalendarTimestamp) {
      return dayjs().hour(t.hour).minute(t.minute).second(0).format('h:mm a')
    }

    function intervalStyle(t: any) {
      if (t.minute != '00') {
        return { 'border-top': 'dashed 1px lightgrey' }
      } else {
        return { 'border-top': 'solid 1px darkgrey' }
      }
    }

    function getEventColor(event: any) {
      return event.color ? event.color : '#0475aa'
    }
    function getEventTextColor(event: any) {
      return event.textColor ? event.textColor : '#000'
    }
    function startTime() {
      if (dragEvent.value && eventDuration.value === null) {
        eventDuration.value = dayjs(dragEvent.value.end).diff(dragEvent.value.start, 'm')
      }
    }

    /***************************************
     **   DRAG-AND-DROP WITHIN CALENDAR    *
     ***************************************/
    const dragEvent = ref<CalendarData | null>(null) //store the event to be moved
    const eventDuration = ref<number | null>(null) // event duration in minutes
    const time = ref<CalendarTime | null>(null) //the last interval the mouse was pointing to
    let startXY: { x: number | null; y: number | null } = { x: null, y: null } // x and y when the mouse first clicks an event
    let currentXY: { x: number | null; y: number | null } = { x: null, y: null } //current mouse x and y location
    let isActive = true // active/inactive for the currently selected event
    let isAllowed = true // is the day the event is being dragged to allowed; zero based

    function startDrag({
      event,
      timed,
      nativeEvent,
    }: {
      event: CalendarData
      timed: boolean
      nativeEvent: MouseEvent
    }) {
      startXY.x = nativeEvent.clientX
      startXY.y = nativeEvent.clientY
      isActive = event.class ? !event.class.includes('inactive') : true

      if (event && timed && isActive) {
        dragEvent.value = lEvents.value.find((e) => e.id == event.id) ?? null //intentionally by ref
        eventDuration.value = null
      }
    }

    function endDrag() {
      if (dragEvent.value && mouseHasMoved() && isActive && isAllowed) {
        ctx.emit('drop:internal', { ...dragEvent.value })
      }
    }

    function eventClick(e: ClickEvent) {
      if (!mouseHasMoved() && isActive && isAllowed) {
        ctx.emit('click:event', e.event)
      }

      clear()
    }

    /*
     When the mouse leaves the calendar area
     */
    function cancelDrag() {
      clear()
    }

    /*
      Stores the event as it is being dragged
    */
    function mouseMove(tms: CalendarTime) {
      allowed(dayjs(tms.date).day())
      if (mouseHasMoved() && isAllowed) {
        if (dragEvent.value && eventDuration.value !== null) {
          const newStart = roundTime(dayjs(tms.date).hour(tms.hour).minute(tms.minute), 30)
          const newEnd = newStart.add(eventDuration.value, 'm')
          dragEvent.value.start = newStart.format('YYYY-MM-DDTHH:mm')
          dragEvent.value.end = newEnd.format('YYYY-MM-DDTHH:mm')
        }
      }
      time.value = tms //used when dragging a matchup onto the calenar
    }

    function allowed(dayOfWeek: number) {
      isAllowed = allowableDays.value.some((d) => d == dayOfWeek)
      return isAllowed
    }

    /*     
      Used in endDrag and eventClick so endDrag isn't triggered by a sloppy click from the user.
      If the mouse moves less that minPixels in either direction, emit the click event.
      If it moves more, emit endDrag.
    */
    function mouseHasMoved() {
      if (startXY.x && startXY.y && currentXY.x && currentXY.y) {
        const minPixels = 5
        if (Math.abs(startXY.x - currentXY.x) > minPixels || Math.abs(startXY.y - currentXY.y) > minPixels) {
          return true
        }
      }
      return false
    }

    function mouseMoveNative(e: MouseEvent) {
      currentXY.x = e.clientX
      currentXY.y = e.clientY
    }

    function clear() {
      eventDuration.value = null
      dragEvent.value = null
      startXY.x = null
      startXY.y = null
      isActive = true
      isAllowed = true
    }

    /******************************************
     **   DRAG-AND-DROP FROM LIST TO CALENDAR  *
     *******************************************/
    function matchupDropped(movedEvent: CalendarData) {
      const e = createCalendarEvent(movedEvent)
      if (e) {
        //Event from external to the calendar dropped onto the calendar
        ctx.emit('drop:external', e)
      }
    }

    function createCalendarEvent(movedEvent: CalendarData): CalendarData | null {
      if (time.value) {
        const t = time.value
        const start = roundTime(dayjs(t.date).hour(t.hour).minute(t.minute), 30)
        const end = start.add(props.defaultEventDuration, 'm')
        movedEvent.start = start.format('YYYY-MM-DDTHH:mm:ss')
        movedEvent.end = end.format('YYYY-MM-DDTHH:mm:ss')
        time.value = null
        return { ...movedEvent }
      } else {
        return null
      }
    }
    function formatTime(dt: string) {
      return dayjs(dt).format('h:mma')
    }

    /************************
     **   Watchers          *
     ************************/
    watch(
      () => props.selectedWeek,
      () => {
        if (weekId.value != props.selectedWeek) {
          weekId.value = props.selectedWeek
        }
      },
      { immediate: true }
    )
    watch(
      () => props.weekdays,
      () => {
        allowableDays.value = props.weekdays
        lWeekdays.value = props.weekdays
      },
      { immediate: true }
    )
    watch(
      () => props.firstTime,
      () => (lFirstTime.value = props.firstTime),
      { immediate: true }
    )
    watch(
      () => props.lastTime,
      () => (lLastTime.value = props.lastTime),
      { immediate: true }
    )
    watch(
      () => props.startDate,
      () => (initialDate.value = props.startDate),
      { immediate: true }
    )
    watch(
      () => props.events,
      () => (lEvents.value = cloneDeep(props.events)),
      { immediate: true }
    )
    watch(
      () => props.matchups,
      () => (lMatchups.value = cloneDeep(props.matchups)),
      { immediate: true }
    )
    watch(
      () => weekId.value,
      () => {
        start.value = getDateById(weekId.value, 'start', weeks.value)
        end.value = getDateById(weekId.value, 'end', weeks.value)
        ctx.emit('currentRange', {
          start: start.value,
          end: end.value,
          weekNumber: weekId.value,
        } as CalendarWeek)
      },
      { immediate: true }
    )
    return {
      initialDate,
      intervalFormat,
      intervalStyle,
      intervalCount,
      lFirstTime,
      lLastTime,
      lWeekdays,
      maxDays,
      weeks,
      weekId,
      start,
      end,
      lEvents,
      lMatchups,
      startDrag,
      startTime,
      mouseMove,
      endDrag,
      cancelDrag,
      matchupDropped,
      formatTime,
      DisplayType,
      getEventColor,
      getEventTextColor,
      eventClick,
      mouseMoveNative,
      allowed,
      coachConflicts,
      calendarListOptions,
      calendarListSwitch,
    }
  },
})
