






























import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'

import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import divisionsClient from '@/clients/divisionsClient'

import { IsEmptyLeagueDivisionInfo } from '@/lib/support/models/LeagueDivisionInfo/data'
import store from '@/store'

export default defineComponent({
  name: 'DivisionSelect',
  components: {
    SelectInput,
  },
  props: {
    value: { type: (null as unknown) as PropType<LeagueDivisionInfo | null>, required: true },
    label: { type: String, required: false, default: '' },
    upwardLeagueId: { type: String, required: true },
    typeProgramId: { type: String, required: false, default: '' },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const currentDivision = computed(() => store.getters.divisions.currentDivision)
    const isLoading = ref(false)
    const selectedItem = ref<number | null>(null)
    const divisions = ref<LeagueDivisionInfo[]>([])

    watch(
      () => [props.upwardLeagueId, props.typeProgramId],
      async () => {
        if (isLoading.value) {
          return
        }

        isLoading.value = true
        const itemList = await fetchItems()
        divisions.value = itemList

        // transpile error was wrapping the boolean function in Object() and Object(false) is true ?!
        const emptyLeague = props.value && !!IsEmptyLeagueDivisionInfo(props.value)
        if (!divisions.value.length) {
          store.commit.divisions.setDivision({ division: null })
          selectedItem.value = null
        } else if (
          // do we have a value already set, or is the value set a placeholder
          (!props.value || emptyLeague) && //blank account number is a seeded,
          itemList?.length && // a list
          !currentDivision.value // and not a preselected league
        ) {
          selectedItem.value = itemList[0].divisionID //set first league returned
        } else if (currentDivision.value) {
          const divisionInCurrentList = itemList.find(
            (item) => item.divisionID === currentDivision.value.divisionID
          )
          if (divisionInCurrentList) {
            selectedItem.value = currentDivision.value.divisionID
          } else {
            if (itemList[0] && itemList[0].divisionID) {
              selectedItem.value = itemList[0].divisionID
            }
          }
        }

        isLoading.value = false
      },
      { immediate: true }
    )

    async function fetchItems(): Promise<LeagueDivisionInfo[]> {
      if (props.upwardLeagueId) {
        if (props.typeProgramId) {
          const divs = await divisionsClient.retrieveByProgram(props.upwardLeagueId, props.typeProgramId)
          if (divs) {
            return divs
          }
        }
        const divs = await divisionsClient.retrieveAll(props.upwardLeagueId)
        if (divs) {
          return divs
        }
      }
      return []
    }

    const hasDivisions = computed(() => {
      if (isLoading.value) return !isLoading.value
      return divisions.value.length > 0
    })

    watch(
      () => [isLoading.value, selectedItem.value],
      () => {
        if (isLoading.value) {
          return
        }

        if (!selectedItem.value) {
          ctx.emit('input', null)
          ctx.emit('change', null)
          return
        }

        //else ...
        if (
          selectedItem.value &&
          (!props.value || (props.value && props.value.divisionID !== selectedItem.value))
        ) {
          const division = divisions.value.find((item) => item.divisionID === selectedItem.value)

          if (division) {
            store.commit.divisions.setDivision({ division: division })
          }

          ctx.emit('input', division)
          ctx.emit('change', division)
        }
      }
    )

    watch(
      () => props.value,
      () => {
        if (props.value) {
          if (selectedItem.value !== props.value.divisionID) {
            selectedItem.value = props.value.divisionID
          }
        } else {
          selectedItem.value = null
        }
      }
    )

    return {
      selectedItem,
      hasDivisions,
      divisions,
    }
  },
})
