

















import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'

export interface TabType {
  id: string
  description: string
}

export default defineComponent({
  name: 'HorizontalTabs',
  props: {
    tabs: { type: Array as PropType<TabType[]>, required: true, default: () => [] },
    value: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const internalValue = ref('')
    /**
     * Ensures there is always a tab selected by returning
     * internalValue or first tab.
     */
    const activeTab = computed(() => {
      if (props.tabs.length) {
        const tab = props.tabs.find((x) => x.id == internalValue.value)
        if (tab) {
          return tab.id
        }
        return props.tabs[0].id
      }
      return internalValue.value
    })

    watch(
      () => internalValue.value,
      () => {
        if (internalValue.value !== props.value) {
          ctx.emit('input', internalValue.value)
        }
      }
    )

    watch(
      () => props.tabs,
      () => {
        handleValueChange()
      }
    )

    watch(
      () => props.value,
      () => {
        handleValueChange()
      },
      { immediate: true }
    )

    function handleValueChange() {
      if (props.tabs.some((x) => x.id == props.value)) {
        if (internalValue.value !== props.value) {
          internalValue.value = props.value
        }
      } else {
        internalValue.value = props.tabs.length ? props.tabs[0].id : ''
      }
    }

    function tabClicked(id: string) {
      internalValue.value = id
    }

    return {
      internalValue,
      activeTab,
      tabClicked,
    }
  },
})
