




































import { defineComponent, computed, onBeforeMount, nextTick } from '@vue/composition-api'

import Stepper from '@/elements/Stepper.vue'
import InfoStep from '@/components/ProductFlow/ProductSetupStepper/InfoStep.vue'
import UpwardAccessStep from '@/components/ProductFlow/ProductSetupStepper/LeagueOnlySteps/UpwardAccessStep.vue'
import UpwardBaseFeeStep from '@/components/ProductFlow/ProductSetupStepper/LeagueOnlySteps/UpwardBaseFeeStep.vue'
import UpwardSystemsStep from '@/components/ProductFlow/ProductSetupStepper/LeagueOnlySteps/UpwardSystemsStep.vue'
import StarterItemsStep from '@/components/ProductFlow/ProductSetupStepper/StarterItemsStep.vue'
import ParticipantKitStep from '@/components/ProductFlow/ProductSetupStepper/ParticipantKitStep.vue'
import RunningTotal from '@/components/ProductFlow/ProductSetupStepper/RunningTotal.vue'
import Loading from '@/elements/Loading.vue'
import CurrencyWarning from '@/components/ProductFlow/ProductSetupStepper/CurrencyWarning.vue'
import { setupStepperLogic } from '@/components/ProductFlow/ProductSetupStepper/SetupStepperLogic'
import store from '@/store'

export default defineComponent({
  name: 'LeagueSetupStepper',
  props: {
    isEstimate: { type: Boolean, required: false, default: false },
  },
  components: {
    Stepper,
    InfoStep,
    UpwardAccessStep,
    UpwardBaseFeeStep,
    UpwardSystemsStep,
    StarterItemsStep,
    ParticipantKitStep,
    RunningTotal,
    Loading,
    CurrencyWarning,
  },
  setup(props, { root }) {
    const stepperLogic = setupStepperLogic()
    const configId = Number(root.$route.params.offeringConfigId ?? '0')

    onBeforeMount(async () => {
      stepperLogic.currentStep.value = 0
      if (props.isEstimate) {
        await stepperLogic.initializeEstimate(configId)
      } else {
        await stepperLogic.initialize()
      }
      await store.dispatch.productOfferingProductPackageTypes.fetchAll(false) //get these cached up

      nextTick(() => (stepperLogic.currentStep.value = 1)) //trigger expansion of first step
    })

    const listOfPrograms = computed(() => store.getters.leagueAbstraction.leagueUpwardProgramTypeID)

    const loading = computed(() => {
      if (store.getters.productFlow.isNewProdConfig) {
        return stepperLogic.stepComponents.value.length == 0
      } else {
        return (
          !store.getters.productOfferings.initialProductFlowLoadingComplete &&
          stepperLogic.stepComponents.value.length == 0
        )
      }
    })

    return {
      listOfPrograms,
      loading,
      ...stepperLogic,
    }
  },
})
