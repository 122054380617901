import dayjs from 'dayjs'

export function scheduleSort(a: ScheduleGridData, b: ScheduleGridData) {
  //sort by datetime then location
  const same = dayjs(a.start, 'minute').isSame(b.start, 'minute')
  if (!same) {
    return dayjs(a.start).isAfter(b.start) ? 1 : -1
  } else {
    if (a.location.toLowerCase() > b.location.toLowerCase()) return 1
    if (a.location.toLowerCase() < b.location.toLowerCase()) return -1
    return 0
  }
}

export interface ScheduleGridData {
  start: Date
  end: Date
  prettyDate: string
  prettyTime: string
  location: string
  title: string
  division: string
  round: number
  id: number
  referees?: string[]
}
