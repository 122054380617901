































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import PackageIcon from './PackageIcon.vue'
import { productOfferingProductPackageTypes } from '@/store/productOfferingProductPackageTypes'
import { UpwardProductOfferingProductPackageTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProductOfferingProductPackageTypeID'

export default defineComponent({
  name: 'ProductPackages',
  props: {},
  components: { PackageIcon },
  setup(props, { emit }) {
    function click(pkg: string) {
      emit('click', pkg)
    }

    const packages = ref<UpwardProductOfferingProductPackageTypeID[]>([])

    onMounted(() => {
      packages.value = productOfferingProductPackageTypes.state.items
    })

    return { packages, click }
  },
})
