



























import { defineComponent, ref, watch } from '@vue/composition-api'
import { xReturnOrderExt } from '@/models/Order/xReturnOrderExt'
import ReturnsOrderDetail from '@/views/AdminDash/vues/ReturnsOrderDetail.vue'
import Loading from '@/elements/Loading.vue'
import { loadReturnsOrder } from '@/lib/components/OrderDetail/loadOrder'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'

export default defineComponent({
  name: 'ReturnsOrderDetailPage',
  components: { Loading, ReturnsOrderDetail },
  setup(props, ctx) {
    const currentOrder = ref<xReturnOrderExt | null>(null)
    const currentOrderInfo = ref<OrderInfoExt | null>(null)
    const loading = ref(false)
    const errorMessage = ref('')

    watch(
      () => ctx.root.$route,
      () => getOrderByRoute(),
      { immediate: true }
    )

    function clearErrorClicked() {
      alert('clear error TBD')
    }

    async function getOrderByRoute() {
      const league = ctx.root.$route.params.id ?? ''
      const orderid = ctx.root.$route.params.orderid ?? ''
      try {
        loading.value = true

        await loadReturnsOrder(league, orderid, currentOrder, currentOrderInfo, errorMessage)
      } catch (e) {
        errorMessage.value = e.errorObject?.message || e.message
        loading.value = false
        throw e
      }
      loading.value = false
    }

    async function statusChanged() {
      await getOrderByRoute()
    }

    return {
      currentOrder,
      errorMessage,
      currentOrderInfo,
      loading,
      clearErrorClicked,
      statusChanged,
    }
  },
})
