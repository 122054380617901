




















































import { defineComponent, ref, watch } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
import contentManagementClient from '@/clients/contentManagementClient'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'

export default defineComponent({
  components: { Alert, Loading },
  setup() {
    // set to an error if there is one.
    const error = ref('')
    //clear an error in 20 seconds automatically
    watch(
      () => error.value,
      (a) => {
        if (a) {
          setTimeout(() => (error.value = ''), 20000)
        }
      }
    )

    const loading = ref(false)

    async function clearProductCache(orderType: string) {
      await clearCache(orderType)
    }

    async function clearCache(orderType: string) {
      loading.value = true
      error.value = ''

      try {
        await contentManagementClient.deleteProductCache(orderType)
        productCacheWasCleared()
      } catch (e) {
        const message = e?.innerException?.errorObject?.message

        error.value = 'Product Cache Error ' + (message || e?.message || e?.errorObject?.message || '')
      } finally {
        loading.value = false
      }
    }

    const isProductCachedCleared = ref(false)
    function productCacheWasCleared() {
      isProductCachedCleared.value = true
    }

    return {
      loading,
      clearProductCache,
      productCacheWasCleared,
      isProductCachedCleared,
      notice: AlertTypeEnum.NOTICE,
    }
  },
})
