


































import { defineComponent, PropType, ref, watch, computed } from '@vue/composition-api'
import { EvaluationsMissingType } from '@/clients/reportsClient'
import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { getEmptyLeaguePlayerInfo, LeaguePlayerSeedInfo } from '@/lib/support/models/LeaguePlayerInfo/data'
import BatchEval from '@/views/Programs/Divisions/TeamManagement/vues/batchevals/BatchEval.vue'
import store from '@/store'

export default defineComponent({
  components: { BatchEval },
  props: {
    report: {
      type: Array as PropType<Array<EvaluationsMissingType>>,
      default: () => [],
      required: true,
    },
  },

  setup(props) {
    const internalReport = ref<Array<EvaluationsMissingType>>([])
    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)
    watch(
      () => props.report,
      () => (internalReport.value = props.report),
      { immediate: true }
    )
    const participantLoaded = ref<LeaguePlayerInfo>(getEmptyLeaguePlayerInfo(new LeaguePlayerSeedInfo()))
    const selectedID = ref<number>(0)
    function select(id: number) {
      selectedID.value = id
    }

    return { selectedID, select, participantLoaded, internalReport, isByAge }
  },
})
