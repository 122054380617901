import restService, { RestServiceResult } from '@/services/restService'
import { UpwardOpportunityTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardOpportunityTypeID'
import { UpwardProductGroupTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProductGroupTypeID'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'
import { UpwardTimezoneTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardTimezoneTypeID'
import { UpwardAddressTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardAddressTypeID'
import { UpwardPartnerPhoneTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerPhoneTypeID'
import { UpwardPhoneTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPhoneTypeID'
import { UpwardPartnerPreferredContactMethodTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerPreferredContactMethodTypeID'
import { UpwardPartnerPreferredTimeOfDayTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerPreferredTimeOfDayTypeID'
import { UpwardPartnerDayTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerDayTypeID'
import { UpwardPartnerRoleTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerRoleTypeID'
import { UpwardDevotionTrackTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardDevotionTrackTypeID'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { UpwardSizeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardSizeTypeID'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import { UpwardColorTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardColorTypeID'
import { UpwardReturnReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardReturnReasonTypeID'
import { UpwardOrderStatusTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardOrderStatusTypeID'
import { UpwardProductOfferingProductPackageTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProductOfferingProductPackageTypeID'
import { UpwardPositionTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPositionTypeID'
import { UpwardGPOrderTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGPOrderTypeID'
import { UpwardPaymentMethodTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPaymentMethodTypeID'
import { UpwardDivisionGameTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardDivisionGameTypeID'
import { UpwardLeagueScoringTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueScoringTypeID'
import { UpwardTapeDownReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardTapeDownReasonTypeID'
import { UpwardRelationshipTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardRelationshipTypeID'
import dayjs from 'dayjs'

const baseUrl = 'upwardTypes/'

const retrieveOpportunityTypes = async () => {
  return await restService.get<UpwardOpportunityTypeID[]>(`${baseUrl}opportunityTypes`)
}
/***
 * @return UpwardTapeDownReasonTypeID[] list from the server of tapedown reasons.
 */
const retrieveTapeDownReasons = async () => {
  return (await restService.get<UpwardTapeDownReasonTypeID[]>(`${baseUrl}tapedownreasons`)).data ?? []
}

const retrieveLeagueTypes = async () => {
  return await restService.get<UpwardLeagueTypeID[]>(`${baseUrl}league`)
}

const retrieveProductGroupTypes = async (leagueUpwardTypeId: string, programStartDate: Date | null) => {
  let date = ''
  if (programStartDate) {
    date = `/${dayjs(programStartDate).format('YYYY-MM-DD')}`
  }

  return await restService.get<UpwardProductGroupTypeID[]>(
    `${baseUrl}productGroupTypes/${leagueUpwardTypeId}${date}`
  )
}

const retrieveTimezoneTypes = async () => {
  return await restService.get<UpwardTimezoneTypeID[]>(`${baseUrl}timezoneTypes`)
}

const retrievePhoneTypes = async () => {
  return await restService.get<UpwardPhoneTypeID[]>(`${baseUrl}PhoneTypes`)
}

const retrievePartnerPhoneTypes = async () => {
  return await restService.get<UpwardPartnerPhoneTypeID[]>(`${baseUrl}PhoneTypes`)
}

const retrievePartnerPreferredContactMethods = async () => {
  return await restService.get<UpwardPartnerPreferredContactMethodTypeID[]>(
    `${baseUrl}partnerPreferredContactMethodTypes`
  )
}

const retrievePartnerPreferredTimesOfDay = async () => {
  return await restService.get<UpwardPartnerPreferredTimeOfDayTypeID[]>(
    `${baseUrl}partnerPreferredTimeOfDayTypes`
  )
}

const retrieveSizeTypes = async () => {
  return await restService.get<UpwardSizeTypeID[]>(`${baseUrl}size`)
}

const retrieveGradeTypes = async () => {
  return await restService.get<UpwardGradeTypeID[]>(`${baseUrl}gradeTypes`)
}

const retrievePartnerDayTypes = async () => {
  return await restService.get<UpwardPartnerDayTypeID[]>(`${baseUrl}partnerDayTypes`)
}

const retrieveDevotionTrackTypes = async () => {
  return await restService.get<UpwardDevotionTrackTypeID[]>(`${baseUrl}devotionTrackTypes`)
}

const retrievePartnerRoles = async () => {
  return await restService.get<UpwardPartnerRoleTypeID[]>(`${baseUrl}partnerRoleTypes`)
}

const retrieveProgramTypes = async (leagueTypeId = '') => {
  const type = leagueTypeId ? `/${leagueTypeId}` : ''
  return await restService.get<UpwardProgramTypeID[]>(`${baseUrl}program${type}`)
}

const retrievePaymentTypes = async () => {
  return await restService.get<UpwardPaymentMethodTypeID[]>(`${baseUrl}paymentMethodTypes`)
}

const retrieveAddressTypes = () => {
  // TODO - hook this up to real data when the controller method is available
  const mockResponse: RestServiceResult<UpwardAddressTypeID[]> = {
    data: [
      { id: 1, description: 'Home', upwardTypeID: 'def123' },
      { id: 2, description: 'Work', upwardTypeID: 'def456' },
    ],
    errorObject: null,
    isSuccess: true,
    status: 200,
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, 1000)
  }).then(() => mockResponse)
}

const retrieveColorTypes = async () => {
  return await restService.get<UpwardColorTypeID[]>(`${baseUrl}color`)
}

const retrieveReturnReasonTypes = async () => {
  return await restService.get<UpwardReturnReasonTypeID[]>(`${baseUrl}returnReason`)
}

const retrieveDivisionGameTypes = async () => {
  return await restService.get<UpwardDivisionGameTypeID[]>(`${baseUrl}divisionGameType`)
}

const retrieveLeagueScoringTypes = async () => {
  return await restService.get<UpwardLeagueScoringTypeID[]>(`${baseUrl}leagueScoringType`)
}

const retrieveReturnStatusTypes = async () => {
  return await restService.get<UpwardOrderStatusTypeID[]>(`${baseUrl}returnOrderStatus`)
}

const retrieveProductOfferingProductPackageTypes = async () => {
  return await restService.get<UpwardProductOfferingProductPackageTypeID[]>(
    `${baseUrl}productOfferingProductPackage`
  )
}

const retrievePositionTypes = async () => {
  return await restService.get<UpwardPositionTypeID[]>(`${baseUrl}positions`)
}

const retrieveGPOrderTypes = async () => {
  return await restService.get<UpwardGPOrderTypeID[]>(`${baseUrl}gporder`)
}

const retrieveRelationshipTypes = async () => {
  return await restService.get<UpwardRelationshipTypeID[]>(`${baseUrl}relationship`)
}

export default {
  retrieveOpportunityTypes,
  retrieveLeagueTypes,
  retrieveProductGroupTypes,
  retrieveTimezoneTypes,
  retrievePhoneTypes,
  retrieveAddressTypes,
  retrievePartnerPhoneTypes,
  retrievePartnerPreferredContactMethods,
  retrievePartnerPreferredTimesOfDay,
  retrievePartnerDayTypes,
  retrievePartnerRoles,
  retrieveTapeDownReasons,
  retrieveDevotionTrackTypes,
  retrievePaymentTypes,
  retrieveProgramTypes,
  retrieveSizeTypes,
  retrieveColorTypes,
  retrieveGradeTypes,
  retrieveReturnReasonTypes,
  retrieveReturnStatusTypes,
  retrieveProductOfferingProductPackageTypes,
  retrievePositionTypes,
  retrieveGPOrderTypes,
  retrieveRelationshipTypes,
  retrieveDivisionGameTypes,
  retrieveLeagueScoringTypes,
}
