















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

// elements
import SimpleGrid from '@/elements/SimpleGrid.vue'
import AddUserModal from '@/views/Account/vues/AddUserModal.vue'
import ResetPasswordModal from '@/views/Account/vues/ResetPasswordModal.vue'
import ResendInvitationModal from '@/views/Account/vues/ResendInvitationModal.vue'
import PartnerRoleSelectInput from '@/views/Account/vues/PartnerRoleSelectInput.vue'

// clients
import partnersClient from '@/clients/partnersClient'
import inviteClient from '@/clients/inviteClient'

//models
import { PartnerUserInfo } from '@/models/UserManagement/PartnerUserInfo'
import { GridColumn } from '@/models/Grid/GridColumn'
import { ApplicationInvitation } from '@/GeneratedTypes/Authorize/ApplicationInvitation'

// stores
import { namespace, getterNames } from '@/store/authorization'

@Component({
  components: {
    SimpleGrid,
    AddUserModal,
    PartnerRoleSelectInput,
    ResetPasswordModal,
    ResendInvitationModal,
  },
})
export default class UserManagement extends Vue {
  @Getter(getterNames.firstAccountNumber, { namespace })
  private accountNumber!: string

  selectedTab = 'active'
  isAddUserModalVisible = false
  isResetPasswordModalVisible = false
  isResendInvitationModalVisible = false

  gridData: PartnerUserInfo[] = []
  pendingInvitations: ApplicationInvitation[] = []

  selectedUser: PartnerUserInfo | null = null
  selectedInvitation: ApplicationInvitation | null = null

  columns: GridColumn[] = [
    { field: 'friendlyName', headerText: 'Name', width: 300 } as GridColumn,
    { field: 'username', headerText: 'Username' } as GridColumn,
    { field: 'partnerRoles', headerText: 'Role', customRender: this.renderPartnerRoles } as GridColumn,
  ]

  pendingInvitationColumns: GridColumn[] = [
    { field: 'recipient', headerText: 'Name', width: 300 } as GridColumn,
    { field: 'email', headerText: 'Username' } as GridColumn,
    { field: 'roles', headerText: 'Role', customRender: this.renderPartnerRoles } as GridColumn,
    { field: 'createDate', headerText: 'Invite Status', customRender: this.renderInviteStatus } as GridColumn,
    { field: 'createDate', headerText: 'Date Sent', customRender: this.renderDateSent } as GridColumn,
  ]

  get isPending(): boolean {
    return this.selectedTab == 'pending'
  }

  get isActive(): boolean {
    return this.selectedTab == 'active'
  }

  async created() {
    this.gridData = await this.getUserList()
  }

  async getUserList(): Promise<PartnerUserInfo[]> {
    const data = await partnersClient.retrieveUsers('partner')
    if (data) {
      return data.filter(
        (p) => ((p.partnerRoles ?? []).filter((r) => r === 'Director' || r === 'DataInput') ?? []).length > 0
      )
    }
    return []
  }

  async getPendingInvites(): Promise<ApplicationInvitation[]> {
    const data = await inviteClient.getOpenPartnerInvitations(this.accountNumber)
    if (data) {
      return data
    }
    return []
  }

  renderPartnerRoles(data: PartnerUserInfo) {
    if (Array.isArray(data) && data.length > 0) {
      if (typeof data[0] === 'string') {
        return data.join(', ')
      }
      return data.map((x) => x.roleID).join(', ')
    }
    return ''
  }

  renderInviteStatus(data: Date) {
    const expirationDate = new Date(new Date(data).getTime() + 1000 * 60 * 60 * 48)
    if (expirationDate < new Date()) {
      return 'Expired'
    }
    return 'Sent'
  }

  renderDateSent(data: Date) {
    const sentDate = new Date(data)
    return sentDate.toLocaleDateString()
  }

  handleRowClicked(entity: PartnerUserInfo) {
    this.selectedUser = entity
  }

  @Watch('selectedTab')
  async handleSelectedTabChanged() {
    if (this.isPending) {
      this.pendingInvitations = (await this.getPendingInvites()) ?? []
    } else {
      this.gridData = await this.getUserList()
    }
    this.selectedUser = null
  }

  resendInvitation(item: ApplicationInvitation) {
    this.selectedInvitation = item
    this.isResendInvitationModalVisible = true
  }

  async deleteUser(item: any) {
    this.selectedUser = null

    if (item.id) {
      await partnersClient.deletePartnerUser(item.id)
      await this.handleSelectedTabChanged()
    }
  }

  async deleteInvitation(item: ApplicationInvitation) {
    if (item.id) {
      await inviteClient.deleteInvitation(item.id)
      await this.handleSelectedTabChanged()
    }
  }

  async saveSelectedUser() {
    if (!this.selectedUser || !this.selectedUser.partnerRoles) {
      return
    }

    await partnersClient.updatePartnerRole(this.selectedUser.id, this.selectedUser.partnerRoles[0])
    this.handleSelectedTabChanged()
  }
}
