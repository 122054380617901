








































import { defineComponent } from '@vue/composition-api'
import GridCard from '@/components/GridCard.vue'

export default defineComponent({
  name: 'SelectorCard',
  components: { GridCard },
  props: {
    isHighlighted: { type: Boolean, required: false, default: false },
    isSecondary: { type: Boolean, required: false, default: false },
    isSelected: { type: Boolean, required: false, default: false },
    selectable: { type: Boolean, required: false, default: false },
    showDismiss: { type: Boolean, required: false, default: false },
    toggle: { type: Boolean, required: false, default: false },
    dark: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    function dismiss() {
      ctx.emit('dismiss')
    }

    function select() {
      ctx.emit('select', true)
    }

    function unSelect() {
      ctx.emit('unselect', false)
    }

    return {
      dismiss,
      unSelect,
      select,
    }
  },
})
