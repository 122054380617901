






























































import { defineComponent, computed } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'

import date from '@/filters/date'

import store from '@/store'

export default defineComponent({
  name: 'Archive',
  components: {
    Loading,
  },
  filters: {
    date,
  },
  setup() {
    const clinic = computed(() => store.getters.clinics.currentItem)
    return {
      clinic,
    }
  },
})
