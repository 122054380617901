




















import { defineComponent } from '@vue/composition-api'
import FullBody from '@/components/FullBody.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'

export default defineComponent({
  name: 'Index',
  components: {
    FullBody,
    HorizontalTabs,
  },
})
