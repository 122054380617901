













import { defineComponent, computed } from '@vue/composition-api'

import CheckboxInput from '@/elements/CheckboxInput.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

export default defineComponent({
  name: 'PlayerConflictFilter',
  components: { CheckboxInput },
  setup() {
    const { setConflictFilters, getConflictFilters } = userPreferences()

    const update = (filterID: string) => {
      setConflictFilters(filterID)
    }

    const filterList = () => {
      return getConflictFilters.value
    }

    return {
      update,
      filters: computed(filterList),
    }
  },
})
