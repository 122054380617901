




























import { defineComponent, ref } from '@vue/composition-api'

import Calendar from '@/elements/Calendar.vue'
import { DisplayType } from '@/models/Calendar/enums'
import { testEvents, testMatchups } from '@/views/TestComponents/CalendarTest/data'
import { CalendarData } from '@/elements/Calendar/types'
import { DeleteIcon } from '@/elements/Icons'

export default defineComponent({
  name: 'CalendarTest',
  components: { Calendar, DeleteIcon },
  setup(props, ctx) {
    const weekdays = ref([0, 4, 5, 6])
    const firstTime = ref('16:00')
    const lastTime = ref('19:00')
    const currentDateRage = ref({ start: '2020-02-02', end: '2020-01-02' })
    let events = ref(testEvents) // event colors are passed as properties on CalendarData
    const lMatchups = ref<CalendarData[]>(testMatchups)

    function eventDropped(event: CalendarData) {
      //event dropped on calendar from external list
      alert(`Event Dropped ${event.name}`)
      events.value.push(event)
    }
    function eventClick(event: CalendarData) {
      alert(`Event Clicked ${event.name}`)
    }
    function eventMoved(event: CalendarData) {
      //event moved within the calendar
      alert(`Event Moved ${event.name}`)
    }
    function removeFromList(event: CalendarData) {
      lMatchups.value = lMatchups.value.filter((m) => m.id != event.id)
    }
    function remove(e: CalendarData) {
      removeFromList(e)
      ctx.emit('remove', e)
    }
    function currentRange(r: { start: string; end: string }) {
      currentDateRage.value = r
    }

    return {
      DisplayType,
      weekdays,
      firstTime,
      lastTime,
      events,
      lMatchups,
      remove,
      eventDropped,
      eventMoved,
      eventClick,
      currentRange,
    }
  },
})
