



















import { defineComponent, PropType, computed } from '@vue/composition-api'
import { Week } from '@/elements/Calendar/types'
import SelectInput from '@/elements/SelectInput.vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

export default defineComponent({
  name: 'CalendarNav',
  components: { SelectInput },
  props: {
    value: { type: Number, required: false, default: 0 },
    weeks: { type: Array as PropType<Week[]>, required: false, default: '' },
  },
  setup(props, ctx) {
    dayjs.extend(isBetween)
    function prev() {
      if (weekId.value > 0) {
        weekId.value = weekId.value - 1
      }
    }
    function next() {
      if (weekId.value < props.weeks.length - 1) {
        weekId.value = weekId.value + 1
      }
    }
    function today() {
      props.weeks.forEach((w) => {
        if (dayjs().isBetween(w.start, w.end)) {
          weekId.value = w.id
        }
      })
      ctx.emit('today')
    }

    const weekId = computed({
      get: () => props.value,
      set: (value) => ctx.emit('input', value),
    })

    const showToday = computed(() => {
      //only show today button if it is with the range of weeks
      const w = props.weeks
      return dayjs().isBetween(w[0].start, w[w.length - 1].end)
    })

    return {
      prev,
      next,
      today,
      weekId,
      showToday,
    }
  },
})
