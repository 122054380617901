


























import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { OrderInfo } from '@/GeneratedTypes/Order/ListInfo/OrderInfo'
import { OrderShipmentInfo } from '@/GeneratedTypes/Order/ListInfo/OrderShipmentInfo'
import TrackingNumber from '@/components/OrderHistory/TrackingNumber.vue'
import Modal from '@/components/Modal.vue'

interface OrderShipmentInfoFixed extends OrderShipmentInfo {
  shippedFromID: string | null
}

export default defineComponent({
  name: 'OrderShipment',
  props: {
    order: { type: Object as PropType<OrderInfo>, required: true },
    orderShipmentInfo: { type: Object as PropType<OrderShipmentInfoFixed>, required: true },
    maxCount: { type: Number as PropType<number>, default: 5, required: false },
  },
  components: { TrackingNumber, Modal },
  setup(props) {
    const isModalVisible = ref(false)

    const trackingMessage = computed(() =>
      props.orderShipmentInfo.shipComplete
        ? 'All tracking numbers received from this distributor'
        : props.orderShipmentInfo.trackingNumbersExist
        ? 'More tracking numbers to come from this distributor'
        : ''
    )

    const allTrackingNumbers = computed(
      () =>
        props.order.trackingNumbers?.filter(
          (t) => t.shippedFromID === props.orderShipmentInfo.shippedFromID
        ) || []
    )

    const trackingNumbersToShow = computed(() => {
      if (props.maxCount) {
        return allTrackingNumbers.value.slice(0, props.maxCount)
      }

      return allTrackingNumbers.value
    })

    const trackingNumbersRemaining = computed(
      () => allTrackingNumbers.value.length - trackingNumbersToShow.value.length
    )

    const viewAll = () => {
      isModalVisible.value = true
    }

    return {
      trackingNumbersToShow,
      trackingMessage,
      trackingNumbersRemaining,
      viewAll,
      allTrackingNumbers,
      isModalVisible,
    }
  },
})
