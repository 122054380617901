import { IndividualEmail } from '@/GeneratedTypes/IndividualEmail'
import { IndividualEmailInfo } from '@/GeneratedTypes/ListInfo/IndividualEmailInfo'

export function individualEmailInfoToIndividualEmail(x: IndividualEmailInfo): IndividualEmail {
  return {
    typeEmailID: x.typeEmailID ?? '',
    emailAddress: x.emailAddress ?? '',
    valid: x.valid ?? '',
    validityReason: x.validityReason ?? '',
    priority: x.priority,
    emailID: x.emailID,
  }
}
