







































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import UpwardValidationObserver from '@/components/UpwardValidationObserver.vue'
import MemoInput from '@/elements/MemoInput.vue'
import { RegistrationCapacityWaitList } from '@/GeneratedTypes/RegistrationCapacityWaitList'
import { getEmptyRegistrationCapacityWaitList } from '@/lib/support/models/RegistrationCapacityWaitList/data'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
//import { LeagueAccountInfo } from '@/GeneratedTypes/ListInfo/LeagueAccountInfo'
import { LeagueAccount } from '@/GeneratedTypes/LeagueAccount'
import PhoneInputMask from '@/elements/PhoneInputMask.vue'
import { registrationClient } from '@/clients/registrationClient'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'
import { isUpwardExceptionResult, RestServiceResult } from '@/services/restService'
//import programsClient from '@/clients/programsClient'
//import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { Genders } from '@/lib/support/models/MiscMappings/Gender'
import { getGradesListFromAccount } from '@/services/gradeService'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import store from '@/store'

export default defineComponent({
  name: 'WaitlistFormModal',
  components: {
    SelectInput,
    TextInput,
    UpwardValidationObserver,
    MemoInput,
    PhoneInputMask,
    Loading,
    Modal,
  },
  props: {
    value: { type: Boolean, required: true },
    upwardLeagueID: { type: String, required: true },
    typeProgramID: { type: String, required: true },
  },
  setup(props, ctx) {
    const isFormValid = ref(false)
    const isLoading = ref(false)
    const isVisible = ref(props.value)
    const showError = ref(false)
    const showDuplicateError = ref(false)
    const showSuccess = ref(false)
    //const leagueInfo = ref<LeagueListItem | null>(null)

    const contactPhone = ref<IndividualPhone>({
      phoneID: 0,
      typePhoneID: '',
      phoneNumber: '',
      extension: '',
      priority: 0,
      allowSMS: false,
    })

    const formData = ref<RegistrationCapacityWaitList>(getEmptyRegistrationCapacityWaitList())

    const observerRef = computed((): any => ctx.refs.observer)
    const myLeague = computed(() => store.getters.leagueAbstraction.currentItem)
    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)

    function reset() {
      isLoading.value = false
      showError.value = false
      showDuplicateError.value = false
      showSuccess.value = false

      if (observerRef.value) {
        observerRef.value.reset()
      }
    }

    /**
     * Program can exclude a gender
     */
    const genderList = computed(() => {
      const program = props.typeProgramID
      const genders: any = {}
      if (program && accounts.value?.length) {
        const grades = accounts.value[0].grades
        if (!grades) return
        if (grades.some((g) => g.typeProgramID === program && g.gender === Genders.male)) {
          genders['M'] = 'Male'
        }
        if (grades.some((g) => g.typeProgramID === program && g.gender === Genders.female)) {
          genders['F'] = 'Female'
        }
      }
      return genders
    })

    const accounts = computed((): LeagueAccount[] | null => {
      if (myLeague.value) {
        return myLeague.value.accounts
      } else {
        return null
      }
    })

    /**
     * Grades vary on gender and program
     */
    const gradesList = computed(() => {
      let retval: UpwardGradeTypeID[] = []
      const gender = formData.value.gender
      const program = props.typeProgramID
      if (gender && program && accounts.value?.length) {
        retval = getGradesListFromAccount(accounts.value[0], program, gender)
      }
      return retval
      /*
      const gender = formData.value.gender
      const program = props.typeProgramID

      function sortgrades(a: string, b: string) {
        // K for kindergarten grades.
        if (a[0] == 'K' && b[0] == 'K') {
          return a.localeCompare(b)
        }
        if (a[0] == 'K') {
          return -1
        }
        if (b[0] == 'K') {
          return 1
        }
        return parseInt(a) == parseInt(b) ? 0 : parseInt(a) < parseInt(b) ? -1 : 1
      }

      if (gender && program && accounts.value?.length) {
        return accounts.value[0]
          .grades!.filter((x) => x.gender == gender && x.typeProgramID == program)
          .map((x) => x.typeGradeID!)
          .sort(sortgrades)
      }
      return []
      */
    })

    function onValidEvent(isValid: boolean) {
      isFormValid.value = isValid
    }

    const registrationCapacityWaitList = computed(() => {
      if (!myLeague.value) {
        return null
      }

      formData.value.id = 0
      formData.value.leagueID = myLeague.value.id
      formData.value.typeProgramID = props.typeProgramID
      formData.value.phone = contactPhone.value.phoneNumber

      return formData.value
    })

    async function validate() {
      return await observerRef.value.validate()
    }

    function close() {
      ctx.emit('wasSubmittedSuccessfully')
      isVisible.value = false
    }

    async function onSubmit() {
      if (!registrationCapacityWaitList.value) {
        return
      }

      showSuccess.value = false
      showError.value = false
      isLoading.value = true
      showDuplicateError.value = false

      const isValid = await validate()

      if (!isValid) {
        isLoading.value = false
        return
      }

      try {
        await registrationClient.addToWaitlist(registrationCapacityWaitList.value)
        showSuccess.value = true
      } catch (e) {
        if (isDuplicateError(e)) {
          showDuplicateError.value = true
        } else {
          showError.value = true
        }
      } finally {
        isLoading.value = false
      }
    }

    function isDuplicateError(e: RestServiceResult<null>) {
      if (!isUpwardExceptionResult(e?.errorObject)) {
        return false
      }

      const message = `${e?.errorObject?.message}`

      return (
        message.indexOf(
          `DataPortal.Update failed (Cannot insert duplicate key row in object 'dbo.RegistrationCapacityWaitList' with unique index 'IX5_RegistrationCapacityWaitList'.`
        ) === 0
      )
    }

    watch(
      () => props.value,
      () => {
        isVisible.value = props.value
      }
    )

    watch(
      () => isVisible.value,
      () => {
        ctx.emit('input', isVisible.value)
        ctx.emit('change', isVisible.value)

        if (!isVisible.value) {
          reset()
        }
      }
    )

    /*
    watch(
      () => props.upwardLeagueID,
      async () => {
        leagueInfo.value = await programsClient.retrieve(props.upwardLeagueID)
      },
      { immediate: true }
    )
    */

    return {
      isVisible,
      isLoading,
      showSuccess,
      onValidEvent,
      formData,
      isByAge,
      genderList,
      gradesList,
      contactPhone,
      showDuplicateError,
      showError,
      close,
      isFormValid,
      onSubmit,
    }
  },
})
