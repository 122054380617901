




















import { defineComponent } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'ConfirmationModal',
  components: {
    Modal,
  },
  props: {
    title: { type: String, required: true },
    show: { type: Boolean, required: false, default: false },
    isSmall: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    function onYes() {
      ctx.emit('onResponse', true)
    }

    function onNo() {
      ctx.emit('onResponse', false)
    }

    return {
      onYes,
      onNo,
    }
  },
})
