























import { defineComponent, ref, computed, watch, onMounted, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import Loading from '@/elements/Loading.vue'
import divisionsClient from '@/clients/divisionsClient'
import { UpwardPracticePlanTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPracticePlanTypeID.ts'
import store from '@/store'

export default defineComponent({
  name: 'PracticePlanSelect',
  components: {
    SelectInput,
    Loading,
  },
  props: {
    value: { type: (null as unknown) as PropType<string | null>, required: true },
    label: { type: String, required: false, default: '' },
    required: { type: Boolean, required: false, default: false },
    blankItemText: { type: String, default: '', required: false },
    programTypeId: { type: String, required: true },
    showBlankItem: { type: Boolean, default: false, required: false },
    blankItemValue: { type: String, required: false, default: '' },
    VeeValidateRules: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const internalValue = ref<string | null>(props.value ?? null)
    const isLoading = ref(false)
    const practicePlanList = ref<UpwardPracticePlanTypeID[]>([])
    const isByAge = computed(() => store.getters.leagueAbstraction.isByAge)
    async function fetchAll() {
      if (isLoading.value) {
        return false
      }
      const practicePlans = await divisionsClient.retrievePracticePlans(props.programTypeId)

      if (practicePlans) {
        practicePlanList.value = practicePlans
      }

      isLoading.value = false
    }

    onMounted(async () => {
      await fetchAll()
    })

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      async () => {
        internalValue.value = props.value
        await fetchAll()
      }
    )

    watch(
      () => props.programTypeId,
      async () => {
        await fetchAll()
      }
    )

    return {
      isLoading,
      isByAge,
      practicePlanList,
      internalValue,
      handleChange,
    }
  },
})
