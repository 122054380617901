












































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { AuthUserInfo } from '@/GeneratedTypes/Authorize/ListInfo/AuthUserInfo'
import RadioGroupInput from '../../../../elements/RadioGroupInput.vue'
import TextInput from '../../../../elements/TextInput.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import Modal from '@/components/Modal.vue'
import authorizationClient from '@/clients/authorizationClient'
import { ValdiatorInterface } from '@/models/Validation/ValidatorInterface'

enum ResetTypeEnum {
  MANUAL,
  MAIL,
  SMS,
}

export default defineComponent({
  components: { VeeValidateForm, TextInput, RadioGroupInput, Modal },
  props: {
    visible: { type: Boolean, required: true },
    user: { type: Object as PropType<AuthUserInfo>, required: true },
    canResetPassword: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    const internalVisibleRef = ref(false)
    const howRef = ref(ResetTypeEnum.MANUAL)
    const inputRef = ref('')
    const validate = ref<null | ValdiatorInterface>(null)

    const resetSelection = [
      {
        id: ResetTypeEnum.MANUAL,
        description: 'Manual Update',
        label: 'New Password',
        validation: 'required',
        enabled: true,
      },
      {
        id: ResetTypeEnum.MAIL,
        description: 'Reset Via Email',
        label: 'Email Address',
        validation: '',
        enabled: false,
      },
      {
        id: ResetTypeEnum.SMS,
        description: 'Reset Via SMS',
        label: 'Phone',
        validation: 'phone|required',
        enabled: true,
      },
    ]
    const currentSelectionRef = computed(() => resetSelection.find((x) => x.id == howRef.value))
    const resetInitialState = () =>
      (inputRef.value = howRef.value == ResetTypeEnum.MAIL ? props.user.email ?? '' : '')
    watch(
      () => howRef.value,
      () => resetInitialState(),
      { immediate: true }
    )
    watch(
      () => props.visible,
      () => {
        if (props.visible != internalVisibleRef.value) {
          internalVisibleRef.value = props.visible
          resetInitialState()
        }
      },
      { immediate: true }
    )

    /**
     * When the internal visible flag (set by modal's v-model) send close.
     */
    watch(
      () => internalVisibleRef.value,
      () => {
        if (!internalVisibleRef.value) {
          inputRef.value = ''
          ctx.emit('closed')
        }
      }
    )

    //bug in initial state of this form prevents validation
    async function triggerValidate() {
      await validate.value?.checkValidity()
    }

    async function reset() {
      //figure out the type of reset first
      try {
        switch (howRef.value) {
          case ResetTypeEnum.MANUAL:
            await authorizationClient.adminResetPassword(String(props.user.id), inputRef.value)
            break
          case ResetTypeEnum.MAIL:
            await authorizationClient.adminResetPasswordEmail(inputRef.value)
            break
          case ResetTypeEnum.SMS:
            await authorizationClient.adminResetPasswordSMS(String(props.user.id), inputRef.value)
            break
        }
      } catch (e) {
        throw e
      }
      close()
      ctx.emit('reset')
    }

    function close() {
      internalVisibleRef.value = false
    }

    return {
      internalVisibleRef,
      triggerValidate,
      validate,
      resetSelection,
      inputRef,
      currentSelectionRef,
      howRef,
      close,
      reset,
    }
  },
})
