import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { sortGrades } from '@/services/gradeService'

const sortByGrades = (a: LeaguePlayerInfo, b: LeaguePlayerInfo, isDesc: boolean): number => {
  if (!a.typeGradeID || !b.typeGradeID) return 0
  const r = sortGrades(a.typeGradeID, b.typeGradeID)
  return isDesc ? r * -1 : r
}

const sortName = (a: LeaguePlayerInfo, b: LeaguePlayerInfo, isDesc: boolean): number => {
  const r = a?.formattedNameLastFirst?.localeCompare(b?.formattedNameLastFirst ?? '') ?? 0
  return isDesc ? r * -1 : r
}

const sortScore = (a: LeaguePlayerInfo, b: LeaguePlayerInfo, isDesc: boolean): number => {
  const r = a.evaluationScore - b.evaluationScore
  return isDesc ? r * -1 : r
}

const sortHeight = (a: LeaguePlayerInfo, b: LeaguePlayerInfo, isDesc: boolean): number => {
  const r = a.extraScore - b.extraScore
  return isDesc ? r * -1 : r
}

const sortGender = (a: LeaguePlayerInfo, b: LeaguePlayerInfo, isDesc: boolean): number => {
  const r = a?.gender?.localeCompare(b?.gender ?? '') ?? 0
  return isDesc ? r * -1 : r
}

const sortExperience = (a: LeaguePlayerInfo, b: LeaguePlayerInfo, isDesc: boolean): number => {
  const r = a.yearsExperience - b.yearsExperience
  return isDesc ? r * -1 : r
}

export const sortFunctions: { [key: string]: any } = {
  name_asc: sortName,
  name_desc: sortName,
  score_asc: sortScore,
  score_desc: sortScore,
  exp_asc: sortExperience,
  exp_desc: sortExperience,
  grade_asc: sortByGrades,
  grade_desc: sortByGrades,
  gender_asc: sortGender,
  gender_desc: sortGender,
  height_asc: sortHeight,
  height_desc: sortHeight,
}
