
















import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import Modal from '@/components/Modal.vue'
import PageContent from '@/components/PageContent.vue'
import { PageContentDocument } from '@/models/PageContent/PageContentDocument'
import { UpwardOpportunityTypesByUpwardTypeID } from '@/lib/common/upwardOpportunityTypes'
import store from '@/store'

export default defineComponent({
  name: 'ReadFirstModal',
  components: {
    Modal,
    PageContent,
  },
  props: {
    value: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    const internalVal = ref(false)

    const isReadMeFirstLoading = ref(false)
    const readMeFirstTitle = ref('')

    const documentID = computed(() => {
      return store.getters.creationParameters.opportunityType ==
        UpwardOpportunityTypesByUpwardTypeID.UPWARDSELECT
        ? 'waitReadMeFirstSelect'
        : 'waitReadMeFirst'
    })

    watch(
      () => props.value,
      () => {
        internalVal.value = props.value
      }
    )

    watch(
      () => internalVal.value,
      (val: boolean) => {
        ctx.emit('input', val)
      }
    )

    function onIsLoadingChange(isLoading: boolean) {
      isReadMeFirstLoading.value = isLoading
    }

    function onDocumentChange(document: PageContentDocument) {
      readMeFirstTitle.value = document.title
    }

    function onTotalAccessClickedConfirmed() {
      ctx.emit('input', false)
      ctx.emit('ReadMeFirstConfirmed', true)
    }

    return {
      internalVal,
      readMeFirstTitle,
      documentID,
      isReadMeFirstLoading,
      onIsLoadingChange,
      onDocumentChange,
      onTotalAccessClickedConfirmed,
    }
  },
})
