import { LeagueDivisionStandingInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionStandingInfo'

export const divisionStandings = (list: LeagueDivisionStandingInfo[]) => {
  // Sort the list by teamWins (descending) and teamName (ascending)
  list.sort((a, b) => {
    if (a.teamWins !== b.teamWins) {
      return b.teamWins - a.teamWins // Descending order
    }
    return (a.teamName || '').localeCompare(b.teamName || '') // Ascending order
  })

  // Directly map the sorted list to the desired format
  const standings: divisionStanding[] = list.map((item) => ({
    teamID: item.teamID,
    teamName: item.teamName,
    teamWins: item.teamWins,
    teamLosses: item.teamLosses,
    teamTies: item.teamTies,
    upwardTeamID: item.upwardTeamID,
  }))

  return standings
}

export interface divisionStanding {
  teamID: number
  teamName: string | null
  upwardTeamID: string | null
  teamWins: number
  teamLosses: number
  teamTies: number
}
