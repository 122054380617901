/**
 * Store module used to track state shared between the many components that
 * make up the stepper used to select products. This is for managing the display
 * state of the components. Data received/sent to API is in productOfferingConfig.
 */

import { defineGetters, defineMutations } from 'direct-vuex'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { ProdConfigTypes } from '@/lib/common/ProdConfigTypes'

interface ProductFlowState {
  viewedSteps: string[]
  prodConfigType: ProdConfigTypes | null // League or Estimate
  isNewProdConfig: boolean | null
  steps: string[]
  productOfferingLoading: boolean
  currentProgramType: UpwardProgramTypeID | null

  //ProductSetup
  currentProductSetupStep: number
  maxTouchedProductSetupStep: number

  //Kit1
  currentParticipantKit1Step: number
  maxTouchedParticipantKit1Step: number

  //Kit2
  currentParticipantKit2Step: number
  maxTouchedParticipantKit2Step: number
}

const initState = (): ProductFlowState => {
  return {
    viewedSteps: [],
    prodConfigType: null,
    isNewProdConfig: null,
    steps: [],
    productOfferingLoading: true,
    currentProgramType: null,

    //ProductSetup
    currentParticipantKit1Step: 1,
    maxTouchedParticipantKit1Step: 1,

    //Kit1
    currentParticipantKit2Step: 1,
    maxTouchedParticipantKit2Step: 1,

    //Kit2
    currentProductSetupStep: 1,
    maxTouchedProductSetupStep: 1,
  }
}

const productFlowState = initState()

const getterTree = defineGetters<ProductFlowState>()({
  currentProductSetupStep: (state) => {
    return state.currentProductSetupStep
  },
  maxTouchedProductSetupStep: (state) => {
    return state.maxTouchedProductSetupStep
  },

  currentParticipantKit1Step: (state) => {
    return state.currentParticipantKit1Step
  },
  maxTouchedParticipantKit1Step: (state) => {
    return state.maxTouchedParticipantKit1Step
  },

  currentParticipantKit2Step: (state) => {
    return state.currentParticipantKit2Step
  },
  maxTouchedParticipantKit2Step: (state) => {
    return state.maxTouchedParticipantKit2Step
  },

  viewedSteps: (state) => {
    return state.viewedSteps
  },
  prodConfigType: (state) => {
    return state.prodConfigType
  },
  isNewProdConfig: (state) => {
    return state.isNewProdConfig
  },
  steps: (state) => {
    return state.steps
  },
  productOfferingLoading: (state) => {
    return state.productOfferingLoading
  },
  currentProgramType: (state) => {
    return state.currentProgramType
  },
  currentProgramTypeId: (state) => {
    return state.currentProgramType ? state.currentProgramType.id : null
  },
  currentProgramUpwardTypeId: (state) => {
    return state.currentProgramType ? state.currentProgramType.upwardTypeID : null
  },
})

const mutations = defineMutations<ProductFlowState>()({
  setCurrentProductSetupStep(state, { step }: { step: number }) {
    state.currentProductSetupStep = step
  },
  setMaxTouchedProductSetupStep(state, { step }: { step: number }) {
    state.maxTouchedProductSetupStep = step
  },

  setCurrentParticipantKit1Step(state, { step }: { step: number }) {
    state.currentParticipantKit1Step = step
  },
  setMaxTouchedParticipantKit1Step(state, { step }: { step: number }) {
    state.maxTouchedParticipantKit1Step = step
  },

  setCurrentParticipantKit2Step(state, { step }: { step: number }) {
    state.currentParticipantKit2Step = step
  },
  setMaxTouchedParticipantKit2Step(state, { step }: { step: number }) {
    state.maxTouchedParticipantKit2Step = step
  },
  setProdConfigType(state, { type }: { type: ProdConfigTypes }) {
    state.prodConfigType = type
  },
  setIsNewProdConfig(state, { isNew }: { isNew: boolean }) {
    state.isNewProdConfig = isNew
  },
  setStepList(state, { stepList }: { stepList: string[] }) {
    state.steps = stepList
  },
  addViewedStep(state, { step }: { step: string }) {
    state.viewedSteps.push(step)
    state.viewedSteps = [...new Set(state.viewedSteps)]
  },
  setProductOfferingLoading(state, { loading }: { loading: boolean }) {
    state.productOfferingLoading = loading
  },
  setCurrentTypeProgram(state, { program }: { program: UpwardProgramTypeID }) {
    state.currentProgramType = program
  },
  clear(state) {
    Object.assign(state, initState())
  },
})

export const namespace = 'productFlow'

export const productFlow = {
  namespaced: true as true,
  state: productFlowState,
  getters: getterTree,
  mutations,
}
