








































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import SearchInput from '@/elements/SearchInput.vue'
import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'
import SelectInput from '@/elements/SelectInput.vue'
import reportsClient from '@/clients/reportsClient'
import dayjs from 'dayjs'
import { AgGridVue } from '@ag-grid-community/vue'
import DateInput from '@/elements/DateInput.vue'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { PageSettings } from '@/models/Grid/PageSettings'

export default defineComponent({
  name: 'MinistryImpactReport',
  components: {
    Loading,
    TextInput,
    SelectInput,
    SearchInput,
    AgGridVue,
    DateInput,
  },
  setup(props, ctx) {
    const maxDate = computed(() => dayjs().add(1, 'year').format('MM/DD/YYYY'))
    const defaultStartDate = computed(() => dayjs().subtract(90, 'day').format('MM/DD/YYYY'))
    const selectedBeginDate = ref(defaultStartDate.value)
    const selectedEndDate = ref(dayjs().subtract(1, 'day').format('MM/DD/YYYY'))
    const pageSettings: PageSettings = { pageSize: 999999 } as PageSettings

    const accountNumber = ctx.root.$route.params.acct ?? ''

    const dateLimits = ref({
      min: '04/01/2020',
      max: maxDate.value,
    })

    const validType = ref<{ text: string; value: string }[]>([])
    const modules = ref(AllCommunityModules)
    const gridOptions: any = {
      suppressColumnVirtualisation: true,
    }
    let api: any = null
    let columnApi: any = null
    let rowData: any = ref(null)

    onMounted(() => {
      validType.value.push({ text: 'First Practice Date', value: 'FPD' })
      validType.value.push({ text: 'First Game Date', value: 'FGD' })
      validType.value.push({ text: 'Season End Date', value: 'SED' })
    })

    const compareType = ref('FPD')

    const areWeReady = computed(() => selectedBeginDate.value || selectedEndDate.value || compareType.value)

    function onReady(params: any) {
      api = params.api
      columnApi = params.columnApi
      let columns = []
      columns.push({
        field: 'ImpactKey',
        headerName: 'Impact Key',
      })
      columns.push({
        field: 'ImpactValue',
        headerName: 'Impact Value',
      })

      api.setColumnDefs(columns)
    }
    function onFirstDataRendered() {
      const cols = ['ImpactKey', 'ImpactValue']
      columnApi.autoSizeColumns(cols, false)
    }

    async function runReportClicked() {
      loading.value = true

      try {
        const ministryImpactData =
          (await reportsClient.getMinistryImpactData(
            accountNumber,
            compareType.value,
            selectedBeginDate.value,
            selectedEndDate.value
          )) ?? []

        rowData.value = ministryImpactData
      } catch (err) {
        rowData.value = []
      }

      loading.value = false
    }
    const errorMessage = ref('')

    const loading = ref(false)

    function downloadCsv() {
      const parms = { processCellCallback: csvProcessCellCallback }
      api.exportDataAsCsv(parms)
    }
    function csvProcessCellCallback(params: any) {
      return params.value
    }

    return {
      areWeReady,
      loading,
      runReportClicked,
      validType,
      errorMessage,
      compareType,
      selectedBeginDate,
      selectedEndDate,
      dateLimits,
      gridOptions,
      modules,
      rowData,
      onReady,
      onFirstDataRendered,
      downloadCsv,
      pageSettings,
      accountNumber,
    }
  },
})
