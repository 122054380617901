import { Node } from 'tiptap'
import { toggleList, wrappingInputRule } from 'tiptap-commands'
export default class LetterList extends Node {
  get name() {
    return 'letter_list'
  }

  get schema() {
    return {
      attrs: {
        order: {
          default: 1,
        },
      },
      content: 'list_item+',
      group: 'block',
      parseDOM: [
        {
          tag: 'ol[type]',
          getAttrs: (dom: any) => ({
            order: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1,
            type: dom.hasAttribute('type') ? dom.getAttribute('type') : 'a',
          }),
        },
      ],
      toDOM: (node: any) =>
        node.attrs.order === 1 ? ['ol', { type: 'a' }, 0] : ['ol', { start: node.attrs.order, type: 'a' }, 0],
    }
  }

  commands({ type, schema }: { type: any; schema: any }) {
    return () => toggleList(type, schema.nodes.list_item)
  }

  keys({ type, schema }: { type: any; schema: any }) {
    return {
      'Shift-Ctrl-0': toggleList(type, schema.nodes.list_item),
    }
  }

  inputRules({ type }: { type: any }) {
    return [
      wrappingInputRule(
        /^(\d+)\.\s$/,
        type,
        (match: (string | number)[]) => ({ order: +match[1], type: 'a' }),
        (match: any, node: any) => node.childCount + node.attrs.order === +match[1]
      ),
    ]
  }
}
