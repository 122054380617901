

























































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import SearchInput from '@/elements/SearchInput.vue'
import { cloneDeep } from 'lodash'
import userClient from '@/clients/userClient'
import { AuthUserInfo } from '@/GeneratedTypes/Authorize/ListInfo/AuthUserInfo'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import Loading from '@/elements/Loading.vue'
import { ImpersonationPayload } from '@/clients/authorizationClient'
import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import PasswordResetModal from '@/views/AdminDash/vues/UserManagement/PasswordResetModal.vue'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import dayjs from 'dayjs'

class UserManagementException extends GeneralError {
  name = 'User Management Exception'
}

export default defineComponent({
  components: { Alert, PasswordResetModal, ConfirmationModal, Loading, SearchInput },
  props: {
    isCurrentUserIsSuperAdmin: { type: Boolean, required: true },
  },
  setup(p, ctx) {
    const searchTerm = ref('')
    // set to an error if there is one.
    const error = ref('')
    //clear an error in 20 seconds automatically
    watch(
      () => error.value,
      (a) => {
        if (a) {
          setTimeout(() => (error.value = ''), 20000)
        }
      }
    )

    const results = ref<AuthUserInfo[]>([])
    const loading = ref(false)

    /**
     * refresh search by search term.
     */
    async function search() {
      loading.value = true
      error.value = ''
      results.value = []
      try {
        results.value = (await userClient.searchUsers(searchTerm.value)) ?? []
        if (!results.value?.length) {
          error.value = 'No results returned.'
        }
      } catch (e) {
        const message = e?.innerException?.errorObject?.message

        error.value = 'Search failure ' + (message || e?.message || e?.errorObject?.message || '')

        throw new UserManagementException('Search failed ' + message, e)
      } finally {
        loading.value = false
      }
    }
    // currently searchTerm is updated on search button click
    watch(
      () => searchTerm.value,
      async () => {
        if (searchTerm.value.length >= 3) {
          await search()
        }
      }
    )

    const confirmDisable = ref(false)
    const userToDisable = ref<AuthUserInfo | null>(null)
    async function disableConfirmed(t: boolean) {
      if (t && userToDisable.value) {
        loading.value = true
        try {
          await userClient.disableUser(userToDisable.value?.id)
        } catch (e) {
          error.value = e.message || ''
          throw e
        } finally {
          loading.value = false
        }
      }
      await search()
      confirmDisable.value = false
    }

    const isResetDialogShowing = ref(false)
    const canCurrentUserResetPassword = computed(
      () => (userToReset.value && !userToReset.value.isSuperAdmin) || p.isCurrentUserIsSuperAdmin
    )
    const userToReset = ref<AuthUserInfo | null>(null)
    function resetUser(user: AuthUserInfo) {
      userToReset.value = cloneDeep(user)
      isResetDialogShowing.value = true
    }

    function disableUser(user: AuthUserInfo) {
      confirmDisable.value = true
      userToDisable.value = cloneDeep(user)
    }
    function impersonateUser(user: AuthUserInfo) {
      let an = user.roles?.length && user.roles[0].accountNumber
      if (an) {
        ctx.emit('impersonate', { accountNumber: an, userName: user.username } as ImpersonationPayload)
      } else {
        error.value = 'Cannot impersonate a user without roles'
        throw new UserManagementException('Cannot impersonate a user without roles')
      }
    }

    const is2FADialogShowing = ref(false)
    const userTo2FA = ref<AuthUserInfo | null>(null)
    function change2FA(user: AuthUserInfo) {
      is2FADialogShowing.value = true
      userTo2FA.value = cloneDeep(user)
    }

    async function change2FAConfirmed(c: boolean) {
      if (c && userTo2FA.value) {
        loading.value = true
        try {
          await userClient.set2FAStatus(
            userTo2FA.value?.email ?? '',
            !userTo2FA.value?.twoFactorAuthEnabled ?? false
          )
        } catch (e) {
          error.value = e.message || ''
          throw e
        } finally {
          loading.value = false
        }
      }
      await search()
      is2FADialogShowing.value = false
    }

    const isPasswordResetShowing = ref(false)
    function passwordWasReset() {
      isPasswordResetShowing.value = true
    }

    function formatDate(d: Date | null) {
      if (d) {
        return dayjs(d).format('MMM DD YYYY' + ' / ' + 'hh:mm A')
      } else {
        return ''
      }
    }

    return {
      searchTerm,
      results,
      disableUser,
      impersonateUser,
      confirmDisable,
      userToDisable,
      resetUser,
      loading,
      error,
      disableConfirmed,
      isResetDialogShowing,
      userToReset,
      canCurrentUserResetPassword,
      passwordWasReset,
      isPasswordResetShowing,
      notice: AlertTypeEnum.NOTICE,
      formatDate,
      is2FADialogShowing,
      change2FA,
      change2FAConfirmed,
      userTo2FA,
    }
  },
})
