
















import { defineComponent, PropType } from '@vue/composition-api'
import { xShipToBase } from '@/GeneratedTypes/xOrder/xShipToBase.ts'
import { getEmptyxShipToBase } from '@/lib/support/models/GeneratedTypes/xOrders/xShipToBase.ts'

export default defineComponent({
  name: 'ShippingAddressDisplay',
  props: {
    value: { type: Object as PropType<xShipToBase>, default: getEmptyxShipToBase, required: false },
  },
})
