import { SavedProductOfferingConfigDetail } from '@/GeneratedTypes/SavedProductOfferingConfigDetail'
import { ProductOfferingProductInfo } from '@/GeneratedTypes/ListInfo/ProductOfferingProductInfo'
import { cloneDeep } from 'lodash'
import { SavedProductOfferingConfigGenderGrade } from 'src/GeneratedTypes/SavedProductOfferingConfigGenderGrade'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { UpwardGradeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardGradeTypeID'
import store from '@/store'

export function getSelectedIndexes(
  details: SavedProductOfferingConfigDetail[] | null,
  allProducts: ProductOfferingProductInfo[],
  categoryId: number
) {
  if (!details) return

  const mySelectedProducts = getProductsByCategory(details, categoryId)

  const indexes = allProducts.reduce((a: any, p: ProductOfferingProductInfo, i: number) => {
    const hasId = mySelectedProducts.some(
      (x: SavedProductOfferingConfigDetail) => x.productID === p.productID
    )

    if (hasId) a.push(i)
    return a
  }, [])

  return indexes
}

export function getProductOptionID(
  details: SavedProductOfferingConfigDetail[] | null,
  productID: number,
  categoryId: number
) {
  if (!details) return

  const mySelectedProducts = getProductsByCategory(details, categoryId)
  const product = mySelectedProducts.find((p: SavedProductOfferingConfigDetail) => p.productID === productID)

  return product ? product.typeProductOptionID : null
}

export function getProductPrice(
  details: SavedProductOfferingConfigDetail[] | null,
  productID: number,
  categoryId: number
) {
  if (!details) return

  const mySelectedProducts = getProductsByCategory(details, categoryId)
  const product = mySelectedProducts.find((p: SavedProductOfferingConfigDetail) => p.productID === productID)

  return product ? product.costToCharge : null
}

export function getProductByID(
  details: SavedProductOfferingConfigDetail[] | null,
  productID: number,
  categoryId: number
) {
  if (!details) return
  const mySelectedProducts = getProductsByCategory(details, categoryId)
  const product = mySelectedProducts.find((p: SavedProductOfferingConfigDetail) => p.productID === productID)

  return product ? product : null
}

export function getProductsByCategory(details: SavedProductOfferingConfigDetail[], categoryId: number) {
  const allDetails = cloneDeep(details)
  return allDetails.filter((p: SavedProductOfferingConfigDetail) => p.categoryID === categoryId)
}

export function buildGradeArray(
  gradeGenders: SavedProductOfferingConfigGenderGrade[] | null,
  gender: 'M' | 'F',
  upwardTypeID: string | null
) {
  if (!gradeGenders || gradeGenders.length === 0) return []
  const typeProgramID = upwardTypeID
  return gradeGenders.reduce((acc: UpwardGradeTypeID[], g: SavedProductOfferingConfigGenderGrade) => {
    if (g.typeProgramID === typeProgramID && g.gender === gender)
      acc.push(store.getters.gradeTypes.byUpwardTypeId(g.typeGradeID))
    return acc
  }, [])
}

export function programByType(programs: UpwardProgramTypeID[], type: 'CHEER' | 'SPORT') {
  const progs = cloneDeep(programs)
  const flag = type === 'CHEER'
  const program = progs.find((x: UpwardProgramTypeID) => x.isCheer === flag)
  return program ? program : null
}
