import { RouteConfig } from 'vue-router'
import Info from '@/views/MyProfile/Info.vue'
import Password from '@/views/MyProfile/ManagePassword.vue'
import Index from '@/views/MyProfile/Index.vue'
import Manage2FA from '@/views/MyProfile/Manage2FA.vue'

const baseName = 'MyProfile'

export default [
  {
    path: '/myprofile',
    component: Index,
    children: [
      {
        path: '',
        redirect: '/myprofile/info',
      },
      {
        path: 'info',
        name: `${baseName}-Info`,
        component: Info,
      },
      {
        path: 'password',
        name: 'profilePassword',
        component: Password,
      },
      {
        path: '2FA',
        name: 'twoFactorAuth',
        component: Manage2FA,
      },
    ],
  },
] as RouteConfig[]
