





























import { defineComponent, ref, watch } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import FullBody from '@/components/FullBody.vue'

export default defineComponent({
  name: 'SetupCompleteConfirmation',
  components: {
    Modal,
    FullBody,
  },
  props: {
    value: { type: Boolean, required: true },
    upwardLeagueId: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const modalIsVisible = ref(props.value)

    function hideModal() {
      modalIsVisible.value = false
      onModalChange()
    }

    watch(
      () => props.value,
      () => {
        modalIsVisible.value = props.value
      }
    )

    function onModalChange() {
      ctx.emit('input', modalIsVisible.value)
      ctx.emit('change', modalIsVisible.value)
    }

    return {
      modalIsVisible,
      onModalChange,
      hideModal,
    }
  },
})
