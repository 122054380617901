import { getEmptyContact } from '@/lib/support/models/Partner/Contact/data'
import { getEmptySavedProductOfferingConfig } from '@/lib/support/models/SaveProductOfferingConfig/SavedProductOfferingConfig'
import { Camp } from '@/GeneratedTypes/Camp'

export function getEmptyCamp(): Camp {
  return {
    campStartDate: new Date(),
    campEndDate: new Date(),
    id: 0,
    typeLeagueID: '',
    typeProductGroupID: '',
    leagueName: '',
    publicDisplayName: null,
    upwardLeagueID: null,
    registrationBeginDate: new Date(),
    registrationEndDate: new Date(),
    evalDescription: null,
    lateDate: new Date(),
    leagueDirectorPartnerID: 0,
    partnerContact: getEmptyContact(),
    isPrivate: false,
    bailedReason: null,
    allowAssistantCoachLinks: false,
    isMailIn: false,
    isOPRLeague: false,
    otherRegistrationVendorName: null,
    leagueRegistrationURL: null,
    typeCountryID: 'US',
    leagueFinderMessage: '',
    programMissionObjective: '',
    typeTimezoneID: '',
    defaultTypePaymentMethodID: null,
    useInternationalDateFormat: false,
    fromEmailAddress: null,
    fromEmailDisplayName: null,
    registrationInfo: null,
    accounts: [],
    programs: null,
    playerUDFs: null,
    roles: null,
    canEdit: true,
    canEditReason: null,
    productOfferingConfig: getEmptySavedProductOfferingConfig(),
    leagueFinderURLOverride: null,
    requestRiskMitigationInfo: false,
    ageCutoffDate: null,
    gradeDescriptions: [],
  }
}
