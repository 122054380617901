var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function () { return (_vm.show = true); }}},[_c('i',{staticClass:"fa fa-calendar"}),_vm._v(" Pick date & time ")])]),_c('div',{staticClass:"col-sm-10"},[(_vm.pickedDateTime)?_c('input',{staticClass:"form-control",attrs:{"readonly":""},domProps:{"value":_vm.pickedDateTime.toLocaleTimeString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          })}}):_vm._e()])]),_c('modal-date-picker',{attrs:{"cancelLabel":"Cancel","pickLabel":"Pick","title":"Pick date & time","show":_vm.show},on:{"cancelled":function () { return (_vm.show = !_vm.show); },"picked":function (picked) {
        _vm.pickedDateTime = picked
        _vm.show = false
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }