import { LeagueListItem } from '@/models/Program/LeagueListItem'

export const programList = (programs: LeagueListItem) => {
  const list: programListType[] = []
  if (programs.programTypeID) {
    list.push({
      id: programs.programTypeID,
      description: programs.programTypeDescription,
    })
  }
  if (programs.cheerProgramTypeID) {
    list.push({
      id: programs.cheerProgramTypeID,
      description: programs.cheerProgramTypeDescription,
    })
  }

  return list
}

export interface programListType {
  id: string | null
  description: string | null
}
