






































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import SearchInput from '@/elements/SearchInput.vue'
import partnersClient, { PartnerSearchResultsType } from '@/clients/partnersClient'
import Loading from '@/elements/Loading.vue'
import { ImpersonationPayload } from '@/clients/authorizationClient'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import TextInput from '@/elements/TextInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'
import { AccountProfile } from '@/models/Partner/AccountProfile'

interface SearchResults {
  name: string
  city: string
  accountNumber: number
  eftBankAcctLast4: string
  placeOrdersOnHoldNote: string
  fanGearCustomURL: string
}

export default defineComponent({
  components: { SearchInput, Loading, ConfirmationModal, TextInput, MemoInput, Alert },
  setup(p, ctx) {
    // set to an error if there is one.
    const error = ref('')
    const searchTerm = ref('')
    const loading = ref(false)
    const results = ref<PartnerSearchResultsType[]>([])
    const searchResults = computed(() => {
      return results.value.map((x) => {
        const split_result = x.name?.match(/(.*)-(.*)?$/) ?? []
        let name = x.name
        let city = 'n/a'
        if (split_result.length == 3) {
          name = split_result[1]
          city = split_result[2]
        }
        return {
          name,
          city,
          accountNumber: x.accountNumber,
          eftBankAcctLast4: x.eftBankAcctLast4,
          placeOrdersOnHoldNote: x.placeOrdersOnHoldNote,
          fanGearCustomURL: x.fanGearCustomURL,
        } as SearchResults
      })
    })

    const isProfileConfirmShowing = ref(false)
    const eftLast4 = ref('')
    const showAlert = ref(false)
    const alertText = ref('')
    const accountNumber = ref('')

    const onHoldNote = ref('')
    const fanGearURL = ref('')

    watch(
      () => searchTerm.value,
      async () => {
        await doSearch()
      }
    )

    const doSearch = async () => {
      if (searchTerm.value.length >= 3) {
        error.value = ''
        try {
          loading.value = true
          const result = await partnersClient.searchPartners(searchTerm.value)
          results.value = result ?? []
          if (!results.value?.length) {
            error.value = 'No results returned'
          }
        } catch (e) {
          error.value =
            e?.message || e?.errorObject?.message || 'An unexpected error occurred searching for partners'
          loading.value = false
          throw e
        } finally {
          loading.value = false
        }
      }
    }

    function impersonate(acct: string) {
      ctx.emit('impersonate', { accountNumber: acct } as ImpersonationPayload)
    }

    async function ministryImpactClick(acct: string) {
      await ctx.root.$router.push(`AdminDash/Reports/MinistryImpact/${acct}`)
    }

    async function confirmProfileClick(b: boolean) {
      isProfileConfirmShowing.value = false
      if (b) {
        try {
          loading.value = true

          await setProfile(accountNumber.value, eftLast4.value, onHoldNote.value, fanGearURL.value)

          showAlert.value = true
          await doSearch()
          alertText.value = `Profile updated for ${accountNumber.value}.`
        } catch (e) {
          throw e
        } finally {
          loading.value = false
        }
      }
    }

    function profileClicked(acct: string, eft: string, note: string, url: string) {
      accountNumber.value = acct
      eftLast4.value = eft
      onHoldNote.value = note
      fanGearURL.value = url
      isProfileConfirmShowing.value = true
    }

    async function setProfile(acct: string, eft: string, note: string, url: string) {
      await partnersClient.updateAccountProfile(acct, {
        accountNumber: acct,
        eftLast4: eft,
        onHoldNote: note,
        fanGearCustomURL: url,
      } as AccountProfile)
    }

    /*
    async function confirmEFTClick(b: boolean) {
      isEFTConfirmShowing.value = false
      if (b) {
        try {
          loading.value = true

          await setEFTLast4(accountNumber.value, eftLast4.value)

          showAlert.value = true
          await doSearch()
          alertText.value = `EFT Last 4 updated for ${accountNumber.value}.`
        } catch (e) {
          throw e
        } finally {
          loading.value = false
        }
      }
    }

    function eftClicked(acct: string, eft: string) {
      accountNumber.value = acct
      eftLast4.value = eft
      isEFTConfirmShowing.value = true
    }

    async function setEFTLast4(acct: string, eft: string) {
      await partnersClient.updateAccountEFTLast4(acct, eft)
    }

    async function confirmOnHoldClick(b: boolean) {
      isOnHoldConfirmShowing.value = false
      if (b) {
        try {
          loading.value = true

          await setOnHoldNote(accountNumber.value, onHoldNote.value)

          showAlert.value = true
          await doSearch()
          alertText.value = `Order On Hold Note updated for ${accountNumber.value}.`
        } catch (e) {
          throw e
        } finally {
          loading.value = false
        }
      }
    }

    function onHoldClicked(acct: string, note: string) {
      accountNumber.value = acct
      onHoldNote.value = note
      isOnHoldConfirmShowing.value = true
    }

    async function setOnHoldNote(acct: string, note: string) {
      await partnersClient.updateAccountPlaceOrdersOnHoldNote(acct, note)
    }
*/

    return {
      searchTerm,
      loading,
      searchResults,
      impersonate,
      error,
      profileClicked,
      accountNumber,
      eftLast4,
      isProfileConfirmShowing,
      confirmProfileClick,
      ministryImpactClick,
      onHoldNote,
      fanGearURL,
      showAlert,
      alertText,
      alertType: AlertTypeEnum.NOTICE,
    }
  },
})
