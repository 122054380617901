import { computed, ref, watch, onMounted, nextTick, SetupContext } from '@vue/composition-api'

export const getProps = () => ({
  value: { required: false, type: Boolean, default: false },
})

export function useModalValue(props: any, ctx: SetupContext) {
  const internalValue = ref<boolean>(props.value)

  //@Watch('value')
  function changeVisible() {
    internalValue.value = props.value
  }

  //@Watch('internalValue')
  function changeShowModal(b: boolean) {
    ctx.emit('input', b)
  }

  const isModalOpen = computed({
    get() {
      return internalValue.value
    },
    set(b: boolean) {
      internalValue.value = b
    },
  })

  /***
   * Set the internal value to false to close the dialog.
   */
  function closeModal() {
    internalValue.value = false
  }

  onMounted(() => {
    changeVisible()
    nextTick(() => {
      watch(() => internalValue.value, changeShowModal)
      watch(() => props.value, changeVisible)
    })
  })

  return {
    isModalOpen,
    closeModal,
  }
}
