import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'

export function TeamPlayerInfoToLeaguePlayerInfo(a: TeamPlayerInfo): LeaguePlayerInfo {
  return <LeaguePlayerInfo>{
    leagueID: a.leagueID,
    accountNumber: null,
    typeGradeID: a.typeGradeID,
    medicalNotes: null,
    generalNotes: null,
    registrationTransactionID: 0,
    isImported: false,
    typeProgramID: a.typeProgramID,
    yearsExperience: 0,
    adminNotes: null,
    pendingCoachLinkIndividualID: 0,
    pendingCoachLinkFirstName: null,
    pendingCoachLinkLastName: null,
    active: true,
    registrationFeeExpectedToPay: 0,
    amountExpectedToPay: 0,
    amountPaid: 0,
    isPlayerSizingComplete: false,
    registrationCompletionInformation: null,
    evaluationScore: a.evaluationScore,
    positionScore: a.positionScore,
    athleticScore: a.athleticScore,
    hasBrokenCarpoolLink: false,
    hasBrokenCoachLink: false,
    actualCoachLinkIndividualID: 0,
    actualCoachLinkFirstName: null,
    actualCoachLinkLastName: null,
    actualCarpoolLinkIndividualID: 0,
    actualCarpoolLinkFirstName: null,
    actualCarpoolLinkLastName: null,
    extraScore: a.extraScore,
    practiceNightExclusions: null,
    products: null,
    individualID: a.individualID,
    firstName: a.firstName,
    lastName: a.lastName,
    middleInitial: a.middleInitial,
    gender: a.gender,
    birthDate: a.birthDate,
    churchName: a.churchName,
    addresses: a.addresses,
    phoneNumbers: a.phoneNumbers,
    emailAddresses: a.addresses,
    thirdPartyClientIDs: a.thirdPartyClientIDs,
    formattedNameFirstLast: a.formattedNameFirstLast,
    formattedNameLastFirst: a.formattedNameLastFirst,
    formattedNamePrivate: a.formattedNamePrivate,
  }
}
