









import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'Stepper',
  props: {
    value: { type: Number, default: 1, required: false },
    vertical: { type: Boolean, default: false, required: false },
  },
  setup(props, ctx) {
    const internalValue = ref(1)

    function change() {
      ctx.emit('change', internalValue.value)
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      },
      { immediate: true }
    )

    return {
      internalValue,
      change,
    }
  },
})
