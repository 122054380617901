import restService from '@/services/restService'
import { ContactInfo } from '@/GeneratedTypes/Partner/ListInfo/ContactInfo'
import { PartnerUserInfo } from '@/models/UserManagement/PartnerUserInfo'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'

import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { UpwardPartnerSocialMediaTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPartnerSocialMediaTypeID'
import { SocialMedia } from '@/GeneratedTypes/Partner/SocialMedia'
import { AccountProfile } from '@/models/Partner/AccountProfile'
import { EFTAuthorizationRequest } from '@/models/Partner/EFTAuthorizationRequest'
import { EFTRequestStatus } from '@/models/Partner/EFTRequestStatus'

const baseUrl = 'partners/'

class PartnerClientException extends GeneralError {
  name = 'Partner API Error'
}

const retrieveAccountContacts = async (accountNumber = '') => {
  return await restService.get<ContactInfo[]>(`${baseUrl}${accountNumber}/trainedLeaders`)
}

const retrieveUsers = async (type: string): Promise<PartnerUserInfo[] | null> => {
  const result = await restService.get<PartnerUserInfo[]>(`users?type=${type}`)

  if (result.isSuccess) {
    return result.data
  } else {
    throw new PartnerClientException('A problem occurred retrieving partner users.')
  }
}

const getAccountsNearBy = async (accountNumber: string): Promise<AccountInfo[] | null> => {
  const result = await restService.get<AccountInfo[]>(`${baseUrl}accountsnearby/${accountNumber}/50`)

  if (result.isSuccess) {
    return result.data
  } else {
    throw new PartnerClientException('A problem occurred retrieving partners.')
  }
}

const deletePartnerUser = async (userId: number): Promise<void> => {
  const x = await restService.delete<void>(`users/${userId}`)
  if (x.isSuccess) {
    return
  }

  throw new PartnerClientException('A problem occurred deleting the specified user.')
}

const updatePartnerRole = async (userId: number, role: string): Promise<void> => {
  const x = await restService.post<void>(`users/${userId}/roles`, { value: role })
  if (x.isSuccess) {
    return
  }

  throw new PartnerClientException('A problem occurred updating the specified user.')
}

const getPartnerInfo = async (accountNumber: string): Promise<AccountInfo | null> => {
  const result = await restService.get<AccountInfo>(`${baseUrl}${accountNumber}`)

  if (result.isSuccess) {
    return result.data
  } else {
    throw new PartnerClientException('A problem occurred retrieving partner info.')
  }
}

const retreivePartners = async (accountNumbers: string[]): Promise<AccountInfo[] | null> => {
  const result = await restService.post<AccountInfo[]>(`${baseUrl}`, accountNumbers)
  return result.data
}

const retreivePartnerSocialMediaTypes = async (): Promise<UpwardPartnerSocialMediaTypeID[] | null> => {
  const result = await restService.get<UpwardPartnerSocialMediaTypeID[]>(
    'upwardTypes/partnerSocialMediaTypes'
  )
  return result.data
}

const retreivePartnerSocialMediaData = async (accountNumber: string): Promise<SocialMedia[] | null> => {
  const result = await restService.get<SocialMedia[]>(`partners/${accountNumber}/socialMedia`)
  return result.data
}

const updatePartnerSocialMediaData = async (accountNumber: string, data: SocialMedia[]): Promise<boolean> => {
  await restService.put<SocialMedia[]>(`partners/${accountNumber}/socialMedia`, data)
  return true
}

const updateAccountProfile = async (accountNumber: string, profile: AccountProfile): Promise<void> => {
  const x = await restService.post<void>(`${baseUrl}${accountNumber}/admin/updateProfile`, profile)
  if (x.isSuccess) {
    return
  }

  throw new PartnerClientException('A problem occurred updating the Account Profile.')
}

const sendEFTAuthorizationRequest = async (
  accountNumber: string,
  eftAuth: EFTAuthorizationRequest
): Promise<void> => {
  const x = await restService.post<void>(`${baseUrl}${accountNumber}/eftauthorization`, eftAuth)
  if (x.isSuccess) {
    return
  }

  throw new PartnerClientException('A problem occurred sending the EFT Authorization Request.')
}

const getEFTRequestStatus = async (accountNumber: string): Promise<EFTRequestStatus | null> => {
  const result = await restService.get<EFTRequestStatus>(`${baseUrl}${accountNumber}/eftrequeststatus`)
  return result.data
}

export interface PartnerSearchResultsType {
  /*** name of church hyphenated with location ***/
  name: string
  accountNumber: number
  eftBankAcctLast4: string
  placeOrdersOnHoldNote: string
  fanGearCustomURL: string

  // ... other stuff returned, but don't need it yet
}

/**
 * Searches the list of partners by a prefix term.
 * @param term
 */
const searchPartners = async (term: string) => {
  const x = await restService.post<PartnerSearchResultsType[]>(`partners/search`, {
    searchFilter: term,
    useStartsWith: true,
  })
  if (x.isSuccess) {
    return x.data
  }

  throw new PartnerClientException('A problem occurred searching partners.')
}

export default {
  retrieveAccountContacts,
  retrieveUsers,
  deletePartnerUser,
  updatePartnerRole,
  getAccountsNearBy,
  searchPartners,
  getPartnerInfo,
  retreivePartners,
  retreivePartnerSocialMediaTypes,
  retreivePartnerSocialMediaData,
  updatePartnerSocialMediaData,
  updateAccountProfile,
  sendEFTAuthorizationRequest,
  getEFTRequestStatus,
}
