








































































































































import CampSetupMixin from '@/views/Camps/CampSetupMixin'

import Alert from '@/components/Alert.vue'
import FullBody from '@/components/FullBody.vue'
import TextInput from '@/elements/TextInput.vue'
import DateInput from '@/elements/DateInput.vue'
import SelectInput from '@/elements/SelectInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import SwitchSlot from '@/elements/SwitchSlot.vue'
import Loading from '@/elements/Loading.vue'
import Modal from '@/components/Modal.vue'
import GradeGenderSelector, { SelectedGradeGender } from '@/components/GradeGenderSelector.vue'
import SaveContinueButton from '@/elements/SaveContinueButton.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import AdditionalParticipantInformation from '@/components/AdditionalParticipantInformation.vue'
import TimezoneSelect from '@/components/TimezoneSelect.vue'
import { UpwardTimezoneTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardTimezoneTypeID'
import * as timezoneStore from '@/store/timezoneTypes'
import { LeagueVolunteerRoleUDFDefinition } from '@/GeneratedTypes/LeagueVolunteerRoleUDFDefinition'
import { LeaguePlayerUDFDefinition } from '@/GeneratedTypes/LeaguePlayerUDFDefinition'
import { getCoachUdfs, updateUdfs } from '@/services/udfService'
import {
  getProgramRegistrationOption,
  setProgramRegistrationOption,
  RegistrationType,
} from '@/services/registrationInfoService'
import { getDateWithTime } from '@/lib/support/utilities/time/time.ts'
import dayjs from 'dayjs'

let useTestData: any = null
if (process.env.VUE_APP_USE_TEST_DATA == 'YES') {
  import('@/views/Camps/testData/information').then((x) => (useTestData = x))
}

interface RunCampByTypeOption {
  description: string
  value: string
}

@Component({
  components: {
    FullBody,
    TextInput,
    DateInput,
    SelectInput,
    Alert,
    TimezoneSelect,
    Loading,
    MemoInput,
    GradeGenderSelector,
    YesNoInput,
    SwitchSlot,
    AdditionalParticipantInformation,
    Modal,
    SaveContinueButton,
    HtmlEditor,
  },
})
export default class Information extends Mixins(CampSetupMixin) {
  @Getter(timezoneStore.getterNames.allItems, { namespace: timezoneStore.namespace })
  private timezones!: UpwardTimezoneTypeID[]

  private gradeGenders = {} as SelectedGradeGender
  private helpIsVisible = false

  private registrationOption: RegistrationType = { registrationTypes: [], onlineRegistrationType: '' }

  private campRunByType = 'GRADE'
  private runCampByTypeList = [
    { description: 'By Grades', value: 'GRADE' },
    { description: 'By Age', value: 'AGE' },
  ] as RunCampByTypeOption[]

  private maxDate = ''
  private minDate = ''
  private lastCutoffDate = null as Date | null

  mounted() {
    this.loading = true
    this.beforeSave = this.handleBeforeSave
    if (this.camp.upwardLeagueID) {
      this.nextRoute = `/programs/camp/${this.camp.upwardLeagueID}/setup/dates`
    } else {
      this.nextRoute = '/programs/camp/setup/dates'
    }
    if (useTestData) {
      useTestData.setInformationForNewCamp(this.camp)
    }

    this.setRegistrationOption()

    this.campRunByType = this.camp.ageCutoffDate ? 'AGE' : 'GRADE'
    this.maxDate = dayjs(this.camp.campStartDate ?? '')
      .add(1, 'year')
      .format('MM/DD/YYYY')
    this.minDate = dayjs(this.camp.campStartDate ?? '')
      .subtract(1, 'year')
      .format('MM/DD/YYYY')
    this.lastCutoffDate = this.camp.ageCutoffDate

    this.loading = false
  }

  // eslint-disable-next-line require-await
  private async handleBeforeSave() {
    if (this.camp.upwardLeagueID && this.camp.isOPRLeague && !this.storeCamp.isOPRLeague) {
      this.camp.registrationInfo = {
        allowCreditCard: true,
        allowPayLater: true,
        // Always set the league.registrationInfo.allowSizingEntry to true per 2635
        allowSizingEntry: true,
        allowGeneralNotes: false,
        allowDonations: false,
        allowWaitlist: true,
        onlineRegistrationBeginDate: getDateWithTime(this.camp.registrationBeginDate, 0, 0, 0),
        onlineRegistrationEndDate: getDateWithTime(
          this.camp.registrationEndDate ?? this.camp.campStartDate,
          23,
          59,
          59
        ),
        donationExplanation: '',
        registrationStartMessage: '',
        confirmationMessage: '',
        twoChildDiscountAmount: 0,
        threeChildDiscountAmount: 0,
        fourOrMoreChildDiscountAmount: 0,
        allowDiscountCodes: false,
        emailLDOnDiscountCodeUse: false,
        discountCodes: [],
        bypassLinks: [],
        contactLine1: '',
        contactLine2: '',
        contactLine3: '',
        contactLine4: '',
        contactLine5: '',
      }
    }
  }

  private setRegistrationOption() {
    const registrationOption = getProgramRegistrationOption(this.camp)
    if (registrationOption !== null) {
      this.registrationOption = registrationOption
    }
  }

  @Watch('caampRunByType')
  private onRunByTypeChange() {
    if (this.campRunByType == 'GRADE') {
      this.lastCutoffDate = this.camp.ageCutoffDate
      this.camp.ageCutoffDate = null
    } else {
      this.camp.ageCutoffDate = this.lastCutoffDate //put it back to what it was in case they accidentally changed it
    }
  }

  @Watch('registrationOption', { immediate: true, deep: true })
  private updateRegistrationOptions() {
    if (this.loading) {
      return
    }
    setProgramRegistrationOption(this.camp, this.registrationOption)
  }

  gradeGenderChange(gradeGenders: SelectedGradeGender) {
    this.gradeGenders = gradeGenders
    if (this.camp.accounts) {
      this.camp.accounts[0].grades = gradeGenders.accountGrades
    }
    if (this.camp.programs) {
      if (this.camp.programs.length > 0) {
        this.camp.programs[0].gradesByGender = gradeGenders.sportProgramGrades
      }
      if (this.camp.programs.length > 1) {
        this.camp.programs[1].gradesByGender = gradeGenders.cheerProgramGrades
      }
    }
  }

  private coachUdfs(): LeagueVolunteerRoleUDFDefinition[] {
    return getCoachUdfs(this.camp)
  }

  private saveUdfs(playerUdfs: LeaguePlayerUDFDefinition[], coachUdfs: LeagueVolunteerRoleUDFDefinition[]) {
    updateUdfs(this.camp, playerUdfs, coachUdfs)
  }
}
