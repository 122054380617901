export const GenderIdToText = (genderId: string | null) => {
  switch (genderId) {
    case 'M':
      return 'Boy'
    case 'F':
      return 'Girl'
    case 'E':
      return 'Both'
    case null:
      return ''
    default:
      return 'not found in getGender'
  }
}

export enum Genders {
  female = 'F',
  male = 'M',
  both = 'E',
}
