export interface DevotionTrackInfo {
  summaryPDFURL: string
  devotionTracks: DevotionTrack[]
}
export interface DevotionTrack {
  title: string
  devotionType: string
  programType: string[]
  description: string | null
  descriptionContent: string | null
}

export function getEmptyDevotionTrackInfo(): DevotionTrackInfo {
  return {
    summaryPDFURL: '',
    devotionTracks: [],
  }
}
