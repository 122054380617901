import { colorTypes } from '@/store/colorTypes'

export function getColorDescription(color: string | null) {
  let retval = '???'
  const upwardColor = colorTypes.state.items.find((x) => x.upwardTypeID === color)
  if (upwardColor) {
    retval = upwardColor.description!
  }

  return retval
}

export function getColorDropDownItems(colors: { typeColorID: string | null }[]) {
  return colors.map((x) => ({ typeColorID: x.typeColorID, description: getColorDescription(x.typeColorID) }))
}
