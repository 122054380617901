










import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SmallCard',
  props: {
    value: { type: Boolean, default: false, required: false },
  },
  setup(props, ctx) {
    function onClick() {
      const newValue = !props.value
      ctx.emit('input', newValue)
      ctx.emit('change', newValue)
      ctx.emit('click')
    }

    return {
      onClick,
    }
  },
})
