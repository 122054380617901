import { FacilityAvailability } from '@/GeneratedTypes/FacilityAvailability'

export const events = {
  Games: 'GAMES',
  Practices: 'PRACTICES',
}

const weekDaysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function renderGameDays(a: FacilityAvailability[]) {
  if (a && a.length) {
    const days = a
      .filter((avl) => avl.typeFacilityEventID == events.Games)
      .sort((a, b) => weekDaysList.indexOf(a.typeDayID) - weekDaysList.indexOf(b.typeDayID))
      .map((g) => g.typeDayID.substring(0, 3))
    return days.length ? days.join(', ') : 'no days available'
  }
}

export function renderPracticeDays(a: FacilityAvailability[]) {
  if (a && a.length) {
    const days = a
      .filter((avl) => avl.typeFacilityEventID == events.Practices)
      .sort((a, b) => weekDaysList.indexOf(a.typeDayID) - weekDaysList.indexOf(b.typeDayID))
      .map((g) => g.typeDayID.substring(0, 3))
    return days.length ? days.join(', ') : 'no days available'
  }
}
