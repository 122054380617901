








































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import * as facilityStore from '@/store/facilities'
import { LeagueFacility } from '@/GeneratedTypes/LeagueFacility'
import GroupBox from '@/elements/GroupBox.vue'
import TextInput from '@/elements/TextInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import Loading from '@/elements/Loading.vue'

import DataTable from '@/elements/DataTable/DataTable.vue'
import EditBtn from '@/elements/DataTable/vue/EditBtn.vue'
import DeleteBtn from '@/elements/DataTable/vue/DeleteBtn.vue'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

import { FacilityAvailability } from '@/GeneratedTypes/FacilityAvailability'
import { viewModes } from '@/store/facilities'
import { FacilityItemEditor } from './ext/FacilityItemEditor'
import FacilityImport from './FacilityImport.vue'

/** external data */
import { headers } from '@/views/Programs/Scheduling/Facilities/ext/headers'
import { getEmptyLeagueFacility } from '@/lib/support/models/LeagueFacility/data'

import { renderGameDays, renderPracticeDays } from './ext/logic'

import { FACILITY_DEFAULT_TIMES } from '@/services/facilityService'

@Component({
  components: {
    GroupBox,
    TextInput,
    YesNoInput,
    ConfirmDeleteModal,
    FacilityImport,
    Loading,
    DataTable,
    EditBtn,
    DeleteBtn,
  },
})
export default class FacilityList extends Vue implements FacilityItemEditor {
  public deleteItem(facility: LeagueFacility) {
    if (facility) {
      this.setCurrentFacility({ id: facility.facilityID })
      this.showConfirmDelete = true
    }
  }

  public async editItem(facility: LeagueFacility) {
    if (facility) {
      this.setCurrentFacility({ id: facility.facilityID })
      await this.beginEdit()
    }
  }

  @Action(facilityStore.actionNames.load, {
    namespace: facilityStore.namespace,
  })
  private readonly retrieveFacilities!: ({ id }: { id: string }) => LeagueFacility[] | null

  @Action(facilityStore.actionNames.delete, {
    namespace: facilityStore.namespace,
  })
  private readonly deleteFacility!: () => boolean

  @Getter(facilityStore.getterNames.currentItem, {
    namespace: facilityStore.namespace,
  })
  private currentFacility!: LeagueFacility

  @Getter(facilityStore.getterNames.items, {
    namespace: facilityStore.namespace,
  })
  private facilities!: LeagueFacility[]

  @Action(facilityStore.actionNames.set, {
    namespace: facilityStore.namespace,
  })
  private readonly setCurrentFacility!: ({ id }: { id: number }) => LeagueFacility

  @Action(facilityStore.actionNames.beginEdit, {
    namespace: facilityStore.namespace,
  })
  private readonly beginEdit!: () => void

  @Action(facilityStore.actionNames.beginCreating, {
    namespace: facilityStore.namespace,
  })
  private readonly beginCreating!: () => void

  @Action(facilityStore.actionNames.beginCreatingFrom, {
    namespace: facilityStore.namespace,
  })
  private readonly beginCreatingFrom!: ({ facility }: { facility: LeagueFacility }) => void

  @Getter(facilityStore.getterNames.viewMode, {
    namespace: facilityStore.namespace,
  })
  private viewMode!: viewModes

  headers = headers
  private renderGameDayList = renderGameDays
  private renderPracticeDayList = renderPracticeDays
  private showConfirmDelete = false
  private showImport = false
  private selectedFacilityId = 0
  private isLoading = true
  private isDeleting = false

  public async mounted() {
    await this.retrieveFacilities({ id: this.$route.params.id })
    this.isLoading = false
  }

  rowSelected(x: DataTableSelection<LeagueFacility>) {
    this.selectedFacilityId = x.item.facilityID
    this.setCurrentFacility({ id: this.selectedFacilityId })
  }

  rowDoubleClick() {
    this.beginEdit()
  }

  private renderGroupDetails(value: any): string | undefined {
    if (value) {
      return value.toString().replace('Facilities -', '')
    }
  }

  private onBeginEdit() {
    this.beginEdit()
  }

  private async create() {
    await this.beginCreating()
  }

  private showFacilityImport() {
    if (!this.showImport) {
      this.showImport = true
    }
  }

  private edit() {
    this.beginEdit()
  }

  async confirmDeleteFacility() {
    try {
      this.isDeleting = true
      await this.deleteFacility()
      await this.retrieveFacilities({ id: this.$route.params.id })
    } finally {
      this.showConfirmDelete = false
      this.isDeleting = false
    }
  }

  private cancelDeleteFacility() {
    this.showConfirmDelete = false
  }

  get getFacilities() {
    if (this.facilities && this.facilities.length > 0) {
      return this.facilities.map((f) => {
        return {
          facilityID: f.facilityID,
          facilityName: f.facilityName,
          games: f.availability ? renderGameDays(f.availability) : null,
          practices: f.availability ? renderPracticeDays(f.availability) : null,
          actions: '',
        }
      })
    }

    return []
  }

  async importFacility(facility: LeagueFacility) {
    const leagueFacility = getEmptyLeagueFacility()

    leagueFacility.postalCode = facility.postalCode
    leagueFacility.typeCountryID = facility.typeCountryID
    leagueFacility.isShared = facility.isShared
    leagueFacility.facilityName = facility.facilityName
    leagueFacility.active = facility.active
    leagueFacility.street1 = facility.street1
    leagueFacility.street2 = facility.street2
    leagueFacility.subdivision1 = facility.subdivision1
    leagueFacility.subdivision2 = facility.subdivision2
    leagueFacility.availability =
      facility.availability?.map((d) => {
        return {
          id: 0,
          typeDayID: d.typeDayID,
          typeFacilityEventID: d.typeFacilityEventID,
          startTime: FACILITY_DEFAULT_TIMES.start,
          endTime: FACILITY_DEFAULT_TIMES.end,
        } as FacilityAvailability
      }) ?? []

    await this.beginCreatingFrom({ facility: leagueFacility })
  }

  groupDetails(street: string): string | null {
    const d = this.renderGroupDetails(street)
    return d ? d : null
  }
}
