




























import { defineComponent, computed, ref } from '@vue/composition-api'

import SelectorCard from '@/components/SelectorCard.vue'
import Loading from '@/elements/Loading.vue'

import store from '@/store'

export default defineComponent({
  name: 'ProgramCreationPicker',
  components: {
    SelectorCard,
    Loading,
  },

  setup(props, ctx) {
    const loading = ref(false)
    const accountName = computed(() => store.getters.authorization.accountName)
    const startingSelection = computed(() => store.getters.leagues.startingSelection)
    function getLeagueTypeById(id: number) {
      return store.getters.leagueTypes.byId(id)
    }

    const leagueType = computed(() => {
      if (startingSelection.value?.leagueTypeId) {
        return getLeagueTypeById(startingSelection.value?.leagueTypeId)
      }

      return null
    })

    async function beginLeague(isEstimate: boolean) {
      loading.value = true
      await store.dispatch.leagues.beginCreating({
        name: startingSelection.value?.name ?? '',
        typeLeagueID: leagueType.value ? leagueType.value.upwardTypeID ?? '' : '',
        publicDisplayName: accountName.value ?? '',
        partnerContact: null,
        addToCache: !isEstimate,
      })
      loading.value = false
      if (isEstimate) {
        ctx.root.$router.push('/programs/estimator')
      } else {
        ctx.root.$router.push('/programs/league/setup/information')
      }
    }

    return {
      loading,
      beginLeague,
    }
  },
})
