


















import { defineComponent, computed, ref } from '@vue/composition-api'
import FullBody from '@/components/FullBody.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import Loading from '@/elements/Loading.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'
import store from '@/store'

export default defineComponent({
  name: 'ConnectIndex',
  components: {
    FullBody,
    HorizontalTabs,
    Loading,
    AlertCloak,
  },
  setup(props, ctx) {
    const currentConnect = computed(() => store.getters.connects.currentItem)
    const alertTypes = ref([AlertCloakTypes.PARTNER])
    const uncloak = ref(false)

    const loading = ref(false)

    const id = computed(() => ctx.root.$route.params.id)

    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    const isDisabled = computed(() => !id.value || !canEdit.value)

    const canEdit = computed(() => store.getters.connects.currentItem.canEdit)

    const tabs = computed((): any[] => [
      { text: 'Setup', to: `/programs/connect/${routeId.value}setup` },
      {
        text: 'Ordering',
        to: `/programs/connect/${routeId.value}ordering`,
        disabled: isDisabled.value,
      },
      {
        text: 'Resources',
        to: `/programs/connect/${routeId.value}resources`,
        disabled: isDisabled.value,
      },
    ])

    //async created hack from https://stackoverflow.com/questions/64117116/how-can-i-use-async-await-in-the-vue-3-0-setup-function-using-typescript
    const run = (asyncFn: () => Promise<void>) => asyncFn()

    //private async created() {
    run(async () => {
      if (ctx.root.$route.params.id) {
        loading.value = true
        await store.dispatch.connects.retrieveAndSetAsCurrent({ id: ctx.root.$route.params.id })
        loading.value = false
      } else {
        //new connect, skip notifications
        uncloak.value = true
      }
    })
    //end created

    return {
      loading,
      alertTypes,
      uncloak,
      id,
      currentConnect,
      tabs,
    }
  },
})
