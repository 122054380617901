













import { defineComponent, computed } from '@vue/composition-api'

import CheckboxInput from '@/elements/CheckboxInput.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

export default defineComponent({
  name: 'CoachConflictFilter',
  components: { CheckboxInput },
  setup() {
    const { setCoachConflictFilters, getCoachConflictFilters } = userPreferences()

    const update = (filterID: string) => {
      setCoachConflictFilters(filterID)
    }

    const filterList = () => {
      return getCoachConflictFilters.value
    }

    return {
      update,
      filters: computed(filterList),
    }
  },
})
