

























import { defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { PlayerConflictInfo } from '@/GeneratedTypes/ListInfo/PlayerConflictInfo'
import MemoInput from '@/elements/MemoInput.vue'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'PlayerAdminNoteEditModal',
  props: {
    conflict: { type: Object as PropType<PlayerConflictInfo>, required: false },
    visible: { type: Boolean, required: true },
  },
  components: { MemoInput, Modal },
  setup(props, ctx) {
    const adminNote = ref<string | null>('')
    const internalVisibleRef = ref(false)

    watch(
      () => props.visible,
      () => {
        if (props.visible != internalVisibleRef.value) {
          internalVisibleRef.value = props.visible
        }
      },
      { immediate: true }
    )

    watch(
      () => props.conflict,
      () => {
        if (props.conflict) {
          adminNote.value = props.conflict?.adminNotes
        }
      },
      { immediate: true, deep: true }
    )

    function close(b: boolean) {
      internalVisibleRef.value = b
      if (!b) {
        ctx.emit('close')
      }
    }
    function confirm() {
      ctx.emit('confirm', props.conflict, adminNote.value)
    }

    return { adminNote, confirm, internalVisibleRef, close }
  },
})
