
















































































































































import { defineComponent, computed, ref, watch, onMounted, nextTick } from '@vue/composition-api'
import Alert from '@/components/Alert.vue'
import Loading from '@/elements/Loading.vue'
import TextInput from '@/elements/TextInput.vue'
import PasswordInput from '@/elements/PasswordInput.vue'
import PartnerList from '@/components/PartnerList.vue'
import localStorageService from '@/services/localStorageService'
import VueRecaptcha from 'vue-recaptcha'
import store from '@/store'
import { PasswordExpiredException } from '@/lib/common/exceptions/login/PasswordExpiredException'
import { LockedAccountException } from '@/lib/common/exceptions/login//LockedAccountException'
import { TwoFactorAuthRequiredException } from '@/lib/common/exceptions/login//TwoFactorAuthRequiredException'
import partnerClient from '@/clients/partnersClient'
import { browserService } from '@/services/browserService'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import { AccountInfo } from '@/GeneratedTypes/ListInfo/AccountInfo'
import QRious from 'qrious'

const localStorageEmailKey = 'authwall-remembered-email'
const rememberedEmail = localStorageService.getRaw(localStorageEmailKey)
const mobileSiteURL = process.env.VUE_APP_URL_MOBILE_REDIRECT

export default defineComponent({
  name: 'TheAuthWall',
  components: {
    Alert,
    TextInput,
    PasswordInput,
    PartnerList,
    Loading,
    VueRecaptcha,
  },
  setup(props, ctx) {
    const userToken = computed(() => store.getters.authorization.userData)
    const daysUntilPasswordExpiration = computed(
      () => store.getters.authorization.daysUntilPasswordExpiration
    )
    const isSuperUser = computed(() => store.getters.authorization.isCurrentUserASuperUser)
    const isMultiAccountUser = computed(() => store.getters.authorization.isMultiAccountUser)
    const accountNumbers = computed(() => store.getters.authorization.accountNumbers)
    const totpInfo = computed(() => store.getters.authorization.totpInfo)
    const fullName = computed(() => store.getters.authorization.fullName)

    const email = ref(rememberedEmail || '')
    const password = ref('')
    const doRememberEmail = ref(!!rememberedEmail)
    const isLoading = ref(false)
    const alertIsVisible = ref(false)
    const currentYear = ref(new Date().getFullYear())
    const partners = ref<AccountInfo[] | null>([])
    const hasMultipleAccounts = ref(false)
    const show2FAPrompt = ref(false)
    const show2FAQRCode = ref(false)
    const totpCode = ref('')
    const qrCodeImage = ref<string | null>(null)
    const qriousInstance = ref(new QRious({ size: 250 }))
    const showAuthKey = ref(false)
    const lastValidTotp = ref<string | null>(null)
    const isReplaying = ref(false)

    const redirectFlag = ref(true)
    const accountToImpersonateAfter2FA = ref('')

    //TNT - temporary hack until we get to Vue3 and can do refs
    const focusVerificationCodeInitial = ref(false)
    const focusVerificationCodeReturning = ref(false)

    onMounted(async () => {
      if (ctx.root.$route.query.token) {
        redirectFlag.value = ctx.root.$route.query.redirect ? ctx.root.$route.query.redirect === 'true' : true

        try {
          verified.value = true
          await store.dispatch.authorization.loginByTokenWithImpersonation({
            chit: ctx.root.$route.query.token as string,
          })
        } catch (e) {
          // locked account
          if (e instanceof LockedAccountException) {
            await ctx.root.$router.push({ path: '/locked-account' })
            return
          }
          if (e instanceof TwoFactorAuthRequiredException) {
            const tfaEx = e as TwoFactorAuthRequiredException
            nextTick(() => {
              isLoading.value = false
              accountToImpersonateAfter2FA.value = tfaEx.accountToImpersonateAfterAuth
              show2FAPrompt.value = true
            })
          }
        }

        redirectCheck()
        ctx.root.$router.push('/')
      }
    })

    const sitekey = computed(() => process.env.VUE_APP_GOOGLE_SITEKEY)

    const errorMessage = ref('')

    /**
     * login called by button click.
     * @private
     */
    async function login(accountNo: string | null = '', replayTOTPCode: string | null) {
      if (isLoading.value || !isVerified.value) {
        return
      }

      isLoading.value = true
      try {
        await store.dispatch.authorization.login({
          email: email.value,
          password: password.value,
          accountNumber: accountNo,
        })
        // at this line it would be an exception if we were not logged in

        if (doRememberEmail.value) {
          localStorageService.set(localStorageEmailKey, email.value)
        }

        if (totpInfo.value == 'GOOD') {
          if (isMultiAccountUser.value) {
            const p = await partnerClient.retreivePartners(
              accountNumbers.value === null ? [] : [...accountNumbers.value] //becuase typescript
            )
            if (p) {
              hasMultipleAccounts.value = true
              partners.value = p
              isLoading.value = false
              return
            }
          }

          redirectCheck()

          //change password if expired.
          if (daysUntilPasswordExpiration.value === 0) {
            await ctx.root.$router.push('/account/security')
          }
        } else {
          if (totpInfo.value == 'PROMPT') {
            if (replayTOTPCode != null) {
              totpCode.value = replayTOTPCode
              isReplaying.value = true
              await validateTOTP()
            } else {
              //show totp prompt
              show2FAPrompt.value = true
            }
          } else {
            //show qr code for initial 2FA signup
            //build QR Code
            //const uri = `otpauth://totp/My%20Upward?secret=${this.totpInfo}`
            const uri = generateTOTPUri(totpInfo.value)
            generateQRCode(uri)
            show2FAQRCode.value = true
          }
        }
      } catch (err) {
        // deal with authentication issues.
        isLoading.value = false

        await handleLoginException(err)

        errorMessage.value = 'User or password combination not valid.'
        alertIsVisible.value = true
      }

      isLoading.value = false
    }

    async function validateTOTP() {
      if ((isLoading.value || !isVerified.value) && !isReplaying.value) {
        return
      }

      isLoading.value = true
      isReplaying.value = false

      try {
        await store.dispatch.authorization.validateTOTP(totpCode.value)

        if (totpInfo.value == 'GOOD') {
          //change password if expired.
          if (daysUntilPasswordExpiration.value === 0) {
            await ctx.root.$router.push('/account/security')
          }

          if (isMultiAccountUser.value) {
            const p = await partnerClient.retreivePartners(
              accountNumbers.value === null ? [] : [...accountNumbers.value] //becuase typescript
            )
            if (p) {
              lastValidTotp.value = totpCode.value
              show2FAPrompt.value = false
              show2FAQRCode.value = false
              hasMultipleAccounts.value = true
              partners.value = p
              isLoading.value = false
              return
            }
          }

          if (accountToImpersonateAfter2FA.value) {
            const who = { userName: undefined, accountNumber: accountToImpersonateAfter2FA.value }
            await store.dispatch.authorization.impersonate(who)
            await ctx.root.$router.push('/')
          }

          redirectCheck()
        } else {
          if (totpInfo.value == 'PROMPT' && !show2FAPrompt.value) {
            totpCode.value = ''
            show2FAPrompt.value = true
          } else {
            throw new Error('Verification Code invalid.  Try again.')
          }
        }
      } catch (err) {
        // deal with authentication issues.
        isLoading.value = false

        await handleLoginException(err)

        errorMessage.value = 'Verification Code invalid.  Try again.'
        alertIsVisible.value = true
      }

      isLoading.value = false
    }

    async function handleLoginException(err: Error) {
      // expired password case
      if (err instanceof PasswordExpiredException) {
        await ctx.root.$router.push({ path: '/expired-password', query: { email: email.value } })
        return
      }

      // locked account
      if (err instanceof LockedAccountException) {
        await ctx.root.$router.push({ path: '/locked-account' })
        return
      }
    }

    function generateQRCode(uri: string) {
      qriousInstance.value.value = uri
      qrCodeImage.value = qriousInstance.value.toDataURL(uri)
    }

    function generateTOTPUri(totpInfo: string) {
      const environment =
        process.env.NODE_ENV == 'production'
          ? process.env.VUE_APP_ROOT_API?.includes('upwqa')
            ? '(QA)'
            : ''
          : '(DEV)'

      const fullNameEncoded = encodeURIComponent(fullName.value || '').replace(
        /[!'()*]/g,
        (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
      )

      return `otpauth://totp/My%20Upward${environment}%3A${fullNameEncoded}?secret=${totpInfo.replace(
        /=/g,
        ''
      )}&issuer=My%20Upward${environment}`
    }

    function redirectCheck() {
      if (useFeatureFlags()?.features?.value?.THE_AUTH_WALL__REDIRECT_TO_MOBILE) {
        // This is a SuperUser on desktop so don't redirect
        if (isSuperUser.value && browserService.isDesktopBrowser()) {
          return
        }

        // This is a SuperUser on mobile with redirectFlag = false so don't redirect
        if (isSuperUser.value && !browserService.isDesktopBrowser() && !redirectFlag.value) {
          return
        }

        if (ctx.root.$router.currentRoute.path.indexOf('/allaccess/') === 0) {
          return
        }
        // redirect everyone else
        redirect()
      }
    }

    function redirect() {
      const url = `${mobileSiteURL}?token=${encodeURIComponent(userToken.value ?? '')}`
      store.commit.authorization.clearCurrentCredentials()
      location.href = url
    }

    const isVerified = computed(() => verified.value || !process.env.VUE_APP_GOOGLE_SITEKEY)

    async function loginWithPartner(p: AccountInfo) {
      await login(p.accountNumber, lastValidTotp.value)
    }

    const verified = ref(false)

    async function verify() {
      verified.value = true
      await login(null, null)
    }

    watch(
      () => show2FAPrompt.value,
      () => {
        if (show2FAPrompt.value) {
          nextTick(() => {
            /*
            const verficatioCode = ctx.refs.verificationCodeReturning as TextInput
            const verficatioCodeTextbox = verficatioCode.$refs.inputField as HTMLInputElement
            verficatioCodeTextbox.focus()
            */
            focusVerificationCodeReturning.value = true
          })
        }
      }
    )

    watch(
      () => show2FAQRCode.value,
      () => {
        if (show2FAQRCode.value) {
          nextTick(() => {
            /*
            const verficatioCode = ctx.refs.verificationCodeInitial as TextInput
            const verficatioCodeTextbox = verficatioCode.$refs.inputField as HTMLInputElement
            verficatioCodeTextbox.focus()
            */
            focusVerificationCodeInitial.value = true
          })
        }
      }
    )

    return {
      alertIsVisible,
      errorMessage,
      show2FAPrompt,
      show2FAQRCode,
      hasMultipleAccounts,
      partners,
      email,
      password,
      isVerified,
      isLoading,
      doRememberEmail,
      totpCode,
      showAuthKey,
      totpInfo,
      qrCodeImage,
      currentYear,
      sitekey,
      loginWithPartner,
      login,
      validateTOTP,
      verify,
      focusVerificationCodeInitial,
      focusVerificationCodeReturning,
    }
  },
})
