
















import { defineComponent, computed, onMounted } from '@vue/composition-api'

import PracticeScheduler from '@/views/Programs/Scheduling/Practices/PracticeScheduler.vue'
import { undefinedIsZero } from '@/lib/support/utilities/numbers/undefinedIsZero'
import store from '@/store'

export default defineComponent({
  name: 'Practices',
  components: { PracticeScheduler },
  setup(props, ctx) {
    const league = computed(() => store.getters.leagues.currentItem)
    const facilities = computed(() => store.getters.facilities.items)

    const leagueID = computed(() => ctx.root.$route.params.id)

    /***
     * cheer or blank
     */
    const programIsCheer = computed(() => ctx.root.$route.params.program == 'cheer')

    /***
     * cheer or blank
     */
    const programID = computed(() => {
      const couldBeCheer = undefinedIsZero(league.value.programs?.length) > 1
      if (programIsCheer.value && couldBeCheer) {
        //@ts-ignore -- checked above.
        return league.value.programs[1].typeProgramID || ''
      }

      if (league.value && league.value.programs?.length) {
        return league.value.programs[0].typeProgramID || ''
      }
      return ''
    })

    const facilitiesLink = computed(
      () => `/programs/league/${ctx.root.$route.params.id}/scheduling/facilities`
    )

    onMounted(async () => {
      await store.dispatch.facilities.load({ id: leagueID.value })
    })

    return {
      facilities,
      facilitiesLink,
      programID,
    }
  },
})
