import { IndividualPhoneInfo } from '@/GeneratedTypes/ListInfo/IndividualPhoneInfo'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'

export function individualPhoneInfoToIndividualPhone(x: IndividualPhoneInfo): IndividualPhone {
  return {
    phoneID: x.phoneID,
    phoneNumber: x.phoneNumber ?? '',
    priority: x.priority,
    typePhoneID: x.typePhoneID ?? '0',
    extension: x.extension ?? '',
    allowSMS: x.typePhoneID == 'MOBILE',
  }
}
