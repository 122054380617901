





























import { defineComponent, computed, ref, watch, PropType, nextTick } from '@vue/composition-api'
import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'
import uuid from 'uuid'

export default defineComponent({
  name: 'RadioGroupInput',
  components: {
    BaseInputWrapper,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    enabled: { type: Boolean, default: true, required: false },
    prependIcon: { type: String, default: '', required: false },
    appendIcon: { type: String, default: '', required: false },
    id: { type: String, default: '', required: false },
    value: { type: (null as unknown) as PropType<any | null>, required: true },
    textPropertyName: { type: String, default: '', required: false },
    valuePropertyName: { type: String, default: '', required: false },
    itemClass: { type: String, default: '', required: false },
    wrapperClass: { type: String, default: '', required: false },
    itemsList: { type: Array, required: true },
    subText: { type: String, default: '', required: false },
    subTextWithLink: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const guid = uuid.v4()
    const internalValue = ref<any>('')

    function getOptionValue(item: any, index: number): string {
      if (props.valuePropertyName !== '') {
        return item[props.valuePropertyName]
      }
      if (props.textPropertyName !== '') {
        return item[props.textPropertyName]
      }
      return index.toString()
    }

    function getOptionText(item: any): string {
      if (props.textPropertyName !== '') {
        return item[props.textPropertyName]
      }
      return item.toString()
    }

    const elementId = computed(() => props.id || `radio-${guid}`)

    function handleChange() {
      ctx.emit('input', internalValue.value)
      ctx.emit('change', internalValue.value)
    }

    const redisplay = ref(false)

    watch(
      () => props.value,
      () => {
        if (internalValue.value !== props.value) {
          internalValue.value = props.value
        }
        redisplay.value = true
        nextTick(() => (redisplay.value = false))
      },
      { immediate: true }
    )

    return {
      elementId,
      redisplay,
      getOptionValue,
      handleChange,
      internalValue,
      getOptionText,
    }
  },
})
