interface SectionNameType {
  [key: string]: string
}
export const SectionNameConversion: SectionNameType = {
  STARTUPORDER: 'Startup Order',
  LEAGUEORDER: 'League Order',
  RETURNORDER: 'Return Order',
  MERCHANDISEORDER: 'Merchandise Order',
  AWARDORDER: 'Award Order',
  CLINICORDER: 'Clinic Order',
  MANUALORDER: 'Manual Order',
}
