


































import dayjs from 'dayjs'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'

export default defineComponent({
  name: 'OrderItem',
  props: {
    order: { type: Object as PropType<OrderInfoExt>, required: true },
    program: { type: String, require: false, default: '' },
  },
  components: {},
  setup(props, ctx) {
    function orderClick(order: OrderInfoExt) {
      ctx.emit('order-click', order)
    }

    function releaseOnHold(order: OrderInfoExt) {
      ctx.emit('order-release', order)
    }

    const shippingStatus = computed(() => (props.order.shipDate ? `Shipped` : `In Process`))
    const orderDate = computed(() => dayjs(props.order.userSubmitDate ?? new Date()).format('MMM D,YYYY'))

    return { orderDate, releaseOnHold, orderClick, shippingStatus }
  },
})
