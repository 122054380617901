import { RouteConfig } from 'vue-router'
import Resources from '@/views/Programs/Resources/Resources.vue'

export default function (namePrefix: string) {
  return [
    {
      name: `${namePrefix}RoleResources`,
      path: 'resources/:roleId?',
      component: Resources,
    },
  ] as RouteConfig[]
}
