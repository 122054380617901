





































import InputLabel from '@/elements/InputLabel.vue'
import Combobox from '@/elements/Combobox.vue'
import dayjs from 'dayjs'
import uuid from 'uuid'
import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
/* eslint-disable @typescript-eslint/no-var-requires */
const customParseFormat = require('dayjs/plugin/customParseFormat')

export default defineComponent({
  name: 'TimePicker',
  props: {
    value: { type: String, required: true },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    required: { type: Boolean, required: false, default: false },
    format: { type: String, required: false, default: 'HH:mm' }, //dayjs format
    interval: { type: Number, required: false, default: 30 }, // in minutes
    min: { type: String, required: false, default: '07:00:00' }, //must match format prop
    max: { type: String, required: false, default: '22:00:00' }, //must match format prop
    VeeValidateRules: { type: String, required: false, default: '' },
    subLabel: { type: String, required: false, default: '' },
    subLabelInline: { type: Boolean, required: false, default: false },
    id: { type: String, required: false, default: '' },
    enabled: { type: Boolean, required: false, default: true },
    name: { type: String, required: false, default: '' },
    tabIndex: { type: String, required: false, default: '' },
    showBlankItem: { type: Boolean, required: false, default: false },
    blankItemText: { type: String, required: false, default: '' },
    noBottomPadding: { type: Boolean, required: false, default: false },
  },
  components: {
    InputLabel,
    ValidationProvider,
    Combobox,
  },
  setup(props, ctx) {
    dayjs.extend(customParseFormat)
    const selected = ref('')
    const invalid = ref('')
    const elemId = 'timepicker-' + uuid.v4()
    const displayFormat = 'h:mmA' //changing the case of the meridiem indicator will require changing handleChange()

    const start = computed(() => dayjs(props.min, props.format))
    const end = computed(() => dayjs(props.max, props.format))

    function handleChange() {
      let val = selected.value

      if (isValid(selected.value)) {
        val = dayjs(selected.value.toUpperCase(), displayFormat).format(props.format)
        invalid.value = ''
      } else if (val != '') {
        invalid.value = 'invalid time (e.g. 10:00AM)'
        return
      }
      ctx.emit('input', val)
      ctx.emit('change', val)
    }

    function isValid(time: string) {
      return time.match(/((?:1[0-2])|(?:0?[0-9])):([0-5][0-9]) ?([ap]m|[AP]M)/) != null
    }

    const times = computed(() => {
      const list = [start.value.format(displayFormat)]
      let time = start.value.add(props.interval, 'm')

      while (time.isBefore(end.value)) {
        list.push(time.format(displayFormat))
        time = time.add(props.interval, 'm')
      }

      list.push(end.value.format(displayFormat))
      return list
    })

    watch(
      () => props.value,
      () => {
        if (props.value != '' && props.value != null) {
          selected.value = dayjs(props.value, props.format).format(displayFormat)
        }
      },
      { immediate: true }
    )
    watch(() => selected.value, handleChange, { immediate: true })

    return {
      times,
      handleChange,
      selected,
      elemId,
      invalid,
    }
  },
})
