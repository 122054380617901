







import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'GroupBox',
  props: {
    header: { type: String, required: true, default: 'HEADER' },
  },
})
