






































import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'DraftOptionSelect',
  setup(props, ctx) {
    function manual() {
      ctx.emit('manualDraft')
    }

    return { manual }
  },
})
