import { render, staticRenderFns } from "./ResourceTile.vue?vue&type=template&id=9c368052&scoped=true&"
import script from "./ResourceTile.vue?vue&type=script&lang=ts&"
export * from "./ResourceTile.vue?vue&type=script&lang=ts&"
import style0 from "./ResourceTile.vue?vue&type=style&index=0&id=9c368052&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c368052",
  null
  
)

export default component.exports