export const campColorSelectionProducts = [
  430101,
  430102,
  430103,
  430104,
  431101,
  431102,
  431103,
  431104,
  432101,
  432102,
  432103,
  432104,
  433101,
  433102,
  433103,
  433104,
  433105,
  433106,
]
