/**
 * Rest Operations for user services
 */

import restService from '@/services/restService'

import { GeneralError } from '@/lib/common/exceptions/GeneralError'
import { AuthUserInfo } from '@/GeneratedTypes/Authorize/ListInfo/AuthUserInfo'

const baseUrl = 'users'

class UserClientException extends GeneralError {
  name = 'User API Error'
}

export default class userClient {
  /**
   * Searches the list of users by a prefix term.
   * @param term
   */
  static async searchUsers(term: string) {
    try {
      const x = await restService.post<AuthUserInfo[]>(`${baseUrl}/search`, {
        searchFilter: term,
        useStartsWith: true,
        appID: 'streamline',
      })
      if (x.isSuccess) {
        return x.data
      }
    } catch (e) {
      throw new UserClientException('A problem occurred searching users.', e)
    }

    throw new UserClientException('A problem occurred searching users.')
  }

  /**
   * User ID to disable.
   * @param userID
   */
  static async disableUser(userID: number) {
    const x = await restService.delete<void>(`${baseUrl}/${userID}`)
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException(`A problem occurred removing user ${userID}`)
  }

  static async get2FAStatus(userID: string) {
    const x = await restService.get<string>(`${baseUrl}/${userID}/2FAStatus`)
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException(`A problem occurred getting 2FA status for user ${userID}`)
  }

  static async set2FAStatus(userID: string, enable: boolean) {
    const x = await restService.put<void>(`${baseUrl}/${userID}/2FAStatus`, {
      value: enable ? 'enabled' : 'disabled',
    })
    if (x.isSuccess) {
      return x.data
    }

    throw new UserClientException(`A problem occurred setting 2FA status for user ${userID}`)
  }
}
