import { RouteConfig } from 'vue-router'
import Setup from '@/views/Leagues/Setup.vue'
import LeagueIndex from '@/views/Leagues/LeagueIndex.vue'
import DigitalInformation from '@/views/Leagues/DigitalInformation.vue'
import { idExpression } from '@/lib/support/router/idHelper'
import resources from './resources'
import orders from './orders'

export default [
  {
    path: `digitalleague/${idExpression}`,
    component: LeagueIndex,
    children: [
      {
        path: '/',
        redirect: `/programs/digitalleague/${idExpression}/setup`,
      },
      {
        path: 'setup',
        component: Setup,
        children: [
          {
            path: '/',
            redirect: `/programs/digitalleague/${idExpression}/setup/information`,
          },
          {
            name: 'digital-information',
            path: 'information',
            component: DigitalInformation,
          },
        ],
      },
      ...resources('DigitalLeague'),
      ...orders('digitalleague'),
    ],
  },
] as RouteConfig[]
