











































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import SearchInput from '@/elements/SearchInput.vue'
import communicationsClient from '@/clients/communicationsClient'
import { EmailSuppressionStatus } from '@/models/Communication/EmailSuppressionStatus'
import Loading from '@/elements/Loading.vue'

interface SearchResults {
  doesExist: boolean
  info: EmailSuppressionStatus
}

export default defineComponent({
  components: { SearchInput, Loading },
  setup() {
    // set to an error if there is one.
    const error = ref('')
    const searchTerm = ref('')
    const loading = ref(false)
    const result = ref<EmailSuppressionStatus | null>(null)
    const searchResults = computed(() => {
      if (result.value) {
        let doesExist = false
        let info = null

        if (result.value.total_count > 0) {
          doesExist = true
          info = result.value
        }
        return { doesExist, info } as SearchResults
      }
    })

    watch(
      () => searchTerm.value,
      async () => {
        if (searchTerm.value.length >= 3) {
          error.value = ''
          try {
            loading.value = true
            result.value = await communicationsClient.getEmailSuppressionStatus(searchTerm.value)

            if (!result.value) {
              error.value = 'Invalid email address or unexpected format'
            }
          } catch (e) {
            error.value =
              e?.message ||
              e?.errorObject?.message ||
              'An unexpected error occurred checking email suppression status'
            loading.value = false
            throw e
          } finally {
            loading.value = false
          }
        }
      }
    )

    return { searchTerm, loading, searchResults, error }
  },
})
