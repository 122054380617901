



























import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import Modal from '@/components/Modal.vue'
import TextInput from '@/elements/TextInput.vue'
import MemoInput from '@/elements/MemoInput.vue'
import YesNoInput from '@/elements/YesNoInput.vue'

import { PartnerUserInfo } from '@/models/UserManagement/PartnerUserInfo'

import authorizationClient from '@/clients/authorizationClient'

@Component({
  components: {
    Modal,
    TextInput,
    MemoInput,
    YesNoInput,
  },
})
export default class ResetPasswordModal extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  private readonly value!: boolean

  @Prop({ type: Object, default: null, required: false })
  private readonly user!: PartnerUserInfo | null

  modalIsVisible = this.value
  phone = ''
  sendSms = false

  @Watch('value')
  handleValueChanged() {
    if (this.value !== this.modalIsVisible) {
      this.modalIsVisible = this.value
    }
  }

  @Watch('modalIsVisible')
  handleModalIsVisibleChanged() {
    if (this.value !== this.modalIsVisible) {
      this.$emit('input', this.modalIsVisible)
    }
  }

  @Watch('sendSms')
  handleSendSmsChanged() {
    if (!this.sendSms) {
      this.phone = ''
    }
  }

  async resetPassword() {
    if (this.user && this.user.username) {
      await authorizationClient.getPasswordResetLink(this.user.username)
    }
    this.modalIsVisible = false
  }
}
