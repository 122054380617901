import localStorageService from '@/services/localStorageService'
import jsonwebtoken from 'jsonwebtoken'
import { AuthToken } from '@/models/Authorization/AuthToken'
import restService from '@/services/restService'
import credentialsService from '@/services/credentialsService'
import { AuthTokenCredentials } from '@/models/Authorization/AuthTokenCredentials'
import { UpwardJwtPayload } from '@/models/UpwardJwtPayload/UpwardJwtPayload'

const localStorageKey = 'auth-token'
const oldLocalStorageKey = 'old-auth-token'

/**
 * Takes an auth token, stores the token so long as it has valid credentials.
 * @param authToken
 */
const getCredentialsFromNewAuthToken = (authToken: AuthToken | null) => {
  if (authToken === null) {
    return null
  }

  const credentials = getCredentialsFromToken(authToken)

  if (!credentialsService.hasValidCredentials(credentials)) {
    return null
  }

  restService.setToken(authToken.upwardJWToken)
  restService.setAccountHeader((credentials?.accountNumbers || [])[0])
  localStorageService.set(localStorageKey, authToken)
  return credentials
}

/**
 * Load and set the token (into axios's default headers) from shared storage.
 */
const getCredentialsFromStoredAuthToken = () => {
  const authToken = localStorageService.getParsed<AuthToken>(localStorageKey)

  if (authToken !== null) {
    const credentials = getCredentialsFromToken(authToken)
    restService.setToken(authToken.upwardJWToken)
    restService.setAccountHeader((credentials?.accountNumbers || [])[0])
    return getCredentialsFromToken(authToken)
  }

  return null
}

/**
 * Get stored auth token.
 */
const getAuthToken = () => {
  return localStorageService.getParsed<AuthToken>(localStorageKey)
}

const clearStoredAuthToken = () => {
  localStorageService.remove(localStorageKey)
  restService.setAccountHeader(null)
  restService.setToken(null)
}

const getCsvFromPayload = (payload: any, propertyName: string) => {
  const raw = payload[propertyName]

  if (!raw || typeof raw !== 'string') {
    return null
  }

  return raw.split(',')
}

/**
 * Will retrieve a stored token.,
 */
const deleteOldToken = () => {
  localStorageService.remove(oldLocalStorageKey)
}

/**
 * Will retrieve a stored token.,
 */
const retrieveOldToken = () => {
  const oldtoken = localStorageService.getParsed<AuthToken>(oldLocalStorageKey)
  if (oldtoken) {
    return oldtoken
  }
  return getAuthToken()
}

/**
 * Backup a token that is not already impersonating a user, otherwise no-op
 */
const backupToken = () => {
  const token = getAuthToken()
  if (token) {
    const credentials = getCredentialsFromToken(token)

    if (
      credentialsService.hasValidCredentials(credentials) &&
      !credentialsService.isImpersonating(credentials)
    ) {
      localStorageService.set(oldLocalStorageKey, token)
    }
  }
}

const setHTTPHeaderAndLocalStorage = (authToken: AuthToken | null) => {
  if (!authToken) return null
  const credentials = getCredentialsFromToken(authToken)
  restService.setToken(authToken.upwardJWToken)
  restService.setAccountHeader((credentials?.accountNumbers || [])[0])
  localStorageService.set(localStorageKey, authToken)
}

const getCredentialsFromToken = (authToken: AuthToken | null) => {
  if (authToken === null || authToken.upwardJWToken === null) {
    return null
  }

  const decodedJwt = jsonwebtoken.decode(authToken.upwardJWToken, { complete: true })

  if (decodedJwt === null) {
    return null
  } else if (typeof decodedJwt === 'string') {
    return null
  }

  const jwtPayload = decodedJwt.payload as UpwardJwtPayload

  const claimAccountNumber = jwtPayload['upw-account']
  const rootAccountNumber = jwtPayload['upw-rootaccount']
  const rolesByLeague = jwtPayload['upw-rolesbyleague']

  /*
    Impersonation is active if account numbers above do not match
  */
  let rootIsImpersonating = false
  if (claimAccountNumber !== rootAccountNumber) {
    rootIsImpersonating = true
  }

  const accountNumbers: string[] = []

  if (claimAccountNumber) {
    accountNumbers.push(claimAccountNumber)
  }

  if (authToken.accountNumbers && authToken.accountNumbers.length) {
    accountNumbers.push(...authToken.accountNumbers)
  }

  return {
    accountNumbers,
    accountName: jwtPayload['upw-aname'],
    activities: jwtPayload['upw-activities'],
    fullName: jwtPayload['upw-name'],
    impersonatedAccount: rootIsImpersonating ? claimAccountNumber : '',
    impersonationActive: rootIsImpersonating,
    email: jwtPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
    userData: jwtPayload['upw-userdata'],
    roles: getCsvFromPayload(jwtPayload, 'upw-roles'),
    expiration: new Date(jwtPayload.exp * 1000),
    daysUntilPasswordExpiration: Number(jwtPayload['upw-pwdexp']) || null,
    leagueRoles: JSON.parse(rolesByLeague),
    totpInfo: jwtPayload['upw-totp'],
  } as AuthTokenCredentials
}

export default {
  deleteOldToken,
  backupToken,
  retrieveOldToken,
  getCredentialsFromNewAuthToken,
  getCredentialsFromStoredAuthToken,
  clearStoredAuthToken,
  getAuthToken,
  setHTTPHeaderAndLocalStorage,
}
