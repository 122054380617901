export const getProps = () => ({
  itemsList: { type: [Object, Array], required: true },
  valueAndTextTheSame: { type: Boolean, default: false, required: false },
  textPropertyName: { type: String, default: '', required: false },
  valuePropertyName: { type: String, default: '', required: false },
})

export function useSelectList(props: any) {
  function getOptionValue(item: any, index: number | symbol | string): string {
    if (props.valueAndTextTheSame) {
      return item
    }
    if (props.valuePropertyName !== '') {
      return item[props.valuePropertyName]
    }
    if (props.textPropertyName !== '') {
      return item[props.textPropertyName]
    }
    return index.toString()
  }

  function getOptionKey(item: any, index: number | symbol | string): string {
    return JSON.stringify(getOptionValue(item, index))
  }

  function getOptionText(item: Record<symbol | string, unknown>): string {
    if (props.textPropertyName !== '') {
      return `${item[props.textPropertyName]}`
    }
    return item.toString()
  }

  return {
    getOptionValue,
    getOptionKey,
    getOptionText,
  }
}
