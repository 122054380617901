import { xShipToBase } from '@/GeneratedTypes/xOrder/xShipToBase.ts'

export function getEmptyxShipToBase(): xShipToBase {
  return {
    name: '',
    attention: '',
    contactInfo: '',
    street1: '',
    street2: '',
    subdivision1: '',
    subdivision2: '',
    postalCode: '',
    countryCode: '',
  }
}
