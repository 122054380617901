
























import { defineComponent, PropType, computed } from '@vue/composition-api'
import { prettyLeagueTypes } from '@/lib/common/LeagueTypesPretty'
import { prettyGradeGender } from '@/services/gradeService'
import { LeagueAbstraction } from '@/models/LeagueAbstraction/LeagueAbstraction'

export default defineComponent({
  name: 'ResumeProgramItem',
  props: {
    program: { type: Object as PropType<LeagueAbstraction>, required: true },
  },
  components: {},
  setup(props, { emit }) {
    const grades = computed(() => {
      const acct = props.program?.accounts ? props.program.accounts[0] : null
      return acct ? prettyGradeGender(acct) : []
    })
    const cheer = computed(() => (grades.value.length > 2 ? grades.value.slice(2) : null))
    const sport = computed(() => (grades.value.length ? grades.value.slice(0, 2) : null))
    const type = computed(() => prettyLeagueTypes[props.program.typeLeagueID])

    function clicked() {
      emit('click', props.program.type)
    }

    function remove() {
      emit('remove', props.program.type)
    }
    return { type, remove, cheer, sport, clicked }
  },
})
