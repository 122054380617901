


















import { defineComponent, ref, watch, onMounted, PropType } from '@vue/composition-api'
import SimpleGrid from '@/elements/SimpleGrid.vue'
import { GridColumn } from '@/models/Grid/GridColumn'
import divisionsClient from '@/clients/divisionsClient'
import Loading from '@/elements/Loading.vue'
import {
  divisionStandings,
  divisionStanding,
} from '@/lib/support/models/GeneratedTypes/ListInfo/LeagueDivisionStandingsInfo'

export default defineComponent({
  name: 'DivisionStandingsList',
  components: {
    SimpleGrid,
    Loading,
  },
  props: {
    ProgramId: { type: String, required: false, default: null },
    DivisionId: { type: Number as PropType<number>, required: true, default: 1 },
    IncrementId: { type: Number as PropType<number>, required: true, default: 0 },
  },
  setup(props, ctx) {
    const isLoading = ref(true)
    const divisionStandingList = ref<divisionStanding[]>([])
    const columns = ref<GridColumn[]>([
      { field: 'teamName', headerText: 'Team' } as GridColumn,
      { field: 'teamWins', headerText: 'Wins' } as GridColumn,
      { field: 'teamLosses', headerText: 'Losses' } as GridColumn,
      { field: 'teamTies', headerText: 'Ties' } as GridColumn,
    ])
    onMounted(async () => {
      await loadList()
    })

    async function loadList() {
      isLoading.value = true
      // Ensure `ProgramId` is a string and not null or undefined
      const programId = props.ProgramId ? props.ProgramId : ''

      const divisionId = props.DivisionId

      try {
        if (props.ProgramId) {
          const list = await divisionsClient.retrieveStandingByDivision(
            ctx.root.$route.params.id,
            programId,
            divisionId
          )
          if (list) {
            divisionStandingList.value = divisionStandings(list).map((x) => ({ ...x }))
          }
        } else {
          divisionStandingList.value = []
        }
      } finally {
        isLoading.value = false
      }
    }

    watch(
      () => props.ProgramId,
      async () => {
        await loadList()
      }
    )

    watch(
      () => props.DivisionId,
      async () => {
        await loadList()
      }
    )

    watch(
      () => props.IncrementId,
      async () => {
        await loadList()
      }
    )

    return {
      isLoading,
      divisionStandingList,
      columns,
    }
  },
})
