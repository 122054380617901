
















import { defineComponent, PropType, computed, watch, reactive } from '@vue/composition-api'
import { SlideType } from '@/components/Carousel/types/Slide.ts'
import Slide from '@/components/Carousel/Slide.vue'

export default defineComponent({
  name: 'Carousel',
  components: { Slide },
  props: {
    deck: { type: Array as PropType<SlideType[]>, required: true },
    current: { type: Number, required: true },
  },
  setup(props, ctx) {
    const history: number[] = reactive([])
    const slide = computed(() => {
      const x = props.deck.find((s) => s.id == props.current)
      return x
    })

    const back = () => {
      const previousId = history[history.length - 2]
      history.pop()
      history.pop()
      ctx.emit('back', previousId)
    }

    watch(
      () => props.current,
      () => {
        history.push(props.current)
      },
      { immediate: true }
    )

    return {
      slide,
      history,
      back,
    }
  },
})
