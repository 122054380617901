import { FacilityAvailability } from '@/GeneratedTypes/FacilityAvailability'
import { ScheduleBlocks } from '@/models/elements/Scheduler'
import { days } from '@/lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import { timeStringToMilliseconds } from '@/lib/support/utilities/time/time'
import { FacilityEventEnum } from '@/lib/support/models/FacilityAvailability/types'

/***
 * Funny day names and imprecise times make scheduleBlocks just a bit
 * easier to work with. It also provides a neutral interface for
 * editing different types of schedule blocks.
 * @param availability
 * @param event
 * @constructor
 */
export function AvailabilityToScheduleBlocks(
  availability: FacilityAvailability[],
  event: string | FacilityEventEnum
): ScheduleBlocks[] {
  return (
    (availability ?? [])
      .filter((x) => x.typeFacilityEventID == event)
      .map((x) => ({
        day: days.find((y) => y.name == x.typeDayID)?.value || 0,
        timeStart: timeStringToMilliseconds(x.startTime),
        timeEnd: timeStringToMilliseconds(x.endTime),
      })) ?? []
  )
}
