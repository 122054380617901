














import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'

import { LeaguePracticeNight } from '@/GeneratedTypes/LeaguePracticeNight'
import ModalMultiSelect from '@/components/ModalMultiSelect.vue'
import { days as upwardDays } from '../../../../lib/support/models/UpwardTypes/UpdwardDayTypeID/days'
import store from '@/store'

export default defineComponent({
  name: 'AvailablePracticeDays',
  components: {
    ModalMultiSelect,
    Modal,
  },
  setup(props, ctx) {
    // original coder was haning on to this on mount and modifies it on save.
    const practiceNights = ref<LeaguePracticeNight[]>([])
    // the days selected at program set up
    const selectedDays = ref<string[]>([])
    //the days currently selected for practice
    const disabledDays = ref<string[]>([])

    const days = ref(upwardDays)

    const modalIsVisible = ref(false)

    onMounted(async () => {
      try {
        const leagueID = ctx.root.$route.params.id
        // so, original coder pulled in the practice nights from facility service,
        // then hangs on to them to modify on save.
        const nights = await store.dispatch.facilities.loadPracticeNights({ id: leagueID })
        practiceNights.value = nights ?? []

        // it appears these are the days that are not available for selection
        const programDays = (await store.dispatch.facilities.loadSelectedPracticeDays({ id: leagueID })) ?? []
        // then ironically the selections are disabled, he can set them on toggle the toggle
        // method below as well, but this is the only place they are disabled.
        disabledDays.value = days.value.filter((x) => programDays?.indexOf(x.name) == -1).map((x) => x.name)
      } catch (error) {}
    })

    function toggle(currentDays: string[] | null) {
      modalIsVisible.value = !modalIsVisible.value
      selectedDays.value = currentDays ?? []
    }

    const list = computed(() => days.value.map((x) => ({ id: x.name, description: x.name })))

    const disableds = computed(() => disabledDays.value)

    const selected = computed(() => selectedDays.value.filter((sd) => !disableds.value.some((d) => d === sd)))

    function save(nights: string[]) {
      modalIsVisible.value = false

      const practiceDays: LeaguePracticeNight[] = []
      if (practiceNights.value) {
        const myPracticeNights = practiceNights.value
        myPracticeNights.forEach((pn) => {
          const night = nights.some((x) => x == pn.typeDayID)

          if (night) {
            practiceDays.push(pn)
          }
        })
      }
      ctx.emit('practiceDaysUpdated', practiceDays)
    }

    return {
      modalIsVisible,
      list,
      disableds,
      selected,
      save,
      toggle,
    }
  },
})
