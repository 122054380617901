




























































import { defineComponent, watch } from '@vue/composition-api'
import { getProps, useTextlike } from '@/composables/TextLike'
import TextLikeWrapper from '@/elements/TextLikeWrapper.vue'

export default defineComponent({
  name: 'NumberInput',
  components: {
    TextLikeWrapper,
  },
  props: {
    value: { default: 0, type: Number, required: false },
    max: { default: Number.MAX_VALUE, type: Number, required: false },
    min: { default: Number.MIN_VALUE, type: Number, required: false },
    placeholder: { type: String, default: '', required: false },
    showSpinButton: { default: true, type: Boolean, required: false },
    changeOnKeyUp: { default: false, type: Boolean, required: false },
    spinStep: { default: 0, type: Number, required: false },
    ...getProps(),
  },
  setup(props, ctx) {
    const { iconClick, internalValue } = useTextlike<string>(props, ctx)

    internalValue.value = convertToString(props.value)

    function convertToString(val: number | null) {
      return val === null || isNaN(val) ? '' : val.toFixed(0)
    }

    function convertToNumber(val: string): number | null {
      const valueAsNumber = Math.round(Number(val) * 100) / 100

      if (isNaN(valueAsNumber)) {
        return null
      }

      return valueAsNumber
    }

    function handleChange() {
      const valueAsNumber = convertToNumber(internalValue.value ?? '')
      ctx.emit('input', valueAsNumber)
      ctx.emit('change', valueAsNumber)
    }

    function handleKeyUp() {
      const valueAsNumber = convertToNumber(internalValue.value ?? '')
      ctx.emit('keyup', valueAsNumber)
      if (!props.changeOnKeyUp) return
      handleChange()
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = convertToString(props.value)
      }
    )

    return {
      iconClick,
      internalValue,
      handleChange,
      handleKeyUp,
    }
  },
})
