















import { defineComponent, computed, ref, watch, onMounted, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import { UpwardLeagueTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueTypeID'
import { LeagueTypes } from '@/lib/common/LeagueTypes'
import store from '@/store'
import { cloneDeep } from 'lodash'

export default defineComponent({
  name: 'LeagueTypeSelect',
  components: {
    SelectInput,
  },
  props: {
    value: { type: (null as unknown) as PropType<number | null>, required: true },
    opportunityTypeId: { type: (null as unknown) as PropType<string | null>, required: true },
  },
  setup(props, ctx) {
    function getByOpportunityTypeId(opportunityTypeId: string): UpwardLeagueTypeID[] {
      return store.getters.leagueTypes.byOpportunityTypeId(opportunityTypeId)
    }

    const isLoading = ref(false)
    const selectedItem = ref<number | null>(props.value)
    const items = ref<UpwardLeagueTypeID[]>([])

    const enabled = computed(() => !!props.opportunityTypeId && !isLoading.value)

    async function loadItems() {
      if (isLoading.value || !props.opportunityTypeId) {
        return
      }

      isLoading.value = true
      await store.dispatch.leagueTypes.fetchAll(false)
      const tempList = cloneDeep(getByOpportunityTypeId(props.opportunityTypeId).filter((t) => t.active))
      const selectType = tempList.find((x) => x.upwardTypeID == LeagueTypes.UPWARDSELECT)
      if (selectType) {
        selectType.description = 'Other'
      }
      items.value = tempList
      isLoading.value = false
    }

    onMounted(async () => {
      await loadItems()
    })

    watch(
      () => props.opportunityTypeId,
      async () => {
        selectedItem.value = null
        onChange()
        await loadItems()
      }
    )

    watch(
      () => props.value,
      () => {
        selectedItem.value = props.value
      }
    )

    function onChange() {
      ctx.emit('input', selectedItem.value)
      ctx.emit('change', selectedItem.value)
    }

    return {
      enabled,
      selectedItem,
      items,
      onChange,
    }
  },
})
