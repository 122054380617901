














import { computed, defineComponent, watch } from '@vue/composition-api'

import SelectInput from '@/elements/SelectInput.vue'
import userPreferences from '@/views/Programs/Divisions/TeamManagement/logic/userPreferences'

interface SortOptionType {
  text: string
  key: string
}

export default defineComponent({
  name: 'TeamSort',
  components: { SelectInput },
  props: {
    program: { type: String, required: true },
  },
  setup(props) {
    const { setTeamSort, getTeamSort } = userPreferences()
    const list: SortOptionType[] = [
      { text: 'Last Name: A to Z', key: 'name_asc' },
      { text: 'Last Name: Z to A', key: 'name_desc' },
      { text: 'First Name: A to Z', key: 'firstname_asc' },
      { text: 'First Name: Z to A', key: 'firstname_desc' },
      { text: 'Score: Low to High', key: 'score_asc' },
      { text: 'Score: High to Low', key: 'score_desc' },
      { text: 'Grade: Low to High', key: 'grade_asc' },
      { text: 'Grade: High to Low', key: 'grade_desc' },
    ]

    const optionList = computed(() => {
      let options = [...list]
      const hideScore =
        props.program == 'BASKETBALLCHEERLEADING' ||
        props.program == 'FOOTBALLCHEERLEADING' ||
        props.program == 'UPWARDSELECTMSS'
      if (hideScore) options = options.filter((i) => !i.key.includes('score'))
      return options
    })

    const update = (sortBy: string) => {
      setTeamSort(sortBy)
    }

    watch(
      () => props.program,
      () => {
        const exists = optionList.value.findIndex((a) => a.key == getTeamSort.value) > 0
        if (!exists) update(optionList.value[0].key)
      },
      { immediate: true }
    )

    return {
      sort: computed(() => getTeamSort.value),
      options: optionList,
      update,
    }
  },
})
