export interface ReturnOrderStatus {
  fromTypeOrderStatusID: string
  toTypeOrderStatusID: string
  note: string | null
}

export function getEmptyReturnOrderStatus() {
  return {
    fromTypeOrderStatusID: '',
    toTypeOrderStatusID: '',
    note: '',
  } as ReturnOrderStatus
}
