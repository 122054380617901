import { LeagueDivisionExtendedInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionExtendedInfo'
import { computed, ref } from '@vue/composition-api'
import { UpwardLeagueIDType } from '@/lib/support/models/League/data'
import divisionsClient from '@/clients/divisionsClient'
import { getEmptyLeagueDivisionExtendedInfo } from '@/lib/support/models/LeagueDivisionExtendedInfo/data'
import reportsClient, {
  CarpoolLinksBrokenReportType,
  CoachLinksBrokenReportType,
  CoachLinksOverageType,
  EvaluationsMissingType,
} from '@/clients/reportsClient'

export function useReports() {
  const summary = ref<LeagueDivisionExtendedInfo>(getEmptyLeagueDivisionExtendedInfo())
  const reportCoachLinks = ref<Array<CoachLinksBrokenReportType>>([])
  const reportEvaluationsMissing = ref<Array<EvaluationsMissingType>>([])
  const reportCarpoolLinks = ref<Array<CarpoolLinksBrokenReportType>>([])
  const reportCoachOverage = ref<Array<CoachLinksOverageType>>([])

  async function fetchAndSetBrokenCarpoolLinks(
    leagueID: UpwardLeagueIDType,
    programID: string,
    divisionID: number
  ) {
    reportCarpoolLinks.value = (await reportsClient.getCarPoolLinks(leagueID, programID, divisionID)) ?? []
  }
  /**
   * refresh the reports, will autoload reports with data.
   * @param leagueID
   * @param programID
   * @param divisionID
   */
  const refresh = async (leagueID: UpwardLeagueIDType, programID: string, divisionID: number) => {
    // Added this to prevent errors like this:
    // GET http://azqaweb01.eastus.cloudapp.azure.com:8080/api/divisions///0/extendedInfo 404 (Not Found)
    if (!leagueID || !programID || !divisionID) {
      return
    }

    summary.value =
      (await divisionsClient.retrieveSummaryInfo(leagueID, programID, divisionID)) ??
      getEmptyLeagueDivisionExtendedInfo()

    if (summary.value.brokenCoachLinkCount) {
      reportCoachLinks.value =
        (await reportsClient.getCoachLinksBroken(leagueID, programID, divisionID)) ?? []
    }
    if (summary.value.missingEvalScoresCount) {
      reportEvaluationsMissing.value =
        (await reportsClient.getEvaluationsMissing(leagueID, programID, divisionID)) ?? []
    }

    if (summary.value.brokenCarpoolLinkCount) {
      await fetchAndSetBrokenCarpoolLinks(leagueID, programID, divisionID)
    }
    if (summary.value.coachLinksExceededCount) {
      reportCoachOverage.value =
        (await reportsClient.getCoachLinksOverage(leagueID, programID, divisionID)) ?? []
    }
  }
  return {
    summary: computed(() => summary.value),
    reportCoachLinks: computed(() => reportCoachLinks.value),
    reportCoachOverage: computed(() => reportCoachOverage.value),
    reportCarpoolLinks: computed(() => reportCarpoolLinks.value),
    reportEvaluationsMissing: computed(() => reportEvaluationsMissing.value),
    refresh,
    fetchAndSetBrokenCarpoolLinks,
  }
}
