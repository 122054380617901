








































































import { computed, defineComponent, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { TransactionSearchByEnum } from '@/models/Dashboard/TransactionSearch'
import SearchInput from '@/elements/SearchInput.vue'
import { useRegistrationFinder } from '@/views/AdminDash/vues/RegistrationTransactionPanel/useRegistrationFinder'
import OPRTransactionItem from '@/views/AdminDash/vues/RegistrationTransactionPanel/OPRTransactionItem.vue'
import TextInput from '@/elements/TextInput.vue'
import Loading from '@/elements/Loading.vue'

export default defineComponent({
  components: { Loading, TextInput, OPRTransactionItem, RadioGroupInput, SearchInput },
  setup() {
    const searchByList = [
      { id: TransactionSearchByEnum.CONFIRMATION, label: 'Confirmation #' },
      { id: TransactionSearchByEnum.INVOICE, label: 'Invoice #' },
      { id: TransactionSearchByEnum.LEAGUE, label: 'League Number' },
    ]
    const searchTermLeague = ref('')
    const searchTermConfirmation = ref('')
    const searchTermAccount = ref('')
    const searchTermCredit = ref('')
    const searchTermLast = ref('')
    const searchTermInvoice = ref('')
    const isLastNameDisabled = computed(
      () =>
        !(
          searchTermCredit.value ||
          searchTermLeague.value ||
          searchTermInvoice.value ||
          searchTermAccount.value ||
          searchTermConfirmation.value
        )
    )
    const areWeReadyToSearch = computed(() => !isLastNameDisabled)
    const registrationFinder = useRegistrationFinder()
    const loading = ref(false)
    const errorMessage = ref('')
    async function search() {
      errorMessage.value = ''
      loading.value = true
      try {
        await registrationFinder.findRegistration({
          accountNumber: searchTermAccount.value,
          confirmationNumber: searchTermConfirmation.value,
          invoiceNumber: searchTermInvoice.value,
          lastName: searchTermLast.value,
          upwardLeagueID: searchTermLeague.value,
          creditCardLastFour: searchTermCredit.value,
        })
        if (!registrationFinder.registrationResults.value?.length) {
          errorMessage.value = 'No results found. Try a different search criteria.'
        }
      } catch (e) {
        errorMessage.value = 'Could not complete search. ' + (e?.errorObject?.message || '')
      } finally {
        loading.value = false
      }
    }

    return {
      search,
      errorMessage,
      loading,
      areWeReadyToSearch,
      isLastNameDisabled,
      searchTermAccount,
      searchTermConfirmation,
      searchTermCredit,
      searchTermInvoice,
      searchTermLast,
      searchTermLeague,
      searchByList,
      results: registrationFinder.registrationResults,
    }
  },
})
