












import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'

import reportsClient from '@/clients/reportsClient'

import ActiveReport from '@/views/Programs/Reports/vues/ActiveReport.vue'
import store from '@/store'

export default defineComponent({
  name: 'Siblings',
  components: {
    ActiveReport,
  },
  setup(props, ctx) {
    const accountNumber = computed(() => store.getters.authorization.firstAccountNumber)
    const upwardLeagueId = computed(() => ctx.root.$route.params.id)
    const loading = ref(false)
    const loadStatus = ref('')

    onMounted(async () => {
      await loadReport()
    })

    function reportLoaded() {
      loading.value = false
    }

    async function loadReport() {
      loadStatus.value = 'Downloading Report Data . . .'
      const data = await Promise.all([
        reportsClient.getSiblingsReport(upwardLeagueId.value, accountNumber.value),
      ])

      loadStatus.value = 'Downloading Report Templates . . .'
      const reportTemplates = await Promise.all([reportsClient.getReportTemplate('sibling')])

      loadStatus.value = 'Processing Report Templates . . .'
      const report = JSON.parse(reportTemplates[0]?.jsonContent ?? '')

      const mainData = data[0]

      report.DataSources[0].ConnectionProperties.ConnectString = 'jsondata=' + JSON.stringify(mainData)

      loadStatus.value = 'Loading Report . . .'
      const activeReport: any = ctx.refs.activereport
      activeReport.loadReportData(report, null)
    }

    return {
      loading,
      loadStatus,
      reportLoaded,
    }
  },
})
