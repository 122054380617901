












import { defineComponent, ref, watch, onMounted, PropType } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import { UpwardLeagueScoringTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardLeagueScoringTypeID'
import { LeagueScoringTypes as LST } from '@/lib/common/LeagueScoringTypes'

import store from '@/store'

export default defineComponent({
  name: 'LeagueScoringTypeSelect',
  components: {
    SelectInput,
  },
  props: {
    value: { type: (null as unknown) as PropType<string | null>, required: true },
    label: { type: String, required: false, default: '' },
    required: { type: Boolean, required: false, default: false },
  },
  setup(props, ctx) {
    const isLoading = ref(false)
    const selectedItem = ref<string>(!props.value || props.value === '' ? LST.NOSTANDINGS : props.value)
    const leagueScoringTypes = ref<UpwardLeagueScoringTypeID[]>([])

    async function loadItems() {
      if (isLoading.value) {
        return
      }

      isLoading.value = true
      await getLeagueScoringTypes()
      isLoading.value = false
    }

    onMounted(async () => {
      await loadItems()
    })

    watch(
      () => selectedItem.value,
      () => {
        if (props.value !== selectedItem.value) {
          ctx.emit('input', selectedItem.value)
          ctx.emit('change', selectedItem.value)
        }
      }
    )

    watch(
      () => props.value,
      () => {
        selectedItem.value = !props.value || props.value === '' ? LST.NOSTANDINGS : props.value
      }
    )

    function getLeagueScoringTypes() {
      leagueScoringTypes.value = store.getters.leagueScoringTypes.allItems
    }

    return {
      isLoading,
      selectedItem,
      leagueScoringTypes,
    }
  },
})
